import React, { useState, useEffect } from 'react';
import { CButton, CCol, CInput, CLabel, CSelect, CFormGroup, CModal, CModalHeader, CModalTitle, CModalFooter, CModalBody, CForm } from '@coreui/react';
import axios from 'axios';
import IP from '../../StoreGlobal/IP';
import AsyncSelect from 'react-select/async';
import $axios from 'src/utils/axios';
const EquipmentModal = ({ modal, setModal, getAllEquipment, onCreateOption = false, equipmentName }) => {
  const Swal = require('sweetalert2');
  const company_id = parseInt(localStorage.getItem('company_id'));
  let accessToken = localStorage.getItem('token');
  const [catreq, setcatreq] = useState(false);
  const [brandreq, setbrandreq] = useState(false);
  const [locreq, setlocreq] = useState(false);
  const [rentreq, setrentreq] = useState(false);
  const [datereq, setdatereq] = useState(false);
  const [perreq, setperreq] = useState(false);
  const [conditionlist, setconditionlist] = useState([
    { value: 'GOOD', label: 'GOOD' },
    { value: 'BAD', label: 'BAD' },
  ]);
  const [rentalOption, setRentalOption] = useState([
    { value: parseInt(1), label: 'YES' },
    { value: parseInt(0), label: 'NO' },
  ]);
  const [newequipment, setnewequipment] = useState([
    {
      category: '',
      category_id: '',
      brand: '',
      location: '',
      condition: '',
      date_acquired: '',
      comment: '',
      date_acquired: null,
    },
  ]);

  const [selectedEquipment, setSelectedEquipment] = useState(null);

  useEffect(() => {
    setEquipmentName();
  }, [equipmentName]);

  const setEquipmentName = () => {
    let arr = [...newequipment];
    arr[0].brand = equipmentName;
    setnewequipment(arr);
    setcatreq(equipmentName);
    setbrandreq(true);
  };

  const fetchCategoryOptions = async (inputValue) => {
    try {
      const response = await $axios.get('equipment/category/list', {
        params: {
          search_category: inputValue,
        }
      });
      const categories = response.data.response;
      categories.map((category) => ({ value: category, label: category }));
      console.log('category', categories);
      return categories;
    } catch (error) {
      console.error('Error fetching equipment categories:', error);
      return [];
    }
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      fetchCategoryOptions(inputValue).then((options) => {
        // callback(options);
        const filteredOptions = options;
        callback(filteredOptions);
      });
    }, 1000);
  };

  const changeCat = (e, Index) => {
    const arr = [...newequipment];
    arr[Index].category = e.target.value;
    arr[Index].category_id = parseInt(e.target.value);
    console.log('changeCat arr', arr);
    setnewequipment(arr);
    setcatreq(true);
  };

  const changeBrand = (e, Index) => {
    const arr = [...newequipment];
    arr[Index].brand = e.target.value;
    setnewequipment(arr);
    if (e.target.value === '') {
      setbrandreq(false);
    } else {
      setbrandreq(true);
    }
  };

  const changeLocation = (e, Index) => {
    const arr = [...newequipment];
    arr[Index].location = e.target.value;
    setnewequipment(arr);
    if (e.target.value === '') {
      setlocreq(false);
    } else {
      setlocreq(true);
    }
  };

  const changeCondition = (e, Index) => {
    const arr = [...newequipment];
    arr[Index].condition = e.target.value;
    setnewequipment(arr);
    if (e.target.value === 'Select Condition') {
      setdatereq(false);
    } else {
      setdatereq(true);
    }
  };

  const changePeriod = (e, Index) => {
    const arr = [...newequipment];
    arr[Index].date_acquired = e.target.value;
    setnewequipment(arr);
    if (e.target.value === '') {
      setperreq(false);
    } else if (rentreq) {
      setperreq(true);
    } else {
      setperreq(true);
    }
  };

  const rentalOnchange = (e, Index) => {
    const arr = [...newequipment];
    // arr[Index]['rental'] = e.target.checked ? 1 : 0;
    arr[Index]['rental'] = parseInt(e.target.value);
    setnewequipment(arr);
    setrentreq(true);
    if (e.target.value === 'Select Rental') {
      setrentreq(false);
    } else {
      setrentreq(true);
    }
  };

  const disableSubmit = () => {
    if (rentreq && newequipment[0]['rental'] === 1) {
      if (!catreq || !brandreq || !locreq) {
        return true;
      }
    } else {
      return !catreq || !brandreq || !locreq || !rentreq || !datereq || !perreq;
    }
  };

  const optionCondition = conditionlist.map((data, Index) => {
    return (
      <option value={data.value} key={Index}>
        {data.label}
      </option>
    );
  });

  const optionRental = rentalOption.map((data, Index) => {
    return (
      <option value={data.value} key={Index}>
        {data.label}
      </option>
    );
  });

  // const optionEquipment = equipmentOption.map((data, Index) => {
  //   return (
  //     <option value={data.value} key={Index}>
  //       {data.label}
  //     </option>
  //   );
  // });

  const submit = () => {
    axios({
      method: 'post',
      url: IP + 'equipment/add',
      data: { data: newequipment },
      headers: {
        'content-type': 'application/vnd.myapp.type+json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (!onCreateOption) {
            getAllEquipment(company_id);
          }
          onClose();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          text: error.response.data.response,
          confirmButtonColor: '#23303D',
        });
        console.log('equipment/Equipment.js', ' ', 'line 403', error);
      });
  };

  const onClose = () => {
    setnewequipment([
      {
        category: '',
        category_id: '',
        brand: '',
        location: '',
        condition: '',
        date_acquired: '',
        comment: '',
        rental: '',
      },
    ]);
    equipmentName = '';
    setcatreq(false);
    setbrandreq(false);
    setlocreq(false);
    setdatereq(false);
    setperreq(false);
    setrentreq(false);
    setModal(false);
    setModal(false);
    setSelectedEquipment(null);
  };

  const equipmentBody = newequipment.map((data, Index) => {
    return (
      <CCol sm="12">
        <CForm action="" method="post">
          <CFormGroup>
            <CLabel>Equipment</CLabel>

            {/* {onCreateOption ? (
              <CInput value={equipmentName} onChange={(e) => changeCat(e, Index)} placeholder="Enter Equipment" />
            ) : (
              <CSelect value={data.category} onChange={(e) => changeCat(e, Index)}>
                <option>Select Equipment</option>
                {optionEquipment}
              </CSelect>
            )} */}

            {/* <CSelect value={data.category} onChange={(e) => changeCat(e, Index)}>
              <option>Select Equipment</option>
              {optionEquipment}
            </CSelect> */}
            {/* <AsyncSelect loadOptions={loadOptions} defaultOptions /> */}
            <AsyncSelect
              value={selectedEquipment}
              loadOptions={loadOptions}
              defaultOptions
              onChange={(selectedOption) => {
                setSelectedEquipment(selectedOption);
                changeCat({ target: { value: selectedOption.value } }, 0);
              }}
            />
          </CFormGroup>
          <CFormGroup>
            <CLabel>Brand/Model</CLabel>
            {onCreateOption ? <CInput value={equipmentName} onChange={(e) => changeBrand(e, Index)} placeholder="Enter Brand/Model" /> : <CInput value={data.brand} onChange={(e) => changeBrand(e, Index)} placeholder="Enter Brand/Model" />}
          </CFormGroup>
          <CFormGroup>
            <CLabel>Location</CLabel>
            <CInput value={data.location} onChange={(e) => changeLocation(e, Index)} placeholder=" Enter Location" />
          </CFormGroup>
          <CFormGroup>
            <CLabel>Condition</CLabel>
            <CSelect value={data.condition} onChange={(e) => changeCondition(e, Index)} disabled={data.rental == 1}>
              <option>Select Condition</option>
              {optionCondition}
            </CSelect>
          </CFormGroup>
          <CFormGroup>
            <CLabel>Date Acquired</CLabel>
            <CInput type="date" value={data.date_acquired} onChange={(e) => changePeriod(e, Index)} disabled={data.rental == 1} />
          </CFormGroup>
          <CFormGroup>
            <CLabel>Rental</CLabel>
            <CSelect value={data.rental} onChange={(e) => rentalOnchange(e, Index)}>
              <option>Select Rental</option>
              {optionRental}
            </CSelect>
          </CFormGroup>
        </CForm>
      </CCol>
    );
  });
  return (
    <CModal show={modal} onClose={() => onClose()} size="md" style={{ borderRadius: '5px' }} centered>
      <CModalHeader closeButton style={{ borderColor: 'white' }}>
        <CModalTitle>Add New Equipment</CModalTitle>
      </CModalHeader>
      <CModalBody>{equipmentBody}</CModalBody>
      <CModalFooter style={{ borderColor: 'white' }}>
        <CCol md="4" sm="4" lg="4" xl="4">
          <CButton block variant="outline" color="aimhi-textlight-neutral" size="sm" onClick={() => submit()} disabled={disableSubmit()}>
            Submit
          </CButton>
        </CCol>
      </CModalFooter>
    </CModal>
  );
};

export default EquipmentModal;
