import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
// added code
import ProjectDetail from './views/projectdetails/ProjectDetails'; // Create this component for displaying project details
// added code

// initializing websocket connection here and store instance in redux
// const socket = io('http://localhost:5000', {
//   'reconnection' : true,
//   'reconnectionDelay' : 5000,
//   'reconnectionAttempts' : 10
// });

// socket.on('connect', () => {
//   console.log('connected')
// })

// socket.emit('disconnect')
//
// socket.on('disconnect', () => {
//   console.log('disconnected from server')
//
//   console.log(socket)
// })

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Forgot = React.lazy(() => import("./views/pages/forgotpassword/Forgot"));
const CompanyProfile = React.lazy(() =>
  import("./views/company-profile/CompanyProfile")
);
const ResetPassword = React.lazy(() => import("./views/pages/resetpassword/ResetPassword"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/Forgot"
              name="Fotgot Password"
              render={(props) => <Forgot {...props} />}
            />
            <Route
              path="/company-profile"
              name="CompanyProfile"
              render={(props) => <CompanyProfile {...props} />}
            />
            <Route
              path="/reset-password/:token"
              name="ResetPassword"
              render={(props) => <ResetPassword {...props} />}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />

            {/* added code */}
            <Route path="/projects/:id/scopeofwork" component={ProjectDetail} />
            {/* added code */}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
