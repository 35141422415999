import { Alert } from '@coreui/coreui';
import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const GetPosition = (projectId) => {
  let params = {
    companyId: parseInt(localStorage.getItem('company_id')),
    accessToken: localStorage.getItem('token'),
  };
  let data = {
    project_id: projectId,
    company_id: params.companyId,
  };

  return $axios.post('manpower/list/all', {
    ...data,
  });
};

export const AddPosition = (val) => {
  let companyId = parseInt(localStorage.getItem('company_id'));
  let accessToken = localStorage.getItem('token');

  let data = {
    position: val.position,
    description: val.description,
    // force: val.force,
    status: val.status,
  };

  return $axios.post('manpower/add/position', {
    ...data,
  });
};

export const GetManpowerPosition = (detailed, page, search = '') => {
  let companyId = parseInt(localStorage.getItem('company_id'));
  console.log(detailed, 'detailed here');
  let routes = detailed ? `manpower/position?detailed=${detailed}&page=${page}&search=${search}` : `manpower/position`;

  return $axios.get(routes);
};

export const GetManpowerPositionNew = ({page = 1, page_size = 10, searchTerm = '' }) => {
  return $axios.get(`manpower/position/new?page=${page}&page_size=${page_size}&search=${searchTerm}`);
};

export const GetManpowerPositionDetailed = (detailed, page, search = '') => {
  return $axios.get(`manpower/position/new?detailed=${detailed}&page=${page}&search=${search}`);
};
export const EditManpowerPosition = (val, position_id) => {
  let data = {
    data: val,
  };

  return $axios.put(`manpower/edit/position/${position_id}`, {
    ...data,
  });
};

export const ArchivePosition = (method, position_id) => {
  return $axios[method.toLowerCase()](`manpower/archive/position/${position_id}`);
};

export const GetManpowerPositionById = (position_id) => {
  return $axios.get(`manpower/position?id=${position_id}`);
};

export const TransferManpower = (val) => {
  return $axios.put(`manpower/transfer`, {
    ...val,
  });
};

export const getManpowerList = ({ page = 1, limit = 10, search_term = '', sortBy = '', order = 'asc' } = {}) => {
  return $axios.get(`manpower/masterlist/new?archived=${0}&page=${page}&page_size=${limit}&search=${search_term}&sort_by=${sortBy}&order=${order}`);
};

export const getManpowerArchiveList = ({ page = 1, limit = 10, search_term = '', sortBy = '', order = 'asc' } = {}) => {
  return $axios.get(`manpower/masterlist/new?archived=${1}&page=${page}&page_size=${limit}&search=${search_term}&sort_by=${sortBy}&order=${order}`);
};

export const getManpowerPerProjectList = ({ page = 1, limit = 10, search_term = '' } = {}) => {
  return $axios.get(`manpower/masterlist/project/new?&page${page}&page_size=${limit}&search=${search_term}`);
};

export const archiveAllManpower = () => {
  const data = { type_id: 2 };
  return $axios.post(`project/archive/bulk`, data);
};

export const postManpowerUpload = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return $axios.post('/manpower/upload/new', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getSampleFormat = async () => {
  const Swal = require('sweetalert2');
  try {
    const response = await $axios.post(`manpower/download/format/new`, {}, { responseType: 'blob' });

    const url_2 = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url_2;
    link.setAttribute('download', `Manpower_Format.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url_2);
  } catch (error) {
    console.error('Error downloading report:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Something went wrong...',
      confirmButtonColor: '#23303D',
    });
  }
};
