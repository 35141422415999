import React, { useState, useEffect } from "react";
import {
  CCol,
  CInput,
  CTextarea,
} from "@coreui/react";
import axios from "axios";
import IP from "../../StoreGlobal/IP";
import { Date } from "core-js";
import { EquipmentRequestHistoryDetailsEdit } from "src/request/Equipment";
import ThingsToConsider from "../aimhi-widgets/ThingToConsider";

const PrintEquipmentRequest = React.forwardRef((props, ref) => {
  let accessToken = localStorage.getItem("token");
  const company_id = parseInt(localStorage.getItem("company_id"));
  let user_id = parseInt(localStorage.getItem("user_id"));
  const Swal = require("sweetalert2");
  const [equipment, setequipment] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [fullName, setFullname] = useState("");
  const [dateNow, setDateNow] = useState("'");
  const [projectManager, setprojectManager] = useState("");
  const [siteEngineer, setsiteEngineer] = useState("");
  const [approver, setApprover] = useState("Name of Approver");
  const [positionApprover, setPositionApprover] = useState("position");
  const [editApprover, setEditApprover] = useState(false);
  const [editProsition, setEditPosistion] = useState(false);
  const [dateApproved, setDateApproved] = useState("mm/dd/yyyy");
  const [editDateApproved, setEditDateApproved] = useState(false);
  const [positionReq, setPositionReq] = useState("Position");
  const [editPositionReq, setEditPositionReq] = useState(false);
  const [nameRequester, setNameRequester] = useState("Name of Requester");
  const [editNameReq, setEditNameReq] = useState(false);
  const [department, setDepartment] = useState("");
  const [fuel, setFuel] = useState([]);
  const [category, setCategory] = useState([]);
  const [companyProfilePicture, setcompanyProfilePicture] = useState("");
  useEffect(() => {
    console.log(props, "props here");
    let user_id = parseInt(localStorage.getItem("user_id"));

    setCategory(props.category);
    console.log(props.equipment, "fuel");
    if (props.category == "Fuel") {
      setequipment([]);
      // setFuel(props.equipment?);
      setFuel(props.fuel?.items ?? []);
      console.log(props.fuel?.items ?? [], "set fuel");
    } else {
      setFuel([]);
      // setequipment(props.equipment?);
      setequipment(props.equipment?.items ?? []);
      console.log(props.equipment?.items ?? [], "set equipment");
    }

    getCompanyData(company_id);
    GetUsersById(user_id);
    getDate();
    getoverview(props.project_id);
  }, [props]);

  const getDate = () => {
    let date = Date.now();
    let dateparse = new Date(date).toLocaleString();
    setDateNow(dateparse);
  };

  const getCompanyData = (company_id) => {
    axios
      .get(IP + `company/info/${company_id}`, {
        company_id,
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${accessToken}`,
          // "Drive-Token": token,
        },
      })
      .then(function (response) {
        if (response.data.status == 1) {
          console.log(response.data.response, "Company details");
          setCompanyName(response.data.response.name);
          setcompanyProfilePicture(response.data.response.company_pic);
          setLocation(response.data.response.location);
        } else {
          Swal.fire({
            icon: "error",
            title: response.data.response,
            text: response.data.description,
            confirmButtonColor: "#23303D",
          });
        }
      });
  };

  const getoverview = (project_id) => {
    axios
      .get(IP + `overview/${company_id}/${project_id}`, {
        // project_id,
        // company_id: company_id,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        // setimage(images);

        setprojectManager(res.data.response.project_team.project_manager);
        setsiteEngineer(res.data.response.project_team.site_engineer);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const GetUsersById = (user_id) => {
    axios
      .post(IP + "account/user/info", {
        user_id,
        headers: {
          "Content-Type": "application/json",
          // "Drive-Token": token,
        },
      })
      .then(function (response) {
        console.log(response);
        if (response.data.status == 1) {
          setFullname(response.data.response.full_name);
          // setPosition(response.data.response.position);
          setDepartment(response.data.response.department);
          // setMobileNumber(response.data.response.mobile_num);
          // setEmail(response.data.response.email);
          // setUserName(response.data.response.user_name);
          // // setPassword(response.data.response.password);
          // setStatus(response.data.response.status);
          // setProfilePic(response.data.response.profile_pic);
          // setTitle(response.data.response.full_name);
          // setUserRole(response.data.response.role_id);
          // // setUsersList(response.data.response);
        }
      });
  };

  const SaveName = (e) => {
    if (e.key == "Enter") {
      setEditApprover(false);
    }
  };

  const SavePosition = (e) => {
    if (e.key == "Enter") {
      setEditPosistion(false);
    }
  };

  const SaveDateApproved = (e) => {
    if (e.key == "Enter") {
      setEditDateApproved(!editDateApproved);
    }
  };

  const SavePositionReq = (e) => {
    if (e.key == "Enter") {
      setEditPositionReq(!editPositionReq);
    }
  };

  const SaveNameRequester = (e) => {
    if (e.key == "Enter") {
      setEditNameReq(!editNameReq);
    }
  };

  const EditNotes = (Index) => {
    let arr = [...equipment];
    arr[Index]["edit_notes"] = true;
    setequipment(arr);
  };

  const notesChange = (val, Index, tx_id) => {
    let arr = [...equipment];
    arr[Index].notes = val;
    arr[Index]["edit_notes"] = false;
    setequipment(arr);

    let data = {
      tx_id: tx_id,
      category: "equipment",
      notes: val,
    };

    EquipmentRequestHistoryDetailsEdit(data).then((res) => {
      console.log(res);
    });
  };
  const purchasetable = equipment.map((item, Index) => {
    return (
      <tr key={Index}>
        <td>{item.equipment_category}</td>
        <td>{item.model}</td>
        <td>{item.rental == 1 ? "YES" : "NO"}</td>
        <td>{item.date_start}</td>
        <td>{item.date_end}</td>
        <td onClick={() => EditNotes(Index)}>
          {item["edit_notes"] == true ? (
            <CTextarea
              onBlur={(e) => notesChange(e.target.value, Index, item.tx_id)}
            />
          ) : (
            <span>{item.notes}</span>
          )}
        </td>
      </tr>
    );
  });

  const fuelTable = fuel.map((item, Index) => {
    return (
      <tr key={Index}>
        <td>{item.category}</td>
        <td>{item.quantity}</td>
        <td>{item.unit_price}</td>
        <td>{item.total_price}</td>
        <td>{item.supplier}</td>
        <td>{item.date_deployment}</td>
        <td>{item.notes}</td>
      </tr>
    );
  });
  return (
    <div ref={ref} style={{ padding: "10px" }}>
      <table className="table table-bordered" style={{ marginTop: "20px" }}>
        <tr>
          <td colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }}>
            <img
              src={companyProfilePicture}
              style={{ width: "100px", height: "100px" }}
            />
            <div>
              <span>{companyName}</span>
            </div>
            <div>
              <span>{location}</span>
            </div>
            <div style={{ marginTop: "15px" }}>
              <span>Equipment Request</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div>
              <span style={{ fontWeight: "bold" }}>Department :</span>
              <span style={{ marginLeft: "5px" }}>{department}</span>
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Project Name :</span>
              <span style={{ marginLeft: "5px" }}>{props.project_name}</span>
            </div>
          </td>
          <td colSpan={3} style={{ textAlign: "right" }}>
            <div>
              <span style={{ fontWeight: "bold" }}>Date Requested :</span>
              <span style={{ marginLeft: "5px" }}>
                {category == "Fuel"
                  ? props.fuel?.date_requested
                  : props.equipment?.date_requested}
              </span>
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>ER Number :</span>
              <span style={{ marginLeft: "5px" }}>
                <span>
                  {category == "Fuel"
                    ? props.fuel?.pr_num
                    : props.equipment?.pr_num}
                </span>
              </span>
            </div>
          </td>
        </tr>
      </table>
      <table className="table table-bordered" style={{ marginTop: "20px" }}>
        <thead>
          {category == "Fuel" ? (
            <tr>
              <th>Category</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total Price</th>
              <th>Supplier</th>
              <th>Deployment Date</th>
              <th>Notes</th>
            </tr>
          ) : (
            <tr>
              <th>Equipment Category</th>
              <th>Model</th>
              <th>Rental</th>
              <th>Date Start</th>
              <th>Date End</th>
              <th>Notes (optional)</th>
            </tr>
          )}
        </thead>
        <tbody>{category == "Fuel" ? fuelTable : purchasetable}</tbody>
      </table>
      <table className="table table-bordered">
        <tbody style={{ textAlign: "center" }}>
          <tr style={{ fontSize: "12px" }}>
            <td>Requested by:</td>
            <td>Prepared by:</td>
            <td style={{ width: "250px" }}>Notes:</td>
            <td>Date Approved:</td>
            <td colSpan={2}>Approved by:</td>
          </tr>
          <tr style={{ fontSize: "12px" }}>
            <td style={{ verticalAlign: "bottom", height: "60px" }}>
              {editNameReq == true ? (
                <CInput
                  type="text"
                  value={nameRequester}
                  onChange={(e) => setNameRequester(e.target.value)}
                  onKeyPress={(e) => SaveNameRequester(e)}
                />
              ) : (
                <span
                  onClick={() => setEditNameReq(!editNameReq)}
                  className="pointer"
                >
                  {nameRequester}
                </span>
              )}
            </td>
            <td style={{ verticalAlign: "bottom" }}>
              {category == "Fuel"
                ? props.fuel?.preparer
                : props.equipment?.preparer}
            </td>
            <td>
              {category == "Fuel" ? props.fuel?.notes : props.equipment?.notes}
            </td>
            <td rowSpan={2} style={{ verticalAlign: "bottom" }}>
              <span>
                {category == "Fuel"
                  ? props.fuel?.date_approved
                  : props.equipment?.date_approved}
              </span>
            </td>
            <td colSpan={2} rowSpan={2} style={{ verticalAlign: "bottom" }}>
              {category == "Fuel"
                ? props.fuel?.approver
                : props.equipment?.approver}
              <div>
                <span>
                  {category == "Fuel"
                    ? props.fuel?.approver_position
                    : props.equipment?.approver_position}
                </span>
              </div>
            </td>
          </tr>
          <tr style={{ fontSize: "12px" }}>
            <td>
              {editPositionReq == true ? (
                <CInput
                  type="text"
                  onChange={(e) => setPositionReq(e.target.value)}
                  value={positionReq}
                  onKeyPress={(e) => SavePositionReq(e)}
                />
              ) : (
                <span
                  onClick={() => setEditPositionReq(!editPositionReq)}
                  className="pointer"
                >
                  {positionReq}
                </span>
              )}
            </td>
            <td>
              {category == "Fuel"
                ? props.fuel?.preparer_position
                : props.equipment?.preparer_position}
            </td>
          </tr>
        </tbody>
      </table>
      <CCol style={{ padding: "0px" }}>
        <ThingsToConsider />
      </CCol>
    </div>
  );
});

export default PrintEquipmentRequest;
