import React, { useState, useEffect } from 'react';
import { CCol, CRow, CInput, CTabs, CTabContent, CTabPane, CNav, CNavItem, CNavLink, CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, CAlert, CButton, CTooltip } from '@coreui/react';

import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import { useHistory } from 'react-router-dom';
import { Table, TabPane } from 'reactstrap';
import { useDispatch } from 'react-redux';

import ProjectOverview from './ProjectOverview';
import ProjectTask from './ProjectTask';
import ProjectBudgetCost from './ProjectBudgetCost';
import ProjectBudgetDetail from './ProjectBudgetDetail';
import Materials from './Materials';
import Equipment from './Equipment';
import Team from './Team';
import IP from '../../StoreGlobal/IP';
import $axios from 'src/utils/axios';
import { OverviewWarning } from 'src/request/OverView';
import Updates from './Updates';
import Report from './Report';
// import { FaArchive, FaTimes, FaThumbsUp, FaReply, FaHardHat, FaArrowDown, FaArrowUp, FaPaperclip, FaImage, FaQuestionCircle, FaRegQuestionCircle, FaPencilAlt, FaTrash, FaTrashAlt, FaCheck, FaArrowCircleLeft, FaExclamationCircle } from 'react-icons/fa';
import { FaPencilAlt, FaCheck, FaArrowCircleLeft, FaExclamationCircle } from 'react-icons/fa';
// import { cilReload } from '@coreui/icons';

// import Tooltip from '@mui/material/Tooltip';
// import Button from '@mui/material/Button';

// import { getSchedule } from 'src/request/Schedule';
import { GetProjectTabStatus, GetTabWarnings } from 'src/request/Projects';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { set } from 'lodash';

const datesMissed = [
  '03/22/2022 - 04/02/2022',
  '04/03/2022 - 04/16/2022',
  '04/17/2022 - 04/30/2022',
  '05/01/2022 - 05/14/2022',
  '05/15/2022 - 05/28/2022',
  '05/29/2022 - 06/11/2022',
  '06/12/2022 - 06/25/2022',
  '06/26/2022 - 07/09/2022',
  '07/10/2022 - 07/23/2022',
  '07/24/2022 - 08/06/2022',
  '08/07/2022 - 08/20/2022',
  '08/21/2022 - 09/03/2022',
  '09/04/2022 - 09/17/2022',
  '09/18/2022 - 10/01/2022',
  // '10/02/2022 - 10/15/2022',
  // '10/16/2022 - 10/29/2022',
  // '10/30/2022 - 11/12/2022',
  // '11/13/2022 - 11/26/2022',
  // '11/27/2022 - 12/10/2022',
  // '12/11/2022 - 12/24/2022',
  // '12/25/2022 - 01/07/2023',
  // '01/08/2023 - 01/21/2023',
  // '01/22/2023 - 02/04/2023',
  // '02/05/2023 - 02/18/2023',
  // '02/19/2023 - 03/04/2023',
  // '03/05/2023 - 03/18/2023',
  // '03/19/2023 - 04/01/2023',
  // '04/02/2023 - 04/15/2023',
  // '04/16/2023 - 04/29/2023',
  // '04/30/2023 - 05/13/2023',
  // '05/14/2023 - 05/27/2023',
  // '05/28/2023 - 06/10/2023',
  // '06/11/2023 - 06/24/2023',
];

const ProjectDetails = (props) => {
  console.log(props, 'ProjectDetails');

  const history = useHistory();
  const Swal = require('sweetalert2');
  const company_id = parseInt(localStorage.getItem('company_id'));
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));
  const [collapse, setCollapse] = useState('');
  const [active, setActive] = useState(1);
  const [dropdownvalue, setDroupDownValue] = useState('Detail');
  const [projectId, setprojectId] = useState('');
  const dispatch = useDispatch();
  const ProjectStatus = 50;
  const [disabled, setdisabled] = useState(false);
  const [requireDisable, setRequireDisabled] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [editProjectName, setEditProjectName] = useState(false);
  const [identifier, setIdentefier] = useState('');
  const [tabStatus, setTabStatus] = useState({});
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [requireDisablePSOW, setRequireDisabledPSOW] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [reportActiveTab, setReportActiveTab] = useState(1);
  const [missedDates, setMissedDates] = useState([]);
  const [reportHasWarning, setReportHasWarning] = useState(false);
  const [OverviewHasWarning, setOverviewHasWarning] = useState(false);
  const [historicalReportCount, setHistoricalReportCount] = useState(0);

  const location = useLocation();
  let { project_id, type, start, end, identifiers } = location.state || {}; // Access project_id from location.state

  // Check if props.project_id is defined
  const idToUse = props.ProjectId !== undefined ? props.ProjectId : project_id;

  const menu = !isNaN(values?.menu) ? parseInt(values?.menu) : null;
  const sowId = !isNaN(values?.sowId) ? parseInt(values?.sowId) : null;
  const phaseId = !isNaN(values?.phaseId) ? parseInt(values?.phaseId) : null;
  useEffect(() => {
    // setprojectId(props.ProjectId);
    // getdetailed(props.ProjectId);
    // getoverview(props.ProjectId);
    // setIdentefier(props.Identifier);
    // // getSchedule(props.ProjectId);
    // getProjectTabStatus(props.ProjectId);
    const fetchData = async () => {
      await getdetailed(idToUse);
      await getoverview(idToUse);
      // getSchedule(props.ProjectId);
      await getProjectTabStatus(idToUse);
      await getTabWarnings(idToUse);
    };
    if (props.ProjectId !== undefined) {
      setIdentefier(props.Identifier);
    }

    setprojectId(idToUse);
    fetchData();
    console.log('project_id123 idToUse', idToUse);
    console.log('shet props', props);

    if (menu > 0 && menu < 9) {
      console.log('menu', menu);
      switch (menu) {
        case 1:
          dispatch({ type: 'set', module: 'project-overview' });
          break;
        case 2:
          setActive(2);
          dispatch({ type: 'set', module: 'project-estimate' });
          break;
        case 3:
          setActive(3);
          dispatch({ type: 'set', module: 'project-scope-of-work' });

          // setActive(1)
          break;
        case 4:
          setActive(4);
          dispatch({ type: 'set', module: 'project-materials' });
          break;
        case 5:
          dispatch({ type: 'set', module: 'project-equipment' });
          break;
        case 6:
          setActive(6);
          dispatch({ type: 'set', module: 'project-team' });
          break;
        case 7:
          dispatch({ type: 'set', module: 'project-reports' });
          break;
        case 8:
          dispatch({ type: 'set', module: 'project-updates' });
          break;
        default:
          dispatch({ type: 'set', module: 'project-overview' });
          break;
      }
      // <ProjectTask
      //   id={project_id}
      //   type={type}
      //   start={start}
      //   end={end}
      //   validate={disablePSOW}
      // />
    }
  }, [props]);

  // const validateData = (arr) => {
  //   const hasDependency = arr.filter(length => length.length != 0);
  //   console.log(arr, "arrreshasDependency");
  //   console.log(hasDependency, "reshasDependency");

  //   if (hasDependency.length === 0) {
  //     setRequireDisabledPSOW(false);
  //   } else {
  //     setRequireDisabledPSOW(true);
  //   }
  // };

  // const getSchedule = (project_id) => {
  //   axios
  //     .get(IP + `schedule/${company_id}/${project_id}`, {
  //       headers: {
  //         "Content-Type": "application/vnd.myapp.type+json",

  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res, "resgetSchedule");
  //       if (res.data.status == 1) {
  //         let data = res.data.response;
  //         console.log(data, "datagetSchedule");

  //         let arr = [];
  //         if (data.length != 0) {
  //           data.forEach((phase) => {

  //             phase.sow.forEach((sow1) => {
  //               arr.push({
  //                 length: sow1.dependencies.length,
  //               });
  //             });
  //           });
  //           console.log(arr, "arrgetSchedule");
  //           validateData(arr);

  //         }
  //       }
  //     })
  // };

  const getTabWarnings = (projectId) => {
    GetTabWarnings(projectId).then(({ data }) => {
      console.log(data, 'tab warnings');
      if (data.status == 200) {
        console.log('report has warning', data.response.Report.warning);
        setReportHasWarning(data.response.Report.warning);
        setHistoricalReportCount(data.response.Report.count.historical_report);
      }
    });
    OverviewWarning(projectId)
    .then((res) => {
      if(res.data.status === 200){
        setOverviewHasWarning(res?.data?.results[0]?.warning);
      }
    })
  };

  const getProjectTabStatus = (projectId) => {
    GetProjectTabStatus(projectId).then(({ data }) => {
      console.log(data, 'tab status');
      if (data.status == 200) {
        setTabStatus(data.response);
      }
    });
  };

  const save = (project_id) => {
    let data = {
      name: projectName,
      identifier: identifier,
      user_id: user_id,
    };

    console.log(data);
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .put(`overview/${company_id}/${project_id}`, {
        // company_id: company_id,
        // project_id,
        update: data,
      })
      .then((res) => {
        if (res.data.status == 1) {
          getoverview(project_id);
          setEditProjectName(!editProjectName);
          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 172', error);
      });
  };

  const getoverview = (project_id) => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .get(`overview/${company_id}/${project_id}`)
      .then((res) => {
        console.log(res, 'request');
        if (res.data.status == 1) {
          setProjectName(res.data.response.project_info.name);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 155', error);
      });
  };

  const getTabClassName = (tab) => {
    if (tab == 'overview') {
      console.log(tabStatus);
    }
  };

  const getdetailed = (project_id) => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .get(`estimate/${company_id}/${project_id}`)
      .then((res) => {
        console.log(res.data.response.detailed.length, 'length');
        console.log(res, 'getdetailed');

        if (res.data.response.detailed.length == 1) {
          setdisabled(true);
          setDroupDownValue('Detail');
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectDetails.js', ' ', 'line 94', error);
      });
  };

  const disableEstimate = (val) => {
    setRequireDisabled(val);
  };

  const disablePSOW = (val) => {
    setRequireDisabledPSOW(val);
  };

  const back = () => {
    history.push({
      pathname: '/projects/Index',
    });
    window.location.reload(true);
  };

  const getMissedDates = () => {
    // TODO: Get missed dates from the backend
    setMissedDates(datesMissed);
    // setMissedDates([]);
  };

  const lorem =
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.';

  const hello = {};
  return (
    <>
      <CRow>
        <FaArrowCircleLeft
          className="back_button"
          // style={{ width: "25px", height: "25px" }}
          onClick={back}
        />
        <CCol xm="12" sm="12" md="8" lg="8" xl="8">
          {editProjectName == true ? (
            <CCol lg="9" xl="9">
              <CRow>
                <CCol lg="10" xl="10" style={{ padding: '0px' }}>
                  <CInput value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                </CCol>
                <CCol lg="2" xl="2">
                  <FaCheck style={{ width: '20px', height: '20px', color: '#3A7D21' }} onClick={() => save(props.ProjectId)} />
                </CCol>
                <CCol lg="5" xl="5" style={{ padding: '0px', marginTop: '10px' }}>
                  <CInput value={identifier} onChange={(e) => setIdentefier(e.target.value)} />
                </CCol>
              </CRow>
            </CCol>
          ) : (
            <span className="Title">
              {projectName}{' '}
              <span>
                {active == 1 && (
                  <FaPencilAlt
                    style={{
                      marginLeft: '10px',
                      width: '20px',
                      height: '20px',
                    }}
                    onClick={() => setEditProjectName(!editProjectName)}
                  />
                )}
              </span>
              <div>
                <span>Project ID : {identifier}</span>
              </div>
            </span>
          )}

          <div>
            <span className="Description">{/* All your projects. Lorem ipsum dolor sit amet, consectetur */}</span>
          </div>
        </CCol>
        {/* <CCol xm="12" sm="12" md="12" lg="12" xl="12" style={{ marginTop: '16px' }}>
          <CAlert
            color="aimhi-danger"
            style={{ position: 'relative', cursor: 'pointer', color: 'rgb(220 38 38)' }}
            onClick={() => {
              setActive(7);
              dispatch({ type: 'set', module: 'project-reports' });
              setShowAlert(false);
              setReportActiveTab(7);
            }}
            show={showAlert}
            fade={true}
          >
            <h6>You have missed reports on: </h6> <strong> {datesMissed.map((date) => date).join(',  ')} </strong>
          </CAlert>
        </CCol> */}

        <CCol xm="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
          <CTabs activeTab={active - 1}>
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink
                  size="sm"
                  // className="projecttabs"
                  // className={
                  //   active == 1
                  //     ? `projecttabs-active border-tabs-radios`
                  //     : `projecttabs border-tabs-radios`
                  // }
                  className={`projecttabs${!tabStatus?.overview ? '-desabled' : active == 1 ? '-active' : ''} border-tabs-radios`}
                  onClick={() => {
                    setActive(1);
                    dispatch({ type: 'set', module: 'project-overview' });
                    // dispatch(currentModuleAction("project-overview"));
                  }}
                  style={{ position: 'relative' }}
                >
                  Overview
                  {OverviewHasWarning && (
                    <CTooltip content="Some POW amounts are missing. Please input the missing POW amounts">
                      <FaExclamationCircle
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '-3px',
                          padding: '0',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '24px', // Set a fixed width
                          height: '24px',
                          color: '#B42A19',
                          backgroundColor: 'white',
                          borderRadius: '50%',
                          animation: 'pulse 2s infinite',
                        }}
                      />
                    </CTooltip>
                  )}
                </CNavLink>
              </CNavItem>
              <CNavItem>
                {/* {requireDisable == false ? (
                  <CNavLink
                    className={
                      active == 2 ? "projecttabs-active" : "projecttabs"
                    }
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      setActive(2);
                      dispatch({ type: "set", module: "project-estimate" });
                      // dispatch(currentModuleAction("project-estimate"));
                    }}
                  >
                    Estimate
                  </CNavLink>
                ) : (
                  <CNavLink
                    className={
                      active == 2
                        ? "projecttabs-active"
                        : "projecttabs-desabled"
                    }
                    style={{ fontSize: "12px" }}
                    disabled
                  >
                    Estimate
                  </CNavLink>
                )} */}

                <CNavLink
                  // className={
                  //   active == 2 ? "projecttabs-active" : "projecttabs"
                  // }
                  className={`projecttabs${!tabStatus?.estimate ? '-desabled' : active == 2 ? '-active' : ''} border-tabs-radios`}
                  disabled={!tabStatus?.estimate}
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    setActive(2);
                    dispatch({ type: 'set', module: 'project-estimate' });
                  }}
                >
                  Estimate
                </CNavLink>
              </CNavItem>
              <CNavItem>
                {/* {requireDisable == false ? (
                  <CNavLink
                    className={
                      active == 3 ? "projecttabs-active" : "projecttabs"
                    }
                    onClick={() => {
                      setActive(3);
                      dispatch({
                        type: "set",
                        module: "project-scope-of-work",
                      });
                      // dispatch(currentModuleAction("project-scope-of-work"));
                    }}
                    style={{ fontSize: "12px" }}
                  >
                    Scope of Work
                  </CNavLink>
                ) : (
                  <CNavLink
                    className={
                      active == 3
                        ? "projecttabs-active"
                        : "projecttabs-desabled"
                    }
                    style={{ fontSize: "12px" }}
                    disabled
                  >
                    Scope of Work
                  </CNavLink>
                )} */}

                <CNavLink
                  // className={
                  //   active == 3 ? "projecttabs-active" : "projecttabs"
                  // }
                  className={`projecttabs${!tabStatus?.scope_of_work ? '-desabled' : active == 3 ? '-active' : ''} border-tabs-radios`}
                  disabled={!tabStatus?.scope_of_work}
                  onClick={() => {
                    setActive(3);
                    dispatch({
                      type: 'set',
                      module: 'project-scope-of-work',
                    });
                    // dispatch(currentModuleAction("project-scope-of-work"));
                  }}
                  style={{ fontSize: '12px' }}
                >
                  Scope of Work
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  // className={active == 7 ? "projecttabs-active" : "projecttabs"}
                  className={`projecttabs${!tabStatus?.report ? '-desabled' : active == 7 ? '-active' : ''} border-tabs-radios`}
                  disabled={!tabStatus?.report}
                  onClick={() => {
                    setActive(7);
                    dispatch({ type: 'set', module: 'project-reports' });
                  }}
                  style={{ position: 'relative' }}
                >
                  {reportHasWarning && (
                    <FaExclamationCircle
                      style={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-3px',
                        padding: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '24px', // Set a fixed width
                        height: '24px',
                        color: '#B42A19',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        animation: 'pulse 2s infinite',
                      }}
                    />
                  )}
                  Report
                </CNavLink>
              </CNavItem>

              <CNavItem>
                {/* {requireDisable == false && requireDisablePSOW == true ? (
                  <CNavLink
                    className={
                      active == 4 ? "projecttabs-active" : "projecttabs"
                    }
                    onClick={() => {
                      setActive(4);
                      dispatch({ type: "set", module: "project-materials" });
                      // dispatch(currentModuleAction("project-materials"));
                    }}
                  >
                    Materials
                  </CNavLink>
                ) : (
                  <Tooltip title="Please set the dependencies on Scope of Work tab first." placement="top" arrow>
                    <CNavLink
                      className={
                        active == 4
                          ? "projecttabs-active"
                          : "projecttabs-desabled"
                      }
                      disabled
                    >
                      Materials
                    </CNavLink>

                    <Tooltip />
                  </Tooltip>
                )} */}

                <CNavLink
                  // className={
                  //   active == 4 ? "projecttabs-active" : "projecttabs"
                  // }
                  className={`projecttabs${!tabStatus?.materials ? '-desabled' : active == 4 ? '-active' : ''} border-tabs-radios`}
                  disabled={!tabStatus?.materials}
                  onClick={() => {
                    setActive(4);
                    dispatch({ type: 'set', module: 'project-materials' });
                    // dispatch(currentModuleAction("project-materials"));
                  }}
                >
                  Materials
                </CNavLink>
              </CNavItem>
              <CNavItem>
                {/* {requireDisable == false && requireDisablePSOW == true ? (
                  <CNavLink
                    className={
                      active == 5 ? "projecttabs-active" : "projecttabs"
                    }
                    onClick={() => {
                      setActive(5);
                      dispatch({ type: "set", module: "project-equipment" });
                      // dispatch(currentModuleAction("project-equipment"));
                    }}
                  >
                    Equipment
                  </CNavLink>
                ) : (
                  <Tooltip title="Please set the target dates and dependency on Scope of Work tab first." placement="top" arrow>

                    <CNavLink
                      className={
                        active == 5
                          ? "projecttabs-active"
                          : "projecttabs-desabled"
                      }
                      disabled
                    >
                      Equipment
                    </CNavLink>
                    <Tooltip />

                  </Tooltip>
                )} */}
                <CNavLink
                  // className={
                  //   active == 5 ? "projecttabs-active" : "projecttabs"
                  // }
                  className={`projecttabs${!tabStatus?.equipment ? '-desabled' : active == 5 ? '-active' : ''} border-tabs-radios`}
                  disabled={!tabStatus?.equipment}
                  onClick={() => {
                    setActive(5);
                    dispatch({ type: 'set', module: 'project-equipment' });
                    // dispatch(currentModuleAction("project-equipment"));
                  }}
                >
                  Equipment
                </CNavLink>
              </CNavItem>
              <CNavItem>
                {/* {requireDisable == false && requireDisablePSOW == true ? (
                  <CNavLink
                    className={
                      active == 6 ? "projecttabs-active" : "projecttabs"
                    }
                    onClick={() => {
                      setActive(6);
                      dispatch({ type: "set", module: "project-team" });
                      // dispatch(currentModuleAction("project-team"));
                    }}
                  >
                    Team
                  </CNavLink>
                ) : (
                  <Tooltip title="Please set the target dates and dependency on Scope of Work tab first." placement="top" arrow>
                    <CNavLink
                      className={
                        active == 6
                          ? "projecttabs-active"
                          : "projecttabs-desabled"
                      }
                      disabled
                    >
                      Team
                    </CNavLink>

                    <Tooltip />
                  </Tooltip>
                )} */}

                <CNavLink
                  // className={
                  //   active == 6 ? "projecttabs-active" : "projecttabs"
                  // }
                  className={`projecttabs${!tabStatus?.team ? '-desabled' : active == 6 ? '-active' : ''} border-tabs-radios`}
                  disabled={!tabStatus?.team}
                  onClick={() => {
                    setActive(6);
                    dispatch({ type: 'set', module: 'project-team' });
                    // dispatch(currentModuleAction("project-team"));
                  }}
                >
                  Team
                </CNavLink>
              </CNavItem>
              {/* <CNavItem>
                <CNavLink
                  // className={active == 7 ? "projecttabs-active" : "projecttabs"}
                  className={
                  `projecttabs${(!tabStatus?.report) ? "-desabled" : (active == 7) ? "-active" : ""} border-tabs-radios`
                  }
                  disabled={!tabStatus?.report}
                  onClick={() => {
                    setActive(7);
                    dispatch({ type: "set", module: "project-reports" });
                  }}
                >
                  Report
                </CNavLink>
              </CNavItem> */}
              <CNavItem>
                {/* {requireDisablePSOW == true ? (
                  <CNavLink
                    className={
                      active == 8 ? "projecttabs-active" : "projecttabs"
                    }
                    onClick={() => {
                      setActive(8);
                      dispatch({
                        type: "set",
                        module: "project-updates",
                      });
                      // dispatch(currentModuleAction("project-scope-of-work"));
                    }}
                    style={{ fontSize: "12px" }}
                  >
                    Notes
                  </CNavLink>
                ) : (
                  <CNavLink
                    className={
                      active == 8
                        ? "projecttabs-active"
                        : "projecttabs-desabled"
                    }
                    style={{ fontSize: "12px" }}
                    disabled
                  >
                    Notes
                  </CNavLink>
                )} */}

                <CNavLink
                  // className={active == 8 ? "projecttabs-active" : "projecttabs"}
                  className={`projecttabs${!tabStatus?.notes ? '-desabled' : active == 8 ? '-active' : ''} border-tabs-radios`}
                  disabled={!tabStatus?.notes}
                  onClick={() => {
                    setActive(8);
                    dispatch({ type: 'set', module: 'project-updates' });
                  }}
                >
                  Updates
                </CNavLink>
              </CNavItem>

              {active == 2 ? (
                <CCol
                  xs="12"
                  md="6"
                  lg="6"
                  xl="1"
                  className="button-mobile-margin"
                  // style={{ marginLeft: "50px" }}
                >
                  <CRow className="float-right">
                    <CCol className="float-right" xs="6" sm="6" md="6" lg="6">
                      {/* <CButton
                        color="secondary_base"
                        size="sm"
                        block
                        variant="outline"
                        className="button-size-budget"
                      >
                        Edit
                      </CButton> */}
                    </CCol>
                    <CCol className="float-right" xs="5" sm="5" md="5" lg="5">
                      <CDropdown>
                        <CDropdownToggle
                          color="secondary_base"
                          size="sm"
                          block
                          // variant="outline"
                          className="button-size-budget"
                        >
                          {dropdownvalue} View
                        </CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem onClick={() => setDroupDownValue('Summary')}>Summary</CDropdownItem>
                          <CDropdownItem onClick={() => setDroupDownValue('Detail')}>Detail</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </CCol>
                  </CRow>
                </CCol>
              ) : (
                ''
              )}
            </CNav>
            <CTabContent className="project-border" fade={false}>
              <CTabPane>{active == 1 && <ProjectOverview project_id={props.ProjectId} validate={disableEstimate} refreshTabStatus={getProjectTabStatus} getTabWarnings={getTabWarnings} />}</CTabPane>
              <CTabPane>
                {dropdownvalue == 'Detail' && active == 2 && <ProjectBudgetDetail project_id={props.ProjectId} project_type_id={props.ProjectType} estimate_status={props.Status} refreshTabStatus={getProjectTabStatus} getTabWarnings={getTabWarnings} />}

                {dropdownvalue != 'Detail' && active == 2 && <ProjectBudgetCost project_id={props.ProjectId} />}
              </CTabPane>
              <CTabPane>
                {active == 3 && props?.ProjectId && <ProjectTask id={props?.ProjectId} type={props?.ProjectType} start={props?.Start} sowId={sowId} phaseId={phaseId} end={props?.End} validate={disablePSOW} refreshTabStatus={getProjectTabStatus} />}
                {active == 3 && !props?.ProjectId && <ProjectTask id={project_id} type={type} start={start} end={end} validate={disablePSOW} refreshTabStatus={getProjectTabStatus} />}
              </CTabPane>

              <CTabPane>{active == 4 && <Materials project_id={props.ProjectId} project_name={props.ProjectName} />}</CTabPane>
              <CTabPane>{active == 5 && <Equipment id={props.ProjectId} project_start={props.Start} project_end={props.End} project_name={props.ProjectName} />}</CTabPane>
              <CTabPane>{active == 6 && <Team id={props.ProjectId} />}</CTabPane>

              <TabPane>
                {/* {active == 7 && <Report id={props.ProjectId} />} */}
                {active == 7 && <Report id={props.ProjectId} type={props.ProjectType} activeTab={reportActiveTab} historicalReportCount={historicalReportCount} getTabWarnings={getTabWarnings} />}
              </TabPane>
              <CTabPane>{active == 8 && <Updates id={props.ProjectId} />}</CTabPane>
            </CTabContent>
          </CTabs>
        </CCol>
      </CRow>
    </>
  );
};

export default ProjectDetails;
