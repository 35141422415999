import $axios from 'src/utils/axios';

export const getFeatures = () => {
  return $axios
    .get('account/feature-access')
    .then((response) => {
      const data = response.data.response[0];
      return Object.keys(data).map((key) => {
        return { id: parseInt(key), name: data[key] };
      });
    })
    .catch((error) => {
      console.log('error', error);
      return [];
    });
};
