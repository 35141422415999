import $axios from 'src/utils/axios';

export const ProjectPosition = (project_id) => {
  return $axios.post('manpower/project/labor/list/new', { project_id: project_id });
};

export const ProjectManpowerPerPosition = (position_id) => {
  return $axios.post('manpower/project/labor/list/dropdown/new', { position_id: position_id });
};

export const SaveManpower = (data = {}) => {
  return $axios.post('manpower/save/new', data);
};

export const ExistingRecords = (project_id) => {
  return $axios.post('/manpower/project/labor/list/team/new', {project_id: project_id})
}
