import React, { useState, useEffect } from 'react';
import { CButton, CCol, CRow, CInput, CLabel, CSelect, CFormGroup, CModal, CModalHeader, CModalTitle, CModalFooter, CModalBody, CTextarea } from '@coreui/react';

import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FaArchive, FaPen, FaTimes, FaUndoAlt } from 'react-icons/fa';

import { getReport, saveDilyReport, getDetails, editReport, changeStatus } from 'src/request/Reports';
let user_id = parseInt(localStorage.getItem('user_id'));
const DailyReports = (props) => {
  const [daily, setDaily] = useState([]);
  const [descReq, setDescReq] = useState(false);
  const [hourReq, setHourReq] = useState(false);
  const [actReq, setActReq] = useState(false);
  const [cond, setCond] = useState('Add');
  const [reportLogId, setReportLogId] = useState(-1);
  const [status, setStatus] = useState(0);

  const [progressReport, setProgressReport] = useState([
    {
      report_id: '',
      activity: '',
      hours: '',
      work: '',
      report_log_id: reportLogId,
    },
  ]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    getDaily(0);
  }, []);

  const getDaily = (status) => {
    getReport(props.id, 'daily', status).then((res) => {
      if (res.data.status == 1) {
        setDaily(res.data.response);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const SaveDaily = () => {
    saveDilyReport(progressReport, 'daily', props.id, user_id).then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });
        setModal(!modal);
        getDaily(status);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const getReportById = (report_log_id) => {
    setReportLogId(report_log_id);
    getDetails('daily', report_log_id).then((res) => {
      if (res.data.status == 1) {
        console.log(res);
        setProgressReport(res.data.response);
        setActReq(true);
        setHourReq(true);
        setDescReq(true);
        setModal(!modal);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const updateDaily = () => {
    editReport(progressReport, 'daily').then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Your work has been updated',
          showConfirmButton: false,
          timer: 1500,
        });
        getDaily(status);
        setModal(!modal);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const changeStatusReport = (report_log_id, method) => {
    Swal.fire({
      icon: 'question',
      title: "<span style='color:#B42A19;'>Are you sure</span>",
      html: "<p style='font-weight:bold'>If you proceed,you will archived all your <br> data.Are you sure you want to <br> achive this report?</p>",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#39f',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(report_log_id, method).then((res) => {
          if (res.data.status == 1) {
            Swal.fire({
              // position: 'top-end',
              icon: 'success',
              title: 'Your work has been archived',
              showConfirmButton: false,
              timer: 1500,
            });
            getDaily(status);
          } else {
            Swal.fire({
              icon: 'error',
              title: res.data.response,
              text: res.data.description,
              confirmButtonColor: '#23303D',
              // footer: '<a href="">Why do I have this issue?</a>',
            });
          }
        });
      }
    });
  };

  const AddNew = () => {
    setModal(!modal);
    setProgressReport([
      {
        report_id: '',
        activity: '',
        hours: '',
        work: '',
        report_log_id: reportLogId,
      },
    ]);
  };

  const add = () => {
    let arr = [...progressReport];
    arr.push({
      report_id: -1,
      activity: '',
      hours: '',
      work: '',
      report_log_id: reportLogId,
    });
    setActReq(false);
    setHourReq(false);
    setDescReq(false);
    setProgressReport(arr);
  };

  const activityChange = (e, Index) => {
    let arr = [...progressReport];

    arr[Index].activity = e.target.value;
    setActReq(true);
    setProgressReport(arr);
  };

  const hoursChange = (e, Index) => {
    let arr = [...progressReport];

    arr[Index].hours = e.target.value;
    setHourReq(true);
    setProgressReport(arr);
  };
  const descriptionChange = (e, Index) => {
    let arr = [...progressReport];

    arr[Index].work = e.target.value;
    setDescReq(true);
    setProgressReport(arr);
  };

  const remove = (Index) => {
    let arr = [...progressReport];
    arr.splice(Index, 1);
    setActReq(true);
    setHourReq(true);
    setDescReq(true);
    setProgressReport(arr);
  };

  const dataTable = daily.map((data, Index) => {
    return (
      <tr key={Index} style={{ textAlign: 'left' }}>
        <td>
          <CButton color="link" style={{ color: '#23303d' }} onClick={() => getReportById(data.report_log_id, setCond('View'))}>
            {data.date}
          </CButton>
        </td>
        <td>{data.submitted}</td>
        <td>{data.last_modified}</td>
        <td>
          {status == 0 && <FaPen onClick={() => getReportById(data.report_log_id, setCond('Edit'))} />}

          {status == 0 ? <FaArchive style={{ marginLeft: '5px' }} onClick={() => changeStatusReport(data.report_log_id, 'DELETE')} /> : <FaUndoAlt style={{ marginLeft: '5px' }} onClick={() => changeStatusReport(data.report_log_id, 'PUT')} />}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  });

  const AddFormTable = progressReport.map((data, Index) => {
    return (
      <tr key={Index}>
        <td>
          <CInput type="text" value={data.activity} onChange={(e) => activityChange(e, Index)} disabled={cond == 'View'} />
        </td>
        <td>
          <CInput type="number" value={data.hours} onChange={(e) => hoursChange(e, Index)} disabled={cond == 'View'} />
        </td>
        <td>
          <CTextarea value={data.work} onChange={(e) => descriptionChange(e, Index)} disabled={cond == 'View'}></CTextarea>
        </td>
        {cond != 'View' && (
          <td>
            <FaTimes onClick={() => remove(Index)} />
          </td>
        )}
      </tr>
    );
  });

  return (
    <>
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="9" style={{ marginTop: '20px' }}>
          <CCol xs="12" sm="12" md="12" lg="12" xl="12">
            <CFormGroup row style={{ marginLeft: '2px' }}>
              <CLabel>Filter View</CLabel>

              <CCol xs="12" sm="12" md="3" lg="3" className="margin-mobile float-left">
                <CSelect name="select" id="select" size="sm" onChange={(e) => getDaily(e.target.value, setStatus(e.target.value))} value={status}>
                  <option disabled value="">
                    Status
                  </option>

                  <option value={0}>Active</option>
                  <option value={1}>Archived</option>
                </CSelect>
              </CCol>
              <CCol lg="6"></CCol>
              {/* Hidden By Ash -- Not functional yet in this branch */}
              {/* <CCol xs="12" sm="12" md="3" lg="3" xl="2" style={{ marginLeft: '15px' }}>
                <CButton color="aimhi-textlight-neutral" variant="outline" size="sm" block onClick={() => {}}>
                  Download
                </CButton>
              </CCol> */}
              {/* Hidden By Ash -- Not functional yet in this branch */}
            </CFormGroup>
          </CCol>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Date</th>
                <th>Submitted by</th>
                <th>Last Modified</th>
                <th>Action</th>
                <th></th>
                <th></th>
                <th>
                  {status == 0 && (
                    <CButton size="sm" color="aimhi-primary" block onClick={() => AddNew(setCond('Add'))}>
                      Add New
                    </CButton>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {dataTable}
              {daily.length == 0 && (
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  No Data Available
                </td>
              )}
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CModal show={modal} onClose={() => setModal(!modal)} style={{ borderRadius: '5px' }} size="lg">
        <CModalHeader closeButton>
          <CModalTitle>Daily Progress Report</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol md="12" sm="12" lg="12" xl="12">
            <table className="table">
              <thead className="border-top-hidden">
                <tr>
                  <th>Scope of Work</th>
                  <th>Hours</th>
                  <th>Description of Work</th>
                  {cond != 'View' && <th>Action</th>}
                </tr>
              </thead>
              <tbody>{AddFormTable}</tbody>
            </table>
            {cond != 'View' && (
              <CButton onClick={add} disabled={!descReq || !hourReq || !actReq}>
                <u>Add More</u>
              </CButton>
            )}
          </CCol>
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            {cond == 'View' && ''}
            {cond == 'Edit' && (
              <CButton block color="aimhi-textlight-neutral" size="sm" variant="outline" onClick={updateDaily}>
                Update
              </CButton>
            )}

            {cond == 'Add' && (
              <CButton block color="aimhi-textlight-neutral" size="sm" variant="outline" disabled={!descReq || !hourReq || !actReq} onClick={SaveDaily}>
                Submit
              </CButton>
            )}
          </CCol>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default DailyReports;
