import { CButton } from '@coreui/react';
import ai2 from '../../assets/img/ai_2.png';

const AIButton = ({ className, toggleCalculator, scopeIndex, Index, title }) => {
  console.log('AIButton scopeIndex', scopeIndex);
  console.log('AIButton Index', Index);
  return (
    <CButton
      className="ai-button"
      onClick={() => {
        toggleCalculator({ scopeIndex, Index, title });
      }}
      style={{ width: '36px', height: '36px', padding: 0 }}
    >
      <img className={className} src={ai2} style={{ width: '36px', height: '36px' }} />
    </CButton>
  );
};

export default AIButton;
