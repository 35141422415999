import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CButton, CCard, CCardBody, CCol, CDataTable, CInput, CPagination, CRow, CLabel, CSpinner, CTabs, CNav, CNavItem, CNavLink, CBadge, CModal, CModalBody, CModalHeader, CModalFooter, CFormGroup } from '@coreui/react';
import { GetTransferMaterialList } from 'src/request/OnsiteRecords';
import { useReactToPrint } from 'react-to-print';

import PrintTransferMaterialHistory from './PrintTransferMaterialHistory';

const TransferMaterialHistory = ({ project_id, project_name }) => {
  let permission = useSelector((state) => state.permission);
  const historyFields = [
    {
      key: 'id',
      label: 'Transaction ID',
      _style: {},
    },
    {
      key: 'transaction_type',
      label: 'Transaction Type',
      _style: {},
    },
    {
      key: 'receiver_name',
      label: 'Destination',
      _style: {},
    },
    {
      key: 'date_created',
      label: 'Date',
      _style: {},
    },
    {
      key: 'final_status',
      label: 'Status',
      _style: {},
    },
    {
      key: 'created_by',
      label: 'Created By',
      _style: {},
    },
    {
      key: 'received_by',
      label: 'Received By',
      _style: {},
    },
    {
      key: 'action',
      label: 'Action',
      _style: {},
    },
  ];

  const [historyData, setHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordDetails, setRecordDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPrintView, setPrintView] = useState(false);
  const [printData, setPrintData] = useState([]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    fetchData(currentPage, 5, searchQuery, project_id, null, null);
  }, [project_id, currentPage, searchQuery]);

  const fetchData = (page, pageSize, search = null, project_id, received = null, debt = null) => {
    GetTransferMaterialList(page, pageSize, search, project_id, received, debt)
      .then((response) => {
        console.log('history list response', response.data.data);
        setHistoryData(response.data.data);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleSearchChange = debounce((e) => {
    setSearchQuery(e.target.value);
  }, 300);

  const handleOnPageChange = (e) => {
    setCurrentPage(e);
  };

  const handleIdClick = (recordDetails) => {
    console.log('recordDetails', recordDetails);
    setIsModalOpen(true);
    setRecordDetails(recordDetails);
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return <CLabel style={{ color: '#FA7503', margin: 0 }}>Pending</CLabel>;
      case 1:
        return <CLabel style={{ color: '#3A7D21', margin: 0 }}>Approved for delivery</CLabel>;
      case 2:
        return <CLabel style={{ color: '#B42A19', margin: 0 }}>Rejected</CLabel>;
      case 3:
        return <CLabel style={{ color: '#3A7D21', margin: 0 }}>Received</CLabel>;
      default:
        return 'Pending';
    }
  };

  const handlePrintHistory = (item) => {
    console.log('Print History', item);
    setPrintView(true);
    setPrintData(item);
  };

  const handleRefresh = () => {
    fetchData(currentPage, 5, searchQuery, project_id, null, null);
  };
  return (
    <>
      <CModal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CModalHeader>Transfer Material Details</CModalHeader>
        <CModalBody>
          <CRow>
            <CCol style={{ marginLeft: '15px' }}>
              <CLabel>
                Transaction ID: <strong>{recordDetails.id}</strong>
              </CLabel>
            </CCol>
            <CCol style={{ textAlign: 'right', marginRight: '15px' }}>
              <CLabel>
                Date:
                <strong style={{ marginLeft: '8px' }}>
                  {new Date(recordDetails.date_created).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}
                </strong>
              </CLabel>
            </CCol>
          </CRow>

          <CCol>
            <CLabel>
              Transaction Type: <strong>{recordDetails.initial_status}</strong>
            </CLabel>
          </CCol>
          <CCol>
            <CLabel>
              Destination: <strong>{recordDetails.receiver_name}</strong>
            </CLabel>
          </CCol>
          <CCol>
            <CLabel>
              Created by: <strong>{recordDetails.created_by}</strong>
            </CLabel>
          </CCol>
          <CCol>
            <CLabel>Status: {getStatus(recordDetails.final_status)}</CLabel>
          </CCol>
          {recordDetails.final_status === 1 ? (
            <CCol>
              <CLabel>
                Approved by: <strong>{recordDetails.approved_for_delivery_by_name}</strong>
              </CLabel>
            </CCol>
          ) : recordDetails.final_status === 2 ? (
            <CCol>
              <CLabel>
                Rejected by: <strong>{recordDetails.rejected_for_delivery_by_name}</strong>
              </CLabel>
            </CCol>
          ) : recordDetails.final_status === 3 ? (
            <CCol>
              <CLabel>
                Received by: <strong>{recordDetails.approved_on_site_received_by_name}</strong>
              </CLabel>
            </CCol>
          ) : (
            ''
          )}
          <CCol>
            <CLabel>Materials to be transferred:</CLabel>
          </CCol>

          <CCol>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #DEDEE1', padding: '8px' }}>Material Name</th>
                  <th style={{ border: '1px solid #DEDEE1', padding: '8px' }}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {recordDetails?.record_details?.map((record, index) => (
                  <tr key={index}>
                    <td style={{ border: '1px solid #DEDEE1', padding: '8px' }}>{record.material_name}</td>
                    <td style={{ border: '1px solid #DEDEE1', padding: '8px' }}>{record.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CCol>
        </CModalBody>
      </CModal>

      {!isPrintView ? (
        <>
          <CDataTable
            items={historyData}
            fields={historyFields}
            itemsPerPage={10}
            responsive
            scopedSlots={{
              id: (item) => (
                <td>
                  <button style={{ color: '#3B74BE', textDecoration: 'underline', background: 'none', border: 'none' }} onClick={() => handleIdClick(item)}>
                    {item.id}
                  </button>
                </td>
              ),
              final_status: (item) => (
                <td>
                  {item.final_status === 0 ? (
                    <CBadge color="aimhi-orange" shape="pill" style={{ padding: '8px' }}>
                      Pending
                    </CBadge>
                  ) : item.final_status === 1 ? (
                    <CBadge color="aimhi-success" shape="pill" style={{ padding: '8px' }}>
                      Approved for delivery
                    </CBadge>
                  ) : item.final_status === 2 ? (
                    <CBadge color="aimhi-danger" shape="pill" style={{ padding: '8px' }}>
                      Rejected
                    </CBadge>
                  ) : (
                    <CBadge color="aimhi-success" shape="pill" style={{ padding: '8px' }}>
                      Received
                    </CBadge>
                  )}
                </td>
              ),
              date_created: (item) => (
                <td>
                  {new Date(item.date_created).toLocaleDateString('en-US', {
                    year: '2-digit',
                    month: '2-digit',
                    day: 'numeric',
                  })}
                </td>
              ),
              received_by: (item) => <td>{item.approved_on_site_received_by_name || 'N/A'}</td>,
              transaction_type: (item) => <td>{item.transaction_type === 'transfer-material' ? 'Transfer Material' : 'Return Material Debt'}</td>,
              action: (item) => (
                <td>
                  {(permission['create'][8] === 1 || permission['update'][8] === 1) && (
                    <CButton color="aimhi-textlight-neutral" onClick={() => handlePrintHistory(item)}>
                      Print
                    </CButton>
                  )}
                </td>
              ),
            }}
            overTableSlot={
              <CRow xs="12" md="6" lg="6" xl="6">
                <CCol xs="12" md="12" lg="12" xl="12" style={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
                  <CLabel style={{ marginRight: '12px', marginLeft: '12px' }}>Filter</CLabel>
                  <CInput type="text" placeholder="Search" onChange={handleSearchChange} style={{ flex: '1' }} />
                  <div style={{ flexGrow: 1 }}></div> {/* Spacer to push the button to the right */}
                  <CButton color="aimhi-textlight-neutral" onClick={handleRefresh}>
                    Refresh
                  </CButton>
                </CCol>
              </CRow>
            }
          />
          {totalPages > 1 && (
            <CPagination
              activePage={currentPage}
              pages={totalPages}
              onActivePageChange={(i) => {
                if (i === 0) {
                  i = 1;
                }
                handleOnPageChange(i);
              }}
            ></CPagination>
          )}
        </>
      ) : (
        // <CCol style={{ padding: '0px' }}>
        //   <CRow>
        //     <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
        //       <CRow>
        //         <CCol xs="12" sm="12" md="12" lg="8"></CCol>
        //         <CCol xs="12" sm="12" md="12" lg="2">
        //           {/* {permission['update'][0] == 1 && ( */}
        //           <CButton variant="outline" color="aimhi-textlight-neutral" size="sm" block onClick={() => handlePrint()}>
        //             <span>Print request</span>
        //           </CButton>
        //           {/* )} */}
        //         </CCol>
        //         <CCol xs="12" sm="12" md="12" lg="2">
        //           {/* {permission['update'][0] == 1 && ( */}
        //           <CButton variant="outline" color="aimhi-textlight-neutral" size="sm" block onClick={() => setPrintView(false)}>
        //             <span>Back</span>
        //           </CButton>
        //           {/* )} */}
        //         </CCol>
        //       </CRow>
        //     </CCol>
        //     <CCol xs="12" sm="12" md="12" lg="12">
        //       <PrintTransferMaterialHistory ref={componentRef} transferMaterialRequest={printData} project_name={project_name} project_id={project_id} />
        //     </CCol>
        //   </CRow>
        // </CCol>
        <CCol>
          <CRow>
            <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
              <CRow className="justify-content-between" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <CCol xs="12" sm="12" md="12" lg="2" className="order-lg-1 order-2">
                  {/* {permission['update'][0] == 1 && ( */}
                  <CButton variant="outline" color="aimhi-textlight-neutral" size="sm" onClick={() => setPrintView(false)}>
                    <span>Back</span>
                  </CButton>
                  {/* )} */}
                </CCol>
                <CCol xs="12" sm="12" md="12" lg="2" className="order-lg-2 order-1">
                  {/* {permission['update'][0] == 1 && ( */}
                  <CButton color="aimhi-textlight-neutral" size="sm" block onClick={() => handlePrint()}>
                    <span>Print request</span>
                  </CButton>
                  {/* )} */}
                </CCol>
              </CRow>
            </CCol>
            <CCol xs="12" sm="12" md="12" lg="12">
              <PrintTransferMaterialHistory ref={componentRef} transferMaterialRequest={printData} project_name={project_name} project_id={project_id} />
            </CCol>
          </CRow>
        </CCol>
      )}
    </>
  );
};

export default TransferMaterialHistory;
