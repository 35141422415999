const ceilToDecimalPlaces = (num, decimalPlaces) => {
  const factor = 10 ** decimalPlaces;
  return Math.ceil(num * factor) / factor;
};

const ceilToDecimalPlacesPayload = (num, decimalPlaces) => {
  const applyRounding = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(item => (typeof item === 'number' ? ceilToDecimalPlaces(item, decimalPlaces) : applyRounding(item)));
    } else if (typeof obj === 'object' && obj != null) {
      return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, typeof value === 'number' ? ceilToDecimalPlaces(value, decimalPlaces) : applyRounding(value)]));
    }
    return obj;
  };
  return applyRounding(JSON.parse(JSON.stringify(num)));
};
export { ceilToDecimalPlaces, ceilToDecimalPlacesPayload };
