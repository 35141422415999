import store from '../store'
import { action_newNotification } from '../redux/actions/notifications'

class Notifications {
  constructor() {
    // this.socket = store.getState().socket
    // this.initialize_receivers()
  }

  initialize_receivers() {
    // this.socket.on('new_notifications', this.handle_notifications)
  }

  request_material_notifications(company_id, project_id, limit=5) {
    // this.socket.emit('request_material_notifications', { company_id, project_id, limit })
  }

  request_sow_notifications(company_id, project_id, limit=5) {
    // this.socket.emit('request_sow_notifications', { company_id, project_id, limit })
  }

  request_equipment_notifications(company_id, project_id, limit=5) {

  }

  handle_notifications(notifications) {

    // alert('length : ' + notifications.list.length)
    console.log('HERE@NOTIFICATIONS : ' + JSON.stringify(notifications) )

    store.dispatch(action_newNotification(notifications))
  }
}

export default Notifications
