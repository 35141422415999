import React, { lazy, useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CLabel,
  CSelect,
  CFormGroup,
  CProgress,
  CProgressBar,
} from "@coreui/react";
import IP from "../../StoreGlobal/IP";
import Project from "./Projects";
import AddProject from "./AddProject";
import "./Projects.css";
import ProjectDetails from "../projectdetails/ProjectDetails";
// import ProjectOverview from "../projectdetails/ProjectOverview";
import { ProjectList } from "src/request/Projects";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const Index = (props) => {
  const history = useHistory();
  const location = useLocation();
  const socket = useSelector((state) => state.socket);
  let accessToken = localStorage.getItem("token");
  let user_id = parseInt(localStorage.getItem("user_id"));
  const Swal = require("sweetalert2");
  const [pages, setPages] = useState("Projects");
  const [projectId, setProjectId] = useState(0);
  const [projectStart, setProjectStart] = useState("");
  const [projectEnd, setProjectEnd] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [estimateStatus, setestimateStatus] = useState(0);
  const [identifier, setIdentifier] = useState("");

  const handleCallback = (childData) => {
    setPages(childData);
  };

  useEffect(() => {
    if (
      localStorage.getItem("company_id") == null ||
      localStorage.getItem("user_id") == null ||
      localStorage.getItem("token") == null
    ) {
      LogoutReload();
    }

    getAllProject();
  }, []);

  const LogoutReload = () => {
    // logout in websocket
    Swal.fire({
      title: "Cache: Error!",
      text: "Please re-login",
      icon: "warning",
      confirmButtonColor: "#3085d6",

      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        // socket.emit("logout", { token: localStorage.getItem("token") });

        localStorage.removeItem("permission");
        localStorage.removeItem("company_id");
        localStorage.removeItem("admin");
        localStorage.removeItem("user_id");
        localStorage.clear();
        history.push({
          pathname: "/",
        });
        window.location.reload(true);
      }
    });
  };

  const getAllProject = (filter = {}, val) => {
    let projectFilter = {};

    ProjectList(projectFilter, "").then(function (res) {
      if (res.data.status == 1) {
        if (res.data.response.length == 0) {
          setPages("AddProject");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: "#23303D",
        });
      }
    });
  };

  const viewOverview = (data, page) => {
    let start = new Date(data.start_date);
    let month = start.getMonth() + 1;
    let days = start.getDate();
    let year = start.getFullYear();
    let startDate = month + "/" + days + "/" + year;

    let duration = parseInt(data.duration);
    let add_start = new Date(data.start_date);
    add_start.setDate(add_start.getDate() + duration);

    let e_month = add_start.getMonth() + 1;
    let e_days = add_start.getDate();
    let e_year = add_start.getFullYear();
    let EndDate = e_month + "/" + e_days + "/" + e_year;

    setProjectId(data.id);
    setProjectStart(startDate);
    setProjectEnd(EndDate);
    setProjectType(parseInt(data.project_type));
    setProjectName(data.Name);
    setPages(page);
    setestimateStatus(data.estimate_status);
    setIdentifier(data.identifier);

    console.log(data.identifier, "fn_saveOverview");
  };

  const OverviewProjects = (data, page) => {
    // console.log('OVERVIEWPROJECT', data)
    setProjectId(data.id);
    setProjectStart(data.start);
    setProjectEnd(data.end);
    setProjectType(data.type);
    setProjectName(data.name);
    setPages(page);
    setestimateStatus(data.estimate_status);
    setIdentifier(data.identifier);

    console.log(data.identifier, "fn_overviewProjects");
  };

  return (
    <>
      {pages == "Projects" && (
        <Project
          ParentCallback={handleCallback}
          clickOverview={OverviewProjects}
        />
      )}

      {pages == "AddProject" && <AddProject Overview={viewOverview} />}

      {pages == "Overview" && (
        <ProjectDetails
          ProjectId={projectId}
          ProjectType={projectType}
          ProjectName={projectName}
          Start={projectStart}
          End={projectEnd}
          Status={estimateStatus}
          Identifier={identifier}
        />
      )}
    </>
  );
};

export default Index;
