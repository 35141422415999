import { createStore } from 'redux';
// import { connectSocket } from 'src/redux/actions/socket'
import { storePermission, loadPermission } from 'src/redux/actions/permission';
import { storeToken, loadToken } from 'src/redux/actions/token';

const initialState = {
  sidebarShow: 'responsive',
  // socket: connectSocket(),
  module: null,
  permission: null,
  token: null,
  userDetails: {},
  materialNotifications: null,
  equipmentNotifications: null,
  manpowerNotifications: null,
  sowNotifications: null,
  allowedRoutes: [],
  features: [],
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

// store state in local storage if needed
const storeState = () => {
  storeToken();
  storePermission();
};

// load state defaults
const loadState = () => {
  return {
    ...initialState,
    ...loadPermission(),
    ...loadToken(),
  };
};

const store = createStore(changeState, loadState());

store.subscribe(() => {
  storeState();
});

export default store;
