import { Alert } from '@coreui/coreui';
import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const EquipmentRequestHistoryDetailsEdit = (data) => {
  return $axios.put(`equipment/request-history/details/edit`, {
    ...data,
  });
};

export const EquipmentRequestHistoryEdit = (companyId, projectId, data) => {
  return $axios.put(`equipment/request-history/${companyId}/${projectId}`);
};

export const GetTotalFuelOrder = (projectId) => {
  return $axios.get(`equipment/fuel/total-order/${projectId}`);
};

// Equipment Module

export const GetEquipmentMasterList = (company_id, page = 1, per_page = 10, search = null, archived = null) => {
  return $axios.get(`equipment/master/list`, { params: { company_id, page, per_page, search, archived } });
};

export const getEquipmentCategory = (page = 1, limit = 10, search_category = '') => {
  const encodedSearchCategory = encodeURIComponent(search_category);
  return $axios.get(`equipment/category/list?page=${page}&limit=${limit}&search_category=${encodedSearchCategory}`);
};

export const archiveAllEquipment = () => {
  const data = { type_id: 3 };
  return $axios.post(`project/archive/bulk`, data);
};

export const GetByProjectEquipment = (page = 1, page_size = 10) => {
  return $axios.get(`equipment/master/list/details?page=${page}&page_size=${page_size}`);
};

export const postEquipmentUpload = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return $axios.post('/equipment/upload/new', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getSampleFormat = async () => {
  const Swal = require('sweetalert2');
  try {
    const response = await $axios.post(`equipment/download/format/new`, {}, { responseType: 'blob' });
    const url_2 = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url_2;
    link.setAttribute('download', `Equipment_Format.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading report:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Something went wrong...',
      confirmButtonColor: '#23303D',
    });
  }
};

export const GetEquipmentTypes = () => {
  return $axios.get('/equipment/types');
};
