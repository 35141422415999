import { Alert } from '@coreui/coreui';
import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const RequestHistoryEdit = (data) => {
  return $axios.put(`material/request-history/details/edit`, {
    ...data,
  });
};

// material module requests
//for testing page = -1, limit = -1, dropdown = 0
export const getMaterialList = ({page = 1, limit = 10, dropdown = 0, search_term = '', sortBy = '', order = 'asc'} = {}) => {
  return $axios.get(`material/list?archived=${0}&page=${page}&limit=${limit}&dropdown=${dropdown}&search=${search_term}&sort_by=${sortBy}&order=${order}`);
};

export const getMaterialTypes = ({page = 1, limit = 10, dropdown = 0, search_term ='' } = {}) => {
  return $axios.get(`material/list/type?archived=${0}&page=${page}&limit=${limit}&dropdown=${dropdown}&search=${search_term}`);
};
export const addMaterial = (data) => {
  console.log('data add material', data);
  return $axios.post(`material/add`, data);
};
export const editMaterial = (data) => {
  return $axios.put(`material/edit`, data);
};

export const archiveMaterial = (cm_id) => {
  return $axios.delete(`material/archive/${cm_id}`);
};

export const restoreMaterial = (cm_id) => {
  return $axios.put(`material/archive/${cm_id}`);
};
export const postMaterialUpload = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return $axios.post('/material/upload/new', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const materialUpload = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await $axios.post('/material/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 1000000
  });

  return response.data;
};
export const getArchiveMaterial = (page = 1, limit = 10, search_term = '') => {
  return $axios.get(`material/list?archived=${1}&page=${page}&limit=${limit}&search=${search_term}`);
};
export const addMaterialType = (data) => {
  return $axios.post(`material/add/type`, data);
};
export const editMaterialType = (data) => {
  return $axios.put(`material/edit/type`, data);
};
export const archiveMaterialType = (cmt_id) => {
  return $axios.delete(`material/archive/type/${cmt_id}`);
};
export const getSupplierList = (material_id) => {
  return $axios.get(`material/list/supplier?material=${material_id}`);
};

export const getMaterialPriceHistory = (material_id, supplier_id) => {
  return $axios.get(`material/price-history?material=${material_id}&supplier=${supplier_id}`);
};
export const getSampleFormat = async () => {
  const Swal = require('sweetalert2');
  try {
    const response = await $axios.get(`material/sample-file`, { responseType: 'blob' });
    const url_2 = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url_2;
    link.setAttribute('download', `Material_Format.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading report:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Something went wrong...',
      confirmButtonColor: '#23303D',
    });
  }
};

export const archiveAllMaterials = () => {
  const data = { type_id: 1 }
  return $axios.post(`project/archive/bulk`, data);
}

