import axios from 'axios';
import IP from '../StoreGlobal/IP';

const accessToken = localStorage.getItem('token');

const $axios = axios.create({
  baseURL: IP,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

export default $axios;
