export const CITIES = [
    {
      "city_code": "012801",
      "city_name": "Adams",
      "province_code": "0128",
      "psgc_code": "012801000",
      "region_desc": "01"
    },
    {
      "city_code": "012802",
      "city_name": "Bacarra",
      "province_code": "0128",
      "psgc_code": "012802000",
      "region_desc": "01"
    },
    {
      "city_code": "012803",
      "city_name": "Badoc",
      "province_code": "0128",
      "psgc_code": "012803000",
      "region_desc": "01"
    },
    {
      "city_code": "012804",
      "city_name": "Bangui",
      "province_code": "0128",
      "psgc_code": "012804000",
      "region_desc": "01"
    },
    {
      "city_code": "012805",
      "city_name": "City Of Batac",
      "province_code": "0128",
      "psgc_code": "012805000",
      "region_desc": "01"
    },
    {
      "city_code": "012806",
      "city_name": "Burgos",
      "province_code": "0128",
      "psgc_code": "012806000",
      "region_desc": "01"
    },
    {
      "city_code": "012807",
      "city_name": "Carasi",
      "province_code": "0128",
      "psgc_code": "012807000",
      "region_desc": "01"
    },
    {
      "city_code": "012808",
      "city_name": "Currimao",
      "province_code": "0128",
      "psgc_code": "012808000",
      "region_desc": "01"
    },
    {
      "city_code": "012809",
      "city_name": "Dingras",
      "province_code": "0128",
      "psgc_code": "012809000",
      "region_desc": "01"
    },
    {
      "city_code": "012810",
      "city_name": "Dumalneg",
      "province_code": "0128",
      "psgc_code": "012810000",
      "region_desc": "01"
    },
    {
      "city_code": "012811",
      "city_name": "Banna (Espiritu)",
      "province_code": "0128",
      "psgc_code": "012811000",
      "region_desc": "01"
    },
    {
      "city_code": "012812",
      "city_name": "Laoag City (Capital)",
      "province_code": "0128",
      "psgc_code": "012812000",
      "region_desc": "01"
    },
    {
      "city_code": "012813",
      "city_name": "Marcos",
      "province_code": "0128",
      "psgc_code": "012813000",
      "region_desc": "01"
    },
    {
      "city_code": "012814",
      "city_name": "Nueva Era",
      "province_code": "0128",
      "psgc_code": "012814000",
      "region_desc": "01"
    },
    {
      "city_code": "012815",
      "city_name": "Pagudpud",
      "province_code": "0128",
      "psgc_code": "012815000",
      "region_desc": "01"
    },
    {
      "city_code": "012816",
      "city_name": "Paoay",
      "province_code": "0128",
      "psgc_code": "012816000",
      "region_desc": "01"
    },
    {
      "city_code": "012817",
      "city_name": "Pasuquin",
      "province_code": "0128",
      "psgc_code": "012817000",
      "region_desc": "01"
    },
    {
      "city_code": "012818",
      "city_name": "Piddig",
      "province_code": "0128",
      "psgc_code": "012818000",
      "region_desc": "01"
    },
    {
      "city_code": "012819",
      "city_name": "Pinili",
      "province_code": "0128",
      "psgc_code": "012819000",
      "region_desc": "01"
    },
    {
      "city_code": "012820",
      "city_name": "San Nicolas",
      "province_code": "0128",
      "psgc_code": "012820000",
      "region_desc": "01"
    },
    {
      "city_code": "012821",
      "city_name": "Sarrat",
      "province_code": "0128",
      "psgc_code": "012821000",
      "region_desc": "01"
    },
    {
      "city_code": "012822",
      "city_name": "Solsona",
      "province_code": "0128",
      "psgc_code": "012822000",
      "region_desc": "01"
    },
    {
      "city_code": "012823",
      "city_name": "Vintar",
      "province_code": "0128",
      "psgc_code": "012823000",
      "region_desc": "01"
    },
    {
      "city_code": "012901",
      "city_name": "Alilem",
      "province_code": "0129",
      "psgc_code": "012901000",
      "region_desc": "01"
    },
    {
      "city_code": "012902",
      "city_name": "Banayoyo",
      "province_code": "0129",
      "psgc_code": "012902000",
      "region_desc": "01"
    },
    {
      "city_code": "012903",
      "city_name": "Bantay",
      "province_code": "0129",
      "psgc_code": "012903000",
      "region_desc": "01"
    },
    {
      "city_code": "012904",
      "city_name": "Burgos",
      "province_code": "0129",
      "psgc_code": "012904000",
      "region_desc": "01"
    },
    {
      "city_code": "012905",
      "city_name": "Cabugao",
      "province_code": "0129",
      "psgc_code": "012905000",
      "region_desc": "01"
    },
    {
      "city_code": "012906",
      "city_name": "City Of Candon",
      "province_code": "0129",
      "psgc_code": "012906000",
      "region_desc": "01"
    },
    {
      "city_code": "012907",
      "city_name": "Caoayan",
      "province_code": "0129",
      "psgc_code": "012907000",
      "region_desc": "01"
    },
    {
      "city_code": "012908",
      "city_name": "Cervantes",
      "province_code": "0129",
      "psgc_code": "012908000",
      "region_desc": "01"
    },
    {
      "city_code": "012909",
      "city_name": "Galimuyod",
      "province_code": "0129",
      "psgc_code": "012909000",
      "region_desc": "01"
    },
    {
      "city_code": "012910",
      "city_name": "Gregorio Del Pilar (Concepcion)",
      "province_code": "0129",
      "psgc_code": "012910000",
      "region_desc": "01"
    },
    {
      "city_code": "012911",
      "city_name": "Lidlidda",
      "province_code": "0129",
      "psgc_code": "012911000",
      "region_desc": "01"
    },
    {
      "city_code": "012912",
      "city_name": "Magsingal",
      "province_code": "0129",
      "psgc_code": "012912000",
      "region_desc": "01"
    },
    {
      "city_code": "012913",
      "city_name": "Nagbukel",
      "province_code": "0129",
      "psgc_code": "012913000",
      "region_desc": "01"
    },
    {
      "city_code": "012914",
      "city_name": "Narvacan",
      "province_code": "0129",
      "psgc_code": "012914000",
      "region_desc": "01"
    },
    {
      "city_code": "012915",
      "city_name": "Quirino (Angkaki)",
      "province_code": "0129",
      "psgc_code": "012915000",
      "region_desc": "01"
    },
    {
      "city_code": "012916",
      "city_name": "Salcedo (Baugen)",
      "province_code": "0129",
      "psgc_code": "012916000",
      "region_desc": "01"
    },
    {
      "city_code": "012917",
      "city_name": "San Emilio",
      "province_code": "0129",
      "psgc_code": "012917000",
      "region_desc": "01"
    },
    {
      "city_code": "012918",
      "city_name": "San Esteban",
      "province_code": "0129",
      "psgc_code": "012918000",
      "region_desc": "01"
    },
    {
      "city_code": "012919",
      "city_name": "San Ildefonso",
      "province_code": "0129",
      "psgc_code": "012919000",
      "region_desc": "01"
    },
    {
      "city_code": "012920",
      "city_name": "San Juan (Lapog)",
      "province_code": "0129",
      "psgc_code": "012920000",
      "region_desc": "01"
    },
    {
      "city_code": "012921",
      "city_name": "San Vicente",
      "province_code": "0129",
      "psgc_code": "012921000",
      "region_desc": "01"
    },
    {
      "city_code": "012922",
      "city_name": "Santa",
      "province_code": "0129",
      "psgc_code": "012922000",
      "region_desc": "01"
    },
    {
      "city_code": "012923",
      "city_name": "Santa Catalina",
      "province_code": "0129",
      "psgc_code": "012923000",
      "region_desc": "01"
    },
    {
      "city_code": "012924",
      "city_name": "Santa Cruz",
      "province_code": "0129",
      "psgc_code": "012924000",
      "region_desc": "01"
    },
    {
      "city_code": "012925",
      "city_name": "Santa Lucia",
      "province_code": "0129",
      "psgc_code": "012925000",
      "region_desc": "01"
    },
    {
      "city_code": "012926",
      "city_name": "Santa Maria",
      "province_code": "0129",
      "psgc_code": "012926000",
      "region_desc": "01"
    },
    {
      "city_code": "012927",
      "city_name": "Santiago",
      "province_code": "0129",
      "psgc_code": "012927000",
      "region_desc": "01"
    },
    {
      "city_code": "012928",
      "city_name": "Santo Domingo",
      "province_code": "0129",
      "psgc_code": "012928000",
      "region_desc": "01"
    },
    {
      "city_code": "012929",
      "city_name": "Sigay",
      "province_code": "0129",
      "psgc_code": "012929000",
      "region_desc": "01"
    },
    {
      "city_code": "012930",
      "city_name": "Sinait",
      "province_code": "0129",
      "psgc_code": "012930000",
      "region_desc": "01"
    },
    {
      "city_code": "012931",
      "city_name": "Sugpon",
      "province_code": "0129",
      "psgc_code": "012931000",
      "region_desc": "01"
    },
    {
      "city_code": "012932",
      "city_name": "Suyo",
      "province_code": "0129",
      "psgc_code": "012932000",
      "region_desc": "01"
    },
    {
      "city_code": "012933",
      "city_name": "Tagudin",
      "province_code": "0129",
      "psgc_code": "012933000",
      "region_desc": "01"
    },
    {
      "city_code": "012934",
      "city_name": "City Of Vigan (Capital)",
      "province_code": "0129",
      "psgc_code": "012934000",
      "region_desc": "01"
    },
    {
      "city_code": "013301",
      "city_name": "Agoo",
      "province_code": "0133",
      "psgc_code": "013301000",
      "region_desc": "01"
    },
    {
      "city_code": "013302",
      "city_name": "Aringay",
      "province_code": "0133",
      "psgc_code": "013302000",
      "region_desc": "01"
    },
    {
      "city_code": "013303",
      "city_name": "Bacnotan",
      "province_code": "0133",
      "psgc_code": "013303000",
      "region_desc": "01"
    },
    {
      "city_code": "013304",
      "city_name": "Bagulin",
      "province_code": "0133",
      "psgc_code": "013304000",
      "region_desc": "01"
    },
    {
      "city_code": "013305",
      "city_name": "Balaoan",
      "province_code": "0133",
      "psgc_code": "013305000",
      "region_desc": "01"
    },
    {
      "city_code": "013306",
      "city_name": "Bangar",
      "province_code": "0133",
      "psgc_code": "013306000",
      "region_desc": "01"
    },
    {
      "city_code": "013307",
      "city_name": "Bauang",
      "province_code": "0133",
      "psgc_code": "013307000",
      "region_desc": "01"
    },
    {
      "city_code": "013308",
      "city_name": "Burgos",
      "province_code": "0133",
      "psgc_code": "013308000",
      "region_desc": "01"
    },
    {
      "city_code": "013309",
      "city_name": "Caba",
      "province_code": "0133",
      "psgc_code": "013309000",
      "region_desc": "01"
    },
    {
      "city_code": "013310",
      "city_name": "Luna",
      "province_code": "0133",
      "psgc_code": "013310000",
      "region_desc": "01"
    },
    {
      "city_code": "013311",
      "city_name": "Naguilian",
      "province_code": "0133",
      "psgc_code": "013311000",
      "region_desc": "01"
    },
    {
      "city_code": "013312",
      "city_name": "Pugo",
      "province_code": "0133",
      "psgc_code": "013312000",
      "region_desc": "01"
    },
    {
      "city_code": "013313",
      "city_name": "Rosario",
      "province_code": "0133",
      "psgc_code": "013313000",
      "region_desc": "01"
    },
    {
      "city_code": "013314",
      "city_name": "City Of San Fernando (Capital)",
      "province_code": "0133",
      "psgc_code": "013314000",
      "region_desc": "01"
    },
    {
      "city_code": "013315",
      "city_name": "San Gabriel",
      "province_code": "0133",
      "psgc_code": "013315000",
      "region_desc": "01"
    },
    {
      "city_code": "013316",
      "city_name": "San Juan",
      "province_code": "0133",
      "psgc_code": "013316000",
      "region_desc": "01"
    },
    {
      "city_code": "013317",
      "city_name": "Santo Tomas",
      "province_code": "0133",
      "psgc_code": "013317000",
      "region_desc": "01"
    },
    {
      "city_code": "013318",
      "city_name": "Santol",
      "province_code": "0133",
      "psgc_code": "013318000",
      "region_desc": "01"
    },
    {
      "city_code": "013319",
      "city_name": "Sudipen",
      "province_code": "0133",
      "psgc_code": "013319000",
      "region_desc": "01"
    },
    {
      "city_code": "013320",
      "city_name": "Tubao",
      "province_code": "0133",
      "psgc_code": "013320000",
      "region_desc": "01"
    },
    {
      "city_code": "015501",
      "city_name": "Agno",
      "province_code": "0155",
      "psgc_code": "015501000",
      "region_desc": "01"
    },
    {
      "city_code": "015502",
      "city_name": "Aguilar",
      "province_code": "0155",
      "psgc_code": "015502000",
      "region_desc": "01"
    },
    {
      "city_code": "015503",
      "city_name": "City Of Alaminos",
      "province_code": "0155",
      "psgc_code": "015503000",
      "region_desc": "01"
    },
    {
      "city_code": "015504",
      "city_name": "Alcala",
      "province_code": "0155",
      "psgc_code": "015504000",
      "region_desc": "01"
    },
    {
      "city_code": "015505",
      "city_name": "Anda",
      "province_code": "0155",
      "psgc_code": "015505000",
      "region_desc": "01"
    },
    {
      "city_code": "015506",
      "city_name": "Asingan",
      "province_code": "0155",
      "psgc_code": "015506000",
      "region_desc": "01"
    },
    {
      "city_code": "015507",
      "city_name": "Balungao",
      "province_code": "0155",
      "psgc_code": "015507000",
      "region_desc": "01"
    },
    {
      "city_code": "015508",
      "city_name": "Bani",
      "province_code": "0155",
      "psgc_code": "015508000",
      "region_desc": "01"
    },
    {
      "city_code": "015509",
      "city_name": "Basista",
      "province_code": "0155",
      "psgc_code": "015509000",
      "region_desc": "01"
    },
    {
      "city_code": "015510",
      "city_name": "Bautista",
      "province_code": "0155",
      "psgc_code": "015510000",
      "region_desc": "01"
    },
    {
      "city_code": "015511",
      "city_name": "Bayambang",
      "province_code": "0155",
      "psgc_code": "015511000",
      "region_desc": "01"
    },
    {
      "city_code": "015512",
      "city_name": "Binalonan",
      "province_code": "0155",
      "psgc_code": "015512000",
      "region_desc": "01"
    },
    {
      "city_code": "015513",
      "city_name": "Binmaley",
      "province_code": "0155",
      "psgc_code": "015513000",
      "region_desc": "01"
    },
    {
      "city_code": "015514",
      "city_name": "Bolinao",
      "province_code": "0155",
      "psgc_code": "015514000",
      "region_desc": "01"
    },
    {
      "city_code": "015515",
      "city_name": "Bugallon",
      "province_code": "0155",
      "psgc_code": "015515000",
      "region_desc": "01"
    },
    {
      "city_code": "015516",
      "city_name": "Burgos",
      "province_code": "0155",
      "psgc_code": "015516000",
      "region_desc": "01"
    },
    {
      "city_code": "015517",
      "city_name": "Calasiao",
      "province_code": "0155",
      "psgc_code": "015517000",
      "region_desc": "01"
    },
    {
      "city_code": "015518",
      "city_name": "Dagupan City",
      "province_code": "0155",
      "psgc_code": "015518000",
      "region_desc": "01"
    },
    {
      "city_code": "015519",
      "city_name": "Dasol",
      "province_code": "0155",
      "psgc_code": "015519000",
      "region_desc": "01"
    },
    {
      "city_code": "015520",
      "city_name": "Infanta",
      "province_code": "0155",
      "psgc_code": "015520000",
      "region_desc": "01"
    },
    {
      "city_code": "015521",
      "city_name": "Labrador",
      "province_code": "0155",
      "psgc_code": "015521000",
      "region_desc": "01"
    },
    {
      "city_code": "015522",
      "city_name": "Lingayen (Capital)",
      "province_code": "0155",
      "psgc_code": "015522000",
      "region_desc": "01"
    },
    {
      "city_code": "015523",
      "city_name": "Mabini",
      "province_code": "0155",
      "psgc_code": "015523000",
      "region_desc": "01"
    },
    {
      "city_code": "015524",
      "city_name": "Malasiqui",
      "province_code": "0155",
      "psgc_code": "015524000",
      "region_desc": "01"
    },
    {
      "city_code": "015525",
      "city_name": "Manaoag",
      "province_code": "0155",
      "psgc_code": "015525000",
      "region_desc": "01"
    },
    {
      "city_code": "015526",
      "city_name": "Mangaldan",
      "province_code": "0155",
      "psgc_code": "015526000",
      "region_desc": "01"
    },
    {
      "city_code": "015527",
      "city_name": "Mangatarem",
      "province_code": "0155",
      "psgc_code": "015527000",
      "region_desc": "01"
    },
    {
      "city_code": "015528",
      "city_name": "Mapandan",
      "province_code": "0155",
      "psgc_code": "015528000",
      "region_desc": "01"
    },
    {
      "city_code": "015529",
      "city_name": "Natividad",
      "province_code": "0155",
      "psgc_code": "015529000",
      "region_desc": "01"
    },
    {
      "city_code": "015530",
      "city_name": "Pozorrubio",
      "province_code": "0155",
      "psgc_code": "015530000",
      "region_desc": "01"
    },
    {
      "city_code": "015531",
      "city_name": "Rosales",
      "province_code": "0155",
      "psgc_code": "015531000",
      "region_desc": "01"
    },
    {
      "city_code": "015532",
      "city_name": "San Carlos City",
      "province_code": "0155",
      "psgc_code": "015532000",
      "region_desc": "01"
    },
    {
      "city_code": "015533",
      "city_name": "San Fabian",
      "province_code": "0155",
      "psgc_code": "015533000",
      "region_desc": "01"
    },
    {
      "city_code": "015534",
      "city_name": "San Jacinto",
      "province_code": "0155",
      "psgc_code": "015534000",
      "region_desc": "01"
    },
    {
      "city_code": "015535",
      "city_name": "San Manuel",
      "province_code": "0155",
      "psgc_code": "015535000",
      "region_desc": "01"
    },
    {
      "city_code": "015536",
      "city_name": "San Nicolas",
      "province_code": "0155",
      "psgc_code": "015536000",
      "region_desc": "01"
    },
    {
      "city_code": "015537",
      "city_name": "San Quintin",
      "province_code": "0155",
      "psgc_code": "015537000",
      "region_desc": "01"
    },
    {
      "city_code": "015538",
      "city_name": "Santa Barbara",
      "province_code": "0155",
      "psgc_code": "015538000",
      "region_desc": "01"
    },
    {
      "city_code": "015539",
      "city_name": "Santa Maria",
      "province_code": "0155",
      "psgc_code": "015539000",
      "region_desc": "01"
    },
    {
      "city_code": "015540",
      "city_name": "Santo Tomas",
      "province_code": "0155",
      "psgc_code": "015540000",
      "region_desc": "01"
    },
    {
      "city_code": "015541",
      "city_name": "Sison",
      "province_code": "0155",
      "psgc_code": "015541000",
      "region_desc": "01"
    },
    {
      "city_code": "015542",
      "city_name": "Sual",
      "province_code": "0155",
      "psgc_code": "015542000",
      "region_desc": "01"
    },
    {
      "city_code": "015543",
      "city_name": "Tayug",
      "province_code": "0155",
      "psgc_code": "015543000",
      "region_desc": "01"
    },
    {
      "city_code": "015544",
      "city_name": "Umingan",
      "province_code": "0155",
      "psgc_code": "015544000",
      "region_desc": "01"
    },
    {
      "city_code": "015545",
      "city_name": "Urbiztondo",
      "province_code": "0155",
      "psgc_code": "015545000",
      "region_desc": "01"
    },
    {
      "city_code": "015546",
      "city_name": "City Of Urdaneta",
      "province_code": "0155",
      "psgc_code": "015546000",
      "region_desc": "01"
    },
    {
      "city_code": "015547",
      "city_name": "Villasis",
      "province_code": "0155",
      "psgc_code": "015547000",
      "region_desc": "01"
    },
    {
      "city_code": "015548",
      "city_name": "Laoac",
      "province_code": "0155",
      "psgc_code": "015548000",
      "region_desc": "01"
    },
    {
      "city_code": "020901",
      "city_name": "Basco (Capital)",
      "province_code": "0209",
      "psgc_code": "020901000",
      "region_desc": "02"
    },
    {
      "city_code": "020902",
      "city_name": "Itbayat",
      "province_code": "0209",
      "psgc_code": "020902000",
      "region_desc": "02"
    },
    {
      "city_code": "020903",
      "city_name": "Ivana",
      "province_code": "0209",
      "psgc_code": "020903000",
      "region_desc": "02"
    },
    {
      "city_code": "020904",
      "city_name": "Mahatao",
      "province_code": "0209",
      "psgc_code": "020904000",
      "region_desc": "02"
    },
    {
      "city_code": "020905",
      "city_name": "Sabtang",
      "province_code": "0209",
      "psgc_code": "020905000",
      "region_desc": "02"
    },
    {
      "city_code": "020906",
      "city_name": "Uyugan",
      "province_code": "0209",
      "psgc_code": "020906000",
      "region_desc": "02"
    },
    {
      "city_code": "021501",
      "city_name": "Abulug",
      "province_code": "0215",
      "psgc_code": "021501000",
      "region_desc": "02"
    },
    {
      "city_code": "021502",
      "city_name": "Alcala",
      "province_code": "0215",
      "psgc_code": "021502000",
      "region_desc": "02"
    },
    {
      "city_code": "021503",
      "city_name": "Allacapan",
      "province_code": "0215",
      "psgc_code": "021503000",
      "region_desc": "02"
    },
    {
      "city_code": "021504",
      "city_name": "Amulung",
      "province_code": "0215",
      "psgc_code": "021504000",
      "region_desc": "02"
    },
    {
      "city_code": "021505",
      "city_name": "Aparri",
      "province_code": "0215",
      "psgc_code": "021505000",
      "region_desc": "02"
    },
    {
      "city_code": "021506",
      "city_name": "Baggao",
      "province_code": "0215",
      "psgc_code": "021506000",
      "region_desc": "02"
    },
    {
      "city_code": "021507",
      "city_name": "Ballesteros",
      "province_code": "0215",
      "psgc_code": "021507000",
      "region_desc": "02"
    },
    {
      "city_code": "021508",
      "city_name": "Buguey",
      "province_code": "0215",
      "psgc_code": "021508000",
      "region_desc": "02"
    },
    {
      "city_code": "021509",
      "city_name": "Calayan",
      "province_code": "0215",
      "psgc_code": "021509000",
      "region_desc": "02"
    },
    {
      "city_code": "021510",
      "city_name": "Camalaniugan",
      "province_code": "0215",
      "psgc_code": "021510000",
      "region_desc": "02"
    },
    {
      "city_code": "021511",
      "city_name": "Claveria",
      "province_code": "0215",
      "psgc_code": "021511000",
      "region_desc": "02"
    },
    {
      "city_code": "021512",
      "city_name": "Enrile",
      "province_code": "0215",
      "psgc_code": "021512000",
      "region_desc": "02"
    },
    {
      "city_code": "021513",
      "city_name": "Gattaran",
      "province_code": "0215",
      "psgc_code": "021513000",
      "region_desc": "02"
    },
    {
      "city_code": "021514",
      "city_name": "Gonzaga",
      "province_code": "0215",
      "psgc_code": "021514000",
      "region_desc": "02"
    },
    {
      "city_code": "021515",
      "city_name": "Iguig",
      "province_code": "0215",
      "psgc_code": "021515000",
      "region_desc": "02"
    },
    {
      "city_code": "021516",
      "city_name": "Lal-lo",
      "province_code": "0215",
      "psgc_code": "021516000",
      "region_desc": "02"
    },
    {
      "city_code": "021517",
      "city_name": "Lasam",
      "province_code": "0215",
      "psgc_code": "021517000",
      "region_desc": "02"
    },
    {
      "city_code": "021518",
      "city_name": "Pamplona",
      "province_code": "0215",
      "psgc_code": "021518000",
      "region_desc": "02"
    },
    {
      "city_code": "021519",
      "city_name": "Peñablanca",
      "province_code": "0215",
      "psgc_code": "021519000",
      "region_desc": "02"
    },
    {
      "city_code": "021520",
      "city_name": "Piat",
      "province_code": "0215",
      "psgc_code": "021520000",
      "region_desc": "02"
    },
    {
      "city_code": "021521",
      "city_name": "Rizal",
      "province_code": "0215",
      "psgc_code": "021521000",
      "region_desc": "02"
    },
    {
      "city_code": "021522",
      "city_name": "Sanchez-mira",
      "province_code": "0215",
      "psgc_code": "021522000",
      "region_desc": "02"
    },
    {
      "city_code": "021523",
      "city_name": "Santa Ana",
      "province_code": "0215",
      "psgc_code": "021523000",
      "region_desc": "02"
    },
    {
      "city_code": "021524",
      "city_name": "Santa Praxedes",
      "province_code": "0215",
      "psgc_code": "021524000",
      "region_desc": "02"
    },
    {
      "city_code": "021525",
      "city_name": "Santa Teresita",
      "province_code": "0215",
      "psgc_code": "021525000",
      "region_desc": "02"
    },
    {
      "city_code": "021526",
      "city_name": "Santo Niño (Faire)",
      "province_code": "0215",
      "psgc_code": "021526000",
      "region_desc": "02"
    },
    {
      "city_code": "021527",
      "city_name": "Solana",
      "province_code": "0215",
      "psgc_code": "021527000",
      "region_desc": "02"
    },
    {
      "city_code": "021528",
      "city_name": "Tuao",
      "province_code": "0215",
      "psgc_code": "021528000",
      "region_desc": "02"
    },
    {
      "city_code": "021529",
      "city_name": "Tuguegarao City (Capital)",
      "province_code": "0215",
      "psgc_code": "021529000",
      "region_desc": "02"
    },
    {
      "city_code": "023101",
      "city_name": "Alicia",
      "province_code": "0231",
      "psgc_code": "023101000",
      "region_desc": "02"
    },
    {
      "city_code": "023102",
      "city_name": "Angadanan",
      "province_code": "0231",
      "psgc_code": "023102000",
      "region_desc": "02"
    },
    {
      "city_code": "023103",
      "city_name": "Aurora",
      "province_code": "0231",
      "psgc_code": "023103000",
      "region_desc": "02"
    },
    {
      "city_code": "023104",
      "city_name": "Benito Soliven",
      "province_code": "0231",
      "psgc_code": "023104000",
      "region_desc": "02"
    },
    {
      "city_code": "023105",
      "city_name": "Burgos",
      "province_code": "0231",
      "psgc_code": "023105000",
      "region_desc": "02"
    },
    {
      "city_code": "023106",
      "city_name": "Cabagan",
      "province_code": "0231",
      "psgc_code": "023106000",
      "region_desc": "02"
    },
    {
      "city_code": "023107",
      "city_name": "Cabatuan",
      "province_code": "0231",
      "psgc_code": "023107000",
      "region_desc": "02"
    },
    {
      "city_code": "023108",
      "city_name": "City Of Cauayan",
      "province_code": "0231",
      "psgc_code": "023108000",
      "region_desc": "02"
    },
    {
      "city_code": "023109",
      "city_name": "Cordon",
      "province_code": "0231",
      "psgc_code": "023109000",
      "region_desc": "02"
    },
    {
      "city_code": "023110",
      "city_name": "Dinapigue",
      "province_code": "0231",
      "psgc_code": "023110000",
      "region_desc": "02"
    },
    {
      "city_code": "023111",
      "city_name": "Divilacan",
      "province_code": "0231",
      "psgc_code": "023111000",
      "region_desc": "02"
    },
    {
      "city_code": "023112",
      "city_name": "Echague",
      "province_code": "0231",
      "psgc_code": "023112000",
      "region_desc": "02"
    },
    {
      "city_code": "023113",
      "city_name": "Gamu",
      "province_code": "0231",
      "psgc_code": "023113000",
      "region_desc": "02"
    },
    {
      "city_code": "023114",
      "city_name": "Ilagan City (Capital)",
      "province_code": "0231",
      "psgc_code": "023114000",
      "region_desc": "02"
    },
    {
      "city_code": "023115",
      "city_name": "Jones",
      "province_code": "0231",
      "psgc_code": "023115000",
      "region_desc": "02"
    },
    {
      "city_code": "023116",
      "city_name": "Luna",
      "province_code": "0231",
      "psgc_code": "023116000",
      "region_desc": "02"
    },
    {
      "city_code": "023117",
      "city_name": "Maconacon",
      "province_code": "0231",
      "psgc_code": "023117000",
      "region_desc": "02"
    },
    {
      "city_code": "023118",
      "city_name": "Delfin Albano (Magsaysay)",
      "province_code": "0231",
      "psgc_code": "023118000",
      "region_desc": "02"
    },
    {
      "city_code": "023119",
      "city_name": "Mallig",
      "province_code": "0231",
      "psgc_code": "023119000",
      "region_desc": "02"
    },
    {
      "city_code": "023120",
      "city_name": "Naguilian",
      "province_code": "0231",
      "psgc_code": "023120000",
      "region_desc": "02"
    },
    {
      "city_code": "023121",
      "city_name": "Palanan",
      "province_code": "0231",
      "psgc_code": "023121000",
      "region_desc": "02"
    },
    {
      "city_code": "023122",
      "city_name": "Quezon",
      "province_code": "0231",
      "psgc_code": "023122000",
      "region_desc": "02"
    },
    {
      "city_code": "023123",
      "city_name": "Quirino",
      "province_code": "0231",
      "psgc_code": "023123000",
      "region_desc": "02"
    },
    {
      "city_code": "023124",
      "city_name": "Ramon",
      "province_code": "0231",
      "psgc_code": "023124000",
      "region_desc": "02"
    },
    {
      "city_code": "023125",
      "city_name": "Reina Mercedes",
      "province_code": "0231",
      "psgc_code": "023125000",
      "region_desc": "02"
    },
    {
      "city_code": "023126",
      "city_name": "Roxas",
      "province_code": "0231",
      "psgc_code": "023126000",
      "region_desc": "02"
    },
    {
      "city_code": "023127",
      "city_name": "San Agustin",
      "province_code": "0231",
      "psgc_code": "023127000",
      "region_desc": "02"
    },
    {
      "city_code": "023128",
      "city_name": "San Guillermo",
      "province_code": "0231",
      "psgc_code": "023128000",
      "region_desc": "02"
    },
    {
      "city_code": "023129",
      "city_name": "San Isidro",
      "province_code": "0231",
      "psgc_code": "023129000",
      "region_desc": "02"
    },
    {
      "city_code": "023130",
      "city_name": "San Manuel",
      "province_code": "0231",
      "psgc_code": "023130000",
      "region_desc": "02"
    },
    {
      "city_code": "023131",
      "city_name": "San Mariano",
      "province_code": "0231",
      "psgc_code": "023131000",
      "region_desc": "02"
    },
    {
      "city_code": "023132",
      "city_name": "San Mateo",
      "province_code": "0231",
      "psgc_code": "023132000",
      "region_desc": "02"
    },
    {
      "city_code": "023133",
      "city_name": "San Pablo",
      "province_code": "0231",
      "psgc_code": "023133000",
      "region_desc": "02"
    },
    {
      "city_code": "023134",
      "city_name": "Santa Maria",
      "province_code": "0231",
      "psgc_code": "023134000",
      "region_desc": "02"
    },
    {
      "city_code": "023135",
      "city_name": "City Of Santiago",
      "province_code": "0231",
      "psgc_code": "023135000",
      "region_desc": "02"
    },
    {
      "city_code": "023136",
      "city_name": "Santo Tomas",
      "province_code": "0231",
      "psgc_code": "023136000",
      "region_desc": "02"
    },
    {
      "city_code": "023137",
      "city_name": "Tumauini",
      "province_code": "0231",
      "psgc_code": "023137000",
      "region_desc": "02"
    },
    {
      "city_code": "025001",
      "city_name": "Ambaguio",
      "province_code": "0250",
      "psgc_code": "025001000",
      "region_desc": "02"
    },
    {
      "city_code": "025002",
      "city_name": "Aritao",
      "province_code": "0250",
      "psgc_code": "025002000",
      "region_desc": "02"
    },
    {
      "city_code": "025003",
      "city_name": "Bagabag",
      "province_code": "0250",
      "psgc_code": "025003000",
      "region_desc": "02"
    },
    {
      "city_code": "025004",
      "city_name": "Bambang",
      "province_code": "0250",
      "psgc_code": "025004000",
      "region_desc": "02"
    },
    {
      "city_code": "025005",
      "city_name": "Bayombong (Capital)",
      "province_code": "0250",
      "psgc_code": "025005000",
      "region_desc": "02"
    },
    {
      "city_code": "025006",
      "city_name": "Diadi",
      "province_code": "0250",
      "psgc_code": "025006000",
      "region_desc": "02"
    },
    {
      "city_code": "025007",
      "city_name": "Dupax Del Norte",
      "province_code": "0250",
      "psgc_code": "025007000",
      "region_desc": "02"
    },
    {
      "city_code": "025008",
      "city_name": "Dupax Del Sur",
      "province_code": "0250",
      "psgc_code": "025008000",
      "region_desc": "02"
    },
    {
      "city_code": "025009",
      "city_name": "Kasibu",
      "province_code": "0250",
      "psgc_code": "025009000",
      "region_desc": "02"
    },
    {
      "city_code": "025010",
      "city_name": "Kayapa",
      "province_code": "0250",
      "psgc_code": "025010000",
      "region_desc": "02"
    },
    {
      "city_code": "025011",
      "city_name": "Quezon",
      "province_code": "0250",
      "psgc_code": "025011000",
      "region_desc": "02"
    },
    {
      "city_code": "025012",
      "city_name": "Santa Fe",
      "province_code": "0250",
      "psgc_code": "025012000",
      "region_desc": "02"
    },
    {
      "city_code": "025013",
      "city_name": "Solano",
      "province_code": "0250",
      "psgc_code": "025013000",
      "region_desc": "02"
    },
    {
      "city_code": "025014",
      "city_name": "Villaverde",
      "province_code": "0250",
      "psgc_code": "025014000",
      "region_desc": "02"
    },
    {
      "city_code": "025015",
      "city_name": "Alfonso Castaneda",
      "province_code": "0250",
      "psgc_code": "025015000",
      "region_desc": "02"
    },
    {
      "city_code": "025701",
      "city_name": "Aglipay",
      "province_code": "0257",
      "psgc_code": "025701000",
      "region_desc": "02"
    },
    {
      "city_code": "025702",
      "city_name": "Cabarroguis (Capital)",
      "province_code": "0257",
      "psgc_code": "025702000",
      "region_desc": "02"
    },
    {
      "city_code": "025703",
      "city_name": "Diffun",
      "province_code": "0257",
      "psgc_code": "025703000",
      "region_desc": "02"
    },
    {
      "city_code": "025704",
      "city_name": "Maddela",
      "province_code": "0257",
      "psgc_code": "025704000",
      "region_desc": "02"
    },
    {
      "city_code": "025705",
      "city_name": "Saguday",
      "province_code": "0257",
      "psgc_code": "025705000",
      "region_desc": "02"
    },
    {
      "city_code": "025706",
      "city_name": "Nagtipunan",
      "province_code": "0257",
      "psgc_code": "025706000",
      "region_desc": "02"
    },
    {
      "city_code": "030801",
      "city_name": "Abucay",
      "province_code": "0308",
      "psgc_code": "030801000",
      "region_desc": "03"
    },
    {
      "city_code": "030802",
      "city_name": "Bagac",
      "province_code": "0308",
      "psgc_code": "030802000",
      "region_desc": "03"
    },
    {
      "city_code": "030803",
      "city_name": "City Of Balanga (Capital)",
      "province_code": "0308",
      "psgc_code": "030803000",
      "region_desc": "03"
    },
    {
      "city_code": "030804",
      "city_name": "Dinalupihan",
      "province_code": "0308",
      "psgc_code": "030804000",
      "region_desc": "03"
    },
    {
      "city_code": "030805",
      "city_name": "Hermosa",
      "province_code": "0308",
      "psgc_code": "030805000",
      "region_desc": "03"
    },
    {
      "city_code": "030806",
      "city_name": "Limay",
      "province_code": "0308",
      "psgc_code": "030806000",
      "region_desc": "03"
    },
    {
      "city_code": "030807",
      "city_name": "Mariveles",
      "province_code": "0308",
      "psgc_code": "030807000",
      "region_desc": "03"
    },
    {
      "city_code": "030808",
      "city_name": "Morong",
      "province_code": "0308",
      "psgc_code": "030808000",
      "region_desc": "03"
    },
    {
      "city_code": "030809",
      "city_name": "Orani",
      "province_code": "0308",
      "psgc_code": "030809000",
      "region_desc": "03"
    },
    {
      "city_code": "030810",
      "city_name": "Orion",
      "province_code": "0308",
      "psgc_code": "030810000",
      "region_desc": "03"
    },
    {
      "city_code": "030811",
      "city_name": "Pilar",
      "province_code": "0308",
      "psgc_code": "030811000",
      "region_desc": "03"
    },
    {
      "city_code": "030812",
      "city_name": "Samal",
      "province_code": "0308",
      "psgc_code": "030812000",
      "region_desc": "03"
    },
    {
      "city_code": "031401",
      "city_name": "Angat",
      "province_code": "0314",
      "psgc_code": "031401000",
      "region_desc": "03"
    },
    {
      "city_code": "031402",
      "city_name": "Balagtas (Bigaa)",
      "province_code": "0314",
      "psgc_code": "031402000",
      "region_desc": "03"
    },
    {
      "city_code": "031403",
      "city_name": "Baliuag",
      "province_code": "0314",
      "psgc_code": "031403000",
      "region_desc": "03"
    },
    {
      "city_code": "031404",
      "city_name": "Bocaue",
      "province_code": "0314",
      "psgc_code": "031404000",
      "region_desc": "03"
    },
    {
      "city_code": "031405",
      "city_name": "Bulacan",
      "province_code": "0314",
      "psgc_code": "031405000",
      "region_desc": "03"
    },
    {
      "city_code": "031406",
      "city_name": "Bustos",
      "province_code": "0314",
      "psgc_code": "031406000",
      "region_desc": "03"
    },
    {
      "city_code": "031407",
      "city_name": "Calumpit",
      "province_code": "0314",
      "psgc_code": "031407000",
      "region_desc": "03"
    },
    {
      "city_code": "031408",
      "city_name": "Guiguinto",
      "province_code": "0314",
      "psgc_code": "031408000",
      "region_desc": "03"
    },
    {
      "city_code": "031409",
      "city_name": "Hagonoy",
      "province_code": "0314",
      "psgc_code": "031409000",
      "region_desc": "03"
    },
    {
      "city_code": "031410",
      "city_name": "City Of Malolos (Capital)",
      "province_code": "0314",
      "psgc_code": "031410000",
      "region_desc": "03"
    },
    {
      "city_code": "031411",
      "city_name": "Marilao",
      "province_code": "0314",
      "psgc_code": "031411000",
      "region_desc": "03"
    },
    {
      "city_code": "031412",
      "city_name": "City Of Meycauayan",
      "province_code": "0314",
      "psgc_code": "031412000",
      "region_desc": "03"
    },
    {
      "city_code": "031413",
      "city_name": "Norzagaray",
      "province_code": "0314",
      "psgc_code": "031413000",
      "region_desc": "03"
    },
    {
      "city_code": "031414",
      "city_name": "Obando",
      "province_code": "0314",
      "psgc_code": "031414000",
      "region_desc": "03"
    },
    {
      "city_code": "031415",
      "city_name": "Pandi",
      "province_code": "0314",
      "psgc_code": "031415000",
      "region_desc": "03"
    },
    {
      "city_code": "031416",
      "city_name": "Paombong",
      "province_code": "0314",
      "psgc_code": "031416000",
      "region_desc": "03"
    },
    {
      "city_code": "031417",
      "city_name": "Plaridel",
      "province_code": "0314",
      "psgc_code": "031417000",
      "region_desc": "03"
    },
    {
      "city_code": "031418",
      "city_name": "Pulilan",
      "province_code": "0314",
      "psgc_code": "031418000",
      "region_desc": "03"
    },
    {
      "city_code": "031419",
      "city_name": "San Ildefonso",
      "province_code": "0314",
      "psgc_code": "031419000",
      "region_desc": "03"
    },
    {
      "city_code": "031420",
      "city_name": "City Of San Jose Del Monte",
      "province_code": "0314",
      "psgc_code": "031420000",
      "region_desc": "03"
    },
    {
      "city_code": "031421",
      "city_name": "San Miguel",
      "province_code": "0314",
      "psgc_code": "031421000",
      "region_desc": "03"
    },
    {
      "city_code": "031422",
      "city_name": "San Rafael",
      "province_code": "0314",
      "psgc_code": "031422000",
      "region_desc": "03"
    },
    {
      "city_code": "031423",
      "city_name": "Santa Maria",
      "province_code": "0314",
      "psgc_code": "031423000",
      "region_desc": "03"
    },
    {
      "city_code": "031424",
      "city_name": "Doña Remedios Trinidad",
      "province_code": "0314",
      "psgc_code": "031424000",
      "region_desc": "03"
    },
    {
      "city_code": "034901",
      "city_name": "Aliaga",
      "province_code": "0349",
      "psgc_code": "034901000",
      "region_desc": "03"
    },
    {
      "city_code": "034902",
      "city_name": "Bongabon",
      "province_code": "0349",
      "psgc_code": "034902000",
      "region_desc": "03"
    },
    {
      "city_code": "034903",
      "city_name": "Cabanatuan City",
      "province_code": "0349",
      "psgc_code": "034903000",
      "region_desc": "03"
    },
    {
      "city_code": "034904",
      "city_name": "Cabiao",
      "province_code": "0349",
      "psgc_code": "034904000",
      "region_desc": "03"
    },
    {
      "city_code": "034905",
      "city_name": "Carranglan",
      "province_code": "0349",
      "psgc_code": "034905000",
      "region_desc": "03"
    },
    {
      "city_code": "034906",
      "city_name": "Cuyapo",
      "province_code": "0349",
      "psgc_code": "034906000",
      "region_desc": "03"
    },
    {
      "city_code": "034907",
      "city_name": "Gabaldon (Bitulok & Sabani)",
      "province_code": "0349",
      "psgc_code": "034907000",
      "region_desc": "03"
    },
    {
      "city_code": "034908",
      "city_name": "City Of Gapan",
      "province_code": "0349",
      "psgc_code": "034908000",
      "region_desc": "03"
    },
    {
      "city_code": "034909",
      "city_name": "General Mamerto Natividad",
      "province_code": "0349",
      "psgc_code": "034909000",
      "region_desc": "03"
    },
    {
      "city_code": "034910",
      "city_name": "General Tinio (Papaya)",
      "province_code": "0349",
      "psgc_code": "034910000",
      "region_desc": "03"
    },
    {
      "city_code": "034911",
      "city_name": "Guimba",
      "province_code": "0349",
      "psgc_code": "034911000",
      "region_desc": "03"
    },
    {
      "city_code": "034912",
      "city_name": "Jaen",
      "province_code": "0349",
      "psgc_code": "034912000",
      "region_desc": "03"
    },
    {
      "city_code": "034913",
      "city_name": "Laur",
      "province_code": "0349",
      "psgc_code": "034913000",
      "region_desc": "03"
    },
    {
      "city_code": "034914",
      "city_name": "Licab",
      "province_code": "0349",
      "psgc_code": "034914000",
      "region_desc": "03"
    },
    {
      "city_code": "034915",
      "city_name": "Llanera",
      "province_code": "0349",
      "psgc_code": "034915000",
      "region_desc": "03"
    },
    {
      "city_code": "034916",
      "city_name": "Lupao",
      "province_code": "0349",
      "psgc_code": "034916000",
      "region_desc": "03"
    },
    {
      "city_code": "034917",
      "city_name": "Science City Of Muñoz",
      "province_code": "0349",
      "psgc_code": "034917000",
      "region_desc": "03"
    },
    {
      "city_code": "034918",
      "city_name": "Nampicuan",
      "province_code": "0349",
      "psgc_code": "034918000",
      "region_desc": "03"
    },
    {
      "city_code": "034919",
      "city_name": "Palayan City (Capital)",
      "province_code": "0349",
      "psgc_code": "034919000",
      "region_desc": "03"
    },
    {
      "city_code": "034920",
      "city_name": "Pantabangan",
      "province_code": "0349",
      "psgc_code": "034920000",
      "region_desc": "03"
    },
    {
      "city_code": "034921",
      "city_name": "Peñaranda",
      "province_code": "0349",
      "psgc_code": "034921000",
      "region_desc": "03"
    },
    {
      "city_code": "034922",
      "city_name": "Quezon",
      "province_code": "0349",
      "psgc_code": "034922000",
      "region_desc": "03"
    },
    {
      "city_code": "034923",
      "city_name": "Rizal",
      "province_code": "0349",
      "psgc_code": "034923000",
      "region_desc": "03"
    },
    {
      "city_code": "034924",
      "city_name": "San Antonio",
      "province_code": "0349",
      "psgc_code": "034924000",
      "region_desc": "03"
    },
    {
      "city_code": "034925",
      "city_name": "San Isidro",
      "province_code": "0349",
      "psgc_code": "034925000",
      "region_desc": "03"
    },
    {
      "city_code": "034926",
      "city_name": "San Jose City",
      "province_code": "0349",
      "psgc_code": "034926000",
      "region_desc": "03"
    },
    {
      "city_code": "034927",
      "city_name": "San Leonardo",
      "province_code": "0349",
      "psgc_code": "034927000",
      "region_desc": "03"
    },
    {
      "city_code": "034928",
      "city_name": "Santa Rosa",
      "province_code": "0349",
      "psgc_code": "034928000",
      "region_desc": "03"
    },
    {
      "city_code": "034929",
      "city_name": "Santo Domingo",
      "province_code": "0349",
      "psgc_code": "034929000",
      "region_desc": "03"
    },
    {
      "city_code": "034930",
      "city_name": "Talavera",
      "province_code": "0349",
      "psgc_code": "034930000",
      "region_desc": "03"
    },
    {
      "city_code": "034931",
      "city_name": "Talugtug",
      "province_code": "0349",
      "psgc_code": "034931000",
      "region_desc": "03"
    },
    {
      "city_code": "034932",
      "city_name": "Zaragoza",
      "province_code": "0349",
      "psgc_code": "034932000",
      "region_desc": "03"
    },
    {
      "city_code": "035401",
      "city_name": "Angeles City",
      "province_code": "0354",
      "psgc_code": "035401000",
      "region_desc": "03"
    },
    {
      "city_code": "035402",
      "city_name": "Apalit",
      "province_code": "0354",
      "psgc_code": "035402000",
      "region_desc": "03"
    },
    {
      "city_code": "035403",
      "city_name": "Arayat",
      "province_code": "0354",
      "psgc_code": "035403000",
      "region_desc": "03"
    },
    {
      "city_code": "035404",
      "city_name": "Bacolor",
      "province_code": "0354",
      "psgc_code": "035404000",
      "region_desc": "03"
    },
    {
      "city_code": "035405",
      "city_name": "Candaba",
      "province_code": "0354",
      "psgc_code": "035405000",
      "region_desc": "03"
    },
    {
      "city_code": "035406",
      "city_name": "Floridablanca",
      "province_code": "0354",
      "psgc_code": "035406000",
      "region_desc": "03"
    },
    {
      "city_code": "035407",
      "city_name": "Guagua",
      "province_code": "0354",
      "psgc_code": "035407000",
      "region_desc": "03"
    },
    {
      "city_code": "035408",
      "city_name": "Lubao",
      "province_code": "0354",
      "psgc_code": "035408000",
      "region_desc": "03"
    },
    {
      "city_code": "035409",
      "city_name": "Mabalacat City",
      "province_code": "0354",
      "psgc_code": "035409000",
      "region_desc": "03"
    },
    {
      "city_code": "035410",
      "city_name": "Macabebe",
      "province_code": "0354",
      "psgc_code": "035410000",
      "region_desc": "03"
    },
    {
      "city_code": "035411",
      "city_name": "Magalang",
      "province_code": "0354",
      "psgc_code": "035411000",
      "region_desc": "03"
    },
    {
      "city_code": "035412",
      "city_name": "Masantol",
      "province_code": "0354",
      "psgc_code": "035412000",
      "region_desc": "03"
    },
    {
      "city_code": "035413",
      "city_name": "Mexico",
      "province_code": "0354",
      "psgc_code": "035413000",
      "region_desc": "03"
    },
    {
      "city_code": "035414",
      "city_name": "Minalin",
      "province_code": "0354",
      "psgc_code": "035414000",
      "region_desc": "03"
    },
    {
      "city_code": "035415",
      "city_name": "Porac",
      "province_code": "0354",
      "psgc_code": "035415000",
      "region_desc": "03"
    },
    {
      "city_code": "035416",
      "city_name": "City Of San Fernando (Capital)",
      "province_code": "0354",
      "psgc_code": "035416000",
      "region_desc": "03"
    },
    {
      "city_code": "035417",
      "city_name": "San Luis",
      "province_code": "0354",
      "psgc_code": "035417000",
      "region_desc": "03"
    },
    {
      "city_code": "035418",
      "city_name": "San Simon",
      "province_code": "0354",
      "psgc_code": "035418000",
      "region_desc": "03"
    },
    {
      "city_code": "035419",
      "city_name": "Santa Ana",
      "province_code": "0354",
      "psgc_code": "035419000",
      "region_desc": "03"
    },
    {
      "city_code": "035420",
      "city_name": "Santa Rita",
      "province_code": "0354",
      "psgc_code": "035420000",
      "region_desc": "03"
    },
    {
      "city_code": "035421",
      "city_name": "Santo Tomas",
      "province_code": "0354",
      "psgc_code": "035421000",
      "region_desc": "03"
    },
    {
      "city_code": "035422",
      "city_name": "Sasmuan (Sexmoan)",
      "province_code": "0354",
      "psgc_code": "035422000",
      "region_desc": "03"
    },
    {
      "city_code": "036901",
      "city_name": "Anao",
      "province_code": "0369",
      "psgc_code": "036901000",
      "region_desc": "03"
    },
    {
      "city_code": "036902",
      "city_name": "Bamban",
      "province_code": "0369",
      "psgc_code": "036902000",
      "region_desc": "03"
    },
    {
      "city_code": "036903",
      "city_name": "Camiling",
      "province_code": "0369",
      "psgc_code": "036903000",
      "region_desc": "03"
    },
    {
      "city_code": "036904",
      "city_name": "Capas",
      "province_code": "0369",
      "psgc_code": "036904000",
      "region_desc": "03"
    },
    {
      "city_code": "036905",
      "city_name": "Concepcion",
      "province_code": "0369",
      "psgc_code": "036905000",
      "region_desc": "03"
    },
    {
      "city_code": "036906",
      "city_name": "Gerona",
      "province_code": "0369",
      "psgc_code": "036906000",
      "region_desc": "03"
    },
    {
      "city_code": "036907",
      "city_name": "La Paz",
      "province_code": "0369",
      "psgc_code": "036907000",
      "region_desc": "03"
    },
    {
      "city_code": "036908",
      "city_name": "Mayantoc",
      "province_code": "0369",
      "psgc_code": "036908000",
      "region_desc": "03"
    },
    {
      "city_code": "036909",
      "city_name": "Moncada",
      "province_code": "0369",
      "psgc_code": "036909000",
      "region_desc": "03"
    },
    {
      "city_code": "036910",
      "city_name": "Paniqui",
      "province_code": "0369",
      "psgc_code": "036910000",
      "region_desc": "03"
    },
    {
      "city_code": "036911",
      "city_name": "Pura",
      "province_code": "0369",
      "psgc_code": "036911000",
      "region_desc": "03"
    },
    {
      "city_code": "036912",
      "city_name": "Ramos",
      "province_code": "0369",
      "psgc_code": "036912000",
      "region_desc": "03"
    },
    {
      "city_code": "036913",
      "city_name": "San Clemente",
      "province_code": "0369",
      "psgc_code": "036913000",
      "region_desc": "03"
    },
    {
      "city_code": "036914",
      "city_name": "San Manuel",
      "province_code": "0369",
      "psgc_code": "036914000",
      "region_desc": "03"
    },
    {
      "city_code": "036915",
      "city_name": "Santa Ignacia",
      "province_code": "0369",
      "psgc_code": "036915000",
      "region_desc": "03"
    },
    {
      "city_code": "036916",
      "city_name": "City Of Tarlac (Capital)",
      "province_code": "0369",
      "psgc_code": "036916000",
      "region_desc": "03"
    },
    {
      "city_code": "036917",
      "city_name": "Victoria",
      "province_code": "0369",
      "psgc_code": "036917000",
      "region_desc": "03"
    },
    {
      "city_code": "036918",
      "city_name": "San Jose",
      "province_code": "0369",
      "psgc_code": "036918000",
      "region_desc": "03"
    },
    {
      "city_code": "037101",
      "city_name": "Botolan",
      "province_code": "0371",
      "psgc_code": "037101000",
      "region_desc": "03"
    },
    {
      "city_code": "037102",
      "city_name": "Cabangan",
      "province_code": "0371",
      "psgc_code": "037102000",
      "region_desc": "03"
    },
    {
      "city_code": "037103",
      "city_name": "Candelaria",
      "province_code": "0371",
      "psgc_code": "037103000",
      "region_desc": "03"
    },
    {
      "city_code": "037104",
      "city_name": "Castillejos",
      "province_code": "0371",
      "psgc_code": "037104000",
      "region_desc": "03"
    },
    {
      "city_code": "037105",
      "city_name": "Iba (Capital)",
      "province_code": "0371",
      "psgc_code": "037105000",
      "region_desc": "03"
    },
    {
      "city_code": "037106",
      "city_name": "Masinloc",
      "province_code": "0371",
      "psgc_code": "037106000",
      "region_desc": "03"
    },
    {
      "city_code": "037107",
      "city_name": "Olongapo City",
      "province_code": "0371",
      "psgc_code": "037107000",
      "region_desc": "03"
    },
    {
      "city_code": "037108",
      "city_name": "Palauig",
      "province_code": "0371",
      "psgc_code": "037108000",
      "region_desc": "03"
    },
    {
      "city_code": "037109",
      "city_name": "San Antonio",
      "province_code": "0371",
      "psgc_code": "037109000",
      "region_desc": "03"
    },
    {
      "city_code": "037110",
      "city_name": "San Felipe",
      "province_code": "0371",
      "psgc_code": "037110000",
      "region_desc": "03"
    },
    {
      "city_code": "037111",
      "city_name": "San Marcelino",
      "province_code": "0371",
      "psgc_code": "037111000",
      "region_desc": "03"
    },
    {
      "city_code": "037112",
      "city_name": "San Narciso",
      "province_code": "0371",
      "psgc_code": "037112000",
      "region_desc": "03"
    },
    {
      "city_code": "037113",
      "city_name": "Santa Cruz",
      "province_code": "0371",
      "psgc_code": "037113000",
      "region_desc": "03"
    },
    {
      "city_code": "037114",
      "city_name": "Subic",
      "province_code": "0371",
      "psgc_code": "037114000",
      "region_desc": "03"
    },
    {
      "city_code": "037701",
      "city_name": "Baler (Capital)",
      "province_code": "0377",
      "psgc_code": "037701000",
      "region_desc": "03"
    },
    {
      "city_code": "037702",
      "city_name": "Casiguran",
      "province_code": "0377",
      "psgc_code": "037702000",
      "region_desc": "03"
    },
    {
      "city_code": "037703",
      "city_name": "Dilasag",
      "province_code": "0377",
      "psgc_code": "037703000",
      "region_desc": "03"
    },
    {
      "city_code": "037704",
      "city_name": "Dinalungan",
      "province_code": "0377",
      "psgc_code": "037704000",
      "region_desc": "03"
    },
    {
      "city_code": "037705",
      "city_name": "Dingalan",
      "province_code": "0377",
      "psgc_code": "037705000",
      "region_desc": "03"
    },
    {
      "city_code": "037706",
      "city_name": "Dipaculao",
      "province_code": "0377",
      "psgc_code": "037706000",
      "region_desc": "03"
    },
    {
      "city_code": "037707",
      "city_name": "Maria Aurora",
      "province_code": "0377",
      "psgc_code": "037707000",
      "region_desc": "03"
    },
    {
      "city_code": "037708",
      "city_name": "San Luis",
      "province_code": "0377",
      "psgc_code": "037708000",
      "region_desc": "03"
    },
    {
      "city_code": "041001",
      "city_name": "Agoncillo",
      "province_code": "0410",
      "psgc_code": "041001000",
      "region_desc": "04"
    },
    {
      "city_code": "041002",
      "city_name": "Alitagtag",
      "province_code": "0410",
      "psgc_code": "041002000",
      "region_desc": "04"
    },
    {
      "city_code": "041003",
      "city_name": "Balayan",
      "province_code": "0410",
      "psgc_code": "041003000",
      "region_desc": "04"
    },
    {
      "city_code": "041004",
      "city_name": "Balete",
      "province_code": "0410",
      "psgc_code": "041004000",
      "region_desc": "04"
    },
    {
      "city_code": "041005",
      "city_name": "Batangas City (Capital)",
      "province_code": "0410",
      "psgc_code": "041005000",
      "region_desc": "04"
    },
    {
      "city_code": "041006",
      "city_name": "Bauan",
      "province_code": "0410",
      "psgc_code": "041006000",
      "region_desc": "04"
    },
    {
      "city_code": "041007",
      "city_name": "Calaca",
      "province_code": "0410",
      "psgc_code": "041007000",
      "region_desc": "04"
    },
    {
      "city_code": "041008",
      "city_name": "Calatagan",
      "province_code": "0410",
      "psgc_code": "041008000",
      "region_desc": "04"
    },
    {
      "city_code": "041009",
      "city_name": "Cuenca",
      "province_code": "0410",
      "psgc_code": "041009000",
      "region_desc": "04"
    },
    {
      "city_code": "041010",
      "city_name": "Ibaan",
      "province_code": "0410",
      "psgc_code": "041010000",
      "region_desc": "04"
    },
    {
      "city_code": "041011",
      "city_name": "Laurel",
      "province_code": "0410",
      "psgc_code": "041011000",
      "region_desc": "04"
    },
    {
      "city_code": "041012",
      "city_name": "Lemery",
      "province_code": "0410",
      "psgc_code": "041012000",
      "region_desc": "04"
    },
    {
      "city_code": "041013",
      "city_name": "Lian",
      "province_code": "0410",
      "psgc_code": "041013000",
      "region_desc": "04"
    },
    {
      "city_code": "041014",
      "city_name": "Lipa City",
      "province_code": "0410",
      "psgc_code": "041014000",
      "region_desc": "04"
    },
    {
      "city_code": "041015",
      "city_name": "Lobo",
      "province_code": "0410",
      "psgc_code": "041015000",
      "region_desc": "04"
    },
    {
      "city_code": "041016",
      "city_name": "Mabini",
      "province_code": "0410",
      "psgc_code": "041016000",
      "region_desc": "04"
    },
    {
      "city_code": "041017",
      "city_name": "Malvar",
      "province_code": "0410",
      "psgc_code": "041017000",
      "region_desc": "04"
    },
    {
      "city_code": "041018",
      "city_name": "Mataasnakahoy",
      "province_code": "0410",
      "psgc_code": "041018000",
      "region_desc": "04"
    },
    {
      "city_code": "041019",
      "city_name": "Nasugbu",
      "province_code": "0410",
      "psgc_code": "041019000",
      "region_desc": "04"
    },
    {
      "city_code": "041020",
      "city_name": "Padre Garcia",
      "province_code": "0410",
      "psgc_code": "041020000",
      "region_desc": "04"
    },
    {
      "city_code": "041021",
      "city_name": "Rosario",
      "province_code": "0410",
      "psgc_code": "041021000",
      "region_desc": "04"
    },
    {
      "city_code": "041022",
      "city_name": "San Jose",
      "province_code": "0410",
      "psgc_code": "041022000",
      "region_desc": "04"
    },
    {
      "city_code": "041023",
      "city_name": "San Juan",
      "province_code": "0410",
      "psgc_code": "041023000",
      "region_desc": "04"
    },
    {
      "city_code": "041024",
      "city_name": "San Luis",
      "province_code": "0410",
      "psgc_code": "041024000",
      "region_desc": "04"
    },
    {
      "city_code": "041025",
      "city_name": "San Nicolas",
      "province_code": "0410",
      "psgc_code": "041025000",
      "region_desc": "04"
    },
    {
      "city_code": "041026",
      "city_name": "San Pascual",
      "province_code": "0410",
      "psgc_code": "041026000",
      "region_desc": "04"
    },
    {
      "city_code": "041027",
      "city_name": "Santa Teresita",
      "province_code": "0410",
      "psgc_code": "041027000",
      "region_desc": "04"
    },
    {
      "city_code": "041028",
      "city_name": "Santo Tomas",
      "province_code": "0410",
      "psgc_code": "041028000",
      "region_desc": "04"
    },
    {
      "city_code": "041029",
      "city_name": "Taal",
      "province_code": "0410",
      "psgc_code": "041029000",
      "region_desc": "04"
    },
    {
      "city_code": "041030",
      "city_name": "Talisay",
      "province_code": "0410",
      "psgc_code": "041030000",
      "region_desc": "04"
    },
    {
      "city_code": "041031",
      "city_name": "City Of Tanauan",
      "province_code": "0410",
      "psgc_code": "041031000",
      "region_desc": "04"
    },
    {
      "city_code": "041032",
      "city_name": "Taysan",
      "province_code": "0410",
      "psgc_code": "041032000",
      "region_desc": "04"
    },
    {
      "city_code": "041033",
      "city_name": "Tingloy",
      "province_code": "0410",
      "psgc_code": "041033000",
      "region_desc": "04"
    },
    {
      "city_code": "041034",
      "city_name": "Tuy",
      "province_code": "0410",
      "psgc_code": "041034000",
      "region_desc": "04"
    },
    {
      "city_code": "042101",
      "city_name": "Alfonso",
      "province_code": "0421",
      "psgc_code": "042101000",
      "region_desc": "04"
    },
    {
      "city_code": "042102",
      "city_name": "Amadeo",
      "province_code": "0421",
      "psgc_code": "042102000",
      "region_desc": "04"
    },
    {
      "city_code": "042103",
      "city_name": "Bacoor City",
      "province_code": "0421",
      "psgc_code": "042103000",
      "region_desc": "04"
    },
    {
      "city_code": "042104",
      "city_name": "Carmona",
      "province_code": "0421",
      "psgc_code": "042104000",
      "region_desc": "04"
    },
    {
      "city_code": "042105",
      "city_name": "Cavite City",
      "province_code": "0421",
      "psgc_code": "042105000",
      "region_desc": "04"
    },
    {
      "city_code": "042106",
      "city_name": "City Of Dasmariñas",
      "province_code": "0421",
      "psgc_code": "042106000",
      "region_desc": "04"
    },
    {
      "city_code": "042107",
      "city_name": "General Emilio Aguinaldo",
      "province_code": "0421",
      "psgc_code": "042107000",
      "region_desc": "04"
    },
    {
      "city_code": "042108",
      "city_name": "General Trias",
      "province_code": "0421",
      "psgc_code": "042108000",
      "region_desc": "04"
    },
    {
      "city_code": "042109",
      "city_name": "Imus City",
      "province_code": "0421",
      "psgc_code": "042109000",
      "region_desc": "04"
    },
    {
      "city_code": "042110",
      "city_name": "Indang",
      "province_code": "0421",
      "psgc_code": "042110000",
      "region_desc": "04"
    },
    {
      "city_code": "042111",
      "city_name": "Kawit",
      "province_code": "0421",
      "psgc_code": "042111000",
      "region_desc": "04"
    },
    {
      "city_code": "042112",
      "city_name": "Magallanes",
      "province_code": "0421",
      "psgc_code": "042112000",
      "region_desc": "04"
    },
    {
      "city_code": "042113",
      "city_name": "Maragondon",
      "province_code": "0421",
      "psgc_code": "042113000",
      "region_desc": "04"
    },
    {
      "city_code": "042114",
      "city_name": "Mendez (Mendez-nuñez)",
      "province_code": "0421",
      "psgc_code": "042114000",
      "region_desc": "04"
    },
    {
      "city_code": "042115",
      "city_name": "Naic",
      "province_code": "0421",
      "psgc_code": "042115000",
      "region_desc": "04"
    },
    {
      "city_code": "042116",
      "city_name": "Noveleta",
      "province_code": "0421",
      "psgc_code": "042116000",
      "region_desc": "04"
    },
    {
      "city_code": "042117",
      "city_name": "Rosario",
      "province_code": "0421",
      "psgc_code": "042117000",
      "region_desc": "04"
    },
    {
      "city_code": "042118",
      "city_name": "Silang",
      "province_code": "0421",
      "psgc_code": "042118000",
      "region_desc": "04"
    },
    {
      "city_code": "042119",
      "city_name": "Tagaytay City",
      "province_code": "0421",
      "psgc_code": "042119000",
      "region_desc": "04"
    },
    {
      "city_code": "042120",
      "city_name": "Tanza",
      "province_code": "0421",
      "psgc_code": "042120000",
      "region_desc": "04"
    },
    {
      "city_code": "042121",
      "city_name": "Ternate",
      "province_code": "0421",
      "psgc_code": "042121000",
      "region_desc": "04"
    },
    {
      "city_code": "042122",
      "city_name": "Trece Martires City (Capital)",
      "province_code": "0421",
      "psgc_code": "042122000",
      "region_desc": "04"
    },
    {
      "city_code": "042123",
      "city_name": "Gen. Mariano Alvarez",
      "province_code": "0421",
      "psgc_code": "042123000",
      "region_desc": "04"
    },
    {
      "city_code": "043401",
      "city_name": "Alaminos",
      "province_code": "0434",
      "psgc_code": "043401000",
      "region_desc": "04"
    },
    {
      "city_code": "043402",
      "city_name": "Bay",
      "province_code": "0434",
      "psgc_code": "043402000",
      "region_desc": "04"
    },
    {
      "city_code": "043403",
      "city_name": "City Of Biñan",
      "province_code": "0434",
      "psgc_code": "043403000",
      "region_desc": "04"
    },
    {
      "city_code": "043404",
      "city_name": "Cabuyao City",
      "province_code": "0434",
      "psgc_code": "043404000",
      "region_desc": "04"
    },
    {
      "city_code": "043405",
      "city_name": "City Of Calamba",
      "province_code": "0434",
      "psgc_code": "043405000",
      "region_desc": "04"
    },
    {
      "city_code": "043406",
      "city_name": "Calauan",
      "province_code": "0434",
      "psgc_code": "043406000",
      "region_desc": "04"
    },
    {
      "city_code": "043407",
      "city_name": "Cavinti",
      "province_code": "0434",
      "psgc_code": "043407000",
      "region_desc": "04"
    },
    {
      "city_code": "043408",
      "city_name": "Famy",
      "province_code": "0434",
      "psgc_code": "043408000",
      "region_desc": "04"
    },
    {
      "city_code": "043409",
      "city_name": "Kalayaan",
      "province_code": "0434",
      "psgc_code": "043409000",
      "region_desc": "04"
    },
    {
      "city_code": "043410",
      "city_name": "Liliw",
      "province_code": "0434",
      "psgc_code": "043410000",
      "region_desc": "04"
    },
    {
      "city_code": "043411",
      "city_name": "Los Baños",
      "province_code": "0434",
      "psgc_code": "043411000",
      "region_desc": "04"
    },
    {
      "city_code": "043412",
      "city_name": "Luisiana",
      "province_code": "0434",
      "psgc_code": "043412000",
      "region_desc": "04"
    },
    {
      "city_code": "043413",
      "city_name": "Lumban",
      "province_code": "0434",
      "psgc_code": "043413000",
      "region_desc": "04"
    },
    {
      "city_code": "043414",
      "city_name": "Mabitac",
      "province_code": "0434",
      "psgc_code": "043414000",
      "region_desc": "04"
    },
    {
      "city_code": "043415",
      "city_name": "Magdalena",
      "province_code": "0434",
      "psgc_code": "043415000",
      "region_desc": "04"
    },
    {
      "city_code": "043416",
      "city_name": "Majayjay",
      "province_code": "0434",
      "psgc_code": "043416000",
      "region_desc": "04"
    },
    {
      "city_code": "043417",
      "city_name": "Nagcarlan",
      "province_code": "0434",
      "psgc_code": "043417000",
      "region_desc": "04"
    },
    {
      "city_code": "043418",
      "city_name": "Paete",
      "province_code": "0434",
      "psgc_code": "043418000",
      "region_desc": "04"
    },
    {
      "city_code": "043419",
      "city_name": "Pagsanjan",
      "province_code": "0434",
      "psgc_code": "043419000",
      "region_desc": "04"
    },
    {
      "city_code": "043420",
      "city_name": "Pakil",
      "province_code": "0434",
      "psgc_code": "043420000",
      "region_desc": "04"
    },
    {
      "city_code": "043421",
      "city_name": "Pangil",
      "province_code": "0434",
      "psgc_code": "043421000",
      "region_desc": "04"
    },
    {
      "city_code": "043422",
      "city_name": "Pila",
      "province_code": "0434",
      "psgc_code": "043422000",
      "region_desc": "04"
    },
    {
      "city_code": "043423",
      "city_name": "Rizal",
      "province_code": "0434",
      "psgc_code": "043423000",
      "region_desc": "04"
    },
    {
      "city_code": "043424",
      "city_name": "San Pablo City",
      "province_code": "0434",
      "psgc_code": "043424000",
      "region_desc": "04"
    },
    {
      "city_code": "043425",
      "city_name": "City Of San Pedro",
      "province_code": "0434",
      "psgc_code": "043425000",
      "region_desc": "04"
    },
    {
      "city_code": "043426",
      "city_name": "Santa Cruz (Capital)",
      "province_code": "0434",
      "psgc_code": "043426000",
      "region_desc": "04"
    },
    {
      "city_code": "043427",
      "city_name": "Santa Maria",
      "province_code": "0434",
      "psgc_code": "043427000",
      "region_desc": "04"
    },
    {
      "city_code": "043428",
      "city_name": "City Of Santa Rosa",
      "province_code": "0434",
      "psgc_code": "043428000",
      "region_desc": "04"
    },
    {
      "city_code": "043429",
      "city_name": "Siniloan",
      "province_code": "0434",
      "psgc_code": "043429000",
      "region_desc": "04"
    },
    {
      "city_code": "043430",
      "city_name": "Victoria",
      "province_code": "0434",
      "psgc_code": "043430000",
      "region_desc": "04"
    },
    {
      "city_code": "045601",
      "city_name": "Agdangan",
      "province_code": "0456",
      "psgc_code": "045601000",
      "region_desc": "04"
    },
    {
      "city_code": "045602",
      "city_name": "Alabat",
      "province_code": "0456",
      "psgc_code": "045602000",
      "region_desc": "04"
    },
    {
      "city_code": "045603",
      "city_name": "Atimonan",
      "province_code": "0456",
      "psgc_code": "045603000",
      "region_desc": "04"
    },
    {
      "city_code": "045605",
      "city_name": "Buenavista",
      "province_code": "0456",
      "psgc_code": "045605000",
      "region_desc": "04"
    },
    {
      "city_code": "045606",
      "city_name": "Burdeos",
      "province_code": "0456",
      "psgc_code": "045606000",
      "region_desc": "04"
    },
    {
      "city_code": "045607",
      "city_name": "Calauag",
      "province_code": "0456",
      "psgc_code": "045607000",
      "region_desc": "04"
    },
    {
      "city_code": "045608",
      "city_name": "Candelaria",
      "province_code": "0456",
      "psgc_code": "045608000",
      "region_desc": "04"
    },
    {
      "city_code": "045610",
      "city_name": "Catanauan",
      "province_code": "0456",
      "psgc_code": "045610000",
      "region_desc": "04"
    },
    {
      "city_code": "045615",
      "city_name": "Dolores",
      "province_code": "0456",
      "psgc_code": "045615000",
      "region_desc": "04"
    },
    {
      "city_code": "045616",
      "city_name": "General Luna",
      "province_code": "0456",
      "psgc_code": "045616000",
      "region_desc": "04"
    },
    {
      "city_code": "045617",
      "city_name": "General Nakar",
      "province_code": "0456",
      "psgc_code": "045617000",
      "region_desc": "04"
    },
    {
      "city_code": "045618",
      "city_name": "Guinayangan",
      "province_code": "0456",
      "psgc_code": "045618000",
      "region_desc": "04"
    },
    {
      "city_code": "045619",
      "city_name": "Gumaca",
      "province_code": "0456",
      "psgc_code": "045619000",
      "region_desc": "04"
    },
    {
      "city_code": "045620",
      "city_name": "Infanta",
      "province_code": "0456",
      "psgc_code": "045620000",
      "region_desc": "04"
    },
    {
      "city_code": "045621",
      "city_name": "Jomalig",
      "province_code": "0456",
      "psgc_code": "045621000",
      "region_desc": "04"
    },
    {
      "city_code": "045622",
      "city_name": "Lopez",
      "province_code": "0456",
      "psgc_code": "045622000",
      "region_desc": "04"
    },
    {
      "city_code": "045623",
      "city_name": "Lucban",
      "province_code": "0456",
      "psgc_code": "045623000",
      "region_desc": "04"
    },
    {
      "city_code": "045624",
      "city_name": "Lucena City (Capital)",
      "province_code": "0456",
      "psgc_code": "045624000",
      "region_desc": "04"
    },
    {
      "city_code": "045625",
      "city_name": "Macalelon",
      "province_code": "0456",
      "psgc_code": "045625000",
      "region_desc": "04"
    },
    {
      "city_code": "045627",
      "city_name": "Mauban",
      "province_code": "0456",
      "psgc_code": "045627000",
      "region_desc": "04"
    },
    {
      "city_code": "045628",
      "city_name": "Mulanay",
      "province_code": "0456",
      "psgc_code": "045628000",
      "region_desc": "04"
    },
    {
      "city_code": "045629",
      "city_name": "Padre Burgos",
      "province_code": "0456",
      "psgc_code": "045629000",
      "region_desc": "04"
    },
    {
      "city_code": "045630",
      "city_name": "Pagbilao",
      "province_code": "0456",
      "psgc_code": "045630000",
      "region_desc": "04"
    },
    {
      "city_code": "045631",
      "city_name": "Panukulan",
      "province_code": "0456",
      "psgc_code": "045631000",
      "region_desc": "04"
    },
    {
      "city_code": "045632",
      "city_name": "Patnanungan",
      "province_code": "0456",
      "psgc_code": "045632000",
      "region_desc": "04"
    },
    {
      "city_code": "045633",
      "city_name": "Perez",
      "province_code": "0456",
      "psgc_code": "045633000",
      "region_desc": "04"
    },
    {
      "city_code": "045634",
      "city_name": "Pitogo",
      "province_code": "0456",
      "psgc_code": "045634000",
      "region_desc": "04"
    },
    {
      "city_code": "045635",
      "city_name": "Plaridel",
      "province_code": "0456",
      "psgc_code": "045635000",
      "region_desc": "04"
    },
    {
      "city_code": "045636",
      "city_name": "Polillo",
      "province_code": "0456",
      "psgc_code": "045636000",
      "region_desc": "04"
    },
    {
      "city_code": "045637",
      "city_name": "Quezon",
      "province_code": "0456",
      "psgc_code": "045637000",
      "region_desc": "04"
    },
    {
      "city_code": "045638",
      "city_name": "Real",
      "province_code": "0456",
      "psgc_code": "045638000",
      "region_desc": "04"
    },
    {
      "city_code": "045639",
      "city_name": "Sampaloc",
      "province_code": "0456",
      "psgc_code": "045639000",
      "region_desc": "04"
    },
    {
      "city_code": "045640",
      "city_name": "San Andres",
      "province_code": "0456",
      "psgc_code": "045640000",
      "region_desc": "04"
    },
    {
      "city_code": "045641",
      "city_name": "San Antonio",
      "province_code": "0456",
      "psgc_code": "045641000",
      "region_desc": "04"
    },
    {
      "city_code": "045642",
      "city_name": "San Francisco (Aurora)",
      "province_code": "0456",
      "psgc_code": "045642000",
      "region_desc": "04"
    },
    {
      "city_code": "045644",
      "city_name": "San Narciso",
      "province_code": "0456",
      "psgc_code": "045644000",
      "region_desc": "04"
    },
    {
      "city_code": "045645",
      "city_name": "Sariaya",
      "province_code": "0456",
      "psgc_code": "045645000",
      "region_desc": "04"
    },
    {
      "city_code": "045646",
      "city_name": "Tagkawayan",
      "province_code": "0456",
      "psgc_code": "045646000",
      "region_desc": "04"
    },
    {
      "city_code": "045647",
      "city_name": "City Of Tayabas",
      "province_code": "0456",
      "psgc_code": "045647000",
      "region_desc": "04"
    },
    {
      "city_code": "045648",
      "city_name": "Tiaong",
      "province_code": "0456",
      "psgc_code": "045648000",
      "region_desc": "04"
    },
    {
      "city_code": "045649",
      "city_name": "Unisan",
      "province_code": "0456",
      "psgc_code": "045649000",
      "region_desc": "04"
    },
    {
      "city_code": "045801",
      "city_name": "Angono",
      "province_code": "0458",
      "psgc_code": "045801000",
      "region_desc": "04"
    },
    {
      "city_code": "045802",
      "city_name": "City Of Antipolo",
      "province_code": "0458",
      "psgc_code": "045802000",
      "region_desc": "04"
    },
    {
      "city_code": "045803",
      "city_name": "Baras",
      "province_code": "0458",
      "psgc_code": "045803000",
      "region_desc": "04"
    },
    {
      "city_code": "045804",
      "city_name": "Binangonan",
      "province_code": "0458",
      "psgc_code": "045804000",
      "region_desc": "04"
    },
    {
      "city_code": "045805",
      "city_name": "Cainta",
      "province_code": "0458",
      "psgc_code": "045805000",
      "region_desc": "04"
    },
    {
      "city_code": "045806",
      "city_name": "Cardona",
      "province_code": "0458",
      "psgc_code": "045806000",
      "region_desc": "04"
    },
    {
      "city_code": "045807",
      "city_name": "Jala-jala",
      "province_code": "0458",
      "psgc_code": "045807000",
      "region_desc": "04"
    },
    {
      "city_code": "045808",
      "city_name": "Rodriguez (Montalban)",
      "province_code": "0458",
      "psgc_code": "045808000",
      "region_desc": "04"
    },
    {
      "city_code": "045809",
      "city_name": "Morong",
      "province_code": "0458",
      "psgc_code": "045809000",
      "region_desc": "04"
    },
    {
      "city_code": "045810",
      "city_name": "Pililla",
      "province_code": "0458",
      "psgc_code": "045810000",
      "region_desc": "04"
    },
    {
      "city_code": "045811",
      "city_name": "San Mateo",
      "province_code": "0458",
      "psgc_code": "045811000",
      "region_desc": "04"
    },
    {
      "city_code": "045812",
      "city_name": "Tanay",
      "province_code": "0458",
      "psgc_code": "045812000",
      "region_desc": "04"
    },
    {
      "city_code": "045813",
      "city_name": "Taytay",
      "province_code": "0458",
      "psgc_code": "045813000",
      "region_desc": "04"
    },
    {
      "city_code": "045814",
      "city_name": "Teresa",
      "province_code": "0458",
      "psgc_code": "045814000",
      "region_desc": "04"
    },
    {
      "city_code": "174001",
      "city_name": "Boac (Capital)",
      "province_code": "1740",
      "psgc_code": "174001000",
      "region_desc": "17"
    },
    {
      "city_code": "174002",
      "city_name": "Buenavista",
      "province_code": "1740",
      "psgc_code": "174002000",
      "region_desc": "17"
    },
    {
      "city_code": "174003",
      "city_name": "Gasan",
      "province_code": "1740",
      "psgc_code": "174003000",
      "region_desc": "17"
    },
    {
      "city_code": "174004",
      "city_name": "Mogpog",
      "province_code": "1740",
      "psgc_code": "174004000",
      "region_desc": "17"
    },
    {
      "city_code": "174005",
      "city_name": "Santa Cruz",
      "province_code": "1740",
      "psgc_code": "174005000",
      "region_desc": "17"
    },
    {
      "city_code": "174006",
      "city_name": "Torrijos",
      "province_code": "1740",
      "psgc_code": "174006000",
      "region_desc": "17"
    },
    {
      "city_code": "175101",
      "city_name": "Abra De Ilog",
      "province_code": "1751",
      "psgc_code": "175101000",
      "region_desc": "17"
    },
    {
      "city_code": "175102",
      "city_name": "Calintaan",
      "province_code": "1751",
      "psgc_code": "175102000",
      "region_desc": "17"
    },
    {
      "city_code": "175103",
      "city_name": "Looc",
      "province_code": "1751",
      "psgc_code": "175103000",
      "region_desc": "17"
    },
    {
      "city_code": "175104",
      "city_name": "Lubang",
      "province_code": "1751",
      "psgc_code": "175104000",
      "region_desc": "17"
    },
    {
      "city_code": "175105",
      "city_name": "Magsaysay",
      "province_code": "1751",
      "psgc_code": "175105000",
      "region_desc": "17"
    },
    {
      "city_code": "175106",
      "city_name": "Mamburao (Capital)",
      "province_code": "1751",
      "psgc_code": "175106000",
      "region_desc": "17"
    },
    {
      "city_code": "175107",
      "city_name": "Paluan",
      "province_code": "1751",
      "psgc_code": "175107000",
      "region_desc": "17"
    },
    {
      "city_code": "175108",
      "city_name": "Rizal",
      "province_code": "1751",
      "psgc_code": "175108000",
      "region_desc": "17"
    },
    {
      "city_code": "175109",
      "city_name": "Sablayan",
      "province_code": "1751",
      "psgc_code": "175109000",
      "region_desc": "17"
    },
    {
      "city_code": "175110",
      "city_name": "San Jose",
      "province_code": "1751",
      "psgc_code": "175110000",
      "region_desc": "17"
    },
    {
      "city_code": "175111",
      "city_name": "Santa Cruz",
      "province_code": "1751",
      "psgc_code": "175111000",
      "region_desc": "17"
    },
    {
      "city_code": "175201",
      "city_name": "Baco",
      "province_code": "1752",
      "psgc_code": "175201000",
      "region_desc": "17"
    },
    {
      "city_code": "175202",
      "city_name": "Bansud",
      "province_code": "1752",
      "psgc_code": "175202000",
      "region_desc": "17"
    },
    {
      "city_code": "175203",
      "city_name": "Bongabong",
      "province_code": "1752",
      "psgc_code": "175203000",
      "region_desc": "17"
    },
    {
      "city_code": "175204",
      "city_name": "Bulalacao (San Pedro)",
      "province_code": "1752",
      "psgc_code": "175204000",
      "region_desc": "17"
    },
    {
      "city_code": "175205",
      "city_name": "City Of Calapan (Capital)",
      "province_code": "1752",
      "psgc_code": "175205000",
      "region_desc": "17"
    },
    {
      "city_code": "175206",
      "city_name": "Gloria",
      "province_code": "1752",
      "psgc_code": "175206000",
      "region_desc": "17"
    },
    {
      "city_code": "175207",
      "city_name": "Mansalay",
      "province_code": "1752",
      "psgc_code": "175207000",
      "region_desc": "17"
    },
    {
      "city_code": "175208",
      "city_name": "Naujan",
      "province_code": "1752",
      "psgc_code": "175208000",
      "region_desc": "17"
    },
    {
      "city_code": "175209",
      "city_name": "Pinamalayan",
      "province_code": "1752",
      "psgc_code": "175209000",
      "region_desc": "17"
    },
    {
      "city_code": "175210",
      "city_name": "Pola",
      "province_code": "1752",
      "psgc_code": "175210000",
      "region_desc": "17"
    },
    {
      "city_code": "175211",
      "city_name": "Puerto Galera",
      "province_code": "1752",
      "psgc_code": "175211000",
      "region_desc": "17"
    },
    {
      "city_code": "175212",
      "city_name": "Roxas",
      "province_code": "1752",
      "psgc_code": "175212000",
      "region_desc": "17"
    },
    {
      "city_code": "175213",
      "city_name": "San Teodoro",
      "province_code": "1752",
      "psgc_code": "175213000",
      "region_desc": "17"
    },
    {
      "city_code": "175214",
      "city_name": "Socorro",
      "province_code": "1752",
      "psgc_code": "175214000",
      "region_desc": "17"
    },
    {
      "city_code": "175215",
      "city_name": "Victoria",
      "province_code": "1752",
      "psgc_code": "175215000",
      "region_desc": "17"
    },
    {
      "city_code": "175301",
      "city_name": "Aborlan",
      "province_code": "1753",
      "psgc_code": "175301000",
      "region_desc": "17"
    },
    {
      "city_code": "175302",
      "city_name": "Agutaya",
      "province_code": "1753",
      "psgc_code": "175302000",
      "region_desc": "17"
    },
    {
      "city_code": "175303",
      "city_name": "Araceli",
      "province_code": "1753",
      "psgc_code": "175303000",
      "region_desc": "17"
    },
    {
      "city_code": "175304",
      "city_name": "Balabac",
      "province_code": "1753",
      "psgc_code": "175304000",
      "region_desc": "17"
    },
    {
      "city_code": "175305",
      "city_name": "Bataraza",
      "province_code": "1753",
      "psgc_code": "175305000",
      "region_desc": "17"
    },
    {
      "city_code": "175306",
      "city_name": "Brooke's Point",
      "province_code": "1753",
      "psgc_code": "175306000",
      "region_desc": "17"
    },
    {
      "city_code": "175307",
      "city_name": "Busuanga",
      "province_code": "1753",
      "psgc_code": "175307000",
      "region_desc": "17"
    },
    {
      "city_code": "175308",
      "city_name": "Cagayancillo",
      "province_code": "1753",
      "psgc_code": "175308000",
      "region_desc": "17"
    },
    {
      "city_code": "175309",
      "city_name": "Coron",
      "province_code": "1753",
      "psgc_code": "175309000",
      "region_desc": "17"
    },
    {
      "city_code": "175310",
      "city_name": "Cuyo",
      "province_code": "1753",
      "psgc_code": "175310000",
      "region_desc": "17"
    },
    {
      "city_code": "175311",
      "city_name": "Dumaran",
      "province_code": "1753",
      "psgc_code": "175311000",
      "region_desc": "17"
    },
    {
      "city_code": "175312",
      "city_name": "El Nido (Bacuit)",
      "province_code": "1753",
      "psgc_code": "175312000",
      "region_desc": "17"
    },
    {
      "city_code": "175313",
      "city_name": "Linapacan",
      "province_code": "1753",
      "psgc_code": "175313000",
      "region_desc": "17"
    },
    {
      "city_code": "175314",
      "city_name": "Magsaysay",
      "province_code": "1753",
      "psgc_code": "175314000",
      "region_desc": "17"
    },
    {
      "city_code": "175315",
      "city_name": "Narra",
      "province_code": "1753",
      "psgc_code": "175315000",
      "region_desc": "17"
    },
    {
      "city_code": "175316",
      "city_name": "Puerto Princesa City (Capital)",
      "province_code": "1753",
      "psgc_code": "175316000",
      "region_desc": "17"
    },
    {
      "city_code": "175317",
      "city_name": "Quezon",
      "province_code": "1753",
      "psgc_code": "175317000",
      "region_desc": "17"
    },
    {
      "city_code": "175318",
      "city_name": "Roxas",
      "province_code": "1753",
      "psgc_code": "175318000",
      "region_desc": "17"
    },
    {
      "city_code": "175319",
      "city_name": "San Vicente",
      "province_code": "1753",
      "psgc_code": "175319000",
      "region_desc": "17"
    },
    {
      "city_code": "175320",
      "city_name": "Taytay",
      "province_code": "1753",
      "psgc_code": "175320000",
      "region_desc": "17"
    },
    {
      "city_code": "175321",
      "city_name": "Kalayaan",
      "province_code": "1753",
      "psgc_code": "175321000",
      "region_desc": "17"
    },
    {
      "city_code": "175322",
      "city_name": "Culion",
      "province_code": "1753",
      "psgc_code": "175322000",
      "region_desc": "17"
    },
    {
      "city_code": "175323",
      "city_name": "Rizal (Marcos)",
      "province_code": "1753",
      "psgc_code": "175323000",
      "region_desc": "17"
    },
    {
      "city_code": "175324",
      "city_name": "Sofronio Española",
      "province_code": "1753",
      "psgc_code": "175324000",
      "region_desc": "17"
    },
    {
      "city_code": "175901",
      "city_name": "Alcantara",
      "province_code": "1759",
      "psgc_code": "175901000",
      "region_desc": "17"
    },
    {
      "city_code": "175902",
      "city_name": "Banton",
      "province_code": "1759",
      "psgc_code": "175902000",
      "region_desc": "17"
    },
    {
      "city_code": "175903",
      "city_name": "Cajidiocan",
      "province_code": "1759",
      "psgc_code": "175903000",
      "region_desc": "17"
    },
    {
      "city_code": "175904",
      "city_name": "Calatrava",
      "province_code": "1759",
      "psgc_code": "175904000",
      "region_desc": "17"
    },
    {
      "city_code": "175905",
      "city_name": "Concepcion",
      "province_code": "1759",
      "psgc_code": "175905000",
      "region_desc": "17"
    },
    {
      "city_code": "175906",
      "city_name": "Corcuera",
      "province_code": "1759",
      "psgc_code": "175906000",
      "region_desc": "17"
    },
    {
      "city_code": "175907",
      "city_name": "Looc",
      "province_code": "1759",
      "psgc_code": "175907000",
      "region_desc": "17"
    },
    {
      "city_code": "175908",
      "city_name": "Magdiwang",
      "province_code": "1759",
      "psgc_code": "175908000",
      "region_desc": "17"
    },
    {
      "city_code": "175909",
      "city_name": "Odiongan",
      "province_code": "1759",
      "psgc_code": "175909000",
      "region_desc": "17"
    },
    {
      "city_code": "175910",
      "city_name": "Romblon (Capital)",
      "province_code": "1759",
      "psgc_code": "175910000",
      "region_desc": "17"
    },
    {
      "city_code": "175911",
      "city_name": "San Agustin",
      "province_code": "1759",
      "psgc_code": "175911000",
      "region_desc": "17"
    },
    {
      "city_code": "175912",
      "city_name": "San Andres",
      "province_code": "1759",
      "psgc_code": "175912000",
      "region_desc": "17"
    },
    {
      "city_code": "175913",
      "city_name": "San Fernando",
      "province_code": "1759",
      "psgc_code": "175913000",
      "region_desc": "17"
    },
    {
      "city_code": "175914",
      "city_name": "San Jose",
      "province_code": "1759",
      "psgc_code": "175914000",
      "region_desc": "17"
    },
    {
      "city_code": "175915",
      "city_name": "Santa Fe",
      "province_code": "1759",
      "psgc_code": "175915000",
      "region_desc": "17"
    },
    {
      "city_code": "175916",
      "city_name": "Ferrol",
      "province_code": "1759",
      "psgc_code": "175916000",
      "region_desc": "17"
    },
    {
      "city_code": "175917",
      "city_name": "Santa Maria (Imelda)",
      "province_code": "1759",
      "psgc_code": "175917000",
      "region_desc": "17"
    },
    {
      "city_code": "050501",
      "city_name": "Bacacay",
      "province_code": "0505",
      "psgc_code": "050501000",
      "region_desc": "05"
    },
    {
      "city_code": "050502",
      "city_name": "Camalig",
      "province_code": "0505",
      "psgc_code": "050502000",
      "region_desc": "05"
    },
    {
      "city_code": "050503",
      "city_name": "Daraga (Locsin)",
      "province_code": "0505",
      "psgc_code": "050503000",
      "region_desc": "05"
    },
    {
      "city_code": "050504",
      "city_name": "Guinobatan",
      "province_code": "0505",
      "psgc_code": "050504000",
      "region_desc": "05"
    },
    {
      "city_code": "050505",
      "city_name": "Jovellar",
      "province_code": "0505",
      "psgc_code": "050505000",
      "region_desc": "05"
    },
    {
      "city_code": "050506",
      "city_name": "Legazpi City (Capital)",
      "province_code": "0505",
      "psgc_code": "050506000",
      "region_desc": "05"
    },
    {
      "city_code": "050507",
      "city_name": "Libon",
      "province_code": "0505",
      "psgc_code": "050507000",
      "region_desc": "05"
    },
    {
      "city_code": "050508",
      "city_name": "City Of Ligao",
      "province_code": "0505",
      "psgc_code": "050508000",
      "region_desc": "05"
    },
    {
      "city_code": "050509",
      "city_name": "Malilipot",
      "province_code": "0505",
      "psgc_code": "050509000",
      "region_desc": "05"
    },
    {
      "city_code": "050510",
      "city_name": "Malinao",
      "province_code": "0505",
      "psgc_code": "050510000",
      "region_desc": "05"
    },
    {
      "city_code": "050511",
      "city_name": "Manito",
      "province_code": "0505",
      "psgc_code": "050511000",
      "region_desc": "05"
    },
    {
      "city_code": "050512",
      "city_name": "Oas",
      "province_code": "0505",
      "psgc_code": "050512000",
      "region_desc": "05"
    },
    {
      "city_code": "050513",
      "city_name": "Pio Duran",
      "province_code": "0505",
      "psgc_code": "050513000",
      "region_desc": "05"
    },
    {
      "city_code": "050514",
      "city_name": "Polangui",
      "province_code": "0505",
      "psgc_code": "050514000",
      "region_desc": "05"
    },
    {
      "city_code": "050515",
      "city_name": "Rapu-rapu",
      "province_code": "0505",
      "psgc_code": "050515000",
      "region_desc": "05"
    },
    {
      "city_code": "050516",
      "city_name": "Santo Domingo (Libog)",
      "province_code": "0505",
      "psgc_code": "050516000",
      "region_desc": "05"
    },
    {
      "city_code": "050517",
      "city_name": "City Of Tabaco",
      "province_code": "0505",
      "psgc_code": "050517000",
      "region_desc": "05"
    },
    {
      "city_code": "050518",
      "city_name": "Tiwi",
      "province_code": "0505",
      "psgc_code": "050518000",
      "region_desc": "05"
    },
    {
      "city_code": "051601",
      "city_name": "Basud",
      "province_code": "0516",
      "psgc_code": "051601000",
      "region_desc": "05"
    },
    {
      "city_code": "051602",
      "city_name": "Capalonga",
      "province_code": "0516",
      "psgc_code": "051602000",
      "region_desc": "05"
    },
    {
      "city_code": "051603",
      "city_name": "Daet (Capital)",
      "province_code": "0516",
      "psgc_code": "051603000",
      "region_desc": "05"
    },
    {
      "city_code": "051604",
      "city_name": "San Lorenzo Ruiz (Imelda)",
      "province_code": "0516",
      "psgc_code": "051604000",
      "region_desc": "05"
    },
    {
      "city_code": "051605",
      "city_name": "Jose Panganiban",
      "province_code": "0516",
      "psgc_code": "051605000",
      "region_desc": "05"
    },
    {
      "city_code": "051606",
      "city_name": "Labo",
      "province_code": "0516",
      "psgc_code": "051606000",
      "region_desc": "05"
    },
    {
      "city_code": "051607",
      "city_name": "Mercedes",
      "province_code": "0516",
      "psgc_code": "051607000",
      "region_desc": "05"
    },
    {
      "city_code": "051608",
      "city_name": "Paracale",
      "province_code": "0516",
      "psgc_code": "051608000",
      "region_desc": "05"
    },
    {
      "city_code": "051609",
      "city_name": "San Vicente",
      "province_code": "0516",
      "psgc_code": "051609000",
      "region_desc": "05"
    },
    {
      "city_code": "051610",
      "city_name": "Santa Elena",
      "province_code": "0516",
      "psgc_code": "051610000",
      "region_desc": "05"
    },
    {
      "city_code": "051611",
      "city_name": "Talisay",
      "province_code": "0516",
      "psgc_code": "051611000",
      "region_desc": "05"
    },
    {
      "city_code": "051612",
      "city_name": "Vinzons",
      "province_code": "0516",
      "psgc_code": "051612000",
      "region_desc": "05"
    },
    {
      "city_code": "051701",
      "city_name": "Baao",
      "province_code": "0517",
      "psgc_code": "051701000",
      "region_desc": "05"
    },
    {
      "city_code": "051702",
      "city_name": "Balatan",
      "province_code": "0517",
      "psgc_code": "051702000",
      "region_desc": "05"
    },
    {
      "city_code": "051703",
      "city_name": "Bato",
      "province_code": "0517",
      "psgc_code": "051703000",
      "region_desc": "05"
    },
    {
      "city_code": "051704",
      "city_name": "Bombon",
      "province_code": "0517",
      "psgc_code": "051704000",
      "region_desc": "05"
    },
    {
      "city_code": "051705",
      "city_name": "Buhi",
      "province_code": "0517",
      "psgc_code": "051705000",
      "region_desc": "05"
    },
    {
      "city_code": "051706",
      "city_name": "Bula",
      "province_code": "0517",
      "psgc_code": "051706000",
      "region_desc": "05"
    },
    {
      "city_code": "051707",
      "city_name": "Cabusao",
      "province_code": "0517",
      "psgc_code": "051707000",
      "region_desc": "05"
    },
    {
      "city_code": "051708",
      "city_name": "Calabanga",
      "province_code": "0517",
      "psgc_code": "051708000",
      "region_desc": "05"
    },
    {
      "city_code": "051709",
      "city_name": "Camaligan",
      "province_code": "0517",
      "psgc_code": "051709000",
      "region_desc": "05"
    },
    {
      "city_code": "051710",
      "city_name": "Canaman",
      "province_code": "0517",
      "psgc_code": "051710000",
      "region_desc": "05"
    },
    {
      "city_code": "051711",
      "city_name": "Caramoan",
      "province_code": "0517",
      "psgc_code": "051711000",
      "region_desc": "05"
    },
    {
      "city_code": "051712",
      "city_name": "Del Gallego",
      "province_code": "0517",
      "psgc_code": "051712000",
      "region_desc": "05"
    },
    {
      "city_code": "051713",
      "city_name": "Gainza",
      "province_code": "0517",
      "psgc_code": "051713000",
      "region_desc": "05"
    },
    {
      "city_code": "051714",
      "city_name": "Garchitorena",
      "province_code": "0517",
      "psgc_code": "051714000",
      "region_desc": "05"
    },
    {
      "city_code": "051715",
      "city_name": "Goa",
      "province_code": "0517",
      "psgc_code": "051715000",
      "region_desc": "05"
    },
    {
      "city_code": "051716",
      "city_name": "Iriga City",
      "province_code": "0517",
      "psgc_code": "051716000",
      "region_desc": "05"
    },
    {
      "city_code": "051717",
      "city_name": "Lagonoy",
      "province_code": "0517",
      "psgc_code": "051717000",
      "region_desc": "05"
    },
    {
      "city_code": "051718",
      "city_name": "Libmanan",
      "province_code": "0517",
      "psgc_code": "051718000",
      "region_desc": "05"
    },
    {
      "city_code": "051719",
      "city_name": "Lupi",
      "province_code": "0517",
      "psgc_code": "051719000",
      "region_desc": "05"
    },
    {
      "city_code": "051720",
      "city_name": "Magarao",
      "province_code": "0517",
      "psgc_code": "051720000",
      "region_desc": "05"
    },
    {
      "city_code": "051721",
      "city_name": "Milaor",
      "province_code": "0517",
      "psgc_code": "051721000",
      "region_desc": "05"
    },
    {
      "city_code": "051722",
      "city_name": "Minalabac",
      "province_code": "0517",
      "psgc_code": "051722000",
      "region_desc": "05"
    },
    {
      "city_code": "051723",
      "city_name": "Nabua",
      "province_code": "0517",
      "psgc_code": "051723000",
      "region_desc": "05"
    },
    {
      "city_code": "051724",
      "city_name": "Naga City",
      "province_code": "0517",
      "psgc_code": "051724000",
      "region_desc": "05"
    },
    {
      "city_code": "051725",
      "city_name": "Ocampo",
      "province_code": "0517",
      "psgc_code": "051725000",
      "region_desc": "05"
    },
    {
      "city_code": "051726",
      "city_name": "Pamplona",
      "province_code": "0517",
      "psgc_code": "051726000",
      "region_desc": "05"
    },
    {
      "city_code": "051727",
      "city_name": "Pasacao",
      "province_code": "0517",
      "psgc_code": "051727000",
      "region_desc": "05"
    },
    {
      "city_code": "051728",
      "city_name": "Pili (Capital)",
      "province_code": "0517",
      "psgc_code": "051728000",
      "region_desc": "05"
    },
    {
      "city_code": "051729",
      "city_name": "Presentacion (Parubcan)",
      "province_code": "0517",
      "psgc_code": "051729000",
      "region_desc": "05"
    },
    {
      "city_code": "051730",
      "city_name": "Ragay",
      "province_code": "0517",
      "psgc_code": "051730000",
      "region_desc": "05"
    },
    {
      "city_code": "051731",
      "city_name": "Sagñay",
      "province_code": "0517",
      "psgc_code": "051731000",
      "region_desc": "05"
    },
    {
      "city_code": "051732",
      "city_name": "San Fernando",
      "province_code": "0517",
      "psgc_code": "051732000",
      "region_desc": "05"
    },
    {
      "city_code": "051733",
      "city_name": "San Jose",
      "province_code": "0517",
      "psgc_code": "051733000",
      "region_desc": "05"
    },
    {
      "city_code": "051734",
      "city_name": "Sipocot",
      "province_code": "0517",
      "psgc_code": "051734000",
      "region_desc": "05"
    },
    {
      "city_code": "051735",
      "city_name": "Siruma",
      "province_code": "0517",
      "psgc_code": "051735000",
      "region_desc": "05"
    },
    {
      "city_code": "051736",
      "city_name": "Tigaon",
      "province_code": "0517",
      "psgc_code": "051736000",
      "region_desc": "05"
    },
    {
      "city_code": "051737",
      "city_name": "Tinambac",
      "province_code": "0517",
      "psgc_code": "051737000",
      "region_desc": "05"
    },
    {
      "city_code": "052001",
      "city_name": "Bagamanoc",
      "province_code": "0520",
      "psgc_code": "052001000",
      "region_desc": "05"
    },
    {
      "city_code": "052002",
      "city_name": "Baras",
      "province_code": "0520",
      "psgc_code": "052002000",
      "region_desc": "05"
    },
    {
      "city_code": "052003",
      "city_name": "Bato",
      "province_code": "0520",
      "psgc_code": "052003000",
      "region_desc": "05"
    },
    {
      "city_code": "052004",
      "city_name": "Caramoran",
      "province_code": "0520",
      "psgc_code": "052004000",
      "region_desc": "05"
    },
    {
      "city_code": "052005",
      "city_name": "Gigmoto",
      "province_code": "0520",
      "psgc_code": "052005000",
      "region_desc": "05"
    },
    {
      "city_code": "052006",
      "city_name": "Pandan",
      "province_code": "0520",
      "psgc_code": "052006000",
      "region_desc": "05"
    },
    {
      "city_code": "052007",
      "city_name": "Panganiban",
      "province_code": "0520",
      "psgc_code": "052007000",
      "region_desc": "05"
    },
    {
      "city_code": "052008",
      "city_name": "San Andres",
      "province_code": "0520",
      "psgc_code": "052008000",
      "region_desc": "05"
    },
    {
      "city_code": "052009",
      "city_name": "San Miguel",
      "province_code": "0520",
      "psgc_code": "052009000",
      "region_desc": "05"
    },
    {
      "city_code": "052010",
      "city_name": "Viga",
      "province_code": "0520",
      "psgc_code": "052010000",
      "region_desc": "05"
    },
    {
      "city_code": "052011",
      "city_name": "Virac",
      "province_code": "0520",
      "psgc_code": "052011000",
      "region_desc": "05"
    },
    {
      "city_code": "054101",
      "city_name": "Aroroy",
      "province_code": "0541",
      "psgc_code": "054101000",
      "region_desc": "05"
    },
    {
      "city_code": "054102",
      "city_name": "Baleno",
      "province_code": "0541",
      "psgc_code": "054102000",
      "region_desc": "05"
    },
    {
      "city_code": "054103",
      "city_name": "Balud",
      "province_code": "0541",
      "psgc_code": "054103000",
      "region_desc": "05"
    },
    {
      "city_code": "054104",
      "city_name": "Batuan",
      "province_code": "0541",
      "psgc_code": "054104000",
      "region_desc": "05"
    },
    {
      "city_code": "054105",
      "city_name": "Cataingan",
      "province_code": "0541",
      "psgc_code": "054105000",
      "region_desc": "05"
    },
    {
      "city_code": "054106",
      "city_name": "Cawayan",
      "province_code": "0541",
      "psgc_code": "054106000",
      "region_desc": "05"
    },
    {
      "city_code": "054107",
      "city_name": "Claveria",
      "province_code": "0541",
      "psgc_code": "054107000",
      "region_desc": "05"
    },
    {
      "city_code": "054108",
      "city_name": "Dimasalang",
      "province_code": "0541",
      "psgc_code": "054108000",
      "region_desc": "05"
    },
    {
      "city_code": "054109",
      "city_name": "Esperanza",
      "province_code": "0541",
      "psgc_code": "054109000",
      "region_desc": "05"
    },
    {
      "city_code": "054110",
      "city_name": "Mandaon",
      "province_code": "0541",
      "psgc_code": "054110000",
      "region_desc": "05"
    },
    {
      "city_code": "054111",
      "city_name": "City Of Masbate (Capital)",
      "province_code": "0541",
      "psgc_code": "054111000",
      "region_desc": "05"
    },
    {
      "city_code": "054112",
      "city_name": "Milagros",
      "province_code": "0541",
      "psgc_code": "054112000",
      "region_desc": "05"
    },
    {
      "city_code": "054113",
      "city_name": "Mobo",
      "province_code": "0541",
      "psgc_code": "054113000",
      "region_desc": "05"
    },
    {
      "city_code": "054114",
      "city_name": "Monreal",
      "province_code": "0541",
      "psgc_code": "054114000",
      "region_desc": "05"
    },
    {
      "city_code": "054115",
      "city_name": "Palanas",
      "province_code": "0541",
      "psgc_code": "054115000",
      "region_desc": "05"
    },
    {
      "city_code": "054116",
      "city_name": "Pio V. Corpuz (Limbuhan)",
      "province_code": "0541",
      "psgc_code": "054116000",
      "region_desc": "05"
    },
    {
      "city_code": "054117",
      "city_name": "Placer",
      "province_code": "0541",
      "psgc_code": "054117000",
      "region_desc": "05"
    },
    {
      "city_code": "054118",
      "city_name": "San Fernando",
      "province_code": "0541",
      "psgc_code": "054118000",
      "region_desc": "05"
    },
    {
      "city_code": "054119",
      "city_name": "San Jacinto",
      "province_code": "0541",
      "psgc_code": "054119000",
      "region_desc": "05"
    },
    {
      "city_code": "054120",
      "city_name": "San Pascual",
      "province_code": "0541",
      "psgc_code": "054120000",
      "region_desc": "05"
    },
    {
      "city_code": "054121",
      "city_name": "Uson",
      "province_code": "0541",
      "psgc_code": "054121000",
      "region_desc": "05"
    },
    {
      "city_code": "056202",
      "city_name": "Barcelona",
      "province_code": "0562",
      "psgc_code": "056202000",
      "region_desc": "05"
    },
    {
      "city_code": "056203",
      "city_name": "Bulan",
      "province_code": "0562",
      "psgc_code": "056203000",
      "region_desc": "05"
    },
    {
      "city_code": "056204",
      "city_name": "Bulusan",
      "province_code": "0562",
      "psgc_code": "056204000",
      "region_desc": "05"
    },
    {
      "city_code": "056205",
      "city_name": "Casiguran",
      "province_code": "0562",
      "psgc_code": "056205000",
      "region_desc": "05"
    },
    {
      "city_code": "056206",
      "city_name": "Castilla",
      "province_code": "0562",
      "psgc_code": "056206000",
      "region_desc": "05"
    },
    {
      "city_code": "056207",
      "city_name": "Donsol",
      "province_code": "0562",
      "psgc_code": "056207000",
      "region_desc": "05"
    },
    {
      "city_code": "056208",
      "city_name": "Gubat",
      "province_code": "0562",
      "psgc_code": "056208000",
      "region_desc": "05"
    },
    {
      "city_code": "056209",
      "city_name": "Irosin",
      "province_code": "0562",
      "psgc_code": "056209000",
      "region_desc": "05"
    },
    {
      "city_code": "056210",
      "city_name": "Juban",
      "province_code": "0562",
      "psgc_code": "056210000",
      "region_desc": "05"
    },
    {
      "city_code": "056211",
      "city_name": "Magallanes",
      "province_code": "0562",
      "psgc_code": "056211000",
      "region_desc": "05"
    },
    {
      "city_code": "056212",
      "city_name": "Matnog",
      "province_code": "0562",
      "psgc_code": "056212000",
      "region_desc": "05"
    },
    {
      "city_code": "056213",
      "city_name": "Pilar",
      "province_code": "0562",
      "psgc_code": "056213000",
      "region_desc": "05"
    },
    {
      "city_code": "056214",
      "city_name": "Prieto Diaz",
      "province_code": "0562",
      "psgc_code": "056214000",
      "region_desc": "05"
    },
    {
      "city_code": "056215",
      "city_name": "Santa Magdalena",
      "province_code": "0562",
      "psgc_code": "056215000",
      "region_desc": "05"
    },
    {
      "city_code": "056216",
      "city_name": "City Of Sorsogon (Capital)",
      "province_code": "0562",
      "psgc_code": "056216000",
      "region_desc": "05"
    },
    {
      "city_code": "060401",
      "city_name": "Altavas",
      "province_code": "0604",
      "psgc_code": "060401000",
      "region_desc": "06"
    },
    {
      "city_code": "060402",
      "city_name": "Balete",
      "province_code": "0604",
      "psgc_code": "060402000",
      "region_desc": "06"
    },
    {
      "city_code": "060403",
      "city_name": "Banga",
      "province_code": "0604",
      "psgc_code": "060403000",
      "region_desc": "06"
    },
    {
      "city_code": "060404",
      "city_name": "Batan",
      "province_code": "0604",
      "psgc_code": "060404000",
      "region_desc": "06"
    },
    {
      "city_code": "060405",
      "city_name": "Buruanga",
      "province_code": "0604",
      "psgc_code": "060405000",
      "region_desc": "06"
    },
    {
      "city_code": "060406",
      "city_name": "Ibajay",
      "province_code": "0604",
      "psgc_code": "060406000",
      "region_desc": "06"
    },
    {
      "city_code": "060407",
      "city_name": "Kalibo (Capital)",
      "province_code": "0604",
      "psgc_code": "060407000",
      "region_desc": "06"
    },
    {
      "city_code": "060408",
      "city_name": "Lezo",
      "province_code": "0604",
      "psgc_code": "060408000",
      "region_desc": "06"
    },
    {
      "city_code": "060409",
      "city_name": "Libacao",
      "province_code": "0604",
      "psgc_code": "060409000",
      "region_desc": "06"
    },
    {
      "city_code": "060410",
      "city_name": "Madalag",
      "province_code": "0604",
      "psgc_code": "060410000",
      "region_desc": "06"
    },
    {
      "city_code": "060411",
      "city_name": "Makato",
      "province_code": "0604",
      "psgc_code": "060411000",
      "region_desc": "06"
    },
    {
      "city_code": "060412",
      "city_name": "Malay",
      "province_code": "0604",
      "psgc_code": "060412000",
      "region_desc": "06"
    },
    {
      "city_code": "060413",
      "city_name": "Malinao",
      "province_code": "0604",
      "psgc_code": "060413000",
      "region_desc": "06"
    },
    {
      "city_code": "060414",
      "city_name": "Nabas",
      "province_code": "0604",
      "psgc_code": "060414000",
      "region_desc": "06"
    },
    {
      "city_code": "060415",
      "city_name": "New Washington",
      "province_code": "0604",
      "psgc_code": "060415000",
      "region_desc": "06"
    },
    {
      "city_code": "060416",
      "city_name": "Numancia",
      "province_code": "0604",
      "psgc_code": "060416000",
      "region_desc": "06"
    },
    {
      "city_code": "060417",
      "city_name": "Tangalan",
      "province_code": "0604",
      "psgc_code": "060417000",
      "region_desc": "06"
    },
    {
      "city_code": "060601",
      "city_name": "Anini-y",
      "province_code": "0606",
      "psgc_code": "060601000",
      "region_desc": "06"
    },
    {
      "city_code": "060602",
      "city_name": "Barbaza",
      "province_code": "0606",
      "psgc_code": "060602000",
      "region_desc": "06"
    },
    {
      "city_code": "060603",
      "city_name": "Belison",
      "province_code": "0606",
      "psgc_code": "060603000",
      "region_desc": "06"
    },
    {
      "city_code": "060604",
      "city_name": "Bugasong",
      "province_code": "0606",
      "psgc_code": "060604000",
      "region_desc": "06"
    },
    {
      "city_code": "060605",
      "city_name": "Caluya",
      "province_code": "0606",
      "psgc_code": "060605000",
      "region_desc": "06"
    },
    {
      "city_code": "060606",
      "city_name": "Culasi",
      "province_code": "0606",
      "psgc_code": "060606000",
      "region_desc": "06"
    },
    {
      "city_code": "060607",
      "city_name": "Tobias Fornier (Dao)",
      "province_code": "0606",
      "psgc_code": "060607000",
      "region_desc": "06"
    },
    {
      "city_code": "060608",
      "city_name": "Hamtic",
      "province_code": "0606",
      "psgc_code": "060608000",
      "region_desc": "06"
    },
    {
      "city_code": "060609",
      "city_name": "Laua-an",
      "province_code": "0606",
      "psgc_code": "060609000",
      "region_desc": "06"
    },
    {
      "city_code": "060610",
      "city_name": "Libertad",
      "province_code": "0606",
      "psgc_code": "060610000",
      "region_desc": "06"
    },
    {
      "city_code": "060611",
      "city_name": "Pandan",
      "province_code": "0606",
      "psgc_code": "060611000",
      "region_desc": "06"
    },
    {
      "city_code": "060612",
      "city_name": "Patnongon",
      "province_code": "0606",
      "psgc_code": "060612000",
      "region_desc": "06"
    },
    {
      "city_code": "060613",
      "city_name": "San Jose (Capital)",
      "province_code": "0606",
      "psgc_code": "060613000",
      "region_desc": "06"
    },
    {
      "city_code": "060614",
      "city_name": "San Remigio",
      "province_code": "0606",
      "psgc_code": "060614000",
      "region_desc": "06"
    },
    {
      "city_code": "060615",
      "city_name": "Sebaste",
      "province_code": "0606",
      "psgc_code": "060615000",
      "region_desc": "06"
    },
    {
      "city_code": "060616",
      "city_name": "Sibalom",
      "province_code": "0606",
      "psgc_code": "060616000",
      "region_desc": "06"
    },
    {
      "city_code": "060617",
      "city_name": "Tibiao",
      "province_code": "0606",
      "psgc_code": "060617000",
      "region_desc": "06"
    },
    {
      "city_code": "060618",
      "city_name": "Valderrama",
      "province_code": "0606",
      "psgc_code": "060618000",
      "region_desc": "06"
    },
    {
      "city_code": "061901",
      "city_name": "Cuartero",
      "province_code": "0619",
      "psgc_code": "061901000",
      "region_desc": "06"
    },
    {
      "city_code": "061902",
      "city_name": "Dao",
      "province_code": "0619",
      "psgc_code": "061902000",
      "region_desc": "06"
    },
    {
      "city_code": "061903",
      "city_name": "Dumalag",
      "province_code": "0619",
      "psgc_code": "061903000",
      "region_desc": "06"
    },
    {
      "city_code": "061904",
      "city_name": "Dumarao",
      "province_code": "0619",
      "psgc_code": "061904000",
      "region_desc": "06"
    },
    {
      "city_code": "061905",
      "city_name": "Ivisan",
      "province_code": "0619",
      "psgc_code": "061905000",
      "region_desc": "06"
    },
    {
      "city_code": "061906",
      "city_name": "Jamindan",
      "province_code": "0619",
      "psgc_code": "061906000",
      "region_desc": "06"
    },
    {
      "city_code": "061907",
      "city_name": "Ma-ayon",
      "province_code": "0619",
      "psgc_code": "061907000",
      "region_desc": "06"
    },
    {
      "city_code": "061908",
      "city_name": "Mambusao",
      "province_code": "0619",
      "psgc_code": "061908000",
      "region_desc": "06"
    },
    {
      "city_code": "061909",
      "city_name": "Panay",
      "province_code": "0619",
      "psgc_code": "061909000",
      "region_desc": "06"
    },
    {
      "city_code": "061910",
      "city_name": "Panitan",
      "province_code": "0619",
      "psgc_code": "061910000",
      "region_desc": "06"
    },
    {
      "city_code": "061911",
      "city_name": "Pilar",
      "province_code": "0619",
      "psgc_code": "061911000",
      "region_desc": "06"
    },
    {
      "city_code": "061912",
      "city_name": "Pontevedra",
      "province_code": "0619",
      "psgc_code": "061912000",
      "region_desc": "06"
    },
    {
      "city_code": "061913",
      "city_name": "President Roxas",
      "province_code": "0619",
      "psgc_code": "061913000",
      "region_desc": "06"
    },
    {
      "city_code": "061914",
      "city_name": "Roxas City (Capital)",
      "province_code": "0619",
      "psgc_code": "061914000",
      "region_desc": "06"
    },
    {
      "city_code": "061915",
      "city_name": "Sapi-an",
      "province_code": "0619",
      "psgc_code": "061915000",
      "region_desc": "06"
    },
    {
      "city_code": "061916",
      "city_name": "Sigma",
      "province_code": "0619",
      "psgc_code": "061916000",
      "region_desc": "06"
    },
    {
      "city_code": "061917",
      "city_name": "Tapaz",
      "province_code": "0619",
      "psgc_code": "061917000",
      "region_desc": "06"
    },
    {
      "city_code": "063001",
      "city_name": "Ajuy",
      "province_code": "0630",
      "psgc_code": "063001000",
      "region_desc": "06"
    },
    {
      "city_code": "063002",
      "city_name": "Alimodian",
      "province_code": "0630",
      "psgc_code": "063002000",
      "region_desc": "06"
    },
    {
      "city_code": "063003",
      "city_name": "Anilao",
      "province_code": "0630",
      "psgc_code": "063003000",
      "region_desc": "06"
    },
    {
      "city_code": "063004",
      "city_name": "Badiangan",
      "province_code": "0630",
      "psgc_code": "063004000",
      "region_desc": "06"
    },
    {
      "city_code": "063005",
      "city_name": "Balasan",
      "province_code": "0630",
      "psgc_code": "063005000",
      "region_desc": "06"
    },
    {
      "city_code": "063006",
      "city_name": "Banate",
      "province_code": "0630",
      "psgc_code": "063006000",
      "region_desc": "06"
    },
    {
      "city_code": "063007",
      "city_name": "Barotac Nuevo",
      "province_code": "0630",
      "psgc_code": "063007000",
      "region_desc": "06"
    },
    {
      "city_code": "063008",
      "city_name": "Barotac Viejo",
      "province_code": "0630",
      "psgc_code": "063008000",
      "region_desc": "06"
    },
    {
      "city_code": "063009",
      "city_name": "Batad",
      "province_code": "0630",
      "psgc_code": "063009000",
      "region_desc": "06"
    },
    {
      "city_code": "063010",
      "city_name": "Bingawan",
      "province_code": "0630",
      "psgc_code": "063010000",
      "region_desc": "06"
    },
    {
      "city_code": "063012",
      "city_name": "Cabatuan",
      "province_code": "0630",
      "psgc_code": "063012000",
      "region_desc": "06"
    },
    {
      "city_code": "063013",
      "city_name": "Calinog",
      "province_code": "0630",
      "psgc_code": "063013000",
      "region_desc": "06"
    },
    {
      "city_code": "063014",
      "city_name": "Carles",
      "province_code": "0630",
      "psgc_code": "063014000",
      "region_desc": "06"
    },
    {
      "city_code": "063015",
      "city_name": "Concepcion",
      "province_code": "0630",
      "psgc_code": "063015000",
      "region_desc": "06"
    },
    {
      "city_code": "063016",
      "city_name": "Dingle",
      "province_code": "0630",
      "psgc_code": "063016000",
      "region_desc": "06"
    },
    {
      "city_code": "063017",
      "city_name": "Dueñas",
      "province_code": "0630",
      "psgc_code": "063017000",
      "region_desc": "06"
    },
    {
      "city_code": "063018",
      "city_name": "Dumangas",
      "province_code": "0630",
      "psgc_code": "063018000",
      "region_desc": "06"
    },
    {
      "city_code": "063019",
      "city_name": "Estancia",
      "province_code": "0630",
      "psgc_code": "063019000",
      "region_desc": "06"
    },
    {
      "city_code": "063020",
      "city_name": "Guimbal",
      "province_code": "0630",
      "psgc_code": "063020000",
      "region_desc": "06"
    },
    {
      "city_code": "063021",
      "city_name": "Igbaras",
      "province_code": "0630",
      "psgc_code": "063021000",
      "region_desc": "06"
    },
    {
      "city_code": "063022",
      "city_name": "Iloilo City (Capital)",
      "province_code": "0630",
      "psgc_code": "063022000",
      "region_desc": "06"
    },
    {
      "city_code": "063023",
      "city_name": "Janiuay",
      "province_code": "0630",
      "psgc_code": "063023000",
      "region_desc": "06"
    },
    {
      "city_code": "063025",
      "city_name": "Lambunao",
      "province_code": "0630",
      "psgc_code": "063025000",
      "region_desc": "06"
    },
    {
      "city_code": "063026",
      "city_name": "Leganes",
      "province_code": "0630",
      "psgc_code": "063026000",
      "region_desc": "06"
    },
    {
      "city_code": "063027",
      "city_name": "Lemery",
      "province_code": "0630",
      "psgc_code": "063027000",
      "region_desc": "06"
    },
    {
      "city_code": "063028",
      "city_name": "Leon",
      "province_code": "0630",
      "psgc_code": "063028000",
      "region_desc": "06"
    },
    {
      "city_code": "063029",
      "city_name": "Maasin",
      "province_code": "0630",
      "psgc_code": "063029000",
      "region_desc": "06"
    },
    {
      "city_code": "063030",
      "city_name": "Miagao",
      "province_code": "0630",
      "psgc_code": "063030000",
      "region_desc": "06"
    },
    {
      "city_code": "063031",
      "city_name": "Mina",
      "province_code": "0630",
      "psgc_code": "063031000",
      "region_desc": "06"
    },
    {
      "city_code": "063032",
      "city_name": "New Lucena",
      "province_code": "0630",
      "psgc_code": "063032000",
      "region_desc": "06"
    },
    {
      "city_code": "063034",
      "city_name": "Oton",
      "province_code": "0630",
      "psgc_code": "063034000",
      "region_desc": "06"
    },
    {
      "city_code": "063035",
      "city_name": "City Of Passi",
      "province_code": "0630",
      "psgc_code": "063035000",
      "region_desc": "06"
    },
    {
      "city_code": "063036",
      "city_name": "Pavia",
      "province_code": "0630",
      "psgc_code": "063036000",
      "region_desc": "06"
    },
    {
      "city_code": "063037",
      "city_name": "Pototan",
      "province_code": "0630",
      "psgc_code": "063037000",
      "region_desc": "06"
    },
    {
      "city_code": "063038",
      "city_name": "San Dionisio",
      "province_code": "0630",
      "psgc_code": "063038000",
      "region_desc": "06"
    },
    {
      "city_code": "063039",
      "city_name": "San Enrique",
      "province_code": "0630",
      "psgc_code": "063039000",
      "region_desc": "06"
    },
    {
      "city_code": "063040",
      "city_name": "San Joaquin",
      "province_code": "0630",
      "psgc_code": "063040000",
      "region_desc": "06"
    },
    {
      "city_code": "063041",
      "city_name": "San Miguel",
      "province_code": "0630",
      "psgc_code": "063041000",
      "region_desc": "06"
    },
    {
      "city_code": "063042",
      "city_name": "San Rafael",
      "province_code": "0630",
      "psgc_code": "063042000",
      "region_desc": "06"
    },
    {
      "city_code": "063043",
      "city_name": "Santa Barbara",
      "province_code": "0630",
      "psgc_code": "063043000",
      "region_desc": "06"
    },
    {
      "city_code": "063044",
      "city_name": "Sara",
      "province_code": "0630",
      "psgc_code": "063044000",
      "region_desc": "06"
    },
    {
      "city_code": "063045",
      "city_name": "Tigbauan",
      "province_code": "0630",
      "psgc_code": "063045000",
      "region_desc": "06"
    },
    {
      "city_code": "063046",
      "city_name": "Tubungan",
      "province_code": "0630",
      "psgc_code": "063046000",
      "region_desc": "06"
    },
    {
      "city_code": "063047",
      "city_name": "Zarraga",
      "province_code": "0630",
      "psgc_code": "063047000",
      "region_desc": "06"
    },
    {
      "city_code": "064501",
      "city_name": "Bacolod City (Capital)",
      "province_code": "0645",
      "psgc_code": "064501000",
      "region_desc": "06"
    },
    {
      "city_code": "064502",
      "city_name": "Bago City",
      "province_code": "0645",
      "psgc_code": "064502000",
      "region_desc": "06"
    },
    {
      "city_code": "064503",
      "city_name": "Binalbagan",
      "province_code": "0645",
      "psgc_code": "064503000",
      "region_desc": "06"
    },
    {
      "city_code": "064504",
      "city_name": "Cadiz City",
      "province_code": "0645",
      "psgc_code": "064504000",
      "region_desc": "06"
    },
    {
      "city_code": "064505",
      "city_name": "Calatrava",
      "province_code": "0645",
      "psgc_code": "064505000",
      "region_desc": "06"
    },
    {
      "city_code": "064506",
      "city_name": "Candoni",
      "province_code": "0645",
      "psgc_code": "064506000",
      "region_desc": "06"
    },
    {
      "city_code": "064507",
      "city_name": "Cauayan",
      "province_code": "0645",
      "psgc_code": "064507000",
      "region_desc": "06"
    },
    {
      "city_code": "064508",
      "city_name": "Enrique B. Magalona (Saravia)",
      "province_code": "0645",
      "psgc_code": "064508000",
      "region_desc": "06"
    },
    {
      "city_code": "064509",
      "city_name": "City Of Escalante",
      "province_code": "0645",
      "psgc_code": "064509000",
      "region_desc": "06"
    },
    {
      "city_code": "064510",
      "city_name": "City Of Himamaylan",
      "province_code": "0645",
      "psgc_code": "064510000",
      "region_desc": "06"
    },
    {
      "city_code": "064511",
      "city_name": "Hinigaran",
      "province_code": "0645",
      "psgc_code": "064511000",
      "region_desc": "06"
    },
    {
      "city_code": "064512",
      "city_name": "Hinoba-an (Asia)",
      "province_code": "0645",
      "psgc_code": "064512000",
      "region_desc": "06"
    },
    {
      "city_code": "064513",
      "city_name": "Ilog",
      "province_code": "0645",
      "psgc_code": "064513000",
      "region_desc": "06"
    },
    {
      "city_code": "064514",
      "city_name": "Isabela",
      "province_code": "0645",
      "psgc_code": "064514000",
      "region_desc": "06"
    },
    {
      "city_code": "064515",
      "city_name": "City Of Kabankalan",
      "province_code": "0645",
      "psgc_code": "064515000",
      "region_desc": "06"
    },
    {
      "city_code": "064516",
      "city_name": "La Carlota City",
      "province_code": "0645",
      "psgc_code": "064516000",
      "region_desc": "06"
    },
    {
      "city_code": "064517",
      "city_name": "La Castellana",
      "province_code": "0645",
      "psgc_code": "064517000",
      "region_desc": "06"
    },
    {
      "city_code": "064518",
      "city_name": "Manapla",
      "province_code": "0645",
      "psgc_code": "064518000",
      "region_desc": "06"
    },
    {
      "city_code": "064519",
      "city_name": "Moises Padilla (Magallon)",
      "province_code": "0645",
      "psgc_code": "064519000",
      "region_desc": "06"
    },
    {
      "city_code": "064520",
      "city_name": "Murcia",
      "province_code": "0645",
      "psgc_code": "064520000",
      "region_desc": "06"
    },
    {
      "city_code": "064521",
      "city_name": "Pontevedra",
      "province_code": "0645",
      "psgc_code": "064521000",
      "region_desc": "06"
    },
    {
      "city_code": "064522",
      "city_name": "Pulupandan",
      "province_code": "0645",
      "psgc_code": "064522000",
      "region_desc": "06"
    },
    {
      "city_code": "064523",
      "city_name": "Sagay City",
      "province_code": "0645",
      "psgc_code": "064523000",
      "region_desc": "06"
    },
    {
      "city_code": "064524",
      "city_name": "San Carlos City",
      "province_code": "0645",
      "psgc_code": "064524000",
      "region_desc": "06"
    },
    {
      "city_code": "064525",
      "city_name": "San Enrique",
      "province_code": "0645",
      "psgc_code": "064525000",
      "region_desc": "06"
    },
    {
      "city_code": "064526",
      "city_name": "Silay City",
      "province_code": "0645",
      "psgc_code": "064526000",
      "region_desc": "06"
    },
    {
      "city_code": "064527",
      "city_name": "City Of Sipalay",
      "province_code": "0645",
      "psgc_code": "064527000",
      "region_desc": "06"
    },
    {
      "city_code": "064528",
      "city_name": "City Of Talisay",
      "province_code": "0645",
      "psgc_code": "064528000",
      "region_desc": "06"
    },
    {
      "city_code": "064529",
      "city_name": "Toboso",
      "province_code": "0645",
      "psgc_code": "064529000",
      "region_desc": "06"
    },
    {
      "city_code": "064530",
      "city_name": "Valladolid",
      "province_code": "0645",
      "psgc_code": "064530000",
      "region_desc": "06"
    },
    {
      "city_code": "064531",
      "city_name": "City Of Victorias",
      "province_code": "0645",
      "psgc_code": "064531000",
      "region_desc": "06"
    },
    {
      "city_code": "064532",
      "city_name": "Salvador Benedicto",
      "province_code": "0645",
      "psgc_code": "064532000",
      "region_desc": "06"
    },
    {
      "city_code": "067901",
      "city_name": "Buenavista",
      "province_code": "0679",
      "psgc_code": "067901000",
      "region_desc": "06"
    },
    {
      "city_code": "067902",
      "city_name": "Jordan (Capital)",
      "province_code": "0679",
      "psgc_code": "067902000",
      "region_desc": "06"
    },
    {
      "city_code": "067903",
      "city_name": "Nueva Valencia",
      "province_code": "0679",
      "psgc_code": "067903000",
      "region_desc": "06"
    },
    {
      "city_code": "067904",
      "city_name": "San Lorenzo",
      "province_code": "0679",
      "psgc_code": "067904000",
      "region_desc": "06"
    },
    {
      "city_code": "067905",
      "city_name": "Sibunag",
      "province_code": "0679",
      "psgc_code": "067905000",
      "region_desc": "06"
    },
    {
      "city_code": "071201",
      "city_name": "Alburquerque",
      "province_code": "0712",
      "psgc_code": "071201000",
      "region_desc": "07"
    },
    {
      "city_code": "071202",
      "city_name": "Alicia",
      "province_code": "0712",
      "psgc_code": "071202000",
      "region_desc": "07"
    },
    {
      "city_code": "071203",
      "city_name": "Anda",
      "province_code": "0712",
      "psgc_code": "071203000",
      "region_desc": "07"
    },
    {
      "city_code": "071204",
      "city_name": "Antequera",
      "province_code": "0712",
      "psgc_code": "071204000",
      "region_desc": "07"
    },
    {
      "city_code": "071205",
      "city_name": "Baclayon",
      "province_code": "0712",
      "psgc_code": "071205000",
      "region_desc": "07"
    },
    {
      "city_code": "071206",
      "city_name": "Balilihan",
      "province_code": "0712",
      "psgc_code": "071206000",
      "region_desc": "07"
    },
    {
      "city_code": "071207",
      "city_name": "Batuan",
      "province_code": "0712",
      "psgc_code": "071207000",
      "region_desc": "07"
    },
    {
      "city_code": "071208",
      "city_name": "Bilar",
      "province_code": "0712",
      "psgc_code": "071208000",
      "region_desc": "07"
    },
    {
      "city_code": "071209",
      "city_name": "Buenavista",
      "province_code": "0712",
      "psgc_code": "071209000",
      "region_desc": "07"
    },
    {
      "city_code": "071210",
      "city_name": "Calape",
      "province_code": "0712",
      "psgc_code": "071210000",
      "region_desc": "07"
    },
    {
      "city_code": "071211",
      "city_name": "Candijay",
      "province_code": "0712",
      "psgc_code": "071211000",
      "region_desc": "07"
    },
    {
      "city_code": "071212",
      "city_name": "Carmen",
      "province_code": "0712",
      "psgc_code": "071212000",
      "region_desc": "07"
    },
    {
      "city_code": "071213",
      "city_name": "Catigbian",
      "province_code": "0712",
      "psgc_code": "071213000",
      "region_desc": "07"
    },
    {
      "city_code": "071214",
      "city_name": "Clarin",
      "province_code": "0712",
      "psgc_code": "071214000",
      "region_desc": "07"
    },
    {
      "city_code": "071215",
      "city_name": "Corella",
      "province_code": "0712",
      "psgc_code": "071215000",
      "region_desc": "07"
    },
    {
      "city_code": "071216",
      "city_name": "Cortes",
      "province_code": "0712",
      "psgc_code": "071216000",
      "region_desc": "07"
    },
    {
      "city_code": "071217",
      "city_name": "Dagohoy",
      "province_code": "0712",
      "psgc_code": "071217000",
      "region_desc": "07"
    },
    {
      "city_code": "071218",
      "city_name": "Danao",
      "province_code": "0712",
      "psgc_code": "071218000",
      "region_desc": "07"
    },
    {
      "city_code": "071219",
      "city_name": "Dauis",
      "province_code": "0712",
      "psgc_code": "071219000",
      "region_desc": "07"
    },
    {
      "city_code": "071220",
      "city_name": "Dimiao",
      "province_code": "0712",
      "psgc_code": "071220000",
      "region_desc": "07"
    },
    {
      "city_code": "071221",
      "city_name": "Duero",
      "province_code": "0712",
      "psgc_code": "071221000",
      "region_desc": "07"
    },
    {
      "city_code": "071222",
      "city_name": "Garcia Hernandez",
      "province_code": "0712",
      "psgc_code": "071222000",
      "region_desc": "07"
    },
    {
      "city_code": "071223",
      "city_name": "Guindulman",
      "province_code": "0712",
      "psgc_code": "071223000",
      "region_desc": "07"
    },
    {
      "city_code": "071224",
      "city_name": "Inabanga",
      "province_code": "0712",
      "psgc_code": "071224000",
      "region_desc": "07"
    },
    {
      "city_code": "071225",
      "city_name": "Jagna",
      "province_code": "0712",
      "psgc_code": "071225000",
      "region_desc": "07"
    },
    {
      "city_code": "071226",
      "city_name": "Jetafe",
      "province_code": "0712",
      "psgc_code": "071226000",
      "region_desc": "07"
    },
    {
      "city_code": "071227",
      "city_name": "Lila",
      "province_code": "0712",
      "psgc_code": "071227000",
      "region_desc": "07"
    },
    {
      "city_code": "071228",
      "city_name": "Loay",
      "province_code": "0712",
      "psgc_code": "071228000",
      "region_desc": "07"
    },
    {
      "city_code": "071229",
      "city_name": "Loboc",
      "province_code": "0712",
      "psgc_code": "071229000",
      "region_desc": "07"
    },
    {
      "city_code": "071230",
      "city_name": "Loon",
      "province_code": "0712",
      "psgc_code": "071230000",
      "region_desc": "07"
    },
    {
      "city_code": "071231",
      "city_name": "Mabini",
      "province_code": "0712",
      "psgc_code": "071231000",
      "region_desc": "07"
    },
    {
      "city_code": "071232",
      "city_name": "Maribojoc",
      "province_code": "0712",
      "psgc_code": "071232000",
      "region_desc": "07"
    },
    {
      "city_code": "071233",
      "city_name": "Panglao",
      "province_code": "0712",
      "psgc_code": "071233000",
      "region_desc": "07"
    },
    {
      "city_code": "071234",
      "city_name": "Pilar",
      "province_code": "0712",
      "psgc_code": "071234000",
      "region_desc": "07"
    },
    {
      "city_code": "071235",
      "city_name": "Pres. Carlos P. Garcia (Pitogo)",
      "province_code": "0712",
      "psgc_code": "071235000",
      "region_desc": "07"
    },
    {
      "city_code": "071236",
      "city_name": "Sagbayan (Borja)",
      "province_code": "0712",
      "psgc_code": "071236000",
      "region_desc": "07"
    },
    {
      "city_code": "071237",
      "city_name": "San Isidro",
      "province_code": "0712",
      "psgc_code": "071237000",
      "region_desc": "07"
    },
    {
      "city_code": "071238",
      "city_name": "San Miguel",
      "province_code": "0712",
      "psgc_code": "071238000",
      "region_desc": "07"
    },
    {
      "city_code": "071239",
      "city_name": "Sevilla",
      "province_code": "0712",
      "psgc_code": "071239000",
      "region_desc": "07"
    },
    {
      "city_code": "071240",
      "city_name": "Sierra Bullones",
      "province_code": "0712",
      "psgc_code": "071240000",
      "region_desc": "07"
    },
    {
      "city_code": "071241",
      "city_name": "Sikatuna",
      "province_code": "0712",
      "psgc_code": "071241000",
      "region_desc": "07"
    },
    {
      "city_code": "071242",
      "city_name": "Tagbilaran City (Capital)",
      "province_code": "0712",
      "psgc_code": "071242000",
      "region_desc": "07"
    },
    {
      "city_code": "071243",
      "city_name": "Talibon",
      "province_code": "0712",
      "psgc_code": "071243000",
      "region_desc": "07"
    },
    {
      "city_code": "071244",
      "city_name": "Trinidad",
      "province_code": "0712",
      "psgc_code": "071244000",
      "region_desc": "07"
    },
    {
      "city_code": "071245",
      "city_name": "Tubigon",
      "province_code": "0712",
      "psgc_code": "071245000",
      "region_desc": "07"
    },
    {
      "city_code": "071246",
      "city_name": "Ubay",
      "province_code": "0712",
      "psgc_code": "071246000",
      "region_desc": "07"
    },
    {
      "city_code": "071247",
      "city_name": "Valencia",
      "province_code": "0712",
      "psgc_code": "071247000",
      "region_desc": "07"
    },
    {
      "city_code": "071248",
      "city_name": "Bien Unido",
      "province_code": "0712",
      "psgc_code": "071248000",
      "region_desc": "07"
    },
    {
      "city_code": "072201",
      "city_name": "Alcantara",
      "province_code": "0722",
      "psgc_code": "072201000",
      "region_desc": "07"
    },
    {
      "city_code": "072202",
      "city_name": "Alcoy",
      "province_code": "0722",
      "psgc_code": "072202000",
      "region_desc": "07"
    },
    {
      "city_code": "072203",
      "city_name": "Alegria",
      "province_code": "0722",
      "psgc_code": "072203000",
      "region_desc": "07"
    },
    {
      "city_code": "072204",
      "city_name": "Aloguinsan",
      "province_code": "0722",
      "psgc_code": "072204000",
      "region_desc": "07"
    },
    {
      "city_code": "072205",
      "city_name": "Argao",
      "province_code": "0722",
      "psgc_code": "072205000",
      "region_desc": "07"
    },
    {
      "city_code": "072206",
      "city_name": "Asturias",
      "province_code": "0722",
      "psgc_code": "072206000",
      "region_desc": "07"
    },
    {
      "city_code": "072207",
      "city_name": "Badian",
      "province_code": "0722",
      "psgc_code": "072207000",
      "region_desc": "07"
    },
    {
      "city_code": "072208",
      "city_name": "Balamban",
      "province_code": "0722",
      "psgc_code": "072208000",
      "region_desc": "07"
    },
    {
      "city_code": "072209",
      "city_name": "Bantayan",
      "province_code": "0722",
      "psgc_code": "072209000",
      "region_desc": "07"
    },
    {
      "city_code": "072210",
      "city_name": "Barili",
      "province_code": "0722",
      "psgc_code": "072210000",
      "region_desc": "07"
    },
    {
      "city_code": "072211",
      "city_name": "City Of Bogo",
      "province_code": "0722",
      "psgc_code": "072211000",
      "region_desc": "07"
    },
    {
      "city_code": "072212",
      "city_name": "Boljoon",
      "province_code": "0722",
      "psgc_code": "072212000",
      "region_desc": "07"
    },
    {
      "city_code": "072213",
      "city_name": "Borbon",
      "province_code": "0722",
      "psgc_code": "072213000",
      "region_desc": "07"
    },
    {
      "city_code": "072214",
      "city_name": "City Of Carcar",
      "province_code": "0722",
      "psgc_code": "072214000",
      "region_desc": "07"
    },
    {
      "city_code": "072215",
      "city_name": "Carmen",
      "province_code": "0722",
      "psgc_code": "072215000",
      "region_desc": "07"
    },
    {
      "city_code": "072216",
      "city_name": "Catmon",
      "province_code": "0722",
      "psgc_code": "072216000",
      "region_desc": "07"
    },
    {
      "city_code": "072217",
      "city_name": "Cebu City (Capital)",
      "province_code": "0722",
      "psgc_code": "072217000",
      "region_desc": "07"
    },
    {
      "city_code": "072218",
      "city_name": "Compostela",
      "province_code": "0722",
      "psgc_code": "072218000",
      "region_desc": "07"
    },
    {
      "city_code": "072219",
      "city_name": "Consolacion",
      "province_code": "0722",
      "psgc_code": "072219000",
      "region_desc": "07"
    },
    {
      "city_code": "072220",
      "city_name": "Cordova",
      "province_code": "0722",
      "psgc_code": "072220000",
      "region_desc": "07"
    },
    {
      "city_code": "072221",
      "city_name": "Daanbantayan",
      "province_code": "0722",
      "psgc_code": "072221000",
      "region_desc": "07"
    },
    {
      "city_code": "072222",
      "city_name": "Dalaguete",
      "province_code": "0722",
      "psgc_code": "072222000",
      "region_desc": "07"
    },
    {
      "city_code": "072223",
      "city_name": "Danao City",
      "province_code": "0722",
      "psgc_code": "072223000",
      "region_desc": "07"
    },
    {
      "city_code": "072224",
      "city_name": "Dumanjug",
      "province_code": "0722",
      "psgc_code": "072224000",
      "region_desc": "07"
    },
    {
      "city_code": "072225",
      "city_name": "Ginatilan",
      "province_code": "0722",
      "psgc_code": "072225000",
      "region_desc": "07"
    },
    {
      "city_code": "072226",
      "city_name": "Lapu-lapu City (Opon)",
      "province_code": "0722",
      "psgc_code": "072226000",
      "region_desc": "07"
    },
    {
      "city_code": "072227",
      "city_name": "Liloan",
      "province_code": "0722",
      "psgc_code": "072227000",
      "region_desc": "07"
    },
    {
      "city_code": "072228",
      "city_name": "Madridejos",
      "province_code": "0722",
      "psgc_code": "072228000",
      "region_desc": "07"
    },
    {
      "city_code": "072229",
      "city_name": "Malabuyoc",
      "province_code": "0722",
      "psgc_code": "072229000",
      "region_desc": "07"
    },
    {
      "city_code": "072230",
      "city_name": "Mandaue City",
      "province_code": "0722",
      "psgc_code": "072230000",
      "region_desc": "07"
    },
    {
      "city_code": "072231",
      "city_name": "Medellin",
      "province_code": "0722",
      "psgc_code": "072231000",
      "region_desc": "07"
    },
    {
      "city_code": "072232",
      "city_name": "Minglanilla",
      "province_code": "0722",
      "psgc_code": "072232000",
      "region_desc": "07"
    },
    {
      "city_code": "072233",
      "city_name": "Moalboal",
      "province_code": "0722",
      "psgc_code": "072233000",
      "region_desc": "07"
    },
    {
      "city_code": "072234",
      "city_name": "City Of Naga",
      "province_code": "0722",
      "psgc_code": "072234000",
      "region_desc": "07"
    },
    {
      "city_code": "072235",
      "city_name": "Oslob",
      "province_code": "0722",
      "psgc_code": "072235000",
      "region_desc": "07"
    },
    {
      "city_code": "072236",
      "city_name": "Pilar",
      "province_code": "0722",
      "psgc_code": "072236000",
      "region_desc": "07"
    },
    {
      "city_code": "072237",
      "city_name": "Pinamungahan",
      "province_code": "0722",
      "psgc_code": "072237000",
      "region_desc": "07"
    },
    {
      "city_code": "072238",
      "city_name": "Poro",
      "province_code": "0722",
      "psgc_code": "072238000",
      "region_desc": "07"
    },
    {
      "city_code": "072239",
      "city_name": "Ronda",
      "province_code": "0722",
      "psgc_code": "072239000",
      "region_desc": "07"
    },
    {
      "city_code": "072240",
      "city_name": "Samboan",
      "province_code": "0722",
      "psgc_code": "072240000",
      "region_desc": "07"
    },
    {
      "city_code": "072241",
      "city_name": "San Fernando",
      "province_code": "0722",
      "psgc_code": "072241000",
      "region_desc": "07"
    },
    {
      "city_code": "072242",
      "city_name": "San Francisco",
      "province_code": "0722",
      "psgc_code": "072242000",
      "region_desc": "07"
    },
    {
      "city_code": "072243",
      "city_name": "San Remigio",
      "province_code": "0722",
      "psgc_code": "072243000",
      "region_desc": "07"
    },
    {
      "city_code": "072244",
      "city_name": "Santa Fe",
      "province_code": "0722",
      "psgc_code": "072244000",
      "region_desc": "07"
    },
    {
      "city_code": "072245",
      "city_name": "Santander",
      "province_code": "0722",
      "psgc_code": "072245000",
      "region_desc": "07"
    },
    {
      "city_code": "072246",
      "city_name": "Sibonga",
      "province_code": "0722",
      "psgc_code": "072246000",
      "region_desc": "07"
    },
    {
      "city_code": "072247",
      "city_name": "Sogod",
      "province_code": "0722",
      "psgc_code": "072247000",
      "region_desc": "07"
    },
    {
      "city_code": "072248",
      "city_name": "Tabogon",
      "province_code": "0722",
      "psgc_code": "072248000",
      "region_desc": "07"
    },
    {
      "city_code": "072249",
      "city_name": "Tabuelan",
      "province_code": "0722",
      "psgc_code": "072249000",
      "region_desc": "07"
    },
    {
      "city_code": "072250",
      "city_name": "City Of Talisay",
      "province_code": "0722",
      "psgc_code": "072250000",
      "region_desc": "07"
    },
    {
      "city_code": "072251",
      "city_name": "Toledo City",
      "province_code": "0722",
      "psgc_code": "072251000",
      "region_desc": "07"
    },
    {
      "city_code": "072252",
      "city_name": "Tuburan",
      "province_code": "0722",
      "psgc_code": "072252000",
      "region_desc": "07"
    },
    {
      "city_code": "072253",
      "city_name": "Tudela",
      "province_code": "0722",
      "psgc_code": "072253000",
      "region_desc": "07"
    },
    {
      "city_code": "074601",
      "city_name": "Amlan (Ayuquitan)",
      "province_code": "0746",
      "psgc_code": "074601000",
      "region_desc": "07"
    },
    {
      "city_code": "074602",
      "city_name": "Ayungon",
      "province_code": "0746",
      "psgc_code": "074602000",
      "region_desc": "07"
    },
    {
      "city_code": "074603",
      "city_name": "Bacong",
      "province_code": "0746",
      "psgc_code": "074603000",
      "region_desc": "07"
    },
    {
      "city_code": "074604",
      "city_name": "Bais City",
      "province_code": "0746",
      "psgc_code": "074604000",
      "region_desc": "07"
    },
    {
      "city_code": "074605",
      "city_name": "Basay",
      "province_code": "0746",
      "psgc_code": "074605000",
      "region_desc": "07"
    },
    {
      "city_code": "074606",
      "city_name": "City Of Bayawan (Tulong)",
      "province_code": "0746",
      "psgc_code": "074606000",
      "region_desc": "07"
    },
    {
      "city_code": "074607",
      "city_name": "Bindoy (Payabon)",
      "province_code": "0746",
      "psgc_code": "074607000",
      "region_desc": "07"
    },
    {
      "city_code": "074608",
      "city_name": "Canlaon City",
      "province_code": "0746",
      "psgc_code": "074608000",
      "region_desc": "07"
    },
    {
      "city_code": "074609",
      "city_name": "Dauin",
      "province_code": "0746",
      "psgc_code": "074609000",
      "region_desc": "07"
    },
    {
      "city_code": "074610",
      "city_name": "Dumaguete City (Capital)",
      "province_code": "0746",
      "psgc_code": "074610000",
      "region_desc": "07"
    },
    {
      "city_code": "074611",
      "city_name": "City Of Guihulngan",
      "province_code": "0746",
      "psgc_code": "074611000",
      "region_desc": "07"
    },
    {
      "city_code": "074612",
      "city_name": "Jimalalud",
      "province_code": "0746",
      "psgc_code": "074612000",
      "region_desc": "07"
    },
    {
      "city_code": "074613",
      "city_name": "La Libertad",
      "province_code": "0746",
      "psgc_code": "074613000",
      "region_desc": "07"
    },
    {
      "city_code": "074614",
      "city_name": "Mabinay",
      "province_code": "0746",
      "psgc_code": "074614000",
      "region_desc": "07"
    },
    {
      "city_code": "074615",
      "city_name": "Manjuyod",
      "province_code": "0746",
      "psgc_code": "074615000",
      "region_desc": "07"
    },
    {
      "city_code": "074616",
      "city_name": "Pamplona",
      "province_code": "0746",
      "psgc_code": "074616000",
      "region_desc": "07"
    },
    {
      "city_code": "074617",
      "city_name": "San Jose",
      "province_code": "0746",
      "psgc_code": "074617000",
      "region_desc": "07"
    },
    {
      "city_code": "074618",
      "city_name": "Santa Catalina",
      "province_code": "0746",
      "psgc_code": "074618000",
      "region_desc": "07"
    },
    {
      "city_code": "074619",
      "city_name": "Siaton",
      "province_code": "0746",
      "psgc_code": "074619000",
      "region_desc": "07"
    },
    {
      "city_code": "074620",
      "city_name": "Sibulan",
      "province_code": "0746",
      "psgc_code": "074620000",
      "region_desc": "07"
    },
    {
      "city_code": "074621",
      "city_name": "City Of Tanjay",
      "province_code": "0746",
      "psgc_code": "074621000",
      "region_desc": "07"
    },
    {
      "city_code": "074622",
      "city_name": "Tayasan",
      "province_code": "0746",
      "psgc_code": "074622000",
      "region_desc": "07"
    },
    {
      "city_code": "074623",
      "city_name": "Valencia (Luzurriaga)",
      "province_code": "0746",
      "psgc_code": "074623000",
      "region_desc": "07"
    },
    {
      "city_code": "074624",
      "city_name": "Vallehermoso",
      "province_code": "0746",
      "psgc_code": "074624000",
      "region_desc": "07"
    },
    {
      "city_code": "074625",
      "city_name": "Zamboanguita",
      "province_code": "0746",
      "psgc_code": "074625000",
      "region_desc": "07"
    },
    {
      "city_code": "076101",
      "city_name": "Enrique Villanueva",
      "province_code": "0761",
      "psgc_code": "076101000",
      "region_desc": "07"
    },
    {
      "city_code": "076102",
      "city_name": "Larena",
      "province_code": "0761",
      "psgc_code": "076102000",
      "region_desc": "07"
    },
    {
      "city_code": "076103",
      "city_name": "Lazi",
      "province_code": "0761",
      "psgc_code": "076103000",
      "region_desc": "07"
    },
    {
      "city_code": "076104",
      "city_name": "Maria",
      "province_code": "0761",
      "psgc_code": "076104000",
      "region_desc": "07"
    },
    {
      "city_code": "076105",
      "city_name": "San Juan",
      "province_code": "0761",
      "psgc_code": "076105000",
      "region_desc": "07"
    },
    {
      "city_code": "076106",
      "city_name": "Siquijor (Capital)",
      "province_code": "0761",
      "psgc_code": "076106000",
      "region_desc": "07"
    },
    {
      "city_code": "082601",
      "city_name": "Arteche",
      "province_code": "0826",
      "psgc_code": "082601000",
      "region_desc": "08"
    },
    {
      "city_code": "082602",
      "city_name": "Balangiga",
      "province_code": "0826",
      "psgc_code": "082602000",
      "region_desc": "08"
    },
    {
      "city_code": "082603",
      "city_name": "Balangkayan",
      "province_code": "0826",
      "psgc_code": "082603000",
      "region_desc": "08"
    },
    {
      "city_code": "082604",
      "city_name": "City Of Borongan (Capital)",
      "province_code": "0826",
      "psgc_code": "082604000",
      "region_desc": "08"
    },
    {
      "city_code": "082605",
      "city_name": "Can-avid",
      "province_code": "0826",
      "psgc_code": "082605000",
      "region_desc": "08"
    },
    {
      "city_code": "082606",
      "city_name": "Dolores",
      "province_code": "0826",
      "psgc_code": "082606000",
      "region_desc": "08"
    },
    {
      "city_code": "082607",
      "city_name": "General Macarthur",
      "province_code": "0826",
      "psgc_code": "082607000",
      "region_desc": "08"
    },
    {
      "city_code": "082608",
      "city_name": "Giporlos",
      "province_code": "0826",
      "psgc_code": "082608000",
      "region_desc": "08"
    },
    {
      "city_code": "082609",
      "city_name": "Guiuan",
      "province_code": "0826",
      "psgc_code": "082609000",
      "region_desc": "08"
    },
    {
      "city_code": "082610",
      "city_name": "Hernani",
      "province_code": "0826",
      "psgc_code": "082610000",
      "region_desc": "08"
    },
    {
      "city_code": "082611",
      "city_name": "Jipapad",
      "province_code": "0826",
      "psgc_code": "082611000",
      "region_desc": "08"
    },
    {
      "city_code": "082612",
      "city_name": "Lawaan",
      "province_code": "0826",
      "psgc_code": "082612000",
      "region_desc": "08"
    },
    {
      "city_code": "082613",
      "city_name": "Llorente",
      "province_code": "0826",
      "psgc_code": "082613000",
      "region_desc": "08"
    },
    {
      "city_code": "082614",
      "city_name": "Maslog",
      "province_code": "0826",
      "psgc_code": "082614000",
      "region_desc": "08"
    },
    {
      "city_code": "082615",
      "city_name": "Maydolong",
      "province_code": "0826",
      "psgc_code": "082615000",
      "region_desc": "08"
    },
    {
      "city_code": "082616",
      "city_name": "Mercedes",
      "province_code": "0826",
      "psgc_code": "082616000",
      "region_desc": "08"
    },
    {
      "city_code": "082617",
      "city_name": "Oras",
      "province_code": "0826",
      "psgc_code": "082617000",
      "region_desc": "08"
    },
    {
      "city_code": "082618",
      "city_name": "Quinapondan",
      "province_code": "0826",
      "psgc_code": "082618000",
      "region_desc": "08"
    },
    {
      "city_code": "082619",
      "city_name": "Salcedo",
      "province_code": "0826",
      "psgc_code": "082619000",
      "region_desc": "08"
    },
    {
      "city_code": "082620",
      "city_name": "San Julian",
      "province_code": "0826",
      "psgc_code": "082620000",
      "region_desc": "08"
    },
    {
      "city_code": "082621",
      "city_name": "San Policarpo",
      "province_code": "0826",
      "psgc_code": "082621000",
      "region_desc": "08"
    },
    {
      "city_code": "082622",
      "city_name": "Sulat",
      "province_code": "0826",
      "psgc_code": "082622000",
      "region_desc": "08"
    },
    {
      "city_code": "082623",
      "city_name": "Taft",
      "province_code": "0826",
      "psgc_code": "082623000",
      "region_desc": "08"
    },
    {
      "city_code": "083701",
      "city_name": "Abuyog",
      "province_code": "0837",
      "psgc_code": "083701000",
      "region_desc": "08"
    },
    {
      "city_code": "083702",
      "city_name": "Alangalang",
      "province_code": "0837",
      "psgc_code": "083702000",
      "region_desc": "08"
    },
    {
      "city_code": "083703",
      "city_name": "Albuera",
      "province_code": "0837",
      "psgc_code": "083703000",
      "region_desc": "08"
    },
    {
      "city_code": "083705",
      "city_name": "Babatngon",
      "province_code": "0837",
      "psgc_code": "083705000",
      "region_desc": "08"
    },
    {
      "city_code": "083706",
      "city_name": "Barugo",
      "province_code": "0837",
      "psgc_code": "083706000",
      "region_desc": "08"
    },
    {
      "city_code": "083707",
      "city_name": "Bato",
      "province_code": "0837",
      "psgc_code": "083707000",
      "region_desc": "08"
    },
    {
      "city_code": "083708",
      "city_name": "City Of Baybay",
      "province_code": "0837",
      "psgc_code": "083708000",
      "region_desc": "08"
    },
    {
      "city_code": "083710",
      "city_name": "Burauen",
      "province_code": "0837",
      "psgc_code": "083710000",
      "region_desc": "08"
    },
    {
      "city_code": "083713",
      "city_name": "Calubian",
      "province_code": "0837",
      "psgc_code": "083713000",
      "region_desc": "08"
    },
    {
      "city_code": "083714",
      "city_name": "Capoocan",
      "province_code": "0837",
      "psgc_code": "083714000",
      "region_desc": "08"
    },
    {
      "city_code": "083715",
      "city_name": "Carigara",
      "province_code": "0837",
      "psgc_code": "083715000",
      "region_desc": "08"
    },
    {
      "city_code": "083717",
      "city_name": "Dagami",
      "province_code": "0837",
      "psgc_code": "083717000",
      "region_desc": "08"
    },
    {
      "city_code": "083718",
      "city_name": "Dulag",
      "province_code": "0837",
      "psgc_code": "083718000",
      "region_desc": "08"
    },
    {
      "city_code": "083719",
      "city_name": "Hilongos",
      "province_code": "0837",
      "psgc_code": "083719000",
      "region_desc": "08"
    },
    {
      "city_code": "083720",
      "city_name": "Hindang",
      "province_code": "0837",
      "psgc_code": "083720000",
      "region_desc": "08"
    },
    {
      "city_code": "083721",
      "city_name": "Inopacan",
      "province_code": "0837",
      "psgc_code": "083721000",
      "region_desc": "08"
    },
    {
      "city_code": "083722",
      "city_name": "Isabel",
      "province_code": "0837",
      "psgc_code": "083722000",
      "region_desc": "08"
    },
    {
      "city_code": "083723",
      "city_name": "Jaro",
      "province_code": "0837",
      "psgc_code": "083723000",
      "region_desc": "08"
    },
    {
      "city_code": "083724",
      "city_name": "Javier (Bugho)",
      "province_code": "0837",
      "psgc_code": "083724000",
      "region_desc": "08"
    },
    {
      "city_code": "083725",
      "city_name": "Julita",
      "province_code": "0837",
      "psgc_code": "083725000",
      "region_desc": "08"
    },
    {
      "city_code": "083726",
      "city_name": "Kananga",
      "province_code": "0837",
      "psgc_code": "083726000",
      "region_desc": "08"
    },
    {
      "city_code": "083728",
      "city_name": "La Paz",
      "province_code": "0837",
      "psgc_code": "083728000",
      "region_desc": "08"
    },
    {
      "city_code": "083729",
      "city_name": "Leyte",
      "province_code": "0837",
      "psgc_code": "083729000",
      "region_desc": "08"
    },
    {
      "city_code": "083730",
      "city_name": "Macarthur",
      "province_code": "0837",
      "psgc_code": "083730000",
      "region_desc": "08"
    },
    {
      "city_code": "083731",
      "city_name": "Mahaplag",
      "province_code": "0837",
      "psgc_code": "083731000",
      "region_desc": "08"
    },
    {
      "city_code": "083733",
      "city_name": "Matag-ob",
      "province_code": "0837",
      "psgc_code": "083733000",
      "region_desc": "08"
    },
    {
      "city_code": "083734",
      "city_name": "Matalom",
      "province_code": "0837",
      "psgc_code": "083734000",
      "region_desc": "08"
    },
    {
      "city_code": "083735",
      "city_name": "Mayorga",
      "province_code": "0837",
      "psgc_code": "083735000",
      "region_desc": "08"
    },
    {
      "city_code": "083736",
      "city_name": "Merida",
      "province_code": "0837",
      "psgc_code": "083736000",
      "region_desc": "08"
    },
    {
      "city_code": "083738",
      "city_name": "Ormoc City",
      "province_code": "0837",
      "psgc_code": "083738000",
      "region_desc": "08"
    },
    {
      "city_code": "083739",
      "city_name": "Palo",
      "province_code": "0837",
      "psgc_code": "083739000",
      "region_desc": "08"
    },
    {
      "city_code": "083740",
      "city_name": "Palompon",
      "province_code": "0837",
      "psgc_code": "083740000",
      "region_desc": "08"
    },
    {
      "city_code": "083741",
      "city_name": "Pastrana",
      "province_code": "0837",
      "psgc_code": "083741000",
      "region_desc": "08"
    },
    {
      "city_code": "083742",
      "city_name": "San Isidro",
      "province_code": "0837",
      "psgc_code": "083742000",
      "region_desc": "08"
    },
    {
      "city_code": "083743",
      "city_name": "San Miguel",
      "province_code": "0837",
      "psgc_code": "083743000",
      "region_desc": "08"
    },
    {
      "city_code": "083744",
      "city_name": "Santa Fe",
      "province_code": "0837",
      "psgc_code": "083744000",
      "region_desc": "08"
    },
    {
      "city_code": "083745",
      "city_name": "Tabango",
      "province_code": "0837",
      "psgc_code": "083745000",
      "region_desc": "08"
    },
    {
      "city_code": "083746",
      "city_name": "Tabontabon",
      "province_code": "0837",
      "psgc_code": "083746000",
      "region_desc": "08"
    },
    {
      "city_code": "083747",
      "city_name": "Tacloban City (Capital)",
      "province_code": "0837",
      "psgc_code": "083747000",
      "region_desc": "08"
    },
    {
      "city_code": "083748",
      "city_name": "Tanauan",
      "province_code": "0837",
      "psgc_code": "083748000",
      "region_desc": "08"
    },
    {
      "city_code": "083749",
      "city_name": "Tolosa",
      "province_code": "0837",
      "psgc_code": "083749000",
      "region_desc": "08"
    },
    {
      "city_code": "083750",
      "city_name": "Tunga",
      "province_code": "0837",
      "psgc_code": "083750000",
      "region_desc": "08"
    },
    {
      "city_code": "083751",
      "city_name": "Villaba",
      "province_code": "0837",
      "psgc_code": "083751000",
      "region_desc": "08"
    },
    {
      "city_code": "084801",
      "city_name": "Allen",
      "province_code": "0848",
      "psgc_code": "084801000",
      "region_desc": "08"
    },
    {
      "city_code": "084802",
      "city_name": "Biri",
      "province_code": "0848",
      "psgc_code": "084802000",
      "region_desc": "08"
    },
    {
      "city_code": "084803",
      "city_name": "Bobon",
      "province_code": "0848",
      "psgc_code": "084803000",
      "region_desc": "08"
    },
    {
      "city_code": "084804",
      "city_name": "Capul",
      "province_code": "0848",
      "psgc_code": "084804000",
      "region_desc": "08"
    },
    {
      "city_code": "084805",
      "city_name": "Catarman (Capital)",
      "province_code": "0848",
      "psgc_code": "084805000",
      "region_desc": "08"
    },
    {
      "city_code": "084806",
      "city_name": "Catubig",
      "province_code": "0848",
      "psgc_code": "084806000",
      "region_desc": "08"
    },
    {
      "city_code": "084807",
      "city_name": "Gamay",
      "province_code": "0848",
      "psgc_code": "084807000",
      "region_desc": "08"
    },
    {
      "city_code": "084808",
      "city_name": "Laoang",
      "province_code": "0848",
      "psgc_code": "084808000",
      "region_desc": "08"
    },
    {
      "city_code": "084809",
      "city_name": "Lapinig",
      "province_code": "0848",
      "psgc_code": "084809000",
      "region_desc": "08"
    },
    {
      "city_code": "084810",
      "city_name": "Las Navas",
      "province_code": "0848",
      "psgc_code": "084810000",
      "region_desc": "08"
    },
    {
      "city_code": "084811",
      "city_name": "Lavezares",
      "province_code": "0848",
      "psgc_code": "084811000",
      "region_desc": "08"
    },
    {
      "city_code": "084812",
      "city_name": "Mapanas",
      "province_code": "0848",
      "psgc_code": "084812000",
      "region_desc": "08"
    },
    {
      "city_code": "084813",
      "city_name": "Mondragon",
      "province_code": "0848",
      "psgc_code": "084813000",
      "region_desc": "08"
    },
    {
      "city_code": "084814",
      "city_name": "Palapag",
      "province_code": "0848",
      "psgc_code": "084814000",
      "region_desc": "08"
    },
    {
      "city_code": "084815",
      "city_name": "Pambujan",
      "province_code": "0848",
      "psgc_code": "084815000",
      "region_desc": "08"
    },
    {
      "city_code": "084816",
      "city_name": "Rosario",
      "province_code": "0848",
      "psgc_code": "084816000",
      "region_desc": "08"
    },
    {
      "city_code": "084817",
      "city_name": "San Antonio",
      "province_code": "0848",
      "psgc_code": "084817000",
      "region_desc": "08"
    },
    {
      "city_code": "084818",
      "city_name": "San Isidro",
      "province_code": "0848",
      "psgc_code": "084818000",
      "region_desc": "08"
    },
    {
      "city_code": "084819",
      "city_name": "San Jose",
      "province_code": "0848",
      "psgc_code": "084819000",
      "region_desc": "08"
    },
    {
      "city_code": "084820",
      "city_name": "San Roque",
      "province_code": "0848",
      "psgc_code": "084820000",
      "region_desc": "08"
    },
    {
      "city_code": "084821",
      "city_name": "San Vicente",
      "province_code": "0848",
      "psgc_code": "084821000",
      "region_desc": "08"
    },
    {
      "city_code": "084822",
      "city_name": "Silvino Lobos",
      "province_code": "0848",
      "psgc_code": "084822000",
      "region_desc": "08"
    },
    {
      "city_code": "084823",
      "city_name": "Victoria",
      "province_code": "0848",
      "psgc_code": "084823000",
      "region_desc": "08"
    },
    {
      "city_code": "084824",
      "city_name": "Lope De Vega",
      "province_code": "0848",
      "psgc_code": "084824000",
      "region_desc": "08"
    },
    {
      "city_code": "086001",
      "city_name": "Almagro",
      "province_code": "0860",
      "psgc_code": "086001000",
      "region_desc": "08"
    },
    {
      "city_code": "086002",
      "city_name": "Basey",
      "province_code": "0860",
      "psgc_code": "086002000",
      "region_desc": "08"
    },
    {
      "city_code": "086003",
      "city_name": "Calbayog City",
      "province_code": "0860",
      "psgc_code": "086003000",
      "region_desc": "08"
    },
    {
      "city_code": "086004",
      "city_name": "Calbiga",
      "province_code": "0860",
      "psgc_code": "086004000",
      "region_desc": "08"
    },
    {
      "city_code": "086005",
      "city_name": "City Of Catbalogan (Capital)",
      "province_code": "0860",
      "psgc_code": "086005000",
      "region_desc": "08"
    },
    {
      "city_code": "086006",
      "city_name": "Daram",
      "province_code": "0860",
      "psgc_code": "086006000",
      "region_desc": "08"
    },
    {
      "city_code": "086007",
      "city_name": "Gandara",
      "province_code": "0860",
      "psgc_code": "086007000",
      "region_desc": "08"
    },
    {
      "city_code": "086008",
      "city_name": "Hinabangan",
      "province_code": "0860",
      "psgc_code": "086008000",
      "region_desc": "08"
    },
    {
      "city_code": "086009",
      "city_name": "Jiabong",
      "province_code": "0860",
      "psgc_code": "086009000",
      "region_desc": "08"
    },
    {
      "city_code": "086010",
      "city_name": "Marabut",
      "province_code": "0860",
      "psgc_code": "086010000",
      "region_desc": "08"
    },
    {
      "city_code": "086011",
      "city_name": "Matuguinao",
      "province_code": "0860",
      "psgc_code": "086011000",
      "region_desc": "08"
    },
    {
      "city_code": "086012",
      "city_name": "Motiong",
      "province_code": "0860",
      "psgc_code": "086012000",
      "region_desc": "08"
    },
    {
      "city_code": "086013",
      "city_name": "Pinabacdao",
      "province_code": "0860",
      "psgc_code": "086013000",
      "region_desc": "08"
    },
    {
      "city_code": "086014",
      "city_name": "San Jose De Buan",
      "province_code": "0860",
      "psgc_code": "086014000",
      "region_desc": "08"
    },
    {
      "city_code": "086015",
      "city_name": "San Sebastian",
      "province_code": "0860",
      "psgc_code": "086015000",
      "region_desc": "08"
    },
    {
      "city_code": "086016",
      "city_name": "Santa Margarita",
      "province_code": "0860",
      "psgc_code": "086016000",
      "region_desc": "08"
    },
    {
      "city_code": "086017",
      "city_name": "Santa Rita",
      "province_code": "0860",
      "psgc_code": "086017000",
      "region_desc": "08"
    },
    {
      "city_code": "086018",
      "city_name": "Santo Niño",
      "province_code": "0860",
      "psgc_code": "086018000",
      "region_desc": "08"
    },
    {
      "city_code": "086019",
      "city_name": "Talalora",
      "province_code": "0860",
      "psgc_code": "086019000",
      "region_desc": "08"
    },
    {
      "city_code": "086020",
      "city_name": "Tarangnan",
      "province_code": "0860",
      "psgc_code": "086020000",
      "region_desc": "08"
    },
    {
      "city_code": "086021",
      "city_name": "Villareal",
      "province_code": "0860",
      "psgc_code": "086021000",
      "region_desc": "08"
    },
    {
      "city_code": "086022",
      "city_name": "Paranas (Wright)",
      "province_code": "0860",
      "psgc_code": "086022000",
      "region_desc": "08"
    },
    {
      "city_code": "086023",
      "city_name": "Zumarraga",
      "province_code": "0860",
      "psgc_code": "086023000",
      "region_desc": "08"
    },
    {
      "city_code": "086024",
      "city_name": "Tagapul-an",
      "province_code": "0860",
      "psgc_code": "086024000",
      "region_desc": "08"
    },
    {
      "city_code": "086025",
      "city_name": "San Jorge",
      "province_code": "0860",
      "psgc_code": "086025000",
      "region_desc": "08"
    },
    {
      "city_code": "086026",
      "city_name": "Pagsanghan",
      "province_code": "0860",
      "psgc_code": "086026000",
      "region_desc": "08"
    },
    {
      "city_code": "086401",
      "city_name": "Anahawan",
      "province_code": "0864",
      "psgc_code": "086401000",
      "region_desc": "08"
    },
    {
      "city_code": "086402",
      "city_name": "Bontoc",
      "province_code": "0864",
      "psgc_code": "086402000",
      "region_desc": "08"
    },
    {
      "city_code": "086403",
      "city_name": "Hinunangan",
      "province_code": "0864",
      "psgc_code": "086403000",
      "region_desc": "08"
    },
    {
      "city_code": "086404",
      "city_name": "Hinundayan",
      "province_code": "0864",
      "psgc_code": "086404000",
      "region_desc": "08"
    },
    {
      "city_code": "086405",
      "city_name": "Libagon",
      "province_code": "0864",
      "psgc_code": "086405000",
      "region_desc": "08"
    },
    {
      "city_code": "086406",
      "city_name": "Liloan",
      "province_code": "0864",
      "psgc_code": "086406000",
      "region_desc": "08"
    },
    {
      "city_code": "086407",
      "city_name": "City Of Maasin (Capital)",
      "province_code": "0864",
      "psgc_code": "086407000",
      "region_desc": "08"
    },
    {
      "city_code": "086408",
      "city_name": "Macrohon",
      "province_code": "0864",
      "psgc_code": "086408000",
      "region_desc": "08"
    },
    {
      "city_code": "086409",
      "city_name": "Malitbog",
      "province_code": "0864",
      "psgc_code": "086409000",
      "region_desc": "08"
    },
    {
      "city_code": "086410",
      "city_name": "Padre Burgos",
      "province_code": "0864",
      "psgc_code": "086410000",
      "region_desc": "08"
    },
    {
      "city_code": "086411",
      "city_name": "Pintuyan",
      "province_code": "0864",
      "psgc_code": "086411000",
      "region_desc": "08"
    },
    {
      "city_code": "086412",
      "city_name": "Saint Bernard",
      "province_code": "0864",
      "psgc_code": "086412000",
      "region_desc": "08"
    },
    {
      "city_code": "086413",
      "city_name": "San Francisco",
      "province_code": "0864",
      "psgc_code": "086413000",
      "region_desc": "08"
    },
    {
      "city_code": "086414",
      "city_name": "San Juan (Cabalian)",
      "province_code": "0864",
      "psgc_code": "086414000",
      "region_desc": "08"
    },
    {
      "city_code": "086415",
      "city_name": "San Ricardo",
      "province_code": "0864",
      "psgc_code": "086415000",
      "region_desc": "08"
    },
    {
      "city_code": "086416",
      "city_name": "Silago",
      "province_code": "0864",
      "psgc_code": "086416000",
      "region_desc": "08"
    },
    {
      "city_code": "086417",
      "city_name": "Sogod",
      "province_code": "0864",
      "psgc_code": "086417000",
      "region_desc": "08"
    },
    {
      "city_code": "086418",
      "city_name": "Tomas Oppus",
      "province_code": "0864",
      "psgc_code": "086418000",
      "region_desc": "08"
    },
    {
      "city_code": "086419",
      "city_name": "Limasawa",
      "province_code": "0864",
      "psgc_code": "086419000",
      "region_desc": "08"
    },
    {
      "city_code": "087801",
      "city_name": "Almeria",
      "province_code": "0878",
      "psgc_code": "087801000",
      "region_desc": "08"
    },
    {
      "city_code": "087802",
      "city_name": "Biliran",
      "province_code": "0878",
      "psgc_code": "087802000",
      "region_desc": "08"
    },
    {
      "city_code": "087803",
      "city_name": "Cabucgayan",
      "province_code": "0878",
      "psgc_code": "087803000",
      "region_desc": "08"
    },
    {
      "city_code": "087804",
      "city_name": "Caibiran",
      "province_code": "0878",
      "psgc_code": "087804000",
      "region_desc": "08"
    },
    {
      "city_code": "087805",
      "city_name": "Culaba",
      "province_code": "0878",
      "psgc_code": "087805000",
      "region_desc": "08"
    },
    {
      "city_code": "087806",
      "city_name": "Kawayan",
      "province_code": "0878",
      "psgc_code": "087806000",
      "region_desc": "08"
    },
    {
      "city_code": "087807",
      "city_name": "Maripipi",
      "province_code": "0878",
      "psgc_code": "087807000",
      "region_desc": "08"
    },
    {
      "city_code": "087808",
      "city_name": "Naval (Capital)",
      "province_code": "0878",
      "psgc_code": "087808000",
      "region_desc": "08"
    },
    {
      "city_code": "097201",
      "city_name": "Dapitan City",
      "province_code": "0972",
      "psgc_code": "097201000",
      "region_desc": "09"
    },
    {
      "city_code": "097202",
      "city_name": "Dipolog City (Capital)",
      "province_code": "0972",
      "psgc_code": "097202000",
      "region_desc": "09"
    },
    {
      "city_code": "097203",
      "city_name": "Katipunan",
      "province_code": "0972",
      "psgc_code": "097203000",
      "region_desc": "09"
    },
    {
      "city_code": "097204",
      "city_name": "La Libertad",
      "province_code": "0972",
      "psgc_code": "097204000",
      "region_desc": "09"
    },
    {
      "city_code": "097205",
      "city_name": "Labason",
      "province_code": "0972",
      "psgc_code": "097205000",
      "region_desc": "09"
    },
    {
      "city_code": "097206",
      "city_name": "Liloy",
      "province_code": "0972",
      "psgc_code": "097206000",
      "region_desc": "09"
    },
    {
      "city_code": "097207",
      "city_name": "Manukan",
      "province_code": "0972",
      "psgc_code": "097207000",
      "region_desc": "09"
    },
    {
      "city_code": "097208",
      "city_name": "Mutia",
      "province_code": "0972",
      "psgc_code": "097208000",
      "region_desc": "09"
    },
    {
      "city_code": "097209",
      "city_name": "Piñan (New Piñan)",
      "province_code": "0972",
      "psgc_code": "097209000",
      "region_desc": "09"
    },
    {
      "city_code": "097210",
      "city_name": "Polanco",
      "province_code": "0972",
      "psgc_code": "097210000",
      "region_desc": "09"
    },
    {
      "city_code": "097211",
      "city_name": "Pres. Manuel A. Roxas",
      "province_code": "0972",
      "psgc_code": "097211000",
      "region_desc": "09"
    },
    {
      "city_code": "097212",
      "city_name": "Rizal",
      "province_code": "0972",
      "psgc_code": "097212000",
      "region_desc": "09"
    },
    {
      "city_code": "097213",
      "city_name": "Salug",
      "province_code": "0972",
      "psgc_code": "097213000",
      "region_desc": "09"
    },
    {
      "city_code": "097214",
      "city_name": "Sergio Osmeña Sr.",
      "province_code": "0972",
      "psgc_code": "097214000",
      "region_desc": "09"
    },
    {
      "city_code": "097215",
      "city_name": "Siayan",
      "province_code": "0972",
      "psgc_code": "097215000",
      "region_desc": "09"
    },
    {
      "city_code": "097216",
      "city_name": "Sibuco",
      "province_code": "0972",
      "psgc_code": "097216000",
      "region_desc": "09"
    },
    {
      "city_code": "097217",
      "city_name": "Sibutad",
      "province_code": "0972",
      "psgc_code": "097217000",
      "region_desc": "09"
    },
    {
      "city_code": "097218",
      "city_name": "Sindangan",
      "province_code": "0972",
      "psgc_code": "097218000",
      "region_desc": "09"
    },
    {
      "city_code": "097219",
      "city_name": "Siocon",
      "province_code": "0972",
      "psgc_code": "097219000",
      "region_desc": "09"
    },
    {
      "city_code": "097220",
      "city_name": "Sirawai",
      "province_code": "0972",
      "psgc_code": "097220000",
      "region_desc": "09"
    },
    {
      "city_code": "097221",
      "city_name": "Tampilisan",
      "province_code": "0972",
      "psgc_code": "097221000",
      "region_desc": "09"
    },
    {
      "city_code": "097222",
      "city_name": "Jose Dalman (Ponot)",
      "province_code": "0972",
      "psgc_code": "097222000",
      "region_desc": "09"
    },
    {
      "city_code": "097223",
      "city_name": "Gutalac",
      "province_code": "0972",
      "psgc_code": "097223000",
      "region_desc": "09"
    },
    {
      "city_code": "097224",
      "city_name": "Baliguian",
      "province_code": "0972",
      "psgc_code": "097224000",
      "region_desc": "09"
    },
    {
      "city_code": "097225",
      "city_name": "Godod",
      "province_code": "0972",
      "psgc_code": "097225000",
      "region_desc": "09"
    },
    {
      "city_code": "097226",
      "city_name": "Bacungan (Leon T. Postigo)",
      "province_code": "0972",
      "psgc_code": "097226000",
      "region_desc": "09"
    },
    {
      "city_code": "097227",
      "city_name": "Kalawit",
      "province_code": "0972",
      "psgc_code": "097227000",
      "region_desc": "09"
    },
    {
      "city_code": "097302",
      "city_name": "Aurora",
      "province_code": "0973",
      "psgc_code": "097302000",
      "region_desc": "09"
    },
    {
      "city_code": "097303",
      "city_name": "Bayog",
      "province_code": "0973",
      "psgc_code": "097303000",
      "region_desc": "09"
    },
    {
      "city_code": "097305",
      "city_name": "Dimataling",
      "province_code": "0973",
      "psgc_code": "097305000",
      "region_desc": "09"
    },
    {
      "city_code": "097306",
      "city_name": "Dinas",
      "province_code": "0973",
      "psgc_code": "097306000",
      "region_desc": "09"
    },
    {
      "city_code": "097307",
      "city_name": "Dumalinao",
      "province_code": "0973",
      "psgc_code": "097307000",
      "region_desc": "09"
    },
    {
      "city_code": "097308",
      "city_name": "Dumingag",
      "province_code": "0973",
      "psgc_code": "097308000",
      "region_desc": "09"
    },
    {
      "city_code": "097311",
      "city_name": "Kumalarang",
      "province_code": "0973",
      "psgc_code": "097311000",
      "region_desc": "09"
    },
    {
      "city_code": "097312",
      "city_name": "Labangan",
      "province_code": "0973",
      "psgc_code": "097312000",
      "region_desc": "09"
    },
    {
      "city_code": "097313",
      "city_name": "Lapuyan",
      "province_code": "0973",
      "psgc_code": "097313000",
      "region_desc": "09"
    },
    {
      "city_code": "097315",
      "city_name": "Mahayag",
      "province_code": "0973",
      "psgc_code": "097315000",
      "region_desc": "09"
    },
    {
      "city_code": "097317",
      "city_name": "Margosatubig",
      "province_code": "0973",
      "psgc_code": "097317000",
      "region_desc": "09"
    },
    {
      "city_code": "097318",
      "city_name": "Midsalip",
      "province_code": "0973",
      "psgc_code": "097318000",
      "region_desc": "09"
    },
    {
      "city_code": "097319",
      "city_name": "Molave",
      "province_code": "0973",
      "psgc_code": "097319000",
      "region_desc": "09"
    },
    {
      "city_code": "097322",
      "city_name": "Pagadian City (Capital)",
      "province_code": "0973",
      "psgc_code": "097322000",
      "region_desc": "09"
    },
    {
      "city_code": "097323",
      "city_name": "Ramon Magsaysay (Liargo)",
      "province_code": "0973",
      "psgc_code": "097323000",
      "region_desc": "09"
    },
    {
      "city_code": "097324",
      "city_name": "San Miguel",
      "province_code": "0973",
      "psgc_code": "097324000",
      "region_desc": "09"
    },
    {
      "city_code": "097325",
      "city_name": "San Pablo",
      "province_code": "0973",
      "psgc_code": "097325000",
      "region_desc": "09"
    },
    {
      "city_code": "097327",
      "city_name": "Tabina",
      "province_code": "0973",
      "psgc_code": "097327000",
      "region_desc": "09"
    },
    {
      "city_code": "097328",
      "city_name": "Tambulig",
      "province_code": "0973",
      "psgc_code": "097328000",
      "region_desc": "09"
    },
    {
      "city_code": "097330",
      "city_name": "Tukuran",
      "province_code": "0973",
      "psgc_code": "097330000",
      "region_desc": "09"
    },
    {
      "city_code": "097332",
      "city_name": "Zamboanga City",
      "province_code": "0973",
      "psgc_code": "097332000",
      "region_desc": "09"
    },
    {
      "city_code": "097333",
      "city_name": "Lakewood",
      "province_code": "0973",
      "psgc_code": "097333000",
      "region_desc": "09"
    },
    {
      "city_code": "097337",
      "city_name": "Josefina",
      "province_code": "0973",
      "psgc_code": "097337000",
      "region_desc": "09"
    },
    {
      "city_code": "097338",
      "city_name": "Pitogo",
      "province_code": "0973",
      "psgc_code": "097338000",
      "region_desc": "09"
    },
    {
      "city_code": "097340",
      "city_name": "Sominot (Don Mariano Marcos)",
      "province_code": "0973",
      "psgc_code": "097340000",
      "region_desc": "09"
    },
    {
      "city_code": "097341",
      "city_name": "Vincenzo A. Sagun",
      "province_code": "0973",
      "psgc_code": "097341000",
      "region_desc": "09"
    },
    {
      "city_code": "097343",
      "city_name": "Guipos",
      "province_code": "0973",
      "psgc_code": "097343000",
      "region_desc": "09"
    },
    {
      "city_code": "097344",
      "city_name": "Tigbao",
      "province_code": "0973",
      "psgc_code": "097344000",
      "region_desc": "09"
    },
    {
      "city_code": "098301",
      "city_name": "Alicia",
      "province_code": "0983",
      "psgc_code": "098301000",
      "region_desc": "09"
    },
    {
      "city_code": "098302",
      "city_name": "Buug",
      "province_code": "0983",
      "psgc_code": "098302000",
      "region_desc": "09"
    },
    {
      "city_code": "098303",
      "city_name": "Diplahan",
      "province_code": "0983",
      "psgc_code": "098303000",
      "region_desc": "09"
    },
    {
      "city_code": "098304",
      "city_name": "Imelda",
      "province_code": "0983",
      "psgc_code": "098304000",
      "region_desc": "09"
    },
    {
      "city_code": "098305",
      "city_name": "Ipil (Capital)",
      "province_code": "0983",
      "psgc_code": "098305000",
      "region_desc": "09"
    },
    {
      "city_code": "098306",
      "city_name": "Kabasalan",
      "province_code": "0983",
      "psgc_code": "098306000",
      "region_desc": "09"
    },
    {
      "city_code": "098307",
      "city_name": "Mabuhay",
      "province_code": "0983",
      "psgc_code": "098307000",
      "region_desc": "09"
    },
    {
      "city_code": "098308",
      "city_name": "Malangas",
      "province_code": "0983",
      "psgc_code": "098308000",
      "region_desc": "09"
    },
    {
      "city_code": "098309",
      "city_name": "Naga",
      "province_code": "0983",
      "psgc_code": "098309000",
      "region_desc": "09"
    },
    {
      "city_code": "098310",
      "city_name": "Olutanga",
      "province_code": "0983",
      "psgc_code": "098310000",
      "region_desc": "09"
    },
    {
      "city_code": "098311",
      "city_name": "Payao",
      "province_code": "0983",
      "psgc_code": "098311000",
      "region_desc": "09"
    },
    {
      "city_code": "098312",
      "city_name": "Roseller Lim",
      "province_code": "0983",
      "psgc_code": "098312000",
      "region_desc": "09"
    },
    {
      "city_code": "098313",
      "city_name": "Siay",
      "province_code": "0983",
      "psgc_code": "098313000",
      "region_desc": "09"
    },
    {
      "city_code": "098314",
      "city_name": "Talusan",
      "province_code": "0983",
      "psgc_code": "098314000",
      "region_desc": "09"
    },
    {
      "city_code": "098315",
      "city_name": "Titay",
      "province_code": "0983",
      "psgc_code": "098315000",
      "region_desc": "09"
    },
    {
      "city_code": "098316",
      "city_name": "Tungawan",
      "province_code": "0983",
      "psgc_code": "098316000",
      "region_desc": "09"
    },
    {
      "city_code": "099701",
      "city_name": "City Of Isabela",
      "province_code": "0997",
      "psgc_code": "099701000",
      "region_desc": "09"
    },
    {
      "city_code": "101301",
      "city_name": "Baungon",
      "province_code": "1013",
      "psgc_code": "101301000",
      "region_desc": "10"
    },
    {
      "city_code": "101302",
      "city_name": "Damulog",
      "province_code": "1013",
      "psgc_code": "101302000",
      "region_desc": "10"
    },
    {
      "city_code": "101303",
      "city_name": "Dangcagan",
      "province_code": "1013",
      "psgc_code": "101303000",
      "region_desc": "10"
    },
    {
      "city_code": "101304",
      "city_name": "Don Carlos",
      "province_code": "1013",
      "psgc_code": "101304000",
      "region_desc": "10"
    },
    {
      "city_code": "101305",
      "city_name": "Impasug-ong",
      "province_code": "1013",
      "psgc_code": "101305000",
      "region_desc": "10"
    },
    {
      "city_code": "101306",
      "city_name": "Kadingilan",
      "province_code": "1013",
      "psgc_code": "101306000",
      "region_desc": "10"
    },
    {
      "city_code": "101307",
      "city_name": "Kalilangan",
      "province_code": "1013",
      "psgc_code": "101307000",
      "region_desc": "10"
    },
    {
      "city_code": "101308",
      "city_name": "Kibawe",
      "province_code": "1013",
      "psgc_code": "101308000",
      "region_desc": "10"
    },
    {
      "city_code": "101309",
      "city_name": "Kitaotao",
      "province_code": "1013",
      "psgc_code": "101309000",
      "region_desc": "10"
    },
    {
      "city_code": "101310",
      "city_name": "Lantapan",
      "province_code": "1013",
      "psgc_code": "101310000",
      "region_desc": "10"
    },
    {
      "city_code": "101311",
      "city_name": "Libona",
      "province_code": "1013",
      "psgc_code": "101311000",
      "region_desc": "10"
    },
    {
      "city_code": "101312",
      "city_name": "City Of Malaybalay (Capital)",
      "province_code": "1013",
      "psgc_code": "101312000",
      "region_desc": "10"
    },
    {
      "city_code": "101313",
      "city_name": "Malitbog",
      "province_code": "1013",
      "psgc_code": "101313000",
      "region_desc": "10"
    },
    {
      "city_code": "101314",
      "city_name": "Manolo Fortich",
      "province_code": "1013",
      "psgc_code": "101314000",
      "region_desc": "10"
    },
    {
      "city_code": "101315",
      "city_name": "Maramag",
      "province_code": "1013",
      "psgc_code": "101315000",
      "region_desc": "10"
    },
    {
      "city_code": "101316",
      "city_name": "Pangantucan",
      "province_code": "1013",
      "psgc_code": "101316000",
      "region_desc": "10"
    },
    {
      "city_code": "101317",
      "city_name": "Quezon",
      "province_code": "1013",
      "psgc_code": "101317000",
      "region_desc": "10"
    },
    {
      "city_code": "101318",
      "city_name": "San Fernando",
      "province_code": "1013",
      "psgc_code": "101318000",
      "region_desc": "10"
    },
    {
      "city_code": "101319",
      "city_name": "Sumilao",
      "province_code": "1013",
      "psgc_code": "101319000",
      "region_desc": "10"
    },
    {
      "city_code": "101320",
      "city_name": "Talakag",
      "province_code": "1013",
      "psgc_code": "101320000",
      "region_desc": "10"
    },
    {
      "city_code": "101321",
      "city_name": "City Of Valencia",
      "province_code": "1013",
      "psgc_code": "101321000",
      "region_desc": "10"
    },
    {
      "city_code": "101322",
      "city_name": "Cabanglasan",
      "province_code": "1013",
      "psgc_code": "101322000",
      "region_desc": "10"
    },
    {
      "city_code": "101801",
      "city_name": "Catarman",
      "province_code": "1018",
      "psgc_code": "101801000",
      "region_desc": "10"
    },
    {
      "city_code": "101802",
      "city_name": "Guinsiliban",
      "province_code": "1018",
      "psgc_code": "101802000",
      "region_desc": "10"
    },
    {
      "city_code": "101803",
      "city_name": "Mahinog",
      "province_code": "1018",
      "psgc_code": "101803000",
      "region_desc": "10"
    },
    {
      "city_code": "101804",
      "city_name": "Mambajao (Capital)",
      "province_code": "1018",
      "psgc_code": "101804000",
      "region_desc": "10"
    },
    {
      "city_code": "101805",
      "city_name": "Sagay",
      "province_code": "1018",
      "psgc_code": "101805000",
      "region_desc": "10"
    },
    {
      "city_code": "103501",
      "city_name": "Bacolod",
      "province_code": "1035",
      "psgc_code": "103501000",
      "region_desc": "10"
    },
    {
      "city_code": "103502",
      "city_name": "Baloi",
      "province_code": "1035",
      "psgc_code": "103502000",
      "region_desc": "10"
    },
    {
      "city_code": "103503",
      "city_name": "Baroy",
      "province_code": "1035",
      "psgc_code": "103503000",
      "region_desc": "10"
    },
    {
      "city_code": "103504",
      "city_name": "Iligan City",
      "province_code": "1035",
      "psgc_code": "103504000",
      "region_desc": "10"
    },
    {
      "city_code": "103505",
      "city_name": "Kapatagan",
      "province_code": "1035",
      "psgc_code": "103505000",
      "region_desc": "10"
    },
    {
      "city_code": "103506",
      "city_name": "Sultan Naga Dimaporo (Karomatan)",
      "province_code": "1035",
      "psgc_code": "103506000",
      "region_desc": "10"
    },
    {
      "city_code": "103507",
      "city_name": "Kauswagan",
      "province_code": "1035",
      "psgc_code": "103507000",
      "region_desc": "10"
    },
    {
      "city_code": "103508",
      "city_name": "Kolambugan",
      "province_code": "1035",
      "psgc_code": "103508000",
      "region_desc": "10"
    },
    {
      "city_code": "103509",
      "city_name": "Lala",
      "province_code": "1035",
      "psgc_code": "103509000",
      "region_desc": "10"
    },
    {
      "city_code": "103510",
      "city_name": "Linamon",
      "province_code": "1035",
      "psgc_code": "103510000",
      "region_desc": "10"
    },
    {
      "city_code": "103511",
      "city_name": "Magsaysay",
      "province_code": "1035",
      "psgc_code": "103511000",
      "region_desc": "10"
    },
    {
      "city_code": "103512",
      "city_name": "Maigo",
      "province_code": "1035",
      "psgc_code": "103512000",
      "region_desc": "10"
    },
    {
      "city_code": "103513",
      "city_name": "Matungao",
      "province_code": "1035",
      "psgc_code": "103513000",
      "region_desc": "10"
    },
    {
      "city_code": "103514",
      "city_name": "Munai",
      "province_code": "1035",
      "psgc_code": "103514000",
      "region_desc": "10"
    },
    {
      "city_code": "103515",
      "city_name": "Nunungan",
      "province_code": "1035",
      "psgc_code": "103515000",
      "region_desc": "10"
    },
    {
      "city_code": "103516",
      "city_name": "Pantao Ragat",
      "province_code": "1035",
      "psgc_code": "103516000",
      "region_desc": "10"
    },
    {
      "city_code": "103517",
      "city_name": "Poona Piagapo",
      "province_code": "1035",
      "psgc_code": "103517000",
      "region_desc": "10"
    },
    {
      "city_code": "103518",
      "city_name": "Salvador",
      "province_code": "1035",
      "psgc_code": "103518000",
      "region_desc": "10"
    },
    {
      "city_code": "103519",
      "city_name": "Sapad",
      "province_code": "1035",
      "psgc_code": "103519000",
      "region_desc": "10"
    },
    {
      "city_code": "103520",
      "city_name": "Tagoloan",
      "province_code": "1035",
      "psgc_code": "103520000",
      "region_desc": "10"
    },
    {
      "city_code": "103521",
      "city_name": "Tangcal",
      "province_code": "1035",
      "psgc_code": "103521000",
      "region_desc": "10"
    },
    {
      "city_code": "103522",
      "city_name": "Tubod (Capital)",
      "province_code": "1035",
      "psgc_code": "103522000",
      "region_desc": "10"
    },
    {
      "city_code": "103523",
      "city_name": "Pantar",
      "province_code": "1035",
      "psgc_code": "103523000",
      "region_desc": "10"
    },
    {
      "city_code": "104201",
      "city_name": "Aloran",
      "province_code": "1042",
      "psgc_code": "104201000",
      "region_desc": "10"
    },
    {
      "city_code": "104202",
      "city_name": "Baliangao",
      "province_code": "1042",
      "psgc_code": "104202000",
      "region_desc": "10"
    },
    {
      "city_code": "104203",
      "city_name": "Bonifacio",
      "province_code": "1042",
      "psgc_code": "104203000",
      "region_desc": "10"
    },
    {
      "city_code": "104204",
      "city_name": "Calamba",
      "province_code": "1042",
      "psgc_code": "104204000",
      "region_desc": "10"
    },
    {
      "city_code": "104205",
      "city_name": "Clarin",
      "province_code": "1042",
      "psgc_code": "104205000",
      "region_desc": "10"
    },
    {
      "city_code": "104206",
      "city_name": "Concepcion",
      "province_code": "1042",
      "psgc_code": "104206000",
      "region_desc": "10"
    },
    {
      "city_code": "104207",
      "city_name": "Jimenez",
      "province_code": "1042",
      "psgc_code": "104207000",
      "region_desc": "10"
    },
    {
      "city_code": "104208",
      "city_name": "Lopez Jaena",
      "province_code": "1042",
      "psgc_code": "104208000",
      "region_desc": "10"
    },
    {
      "city_code": "104209",
      "city_name": "Oroquieta City (Capital)",
      "province_code": "1042",
      "psgc_code": "104209000",
      "region_desc": "10"
    },
    {
      "city_code": "104210",
      "city_name": "Ozamis City",
      "province_code": "1042",
      "psgc_code": "104210000",
      "region_desc": "10"
    },
    {
      "city_code": "104211",
      "city_name": "Panaon",
      "province_code": "1042",
      "psgc_code": "104211000",
      "region_desc": "10"
    },
    {
      "city_code": "104212",
      "city_name": "Plaridel",
      "province_code": "1042",
      "psgc_code": "104212000",
      "region_desc": "10"
    },
    {
      "city_code": "104213",
      "city_name": "Sapang Dalaga",
      "province_code": "1042",
      "psgc_code": "104213000",
      "region_desc": "10"
    },
    {
      "city_code": "104214",
      "city_name": "Sinacaban",
      "province_code": "1042",
      "psgc_code": "104214000",
      "region_desc": "10"
    },
    {
      "city_code": "104215",
      "city_name": "Tangub City",
      "province_code": "1042",
      "psgc_code": "104215000",
      "region_desc": "10"
    },
    {
      "city_code": "104216",
      "city_name": "Tudela",
      "province_code": "1042",
      "psgc_code": "104216000",
      "region_desc": "10"
    },
    {
      "city_code": "104217",
      "city_name": "Don Victoriano Chiongbian  (Don Mariano Marcos)",
      "province_code": "1042",
      "psgc_code": "104217000",
      "region_desc": "10"
    },
    {
      "city_code": "104301",
      "city_name": "Alubijid",
      "province_code": "1043",
      "psgc_code": "104301000",
      "region_desc": "10"
    },
    {
      "city_code": "104302",
      "city_name": "Balingasag",
      "province_code": "1043",
      "psgc_code": "104302000",
      "region_desc": "10"
    },
    {
      "city_code": "104303",
      "city_name": "Balingoan",
      "province_code": "1043",
      "psgc_code": "104303000",
      "region_desc": "10"
    },
    {
      "city_code": "104304",
      "city_name": "Binuangan",
      "province_code": "1043",
      "psgc_code": "104304000",
      "region_desc": "10"
    },
    {
      "city_code": "104305",
      "city_name": "Cagayan De Oro City (Capital)",
      "province_code": "1043",
      "psgc_code": "104305000",
      "region_desc": "10"
    },
    {
      "city_code": "104306",
      "city_name": "Claveria",
      "province_code": "1043",
      "psgc_code": "104306000",
      "region_desc": "10"
    },
    {
      "city_code": "104307",
      "city_name": "City Of El Salvador",
      "province_code": "1043",
      "psgc_code": "104307000",
      "region_desc": "10"
    },
    {
      "city_code": "104308",
      "city_name": "Gingoog City",
      "province_code": "1043",
      "psgc_code": "104308000",
      "region_desc": "10"
    },
    {
      "city_code": "104309",
      "city_name": "Gitagum",
      "province_code": "1043",
      "psgc_code": "104309000",
      "region_desc": "10"
    },
    {
      "city_code": "104310",
      "city_name": "Initao",
      "province_code": "1043",
      "psgc_code": "104310000",
      "region_desc": "10"
    },
    {
      "city_code": "104311",
      "city_name": "Jasaan",
      "province_code": "1043",
      "psgc_code": "104311000",
      "region_desc": "10"
    },
    {
      "city_code": "104312",
      "city_name": "Kinoguitan",
      "province_code": "1043",
      "psgc_code": "104312000",
      "region_desc": "10"
    },
    {
      "city_code": "104313",
      "city_name": "Lagonglong",
      "province_code": "1043",
      "psgc_code": "104313000",
      "region_desc": "10"
    },
    {
      "city_code": "104314",
      "city_name": "Laguindingan",
      "province_code": "1043",
      "psgc_code": "104314000",
      "region_desc": "10"
    },
    {
      "city_code": "104315",
      "city_name": "Libertad",
      "province_code": "1043",
      "psgc_code": "104315000",
      "region_desc": "10"
    },
    {
      "city_code": "104316",
      "city_name": "Lugait",
      "province_code": "1043",
      "psgc_code": "104316000",
      "region_desc": "10"
    },
    {
      "city_code": "104317",
      "city_name": "Magsaysay (Linugos)",
      "province_code": "1043",
      "psgc_code": "104317000",
      "region_desc": "10"
    },
    {
      "city_code": "104318",
      "city_name": "Manticao",
      "province_code": "1043",
      "psgc_code": "104318000",
      "region_desc": "10"
    },
    {
      "city_code": "104319",
      "city_name": "Medina",
      "province_code": "1043",
      "psgc_code": "104319000",
      "region_desc": "10"
    },
    {
      "city_code": "104320",
      "city_name": "Naawan",
      "province_code": "1043",
      "psgc_code": "104320000",
      "region_desc": "10"
    },
    {
      "city_code": "104321",
      "city_name": "Opol",
      "province_code": "1043",
      "psgc_code": "104321000",
      "region_desc": "10"
    },
    {
      "city_code": "104322",
      "city_name": "Salay",
      "province_code": "1043",
      "psgc_code": "104322000",
      "region_desc": "10"
    },
    {
      "city_code": "104323",
      "city_name": "Sugbongcogon",
      "province_code": "1043",
      "psgc_code": "104323000",
      "region_desc": "10"
    },
    {
      "city_code": "104324",
      "city_name": "Tagoloan",
      "province_code": "1043",
      "psgc_code": "104324000",
      "region_desc": "10"
    },
    {
      "city_code": "104325",
      "city_name": "Talisayan",
      "province_code": "1043",
      "psgc_code": "104325000",
      "region_desc": "10"
    },
    {
      "city_code": "104326",
      "city_name": "Villanueva",
      "province_code": "1043",
      "psgc_code": "104326000",
      "region_desc": "10"
    },
    {
      "city_code": "112301",
      "city_name": "Asuncion (Saug)",
      "province_code": "1123",
      "psgc_code": "112301000",
      "region_desc": "11"
    },
    {
      "city_code": "112303",
      "city_name": "Carmen",
      "province_code": "1123",
      "psgc_code": "112303000",
      "region_desc": "11"
    },
    {
      "city_code": "112305",
      "city_name": "Kapalong",
      "province_code": "1123",
      "psgc_code": "112305000",
      "region_desc": "11"
    },
    {
      "city_code": "112314",
      "city_name": "New Corella",
      "province_code": "1123",
      "psgc_code": "112314000",
      "region_desc": "11"
    },
    {
      "city_code": "112315",
      "city_name": "City Of Panabo",
      "province_code": "1123",
      "psgc_code": "112315000",
      "region_desc": "11"
    },
    {
      "city_code": "112317",
      "city_name": "Island Garden City Of Samal",
      "province_code": "1123",
      "psgc_code": "112317000",
      "region_desc": "11"
    },
    {
      "city_code": "112318",
      "city_name": "Santo Tomas",
      "province_code": "1123",
      "psgc_code": "112318000",
      "region_desc": "11"
    },
    {
      "city_code": "112319",
      "city_name": "City Of Tagum (Capital)",
      "province_code": "1123",
      "psgc_code": "112319000",
      "region_desc": "11"
    },
    {
      "city_code": "112322",
      "city_name": "Talaingod",
      "province_code": "1123",
      "psgc_code": "112322000",
      "region_desc": "11"
    },
    {
      "city_code": "112323",
      "city_name": "Braulio E. Dujali",
      "province_code": "1123",
      "psgc_code": "112323000",
      "region_desc": "11"
    },
    {
      "city_code": "112324",
      "city_name": "San Isidro",
      "province_code": "1123",
      "psgc_code": "112324000",
      "region_desc": "11"
    },
    {
      "city_code": "112401",
      "city_name": "Bansalan",
      "province_code": "1124",
      "psgc_code": "112401000",
      "region_desc": "11"
    },
    {
      "city_code": "112402",
      "city_name": "Davao City",
      "province_code": "1124",
      "psgc_code": "112402000",
      "region_desc": "11"
    },
    {
      "city_code": "112403",
      "city_name": "City Of Digos (Capital)",
      "province_code": "1124",
      "psgc_code": "112403000",
      "region_desc": "11"
    },
    {
      "city_code": "112404",
      "city_name": "Hagonoy",
      "province_code": "1124",
      "psgc_code": "112404000",
      "region_desc": "11"
    },
    {
      "city_code": "112406",
      "city_name": "Kiblawan",
      "province_code": "1124",
      "psgc_code": "112406000",
      "region_desc": "11"
    },
    {
      "city_code": "112407",
      "city_name": "Magsaysay",
      "province_code": "1124",
      "psgc_code": "112407000",
      "region_desc": "11"
    },
    {
      "city_code": "112408",
      "city_name": "Malalag",
      "province_code": "1124",
      "psgc_code": "112408000",
      "region_desc": "11"
    },
    {
      "city_code": "112410",
      "city_name": "Matanao",
      "province_code": "1124",
      "psgc_code": "112410000",
      "region_desc": "11"
    },
    {
      "city_code": "112411",
      "city_name": "Padada",
      "province_code": "1124",
      "psgc_code": "112411000",
      "region_desc": "11"
    },
    {
      "city_code": "112412",
      "city_name": "Santa Cruz",
      "province_code": "1124",
      "psgc_code": "112412000",
      "region_desc": "11"
    },
    {
      "city_code": "112414",
      "city_name": "Sulop",
      "province_code": "1124",
      "psgc_code": "112414000",
      "region_desc": "11"
    },
    {
      "city_code": "112501",
      "city_name": "Baganga",
      "province_code": "1125",
      "psgc_code": "112501000",
      "region_desc": "11"
    },
    {
      "city_code": "112502",
      "city_name": "Banaybanay",
      "province_code": "1125",
      "psgc_code": "112502000",
      "region_desc": "11"
    },
    {
      "city_code": "112503",
      "city_name": "Boston",
      "province_code": "1125",
      "psgc_code": "112503000",
      "region_desc": "11"
    },
    {
      "city_code": "112504",
      "city_name": "Caraga",
      "province_code": "1125",
      "psgc_code": "112504000",
      "region_desc": "11"
    },
    {
      "city_code": "112505",
      "city_name": "Cateel",
      "province_code": "1125",
      "psgc_code": "112505000",
      "region_desc": "11"
    },
    {
      "city_code": "112506",
      "city_name": "Governor Generoso",
      "province_code": "1125",
      "psgc_code": "112506000",
      "region_desc": "11"
    },
    {
      "city_code": "112507",
      "city_name": "Lupon",
      "province_code": "1125",
      "psgc_code": "112507000",
      "region_desc": "11"
    },
    {
      "city_code": "112508",
      "city_name": "Manay",
      "province_code": "1125",
      "psgc_code": "112508000",
      "region_desc": "11"
    },
    {
      "city_code": "112509",
      "city_name": "City Of Mati (Capital)",
      "province_code": "1125",
      "psgc_code": "112509000",
      "region_desc": "11"
    },
    {
      "city_code": "112510",
      "city_name": "San Isidro",
      "province_code": "1125",
      "psgc_code": "112510000",
      "region_desc": "11"
    },
    {
      "city_code": "112511",
      "city_name": "Tarragona",
      "province_code": "1125",
      "psgc_code": "112511000",
      "region_desc": "11"
    },
    {
      "city_code": "118201",
      "city_name": "Compostela",
      "province_code": "1182",
      "psgc_code": "118201000",
      "region_desc": "11"
    },
    {
      "city_code": "118202",
      "city_name": "Laak (San Vicente)",
      "province_code": "1182",
      "psgc_code": "118202000",
      "region_desc": "11"
    },
    {
      "city_code": "118203",
      "city_name": "Mabini (Doña Alicia)",
      "province_code": "1182",
      "psgc_code": "118203000",
      "region_desc": "11"
    },
    {
      "city_code": "118204",
      "city_name": "Maco",
      "province_code": "1182",
      "psgc_code": "118204000",
      "region_desc": "11"
    },
    {
      "city_code": "118205",
      "city_name": "Maragusan (San Mariano)",
      "province_code": "1182",
      "psgc_code": "118205000",
      "region_desc": "11"
    },
    {
      "city_code": "118206",
      "city_name": "Mawab",
      "province_code": "1182",
      "psgc_code": "118206000",
      "region_desc": "11"
    },
    {
      "city_code": "118207",
      "city_name": "Monkayo",
      "province_code": "1182",
      "psgc_code": "118207000",
      "region_desc": "11"
    },
    {
      "city_code": "118208",
      "city_name": "Montevista",
      "province_code": "1182",
      "psgc_code": "118208000",
      "region_desc": "11"
    },
    {
      "city_code": "118209",
      "city_name": "Nabunturan (Capital)",
      "province_code": "1182",
      "psgc_code": "118209000",
      "region_desc": "11"
    },
    {
      "city_code": "118210",
      "city_name": "New Bataan",
      "province_code": "1182",
      "psgc_code": "118210000",
      "region_desc": "11"
    },
    {
      "city_code": "118211",
      "city_name": "Pantukan",
      "province_code": "1182",
      "psgc_code": "118211000",
      "region_desc": "11"
    },
    {
      "city_code": "118601",
      "city_name": "Don Marcelino",
      "province_code": "1186",
      "psgc_code": "118601000",
      "region_desc": "11"
    },
    {
      "city_code": "118602",
      "city_name": "Jose Abad Santos (Trinidad)",
      "province_code": "1186",
      "psgc_code": "118602000",
      "region_desc": "11"
    },
    {
      "city_code": "118603",
      "city_name": "Malita",
      "province_code": "1186",
      "psgc_code": "118603000",
      "region_desc": "11"
    },
    {
      "city_code": "118604",
      "city_name": "Santa Maria",
      "province_code": "1186",
      "psgc_code": "118604000",
      "region_desc": "11"
    },
    {
      "city_code": "118605",
      "city_name": "Sarangani",
      "province_code": "1186",
      "psgc_code": "118605000",
      "region_desc": "11"
    },
    {
      "city_code": "124701",
      "city_name": "Alamada",
      "province_code": "1247",
      "psgc_code": "124701000",
      "region_desc": "12"
    },
    {
      "city_code": "124702",
      "city_name": "Carmen",
      "province_code": "1247",
      "psgc_code": "124702000",
      "region_desc": "12"
    },
    {
      "city_code": "124703",
      "city_name": "Kabacan",
      "province_code": "1247",
      "psgc_code": "124703000",
      "region_desc": "12"
    },
    {
      "city_code": "124704",
      "city_name": "City Of Kidapawan (Capital)",
      "province_code": "1247",
      "psgc_code": "124704000",
      "region_desc": "12"
    },
    {
      "city_code": "124705",
      "city_name": "Libungan",
      "province_code": "1247",
      "psgc_code": "124705000",
      "region_desc": "12"
    },
    {
      "city_code": "124706",
      "city_name": "Magpet",
      "province_code": "1247",
      "psgc_code": "124706000",
      "region_desc": "12"
    },
    {
      "city_code": "124707",
      "city_name": "Makilala",
      "province_code": "1247",
      "psgc_code": "124707000",
      "region_desc": "12"
    },
    {
      "city_code": "124708",
      "city_name": "Matalam",
      "province_code": "1247",
      "psgc_code": "124708000",
      "region_desc": "12"
    },
    {
      "city_code": "124709",
      "city_name": "Midsayap",
      "province_code": "1247",
      "psgc_code": "124709000",
      "region_desc": "12"
    },
    {
      "city_code": "124710",
      "city_name": "M'lang",
      "province_code": "1247",
      "psgc_code": "124710000",
      "region_desc": "12"
    },
    {
      "city_code": "124711",
      "city_name": "Pigkawayan",
      "province_code": "1247",
      "psgc_code": "124711000",
      "region_desc": "12"
    },
    {
      "city_code": "124712",
      "city_name": "Pikit",
      "province_code": "1247",
      "psgc_code": "124712000",
      "region_desc": "12"
    },
    {
      "city_code": "124713",
      "city_name": "President Roxas",
      "province_code": "1247",
      "psgc_code": "124713000",
      "region_desc": "12"
    },
    {
      "city_code": "124714",
      "city_name": "Tulunan",
      "province_code": "1247",
      "psgc_code": "124714000",
      "region_desc": "12"
    },
    {
      "city_code": "124715",
      "city_name": "Antipas",
      "province_code": "1247",
      "psgc_code": "124715000",
      "region_desc": "12"
    },
    {
      "city_code": "124716",
      "city_name": "Banisilan",
      "province_code": "1247",
      "psgc_code": "124716000",
      "region_desc": "12"
    },
    {
      "city_code": "124717",
      "city_name": "Aleosan",
      "province_code": "1247",
      "psgc_code": "124717000",
      "region_desc": "12"
    },
    {
      "city_code": "124718",
      "city_name": "Arakan",
      "province_code": "1247",
      "psgc_code": "124718000",
      "region_desc": "12"
    },
    {
      "city_code": "126302",
      "city_name": "Banga",
      "province_code": "1263",
      "psgc_code": "126302000",
      "region_desc": "12"
    },
    {
      "city_code": "126303",
      "city_name": "General Santos City (Dadiangas)",
      "province_code": "1263",
      "psgc_code": "126303000",
      "region_desc": "12"
    },
    {
      "city_code": "126306",
      "city_name": "City Of Koronadal (Capital)",
      "province_code": "1263",
      "psgc_code": "126306000",
      "region_desc": "12"
    },
    {
      "city_code": "126311",
      "city_name": "Norala",
      "province_code": "1263",
      "psgc_code": "126311000",
      "region_desc": "12"
    },
    {
      "city_code": "126312",
      "city_name": "Polomolok",
      "province_code": "1263",
      "psgc_code": "126312000",
      "region_desc": "12"
    },
    {
      "city_code": "126313",
      "city_name": "Surallah",
      "province_code": "1263",
      "psgc_code": "126313000",
      "region_desc": "12"
    },
    {
      "city_code": "126314",
      "city_name": "Tampakan",
      "province_code": "1263",
      "psgc_code": "126314000",
      "region_desc": "12"
    },
    {
      "city_code": "126315",
      "city_name": "Tantangan",
      "province_code": "1263",
      "psgc_code": "126315000",
      "region_desc": "12"
    },
    {
      "city_code": "126316",
      "city_name": "T'boli",
      "province_code": "1263",
      "psgc_code": "126316000",
      "region_desc": "12"
    },
    {
      "city_code": "126317",
      "city_name": "Tupi",
      "province_code": "1263",
      "psgc_code": "126317000",
      "region_desc": "12"
    },
    {
      "city_code": "126318",
      "city_name": "Santo Niño",
      "province_code": "1263",
      "psgc_code": "126318000",
      "region_desc": "12"
    },
    {
      "city_code": "126319",
      "city_name": "Lake Sebu",
      "province_code": "1263",
      "psgc_code": "126319000",
      "region_desc": "12"
    },
    {
      "city_code": "126501",
      "city_name": "Bagumbayan",
      "province_code": "1265",
      "psgc_code": "126501000",
      "region_desc": "12"
    },
    {
      "city_code": "126502",
      "city_name": "Columbio",
      "province_code": "1265",
      "psgc_code": "126502000",
      "region_desc": "12"
    },
    {
      "city_code": "126503",
      "city_name": "Esperanza",
      "province_code": "1265",
      "psgc_code": "126503000",
      "region_desc": "12"
    },
    {
      "city_code": "126504",
      "city_name": "Isulan (Capital)",
      "province_code": "1265",
      "psgc_code": "126504000",
      "region_desc": "12"
    },
    {
      "city_code": "126505",
      "city_name": "Kalamansig",
      "province_code": "1265",
      "psgc_code": "126505000",
      "region_desc": "12"
    },
    {
      "city_code": "126506",
      "city_name": "Lebak",
      "province_code": "1265",
      "psgc_code": "126506000",
      "region_desc": "12"
    },
    {
      "city_code": "126507",
      "city_name": "Lutayan",
      "province_code": "1265",
      "psgc_code": "126507000",
      "region_desc": "12"
    },
    {
      "city_code": "126508",
      "city_name": "Lambayong (Mariano Marcos)",
      "province_code": "1265",
      "psgc_code": "126508000",
      "region_desc": "12"
    },
    {
      "city_code": "126509",
      "city_name": "Palimbang",
      "province_code": "1265",
      "psgc_code": "126509000",
      "region_desc": "12"
    },
    {
      "city_code": "126510",
      "city_name": "President Quirino",
      "province_code": "1265",
      "psgc_code": "126510000",
      "region_desc": "12"
    },
    {
      "city_code": "126511",
      "city_name": "City Of Tacurong",
      "province_code": "1265",
      "psgc_code": "126511000",
      "region_desc": "12"
    },
    {
      "city_code": "126512",
      "city_name": "Sen. Ninoy Aquino",
      "province_code": "1265",
      "psgc_code": "126512000",
      "region_desc": "12"
    },
    {
      "city_code": "128001",
      "city_name": "Alabel (Capital)",
      "province_code": "1280",
      "psgc_code": "128001000",
      "region_desc": "12"
    },
    {
      "city_code": "128002",
      "city_name": "Glan",
      "province_code": "1280",
      "psgc_code": "128002000",
      "region_desc": "12"
    },
    {
      "city_code": "128003",
      "city_name": "Kiamba",
      "province_code": "1280",
      "psgc_code": "128003000",
      "region_desc": "12"
    },
    {
      "city_code": "128004",
      "city_name": "Maasim",
      "province_code": "1280",
      "psgc_code": "128004000",
      "region_desc": "12"
    },
    {
      "city_code": "128005",
      "city_name": "Maitum",
      "province_code": "1280",
      "psgc_code": "128005000",
      "region_desc": "12"
    },
    {
      "city_code": "128006",
      "city_name": "Malapatan",
      "province_code": "1280",
      "psgc_code": "128006000",
      "region_desc": "12"
    },
    {
      "city_code": "128007",
      "city_name": "Malungon",
      "province_code": "1280",
      "psgc_code": "128007000",
      "region_desc": "12"
    },
    {
      "city_code": "129804",
      "city_name": "Cotabato City",
      "province_code": "1298",
      "psgc_code": "129804000",
      "region_desc": "12"
    },
    {
      "city_code": "133901",
      "city_name": "Tondo I / Ii",
      "province_code": "1339",
      "psgc_code": "133901000",
      "region_desc": "13"
    },
    {
      "city_code": "133902",
      "city_name": "Binondo",
      "province_code": "1339",
      "psgc_code": "133902000",
      "region_desc": "13"
    },
    {
      "city_code": "133903",
      "city_name": "Quiapo",
      "province_code": "1339",
      "psgc_code": "133903000",
      "region_desc": "13"
    },
    {
      "city_code": "133904",
      "city_name": "San Nicolas",
      "province_code": "1339",
      "psgc_code": "133904000",
      "region_desc": "13"
    },
    {
      "city_code": "133905",
      "city_name": "Santa Cruz",
      "province_code": "1339",
      "psgc_code": "133905000",
      "region_desc": "13"
    },
    {
      "city_code": "133906",
      "city_name": "Sampaloc",
      "province_code": "1339",
      "psgc_code": "133906000",
      "region_desc": "13"
    },
    {
      "city_code": "133907",
      "city_name": "San Miguel",
      "province_code": "1339",
      "psgc_code": "133907000",
      "region_desc": "13"
    },
    {
      "city_code": "133908",
      "city_name": "Ermita",
      "province_code": "1339",
      "psgc_code": "133908000",
      "region_desc": "13"
    },
    {
      "city_code": "133909",
      "city_name": "Intramuros",
      "province_code": "1339",
      "psgc_code": "133909000",
      "region_desc": "13"
    },
    {
      "city_code": "133910",
      "city_name": "Malate",
      "province_code": "1339",
      "psgc_code": "133910000",
      "region_desc": "13"
    },
    {
      "city_code": "133911",
      "city_name": "Paco",
      "province_code": "1339",
      "psgc_code": "133911000",
      "region_desc": "13"
    },
    {
      "city_code": "133912",
      "city_name": "Pandacan",
      "province_code": "1339",
      "psgc_code": "133912000",
      "region_desc": "13"
    },
    {
      "city_code": "133913",
      "city_name": "Port Area",
      "province_code": "1339",
      "psgc_code": "133913000",
      "region_desc": "13"
    },
    {
      "city_code": "133914",
      "city_name": "Santa Ana",
      "province_code": "1339",
      "psgc_code": "133914000",
      "region_desc": "13"
    },
    {
      "city_code": "137401",
      "city_name": "City Of Mandaluyong",
      "province_code": "1374",
      "psgc_code": "137401000",
      "region_desc": "13"
    },
    {
      "city_code": "137402",
      "city_name": "City Of Marikina",
      "province_code": "1374",
      "psgc_code": "137402000",
      "region_desc": "13"
    },
    {
      "city_code": "137403",
      "city_name": "City Of Pasig",
      "province_code": "1374",
      "psgc_code": "137403000",
      "region_desc": "13"
    },
    {
      "city_code": "137404",
      "city_name": "Quezon City",
      "province_code": "1374",
      "psgc_code": "137404000",
      "region_desc": "13"
    },
    {
      "city_code": "137405",
      "city_name": "City Of San Juan",
      "province_code": "1374",
      "psgc_code": "137405000",
      "region_desc": "13"
    },
    {
      "city_code": "137501",
      "city_name": "Caloocan City",
      "province_code": "1375",
      "psgc_code": "137501000",
      "region_desc": "13"
    },
    {
      "city_code": "137502",
      "city_name": "City Of Malabon",
      "province_code": "1375",
      "psgc_code": "137502000",
      "region_desc": "13"
    },
    {
      "city_code": "137503",
      "city_name": "City Of Navotas",
      "province_code": "1375",
      "psgc_code": "137503000",
      "region_desc": "13"
    },
    {
      "city_code": "137504",
      "city_name": "City Of Valenzuela",
      "province_code": "1375",
      "psgc_code": "137504000",
      "region_desc": "13"
    },
    {
      "city_code": "137601",
      "city_name": "City Of Las Piñas",
      "province_code": "1376",
      "psgc_code": "137601000",
      "region_desc": "13"
    },
    {
      "city_code": "137602",
      "city_name": "City Of Makati",
      "province_code": "1376",
      "psgc_code": "137602000",
      "region_desc": "13"
    },
    {
      "city_code": "137603",
      "city_name": "City Of Muntinlupa",
      "province_code": "1376",
      "psgc_code": "137603000",
      "region_desc": "13"
    },
    {
      "city_code": "137604",
      "city_name": "City Of Parañaque",
      "province_code": "1376",
      "psgc_code": "137604000",
      "region_desc": "13"
    },
    {
      "city_code": "137605",
      "city_name": "Pasay City",
      "province_code": "1376",
      "psgc_code": "137605000",
      "region_desc": "13"
    },
    {
      "city_code": "137606",
      "city_name": "Pateros",
      "province_code": "1376",
      "psgc_code": "137606000",
      "region_desc": "13"
    },
    {
      "city_code": "137607",
      "city_name": "Taguig City",
      "province_code": "1376",
      "psgc_code": "137607000",
      "region_desc": "13"
    },
    {
      "city_code": "140101",
      "city_name": "Bangued (Capital)",
      "province_code": "1401",
      "psgc_code": "140101000",
      "region_desc": "14"
    },
    {
      "city_code": "140102",
      "city_name": "Boliney",
      "province_code": "1401",
      "psgc_code": "140102000",
      "region_desc": "14"
    },
    {
      "city_code": "140103",
      "city_name": "Bucay",
      "province_code": "1401",
      "psgc_code": "140103000",
      "region_desc": "14"
    },
    {
      "city_code": "140104",
      "city_name": "Bucloc",
      "province_code": "1401",
      "psgc_code": "140104000",
      "region_desc": "14"
    },
    {
      "city_code": "140105",
      "city_name": "Daguioman",
      "province_code": "1401",
      "psgc_code": "140105000",
      "region_desc": "14"
    },
    {
      "city_code": "140106",
      "city_name": "Danglas",
      "province_code": "1401",
      "psgc_code": "140106000",
      "region_desc": "14"
    },
    {
      "city_code": "140107",
      "city_name": "Dolores",
      "province_code": "1401",
      "psgc_code": "140107000",
      "region_desc": "14"
    },
    {
      "city_code": "140108",
      "city_name": "La Paz",
      "province_code": "1401",
      "psgc_code": "140108000",
      "region_desc": "14"
    },
    {
      "city_code": "140109",
      "city_name": "Lacub",
      "province_code": "1401",
      "psgc_code": "140109000",
      "region_desc": "14"
    },
    {
      "city_code": "140110",
      "city_name": "Lagangilang",
      "province_code": "1401",
      "psgc_code": "140110000",
      "region_desc": "14"
    },
    {
      "city_code": "140111",
      "city_name": "Lagayan",
      "province_code": "1401",
      "psgc_code": "140111000",
      "region_desc": "14"
    },
    {
      "city_code": "140112",
      "city_name": "Langiden",
      "province_code": "1401",
      "psgc_code": "140112000",
      "region_desc": "14"
    },
    {
      "city_code": "140113",
      "city_name": "Licuan-baay (Licuan)",
      "province_code": "1401",
      "psgc_code": "140113000",
      "region_desc": "14"
    },
    {
      "city_code": "140114",
      "city_name": "Luba",
      "province_code": "1401",
      "psgc_code": "140114000",
      "region_desc": "14"
    },
    {
      "city_code": "140115",
      "city_name": "Malibcong",
      "province_code": "1401",
      "psgc_code": "140115000",
      "region_desc": "14"
    },
    {
      "city_code": "140116",
      "city_name": "Manabo",
      "province_code": "1401",
      "psgc_code": "140116000",
      "region_desc": "14"
    },
    {
      "city_code": "140117",
      "city_name": "Peñarrubia",
      "province_code": "1401",
      "psgc_code": "140117000",
      "region_desc": "14"
    },
    {
      "city_code": "140118",
      "city_name": "Pidigan",
      "province_code": "1401",
      "psgc_code": "140118000",
      "region_desc": "14"
    },
    {
      "city_code": "140119",
      "city_name": "Pilar",
      "province_code": "1401",
      "psgc_code": "140119000",
      "region_desc": "14"
    },
    {
      "city_code": "140120",
      "city_name": "Sallapadan",
      "province_code": "1401",
      "psgc_code": "140120000",
      "region_desc": "14"
    },
    {
      "city_code": "140121",
      "city_name": "San Isidro",
      "province_code": "1401",
      "psgc_code": "140121000",
      "region_desc": "14"
    },
    {
      "city_code": "140122",
      "city_name": "San Juan",
      "province_code": "1401",
      "psgc_code": "140122000",
      "region_desc": "14"
    },
    {
      "city_code": "140123",
      "city_name": "San Quintin",
      "province_code": "1401",
      "psgc_code": "140123000",
      "region_desc": "14"
    },
    {
      "city_code": "140124",
      "city_name": "Tayum",
      "province_code": "1401",
      "psgc_code": "140124000",
      "region_desc": "14"
    },
    {
      "city_code": "140125",
      "city_name": "Tineg",
      "province_code": "1401",
      "psgc_code": "140125000",
      "region_desc": "14"
    },
    {
      "city_code": "140126",
      "city_name": "Tubo",
      "province_code": "1401",
      "psgc_code": "140126000",
      "region_desc": "14"
    },
    {
      "city_code": "140127",
      "city_name": "Villaviciosa",
      "province_code": "1401",
      "psgc_code": "140127000",
      "region_desc": "14"
    },
    {
      "city_code": "141101",
      "city_name": "Atok",
      "province_code": "1411",
      "psgc_code": "141101000",
      "region_desc": "14"
    },
    {
      "city_code": "141102",
      "city_name": "Baguio City",
      "province_code": "1411",
      "psgc_code": "141102000",
      "region_desc": "14"
    },
    {
      "city_code": "141103",
      "city_name": "Bakun",
      "province_code": "1411",
      "psgc_code": "141103000",
      "region_desc": "14"
    },
    {
      "city_code": "141104",
      "city_name": "Bokod",
      "province_code": "1411",
      "psgc_code": "141104000",
      "region_desc": "14"
    },
    {
      "city_code": "141105",
      "city_name": "Buguias",
      "province_code": "1411",
      "psgc_code": "141105000",
      "region_desc": "14"
    },
    {
      "city_code": "141106",
      "city_name": "Itogon",
      "province_code": "1411",
      "psgc_code": "141106000",
      "region_desc": "14"
    },
    {
      "city_code": "141107",
      "city_name": "Kabayan",
      "province_code": "1411",
      "psgc_code": "141107000",
      "region_desc": "14"
    },
    {
      "city_code": "141108",
      "city_name": "Kapangan",
      "province_code": "1411",
      "psgc_code": "141108000",
      "region_desc": "14"
    },
    {
      "city_code": "141109",
      "city_name": "Kibungan",
      "province_code": "1411",
      "psgc_code": "141109000",
      "region_desc": "14"
    },
    {
      "city_code": "141110",
      "city_name": "La Trinidad (Capital)",
      "province_code": "1411",
      "psgc_code": "141110000",
      "region_desc": "14"
    },
    {
      "city_code": "141111",
      "city_name": "Mankayan",
      "province_code": "1411",
      "psgc_code": "141111000",
      "region_desc": "14"
    },
    {
      "city_code": "141112",
      "city_name": "Sablan",
      "province_code": "1411",
      "psgc_code": "141112000",
      "region_desc": "14"
    },
    {
      "city_code": "141113",
      "city_name": "Tuba",
      "province_code": "1411",
      "psgc_code": "141113000",
      "region_desc": "14"
    },
    {
      "city_code": "141114",
      "city_name": "Tublay",
      "province_code": "1411",
      "psgc_code": "141114000",
      "region_desc": "14"
    },
    {
      "city_code": "142701",
      "city_name": "Banaue",
      "province_code": "1427",
      "psgc_code": "142701000",
      "region_desc": "14"
    },
    {
      "city_code": "142702",
      "city_name": "Hungduan",
      "province_code": "1427",
      "psgc_code": "142702000",
      "region_desc": "14"
    },
    {
      "city_code": "142703",
      "city_name": "Kiangan",
      "province_code": "1427",
      "psgc_code": "142703000",
      "region_desc": "14"
    },
    {
      "city_code": "142704",
      "city_name": "Lagawe (Capital)",
      "province_code": "1427",
      "psgc_code": "142704000",
      "region_desc": "14"
    },
    {
      "city_code": "142705",
      "city_name": "Lamut",
      "province_code": "1427",
      "psgc_code": "142705000",
      "region_desc": "14"
    },
    {
      "city_code": "142706",
      "city_name": "Mayoyao",
      "province_code": "1427",
      "psgc_code": "142706000",
      "region_desc": "14"
    },
    {
      "city_code": "142707",
      "city_name": "Alfonso Lista (Potia)",
      "province_code": "1427",
      "psgc_code": "142707000",
      "region_desc": "14"
    },
    {
      "city_code": "142708",
      "city_name": "Aguinaldo",
      "province_code": "1427",
      "psgc_code": "142708000",
      "region_desc": "14"
    },
    {
      "city_code": "142709",
      "city_name": "Hingyon",
      "province_code": "1427",
      "psgc_code": "142709000",
      "region_desc": "14"
    },
    {
      "city_code": "142710",
      "city_name": "Tinoc",
      "province_code": "1427",
      "psgc_code": "142710000",
      "region_desc": "14"
    },
    {
      "city_code": "142711",
      "city_name": "Asipulo",
      "province_code": "1427",
      "psgc_code": "142711000",
      "region_desc": "14"
    },
    {
      "city_code": "143201",
      "city_name": "Balbalan",
      "province_code": "1432",
      "psgc_code": "143201000",
      "region_desc": "14"
    },
    {
      "city_code": "143206",
      "city_name": "Lubuagan",
      "province_code": "1432",
      "psgc_code": "143206000",
      "region_desc": "14"
    },
    {
      "city_code": "143208",
      "city_name": "Pasil",
      "province_code": "1432",
      "psgc_code": "143208000",
      "region_desc": "14"
    },
    {
      "city_code": "143209",
      "city_name": "Pinukpuk",
      "province_code": "1432",
      "psgc_code": "143209000",
      "region_desc": "14"
    },
    {
      "city_code": "143211",
      "city_name": "Rizal (Liwan)",
      "province_code": "1432",
      "psgc_code": "143211000",
      "region_desc": "14"
    },
    {
      "city_code": "143213",
      "city_name": "City Of Tabuk (Capital)",
      "province_code": "1432",
      "psgc_code": "143213000",
      "region_desc": "14"
    },
    {
      "city_code": "143214",
      "city_name": "Tanudan",
      "province_code": "1432",
      "psgc_code": "143214000",
      "region_desc": "14"
    },
    {
      "city_code": "143215",
      "city_name": "Tinglayan",
      "province_code": "1432",
      "psgc_code": "143215000",
      "region_desc": "14"
    },
    {
      "city_code": "144401",
      "city_name": "Barlig",
      "province_code": "1444",
      "psgc_code": "144401000",
      "region_desc": "14"
    },
    {
      "city_code": "144402",
      "city_name": "Bauko",
      "province_code": "1444",
      "psgc_code": "144402000",
      "region_desc": "14"
    },
    {
      "city_code": "144403",
      "city_name": "Besao",
      "province_code": "1444",
      "psgc_code": "144403000",
      "region_desc": "14"
    },
    {
      "city_code": "144404",
      "city_name": "Bontoc (Capital)",
      "province_code": "1444",
      "psgc_code": "144404000",
      "region_desc": "14"
    },
    {
      "city_code": "144405",
      "city_name": "Natonin",
      "province_code": "1444",
      "psgc_code": "144405000",
      "region_desc": "14"
    },
    {
      "city_code": "144406",
      "city_name": "Paracelis",
      "province_code": "1444",
      "psgc_code": "144406000",
      "region_desc": "14"
    },
    {
      "city_code": "144407",
      "city_name": "Sabangan",
      "province_code": "1444",
      "psgc_code": "144407000",
      "region_desc": "14"
    },
    {
      "city_code": "144408",
      "city_name": "Sadanga",
      "province_code": "1444",
      "psgc_code": "144408000",
      "region_desc": "14"
    },
    {
      "city_code": "144409",
      "city_name": "Sagada",
      "province_code": "1444",
      "psgc_code": "144409000",
      "region_desc": "14"
    },
    {
      "city_code": "144410",
      "city_name": "Tadian",
      "province_code": "1444",
      "psgc_code": "144410000",
      "region_desc": "14"
    },
    {
      "city_code": "148101",
      "city_name": "Calanasan (Bayag)",
      "province_code": "1481",
      "psgc_code": "148101000",
      "region_desc": "14"
    },
    {
      "city_code": "148102",
      "city_name": "Conner",
      "province_code": "1481",
      "psgc_code": "148102000",
      "region_desc": "14"
    },
    {
      "city_code": "148103",
      "city_name": "Flora",
      "province_code": "1481",
      "psgc_code": "148103000",
      "region_desc": "14"
    },
    {
      "city_code": "148104",
      "city_name": "Kabugao (Capital)",
      "province_code": "1481",
      "psgc_code": "148104000",
      "region_desc": "14"
    },
    {
      "city_code": "148105",
      "city_name": "Luna",
      "province_code": "1481",
      "psgc_code": "148105000",
      "region_desc": "14"
    },
    {
      "city_code": "148106",
      "city_name": "Pudtol",
      "province_code": "1481",
      "psgc_code": "148106000",
      "region_desc": "14"
    },
    {
      "city_code": "148107",
      "city_name": "Santa Marcela",
      "province_code": "1481",
      "psgc_code": "148107000",
      "region_desc": "14"
    },
    {
      "city_code": "150702",
      "city_name": "City Of Lamitan",
      "province_code": "1507",
      "psgc_code": "150702000",
      "region_desc": "15"
    },
    {
      "city_code": "150703",
      "city_name": "Lantawan",
      "province_code": "1507",
      "psgc_code": "150703000",
      "region_desc": "15"
    },
    {
      "city_code": "150704",
      "city_name": "Maluso",
      "province_code": "1507",
      "psgc_code": "150704000",
      "region_desc": "15"
    },
    {
      "city_code": "150705",
      "city_name": "Sumisip",
      "province_code": "1507",
      "psgc_code": "150705000",
      "region_desc": "15"
    },
    {
      "city_code": "150706",
      "city_name": "Tipo-tipo",
      "province_code": "1507",
      "psgc_code": "150706000",
      "region_desc": "15"
    },
    {
      "city_code": "150707",
      "city_name": "Tuburan",
      "province_code": "1507",
      "psgc_code": "150707000",
      "region_desc": "15"
    },
    {
      "city_code": "150708",
      "city_name": "Akbar",
      "province_code": "1507",
      "psgc_code": "150708000",
      "region_desc": "15"
    },
    {
      "city_code": "150709",
      "city_name": "Al-barka",
      "province_code": "1507",
      "psgc_code": "150709000",
      "region_desc": "15"
    },
    {
      "city_code": "150710",
      "city_name": "Hadji Mohammad Ajul",
      "province_code": "1507",
      "psgc_code": "150710000",
      "region_desc": "15"
    },
    {
      "city_code": "150711",
      "city_name": "Ungkaya Pukan",
      "province_code": "1507",
      "psgc_code": "150711000",
      "region_desc": "15"
    },
    {
      "city_code": "150712",
      "city_name": "Hadji Muhtamad",
      "province_code": "1507",
      "psgc_code": "150712000",
      "region_desc": "15"
    },
    {
      "city_code": "150713",
      "city_name": "Tabuan-lasa",
      "province_code": "1507",
      "psgc_code": "150713000",
      "region_desc": "15"
    },
    {
      "city_code": "153601",
      "city_name": "Bacolod-kalawi (Bacolod Grande)",
      "province_code": "1536",
      "psgc_code": "153601000",
      "region_desc": "15"
    },
    {
      "city_code": "153602",
      "city_name": "Balabagan",
      "province_code": "1536",
      "psgc_code": "153602000",
      "region_desc": "15"
    },
    {
      "city_code": "153603",
      "city_name": "Balindong (Watu)",
      "province_code": "1536",
      "psgc_code": "153603000",
      "region_desc": "15"
    },
    {
      "city_code": "153604",
      "city_name": "Bayang",
      "province_code": "1536",
      "psgc_code": "153604000",
      "region_desc": "15"
    },
    {
      "city_code": "153605",
      "city_name": "Binidayan",
      "province_code": "1536",
      "psgc_code": "153605000",
      "region_desc": "15"
    },
    {
      "city_code": "153606",
      "city_name": "Bubong",
      "province_code": "1536",
      "psgc_code": "153606000",
      "region_desc": "15"
    },
    {
      "city_code": "153607",
      "city_name": "Butig",
      "province_code": "1536",
      "psgc_code": "153607000",
      "region_desc": "15"
    },
    {
      "city_code": "153609",
      "city_name": "Ganassi",
      "province_code": "1536",
      "psgc_code": "153609000",
      "region_desc": "15"
    },
    {
      "city_code": "153610",
      "city_name": "Kapai",
      "province_code": "1536",
      "psgc_code": "153610000",
      "region_desc": "15"
    },
    {
      "city_code": "153611",
      "city_name": "Lumba-bayabao (Maguing)",
      "province_code": "1536",
      "psgc_code": "153611000",
      "region_desc": "15"
    },
    {
      "city_code": "153612",
      "city_name": "Lumbatan",
      "province_code": "1536",
      "psgc_code": "153612000",
      "region_desc": "15"
    },
    {
      "city_code": "153613",
      "city_name": "Madalum",
      "province_code": "1536",
      "psgc_code": "153613000",
      "region_desc": "15"
    },
    {
      "city_code": "153614",
      "city_name": "Madamba",
      "province_code": "1536",
      "psgc_code": "153614000",
      "region_desc": "15"
    },
    {
      "city_code": "153615",
      "city_name": "Malabang",
      "province_code": "1536",
      "psgc_code": "153615000",
      "region_desc": "15"
    },
    {
      "city_code": "153616",
      "city_name": "Marantao",
      "province_code": "1536",
      "psgc_code": "153616000",
      "region_desc": "15"
    },
    {
      "city_code": "153617",
      "city_name": "Marawi City (Capital)",
      "province_code": "1536",
      "psgc_code": "153617000",
      "region_desc": "15"
    },
    {
      "city_code": "153618",
      "city_name": "Masiu",
      "province_code": "1536",
      "psgc_code": "153618000",
      "region_desc": "15"
    },
    {
      "city_code": "153619",
      "city_name": "Mulondo",
      "province_code": "1536",
      "psgc_code": "153619000",
      "region_desc": "15"
    },
    {
      "city_code": "153620",
      "city_name": "Pagayawan (Tatarikan)",
      "province_code": "1536",
      "psgc_code": "153620000",
      "region_desc": "15"
    },
    {
      "city_code": "153621",
      "city_name": "Piagapo",
      "province_code": "1536",
      "psgc_code": "153621000",
      "region_desc": "15"
    },
    {
      "city_code": "153622",
      "city_name": "Poona Bayabao (Gata)",
      "province_code": "1536",
      "psgc_code": "153622000",
      "region_desc": "15"
    },
    {
      "city_code": "153623",
      "city_name": "Pualas",
      "province_code": "1536",
      "psgc_code": "153623000",
      "region_desc": "15"
    },
    {
      "city_code": "153624",
      "city_name": "Ditsaan-ramain",
      "province_code": "1536",
      "psgc_code": "153624000",
      "region_desc": "15"
    },
    {
      "city_code": "153625",
      "city_name": "Saguiaran",
      "province_code": "1536",
      "psgc_code": "153625000",
      "region_desc": "15"
    },
    {
      "city_code": "153626",
      "city_name": "Tamparan",
      "province_code": "1536",
      "psgc_code": "153626000",
      "region_desc": "15"
    },
    {
      "city_code": "153627",
      "city_name": "Taraka",
      "province_code": "1536",
      "psgc_code": "153627000",
      "region_desc": "15"
    },
    {
      "city_code": "153628",
      "city_name": "Tubaran",
      "province_code": "1536",
      "psgc_code": "153628000",
      "region_desc": "15"
    },
    {
      "city_code": "153629",
      "city_name": "Tugaya",
      "province_code": "1536",
      "psgc_code": "153629000",
      "region_desc": "15"
    },
    {
      "city_code": "153630",
      "city_name": "Wao",
      "province_code": "1536",
      "psgc_code": "153630000",
      "region_desc": "15"
    },
    {
      "city_code": "153631",
      "city_name": "Marogong",
      "province_code": "1536",
      "psgc_code": "153631000",
      "region_desc": "15"
    },
    {
      "city_code": "153632",
      "city_name": "Calanogas",
      "province_code": "1536",
      "psgc_code": "153632000",
      "region_desc": "15"
    },
    {
      "city_code": "153633",
      "city_name": "Buadiposo-buntong",
      "province_code": "1536",
      "psgc_code": "153633000",
      "region_desc": "15"
    },
    {
      "city_code": "153634",
      "city_name": "Maguing",
      "province_code": "1536",
      "psgc_code": "153634000",
      "region_desc": "15"
    },
    {
      "city_code": "153635",
      "city_name": "Picong (Sultan Gumander)",
      "province_code": "1536",
      "psgc_code": "153635000",
      "region_desc": "15"
    },
    {
      "city_code": "153636",
      "city_name": "Lumbayanague",
      "province_code": "1536",
      "psgc_code": "153636000",
      "region_desc": "15"
    },
    {
      "city_code": "153637",
      "city_name": "Bumbaran",
      "province_code": "1536",
      "psgc_code": "153637000",
      "region_desc": "15"
    },
    {
      "city_code": "153638",
      "city_name": "Tagoloan Ii",
      "province_code": "1536",
      "psgc_code": "153638000",
      "region_desc": "15"
    },
    {
      "city_code": "153639",
      "city_name": "Kapatagan",
      "province_code": "1536",
      "psgc_code": "153639000",
      "region_desc": "15"
    },
    {
      "city_code": "153640",
      "city_name": "Sultan Dumalondong",
      "province_code": "1536",
      "psgc_code": "153640000",
      "region_desc": "15"
    },
    {
      "city_code": "153641",
      "city_name": "Lumbaca-unayan",
      "province_code": "1536",
      "psgc_code": "153641000",
      "region_desc": "15"
    },
    {
      "city_code": "153801",
      "city_name": "Ampatuan",
      "province_code": "1538",
      "psgc_code": "153801000",
      "region_desc": "15"
    },
    {
      "city_code": "153802",
      "city_name": "Buldon",
      "province_code": "1538",
      "psgc_code": "153802000",
      "region_desc": "15"
    },
    {
      "city_code": "153803",
      "city_name": "Buluan",
      "province_code": "1538",
      "psgc_code": "153803000",
      "region_desc": "15"
    },
    {
      "city_code": "153805",
      "city_name": "Datu Paglas",
      "province_code": "1538",
      "psgc_code": "153805000",
      "region_desc": "15"
    },
    {
      "city_code": "153806",
      "city_name": "Datu Piang",
      "province_code": "1538",
      "psgc_code": "153806000",
      "region_desc": "15"
    },
    {
      "city_code": "153807",
      "city_name": "Datu Odin Sinsuat (Dinaig)",
      "province_code": "1538",
      "psgc_code": "153807000",
      "region_desc": "15"
    },
    {
      "city_code": "153808",
      "city_name": "Shariff Aguak (Maganoy) (Capital)",
      "province_code": "1538",
      "psgc_code": "153808000",
      "region_desc": "15"
    },
    {
      "city_code": "153809",
      "city_name": "Matanog",
      "province_code": "1538",
      "psgc_code": "153809000",
      "region_desc": "15"
    },
    {
      "city_code": "153810",
      "city_name": "Pagalungan",
      "province_code": "1538",
      "psgc_code": "153810000",
      "region_desc": "15"
    },
    {
      "city_code": "153811",
      "city_name": "Parang",
      "province_code": "1538",
      "psgc_code": "153811000",
      "region_desc": "15"
    },
    {
      "city_code": "153812",
      "city_name": "Sultan Kudarat (Nuling)",
      "province_code": "1538",
      "psgc_code": "153812000",
      "region_desc": "15"
    },
    {
      "city_code": "153813",
      "city_name": "Sultan Sa Barongis (Lambayong)",
      "province_code": "1538",
      "psgc_code": "153813000",
      "region_desc": "15"
    },
    {
      "city_code": "153814",
      "city_name": "Kabuntalan (Tumbao)",
      "province_code": "1538",
      "psgc_code": "153814000",
      "region_desc": "15"
    },
    {
      "city_code": "153815",
      "city_name": "Upi",
      "province_code": "1538",
      "psgc_code": "153815000",
      "region_desc": "15"
    },
    {
      "city_code": "153816",
      "city_name": "Talayan",
      "province_code": "1538",
      "psgc_code": "153816000",
      "region_desc": "15"
    },
    {
      "city_code": "153817",
      "city_name": "South Upi",
      "province_code": "1538",
      "psgc_code": "153817000",
      "region_desc": "15"
    },
    {
      "city_code": "153818",
      "city_name": "Barira",
      "province_code": "1538",
      "psgc_code": "153818000",
      "region_desc": "15"
    },
    {
      "city_code": "153819",
      "city_name": "Gen. S. K. Pendatun",
      "province_code": "1538",
      "psgc_code": "153819000",
      "region_desc": "15"
    },
    {
      "city_code": "153820",
      "city_name": "Mamasapano",
      "province_code": "1538",
      "psgc_code": "153820000",
      "region_desc": "15"
    },
    {
      "city_code": "153821",
      "city_name": "Talitay",
      "province_code": "1538",
      "psgc_code": "153821000",
      "region_desc": "15"
    },
    {
      "city_code": "153822",
      "city_name": "Pagagawan",
      "province_code": "1538",
      "psgc_code": "153822000",
      "region_desc": "15"
    },
    {
      "city_code": "153823",
      "city_name": "Paglat",
      "province_code": "1538",
      "psgc_code": "153823000",
      "region_desc": "15"
    },
    {
      "city_code": "153824",
      "city_name": "Sultan Mastura",
      "province_code": "1538",
      "psgc_code": "153824000",
      "region_desc": "15"
    },
    {
      "city_code": "153825",
      "city_name": "Guindulungan",
      "province_code": "1538",
      "psgc_code": "153825000",
      "region_desc": "15"
    },
    {
      "city_code": "153826",
      "city_name": "Datu Saudi-ampatuan",
      "province_code": "1538",
      "psgc_code": "153826000",
      "region_desc": "15"
    },
    {
      "city_code": "153827",
      "city_name": "Datu Unsay",
      "province_code": "1538",
      "psgc_code": "153827000",
      "region_desc": "15"
    },
    {
      "city_code": "153828",
      "city_name": "Datu Abdullah Sangki",
      "province_code": "1538",
      "psgc_code": "153828000",
      "region_desc": "15"
    },
    {
      "city_code": "153829",
      "city_name": "Rajah Buayan",
      "province_code": "1538",
      "psgc_code": "153829000",
      "region_desc": "15"
    },
    {
      "city_code": "153830",
      "city_name": "Datu Blah T. Sinsuat",
      "province_code": "1538",
      "psgc_code": "153830000",
      "region_desc": "15"
    },
    {
      "city_code": "153831",
      "city_name": "Datu Anggal Midtimbang",
      "province_code": "1538",
      "psgc_code": "153831000",
      "region_desc": "15"
    },
    {
      "city_code": "153832",
      "city_name": "Mangudadatu",
      "province_code": "1538",
      "psgc_code": "153832000",
      "region_desc": "15"
    },
    {
      "city_code": "153833",
      "city_name": "Pandag",
      "province_code": "1538",
      "psgc_code": "153833000",
      "region_desc": "15"
    },
    {
      "city_code": "153834",
      "city_name": "Northern Kabuntalan",
      "province_code": "1538",
      "psgc_code": "153834000",
      "region_desc": "15"
    },
    {
      "city_code": "153835",
      "city_name": "Datu Hoffer Ampatuan",
      "province_code": "1538",
      "psgc_code": "153835000",
      "region_desc": "15"
    },
    {
      "city_code": "153836",
      "city_name": "Datu Salibo",
      "province_code": "1538",
      "psgc_code": "153836000",
      "region_desc": "15"
    },
    {
      "city_code": "153837",
      "city_name": "Shariff Saydona Mustapha",
      "province_code": "1538",
      "psgc_code": "153837000",
      "region_desc": "15"
    },
    {
      "city_code": "156601",
      "city_name": "Indanan",
      "province_code": "1566",
      "psgc_code": "156601000",
      "region_desc": "15"
    },
    {
      "city_code": "156602",
      "city_name": "Jolo (Capital)",
      "province_code": "1566",
      "psgc_code": "156602000",
      "region_desc": "15"
    },
    {
      "city_code": "156603",
      "city_name": "Kalingalan Caluang",
      "province_code": "1566",
      "psgc_code": "156603000",
      "region_desc": "15"
    },
    {
      "city_code": "156604",
      "city_name": "Luuk",
      "province_code": "1566",
      "psgc_code": "156604000",
      "region_desc": "15"
    },
    {
      "city_code": "156605",
      "city_name": "Maimbung",
      "province_code": "1566",
      "psgc_code": "156605000",
      "region_desc": "15"
    },
    {
      "city_code": "156606",
      "city_name": "Hadji Panglima Tahil (Marunggas)",
      "province_code": "1566",
      "psgc_code": "156606000",
      "region_desc": "15"
    },
    {
      "city_code": "156607",
      "city_name": "Old Panamao",
      "province_code": "1566",
      "psgc_code": "156607000",
      "region_desc": "15"
    },
    {
      "city_code": "156608",
      "city_name": "Pangutaran",
      "province_code": "1566",
      "psgc_code": "156608000",
      "region_desc": "15"
    },
    {
      "city_code": "156609",
      "city_name": "Parang",
      "province_code": "1566",
      "psgc_code": "156609000",
      "region_desc": "15"
    },
    {
      "city_code": "156610",
      "city_name": "Pata",
      "province_code": "1566",
      "psgc_code": "156610000",
      "region_desc": "15"
    },
    {
      "city_code": "156611",
      "city_name": "Patikul",
      "province_code": "1566",
      "psgc_code": "156611000",
      "region_desc": "15"
    },
    {
      "city_code": "156612",
      "city_name": "Siasi",
      "province_code": "1566",
      "psgc_code": "156612000",
      "region_desc": "15"
    },
    {
      "city_code": "156613",
      "city_name": "Talipao",
      "province_code": "1566",
      "psgc_code": "156613000",
      "region_desc": "15"
    },
    {
      "city_code": "156614",
      "city_name": "Tapul",
      "province_code": "1566",
      "psgc_code": "156614000",
      "region_desc": "15"
    },
    {
      "city_code": "156615",
      "city_name": "Tongkil",
      "province_code": "1566",
      "psgc_code": "156615000",
      "region_desc": "15"
    },
    {
      "city_code": "156616",
      "city_name": "Panglima Estino (New Panamao)",
      "province_code": "1566",
      "psgc_code": "156616000",
      "region_desc": "15"
    },
    {
      "city_code": "156617",
      "city_name": "Lugus",
      "province_code": "1566",
      "psgc_code": "156617000",
      "region_desc": "15"
    },
    {
      "city_code": "156618",
      "city_name": "Pandami",
      "province_code": "1566",
      "psgc_code": "156618000",
      "region_desc": "15"
    },
    {
      "city_code": "156619",
      "city_name": "Omar",
      "province_code": "1566",
      "psgc_code": "156619000",
      "region_desc": "15"
    },
    {
      "city_code": "157001",
      "city_name": "Panglima Sugala (Balimbing)",
      "province_code": "1570",
      "psgc_code": "157001000",
      "region_desc": "15"
    },
    {
      "city_code": "157002",
      "city_name": "Bongao (Capital)",
      "province_code": "1570",
      "psgc_code": "157002000",
      "region_desc": "15"
    },
    {
      "city_code": "157003",
      "city_name": "Mapun (Cagayan De Tawi-tawi)",
      "province_code": "1570",
      "psgc_code": "157003000",
      "region_desc": "15"
    },
    {
      "city_code": "157004",
      "city_name": "Simunul",
      "province_code": "1570",
      "psgc_code": "157004000",
      "region_desc": "15"
    },
    {
      "city_code": "157005",
      "city_name": "Sitangkai",
      "province_code": "1570",
      "psgc_code": "157005000",
      "region_desc": "15"
    },
    {
      "city_code": "157006",
      "city_name": "South Ubian",
      "province_code": "1570",
      "psgc_code": "157006000",
      "region_desc": "15"
    },
    {
      "city_code": "157007",
      "city_name": "Tandubas",
      "province_code": "1570",
      "psgc_code": "157007000",
      "region_desc": "15"
    },
    {
      "city_code": "157008",
      "city_name": "Turtle Islands",
      "province_code": "1570",
      "psgc_code": "157008000",
      "region_desc": "15"
    },
    {
      "city_code": "157009",
      "city_name": "Languyan",
      "province_code": "1570",
      "psgc_code": "157009000",
      "region_desc": "15"
    },
    {
      "city_code": "157010",
      "city_name": "Sapa-sapa",
      "province_code": "1570",
      "psgc_code": "157010000",
      "region_desc": "15"
    },
    {
      "city_code": "157011",
      "city_name": "Sibutu",
      "province_code": "1570",
      "psgc_code": "157011000",
      "region_desc": "15"
    },
    {
      "city_code": "160201",
      "city_name": "Buenavista",
      "province_code": "1602",
      "psgc_code": "160201000",
      "region_desc": "16"
    },
    {
      "city_code": "160202",
      "city_name": "Butuan City (Capital)",
      "province_code": "1602",
      "psgc_code": "160202000",
      "region_desc": "16"
    },
    {
      "city_code": "160203",
      "city_name": "City Of Cabadbaran",
      "province_code": "1602",
      "psgc_code": "160203000",
      "region_desc": "16"
    },
    {
      "city_code": "160204",
      "city_name": "Carmen",
      "province_code": "1602",
      "psgc_code": "160204000",
      "region_desc": "16"
    },
    {
      "city_code": "160205",
      "city_name": "Jabonga",
      "province_code": "1602",
      "psgc_code": "160205000",
      "region_desc": "16"
    },
    {
      "city_code": "160206",
      "city_name": "Kitcharao",
      "province_code": "1602",
      "psgc_code": "160206000",
      "region_desc": "16"
    },
    {
      "city_code": "160207",
      "city_name": "Las Nieves",
      "province_code": "1602",
      "psgc_code": "160207000",
      "region_desc": "16"
    },
    {
      "city_code": "160208",
      "city_name": "Magallanes",
      "province_code": "1602",
      "psgc_code": "160208000",
      "region_desc": "16"
    },
    {
      "city_code": "160209",
      "city_name": "Nasipit",
      "province_code": "1602",
      "psgc_code": "160209000",
      "region_desc": "16"
    },
    {
      "city_code": "160210",
      "city_name": "Santiago",
      "province_code": "1602",
      "psgc_code": "160210000",
      "region_desc": "16"
    },
    {
      "city_code": "160211",
      "city_name": "Tubay",
      "province_code": "1602",
      "psgc_code": "160211000",
      "region_desc": "16"
    },
    {
      "city_code": "160212",
      "city_name": "Remedios T. Romualdez",
      "province_code": "1602",
      "psgc_code": "160212000",
      "region_desc": "16"
    },
    {
      "city_code": "160301",
      "city_name": "City Of Bayugan",
      "province_code": "1603",
      "psgc_code": "160301000",
      "region_desc": "16"
    },
    {
      "city_code": "160302",
      "city_name": "Bunawan",
      "province_code": "1603",
      "psgc_code": "160302000",
      "region_desc": "16"
    },
    {
      "city_code": "160303",
      "city_name": "Esperanza",
      "province_code": "1603",
      "psgc_code": "160303000",
      "region_desc": "16"
    },
    {
      "city_code": "160304",
      "city_name": "La Paz",
      "province_code": "1603",
      "psgc_code": "160304000",
      "region_desc": "16"
    },
    {
      "city_code": "160305",
      "city_name": "Loreto",
      "province_code": "1603",
      "psgc_code": "160305000",
      "region_desc": "16"
    },
    {
      "city_code": "160306",
      "city_name": "Prosperidad (Capital)",
      "province_code": "1603",
      "psgc_code": "160306000",
      "region_desc": "16"
    },
    {
      "city_code": "160307",
      "city_name": "Rosario",
      "province_code": "1603",
      "psgc_code": "160307000",
      "region_desc": "16"
    },
    {
      "city_code": "160308",
      "city_name": "San Francisco",
      "province_code": "1603",
      "psgc_code": "160308000",
      "region_desc": "16"
    },
    {
      "city_code": "160309",
      "city_name": "San Luis",
      "province_code": "1603",
      "psgc_code": "160309000",
      "region_desc": "16"
    },
    {
      "city_code": "160310",
      "city_name": "Santa Josefa",
      "province_code": "1603",
      "psgc_code": "160310000",
      "region_desc": "16"
    },
    {
      "city_code": "160311",
      "city_name": "Talacogon",
      "province_code": "1603",
      "psgc_code": "160311000",
      "region_desc": "16"
    },
    {
      "city_code": "160312",
      "city_name": "Trento",
      "province_code": "1603",
      "psgc_code": "160312000",
      "region_desc": "16"
    },
    {
      "city_code": "160313",
      "city_name": "Veruela",
      "province_code": "1603",
      "psgc_code": "160313000",
      "region_desc": "16"
    },
    {
      "city_code": "160314",
      "city_name": "Sibagat",
      "province_code": "1603",
      "psgc_code": "160314000",
      "region_desc": "16"
    },
    {
      "city_code": "166701",
      "city_name": "Alegria",
      "province_code": "1667",
      "psgc_code": "166701000",
      "region_desc": "16"
    },
    {
      "city_code": "166702",
      "city_name": "Bacuag",
      "province_code": "1667",
      "psgc_code": "166702000",
      "region_desc": "16"
    },
    {
      "city_code": "166704",
      "city_name": "Burgos",
      "province_code": "1667",
      "psgc_code": "166704000",
      "region_desc": "16"
    },
    {
      "city_code": "166706",
      "city_name": "Claver",
      "province_code": "1667",
      "psgc_code": "166706000",
      "region_desc": "16"
    },
    {
      "city_code": "166707",
      "city_name": "Dapa",
      "province_code": "1667",
      "psgc_code": "166707000",
      "region_desc": "16"
    },
    {
      "city_code": "166708",
      "city_name": "Del Carmen",
      "province_code": "1667",
      "psgc_code": "166708000",
      "region_desc": "16"
    },
    {
      "city_code": "166710",
      "city_name": "General Luna",
      "province_code": "1667",
      "psgc_code": "166710000",
      "region_desc": "16"
    },
    {
      "city_code": "166711",
      "city_name": "Gigaquit",
      "province_code": "1667",
      "psgc_code": "166711000",
      "region_desc": "16"
    },
    {
      "city_code": "166714",
      "city_name": "Mainit",
      "province_code": "1667",
      "psgc_code": "166714000",
      "region_desc": "16"
    },
    {
      "city_code": "166715",
      "city_name": "Malimono",
      "province_code": "1667",
      "psgc_code": "166715000",
      "region_desc": "16"
    },
    {
      "city_code": "166716",
      "city_name": "Pilar",
      "province_code": "1667",
      "psgc_code": "166716000",
      "region_desc": "16"
    },
    {
      "city_code": "166717",
      "city_name": "Placer",
      "province_code": "1667",
      "psgc_code": "166717000",
      "region_desc": "16"
    },
    {
      "city_code": "166718",
      "city_name": "San Benito",
      "province_code": "1667",
      "psgc_code": "166718000",
      "region_desc": "16"
    },
    {
      "city_code": "166719",
      "city_name": "San Francisco (Anao-aon)",
      "province_code": "1667",
      "psgc_code": "166719000",
      "region_desc": "16"
    },
    {
      "city_code": "166720",
      "city_name": "San Isidro",
      "province_code": "1667",
      "psgc_code": "166720000",
      "region_desc": "16"
    },
    {
      "city_code": "166721",
      "city_name": "Santa Monica (Sapao)",
      "province_code": "1667",
      "psgc_code": "166721000",
      "region_desc": "16"
    },
    {
      "city_code": "166722",
      "city_name": "Sison",
      "province_code": "1667",
      "psgc_code": "166722000",
      "region_desc": "16"
    },
    {
      "city_code": "166723",
      "city_name": "Socorro",
      "province_code": "1667",
      "psgc_code": "166723000",
      "region_desc": "16"
    },
    {
      "city_code": "166724",
      "city_name": "Surigao City (Capital)",
      "province_code": "1667",
      "psgc_code": "166724000",
      "region_desc": "16"
    },
    {
      "city_code": "166725",
      "city_name": "Tagana-an",
      "province_code": "1667",
      "psgc_code": "166725000",
      "region_desc": "16"
    },
    {
      "city_code": "166727",
      "city_name": "Tubod",
      "province_code": "1667",
      "psgc_code": "166727000",
      "region_desc": "16"
    },
    {
      "city_code": "166801",
      "city_name": "Barobo",
      "province_code": "1668",
      "psgc_code": "166801000",
      "region_desc": "16"
    },
    {
      "city_code": "166802",
      "city_name": "Bayabas",
      "province_code": "1668",
      "psgc_code": "166802000",
      "region_desc": "16"
    },
    {
      "city_code": "166803",
      "city_name": "City Of Bislig",
      "province_code": "1668",
      "psgc_code": "166803000",
      "region_desc": "16"
    },
    {
      "city_code": "166804",
      "city_name": "Cagwait",
      "province_code": "1668",
      "psgc_code": "166804000",
      "region_desc": "16"
    },
    {
      "city_code": "166805",
      "city_name": "Cantilan",
      "province_code": "1668",
      "psgc_code": "166805000",
      "region_desc": "16"
    },
    {
      "city_code": "166806",
      "city_name": "Carmen",
      "province_code": "1668",
      "psgc_code": "166806000",
      "region_desc": "16"
    },
    {
      "city_code": "166807",
      "city_name": "Carrascal",
      "province_code": "1668",
      "psgc_code": "166807000",
      "region_desc": "16"
    },
    {
      "city_code": "166808",
      "city_name": "Cortes",
      "province_code": "1668",
      "psgc_code": "166808000",
      "region_desc": "16"
    },
    {
      "city_code": "166809",
      "city_name": "Hinatuan",
      "province_code": "1668",
      "psgc_code": "166809000",
      "region_desc": "16"
    },
    {
      "city_code": "166810",
      "city_name": "Lanuza",
      "province_code": "1668",
      "psgc_code": "166810000",
      "region_desc": "16"
    },
    {
      "city_code": "166811",
      "city_name": "Lianga",
      "province_code": "1668",
      "psgc_code": "166811000",
      "region_desc": "16"
    },
    {
      "city_code": "166812",
      "city_name": "Lingig",
      "province_code": "1668",
      "psgc_code": "166812000",
      "region_desc": "16"
    },
    {
      "city_code": "166813",
      "city_name": "Madrid",
      "province_code": "1668",
      "psgc_code": "166813000",
      "region_desc": "16"
    },
    {
      "city_code": "166814",
      "city_name": "Marihatag",
      "province_code": "1668",
      "psgc_code": "166814000",
      "region_desc": "16"
    },
    {
      "city_code": "166815",
      "city_name": "San Agustin",
      "province_code": "1668",
      "psgc_code": "166815000",
      "region_desc": "16"
    },
    {
      "city_code": "166816",
      "city_name": "San Miguel",
      "province_code": "1668",
      "psgc_code": "166816000",
      "region_desc": "16"
    },
    {
      "city_code": "166817",
      "city_name": "Tagbina",
      "province_code": "1668",
      "psgc_code": "166817000",
      "region_desc": "16"
    },
    {
      "city_code": "166818",
      "city_name": "Tago",
      "province_code": "1668",
      "psgc_code": "166818000",
      "region_desc": "16"
    },
    {
      "city_code": "166819",
      "city_name": "City Of Tandag (Capital)",
      "province_code": "1668",
      "psgc_code": "166819000",
      "region_desc": "16"
    },
    {
      "city_code": "168501",
      "city_name": "Basilisa (Rizal)",
      "province_code": "1685",
      "psgc_code": "168501000",
      "region_desc": "16"
    },
    {
      "city_code": "168502",
      "city_name": "Cagdianao",
      "province_code": "1685",
      "psgc_code": "168502000",
      "region_desc": "16"
    },
    {
      "city_code": "168503",
      "city_name": "Dinagat",
      "province_code": "1685",
      "psgc_code": "168503000",
      "region_desc": "16"
    },
    {
      "city_code": "168504",
      "city_name": "Libjo (Albor)",
      "province_code": "1685",
      "psgc_code": "168504000",
      "region_desc": "16"
    },
    {
      "city_code": "168505",
      "city_name": "Loreto",
      "province_code": "1685",
      "psgc_code": "168505000",
      "region_desc": "16"
    },
    {
      "city_code": "168506",
      "city_name": "San Jose (Capital)",
      "province_code": "1685",
      "psgc_code": "168506000",
      "region_desc": "16"
    },
    {
      "city_code": "168507",
      "city_name": "Tubajon",
      "province_code": "1685",
      "psgc_code": "168507000",
      "region_desc": "16"
    }
  ]