import actionType from '../constants/permission'
// import { store } from '../../index'
import store from '../../store'

export const loadPermission = () => {
  let permission = localStorage.getItem('permission')

  if (permission !== null) {
    return { permission: JSON.parse(permission) }
  }

  return { permission: null }
}

export const storePermission = () => {
  let state = store.getState()

  if (state.permission !== null) {
    localStorage.setItem('permission', JSON.stringify(state.permission))
  }
}
