import React, { useState } from 'react'

const useCustomPagination = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('ASC');

    const updatePagination = ({ newCurrentPage, newPageSize, newTotalPages, newSearchTerm, newSortBy, newOrder } = {}) => {
        if (newCurrentPage !== undefined) setCurrentPage(newCurrentPage);
        if (newPageSize !== undefined) setPageSize(newPageSize);
        if (newTotalPages !== undefined) setTotalPages(newTotalPages);
        if (newSearchTerm !== undefined) setSearchTerm(newSearchTerm);
        if (newSortBy !== undefined) setSortBy(newSortBy);
        if (newOrder !== undefined) setOrder(newOrder);
    }
    return { currentPage, pageSize, totalPages, searchTerm, sortBy, order, updatePagination }
}

export default useCustomPagination;
