import React, { useState, useEffect } from 'react';
import { CCol, CInput, CTextarea } from '@coreui/react';
import axios from 'axios';
import IP from '../../../StoreGlobal/IP';
import { Date } from 'core-js';
import { RequestHistoryEdit } from 'src/request/Materials';
// import ThingsToConsider from '../aimhi-widgets/ThingToConsider';

const PrintTransferMaterialHistory = React.forwardRef((props, ref) => {
  let accessToken = localStorage.getItem('token');
  const company_id = parseInt(localStorage.getItem('company_id'));
  let user_id = parseInt(localStorage.getItem('user_id'));
  const Swal = require('sweetalert2');

  const [transferMaterialRequest, setTransferMaterialRequest] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [location, setLocation] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [companyProfilePicture, setcompanyProfilePicture] = useState('');
  const [department, setDepartment] = useState('');
  const [projectName, setProjectName] = useState('');
  const [dateRequested, setDateRequested] = useState('');
  const [datePurchased, setDatePurchased] = useState('');
  const [fullName, setFullname] = useState('');
  const [dateNow, setDateNow] = useState("'");
  const [projectManager, setprojectManager] = useState('');
  const [siteEngineer, setsiteEngineer] = useState('');
  const [approver, setApprover] = useState('Name of Approver');
  const [positionApprover, setPositionApprover] = useState('position');
  const [editApprover, setEditApprover] = useState(false);
  const [editProsition, setEditPosistion] = useState(false);
  const [dateApproved, setDateApproved] = useState('mm/dd/yyyy');
  const [editDateApproved, setEditDateApproved] = useState(false);
  const [positionReq, setPositionReq] = useState('Position');
  const [editPositionReq, setEditPositionReq] = useState(false);
  const [nameRequester, setNameRequester] = useState('Name of Requester');
  const [editNameReq, setEditNameReq] = useState(false);

  useEffect(() => {
    console.log('transferMaterialRequest', props.transferMaterialRequest);
    console.log('date_created', props.transferMaterialRequest?.date_created);
    let user_id = parseInt(localStorage.getItem('user_id'));
    setTransferMaterialRequest(props.transferMaterialRequest ?? []);
    getCompanyData(company_id);
    GetUsersById(user_id);
    getDate();
    getoverview(props.project_id);
  }, [props.purchaserequest]);

  const getDate = () => {
    let date = Date.now();
    let dateparse = new Date(date).toLocaleString();
    // setDateNow(dateparse);
  };

  const getCompanyData = (company_id) => {
    axios
      .get(IP + `company/info/${company_id}`, {
        company_id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          // "Drive-Token": token,
        },
      })
      .then(function (response) {
        if (response.data.status == 1) {
          setCompanyName(response.data.response.name);

          setLocation(response.data.response.location);
          //   setBusinessEmail(response.data.response.business_email);
          //   setBusinessPhone(response.data.response.contact_num);

          setcompanyProfilePicture(response.data.response.company_pic);
          console.log(response.data.response.company_pic, 'hello');
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.response,
            text: response.data.description,
            confirmButtonColor: '#23303D',
          });
        }
      });
  };

  const getoverview = (project_id) => {
    axios
      .get(IP + `overview/${company_id}/${project_id}`, {
        // project_id,
        // company_id: company_id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        // setimage(images);
        // setprojectManager(res.data.response.project_team.project_manager);
        // setsiteEngineer(res.data.response.project_team.site_engineer);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const GetUsersById = (user_id) => {
    axios
      .post(IP + 'account/user/info', {
        user_id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          // "Drive-Token": token,
        },
      })
      .then(function (response) {
        console.log(response);
        if (response.data.status == 1) {
          //   setFullname(response.data.response.full_name);

          setDepartment(response.data.response.department);
        }
      });
  };

  const SaveName = (e) => {
    if (e.key == 'Enter') {
      setEditApprover(false);
    }
  };

  const SavePosition = (e) => {
    if (e.key == 'Enter') {
      setEditPosistion(false);
    }
  };

  const SaveDateApproved = (e) => {
    if (e.key == 'Enter') {
      setEditDateApproved(!editDateApproved);
    }
  };

  const SavePositionReq = (e) => {
    if (e.key == 'Enter') {
      setEditPositionReq(!editPositionReq);
    }
  };

  const SaveNameRequester = (e) => {
    if (e.key == 'Enter') {
      setEditNameReq(!editNameReq);
    }
  };

  const editNotes = (index) => {
    let arr = [...transferMaterialRequest];
    arr[index]['edit'] = true;
    setTransferMaterialRequest(arr);
  };

  const noteChange = (e, index, tx_id) => {
    // if (e.key == "Enter") {
    //   let arr = [...transferMaterialRequest];];
    //   arr[index].notes = e.target.value;
    //   arr[index]["edit"] = false;
    //   setTransferMaterialRequest(arr);

    //   let data = {
    //     tx_id: tx_id,
    //     notes: e.target.value,
    //   };

    //   RequestHistoryEdit(data).then((res) => {
    //     console.log(res);
    //   });
    // }

    let arr = [...transferMaterialRequest];
    arr[index].notes = e.target.value;
    // arr[index]['edit'] = false;
    setTransferMaterialRequest(arr);

    let data = {
      tx_id: tx_id,
      notes: e.target.value,
    };

    RequestHistoryEdit(data).then((res) => {
      console.log(res);
    });
  };

  const noteOnBlur = (index) => {
    let arr = [...transferMaterialRequest];
    arr[index]['edit'] = false;
    setTransferMaterialRequest(arr);
  };

  const editUnits = (index) => {
    let arr = [...transferMaterialRequest];
    arr[index]['edit_units'] = true;
    setTransferMaterialRequest(arr);
  };

  const unitChange = (e, index, tx_id) => {
    let arr = [...transferMaterialRequest];
    arr[index].unit = e.target.value;
    arr[index]['edit_units'] = false;
    setTransferMaterialRequest(arr);

    let data = {
      tx_id: tx_id,
      unit: e.target.value,
    };

    RequestHistoryEdit(data).then((res) => {
      console.log(res);
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const materialsTable = transferMaterialRequest?.record_details?.map((item, Index) => {
    return (
      <tr key={Index}>
        <td>{Index + 1}</td>
        <td>{item.material_name}</td>
        <td>{item.quantity}</td>
        {/* <td onClick={() => editUnits(Index)}>
          {item['edit_units'] == true ? (
            <CTextarea
              // onKeyPress={(e) => noteChange(e, Index, item.tx_id)}
              onBlur={(e) => unitChange(e, Index, item.tx_id)}
            ></CTextarea>
          ) : (
            <span>{item.unit}</span>
          )}
        </td> */}
        {/* <td onClick={() => editNotes(Index)}>
          {item['edit'] == true ? (
            <CTextarea
              // onKeyPress={(e) => noteChange(e, Index, item.tx_id)}
              onChange={(e) => noteChange(e, Index, item.tx_id)}
              onBlur={(e) => noteOnBlur(Index)}
              value={item.notes}
            ></CTextarea>
          ) : (
            <span>{item.notes}</span>
          )}
        </td> */}
      </tr>
    );
  });

  return (
    <div ref={ref} style={{ padding: '10px' }}>
      <table className="table table-bordered" style={{ marginTop: '20px' }}>
        <tr style={{ fontWeight: 'bold' }}>
          <td colSpan={6} style={{ textAlign: 'center' }}>
            <img src={companyProfilePicture} style={{ width: '100px', height: '100px' }} />
            <div>
              <span>{companyName}</span>
            </div>
            <div>
              <span>{location}</span>
            </div>
            <div style={{ marginTop: '15px' }}>
              <span>Transfer Material Request</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div>
              <span style={{ fontWeight: 'bold' }}>Project Name :</span>
              <span style={{ marginLeft: '5px' }}>{props.project_name}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Transfer to :</span>
              <span style={{ marginLeft: '5px' }}>{props.transferMaterialRequest.receiver_name}</span>
            </div>
          </td>
          <td colSpan={3} style={{ textAlign: 'right' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>Date Requested :</span>
              {/* <span style={{ marginLeft: '5px' }}>{props.transferMaterialRequest?.date_created ?? ''}</span> */}
              <span style={{ marginLeft: '5px' }}>
                {props.transferMaterialRequest?.date_created
                  ? new Date(props.transferMaterialRequest.date_created).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    })
                  : ''}
              </span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Transaction ID :</span>
              <span style={{ marginLeft: '5px' }}>{props.transferMaterialRequest?.id ?? ''}</span>
            </div>
          </td>
        </tr>
      </table>
      <table className="table table-bordered" style={{ marginTop: '20px' }}>
        <thead>
          <tr>
            <th>Item</th>
            <th>Item Description</th>
            <th>Quantity</th>
            {/* <th>Unit</th> */}
            {/* <th>Notes (optional)</th> */}
          </tr>
        </thead>
        <tbody>{materialsTable}</tbody>
      </table>
      <table className="table table-bordered">
        <tbody style={{ textAlign: 'center' }}>
          <tr style={{ fontSize: '12px' }}>
            <td>Created by:</td>
            {/* <td>Prepared by:</td> */}
            <td style={{ width: '300px' }}>Notes: </td>
            <td>Date Approved:</td>
            <td colSpan={2}>Approved by:</td>
          </tr>
          <tr style={{ fontSize: '12px' }}>
            <td style={{ verticalAlign: 'bottom', height: '60px' }}>
              {/* {editNameReq == true ? (
                <CInput type="text" value={nameRequester} onChange={(e) => setNameRequester(e.target.value)} onKeyPress={(e) => SaveNameRequester(e)} />
              ) : (
                <span onClick={() => setEditNameReq(!editNameReq)} className="pointer">
                  {nameRequester}
                </span>
              )} */}
              {transferMaterialRequest?.created_by}
            </td>
            {/* <td style={{ verticalAlign: 'bottom' }}>{props.purchaserequest?.preparer}</td> */}
            <td>{props.purchaserequest?.notes}</td>
            <td rowSpan={2} style={{ verticalAlign: 'bottom' }}>
              {transferMaterialRequest.final_status === 1
                ? formatDate(transferMaterialRequest?.date_time_approved_for_delivery)
                : transferMaterialRequest.final_status === 2
                ? formatDate(transferMaterialRequest?.date_time_rejected_for_delivery)
                : transferMaterialRequest.final_status === 3
                ? formatDate(transferMaterialRequest?.date_time_approved_on_site_received)
                : ''}
            </td>
            <td colSpan={2} rowSpan={2} style={{ verticalAlign: 'bottom' }}>
              {transferMaterialRequest.final_status === 1
                ? transferMaterialRequest?.approved_for_delivery_by_name
                : transferMaterialRequest.final_status === 2
                ? transferMaterialRequest?.rejected_for_delivery_by_name
                : transferMaterialRequest.final_status === 3
                ? transferMaterialRequest?.approved_on_site_received_by_name
                : ''}
            </td>
          </tr>
          {/* <tr style={{ fontSize: '12px' }}>
            <td>
              {editPositionReq == true ? (
                <CInput type="text" onChange={(e) => setPositionReq(e.target.value)} value={positionReq} onKeyPress={(e) => SavePositionReq(e)} />
              ) : (
                <span onClick={() => setEditPositionReq(!editPositionReq)} className="pointer">
                  {positionReq}
                </span>
              )}
            </td>
            <td>{props.purchaserequest?.preparer_position ?? ''}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
});

export default PrintTransferMaterialHistory;
