import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const GetPhases = () => {
  return $axios.get(`phase/?limit=-1`);
};

export const GetEstimate = (project_id) => {
  let companyId = parseInt(localStorage.getItem('company_id'));

  return $axios.get(`estimate/${companyId}/${project_id}`);
};

export const SearchSow = (dataVal, project_id, sow_id, search) => {
  let companyId = parseInt(localStorage.getItem('company_id'));
  let data = {
    data: dataVal,
  };

  // return $axios.get(`estimate/${companyId}/${project_id}?sow=${sow_id}`, {
  //   params: data,
  // });

  return $axios.get(`estimate/${companyId}/${project_id}?sow=${sow_id}&search=${search}`);
};

export const GetUnits = () => {
  return $axios.get('estimate/units');
};

export const GetActualCost = (project_id) => {
  return $axios.get(`/estimate/actual-cost-computation`, { params: { project_id } });
};
