import React, { useState, useEffect } from 'react';
import ai2 from '../../assets/img/ai_2.png';
// import ai2 from '../../assets/img/ai-suggest2.png';
import { CForm, CFormGroup, CInputRadio, CLabel, CButton, CSpinner } from '@coreui/react';

// const RecommenderPopUp = () => {
//   const [isClicked, setIsClicked] = useState(false);

//   const handleClick = () => {
//     setIsClicked(!isClicked);
//   };
//   const handleSubmit = () => {
//     // Handle submit logic here
//     console.log('Submit button clicked!');
//   };

//   return (
//     <div style={{ position: 'relative', marginLeft: '8px' }}>
//       <img src={ai2} alt="AI Logo" style={{ height: '36px', width: '36px', cursor: 'pointer' }} onClick={handleClick} />
//       {isClicked && (
//         <div
//           style={{
//             width: '360px',
//             height: '200px',
//             backgroundColor: '#DCE7DF',
//             position: 'absolute',
//             top: '40px',
//             left: '0',
//             zIndex: '1',
//             borderRadius: '0 10px 10px 10px',
//             boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
//             padding: '25px 20px 0 20px',
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'flex-start',
//             fontSize: '12px',
//           }}
//         >
//           {/* title here */}
//           <p style={{ fontSize: '12px' }}>Based on your project requirements, this quantity is low. It is recommended to have 1500 tons of Portland Cement.</p>
//           <label style={{ display: 'block', marginBottom: '10px', marginRight: '10' }}>
//             <input type="radio" name="recommendation" value="option1" /> Change quantity to 1500
//           </label>

//           <label style={{ display: 'block', marginBottom: '10px' }}>
//             <input type="radio" name="recommendation" value="option2" /> Record quantity for the project type
//           </label>

//           <label style={{ display: 'block' }}>
//             <input type="radio" name="recommendation" value="option3" /> Do not change
//           </label>
//           <CButton
//             className="ml-auto" // Aligns the button to the right
//             onClick={handleSubmit}
//             style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px', fontSize: '12px' }}
//           >
//             Submit
//           </CButton>
//         </div>
//       )}
//     </div>
//   );
// };

const RecommenderPopUp = (props) => {
  const [isClicked, setIsClicked] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  let iconDimensions = { height: '36px', width: '36px' };

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  // small
  if (props?.size === 's') {
    iconDimensions = { height: '25px', width: '25px' };
  }

  return (
    <div style={{ position: 'relative', marginLeft: '8px' }}>
      <img src={ai2} alt="AI Logo" style={{ ...iconDimensions, cursor: 'pointer' }} onClick={handleClick} />

      {isClicked && (
        <div
          style={{
            width: '360px',
            height: 'auto',
            maxWidth: '360px',
            backgroundColor: '#DCE7DF',
            position: 'absolute',
            top: '40px',
            left: '0',
            zIndex: '1',
            borderRadius: '0 10px 10px 10px',
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
            padding: '25px 20px 0 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            fontSize: '12px',
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};
export default RecommenderPopUp;

{
  /* Map over recommendations and render options dynamically */
}
{
  /* {recommendations.map((recommendation, index) => (
            <React.Fragment key={index}>
              <p style={{ fontSize: '12px', marginBottom: '10px' }}>{recommendation.title}</p>
              {recommendation.options.map((option, optionIndex) => (
                <label key={optionIndex} style={{ display: 'block', marginBottom: '10px' }}>
                  <input type="radio" name={`recommendation-${index}`} value={`option-${optionIndex + 1}`} checked={selectedOption === `option-${optionIndex + 1}`} onChange={() => handleOptionChange(`option-${optionIndex + 1}`)} /> {option}
                </label>
              ))}
            </React.Fragment>
          ))}
          <CButton
            className="ml-auto" // Aligns the button to the right
            onClick={handleSubmit}
            style={{ backgroundColor: '#23303D', color: '#FFFFFF', marginRight: '10px', fontSize: '12px' }}
          >
            Submit
          </CButton> */
}
