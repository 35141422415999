import { CButton, CTooltip } from '@coreui/react';
import { green } from '@mui/material/colors';
import calculator from '../../assets/img/backup-calculator.png';
import axios from 'axios';
import IP from '../../StoreGlobal/IP';
import React, { useState } from 'react';

const BackupButton = ({ toggleCalculator, projectID, dupadata, scopeIndex, Index, accessToken, setLaborProductivityForCalculator, setEquipmentProductivityForCalculator, title, editLabor, editEquipment, setMaterialQuantityForCalculator, editMaterial }) => {
  // const [laborProductivity, setLaborProductivity] = useState([]);
  const customTooltipStyle = {
    '--cui-tooltip-bg': 'red',
  };

  const getHistory = ({ title }) => {
    let newdata = [...dupadata];

    if (title === 'Labor') {
      const requestData = {
        type: 1,
        type_id: newdata[scopeIndex].labor[Index].labor_id,
        phase_id: newdata[scopeIndex].phase_id,
        sow_id: newdata[scopeIndex].sow_id,
        project_id: projectID,
        unit_area_id: newdata[scopeIndex].unit_id,
      };

      axios
        .post(IP + 'calculator/detail', requestData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.response == 'No data') {
            let area_value = newdata[scopeIndex].measurement;
            let area_unit_id = newdata[scopeIndex].unit_id < 0 ? 7 : newdata[scopeIndex].unit_id;
            let productivity_history = response.data.history;
            console.log('No Productivity Data');
            let laborProductivityArray = [
              {
                productivity: productivity_history.length > 0 ? productivity_history[0] : null,
                productivity_unit: null,
                area_value: area_value,
                area_unit: null,
                area_unit_id: area_unit_id || 7,
                time_unit: null,
                time_unit_id: null,
                phase_id: newdata[scopeIndex].phase_id,
                sow_id: newdata[scopeIndex].sow_id,
                type_id: newdata[scopeIndex].labor[Index].labor_id,
                no_productivity: 1,
                productivity_history: productivity_history,
              },
            ];
            setLaborProductivityForCalculator(laborProductivityArray);
            // setLaborProductivityForCalculator([]);
            editLabor(scopeIndex, Index);
          } else {
            let productivity = response.data.response.productivity;
            let productivity_unit = `${response.data.response.unit_area_abbreviation} / ${response.data.response.unit_time_abbreviation === 'h' ? 'hr' : 'day'}`;
            let area_unit = response.data.response.unit_area_abbreviation;
            let area_unit_id = response.data.response.unit_area_id;
            let time_unit = response.data.response.unit_time_abbreviation;
            let time_unit_id = response.data.response.unit_time_id;
            let duration = response.data.response.duration;
            let area = response.data.response.unit_area_value;
            let workers = response.data.response.worker_count;
            let worker_id = response.data.response.worker_id;
            let productivity_history = response.data.response.history;
            console.log('history', productivity_history);
            let laborProductivityArray = [
              {
                productivity: productivity,
                productivity_unit: productivity_unit,
                area_unit: area_unit,
                area_unit_id: area_unit_id,
                time_unit: time_unit,
                time_unit_id: time_unit_id,
                phase_id: newdata[scopeIndex].phase_id,
                sow_id: newdata[scopeIndex].sow_id,
                type_id: newdata[scopeIndex].labor[Index].labor_id,
                duration: duration,
                area: area,
                workers: workers,
                worker_id: worker_id,
                from_backup: 1,
                productivity_history: productivity_history,
              },
            ];
            setLaborProductivityForCalculator(laborProductivityArray);
            editLabor(scopeIndex, Index);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error('Error making request:', error);
        });
    } else if (title === 'Equipment') {
      const requestData = {
        type: 2,
        type_id: newdata[scopeIndex].equipment[Index].equipment_category_id,
        phase_id: newdata[scopeIndex].phase_id,
        sow_id: newdata[scopeIndex].sow_id,
        project_id: projectID,
        unit_area_id: newdata[scopeIndex].unit_id,
      };

      axios
        .post(IP + 'calculator/detail', requestData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.response == 'No data') {
            let area_value = newdata[scopeIndex].measurement;
            let area_unit_id = newdata[scopeIndex].unit_id < 0 ? 7 : newdata[scopeIndex].unit_id;
            let productivity_history = response.data.history;
            console.log('No Productivity Data');
            let equipmentProductivityArray = [
              {
                productivity: productivity_history?.length > 0 ? productivity_history[0] : null,
                productivity_unit: null,
                area_value: area_value,
                area_unit: null,
                area_unit_id: area_unit_id || 7,
                time_unit: null,
                time_unit_id: null,
                phase_id: newdata[scopeIndex].phase_id,
                sow_id: newdata[scopeIndex].sow_id,
                type_id: newdata[scopeIndex].equipment[Index].equipment_category_id,
                no_productivity: 1,
                productivity_history: productivity_history,
              },
            ];
            setEquipmentProductivityForCalculator(equipmentProductivityArray);
            editEquipment(scopeIndex, Index);
          } else {
            let productivity = response.data.response.productivity;
            let productivity_unit = `${response.data.response.unit_area_abbreviation} / ${response.data.response.unit_time_abbreviation === 'h' ? 'hr' : 'day'}`;
            let area_unit = response.data.response.unit_area_abbreviation;
            let area_unit_id = response.data.response.unit_area_id;
            let time_unit = response.data.response.unit_time_abbreviation;
            let time_unit_id = response.data.response.unit_time_id;
            let duration = response.data.response.duration;
            let area = response.data.response.unit_area_value;
            let equipments = response.data.response.equpment_count;
            let equipment_id = response.data.response.equipment_id;
            let productivity_history = response.data.response.history;
            console.log('history', productivity_history);
            let equipmentProductivityArray = [
              {
                productivity: productivity,
                productivity_unit: productivity_unit,
                area_unit: area_unit,
                area_unit_id: area_unit_id,
                time_unit: time_unit,
                time_unit_id: time_unit_id,
                phase_id: newdata[scopeIndex].phase_id,
                sow_id: newdata[scopeIndex].sow_id,
                type_id: newdata[scopeIndex].equipment[Index].equipment_category_id,
                duration: duration,
                area: area,
                equipments: equipments,
                equipment_id: equipment_id,
                from_backup: 1,
                productivity_history: productivity_history,
              },
            ];
            setEquipmentProductivityForCalculator(equipmentProductivityArray);
            editEquipment(scopeIndex, Index);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error('Error making request:', error);
        });
    } else if (title === 'Material') {
      const requestData = {
        type: 3,
        phase_id: newdata[scopeIndex].phase_id,
        sow_id: newdata[scopeIndex].sow_id,
        project_id: projectID,
        type_id: newdata[scopeIndex].material[Index].material_id,
      };

      axios
        .post(IP + 'calculator/detail', requestData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.response == 'No data') {
            console.log('No Productivity Data');
            editMaterial(scopeIndex, Index);
          } else {
            let data = [response.data.data];
            let input = [response.data.input];
            let materialArray = [{ data: data, input: input, type_id: newdata[scopeIndex].material[Index].material_id, from_backup: 1, phase_id: newdata[scopeIndex].phase_id, sow_id: newdata[scopeIndex].sow_id }];
            setMaterialQuantityForCalculator(materialArray);
            editMaterial(scopeIndex, Index);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error('Error making request:', error);
        });
    }
  };

  return (
    <CTooltip content="Backup calculation was used in this item." placement="right" style={customTooltipStyle}>
      <CButton
        onClick={() => {
          getHistory({ title });
          toggleCalculator();
        }}
        style={{ width: '50px', height: '50px', padding: 0 }}
      >
        <img src={calculator} style={{ width: '100%', height: '100%' }} />
      </CButton>
    </CTooltip>
  );
};

export default BackupButton;
