import React, { useState, useEffect } from "react";

import "react-circular-progressbar/dist/styles.css";
import "./ProjectDetails.css";
import Loading from "../aimhi-widgets/Loading";
import NumberFormat from "react-number-format";
import $axios from 'src/utils/axios';

import IP from "../../StoreGlobal/IP";

const data = {
  "ceiling work": {
    material_estimated: 1200000,
    material_actual: 60000,
    material_percentage: 50,
    labor_estimated: 6000,
    labor_actual: 5000,
    labor_percentage: 90,
    equipment_estimated: 10000,
    equipment_actual: 5000,
    equipment_percentage: 50,
    estimated_cost: 0,
    actual_cost: 0,
    current_profit: 0,
  },

  total: {
    material_estimated: 200000,
    material_actual: 0,
    material_percentage: 0,
    labor_estimated: 0,
    labor_actual: 0,
    labor_percentage: 0,
    equipment_estimated: 0,
    equipment_actual: 0,
    equipment_percentage: 0,
    estimated_cost: 0,
    actual_cost: 0,
    current_profit: 0,
  },
};

const ProjectBudgetCost = (props) => {
  const company_id = parseInt(localStorage.getItem("company_id"));
  let accessToken = localStorage.getItem("token");
  let user_id = parseInt(localStorage.getItem("user_id"));

  const [estimateData, setestimateData] = useState([]);
  const [estimate, setestimate] = useState({});
  const [errorTrapping, seterrorTrapping] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getestimate(props.project_id);
  }, []);

  const getestimate = (project_id) => {
    let company_id = parseInt(localStorage.getItem("company_id"));
    $axios.get( `estimate/${company_id}/${project_id}`).then((res) => {
        setestimate(res.data.response.summary);

        let arr = Object.keys(res.data.response.summary);

        let newarr = arr.filter((e) => e != "total");

        setestimateData(newarr);
        seterrorTrapping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          "projectdetails/ProjectBudgetCost.js",
          " ",
          "line 115",
          error
        );
      });
  };

  const tbody = estimateData.map((item, Index) => {
    return (
      <tbody key={Index}>
        <tr>
          <td className="sticky-col first-col">{item}</td>
          <th className="sticky-col third-last-col">
            Php
            <NumberFormat
              value={estimate[item]["estimated_cost"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </th>
          <th className="sticky-col second-last-col">
            Php
            <NumberFormat
              value={estimate[item]["actual_cost"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </th>
          <th className="sticky-col last-col">
            Php
            <NumberFormat
              value={estimate[item]["profit"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </th>
          <td>
            Php
            <NumberFormat
              value={estimate[item]["material_estimated"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>
            Php
            <NumberFormat
              value={estimate[item]["material_actual"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>
            <NumberFormat
              value={estimate[item]["material_percentage"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginRight: "5px" }}
            />
            %
          </td>
          <td>
            Php
            <NumberFormat
              value={estimate[item]["labor_estimated"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>
            Php
            <NumberFormat
              value={estimate[item]["labor_actual"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>
            <NumberFormat
              value={estimate[item]["labor_percentage"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginRight: "5px" }}
            />
            %
          </td>
          <td>
            Php
            <NumberFormat
              value={estimate[item]["equipment_estimated"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>
            Php
            <NumberFormat
              value={estimate[item]["equipment_actual"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>
            <NumberFormat
              value={estimate[item]["equipment_percentage"]}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginRight: "5px" }}
            />
            %
          </td>
        </tr>
      </tbody>
    );
  });

  return (
    <>
      {loading ? <Loading /> : ""}
      <div className="view">
        <div className="wrapper">
          <table
            className="table table-sm"
            style={{ textAlign: "left", fontSize: "14px" }}
          >
            <thead className="sticky">
              <tr>
                <th className="sticky-col first-col">Description</th>
                <th className="sticky-col third-last-col">Estimated Cost</th>
                <th className="sticky-col second-last-col">Actual Cost</th>
                <th className="sticky-col last-col">
                  Current <br />
                  (Profit)
                </th>
                <th className="td_width">
                  Material <br /> (Estimated)
                </th>
                <th className="td_width">
                  Material <br /> (Actual)
                </th>
                <th className="percentage_width">%</th>
                <th className="td_width">
                  Labor
                  <br />
                  (Estimated)
                </th>
                <th className="td_width">
                  Labor
                  <br />
                  (Actual)
                </th>
                <th className="percentage_width">%</th>

                <th className="td_width">
                  Equipment
                  <br />
                  (Estimated)
                </th>
                <th className="td_width">
                  Equipment
                  <br />
                  (Actual)
                </th>
                <th className="percentage_width">%</th>
              </tr>
            </thead>
            {tbody}
            {errorTrapping == false && (
              <tfoot style={{ fontWeight: "bold" }}>
                <tr>
                  <td className="sticky-col first-col">TOTAL</td>
                  <th className="sticky-col third-last-col">
                    Php
                    <NumberFormat
                      value={estimate["total"]["estimated_cost"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </th>
                  <th className="sticky-col second-last-col">
                    Php
                    <NumberFormat
                      value={estimate["total"]["actual_cost"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </th>
                  <th className="sticky-col last-col">
                    Php
                    <NumberFormat
                      value={estimate["total"]["profit"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </th>
                  <td>
                    Php
                    <NumberFormat
                      value={estimate["total"]["material_estimated"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </td>
                  <td>
                    Php
                    <NumberFormat
                      value={estimate["total"]["material_actual"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={estimate["total"]["material_percentage"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginRight: "5px" }}
                    />
                    %
                  </td>
                  <td>
                    Php
                    <NumberFormat
                      value={estimate["total"]["labor_estimated"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </td>
                  <td>
                    Php
                    <NumberFormat
                      value={estimate["total"]["labor_actual"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={estimate["total"]["labor_percentage"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginRight: "5px" }}
                    />
                    %
                  </td>
                  <td>
                    Php
                    <NumberFormat
                      value={estimate["total"]["equipment_estimated"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </td>
                  <td>
                    Php
                    <NumberFormat
                      value={estimate["total"]["equipment_actual"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginLeft: "5px" }}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={estimate["total"]["equipment_percentage"]}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      style={{ marginRight: "5px" }}
                    />
                    %
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default ProjectBudgetCost;
