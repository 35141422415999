import React, { useEffect, useState } from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter, CButton, CFormGroup, CInput, CLabel, CCol, CForm, CSwitch } from '@coreui/react';
import { GetReorderStatus, UpdateReorderStatus } from 'src/request/OnsiteRecords';
const ReorderPoint = ({ isOpen, onClose, projectId }) => {
  const Swal = require('sweetalert2');
  const [form, setForm] = useState({ project_id: '', value: '' });
  const [validated, setValidated] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formElement = event.currentTarget;
    if (formElement.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const payload = { reorder_point: form.value };
      updateReorderStatus(payload).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Reorder point has been set successfully!',
          confirmButtonColor: '#23303D',
        });
        onClose();
      });
    }
  };

  const handleInputChange = (e) => {
    if (!isEnabled) {
      setIsEnabled(true);
    }
    setForm({ ...form, [e.target.name]: parseInt(e.target.value) });
  };

  useEffect(() => {
    setForm({ project_id: projectId });
    const fetchReorderStatus = async () => {
      await GetReorderStatus(projectId)
        .then((response) => {
          if (response.status === 200) {
            console.log('response', response);
            if (response.data.response.has_reorder_point) {
              setIsEnabled(true);
            }
            setForm({ value: response.data.response.reorder_point_percentage });
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    fetchReorderStatus();
  }, [projectId]);

  const handleSwitchChange = async (e) => {
    const value = e.target.checked;
    setIsEnabled(value);
    if (value === false) {
      setForm({ ...form, value: '' });
      updateReorderStatus(null);
    }
  };

  const updateReorderStatus = async (material_properties) => {
    await UpdateReorderStatus({ project_id: projectId, material_properties: material_properties });
  };

  return (
    <CCol>
      <CModal size="md" show={isOpen} onClose={onClose} centered>
        {/* <CModalHeader>
          {' '}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CSwitch size="sm" variant="3d" color="aimhi-textlight-neutral" checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />
            <span style={{ fontSize: '150%', marginLeft: '10px' }}>Reorder Point</span>
          </div>
        </CModalHeader> */}
        <CForm noValidate onSubmit={handleSubmit}>
          <CModalBody>
            <div style={{ display: 'flex', alignItems: 'center', padding: '5%' }}>
              <CSwitch size="sm" variant="3d" color="aimhi-textlight-neutral" checked={isEnabled} onChange={handleSwitchChange} />
              <span style={{ fontSize: '150%', marginLeft: '10px' }}>Reorder Point</span>
            </div>
            <p style={{ textAlign: 'justify', color: 'gray', fontStyle: 'italic', fontSize: '12px', padding: '2% 15% 0% 10%' }}>
              Set a Reorder Point to manage your materials. This threshold triggers notifications when a material's stock reaches it. Use the adjacent switch to enable or disable this feature. Note: This setting applies to all materials.
            </p>
            <CFormGroup style={{ padding: '5% 15% ' }}>
              <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                {/* <input type="number" onWheel={(e) => e.target.blur()}></input> */}
                <span style={{ marginRight: '10px' }}>Value</span>
                <CInput value={form.value} name="value" type="number" onChange={handleInputChange} onWheel={(e) => e.target.blur()}></CInput>
                <span style={{ fontSize: '150%', marginLeft: '10px', fontWeight: 'bold' }}> %</span>
              </div>
            </CFormGroup>
          </CModalBody>
          <CModalFooter>
            <CButton color="aimhi-textlight-neutral" type="submit">
              Save
            </CButton>
            <CButton color="aimhi-textlight-neutral" variant="outline" onClick={onClose}>
              Cancel
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </CCol>
  );
};

export default ReorderPoint;
