import React, { useState } from 'react';
import { CCol, CRow, CTabs, CNav, CNavItem, CNavLink, CBadge } from '@coreui/react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import './ProjectDetails.css';
import Loading from '../aimhi-widgets/Loading';

import 'react-datepicker/dist/react-datepicker.css';

import DailyReports from './DailyReports';
import Swa from './Swa';
import SiteVisit from './SiteVisit';
import Weekly from './Weekly';
import EquipmentUsage from './EquipmentUsage';
import TwoWeeks from './TwoWeeks';
import HistoricalReports from './HistoricalReports';

const Report = (props) => {
  console.log(props.id);
  const [active, setActive] = useState(props.activeTab);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? <Loading /> : ''}
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="10" style={{ marginTop: '20px' }}>
          <CTabs>
            <CNav variant="tabs" style={{ borderWidth: '5px' }}>
              <CNavItem>
                <CNavLink className={active == 1 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(1)}>
                  Daily
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink className={active == 2 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(2)}>
                  Current Week
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink className={active == 7 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(7)} style={{ position: 'relative' }}>
                  Historical Reports
                  {props.historicalReportCount > 0 && (
                    <CBadge
                      color="aimhi-danger"
                      style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-2px',
                        padding: '0.4em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        width: '20px', // Set a fixed width
                        height: '20px',
                      }}
                    >
                      {props.historicalReportCount}
                    </CBadge>
                  )}
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink className={active == 3 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(3)}>
                  Two Weeks Ahead
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink className={active == 4 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(4)}>
                  SWA
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink className={active == 5 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(5)}>
                  Site Visit
                </CNavLink>
              </CNavItem>
              {/* <CNavItem>
                <CNavLink className={active == 6 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(6)}>
                  Equipment Usage
                </CNavLink>
              </CNavItem> */}
            </CNav>
            {active == 1 && <DailyReports id={props.id} />}
            {/* {active == 2 && <Weekly id={props.id} />} */}
            {active == 2 && <Weekly id={props.id} type={props.type} />}
            {active == 3 && <TwoWeeks id={props.id} />}
            {active == 4 && <Swa id={props.id} />}
            {active == 5 && <SiteVisit id={props.id} />}
            {/* {active == 6 && <EquipmentUsage id={props.id} />} */}
            {active == 7 && <HistoricalReports id={props.id} getTabWarnings={props.getTabWarnings} />}
          </CTabs>
        </CCol>
      </CRow>
    </>
  );
};

export default Report;
