import React, { useState, useEffect, useMemo, useRef } from 'react';
import { CButton, CCol, CRow, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider } from '@coreui/react';
import Select from 'react-select';
import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import ThingsToConsider from '../aimhi-widgets/ThingToConsider';
import { useSelector } from 'react-redux';
import { FaRecycle } from 'react-icons/fa';
import { ProjectList } from 'src/request/Projects';
import { TransferManpower } from 'src/request/Manpower';
import { SaveManpower, ProjectManpowerPerPosition, ProjectPosition, ExistingRecords } from 'src/request/Teams';
const Swal = require('sweetalert2');
const Team = (props) => {
  let projectID = props.id;
  const [dropDownOption, setDropDownOption] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [existingRecordsData, setExistingRecordsData] = useState([]);
  let permission = useSelector((state) => state.permission);
  useEffect(() => {
    getProjectList();
    fetchPosition(projectID);
    fetchExistingRecords(projectID);
  }, []);

  const getProjectList = () => {
    ProjectList({}, true).then((res) => {
      if (res.data.status === 1) {
        setProjectList(res.data.response);
      }
    });
  };

  const fetchPosition = (project_id) => {
    ProjectPosition(project_id).then((res) => {
      if (res.data.status === 200) {
        setPositionList(res.data.data);
      }
    });
  };

  const fetchExistingRecords = (project_id) => {
    ExistingRecords(project_id)
      .then((response) => {
        if (response && response?.data?.data) {
          setExistingRecordsData(response.data.data);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error || 'An error has occured',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const fetchDropDown = (position_id) => {
    ProjectManpowerPerPosition(position_id).then((res) => {
      if (res.data.status === 200) {
        const transformData = res?.data?.data.map((option) => ({
          value: option.manpower_id,
          label: option.manpower_name,
          labor_id: option.labor_id,
        }));
        setDropDownOption(transformData);
      }
    });
  };

  const addMore = (item) => {
    const index = existingRecordsData.findIndex((record) => record.pos_id === item.position_id);
    const newRecord = {
      manpower_id: null,
      manpower_name: '',
      mobile: '',
      position: '',
      position_id: null,
      project_designation_id: null,
    };
    setExistingRecordsData((prevData) => {
      const updatedRecords = [...prevData];
      const updatedRecord = { ...updatedRecords[index] };
      updatedRecord.manpower = [...updatedRecord.manpower, newRecord];
      updatedRecords[index] = updatedRecord;
      return updatedRecords;
    });
  };
  const transferLabor = (targetProjectId, positionIndex, manpowerIndex) => {
    const updatedList = [...existingRecordsData];
    const selectedEmployee = updatedList[positionIndex]?.manpower?.[manpowerIndex];
    if (!selectedEmployee) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "You can't transfer because no employee is selected",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    const transferPayload = {
      manpower_id: selectedEmployee.manpower_id,
      project_id: targetProjectId,
    };

    TransferManpower(transferPayload)
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Transfer successful',
            showConfirmButton: false,
          });
          fetchExistingRecords(projectID);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'info',
          title: 'Notice:',
          text: error.response?.data?.response || 'An error occurred',
          showConfirmButton: false,
        });
      });
  };
  const { handleSavingName } = useSavingManpower( existingRecordsData, projectID, fetchExistingRecords);
  const { renderTbody } = useTeamManagement({ projectList, permission, handleSavingName, addMore, transferLabor, positionList, existingRecordsData, setExistingRecordsData, dropDownOption, fetchDropDown, setDropDownOption });
  return (
    <>
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="9" style={{ marginTop: '20px' }}>
          <h2>Employee List</h2>
          <table className="table ">
            <thead style={{ borderBottom: '10px' }}>
              <tr>
                <th>Position</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Transfer</th>
              </tr>
            </thead>
            <tbody>{renderTbody}</tbody>
          </table>
        </CCol>
        <CCol xs="12" sm="12" md="12" lg="12" xl="3">
          <ThingsToConsider />
        </CCol>
      </CRow>
    </>
  );
};

const useSavingManpower = (existingRecordsData, projectID, fetchExistingRecords) => {
  const handleSavingName = (e, index, dIndex, pos_id) => {
    const oldData = [...existingRecordsData];
    const old_manpower = oldData[index]?.manpower[dIndex];
    if (e === '' || e === null) {
      handleEmptyValue(old_manpower, pos_id);
    } else {
      handleNonEmptyValue(e, old_manpower.manpower_id, projectID);
    }
  };

  const handleEmptyValue = (old_manpower, pos_id) => {
    const data = {
      project_id: projectID,
      old_manpower_id: null,
      labor_id: pos_id,
      manpower_id: old_manpower.manpower_id,
      action: 'remove',
    };
    SaveManpower(data)
      .then((res) => {
        if (res.data.status === 200) {
          fetchExistingRecords(projectID);
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const handleNonEmptyValue = (e, old_manpower_id, projectID) => {
    const data = {
      project_id: projectID,
      old_manpower_id: old_manpower_id,
      labor_id: e?.labor_id,
      manpower_id: e?.value,
      action: 'save',
    };

    SaveManpower(data)
      .then((res) => {
        if (res.data.status === 200) {
          fetchExistingRecords(projectID);
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const showErrorMessage = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.message || 'An error occurred!',
      confirmButtonColor: '#23303D',
    });
  };
  return {
    handleSavingName,
  };
};
const useTeamManagement = (props) => {
  const { projectList, permission, handleSavingName, addMore, transferLabor, positionList, existingRecordsData, setExistingRecordsData, dropDownOption, fetchDropDown, setDropDownOption } = props;

  const renderTransferDropdown = (projects, rowIndex) => {
    const records = existingRecordsData[rowIndex];

    if (!records || !records.manpower || records.manpower.length === 0) {
      return null;
    }

    return records.manpower
      .filter((item) => item.manpower_name !== '' && item.manpower_id !== null)
      .map((record, manpowerIndex) => (
        <CDropdown size="sm" key={`${record.manpower_id}-${manpowerIndex}`}>
          <CDropdownToggle className="caret">
            <FaRecycle style={{ width: '25px', height: '25px' }} />
          </CDropdownToggle>
          <CDropdownMenu style={{ height: '300px', overflow: 'scroll' }}>
            <CDropdownItem header>Action</CDropdownItem>
            <CDropdownDivider />
            {projects.map((project) => (
              <CDropdownItem style={{ width: '250px', whiteSpace: 'normal' }} key={project.id} onClick={() => transferLabor(project.id, rowIndex, manpowerIndex)}>
                <span>{project.name}</span>
              </CDropdownItem>
            ))}
          </CDropdownMenu>
        </CDropdown>
      ));
  };

  const renderExistingRecords = (item) => {
    const records = existingRecordsData.find((record) => record.pos_id === item.position_id);

    const index = existingRecordsData.findIndex((record) => record.pos_id === item.position_id);
    if (!records) {
      return null;
    }
    return records?.manpower?.map((record, recordIndex) => (
      <Select
        key={`dropdown-${index}-${recordIndex}`}
        styles={{ marginTop: '10px' }}
        value={record.manpower_id && record.manpower_name ? { value: record.manpower_id, label: record.manpower_name } : null}
        onMenuOpen={() => {
          fetchDropDown(item.position_id);
        }}
        className="m-1"
        onBlur={() => setDropDownOption([])}
        onChange={(e) => {
          handleSavingName(e, index, recordIndex, item.position_id);
        }}
        options={dropDownOption}
        isDisabled={permission['update'][0] !== 1}
        placeholder="Select"
        isClearable={!!record.manpower_id}
      />
    ));
  };

  const renderMobileNumbers = (item) => {
    const records = existingRecordsData.find((record) => record.pos_id === item.position_id);
    if (!records || !records.manpower || records.manpower.length === 0) {
      return (
        <div key="empty-mobile" className="margin_table_top">
          <span>{'\u00A0'}</span>
        </div>
      );
    }
    return records.manpower.map((record, recordIndex) => (
      <div key={`mobile-${recordIndex}`} className={recordIndex === 0 ? 'margin_top_ss' : 'margin_table_top'} style={{ marginTop: '16px' }}>
        <span>{record.mobile || '\u00A0'}</span>
      </div>
    ));
  };

  const renderTbody = () => {
    return positionList.map((item, index) => {
      const existingRecords = renderExistingRecords(item, index);
      const existingMobile = renderMobileNumbers(item);
      const transferDropdown = renderTransferDropdown(projectList, index);
      return (
        <>
          <tr key={index} className="align-top">
            <td className="w-25 px-3 text-start align-middle">{item.position}</td>
            <td className="w-40 px-3">
              <div className="row gy-2">
                {' '}
                <div className="col-12">{existingRecords}</div>
                <div className="col-12 d-flex align-items-center text-center justify-content-center">
                  <CButton className="p-0 pl-2 " onClick={() => addMore(item, index)} disabled={permission['update'][0] !== 1}>
                    <u> Add more</u>
                  </CButton>
                </div>
              </div>
            </td>
            <td className="w-20 px-3">{existingMobile}</td>
            <td className="w-15 px-3">{transferDropdown}</td>
          </tr>
        </>
      );
    });
  };
  const memoizedTbody = useMemo(() => renderTbody(), [positionList, existingRecordsData, dropDownOption]);

  return {
    renderTbody: memoizedTbody,
  };
};

export default Team;
