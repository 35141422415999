import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";

import NumberFormat from "react-number-format";
import "jspdf-autotable";

const PrintEstimate = (props) => {
  console.log(props.value);
  const [printData, setprintData] = useState([]);

  useEffect(() => {
    console.log(props);
    setprintData(props.value);
  }, []);

  const tbody = printData.map((scope, Index) => {
    let Total_Labor = 0;
    let Total_Equipment = 0;
    let Total_Material = 0;
    const Labor = scope.labor.map((labor, Index) => {
      Total_Labor += labor.estimate;
      return (
        <tr key={Index} className="text-center" style={{ fontSize: "14px" }}>
          <td>{labor.designation}</td>
          <td>{labor.estimated_workers}</td>
          <td>{labor.duration}</td>

          <td className="text-left">
            <span>
              Php
              <NumberFormat
                value={labor.rate}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                style={{ marginLeft: "5px" }}
              />
            </span>
          </td>
          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={labor.estimated_amount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>{labor.hired_workers}</td>
          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={labor.cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>
            {" "}
            Php
            <NumberFormat
              value={labor.balance}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>
      );
    });
    const Equipment = scope.equipment.map((equipment, Index) => {
      Total_Equipment += equipment.estimate;

      let rowClass = "text-center";

      if (equipment.remove == true) {
        rowClass = "text-center disable-table-column";
      }

      return (
        <tr key={Index} className={rowClass} style={{ fontSize: "14px" }}>
          <td>{equipment.description}</td>
          <td>{equipment.quantity}</td>
          <td>{equipment.duration}</td>

          <td style={{ textAlign: "right" }}>
            <span>
              Php
              <NumberFormat
                value={equipment.rate}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                style={{ marginLeft: "5px" }}
              />
            </span>
          </td>
          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={equipment.estimated_amount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td>{equipment.ordered_quantity}</td>
          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={equipment.cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={equipment.balance}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>
      );
    });
    const Material = scope.material.map((material, Index) => {
      Total_Material += material.estimate;
      let rowClass = "text-center";
      if (material.remove == true) {
        rowClass = "text-center disable-table-column";
      }
      return (
        <tr key={Index} className={rowClass} style={{ fontSize: "14px" }}>
          <td>{material.description}</td>
          <td>{material.quantity}</td>
          <td>{material.unit}</td>
          <td style={{ textAlign: "right" }}>
            <span>
              Php
              <NumberFormat
                value={material.unit_cost}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                style={{ marginLeft: "5px" }}
              />
            </span>
          </td>

          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={material.estimated_amount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>

          <td>{material.ordered_quantity}</td>

          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={material.cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={material.balance}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>
      );
    });

    return (
      <tbody key={Index} lg="12">
        <tr className="table-borderless" style={{ marginTop: "20px" }}>
          <td colSpan="5">
            <span
              style={{
                fontWeight: "700",
                fontSize: "16px",
              }}
            >
              {scope[0]}
            </span>
          </td>
        </tr>

        <tr
          className="text-center"
          style={{
            background: "#F4F4F7",
            fontWeight: "600",
            fontSize: "14px",
            border: "none",
            marginTop: "8px",
          }}
        >
          <td style={{ width: "400px" }}>Designation</td>
          <td>
            Estimated <br></br>Workers
          </td>

          <td>Duration</td>
          <td>Rate</td>
          <td>
            Estimated <br></br>Cost
          </td>
          <td>
            Hired <br></br>Workers
          </td>
          <td>
            Actual <br></br>Cost
          </td>
          <td>Balance</td>
        </tr>
        <tr
          style={{
            // background: "#F4F4F7",
            fontWeight: "600",
            fontSize: "16px",
          }}
          // onClick={() => toggleShow(scope[0] + "Labor" + Index)}
        >
          <td colSpan="4">Labor</td>
          <td colSpan="1" style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={scope.total_actual_labor_cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td colSpan="3" style={{ textAlign: "right" }}></td>
        </tr>
        {Labor}

        <tr
          className="text-center"
          style={{
            background: "#F4F4F7",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          <td>Description</td>
          <td>
            Estimated <br></br>Quantity
          </td>

          <td>Duration</td>
          <td>Rate</td>
          <td>
            Estimated <br></br>Cost
          </td>
          <td>
            Ordered <br></br> Quantity
          </td>
          <td>
            Actual <br></br>Cost
          </td>
          <td>Balance</td>
        </tr>
        <tr
          style={{
            // background: "#F4F4F7",
            fontWeight: "600",
            fontSize: "16px",
          }}
          // onClick={() => toggleShow(scope[0] + "Equipment" + Index)}
        >
          <td colSpan="4">Equipment</td>
          <td colSpan="1" style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={scope.total_actual_equipment_cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td colSpan="3" style={{ textAlign: "right" }}></td>
        </tr>
        {Equipment}

        <tr
          className="text-center"
          style={{
            background: "#F4F4F7",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          <td>Description</td>
          <td>
            Estimated <br></br>Quantity
          </td>

          <td>Unit</td>
          <td>Unit Cost</td>
          <td>
            Estimated <br></br> Cost
          </td>
          <td>
            Ordered <br></br> Quantity
          </td>

          <td>
            Actual <br></br>Cost
          </td>
          <td>Balance</td>
        </tr>
        <tr
          style={{
            // background: "#F4F4F7",
            fontWeight: "600",
            fontSize: "16px",
          }}
          // onClick={() => toggleShow(scope[0] + "Material" + Index)}
        >
          <td colSpan="4">Material</td>
          <td colSpan="1" style={{ textAlign: "right" }}>
            Php
            <NumberFormat
              value={scope.total_actual_material_cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td colSpan="3" style={{ textAlign: "right" }}></td>
        </tr>
        {Material}

        <tr style={{ fontSize: "14px" }}>
          <td colSpan="3">Direct Total Cost</td>
          <td colSpan="1">A + B + C</td>
          <td colSpan="1" className="text-right">
            Php
            <NumberFormat
              value={scope.direct_total_cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
          <td colSpan="1"></td>
          <td colSpan="1"></td>
          <td colSpan="1"></td>
        </tr>

        <tr className="table-borderless" style={{ fontSize: "14px" }}>
          <td colSpan="3">Direct Unit Cost</td>
          <td colSpan="1">D / Quantity</td>
          <td colSpan="1" className="text-right">
            Php
            <NumberFormat
              value={scope.direct_unit_cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>

        <tr className="table-borderless" style={{ fontSize: "14px" }}>
          <td colSpan="3">Overhead, contingencies, and miscellaneous (OCM)</td>
          <td colSpan="1">15.00% of D</td>
          <td colSpan="1" className="text-right">
            Php
            <NumberFormat
              value={scope.ocm}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>

        <tr className="table-borderless" style={{ fontSize: "14px" }}>
          <td colSpan="3">Contractor’s Profit (CP)</td>
          <td colSpan="1">10.00% of D</td>
          <td colSpan="1" className="text-right">
            Php
            <NumberFormat
              value={scope.profit}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>

        <tr className="table-borderless" style={{ fontSize: "14px" }}>
          <td colSpan="3">Value Added Tax (VAT)</td>
          <td colSpan="1">5% of (D + F + G)</td>
          <td colSpan="1" className="text-right">
            Php
            <NumberFormat
              value={scope.vat}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>
        <tr className="table-borderless" style={{ fontSize: "14px" }}>
          <td colSpan="3">Total Cost</td>
          <td colSpan="1">D + F + G + H </td>
          <td colSpan="1" className="text-right">
            Php
            <NumberFormat
              value={scope.total_cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>
        <tr className="table-borderless" style={{ fontSize: "14px" }}>
          <td colSpan="3">Total Unit Cost</td>
          <td colSpan="1">I / Quantity</td>
          <td colSpan="1" className="text-right">
            Php
            <NumberFormat
              value={scope.total_unit_cost}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{ marginLeft: "5px" }}
            />
          </td>
        </tr>

        <tr className="table-borderless">
          <td> </td>
        </tr>
        <tr className="table-borderless">
          <td> </td>
        </tr>
        <tr>
          <td
            colSpan="6"
            style={{
              borderBottom: "5px solid #549764",
              borderTop: "1px solid white",
            }}
          ></td>
        </tr>
      </tbody>
    );
  });

  return <>{tbody}</>;
};

export default PrintEstimate;
