import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const getUpdates = (projectId, page, limit) => {
  let companyId = parseInt(localStorage.getItem('company_id'));
  let user_id = parseInt(localStorage.getItem('user_id'));

  return $axios.get(`project/updates/${companyId}/${projectId}/${user_id}?page=${page}&limit=${limit}`);
};

export const postUpdates = (data) => {
  return $axios.post('project/updates', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const postReply = (data) => {
  return $axios.post('project/updates/reply', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const posLike = (data) => {
  console.log(data, 'data');
  return $axios.post('project/updates/like', {
    ...data,
  });
};

export const deletePost = (post_id, category) => {
  return $axios.delete(`project/updates/${category}/${post_id}`);
};

export const getUsers = () => {
  let companyId = parseInt(localStorage.getItem('company_id'));
  let user_id = parseInt(localStorage.getItem('user_id'));

  return $axios.get(`mention/list/${companyId}/${user_id}?type=person`);
};

export const Update = (data, category, id) => {
  return $axios.put(`project/updates/${category}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
