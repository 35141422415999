import $axios from 'src/utils/axios';

// labor onsite
export const GetProjects = () => {
  return $axios.get('onsite/project');
};

export const GetSows = (project_id) => {
  return $axios.get('onsite/scope-of-work', { params: { project_id: project_id, type: 1 } });
};

export const GetWorkItems = (project_id) => {
  return $axios.get('onsite/work-item', { params: { project_id: project_id, type: 1 } });
};

export const GetDesignations = (project_id) => {
  return $axios.get('onsite/designation', { params: { project_id: project_id } });
};
export const GetManpowerPosition = ({page = 1, page_size = 10, searchTerm = '' }) => {
  return $axios.get(`manpower/position/new?page=${page}&page_size=${page_size}&search=${searchTerm}`);
};

export const GetNames = (project_id, designation_id, worker = null) => {
  return $axios.get('onsite/name', { params: { project_id: project_id, labor_id: designation_id, search: worker } });
};

export const GetLaborOnsiteData = (page = 1, page_size = 10, searchTerm = '') => {
  return $axios.get(`onsite/labor/new?page=${page}&page_size=${page_size}&search=${searchTerm}`);
};

export const SaveLaborOnsiteData = (data) => {
  return $axios.post('onsite/labor/new/save', data);
};

export const UpdateLaborOnsiteData = (data) => {
  return $axios.put('onsite/labor/update/new', data);
};
export const GetManpowerNames = ({page = 1, pageSize = 10, searchTerm = '',isTimeOut, isTimeIn, MeridiemAM, MeridiemPM, projectId, dateCreated, designation_id }) => {
  return $axios.get(`onsite/labor/list/new?page=${page}&page_size=${pageSize}&search=${searchTerm}&is_time_out=${isTimeOut}&is_time_in=${isTimeIn}&merediem_am=${MeridiemAM}&merediem_pm=${MeridiemPM}&project_id=${projectId}&date_created=${dateCreated}&designation_id=${designation_id}`);
};

//material inbound
export const GetProjectsMaterialInbound = (filter = null) => {
  return $axios.get('material/inbound/get-project-list', { params: { filter: filter } });
};

export const GetPurchaseRequestsMaterialInbound = (project_id, filter = null) => {
  return $axios.get('material/inbound/get-pr-list', { params: { project: project_id, filter: filter } });
};

export const GetPRDetailsMaterialInbound = (project_id, pr_id) => {
  return $axios.get('material/inbound/get-pr-details', { params: { project: project_id, pr: pr_id } });
};

export const GetMaterialInboundListData = () => {
  return $axios.get('material/inbound/get-inbound-list');
};

export const SaveMaterialInboundData = (data) => {
  return $axios.post('material/inbound/add-inbound-material', data);
};

export const GetMaterialInboundDetails = (pr_id) => {
  return $axios.get('material/inbound/get-inbound-list-details', { params: { pr: pr_id } });
};

export const UpdateMaterialInboundData = (data) => {
  return $axios.put('material/inbound/edit-inbound-material', data);
};

export const CheckUtilizedQuantity = (data) => {
  return $axios.post('material/check/utilized/qty', data)
}
//material utilization

export const GetProjectsMaterialUtilization = (filter = null) => {
  return $axios.get('material/utilization/get-project-list');
};

export const GetPhaseMaterialUtilization = (project_id) => {
  return $axios.get('material/utilization/get-phase-list', { params: { project: project_id } });
};

export const GetSowMaterialUtilization = (project_id, phase_id) => {
  return $axios.get('material/utilization/get-sow-list', { params: { project: project_id, phase: phase_id } });
};

export const GetMaterialOptions = (project_id, phase_id, sow_id) => {
  return $axios.get('material/utilization/get-material-list', { params: { project: project_id, phase: phase_id, sow: sow_id } });
};
export const GetMaterialUtilizationMaterialOptions = (project_id) => {
  return $axios.get('material/utilization/add/material-list', { params: { project_id: project_id } });
};

export const GetMaterialUtilizationListData = (project_id) => {
  return $axios.get('material/utilization/get-utilized-list', { params: { project: project_id ? project_id : null } });
};
export const SaveMaterialUtilizationData = (data) => {
  return $axios.post('material/utilization/add-utilized-materials', data);
};

export const GetUtilizedMaterialDetails = (release_id) => {
  return $axios.get('material/utilization/get-utilized-list-details', { params: { release: release_id } });
};

export const UpdateUtilizedMaterialData = (data) => {
  return $axios.put('material/utilization/edit-utilized-materials', data);
};

export const GetReorderStatus = (project_id) => {
  return $axios.get('material/utilization/get-reorder-point-status', { params: { project: project_id } });
};

export const UpdateReorderStatus = (data) => {
  return $axios.put('material/utilization/set-reorder-point', data);
};

export const GetMaterialUtilizationProjectData = (project_id, page, pageSize, search) => {
  return $axios.get('material/project-material-utilization', { params: { project_id: project_id, page: page, page_size: pageSize, search: search } });
};

export const ReverseUtilizedRecordData = (data) => {
  return $axios.post('material/utilization/reverse', data)
}

export const GetMaterialUtilizationProjectDataNew = (project_id, page, pageSize, search) => {
  return $axios.get('material/project-material-utilization/new', { params: { project_id: project_id, page: page, page_size: pageSize, search: search } });
};
//equipment activity report

export const GetEquipmentPurchaseRequestList = (project_id) => {
  return $axios.get('onsite/equipment/request', { params: { project_id: project_id } });
};

export const GetEquipmentList = (pr_num) => {
  return $axios.get('onsite/request/equipment', { params: { pr_num: pr_num } });
};

export const GetEquipmentDataList = (page, page_size) => {
  return $axios.get('onsite/equipment', { params: page || page_size ? { page: page, page_size: page_size } : {} });
};

export const SaveEquipmentActivityReport = (data) => {
  return $axios.post('onsite/equipment/save', data);
};

export const GetOperatorList = (project_id, category_id) => {
  return $axios.get('onsite/operator', { params: { project_id: project_id, category_id: category_id } });
};

export const GetEquipmentPrDetails = (request_id) => {
  return $axios.get('onsite/request/equipment/details', { params: { equipment_request_id: request_id } });
};

export const UpdateEquipmentActivityReport = (data) => {
  return $axios.put('onsite/equipment/update', data);
};

//stock balance

export const GetProjectStockBalance = () => {
  return $axios.get('project/user/project');
};

export const SaveTransferMaterial = (data) => {
  return $axios.post('material/transfer-material/create', data);
};

export const GetTransferMaterialList = (page = 1, page_size = 10, search = null, project_id = null, received = null, debt = null) => {
  return $axios.get('material/transfer-material/list', { params: { page, page_size, search, project_id, received, debt } });
};

export const ReceiveTransferredMaterial = (data) => {
  return $axios.post('material/transfer-material/approve', data);
};

export const ReturnTransferredMaterial = (data) => {
  return $axios.post('material/transfer-material/debt', data);
};

export const GetPsowId = (sender_project_id, receiver_project_id) => {
  return $axios.post('material/transfer-material/work-item', { sender_project_id, receiver_project_id });
};
