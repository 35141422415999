import React, { useState, useEffect, useRef } from 'react';
// import './ProjectDetails.css';
import { useLocation } from 'react-router-dom';
import { CButton, CCard, CCol, CRow, CCollapse, CBadge, CInput, CProgress, CProgressBar, CSpinner } from '@coreui/react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { MultiSelect } from 'react-multi-select-component';
import ThingsToConsider from '../aimhi-widgets/ThingToConsider';
import axios from 'axios';
import IP from '../../StoreGlobal/IP';
import Notifications from '../../websocket/notifications';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import Loading from '../aimhi-widgets/Loading';
import { requestSowNotifications } from 'src/redux/actions/notifications';
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// const depency = [
//   { value: "Cement Plaster", label: "Cement Plaster" },
//   { value: "Non Load Bearing", label: "Non Load Bearing" },
// ];

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DownloadReportWeekly, GetProjectTabStatus } from 'src/request/Projects';
import { getFeatures } from 'src/request/Features';
import { findAllowedFeature } from 'src/utils/findAllowedFeature';
import { getReport } from 'src/request/Reports';
import {ceilToDecimalPlaces} from 'src/utils/ceilToDecimalPlaces';
const task_empty = {
  Dependencies: [],
  EndDate: '',
  Priority: '',
  SOW: '',
  StartDate: '',
  Status: 'not_started',
  sow_id: 0,
  new: true,
};

const empty_phase = {
  Phase: '',
  ProgressPercentage: 0,
  Task: [
    {
      Dependencies: [],
      StartDate: '',
      EndDate: '',
      actual_start: '',
      actual_end: '',
      Priority: '',
      SOW: '',
      Status: 'not_started',
      sow_id: 0,
      new: true,
    },
  ],
  Total_Budget: 0,
  Total_Cost: 0,
  new: true,
};

const recommenderDependency = [
  {
    dependencies: [
      { label: 'None', value: '0' },
      { label: 'B.4 (1) Construction survey and staking', value: 43 },
    ],
    SOW: 'B.9 Mobilization / Demobilization',
    sow_id: 59,
  },
  {
    dependencies: [
      { label: 'Site Cleaning', value: 3811 },
      { label: '1. Mobilization', value: 3830 },
    ],
    SOW: 'Drywall',
    sow_id: 3700,
  },
  {
    dependencies: [
      { label: 'Cabinets', value: 3944 },
      { label: 'Painting (Drywall)', value: 3949 },
    ],
    SOW: 'Site Cleaning',
    sow_id: 3811,
  },
  {
    dependencies: [{ label: '1. Mobilization', value: 3830 }],
    SOW: 'Clearing',
    sow_id: 3812,
  },
  {
    dependencies: [
      { label: 'CHB FILING Exterior Walls', value: 3890 },
      { label: 'Plastering exterior walls', value: 4059 },
    ],
    SOW: 'Accent Wall',
    sow_id: 3813,
  },
  {
    dependencies: [{ label: '1. Mobilization', value: 3830 }],
    SOW: 'Site Management',
    sow_id: 3815,
  },
  {
    dependencies: [
      { label: 'Drywall', value: 3700 },
      { label: 'Concrete Walls (27.1 sq.m) (Optional)', value: 3950 },
    ],
    SOW: 'Electrical',
    sow_id: 3902,
  },
  {
    dependencies: [
      { label: 'Site Cleaning', value: 3811 },
      { label: '1. Mobilization', value: 3830 },
    ],
    SOW: 'Cabinets',
    sow_id: 3944,
  },
  {
    dependencies: [{ label: 'Drywall', value: 3700 }],
    SOW: 'Windows (3.25m x 1.0m)',
    sow_id: 3946,
  },
  {
    dependencies: [{ label: 'Drywall', value: 3700 }],
    SOW: 'Painting (Drywall)',
    sow_id: 3949,
  },
  {
    dependencies: [{ label: 'Drywall', value: 3700 }],
    SOW: 'Concrete Walls (27.1 sq.m) (Optional)',
    sow_id: 3950,
  },
  {
    dependencies: [{ label: 'Drywall', value: 3700 }],
    SOW: 'Door (0.80m x 2.85m)',
    sow_id: 4323,
  },
  {
    dependencies: [{ label: 'Cabinets', value: 3944 }],
    SOW: 'Fixed Glass (82 sq. ft.)',
    sow_id: 4983,
  },
];

const animatedComponents = makeAnimated();
const ProjectTask = (props) => {
  const features = useSelector((state) => state.features);
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));
  const Swal = require('sweetalert2');
  const [loading, setLoading] = useState(true);
  const company_id = parseInt(localStorage.getItem('company_id'));
  const [collapse, setCollapse] = useState('');
  const [active, setActive] = useState(1);
  const [taskItem, settaskItem] = useState([]);
  const [toggle, settoggle] = useState([]);
  const [selected, setSelected] = useState([]);
  const [depency, setdepency] = useState([]);
  const [projectId, setprojectId] = useState('');
  const [projectStart, setprojectStart] = useState('');
  const [projectEnd, setprojectEnd] = useState('');
  const [TaskIndex, setTaskIndex] = useState(0);
  const [sowOption, setSowOption] = useState([]);
  const [phasesOption, setphasesOption] = useState([]);
  const [taskState, setTaskState] = useState({ under_budget: 1, delayed: 0 });
  const [showAll, setShowAll] = useState(false);
  const [recommenderDependency, setRecommenderDependency] = useState([]);
  const [recommenderDependencyIsActionable, setRecommenderDependencyIsActionable] = useState(false);
  const [showRecommenderDependency, setShowRecommenderDependency] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const redux_module = useSelector((state) => state.module);
  let permission = useSelector((state) => state.permission);

  const location = useLocation();
  const { project_id } = location.state || {}; // Access project_id from location.state
  const [isDownloadableReportAllowed, setIsDownloadableReportAllowed] = useState(false);
  const [date, setDate] = useState('');
  // Check if props.project_id is defined
  // const idToUse = props.id !== undefined ? props.id : project_id;
  // let aw = 0;

  useEffect(() => {
    const getWeekly = (status) => {
      getReport(props.id, 'weekly', status).then((res) => {
        if (res.status == 200) {
          setDate(res.data.response.date);
        } else {
          console.log('error', res.data.response);
        }
      });
    };
    getWeekly(0);
  }, []);

  useEffect(() => {
    if (features.length > 0) {
      setIsDownloadableReportAllowed(findAllowedFeature(features, 5));
    }
  }, [features]);

  useEffect(() => {
    // console.log('shet ', props);
    const fetchData = async () => {
      await setprojectId(props.id);
      await scopeofwork(props.id);
      await depencies(props.id);
      await getRecommenderDependencies(props.id);
      await dateformat(props.start, props.end);
      if (props.phaseId) {
        setTimeout(() => {
          const el = document.getElementById(`task-${props.phaseId}`);
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth', // or 'auto'
              block: 'center', // aligns the element to the center of the viewport
              inline: 'center', // aligns the element to the center horizontally (optional)
            });
          }
        }, 400);
      }
    };
    fetchData();
    getsowlist();
    getphases();
  }, [props.id]);

  useEffect(() => {
    // This useEffect runs whenever taskItem changes
    const toggleData = async () => {
      await toggleShow(props.phaseId, true, taskItem);
    };
    if (props.phaseId) {
      toggleData();
    }
    // perform any other actions that depend on taskItem here
  }, [taskItem]);

  useEffect(() => {
    if (redux_module == 'project-scope-of-work') {
      const n = new Notifications();
      n.request_sow_notifications(company_id, props.id);
    }
  }, [redux_module]);

  const validateData = (arr) => {
    const hasDependency = arr.filter((length) => length.length === 0);
    // console.log('hasDependency', arr);
    if (hasDependency.length === 0) {
      props.validate(true);
    } else {
      props.validate(false);
    }
  };

  const dateformat = (start, end) => {
    let a = new Date(start);
    let b = new Date(end);

    let sm = a.getMonth() + 1;
    let sd = a.getDate();
    let sy = a.getFullYear();
    let date_start = sy + '-' + sm + '-' + sd;

    let em = b.getMonth() + 1;
    let ed = b.getDate();
    let ey = b.getFullYear();
    let date_end = ey + '-' + em + '-' + ed;

    setprojectStart(date_start.toString());
    setprojectEnd(date_end.toString());
    // document.getElementById("date_field").setAttribute("max", date_end);
    // document.getElementById("date_field").setAttribute("min", date_start);
  };

  const allPhases = taskItem.map((item) => item.Phase);
  const uniquePhases = [...new Set(allPhases)];

  const toggleShow = (value, isId = false, baseData) => {
    if (isId) {
      baseData.forEach((e) => {
        const data = e.Task.find((x) => x.sow_id === value);
        if (data) {
          value = e.Phase;
        }
      });
    }
    console.log(value, 'valuejanrey');
    const shownState = toggle.slice();
    const index = shownState.indexOf(value);
    console.log('shownStates', shownState);

    if (index >= 0) {
      shownState.splice(index, 1);
      settoggle(shownState);
    } else if (value === 'all') {
      settoggle(uniquePhases);
      setRecommenderDependencies();
      console.log('all taskItem ', taskItem);
    } else {
      shownState.push(value);
      settoggle(shownState);
    }
  };

  const changeprio = (e, Index, scopeIndex) => {
    const newArr = [...taskItem];

    // console.log(newArr, e.target.value, Index, scopeIndex);

    newArr[scopeIndex].Task[Index].Priority = e.target.value;

    settaskItem(newArr);
  };

  const changestatus = (e, Index, scopeIndex) => {
    const newArr = [...taskItem];

    // console.log(newArr, e.target.value, Index, scopeIndex);

    newArr[scopeIndex].Task[Index].Status = e.target.value;

    settaskItem(newArr);
  };

  const showRecommenderDependencies = (isSet) => {
    setShowRecommenderDependency(isSet);
  };

  const scopeofwork = async (project_id) => {
    console.log('props.projid', project_id);
    console.log('props.projid2', props.project_id);
    await axios
      .get(IP + `sow/details/${company_id}/${project_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        console.log('res', res);
        settaskItem(res.data.response);

        setLoading(false);

        let selectedDependency = res.data.response;
        let arr = [];
        console.log(selectedDependency, 'selectedDependency.length');

        if (selectedDependency.length != 0) {
          selectedDependency.forEach((value) => {
            value.Task.forEach((dependency) => {
              // console.log('value.Task', dependency.Dependencies);

              arr.push({
                length: dependency.Dependencies.length,
                StartDate: dependency.StartDate,
                EndDate: dependency.EndDate,
              });
            });
          });
          validateData(arr);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 264', error);
      });
  };

  const getRecommenderDependencies = (project_id) => {
    axios
      .get(IP + `recommender/dependency?project_id=${project_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          const updatedData = res.data.data.map((item) => ({
            ...item,
            dependencies: item.dependencies.map((dep) => ({
              label: dep.value === 0 ? 'None' : dep.label,
              value: dep.value === 0 ? '0' : dep.value,
            })),
          }));
          console.log('updatedData ', updatedData);
          setRecommenderDependency(updatedData);
          setShowRecommenderDependency(true);
        } else {
          console.log('No Recommendation');
          setShowRecommenderDependency(true);
        }
      })
      .catch((error) => {
        console.log('error', error);
        setShowRecommenderDependency(false);
      });
  };

  const setRecommenderDependencies = () => {
    const newTaskItem = [...taskItem];

    console.log('newTaskItem recommender', newTaskItem);

    const updatedTaskItem = newTaskItem.map((phase) => {
      phase.Task.forEach((task) => {
        const matchingDependency = recommenderDependency.find((dep) => dep.sow_id === task.sow_id);
        if (matchingDependency) {
          task.Dependencies = matchingDependency.dependencies;
          task.recommender = 1;
        }
      });
      return phase;
    });

    console.log('Updated newTaskItem with recommender dependencies:', updatedTaskItem);
    settaskItem(updatedTaskItem);
  };

  const depencies = (project_id) => {
    axios
      .post(IP + 'sow/dependencies', {
        project_id,
        company_id: company_id,
        headers: {
          'Content-Type': 'appliation/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        // Use a Set to remove duplicate values based on 'value'
        const uniqueDependencies = Array.from(new Set(res.data.response.map((dep) => dep.value)));

        // Create a new array of unique dependency objects
        const uniqueDependencyObjects = uniqueDependencies.map((value) => {
          return res.data.response.find((dep) => dep.value === value);
        });

        setdepency(uniqueDependencyObjects);
        console.log(uniqueDependencyObjects, 'setdepency()');

        // setdepency(res.data.response);
        // console.log(res.data.response, 'setdepency()');
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 281', error);
      });
  };

  const handleDeselectAll = () => {
    // revert back to original state
    scopeofwork(props.id, props.type);
  };

  const handleAccept = () => {
    // Create a copy of the data object to avoid mutating the original state
    const newTaskItem = [...taskItem];

    // Iterate over each item in taskItem
    newTaskItem.forEach((item, scopeIndex) => {
      // Iterate over each task in the current item
      item.Task.forEach((itemtask, innerIndex) => {
        autosave(
          {
            id: itemtask.id,
            dependencies: itemtask.Dependencies,
          },
          innerIndex,
          scopeIndex,
        );
      });
    });

    // Update the state with the modified taskItem
    settaskItem(newTaskItem);
  };

  const autosave = (data, Index, scopeIndex) => {
    console.log(data, 'data', Index, 'Index', scopeIndex, 'scopeIndex');
    if (data.dependencies) {
      console.log('dependencies_data', data);
      let minusLength = data.dependencies.length - 1;
      console.log(minusLength, 'MINUS LENGTH');

      const includeNone = data.dependencies.filter((dependency) => dependency.value == 0);
      const excludeNone = data.dependencies.filter((dependency) => dependency.value != 0);

      console.log('includeNone', includeNone);
      console.log('excludeNone', excludeNone);

      if (excludeNone.length >= 1 && includeNone.length == 1) {
        if (data.dependencies[minusLength].value == 0) {
          console.log('NONE SELECTED...');
          data.dependencies = includeNone;

          Swal.fire({
            title: '<div class="swaltitle">WARNING</div>',
            text: 'By selecting none option, your current selected dependencies will be removed.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            customClass: {
              icon: 'swal-icon-size',
              confirmButton: 'btn confirm',
              cancelButton: 'btn cancel',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              axios
                .post(IP + 'sow/save', {
                  data,
                  headers: {
                    'Content-Type': 'appliation/json',
                    Authorization: `Bearer ${accessToken}`,
                  },
                })
                .then((res) => {
                  if (typeof data.dependencies != 'undefined') {
                    let arr = [...taskItem];
                    arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

                    settaskItem(arr);
                  }

                  if (res.data.status == 1) {
                    if (res.data.response == 1) {
                      requestSowNotifications(company_id, props.id);

                      // refresh screen
                      scopeofwork(props.id, props.type);

                      // callback from ProjectDetails.js
                      props.refreshTabStatus(props.id);
                    }
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: res.data.response,
                      text: res.data.description,
                      confirmButtonColor: '#23303D',
                    });

                    scopeofwork(props.id, props.type);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              console.log('DEPENDENCY/IES REMOVED!');
            } else if (result.isDenied) {
              Swal.fire('Changes saved', '', 'info');
            }
          });
        } else if (data.dependencies[minusLength].value != 0) {
          console.log('DEPENDENCY SELECTED...');
          data.dependencies = excludeNone;
          axios
            .post(IP + 'sow/save', {
              data,
              headers: {
                'Content-Type': 'appliation/json',
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((res) => {
              if (typeof data.dependencies != 'undefined') {
                let arr = [...taskItem];
                arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

                settaskItem(arr);
              }

              if (res.data.status == 1) {
                if (res.data.response == 1) {
                  requestSowNotifications(company_id, props.id);

                  // refresh screen
                  scopeofwork(props.id, props.type);

                  // callback from ProjectDetails.js
                  props.refreshTabStatus(props.id);
                }
              } else {
                Swal.fire({
                  icon: 'error',
                  title: res.data.response,
                  text: res.data.description,
                  confirmButtonColor: '#23303D',
                });

                scopeofwork(props.id, props.type);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          console.log('NONE REMOVED!');
        }
      }

      if (excludeNone.length <= 0 && includeNone.length >= 1) {
        console.log('None ONLY');

        data.dependencies = includeNone;
        axios
          .post(IP + 'sow/save', {
            data,
            headers: {
              'Content-Type': 'appliation/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((res) => {
            if (typeof data.dependencies != 'undefined') {
              let arr = [...taskItem];
              arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

              settaskItem(arr);
            }

            if (res.data.status == 1) {
              if (res.data.response == 1) {
                requestSowNotifications(company_id, props.id);

                // refresh screen
                scopeofwork(props.id, props.type);

                // callback from ProjectDetails.js
                props.refreshTabStatus(props.id);
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: res.data.response,
                text: res.data.description,
                confirmButtonColor: '#23303D',
              });

              scopeofwork(props.id, props.type);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (excludeNone.length >= 1 && includeNone.length <= 0) {
        console.log('Dependencies ONLY');
        data.dependencies = excludeNone;
        axios
          .post(IP + 'sow/save', {
            data,
            headers: {
              'Content-Type': 'appliation/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((res) => {
            if (typeof data.dependencies != 'undefined') {
              let arr = [...taskItem];
              arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

              settaskItem(arr);
            }

            if (res.data.status == 1) {
              if (res.data.response == 1) {
                requestSowNotifications(company_id, props.id);

                // refresh screen
                scopeofwork(props.id, props.type);

                // callback from ProjectDetails.js
                props.refreshTabStatus(props.id);
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: res.data.response,
                text: res.data.description,
                confirmButtonColor: '#23303D',
              });

              scopeofwork(props.id, props.type);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (excludeNone.length === 0 && includeNone.length === 0) {
        console.log('None ONLY2');

        axios
          .post(IP + 'sow/save', {
            data,
            headers: {
              'Content-Type': 'appliation/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((res) => {
            if (typeof data.dependencies != 'undefined') {
              let arr = [...taskItem];
              arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);
              settaskItem(arr);
            }

            if (res.data.status == 1) {
              if (res.data.response == 1) {
                requestSowNotifications(company_id, props.id);

                // refresh screen
                scopeofwork(props.id, props.type);

                // callback from ProjectDetails.js
                props.refreshTabStatus(props.id);
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: res.data.response,
                text: res.data.description,
                confirmButtonColor: '#23303D',
              });

              scopeofwork(props.id, props.type);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (data.status) {
      axios
        .post(IP + 'sow/save', {
          data,
          headers: {
            'Content-Type': 'appliation/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          if (typeof data.dependencies != 'undefined') {
            let arr = [...taskItem];
            arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

            settaskItem(arr);
          }

          if (res.data.status == 1) {
            if (res.data.response == 1) {
              requestSowNotifications(company_id, props.id);

              // refresh screen
              scopeofwork(props.id, props.type);

              // callback from ProjectDetails.js
              props.refreshTabStatus(props.id);
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: res.data.response,
              text: res.data.description,
              confirmButtonColor: '#23303D',
            });

            scopeofwork(props.id, props.type);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (data.priority) {
      axios
        .post(IP + 'sow/save', {
          data,
          headers: {
            'Content-Type': 'appliation/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          if (typeof data.dependencies != 'undefined') {
            let arr = [...taskItem];
            arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

            settaskItem(arr);
          }

          if (res.data.status == 1) {
            if (res.data.response == 1) {
              requestSowNotifications(company_id, props.id);

              // refresh screen
              scopeofwork(props.id, props.type);

              // callback from ProjectDetails.js
              props.refreshTabStatus(props.id);
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: res.data.response,
              text: res.data.description,
              confirmButtonColor: '#23303D',
            });

            scopeofwork(props.id, props.type);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // axios
    // .post(IP + 'sow/save', {
    //   data,
    //   headers: {
    //     'Content-Type': 'appliation/json',
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // })
    // .then((res) => {
    //   if (typeof data.dependencies != 'undefined') {
    //     let arr = [...taskItem];
    //     arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

    //     settaskItem(arr);
    //   }

    //   if (res.data.status == 1) {
    //     if (res.data.response == 1) {
    //       requestSowNotifications(company_id, props.id);

    //       // refresh screen
    //       scopeofwork(props.id, props.type);
    //     }
    //   } else {
    //     Swal.fire({
    //       icon: 'error',
    //       title: res.data.response,
    //       text: res.data.description,
    //       confirmButtonColor: '#23303D',
    //     });

    //     scopeofwork(props.id, props.type);
    //   }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  const changedates = (value, Index, scopeIndex, category) => {
    const newArr = [...taskItem];

    if (category === 'itemtask.StartDate') {
      newArr[scopeIndex].Task[Index].StartDate = value;
    } else if (category === 'itemtask.EndDate') {
      newArr[scopeIndex].Task[Index].EndDate = value;
    }
    settaskItem(newArr);
  };

  const date_autosave = (data, Index, scopeIndex) => {
    let formattedData = { ...data };

    if (data.start_date) {
      const startDate = new Date(data.start_date);
      const timezoneOffset = startDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
      const adjustedDate = new Date(startDate - timezoneOffset);
      formattedData.start_date = adjustedDate.toISOString().split('T')[0];
    }
    if (data.end_date) {
      const endDate = new Date(data.end_date);
      const timezoneOffset = endDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
      const adjustedDate = new Date(endDate - timezoneOffset);
      formattedData.end_date = adjustedDate.toISOString().split('T')[0];
    }
    // console.log(formattedData, "formated");

    axios
      .post(IP + 'sow/save', {
        data: formattedData,
        headers: {
          'Content-Type': 'appliation/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (typeof data.dependencies != 'undefined') {
          let arr = [...taskItem];
          arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

          settaskItem(arr);
        }

        if (res.data.status == 1) {
          if (res.data.response == 1) {
            requestSowNotifications(company_id, props.id);

            // refresh screen
            scopeofwork(props.id, props.type);

            // callback from ProjectDetails.js
            props.refreshTabStatus(props.id);
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
          });

          scopeofwork(props.id, props.type);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 318', error);
      });
  };

  const formatDate = (date) => {
    let formattedDate;
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      formattedDate = `${year}-${month}-${day}`;
    } else if (typeof date === 'string') {
      // Assuming the date is in 'MM/dd/yyyy' format
      const [month, day, year] = date.split('/');
      formattedDate = `${year}-${month}-${day}`;
    } else {
      // Handle other cases or throw an error if the date format is unexpected
      throw new Error('Invalid date format');
    }
    return formattedDate;
  };

  const AddNewSow = (index) => {
    let newArr = [...taskItem];

    let add_new = JSON.stringify(task_empty);
    newArr[index].Task.push(JSON.parse(add_new));

    settaskItem(newArr);
  };

  const AddNewPhase = (index) => {
    let newArr = [...taskItem];

    let add_new = JSON.stringify(empty_phase);
    newArr.push(JSON.parse(add_new));

    settaskItem(newArr.reverse());
  };

  const getsowlist = () => {
    axios
      .get(IP + 'sow/list', {
        headers: {
          'Content-Type': 'appliation/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setSowOption(res.data.response);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getphases = () => {
    axios
      .post(IP + 'sow/phase/list', {
        company_id: company_id,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setphasesOption(res.data.response);
      })
      .catch((error) => {
        console.log('projectdetails/ProjectTask.js', ' ', 'line 363', error);
      });
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
    //console.log(e.target.innerHTML);
    // console.log(position, 'dragStart position');
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    //console.log(e.target.innerHTML);
    // console.log(position, 'dragEnter position');
  };

  const drop = (e) => {
    const copyListItems = [...taskItem];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    settaskItem(copyListItems);
  };

  // const toggleOptions = () => {
  //   setShowOptions(!showOptions);
  // };

  // const [showOptions, setShowOptions] = useState([]);
  // const toggleOptions = (index) => {
  //   setShowOptions((prevOptions) => {
  //     const newOptions = [...prevOptions];
  //     newOptions[index] = !newOptions[index];
  //     return newOptions;
  //   });
  // };

  // const handleOptionClick = () => {
  //   setShowOptions([]);
  // };

  // const handleClickOutside = (event) => {
  //   if (event.target.closest('.progress-dropdown')) return;
  //   setShowOptions([]);
  // };

  // React.useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  // const changestartdate = (e, Index, scopeIndex, category) => {
  //   const newArr = [...taskItem];

  //   if (category === 'target') {
  //     newArr[scopeIndex].Task[Index].StartDate = e.target.value;
  //   } else if ('actual') {
  //     newArr[scopeIndex].Task[Index].actual_start = e.target.value;
  //   }

  //   settaskItem(newArr);

  console.log(taskItem);
  // };

  const ItemBadge = ({ underBudget, delayed, incomplete }) => {
    let badge = [];

    if (underBudget == 1) {
      badge.push(
        <CBadge color="aimhi-success" className="badge-stats">
          WITHIN BUDGET
        </CBadge>,
      );
    } else {
      badge.push(
        <CBadge color="aimhi-danger" className="badge-stats">
          OVER BUDGET
        </CBadge>,
      );
    }

    if (incomplete == 1) {
      badge.push(
        <CBadge color="aimhi-success" className="badge-stats">
          INCOMPLETE
        </CBadge>,
      );
    } else {
      if (delayed == 0) {
        badge.push(
          <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '3px' }}>
            ON-TIME
          </CBadge>,
        );
      } else {
        badge.push(
          <CBadge color="aimhi-danger" className="badge-stats" style={{ marginTop: '3px' }}>
            DELAYED
          </CBadge>,
        );
      }
    }

    return badge;
  };

  const taskCard = taskItem.map((item, index) => {
    let scopeIndex = index;
    //console.log(item, "completeshet");

    const taskdetails = item.Task.map((itemtask, innerIndex) => {
      let classifier = <div className="left-design float-left"></div>;
      // console.log(itemtask.SOW, "itemtask.SOW");
      // console.log('value ', itemtask.Dependencies, 'dependency');
      // console.log('option ', depency, 'dependency');
      // console.log('itemtaskactual', itemtask);

      let color = '';
      let colorstatus = '';
      if (itemtask.Priority == 'high') {
        color = 'red';
      } else if (itemtask.Priority == 'moderate') {
        color = 'yellow';
      } else {
        color = 'green';
      }

      if (itemtask.Status >= '100') {
        colorstatus = 'green';
      } else if (itemtask.Status >= '1' && itemtask.Status <= '99') {
        colorstatus = 'blue';
      } else if (itemtask.Status <= '0') {
        colorstatus = 'gray';
      }

      if (itemtask.risk_score <= 0.2) {
        classifier = <div className="left-design-success float-left"></div>;
      }
      if (itemtask.risk_score > 0.2 && itemtask.risk_score <= 0.4) {
        classifier = <div className="left-design-semiwarning float-left"></div>;
      }
      if (itemtask.risk_score > 0.4 && itemtask.risk_score <= 0.6) {
        classifier = <div className="left-design-warning float-left"></div>;
      }
      if (itemtask.risk_score > 0.6) {
        classifier = <div className="left-design-danger float-left"></div>;
      }
      return (
        <CCol xs="12" sm="12" md="12" lg="12" xl="12" key={innerIndex} style={{ padding: '0px' }}>
          {classifier}
          <CCard id={`task-${itemtask.sow_id}`} className="task-card" style={{ marginLeft: '30px' }}>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="3" id="Task">
                {itemtask.new == true ? <Select className="basic-single" classNamePrefix="select" isClearable={true} isSearchable={true} options={sowOption} /> : <span className="task-label">{itemtask.SOW}</span>}
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="6" className="text-center" id="Priority, Status and dates">
                <CRow>
                  <CCol xs="12" sm="12" md="12" lg="3" id="Priority" style={{ padding: '0px' }}>
                    <span className="top-label">Priority</span>
                    <br />
                    <select
                      className={`${color} select-design ${color === 'yellow' ? 'text-dark' : ''}`}
                      onChange={(e) => changeprio(e, innerIndex, scopeIndex)}
                      onBlur={(e) =>
                        autosave(
                          {
                            // project_id: props.id,
                            id: itemtask.id,
                            // sow_id: itemtask.sow_id,
                            priority: e.target.value,
                          },
                          innerIndex,
                          scopeIndex,
                        )
                      }
                      value={itemtask.Priority}
                      disabled={permission['update'][0] != 1 || permission['create'][0] != 1}
                    >
                      <option style={{ backgroundColor: 'white' }} value=""></option>
                      <option style={{ backgroundColor: '#B42A19', color: 'white' }} value="high">
                        High
                      </option>
                      <option style={{ backgroundColor: '#FDFE54', color: 'black' }} value="moderate">
                        Moderate
                      </option>
                      <option style={{ backgroundColor: '#3A7D21', color: 'white' }} value="low">
                        Low
                      </option>
                    </select>
                  </CCol>
                  <CCol xs="12" sm="12" md="12" lg="3" id="Status" style={{ padding: '0px' }}>
                    <span className="top-label">Status</span>
                    <br />
                    <div className="progress-dropdown" style={{ marginTop: '3px' }}>
                      <div style={{ position: 'relative' }}>
                        <CProgress style={{ height: '15px' }}>
                          <div
                            id="editable"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: itemtask.Status < 60 ? 'black' : 'white',
                              fontWeight: 'bold',
                            }}
                            // onDoubleClick={(e) => {
                            //   e.target.contentEditable = true;
                            //   e.target.focus();
                            //   e.target.style.paddingLeft = '1rem';
                            //   e.target.style.paddingRight = '1rem';
                            // }}
                            // onBlur={(e) => {
                            //   const newValue = e.target.innerText.replace('%', '');
                            //   if (newValue > 100 || newValue < 0) {
                            //     e.target.innerText = itemtask.Status;
                            //   } else {
                            //     const newArr = [...taskItem];
                            //     newArr[scopeIndex].Task[innerIndex].Status = newValue;
                            //     settaskItem(newArr);
                            //     autosave(
                            //       {
                            //         id: itemtask.id,
                            //         status: newValue,
                            //       },
                            //       innerIndex,
                            //       scopeIndex,
                            //     );
                            //   }
                            //   e.target.contentEditable = false;
                            //   e.target.style.paddingLeft = '8px';
                            //   e.target.style.paddingRight = '8px';
                            // }}
                            // onKeyDown={(e) => {
                            //   if (e.key === 'Enter') {
                            //     const newValue = e.target.innerText.replace('%', '');
                            //     if (newValue > 100 || newValue < 0) {
                            //       e.target.innerText = itemtask.Status;
                            //     } else {
                            //       const newArr = [...taskItem];
                            //       newArr[scopeIndex].Task[innerIndex].Status = newValue;
                            //       settaskItem(newArr);
                            //       autosave(
                            //         {
                            //           id: itemtask.id,
                            //           status: newValue,
                            //         },
                            //         innerIndex,
                            //         scopeIndex,
                            //       );
                            //     }
                            //     e.target.contentEditable = false;
                            //     e.target.style.paddingLeft = '8px';
                            //     e.target.style.paddingRight = '8px';
                            //   }
                            // }}
                          >
                            <span contentEditable="false">{itemtask.Status}</span>%
                          </div>
                          <CProgressBar value={itemtask.Status} className={colorstatus + ' ' + 'select-design'} />
                        </CProgress>
                      </div>
                    </div>
                  </CCol>
                  <CCol xs="12" sm="12" md="12" lg="3" className="text-center" id="Target Start and Actual Start">
                    <CRow>
                      <CCol xs="12" sm="12" md="12" lg="12" id="Target Start">
                        <span className="top-label">Target Start</span>
                        <br />
                        {/* <DatePicker
                          className="responsive-datepicker"
                          format="MM/dd/yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          yearPlaceholder="yyyy"
                          onBlur={(value) => {
                            date_autosave(
                              {
                                id: itemtask.id,
                                start_date: itemtask.StartDate,
                              },
                              innerIndex,
                              scopeIndex
                            );
                          }}
                          onChange={(value) => {
                            changestartdate(value, innerIndex, scopeIndex, 'target');
                          }}
                          value={itemtask.StartDate}
                        /> */}
                        <DatePicker
                          className="sow"
                          placeholderText="mm/dd/yyyy"
                          format="MM/dd/yyyy"
                          selected={itemtask.StartDate ? new Date(itemtask.StartDate) : null}
                          value={itemtask.StartDate}
                          onChange={(value) => {
                            const date = new Date(value);
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const day = String(date.getDate()).padStart(2, '0');
                            const year = date.getFullYear();
                            const formattedValue = `${month}/${day}/${year}`;
                            changedates(formattedValue, innerIndex, scopeIndex, 'itemtask.StartDate');
                            date_autosave(
                              {
                                id: itemtask.id,
                                start_date: itemtask.StartDate,
                              },
                              innerIndex,
                              scopeIndex,
                            );
                          }}
                        />
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12" id="Actual Start">
                        <span className="top-label">Actual Start</span>
                        <br />
                        <span style={{ fontSize: '0.765625rem' }}>{itemtask.actual_start ? itemtask.actual_start : 'mm/dd/yyyy'}</span>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol xs="12" sm="12" md="12" lg="3" className="text-center" id="Target End and Actual End">
                    <CRow>
                      <CCol xs="12" sm="12" md="12" lg="12" id="Target End">
                        <span className="top-label">Target End</span>
                        <br />
                        {/* <DatePicker
                          className="responsive-datepicker"
                          format="MM/dd/yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          yearPlaceholder="yyyy"
                          onBlur={(value) => {
                            date_autosave(
                              {
                                id: itemtask.id,
                                end_date: itemtask.EndDate,
                              },
                              innerIndex,
                              scopeIndex
                            );
                          }}
                          onChange={(value) => {
                            changeenddate(value, innerIndex, scopeIndex, 'target');
                          }}
                          value={itemtask.EndDate}
                        /> */}
                        <DatePicker
                          className="sow"
                          placeholderText="mm/dd/yyyy"
                          selected={itemtask.EndDate ? new Date(itemtask.EndDate) : null}
                          value={new Date(itemtask.EndDate)}
                          onChange={(value) => {
                            const date = new Date(value);
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const day = String(date.getDate()).padStart(2, '0');
                            const year = date.getFullYear();
                            const formattedValue = `${month}/${day}/${year}`;
                            changedates(formattedValue, innerIndex, scopeIndex, 'itemtask.EndDate');
                            date_autosave(
                              {
                                id: itemtask.id,
                                end_date: itemtask.EndDate,
                              },
                              innerIndex,
                              scopeIndex,
                            );
                          }}
                        />
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12" id="Actual End">
                        <span className="top-label">Actual End</span>
                        <br />
                        <span style={{ fontSize: '0.765625rem' }}>{itemtask.actual_end ? itemtask.actual_end : 'mm/dd/yyyy'}</span>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="3" className="text-center" id="Dependency and Dots">
                <CRow>
                  <CCol xs="12" sm="12" md="12" lg="10" className="text-center" id="Dependency" style={{ paddingRight: '0px' }}>
                    <CCol xs="12" sm="12" md="12" lg="12">
                      <span className="top-label">Dependency</span>
                      <br />
                      <MultiSelect
                        options={depency.filter((option) => option.value !== itemtask.id)}
                        value={itemtask.Dependencies}
                        onChange={(e) =>
                          autosave(
                            {
                              id: itemtask.id,
                              dependencies: e,
                            },
                            innerIndex,
                            scopeIndex,
                          )
                        }
                        // onBlur={(value) => {
                        //   date_autosave(
                        //     {
                        //       id: itemtask.id,
                        //       start_date: itemtask.StartDate,
                        //     },
                        //     innerIndex,
                        //     scopeIndex
                        //   );
                        // }}
                        // onChange={(value) => {
                        //   changestartdate(value, innerIndex, scopeIndex, 'target');
                        // }}

                        labelledBy="Select"
                        className={itemtask.recommender !== 1 ? 'multi-select-design' : 'multi-select-design-recommender'}
                        disabled={permission['update'][0] != 1 || permission['create'][0] != 1}
                        style={{ textAlign: 'left', border: '1px solid #ff8b67' }}
                      >
                        <div className="panel-content"></div>
                      </MultiSelect>
                    </CCol>
                  </CCol>
                  {/* <CCol xs="12" sm="12" md="12" lg="2" className="text-center" id="Dots" style={{ padding: '0px' }}>
                    <div className="dot-position-sow">
                      <CButton id="popoverone" size="sm" onClick={() => setTaskIndex(scopeIndex)}>
                        <span className="iconify" data-icon="akar-icons:more-vertical" style={{ width: '20px', height: '20px' }}></span>
                      </CButton>
                      <UncontrolledPopover placement="bottom" target="popoverone">
                        <PopoverHeader>Actions</PopoverHeader>
                        <PopoverBody>
                          <div>
                            <CButton size="sm" onClick={() => AddNewSow(TaskIndex)}>
                              <span className="iconify" data-icon="akar-icons:plus"></span>
                              <span style={{ marginLeft: '5px' }}>Add New</span>
                            </CButton>
                          </div>
                          <div>
                            <CButton size="sm">
                              <span className="iconify" data-icon="akar-icons:arrow-up"></span>
                              <span style={{ marginLeft: '5px' }}>Move Up</span>
                            </CButton>
                          </div>
                          <div>
                            <CButton size="sm">
                              <span className="iconify" data-icon="akar-icons:arrow-down"></span>
                              <span style={{ marginLeft: '5px' }}>Move Down</span>
                            </CButton>
                          </div>
                          <div>
                            <CButton size="sm">
                              <span className="iconify" data-icon="akar-icons:minus"></span>
                              <span style={{ marginLeft: '5px' }}>Remove</span>
                            </CButton>
                          </div>
                          <span>Move to</span>
                          <div>
                            <CInput type="text" size="sm" placeholder="Type Phase Here" />
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  </CCol> */}
                </CRow>
              </CCol>
            </CRow>
          </CCard>
        </CCol>
      );
    });
    const progressPercentage = ceilToDecimalPlaces(item.ProgressPercentage, 2);
    const totalBudget = ceilToDecimalPlaces(item.Total_Budget, 2);
    const totalCost = ceilToDecimalPlaces(item.Total_Cost, 2);
    const itemtask = item.Task[0];
    return (
      <CCol xs="12" sm="12" md="12" lg="12" className="list-item" style={{ padding: '1px', marginTop: '20px' }} onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop} draggable key={index}>
        <CCard style={{ padding: '20px', marginBottom: '0' }} className="shadow" onClick={() => toggleShow(item.Phase)}>
          <CRow>
            <CCol xs="12" sm="12" md="4" lg="3" xl="3" style={{ marginTop: '10px' }}>
              <span className="float-left circle-number">
                <span>{index + 1}</span>
              </span>
              {item.new == true ? (
                <div style={{ marginLeft: '10px' }}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    // defaultValue={}

                    isClearable={true}
                    isSearchable={true}
                    options={phasesOption}
                  />
                </div>
              ) : (
                // <span className="sow-label">{item.Phase}</span>
                <div className="sow-label">{item.Phase.toUpperCase()}</div>
              )}
            </CCol>

            <CCol xs="12" sm="12" md="1" lg="1" className="text-center float-left" style={{ paddingTop: '10px' }}>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ItemBadge underBudget={item.under_budget} delayed={item.delayed} incomplete={item.incomplete} />

                {/* {item.under_budget === 1 ? (
                  <>
                    {item.Total_Cost === 0.0 && (!itemtask.StartDate || itemtask.Status > 0) ? (
                      <CBadge color="aimhi-success" className="badge-stats">
                        INCOMPLETE
                      </CBadge>
                    ) : (
                      <CBadge color="aimhi-success" className="badge-stats">
                        WITHIN BUDGET
                      </CBadge>
                    )}
                  </>
                ) : (
                  <CBadge color="aimhi-danger" className="badge-stats">
                    OVER BUDGET
                  </CBadge>
                )}
                {item.delayed === 0 ? (
                  <CBadge color="aimhi-danger" className="badge-stats" style={{ marginTop: '3px' }}>
                    DELAYED
                  </CBadge>
                ) : (
                  <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '3px' }}>
                    ON-TIME
                  </CBadge>
                )} */}
              </CCol>
            </CCol>
            <CCol md="7" lg="6" style={{ paddingLeft: '40px' }}>
              <CCol md="12" lg="12" className="float-right">
                <CRow>
                  <CCol xs="6" sm="6" md="6" lg="6">
                    <span className="budget-label">Total Budget</span>
                    <div className="budget-value">
                      <div style={{ textAlign: 'left' }}>
                        ₱
                        <NumberFormat value={totalBudget} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      </div>
                    </div>
                  </CCol>
                  <CCol xs="6" sm="6" md="6" lg="6">
                    <div className="float-right">
                      <div className="budget-label" style={{ textAlign: 'right' }}>
                        Total Cost
                      </div>
                      <div className="budget-value">
                        <div style={{ textAlign: 'right' }}>
                          ₱
                          <NumberFormat value={totalCost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
                <CProgress size="md">
                  <CProgressBar
                    color="aimhi-textlight-neutral"
                    // Total Cost
                    value={totalBudget === 0 ? 0 : totalCost}
                    // Total Budget
                    max={totalBudget === 0 ? 1 : totalBudget}
                    // value={item.Total_Cost === 0 && item.Total_Budget === 0 ? 0 : item.Total_Cost}
                    // max={item.Total_Cost === 0 && item.Total_Budget === 0 ? 0 : item.Total_Budget}
                    showPercentage
                    className="text-white"
                  />
                </CProgress>
              </CCol>
            </CCol>

            <CCol xs="12" sm="12" md="2" lg="2" className="align-center">
              <div className="circular-progress-size">
                <CircularProgressbarWithChildren
                  className="circular-progress-size"
                  value={progressPercentage}
                  text={`${progressPercentage}%`}
                  styles={{
                    path: {
                      stroke: '#351D75',
                      strokeLinecap: 'butt',
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      transformOrigin: 'center center',
                      circleRatio: '0',
                      strokeWidth: '-10',
                    },
                    trail: {
                      stroke: '#9c9c9c',
                      strokeLinecap: 'butt',
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                      circleRatio: '0',
                      strokeWidth: '-10',
                    },
                    text: {
                      fill: '#23303D',
                      fontSize: '20px',
                      fontWeight: '700',
                    },
                    background: {
                      fill: '#54c2a2',
                    },
                  }}
                />
              </div>
              <span className="status-label align-text-mobile">Progress</span>
            </CCol>
          </CRow>
        </CCard>
        <CCol xs="12" sm="12" md="12" lg="12" style={{ padding: '0px' }}>
          <CCollapse show={toggle.includes(item.Phase)}>{taskdetails}</CCollapse>
        </CCol>
      </CCol>
    );
  });

  const ProjectStatus = 50;
  const handleDownload = async (project_id, type) => {
    const [start_date, end_date] = date.split(' - ');
    setIsLoading(true);
    await DownloadReportWeekly(project_id, start_date.trim(), end_date.trim(), type);
    setIsLoading(false);
  };

  return (
    <>
      {loading ? <Loading /> : ''}
      <CRow>
        <CCol xs="12" sm="12" md="9" lg="12" xl="9">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            {isDownloadableReportAllowed && (
              <CButton
                color="aimhi-textlight-neutral"
                variant="outline"
                size="sm"
                onClick={() => {
                  handleDownload(props.id, 4);
                }}
                style={{ width: '155px' }}
              >
                {isLoading ? (
                  <>
                    <CSpinner color="#23303D" size="sm" />
                    <span style={{ marginLeft: '8px' }}>Download SWA</span>
                  </>
                ) : (
                  'Download SWA'
                )}
              </CButton>
            )}
          </div>
          {taskCard}
        </CCol>
        <CCol xs="12" sm="12" md="3" lg="12" xl="3">
          <ThingsToConsider project_id={props.id} show_all={toggleShow} onDecline={handleDeselectAll} onAccept={handleAccept} showRecommenderDependency={showRecommenderDependency} taskItem={taskItem}/>
        </CCol>
      </CRow>
    </>
  );
};

export default ProjectTask;
