export const BARANGAYS = [
    {
      "brgy_code": "012801001",
      "brgy_name": "Adams (Pob.)",
      "city_code": "012801",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802001",
      "brgy_name": "Bani",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802002",
      "brgy_name": "Buyon",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802003",
      "brgy_name": "Cabaruan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802004",
      "brgy_name": "Cabulalaan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802005",
      "brgy_name": "Cabusligan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802006",
      "brgy_name": "Cadaratan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802007",
      "brgy_name": "Calioet-Libong",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802008",
      "brgy_name": "Casilian",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802009",
      "brgy_name": "Corocor",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802011",
      "brgy_name": "Duripes",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802012",
      "brgy_name": "Ganagan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802013",
      "brgy_name": "Libtong",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802014",
      "brgy_name": "Macupit",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802015",
      "brgy_name": "Nambaran",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802016",
      "brgy_name": "Natba",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802017",
      "brgy_name": "Paninaan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802018",
      "brgy_name": "Pasiocan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802019",
      "brgy_name": "Pasngal",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802020",
      "brgy_name": "Pipias",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802021",
      "brgy_name": "Pulangi",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802022",
      "brgy_name": "Pungto",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802024",
      "brgy_name": "San Agustin I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802025",
      "brgy_name": "San Agustin II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802027",
      "brgy_name": "San Andres I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802028",
      "brgy_name": "San Andres II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802030",
      "brgy_name": "San Gabriel I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802031",
      "brgy_name": "San Gabriel II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802033",
      "brgy_name": "San Pedro I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802034",
      "brgy_name": "San Pedro II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802036",
      "brgy_name": "San Roque I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802037",
      "brgy_name": "San Roque II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802039",
      "brgy_name": "San Simon I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802040",
      "brgy_name": "San Simon II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802041",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802042",
      "brgy_name": "Sangil",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802044",
      "brgy_name": "Santa Filomena I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802045",
      "brgy_name": "Santa Filomena II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802046",
      "brgy_name": "Santa Rita (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802047",
      "brgy_name": "Santo Cristo I (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802048",
      "brgy_name": "Santo Cristo II (Pob.)",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802050",
      "brgy_name": "Tambidao",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802051",
      "brgy_name": "Teppang",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012802052",
      "brgy_name": "Tubburan",
      "city_code": "012802",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803001",
      "brgy_name": "Alay-Nangbabaan (Alay 15-B)",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803002",
      "brgy_name": "Alogoog",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803003",
      "brgy_name": "Ar-arusip",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803004",
      "brgy_name": "Aring",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803005",
      "brgy_name": "Balbaldez",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803006",
      "brgy_name": "Bato",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803007",
      "brgy_name": "Camanga",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803008",
      "brgy_name": "Canaan (Pob.)",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803009",
      "brgy_name": "Caraitan",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803011",
      "brgy_name": "Gabut Norte",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803012",
      "brgy_name": "Gabut Sur",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803013",
      "brgy_name": "Garreta (Pob.)",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803016",
      "brgy_name": "Labut",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803017",
      "brgy_name": "Lacuben",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803018",
      "brgy_name": "Lubigan",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803020",
      "brgy_name": "Mabusag Norte",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803021",
      "brgy_name": "Mabusag Sur",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803022",
      "brgy_name": "Madupayas",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803023",
      "brgy_name": "Morong",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803025",
      "brgy_name": "Nagrebcan",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803026",
      "brgy_name": "Napu",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803027",
      "brgy_name": "La Virgen Milagrosa (Paguetpet)",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803028",
      "brgy_name": "Pagsanahan Norte",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803029",
      "brgy_name": "Pagsanahan Sur",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803030",
      "brgy_name": "Paltit",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803031",
      "brgy_name": "Parang",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803032",
      "brgy_name": "Pasuc",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803034",
      "brgy_name": "Santa Cruz Norte",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803035",
      "brgy_name": "Santa Cruz Sur",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803036",
      "brgy_name": "Saud",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012803037",
      "brgy_name": "Turod",
      "city_code": "012803",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804001",
      "brgy_name": "Abaca",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804002",
      "brgy_name": "Bacsil",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804003",
      "brgy_name": "Banban",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804004",
      "brgy_name": "Baruyen",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804005",
      "brgy_name": "Dadaor",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804006",
      "brgy_name": "Lanao",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804007",
      "brgy_name": "Malasin",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804008",
      "brgy_name": "Manayon",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804009",
      "brgy_name": "Masikil",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804010",
      "brgy_name": "Nagbalagan",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804011",
      "brgy_name": "Payac",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804012",
      "brgy_name": "San Lorenzo (Pob.)",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804014",
      "brgy_name": "Taguiporo",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012804015",
      "brgy_name": "Utol",
      "city_code": "012804",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805001",
      "brgy_name": "Aglipay (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805002",
      "brgy_name": "Baay",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805003",
      "brgy_name": "Baligat",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805004",
      "brgy_name": "Bungon",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805005",
      "brgy_name": "Baoa East",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805006",
      "brgy_name": "Baoa West",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805007",
      "brgy_name": "Barani (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805009",
      "brgy_name": "Ben-agan (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805010",
      "brgy_name": "Bil-loca",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805011",
      "brgy_name": "Biningan",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805012",
      "brgy_name": "Callaguip (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805013",
      "brgy_name": "Camandingan",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805014",
      "brgy_name": "Camguidan",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805015",
      "brgy_name": "Cangrunaan (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805016",
      "brgy_name": "Capacuan",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805017",
      "brgy_name": "Caunayan (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805018",
      "brgy_name": "Valdez Pob. (Caoayan)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805019",
      "brgy_name": "Colo",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805020",
      "brgy_name": "Pimentel (Cubol)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805021",
      "brgy_name": "Dariwdiw",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805022",
      "brgy_name": "Acosta Pob. (Iloilo)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805023",
      "brgy_name": "Ablan Pob. (Labucao)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805024",
      "brgy_name": "Lacub (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805025",
      "brgy_name": "Mabaleng",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805026",
      "brgy_name": "Magnuang",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805027",
      "brgy_name": "Maipalig",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805028",
      "brgy_name": "Nagbacalan",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805029",
      "brgy_name": "Naguirangan",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805030",
      "brgy_name": "Ricarte Pob. (Nalasin)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805031",
      "brgy_name": "Palongpong",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805032",
      "brgy_name": "Palpalicong (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805033",
      "brgy_name": "Parangopong",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805034",
      "brgy_name": "Payao",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805035",
      "brgy_name": "Quiling Norte",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805036",
      "brgy_name": "Quiling Sur",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805037",
      "brgy_name": "Quiom",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805038",
      "brgy_name": "Rayuray",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805039",
      "brgy_name": "San Julian (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805040",
      "brgy_name": "San Mateo",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805041",
      "brgy_name": "San Pedro",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805042",
      "brgy_name": "Suabit (Pob.)",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805043",
      "brgy_name": "Sumader",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012805044",
      "brgy_name": "Tabug",
      "city_code": "012805",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806001",
      "brgy_name": "Ablan Sarat",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806002",
      "brgy_name": "Agaga",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806005",
      "brgy_name": "Bayog",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806006",
      "brgy_name": "Bobon",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806007",
      "brgy_name": "Buduan (Malituek)",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806008",
      "brgy_name": "Nagsurot",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806009",
      "brgy_name": "Paayas",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806010",
      "brgy_name": "Pagali",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806011",
      "brgy_name": "Poblacion",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806013",
      "brgy_name": "Saoit",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012806014",
      "brgy_name": "Tanap",
      "city_code": "012806",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012807001",
      "brgy_name": "Angset",
      "city_code": "012807",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012807003",
      "brgy_name": "Barbaqueso (Pob.)",
      "city_code": "012807",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012807004",
      "brgy_name": "Virbira",
      "city_code": "012807",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808001",
      "brgy_name": "Anggapang Norte",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808002",
      "brgy_name": "Anggapang Sur",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808003",
      "brgy_name": "Bimmanga",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808004",
      "brgy_name": "Cabuusan",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808005",
      "brgy_name": "Comcomloong",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808006",
      "brgy_name": "Gaang",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808007",
      "brgy_name": "Lang-ayan-Baramban",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808008",
      "brgy_name": "Lioes",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808009",
      "brgy_name": "Maglaoi Centro",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808010",
      "brgy_name": "Maglaoi Norte",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808011",
      "brgy_name": "Maglaoi Sur",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808013",
      "brgy_name": "Paguludan-Salindeg",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808014",
      "brgy_name": "Pangil",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808015",
      "brgy_name": "Pias Norte",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808016",
      "brgy_name": "Pias Sur",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808017",
      "brgy_name": "Poblacion I",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808018",
      "brgy_name": "Poblacion II",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808019",
      "brgy_name": "Salugan",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808020",
      "brgy_name": "San Simeon",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808021",
      "brgy_name": "Santa Cruz",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808022",
      "brgy_name": "Tapao-Tigue",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808023",
      "brgy_name": "Torre",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012808024",
      "brgy_name": "Victoria",
      "city_code": "012808",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809001",
      "brgy_name": "Albano (Pob.)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809002",
      "brgy_name": "Bacsil",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809003",
      "brgy_name": "Bagut",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809004",
      "brgy_name": "Parado (Bangay)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809005",
      "brgy_name": "Baresbes",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809006",
      "brgy_name": "Barong",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809007",
      "brgy_name": "Bungcag",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809009",
      "brgy_name": "Cali",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809010",
      "brgy_name": "Capasan",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809011",
      "brgy_name": "Dancel (Pob.)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809012",
      "brgy_name": "Foz",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809013",
      "brgy_name": "Guerrero (Pob.)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809014",
      "brgy_name": "Lanas",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809015",
      "brgy_name": "Lumbad",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809016",
      "brgy_name": "Madamba (Pob.)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809017",
      "brgy_name": "Mandaloque",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809018",
      "brgy_name": "Medina",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809019",
      "brgy_name": "Ver (Naglayaan)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809020",
      "brgy_name": "San Marcelino (Padong)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809021",
      "brgy_name": "Puruganan (Pob.)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809022",
      "brgy_name": "Peralta (Pob.)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809023",
      "brgy_name": "Root (Baldias)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809024",
      "brgy_name": "Sagpatan",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809025",
      "brgy_name": "Saludares",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809026",
      "brgy_name": "San Esteban",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809027",
      "brgy_name": "Espiritu",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809028",
      "brgy_name": "Sulquiano (Sidiran)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809029",
      "brgy_name": "San Francisco (Surrate)",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809030",
      "brgy_name": "Suyo",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809031",
      "brgy_name": "San Marcos",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012809032",
      "brgy_name": "Elizabeth",
      "city_code": "012809",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012810001",
      "brgy_name": "Dumalneg",
      "city_code": "012810",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012810002",
      "brgy_name": "San Isidro",
      "city_code": "012810",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811001",
      "brgy_name": "Balioeg",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811002",
      "brgy_name": "Bangsar",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811003",
      "brgy_name": "Barbarangay",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811004",
      "brgy_name": "Bomitog",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811005",
      "brgy_name": "Bugasi",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811006",
      "brgy_name": "Caestebanan",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811008",
      "brgy_name": "Caribquib",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811009",
      "brgy_name": "Catagtaguen",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811011",
      "brgy_name": "Crispina",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811012",
      "brgy_name": "Hilario (Pob.)",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811013",
      "brgy_name": "Imelda",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811014",
      "brgy_name": "Lorenzo (Pob.)",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811015",
      "brgy_name": "Macayepyep",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811016",
      "brgy_name": "Marcos (Pob.)",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811017",
      "brgy_name": "Nagpatayan",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811018",
      "brgy_name": "Valdez",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811019",
      "brgy_name": "Sinamar",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811020",
      "brgy_name": "Tabtabagan",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811021",
      "brgy_name": "Valenciano (Pob.)",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012811022",
      "brgy_name": "Binacag",
      "city_code": "012811",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812001",
      "brgy_name": "Bgy. No. 42, Apaya",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812002",
      "brgy_name": "Bgy. No. 36, Araniw",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812003",
      "brgy_name": "Bgy. No. 56-A, Bacsil North",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812004",
      "brgy_name": "Bgy. No. 56-B, Bacsil South",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812005",
      "brgy_name": "Bgy. No. 41, Balacad",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812006",
      "brgy_name": "Bgy. No. 40, Balatong",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812007",
      "brgy_name": "Bgy. No. 55-A, Barit-Pandan",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812008",
      "brgy_name": "Bgy. No. 47, Bengcag",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812009",
      "brgy_name": "Bgy. No. 50, Buttong",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812010",
      "brgy_name": "Bgy. No. 60-A, Caaoacan",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812011",
      "brgy_name": "Bry. No. 48-A, Cabungaan North",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812012",
      "brgy_name": "Bgy. No. 48-B, Cabungaan South",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812013",
      "brgy_name": "Bgy. No. 37, Calayab",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812014",
      "brgy_name": "Bgy. No. 54-B, Camangaan",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812015",
      "brgy_name": "Bgy. No. 58, Casili",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812016",
      "brgy_name": "Bgy. No. 61, Cataban",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812017",
      "brgy_name": "Bgy. No. 43, Cavit",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812019",
      "brgy_name": "Bgy. No. 49-A, Darayday",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812020",
      "brgy_name": "Bgy. No. 59-B, Dibua North",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812021",
      "brgy_name": "Bgy. No. 59-A, Dibua South",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812022",
      "brgy_name": "Bgy. No. 34-B, Gabu Norte East",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812023",
      "brgy_name": "Bgy. No. 34-A, Gabu Norte West",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812024",
      "brgy_name": "Bgy. No. 35, Gabu Sur",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812026",
      "brgy_name": "Bgy. No. 32-C La Paz East",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812027",
      "brgy_name": "Bgy. No. 33-B, La Paz Proper",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812028",
      "brgy_name": "Bgy. No. 32-B, La Paz West",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812029",
      "brgy_name": "Bgy. No. 54-A, Lagui-Sail",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812030",
      "brgy_name": "Bgy. No. 32-A, La Paz East",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812031",
      "brgy_name": "Bgy. No. 33-A, La Paz Proper",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812032",
      "brgy_name": "Bgy. No. 52-B, Lataag",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812033",
      "brgy_name": "Bgy. No. 60-B, Madiladig",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812034",
      "brgy_name": "Bgy. No. 38-A, Mangato East",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812035",
      "brgy_name": "Bgy. No. 38-B, Mangato West",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812036",
      "brgy_name": "Bgy. No. 62-A, Navotas North",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812037",
      "brgy_name": "Bgy. No. 62-B, Navotas South",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812038",
      "brgy_name": "Bgy. No. 46, Nalbo",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812039",
      "brgy_name": "Bgy. No. 51-A, Nangalisan East",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812040",
      "brgy_name": "Bgy. No. 51-B, Nangalisan West",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812041",
      "brgy_name": "Bgy. No. 24, Nstra. Sra. De Consolacion (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812042",
      "brgy_name": "Bgy. No. 7-A, Nstra. Sra. De Natividad (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812043",
      "brgy_name": "Bgy. No. 7-B, Nstra. Sra. De Natividad (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812044",
      "brgy_name": "Bgy. No. 27, Nstra. Sra. De Soledad (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812045",
      "brgy_name": "Bgy. No. 13, Nstra. Sra. De Visitacion (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812046",
      "brgy_name": "Bgy. No. 3, Nstra. Sra. Del Rosario (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812047",
      "brgy_name": "Bgy. No. 57, Pila",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812048",
      "brgy_name": "Bgy. No. 49-B, Raraburan",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812049",
      "brgy_name": "Bgy. No. 53, Rioeng",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812050",
      "brgy_name": "Bgy. No. 55-B, Salet-Bulangon",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812053",
      "brgy_name": "Bgy. No. 6, San Agustin (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812054",
      "brgy_name": "Bgy. No. 22, San Andres (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812055",
      "brgy_name": "Bgy. No. 28, San Bernardo (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812056",
      "brgy_name": "Bgy. No. 17, San Francisco (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812057",
      "brgy_name": "Bgy. No. 4, San Guillermo (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812058",
      "brgy_name": "Bgy. No. 15, San Guillermo (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812059",
      "brgy_name": "Bgy. No. 12, San Isidro (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812060",
      "brgy_name": "Bgy. No. 16, San Jacinto (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812061",
      "brgy_name": "Bgy. No. 10, San Jose (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812062",
      "brgy_name": "Bgy. No. 1, San Lorenzo (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812063",
      "brgy_name": "Bgy. No. 26, San Marcelino (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812064",
      "brgy_name": "Bgy. No. 52-A, San Mateo",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812065",
      "brgy_name": "Bgy. No. 23, San Matias (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812066",
      "brgy_name": "Bgy. No. 20, San Miguel (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812067",
      "brgy_name": "Bgy. No. 21, San Pedro (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812068",
      "brgy_name": "Bgy. No. 5, San Pedro (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812069",
      "brgy_name": "Bry. No. 18, San Quirino (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812070",
      "brgy_name": "Bgy. No. 8, San Vicente (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812071",
      "brgy_name": "Bgy. No. 9, Santa Angela (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812072",
      "brgy_name": "Bgy. No. 11, Santa Balbina (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812073",
      "brgy_name": "Bgy. No. 25, Santa Cayetana (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812074",
      "brgy_name": "Bgy. No. 2, Santa Joaquina (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812075",
      "brgy_name": "Bgy. No. 19, Santa Marcela (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812076",
      "brgy_name": "Bgy. No. 30-B, Santa Maria",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812077",
      "brgy_name": "Bgy. No. 39, Santa Rosa",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812078",
      "brgy_name": "Bgy. No. 14, Santo Tomas (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812079",
      "brgy_name": "Bgy. No. 29, Santo Tomas (Pob.)",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812080",
      "brgy_name": "Bgy. No. 30-A, Suyo",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812081",
      "brgy_name": "Bgy. No. 31, Talingaan",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812082",
      "brgy_name": "Bgy. No. 45, Tangid",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812083",
      "brgy_name": "Bgy. No. 55-C, Vira",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012812084",
      "brgy_name": "Bgy. No. 44, Zamboanga",
      "city_code": "012812",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813001",
      "brgy_name": "Pacifico (Agunit)",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813003",
      "brgy_name": "Imelda (Capariaan)",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813004",
      "brgy_name": "Elizabeth (Culao)",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813005",
      "brgy_name": "Daquioag",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813006",
      "brgy_name": "Escoda",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813007",
      "brgy_name": "Ferdinand",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813008",
      "brgy_name": "Fortuna",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813009",
      "brgy_name": "Lydia (Pob.)",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813010",
      "brgy_name": "Mabuti",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813011",
      "brgy_name": "Valdez (Biding)",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813012",
      "brgy_name": "Tabucbuc (Ragas)",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813013",
      "brgy_name": "Santiago",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012813014",
      "brgy_name": "Cacafean",
      "city_code": "012813",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814001",
      "brgy_name": "Acnam",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814002",
      "brgy_name": "Barangobong",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814003",
      "brgy_name": "Barikir",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814004",
      "brgy_name": "Bugayong",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814005",
      "brgy_name": "Cabittauran",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814006",
      "brgy_name": "Caray",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814007",
      "brgy_name": "Garnaden",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814008",
      "brgy_name": "Naguillan (Pagpag-ong)",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814009",
      "brgy_name": "Poblacion",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814010",
      "brgy_name": "Santo Niño",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012814011",
      "brgy_name": "Uguis",
      "city_code": "012814",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815001",
      "brgy_name": "Aggasi",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815003",
      "brgy_name": "Baduang",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815004",
      "brgy_name": "Balaoi",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815005",
      "brgy_name": "Burayoc",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815006",
      "brgy_name": "Caunayan",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815007",
      "brgy_name": "Dampig",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815008",
      "brgy_name": "Ligaya",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815010",
      "brgy_name": "Pancian",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815011",
      "brgy_name": "Pasaleng",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815012",
      "brgy_name": "Poblacion 1",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815013",
      "brgy_name": "Poblacion 2",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815014",
      "brgy_name": "Saguigui",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815015",
      "brgy_name": "Saud",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815016",
      "brgy_name": "Subec",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815017",
      "brgy_name": "Tarrag",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012815018",
      "brgy_name": "Caparispisan",
      "city_code": "012815",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816001",
      "brgy_name": "Bacsil",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816002",
      "brgy_name": "Cabagoan",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816003",
      "brgy_name": "Cabangaran",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816004",
      "brgy_name": "Callaguip",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816005",
      "brgy_name": "Cayubog",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816006",
      "brgy_name": "Dolores",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816007",
      "brgy_name": "Laoa",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816008",
      "brgy_name": "Masintoc",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816009",
      "brgy_name": "Monte",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816010",
      "brgy_name": "Mumulaan",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816011",
      "brgy_name": "Nagbacalan",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816012",
      "brgy_name": "Nalasin",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816013",
      "brgy_name": "Nanguyudan",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816014",
      "brgy_name": "Oaig-Upay-Abulao",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816015",
      "brgy_name": "Pambaran",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816016",
      "brgy_name": "Pannaratan (Pob.)",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816017",
      "brgy_name": "Paratong",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816018",
      "brgy_name": "Pasil",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816019",
      "brgy_name": "Salbang (Pob.)",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816020",
      "brgy_name": "San Agustin",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816021",
      "brgy_name": "San Blas (Pob.)",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816022",
      "brgy_name": "San Juan",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816025",
      "brgy_name": "San Pedro",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816028",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816029",
      "brgy_name": "Sangladan Pob. (Nalbuan)",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816030",
      "brgy_name": "Santa Rita (Pob.)",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816031",
      "brgy_name": "Sideg",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816032",
      "brgy_name": "Suba",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816033",
      "brgy_name": "Sungadan",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816034",
      "brgy_name": "Surgui",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012816035",
      "brgy_name": "Veronica",
      "city_code": "012816",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817001",
      "brgy_name": "Batuli",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817002",
      "brgy_name": "Binsang",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817003",
      "brgy_name": "Nalvo (Cababaan-Nalvo)",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817004",
      "brgy_name": "Caruan",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817005",
      "brgy_name": "Carusikis",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817006",
      "brgy_name": "Carusipan",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817007",
      "brgy_name": "Dadaeman",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817008",
      "brgy_name": "Darupidip",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817009",
      "brgy_name": "Davila",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817010",
      "brgy_name": "Dilanis",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817011",
      "brgy_name": "Dilavo",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817012",
      "brgy_name": "Estancia",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817014",
      "brgy_name": "Naglicuan",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817015",
      "brgy_name": "Nagsanga",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817016",
      "brgy_name": "Ngabangab",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817017",
      "brgy_name": "Pangil",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817018",
      "brgy_name": "Poblacion 1",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817019",
      "brgy_name": "Poblacion 2",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817020",
      "brgy_name": "Poblacion 3",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817021",
      "brgy_name": "Poblacion 4",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817022",
      "brgy_name": "Pragata (Pragata-Bungro)",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817023",
      "brgy_name": "Puyupuyan",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817024",
      "brgy_name": "Sulongan",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817025",
      "brgy_name": "Salpad",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817026",
      "brgy_name": "San Juan",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817027",
      "brgy_name": "Santa Catalina",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817028",
      "brgy_name": "Santa Matilde",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817029",
      "brgy_name": "Sapat",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817030",
      "brgy_name": "Sulbec",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817031",
      "brgy_name": "Surong",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817032",
      "brgy_name": "Susugaen",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817033",
      "brgy_name": "Tabungao",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012817034",
      "brgy_name": "Tadao",
      "city_code": "012817",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818001",
      "brgy_name": "Ab-abut",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818002",
      "brgy_name": "Abucay",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818003",
      "brgy_name": "Anao (Pob.)",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818004",
      "brgy_name": "Arua-ay",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818005",
      "brgy_name": "Bimmanga",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818006",
      "brgy_name": "Boyboy",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818007",
      "brgy_name": "Cabaroan (Pob.)",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818008",
      "brgy_name": "Calambeg",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818009",
      "brgy_name": "Callusa",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818010",
      "brgy_name": "Dupitac",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818011",
      "brgy_name": "Estancia",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818012",
      "brgy_name": "Gayamat",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818013",
      "brgy_name": "Lagandit",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818014",
      "brgy_name": "Libnaoan",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818015",
      "brgy_name": "Loing (Pob.)",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818016",
      "brgy_name": "Maab-abaca",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818017",
      "brgy_name": "Mangitayag",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818018",
      "brgy_name": "Maruaya",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818019",
      "brgy_name": "San Antonio",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818020",
      "brgy_name": "Santa Maria",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818021",
      "brgy_name": "Sucsuquen",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818022",
      "brgy_name": "Tangaoan",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012818023",
      "brgy_name": "Tonoton",
      "city_code": "012818",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819001",
      "brgy_name": "Aglipay",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819002",
      "brgy_name": "Apatut-Lubong",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819003",
      "brgy_name": "Badio",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819004",
      "brgy_name": "Barbar",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819005",
      "brgy_name": "Buanga",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819006",
      "brgy_name": "Bulbulala",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819007",
      "brgy_name": "Bungro",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819008",
      "brgy_name": "Cabaroan",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819009",
      "brgy_name": "Capangdanan",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819010",
      "brgy_name": "Dalayap",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819011",
      "brgy_name": "Darat",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819012",
      "brgy_name": "Gulpeng",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819013",
      "brgy_name": "Liliputen",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819014",
      "brgy_name": "Lumbaan-Bicbica",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819015",
      "brgy_name": "Nagtrigoan",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819016",
      "brgy_name": "Pagdilao (Pob.)",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819017",
      "brgy_name": "Pugaoan",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819018",
      "brgy_name": "Puritac",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819019",
      "brgy_name": "Sacritan",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819020",
      "brgy_name": "Salanap",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819021",
      "brgy_name": "Santo Tomas",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819022",
      "brgy_name": "Tartarabang",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819023",
      "brgy_name": "Puzol",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819024",
      "brgy_name": "Upon",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012819025",
      "brgy_name": "Valbuena (Pob.)",
      "city_code": "012819",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820001",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820002",
      "brgy_name": "San Ildefonso (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820003",
      "brgy_name": "San Agustin",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820004",
      "brgy_name": "San Baltazar (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820005",
      "brgy_name": "San Bartolome (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820006",
      "brgy_name": "San Cayetano (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820007",
      "brgy_name": "San Eugenio (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820008",
      "brgy_name": "San Fernando (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820009",
      "brgy_name": "San Gregorio (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820010",
      "brgy_name": "San Guillermo",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820011",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820012",
      "brgy_name": "San Juan Bautista (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820013",
      "brgy_name": "San Lorenzo",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820014",
      "brgy_name": "San Lucas (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820015",
      "brgy_name": "San Marcos (Payas)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820016",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820017",
      "brgy_name": "San Pablo",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820018",
      "brgy_name": "San Paulo (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820019",
      "brgy_name": "San Pedro (Bingao)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820020",
      "brgy_name": "San Rufino (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820021",
      "brgy_name": "San Silvestre (Pob.)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820022",
      "brgy_name": "Santa Asuncion (Samac)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820023",
      "brgy_name": "Santa Cecilia (Barabar)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012820024",
      "brgy_name": "Santa Monica (Nagrebcan)",
      "city_code": "012820",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821001",
      "brgy_name": "San Agustin (Pob.)",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821002",
      "brgy_name": "San Andres",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821003",
      "brgy_name": "San Antonio",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821004",
      "brgy_name": "San Bernabe",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821005",
      "brgy_name": "San Cristobal",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821006",
      "brgy_name": "San Felipe",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821007",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821008",
      "brgy_name": "San Isidro",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821009",
      "brgy_name": "San Joaquin (Pob.)",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821010",
      "brgy_name": "San Jose",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821011",
      "brgy_name": "San Juan",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821012",
      "brgy_name": "San Leandro (Pob.)",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821014",
      "brgy_name": "San Lorenzo",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821015",
      "brgy_name": "San Manuel",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821016",
      "brgy_name": "San Marcos",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821017",
      "brgy_name": "San Nicolas",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821018",
      "brgy_name": "San Pedro",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821019",
      "brgy_name": "San Roque",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821020",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821021",
      "brgy_name": "Santa Barbara (Pob.)",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821022",
      "brgy_name": "Santa Magdalena",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821023",
      "brgy_name": "Santa Rosa",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821024",
      "brgy_name": "Santo Santiago",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012821025",
      "brgy_name": "Santo Tomas",
      "city_code": "012821",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822001",
      "brgy_name": "Aguitap",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822002",
      "brgy_name": "Bagbag",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822003",
      "brgy_name": "Bagbago",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822004",
      "brgy_name": "Barcelona",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822005",
      "brgy_name": "Bubuos",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822006",
      "brgy_name": "Capurictan",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822007",
      "brgy_name": "Catangraran",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822008",
      "brgy_name": "Darasdas",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822009",
      "brgy_name": "Juan (Pob.)",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822010",
      "brgy_name": "Laureta (Pob.)",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822011",
      "brgy_name": "Lipay",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822012",
      "brgy_name": "Maananteng",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822013",
      "brgy_name": "Manalpac",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822014",
      "brgy_name": "Mariquet",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822015",
      "brgy_name": "Nagpatpatan",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822016",
      "brgy_name": "Nalasin",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822017",
      "brgy_name": "Puttao",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822018",
      "brgy_name": "San Juan",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822019",
      "brgy_name": "San Julian",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822020",
      "brgy_name": "Santa Ana",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822021",
      "brgy_name": "Santiago",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012822022",
      "brgy_name": "Talugtog",
      "city_code": "012822",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823001",
      "brgy_name": "Abkir",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823003",
      "brgy_name": "Alsem",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823005",
      "brgy_name": "Bago",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823010",
      "brgy_name": "Bulbulala",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823011",
      "brgy_name": "Cabangaran",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823012",
      "brgy_name": "Cabayo",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823013",
      "brgy_name": "Cabisocolan",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823014",
      "brgy_name": "Canaam",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823015",
      "brgy_name": "Columbia",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823016",
      "brgy_name": "Dagupan",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823017",
      "brgy_name": "Pedro F. Alviar (Diaton)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823019",
      "brgy_name": "Dipilat",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823022",
      "brgy_name": "Esperanza",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823023",
      "brgy_name": "Ester",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823024",
      "brgy_name": "Isic Isic",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823027",
      "brgy_name": "Lubnac",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823028",
      "brgy_name": "Mabanbanag",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823030",
      "brgy_name": "Alejo Malasig",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823031",
      "brgy_name": "Manarang",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823033",
      "brgy_name": "Margaay",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823035",
      "brgy_name": "Namoroc",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823038",
      "brgy_name": "Malampa (Peninaan-Malampa)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823039",
      "brgy_name": "Parparoroc",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823040",
      "brgy_name": "Parut",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823047",
      "brgy_name": "Salsalamagui",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823049",
      "brgy_name": "San Jose (Lipay)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823050",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823051",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823052",
      "brgy_name": "San Ramon (Pob.)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823053",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823054",
      "brgy_name": "Santa Maria (Pob.)",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823056",
      "brgy_name": "Tamdagan",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012823058",
      "brgy_name": "Visaya",
      "city_code": "012823",
      "province_code": "0128",
      "region_code": "01"
    },
    {
      "brgy_code": "012901001",
      "brgy_name": "Alilem Daya (Pob.)",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901002",
      "brgy_name": "Amilongan",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901003",
      "brgy_name": "Anaao",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901004",
      "brgy_name": "Apang",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901005",
      "brgy_name": "Apaya",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901006",
      "brgy_name": "Batbato",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901010",
      "brgy_name": "Daddaay",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901011",
      "brgy_name": "Dalawa",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012901013",
      "brgy_name": "Kiat",
      "city_code": "012901",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902001",
      "brgy_name": "Bagbagotot",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902002",
      "brgy_name": "Banbanaal",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902004",
      "brgy_name": "Bisangol",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902005",
      "brgy_name": "Cadanglaan",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902006",
      "brgy_name": "Casilagan Norte",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902007",
      "brgy_name": "Casilagan Sur",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902008",
      "brgy_name": "Elefante",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902009",
      "brgy_name": "Guardia",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902010",
      "brgy_name": "Lintic",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902011",
      "brgy_name": "Lopez",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902012",
      "brgy_name": "Montero",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902013",
      "brgy_name": "Naguimba",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902014",
      "brgy_name": "Pila",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012902015",
      "brgy_name": "Poblacion",
      "city_code": "012902",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903001",
      "brgy_name": "Aggay",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903002",
      "brgy_name": "An-annam",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903003",
      "brgy_name": "Balaleng",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903004",
      "brgy_name": "Banaoang",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903005",
      "brgy_name": "Bulag",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903006",
      "brgy_name": "Buquig",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903007",
      "brgy_name": "Cabalanggan",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903008",
      "brgy_name": "Cabaroan",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903009",
      "brgy_name": "Cabusligan",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903010",
      "brgy_name": "Capangdanan",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903011",
      "brgy_name": "Guimod",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903012",
      "brgy_name": "Lingsat",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903013",
      "brgy_name": "Malingeb",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903014",
      "brgy_name": "Mira",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903015",
      "brgy_name": "Naguiddayan",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903016",
      "brgy_name": "Ora",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903017",
      "brgy_name": "Paing",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903018",
      "brgy_name": "Puspus",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903019",
      "brgy_name": "Quimmarayan",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903020",
      "brgy_name": "Sagneb",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903021",
      "brgy_name": "Sagpat",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903022",
      "brgy_name": "San Mariano (Sallacong)",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903023",
      "brgy_name": "San Isidro",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903024",
      "brgy_name": "San Julian",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903026",
      "brgy_name": "Sinabaan",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903027",
      "brgy_name": "Taguiporo",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903028",
      "brgy_name": "Taleb",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903029",
      "brgy_name": "Tay-ac",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903030",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903031",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903032",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903033",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903034",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012903035",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "012903",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904001",
      "brgy_name": "Ambugat",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904002",
      "brgy_name": "Balugang",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904003",
      "brgy_name": "Bangbangar",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904005",
      "brgy_name": "Bessang",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904006",
      "brgy_name": "Cabcaburao",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904007",
      "brgy_name": "Cadacad",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904008",
      "brgy_name": "Callitong",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904009",
      "brgy_name": "Dayanki",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904010",
      "brgy_name": "Lesseb",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904011",
      "brgy_name": "Lubing",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904012",
      "brgy_name": "Lucaban",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904013",
      "brgy_name": "Luna",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904014",
      "brgy_name": "Macaoayan",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904015",
      "brgy_name": "Mambug",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904016",
      "brgy_name": "Manaboc",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904017",
      "brgy_name": "Mapanit",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904018",
      "brgy_name": "Poblacion Sur (Masingit)",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904019",
      "brgy_name": "Nagpanaoan",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904020",
      "brgy_name": "Dirdirig (Dirdirig-Paday)",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904022",
      "brgy_name": "Paduros",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904023",
      "brgy_name": "Patac",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904024",
      "brgy_name": "Poblacion Norte (Bato)",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904026",
      "brgy_name": "Sabangan Pinggan",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904027",
      "brgy_name": "Subadi Norte",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904028",
      "brgy_name": "Subadi Sur",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012904029",
      "brgy_name": "Taliao",
      "city_code": "012904",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905001",
      "brgy_name": "Alinaay",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905002",
      "brgy_name": "Aragan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905003",
      "brgy_name": "Arnap",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905004",
      "brgy_name": "Baclig (Pob.)",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905005",
      "brgy_name": "Bato",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905006",
      "brgy_name": "Bonifacio (Pob.)",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905007",
      "brgy_name": "Bungro",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905008",
      "brgy_name": "Cacadiran",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905009",
      "brgy_name": "Caellayan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905010",
      "brgy_name": "Carusipan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905011",
      "brgy_name": "Catucdaan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905012",
      "brgy_name": "Cuancabal",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905013",
      "brgy_name": "Cuantacla",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905014",
      "brgy_name": "Daclapan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905015",
      "brgy_name": "Dardarat",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905016",
      "brgy_name": "Lipit",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905017",
      "brgy_name": "Maradodon",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905018",
      "brgy_name": "Margaay",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905019",
      "brgy_name": "Nagsantaan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905020",
      "brgy_name": "Nagsincaoan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905021",
      "brgy_name": "Namruangan",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905022",
      "brgy_name": "Pila",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905023",
      "brgy_name": "Pug-os",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905024",
      "brgy_name": "Quezon (Pob.)",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905025",
      "brgy_name": "Reppaac",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905026",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905027",
      "brgy_name": "Sabang",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905028",
      "brgy_name": "Sagayaden",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905029",
      "brgy_name": "Salapasap",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905030",
      "brgy_name": "Salomague",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905031",
      "brgy_name": "Sisim",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905032",
      "brgy_name": "Turod",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012905033",
      "brgy_name": "Turod-Patac",
      "city_code": "012905",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906001",
      "brgy_name": "Allangigan Primero",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906002",
      "brgy_name": "Allangigan Segundo",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906003",
      "brgy_name": "Amguid",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906004",
      "brgy_name": "Ayudante",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906005",
      "brgy_name": "Bagani Camposanto",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906006",
      "brgy_name": "Bagani Gabor",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906007",
      "brgy_name": "Bagani Tocgo",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906008",
      "brgy_name": "Bagani Ubbog",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906009",
      "brgy_name": "Bagar",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906010",
      "brgy_name": "Balingaoan",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906015",
      "brgy_name": "Bugnay",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906016",
      "brgy_name": "Calaoaan",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906017",
      "brgy_name": "Calongbuyan",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906018",
      "brgy_name": "Caterman",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906019",
      "brgy_name": "Cubcubboot",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906020",
      "brgy_name": "Darapidap",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906022",
      "brgy_name": "Langlangca Primero",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906023",
      "brgy_name": "Langlangca Segundo",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906024",
      "brgy_name": "Oaig-Daya",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906025",
      "brgy_name": "Palacapac",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906026",
      "brgy_name": "Paras",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906027",
      "brgy_name": "Parioc Primero",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906028",
      "brgy_name": "Parioc Segundo",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906029",
      "brgy_name": "Patpata Primero",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906030",
      "brgy_name": "Patpata Segundo",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906031",
      "brgy_name": "Paypayad",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906032",
      "brgy_name": "Salvador Primero",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906033",
      "brgy_name": "Salvador Segundo",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906034",
      "brgy_name": "San Agustin",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906035",
      "brgy_name": "San Andres",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906036",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906037",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906038",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906039",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906040",
      "brgy_name": "San Nicolas",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906041",
      "brgy_name": "San Pedro",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906042",
      "brgy_name": "Santo Tomas",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906043",
      "brgy_name": "Tablac",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906044",
      "brgy_name": "Talogtog",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906045",
      "brgy_name": "Tamurong Primero",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906046",
      "brgy_name": "Tamurong Segundo",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012906048",
      "brgy_name": "Villarica",
      "city_code": "012906",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907001",
      "brgy_name": "Anonang Mayor",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907002",
      "brgy_name": "Anonang Menor",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907003",
      "brgy_name": "Baggoc",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907004",
      "brgy_name": "Callaguip",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907005",
      "brgy_name": "Caparacadan",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907006",
      "brgy_name": "Fuerte",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907007",
      "brgy_name": "Manangat",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907008",
      "brgy_name": "Naguilian",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907009",
      "brgy_name": "Nansuagao",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907010",
      "brgy_name": "Pandan",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907011",
      "brgy_name": "Pantay-Quitiquit",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907012",
      "brgy_name": "Don Dimas Querubin (Pob.)",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907013",
      "brgy_name": "Puro",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907014",
      "brgy_name": "Pantay Tamurong",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907015",
      "brgy_name": "Villamar",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907016",
      "brgy_name": "Don Alejandro Quirolgico (Pob.)",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012907017",
      "brgy_name": "Don Lorenzo Querubin (Pob.)",
      "city_code": "012907",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908001",
      "brgy_name": "Aluling",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908002",
      "brgy_name": "Comillas North",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908003",
      "brgy_name": "Comillas South",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908004",
      "brgy_name": "Concepcion (Pob.)",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908005",
      "brgy_name": "Dinwede East",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908006",
      "brgy_name": "Dinwede West",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908007",
      "brgy_name": "Libang",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908010",
      "brgy_name": "Pilipil",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908013",
      "brgy_name": "Remedios",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908014",
      "brgy_name": "Rosario (Pob.)",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908015",
      "brgy_name": "San Juan",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908016",
      "brgy_name": "San Luis",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012908017",
      "brgy_name": "Malaya",
      "city_code": "012908",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909001",
      "brgy_name": "Abaya",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909002",
      "brgy_name": "Baracbac",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909003",
      "brgy_name": "Bidbiday",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909004",
      "brgy_name": "Bitong",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909005",
      "brgy_name": "Borobor",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909006",
      "brgy_name": "Calimugtong",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909007",
      "brgy_name": "Calongbuyan",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909008",
      "brgy_name": "Calumbaya",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909009",
      "brgy_name": "Daldagan",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909010",
      "brgy_name": "Kilang",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909011",
      "brgy_name": "Legaspi",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909012",
      "brgy_name": "Mabayag",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909013",
      "brgy_name": "Matanubong",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909014",
      "brgy_name": "Mckinley",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909015",
      "brgy_name": "Nagsingcaoan",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909016",
      "brgy_name": "Oaig-Daya",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909017",
      "brgy_name": "Pagangpang",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909018",
      "brgy_name": "Patac",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909019",
      "brgy_name": "Poblacion",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909020",
      "brgy_name": "Rubio",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909021",
      "brgy_name": "Sabangan-Bato",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909022",
      "brgy_name": "Sacaang",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909023",
      "brgy_name": "San Vicente",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012909024",
      "brgy_name": "Sapang",
      "city_code": "012909",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012910001",
      "brgy_name": "Alfonso (Tangaoan)",
      "city_code": "012910",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012910002",
      "brgy_name": "Bussot",
      "city_code": "012910",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012910003",
      "brgy_name": "Concepcion",
      "city_code": "012910",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012910004",
      "brgy_name": "Dapdappig",
      "city_code": "012910",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012910005",
      "brgy_name": "Matue-Butarag",
      "city_code": "012910",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012910007",
      "brgy_name": "Poblacion Norte",
      "city_code": "012910",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012910008",
      "brgy_name": "Poblacion Sur",
      "city_code": "012910",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911001",
      "brgy_name": "Banucal",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911002",
      "brgy_name": "Bequi-Walin",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911003",
      "brgy_name": "Bugui",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911004",
      "brgy_name": "Calungbuyan",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911005",
      "brgy_name": "Carcarabasa",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911006",
      "brgy_name": "Labut",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911007",
      "brgy_name": "Poblacion Norte",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911008",
      "brgy_name": "Poblacion Sur",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911009",
      "brgy_name": "San Vicente",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911010",
      "brgy_name": "Suysuyan",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012911011",
      "brgy_name": "Tay-ac",
      "city_code": "012911",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912001",
      "brgy_name": "Alangan",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912002",
      "brgy_name": "Bacar",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912006",
      "brgy_name": "Barbarit",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912007",
      "brgy_name": "Bungro",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912008",
      "brgy_name": "Cabaroan",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912010",
      "brgy_name": "Cadanglaan",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912011",
      "brgy_name": "Caraisan",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912014",
      "brgy_name": "Dacutan",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912015",
      "brgy_name": "Labut",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912017",
      "brgy_name": "Maas-asin",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912018",
      "brgy_name": "Macatcatud",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912019",
      "brgy_name": "Namalpalan",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912020",
      "brgy_name": "Manzante",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912022",
      "brgy_name": "Maratudo",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912023",
      "brgy_name": "Miramar",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912025",
      "brgy_name": "Napo",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912026",
      "brgy_name": "Pagsanaan Norte",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912027",
      "brgy_name": "Pagsanaan Sur",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912028",
      "brgy_name": "Panay Norte",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912029",
      "brgy_name": "Panay Sur",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912030",
      "brgy_name": "Patong",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912031",
      "brgy_name": "Puro",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912032",
      "brgy_name": "San Basilio (Pob.)",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912033",
      "brgy_name": "San Clemente (Pob.)",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912034",
      "brgy_name": "San Julian (Pob.)",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912035",
      "brgy_name": "San Lucas (Pob.)",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912036",
      "brgy_name": "San Ramon (Pob.)",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912037",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912038",
      "brgy_name": "Santa Monica",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012912039",
      "brgy_name": "Sarsaracat",
      "city_code": "012912",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913001",
      "brgy_name": "Balaweg",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913002",
      "brgy_name": "Bandril",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913003",
      "brgy_name": "Bantugo",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913004",
      "brgy_name": "Cadacad",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913005",
      "brgy_name": "Casilagan",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913006",
      "brgy_name": "Casocos",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913007",
      "brgy_name": "Lapting",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913008",
      "brgy_name": "Mapisi",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913009",
      "brgy_name": "Mission",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913010",
      "brgy_name": "Poblacion East",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913011",
      "brgy_name": "Poblacion West",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012913012",
      "brgy_name": "Taleb",
      "city_code": "012913",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914001",
      "brgy_name": "Abuor",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914002",
      "brgy_name": "Ambulogan",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914004",
      "brgy_name": "Aquib",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914005",
      "brgy_name": "Banglayan",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914006",
      "brgy_name": "Bulanos",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914007",
      "brgy_name": "Cadacad",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914008",
      "brgy_name": "Cagayungan",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914009",
      "brgy_name": "Camarao",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914010",
      "brgy_name": "Casilagan",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914011",
      "brgy_name": "Codoog",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914012",
      "brgy_name": "Dasay",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914013",
      "brgy_name": "Dinalaoan",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914014",
      "brgy_name": "Estancia",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914015",
      "brgy_name": "Lanipao",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914016",
      "brgy_name": "Lungog",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914017",
      "brgy_name": "Margaay",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914018",
      "brgy_name": "Marozo",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914019",
      "brgy_name": "Naguneg",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914020",
      "brgy_name": "Orence",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914021",
      "brgy_name": "Pantoc",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914022",
      "brgy_name": "Paratong",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914023",
      "brgy_name": "Parparia",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914024",
      "brgy_name": "Quinarayan",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914025",
      "brgy_name": "Rivadavia",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914026",
      "brgy_name": "San Antonio",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914027",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914028",
      "brgy_name": "San Pablo",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914029",
      "brgy_name": "San Pedro",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914030",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914031",
      "brgy_name": "Sarmingan",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914032",
      "brgy_name": "Sucoc",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914033",
      "brgy_name": "Sulvec",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914034",
      "brgy_name": "Turod",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012914035",
      "brgy_name": "Bantay Abot",
      "city_code": "012914",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915001",
      "brgy_name": "Banoen",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915002",
      "brgy_name": "Cayus",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915003",
      "brgy_name": "Patungcaleo (Lamag)",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915004",
      "brgy_name": "Malideg",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915005",
      "brgy_name": "Namitpit",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915006",
      "brgy_name": "Patiacan",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915007",
      "brgy_name": "Legleg (Pob.)",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915008",
      "brgy_name": "Suagayan",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012915009",
      "brgy_name": "Lamag (Tubtuba)",
      "city_code": "012915",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916001",
      "brgy_name": "Atabay",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916002",
      "brgy_name": "Calangcuasan",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916003",
      "brgy_name": "Balidbid",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916004",
      "brgy_name": "Baluarte",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916005",
      "brgy_name": "Baybayading",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916006",
      "brgy_name": "Boguibog",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916007",
      "brgy_name": "Bulala-Leguey",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916008",
      "brgy_name": "Kaliwakiw",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916010",
      "brgy_name": "Culiong",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916011",
      "brgy_name": "Dinaratan",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916012",
      "brgy_name": "Kinmarin",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916013",
      "brgy_name": "Lucbuban",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916014",
      "brgy_name": "Madarang",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916015",
      "brgy_name": "Maligcong",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916016",
      "brgy_name": "Pias",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916017",
      "brgy_name": "Poblacion Norte",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916018",
      "brgy_name": "Poblacion Sur",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916019",
      "brgy_name": "San Gaspar",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916020",
      "brgy_name": "San Tiburcio",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916021",
      "brgy_name": "Sorioan",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012916022",
      "brgy_name": "Ubbog",
      "city_code": "012916",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917001",
      "brgy_name": "Cabaroan (Pob.)",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917002",
      "brgy_name": "Kalumsing",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917003",
      "brgy_name": "Lancuas",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917004",
      "brgy_name": "Matibuey",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917005",
      "brgy_name": "Paltoc",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917006",
      "brgy_name": "Sibsibbu",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917007",
      "brgy_name": "Tiagan",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012917008",
      "brgy_name": "San Miliano",
      "city_code": "012917",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918001",
      "brgy_name": "Ansad",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918002",
      "brgy_name": "Apatot",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918003",
      "brgy_name": "Bateria",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918004",
      "brgy_name": "Cabaroan",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918005",
      "brgy_name": "Cappa-cappa",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918006",
      "brgy_name": "Poblacion",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918007",
      "brgy_name": "San Nicolas",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918008",
      "brgy_name": "San Pablo",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918009",
      "brgy_name": "San Rafael",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012918010",
      "brgy_name": "Villa Quirino",
      "city_code": "012918",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919001",
      "brgy_name": "Arnap",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919002",
      "brgy_name": "Bahet",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919003",
      "brgy_name": "Belen",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919004",
      "brgy_name": "Bungro",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919006",
      "brgy_name": "Busiing Sur",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919007",
      "brgy_name": "Busiing Norte",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919009",
      "brgy_name": "Dongalo",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919010",
      "brgy_name": "Gongogong",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919011",
      "brgy_name": "Iboy",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919013",
      "brgy_name": "Otol-Patac",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919014",
      "brgy_name": "Poblacion East",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919015",
      "brgy_name": "Poblacion West",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919016",
      "brgy_name": "Kinamantirisan",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919017",
      "brgy_name": "Sagneb",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012919018",
      "brgy_name": "Sagsagat",
      "city_code": "012919",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920001",
      "brgy_name": "Bacsil",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920002",
      "brgy_name": "Baliw",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920003",
      "brgy_name": "Bannuar (Pob.)",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920004",
      "brgy_name": "Barbar",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920005",
      "brgy_name": "Cabanglotan",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920006",
      "brgy_name": "Cacandongan",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920007",
      "brgy_name": "Camanggaan",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920008",
      "brgy_name": "Camindoroan",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920009",
      "brgy_name": "Caronoan",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920010",
      "brgy_name": "Darao",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920012",
      "brgy_name": "Dardarat",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920013",
      "brgy_name": "Guimod Norte",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920014",
      "brgy_name": "Guimod Sur",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920015",
      "brgy_name": "Immayos Norte",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920016",
      "brgy_name": "Immayos Sur",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920017",
      "brgy_name": "Labnig",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920018",
      "brgy_name": "Lapting",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920019",
      "brgy_name": "Lira (Pob.)",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920020",
      "brgy_name": "Malamin",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920021",
      "brgy_name": "Muraya",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920022",
      "brgy_name": "Nagsabaran",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920023",
      "brgy_name": "Nagsupotan",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920025",
      "brgy_name": "Pandayan (Pob.)",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920027",
      "brgy_name": "Refaro",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920028",
      "brgy_name": "Resurreccion (Pob.)",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920029",
      "brgy_name": "Sabangan",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920031",
      "brgy_name": "San Isidro",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920035",
      "brgy_name": "Saoang",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920037",
      "brgy_name": "Solotsolot",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920038",
      "brgy_name": "Sunggiam",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920039",
      "brgy_name": "Surngit",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012920040",
      "brgy_name": "Asilang",
      "city_code": "012920",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012921001",
      "brgy_name": "Bantaoay",
      "city_code": "012921",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012921002",
      "brgy_name": "Bayubay Norte",
      "city_code": "012921",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012921003",
      "brgy_name": "Bayubay Sur",
      "city_code": "012921",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012921004",
      "brgy_name": "Lubong",
      "city_code": "012921",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012921005",
      "brgy_name": "Poblacion",
      "city_code": "012921",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012921006",
      "brgy_name": "Pudoc",
      "city_code": "012921",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012921007",
      "brgy_name": "San Sebastian",
      "city_code": "012921",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922001",
      "brgy_name": "Ampandula",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922002",
      "brgy_name": "Banaoang",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922003",
      "brgy_name": "Basug",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922004",
      "brgy_name": "Bucalag",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922005",
      "brgy_name": "Cabangaran",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922006",
      "brgy_name": "Calungboyan",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922008",
      "brgy_name": "Casiber",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922009",
      "brgy_name": "Dammay",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922010",
      "brgy_name": "Labut Norte",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922011",
      "brgy_name": "Labut Sur",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922012",
      "brgy_name": "Mabilbila Sur",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922013",
      "brgy_name": "Mabilbila Norte",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922014",
      "brgy_name": "Magsaysay District (Pob.)",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922015",
      "brgy_name": "Manueva",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922016",
      "brgy_name": "Marcos (Pob.)",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922017",
      "brgy_name": "Nagpanaoan",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922018",
      "brgy_name": "Namalangan",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922019",
      "brgy_name": "Oribi",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922020",
      "brgy_name": "Pasungol",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922022",
      "brgy_name": "Quezon (Pob.)",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922023",
      "brgy_name": "Quirino (Pob.)",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922024",
      "brgy_name": "Rancho",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922025",
      "brgy_name": "Rizal",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922026",
      "brgy_name": "Sacuyya Norte",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922027",
      "brgy_name": "Sacuyya Sur",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012922028",
      "brgy_name": "Tabucolan",
      "city_code": "012922",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923001",
      "brgy_name": "Cabaroan",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923002",
      "brgy_name": "Cabittaogan",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923003",
      "brgy_name": "Cabuloan",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923004",
      "brgy_name": "Pangada",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923005",
      "brgy_name": "Paratong",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923006",
      "brgy_name": "Poblacion",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923007",
      "brgy_name": "Sinabaan",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923008",
      "brgy_name": "Subec",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012923009",
      "brgy_name": "Tamorong",
      "city_code": "012923",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924001",
      "brgy_name": "Amarao",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924002",
      "brgy_name": "Babayoan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924003",
      "brgy_name": "Bacsayan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924004",
      "brgy_name": "Banay",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924005",
      "brgy_name": "Bayugao Este",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924006",
      "brgy_name": "Bayugao Oeste",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924007",
      "brgy_name": "Besalan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924008",
      "brgy_name": "Bugbuga",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924009",
      "brgy_name": "Calaoaan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924010",
      "brgy_name": "Camanggaan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924011",
      "brgy_name": "Candalican",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924012",
      "brgy_name": "Capariaan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924013",
      "brgy_name": "Casilagan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924014",
      "brgy_name": "Coscosnong",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924015",
      "brgy_name": "Daligan",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924016",
      "brgy_name": "Dili",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924017",
      "brgy_name": "Gabor Norte",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924018",
      "brgy_name": "Gabor Sur",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924019",
      "brgy_name": "Lalong",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924020",
      "brgy_name": "Lantag",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924021",
      "brgy_name": "Las-ud",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924022",
      "brgy_name": "Mambog",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924023",
      "brgy_name": "Mantanas",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924024",
      "brgy_name": "Nagtengnga",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924025",
      "brgy_name": "Padaoil",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924026",
      "brgy_name": "Paratong",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924027",
      "brgy_name": "Pattiqui",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924028",
      "brgy_name": "Pidpid",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924029",
      "brgy_name": "Pilar",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924030",
      "brgy_name": "Pinipin",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924031",
      "brgy_name": "Poblacion Este",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924032",
      "brgy_name": "Poblacion Norte",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924033",
      "brgy_name": "Poblacion Weste",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924034",
      "brgy_name": "Poblacion Sur",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924035",
      "brgy_name": "Quinfermin",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924036",
      "brgy_name": "Quinsoriano",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924037",
      "brgy_name": "Sagat",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924038",
      "brgy_name": "San Antonio",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924039",
      "brgy_name": "San Jose",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924040",
      "brgy_name": "San Pedro",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924041",
      "brgy_name": "Saoat",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924042",
      "brgy_name": "Sevilla",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924043",
      "brgy_name": "Sidaoen",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924044",
      "brgy_name": "Suyo",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924045",
      "brgy_name": "Tampugo",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924046",
      "brgy_name": "Turod",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924047",
      "brgy_name": "Villa Garcia",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924048",
      "brgy_name": "Villa Hermosa",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012924049",
      "brgy_name": "Villa Laurencia",
      "city_code": "012924",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925001",
      "brgy_name": "Alincaoeg",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925002",
      "brgy_name": "Angkileng",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925003",
      "brgy_name": "Arangin",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925004",
      "brgy_name": "Ayusan (Pob.)",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925005",
      "brgy_name": "Banbanaba",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925006",
      "brgy_name": "Bao-as",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925007",
      "brgy_name": "Barangobong (Pob.)",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925008",
      "brgy_name": "Buliclic",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925009",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925010",
      "brgy_name": "Cabaritan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925011",
      "brgy_name": "Catayagan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925012",
      "brgy_name": "Conconig East",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925013",
      "brgy_name": "Conconig West",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925014",
      "brgy_name": "Damacuag",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925015",
      "brgy_name": "Lubong",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925016",
      "brgy_name": "Luba",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925017",
      "brgy_name": "Nagrebcan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925018",
      "brgy_name": "Nagtablaan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925019",
      "brgy_name": "Namatican",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925020",
      "brgy_name": "Nangalisan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925021",
      "brgy_name": "Palali Norte",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925022",
      "brgy_name": "Palali Sur",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925023",
      "brgy_name": "Paoc Norte",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925024",
      "brgy_name": "Paoc Sur",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925025",
      "brgy_name": "Paratong",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925026",
      "brgy_name": "Pila East",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925027",
      "brgy_name": "Pila West",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925028",
      "brgy_name": "Quinabalayangan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925029",
      "brgy_name": "Ronda",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925030",
      "brgy_name": "Sabuanan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925031",
      "brgy_name": "San Juan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925032",
      "brgy_name": "San Pedro",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925033",
      "brgy_name": "Sapang",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925034",
      "brgy_name": "Suagayan",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925035",
      "brgy_name": "Vical",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012925036",
      "brgy_name": "Bani",
      "city_code": "012925",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926001",
      "brgy_name": "Ag-agrao",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926002",
      "brgy_name": "Ampuagan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926003",
      "brgy_name": "Baballasioan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926004",
      "brgy_name": "Baliw Daya",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926005",
      "brgy_name": "Baliw Laud",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926006",
      "brgy_name": "Bia-o",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926007",
      "brgy_name": "Butir",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926008",
      "brgy_name": "Cabaroan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926009",
      "brgy_name": "Danuman East",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926010",
      "brgy_name": "Danuman West",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926011",
      "brgy_name": "Dunglayan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926012",
      "brgy_name": "Gusing",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926013",
      "brgy_name": "Langaoan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926014",
      "brgy_name": "Laslasong Norte",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926015",
      "brgy_name": "Laslasong Sur",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926016",
      "brgy_name": "Laslasong West",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926017",
      "brgy_name": "Lesseb",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926018",
      "brgy_name": "Lingsat",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926019",
      "brgy_name": "Lubong",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926020",
      "brgy_name": "Maynganay Norte",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926021",
      "brgy_name": "Maynganay Sur",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926022",
      "brgy_name": "Nagsayaoan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926023",
      "brgy_name": "Nagtupacan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926024",
      "brgy_name": "Nalvo",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926027",
      "brgy_name": "Pacang",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926028",
      "brgy_name": "Penned",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926029",
      "brgy_name": "Poblacion Norte",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926030",
      "brgy_name": "Poblacion Sur",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926031",
      "brgy_name": "Silag",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926032",
      "brgy_name": "Sumagui",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926033",
      "brgy_name": "Suso",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926034",
      "brgy_name": "Tangaoan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012926035",
      "brgy_name": "Tinaan",
      "city_code": "012926",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927001",
      "brgy_name": "Al-aludig",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927002",
      "brgy_name": "Ambucao",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927003",
      "brgy_name": "San Jose (Baraoas)",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927004",
      "brgy_name": "Baybayabas",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927005",
      "brgy_name": "Bigbiga",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927006",
      "brgy_name": "Bulbulala",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927007",
      "brgy_name": "Busel-busel",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927008",
      "brgy_name": "Butol",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927009",
      "brgy_name": "Caburao",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927010",
      "brgy_name": "Dan-ar",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927011",
      "brgy_name": "Gabao",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927012",
      "brgy_name": "Guinabang",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927013",
      "brgy_name": "Imus",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927014",
      "brgy_name": "Lang-ayan",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927015",
      "brgy_name": "Mambug",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927016",
      "brgy_name": "Nalasin",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927017",
      "brgy_name": "Olo-olo Norte",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927018",
      "brgy_name": "Olo-olo Sur",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927019",
      "brgy_name": "Poblacion Norte",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927020",
      "brgy_name": "Poblacion Sur",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927021",
      "brgy_name": "Sabangan",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927022",
      "brgy_name": "Salincub",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927023",
      "brgy_name": "San Roque",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012927024",
      "brgy_name": "Ubbog",
      "city_code": "012927",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928001",
      "brgy_name": "Binalayangan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928002",
      "brgy_name": "Binongan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928003",
      "brgy_name": "Borobor",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928004",
      "brgy_name": "Cabaritan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928005",
      "brgy_name": "Cabigbigaan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928006",
      "brgy_name": "Calautit",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928007",
      "brgy_name": "Calay-ab",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928008",
      "brgy_name": "Camestizoan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928009",
      "brgy_name": "Casili",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928010",
      "brgy_name": "Flora",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928011",
      "brgy_name": "Lagatit",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928012",
      "brgy_name": "Laoingen",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928013",
      "brgy_name": "Lussoc",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928014",
      "brgy_name": "Nalasin",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928015",
      "brgy_name": "Nagbettedan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928016",
      "brgy_name": "Naglaoa-an",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928017",
      "brgy_name": "Nambaran",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928018",
      "brgy_name": "Nanerman",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928019",
      "brgy_name": "Napo",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928020",
      "brgy_name": "Padu Chico",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928021",
      "brgy_name": "Padu Grande",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928022",
      "brgy_name": "Paguraper",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928023",
      "brgy_name": "Panay",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928024",
      "brgy_name": "Pangpangdan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928025",
      "brgy_name": "Parada",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928026",
      "brgy_name": "Paras",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928027",
      "brgy_name": "Poblacion",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928030",
      "brgy_name": "Puerta Real",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928031",
      "brgy_name": "Pussuac",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928032",
      "brgy_name": "Quimmarayan",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928033",
      "brgy_name": "San Pablo",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928034",
      "brgy_name": "Santa Cruz",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928035",
      "brgy_name": "Santo Tomas",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928036",
      "brgy_name": "Sived",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928038",
      "brgy_name": "Vacunero",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012928039",
      "brgy_name": "Suksukit",
      "city_code": "012928",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012929001",
      "brgy_name": "Abaccan",
      "city_code": "012929",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012929002",
      "brgy_name": "Mabileg",
      "city_code": "012929",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012929003",
      "brgy_name": "Matallucod",
      "city_code": "012929",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012929004",
      "brgy_name": "Poblacion (Madayaw)",
      "city_code": "012929",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012929005",
      "brgy_name": "San Elias",
      "city_code": "012929",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012929006",
      "brgy_name": "San Ramon",
      "city_code": "012929",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012929007",
      "brgy_name": "Santo Rosario",
      "city_code": "012929",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930001",
      "brgy_name": "Aguing",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930002",
      "brgy_name": "Ballaigui (Pob.)",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930003",
      "brgy_name": "Baliw",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930004",
      "brgy_name": "Baracbac",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930005",
      "brgy_name": "Barikir",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930006",
      "brgy_name": "Battog",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930007",
      "brgy_name": "Binacud",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930008",
      "brgy_name": "Cabangtalan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930009",
      "brgy_name": "Cabarambanan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930010",
      "brgy_name": "Cabulalaan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930011",
      "brgy_name": "Cadanglaan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930012",
      "brgy_name": "Calingayan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930013",
      "brgy_name": "Curtin",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930014",
      "brgy_name": "Dadalaquiten Norte",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930015",
      "brgy_name": "Dadalaquiten Sur",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930016",
      "brgy_name": "Duyayyat",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930017",
      "brgy_name": "Jordan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930018",
      "brgy_name": "Calanutian",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930019",
      "brgy_name": "Katipunan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930020",
      "brgy_name": "Macabiag (Pob.)",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930021",
      "brgy_name": "Magsaysay",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930022",
      "brgy_name": "Marnay",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930023",
      "brgy_name": "Masadag",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930024",
      "brgy_name": "Nagcullooban",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930025",
      "brgy_name": "Nagbalioartian",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930026",
      "brgy_name": "Nagongburan",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930027",
      "brgy_name": "Namnama (Pob.)",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930028",
      "brgy_name": "Pacis",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930029",
      "brgy_name": "Paratong",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930030",
      "brgy_name": "Dean Leopoldo Yabes (Pug-os)",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930031",
      "brgy_name": "Purag",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930032",
      "brgy_name": "Quibit-quibit",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930033",
      "brgy_name": "Quimmallogong",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930034",
      "brgy_name": "Rang-ay (Pob.)",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930035",
      "brgy_name": "Ricudo",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930036",
      "brgy_name": "Sabañgan (Marcos)",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930037",
      "brgy_name": "Sallacapo",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930038",
      "brgy_name": "Santa Cruz",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930039",
      "brgy_name": "Sapriana",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930040",
      "brgy_name": "Tapao",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930041",
      "brgy_name": "Teppeng",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930042",
      "brgy_name": "Tubigay",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930043",
      "brgy_name": "Ubbog",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012930044",
      "brgy_name": "Zapat",
      "city_code": "012930",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012931001",
      "brgy_name": "Banga",
      "city_code": "012931",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012931002",
      "brgy_name": "Caoayan",
      "city_code": "012931",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012931004",
      "brgy_name": "Licungan (Cullang)",
      "city_code": "012931",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012931005",
      "brgy_name": "Danac",
      "city_code": "012931",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012931007",
      "brgy_name": "Pangotan",
      "city_code": "012931",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012931008",
      "brgy_name": "Balbalayang (Pob.)",
      "city_code": "012931",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932001",
      "brgy_name": "Baringcucurong",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932002",
      "brgy_name": "Cabugao",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932003",
      "brgy_name": "Man-atong",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932004",
      "brgy_name": "Patoc-ao",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932005",
      "brgy_name": "Poblacion (Kimpusa)",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932007",
      "brgy_name": "Suyo Proper",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932008",
      "brgy_name": "Urzadan",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012932009",
      "brgy_name": "Uso",
      "city_code": "012932",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933001",
      "brgy_name": "Ag-aguman",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933002",
      "brgy_name": "Ambalayat",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933003",
      "brgy_name": "Baracbac",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933004",
      "brgy_name": "Bario-an",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933005",
      "brgy_name": "Baritao",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933006",
      "brgy_name": "Borono",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933007",
      "brgy_name": "Becques",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933008",
      "brgy_name": "Bimmanga",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933009",
      "brgy_name": "Bio",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933010",
      "brgy_name": "Bitalag",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933011",
      "brgy_name": "Bucao East",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933012",
      "brgy_name": "Bucao West",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933013",
      "brgy_name": "Cabaroan",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933014",
      "brgy_name": "Cabugbugan",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933015",
      "brgy_name": "Cabulanglangan",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933016",
      "brgy_name": "Dacutan",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933017",
      "brgy_name": "Dardarat",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933018",
      "brgy_name": "Del Pilar (Pob.)",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933019",
      "brgy_name": "Farola",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933020",
      "brgy_name": "Gabur",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933021",
      "brgy_name": "Garitan",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933022",
      "brgy_name": "Jardin",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933023",
      "brgy_name": "Lacong",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933024",
      "brgy_name": "Lantag",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933025",
      "brgy_name": "Las-ud",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933026",
      "brgy_name": "Libtong",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933027",
      "brgy_name": "Lubnac",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933028",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933029",
      "brgy_name": "Malacañang",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933030",
      "brgy_name": "Pacac",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933031",
      "brgy_name": "Pallogan",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933032",
      "brgy_name": "Pudoc East",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933033",
      "brgy_name": "Pudoc West",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933034",
      "brgy_name": "Pula",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933035",
      "brgy_name": "Quirino (Pob.)",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933036",
      "brgy_name": "Ranget",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933038",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933039",
      "brgy_name": "Salvacion",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933040",
      "brgy_name": "San Miguel",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933041",
      "brgy_name": "Sawat",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933044",
      "brgy_name": "Tallaoen",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933045",
      "brgy_name": "Tampugo",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012933046",
      "brgy_name": "Tarangotong",
      "city_code": "012933",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934001",
      "brgy_name": "Ayusan Norte",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934002",
      "brgy_name": "Ayusan Sur",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934003",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934004",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934005",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934006",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934007",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934008",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934009",
      "brgy_name": "Barraca",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934010",
      "brgy_name": "Beddeng Laud",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934011",
      "brgy_name": "Beddeng Daya",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934012",
      "brgy_name": "Bongtolan",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934013",
      "brgy_name": "Bulala",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934014",
      "brgy_name": "Cabalangegan",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934015",
      "brgy_name": "Cabaroan Daya",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934016",
      "brgy_name": "Cabaroan Laud",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934017",
      "brgy_name": "Camangaan",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934018",
      "brgy_name": "Capangpangan",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934020",
      "brgy_name": "Mindoro",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934021",
      "brgy_name": "Nagsangalan",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934022",
      "brgy_name": "Pantay Daya",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934023",
      "brgy_name": "Pantay Fatima",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934024",
      "brgy_name": "Pantay Laud",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934025",
      "brgy_name": "Paoa",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934026",
      "brgy_name": "Paratong",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934027",
      "brgy_name": "Pong-ol",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934028",
      "brgy_name": "Purok-a-bassit",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934029",
      "brgy_name": "Purok-a-dackel",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934030",
      "brgy_name": "Raois",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934031",
      "brgy_name": "Rugsuanan",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934032",
      "brgy_name": "Salindeg",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934033",
      "brgy_name": "San Jose",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934034",
      "brgy_name": "San Julian Norte",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934035",
      "brgy_name": "San Julian Sur",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934036",
      "brgy_name": "San Pedro",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934037",
      "brgy_name": "Tamag",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934038",
      "brgy_name": "Barangay VII",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934039",
      "brgy_name": "Barangay VIII",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "012934040",
      "brgy_name": "Barangay IX",
      "city_code": "012934",
      "province_code": "0129",
      "region_code": "01"
    },
    {
      "brgy_code": "013301001",
      "brgy_name": "Ambitacay",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301002",
      "brgy_name": "Balawarte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301003",
      "brgy_name": "Capas",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301004",
      "brgy_name": "Consolacion (Pob.)",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301005",
      "brgy_name": "Macalva Central",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301006",
      "brgy_name": "Macalva Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301007",
      "brgy_name": "Macalva Sur",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301008",
      "brgy_name": "Nazareno",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301009",
      "brgy_name": "Purok",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301010",
      "brgy_name": "San Agustin East",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301011",
      "brgy_name": "San Agustin Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301012",
      "brgy_name": "San Agustin Sur",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301013",
      "brgy_name": "San Antonino",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301014",
      "brgy_name": "San Antonio",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301015",
      "brgy_name": "San Francisco",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301016",
      "brgy_name": "San Isidro",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301017",
      "brgy_name": "San Joaquin Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301018",
      "brgy_name": "San Joaquin Sur",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301019",
      "brgy_name": "San Jose Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301020",
      "brgy_name": "San Jose Sur",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301021",
      "brgy_name": "San Juan",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301022",
      "brgy_name": "San Julian Central",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301023",
      "brgy_name": "San Julian East",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301024",
      "brgy_name": "San Julian Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301025",
      "brgy_name": "San Julian West",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301026",
      "brgy_name": "San Manuel Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301027",
      "brgy_name": "San Manuel Sur",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301028",
      "brgy_name": "San Marcos",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301029",
      "brgy_name": "San Miguel",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301030",
      "brgy_name": "San Nicolas Central (Pob.)",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301031",
      "brgy_name": "San Nicolas East",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301032",
      "brgy_name": "San Nicolas Norte (Pob.)",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301033",
      "brgy_name": "San Nicolas West",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301034",
      "brgy_name": "San Nicolas Sur (Pob.)",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301035",
      "brgy_name": "San Pedro",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301036",
      "brgy_name": "San Roque West",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301037",
      "brgy_name": "San Roque East",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301038",
      "brgy_name": "San Vicente Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301039",
      "brgy_name": "San Vicente Sur",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301040",
      "brgy_name": "Santa Ana",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301041",
      "brgy_name": "Santa Barbara (Pob.)",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301042",
      "brgy_name": "Santa Fe",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301043",
      "brgy_name": "Santa Maria",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301044",
      "brgy_name": "Santa Monica",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301045",
      "brgy_name": "Santa Rita (Nalinac)",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301046",
      "brgy_name": "Santa Rita East",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301047",
      "brgy_name": "Santa Rita Norte",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301048",
      "brgy_name": "Santa Rita Sur",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013301049",
      "brgy_name": "Santa Rita West",
      "city_code": "013301",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302001",
      "brgy_name": "Alaska",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302002",
      "brgy_name": "Basca",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302003",
      "brgy_name": "Dulao",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302004",
      "brgy_name": "Gallano",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302005",
      "brgy_name": "Macabato",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302006",
      "brgy_name": "Manga",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302007",
      "brgy_name": "Pangao-aoan East",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302009",
      "brgy_name": "Pangao-aoan West",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302010",
      "brgy_name": "Poblacion",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302011",
      "brgy_name": "Samara",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302012",
      "brgy_name": "San Antonio",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302014",
      "brgy_name": "San Benito Norte",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302015",
      "brgy_name": "San Benito Sur",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302016",
      "brgy_name": "San Eugenio",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302018",
      "brgy_name": "San Juan East",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302019",
      "brgy_name": "San Juan West",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302020",
      "brgy_name": "San Simon East",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302021",
      "brgy_name": "San Simon West",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302022",
      "brgy_name": "Santa Cecilia",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302023",
      "brgy_name": "Santa Lucia",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302024",
      "brgy_name": "Santa Rita East",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302025",
      "brgy_name": "Santa Rita West",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302027",
      "brgy_name": "Santo Rosario East",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013302028",
      "brgy_name": "Santo Rosario West",
      "city_code": "013302",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303001",
      "brgy_name": "Agtipal",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303002",
      "brgy_name": "Arosip",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303003",
      "brgy_name": "Bacqui",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303004",
      "brgy_name": "Bacsil",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303005",
      "brgy_name": "Bagutot",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303006",
      "brgy_name": "Ballogo",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303007",
      "brgy_name": "Baroro",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303008",
      "brgy_name": "Bitalag",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303009",
      "brgy_name": "Bulala",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303010",
      "brgy_name": "Burayoc",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303011",
      "brgy_name": "Bussaoit",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303012",
      "brgy_name": "Cabaroan",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303013",
      "brgy_name": "Cabarsican",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303014",
      "brgy_name": "Cabugao",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303015",
      "brgy_name": "Calautit",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303016",
      "brgy_name": "Carcarmay",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303017",
      "brgy_name": "Casiaman",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303018",
      "brgy_name": "Galongen",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303019",
      "brgy_name": "Guinabang",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303020",
      "brgy_name": "Legleg",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303021",
      "brgy_name": "Lisqueb",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303022",
      "brgy_name": "Mabanengbeng 1st",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303023",
      "brgy_name": "Mabanengbeng 2nd",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303024",
      "brgy_name": "Maragayap",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303025",
      "brgy_name": "Nangalisan",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303026",
      "brgy_name": "Nagatiran",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303027",
      "brgy_name": "Nagsaraboan",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303028",
      "brgy_name": "Nagsimbaanan",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303029",
      "brgy_name": "Narra",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303030",
      "brgy_name": "Ortega",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303031",
      "brgy_name": "Paagan",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303032",
      "brgy_name": "Pandan",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303033",
      "brgy_name": "Pang-pang",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303034",
      "brgy_name": "Poblacion",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303035",
      "brgy_name": "Quirino",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303036",
      "brgy_name": "Raois",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303037",
      "brgy_name": "Salincob",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303038",
      "brgy_name": "San Martin",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303039",
      "brgy_name": "Santa Cruz",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303040",
      "brgy_name": "Santa Rita",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303041",
      "brgy_name": "Sapilang",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303042",
      "brgy_name": "Sayoan",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303043",
      "brgy_name": "Sipulo",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303044",
      "brgy_name": "Tammocalao",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303045",
      "brgy_name": "Ubbog",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303046",
      "brgy_name": "Oya-oy",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013303047",
      "brgy_name": "Zaragosa",
      "city_code": "013303",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304001",
      "brgy_name": "Alibangsay",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304002",
      "brgy_name": "Baay",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304003",
      "brgy_name": "Cambaly",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304004",
      "brgy_name": "Cardiz",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304005",
      "brgy_name": "Dagup",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304006",
      "brgy_name": "Libbo",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304007",
      "brgy_name": "Suyo (Pob.)",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304008",
      "brgy_name": "Tagudtud",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304009",
      "brgy_name": "Tio-angan",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013304010",
      "brgy_name": "Wallayan",
      "city_code": "013304",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305001",
      "brgy_name": "Apatut",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305002",
      "brgy_name": "Ar-arampang",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305003",
      "brgy_name": "Baracbac Este",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305004",
      "brgy_name": "Baracbac Oeste",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305005",
      "brgy_name": "Bet-ang",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305006",
      "brgy_name": "Bulbulala",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305007",
      "brgy_name": "Bungol",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305008",
      "brgy_name": "Butubut Este",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305009",
      "brgy_name": "Butubut Norte",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305010",
      "brgy_name": "Butubut Oeste",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305011",
      "brgy_name": "Butubut Sur",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305012",
      "brgy_name": "Cabuaan Oeste (Pob.)",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305013",
      "brgy_name": "Calliat",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305014",
      "brgy_name": "Calungbuyan",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305015",
      "brgy_name": "Camiling",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305016",
      "brgy_name": "Guinaburan",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305017",
      "brgy_name": "Masupe",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305018",
      "brgy_name": "Nagsabaran Norte",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305019",
      "brgy_name": "Nagsabaran Sur",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305020",
      "brgy_name": "Nalasin",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305021",
      "brgy_name": "Napaset",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305022",
      "brgy_name": "Pagbennecan",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305023",
      "brgy_name": "Pagleddegan",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305024",
      "brgy_name": "Pantar Norte",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305025",
      "brgy_name": "Pantar Sur",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305026",
      "brgy_name": "Pa-o",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305028",
      "brgy_name": "Almieda",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305029",
      "brgy_name": "Paraoir",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305030",
      "brgy_name": "Patpata",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305031",
      "brgy_name": "Dr. Camilo Osias Pob. (Cabuaan Este)",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305032",
      "brgy_name": "Sablut",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305033",
      "brgy_name": "San Pablo",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305034",
      "brgy_name": "Sinapangan Norte",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305035",
      "brgy_name": "Sinapangan Sur",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305036",
      "brgy_name": "Tallipugo",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013305037",
      "brgy_name": "Antonino",
      "city_code": "013305",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306001",
      "brgy_name": "Agdeppa",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306002",
      "brgy_name": "Alzate",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306004",
      "brgy_name": "Bangaoilan East",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306005",
      "brgy_name": "Bangaoilan West",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306006",
      "brgy_name": "Barraca",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306007",
      "brgy_name": "Cadapli",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306008",
      "brgy_name": "Caggao",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306009",
      "brgy_name": "Consuegra",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306010",
      "brgy_name": "General Prim East",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306011",
      "brgy_name": "General Prim West",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306013",
      "brgy_name": "General Terrero",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306015",
      "brgy_name": "Luzong Norte",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306016",
      "brgy_name": "Luzong Sur",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306018",
      "brgy_name": "Maria Cristina East",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306019",
      "brgy_name": "Maria Cristina West",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306020",
      "brgy_name": "Mindoro",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306021",
      "brgy_name": "Nagsabaran",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306022",
      "brgy_name": "Paratong Norte",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306023",
      "brgy_name": "Paratong No. 3",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306024",
      "brgy_name": "Paratong No. 4",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306025",
      "brgy_name": "Central East No. 1 (Pob.)",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306026",
      "brgy_name": "Central East No. 2 (Pob.)",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306027",
      "brgy_name": "Central West No. 1 (Pob.)",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306028",
      "brgy_name": "Central West No. 2 (Pob.)",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306029",
      "brgy_name": "Central West No. 3 (Pob.)",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306030",
      "brgy_name": "Quintarong",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306031",
      "brgy_name": "Reyna Regente",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306032",
      "brgy_name": "Rissing",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306033",
      "brgy_name": "San Blas",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306034",
      "brgy_name": "San Cristobal",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306035",
      "brgy_name": "Sinapangan Norte",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306036",
      "brgy_name": "Sinapangan Sur",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013306037",
      "brgy_name": "Ubbog",
      "city_code": "013306",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307001",
      "brgy_name": "Acao",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307002",
      "brgy_name": "Baccuit Norte",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307003",
      "brgy_name": "Baccuit Sur",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307004",
      "brgy_name": "Bagbag",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307005",
      "brgy_name": "Ballay",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307006",
      "brgy_name": "Bawanta",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307007",
      "brgy_name": "Boy-utan",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307008",
      "brgy_name": "Bucayab",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307009",
      "brgy_name": "Cabalayangan",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307010",
      "brgy_name": "Cabisilan",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307011",
      "brgy_name": "Calumbaya",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307012",
      "brgy_name": "Carmay",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307013",
      "brgy_name": "Casilagan",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307014",
      "brgy_name": "Central East (Pob.)",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307015",
      "brgy_name": "Central West (Pob.)",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307016",
      "brgy_name": "Dili",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307017",
      "brgy_name": "Disso-or",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307018",
      "brgy_name": "Guerrero",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307019",
      "brgy_name": "Nagrebcan",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307020",
      "brgy_name": "Pagdalagan Sur",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307021",
      "brgy_name": "Palintucang",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307022",
      "brgy_name": "Palugsi-Limmansangan",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307023",
      "brgy_name": "Parian Oeste",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307024",
      "brgy_name": "Parian Este",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307025",
      "brgy_name": "Paringao",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307026",
      "brgy_name": "Payocpoc Norte Este",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307027",
      "brgy_name": "Payocpoc Norte Oeste",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307028",
      "brgy_name": "Payocpoc Sur",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307029",
      "brgy_name": "Pilar",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307030",
      "brgy_name": "Pudoc",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307031",
      "brgy_name": "Pottot",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307032",
      "brgy_name": "Pugo",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307033",
      "brgy_name": "Quinavite",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307034",
      "brgy_name": "Lower San Agustin",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307035",
      "brgy_name": "Santa Monica",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307036",
      "brgy_name": "Santiago",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307037",
      "brgy_name": "Taberna",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307038",
      "brgy_name": "Upper San Agustin",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013307039",
      "brgy_name": "Urayong",
      "city_code": "013307",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308001",
      "brgy_name": "Agpay",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308002",
      "brgy_name": "Bilis",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308003",
      "brgy_name": "Caoayan",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308004",
      "brgy_name": "Dalacdac",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308005",
      "brgy_name": "Delles",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308006",
      "brgy_name": "Imelda",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308007",
      "brgy_name": "Libtong",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308008",
      "brgy_name": "Linuan",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308009",
      "brgy_name": "New Poblacion",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308010",
      "brgy_name": "Old Poblacion",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308011",
      "brgy_name": "Lower Tumapoc",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013308012",
      "brgy_name": "Upper Tumapoc",
      "city_code": "013308",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309001",
      "brgy_name": "Bautista",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309002",
      "brgy_name": "Gana",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309003",
      "brgy_name": "Juan Cartas",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309004",
      "brgy_name": "Las-ud",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309005",
      "brgy_name": "Liquicia",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309006",
      "brgy_name": "Poblacion Norte",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309007",
      "brgy_name": "Poblacion Sur",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309009",
      "brgy_name": "San Carlos",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309010",
      "brgy_name": "San Cornelio",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309011",
      "brgy_name": "San Fermin",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309012",
      "brgy_name": "San Gregorio",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309013",
      "brgy_name": "San Jose",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309014",
      "brgy_name": "Santiago Norte",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309015",
      "brgy_name": "Santiago Sur",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309016",
      "brgy_name": "Sobredillo",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309017",
      "brgy_name": "Urayong",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013309018",
      "brgy_name": "Wenceslao",
      "city_code": "013309",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310001",
      "brgy_name": "Alcala (Pob.)",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310002",
      "brgy_name": "Ayaoan",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310003",
      "brgy_name": "Barangobong",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310004",
      "brgy_name": "Barrientos",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310005",
      "brgy_name": "Bungro",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310006",
      "brgy_name": "Buselbusel",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310007",
      "brgy_name": "Cabalitocan",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310008",
      "brgy_name": "Cantoria No. 1",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310009",
      "brgy_name": "Cantoria No. 2",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310010",
      "brgy_name": "Cantoria No. 3",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310011",
      "brgy_name": "Cantoria No. 4",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310012",
      "brgy_name": "Carisquis",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310013",
      "brgy_name": "Darigayos",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310014",
      "brgy_name": "Magallanes (Pob.)",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310015",
      "brgy_name": "Magsiping",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310016",
      "brgy_name": "Mamay",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310017",
      "brgy_name": "Nagrebcan",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310018",
      "brgy_name": "Nalvo Norte",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310019",
      "brgy_name": "Nalvo Sur",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310020",
      "brgy_name": "Napaset",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310021",
      "brgy_name": "Oaqui No. 1",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310022",
      "brgy_name": "Oaqui No. 2",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310023",
      "brgy_name": "Oaqui No. 3",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310024",
      "brgy_name": "Oaqui No. 4",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310025",
      "brgy_name": "Pila",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310026",
      "brgy_name": "Pitpitac",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310027",
      "brgy_name": "Rimos No. 1",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310028",
      "brgy_name": "Rimos No. 2",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310029",
      "brgy_name": "Rimos No. 3",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310030",
      "brgy_name": "Rimos No. 4",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310031",
      "brgy_name": "Rimos No. 5",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310032",
      "brgy_name": "Rissing",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310033",
      "brgy_name": "Salcedo (Pob.)",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310034",
      "brgy_name": "Santo Domingo Norte",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310035",
      "brgy_name": "Santo Domingo Sur",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310036",
      "brgy_name": "Sucoc Norte",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310037",
      "brgy_name": "Sucoc Sur",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310038",
      "brgy_name": "Suyo",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310039",
      "brgy_name": "Tallaoen",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013310040",
      "brgy_name": "Victoria (Pob.)",
      "city_code": "013310",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311001",
      "brgy_name": "Aguioas",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311002",
      "brgy_name": "Al-alinao Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311003",
      "brgy_name": "Al-alinao Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311004",
      "brgy_name": "Ambaracao Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311005",
      "brgy_name": "Ambaracao Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311006",
      "brgy_name": "Angin",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311007",
      "brgy_name": "Balecbec",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311008",
      "brgy_name": "Bancagan",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311009",
      "brgy_name": "Baraoas Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311010",
      "brgy_name": "Baraoas Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311011",
      "brgy_name": "Bariquir",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311012",
      "brgy_name": "Bato",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311013",
      "brgy_name": "Bimmotobot",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311014",
      "brgy_name": "Cabaritan Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311015",
      "brgy_name": "Cabaritan Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311016",
      "brgy_name": "Casilagan",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311017",
      "brgy_name": "Dal-lipaoen",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311018",
      "brgy_name": "Daramuangan",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311019",
      "brgy_name": "Guesset",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311020",
      "brgy_name": "Gusing Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311021",
      "brgy_name": "Gusing Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311022",
      "brgy_name": "Imelda",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311023",
      "brgy_name": "Lioac Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311024",
      "brgy_name": "Lioac Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311025",
      "brgy_name": "Magungunay",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311026",
      "brgy_name": "Mamat-ing Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311027",
      "brgy_name": "Mamat-ing Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311028",
      "brgy_name": "Nagsidorisan",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311029",
      "brgy_name": "Natividad (Pob.)",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311030",
      "brgy_name": "Ortiz (Pob.)",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311032",
      "brgy_name": "Ribsuan",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311033",
      "brgy_name": "San Antonio",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311034",
      "brgy_name": "San Isidro",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311035",
      "brgy_name": "Sili",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311037",
      "brgy_name": "Suguidan Norte",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311038",
      "brgy_name": "Suguidan Sur",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013311039",
      "brgy_name": "Tuddingan",
      "city_code": "013311",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312001",
      "brgy_name": "Ambalite",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312002",
      "brgy_name": "Ambangonan",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312003",
      "brgy_name": "Cares",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312004",
      "brgy_name": "Cuenca",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312005",
      "brgy_name": "Duplas",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312007",
      "brgy_name": "Maoasoas Norte",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312008",
      "brgy_name": "Maoasoas Sur",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312009",
      "brgy_name": "Palina",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312010",
      "brgy_name": "Poblacion East",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312011",
      "brgy_name": "San Luis",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312012",
      "brgy_name": "Saytan",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312013",
      "brgy_name": "Tavora East",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312014",
      "brgy_name": "Tavora Proper",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013312015",
      "brgy_name": "Poblacion West",
      "city_code": "013312",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313001",
      "brgy_name": "Alipang",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313002",
      "brgy_name": "Ambangonan",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313003",
      "brgy_name": "Amlang",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313004",
      "brgy_name": "Bacani",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313005",
      "brgy_name": "Bangar",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313006",
      "brgy_name": "Bani",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313007",
      "brgy_name": "Benteng-Sapilang",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313008",
      "brgy_name": "Cadumanian",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313009",
      "brgy_name": "Camp One",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313010",
      "brgy_name": "Carunuan East",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313011",
      "brgy_name": "Carunuan West",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313012",
      "brgy_name": "Casilagan",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313013",
      "brgy_name": "Cataguingtingan",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313014",
      "brgy_name": "Concepcion",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313015",
      "brgy_name": "Damortis",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313016",
      "brgy_name": "Gumot-Nagcolaran",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313017",
      "brgy_name": "Inabaan Norte",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313018",
      "brgy_name": "Inabaan Sur",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313019",
      "brgy_name": "Nagtagaan",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313020",
      "brgy_name": "Nangcamotian",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313021",
      "brgy_name": "Parasapas",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313022",
      "brgy_name": "Poblacion East",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313023",
      "brgy_name": "Poblacion West",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313024",
      "brgy_name": "Puzon",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313026",
      "brgy_name": "Rabon",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313027",
      "brgy_name": "San Jose",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313028",
      "brgy_name": "Marcos",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313029",
      "brgy_name": "Subusub",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313030",
      "brgy_name": "Tabtabungao",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313031",
      "brgy_name": "Tanglag",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313032",
      "brgy_name": "Tay-ac",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313033",
      "brgy_name": "Udiao",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013313034",
      "brgy_name": "Vila",
      "city_code": "013313",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314001",
      "brgy_name": "Abut",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314002",
      "brgy_name": "Apaleng",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314003",
      "brgy_name": "Bacsil",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314004",
      "brgy_name": "Bangbangolan",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314005",
      "brgy_name": "Bangcusay",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314006",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314007",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314008",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314009",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314010",
      "brgy_name": "Baraoas",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314011",
      "brgy_name": "Bato",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314012",
      "brgy_name": "Biday",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314013",
      "brgy_name": "Birunget",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314014",
      "brgy_name": "Bungro",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314015",
      "brgy_name": "Cabaroan (Negro)",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314016",
      "brgy_name": "Cabarsican",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314017",
      "brgy_name": "Cadaclan",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314018",
      "brgy_name": "Calabugao",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314019",
      "brgy_name": "Camansi",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314020",
      "brgy_name": "Canaoay",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314021",
      "brgy_name": "Carlatan",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314022",
      "brgy_name": "Catbangen",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314023",
      "brgy_name": "Dallangayan Este",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314024",
      "brgy_name": "Dallangayan Oeste",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314025",
      "brgy_name": "Dalumpinas Este",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314026",
      "brgy_name": "Dalumpinas Oeste",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314027",
      "brgy_name": "Ilocanos Norte",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314028",
      "brgy_name": "Ilocanos Sur",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314029",
      "brgy_name": "Langcuas",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314030",
      "brgy_name": "Lingsat",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314031",
      "brgy_name": "Madayegdeg",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314032",
      "brgy_name": "Mameltac",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314033",
      "brgy_name": "Masicong",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314034",
      "brgy_name": "Nagyubuyuban",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314035",
      "brgy_name": "Namtutan",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314036",
      "brgy_name": "Narra Este",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314037",
      "brgy_name": "Narra Oeste",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314039",
      "brgy_name": "Pacpaco",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314040",
      "brgy_name": "Pagdalagan",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314041",
      "brgy_name": "Pagdaraoan",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314042",
      "brgy_name": "Pagudpud",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314043",
      "brgy_name": "Pao Norte",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314044",
      "brgy_name": "Pao Sur",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314045",
      "brgy_name": "Parian",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314046",
      "brgy_name": "Pias",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314047",
      "brgy_name": "Poro",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314048",
      "brgy_name": "Puspus",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314049",
      "brgy_name": "Sacyud",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314050",
      "brgy_name": "Sagayad",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314051",
      "brgy_name": "San Agustin",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314052",
      "brgy_name": "San Francisco",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314053",
      "brgy_name": "San Vicente",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314054",
      "brgy_name": "Santiago Norte",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314055",
      "brgy_name": "Santiago Sur",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314056",
      "brgy_name": "Saoay",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314057",
      "brgy_name": "Sevilla",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314058",
      "brgy_name": "Siboan-Otong",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314059",
      "brgy_name": "Tanqui",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013314060",
      "brgy_name": "Tanquigan",
      "city_code": "013314",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315002",
      "brgy_name": "Amontoc",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315003",
      "brgy_name": "Apayao",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315004",
      "brgy_name": "Balbalayang",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315006",
      "brgy_name": "Bayabas",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315007",
      "brgy_name": "Bucao",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315008",
      "brgy_name": "Bumbuneg",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315012",
      "brgy_name": "Lacong",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315013",
      "brgy_name": "Lipay Este",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315014",
      "brgy_name": "Lipay Norte",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315015",
      "brgy_name": "Lipay Proper",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315016",
      "brgy_name": "Lipay Sur",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315017",
      "brgy_name": "Lon-oy",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315019",
      "brgy_name": "Poblacion",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315020",
      "brgy_name": "Polipol",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013315021",
      "brgy_name": "Daking",
      "city_code": "013315",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316001",
      "brgy_name": "Allangigan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316002",
      "brgy_name": "Aludaid",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316003",
      "brgy_name": "Bacsayan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316004",
      "brgy_name": "Balballosa",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316005",
      "brgy_name": "Bambanay",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316006",
      "brgy_name": "Bugbugcao",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316007",
      "brgy_name": "Caarusipan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316008",
      "brgy_name": "Cabaroan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316009",
      "brgy_name": "Cabugnayan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316010",
      "brgy_name": "Cacapian",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316011",
      "brgy_name": "Caculangan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316012",
      "brgy_name": "Calincamasan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316013",
      "brgy_name": "Casilagan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316014",
      "brgy_name": "Catdongan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316015",
      "brgy_name": "Dangdangla",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316016",
      "brgy_name": "Dasay",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316017",
      "brgy_name": "Dinanum",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316018",
      "brgy_name": "Duplas",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316019",
      "brgy_name": "Guinguinabang",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316020",
      "brgy_name": "Ili Norte (Pob.)",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316021",
      "brgy_name": "Ili Sur (Pob.)",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316022",
      "brgy_name": "Legleg",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316023",
      "brgy_name": "Lubing",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316024",
      "brgy_name": "Nadsaag",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316025",
      "brgy_name": "Nagsabaran",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316026",
      "brgy_name": "Naguirangan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316027",
      "brgy_name": "Naguituban",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316028",
      "brgy_name": "Nagyubuyuban",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316029",
      "brgy_name": "Oaquing",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316030",
      "brgy_name": "Pacpacac",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316031",
      "brgy_name": "Pagdildilan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316032",
      "brgy_name": "Panicsican",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316033",
      "brgy_name": "Quidem",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316035",
      "brgy_name": "San Felipe",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316036",
      "brgy_name": "Santa Rosa",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316037",
      "brgy_name": "Santo Rosario",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316038",
      "brgy_name": "Saracat",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316039",
      "brgy_name": "Sinapangan",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316040",
      "brgy_name": "Taboc",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316041",
      "brgy_name": "Talogtog",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013316042",
      "brgy_name": "Urbiztondo",
      "city_code": "013316",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317001",
      "brgy_name": "Ambitacay",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317002",
      "brgy_name": "Bail",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317003",
      "brgy_name": "Balaoc",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317004",
      "brgy_name": "Balsaan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317005",
      "brgy_name": "Baybay",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317006",
      "brgy_name": "Cabaruan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317007",
      "brgy_name": "Casantaan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317008",
      "brgy_name": "Casilagan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317009",
      "brgy_name": "Cupang",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317010",
      "brgy_name": "Damortis",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317011",
      "brgy_name": "Fernando",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317012",
      "brgy_name": "Linong",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317013",
      "brgy_name": "Lomboy",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317014",
      "brgy_name": "Malabago",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317015",
      "brgy_name": "Namboongan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317016",
      "brgy_name": "Namonitan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317017",
      "brgy_name": "Narvacan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317018",
      "brgy_name": "Patac",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317019",
      "brgy_name": "Poblacion",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317020",
      "brgy_name": "Pongpong",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317021",
      "brgy_name": "Raois",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317022",
      "brgy_name": "Tubod",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317023",
      "brgy_name": "Tococ",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013317024",
      "brgy_name": "Ubagan",
      "city_code": "013317",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318002",
      "brgy_name": "Corrooy",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318003",
      "brgy_name": "Lettac Norte",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318004",
      "brgy_name": "Lettac Sur",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318005",
      "brgy_name": "Mangaan",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318008",
      "brgy_name": "Paagan",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318010",
      "brgy_name": "Poblacion",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318011",
      "brgy_name": "Puguil",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318012",
      "brgy_name": "Ramot",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318013",
      "brgy_name": "Sapdaan",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318014",
      "brgy_name": "Sasaba",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013318015",
      "brgy_name": "Tubaday",
      "city_code": "013318",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319001",
      "brgy_name": "Bigbiga",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319002",
      "brgy_name": "Castro",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319003",
      "brgy_name": "Duplas",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319004",
      "brgy_name": "Ipet",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319005",
      "brgy_name": "Ilocano",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319006",
      "brgy_name": "Maliclico",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319007",
      "brgy_name": "Old Central",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319008",
      "brgy_name": "Namaltugan",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319010",
      "brgy_name": "Poblacion",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319011",
      "brgy_name": "Porporiket",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319013",
      "brgy_name": "San Francisco Norte",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319014",
      "brgy_name": "San Francisco Sur",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319015",
      "brgy_name": "San Jose",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319017",
      "brgy_name": "Sengngat",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319018",
      "brgy_name": "Turod",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319019",
      "brgy_name": "Up-uplas",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013319020",
      "brgy_name": "Bulalaan",
      "city_code": "013319",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320001",
      "brgy_name": "Amallapay",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320002",
      "brgy_name": "Anduyan",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320003",
      "brgy_name": "Caoigue",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320004",
      "brgy_name": "Francia Sur",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320005",
      "brgy_name": "Francia West",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320006",
      "brgy_name": "Garcia",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320007",
      "brgy_name": "Gonzales",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320008",
      "brgy_name": "Halog East",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320009",
      "brgy_name": "Halog West",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320010",
      "brgy_name": "Leones East",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320011",
      "brgy_name": "Leones West",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320012",
      "brgy_name": "Linapew",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320015",
      "brgy_name": "Magsaysay",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320016",
      "brgy_name": "Pideg",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320017",
      "brgy_name": "Poblacion",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320018",
      "brgy_name": "Rizal",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320019",
      "brgy_name": "Santa Teresa",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "013320020",
      "brgy_name": "Lloren",
      "city_code": "013320",
      "province_code": "0133",
      "region_code": "01"
    },
    {
      "brgy_code": "015501001",
      "brgy_name": "Allabon",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501002",
      "brgy_name": "Aloleng",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501003",
      "brgy_name": "Bangan-Oda",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501004",
      "brgy_name": "Baruan",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501005",
      "brgy_name": "Boboy",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501006",
      "brgy_name": "Cayungnan",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501007",
      "brgy_name": "Dangley",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501009",
      "brgy_name": "Gayusan",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501010",
      "brgy_name": "Macaboboni",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501011",
      "brgy_name": "Magsaysay",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501012",
      "brgy_name": "Namatucan",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501013",
      "brgy_name": "Patar",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501014",
      "brgy_name": "Poblacion East",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501015",
      "brgy_name": "Poblacion West",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501016",
      "brgy_name": "San Juan",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501017",
      "brgy_name": "Tupa",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015501018",
      "brgy_name": "Viga",
      "city_code": "015501",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502002",
      "brgy_name": "Bayaoas",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502003",
      "brgy_name": "Baybay",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502004",
      "brgy_name": "Bocacliw",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502006",
      "brgy_name": "Bocboc East",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502007",
      "brgy_name": "Bocboc West",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502008",
      "brgy_name": "Buer",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502009",
      "brgy_name": "Calsib",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502010",
      "brgy_name": "Ninoy",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502011",
      "brgy_name": "Poblacion",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502012",
      "brgy_name": "Pogomboa",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502013",
      "brgy_name": "Pogonsili",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502014",
      "brgy_name": "San Jose",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502016",
      "brgy_name": "Tampac",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502017",
      "brgy_name": "Laoag",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502018",
      "brgy_name": "Manlocboc",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015502019",
      "brgy_name": "Panacol",
      "city_code": "015502",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503001",
      "brgy_name": "Alos",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503002",
      "brgy_name": "Amandiego",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503003",
      "brgy_name": "Amangbangan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503004",
      "brgy_name": "Balangobong",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503005",
      "brgy_name": "Balayang",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503006",
      "brgy_name": "Bisocol",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503007",
      "brgy_name": "Bolaney",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503008",
      "brgy_name": "Baleyadaan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503009",
      "brgy_name": "Bued",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503010",
      "brgy_name": "Cabatuan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503011",
      "brgy_name": "Cayucay",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503012",
      "brgy_name": "Dulacac",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503013",
      "brgy_name": "Inerangan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503014",
      "brgy_name": "Linmansangan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503015",
      "brgy_name": "Lucap",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503016",
      "brgy_name": "Macatiw",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503017",
      "brgy_name": "Magsaysay",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503018",
      "brgy_name": "Mona",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503019",
      "brgy_name": "Palamis",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503020",
      "brgy_name": "Pangapisan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503021",
      "brgy_name": "Poblacion",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503022",
      "brgy_name": "Pocalpocal",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503023",
      "brgy_name": "Pogo",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503024",
      "brgy_name": "Polo",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503025",
      "brgy_name": "Quibuar",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503026",
      "brgy_name": "Sabangan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503029",
      "brgy_name": "San Jose",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503030",
      "brgy_name": "San Roque",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503031",
      "brgy_name": "San Vicente",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503032",
      "brgy_name": "Santa Maria",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503033",
      "brgy_name": "Tanaytay",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503034",
      "brgy_name": "Tangcarang",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503035",
      "brgy_name": "Tawintawin",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503036",
      "brgy_name": "Telbang",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503037",
      "brgy_name": "Victoria",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503038",
      "brgy_name": "Landoc",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503039",
      "brgy_name": "Maawi",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503040",
      "brgy_name": "Pandan",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015503041",
      "brgy_name": "San Antonio (R. Magsaysay)",
      "city_code": "015503",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504001",
      "brgy_name": "Anulid",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504003",
      "brgy_name": "Atainan",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504004",
      "brgy_name": "Bersamin",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504005",
      "brgy_name": "Canarvacanan",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504006",
      "brgy_name": "Caranglaan",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504007",
      "brgy_name": "Curareng",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504008",
      "brgy_name": "Gualsic",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504009",
      "brgy_name": "Kasikis",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504010",
      "brgy_name": "Laoac",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504011",
      "brgy_name": "Macayo",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504012",
      "brgy_name": "Pindangan Centro",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504013",
      "brgy_name": "Pindangan East",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504014",
      "brgy_name": "Pindangan West",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504015",
      "brgy_name": "Poblacion East",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504016",
      "brgy_name": "Poblacion West",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504017",
      "brgy_name": "San Juan",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504018",
      "brgy_name": "San Nicolas",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504019",
      "brgy_name": "San Pedro Apartado",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504020",
      "brgy_name": "San Pedro IlI",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504021",
      "brgy_name": "San Vicente",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015504022",
      "brgy_name": "Vacante",
      "city_code": "015504",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505002",
      "brgy_name": "Awile",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505003",
      "brgy_name": "Awag",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505004",
      "brgy_name": "Batiarao",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505005",
      "brgy_name": "Cabungan",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505006",
      "brgy_name": "Carot",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505007",
      "brgy_name": "Dolaoan",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505008",
      "brgy_name": "Imbo",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505009",
      "brgy_name": "Macaleeng",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505010",
      "brgy_name": "Macandocandong",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505011",
      "brgy_name": "Mal-ong",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505012",
      "brgy_name": "Namagbagan",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505014",
      "brgy_name": "Poblacion",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505015",
      "brgy_name": "Roxas",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505016",
      "brgy_name": "Sablig",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505018",
      "brgy_name": "San Jose",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505019",
      "brgy_name": "Siapar",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505020",
      "brgy_name": "Tondol",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015505021",
      "brgy_name": "Toritori",
      "city_code": "015505",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506002",
      "brgy_name": "Ariston Este",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506003",
      "brgy_name": "Ariston Weste",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506004",
      "brgy_name": "Bantog",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506005",
      "brgy_name": "Baro",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506006",
      "brgy_name": "Bobonan",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506007",
      "brgy_name": "Cabalitian",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506008",
      "brgy_name": "Calepaan",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506009",
      "brgy_name": "Carosucan Norte",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506010",
      "brgy_name": "Carosucan Sur",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506011",
      "brgy_name": "Coldit",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506012",
      "brgy_name": "Domanpot",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506013",
      "brgy_name": "Dupac",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506014",
      "brgy_name": "Macalong",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506015",
      "brgy_name": "Palaris",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506016",
      "brgy_name": "Poblacion East",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506017",
      "brgy_name": "Poblacion West",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506018",
      "brgy_name": "San Vicente Este",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506019",
      "brgy_name": "San Vicente Weste",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506020",
      "brgy_name": "Sanchez",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506021",
      "brgy_name": "Sobol",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015506022",
      "brgy_name": "Toboy",
      "city_code": "015506",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507001",
      "brgy_name": "Angayan Norte",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507002",
      "brgy_name": "Angayan Sur",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507003",
      "brgy_name": "Capulaan",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507004",
      "brgy_name": "Esmeralda",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507005",
      "brgy_name": "Kita-kita",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507006",
      "brgy_name": "Mabini",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507007",
      "brgy_name": "Mauban",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507008",
      "brgy_name": "Poblacion",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507009",
      "brgy_name": "Pugaro",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507010",
      "brgy_name": "Rajal",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507011",
      "brgy_name": "San Andres",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507012",
      "brgy_name": "San Aurelio 1st",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507013",
      "brgy_name": "San Aurelio 2nd",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507014",
      "brgy_name": "San Aurelio 3rd",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507015",
      "brgy_name": "San Joaquin",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507016",
      "brgy_name": "San Julian",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507017",
      "brgy_name": "San Leon",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507018",
      "brgy_name": "San Marcelino",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507019",
      "brgy_name": "San Miguel",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015507020",
      "brgy_name": "San Raymundo",
      "city_code": "015507",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508001",
      "brgy_name": "Ambabaay",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508002",
      "brgy_name": "Aporao",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508003",
      "brgy_name": "Arwas",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508004",
      "brgy_name": "Ballag",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508005",
      "brgy_name": "Banog Norte",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508006",
      "brgy_name": "Banog Sur",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508007",
      "brgy_name": "Centro Toma",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508008",
      "brgy_name": "Colayo",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508009",
      "brgy_name": "Dacap Norte",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508010",
      "brgy_name": "Dacap Sur",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508011",
      "brgy_name": "Garrita",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508012",
      "brgy_name": "Luac",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508013",
      "brgy_name": "Macabit",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508014",
      "brgy_name": "Masidem",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508015",
      "brgy_name": "Poblacion",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508016",
      "brgy_name": "Quinaoayanan",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508017",
      "brgy_name": "Ranao",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508018",
      "brgy_name": "Ranom Iloco",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508019",
      "brgy_name": "San Jose",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508020",
      "brgy_name": "San Miguel",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508021",
      "brgy_name": "San Simon",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508022",
      "brgy_name": "San Vicente",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508023",
      "brgy_name": "Tiep",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508024",
      "brgy_name": "Tipor",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508025",
      "brgy_name": "Tugui Grande",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508026",
      "brgy_name": "Tugui Norte",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015508027",
      "brgy_name": "Calabeng",
      "city_code": "015508",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509001",
      "brgy_name": "Anambongan",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509002",
      "brgy_name": "Bayoyong",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509003",
      "brgy_name": "Cabeldatan",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509004",
      "brgy_name": "Dumpay",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509005",
      "brgy_name": "Malimpec East",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509006",
      "brgy_name": "Mapolopolo",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509007",
      "brgy_name": "Nalneran",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509008",
      "brgy_name": "Navatat",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509009",
      "brgy_name": "Obong",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509010",
      "brgy_name": "Osmena Sr.",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509011",
      "brgy_name": "Palma",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509012",
      "brgy_name": "Patacbo",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015509013",
      "brgy_name": "Poblacion",
      "city_code": "015509",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510001",
      "brgy_name": "Artacho",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510003",
      "brgy_name": "Cabuaan",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510004",
      "brgy_name": "Cacandongan",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510006",
      "brgy_name": "Diaz",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510007",
      "brgy_name": "Nandacan",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510009",
      "brgy_name": "Nibaliw Norte",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510010",
      "brgy_name": "Nibaliw Sur",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510011",
      "brgy_name": "Palisoc",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510012",
      "brgy_name": "Poblacion East",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510013",
      "brgy_name": "Poblacion West",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510014",
      "brgy_name": "Pogo",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510015",
      "brgy_name": "Poponto",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510016",
      "brgy_name": "Primicias",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510017",
      "brgy_name": "Ketegan",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510018",
      "brgy_name": "Sinabaan",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510019",
      "brgy_name": "Vacante",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510020",
      "brgy_name": "Villanueva",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015510021",
      "brgy_name": "Baluyot",
      "city_code": "015510",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511001",
      "brgy_name": "Alinggan",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511002",
      "brgy_name": "Amamperez",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511003",
      "brgy_name": "Amancosiling Norte",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511004",
      "brgy_name": "Amancosiling Sur",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511005",
      "brgy_name": "Ambayat I",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511006",
      "brgy_name": "Ambayat II",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511007",
      "brgy_name": "Apalen",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511008",
      "brgy_name": "Asin",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511009",
      "brgy_name": "Ataynan",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511010",
      "brgy_name": "Bacnono",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511011",
      "brgy_name": "Balaybuaya",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511012",
      "brgy_name": "Banaban",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511013",
      "brgy_name": "Bani",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511014",
      "brgy_name": "Batangcawa",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511015",
      "brgy_name": "Beleng",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511016",
      "brgy_name": "Bical Norte",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511017",
      "brgy_name": "Bical Sur",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511018",
      "brgy_name": "Bongato East",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511019",
      "brgy_name": "Bongato West",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511020",
      "brgy_name": "Buayaen",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511021",
      "brgy_name": "Buenlag 1st",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511022",
      "brgy_name": "Buenlag 2nd",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511023",
      "brgy_name": "Cadre Site",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511024",
      "brgy_name": "Carungay",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511025",
      "brgy_name": "Caturay",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511027",
      "brgy_name": "Duera",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511028",
      "brgy_name": "Dusoc",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511029",
      "brgy_name": "Hermoza",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511030",
      "brgy_name": "Idong",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511031",
      "brgy_name": "Inanlorenzana",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511032",
      "brgy_name": "Inirangan",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511033",
      "brgy_name": "Iton",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511034",
      "brgy_name": "Langiran",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511035",
      "brgy_name": "Ligue",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511036",
      "brgy_name": "M. H. del Pilar",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511037",
      "brgy_name": "Macayocayo",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511038",
      "brgy_name": "Magsaysay",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511039",
      "brgy_name": "Maigpa",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511040",
      "brgy_name": "Malimpec",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511041",
      "brgy_name": "Malioer",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511042",
      "brgy_name": "Managos",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511043",
      "brgy_name": "Manambong Norte",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511044",
      "brgy_name": "Manambong Parte",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511045",
      "brgy_name": "Manambong Sur",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511046",
      "brgy_name": "Mangayao",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511047",
      "brgy_name": "Nalsian Norte",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511048",
      "brgy_name": "Nalsian Sur",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511049",
      "brgy_name": "Pangdel",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511050",
      "brgy_name": "Pantol",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511051",
      "brgy_name": "Paragos",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511053",
      "brgy_name": "Poblacion Sur",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511054",
      "brgy_name": "Pugo",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511055",
      "brgy_name": "Reynado",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511056",
      "brgy_name": "San Gabriel 1st",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511057",
      "brgy_name": "San Gabriel 2nd",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511058",
      "brgy_name": "San Vicente",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511059",
      "brgy_name": "Sangcagulis",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511060",
      "brgy_name": "Sanlibo",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511061",
      "brgy_name": "Sapang",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511062",
      "brgy_name": "Tamaro",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511063",
      "brgy_name": "Tambac",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511064",
      "brgy_name": "Tampog",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511065",
      "brgy_name": "Darawey (Tangal)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511066",
      "brgy_name": "Tanolong",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511067",
      "brgy_name": "Tatarao",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511068",
      "brgy_name": "Telbang",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511069",
      "brgy_name": "Tococ East",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511070",
      "brgy_name": "Tococ West",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511071",
      "brgy_name": "Warding",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511072",
      "brgy_name": "Wawa",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511073",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511074",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511075",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511076",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511077",
      "brgy_name": "Zone V (Pob.)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511078",
      "brgy_name": "Zone VI (Pob.)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015511079",
      "brgy_name": "Zone VII (Pob.)",
      "city_code": "015511",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512001",
      "brgy_name": "Balangobong",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512002",
      "brgy_name": "Bued",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512003",
      "brgy_name": "Bugayong",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512004",
      "brgy_name": "Camangaan",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512005",
      "brgy_name": "Canarvacanan",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512006",
      "brgy_name": "Capas",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512007",
      "brgy_name": "Cili",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512008",
      "brgy_name": "Dumayat",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512009",
      "brgy_name": "Linmansangan",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512011",
      "brgy_name": "Mangcasuy",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512012",
      "brgy_name": "Moreno",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512013",
      "brgy_name": "Pasileng Norte",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512014",
      "brgy_name": "Pasileng Sur",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512015",
      "brgy_name": "Poblacion",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512016",
      "brgy_name": "San Felipe Central",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512017",
      "brgy_name": "San Felipe Sur",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512019",
      "brgy_name": "San Pablo",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512020",
      "brgy_name": "Santa Catalina",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512021",
      "brgy_name": "Santa Maria Norte",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512022",
      "brgy_name": "Santiago",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512023",
      "brgy_name": "Santo Niño",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512024",
      "brgy_name": "Sumabnit",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512025",
      "brgy_name": "Tabuyoc",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015512026",
      "brgy_name": "Vacante",
      "city_code": "015512",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513001",
      "brgy_name": "Amancoro",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513002",
      "brgy_name": "Balagan",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513003",
      "brgy_name": "Balogo",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513004",
      "brgy_name": "Basing",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513005",
      "brgy_name": "Baybay Lopez",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513006",
      "brgy_name": "Baybay Polong",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513007",
      "brgy_name": "Biec",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513008",
      "brgy_name": "Buenlag",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513009",
      "brgy_name": "Calit",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513010",
      "brgy_name": "Caloocan Norte",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513011",
      "brgy_name": "Caloocan Sur",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513012",
      "brgy_name": "Camaley",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513013",
      "brgy_name": "Canaoalan",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513014",
      "brgy_name": "Dulag",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513015",
      "brgy_name": "Gayaman",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513016",
      "brgy_name": "Linoc",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513017",
      "brgy_name": "Lomboy",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513018",
      "brgy_name": "Nagpalangan",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513019",
      "brgy_name": "Malindong",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513020",
      "brgy_name": "Manat",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513021",
      "brgy_name": "Naguilayan",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513022",
      "brgy_name": "Pallas",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513023",
      "brgy_name": "Papagueyan",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513024",
      "brgy_name": "Parayao",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513026",
      "brgy_name": "Poblacion",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513027",
      "brgy_name": "Pototan",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513028",
      "brgy_name": "Sabangan",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513029",
      "brgy_name": "Salapingao",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513030",
      "brgy_name": "San Isidro Norte",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513031",
      "brgy_name": "San Isidro Sur",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513032",
      "brgy_name": "Santa Rosa",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513033",
      "brgy_name": "Tombor",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015513034",
      "brgy_name": "Caloocan Dupo",
      "city_code": "015513",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514001",
      "brgy_name": "Arnedo",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514002",
      "brgy_name": "Balingasay",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514003",
      "brgy_name": "Binabalian",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514004",
      "brgy_name": "Cabuyao",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514005",
      "brgy_name": "Catuday",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514006",
      "brgy_name": "Catungi",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514007",
      "brgy_name": "Concordia (Pob.)",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514008",
      "brgy_name": "Culang",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514009",
      "brgy_name": "Dewey",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514010",
      "brgy_name": "Estanza",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514011",
      "brgy_name": "Germinal (Pob.)",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514012",
      "brgy_name": "Goyoden",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514013",
      "brgy_name": "Ilogmalino",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514014",
      "brgy_name": "Lambes (Lames)",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514015",
      "brgy_name": "Liwa-liwa",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514016",
      "brgy_name": "Lucero",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514017",
      "brgy_name": "Luciente 1.0",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514018",
      "brgy_name": "Luciente 2.0",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514019",
      "brgy_name": "Luna",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514020",
      "brgy_name": "Patar",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514021",
      "brgy_name": "Pilar",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514023",
      "brgy_name": "Salud",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514024",
      "brgy_name": "Samang Norte",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514025",
      "brgy_name": "Samang Sur",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514026",
      "brgy_name": "Sampaloc",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514027",
      "brgy_name": "San Roque",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514028",
      "brgy_name": "Tara",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514029",
      "brgy_name": "Tupa",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514030",
      "brgy_name": "Victory",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015514031",
      "brgy_name": "Zaragoza",
      "city_code": "015514",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515001",
      "brgy_name": "Angarian",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515002",
      "brgy_name": "Asinan",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515003",
      "brgy_name": "Banaga",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515004",
      "brgy_name": "Bacabac",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515005",
      "brgy_name": "Bolaoen",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515006",
      "brgy_name": "Buenlag",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515007",
      "brgy_name": "Cabayaoasan",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515008",
      "brgy_name": "Cayanga",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515009",
      "brgy_name": "Gueset",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515010",
      "brgy_name": "Hacienda",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515011",
      "brgy_name": "Laguit Centro",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515012",
      "brgy_name": "Laguit Padilla",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515013",
      "brgy_name": "Magtaking",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515014",
      "brgy_name": "Pangascasan",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515015",
      "brgy_name": "Pantal",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515016",
      "brgy_name": "Poblacion",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515017",
      "brgy_name": "Polong",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515018",
      "brgy_name": "Portic",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515019",
      "brgy_name": "Salasa",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515020",
      "brgy_name": "Salomague Norte",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515021",
      "brgy_name": "Salomague Sur",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515022",
      "brgy_name": "Samat",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515023",
      "brgy_name": "San Francisco",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015515024",
      "brgy_name": "Umanday",
      "city_code": "015515",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516001",
      "brgy_name": "Anapao (Bur Anapac)",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516003",
      "brgy_name": "Cacayasen",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516004",
      "brgy_name": "Concordia",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516005",
      "brgy_name": "Ilio-ilio (Iliw-iliw)",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516006",
      "brgy_name": "Papallasen",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516007",
      "brgy_name": "Poblacion",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516008",
      "brgy_name": "Pogoruac",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516009",
      "brgy_name": "Don Matias",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516010",
      "brgy_name": "San Miguel",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516011",
      "brgy_name": "San Pascual",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516012",
      "brgy_name": "San Vicente",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516013",
      "brgy_name": "Sapa Grande",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516014",
      "brgy_name": "Sapa Pequeña",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015516015",
      "brgy_name": "Tambacan",
      "city_code": "015516",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517001",
      "brgy_name": "Ambonao",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517002",
      "brgy_name": "Ambuetel",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517003",
      "brgy_name": "Banaoang",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517004",
      "brgy_name": "Bued",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517005",
      "brgy_name": "Buenlag",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517006",
      "brgy_name": "Cabilocaan",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517007",
      "brgy_name": "Dinalaoan",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517008",
      "brgy_name": "Doyong",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517009",
      "brgy_name": "Gabon",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517010",
      "brgy_name": "Lasip",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517011",
      "brgy_name": "Longos",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517012",
      "brgy_name": "Lumbang",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517013",
      "brgy_name": "Macabito",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517014",
      "brgy_name": "Malabago",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517015",
      "brgy_name": "Mancup",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517016",
      "brgy_name": "Nagsaing",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517017",
      "brgy_name": "Nalsian",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517018",
      "brgy_name": "Poblacion East",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517019",
      "brgy_name": "Poblacion West",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517020",
      "brgy_name": "Quesban",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517022",
      "brgy_name": "San Miguel",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517023",
      "brgy_name": "San Vicente",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517024",
      "brgy_name": "Songkoy",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015517025",
      "brgy_name": "Talibaew",
      "city_code": "015517",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518001",
      "brgy_name": "Bacayao Norte",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518002",
      "brgy_name": "Bacayao Sur",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518003",
      "brgy_name": "Barangay II (Nueva)",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518004",
      "brgy_name": "Barangay IV (Zamora)",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518006",
      "brgy_name": "Bolosan",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518007",
      "brgy_name": "Bonuan Binloc",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518008",
      "brgy_name": "Bonuan Boquig",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518009",
      "brgy_name": "Bonuan Gueset",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518010",
      "brgy_name": "Calmay",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518011",
      "brgy_name": "Carael",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518012",
      "brgy_name": "Caranglaan",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518013",
      "brgy_name": "Herrero",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518014",
      "brgy_name": "Lasip Chico",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518015",
      "brgy_name": "Lasip Grande",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518016",
      "brgy_name": "Lomboy",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518017",
      "brgy_name": "Lucao",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518018",
      "brgy_name": "Malued",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518019",
      "brgy_name": "Mamalingling",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518020",
      "brgy_name": "Mangin",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518021",
      "brgy_name": "Mayombo",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518022",
      "brgy_name": "Pantal",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518023",
      "brgy_name": "Poblacion Oeste",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518024",
      "brgy_name": "Barangay I (T. Bugallon)",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518025",
      "brgy_name": "Pogo Chico",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518026",
      "brgy_name": "Pogo Grande",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518027",
      "brgy_name": "Pugaro Suit",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518028",
      "brgy_name": "Salapingao",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518029",
      "brgy_name": "Salisay",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518030",
      "brgy_name": "Tambac",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518031",
      "brgy_name": "Tapuac",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015518032",
      "brgy_name": "Tebeng",
      "city_code": "015518",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519001",
      "brgy_name": "Alilao",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519002",
      "brgy_name": "Amalbalan",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519003",
      "brgy_name": "Bobonot",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519004",
      "brgy_name": "Eguia",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519005",
      "brgy_name": "Gais-Guipe",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519006",
      "brgy_name": "Hermosa",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519007",
      "brgy_name": "Macalang",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519008",
      "brgy_name": "Magsaysay",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519010",
      "brgy_name": "Malacapas",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519011",
      "brgy_name": "Malimpin",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519012",
      "brgy_name": "Osmeña",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519013",
      "brgy_name": "Petal",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519014",
      "brgy_name": "Poblacion",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519015",
      "brgy_name": "San Vicente",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519016",
      "brgy_name": "Tambac",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519017",
      "brgy_name": "Tambobong",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519018",
      "brgy_name": "Uli",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015519019",
      "brgy_name": "Viga",
      "city_code": "015519",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520002",
      "brgy_name": "Bamban",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520003",
      "brgy_name": "Batang",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520004",
      "brgy_name": "Bayambang",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520005",
      "brgy_name": "Cato",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520006",
      "brgy_name": "Doliman",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520007",
      "brgy_name": "Fatima",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520008",
      "brgy_name": "Maya",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520009",
      "brgy_name": "Nangalisan",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520010",
      "brgy_name": "Nayom",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520011",
      "brgy_name": "Pita",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520012",
      "brgy_name": "Poblacion",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520013",
      "brgy_name": "Potol",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015520014",
      "brgy_name": "Babuyan",
      "city_code": "015520",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521001",
      "brgy_name": "Bolo",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521002",
      "brgy_name": "Bongalon",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521003",
      "brgy_name": "Dulig",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521004",
      "brgy_name": "Laois",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521005",
      "brgy_name": "Magsaysay",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521006",
      "brgy_name": "Poblacion",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521007",
      "brgy_name": "San Gonzalo",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521008",
      "brgy_name": "San Jose",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521009",
      "brgy_name": "Tobuan",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015521010",
      "brgy_name": "Uyong",
      "city_code": "015521",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522001",
      "brgy_name": "Aliwekwek",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522002",
      "brgy_name": "Baay",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522003",
      "brgy_name": "Balangobong",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522004",
      "brgy_name": "Balococ",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522006",
      "brgy_name": "Bantayan",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522007",
      "brgy_name": "Basing",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522008",
      "brgy_name": "Capandanan",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522009",
      "brgy_name": "Domalandan Center",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522010",
      "brgy_name": "Domalandan East",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522011",
      "brgy_name": "Domalandan West",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522013",
      "brgy_name": "Dorongan",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522014",
      "brgy_name": "Dulag",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522015",
      "brgy_name": "Estanza",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522016",
      "brgy_name": "Lasip",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522017",
      "brgy_name": "Libsong East",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522018",
      "brgy_name": "Libsong West",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522019",
      "brgy_name": "Malawa",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522020",
      "brgy_name": "Malimpuec",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522021",
      "brgy_name": "Maniboc",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522022",
      "brgy_name": "Matalava",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522023",
      "brgy_name": "Naguelguel",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522025",
      "brgy_name": "Namolan",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522026",
      "brgy_name": "Pangapisan North",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522027",
      "brgy_name": "Pangapisan Sur",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522028",
      "brgy_name": "Poblacion",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522029",
      "brgy_name": "Quibaol",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522030",
      "brgy_name": "Rosario",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522031",
      "brgy_name": "Sabangan",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522032",
      "brgy_name": "Talogtog",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522033",
      "brgy_name": "Tonton",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522034",
      "brgy_name": "Tumbar",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015522035",
      "brgy_name": "Wawa",
      "city_code": "015522",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523002",
      "brgy_name": "Bacnit",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523003",
      "brgy_name": "Barlo",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523005",
      "brgy_name": "Caabiangaan",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523006",
      "brgy_name": "Cabanaetan",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523007",
      "brgy_name": "Cabinuangan",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523008",
      "brgy_name": "Calzada",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523009",
      "brgy_name": "Caranglaan",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523010",
      "brgy_name": "De Guzman",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523012",
      "brgy_name": "Luna",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523013",
      "brgy_name": "Magalong",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523014",
      "brgy_name": "Nibaliw",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523015",
      "brgy_name": "Patar",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523016",
      "brgy_name": "Poblacion",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523017",
      "brgy_name": "San Pedro",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523018",
      "brgy_name": "Tagudin",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015523020",
      "brgy_name": "Villacorta",
      "city_code": "015523",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524001",
      "brgy_name": "Abonagan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524002",
      "brgy_name": "Agdao",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524003",
      "brgy_name": "Alacan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524004",
      "brgy_name": "Aliaga",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524005",
      "brgy_name": "Amacalan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524006",
      "brgy_name": "Anolid",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524007",
      "brgy_name": "Apaya",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524008",
      "brgy_name": "Asin Este",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524009",
      "brgy_name": "Asin Weste",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524010",
      "brgy_name": "Bacundao Este",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524011",
      "brgy_name": "Bacundao Weste",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524012",
      "brgy_name": "Bakitiw",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524013",
      "brgy_name": "Balite",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524014",
      "brgy_name": "Banawang",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524015",
      "brgy_name": "Barang",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524016",
      "brgy_name": "Bawer",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524017",
      "brgy_name": "Binalay",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524018",
      "brgy_name": "Bobon",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524019",
      "brgy_name": "Bolaoit",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524020",
      "brgy_name": "Bongar",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524021",
      "brgy_name": "Butao",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524022",
      "brgy_name": "Cabatling",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524023",
      "brgy_name": "Cabueldatan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524026",
      "brgy_name": "Calbueg",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524027",
      "brgy_name": "Canan Norte",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524028",
      "brgy_name": "Canan Sur",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524029",
      "brgy_name": "Cawayan Bogtong",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524031",
      "brgy_name": "Don Pedro",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524032",
      "brgy_name": "Gatang",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524033",
      "brgy_name": "Goliman",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524034",
      "brgy_name": "Gomez",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524035",
      "brgy_name": "Guilig",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524036",
      "brgy_name": "Ican",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524037",
      "brgy_name": "Ingalagala",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524038",
      "brgy_name": "Lareg-lareg",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524039",
      "brgy_name": "Lasip",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524040",
      "brgy_name": "Lepa",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524041",
      "brgy_name": "Loqueb Este",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524042",
      "brgy_name": "Loqueb Norte",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524043",
      "brgy_name": "Loqueb Sur",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524044",
      "brgy_name": "Lunec",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524045",
      "brgy_name": "Mabulitec",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524047",
      "brgy_name": "Malimpec",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524048",
      "brgy_name": "Manggan-Dampay",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524049",
      "brgy_name": "Nancapian",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524050",
      "brgy_name": "Nalsian Norte",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524051",
      "brgy_name": "Nalsian Sur",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524053",
      "brgy_name": "Nansangaan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524054",
      "brgy_name": "Olea",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524055",
      "brgy_name": "Pacuan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524056",
      "brgy_name": "Palapar Norte",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524057",
      "brgy_name": "Palapar Sur",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524058",
      "brgy_name": "Palong",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524059",
      "brgy_name": "Pamaranum",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524060",
      "brgy_name": "Pasima",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524061",
      "brgy_name": "Payar",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524062",
      "brgy_name": "Poblacion",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524063",
      "brgy_name": "Polong Norte",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524064",
      "brgy_name": "Polong Sur",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524065",
      "brgy_name": "Potiocan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524066",
      "brgy_name": "San Julian",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524067",
      "brgy_name": "Tabo-Sili",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524068",
      "brgy_name": "Tobor",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524069",
      "brgy_name": "Talospatang",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524070",
      "brgy_name": "Taloy",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524071",
      "brgy_name": "Taloyan",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524072",
      "brgy_name": "Tambac",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524073",
      "brgy_name": "Tolonguat",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524074",
      "brgy_name": "Tomling",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524075",
      "brgy_name": "Umando",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524076",
      "brgy_name": "Viado",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524077",
      "brgy_name": "Waig",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015524078",
      "brgy_name": "Warey",
      "city_code": "015524",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525002",
      "brgy_name": "Babasit",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525003",
      "brgy_name": "Baguinay",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525004",
      "brgy_name": "Baritao",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525005",
      "brgy_name": "Bisal",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525007",
      "brgy_name": "Bucao",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525009",
      "brgy_name": "Cabanbanan",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525014",
      "brgy_name": "Calaocan",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525019",
      "brgy_name": "Inamotan",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525023",
      "brgy_name": "Lelemaan",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525024",
      "brgy_name": "Licsi",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525025",
      "brgy_name": "Lipit Norte",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525026",
      "brgy_name": "Lipit Sur",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525027",
      "brgy_name": "Parian",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525028",
      "brgy_name": "Matolong",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525030",
      "brgy_name": "Mermer",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525031",
      "brgy_name": "Nalsian",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525033",
      "brgy_name": "Oraan East",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525034",
      "brgy_name": "Oraan West",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525036",
      "brgy_name": "Pantal",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525037",
      "brgy_name": "Pao",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525039",
      "brgy_name": "Poblacion",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525040",
      "brgy_name": "Pugaro",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525041",
      "brgy_name": "San Ramon",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525042",
      "brgy_name": "Santa Ines",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525043",
      "brgy_name": "Sapang",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015525045",
      "brgy_name": "Tebuel",
      "city_code": "015525",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526001",
      "brgy_name": "Alitaya",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526002",
      "brgy_name": "Amansabina",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526003",
      "brgy_name": "Anolid",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526004",
      "brgy_name": "Banaoang",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526005",
      "brgy_name": "Bantayan",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526006",
      "brgy_name": "Bari",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526007",
      "brgy_name": "Bateng",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526008",
      "brgy_name": "Buenlag",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526009",
      "brgy_name": "David",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526010",
      "brgy_name": "Embarcadero",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526011",
      "brgy_name": "Gueguesangen",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526012",
      "brgy_name": "Guesang",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526013",
      "brgy_name": "Guiguilonen",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526014",
      "brgy_name": "Guilig",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526015",
      "brgy_name": "Inlambo",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526016",
      "brgy_name": "Lanas",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526017",
      "brgy_name": "Landas",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526018",
      "brgy_name": "Maasin",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526019",
      "brgy_name": "Macayug",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526020",
      "brgy_name": "Malabago",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526021",
      "brgy_name": "Navaluan",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526022",
      "brgy_name": "Nibaliw",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526023",
      "brgy_name": "Osiem",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526024",
      "brgy_name": "Palua",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526025",
      "brgy_name": "Poblacion",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526026",
      "brgy_name": "Pogo",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526027",
      "brgy_name": "Salaan",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526028",
      "brgy_name": "Salay",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526029",
      "brgy_name": "Tebag",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015526030",
      "brgy_name": "Talogtog",
      "city_code": "015526",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527001",
      "brgy_name": "Andangin",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527002",
      "brgy_name": "Arellano Street (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527003",
      "brgy_name": "Bantay",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527004",
      "brgy_name": "Bantocaling",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527005",
      "brgy_name": "Baracbac",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527006",
      "brgy_name": "Peania Pedania (Bedania)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527007",
      "brgy_name": "Bogtong Bolo",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527008",
      "brgy_name": "Bogtong Bunao",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527009",
      "brgy_name": "Bogtong Centro",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527010",
      "brgy_name": "Bogtong Niog",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527011",
      "brgy_name": "Bogtong Silag",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527012",
      "brgy_name": "Buaya",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527013",
      "brgy_name": "Buenlag",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527014",
      "brgy_name": "Bueno",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527015",
      "brgy_name": "Bunagan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527017",
      "brgy_name": "Bunlalacao",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527018",
      "brgy_name": "Burgos Street (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527019",
      "brgy_name": "Cabaluyan 1st",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527020",
      "brgy_name": "Cabaluyan 2nd",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527021",
      "brgy_name": "Cabarabuan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527022",
      "brgy_name": "Cabaruan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527023",
      "brgy_name": "Cabayaoasan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527024",
      "brgy_name": "Cabayugan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527025",
      "brgy_name": "Cacaoiten",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527026",
      "brgy_name": "Calumboyan Norte",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527027",
      "brgy_name": "Calumboyan Sur",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527028",
      "brgy_name": "Calvo (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527029",
      "brgy_name": "Casilagan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527030",
      "brgy_name": "Catarataraan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527031",
      "brgy_name": "Caturay Norte",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527032",
      "brgy_name": "Caturay Sur",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527033",
      "brgy_name": "Caviernesan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527034",
      "brgy_name": "Dorongan Ketaket",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527035",
      "brgy_name": "Dorongan Linmansangan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527036",
      "brgy_name": "Dorongan Punta",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527037",
      "brgy_name": "Dorongan Sawat",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527038",
      "brgy_name": "Dorongan Valerio",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527039",
      "brgy_name": "General Luna (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527040",
      "brgy_name": "Historia",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527041",
      "brgy_name": "Lawak Langka",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527042",
      "brgy_name": "Linmansangan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527043",
      "brgy_name": "Lopez (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527044",
      "brgy_name": "Mabini (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527045",
      "brgy_name": "Macarang",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527046",
      "brgy_name": "Malabobo",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527047",
      "brgy_name": "Malibong",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527048",
      "brgy_name": "Malunec",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527049",
      "brgy_name": "Maravilla (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527050",
      "brgy_name": "Maravilla-Arellano Ext. (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527051",
      "brgy_name": "Muelang",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527052",
      "brgy_name": "Naguilayan East",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527053",
      "brgy_name": "Naguilayan West",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527054",
      "brgy_name": "Nancasalan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527055",
      "brgy_name": "Niog-Cabison-Bulaney",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527056",
      "brgy_name": "Olegario-Caoile (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527057",
      "brgy_name": "Olo Cacamposan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527058",
      "brgy_name": "Olo Cafabrosan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527059",
      "brgy_name": "Olo Cagarlitan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527060",
      "brgy_name": "Osmeña (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527061",
      "brgy_name": "Pacalat",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527062",
      "brgy_name": "Pampano",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527063",
      "brgy_name": "Parian",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527064",
      "brgy_name": "Paul",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527065",
      "brgy_name": "Pogon-Aniat",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527066",
      "brgy_name": "Pogon-Lomboy (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527067",
      "brgy_name": "Ponglo-Baleg",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527068",
      "brgy_name": "Ponglo-Muelag",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527069",
      "brgy_name": "Quetegan (Pogon-Baleg)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527070",
      "brgy_name": "Quezon (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527071",
      "brgy_name": "Salavante",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527072",
      "brgy_name": "Sapang",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527073",
      "brgy_name": "Sonson Ongkit",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527074",
      "brgy_name": "Suaco",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527075",
      "brgy_name": "Tagac",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527076",
      "brgy_name": "Takipan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527077",
      "brgy_name": "Talogtog",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527078",
      "brgy_name": "Tococ Barikir",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527079",
      "brgy_name": "Torre 1st",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527080",
      "brgy_name": "Torre 2nd",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527081",
      "brgy_name": "Torres Bugallon (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527082",
      "brgy_name": "Umangan",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015527083",
      "brgy_name": "Zamora (Pob.)",
      "city_code": "015527",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528001",
      "brgy_name": "Amanoaoac",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528002",
      "brgy_name": "Apaya",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528003",
      "brgy_name": "Aserda",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528004",
      "brgy_name": "Baloling",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528006",
      "brgy_name": "Coral",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528007",
      "brgy_name": "Golden",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528008",
      "brgy_name": "Jimenez",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528009",
      "brgy_name": "Lambayan",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528010",
      "brgy_name": "Luyan (Luyan South)",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528012",
      "brgy_name": "Nilombot",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528013",
      "brgy_name": "Pias",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528014",
      "brgy_name": "Poblacion",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528015",
      "brgy_name": "Primicias",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528016",
      "brgy_name": "Santa Maria (Luyan North)",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015528018",
      "brgy_name": "Torres",
      "city_code": "015528",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529001",
      "brgy_name": "Barangobong",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529003",
      "brgy_name": "Batchelor East",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529004",
      "brgy_name": "Batchelor West",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529005",
      "brgy_name": "Burgos (San Narciso)",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529006",
      "brgy_name": "Cacandungan",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529007",
      "brgy_name": "Calapugan",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529008",
      "brgy_name": "Canarem",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529009",
      "brgy_name": "Luna",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529011",
      "brgy_name": "Poblacion East",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529012",
      "brgy_name": "Poblacion West",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529013",
      "brgy_name": "Rizal",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529014",
      "brgy_name": "Salud",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529015",
      "brgy_name": "San Eugenio",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529017",
      "brgy_name": "San Macario Norte",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529018",
      "brgy_name": "San Macario Sur",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529019",
      "brgy_name": "San Maximo",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529020",
      "brgy_name": "San Miguel",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015529022",
      "brgy_name": "Silag",
      "city_code": "015529",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530001",
      "brgy_name": "Alipangpang",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530002",
      "brgy_name": "Amagbagan",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530003",
      "brgy_name": "Balacag",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530004",
      "brgy_name": "Banding",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530006",
      "brgy_name": "Bantugan",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530007",
      "brgy_name": "Batakil",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530008",
      "brgy_name": "Bobonan",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530009",
      "brgy_name": "Buneg",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530010",
      "brgy_name": "Cablong",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530011",
      "brgy_name": "Castaño",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530012",
      "brgy_name": "Dilan",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530013",
      "brgy_name": "Don Benito",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530014",
      "brgy_name": "Haway",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530015",
      "brgy_name": "Imbalbalatong",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530016",
      "brgy_name": "Inoman",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530017",
      "brgy_name": "Laoac",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530018",
      "brgy_name": "Maambal",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530019",
      "brgy_name": "Malasin",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530020",
      "brgy_name": "Malokiat",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530021",
      "brgy_name": "Manaol",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530022",
      "brgy_name": "Nama",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530023",
      "brgy_name": "Nantangalan",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530024",
      "brgy_name": "Palacpalac",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530025",
      "brgy_name": "Palguyod",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530026",
      "brgy_name": "Poblacion I",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530027",
      "brgy_name": "Poblacion II",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530028",
      "brgy_name": "Poblacion III",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530029",
      "brgy_name": "Poblacion IV",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530030",
      "brgy_name": "Rosario",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530031",
      "brgy_name": "Sugcong",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530032",
      "brgy_name": "Talogtog",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530033",
      "brgy_name": "Tulnac",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530034",
      "brgy_name": "Villegas",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015530035",
      "brgy_name": "Casanfernandoan",
      "city_code": "015530",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531001",
      "brgy_name": "Acop",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531002",
      "brgy_name": "Bakitbakit",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531003",
      "brgy_name": "Balingcanaway",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531004",
      "brgy_name": "Cabalaoangan Norte",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531005",
      "brgy_name": "Cabalaoangan Sur",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531006",
      "brgy_name": "Camangaan",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531007",
      "brgy_name": "Capitan Tomas",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531008",
      "brgy_name": "Carmay West",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531009",
      "brgy_name": "Carmen East",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531010",
      "brgy_name": "Carmen West",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531012",
      "brgy_name": "Casanicolasan",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531013",
      "brgy_name": "Coliling",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531014",
      "brgy_name": "Calanutan (Don Felix Coloma)",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531015",
      "brgy_name": "Guiling",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531016",
      "brgy_name": "Palakipak",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531017",
      "brgy_name": "Pangaoan",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531018",
      "brgy_name": "Rabago",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531019",
      "brgy_name": "Rizal",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531020",
      "brgy_name": "Salvacion",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531021",
      "brgy_name": "San Antonio",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531022",
      "brgy_name": "San Bartolome",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531023",
      "brgy_name": "San Isidro",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531024",
      "brgy_name": "San Luis",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531025",
      "brgy_name": "San Pedro East",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531026",
      "brgy_name": "San Pedro West",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531027",
      "brgy_name": "San Vicente",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531028",
      "brgy_name": "San Angel",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531029",
      "brgy_name": "Station District",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531031",
      "brgy_name": "Tomana East",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531032",
      "brgy_name": "Tomana West",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531033",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531034",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531035",
      "brgy_name": "Carmay East",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531036",
      "brgy_name": "Don Antonio Village",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531037",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531038",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015531039",
      "brgy_name": "Zone V (Pob.)",
      "city_code": "015531",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532001",
      "brgy_name": "Abanon",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532002",
      "brgy_name": "Agdao",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532003",
      "brgy_name": "Anando",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532004",
      "brgy_name": "Ano",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532005",
      "brgy_name": "Antipangol",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532006",
      "brgy_name": "Aponit",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532007",
      "brgy_name": "Bacnar",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532008",
      "brgy_name": "Balaya",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532009",
      "brgy_name": "Balayong",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532010",
      "brgy_name": "Baldog",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532011",
      "brgy_name": "Balite Sur",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532012",
      "brgy_name": "Balococ",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532013",
      "brgy_name": "Bani",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532014",
      "brgy_name": "Bega",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532015",
      "brgy_name": "Bocboc",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532016",
      "brgy_name": "Bugallon-Posadas Street (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532017",
      "brgy_name": "Bogaoan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532018",
      "brgy_name": "Bolingit",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532019",
      "brgy_name": "Bolosan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532020",
      "brgy_name": "Bonifacio (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532021",
      "brgy_name": "Buenglat",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532022",
      "brgy_name": "Burgos Padlan (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532023",
      "brgy_name": "Cacaritan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532024",
      "brgy_name": "Caingal",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532025",
      "brgy_name": "Calobaoan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532026",
      "brgy_name": "Calomboyan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532027",
      "brgy_name": "Capataan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532028",
      "brgy_name": "Caoayan-Kiling",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532029",
      "brgy_name": "Cobol",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532030",
      "brgy_name": "Coliling",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532031",
      "brgy_name": "Cruz",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532032",
      "brgy_name": "Doyong",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532035",
      "brgy_name": "Gamata",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532036",
      "brgy_name": "Guelew",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532037",
      "brgy_name": "Ilang",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532038",
      "brgy_name": "Inerangan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532039",
      "brgy_name": "Isla",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532040",
      "brgy_name": "Libas",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532041",
      "brgy_name": "Lilimasan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532043",
      "brgy_name": "Longos",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532044",
      "brgy_name": "Lucban (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532045",
      "brgy_name": "Mabalbalino",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532046",
      "brgy_name": "Mabini (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532047",
      "brgy_name": "Magtaking",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532048",
      "brgy_name": "Malacañang",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532050",
      "brgy_name": "Maliwara",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532051",
      "brgy_name": "Mamarlao",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532052",
      "brgy_name": "Manzon",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532053",
      "brgy_name": "Matagdem",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532054",
      "brgy_name": "Mestizo Norte",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532055",
      "brgy_name": "Naguilayan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532056",
      "brgy_name": "Nilentap",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532057",
      "brgy_name": "Padilla-Gomez",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532058",
      "brgy_name": "Pagal",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532060",
      "brgy_name": "Palaming",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532061",
      "brgy_name": "Palaris (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532062",
      "brgy_name": "Palospos",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532063",
      "brgy_name": "Pangalangan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532064",
      "brgy_name": "Pangoloan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532065",
      "brgy_name": "Pangpang",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532066",
      "brgy_name": "Paitan-Panoypoy",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532067",
      "brgy_name": "Parayao",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532069",
      "brgy_name": "Payapa",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532070",
      "brgy_name": "Payar",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532071",
      "brgy_name": "Perez Boulevard (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532072",
      "brgy_name": "Polo",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532073",
      "brgy_name": "Quezon Boulevard (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532074",
      "brgy_name": "Quintong",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532075",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532076",
      "brgy_name": "Roxas Boulevard (Pob.)",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532077",
      "brgy_name": "Salinap",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532078",
      "brgy_name": "San Juan",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532079",
      "brgy_name": "San Pedro-Taloy",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532080",
      "brgy_name": "Sapinit",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532081",
      "brgy_name": "PNR Station Site",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532082",
      "brgy_name": "Supo",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532083",
      "brgy_name": "Talang",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532084",
      "brgy_name": "Tamayo",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532085",
      "brgy_name": "Tandoc",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532086",
      "brgy_name": "Tarece",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532087",
      "brgy_name": "Tarectec",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532088",
      "brgy_name": "Tayambani",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532089",
      "brgy_name": "Tebag",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532090",
      "brgy_name": "Turac",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532091",
      "brgy_name": "M. Soriano",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015532092",
      "brgy_name": "Tandang Sora",
      "city_code": "015532",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533001",
      "brgy_name": "Ambalangan-Dalin",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533002",
      "brgy_name": "Angio",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533003",
      "brgy_name": "Anonang",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533004",
      "brgy_name": "Aramal",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533006",
      "brgy_name": "Bigbiga",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533007",
      "brgy_name": "Binday",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533008",
      "brgy_name": "Bolaoen",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533009",
      "brgy_name": "Bolasi",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533010",
      "brgy_name": "Cayanga",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533011",
      "brgy_name": "Gomot",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533012",
      "brgy_name": "Inmalog",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533013",
      "brgy_name": "Lekep-Butao",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533014",
      "brgy_name": "Longos",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533015",
      "brgy_name": "Mabilao",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533016",
      "brgy_name": "Nibaliw Central",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533017",
      "brgy_name": "Nibaliw East",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533018",
      "brgy_name": "Nibaliw Magliba",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533019",
      "brgy_name": "Palapad",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533020",
      "brgy_name": "Poblacion",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533021",
      "brgy_name": "Rabon",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533022",
      "brgy_name": "Sagud-Bahley",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533023",
      "brgy_name": "Sobol",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533024",
      "brgy_name": "Tempra-Guilig",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533025",
      "brgy_name": "Tocok",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533027",
      "brgy_name": "Lipit-Tomeeng",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533028",
      "brgy_name": "Colisao",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533029",
      "brgy_name": "Nibaliw Narvarte (Nibaliw West Compound)",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533030",
      "brgy_name": "Nibaliw Vidal (Nibaliw West Proper)",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533031",
      "brgy_name": "Alacan",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533032",
      "brgy_name": "Cabaruan",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533033",
      "brgy_name": "Inmalog Norte",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533034",
      "brgy_name": "Longos-Amangonan-Parac-Parac Fabrica",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533035",
      "brgy_name": "Longos Proper",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015533036",
      "brgy_name": "Tiblong",
      "city_code": "015533",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534001",
      "brgy_name": "Awai",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534002",
      "brgy_name": "Bolo",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534003",
      "brgy_name": "Capaoay (Pob.)",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534004",
      "brgy_name": "Casibong",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534005",
      "brgy_name": "Imelda (Decrito)",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534006",
      "brgy_name": "Guibel",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534007",
      "brgy_name": "Labney",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534009",
      "brgy_name": "Magsaysay (Capay)",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534010",
      "brgy_name": "Lobong",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534011",
      "brgy_name": "Macayug",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534013",
      "brgy_name": "Bagong Pag-asa (Poblacion East)",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534014",
      "brgy_name": "San Guillermo (Poblacion West)",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534015",
      "brgy_name": "San Jose",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534016",
      "brgy_name": "San Juan",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534017",
      "brgy_name": "San Roque",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534018",
      "brgy_name": "San Vicente",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534019",
      "brgy_name": "Santa Cruz",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534020",
      "brgy_name": "Santa Maria",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015534021",
      "brgy_name": "Santo Tomas",
      "city_code": "015534",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535001",
      "brgy_name": "San Antonio-Arzadon",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535003",
      "brgy_name": "Cabacaraan",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535004",
      "brgy_name": "Cabaritan",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535005",
      "brgy_name": "Flores",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535007",
      "brgy_name": "Guiset Norte (Pob.)",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535008",
      "brgy_name": "Guiset Sur (Pob.)",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535009",
      "brgy_name": "Lapalo",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535010",
      "brgy_name": "Nagsaag",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535011",
      "brgy_name": "Narra",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535013",
      "brgy_name": "San Bonifacio",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535014",
      "brgy_name": "San Juan",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535015",
      "brgy_name": "San Roque",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535016",
      "brgy_name": "San Vicente",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015535018",
      "brgy_name": "Santo Domingo",
      "city_code": "015535",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536001",
      "brgy_name": "Bensican",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536002",
      "brgy_name": "Cabitnongan",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536003",
      "brgy_name": "Caboloan",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536005",
      "brgy_name": "Cacabugaoan",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536006",
      "brgy_name": "Calanutian",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536007",
      "brgy_name": "Calaocan",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536008",
      "brgy_name": "Camanggaan",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536009",
      "brgy_name": "Camindoroan",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536010",
      "brgy_name": "Casaratan",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536011",
      "brgy_name": "Dalumpinas",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536013",
      "brgy_name": "Fianza",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536015",
      "brgy_name": "Lungao",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536016",
      "brgy_name": "Malico",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536017",
      "brgy_name": "Malilion",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536018",
      "brgy_name": "Nagkaysa",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536019",
      "brgy_name": "Nining",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536020",
      "brgy_name": "Poblacion East",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536021",
      "brgy_name": "Poblacion West",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536022",
      "brgy_name": "Salingcob",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536023",
      "brgy_name": "Salpad",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536024",
      "brgy_name": "San Felipe East",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536025",
      "brgy_name": "San Felipe West",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536026",
      "brgy_name": "San Isidro (Sta. Cruzan)",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536027",
      "brgy_name": "San Jose",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536028",
      "brgy_name": "San Rafael Centro",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536029",
      "brgy_name": "San Rafael East",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536030",
      "brgy_name": "San Rafael West",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536031",
      "brgy_name": "San Roque",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536032",
      "brgy_name": "Santa Maria East",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536033",
      "brgy_name": "Santa Maria West",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536034",
      "brgy_name": "Santo Tomas",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536035",
      "brgy_name": "Siblot",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015536036",
      "brgy_name": "Sobol",
      "city_code": "015536",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537001",
      "brgy_name": "Alac",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537002",
      "brgy_name": "Baligayan",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537004",
      "brgy_name": "Bantog",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537005",
      "brgy_name": "Bolintaguen",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537006",
      "brgy_name": "Cabangaran",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537007",
      "brgy_name": "Cabalaoangan",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537009",
      "brgy_name": "Calomboyan",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537010",
      "brgy_name": "Carayacan",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537011",
      "brgy_name": "Casantamarian",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537012",
      "brgy_name": "Gonzalo",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537013",
      "brgy_name": "Labuan",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537014",
      "brgy_name": "Lagasit",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537015",
      "brgy_name": "Lumayao",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537016",
      "brgy_name": "Mabini",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537017",
      "brgy_name": "Mantacdang",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537018",
      "brgy_name": "Nangapugan",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537020",
      "brgy_name": "San Pedro",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537021",
      "brgy_name": "Ungib",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537022",
      "brgy_name": "Poblacion Zone I",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537023",
      "brgy_name": "Poblacion Zone II",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015537024",
      "brgy_name": "Poblacion Zone III",
      "city_code": "015537",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538001",
      "brgy_name": "Alibago",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538002",
      "brgy_name": "Balingueo",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538003",
      "brgy_name": "Banaoang",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538004",
      "brgy_name": "Banzal",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538005",
      "brgy_name": "Botao",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538006",
      "brgy_name": "Cablong",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538007",
      "brgy_name": "Carusocan",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538008",
      "brgy_name": "Dalongue",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538009",
      "brgy_name": "Erfe",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538010",
      "brgy_name": "Gueguesangen",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538011",
      "brgy_name": "Leet",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538012",
      "brgy_name": "Malanay",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538013",
      "brgy_name": "Maningding",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538014",
      "brgy_name": "Maronong",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538015",
      "brgy_name": "Maticmatic",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538016",
      "brgy_name": "Minien East",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538017",
      "brgy_name": "Minien West",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538018",
      "brgy_name": "Nilombot",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538019",
      "brgy_name": "Patayac",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538020",
      "brgy_name": "Payas",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538021",
      "brgy_name": "Poblacion Norte",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538022",
      "brgy_name": "Poblacion Sur",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538023",
      "brgy_name": "Sapang",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538024",
      "brgy_name": "Sonquil",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538025",
      "brgy_name": "Tebag East",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538026",
      "brgy_name": "Tebag West",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538027",
      "brgy_name": "Tuliao",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538028",
      "brgy_name": "Ventinilla (Ventinilla East)",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015538029",
      "brgy_name": "Primicias (Ventinilla West)",
      "city_code": "015538",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539001",
      "brgy_name": "Bal-loy",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539002",
      "brgy_name": "Bantog",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539003",
      "brgy_name": "Caboluan",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539004",
      "brgy_name": "Cal-litang",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539005",
      "brgy_name": "Capandanan",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539006",
      "brgy_name": "Cauplasan",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539008",
      "brgy_name": "Dalayap",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539009",
      "brgy_name": "Libsong",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539010",
      "brgy_name": "Namagbagan",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539011",
      "brgy_name": "Paitan",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539012",
      "brgy_name": "Pataquid",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539013",
      "brgy_name": "Pilar",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539014",
      "brgy_name": "Poblacion East",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539015",
      "brgy_name": "Poblacion West",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539016",
      "brgy_name": "Pugot",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539017",
      "brgy_name": "Samon",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539018",
      "brgy_name": "San Alejandro",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539020",
      "brgy_name": "San Mariano",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539021",
      "brgy_name": "San Pablo",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539022",
      "brgy_name": "San Patricio",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539023",
      "brgy_name": "San Vicente",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539024",
      "brgy_name": "Santa Cruz",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015539025",
      "brgy_name": "Santa Rosa",
      "city_code": "015539",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540001",
      "brgy_name": "La Luna",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540002",
      "brgy_name": "Poblacion East",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540003",
      "brgy_name": "Poblacion West",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540004",
      "brgy_name": "Salvacion",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540005",
      "brgy_name": "San Agustin",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540006",
      "brgy_name": "San Antonio",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540007",
      "brgy_name": "San Jose",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540008",
      "brgy_name": "San Marcos",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540009",
      "brgy_name": "Santo Domingo",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015540010",
      "brgy_name": "Santo Niño",
      "city_code": "015540",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541001",
      "brgy_name": "Agat",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541002",
      "brgy_name": "Alibeng",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541003",
      "brgy_name": "Amagbagan",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541004",
      "brgy_name": "Artacho",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541005",
      "brgy_name": "Asan Norte",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541006",
      "brgy_name": "Asan Sur",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541007",
      "brgy_name": "Bantay Insik",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541008",
      "brgy_name": "Bila",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541009",
      "brgy_name": "Binmeckeg",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541010",
      "brgy_name": "Bulaoen East",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541011",
      "brgy_name": "Bulaoen West",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541012",
      "brgy_name": "Cabaritan",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541013",
      "brgy_name": "Calunetan",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541014",
      "brgy_name": "Camangaan",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541015",
      "brgy_name": "Cauringan",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541016",
      "brgy_name": "Dungon",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541017",
      "brgy_name": "Esperanza",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541018",
      "brgy_name": "Killo",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541019",
      "brgy_name": "Labayug",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541021",
      "brgy_name": "Paldit",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541022",
      "brgy_name": "Pindangan",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541023",
      "brgy_name": "Pinmilapil",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541024",
      "brgy_name": "Poblacion Central",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541025",
      "brgy_name": "Poblacion Norte",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541026",
      "brgy_name": "Poblacion Sur",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541027",
      "brgy_name": "Sagunto",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541028",
      "brgy_name": "Inmalog",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015541029",
      "brgy_name": "Tara-tara",
      "city_code": "015541",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542001",
      "brgy_name": "Baquioen",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542002",
      "brgy_name": "Baybay Norte",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542003",
      "brgy_name": "Baybay Sur",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542004",
      "brgy_name": "Bolaoen",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542005",
      "brgy_name": "Cabalitian",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542006",
      "brgy_name": "Calumbuyan",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542007",
      "brgy_name": "Camagsingalan",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542008",
      "brgy_name": "Caoayan",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542009",
      "brgy_name": "Capantolan",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542010",
      "brgy_name": "Macaycayawan",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542011",
      "brgy_name": "Paitan East",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542012",
      "brgy_name": "Paitan West",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542013",
      "brgy_name": "Pangascasan",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542014",
      "brgy_name": "Poblacion",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542015",
      "brgy_name": "Santo Domingo",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542016",
      "brgy_name": "Seselangen",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542018",
      "brgy_name": "Sioasio East",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542019",
      "brgy_name": "Sioasio West",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015542020",
      "brgy_name": "Victoria",
      "city_code": "015542",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543001",
      "brgy_name": "Agno",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543002",
      "brgy_name": "Amistad",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543003",
      "brgy_name": "Barangobong",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543004",
      "brgy_name": "Carriedo",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543005",
      "brgy_name": "C. Lichauco",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543006",
      "brgy_name": "Evangelista",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543007",
      "brgy_name": "Guzon",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543008",
      "brgy_name": "Lawak",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543009",
      "brgy_name": "Legaspi",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543010",
      "brgy_name": "Libertad",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543012",
      "brgy_name": "Magallanes",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543013",
      "brgy_name": "Panganiban",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543014",
      "brgy_name": "Barangay A (Pob.)",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543015",
      "brgy_name": "Barangay B (Pob.)",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543016",
      "brgy_name": "Barangay C (Pob.)",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543017",
      "brgy_name": "Barangay D (Pob.)",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543019",
      "brgy_name": "Saleng",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543020",
      "brgy_name": "Santo Domingo",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543022",
      "brgy_name": "Toketec",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543023",
      "brgy_name": "Trenchera",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015543024",
      "brgy_name": "Zamora",
      "city_code": "015543",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544001",
      "brgy_name": "Abot Molina",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544002",
      "brgy_name": "Alo-o",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544003",
      "brgy_name": "Amaronan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544004",
      "brgy_name": "Annam",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544005",
      "brgy_name": "Bantug",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544006",
      "brgy_name": "Baracbac",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544007",
      "brgy_name": "Barat",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544008",
      "brgy_name": "Buenavista",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544010",
      "brgy_name": "Cabalitian",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544011",
      "brgy_name": "Cabaruan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544012",
      "brgy_name": "Cabatuan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544013",
      "brgy_name": "Cadiz",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544014",
      "brgy_name": "Calitlitan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544015",
      "brgy_name": "Capas",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544017",
      "brgy_name": "Carosalesan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544018",
      "brgy_name": "Casilan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544019",
      "brgy_name": "Caurdanetaan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544020",
      "brgy_name": "Concepcion",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544021",
      "brgy_name": "Decreto",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544022",
      "brgy_name": "Diaz",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544023",
      "brgy_name": "Diket",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544024",
      "brgy_name": "Don Justo Abalos (Caroan)",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544025",
      "brgy_name": "Don Montano",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544026",
      "brgy_name": "Esperanza",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544027",
      "brgy_name": "Evangelista",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544028",
      "brgy_name": "Flores",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544029",
      "brgy_name": "Fulgosino",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544030",
      "brgy_name": "Gonzales",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544031",
      "brgy_name": "La Paz",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544032",
      "brgy_name": "Labuan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544033",
      "brgy_name": "Lauren",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544034",
      "brgy_name": "Lubong",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544035",
      "brgy_name": "Luna Weste",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544036",
      "brgy_name": "Luna Este",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544037",
      "brgy_name": "Mantacdang",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544038",
      "brgy_name": "Maseil-seil",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544039",
      "brgy_name": "Nampalcan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544040",
      "brgy_name": "Nancalabasaan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544041",
      "brgy_name": "Pangangaan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544042",
      "brgy_name": "Papallasen",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544044",
      "brgy_name": "Pemienta",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544046",
      "brgy_name": "Poblacion East",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544047",
      "brgy_name": "Poblacion West",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544048",
      "brgy_name": "Prado",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544049",
      "brgy_name": "Resurreccion",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544050",
      "brgy_name": "Ricos",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544051",
      "brgy_name": "San Andres",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544052",
      "brgy_name": "San Juan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544053",
      "brgy_name": "San Leon",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544054",
      "brgy_name": "San Pablo",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544055",
      "brgy_name": "San Vicente",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544056",
      "brgy_name": "Santa Maria",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544057",
      "brgy_name": "Santa Rosa",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544058",
      "brgy_name": "Sinabaan",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544059",
      "brgy_name": "Tanggal Sawang",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544060",
      "brgy_name": "Cabangaran",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544061",
      "brgy_name": "Carayungan Sur",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015544062",
      "brgy_name": "Del Rosario",
      "city_code": "015544",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545001",
      "brgy_name": "Angatel",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545002",
      "brgy_name": "Balangay",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545003",
      "brgy_name": "Batangcaoa",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545004",
      "brgy_name": "Baug",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545005",
      "brgy_name": "Bayaoas",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545006",
      "brgy_name": "Bituag",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545007",
      "brgy_name": "Camambugan",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545008",
      "brgy_name": "Dalangiring",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545009",
      "brgy_name": "Duplac",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545010",
      "brgy_name": "Galarin",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545011",
      "brgy_name": "Gueteb",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545012",
      "brgy_name": "Malaca",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545013",
      "brgy_name": "Malayo",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545014",
      "brgy_name": "Malibong",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545015",
      "brgy_name": "Pasibi East",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545016",
      "brgy_name": "Pasibi West",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545017",
      "brgy_name": "Pisuac",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545018",
      "brgy_name": "Poblacion",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545019",
      "brgy_name": "Real",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545020",
      "brgy_name": "Salavante",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015545021",
      "brgy_name": "Sawat",
      "city_code": "015545",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546001",
      "brgy_name": "Anonas",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546003",
      "brgy_name": "Bactad East",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546004",
      "brgy_name": "Dr. Pedro T. Orata (Bactad Proper)",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546005",
      "brgy_name": "Bayaoas",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546007",
      "brgy_name": "Bolaoen",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546008",
      "brgy_name": "Cabaruan",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546009",
      "brgy_name": "Cabuloan",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546011",
      "brgy_name": "Camanang",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546012",
      "brgy_name": "Camantiles",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546014",
      "brgy_name": "Casantaan",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546015",
      "brgy_name": "Catablan",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546016",
      "brgy_name": "Cayambanan",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546017",
      "brgy_name": "Consolacion",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546018",
      "brgy_name": "Dilan Paurido",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546019",
      "brgy_name": "Labit Proper",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546020",
      "brgy_name": "Labit West",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546022",
      "brgy_name": "Mabanogbog",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546023",
      "brgy_name": "Macalong",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546024",
      "brgy_name": "Nancalobasaan",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546025",
      "brgy_name": "Nancamaliran East",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546026",
      "brgy_name": "Nancamaliran West",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546027",
      "brgy_name": "Nancayasan",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546028",
      "brgy_name": "Oltama",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546029",
      "brgy_name": "Palina East",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546030",
      "brgy_name": "Palina West",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546031",
      "brgy_name": "Pinmaludpod",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546032",
      "brgy_name": "Poblacion",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546033",
      "brgy_name": "San Jose",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546034",
      "brgy_name": "San Vicente",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546035",
      "brgy_name": "Santa Lucia",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546036",
      "brgy_name": "Santo Domingo",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546037",
      "brgy_name": "Sugcong",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546038",
      "brgy_name": "Tipuso",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015546039",
      "brgy_name": "Tulong",
      "city_code": "015546",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547001",
      "brgy_name": "Amamperez",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547002",
      "brgy_name": "Bacag",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547003",
      "brgy_name": "Barangobong",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547004",
      "brgy_name": "Barraca",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547005",
      "brgy_name": "Capulaan",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547006",
      "brgy_name": "Caramutan",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547007",
      "brgy_name": "La Paz",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547008",
      "brgy_name": "Labit",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547009",
      "brgy_name": "Lipay",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547010",
      "brgy_name": "Lomboy",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547011",
      "brgy_name": "Piaz (Plaza)",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547012",
      "brgy_name": "Zone V (Pob.)",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547013",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547014",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547015",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547016",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547017",
      "brgy_name": "Puelay",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547018",
      "brgy_name": "San Blas",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547019",
      "brgy_name": "San Nicolas",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547020",
      "brgy_name": "Tombod",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015547021",
      "brgy_name": "Unzad",
      "city_code": "015547",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548001",
      "brgy_name": "Anis",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548002",
      "brgy_name": "Botique",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548003",
      "brgy_name": "Caaringayan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548004",
      "brgy_name": "Domingo Alarcio (Cabilaoan East)",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548005",
      "brgy_name": "Cabilaoan West",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548006",
      "brgy_name": "Cabulalaan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548007",
      "brgy_name": "Calaoagan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548008",
      "brgy_name": "Calmay",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548009",
      "brgy_name": "Casampagaan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548010",
      "brgy_name": "Casanestebanan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548011",
      "brgy_name": "Casantiagoan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548012",
      "brgy_name": "Inmanduyan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548013",
      "brgy_name": "Poblacion (Laoac)",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548014",
      "brgy_name": "Lebueg",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548015",
      "brgy_name": "Maraboc",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548016",
      "brgy_name": "Nanbagatan",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548017",
      "brgy_name": "Panaga",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548018",
      "brgy_name": "Talogtog",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548019",
      "brgy_name": "Turko",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548020",
      "brgy_name": "Yatyat",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548021",
      "brgy_name": "Balligi",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "015548022",
      "brgy_name": "Banuar",
      "city_code": "015548",
      "province_code": "0155",
      "region_code": "01"
    },
    {
      "brgy_code": "020901001",
      "brgy_name": "Ihubok II (Kayvaluganan)",
      "city_code": "020901",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020901002",
      "brgy_name": "Ihubok I (Kaychanarianan)",
      "city_code": "020901",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020901003",
      "brgy_name": "San Antonio",
      "city_code": "020901",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020901004",
      "brgy_name": "San Joaquin",
      "city_code": "020901",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020901005",
      "brgy_name": "Chanarian",
      "city_code": "020901",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020901006",
      "brgy_name": "Kayhuvokan",
      "city_code": "020901",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020902006",
      "brgy_name": "Raele",
      "city_code": "020902",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020902007",
      "brgy_name": "San Rafael (Idiang)",
      "city_code": "020902",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020902008",
      "brgy_name": "Santa Lucia (Kauhauhasan)",
      "city_code": "020902",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020902009",
      "brgy_name": "Santa Maria (Marapuy)",
      "city_code": "020902",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020902010",
      "brgy_name": "Santa Rosa (Kaynatuan)",
      "city_code": "020902",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020903001",
      "brgy_name": "Radiwan",
      "city_code": "020903",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020903002",
      "brgy_name": "Salagao",
      "city_code": "020903",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020903003",
      "brgy_name": "San Vicente (Igang)",
      "city_code": "020903",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020903004",
      "brgy_name": "Tuhel (Pob.)",
      "city_code": "020903",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020904001",
      "brgy_name": "Hanib",
      "city_code": "020904",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020904002",
      "brgy_name": "Kaumbakan",
      "city_code": "020904",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020904003",
      "brgy_name": "Panatayan",
      "city_code": "020904",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020904004",
      "brgy_name": "Uvoy (Pob.)",
      "city_code": "020904",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020905001",
      "brgy_name": "Chavayan",
      "city_code": "020905",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020905002",
      "brgy_name": "Malakdang (Pob.)",
      "city_code": "020905",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020905003",
      "brgy_name": "Nakanmuan",
      "city_code": "020905",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020905004",
      "brgy_name": "Savidug",
      "city_code": "020905",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020905005",
      "brgy_name": "Sinakan (Pob.)",
      "city_code": "020905",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020905006",
      "brgy_name": "Sumnanga",
      "city_code": "020905",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020906001",
      "brgy_name": "Kayvaluganan (Pob.)",
      "city_code": "020906",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020906002",
      "brgy_name": "Imnajbu",
      "city_code": "020906",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020906003",
      "brgy_name": "Itbud",
      "city_code": "020906",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "020906004",
      "brgy_name": "Kayuganan (Pob.)",
      "city_code": "020906",
      "province_code": "0209",
      "region_code": "02"
    },
    {
      "brgy_code": "021501001",
      "brgy_name": "Alinunu",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501002",
      "brgy_name": "Bagu",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501003",
      "brgy_name": "Banguian",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501004",
      "brgy_name": "Calog Norte",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501005",
      "brgy_name": "Calog Sur",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501006",
      "brgy_name": "Canayun",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501007",
      "brgy_name": "Centro (Pob.)",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501008",
      "brgy_name": "Dana-Ili",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501009",
      "brgy_name": "Guiddam",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501010",
      "brgy_name": "Libertad",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501011",
      "brgy_name": "Lucban",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501012",
      "brgy_name": "Pinili",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501013",
      "brgy_name": "Santa Filomena",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501014",
      "brgy_name": "Santo Tomas",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501015",
      "brgy_name": "Siguiran",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501016",
      "brgy_name": "Simayung",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501017",
      "brgy_name": "Sirit",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501018",
      "brgy_name": "San Agustin",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501019",
      "brgy_name": "San Julian",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021501020",
      "brgy_name": "Santa Rosa",
      "city_code": "021501",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502001",
      "brgy_name": "Abbeg",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502002",
      "brgy_name": "Afusing Bato",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502003",
      "brgy_name": "Afusing Daga",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502004",
      "brgy_name": "Agani",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502005",
      "brgy_name": "Baculod",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502006",
      "brgy_name": "Baybayog",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502007",
      "brgy_name": "Cabuluan",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502008",
      "brgy_name": "Calantac",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502009",
      "brgy_name": "Carallangan",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502010",
      "brgy_name": "Centro Norte (Pob.)",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502011",
      "brgy_name": "Centro Sur (Pob.)",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502012",
      "brgy_name": "Dalaoig",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502013",
      "brgy_name": "Damurog",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502014",
      "brgy_name": "Jurisdiction",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502015",
      "brgy_name": "Malalatan",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502016",
      "brgy_name": "Maraburab",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502017",
      "brgy_name": "Masin",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502018",
      "brgy_name": "Pagbangkeruan",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502019",
      "brgy_name": "Pared",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502020",
      "brgy_name": "Piggatan",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502021",
      "brgy_name": "Pinopoc",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502022",
      "brgy_name": "Pussian",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502023",
      "brgy_name": "San Esteban",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502024",
      "brgy_name": "Tamban",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021502025",
      "brgy_name": "Tupang",
      "city_code": "021502",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503001",
      "brgy_name": "Bessang",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503002",
      "brgy_name": "Binobongan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503003",
      "brgy_name": "Bulo",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503004",
      "brgy_name": "Burot",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503005",
      "brgy_name": "Capagaran (Brigida)",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503006",
      "brgy_name": "Capalutan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503007",
      "brgy_name": "Capanickian Norte",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503008",
      "brgy_name": "Capanickian Sur",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503009",
      "brgy_name": "Cataratan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503010",
      "brgy_name": "Centro East (Pob.)",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503011",
      "brgy_name": "Centro West (Pob.)",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503012",
      "brgy_name": "Daan-Ili",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503013",
      "brgy_name": "Dagupan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503014",
      "brgy_name": "Dalayap",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503015",
      "brgy_name": "Gagaddangan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503016",
      "brgy_name": "Iringan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503017",
      "brgy_name": "Labben",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503018",
      "brgy_name": "Maluyo",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503019",
      "brgy_name": "Mapurao",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503020",
      "brgy_name": "Matucay",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503021",
      "brgy_name": "Nagattatan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503022",
      "brgy_name": "Pacac",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503023",
      "brgy_name": "San Juan (Maguininango)",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503024",
      "brgy_name": "Silangan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503025",
      "brgy_name": "Tamboli",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503026",
      "brgy_name": "Tubel",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021503027",
      "brgy_name": "Utan",
      "city_code": "021503",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504001",
      "brgy_name": "Abolo",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504002",
      "brgy_name": "Agguirit",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504003",
      "brgy_name": "Alitungtung",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504004",
      "brgy_name": "Annabuculan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504005",
      "brgy_name": "Annafatan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504006",
      "brgy_name": "Anquiray",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504007",
      "brgy_name": "Babayuan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504008",
      "brgy_name": "Baccuit",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504009",
      "brgy_name": "Bacring",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504010",
      "brgy_name": "Baculud",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504011",
      "brgy_name": "Balauini",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504012",
      "brgy_name": "Bauan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504013",
      "brgy_name": "Bayabat",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504014",
      "brgy_name": "Calamagui",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504015",
      "brgy_name": "Calintaan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504016",
      "brgy_name": "Caratacat",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504017",
      "brgy_name": "Casingsingan Norte",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504018",
      "brgy_name": "Casingsingan Sur",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504019",
      "brgy_name": "Catarauan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504020",
      "brgy_name": "Centro",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504021",
      "brgy_name": "Concepcion",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504022",
      "brgy_name": "Cordova",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504023",
      "brgy_name": "Dadda",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504024",
      "brgy_name": "Dafunganay",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504025",
      "brgy_name": "Dugayung",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504026",
      "brgy_name": "Estefania",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504027",
      "brgy_name": "Gabut",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504028",
      "brgy_name": "Gangauan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504029",
      "brgy_name": "Goran",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504030",
      "brgy_name": "Jurisdiccion",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504031",
      "brgy_name": "La Suerte",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504032",
      "brgy_name": "Logung",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504033",
      "brgy_name": "Magogod",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504034",
      "brgy_name": "Manalo",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504035",
      "brgy_name": "Marobbob",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504036",
      "brgy_name": "Masical",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504037",
      "brgy_name": "Monte Alegre",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504038",
      "brgy_name": "Nabbialan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504039",
      "brgy_name": "Nagsabaran",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504040",
      "brgy_name": "Nangalasauan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504041",
      "brgy_name": "Nanuccauan",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504042",
      "brgy_name": "Pacac-Grande",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504043",
      "brgy_name": "Pacac-Pequeño",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504044",
      "brgy_name": "Palacu",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504045",
      "brgy_name": "Palayag",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504046",
      "brgy_name": "Tana",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021504047",
      "brgy_name": "Unag",
      "city_code": "021504",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505001",
      "brgy_name": "Backiling",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505002",
      "brgy_name": "Bangag",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505003",
      "brgy_name": "Binalan",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505004",
      "brgy_name": "Bisagu",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505005",
      "brgy_name": "Centro 1 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505006",
      "brgy_name": "Centro 2 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505007",
      "brgy_name": "Centro 3 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505008",
      "brgy_name": "Centro 4 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505009",
      "brgy_name": "Centro 5 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505010",
      "brgy_name": "Centro 6 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505011",
      "brgy_name": "Centro 7 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505012",
      "brgy_name": "Centro 8 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505013",
      "brgy_name": "Centro 9 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505014",
      "brgy_name": "Centro 10 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505015",
      "brgy_name": "Centro 11 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505016",
      "brgy_name": "Centro 12 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505017",
      "brgy_name": "Centro 13 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505018",
      "brgy_name": "Centro 14 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505019",
      "brgy_name": "Bukig",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505020",
      "brgy_name": "Bulala Norte",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505021",
      "brgy_name": "Bulala Sur",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505022",
      "brgy_name": "Caagaman",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505023",
      "brgy_name": "Centro 15 (Pob.)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505024",
      "brgy_name": "Dodan",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505025",
      "brgy_name": "Fuga Island",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505026",
      "brgy_name": "Gaddang",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505027",
      "brgy_name": "Linao",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505028",
      "brgy_name": "Mabanguc",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505029",
      "brgy_name": "Macanaya (Pescaria)",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505030",
      "brgy_name": "Maura",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505031",
      "brgy_name": "Minanga",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505032",
      "brgy_name": "Navagan",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505033",
      "brgy_name": "Paddaya",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505034",
      "brgy_name": "Paruddun Norte",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505035",
      "brgy_name": "Paruddun Sur",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505036",
      "brgy_name": "Plaza",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505037",
      "brgy_name": "Punta",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505038",
      "brgy_name": "San Antonio",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505039",
      "brgy_name": "Tallungan",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505040",
      "brgy_name": "Toran",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505041",
      "brgy_name": "Sanja",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021505042",
      "brgy_name": "Zinarag",
      "city_code": "021505",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506001",
      "brgy_name": "Adaoag",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506002",
      "brgy_name": "Agaman (Proper)",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506003",
      "brgy_name": "Alba",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506004",
      "brgy_name": "Annayatan",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506005",
      "brgy_name": "Asassi",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506006",
      "brgy_name": "Asinga-Via",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506007",
      "brgy_name": "Awallan",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506008",
      "brgy_name": "Bacagan",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506009",
      "brgy_name": "Bagunot",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506011",
      "brgy_name": "Barsat East",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506012",
      "brgy_name": "Barsat West",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506013",
      "brgy_name": "Bitag Grande",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506014",
      "brgy_name": "Bitag Pequeño",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506015",
      "brgy_name": "Bunugan",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506016",
      "brgy_name": "Canagatan",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506017",
      "brgy_name": "Carupian",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506018",
      "brgy_name": "Catugay",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506019",
      "brgy_name": "Poblacion (Centro)",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506020",
      "brgy_name": "Dabbac Grande",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506021",
      "brgy_name": "Dalin",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506022",
      "brgy_name": "Dalla",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506023",
      "brgy_name": "Hacienda Intal",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506024",
      "brgy_name": "Ibulo",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506025",
      "brgy_name": "Imurong",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506026",
      "brgy_name": "J. Pallagao",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506027",
      "brgy_name": "Lasilat",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506028",
      "brgy_name": "Masical",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506029",
      "brgy_name": "Mocag",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506030",
      "brgy_name": "Nangalinan",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506031",
      "brgy_name": "Remus",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506032",
      "brgy_name": "San Antonio",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506033",
      "brgy_name": "San Francisco",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506034",
      "brgy_name": "San Isidro",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506035",
      "brgy_name": "San Jose",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506036",
      "brgy_name": "San Miguel",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506037",
      "brgy_name": "San Vicente",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506038",
      "brgy_name": "Santa Margarita",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506039",
      "brgy_name": "Santor",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506040",
      "brgy_name": "Taguing",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506041",
      "brgy_name": "Taguntungan",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506042",
      "brgy_name": "Tallang",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506043",
      "brgy_name": "Temblique",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506044",
      "brgy_name": "Taytay",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506045",
      "brgy_name": "Tungel",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506046",
      "brgy_name": "Mabini",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506047",
      "brgy_name": "Agaman Norte",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506048",
      "brgy_name": "Agaman Sur",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021506049",
      "brgy_name": "C. Verzosa ((Valley Cove*)",
      "city_code": "021506",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507001",
      "brgy_name": "Ammubuan",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507002",
      "brgy_name": "Baran",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507003",
      "brgy_name": "Cabaritan East",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507004",
      "brgy_name": "Cabaritan West",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507005",
      "brgy_name": "Cabayu",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507006",
      "brgy_name": "Cabuluan East",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507007",
      "brgy_name": "Cabuluan West",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507008",
      "brgy_name": "Centro East (Pob.)",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507009",
      "brgy_name": "Centro West (Pob.)",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507010",
      "brgy_name": "Fugu",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507012",
      "brgy_name": "Mabuttal East",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507013",
      "brgy_name": "Mabuttal West",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507014",
      "brgy_name": "Nararagan",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507015",
      "brgy_name": "Palloc",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507017",
      "brgy_name": "Payagan East",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507018",
      "brgy_name": "Payagan West",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507019",
      "brgy_name": "San Juan",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507020",
      "brgy_name": "Santa Cruz",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021507021",
      "brgy_name": "Zitanga",
      "city_code": "021507",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508001",
      "brgy_name": "Ballang",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508002",
      "brgy_name": "Balza",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508003",
      "brgy_name": "Cabaritan",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508004",
      "brgy_name": "Calamegatan",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508005",
      "brgy_name": "Centro (Pob.)",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508006",
      "brgy_name": "Centro West",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508007",
      "brgy_name": "Dalaya",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508008",
      "brgy_name": "Fula",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508009",
      "brgy_name": "Leron",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508010",
      "brgy_name": "Antiporda",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508011",
      "brgy_name": "Maddalero",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508012",
      "brgy_name": "Mala Este",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508013",
      "brgy_name": "Mala Weste",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508014",
      "brgy_name": "Minanga Este",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508015",
      "brgy_name": "Paddaya Este",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508016",
      "brgy_name": "Pattao",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508018",
      "brgy_name": "Quinawegan",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508019",
      "brgy_name": "Remebella",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508020",
      "brgy_name": "San Isidro",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508021",
      "brgy_name": "Santa Isabel",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508022",
      "brgy_name": "Santa Maria",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508023",
      "brgy_name": "Tabbac",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508024",
      "brgy_name": "Villa Cielo",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508025",
      "brgy_name": "Alucao Weste (San Lorenzo*)",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508026",
      "brgy_name": "Minanga Weste",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508027",
      "brgy_name": "Paddaya Weste",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508028",
      "brgy_name": "San Juan",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508029",
      "brgy_name": "San Vicente",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508030",
      "brgy_name": "Villa Gracia",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021508031",
      "brgy_name": "Villa Leonora",
      "city_code": "021508",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509001",
      "brgy_name": "Cabudadan",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509002",
      "brgy_name": "Balatubat",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509003",
      "brgy_name": "Dadao",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509004",
      "brgy_name": "Dibay",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509005",
      "brgy_name": "Dilam",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509006",
      "brgy_name": "Magsidel",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509007",
      "brgy_name": "Naguilian",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509008",
      "brgy_name": "Poblacion",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509009",
      "brgy_name": "Babuyan Claro",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509010",
      "brgy_name": "Centro II",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509011",
      "brgy_name": "Dalupiri",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021509012",
      "brgy_name": "Minabel",
      "city_code": "021509",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510001",
      "brgy_name": "Abagao",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510002",
      "brgy_name": "Afunan Cabayu",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510003",
      "brgy_name": "Agusi",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510004",
      "brgy_name": "Alilinu",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510005",
      "brgy_name": "Baggao",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510006",
      "brgy_name": "Bantay",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510007",
      "brgy_name": "Bulala",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510009",
      "brgy_name": "Casili Norte",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510010",
      "brgy_name": "Catotoran Norte",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510011",
      "brgy_name": "Centro Norte (Pob.)",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510012",
      "brgy_name": "Centro Sur (Pob.)",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510013",
      "brgy_name": "Cullit",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510014",
      "brgy_name": "Dacal-Lafugu",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510015",
      "brgy_name": "Dammang Norte (Joaquin de la Cruz*)",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510016",
      "brgy_name": "Dugo",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510017",
      "brgy_name": "Fusina",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510018",
      "brgy_name": "Gang-ngo",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510019",
      "brgy_name": "Jurisdiction",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510020",
      "brgy_name": "Luec",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510021",
      "brgy_name": "Minanga",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510022",
      "brgy_name": "Paragat",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510023",
      "brgy_name": "Tagum",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510024",
      "brgy_name": "Tuluttuging",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510025",
      "brgy_name": "Ziminila",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510026",
      "brgy_name": "Casili Sur",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510027",
      "brgy_name": "Catotoran Sur",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510028",
      "brgy_name": "Dammang Sur (Felipe Tuzon*)",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021510029",
      "brgy_name": "Sapping",
      "city_code": "021510",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511001",
      "brgy_name": "Alimoan",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511002",
      "brgy_name": "Bacsay Cataraoan Norte",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511003",
      "brgy_name": "Bacsay Mapulapula",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511004",
      "brgy_name": "Bilibigao",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511005",
      "brgy_name": "Buenavista",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511006",
      "brgy_name": "Cadcadir East",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511007",
      "brgy_name": "Capanikian",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511008",
      "brgy_name": "Centro I (Pob.)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511009",
      "brgy_name": "Centro II (Pob.)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511010",
      "brgy_name": "Culao",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511011",
      "brgy_name": "Dibalio",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511012",
      "brgy_name": "Kilkiling",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511013",
      "brgy_name": "Lablabig",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511014",
      "brgy_name": "Luzon",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511015",
      "brgy_name": "Mabnang",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511016",
      "brgy_name": "Magdalena",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511017",
      "brgy_name": "Centro VII (Malasin East)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511018",
      "brgy_name": "Malilitao",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511019",
      "brgy_name": "Centro VI (Minanga)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511020",
      "brgy_name": "Nagsabaran",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511021",
      "brgy_name": "Centro IV (Nangasangan)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511022",
      "brgy_name": "Pata East",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511023",
      "brgy_name": "Pinas",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511024",
      "brgy_name": "Santiago",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511025",
      "brgy_name": "Santo Tomas",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511026",
      "brgy_name": "Santa Maria (Surngot)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511027",
      "brgy_name": "Tabbugan",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511028",
      "brgy_name": "Taggat Norte",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511029",
      "brgy_name": "Union",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511030",
      "brgy_name": "Bacsay Cataraoan Sur",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511031",
      "brgy_name": "Cadcadir West",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511032",
      "brgy_name": "Camalaggoan/D Leaño",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511033",
      "brgy_name": "Centro III",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511034",
      "brgy_name": "Centro V (Mina)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511035",
      "brgy_name": "Centro VIII (Malasin West)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511036",
      "brgy_name": "Pata West",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511037",
      "brgy_name": "San Antonio (Sayad*/Bimekel*)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511038",
      "brgy_name": "San Isidro",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511039",
      "brgy_name": "San Vicente",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511040",
      "brgy_name": "Santo Niño (Barbarnis)",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021511041",
      "brgy_name": "Taggat Sur",
      "city_code": "021511",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512001",
      "brgy_name": "Alibago (Villa Maria)",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512003",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512006",
      "brgy_name": "Barangay III (San Roque*)",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512007",
      "brgy_name": "Divisoria",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512008",
      "brgy_name": "Inga",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512009",
      "brgy_name": "Lanna",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512010",
      "brgy_name": "Lemu Norte",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512011",
      "brgy_name": "Liwan Norte",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512012",
      "brgy_name": "Liwan Sur",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512013",
      "brgy_name": "Maddarulug Norte",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512014",
      "brgy_name": "Magalalag East",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512015",
      "brgy_name": "Maracuru",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512019",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512020",
      "brgy_name": "Roma Norte",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512021",
      "brgy_name": "Barangay III-A",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512022",
      "brgy_name": "Batu",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512023",
      "brgy_name": "Lemu Sur",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512024",
      "brgy_name": "Maddarulug Sur",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512025",
      "brgy_name": "Magalalag West (San Nicolas*)",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512026",
      "brgy_name": "Roma Sur",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021512027",
      "brgy_name": "San Antonio",
      "city_code": "021512",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513001",
      "brgy_name": "Abra",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513005",
      "brgy_name": "Aguiguican",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513006",
      "brgy_name": "Bangatan Ngagan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513007",
      "brgy_name": "Baracaoit",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513008",
      "brgy_name": "Baraoidan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513009",
      "brgy_name": "Barbarit",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513010",
      "brgy_name": "Basao",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513011",
      "brgy_name": "Cabayu",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513012",
      "brgy_name": "Calaoagan Bassit",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513013",
      "brgy_name": "Calaoagan Dackel",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513014",
      "brgy_name": "Capiddigan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513015",
      "brgy_name": "Capissayan Norte",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513016",
      "brgy_name": "Capissayan Sur",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513018",
      "brgy_name": "Casicallan Sur",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513019",
      "brgy_name": "Casicallan Norte",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513020",
      "brgy_name": "Centro Norte (Pob.)",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513021",
      "brgy_name": "Centro Sur (Pob.)",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513022",
      "brgy_name": "Cullit",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513023",
      "brgy_name": "Cumao",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513024",
      "brgy_name": "Cunig",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513025",
      "brgy_name": "Dummun",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513026",
      "brgy_name": "Fugu",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513027",
      "brgy_name": "Ganzano",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513028",
      "brgy_name": "Guising",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513029",
      "brgy_name": "Langgan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513030",
      "brgy_name": "Lapogan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513031",
      "brgy_name": "L. Adviento",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513033",
      "brgy_name": "Mabuno",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513035",
      "brgy_name": "Nabaccayan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513036",
      "brgy_name": "Naddungan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513037",
      "brgy_name": "Nagatutuan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513038",
      "brgy_name": "Nassiping",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513039",
      "brgy_name": "Newagac",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513040",
      "brgy_name": "Palagao Norte",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513041",
      "brgy_name": "Palagao Sur",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513042",
      "brgy_name": "Piña Este",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513043",
      "brgy_name": "Piña Weste",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513044",
      "brgy_name": "San Vicente",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513045",
      "brgy_name": "Santa Maria",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513046",
      "brgy_name": "Sidem",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513047",
      "brgy_name": "Santa Ana",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513048",
      "brgy_name": "Tagumay",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513049",
      "brgy_name": "Takiki",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513050",
      "brgy_name": "Taligan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513051",
      "brgy_name": "Tanglagan",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513052",
      "brgy_name": "T. Elizaga (Mabirbira)",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513053",
      "brgy_name": "Tubungan Este",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513054",
      "brgy_name": "Tubungan Weste",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513056",
      "brgy_name": "Bolos Point",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021513057",
      "brgy_name": "San Carlos",
      "city_code": "021513",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514001",
      "brgy_name": "Amunitan",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514002",
      "brgy_name": "Batangan",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514003",
      "brgy_name": "Baua",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514004",
      "brgy_name": "Cabanbanan Norte",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514005",
      "brgy_name": "Cabanbanan Sur",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514006",
      "brgy_name": "Cabiraoan",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514007",
      "brgy_name": "Callao",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514008",
      "brgy_name": "Calayan",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514009",
      "brgy_name": "Caroan",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514010",
      "brgy_name": "Casitan",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514011",
      "brgy_name": "Flourishing (Pob.)",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514012",
      "brgy_name": "Ipil",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514013",
      "brgy_name": "Isca",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514014",
      "brgy_name": "Magrafil",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514015",
      "brgy_name": "Minanga",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514016",
      "brgy_name": "Rebecca (Nagbabalacan*)",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514017",
      "brgy_name": "Paradise (Pob.)",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514018",
      "brgy_name": "Pateng",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514019",
      "brgy_name": "Progressive (Pob.)",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514020",
      "brgy_name": "San Jose",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514021",
      "brgy_name": "Santa Clara",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514022",
      "brgy_name": "Santa Cruz",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514023",
      "brgy_name": "Santa Maria",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514024",
      "brgy_name": "Smart (Pob.)",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021514025",
      "brgy_name": "Tapel",
      "city_code": "021514",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515001",
      "brgy_name": "Ajat (Pob.)",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515002",
      "brgy_name": "Atulu",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515003",
      "brgy_name": "Baculud",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515004",
      "brgy_name": "Bayo",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515005",
      "brgy_name": "Campo",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515006",
      "brgy_name": "San Esteban (Capitan)",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515007",
      "brgy_name": "Dumpao",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515009",
      "brgy_name": "Gammad",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515010",
      "brgy_name": "Santa Teresa (Gammad Sur)",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515011",
      "brgy_name": "Garab",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515013",
      "brgy_name": "Malabbac",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515014",
      "brgy_name": "Manaoag (Aquiliquilao)",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515015",
      "brgy_name": "Minanga Norte",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515016",
      "brgy_name": "Minanga Sur",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515017",
      "brgy_name": "Nattanzan (Pob.)",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515018",
      "brgy_name": "Redondo",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515019",
      "brgy_name": "Salamague",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515020",
      "brgy_name": "San Isidro (Ugac West)",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515021",
      "brgy_name": "San Lorenzo",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515022",
      "brgy_name": "Santa Barbara",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515023",
      "brgy_name": "Santa Rosa",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515024",
      "brgy_name": "Santiago",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021515025",
      "brgy_name": "San Vicente (Ugac East)",
      "city_code": "021515",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516001",
      "brgy_name": "Abagao",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516002",
      "brgy_name": "Alaguia",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516003",
      "brgy_name": "Bagumbayan",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516004",
      "brgy_name": "Bangag",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516005",
      "brgy_name": "Bical",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516006",
      "brgy_name": "Bicud",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516007",
      "brgy_name": "Binag",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516008",
      "brgy_name": "Cabayabasan (Capacuan)",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516009",
      "brgy_name": "Cagoran",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516010",
      "brgy_name": "Cambong",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516011",
      "brgy_name": "Catayauan",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516012",
      "brgy_name": "Catugan",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516013",
      "brgy_name": "Centro (Pob.)",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516014",
      "brgy_name": "Cullit",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516015",
      "brgy_name": "Dagupan",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516016",
      "brgy_name": "Dalaya",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516017",
      "brgy_name": "Fabrica",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516018",
      "brgy_name": "Fusina",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516019",
      "brgy_name": "Jurisdiction",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516020",
      "brgy_name": "Lalafugan",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516021",
      "brgy_name": "Logac",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516022",
      "brgy_name": "Magallungon (Sta. Teresa*)",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516023",
      "brgy_name": "Magapit",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516024",
      "brgy_name": "Malanao",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516025",
      "brgy_name": "Maxingal",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516026",
      "brgy_name": "Naguilian",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516027",
      "brgy_name": "Paranum",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516028",
      "brgy_name": "Rosario",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516029",
      "brgy_name": "San Antonio (Lafu)",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516030",
      "brgy_name": "San Jose",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516031",
      "brgy_name": "San Juan",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516032",
      "brgy_name": "San Lorenzo",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516033",
      "brgy_name": "San Mariano",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516034",
      "brgy_name": "Santa Maria",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021516035",
      "brgy_name": "Tucalana",
      "city_code": "021516",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517001",
      "brgy_name": "Aggunetan",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517002",
      "brgy_name": "Alannay",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517003",
      "brgy_name": "Battalan",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517005",
      "brgy_name": "Calapangan Norte",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517006",
      "brgy_name": "Calapangan Sur",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517007",
      "brgy_name": "Callao Norte",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517008",
      "brgy_name": "Callao Sur",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517009",
      "brgy_name": "Cataliganan",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517010",
      "brgy_name": "Finugo Norte",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517012",
      "brgy_name": "Gabun",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517013",
      "brgy_name": "Ignacio Jurado (Finugu Sur)",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517014",
      "brgy_name": "Magsaysay",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517015",
      "brgy_name": "Malinta",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517016",
      "brgy_name": "Minanga Sur",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517017",
      "brgy_name": "Minanga Norte",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517019",
      "brgy_name": "Nicolas Agatep",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517020",
      "brgy_name": "Peru",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517021",
      "brgy_name": "Centro I (Pob.)",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517022",
      "brgy_name": "San Pedro",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517023",
      "brgy_name": "Sicalao",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517024",
      "brgy_name": "Tagao",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517025",
      "brgy_name": "Tucalan Passing",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517026",
      "brgy_name": "Viga",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517027",
      "brgy_name": "Cabatacan East (Duldugan*)",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517028",
      "brgy_name": "Cabatacan West",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517029",
      "brgy_name": "Nabannagan East",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517030",
      "brgy_name": "Nabannagan West",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517031",
      "brgy_name": "Centro II (Pob.)",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517032",
      "brgy_name": "Centro III (Pob.)",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021517033",
      "brgy_name": "New Orlins",
      "city_code": "021517",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518001",
      "brgy_name": "Abanqueruan",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518002",
      "brgy_name": "Allasitan",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518003",
      "brgy_name": "Bagu",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518004",
      "brgy_name": "Balingit",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518005",
      "brgy_name": "Bidduang",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518006",
      "brgy_name": "Cabaggan",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518007",
      "brgy_name": "Capalalian",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518008",
      "brgy_name": "Casitan",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518009",
      "brgy_name": "Centro (Pob.)",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518010",
      "brgy_name": "Curva",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518011",
      "brgy_name": "Gattu",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518012",
      "brgy_name": "Masi",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518013",
      "brgy_name": "Nagattatan",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518014",
      "brgy_name": "Nagtupacan",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518015",
      "brgy_name": "San Juan",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518016",
      "brgy_name": "Santa Cruz",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518017",
      "brgy_name": "Tabba",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021518018",
      "brgy_name": "Tupanna",
      "city_code": "021518",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519001",
      "brgy_name": "Aggugaddan",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519002",
      "brgy_name": "Alimanao",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519003",
      "brgy_name": "Baliuag",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519004",
      "brgy_name": "Bical",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519005",
      "brgy_name": "Bugatay",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519006",
      "brgy_name": "Buyun",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519007",
      "brgy_name": "Cabasan",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519008",
      "brgy_name": "Cabbo",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519009",
      "brgy_name": "Callao",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519010",
      "brgy_name": "Camasi",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519011",
      "brgy_name": "Centro (Pob.)",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519012",
      "brgy_name": "Dodan",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519013",
      "brgy_name": "Lapi",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519015",
      "brgy_name": "Malibabag",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519016",
      "brgy_name": "Manga",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519017",
      "brgy_name": "Minanga",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519018",
      "brgy_name": "Nabbabalayan",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519019",
      "brgy_name": "Nanguilattan",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519020",
      "brgy_name": "Nannarian",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519021",
      "brgy_name": "Parabba",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519022",
      "brgy_name": "Patagueleg",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519023",
      "brgy_name": "Quibal",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519024",
      "brgy_name": "San Roque (Litto)",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021519025",
      "brgy_name": "Sisim",
      "city_code": "021519",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520001",
      "brgy_name": "Apayao",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520002",
      "brgy_name": "Aquib",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520003",
      "brgy_name": "Dugayung",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520004",
      "brgy_name": "Gumarueng",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520005",
      "brgy_name": "Macapil",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520006",
      "brgy_name": "Maguilling",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520007",
      "brgy_name": "Minanga",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520008",
      "brgy_name": "Poblacion I",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520011",
      "brgy_name": "Santa Barbara",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520012",
      "brgy_name": "Santo Domingo",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520013",
      "brgy_name": "Sicatna",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520014",
      "brgy_name": "Villa Rey (San Gaspar*)",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520015",
      "brgy_name": "Warat",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520016",
      "brgy_name": "Baung",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520017",
      "brgy_name": "Calaoagan",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520018",
      "brgy_name": "Catarauan",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520019",
      "brgy_name": "Poblacion II",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021520020",
      "brgy_name": "Villa Reyno",
      "city_code": "021520",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521001",
      "brgy_name": "Anagguan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521002",
      "brgy_name": "Anurturu",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521003",
      "brgy_name": "Anungu",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521004",
      "brgy_name": "Balungcanag",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521005",
      "brgy_name": "Batu",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521006",
      "brgy_name": "Cambabangan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521007",
      "brgy_name": "Capacuan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521008",
      "brgy_name": "Dunggan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521009",
      "brgy_name": "Duyun",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521010",
      "brgy_name": "Gaddangao",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521011",
      "brgy_name": "Gaggabutan East",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521012",
      "brgy_name": "Illuru Norte",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521013",
      "brgy_name": "Lattut",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521014",
      "brgy_name": "Linno (Villa Cruz*)",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521015",
      "brgy_name": "Liwan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521016",
      "brgy_name": "Mabbang",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521017",
      "brgy_name": "Mauanan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521018",
      "brgy_name": "Masi (Zinundungan)",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521019",
      "brgy_name": "Minanga",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521020",
      "brgy_name": "Nanauatan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521021",
      "brgy_name": "Nanungaran",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521022",
      "brgy_name": "Pasingan",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521023",
      "brgy_name": "Poblacion",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521024",
      "brgy_name": "San Juan (Zinundungan)",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521025",
      "brgy_name": "Sinicking",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521026",
      "brgy_name": "Battut",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521027",
      "brgy_name": "Bural (Zinundungan)",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521028",
      "brgy_name": "Gaggabutan West",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021521029",
      "brgy_name": "Illuru Sur",
      "city_code": "021521",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522001",
      "brgy_name": "Bangan",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522002",
      "brgy_name": "Callungan",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522003",
      "brgy_name": "Centro I (Pob.)",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522004",
      "brgy_name": "Centro II (Pob.)",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522005",
      "brgy_name": "Dacal",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522006",
      "brgy_name": "Dagueray",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522008",
      "brgy_name": "Dammang",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522009",
      "brgy_name": "Kittag",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522010",
      "brgy_name": "Langagan",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522011",
      "brgy_name": "Magacan",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522012",
      "brgy_name": "Marzan",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522013",
      "brgy_name": "Masisit",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522014",
      "brgy_name": "Nagrangtayan",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522015",
      "brgy_name": "Namuac",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522016",
      "brgy_name": "San Andres",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522017",
      "brgy_name": "Santiago",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522018",
      "brgy_name": "Santor",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021522019",
      "brgy_name": "Tokitok",
      "city_code": "021522",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523001",
      "brgy_name": "Casagan",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523002",
      "brgy_name": "Casambalangan (Port Irene)",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523003",
      "brgy_name": "Centro (Pob.)",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523004",
      "brgy_name": "Diora-Zinungan",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523005",
      "brgy_name": "Dungeg",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523006",
      "brgy_name": "Kapanikian",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523007",
      "brgy_name": "Marede",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523008",
      "brgy_name": "Palawig",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523009",
      "brgy_name": "Batu-Parada",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523010",
      "brgy_name": "Patunungan",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523011",
      "brgy_name": "Rapuli (Punti)",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523012",
      "brgy_name": "San Vicente (Fort)",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523013",
      "brgy_name": "Santa Clara",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523014",
      "brgy_name": "Santa Cruz",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523015",
      "brgy_name": "Visitacion (Pob.)",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021523016",
      "brgy_name": "Tangatan",
      "city_code": "021523",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524001",
      "brgy_name": "Cadongdongan",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524002",
      "brgy_name": "Capacuan",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524003",
      "brgy_name": "Centro I (Pob.)",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524004",
      "brgy_name": "Centro II (Pob.)",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524005",
      "brgy_name": "Macatel",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524008",
      "brgy_name": "Portabaga",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524009",
      "brgy_name": "San Juan",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524010",
      "brgy_name": "San Miguel",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524011",
      "brgy_name": "Salungsong",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021524012",
      "brgy_name": "Sicul",
      "city_code": "021524",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525001",
      "brgy_name": "Alucao",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525002",
      "brgy_name": "Buyun",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525003",
      "brgy_name": "Centro East (Pob.)",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525004",
      "brgy_name": "Dungeg",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525005",
      "brgy_name": "Luga",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525006",
      "brgy_name": "Masi",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525007",
      "brgy_name": "Mission",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525011",
      "brgy_name": "Simpatuyo",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525012",
      "brgy_name": "Villa",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525013",
      "brgy_name": "Aridawen",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525014",
      "brgy_name": "Caniugan",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525015",
      "brgy_name": "Centro West",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021525016",
      "brgy_name": "Simbaluca",
      "city_code": "021525",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526001",
      "brgy_name": "Abariongan Ruar",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526002",
      "brgy_name": "Abariongan Uneg",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526003",
      "brgy_name": "Balagan",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526004",
      "brgy_name": "Balanni",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526006",
      "brgy_name": "Cabayo",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526007",
      "brgy_name": "Calapangan",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526008",
      "brgy_name": "Calassitan",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526009",
      "brgy_name": "Campo",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526010",
      "brgy_name": "Centro Norte (Pob.)",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526011",
      "brgy_name": "Centro Sur (Pob.)",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526012",
      "brgy_name": "Dungao",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526014",
      "brgy_name": "Lattac",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526015",
      "brgy_name": "Lipatan",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526016",
      "brgy_name": "Lubo",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526017",
      "brgy_name": "Mabitbitnong",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526018",
      "brgy_name": "Mapitac",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526019",
      "brgy_name": "Masical",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526020",
      "brgy_name": "Matalao",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526021",
      "brgy_name": "Nag-uma",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526022",
      "brgy_name": "Namuccayan",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526023",
      "brgy_name": "Niug Norte",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526024",
      "brgy_name": "Niug Sur",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526025",
      "brgy_name": "Palusao",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526026",
      "brgy_name": "San Manuel",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526027",
      "brgy_name": "San Roque",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526028",
      "brgy_name": "Santa Felicitas",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526029",
      "brgy_name": "Santa Maria",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526030",
      "brgy_name": "Sidiran",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526031",
      "brgy_name": "Tabang",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526032",
      "brgy_name": "Tamucco",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021526033",
      "brgy_name": "Virginia",
      "city_code": "021526",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527001",
      "brgy_name": "Andarayan North",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527002",
      "brgy_name": "Lannig",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527003",
      "brgy_name": "Bangag",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527004",
      "brgy_name": "Bantay",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527005",
      "brgy_name": "Basi East",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527006",
      "brgy_name": "Bauan East",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527007",
      "brgy_name": "Cadaanan",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527008",
      "brgy_name": "Calamagui",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527009",
      "brgy_name": "Carilucud",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527010",
      "brgy_name": "Cattaran",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527011",
      "brgy_name": "Centro Northeast (Pob.)",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527012",
      "brgy_name": "Centro Northwest (Pob.)",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527013",
      "brgy_name": "Centro Southeast (Pob.)",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527014",
      "brgy_name": "Centro Southwest (Pob.)",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527015",
      "brgy_name": "Lanna",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527016",
      "brgy_name": "Lingu",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527017",
      "brgy_name": "Maguirig",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527018",
      "brgy_name": "Nabbotuan",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527019",
      "brgy_name": "Nangalisan",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527020",
      "brgy_name": "Natappian East",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527021",
      "brgy_name": "Padul",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527022",
      "brgy_name": "Palao",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527023",
      "brgy_name": "Parug-parug",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527024",
      "brgy_name": "Pataya",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527025",
      "brgy_name": "Sampaguita",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527026",
      "brgy_name": "Maddarulug (Santo Domingo)",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527030",
      "brgy_name": "Ubong",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527033",
      "brgy_name": "Dassun",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527034",
      "brgy_name": "Furagui",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527035",
      "brgy_name": "Gadu",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527036",
      "brgy_name": "Iraga",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527037",
      "brgy_name": "Andarayan South",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527038",
      "brgy_name": "Basi West",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527039",
      "brgy_name": "Bauan West",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527040",
      "brgy_name": "Calillauan",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527041",
      "brgy_name": "Gen. Eulogio Balao",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527042",
      "brgy_name": "Natappian West",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021527043",
      "brgy_name": "Malalam-Malacabibi",
      "city_code": "021527",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528001",
      "brgy_name": "Accusilian",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528002",
      "brgy_name": "Alabiao",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528003",
      "brgy_name": "Alabug",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528004",
      "brgy_name": "Angang",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528005",
      "brgy_name": "Bagumbayan",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528006",
      "brgy_name": "Barancuag",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528007",
      "brgy_name": "Battung",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528008",
      "brgy_name": "Bicok",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528009",
      "brgy_name": "Bugnay",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528010",
      "brgy_name": "Balagao",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528011",
      "brgy_name": "Cagumitan",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528012",
      "brgy_name": "Cato",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528013",
      "brgy_name": "Culong",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528014",
      "brgy_name": "Dagupan",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528015",
      "brgy_name": "Fugu",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528016",
      "brgy_name": "Lakambini",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528017",
      "brgy_name": "Lallayug",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528019",
      "brgy_name": "Malumin",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528020",
      "brgy_name": "Mambacag",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528021",
      "brgy_name": "San Vicente (Maleg)",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528022",
      "brgy_name": "Mungo",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528023",
      "brgy_name": "Naruangan",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528024",
      "brgy_name": "Palca",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528025",
      "brgy_name": "Pata",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528026",
      "brgy_name": "San Juan",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528027",
      "brgy_name": "San Luis (Gurengad)",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528028",
      "brgy_name": "Santo Tomas",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528029",
      "brgy_name": "Taribubu",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528030",
      "brgy_name": "Villa Laida",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528031",
      "brgy_name": "Poblacion I (Ward I Centro)",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528032",
      "brgy_name": "Poblacion II (Ward II Centro)",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021528033",
      "brgy_name": "Malalinta",
      "city_code": "021528",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529001",
      "brgy_name": "Annafunan East",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529002",
      "brgy_name": "Atulayan Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529003",
      "brgy_name": "Bagay",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529005",
      "brgy_name": "Centro 1 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529006",
      "brgy_name": "Centro 4 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529007",
      "brgy_name": "Centro 5 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529008",
      "brgy_name": "Centro 6 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529009",
      "brgy_name": "Centro 7 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529010",
      "brgy_name": "Centro 8 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529011",
      "brgy_name": "Centro 9 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529013",
      "brgy_name": "Centro 10 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529014",
      "brgy_name": "Centro 11 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529015",
      "brgy_name": "Buntun",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529016",
      "brgy_name": "Caggay",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529017",
      "brgy_name": "Capatan",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529018",
      "brgy_name": "Carig",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529019",
      "brgy_name": "Caritan Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529020",
      "brgy_name": "Caritan Sur",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529021",
      "brgy_name": "Cataggaman Nuevo",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529022",
      "brgy_name": "Cataggaman Viejo",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529023",
      "brgy_name": "Gosi Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529024",
      "brgy_name": "Larion Alto",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529025",
      "brgy_name": "Larion Bajo",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529026",
      "brgy_name": "Libag Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529027",
      "brgy_name": "Linao East",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529029",
      "brgy_name": "Nambbalan Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529030",
      "brgy_name": "Pallua Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529031",
      "brgy_name": "Pengue (Pengue-Ruyu)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529032",
      "brgy_name": "Tagga",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529033",
      "brgy_name": "Tanza",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529034",
      "brgy_name": "Ugac Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529035",
      "brgy_name": "Centro 2 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529036",
      "brgy_name": "Centro 3 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529037",
      "brgy_name": "Centro 12 (Pob.)",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529038",
      "brgy_name": "Annafunan West",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529039",
      "brgy_name": "Atulayan Sur",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529040",
      "brgy_name": "Caritan Centro",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529041",
      "brgy_name": "Cataggaman Pardo",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529042",
      "brgy_name": "Dadda",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529043",
      "brgy_name": "Gosi Sur",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529044",
      "brgy_name": "Leonarda",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529045",
      "brgy_name": "Libag Sur",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529046",
      "brgy_name": "Linao Norte",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529047",
      "brgy_name": "Linao West",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529048",
      "brgy_name": "Nambbalan Sur",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529049",
      "brgy_name": "Pallua Sur",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529050",
      "brgy_name": "Reyes",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529051",
      "brgy_name": "San Gabriel",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "021529052",
      "brgy_name": "Ugac Sur",
      "city_code": "021529",
      "province_code": "0215",
      "region_code": "02"
    },
    {
      "brgy_code": "023101001",
      "brgy_name": "Amistad",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101002",
      "brgy_name": "Antonino (Pob.)",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101003",
      "brgy_name": "Apanay",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101004",
      "brgy_name": "Aurora",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101005",
      "brgy_name": "Bagnos",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101006",
      "brgy_name": "Bagong Sikat",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101007",
      "brgy_name": "Bantug-Petines",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101008",
      "brgy_name": "Bonifacio",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101009",
      "brgy_name": "Burgos",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101010",
      "brgy_name": "Calaocan (Pob.)",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101011",
      "brgy_name": "Callao",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101012",
      "brgy_name": "Dagupan",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101013",
      "brgy_name": "Inanama",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101014",
      "brgy_name": "Linglingay",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101015",
      "brgy_name": "M.H. del Pilar",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101016",
      "brgy_name": "Mabini",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101017",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101018",
      "brgy_name": "Mataas na Kahoy",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101019",
      "brgy_name": "Paddad",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101020",
      "brgy_name": "Rizal",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101021",
      "brgy_name": "Rizaluna",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101022",
      "brgy_name": "Salvacion",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101023",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101024",
      "brgy_name": "San Fernando",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101025",
      "brgy_name": "San Francisco",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101026",
      "brgy_name": "San Juan",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101027",
      "brgy_name": "San Pablo",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101028",
      "brgy_name": "San Pedro",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101029",
      "brgy_name": "Santa Cruz",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101030",
      "brgy_name": "Santa Maria",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101031",
      "brgy_name": "Santo Domingo",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101032",
      "brgy_name": "Santo Tomas",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101033",
      "brgy_name": "Victoria",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023101034",
      "brgy_name": "Zamora",
      "city_code": "023101",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102001",
      "brgy_name": "Allangigan",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102002",
      "brgy_name": "Aniog",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102003",
      "brgy_name": "Baniket",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102004",
      "brgy_name": "Bannawag",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102005",
      "brgy_name": "Bantug",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102006",
      "brgy_name": "Barangcuag",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102007",
      "brgy_name": "Baui",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102008",
      "brgy_name": "Bonifacio",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102009",
      "brgy_name": "Buenavista",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102010",
      "brgy_name": "Bunnay",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102011",
      "brgy_name": "Calabayan-Minanga",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102012",
      "brgy_name": "Calaccab",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102013",
      "brgy_name": "Calaocan",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102014",
      "brgy_name": "Kalusutan",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102015",
      "brgy_name": "Campanario",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102016",
      "brgy_name": "Canangan",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102017",
      "brgy_name": "Centro I (Pob.)",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102018",
      "brgy_name": "Centro II (Pob.)",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102019",
      "brgy_name": "Centro III (Pob.)",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102020",
      "brgy_name": "Consular",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102021",
      "brgy_name": "Cumu",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102022",
      "brgy_name": "Dalakip",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102023",
      "brgy_name": "Dalenat",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102024",
      "brgy_name": "Dipaluda",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102025",
      "brgy_name": "Duroc",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102026",
      "brgy_name": "Lourdes (El Escaño)",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102027",
      "brgy_name": "Esperanza",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102028",
      "brgy_name": "Fugaru",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102029",
      "brgy_name": "Liwliwa",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102030",
      "brgy_name": "Ingud Norte",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102031",
      "brgy_name": "Ingud Sur",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102032",
      "brgy_name": "La Suerte",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102033",
      "brgy_name": "Lomboy",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102034",
      "brgy_name": "Loria",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102035",
      "brgy_name": "Mabuhay",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102036",
      "brgy_name": "Macalauat",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102037",
      "brgy_name": "Macaniao",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102038",
      "brgy_name": "Malannao",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102039",
      "brgy_name": "Malasin",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102040",
      "brgy_name": "Mangandingay",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102041",
      "brgy_name": "Minanga Proper",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102042",
      "brgy_name": "Pappat",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102043",
      "brgy_name": "Pissay",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102044",
      "brgy_name": "Ramona",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102045",
      "brgy_name": "Rancho Bassit",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102046",
      "brgy_name": "Rang-ayan",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102047",
      "brgy_name": "Salay",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102048",
      "brgy_name": "San Ambrocio",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102049",
      "brgy_name": "San Guillermo",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102050",
      "brgy_name": "San Isidro",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102051",
      "brgy_name": "San Marcelo",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102052",
      "brgy_name": "San Roque",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102053",
      "brgy_name": "San Vicente",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102054",
      "brgy_name": "Santo Niño",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102055",
      "brgy_name": "Saranay",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102056",
      "brgy_name": "Sinabbaran",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102058",
      "brgy_name": "Victory",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102059",
      "brgy_name": "Viga",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023102060",
      "brgy_name": "Villa Domingo",
      "city_code": "023102",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103001",
      "brgy_name": "Apiat",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103002",
      "brgy_name": "Bagnos",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103003",
      "brgy_name": "Bagong Tanza",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103004",
      "brgy_name": "Ballesteros",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103005",
      "brgy_name": "Bannagao",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103006",
      "brgy_name": "Bannawag",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103007",
      "brgy_name": "Bolinao",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103008",
      "brgy_name": "Caipilan",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103009",
      "brgy_name": "Camarunggayan",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103010",
      "brgy_name": "Dalig-Kalinga",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103011",
      "brgy_name": "Diamantina",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103012",
      "brgy_name": "Divisoria",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103013",
      "brgy_name": "Esperanza East",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103014",
      "brgy_name": "Esperanza West",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103015",
      "brgy_name": "Kalabaza",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103016",
      "brgy_name": "Rizaluna (Lapuz)",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103017",
      "brgy_name": "Macatal",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103018",
      "brgy_name": "Malasin",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103019",
      "brgy_name": "Nampicuan",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103020",
      "brgy_name": "Villa Nuesa",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103021",
      "brgy_name": "Panecien",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103022",
      "brgy_name": "San Andres",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103023",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103024",
      "brgy_name": "San Rafael",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103025",
      "brgy_name": "San Ramon",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103026",
      "brgy_name": "Santa Rita",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103027",
      "brgy_name": "Santa Rosa",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103028",
      "brgy_name": "Saranay",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103029",
      "brgy_name": "Sili",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103030",
      "brgy_name": "Victoria",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103031",
      "brgy_name": "Villa Fugu",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103032",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023103033",
      "brgy_name": "San Pedro-San Pablo (Pob.)",
      "city_code": "023103",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104001",
      "brgy_name": "Andabuen",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104002",
      "brgy_name": "Ara",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104003",
      "brgy_name": "Binogtungan",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104004",
      "brgy_name": "Capuseran (Capurocan)",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104005",
      "brgy_name": "Dagupan",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104006",
      "brgy_name": "Danipa",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104007",
      "brgy_name": "District II (Pob.)",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104008",
      "brgy_name": "Gomez",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104009",
      "brgy_name": "Guilingan",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104010",
      "brgy_name": "La Salette",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104011",
      "brgy_name": "Makindol",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104012",
      "brgy_name": "Maluno Norte",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104013",
      "brgy_name": "Maluno Sur",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104014",
      "brgy_name": "Nacalma",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104015",
      "brgy_name": "New Magsaysay",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104016",
      "brgy_name": "District I (Pob.)",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104017",
      "brgy_name": "Punit",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104018",
      "brgy_name": "San Carlos",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104019",
      "brgy_name": "San Francisco",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104021",
      "brgy_name": "Santa Cruz",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104022",
      "brgy_name": "Sevillana",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104023",
      "brgy_name": "Sinipit",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104024",
      "brgy_name": "Lucban",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104025",
      "brgy_name": "Villaluz",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104026",
      "brgy_name": "Yeban Norte",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104027",
      "brgy_name": "Yeban Sur",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104028",
      "brgy_name": "Santiago",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104029",
      "brgy_name": "Placer",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023104030",
      "brgy_name": "Balliao",
      "city_code": "023104",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105001",
      "brgy_name": "Bacnor East",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105002",
      "brgy_name": "Bacnor West",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105004",
      "brgy_name": "Caliguian (Pob.)",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105005",
      "brgy_name": "Catabban",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105006",
      "brgy_name": "Cullalabo Del Norte",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105007",
      "brgy_name": "Cullalabo San Antonio",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105008",
      "brgy_name": "Cullalabo Del Sur",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105009",
      "brgy_name": "Dalig",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105012",
      "brgy_name": "Malasin",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105013",
      "brgy_name": "Masigun",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105014",
      "brgy_name": "Raniag",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105015",
      "brgy_name": "San Bonifacio",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105016",
      "brgy_name": "San Miguel",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023105017",
      "brgy_name": "San Roque",
      "city_code": "023105",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106001",
      "brgy_name": "Aggub",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106002",
      "brgy_name": "Anao",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106003",
      "brgy_name": "Angancasilian",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106004",
      "brgy_name": "Balasig",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106005",
      "brgy_name": "Cansan",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106006",
      "brgy_name": "Casibarag Norte",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106007",
      "brgy_name": "Casibarag Sur",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106008",
      "brgy_name": "Catabayungan",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106009",
      "brgy_name": "Cubag",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106010",
      "brgy_name": "Garita",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106011",
      "brgy_name": "Luquilu",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106012",
      "brgy_name": "Mabangug",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106013",
      "brgy_name": "Magassi",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106015",
      "brgy_name": "Ngarag",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106016",
      "brgy_name": "Pilig Abajo",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106017",
      "brgy_name": "Pilig Alto",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106018",
      "brgy_name": "Centro (Pob.)",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106019",
      "brgy_name": "San Bernardo",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106020",
      "brgy_name": "San Juan",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106021",
      "brgy_name": "Saui",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106022",
      "brgy_name": "Tallag",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106023",
      "brgy_name": "Ugad",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106024",
      "brgy_name": "Union",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106025",
      "brgy_name": "Masipi East",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106026",
      "brgy_name": "Masipi West",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023106027",
      "brgy_name": "San Antonio",
      "city_code": "023106",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107001",
      "brgy_name": "Rang-ay (Caggong)",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107002",
      "brgy_name": "Calaocan",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107003",
      "brgy_name": "Canan",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107004",
      "brgy_name": "Centro (Pob.)",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107005",
      "brgy_name": "Culing Centro",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107006",
      "brgy_name": "Culing East",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107007",
      "brgy_name": "Culing West",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107008",
      "brgy_name": "Del Corpuz",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107009",
      "brgy_name": "Del Pilar",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107010",
      "brgy_name": "Diamantina",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107011",
      "brgy_name": "La Paz",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107012",
      "brgy_name": "Luzon",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107013",
      "brgy_name": "Macalaoat",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107014",
      "brgy_name": "Magdalena",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107015",
      "brgy_name": "Magsaysay",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107016",
      "brgy_name": "Namnama",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107017",
      "brgy_name": "Nueva Era",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107018",
      "brgy_name": "Paraiso",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107019",
      "brgy_name": "Sampaloc",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107020",
      "brgy_name": "San Andres (Teodoro Abad)",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107021",
      "brgy_name": "Saranay",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023107022",
      "brgy_name": "Tandul",
      "city_code": "023107",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108001",
      "brgy_name": "Alicaocao",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108002",
      "brgy_name": "Alinam",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108003",
      "brgy_name": "Amobocan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108004",
      "brgy_name": "Andarayan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108006",
      "brgy_name": "Baculod",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108007",
      "brgy_name": "Baringin Norte",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108008",
      "brgy_name": "Baringin Sur",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108009",
      "brgy_name": "Buena Suerte",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108010",
      "brgy_name": "Bugallon",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108011",
      "brgy_name": "Buyon",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108012",
      "brgy_name": "Cabaruan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108014",
      "brgy_name": "Cabugao",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108015",
      "brgy_name": "Carabatan Chica",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108016",
      "brgy_name": "Carabatan Grande",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108017",
      "brgy_name": "Carabatan Punta",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108018",
      "brgy_name": "Carabatan Bacareno",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108019",
      "brgy_name": "Casalatan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108020",
      "brgy_name": "San Pablo (Casap Hacienda)",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108022",
      "brgy_name": "Cassap Fuera",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108023",
      "brgy_name": "Catalina",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108024",
      "brgy_name": "Culalabat",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108025",
      "brgy_name": "Dabburab",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108028",
      "brgy_name": "De Vera",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108029",
      "brgy_name": "Dianao",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108030",
      "brgy_name": "Disimuray",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108031",
      "brgy_name": "District I (Pob.)",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108032",
      "brgy_name": "District II (Pob.)",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108033",
      "brgy_name": "District III (Pob.)",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108034",
      "brgy_name": "Duminit",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108035",
      "brgy_name": "Faustino (Sipay)",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108036",
      "brgy_name": "Gagabutan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108037",
      "brgy_name": "Gappal",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108038",
      "brgy_name": "Guayabal",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108039",
      "brgy_name": "Labinab",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108040",
      "brgy_name": "Linglingay",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108041",
      "brgy_name": "Mabantad",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108043",
      "brgy_name": "Maligaya",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108044",
      "brgy_name": "Manaoag",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108045",
      "brgy_name": "Marabulig I",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108046",
      "brgy_name": "Marabulig II",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108048",
      "brgy_name": "Minante I",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108049",
      "brgy_name": "Minante II",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108050",
      "brgy_name": "Nagcampegan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108051",
      "brgy_name": "Naganacan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108052",
      "brgy_name": "Nagrumbuan",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108053",
      "brgy_name": "Nungnungan I",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108054",
      "brgy_name": "Nungnungan II",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108055",
      "brgy_name": "Pinoma",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108056",
      "brgy_name": "Rizal",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108057",
      "brgy_name": "Rogus",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108058",
      "brgy_name": "San Antonio",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108059",
      "brgy_name": "San Fermin",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108060",
      "brgy_name": "San Francisco",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108061",
      "brgy_name": "San Isidro",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108062",
      "brgy_name": "San Luis",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108064",
      "brgy_name": "Santa Luciana (Daburab 2)",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108065",
      "brgy_name": "Santa Maria",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108066",
      "brgy_name": "Sillawit",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108067",
      "brgy_name": "Sinippil",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108069",
      "brgy_name": "Tagaran",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108070",
      "brgy_name": "Turayong",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108071",
      "brgy_name": "Union",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108072",
      "brgy_name": "Villa Concepcion",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108073",
      "brgy_name": "Villa Luna",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023108074",
      "brgy_name": "Villaflor",
      "city_code": "023108",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109001",
      "brgy_name": "Aguinaldo (Rizaluna Este)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109002",
      "brgy_name": "Calimaturod",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109003",
      "brgy_name": "Capirpiriwan",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109004",
      "brgy_name": "Caquilingan (San Luis)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109005",
      "brgy_name": "Dallao",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109006",
      "brgy_name": "Gayong",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109007",
      "brgy_name": "Laurel (Centro Norte)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109008",
      "brgy_name": "Magsaysay (Centro Sur Oeste)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109009",
      "brgy_name": "Malapat",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109011",
      "brgy_name": "Osmena (Centro Sur Este)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109012",
      "brgy_name": "Quezon (Centro Norte Este)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109013",
      "brgy_name": "Quirino (Manasin)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109014",
      "brgy_name": "Rizaluna (Rizaluna Oeste)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109015",
      "brgy_name": "Roxas Pob. (Centro Sur)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109016",
      "brgy_name": "Sagat",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109017",
      "brgy_name": "San Juan (San Juan Este)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109018",
      "brgy_name": "Taliktik",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109019",
      "brgy_name": "Tanggal",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109020",
      "brgy_name": "Tarinsing",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109021",
      "brgy_name": "Turod Norte",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109022",
      "brgy_name": "Turod Sur",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109023",
      "brgy_name": "Villamiemban",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109024",
      "brgy_name": "Villamarzo",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109025",
      "brgy_name": "Anonang (Balitoc*)",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109026",
      "brgy_name": "Camarao",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023109027",
      "brgy_name": "Wigan",
      "city_code": "023109",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023110001",
      "brgy_name": "Ayod",
      "city_code": "023110",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023110002",
      "brgy_name": "Bucal Sur",
      "city_code": "023110",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023110003",
      "brgy_name": "Bucal Norte",
      "city_code": "023110",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023110004",
      "brgy_name": "Dibulo",
      "city_code": "023110",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023110005",
      "brgy_name": "Digumased (Pob.)",
      "city_code": "023110",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023110006",
      "brgy_name": "Dimaluade",
      "city_code": "023110",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111003",
      "brgy_name": "Dicambangan",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111004",
      "brgy_name": "Dicaroyan",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111005",
      "brgy_name": "Dicatian",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111006",
      "brgy_name": "Bicobian",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111007",
      "brgy_name": "Dilakit",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111008",
      "brgy_name": "Dimapnat",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111009",
      "brgy_name": "Dimapula (Pob.)",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111010",
      "brgy_name": "Dimasalansan",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111011",
      "brgy_name": "Dipudo",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111012",
      "brgy_name": "Dibulos",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111013",
      "brgy_name": "Ditarum",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023111016",
      "brgy_name": "Sapinit",
      "city_code": "023111",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112001",
      "brgy_name": "Angoluan",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112002",
      "brgy_name": "Annafunan",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112003",
      "brgy_name": "Arabiat",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112004",
      "brgy_name": "Aromin",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112005",
      "brgy_name": "Babaran",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112006",
      "brgy_name": "Bacradal",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112007",
      "brgy_name": "Benguet",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112008",
      "brgy_name": "Buneg",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112009",
      "brgy_name": "Busilelao",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112010",
      "brgy_name": "Caniguing",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112011",
      "brgy_name": "Carulay",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112012",
      "brgy_name": "Castillo",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112013",
      "brgy_name": "Dammang East",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112014",
      "brgy_name": "Dammang West",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112015",
      "brgy_name": "Dicaraoyan",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112016",
      "brgy_name": "Dugayong",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112017",
      "brgy_name": "Fugu",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112018",
      "brgy_name": "Garit Norte",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112019",
      "brgy_name": "Garit Sur",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112020",
      "brgy_name": "Gucab",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112021",
      "brgy_name": "Gumbauan",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112023",
      "brgy_name": "Ipil",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112024",
      "brgy_name": "Libertad",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112025",
      "brgy_name": "Mabbayad",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112026",
      "brgy_name": "Mabuhay",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112027",
      "brgy_name": "Madadamian",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112028",
      "brgy_name": "Magleticia",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112029",
      "brgy_name": "Malibago",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112030",
      "brgy_name": "Maligaya",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112031",
      "brgy_name": "Malitao",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112032",
      "brgy_name": "Narra",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112033",
      "brgy_name": "Nilumisu",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112034",
      "brgy_name": "Pag-asa",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112036",
      "brgy_name": "Pangal Norte",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112037",
      "brgy_name": "Pangal Sur",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112039",
      "brgy_name": "Rumang-ay",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112040",
      "brgy_name": "Salay",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112041",
      "brgy_name": "Salvacion",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112042",
      "brgy_name": "San Antonio Ugad",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112043",
      "brgy_name": "San Antonio Minit",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112044",
      "brgy_name": "San Carlos",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112045",
      "brgy_name": "San Fabian",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112046",
      "brgy_name": "San Felipe",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112047",
      "brgy_name": "San Juan",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112048",
      "brgy_name": "San Manuel",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112049",
      "brgy_name": "San Miguel",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112050",
      "brgy_name": "San Salvador",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112051",
      "brgy_name": "Santa Ana",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112052",
      "brgy_name": "Santa Cruz",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112053",
      "brgy_name": "Santa Maria",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112054",
      "brgy_name": "Santa Monica",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112055",
      "brgy_name": "Santo Domingo",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112056",
      "brgy_name": "Silauan Sur (Pob.)",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112057",
      "brgy_name": "Silauan Norte (Pob.)",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112058",
      "brgy_name": "Sinabbaran",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112059",
      "brgy_name": "Soyung",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112060",
      "brgy_name": "Taggappan",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112061",
      "brgy_name": "Tuguegarao",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112062",
      "brgy_name": "Villa Campo",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112063",
      "brgy_name": "Villa Fermin",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112064",
      "brgy_name": "Villa Rey",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112065",
      "brgy_name": "Villa Victoria",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112066",
      "brgy_name": "Cabugao (Pob.)",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023112067",
      "brgy_name": "Diasan",
      "city_code": "023112",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113002",
      "brgy_name": "Barcolan",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113004",
      "brgy_name": "Buenavista",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113005",
      "brgy_name": "Dammao",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113006",
      "brgy_name": "Furao",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113009",
      "brgy_name": "Guibang",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113010",
      "brgy_name": "Lenzon",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113011",
      "brgy_name": "Linglingay",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113012",
      "brgy_name": "Mabini",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113016",
      "brgy_name": "Pintor",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113017",
      "brgy_name": "District I (Pob.)",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113018",
      "brgy_name": "District II (Pob.)",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113019",
      "brgy_name": "District III (Pob.)",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113020",
      "brgy_name": "Rizal",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113021",
      "brgy_name": "Songsong",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113024",
      "brgy_name": "Union",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023113025",
      "brgy_name": "Upi",
      "city_code": "023113",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114001",
      "brgy_name": "Cabeseria 27 (Abuan)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114002",
      "brgy_name": "Aggasian",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114003",
      "brgy_name": "Alibagu",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114004",
      "brgy_name": "Allinguigan 1st",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114005",
      "brgy_name": "Allinguigan 2nd",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114006",
      "brgy_name": "Allinguigan 3rd",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114008",
      "brgy_name": "Arusip",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114009",
      "brgy_name": "Baculod (Pob.)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114011",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114013",
      "brgy_name": "Baligatan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114014",
      "brgy_name": "Ballacong",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114015",
      "brgy_name": "Bangag",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114016",
      "brgy_name": "Cabeseria 5 (Baribad)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114017",
      "brgy_name": "Batong-Labang",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114018",
      "brgy_name": "Bigao",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114020",
      "brgy_name": "Cabeseria 4 (San Manuel)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114023",
      "brgy_name": "Cabannungan 1st",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114024",
      "brgy_name": "Cabannungan 2nd",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114025",
      "brgy_name": "Cabeseria 6 & 24 (Villa Marcos*)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114027",
      "brgy_name": "Cabeseria 19 (Villa Suerte*)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114029",
      "brgy_name": "Cabeseria 25 (Sta. Lucia*)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114030",
      "brgy_name": "Cabeseria 3 (San Fernando)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114031",
      "brgy_name": "Cabeseria 23 (San Francisco*)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114032",
      "brgy_name": "Cadu",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114033",
      "brgy_name": "Calamagui 1st",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114034",
      "brgy_name": "Calamagui 2nd",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114035",
      "brgy_name": "Camunatan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114037",
      "brgy_name": "Capellan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114038",
      "brgy_name": "Capo",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114039",
      "brgy_name": "Cabeseria 9 and 11 (Capogotan)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114040",
      "brgy_name": "Carikkikan Norte",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114041",
      "brgy_name": "Carikkikan Sur",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114042",
      "brgy_name": "Cabeseria 14 and 16 (Casilagan)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114044",
      "brgy_name": "Cabeseria 2 (Dappat)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114045",
      "brgy_name": "Fugu",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114046",
      "brgy_name": "Fuyo",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114047",
      "brgy_name": "Gayong-Gayong Norte",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114048",
      "brgy_name": "Gayong-Gayong Sur",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114049",
      "brgy_name": "Guinatan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114050",
      "brgy_name": "Lullutan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114051",
      "brgy_name": "Cabeseria 10 (Lapigui)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114052",
      "brgy_name": "Malalam",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114053",
      "brgy_name": "Malasin (Angeles)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114054",
      "brgy_name": "Manaring",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114055",
      "brgy_name": "Mangcuram",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114056",
      "brgy_name": "Villa Imelda (Maplas)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114057",
      "brgy_name": "Marana I",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114058",
      "brgy_name": "Marana II",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114059",
      "brgy_name": "Marana III",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114060",
      "brgy_name": "Minabang",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114061",
      "brgy_name": "Morado",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114062",
      "brgy_name": "Naguilian Norte",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114063",
      "brgy_name": "Naguilian Sur",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114064",
      "brgy_name": "Namnama",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114065",
      "brgy_name": "Nanaguan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114066",
      "brgy_name": "Cabeseria 7 (Nangalisan)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114067",
      "brgy_name": "Osmeña (Sinippil)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114068",
      "brgy_name": "Paliueg",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114070",
      "brgy_name": "Pasa",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114071",
      "brgy_name": "Pilar",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114072",
      "brgy_name": "Quimalabasa",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114073",
      "brgy_name": "Rang-ayan (Bintacan)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114074",
      "brgy_name": "Rugao",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114075",
      "brgy_name": "Cabeseria 22 (Sablang)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114076",
      "brgy_name": "Salindingan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114077",
      "brgy_name": "San Andres (Angarilla)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114079",
      "brgy_name": "Centro - San Antonio",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114080",
      "brgy_name": "San Felipe",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114081",
      "brgy_name": "San Ignacio (Canapi)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114082",
      "brgy_name": "San Isidro",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114083",
      "brgy_name": "San Juan",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114084",
      "brgy_name": "San Lorenzo",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114085",
      "brgy_name": "San Pablo",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114086",
      "brgy_name": "Cabeseria 17 and 21 (San Rafael)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114087",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114088",
      "brgy_name": "Santa Barbara (Pob.)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114089",
      "brgy_name": "Santa Catalina",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114091",
      "brgy_name": "Santa Isabel Norte",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114092",
      "brgy_name": "Santa Isabel Sur",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114093",
      "brgy_name": "Santa Victoria",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114094",
      "brgy_name": "Santo Tomas",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114095",
      "brgy_name": "Siffu",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114096",
      "brgy_name": "Sindon Bayabo",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114097",
      "brgy_name": "Sindon Maride",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114098",
      "brgy_name": "Sipay",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114099",
      "brgy_name": "Tangcul",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114100",
      "brgy_name": "Centro Poblacion",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114101",
      "brgy_name": "Bagong Silang",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114102",
      "brgy_name": "Imelda Bliss Village",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114103",
      "brgy_name": "San Rodrigo",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023114104",
      "brgy_name": "Santa Maria (Cabeseria 8)",
      "city_code": "023114",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115001",
      "brgy_name": "Abulan",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115002",
      "brgy_name": "Addalam",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115003",
      "brgy_name": "Arubub",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115004",
      "brgy_name": "Bannawag",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115005",
      "brgy_name": "Bantay",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115006",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115007",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115008",
      "brgy_name": "Barangcuag",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115009",
      "brgy_name": "Dalibubon",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115010",
      "brgy_name": "Daligan",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115011",
      "brgy_name": "Diarao",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115012",
      "brgy_name": "Dibuluan",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115013",
      "brgy_name": "Dicamay I",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115014",
      "brgy_name": "Dicamay II",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115015",
      "brgy_name": "Dipangit",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115016",
      "brgy_name": "Disimpit",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115017",
      "brgy_name": "Divinan",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115018",
      "brgy_name": "Dumawing",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115019",
      "brgy_name": "Fugu",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115020",
      "brgy_name": "Lacab",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115021",
      "brgy_name": "Linamanan",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115022",
      "brgy_name": "Linomot",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115023",
      "brgy_name": "Malannit",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115024",
      "brgy_name": "Minuri",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115025",
      "brgy_name": "Namnama",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115026",
      "brgy_name": "Napaliong",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115028",
      "brgy_name": "Palagao",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115030",
      "brgy_name": "Papan Este",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115031",
      "brgy_name": "Papan Weste",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115032",
      "brgy_name": "Payac",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115033",
      "brgy_name": "Pongpongan",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115034",
      "brgy_name": "San Antonio",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115035",
      "brgy_name": "San Isidro",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115036",
      "brgy_name": "San Jose",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115037",
      "brgy_name": "San Roque",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115038",
      "brgy_name": "San Sebastian",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115039",
      "brgy_name": "San Vicente",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115040",
      "brgy_name": "Santa Isabel",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115041",
      "brgy_name": "Santo Domingo",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115042",
      "brgy_name": "Tupax",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115043",
      "brgy_name": "Usol",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023115044",
      "brgy_name": "Villa Bello",
      "city_code": "023115",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116001",
      "brgy_name": "Bustamante",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116002",
      "brgy_name": "Centro 1 (Pob.)",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116003",
      "brgy_name": "Centro 2 (Pob.)",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116004",
      "brgy_name": "Centro 3 (Pob.)",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116005",
      "brgy_name": "Concepcion",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116006",
      "brgy_name": "Dadap",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116007",
      "brgy_name": "Harana",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116008",
      "brgy_name": "Lalog 1",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116009",
      "brgy_name": "Lalog 2",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116010",
      "brgy_name": "Luyao",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116011",
      "brgy_name": "Macañao",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116012",
      "brgy_name": "Macugay",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116013",
      "brgy_name": "Mambabanga",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116014",
      "brgy_name": "Pulay",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116015",
      "brgy_name": "Puroc",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116016",
      "brgy_name": "San Isidro",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116017",
      "brgy_name": "San Miguel",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116018",
      "brgy_name": "Santo Domingo",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023116019",
      "brgy_name": "Union Kalinga",
      "city_code": "023116",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117001",
      "brgy_name": "Diana",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117002",
      "brgy_name": "Eleonor (Pob.)",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117003",
      "brgy_name": "Fely (Pob.)",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117004",
      "brgy_name": "Lita (Pob.)",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117005",
      "brgy_name": "Reina Mercedes",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117006",
      "brgy_name": "Minanga",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117007",
      "brgy_name": "Malasin",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117008",
      "brgy_name": "Canadam",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117009",
      "brgy_name": "Aplaya",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023117010",
      "brgy_name": "Santa Marina (Dianggo)",
      "city_code": "023117",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118001",
      "brgy_name": "Aga",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118002",
      "brgy_name": "Andarayan",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118003",
      "brgy_name": "Aneg",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118004",
      "brgy_name": "Bayabo",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118006",
      "brgy_name": "Calinaoan Sur",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118007",
      "brgy_name": "Capitol",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118008",
      "brgy_name": "Carmencita",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118009",
      "brgy_name": "Concepcion",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118010",
      "brgy_name": "Maui",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118011",
      "brgy_name": "Quibal",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118012",
      "brgy_name": "Ragan Almacen",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118013",
      "brgy_name": "Ragan Norte",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118014",
      "brgy_name": "Ragan Sur (Pob.)",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118015",
      "brgy_name": "Rizal (Ragan Almacen Alto)",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118016",
      "brgy_name": "San Andres",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118017",
      "brgy_name": "San Antonio",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118018",
      "brgy_name": "San Isidro",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118019",
      "brgy_name": "San Jose",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118020",
      "brgy_name": "San Juan",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118021",
      "brgy_name": "San Macario",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118022",
      "brgy_name": "San Nicolas (Fusi)",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118023",
      "brgy_name": "San Patricio",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118024",
      "brgy_name": "San Roque",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118025",
      "brgy_name": "Santo Rosario",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118026",
      "brgy_name": "Santor",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118027",
      "brgy_name": "Villa Luz",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118028",
      "brgy_name": "Villa Pereda",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118029",
      "brgy_name": "Visitacion",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023118030",
      "brgy_name": "Caloocan",
      "city_code": "023118",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119001",
      "brgy_name": "San Pedro (Barucbuc Sur)",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119002",
      "brgy_name": "Binmonton",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119003",
      "brgy_name": "Casili",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119004",
      "brgy_name": "Centro I (Pob.)",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119005",
      "brgy_name": "Holy Friday",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119006",
      "brgy_name": "Maligaya",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119007",
      "brgy_name": "Manano",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119008",
      "brgy_name": "Olango",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119009",
      "brgy_name": "Centro II (Pob.)",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119010",
      "brgy_name": "Rang-ayan",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119011",
      "brgy_name": "San Jose Norte I",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119012",
      "brgy_name": "San Jose Sur",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119013",
      "brgy_name": "Siempre Viva Norte",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119014",
      "brgy_name": "Trinidad",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119015",
      "brgy_name": "Victoria",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119016",
      "brgy_name": "San Jose Norte II",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119017",
      "brgy_name": "San Ramon",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023119018",
      "brgy_name": "Siempre Viva Sur",
      "city_code": "023119",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120001",
      "brgy_name": "Aguinaldo",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120002",
      "brgy_name": "Bagong Sikat",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120003",
      "brgy_name": "Burgos",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120004",
      "brgy_name": "Cabaruan",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120005",
      "brgy_name": "Flores",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120006",
      "brgy_name": "La Union",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120007",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120008",
      "brgy_name": "Manaring",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120009",
      "brgy_name": "Mansibang",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120010",
      "brgy_name": "Minallo",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120011",
      "brgy_name": "Minanga",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120012",
      "brgy_name": "Palattao",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120013",
      "brgy_name": "Quezon (Pob.)",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120014",
      "brgy_name": "Quinalabasa",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120015",
      "brgy_name": "Quirino (Pob.)",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120016",
      "brgy_name": "Rangayan",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120017",
      "brgy_name": "Rizal",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120018",
      "brgy_name": "Roxas (Pob.)",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120019",
      "brgy_name": "San Manuel",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120020",
      "brgy_name": "Santo Tomas",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120021",
      "brgy_name": "Sunlife",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120022",
      "brgy_name": "Surcoc",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120023",
      "brgy_name": "Tomines",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120024",
      "brgy_name": "Villa Paz",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023120025",
      "brgy_name": "Santa Victoria (Villa Capuchino)",
      "city_code": "023120",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121001",
      "brgy_name": "Bisag",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121002",
      "brgy_name": "Dialaoyao",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121003",
      "brgy_name": "Dicadyuan",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121004",
      "brgy_name": "Didiyan",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121005",
      "brgy_name": "Dimalicu-licu",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121006",
      "brgy_name": "Dimasari",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121007",
      "brgy_name": "Dimatican",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121008",
      "brgy_name": "Maligaya",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121009",
      "brgy_name": "Marikit",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121010",
      "brgy_name": "Dicabisagan East (Pob.)",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121011",
      "brgy_name": "Dicabisagan West (Pob.)",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121012",
      "brgy_name": "Santa Jacinta",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121013",
      "brgy_name": "Villa Robles",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121014",
      "brgy_name": "Culasi",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121015",
      "brgy_name": "Alomanay",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121016",
      "brgy_name": "Diddadungan",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023121017",
      "brgy_name": "San Isidro",
      "city_code": "023121",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122001",
      "brgy_name": "Abut",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122002",
      "brgy_name": "Alunan (Pob.)",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122003",
      "brgy_name": "Arellano (Pob.)",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122004",
      "brgy_name": "Aurora",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122005",
      "brgy_name": "Barucboc Norte",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122006",
      "brgy_name": "Estrada",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122007",
      "brgy_name": "Santos (Pob.)",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122008",
      "brgy_name": "Lepanto",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122009",
      "brgy_name": "Mangga",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122010",
      "brgy_name": "Minagbag",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122011",
      "brgy_name": "Samonte (Pob.)",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122012",
      "brgy_name": "Turod",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122013",
      "brgy_name": "Dunmon",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122014",
      "brgy_name": "Calangigan (Calamagui)",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023122015",
      "brgy_name": "San Juan",
      "city_code": "023122",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123001",
      "brgy_name": "Binarzang",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123002",
      "brgy_name": "Cabaruan",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123003",
      "brgy_name": "Camaal",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123004",
      "brgy_name": "Dolores",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123005",
      "brgy_name": "Luna",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123006",
      "brgy_name": "Manaoag",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123007",
      "brgy_name": "Rizal",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123008",
      "brgy_name": "San Isidro",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123009",
      "brgy_name": "San Jose",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123010",
      "brgy_name": "San Juan",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123011",
      "brgy_name": "San Mateo",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123012",
      "brgy_name": "San Vicente",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123013",
      "brgy_name": "Santa Catalina",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123014",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123015",
      "brgy_name": "Santiago",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123016",
      "brgy_name": "Santo Domingo",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123017",
      "brgy_name": "Sinait",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123018",
      "brgy_name": "Suerte",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123019",
      "brgy_name": "Villa Bulusan",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123020",
      "brgy_name": "Villa Miguel (Ugak)",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023123021",
      "brgy_name": "Vintar",
      "city_code": "023123",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124001",
      "brgy_name": "Ambatali",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124002",
      "brgy_name": "Bantug",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124004",
      "brgy_name": "Bugallon Norte",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124005",
      "brgy_name": "Burgos",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124006",
      "brgy_name": "Nagbacalan",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124007",
      "brgy_name": "Oscariz",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124008",
      "brgy_name": "Pabil",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124009",
      "brgy_name": "Pagrang-ayan",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124011",
      "brgy_name": "Planas",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124012",
      "brgy_name": "Purok ni Bulan",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124013",
      "brgy_name": "Raniag",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124014",
      "brgy_name": "San Antonio",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124015",
      "brgy_name": "San Miguel",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124016",
      "brgy_name": "San Sebastian",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124018",
      "brgy_name": "Villa Beltran",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124019",
      "brgy_name": "Villa Carmen",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124020",
      "brgy_name": "Villa Marcos",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124021",
      "brgy_name": "General Aguinaldo",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023124022",
      "brgy_name": "Bugallon Proper (Pob.)",
      "city_code": "023124",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125001",
      "brgy_name": "Banquero",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125002",
      "brgy_name": "Binarsang",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125003",
      "brgy_name": "Cutog Grande",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125004",
      "brgy_name": "Cutog Pequeño",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125005",
      "brgy_name": "Dangan",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125006",
      "brgy_name": "District I (Pob.)",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125007",
      "brgy_name": "District II (Pob.)",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125008",
      "brgy_name": "Labinab Grande (Pob.)",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125009",
      "brgy_name": "Labinab Pequeño (Pob.)",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125010",
      "brgy_name": "Mallalatang Grande",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125011",
      "brgy_name": "Mallalatang Tunggui",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125012",
      "brgy_name": "Napaccu Grande",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125013",
      "brgy_name": "Napaccu Pequeño",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125014",
      "brgy_name": "Salucong",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125015",
      "brgy_name": "Santor",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125016",
      "brgy_name": "Sinippil",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125017",
      "brgy_name": "Tallungan (Pob.)",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125019",
      "brgy_name": "Turod",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125020",
      "brgy_name": "Villador",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023125021",
      "brgy_name": "Santiago",
      "city_code": "023125",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126001",
      "brgy_name": "Anao",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126004",
      "brgy_name": "Imbiao",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126005",
      "brgy_name": "Lanting",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126006",
      "brgy_name": "Lucban",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126007",
      "brgy_name": "Marcos",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126008",
      "brgy_name": "Masigun",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126011",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126012",
      "brgy_name": "Vira (Pob.)",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126013",
      "brgy_name": "Bantug (Pob.)",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126014",
      "brgy_name": "Luna (Pob.)",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126015",
      "brgy_name": "Quiling",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126016",
      "brgy_name": "Rang-ayan",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126017",
      "brgy_name": "San Antonio",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126018",
      "brgy_name": "San Jose",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126019",
      "brgy_name": "San Pedro",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126020",
      "brgy_name": "San Placido",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126021",
      "brgy_name": "San Rafael",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126023",
      "brgy_name": "Simimbaan",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126024",
      "brgy_name": "Sinamar",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126025",
      "brgy_name": "Sotero Nuesa",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126026",
      "brgy_name": "Villa Concepcion",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126028",
      "brgy_name": "Matusalem",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126029",
      "brgy_name": "Muñoz East",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126030",
      "brgy_name": "Muñoz West",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126031",
      "brgy_name": "Doña Concha",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023126032",
      "brgy_name": "San Luis",
      "city_code": "023126",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127001",
      "brgy_name": "Bautista",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127002",
      "brgy_name": "Calaocan",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127003",
      "brgy_name": "Dabubu Grande",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127004",
      "brgy_name": "Dabubu Pequeño",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127005",
      "brgy_name": "Dappig",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127006",
      "brgy_name": "Laoag",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127007",
      "brgy_name": "Mapalad",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127008",
      "brgy_name": "Masaya Centro (Pob.)",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127009",
      "brgy_name": "Masaya Norte",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127010",
      "brgy_name": "Masaya Sur",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127011",
      "brgy_name": "Nemmatan",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127012",
      "brgy_name": "Palacian",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127013",
      "brgy_name": "Panang",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127014",
      "brgy_name": "Quimalabasa Norte",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127015",
      "brgy_name": "Quimalabasa Sur",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127016",
      "brgy_name": "Rang-ay",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127017",
      "brgy_name": "Salay",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127018",
      "brgy_name": "San Antonio",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127019",
      "brgy_name": "Santo Niño",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127020",
      "brgy_name": "Santos",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127021",
      "brgy_name": "Sinaoangan Norte",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127022",
      "brgy_name": "Sinaoangan Sur",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023127023",
      "brgy_name": "Virgoneza",
      "city_code": "023127",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128001",
      "brgy_name": "Anonang",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128002",
      "brgy_name": "Aringay",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128004",
      "brgy_name": "Centro 1 (Pob.)",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128005",
      "brgy_name": "Centro 2 (Pob.)",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128006",
      "brgy_name": "Colorado",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128007",
      "brgy_name": "Dietban",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128008",
      "brgy_name": "Dingading",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128009",
      "brgy_name": "Dipacamo",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128010",
      "brgy_name": "Estrella",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128011",
      "brgy_name": "Guam",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128013",
      "brgy_name": "Nakar",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128014",
      "brgy_name": "Palawan",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128015",
      "brgy_name": "Progreso",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128016",
      "brgy_name": "Rizal",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128017",
      "brgy_name": "San Francisco Sur",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128018",
      "brgy_name": "San Mariano Norte",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128019",
      "brgy_name": "San Mariano Sur",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128020",
      "brgy_name": "Sinalugan",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128021",
      "brgy_name": "Villa Remedios",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128022",
      "brgy_name": "Villa Rose",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128023",
      "brgy_name": "Villa Sanchez",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128024",
      "brgy_name": "Villa Teresita",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128025",
      "brgy_name": "Burgos",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128026",
      "brgy_name": "San Francisco Norte",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128027",
      "brgy_name": "Calaoagan",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023128028",
      "brgy_name": "San Rafael",
      "city_code": "023128",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129001",
      "brgy_name": "Camarag",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129002",
      "brgy_name": "Cebu",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129003",
      "brgy_name": "Gomez",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129004",
      "brgy_name": "Gud",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129005",
      "brgy_name": "Nagbukel",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129006",
      "brgy_name": "Patanad",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129007",
      "brgy_name": "Quezon",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129009",
      "brgy_name": "Ramos East",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129010",
      "brgy_name": "Ramos West",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129012",
      "brgy_name": "Rizal East (Pob.)",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129013",
      "brgy_name": "Rizal West (Pob.)",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129014",
      "brgy_name": "Victoria",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023129015",
      "brgy_name": "Villaflor",
      "city_code": "023129",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130001",
      "brgy_name": "Agliam",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130002",
      "brgy_name": "Babanuang",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130003",
      "brgy_name": "Cabaritan",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130004",
      "brgy_name": "Caraniogan",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130006",
      "brgy_name": "Eden",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130007",
      "brgy_name": "Malalinta",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130008",
      "brgy_name": "Mararigue",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130009",
      "brgy_name": "Nueva Era",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130010",
      "brgy_name": "Pisang",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130011",
      "brgy_name": "District 1 (Pob.)",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130012",
      "brgy_name": "District 2 (Pob.)",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130013",
      "brgy_name": "District 3 (Pob.)",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130014",
      "brgy_name": "District 4 (Pob.)",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130015",
      "brgy_name": "San Francisco",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130016",
      "brgy_name": "Sandiat Centro",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130017",
      "brgy_name": "Sandiat East",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130018",
      "brgy_name": "Sandiat West",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130019",
      "brgy_name": "Santa Cruz",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023130020",
      "brgy_name": "Villanueva",
      "city_code": "023130",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131001",
      "brgy_name": "Alibadabad",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131003",
      "brgy_name": "Binatug",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131004",
      "brgy_name": "Bitabian",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131005",
      "brgy_name": "Buyasan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131006",
      "brgy_name": "Cadsalan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131007",
      "brgy_name": "Casala",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131008",
      "brgy_name": "Cataguing",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131009",
      "brgy_name": "Daragutan East",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131010",
      "brgy_name": "Daragutan West",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131011",
      "brgy_name": "Del Pilar",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131012",
      "brgy_name": "Dibuluan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131013",
      "brgy_name": "Dicamay",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131014",
      "brgy_name": "Dipusu",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131015",
      "brgy_name": "Disulap",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131016",
      "brgy_name": "Disusuan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131017",
      "brgy_name": "Gangalan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131018",
      "brgy_name": "Ibujan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131019",
      "brgy_name": "Libertad",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131020",
      "brgy_name": "Macayucayu",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131021",
      "brgy_name": "Mallabo",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131022",
      "brgy_name": "Marannao",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131023",
      "brgy_name": "Minanga",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131024",
      "brgy_name": "Old San Mariano",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131025",
      "brgy_name": "Palutan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131026",
      "brgy_name": "Panninan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131027",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131028",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131029",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131030",
      "brgy_name": "San Jose",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131031",
      "brgy_name": "San Pablo",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131032",
      "brgy_name": "San Pedro",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131033",
      "brgy_name": "Santa Filomina",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131034",
      "brgy_name": "Tappa",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131035",
      "brgy_name": "Ueg",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131038",
      "brgy_name": "Zamora",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023131039",
      "brgy_name": "Balagan",
      "city_code": "023131",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132001",
      "brgy_name": "Bacareña",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132002",
      "brgy_name": "Bagong Sikat",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132003",
      "brgy_name": "Bella Luz",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132004",
      "brgy_name": "Daramuangan Sur",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132005",
      "brgy_name": "Estrella",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132007",
      "brgy_name": "Gaddanan",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132009",
      "brgy_name": "Malasin",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132010",
      "brgy_name": "Mapuroc",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132011",
      "brgy_name": "Marasat Grande",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132012",
      "brgy_name": "Marasat Pequeño",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132013",
      "brgy_name": "Old Centro I",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132014",
      "brgy_name": "Old Centro II",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132017",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132018",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132019",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132020",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132021",
      "brgy_name": "Salinungan East",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132024",
      "brgy_name": "Salinungan West",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132025",
      "brgy_name": "San Andres",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132026",
      "brgy_name": "San Antonio",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132027",
      "brgy_name": "San Ignacio",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132028",
      "brgy_name": "San Manuel",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132029",
      "brgy_name": "San Marcos",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132030",
      "brgy_name": "San Roque",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132031",
      "brgy_name": "Sinamar Norte",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132032",
      "brgy_name": "Sinamar Sur",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132033",
      "brgy_name": "Victoria",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132035",
      "brgy_name": "Villafuerte",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132036",
      "brgy_name": "Villa Cruz",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132037",
      "brgy_name": "Villa Magat",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132038",
      "brgy_name": "Villa Gamiao (Buyon)",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132039",
      "brgy_name": "Dagupan",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023132040",
      "brgy_name": "Daramuangan Norte",
      "city_code": "023132",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133001",
      "brgy_name": "Annanuman",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133002",
      "brgy_name": "Auitan",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133003",
      "brgy_name": "Ballacayu",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133004",
      "brgy_name": "Binguang",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133005",
      "brgy_name": "Bungad",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133006",
      "brgy_name": "Dalena",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133007",
      "brgy_name": "Caddangan/Limbauan",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133008",
      "brgy_name": "Calamagui",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133009",
      "brgy_name": "Caralucud",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133010",
      "brgy_name": "Guminga",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133011",
      "brgy_name": "Minanga Norte",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133012",
      "brgy_name": "Minanga Sur",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133013",
      "brgy_name": "San Jose",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133014",
      "brgy_name": "Poblacion",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133015",
      "brgy_name": "Simanu Norte",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133016",
      "brgy_name": "Simanu Sur",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023133017",
      "brgy_name": "Tupa (San Vicente)",
      "city_code": "023133",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134001",
      "brgy_name": "Bangad",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134002",
      "brgy_name": "Buenavista",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134003",
      "brgy_name": "Calamagui North",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134004",
      "brgy_name": "Calamagui East",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134005",
      "brgy_name": "Calamagui West",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134007",
      "brgy_name": "Divisoria",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134008",
      "brgy_name": "Lingaling",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134009",
      "brgy_name": "Mozzozzin Sur",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134010",
      "brgy_name": "Mozzozzin North",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134011",
      "brgy_name": "Naganacan",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134012",
      "brgy_name": "Poblacion 1",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134013",
      "brgy_name": "Poblacion 2",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134014",
      "brgy_name": "Poblacion 3",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134015",
      "brgy_name": "Quinagabian",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134016",
      "brgy_name": "San Antonio",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134017",
      "brgy_name": "San Isidro East",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134018",
      "brgy_name": "San Isidro West",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134019",
      "brgy_name": "San Rafael West",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134020",
      "brgy_name": "San Rafael East",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023134021",
      "brgy_name": "Villabuena",
      "city_code": "023134",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135001",
      "brgy_name": "Abra",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135002",
      "brgy_name": "Ambalatungan",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135003",
      "brgy_name": "Balintocatoc",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135004",
      "brgy_name": "Baluarte",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135005",
      "brgy_name": "Bannawag Norte",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135006",
      "brgy_name": "Batal",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135007",
      "brgy_name": "Buenavista",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135008",
      "brgy_name": "Cabulay",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135009",
      "brgy_name": "Calao East (Pob.)",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135010",
      "brgy_name": "Calao West (Pob.)",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135011",
      "brgy_name": "Calaocan",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135012",
      "brgy_name": "Villa Gonzaga",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135013",
      "brgy_name": "Centro East (Pob.)",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135014",
      "brgy_name": "Centro West (Pob.)",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135015",
      "brgy_name": "Divisoria",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135016",
      "brgy_name": "Dubinan East",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135017",
      "brgy_name": "Dubinan West",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135018",
      "brgy_name": "Luna",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135019",
      "brgy_name": "Mabini",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135020",
      "brgy_name": "Malvar",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135021",
      "brgy_name": "Nabbuan",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135022",
      "brgy_name": "Naggasican",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135023",
      "brgy_name": "Patul",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135024",
      "brgy_name": "Plaridel",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135025",
      "brgy_name": "Rizal",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135026",
      "brgy_name": "Rosario",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135027",
      "brgy_name": "Sagana",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135028",
      "brgy_name": "Salvador",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135029",
      "brgy_name": "San Andres",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135030",
      "brgy_name": "San Isidro",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135031",
      "brgy_name": "San Jose",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135032",
      "brgy_name": "Sinili",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135033",
      "brgy_name": "Sinsayon",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135034",
      "brgy_name": "Santa Rosa",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135035",
      "brgy_name": "Victory Norte",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135036",
      "brgy_name": "Victory Sur",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023135037",
      "brgy_name": "Villasis",
      "city_code": "023135",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136001",
      "brgy_name": "Ammugauan",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136002",
      "brgy_name": "Antagan",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136003",
      "brgy_name": "Bagabag",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136004",
      "brgy_name": "Bagutari",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136005",
      "brgy_name": "Balelleng",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136006",
      "brgy_name": "Barumbong",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136008",
      "brgy_name": "Bubug",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136009",
      "brgy_name": "Bolinao-Culalabo",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136010",
      "brgy_name": "Cañogan Abajo Norte",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136013",
      "brgy_name": "Calinaoan Centro",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136015",
      "brgy_name": "Calinaoan Malasin",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136016",
      "brgy_name": "Calinaoan Norte",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136017",
      "brgy_name": "Cañogan Abajo Sur",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136018",
      "brgy_name": "Cañogan Alto",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136019",
      "brgy_name": "Centro",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136020",
      "brgy_name": "Colunguan",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136021",
      "brgy_name": "Malapagay",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136022",
      "brgy_name": "San Rafael Abajo",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136023",
      "brgy_name": "San Rafael Alto",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136024",
      "brgy_name": "San Roque",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136025",
      "brgy_name": "San Vicente",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136026",
      "brgy_name": "Uauang-Tuliao",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136027",
      "brgy_name": "Uauang-Galicia",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136029",
      "brgy_name": "Biga Occidental",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136030",
      "brgy_name": "Biga Oriental",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136031",
      "brgy_name": "Calanigan Norte",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023136032",
      "brgy_name": "Calanigan Sur",
      "city_code": "023136",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137001",
      "brgy_name": "Annafunan",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137002",
      "brgy_name": "Antagan I",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137003",
      "brgy_name": "Antagan II",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137005",
      "brgy_name": "Arcon",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137007",
      "brgy_name": "Balug",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137008",
      "brgy_name": "Banig",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137009",
      "brgy_name": "Bantug",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137010",
      "brgy_name": "Bayabo East",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137012",
      "brgy_name": "Caligayan",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137013",
      "brgy_name": "Camasi",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137014",
      "brgy_name": "Carpentero",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137015",
      "brgy_name": "Compania",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137016",
      "brgy_name": "Cumabao",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137018",
      "brgy_name": "Fugu Abajo",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137019",
      "brgy_name": "Fugu Norte",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137020",
      "brgy_name": "Fugu Sur",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137021",
      "brgy_name": "Fermeldy (Hcda. San Francisco)",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137022",
      "brgy_name": "Lalauanan",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137023",
      "brgy_name": "Lanna",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137024",
      "brgy_name": "Lapogan",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137025",
      "brgy_name": "Lingaling",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137026",
      "brgy_name": "Liwanag",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137027",
      "brgy_name": "Santa Visitacion (Maggayu)",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137029",
      "brgy_name": "Malamag East",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137030",
      "brgy_name": "Malamag West",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137031",
      "brgy_name": "Maligaya",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137032",
      "brgy_name": "Minanga",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137033",
      "brgy_name": "Namnama",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137035",
      "brgy_name": "Paragu",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137036",
      "brgy_name": "Pilitan",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137037",
      "brgy_name": "Barangay District 1 (Pob.)",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137038",
      "brgy_name": "Barangay District 2 (Pob.)",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137039",
      "brgy_name": "Barangay District 3 (Pob.)",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137040",
      "brgy_name": "Barangay District 4 (Pob.)",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137041",
      "brgy_name": "San Mateo",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137042",
      "brgy_name": "San Pedro",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137044",
      "brgy_name": "San Vicente",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137045",
      "brgy_name": "Santa",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137046",
      "brgy_name": "Santa Catalina",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137047",
      "brgy_name": "Santo Niño",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137048",
      "brgy_name": "Sinippil",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137050",
      "brgy_name": "Sisim Abajo",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137051",
      "brgy_name": "Sisim Alto",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137053",
      "brgy_name": "Tunggui",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137054",
      "brgy_name": "Ugad",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "023137055",
      "brgy_name": "Moldero",
      "city_code": "023137",
      "province_code": "0231",
      "region_code": "02"
    },
    {
      "brgy_code": "025001001",
      "brgy_name": "Ammueg",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025001004",
      "brgy_name": "Camandag",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025001005",
      "brgy_name": "Labang",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025001006",
      "brgy_name": "Napo",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025001007",
      "brgy_name": "Poblacion",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025001008",
      "brgy_name": "Salingsingan",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025001009",
      "brgy_name": "Tiblac",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025001010",
      "brgy_name": "Dulli",
      "city_code": "025001",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002001",
      "brgy_name": "Banganan",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002002",
      "brgy_name": "Beti",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002003",
      "brgy_name": "Bone North",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002004",
      "brgy_name": "Bone South",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002005",
      "brgy_name": "Calitlitan",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002006",
      "brgy_name": "Comon",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002007",
      "brgy_name": "Cutar",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002008",
      "brgy_name": "Darapidap",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002009",
      "brgy_name": "Kirang",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002010",
      "brgy_name": "Nagcuartelan",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002011",
      "brgy_name": "Poblacion",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002012",
      "brgy_name": "Santa Clara",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002013",
      "brgy_name": "Tabueng",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002014",
      "brgy_name": "Tucanon",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002016",
      "brgy_name": "Anayo",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002017",
      "brgy_name": "Baan",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002018",
      "brgy_name": "Balite",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002019",
      "brgy_name": "Canabuan",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002020",
      "brgy_name": "Canarem",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002021",
      "brgy_name": "Latar-Nocnoc-San Francisco",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002022",
      "brgy_name": "Ocao-Capiniaan",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025002023",
      "brgy_name": "Yaway",
      "city_code": "025002",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003001",
      "brgy_name": "Bakir",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003002",
      "brgy_name": "Baretbet",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003003",
      "brgy_name": "Careb",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003004",
      "brgy_name": "Lantap",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003005",
      "brgy_name": "Murong",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003006",
      "brgy_name": "Nangalisan",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003007",
      "brgy_name": "Paniki",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003008",
      "brgy_name": "Pogonsino",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003009",
      "brgy_name": "San Geronimo (Pob.)",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003010",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003011",
      "brgy_name": "Santa Cruz",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003012",
      "brgy_name": "Santa Lucia",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003014",
      "brgy_name": "Tuao North",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003015",
      "brgy_name": "Villa Coloma (Pob.)",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003016",
      "brgy_name": "Quirino (Pob.)",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003017",
      "brgy_name": "Villaros",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025003018",
      "brgy_name": "Tuao South",
      "city_code": "025003",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004001",
      "brgy_name": "Abian",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004002",
      "brgy_name": "Abinganan",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004003",
      "brgy_name": "Aliaga",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004005",
      "brgy_name": "Almaguer North",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004006",
      "brgy_name": "Almaguer South",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004007",
      "brgy_name": "Banggot (Pob.)",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004008",
      "brgy_name": "Barat",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004009",
      "brgy_name": "Buag (Pob.)",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004010",
      "brgy_name": "Calaocan (Pob.)",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004011",
      "brgy_name": "Dullao",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004012",
      "brgy_name": "Homestead",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004013",
      "brgy_name": "Indiana",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004014",
      "brgy_name": "Mabuslo",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004015",
      "brgy_name": "Macate",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004016",
      "brgy_name": "Manamtam",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004017",
      "brgy_name": "Mauan",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004018",
      "brgy_name": "Salinas",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004020",
      "brgy_name": "San Antonio North",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004021",
      "brgy_name": "San Antonio South",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004022",
      "brgy_name": "San Fernando",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004023",
      "brgy_name": "San Leonardo",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004024",
      "brgy_name": "Santo Domingo (Tabangan)",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004025",
      "brgy_name": "Pallas",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004026",
      "brgy_name": "Magsaysay Hills",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025004027",
      "brgy_name": "Santo Domingo West",
      "city_code": "025004",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005001",
      "brgy_name": "Bonfal East",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005002",
      "brgy_name": "Bonfal Proper",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005003",
      "brgy_name": "Bonfal West",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005004",
      "brgy_name": "Buenavista (Vista Hills)",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005005",
      "brgy_name": "Busilac",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005006",
      "brgy_name": "Casat",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005008",
      "brgy_name": "La Torre North",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005009",
      "brgy_name": "Magapuy",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005010",
      "brgy_name": "Magsaysay",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005011",
      "brgy_name": "Masoc",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005012",
      "brgy_name": "Paitan",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005014",
      "brgy_name": "Don Domingo Maddela Pob. (District I)",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005015",
      "brgy_name": "Don Tomas Maddela Pob. (District II)",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005016",
      "brgy_name": "District III Pob. (Don M. Perez)",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005017",
      "brgy_name": "District IV (Pob.)",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005019",
      "brgy_name": "Bansing",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005020",
      "brgy_name": "Cabuaan",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005021",
      "brgy_name": "Don Mariano Marcos",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005022",
      "brgy_name": "Ipil-Cuneg",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005023",
      "brgy_name": "La Torre South",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005024",
      "brgy_name": "Luyang",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005025",
      "brgy_name": "Salvacion",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005026",
      "brgy_name": "San Nicolas North (Luyang)",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005027",
      "brgy_name": "Santa Rosa",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025005028",
      "brgy_name": "Vista Alegre (B. Baringin)",
      "city_code": "025005",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006001",
      "brgy_name": "Arwas",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006002",
      "brgy_name": "Balete",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006003",
      "brgy_name": "Bugnay",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006004",
      "brgy_name": "Decabacan",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006005",
      "brgy_name": "Duruarog",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006006",
      "brgy_name": "Escoting",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006007",
      "brgy_name": "Nagsabaran",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006008",
      "brgy_name": "Namamparan",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006009",
      "brgy_name": "Pinya",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006010",
      "brgy_name": "Poblacion",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006011",
      "brgy_name": "Ampakling",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006012",
      "brgy_name": "Butao",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006013",
      "brgy_name": "Langca",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006014",
      "brgy_name": "Lurad",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006015",
      "brgy_name": "Rosario",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006016",
      "brgy_name": "San Luis",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006017",
      "brgy_name": "San Pablo",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006018",
      "brgy_name": "Villa Aurora",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025006019",
      "brgy_name": "Villa Florentino",
      "city_code": "025006",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007002",
      "brgy_name": "Belance",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007003",
      "brgy_name": "Bulala",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007009",
      "brgy_name": "Inaban",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007010",
      "brgy_name": "Ineangan",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007011",
      "brgy_name": "Lamo",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007012",
      "brgy_name": "Mabasa",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007013",
      "brgy_name": "Malasin (Pob.)",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007015",
      "brgy_name": "Munguia",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007016",
      "brgy_name": "Oyao",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007018",
      "brgy_name": "New Gumiad",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007019",
      "brgy_name": "Yabbi",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007020",
      "brgy_name": "Binnuangan",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007021",
      "brgy_name": "Bitnong",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007022",
      "brgy_name": "Macabenga",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025007023",
      "brgy_name": "Parai",
      "city_code": "025007",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008001",
      "brgy_name": "Abaca",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008003",
      "brgy_name": "Banila",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008004",
      "brgy_name": "Carolotan",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008007",
      "brgy_name": "Gabut",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008008",
      "brgy_name": "Ganao (Lingad)",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008010",
      "brgy_name": "Lukidnon",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008011",
      "brgy_name": "Mangayang",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008012",
      "brgy_name": "Palabotan",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008013",
      "brgy_name": "Biruk",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008014",
      "brgy_name": "Bagumbayan",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008015",
      "brgy_name": "Balsain",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008016",
      "brgy_name": "Canabay",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008017",
      "brgy_name": "Domang",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008018",
      "brgy_name": "Dopaj",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008019",
      "brgy_name": "Kimbutan",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008020",
      "brgy_name": "Kinabuan",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008021",
      "brgy_name": "Sanguit",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008022",
      "brgy_name": "Santa Maria",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025008023",
      "brgy_name": "Talbek",
      "city_code": "025008",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009001",
      "brgy_name": "Antutot",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009002",
      "brgy_name": "Alimit",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009003",
      "brgy_name": "Poblacion (Alloy)",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009004",
      "brgy_name": "Bilet",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009005",
      "brgy_name": "Binogawan",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009006",
      "brgy_name": "Bua",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009007",
      "brgy_name": "Biyoy",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009008",
      "brgy_name": "Capisaan",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009009",
      "brgy_name": "Cordon",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009010",
      "brgy_name": "Didipio",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009011",
      "brgy_name": "Dine",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009012",
      "brgy_name": "Kakiduguen",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009014",
      "brgy_name": "Lupa",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009015",
      "brgy_name": "Macalong",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009016",
      "brgy_name": "Malabing",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009017",
      "brgy_name": "Muta",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009018",
      "brgy_name": "Pao",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009019",
      "brgy_name": "Papaya",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009020",
      "brgy_name": "Pudi",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009021",
      "brgy_name": "Tokod",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009022",
      "brgy_name": "Seguem",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009023",
      "brgy_name": "Tadji",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009024",
      "brgy_name": "Wangal",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009025",
      "brgy_name": "Watwat",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009026",
      "brgy_name": "Camamasi",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009027",
      "brgy_name": "Catarawan",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009028",
      "brgy_name": "Nantawacan",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009029",
      "brgy_name": "Alloy",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009030",
      "brgy_name": "Kongkong",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025009031",
      "brgy_name": "Pacquet (Ilongot Res.)",
      "city_code": "025009",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010001",
      "brgy_name": "Acacia",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010002",
      "brgy_name": "Amilong Labeng",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010003",
      "brgy_name": "Ansipsip",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010004",
      "brgy_name": "Baan",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010005",
      "brgy_name": "Babadi",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010006",
      "brgy_name": "Balangabang",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010007",
      "brgy_name": "Banao",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010008",
      "brgy_name": "Binalian",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010009",
      "brgy_name": "Besong",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010010",
      "brgy_name": "Cabalatan-Alang",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010011",
      "brgy_name": "Cabanglasan",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010012",
      "brgy_name": "Kayapa Proper East",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010013",
      "brgy_name": "Kayapa Proper West",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010014",
      "brgy_name": "Mapayao",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010015",
      "brgy_name": "Nansiakan",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010016",
      "brgy_name": "Pampang (Pob.)",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010017",
      "brgy_name": "Pangawan",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010018",
      "brgy_name": "Pinayag",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010019",
      "brgy_name": "Pingkian",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010020",
      "brgy_name": "San Fabian",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010021",
      "brgy_name": "Talecabcab",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010022",
      "brgy_name": "Tubongan",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010023",
      "brgy_name": "Alang-Salacsac",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010024",
      "brgy_name": "Balete",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010025",
      "brgy_name": "Buyasyas",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010026",
      "brgy_name": "Cabayo",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010027",
      "brgy_name": "Castillo Village",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010028",
      "brgy_name": "Latbang",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010029",
      "brgy_name": "Lawigan",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025010030",
      "brgy_name": "Tidang Village",
      "city_code": "025010",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011001",
      "brgy_name": "Aurora",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011002",
      "brgy_name": "Baresbes",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011003",
      "brgy_name": "Buliwao",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011004",
      "brgy_name": "Bonifacio",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011005",
      "brgy_name": "Calaocan",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011006",
      "brgy_name": "Caliat (Pob.)",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011007",
      "brgy_name": "Darubba",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011008",
      "brgy_name": "Maddiangat",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011009",
      "brgy_name": "Nalubbunan",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011010",
      "brgy_name": "Runruno",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011011",
      "brgy_name": "Maasin",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025011012",
      "brgy_name": "Dagupan",
      "city_code": "025011",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012002",
      "brgy_name": "Bacneng",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012003",
      "brgy_name": "Baliling",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012004",
      "brgy_name": "Bantinan",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012005",
      "brgy_name": "Baracbac",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012006",
      "brgy_name": "Buyasyas",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012008",
      "brgy_name": "Imugan",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012009",
      "brgy_name": "Poblacion",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012010",
      "brgy_name": "Sinapaoan",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012011",
      "brgy_name": "Tactac",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012012",
      "brgy_name": "Villa Flores",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012013",
      "brgy_name": "Atbu",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012014",
      "brgy_name": "Balete",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012015",
      "brgy_name": "Canabuan",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012016",
      "brgy_name": "Malico",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012017",
      "brgy_name": "Santa Rosa",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025012018",
      "brgy_name": "Unib",
      "city_code": "025012",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013001",
      "brgy_name": "Aggub",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013002",
      "brgy_name": "Bangaan",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013003",
      "brgy_name": "Bangar",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013004",
      "brgy_name": "Bascaran",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013005",
      "brgy_name": "Curifang",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013006",
      "brgy_name": "Dadap",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013007",
      "brgy_name": "Lactawan",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013008",
      "brgy_name": "Osmeña",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013009",
      "brgy_name": "Poblacion North",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013010",
      "brgy_name": "Poblacion South",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013011",
      "brgy_name": "Quezon",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013012",
      "brgy_name": "Quirino",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013013",
      "brgy_name": "Roxas",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013014",
      "brgy_name": "San Juan",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013015",
      "brgy_name": "San Luis",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013017",
      "brgy_name": "Tucal",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013018",
      "brgy_name": "Uddiawan",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013019",
      "brgy_name": "Wacal",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013020",
      "brgy_name": "Bagahabag",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013021",
      "brgy_name": "Communal",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013022",
      "brgy_name": "Concepcion (Calalabangan)",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025013023",
      "brgy_name": "Pilar D. Galima",
      "city_code": "025013",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014001",
      "brgy_name": "Bintawan Sur",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014002",
      "brgy_name": "Ibung",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014003",
      "brgy_name": "Cabuluan",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014004",
      "brgy_name": "Nagbitin",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014005",
      "brgy_name": "Ocapon",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014006",
      "brgy_name": "Pieza",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014007",
      "brgy_name": "Sawmill",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014008",
      "brgy_name": "Poblacion (Turod)",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025014009",
      "brgy_name": "Bintawan Norte",
      "city_code": "025014",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025015001",
      "brgy_name": "Abuyo",
      "city_code": "025015",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025015002",
      "brgy_name": "Galintuja",
      "city_code": "025015",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025015003",
      "brgy_name": "Cauayan",
      "city_code": "025015",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025015004",
      "brgy_name": "Lipuga",
      "city_code": "025015",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025015005",
      "brgy_name": "Lublub (Pob.)",
      "city_code": "025015",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025015006",
      "brgy_name": "Pelaway",
      "city_code": "025015",
      "province_code": "0250",
      "region_code": "02"
    },
    {
      "brgy_code": "025701001",
      "brgy_name": "Dagupan",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701002",
      "brgy_name": "Dumabel",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701003",
      "brgy_name": "Dungo (Osmeña)",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701004",
      "brgy_name": "Guinalbin",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701005",
      "brgy_name": "Ligaya",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701006",
      "brgy_name": "Palacian",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701007",
      "brgy_name": "Pinaripad Sur",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701008",
      "brgy_name": "Progreso (Pob.)",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701009",
      "brgy_name": "Ramos",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701010",
      "brgy_name": "Rang-ayan",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701011",
      "brgy_name": "San Antonio",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701012",
      "brgy_name": "San Francisco",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701013",
      "brgy_name": "San Leonardo (Cabarroguis)",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701014",
      "brgy_name": "San Ramon",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701015",
      "brgy_name": "Victoria",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701016",
      "brgy_name": "Villa Pagaduan",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701017",
      "brgy_name": "Villa Santiago",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701018",
      "brgy_name": "Alicia",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701019",
      "brgy_name": "Cabugao",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701020",
      "brgy_name": "Diodol",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701021",
      "brgy_name": "Nagabgaban",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701022",
      "brgy_name": "Pinaripad Norte",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701023",
      "brgy_name": "San Benigno",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701024",
      "brgy_name": "San Manuel",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025701025",
      "brgy_name": "Villa Ventura",
      "city_code": "025701",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702001",
      "brgy_name": "Banuar",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702002",
      "brgy_name": "Burgos",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702003",
      "brgy_name": "Calaocan",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702004",
      "brgy_name": "Del Pilar",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702005",
      "brgy_name": "Dibibi",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702006",
      "brgy_name": "Eden",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702007",
      "brgy_name": "Gundaway (Pob.)",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702008",
      "brgy_name": "Mangandingay (Pob.)",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702009",
      "brgy_name": "San Marcos",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702010",
      "brgy_name": "Villamor",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702011",
      "brgy_name": "Zamora",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702012",
      "brgy_name": "Villarose",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702013",
      "brgy_name": "Villa Peña (Capellangan)",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702014",
      "brgy_name": "Dingasan",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702015",
      "brgy_name": "Tucod",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702016",
      "brgy_name": "Gomez",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025702017",
      "brgy_name": "Santo Domingo",
      "city_code": "025702",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703001",
      "brgy_name": "Andres Bonifacio (Pob.)",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703002",
      "brgy_name": "Aurora East (Pob.)",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703003",
      "brgy_name": "Aurora West (Pob.)",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703004",
      "brgy_name": "Baguio Village",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703005",
      "brgy_name": "Balagbag",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703006",
      "brgy_name": "Bannawag",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703007",
      "brgy_name": "Cajel",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703008",
      "brgy_name": "Campamento",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703009",
      "brgy_name": "Diego Silang",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703010",
      "brgy_name": "Don Mariano Perez, Sr.",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703011",
      "brgy_name": "Doña Imelda",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703012",
      "brgy_name": "Dumanisi",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703013",
      "brgy_name": "Gabriela Silang",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703014",
      "brgy_name": "Gulac",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703015",
      "brgy_name": "Guribang",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703016",
      "brgy_name": "Ifugao Village",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703017",
      "brgy_name": "Isidro Paredes",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703018",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703019",
      "brgy_name": "Liwayway",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703020",
      "brgy_name": "Luttuad",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703021",
      "brgy_name": "Magsaysay",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703022",
      "brgy_name": "Makate",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703023",
      "brgy_name": "Maria Clara",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703024",
      "brgy_name": "Rafael Palma (Don Sergio Osmeña)",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703025",
      "brgy_name": "Ricarte Norte",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703026",
      "brgy_name": "Ricarte Sur",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703027",
      "brgy_name": "San Antonio",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703028",
      "brgy_name": "San Isidro",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703029",
      "brgy_name": "San Pascual",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703030",
      "brgy_name": "Villa Pascua",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703031",
      "brgy_name": "Aklan Village",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703032",
      "brgy_name": "Gregorio Pimentel",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025703033",
      "brgy_name": "Don Faustino Pagaduan",
      "city_code": "025703",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704001",
      "brgy_name": "Abbag",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704003",
      "brgy_name": "Balligui",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704004",
      "brgy_name": "Divisoria Sur (Bisangal)",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704005",
      "brgy_name": "Buenavista",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704006",
      "brgy_name": "Cabaruan",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704007",
      "brgy_name": "Cabua-an",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704008",
      "brgy_name": "Cofcaville",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704009",
      "brgy_name": "Diduyon",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704010",
      "brgy_name": "Dipintin",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704011",
      "brgy_name": "Divisoria Norte",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704012",
      "brgy_name": "Dumabato Norte",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704013",
      "brgy_name": "Dumabato Sur",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704014",
      "brgy_name": "Lusod",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704015",
      "brgy_name": "Manglad",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704016",
      "brgy_name": "Pedlisan",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704017",
      "brgy_name": "Poblacion Norte",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704019",
      "brgy_name": "San Bernabe",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704021",
      "brgy_name": "San Dionisio I",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704023",
      "brgy_name": "San Martin",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704024",
      "brgy_name": "San Pedro",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704025",
      "brgy_name": "San Salvador",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704026",
      "brgy_name": "Santo Niño",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704027",
      "brgy_name": "Santo Tomas",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704028",
      "brgy_name": "Villa Gracia",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704029",
      "brgy_name": "Villa Hermosa Sur",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704030",
      "brgy_name": "Villa Hermosa Norte",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704032",
      "brgy_name": "Ysmael",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704034",
      "brgy_name": "Villa Agullana",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704036",
      "brgy_name": "Poblacion Sur",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704037",
      "brgy_name": "Villa Jose V Ylanan",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704038",
      "brgy_name": "Jose Ancheta",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025704039",
      "brgy_name": "Santa Maria",
      "city_code": "025704",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705001",
      "brgy_name": "Dibul",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705002",
      "brgy_name": "La Paz",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705003",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705004",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705005",
      "brgy_name": "Salvacion",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705006",
      "brgy_name": "Santo Tomas",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705007",
      "brgy_name": "Tres Reyes",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705008",
      "brgy_name": "Cardenas",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025705009",
      "brgy_name": "Gamis",
      "city_code": "025705",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706001",
      "brgy_name": "Anak",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706002",
      "brgy_name": "Dipantan",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706003",
      "brgy_name": "Dissimungal",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706004",
      "brgy_name": "Guino (Giayan)",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706005",
      "brgy_name": "La Conwap (Guingin)",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706006",
      "brgy_name": "Landingan",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706007",
      "brgy_name": "Mataddi",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706008",
      "brgy_name": "Matmad",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706009",
      "brgy_name": "Old Gumiad",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706010",
      "brgy_name": "Ponggo",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706011",
      "brgy_name": "San Dionisio II",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706012",
      "brgy_name": "San Pugo",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706013",
      "brgy_name": "San Ramos",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706014",
      "brgy_name": "Sangbay",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706015",
      "brgy_name": "Wasid",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "025706016",
      "brgy_name": "Asaklat",
      "city_code": "025706",
      "province_code": "0257",
      "region_code": "02"
    },
    {
      "brgy_code": "030801001",
      "brgy_name": "Bangkal",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801002",
      "brgy_name": "Calaylayan (Pob.)",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801003",
      "brgy_name": "Capitangan",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801004",
      "brgy_name": "Gabon",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801006",
      "brgy_name": "Laon (Pob.)",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801007",
      "brgy_name": "Mabatang",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801008",
      "brgy_name": "Omboy",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801010",
      "brgy_name": "Salian",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030801011",
      "brgy_name": "Wawa (Pob.)",
      "city_code": "030801",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802001",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802002",
      "brgy_name": "Banawang",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802003",
      "brgy_name": "Binuangan",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802004",
      "brgy_name": "Binukawan",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802006",
      "brgy_name": "Ibaba",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802007",
      "brgy_name": "Ibis",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802008",
      "brgy_name": "Pag-asa (Wawa-Sibacan)",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802009",
      "brgy_name": "Parang",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802010",
      "brgy_name": "Paysawan",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802012",
      "brgy_name": "Quinawan",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802013",
      "brgy_name": "San Antonio",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802014",
      "brgy_name": "Saysain",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802015",
      "brgy_name": "Tabing-Ilog (Pob.)",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030802016",
      "brgy_name": "Atilano L. Ricardo",
      "city_code": "030802",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803001",
      "brgy_name": "Bagumbayan",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803003",
      "brgy_name": "Cabog-Cabog",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803004",
      "brgy_name": "Munting Batangas (Cadre)",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803005",
      "brgy_name": "Cataning",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803006",
      "brgy_name": "Central",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803008",
      "brgy_name": "Cupang Proper",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803009",
      "brgy_name": "Cupang West",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803010",
      "brgy_name": "Dangcol (Bernabe)",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803011",
      "brgy_name": "Ibayo",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803012",
      "brgy_name": "Malabia",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803013",
      "brgy_name": "Poblacion",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803014",
      "brgy_name": "Pto. Rivas Ibaba",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803015",
      "brgy_name": "Pto. Rivas Itaas",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803016",
      "brgy_name": "San Jose",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803017",
      "brgy_name": "Sibacan",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803018",
      "brgy_name": "Camacho",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803019",
      "brgy_name": "Talisay",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803020",
      "brgy_name": "Tanato",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803021",
      "brgy_name": "Tenejero",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803022",
      "brgy_name": "Tortugas",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803023",
      "brgy_name": "Tuyo",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803024",
      "brgy_name": "Bagong Silang",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803025",
      "brgy_name": "Cupang North",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803026",
      "brgy_name": "Doña Francisca",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030803027",
      "brgy_name": "Lote",
      "city_code": "030803",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804002",
      "brgy_name": "Bangal",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804003",
      "brgy_name": "Bonifacio (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804006",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804007",
      "brgy_name": "Colo",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804008",
      "brgy_name": "Daang Bago",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804009",
      "brgy_name": "Dalao",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804010",
      "brgy_name": "Del Pilar (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804011",
      "brgy_name": "Gen. Luna (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804012",
      "brgy_name": "Gomez (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804013",
      "brgy_name": "Happy Valley",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804014",
      "brgy_name": "Kataasan",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804015",
      "brgy_name": "Layac",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804016",
      "brgy_name": "Luacan",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804017",
      "brgy_name": "Mabini Proper (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804018",
      "brgy_name": "Mabini Ext. (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804019",
      "brgy_name": "Magsaysay",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804020",
      "brgy_name": "Naparing",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804021",
      "brgy_name": "New San Jose",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804022",
      "brgy_name": "Old San Jose",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804023",
      "brgy_name": "Padre Dandan (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804024",
      "brgy_name": "Pag-asa",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804025",
      "brgy_name": "Pagalanggang",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804026",
      "brgy_name": "Pinulot",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804027",
      "brgy_name": "Pita",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804029",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804030",
      "brgy_name": "Roosevelt",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804031",
      "brgy_name": "Roxas (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804032",
      "brgy_name": "Saguing",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804033",
      "brgy_name": "San Benito",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804034",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804035",
      "brgy_name": "San Pablo (Bulate)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804036",
      "brgy_name": "San Ramon",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804037",
      "brgy_name": "San Simon",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804038",
      "brgy_name": "Santo Niño",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804039",
      "brgy_name": "Sapang Balas",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804040",
      "brgy_name": "Santa Isabel (Tabacan)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804041",
      "brgy_name": "Torres Bugauen (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804042",
      "brgy_name": "Tucop",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804043",
      "brgy_name": "Zamora (Pob.)",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804044",
      "brgy_name": "Aquino",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804045",
      "brgy_name": "Bayan-bayanan",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804046",
      "brgy_name": "Maligaya",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804047",
      "brgy_name": "Payangan",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804048",
      "brgy_name": "Pentor",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804049",
      "brgy_name": "Tubo-tubo",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030804050",
      "brgy_name": "Jose C. Payumo, Jr.",
      "city_code": "030804",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805001",
      "brgy_name": "A. Rivera (Pob.)",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805002",
      "brgy_name": "Almacen",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805003",
      "brgy_name": "Bacong",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805004",
      "brgy_name": "Balsic",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805005",
      "brgy_name": "Bamban",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805006",
      "brgy_name": "Burgos-Soliman (Pob.)",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805007",
      "brgy_name": "Cataning (Pob.)",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805008",
      "brgy_name": "Culis",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805009",
      "brgy_name": "Daungan (Pob.)",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805010",
      "brgy_name": "Mabiga",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805011",
      "brgy_name": "Mabuco",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805012",
      "brgy_name": "Maite",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805013",
      "brgy_name": "Mambog - Mandama",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805014",
      "brgy_name": "Palihan",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805015",
      "brgy_name": "Pandatung",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805016",
      "brgy_name": "Pulo",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805017",
      "brgy_name": "Saba",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805019",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805020",
      "brgy_name": "Santo Cristo (Pob.)",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805021",
      "brgy_name": "Sumalo",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805023",
      "brgy_name": "Tipo",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805024",
      "brgy_name": "Judge Roman Cruz Sr. (Mandama)",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030805025",
      "brgy_name": "Sacrifice Valley",
      "city_code": "030805",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806001",
      "brgy_name": "Alangan",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806002",
      "brgy_name": "Kitang I",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806003",
      "brgy_name": "Kitang 2 & Luz",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806004",
      "brgy_name": "Lamao",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806006",
      "brgy_name": "Landing",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806007",
      "brgy_name": "Poblacion",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806008",
      "brgy_name": "Reformista",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806009",
      "brgy_name": "Townsite",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806012",
      "brgy_name": "Wawa",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806013",
      "brgy_name": "Duale",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806014",
      "brgy_name": "San Francisco de Asis",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030806015",
      "brgy_name": "St. Francis II",
      "city_code": "030806",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807001",
      "brgy_name": "Alas-asin",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807002",
      "brgy_name": "Alion",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807003",
      "brgy_name": "Batangas II",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807004",
      "brgy_name": "Cabcaben",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807005",
      "brgy_name": "Lucanin",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807006",
      "brgy_name": "Baseco Country (Nassco)",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807008",
      "brgy_name": "Poblacion",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807009",
      "brgy_name": "San Carlos",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807010",
      "brgy_name": "San Isidro",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807011",
      "brgy_name": "Sisiman",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807013",
      "brgy_name": "Balon-Anito",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807014",
      "brgy_name": "Biaan",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807015",
      "brgy_name": "Camaya",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807016",
      "brgy_name": "Ipag",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807017",
      "brgy_name": "Malaya",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807018",
      "brgy_name": "Maligaya",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807019",
      "brgy_name": "Mt. View",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030807020",
      "brgy_name": "Townsite",
      "city_code": "030807",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030808001",
      "brgy_name": "Binaritan",
      "city_code": "030808",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030808002",
      "brgy_name": "Mabayo",
      "city_code": "030808",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030808003",
      "brgy_name": "Nagbalayong",
      "city_code": "030808",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030808004",
      "brgy_name": "Poblacion",
      "city_code": "030808",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030808005",
      "brgy_name": "Sabang",
      "city_code": "030808",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809001",
      "brgy_name": "Bagong Paraiso (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809002",
      "brgy_name": "Balut (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809003",
      "brgy_name": "Bayan (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809004",
      "brgy_name": "Calero (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809005",
      "brgy_name": "Paking-Carbonero (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809006",
      "brgy_name": "Centro II (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809007",
      "brgy_name": "Dona",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809008",
      "brgy_name": "Kaparangan",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809009",
      "brgy_name": "Masantol",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809010",
      "brgy_name": "Mulawin",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809011",
      "brgy_name": "Pag-asa",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809012",
      "brgy_name": "Palihan (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809013",
      "brgy_name": "Pantalan Bago (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809014",
      "brgy_name": "Pantalan Luma (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809015",
      "brgy_name": "Parang Parang (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809016",
      "brgy_name": "Centro I (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809017",
      "brgy_name": "Sibul",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809018",
      "brgy_name": "Silahis",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809019",
      "brgy_name": "Tala",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809020",
      "brgy_name": "Talimundoc",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809021",
      "brgy_name": "Tapulao",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809022",
      "brgy_name": "Tenejero (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809023",
      "brgy_name": "Tugatog",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809024",
      "brgy_name": "Wawa (Pob.)",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809025",
      "brgy_name": "Apollo",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809026",
      "brgy_name": "Kabalutan",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809027",
      "brgy_name": "Maria Fe",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809028",
      "brgy_name": "Puksuan",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030809029",
      "brgy_name": "Tagumpay",
      "city_code": "030809",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810001",
      "brgy_name": "Arellano (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810002",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810003",
      "brgy_name": "Balagtas (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810004",
      "brgy_name": "Balut (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810007",
      "brgy_name": "Bantan",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810008",
      "brgy_name": "Bilolo",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810009",
      "brgy_name": "Calungusan",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810010",
      "brgy_name": "Camachile",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810011",
      "brgy_name": "Daang Bago (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810012",
      "brgy_name": "Daang Bilolo (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810013",
      "brgy_name": "Daang Pare",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810014",
      "brgy_name": "General Lim (Kaput)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810016",
      "brgy_name": "Kapunitan",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810017",
      "brgy_name": "Lati (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810018",
      "brgy_name": "Lusungan (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810020",
      "brgy_name": "Puting Buhangin",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810021",
      "brgy_name": "Sabatan",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810022",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810024",
      "brgy_name": "Santo Domingo",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810027",
      "brgy_name": "Villa Angeles (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810028",
      "brgy_name": "Wakas (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810029",
      "brgy_name": "Wawa (Pob.)",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030810030",
      "brgy_name": "Santa Elena",
      "city_code": "030810",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811001",
      "brgy_name": "Ala-uli",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811003",
      "brgy_name": "Bagumbayan",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811005",
      "brgy_name": "Balut I",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811006",
      "brgy_name": "Balut II",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811007",
      "brgy_name": "Bantan Munti",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811009",
      "brgy_name": "Burgos",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811011",
      "brgy_name": "Del Rosario (Pob.)",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811012",
      "brgy_name": "Diwa",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811013",
      "brgy_name": "Landing",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811014",
      "brgy_name": "Liyang",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811016",
      "brgy_name": "Nagwaling",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811019",
      "brgy_name": "Panilao",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811020",
      "brgy_name": "Pantingan",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811021",
      "brgy_name": "Poblacion",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811022",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811023",
      "brgy_name": "Santa Rosa",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811025",
      "brgy_name": "Wakas North",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811026",
      "brgy_name": "Wakas South",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030811027",
      "brgy_name": "Wawa",
      "city_code": "030811",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812001",
      "brgy_name": "East Calaguiman (Pob.)",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812002",
      "brgy_name": "East Daang Bago (Pob.)",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812003",
      "brgy_name": "Ibaba (Pob.)",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812004",
      "brgy_name": "Imelda",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812005",
      "brgy_name": "Lalawigan",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812006",
      "brgy_name": "Palili",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812008",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812009",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812010",
      "brgy_name": "Santa Lucia",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812011",
      "brgy_name": "Sapa",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812012",
      "brgy_name": "Tabing Ilog",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812013",
      "brgy_name": "Gugo",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812014",
      "brgy_name": "West Calaguiman (Pob.)",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "030812015",
      "brgy_name": "West Daang Bago (Pob.)",
      "city_code": "030812",
      "province_code": "0308",
      "region_code": "03"
    },
    {
      "brgy_code": "031401001",
      "brgy_name": "Banaban",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401002",
      "brgy_name": "Baybay",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401003",
      "brgy_name": "Binagbag",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401005",
      "brgy_name": "Donacion",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401006",
      "brgy_name": "Encanto",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401007",
      "brgy_name": "Laog",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401008",
      "brgy_name": "Marungko",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401009",
      "brgy_name": "Niugan",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401010",
      "brgy_name": "Paltok",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401013",
      "brgy_name": "Pulong Yantok",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401014",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401015",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401016",
      "brgy_name": "Santa Lucia",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401017",
      "brgy_name": "Santo Cristo (Pob.)",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401018",
      "brgy_name": "Sulucan",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031401019",
      "brgy_name": "Taboc",
      "city_code": "031401",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402002",
      "brgy_name": "Borol 2nd",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402003",
      "brgy_name": "Borol 1st",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402004",
      "brgy_name": "Dalig",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402005",
      "brgy_name": "Longos",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402007",
      "brgy_name": "Panginay",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402008",
      "brgy_name": "Pulong Gubat",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402009",
      "brgy_name": "San Juan",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402010",
      "brgy_name": "Santol",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031402011",
      "brgy_name": "Wawa (Pob.)",
      "city_code": "031402",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403001",
      "brgy_name": "Bagong Nayon",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403002",
      "brgy_name": "Barangca",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403003",
      "brgy_name": "Calantipay",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403004",
      "brgy_name": "Catulinan",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403005",
      "brgy_name": "Concepcion",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403006",
      "brgy_name": "Hinukay",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403007",
      "brgy_name": "Makinabang",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403008",
      "brgy_name": "Matangtubig",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403010",
      "brgy_name": "Pagala",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403011",
      "brgy_name": "Paitan",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403012",
      "brgy_name": "Piel",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403013",
      "brgy_name": "Pinagbarilan",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403014",
      "brgy_name": "Poblacion",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403016",
      "brgy_name": "Sabang",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403017",
      "brgy_name": "San Jose",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403018",
      "brgy_name": "San Roque",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403019",
      "brgy_name": "Santa Barbara",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403020",
      "brgy_name": "Santo Cristo",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403021",
      "brgy_name": "Santo Niño",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403022",
      "brgy_name": "Subic",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403023",
      "brgy_name": "Sulivan",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403024",
      "brgy_name": "Tangos",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403025",
      "brgy_name": "Tarcan",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403026",
      "brgy_name": "Tiaong",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403027",
      "brgy_name": "Tibag",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403028",
      "brgy_name": "Tilapayong",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031403030",
      "brgy_name": "Virgen delas Flores",
      "city_code": "031403",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404001",
      "brgy_name": "Antipona",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404002",
      "brgy_name": "Bagumbayan",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404003",
      "brgy_name": "Bambang",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404004",
      "brgy_name": "Batia",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404005",
      "brgy_name": "Biñang 1st",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404006",
      "brgy_name": "Biñang 2nd",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404007",
      "brgy_name": "Bolacan",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404008",
      "brgy_name": "Bundukan",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404009",
      "brgy_name": "Bunlo",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404010",
      "brgy_name": "Caingin",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404011",
      "brgy_name": "Duhat",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404012",
      "brgy_name": "Igulot",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404013",
      "brgy_name": "Lolomboy",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404014",
      "brgy_name": "Poblacion",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404015",
      "brgy_name": "Sulucan",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404016",
      "brgy_name": "Taal",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404017",
      "brgy_name": "Tambobong",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404018",
      "brgy_name": "Turo",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031404019",
      "brgy_name": "Wakas",
      "city_code": "031404",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405001",
      "brgy_name": "Bagumbayan",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405002",
      "brgy_name": "Balubad",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405003",
      "brgy_name": "Bambang",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405004",
      "brgy_name": "Matungao",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405005",
      "brgy_name": "Maysantol",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405006",
      "brgy_name": "Perez",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405007",
      "brgy_name": "Pitpitan",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405009",
      "brgy_name": "San Francisco",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405010",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405012",
      "brgy_name": "San Nicolas",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405013",
      "brgy_name": "Santa Ana",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405014",
      "brgy_name": "Santa Ines",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405015",
      "brgy_name": "Taliptip",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031405016",
      "brgy_name": "Tibig",
      "city_code": "031405",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406001",
      "brgy_name": "Bonga Mayor",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406002",
      "brgy_name": "Bonga Menor",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406003",
      "brgy_name": "Buisan",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406004",
      "brgy_name": "Camachilihan",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406005",
      "brgy_name": "Cambaog",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406006",
      "brgy_name": "Catacte",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406007",
      "brgy_name": "Liciada",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406008",
      "brgy_name": "Malamig",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406009",
      "brgy_name": "Malawak",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406010",
      "brgy_name": "Poblacion",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406011",
      "brgy_name": "San Pedro",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406012",
      "brgy_name": "Talampas",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406013",
      "brgy_name": "Tanawan",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031406014",
      "brgy_name": "Tibagan",
      "city_code": "031406",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407001",
      "brgy_name": "Balite",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407002",
      "brgy_name": "Balungao",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407004",
      "brgy_name": "Buguion",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407005",
      "brgy_name": "Bulusan",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407006",
      "brgy_name": "Calizon",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407007",
      "brgy_name": "Calumpang",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407008",
      "brgy_name": "Caniogan",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407009",
      "brgy_name": "Corazon",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407010",
      "brgy_name": "Frances",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407011",
      "brgy_name": "Gatbuca",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407012",
      "brgy_name": "Gugo",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407013",
      "brgy_name": "Iba Este",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407014",
      "brgy_name": "Iba O'Este",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407015",
      "brgy_name": "Longos",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407016",
      "brgy_name": "Meysulao",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407017",
      "brgy_name": "Meyto",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407018",
      "brgy_name": "Palimbang",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407019",
      "brgy_name": "Panducot",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407020",
      "brgy_name": "Pio Cruzcosa",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407021",
      "brgy_name": "Poblacion",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407022",
      "brgy_name": "Pungo",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407023",
      "brgy_name": "San Jose",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407024",
      "brgy_name": "San Marcos",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407025",
      "brgy_name": "San Miguel",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407026",
      "brgy_name": "Santa Lucia",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407027",
      "brgy_name": "Santo Niño",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407028",
      "brgy_name": "Sapang Bayan",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407029",
      "brgy_name": "Sergio Bayan",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031407030",
      "brgy_name": "Sucol",
      "city_code": "031407",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408001",
      "brgy_name": "Cutcut",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408002",
      "brgy_name": "Daungan",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408003",
      "brgy_name": "Ilang-Ilang",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408004",
      "brgy_name": "Malis",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408005",
      "brgy_name": "Panginay",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408006",
      "brgy_name": "Poblacion",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408007",
      "brgy_name": "Pritil",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408008",
      "brgy_name": "Pulong Gubat",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408009",
      "brgy_name": "Santa Cruz",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408010",
      "brgy_name": "Santa Rita",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408011",
      "brgy_name": "Tabang",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408012",
      "brgy_name": "Tabe",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408013",
      "brgy_name": "Tiaong",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031408015",
      "brgy_name": "Tuktukan",
      "city_code": "031408",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409001",
      "brgy_name": "Abulalas",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409002",
      "brgy_name": "Carillo",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409003",
      "brgy_name": "Iba",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409004",
      "brgy_name": "Mercado",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409005",
      "brgy_name": "Palapat",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409006",
      "brgy_name": "Pugad",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409007",
      "brgy_name": "Sagrada Familia",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409008",
      "brgy_name": "San Agustin",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409009",
      "brgy_name": "San Isidro",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409011",
      "brgy_name": "San Jose",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409012",
      "brgy_name": "San Juan",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409013",
      "brgy_name": "San Miguel",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409014",
      "brgy_name": "San Nicolas",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409015",
      "brgy_name": "San Pablo",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409016",
      "brgy_name": "San Pascual",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409017",
      "brgy_name": "San Pedro",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409018",
      "brgy_name": "San Roque",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409019",
      "brgy_name": "San Sebastian",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409020",
      "brgy_name": "Santa Cruz",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409021",
      "brgy_name": "Santa Elena",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409022",
      "brgy_name": "Santa Monica",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409023",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409024",
      "brgy_name": "Santo Rosario",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409025",
      "brgy_name": "Tampok",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409026",
      "brgy_name": "Tibaguin",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031409027",
      "brgy_name": "Iba-Ibayo",
      "city_code": "031409",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410001",
      "brgy_name": "Anilao",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410002",
      "brgy_name": "Atlag",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410003",
      "brgy_name": "Babatnin",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410004",
      "brgy_name": "Bagna",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410005",
      "brgy_name": "Bagong Bayan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410006",
      "brgy_name": "Balayong",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410007",
      "brgy_name": "Balite",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410008",
      "brgy_name": "Bangkal",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410010",
      "brgy_name": "Barihan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410012",
      "brgy_name": "Bulihan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410013",
      "brgy_name": "Bungahan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410014",
      "brgy_name": "Dakila",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410015",
      "brgy_name": "Guinhawa",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410016",
      "brgy_name": "Caingin",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410017",
      "brgy_name": "Calero",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410018",
      "brgy_name": "Caliligawan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410019",
      "brgy_name": "Canalate",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410020",
      "brgy_name": "Caniogan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410021",
      "brgy_name": "Catmon",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410022",
      "brgy_name": "Ligas",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410023",
      "brgy_name": "Liyang",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410025",
      "brgy_name": "Longos",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410026",
      "brgy_name": "Look 1st",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410027",
      "brgy_name": "Look 2nd",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410028",
      "brgy_name": "Lugam",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410029",
      "brgy_name": "Mabolo",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410031",
      "brgy_name": "Mambog",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410032",
      "brgy_name": "Masile",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410033",
      "brgy_name": "Matimbo",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410034",
      "brgy_name": "Mojon",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410035",
      "brgy_name": "Namayan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410036",
      "brgy_name": "Niugan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410037",
      "brgy_name": "Pamarawan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410038",
      "brgy_name": "Panasahan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410039",
      "brgy_name": "Pinagbakahan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410041",
      "brgy_name": "San Agustin",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410042",
      "brgy_name": "San Gabriel",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410043",
      "brgy_name": "San Juan",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410044",
      "brgy_name": "San Pablo",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410045",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410046",
      "brgy_name": "Santiago",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410047",
      "brgy_name": "Santisima Trinidad",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410048",
      "brgy_name": "Santo Cristo",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410049",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410050",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410051",
      "brgy_name": "Santol",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410052",
      "brgy_name": "Sumapang Bata",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410053",
      "brgy_name": "Sumapang Matanda",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410054",
      "brgy_name": "Taal",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410055",
      "brgy_name": "Tikay",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031410056",
      "brgy_name": "Cofradia",
      "city_code": "031410",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411001",
      "brgy_name": "Abangan Norte",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411002",
      "brgy_name": "Abangan Sur",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411003",
      "brgy_name": "Ibayo",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411004",
      "brgy_name": "Lambakin",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411005",
      "brgy_name": "Lias",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411006",
      "brgy_name": "Loma de Gato",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411007",
      "brgy_name": "Nagbalon",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411008",
      "brgy_name": "Patubig",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411009",
      "brgy_name": "Poblacion I",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411010",
      "brgy_name": "Poblacion II",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411011",
      "brgy_name": "Prenza I",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411012",
      "brgy_name": "Prenza II",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411013",
      "brgy_name": "Santa Rosa I",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411014",
      "brgy_name": "Santa Rosa II",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411015",
      "brgy_name": "Saog",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031411016",
      "brgy_name": "Tabing Ilog",
      "city_code": "031411",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412001",
      "brgy_name": "Bagbaguin",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412002",
      "brgy_name": "Bahay Pare",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412003",
      "brgy_name": "Bancal",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412004",
      "brgy_name": "Banga",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412005",
      "brgy_name": "Bayugo",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412006",
      "brgy_name": "Calvario",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412007",
      "brgy_name": "Camalig",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412008",
      "brgy_name": "Hulo",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412009",
      "brgy_name": "Iba",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412010",
      "brgy_name": "Langka",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412011",
      "brgy_name": "Lawa",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412012",
      "brgy_name": "Libtong",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412013",
      "brgy_name": "Liputan",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412014",
      "brgy_name": "Longos",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412015",
      "brgy_name": "Malhacan",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412016",
      "brgy_name": "Pajo",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412017",
      "brgy_name": "Pandayan",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412018",
      "brgy_name": "Pantoc",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412019",
      "brgy_name": "Perez",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412020",
      "brgy_name": "Poblacion",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412021",
      "brgy_name": "Saluysoy",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412022",
      "brgy_name": "Saint Francis (Gasak)",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412023",
      "brgy_name": "Tugatog",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412024",
      "brgy_name": "Ubihan",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412025",
      "brgy_name": "Zamora",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031412026",
      "brgy_name": "Caingin",
      "city_code": "031412",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413003",
      "brgy_name": "Bangkal",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413004",
      "brgy_name": "Baraka",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413006",
      "brgy_name": "Bigte",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413007",
      "brgy_name": "Bitungol",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413015",
      "brgy_name": "Matictic",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413016",
      "brgy_name": "Minuyan",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413020",
      "brgy_name": "Partida",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413021",
      "brgy_name": "Pinagtulayan",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413022",
      "brgy_name": "Poblacion",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413024",
      "brgy_name": "San Mateo",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413031",
      "brgy_name": "Tigbe",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413032",
      "brgy_name": "San Lorenzo",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031413033",
      "brgy_name": "Friendship Village Resources (FVR)",
      "city_code": "031413",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414001",
      "brgy_name": "Binuangan",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414002",
      "brgy_name": "Catanghalan",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414003",
      "brgy_name": "Hulo",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414004",
      "brgy_name": "Lawa",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414005",
      "brgy_name": "Salambao",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414006",
      "brgy_name": "Paco",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414007",
      "brgy_name": "Pag-asa (Pob.)",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414008",
      "brgy_name": "Paliwas",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414009",
      "brgy_name": "Panghulo",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414010",
      "brgy_name": "San Pascual",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031414011",
      "brgy_name": "Tawiran",
      "city_code": "031414",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415001",
      "brgy_name": "Bagbaguin",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415002",
      "brgy_name": "Bagong Barrio",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415003",
      "brgy_name": "Bunsuran III",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415004",
      "brgy_name": "Bunsuran I",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415005",
      "brgy_name": "Bunsuran II",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415006",
      "brgy_name": "Cacarong Bata",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415007",
      "brgy_name": "Cacarong Matanda",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415008",
      "brgy_name": "Cupang",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415009",
      "brgy_name": "Malibong Bata",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415010",
      "brgy_name": "Malibong Matanda",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415011",
      "brgy_name": "Manatal",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415012",
      "brgy_name": "Mapulang Lupa",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415013",
      "brgy_name": "Masagana",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415014",
      "brgy_name": "Masuso",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415015",
      "brgy_name": "Pinagkuartelan",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415016",
      "brgy_name": "Poblacion",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415017",
      "brgy_name": "Real de Cacarong",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415018",
      "brgy_name": "San Roque",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415019",
      "brgy_name": "Siling Bata",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415020",
      "brgy_name": "Siling Matanda",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415021",
      "brgy_name": "Baka-bakahan",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031415022",
      "brgy_name": "Santo Niño",
      "city_code": "031415",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416001",
      "brgy_name": "Binakod",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416002",
      "brgy_name": "Kapitangan",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416003",
      "brgy_name": "Malumot",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416004",
      "brgy_name": "Masukol",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416006",
      "brgy_name": "Pinalagdan",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416007",
      "brgy_name": "Poblacion",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416009",
      "brgy_name": "San Isidro I",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416010",
      "brgy_name": "San Isidro II",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416011",
      "brgy_name": "San Jose",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416012",
      "brgy_name": "San Roque",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416013",
      "brgy_name": "San Vicente",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416014",
      "brgy_name": "Santa Cruz",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416015",
      "brgy_name": "Santo Niño",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031416016",
      "brgy_name": "Santo Rosario",
      "city_code": "031416",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417001",
      "brgy_name": "Agnaya",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417002",
      "brgy_name": "Bagong Silang",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417003",
      "brgy_name": "Banga I",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417004",
      "brgy_name": "Banga II",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417005",
      "brgy_name": "Bintog",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417006",
      "brgy_name": "Bulihan",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417007",
      "brgy_name": "Culianin",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417008",
      "brgy_name": "Dampol",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417010",
      "brgy_name": "Lagundi",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417011",
      "brgy_name": "Lalangan",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417012",
      "brgy_name": "Lumang Bayan",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417014",
      "brgy_name": "Parulan",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417015",
      "brgy_name": "Poblacion",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417016",
      "brgy_name": "Rueda",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417017",
      "brgy_name": "San Jose",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417018",
      "brgy_name": "Santa Ines",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417019",
      "brgy_name": "Santo Niño",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417020",
      "brgy_name": "Sipat",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031417021",
      "brgy_name": "Tabang",
      "city_code": "031417",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418001",
      "brgy_name": "Balatong A",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418002",
      "brgy_name": "Balatong B",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418003",
      "brgy_name": "Cutcot",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418005",
      "brgy_name": "Dampol I",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418006",
      "brgy_name": "Dampol II-A",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418007",
      "brgy_name": "Dampol II-B",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418008",
      "brgy_name": "Dulong Malabon",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418009",
      "brgy_name": "Inaon",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418010",
      "brgy_name": "Longos",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418011",
      "brgy_name": "Lumbac",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418018",
      "brgy_name": "Paltao",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418020",
      "brgy_name": "Penabatan",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418022",
      "brgy_name": "Poblacion",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418025",
      "brgy_name": "Santa Peregrina",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418026",
      "brgy_name": "Santo Cristo",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418033",
      "brgy_name": "Taal",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418034",
      "brgy_name": "Tabon",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418035",
      "brgy_name": "Tibag",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031418037",
      "brgy_name": "Tinejero",
      "city_code": "031418",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419001",
      "brgy_name": "Akle",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419002",
      "brgy_name": "Alagao",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419003",
      "brgy_name": "Anyatam",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419006",
      "brgy_name": "Bagong Barrio",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419007",
      "brgy_name": "Basuit",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419008",
      "brgy_name": "Bubulong Munti",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419009",
      "brgy_name": "Bubulong Malaki",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419010",
      "brgy_name": "Buhol na Mangga",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419011",
      "brgy_name": "Bulusukan",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419012",
      "brgy_name": "Calasag",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419013",
      "brgy_name": "Calawitan",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419014",
      "brgy_name": "Casalat",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419015",
      "brgy_name": "Gabihan",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419016",
      "brgy_name": "Garlang",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419017",
      "brgy_name": "Lapnit",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419018",
      "brgy_name": "Maasim",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419019",
      "brgy_name": "Makapilapil",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419020",
      "brgy_name": "Malipampang",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419021",
      "brgy_name": "Matimbubong",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419022",
      "brgy_name": "Nabaong Garlang",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419023",
      "brgy_name": "Palapala",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419024",
      "brgy_name": "Pasong Bangkal",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419025",
      "brgy_name": "Pinaod",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419026",
      "brgy_name": "Poblacion",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419027",
      "brgy_name": "Pulong Tamo",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419028",
      "brgy_name": "San Juan",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419029",
      "brgy_name": "Santa Catalina Bata",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419030",
      "brgy_name": "Santa Catalina Matanda",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419031",
      "brgy_name": "Sapang Dayap",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419032",
      "brgy_name": "Sapang Putik",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419033",
      "brgy_name": "Sapang Putol",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419034",
      "brgy_name": "Sumandig",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419035",
      "brgy_name": "Telepatio",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419036",
      "brgy_name": "Upig",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419037",
      "brgy_name": "Umpucan",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031419038",
      "brgy_name": "Mataas na Parang",
      "city_code": "031419",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420001",
      "brgy_name": "Bagong Buhay",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420002",
      "brgy_name": "Dulong Bayan",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420003",
      "brgy_name": "Gaya-gaya",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420004",
      "brgy_name": "Kaypian",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420005",
      "brgy_name": "Kaybanban",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420006",
      "brgy_name": "Minuyan",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420007",
      "brgy_name": "Muzon",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420008",
      "brgy_name": "Poblacion",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420009",
      "brgy_name": "Santo Cristo",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420010",
      "brgy_name": "Sapang Palay",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420011",
      "brgy_name": "Tungkong Mangga",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420012",
      "brgy_name": "Citrus",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420013",
      "brgy_name": "San Martin",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420014",
      "brgy_name": "Santa Cruz",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420015",
      "brgy_name": "Fatima",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420016",
      "brgy_name": "San Pedro",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420017",
      "brgy_name": "San Rafael",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420018",
      "brgy_name": "Santo Niño",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420019",
      "brgy_name": "Assumption",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420020",
      "brgy_name": "Bagong Buhay II",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420021",
      "brgy_name": "Bagong Buhay III",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420022",
      "brgy_name": "Ciudad Real",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420023",
      "brgy_name": "Fatima II",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420024",
      "brgy_name": "Fatima III",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420025",
      "brgy_name": "Fatima IV",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420026",
      "brgy_name": "Fatima V",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420027",
      "brgy_name": "Francisco Homes-Guijo",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420028",
      "brgy_name": "Francisco Homes-Mulawin",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420029",
      "brgy_name": "Francisco Homes-Narra",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420030",
      "brgy_name": "Francisco Homes-Yakal",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420031",
      "brgy_name": "Graceville",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420032",
      "brgy_name": "Gumaoc Central",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420033",
      "brgy_name": "Gumaoc East",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420034",
      "brgy_name": "Gumaoc West",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420035",
      "brgy_name": "Lawang Pari",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420036",
      "brgy_name": "Maharlika",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420037",
      "brgy_name": "Minuyan II",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420038",
      "brgy_name": "Minuyan III",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420039",
      "brgy_name": "Minuyan IV",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420040",
      "brgy_name": "Minuyan V",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420041",
      "brgy_name": "Minuyan Proper",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420042",
      "brgy_name": "Paradise III",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420043",
      "brgy_name": "Poblacion I",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420044",
      "brgy_name": "San Isidro",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420045",
      "brgy_name": "San Manuel",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420046",
      "brgy_name": "San Martin II",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420047",
      "brgy_name": "San Martin III",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420048",
      "brgy_name": "San Martin IV",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420049",
      "brgy_name": "San Rafael I",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420050",
      "brgy_name": "San Rafael III",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420051",
      "brgy_name": "San Rafael IV",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420052",
      "brgy_name": "San Rafael V",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420053",
      "brgy_name": "San Roque",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420054",
      "brgy_name": "Santa Cruz II",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420055",
      "brgy_name": "Santa Cruz III",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420056",
      "brgy_name": "Santa Cruz IV",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420057",
      "brgy_name": "Santa Cruz V",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420058",
      "brgy_name": "Santo Niño II",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031420059",
      "brgy_name": "St. Martin de Porres",
      "city_code": "031420",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421001",
      "brgy_name": "Bagong Silang",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421002",
      "brgy_name": "Balaong",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421003",
      "brgy_name": "Balite",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421004",
      "brgy_name": "Bantog",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421006",
      "brgy_name": "Bardias",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421007",
      "brgy_name": "Baritan",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421008",
      "brgy_name": "Batasan Bata",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421009",
      "brgy_name": "Batasan Matanda",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421011",
      "brgy_name": "Biak-na-Bato",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421012",
      "brgy_name": "Biclat",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421013",
      "brgy_name": "Buga",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421014",
      "brgy_name": "Buliran",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421015",
      "brgy_name": "Bulualto",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421016",
      "brgy_name": "Calumpang",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421019",
      "brgy_name": "Cambio",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421020",
      "brgy_name": "Camias",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421021",
      "brgy_name": "Ilog-Bulo",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421023",
      "brgy_name": "King Kabayo",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421024",
      "brgy_name": "Labne",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421025",
      "brgy_name": "Lambakin",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421027",
      "brgy_name": "Magmarale",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421028",
      "brgy_name": "Malibay",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421030",
      "brgy_name": "Mandile",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421031",
      "brgy_name": "Masalipit",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421032",
      "brgy_name": "Pacalag",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421033",
      "brgy_name": "Paliwasan",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421035",
      "brgy_name": "Partida",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421036",
      "brgy_name": "Pinambaran",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421037",
      "brgy_name": "Poblacion",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421038",
      "brgy_name": "Pulong Bayabas",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421039",
      "brgy_name": "Sacdalan",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421040",
      "brgy_name": "Salacot",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421041",
      "brgy_name": "Salangan",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421043",
      "brgy_name": "San Agustin",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421044",
      "brgy_name": "San Jose",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421045",
      "brgy_name": "San Juan",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421046",
      "brgy_name": "San Vicente",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421047",
      "brgy_name": "Santa Ines",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421048",
      "brgy_name": "Santa Lucia",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421049",
      "brgy_name": "Santa Rita Bata",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421050",
      "brgy_name": "Santa Rita Matanda",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421051",
      "brgy_name": "Sapang",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421052",
      "brgy_name": "Sibul",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421054",
      "brgy_name": "Tartaro",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421055",
      "brgy_name": "Tibagan",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421056",
      "brgy_name": "Bagong Pag-asa",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421057",
      "brgy_name": "Pulong Duhat",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421058",
      "brgy_name": "Maligaya",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031421059",
      "brgy_name": "Tigpalas",
      "city_code": "031421",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422001",
      "brgy_name": "BMA-Balagtas",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422002",
      "brgy_name": "Banca-banca",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422003",
      "brgy_name": "Caingin",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422004",
      "brgy_name": "Coral na Bato",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422005",
      "brgy_name": "Cruz na Daan",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422006",
      "brgy_name": "Dagat-dagatan",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422007",
      "brgy_name": "Diliman I",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422008",
      "brgy_name": "Diliman II",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422009",
      "brgy_name": "Capihan",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422010",
      "brgy_name": "Libis",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422011",
      "brgy_name": "Lico",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422012",
      "brgy_name": "Maasim",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422013",
      "brgy_name": "Mabalas-balas",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422014",
      "brgy_name": "Maguinao",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422015",
      "brgy_name": "Maronguillo",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422016",
      "brgy_name": "Paco",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422017",
      "brgy_name": "Pansumaloc",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422018",
      "brgy_name": "Pantubig",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422019",
      "brgy_name": "Pasong Bangkal",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422020",
      "brgy_name": "Pasong Callos",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422021",
      "brgy_name": "Pasong Intsik",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422022",
      "brgy_name": "Pinacpinacan",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422023",
      "brgy_name": "Poblacion",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422024",
      "brgy_name": "Pulo",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422025",
      "brgy_name": "Pulong Bayabas",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422026",
      "brgy_name": "Salapungan",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422027",
      "brgy_name": "Sampaloc",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422028",
      "brgy_name": "San Agustin",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422030",
      "brgy_name": "San Roque",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422031",
      "brgy_name": "Talacsan",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422032",
      "brgy_name": "Tambubong",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422033",
      "brgy_name": "Tukod",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422034",
      "brgy_name": "Ulingao",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031422035",
      "brgy_name": "Sapang Pahalang",
      "city_code": "031422",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423001",
      "brgy_name": "Bagbaguin",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423002",
      "brgy_name": "Balasing",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423003",
      "brgy_name": "Buenavista",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423004",
      "brgy_name": "Bulac",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423005",
      "brgy_name": "Camangyanan",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423006",
      "brgy_name": "Catmon",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423007",
      "brgy_name": "Cay Pombo",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423008",
      "brgy_name": "Caysio",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423009",
      "brgy_name": "Guyong",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423010",
      "brgy_name": "Lalakhan",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423011",
      "brgy_name": "Mag-asawang Sapa",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423013",
      "brgy_name": "Mahabang Parang",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423014",
      "brgy_name": "Manggahan",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423015",
      "brgy_name": "Parada",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423016",
      "brgy_name": "Poblacion",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423017",
      "brgy_name": "Pulong Buhangin",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423018",
      "brgy_name": "San Gabriel",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423019",
      "brgy_name": "San Jose Patag",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423020",
      "brgy_name": "San Vicente",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423021",
      "brgy_name": "Santa Clara",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423022",
      "brgy_name": "Santa Cruz",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423023",
      "brgy_name": "Silangan",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423024",
      "brgy_name": "Tabing Bakod",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031423025",
      "brgy_name": "Tumana",
      "city_code": "031423",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424001",
      "brgy_name": "Bayabas",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424002",
      "brgy_name": "Kabayunan",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424003",
      "brgy_name": "Camachin",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424004",
      "brgy_name": "Camachile",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424005",
      "brgy_name": "Kalawakan",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424006",
      "brgy_name": "Pulong Sampalok",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424007",
      "brgy_name": "Talbak",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "031424008",
      "brgy_name": "Sapang Bulak",
      "city_code": "031424",
      "province_code": "0314",
      "region_code": "03"
    },
    {
      "brgy_code": "034901003",
      "brgy_name": "Betes",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901004",
      "brgy_name": "Bibiclat",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901005",
      "brgy_name": "Bucot",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901010",
      "brgy_name": "La Purisima",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901015",
      "brgy_name": "Magsaysay",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901016",
      "brgy_name": "Macabucod",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901018",
      "brgy_name": "Pantoc",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901020",
      "brgy_name": "Poblacion Centro",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901021",
      "brgy_name": "Poblacion East I",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901022",
      "brgy_name": "Poblacion East II",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901023",
      "brgy_name": "Poblacion West III",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901024",
      "brgy_name": "Poblacion West IV",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901027",
      "brgy_name": "San Carlos",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901028",
      "brgy_name": "San Emiliano",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901029",
      "brgy_name": "San Eustacio",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901030",
      "brgy_name": "San Felipe Bata",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901031",
      "brgy_name": "San Felipe Matanda",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901032",
      "brgy_name": "San Juan",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901033",
      "brgy_name": "San Pablo Bata",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901034",
      "brgy_name": "San Pablo Matanda",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901035",
      "brgy_name": "Santa Monica",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901036",
      "brgy_name": "Santiago",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901038",
      "brgy_name": "Santo Rosario",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901039",
      "brgy_name": "Santo Tomas",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901040",
      "brgy_name": "Sunson",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034901042",
      "brgy_name": "Umangan",
      "city_code": "034901",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902001",
      "brgy_name": "Antipolo",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902002",
      "brgy_name": "Ariendo",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902003",
      "brgy_name": "Bantug",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902004",
      "brgy_name": "Calaanan",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902005",
      "brgy_name": "Commercial (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902006",
      "brgy_name": "Cruz",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902007",
      "brgy_name": "Digmala",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902008",
      "brgy_name": "Curva (J. Tomacruz)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902009",
      "brgy_name": "Kaingin (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902010",
      "brgy_name": "Labi",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902011",
      "brgy_name": "Larcon",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902012",
      "brgy_name": "Lusok",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902013",
      "brgy_name": "Macabaclay",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902014",
      "brgy_name": "Magtanggol (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902015",
      "brgy_name": "Mantile (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902016",
      "brgy_name": "Olivete",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902017",
      "brgy_name": "Palo Maria (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902018",
      "brgy_name": "Pesa",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902020",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902021",
      "brgy_name": "Sampalucan (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902022",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902023",
      "brgy_name": "Santor",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902024",
      "brgy_name": "Sinipit (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902025",
      "brgy_name": "Sisilang na Ligaya (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902026",
      "brgy_name": "Social (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902027",
      "brgy_name": "Tugatug",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902028",
      "brgy_name": "Tulay na Bato (Pob.)",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034902029",
      "brgy_name": "Vega",
      "city_code": "034902",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903002",
      "brgy_name": "Aduas Centro (Aduas)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903003",
      "brgy_name": "Bagong Sikat",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903005",
      "brgy_name": "Bagong Buhay",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903006",
      "brgy_name": "Bakero",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903007",
      "brgy_name": "Bakod Bayan",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903008",
      "brgy_name": "Balite",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903009",
      "brgy_name": "Bangad",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903010",
      "brgy_name": "Bantug Bulalo",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903011",
      "brgy_name": "Bantug Norte",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903012",
      "brgy_name": "Barlis",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903013",
      "brgy_name": "Barrera District (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903015",
      "brgy_name": "Bernardo District (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903016",
      "brgy_name": "Bitas",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903017",
      "brgy_name": "Bonifacio District (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903018",
      "brgy_name": "Buliran",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903019",
      "brgy_name": "Caalibangbangan",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903020",
      "brgy_name": "Cabu",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903022",
      "brgy_name": "Campo Tinio",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903023",
      "brgy_name": "Kapitan Pepe (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903024",
      "brgy_name": "Cinco-Cinco",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903025",
      "brgy_name": "City Supermarket (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903026",
      "brgy_name": "Caudillo",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903027",
      "brgy_name": "Communal",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903028",
      "brgy_name": "Cruz Roja",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903029",
      "brgy_name": "Daang Sarile",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903030",
      "brgy_name": "Dalampang",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903031",
      "brgy_name": "Dicarma (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903032",
      "brgy_name": "Dimasalang (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903033",
      "brgy_name": "Dionisio S. Garcia",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903035",
      "brgy_name": "Fatima (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903036",
      "brgy_name": "General Luna (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903037",
      "brgy_name": "Ibabao Bana",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903038",
      "brgy_name": "Imelda District",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903039",
      "brgy_name": "Isla (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903040",
      "brgy_name": "Calawagan (Kalawagan)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903041",
      "brgy_name": "Kalikid Norte",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903042",
      "brgy_name": "Kalikid Sur",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903043",
      "brgy_name": "Lagare",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903044",
      "brgy_name": "M. S. Garcia",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903046",
      "brgy_name": "Mabini Extension",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903047",
      "brgy_name": "Mabini Homesite",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903048",
      "brgy_name": "Macatbong",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903049",
      "brgy_name": "Magsaysay District",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903050",
      "brgy_name": "Matadero (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903051",
      "brgy_name": "Lourdes (Matungal-tungal)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903052",
      "brgy_name": "Mayapyap Norte",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903053",
      "brgy_name": "Mayapyap Sur",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903054",
      "brgy_name": "Melojavilla (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903055",
      "brgy_name": "Obrero",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903056",
      "brgy_name": "Padre Crisostomo",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903057",
      "brgy_name": "Pagas",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903058",
      "brgy_name": "Palagay",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903059",
      "brgy_name": "Pamaldan",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903060",
      "brgy_name": "Pangatian",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903061",
      "brgy_name": "Patalac",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903063",
      "brgy_name": "Polilio",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903064",
      "brgy_name": "Pula",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903065",
      "brgy_name": "Quezon District (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903066",
      "brgy_name": "Rizdelis (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903067",
      "brgy_name": "Samon",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903068",
      "brgy_name": "San Isidro",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903071",
      "brgy_name": "San Josef Norte",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903072",
      "brgy_name": "San Josef Sur",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903073",
      "brgy_name": "San Juan Pob. (Acofa)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903074",
      "brgy_name": "San Roque Norte",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903075",
      "brgy_name": "San Roque Sur",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903076",
      "brgy_name": "Sanbermicristi (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903077",
      "brgy_name": "Sangitan",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903078",
      "brgy_name": "Santa Arcadia",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903079",
      "brgy_name": "Sumacab Norte",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903082",
      "brgy_name": "Valdefuente",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903083",
      "brgy_name": "Valle Cruz",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903084",
      "brgy_name": "Vijandre District (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903085",
      "brgy_name": "Villa Ofelia-Caridad",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903086",
      "brgy_name": "Zulueta District (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903087",
      "brgy_name": "Nabao (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903088",
      "brgy_name": "Padre Burgos (Pob.)",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903089",
      "brgy_name": "Talipapa",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903090",
      "brgy_name": "Aduas Norte",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903091",
      "brgy_name": "Aduas Sur",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903092",
      "brgy_name": "Hermogenes C. Concepcion, Sr.",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903093",
      "brgy_name": "Sapang",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903094",
      "brgy_name": "Sumacab Este",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903095",
      "brgy_name": "Sumacab South",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903096",
      "brgy_name": "Caridad",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903097",
      "brgy_name": "Magsaysay South",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903098",
      "brgy_name": "Maria Theresa",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903099",
      "brgy_name": "Sangitan East",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034903100",
      "brgy_name": "Santo Niño",
      "city_code": "034903",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904001",
      "brgy_name": "Bagong Buhay",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904002",
      "brgy_name": "Bagong Sikat",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904003",
      "brgy_name": "Bagong Silang",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904004",
      "brgy_name": "Concepcion",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904005",
      "brgy_name": "Entablado",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904006",
      "brgy_name": "Maligaya",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904008",
      "brgy_name": "Natividad North (Pob.)",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904009",
      "brgy_name": "Natividad South (Pob.)",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904010",
      "brgy_name": "Palasinan",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904012",
      "brgy_name": "San Antonio",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904013",
      "brgy_name": "San Fernando Norte",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904014",
      "brgy_name": "San Fernando Sur",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904015",
      "brgy_name": "San Gregorio",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904016",
      "brgy_name": "San Juan North (Pob.)",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904017",
      "brgy_name": "San Juan South (Pob.)",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904019",
      "brgy_name": "San Roque",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904020",
      "brgy_name": "San Vicente",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904021",
      "brgy_name": "Santa Rita",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904022",
      "brgy_name": "Sinipit",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904023",
      "brgy_name": "Polilio",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904024",
      "brgy_name": "San Carlos",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904025",
      "brgy_name": "Santa Isabel",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034904026",
      "brgy_name": "Santa Ines",
      "city_code": "034904",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905001",
      "brgy_name": "R.A.Padilla (Baluarte)",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905002",
      "brgy_name": "Bantug",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905004",
      "brgy_name": "Bunga",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905005",
      "brgy_name": "Burgos",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905007",
      "brgy_name": "Capintalan",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905015",
      "brgy_name": "Joson (Digidig)",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905020",
      "brgy_name": "General Luna",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905030",
      "brgy_name": "Minuli",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905041",
      "brgy_name": "Piut",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905045",
      "brgy_name": "Puncan",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905046",
      "brgy_name": "Putlan",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905047",
      "brgy_name": "Salazar",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905048",
      "brgy_name": "San Agustin",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905052",
      "brgy_name": "T. L. Padilla Pob. (Bgy. I)",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905053",
      "brgy_name": "F. C. Otic Pob. (Bgy. II)",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905054",
      "brgy_name": "D. L. Maglanoc Pob. (Bgy.III)",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034905055",
      "brgy_name": "G. S. Rosario Pob. (Bgy. IV)",
      "city_code": "034905",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906001",
      "brgy_name": "Baloy",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906002",
      "brgy_name": "Bambanaba",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906003",
      "brgy_name": "Bantug",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906004",
      "brgy_name": "Bentigan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906005",
      "brgy_name": "Bibiclat",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906006",
      "brgy_name": "Bonifacio",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906007",
      "brgy_name": "Bued",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906008",
      "brgy_name": "Bulala",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906009",
      "brgy_name": "Burgos",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906010",
      "brgy_name": "Cabileo",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906011",
      "brgy_name": "Cabatuan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906012",
      "brgy_name": "Cacapasan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906013",
      "brgy_name": "Calancuasan Norte",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906014",
      "brgy_name": "Calancuasan Sur",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906015",
      "brgy_name": "Colosboa",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906016",
      "brgy_name": "Columbitin",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906017",
      "brgy_name": "Curva",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906018",
      "brgy_name": "District I (Pob. I)",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906019",
      "brgy_name": "District II (Pob. II)",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906021",
      "brgy_name": "District IV (Pob. IV)",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906022",
      "brgy_name": "District V (Pob. V)",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906023",
      "brgy_name": "District VI (Pob. VI)",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906024",
      "brgy_name": "District VII (Pob. VII)",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906025",
      "brgy_name": "District VIII (Pob. VIII)",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906026",
      "brgy_name": "Landig",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906027",
      "brgy_name": "Latap",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906028",
      "brgy_name": "Loob",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906029",
      "brgy_name": "Luna",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906030",
      "brgy_name": "Malbeg-Patalan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906031",
      "brgy_name": "Malineng",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906032",
      "brgy_name": "Matindeg",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906033",
      "brgy_name": "Maycaban",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906034",
      "brgy_name": "Nacuralan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906035",
      "brgy_name": "Nagmisahan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906036",
      "brgy_name": "Paitan Norte",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906037",
      "brgy_name": "Paitan Sur",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906038",
      "brgy_name": "Piglisan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906039",
      "brgy_name": "Pugo",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906040",
      "brgy_name": "Rizal",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906041",
      "brgy_name": "Sabit",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906042",
      "brgy_name": "Salagusog",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906043",
      "brgy_name": "San Antonio",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906044",
      "brgy_name": "San Jose",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906045",
      "brgy_name": "San Juan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906046",
      "brgy_name": "Santa Clara",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906047",
      "brgy_name": "Santa Cruz",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906048",
      "brgy_name": "Sinimbaan",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906049",
      "brgy_name": "Tagtagumbao",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906050",
      "brgy_name": "Tutuloy",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906051",
      "brgy_name": "Ungab",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034906052",
      "brgy_name": "Villaflores",
      "city_code": "034906",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907001",
      "brgy_name": "Bagong Sikat",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907002",
      "brgy_name": "Bagting",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907003",
      "brgy_name": "Bantug",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907004",
      "brgy_name": "Bitulok (North Pob.)",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907005",
      "brgy_name": "Bugnan",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907006",
      "brgy_name": "Calabasa",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907007",
      "brgy_name": "Camachile",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907008",
      "brgy_name": "Cuyapa",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907009",
      "brgy_name": "Ligaya",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907010",
      "brgy_name": "Macasandal",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907011",
      "brgy_name": "Malinao",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907012",
      "brgy_name": "Pantoc",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907013",
      "brgy_name": "Pinamalisan",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907014",
      "brgy_name": "South Poblacion",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907015",
      "brgy_name": "Sawmill",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034907016",
      "brgy_name": "Tagumpay",
      "city_code": "034907",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908001",
      "brgy_name": "Bayanihan",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908002",
      "brgy_name": "Bulak",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908003",
      "brgy_name": "Kapalangan",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908004",
      "brgy_name": "Mahipon",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908005",
      "brgy_name": "Malimba",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908006",
      "brgy_name": "Mangino",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908007",
      "brgy_name": "Marelo",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908008",
      "brgy_name": "Pambuan",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908009",
      "brgy_name": "Parcutela",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908010",
      "brgy_name": "San Lorenzo (Pob.)",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908011",
      "brgy_name": "San Nicolas",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908012",
      "brgy_name": "San Roque",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908013",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908014",
      "brgy_name": "Santa Cruz",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908015",
      "brgy_name": "Santo Cristo Norte",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908016",
      "brgy_name": "Santo Cristo Sur",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908017",
      "brgy_name": "Santo Niño",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908018",
      "brgy_name": "Makabaclay",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908019",
      "brgy_name": "Balante",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908020",
      "brgy_name": "Bungo",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908021",
      "brgy_name": "Mabunga",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908022",
      "brgy_name": "Maburak",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034908023",
      "brgy_name": "Puting Tubig",
      "city_code": "034908",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909002",
      "brgy_name": "Balangkare Norte",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909003",
      "brgy_name": "Balangkare Sur",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909004",
      "brgy_name": "Balaring",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909005",
      "brgy_name": "Belen",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909006",
      "brgy_name": "Bravo",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909007",
      "brgy_name": "Burol",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909009",
      "brgy_name": "Kabulihan",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909010",
      "brgy_name": "Mag-asawang Sampaloc",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909011",
      "brgy_name": "Manarog",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909012",
      "brgy_name": "Mataas na Kahoy",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909014",
      "brgy_name": "Panacsac",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909016",
      "brgy_name": "Picaleon",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909017",
      "brgy_name": "Pinahan",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909018",
      "brgy_name": "Platero",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909019",
      "brgy_name": "Poblacion",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909020",
      "brgy_name": "Pula",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909021",
      "brgy_name": "Pulong Singkamas",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909023",
      "brgy_name": "Sapang Bato",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909025",
      "brgy_name": "Talabutab Norte",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034909026",
      "brgy_name": "Talabutab Sur",
      "city_code": "034909",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910001",
      "brgy_name": "Bago",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910002",
      "brgy_name": "Concepcion",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910003",
      "brgy_name": "Nazareth",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910004",
      "brgy_name": "Padolina",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910005",
      "brgy_name": "Pias",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910006",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910007",
      "brgy_name": "Poblacion East",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910008",
      "brgy_name": "Poblacion West",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910014",
      "brgy_name": "Rio Chico",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910015",
      "brgy_name": "Poblacion Central",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910016",
      "brgy_name": "Pulong Matong",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910017",
      "brgy_name": "Sampaguita",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034910018",
      "brgy_name": "Palale",
      "city_code": "034910",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911001",
      "brgy_name": "Agcano",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911002",
      "brgy_name": "Ayos Lomboy",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911003",
      "brgy_name": "Bacayao",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911004",
      "brgy_name": "Bagong Barrio",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911005",
      "brgy_name": "Balbalino",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911006",
      "brgy_name": "Balingog East",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911007",
      "brgy_name": "Balingog West",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911008",
      "brgy_name": "Banitan",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911009",
      "brgy_name": "Bantug",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911011",
      "brgy_name": "Bulakid",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911014",
      "brgy_name": "Caballero",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911015",
      "brgy_name": "Cabaruan",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911016",
      "brgy_name": "Caingin Tabing Ilog",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911017",
      "brgy_name": "Calem",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911018",
      "brgy_name": "Camiling",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911019",
      "brgy_name": "Cardinal",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911020",
      "brgy_name": "Casongsong",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911021",
      "brgy_name": "Catimon",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911022",
      "brgy_name": "Cavite",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911023",
      "brgy_name": "Cawayan Bugtong",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911024",
      "brgy_name": "Consuelo",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911025",
      "brgy_name": "Culong",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911026",
      "brgy_name": "Escano",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911027",
      "brgy_name": "Faigal",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911028",
      "brgy_name": "Galvan",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911029",
      "brgy_name": "Guiset",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911030",
      "brgy_name": "Lamorito",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911031",
      "brgy_name": "Lennec",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911032",
      "brgy_name": "Macamias",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911033",
      "brgy_name": "Macapabellag",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911034",
      "brgy_name": "Macatcatuit",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911035",
      "brgy_name": "Manacsac",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911036",
      "brgy_name": "Manggang Marikit",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911038",
      "brgy_name": "Maturanoc",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911039",
      "brgy_name": "Maybubon",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911041",
      "brgy_name": "Naglabrahan",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911042",
      "brgy_name": "Nagpandayan",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911043",
      "brgy_name": "Narvacan I",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911044",
      "brgy_name": "Narvacan II",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911045",
      "brgy_name": "Pacac",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911046",
      "brgy_name": "Partida I",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911047",
      "brgy_name": "Partida II",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911048",
      "brgy_name": "Pasong Inchic",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911049",
      "brgy_name": "Saint John District (Pob.)",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911050",
      "brgy_name": "San Agustin",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911051",
      "brgy_name": "San Andres",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911052",
      "brgy_name": "San Bernardino",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911053",
      "brgy_name": "San Marcelino",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911054",
      "brgy_name": "San Miguel",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911055",
      "brgy_name": "San Rafael",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911056",
      "brgy_name": "San Roque",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911057",
      "brgy_name": "Santa Ana",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911058",
      "brgy_name": "Santa Cruz",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911059",
      "brgy_name": "Santa Lucia",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911060",
      "brgy_name": "Santa Veronica District (Pob.)",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911061",
      "brgy_name": "Santo Cristo District (Pob.)",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911062",
      "brgy_name": "Saranay District (Pob.)",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911063",
      "brgy_name": "Sinulatan",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911064",
      "brgy_name": "Subol",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911065",
      "brgy_name": "Tampac I",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911066",
      "brgy_name": "Tampac II & III",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911068",
      "brgy_name": "Triala",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911069",
      "brgy_name": "Yuson",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034911070",
      "brgy_name": "Bunol",
      "city_code": "034911",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912001",
      "brgy_name": "Calabasa",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912002",
      "brgy_name": "Dampulan (Pob.)",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912003",
      "brgy_name": "Hilera",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912004",
      "brgy_name": "Imbunia",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912005",
      "brgy_name": "Imelda Pob. (Doña Aurora)",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912006",
      "brgy_name": "Lambakin",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912007",
      "brgy_name": "Langla",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912008",
      "brgy_name": "Magsalisi",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912010",
      "brgy_name": "Malabon-Kaingin",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912012",
      "brgy_name": "Marawa",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912013",
      "brgy_name": "Don Mariano Marcos (Pob.)",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912014",
      "brgy_name": "San Josef (Nabao)",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912015",
      "brgy_name": "Niyugan",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912016",
      "brgy_name": "Pamacpacan",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912017",
      "brgy_name": "Pakol",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912018",
      "brgy_name": "Pinanggaan",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912019",
      "brgy_name": "Ulanin-Pitak",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912020",
      "brgy_name": "Putlod",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912021",
      "brgy_name": "Ocampo-Rivera District (Pob.)",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912022",
      "brgy_name": "San Jose",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912023",
      "brgy_name": "San Pablo",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912024",
      "brgy_name": "San Roque",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912025",
      "brgy_name": "San Vicente",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912026",
      "brgy_name": "Santa Rita",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912027",
      "brgy_name": "Santo Tomas North",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912028",
      "brgy_name": "Santo Tomas South",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034912029",
      "brgy_name": "Sapang",
      "city_code": "034912",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913001",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913002",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913003",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913004",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913005",
      "brgy_name": "Betania",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913006",
      "brgy_name": "Canantong",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913009",
      "brgy_name": "Nauzon",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913011",
      "brgy_name": "Pangarulong",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913013",
      "brgy_name": "Pinagbayanan",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913016",
      "brgy_name": "Sagana",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913017",
      "brgy_name": "San Fernando",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913018",
      "brgy_name": "San Isidro",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913019",
      "brgy_name": "San Josef",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913020",
      "brgy_name": "San Juan",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913021",
      "brgy_name": "San Vicente",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913022",
      "brgy_name": "Siclong",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034913023",
      "brgy_name": "San Felipe",
      "city_code": "034913",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914002",
      "brgy_name": "Linao",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914003",
      "brgy_name": "Poblacion Norte",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914004",
      "brgy_name": "Poblacion Sur",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914005",
      "brgy_name": "San Casimiro",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914006",
      "brgy_name": "San Cristobal",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914007",
      "brgy_name": "San Jose",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914008",
      "brgy_name": "San Juan",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914009",
      "brgy_name": "Santa Maria",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914011",
      "brgy_name": "Tabing Ilog",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914012",
      "brgy_name": "Villarosa",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034914013",
      "brgy_name": "Aquino",
      "city_code": "034914",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915001",
      "brgy_name": "A. Bonifacio",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915002",
      "brgy_name": "Caridad Norte",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915003",
      "brgy_name": "Caridad Sur",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915004",
      "brgy_name": "Casile",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915005",
      "brgy_name": "Florida Blanca",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915006",
      "brgy_name": "General Luna",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915008",
      "brgy_name": "General Ricarte",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915009",
      "brgy_name": "Gomez",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915010",
      "brgy_name": "Inanama",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915011",
      "brgy_name": "Ligaya",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915012",
      "brgy_name": "Mabini",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915013",
      "brgy_name": "Murcon",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915015",
      "brgy_name": "Plaridel",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915016",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915018",
      "brgy_name": "San Felipe",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915019",
      "brgy_name": "San Francisco",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915020",
      "brgy_name": "San Nicolas",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915021",
      "brgy_name": "San Vicente",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915022",
      "brgy_name": "Santa Barbara",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915023",
      "brgy_name": "Victoria",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915024",
      "brgy_name": "Villa Viniegas",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034915025",
      "brgy_name": "Bosque",
      "city_code": "034915",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916002",
      "brgy_name": "Agupalo Este",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916003",
      "brgy_name": "Agupalo Weste",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916004",
      "brgy_name": "Alalay Chica",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916005",
      "brgy_name": "Alalay Grande",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916006",
      "brgy_name": "J. U. Tienzo (Arimal)",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916007",
      "brgy_name": "Bagong Flores",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916008",
      "brgy_name": "Balbalungao",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916010",
      "brgy_name": "Burgos",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916013",
      "brgy_name": "Cordero",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916016",
      "brgy_name": "Mapangpang",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916017",
      "brgy_name": "Namulandayan",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916018",
      "brgy_name": "Parista",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916020",
      "brgy_name": "Poblacion East",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916021",
      "brgy_name": "Poblacion North",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916022",
      "brgy_name": "Poblacion South",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916023",
      "brgy_name": "Poblacion West",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916025",
      "brgy_name": "Salvacion I",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916026",
      "brgy_name": "Salvacion II",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916028",
      "brgy_name": "San Antonio Este",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916029",
      "brgy_name": "San Antonio Weste",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916030",
      "brgy_name": "San Isidro",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916032",
      "brgy_name": "San Pedro",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916033",
      "brgy_name": "San Roque",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034916034",
      "brgy_name": "Santo Domingo",
      "city_code": "034916",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917001",
      "brgy_name": "Bagong Sikat",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917002",
      "brgy_name": "Balante",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917003",
      "brgy_name": "Bantug",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917004",
      "brgy_name": "Bical",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917005",
      "brgy_name": "Cabisuculan",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917006",
      "brgy_name": "Calabalabaan",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917007",
      "brgy_name": "Calisitan",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917008",
      "brgy_name": "Catalanacan",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917009",
      "brgy_name": "Curva",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917010",
      "brgy_name": "Franza",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917011",
      "brgy_name": "Gabaldon",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917012",
      "brgy_name": "Labney",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917013",
      "brgy_name": "Licaong",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917014",
      "brgy_name": "Linglingay",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917016",
      "brgy_name": "Mangandingay",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917017",
      "brgy_name": "Magtanggol",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917018",
      "brgy_name": "Maligaya",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917019",
      "brgy_name": "Mapangpang",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917020",
      "brgy_name": "Maragol",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917021",
      "brgy_name": "Matingkis",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917022",
      "brgy_name": "Naglabrahan",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917024",
      "brgy_name": "Palusapis",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917025",
      "brgy_name": "Pandalla",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917026",
      "brgy_name": "Poblacion East",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917027",
      "brgy_name": "Poblacion North",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917028",
      "brgy_name": "Poblacion South",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917029",
      "brgy_name": "Poblacion West",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917030",
      "brgy_name": "Rang-ayan",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917031",
      "brgy_name": "Rizal",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917032",
      "brgy_name": "San Andres",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917033",
      "brgy_name": "San Antonio",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917034",
      "brgy_name": "San Felipe",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917035",
      "brgy_name": "Sapang Cawayan",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917036",
      "brgy_name": "Villa Isla",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917037",
      "brgy_name": "Villa Nati",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917038",
      "brgy_name": "Villa Santos",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034917040",
      "brgy_name": "Villa Cuizon",
      "city_code": "034917",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918001",
      "brgy_name": "Alemania",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918002",
      "brgy_name": "Ambasador Alzate Village",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918003",
      "brgy_name": "Cabaducan East (Pob.)",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918004",
      "brgy_name": "Cabaducan West (Pob.)",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918005",
      "brgy_name": "Cabawangan",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918008",
      "brgy_name": "East Central Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918009",
      "brgy_name": "Edy",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918011",
      "brgy_name": "Maeling",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918012",
      "brgy_name": "Mayantoc",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918013",
      "brgy_name": "Medico",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918014",
      "brgy_name": "Monic",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918016",
      "brgy_name": "North Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918017",
      "brgy_name": "Northwest Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918019",
      "brgy_name": "Estacion (Pob.)",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918021",
      "brgy_name": "West Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918023",
      "brgy_name": "Recuerdo",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918024",
      "brgy_name": "South Central Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918025",
      "brgy_name": "Southeast Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918026",
      "brgy_name": "Southwest Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918027",
      "brgy_name": "Tony",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034918028",
      "brgy_name": "West Central Poblacion",
      "city_code": "034918",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919001",
      "brgy_name": "Aulo",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919003",
      "brgy_name": "Bo. Militar (Fort Magsaysay)",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919004",
      "brgy_name": "Ganaderia",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919005",
      "brgy_name": "Maligaya",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919006",
      "brgy_name": "Manacnac",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919007",
      "brgy_name": "Mapait",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919008",
      "brgy_name": "Marcos Village",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919010",
      "brgy_name": "Malate (Pob.)",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919011",
      "brgy_name": "Sapang Buho",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919012",
      "brgy_name": "Singalat",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919013",
      "brgy_name": "Atate",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919014",
      "brgy_name": "Caballero",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919015",
      "brgy_name": "Caimito",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919016",
      "brgy_name": "Doña Josefa",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919017",
      "brgy_name": "Imelda Valley",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919018",
      "brgy_name": "Langka",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919020",
      "brgy_name": "Santolan",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919021",
      "brgy_name": "Popolon Pagas",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034919022",
      "brgy_name": "Bagong Buhay",
      "city_code": "034919",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920001",
      "brgy_name": "Cadaclan",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920003",
      "brgy_name": "Cambitala",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920004",
      "brgy_name": "Conversion",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920005",
      "brgy_name": "Ganduz",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920007",
      "brgy_name": "Liberty",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920009",
      "brgy_name": "Malbang",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920010",
      "brgy_name": "Marikit",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920011",
      "brgy_name": "Napon-Napon",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920012",
      "brgy_name": "Poblacion East",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920013",
      "brgy_name": "Poblacion West",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920014",
      "brgy_name": "Sampaloc",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920015",
      "brgy_name": "San Juan",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920016",
      "brgy_name": "Villarica",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034920017",
      "brgy_name": "Fatima",
      "city_code": "034920",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921001",
      "brgy_name": "Callos",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921003",
      "brgy_name": "Las Piñas",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921006",
      "brgy_name": "Poblacion I",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921007",
      "brgy_name": "Poblacion II",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921008",
      "brgy_name": "Poblacion III",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921009",
      "brgy_name": "Poblacion IV",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921012",
      "brgy_name": "Santo Tomas",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921013",
      "brgy_name": "Sinasajan",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921014",
      "brgy_name": "San Josef",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034921015",
      "brgy_name": "San Mariano (Maugat)",
      "city_code": "034921",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922001",
      "brgy_name": "Bertese",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922004",
      "brgy_name": "Doña Lucia",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922005",
      "brgy_name": "Dulong Bayan",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922006",
      "brgy_name": "Ilog Baliwag",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922010",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922011",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922012",
      "brgy_name": "Pulong Bahay",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922013",
      "brgy_name": "San Alejandro",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922014",
      "brgy_name": "San Andres I",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922015",
      "brgy_name": "San Andres II",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922017",
      "brgy_name": "San Manuel",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922018",
      "brgy_name": "Santa Clara",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922019",
      "brgy_name": "Santa Rita",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922020",
      "brgy_name": "Santo Cristo",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922022",
      "brgy_name": "Santo Tomas Feria",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034922024",
      "brgy_name": "San Miguel",
      "city_code": "034922",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923002",
      "brgy_name": "Agbannawag",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923005",
      "brgy_name": "Bicos",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923006",
      "brgy_name": "Cabucbucan",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923007",
      "brgy_name": "Calaocan District",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923008",
      "brgy_name": "Canaan East",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923009",
      "brgy_name": "Canaan West",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923010",
      "brgy_name": "Casilagan",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923011",
      "brgy_name": "Aglipay (Curva)",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923012",
      "brgy_name": "Del Pilar",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923013",
      "brgy_name": "Estrella",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923016",
      "brgy_name": "General Luna",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923018",
      "brgy_name": "Macapsing",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923019",
      "brgy_name": "Maligaya",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923021",
      "brgy_name": "Paco Roman",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923022",
      "brgy_name": "Pag-asa",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923025",
      "brgy_name": "Poblacion Central",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923026",
      "brgy_name": "Poblacion East",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923027",
      "brgy_name": "Poblacion Norte",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923028",
      "brgy_name": "Poblacion Sur",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923029",
      "brgy_name": "Poblacion West",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923030",
      "brgy_name": "Portal",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923031",
      "brgy_name": "San Esteban",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923032",
      "brgy_name": "Santa Monica",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923033",
      "brgy_name": "Villa Labrador",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923034",
      "brgy_name": "Villa Paraiso",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034923035",
      "brgy_name": "San Gregorio",
      "city_code": "034923",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924001",
      "brgy_name": "Buliran",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924002",
      "brgy_name": "Cama Juan",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924003",
      "brgy_name": "Julo",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924004",
      "brgy_name": "Lawang Kupang",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924005",
      "brgy_name": "Luyos",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924006",
      "brgy_name": "Maugat",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924007",
      "brgy_name": "Panabingan",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924008",
      "brgy_name": "Papaya",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924009",
      "brgy_name": "Poblacion",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924011",
      "brgy_name": "San Francisco",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924012",
      "brgy_name": "San Jose",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924013",
      "brgy_name": "San Mariano",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924014",
      "brgy_name": "Santa Cruz",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924015",
      "brgy_name": "Santo Cristo",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924016",
      "brgy_name": "Santa Barbara",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034924017",
      "brgy_name": "Tikiw",
      "city_code": "034924",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925001",
      "brgy_name": "Alua",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925002",
      "brgy_name": "Calaba",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925004",
      "brgy_name": "Malapit",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925005",
      "brgy_name": "Mangga",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925006",
      "brgy_name": "Poblacion",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925008",
      "brgy_name": "Pulo",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925010",
      "brgy_name": "San Roque",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925011",
      "brgy_name": "Santo Cristo",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034925012",
      "brgy_name": "Tabon",
      "city_code": "034925",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926001",
      "brgy_name": "A. Pascual",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926002",
      "brgy_name": "Abar Ist",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926003",
      "brgy_name": "Abar 2nd",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926004",
      "brgy_name": "Bagong Sikat",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926005",
      "brgy_name": "Caanawan",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926006",
      "brgy_name": "Calaocan",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926007",
      "brgy_name": "Camanacsacan",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926008",
      "brgy_name": "Culaylay",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926009",
      "brgy_name": "Dizol",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926010",
      "brgy_name": "Kaliwanagan",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926011",
      "brgy_name": "Kita-Kita",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926012",
      "brgy_name": "Malasin",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926013",
      "brgy_name": "Manicla",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926014",
      "brgy_name": "Palestina",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926015",
      "brgy_name": "Parang Mangga",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926016",
      "brgy_name": "Villa Joson (Parilla)",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926017",
      "brgy_name": "Pinili",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926019",
      "brgy_name": "Rafael Rueda, Sr. Pob. (District I)",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926020",
      "brgy_name": "Ferdinand E. Marcos Pob. (District II)",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926021",
      "brgy_name": "Canuto Ramos Pob. (District III)",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926022",
      "brgy_name": "Raymundo Eugenio Pob. (District IV)",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926023",
      "brgy_name": "Crisanto Sanchez Pob. (District V)",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926024",
      "brgy_name": "Porais",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926027",
      "brgy_name": "San Agustin",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926028",
      "brgy_name": "San Juan",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926029",
      "brgy_name": "San Mauricio",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926030",
      "brgy_name": "Santo Niño 1st",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926031",
      "brgy_name": "Santo Niño 2nd",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926032",
      "brgy_name": "Santo Tomas",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926033",
      "brgy_name": "Sibut",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926034",
      "brgy_name": "Sinipit Bubon",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926035",
      "brgy_name": "Santo Niño 3rd",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926036",
      "brgy_name": "Tabulac",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926037",
      "brgy_name": "Tayabo",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926038",
      "brgy_name": "Tondod",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926039",
      "brgy_name": "Tulat",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926040",
      "brgy_name": "Villa Floresca",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034926042",
      "brgy_name": "Villa Marina",
      "city_code": "034926",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927002",
      "brgy_name": "Bonifacio District (Pob.)",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927003",
      "brgy_name": "Burgos District (Pob.)",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927004",
      "brgy_name": "Castellano",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927005",
      "brgy_name": "Diversion",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927006",
      "brgy_name": "Magpapalayoc",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927007",
      "brgy_name": "Mallorca",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927008",
      "brgy_name": "Mambangnan",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927009",
      "brgy_name": "Nieves",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927010",
      "brgy_name": "San Bartolome (Pob.)",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927011",
      "brgy_name": "Rizal District (Pob.)",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927012",
      "brgy_name": "San Anton",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927013",
      "brgy_name": "San Roque",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927014",
      "brgy_name": "Tabuating",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927015",
      "brgy_name": "Tagumpay",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034927016",
      "brgy_name": "Tambo Adorable",
      "city_code": "034927",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928001",
      "brgy_name": "Cojuangco (Pob.)",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928002",
      "brgy_name": "La Fuente",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928003",
      "brgy_name": "Liwayway",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928004",
      "brgy_name": "Malacañang",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928005",
      "brgy_name": "Maliolio",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928007",
      "brgy_name": "Mapalad",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928010",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928011",
      "brgy_name": "Rajal Centro",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928012",
      "brgy_name": "Rajal Norte",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928013",
      "brgy_name": "Rajal Sur",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928015",
      "brgy_name": "San Gregorio",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928016",
      "brgy_name": "San Mariano",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928017",
      "brgy_name": "San Pedro",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928018",
      "brgy_name": "Santo Rosario",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928019",
      "brgy_name": "Soledad",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928020",
      "brgy_name": "Valenzuela (Pob.)",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928021",
      "brgy_name": "Zamora (Pob.)",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928022",
      "brgy_name": "Aguinaldo",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928023",
      "brgy_name": "Berang",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928024",
      "brgy_name": "Burgos",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928025",
      "brgy_name": "Del Pilar",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928026",
      "brgy_name": "Gomez",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928027",
      "brgy_name": "Inspector",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928028",
      "brgy_name": "Isla",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928029",
      "brgy_name": "Lourdes",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928030",
      "brgy_name": "Luna",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928031",
      "brgy_name": "Mabini",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928032",
      "brgy_name": "San Isidro",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928033",
      "brgy_name": "San Josep",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928034",
      "brgy_name": "Santa Teresita",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928035",
      "brgy_name": "Sapsap",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928036",
      "brgy_name": "Tagpos",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034928037",
      "brgy_name": "Tramo",
      "city_code": "034928",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929002",
      "brgy_name": "Baloc",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929004",
      "brgy_name": "Buasao",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929005",
      "brgy_name": "Burgos",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929006",
      "brgy_name": "Cabugao",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929007",
      "brgy_name": "Casulucan",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929008",
      "brgy_name": "Comitang",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929009",
      "brgy_name": "Concepcion",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929011",
      "brgy_name": "Dolores",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929012",
      "brgy_name": "General Luna",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929014",
      "brgy_name": "Hulo",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929016",
      "brgy_name": "Mabini",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929017",
      "brgy_name": "Malasin",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929019",
      "brgy_name": "Malayantoc",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929020",
      "brgy_name": "Mambarao",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929023",
      "brgy_name": "Poblacion",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929024",
      "brgy_name": "Malaya (Pook Malaya)",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929026",
      "brgy_name": "Pulong Buli",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929029",
      "brgy_name": "Sagaba",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929030",
      "brgy_name": "San Agustin",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929031",
      "brgy_name": "San Fabian",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929032",
      "brgy_name": "San Francisco",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929033",
      "brgy_name": "San Pascual",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929034",
      "brgy_name": "Santa Rita",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034929036",
      "brgy_name": "Santo Rosario",
      "city_code": "034929",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930001",
      "brgy_name": "Andal Alino (Pob.)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930002",
      "brgy_name": "Bagong Sikat",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930003",
      "brgy_name": "Bagong Silang",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930004",
      "brgy_name": "Bakal I",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930005",
      "brgy_name": "Bakal II",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930006",
      "brgy_name": "Bakal III",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930008",
      "brgy_name": "Baluga",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930009",
      "brgy_name": "Bantug",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930010",
      "brgy_name": "Bantug Hacienda",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930011",
      "brgy_name": "Bantug Hamog (Basang Hamog)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930012",
      "brgy_name": "Bugtong na Buli",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930013",
      "brgy_name": "Bulac",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930014",
      "brgy_name": "Burnay",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930015",
      "brgy_name": "Calipahan",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930016",
      "brgy_name": "Campos",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930018",
      "brgy_name": "Casulucan Este",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930019",
      "brgy_name": "Collado",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930020",
      "brgy_name": "Dimasalang Norte",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930021",
      "brgy_name": "Dimasalang Sur",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930022",
      "brgy_name": "Dinarayat",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930024",
      "brgy_name": "Esguerra District (Pob.)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930025",
      "brgy_name": "Gulod",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930026",
      "brgy_name": "Homestead I",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930027",
      "brgy_name": "Homestead II",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930028",
      "brgy_name": "Cabubulaonan",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930029",
      "brgy_name": "Caaniplahan",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930030",
      "brgy_name": "Caputican",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930031",
      "brgy_name": "Kinalanguyan",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930032",
      "brgy_name": "La Torre",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930033",
      "brgy_name": "Lomboy",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930034",
      "brgy_name": "Mabuhay",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930035",
      "brgy_name": "Maestrang Kikay (Pob.)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930036",
      "brgy_name": "Mamandil",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930037",
      "brgy_name": "Marcos District (Pob.)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930038",
      "brgy_name": "Purok Matias (Pob.)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930039",
      "brgy_name": "Matingkis",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930041",
      "brgy_name": "Minabuyoc",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930042",
      "brgy_name": "Pag-asa (Pob.)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930043",
      "brgy_name": "Paludpod",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930044",
      "brgy_name": "Pantoc Bulac",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930045",
      "brgy_name": "Pinagpanaan",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930046",
      "brgy_name": "Poblacion Sur",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930047",
      "brgy_name": "Pula",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930048",
      "brgy_name": "Pulong San Miguel (Pob.)",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930049",
      "brgy_name": "Sampaloc",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930050",
      "brgy_name": "San Miguel na Munti",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930051",
      "brgy_name": "San Pascual",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930052",
      "brgy_name": "San Ricardo",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930053",
      "brgy_name": "Sibul",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930054",
      "brgy_name": "Sicsican Matanda",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930055",
      "brgy_name": "Tabacao",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930056",
      "brgy_name": "Tagaytay",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034930057",
      "brgy_name": "Valle",
      "city_code": "034930",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931002",
      "brgy_name": "Alula",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931003",
      "brgy_name": "Baybayabas",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931004",
      "brgy_name": "Buted",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931005",
      "brgy_name": "Cabiangan",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931006",
      "brgy_name": "Calisitan",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931007",
      "brgy_name": "Cinense",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931008",
      "brgy_name": "Culiat",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931009",
      "brgy_name": "Maasin",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931010",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931011",
      "brgy_name": "Mayamot I",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931012",
      "brgy_name": "Mayamot II",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931013",
      "brgy_name": "Nangabulan",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931014",
      "brgy_name": "Osmeña (Pob.)",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931015",
      "brgy_name": "Pangit",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931016",
      "brgy_name": "Patola",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931017",
      "brgy_name": "Quezon (Pob.)",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931018",
      "brgy_name": "Quirino (Pob.)",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931020",
      "brgy_name": "Roxas (Pob.)",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931021",
      "brgy_name": "Saguing",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931022",
      "brgy_name": "Sampaloc",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931023",
      "brgy_name": "Santa Catalina",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931024",
      "brgy_name": "Santo Domingo",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931025",
      "brgy_name": "Saringaya",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931026",
      "brgy_name": "Saverona",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931027",
      "brgy_name": "Tandoc",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931028",
      "brgy_name": "Tibag",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931029",
      "brgy_name": "Villa Rosario",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034931030",
      "brgy_name": "Villa Boado",
      "city_code": "034931",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932001",
      "brgy_name": "Batitang",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932003",
      "brgy_name": "Carmen",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932004",
      "brgy_name": "Concepcion",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932005",
      "brgy_name": "Del Pilar",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932008",
      "brgy_name": "General Luna",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932009",
      "brgy_name": "H. Romero",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932011",
      "brgy_name": "Macarse",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932012",
      "brgy_name": "Manaul",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932013",
      "brgy_name": "Mayamot",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932015",
      "brgy_name": "Pantoc",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932017",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932020",
      "brgy_name": "San Isidro",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932021",
      "brgy_name": "San Rafael",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932022",
      "brgy_name": "Santa Cruz",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932024",
      "brgy_name": "Santa Lucia Old",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932025",
      "brgy_name": "Santa Lucia Young",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932026",
      "brgy_name": "Santo Rosario Old",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932027",
      "brgy_name": "Santo Rosario Young",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "034932030",
      "brgy_name": "Valeriana",
      "city_code": "034932",
      "province_code": "0349",
      "region_code": "03"
    },
    {
      "brgy_code": "035401001",
      "brgy_name": "Agapito del Rosario",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401002",
      "brgy_name": "Anunas",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401003",
      "brgy_name": "Balibago",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401004",
      "brgy_name": "Capaya",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401005",
      "brgy_name": "Claro M. Recto",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401006",
      "brgy_name": "Cuayan",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401007",
      "brgy_name": "Cutcut",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401008",
      "brgy_name": "Cutud",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401010",
      "brgy_name": "Lourdes North West",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401011",
      "brgy_name": "Lourdes Sur",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401012",
      "brgy_name": "Lourdes Sur East",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401013",
      "brgy_name": "Malabanias",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401014",
      "brgy_name": "Margot",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401015",
      "brgy_name": "Mining",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401017",
      "brgy_name": "Pampang",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401018",
      "brgy_name": "Pandan",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401019",
      "brgy_name": "Pulung Maragul",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401020",
      "brgy_name": "Pulungbulu",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401021",
      "brgy_name": "Pulung Cacutud",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401022",
      "brgy_name": "Salapungan",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401023",
      "brgy_name": "San Jose",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401024",
      "brgy_name": "San Nicolas",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401025",
      "brgy_name": "Santa Teresita",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401026",
      "brgy_name": "Santa Trinidad",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401027",
      "brgy_name": "Santo Cristo",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401028",
      "brgy_name": "Santo Domingo",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401029",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401030",
      "brgy_name": "Sapalibutad",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401031",
      "brgy_name": "Sapangbato",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401032",
      "brgy_name": "Tabun",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401034",
      "brgy_name": "Virgen Delos Remedios",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401035",
      "brgy_name": "Amsic",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035401036",
      "brgy_name": "Ninoy Aquino (Marisol)",
      "city_code": "035401",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402001",
      "brgy_name": "Balucuc",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402003",
      "brgy_name": "Calantipe",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402004",
      "brgy_name": "Cansinala",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402005",
      "brgy_name": "Capalangan",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402007",
      "brgy_name": "Colgante",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402009",
      "brgy_name": "Paligui",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402010",
      "brgy_name": "Sampaloc",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402011",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402012",
      "brgy_name": "San Vicente",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402013",
      "brgy_name": "Sucad",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402014",
      "brgy_name": "Sulipan",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035402015",
      "brgy_name": "Tabuyuc (Santo Rosario)",
      "city_code": "035402",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403001",
      "brgy_name": "Arenas",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403002",
      "brgy_name": "Baliti",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403003",
      "brgy_name": "Batasan",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403005",
      "brgy_name": "Buensuceso",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403007",
      "brgy_name": "Candating",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403008",
      "brgy_name": "Gatiawin",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403009",
      "brgy_name": "Guemasan",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403010",
      "brgy_name": "La Paz (Turu)",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403011",
      "brgy_name": "Lacmit",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403012",
      "brgy_name": "Lacquios",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403013",
      "brgy_name": "Mangga-Cacutud",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403014",
      "brgy_name": "Mapalad",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403015",
      "brgy_name": "Panlinlang",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403016",
      "brgy_name": "Paralaya",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403017",
      "brgy_name": "Plazang Luma",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403018",
      "brgy_name": "Poblacion",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403020",
      "brgy_name": "San Agustin Norte",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403021",
      "brgy_name": "San Agustin Sur",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403022",
      "brgy_name": "San Antonio",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403025",
      "brgy_name": "San Jose Mesulo",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403026",
      "brgy_name": "San Juan Bano",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403027",
      "brgy_name": "San Mateo",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403028",
      "brgy_name": "San Nicolas",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403029",
      "brgy_name": "San Roque Bitas",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403030",
      "brgy_name": "Cupang (Santa Lucia)",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403031",
      "brgy_name": "Matamo (Santa Lucia)",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403032",
      "brgy_name": "Santo Niño Tabuan",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403034",
      "brgy_name": "Suclayin",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403035",
      "brgy_name": "Telapayong",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035403036",
      "brgy_name": "Kaledian (Camba)",
      "city_code": "035403",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404001",
      "brgy_name": "Balas",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404002",
      "brgy_name": "Cabalantian",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404003",
      "brgy_name": "Cabambangan (Pob.)",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404004",
      "brgy_name": "Cabetican",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404005",
      "brgy_name": "Calibutbut",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404006",
      "brgy_name": "Concepcion",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404007",
      "brgy_name": "Dolores",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404008",
      "brgy_name": "Duat",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404009",
      "brgy_name": "Macabacle",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404010",
      "brgy_name": "Magliman",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404011",
      "brgy_name": "Maliwalu",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404012",
      "brgy_name": "Mesalipit",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404013",
      "brgy_name": "Parulog",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404015",
      "brgy_name": "Potrero",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404016",
      "brgy_name": "San Antonio",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404018",
      "brgy_name": "San Isidro",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404019",
      "brgy_name": "San Vicente",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404020",
      "brgy_name": "Santa Barbara",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404021",
      "brgy_name": "Santa Ines",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404023",
      "brgy_name": "Talba",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035404024",
      "brgy_name": "Tinajero",
      "city_code": "035404",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405001",
      "brgy_name": "Bahay Pare",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405002",
      "brgy_name": "Bambang",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405003",
      "brgy_name": "Barangca",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405004",
      "brgy_name": "Barit",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405005",
      "brgy_name": "Buas (Pob.)",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405008",
      "brgy_name": "Cuayang Bugtong",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405009",
      "brgy_name": "Dalayap",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405010",
      "brgy_name": "Dulong Ilog",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405011",
      "brgy_name": "Gulap",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405012",
      "brgy_name": "Lanang",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405013",
      "brgy_name": "Lourdes",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405014",
      "brgy_name": "Magumbali",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405015",
      "brgy_name": "Mandasig",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405016",
      "brgy_name": "Mandili",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405017",
      "brgy_name": "Mangga",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405018",
      "brgy_name": "Mapaniqui",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405020",
      "brgy_name": "Paligui",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405021",
      "brgy_name": "Pangclara",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405022",
      "brgy_name": "Pansinao",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405023",
      "brgy_name": "Paralaya (Pob.)",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405024",
      "brgy_name": "Pasig",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405025",
      "brgy_name": "Pescadores (Pob.)",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405026",
      "brgy_name": "Pulong Gubat",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405027",
      "brgy_name": "Pulong Palazan",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405028",
      "brgy_name": "Salapungan",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405029",
      "brgy_name": "San Agustin (Pob.)",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405031",
      "brgy_name": "Santo Rosario",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405032",
      "brgy_name": "Tagulod",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405033",
      "brgy_name": "Talang",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405034",
      "brgy_name": "Tenejero",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405035",
      "brgy_name": "Vizal San Pablo",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405036",
      "brgy_name": "Vizal Santo Cristo",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035405037",
      "brgy_name": "Vizal Santo Niño",
      "city_code": "035405",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406001",
      "brgy_name": "Anon",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406002",
      "brgy_name": "Apalit",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406003",
      "brgy_name": "Basa Air Base",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406004",
      "brgy_name": "Benedicto",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406005",
      "brgy_name": "Bodega",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406006",
      "brgy_name": "Cabangcalan",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406007",
      "brgy_name": "Calantas",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406008",
      "brgy_name": "Carmencita",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406009",
      "brgy_name": "Consuelo",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406010",
      "brgy_name": "Dampe",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406011",
      "brgy_name": "Del Carmen",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406012",
      "brgy_name": "Fortuna",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406013",
      "brgy_name": "Gutad",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406014",
      "brgy_name": "Mabical",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406015",
      "brgy_name": "Santo Rosario (Malabo)",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406016",
      "brgy_name": "Maligaya",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406017",
      "brgy_name": "Nabuclod",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406018",
      "brgy_name": "Pabanlag",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406019",
      "brgy_name": "Paguiruan",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406020",
      "brgy_name": "Palmayo",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406021",
      "brgy_name": "Pandaguirig",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406023",
      "brgy_name": "Poblacion",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406024",
      "brgy_name": "San Antonio",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406025",
      "brgy_name": "San Isidro",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406026",
      "brgy_name": "San Jose",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406027",
      "brgy_name": "San Nicolas",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406028",
      "brgy_name": "San Pedro",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406029",
      "brgy_name": "San Ramon",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406030",
      "brgy_name": "San Roque",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406031",
      "brgy_name": "Santa Monica",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406032",
      "brgy_name": "Solib",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406033",
      "brgy_name": "Valdez",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035406034",
      "brgy_name": "Mawacat",
      "city_code": "035406",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407002",
      "brgy_name": "Bancal",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407004",
      "brgy_name": "Jose Abad Santos (Siran)",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407005",
      "brgy_name": "Lambac",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407006",
      "brgy_name": "Magsaysay",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407007",
      "brgy_name": "Maquiapo",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407008",
      "brgy_name": "Natividad",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407009",
      "brgy_name": "Plaza Burgos (Pob.)",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407010",
      "brgy_name": "Pulungmasle",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407011",
      "brgy_name": "Rizal",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407012",
      "brgy_name": "San Agustin",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407013",
      "brgy_name": "San Antonio",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407014",
      "brgy_name": "San Isidro",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407015",
      "brgy_name": "San Jose",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407016",
      "brgy_name": "San Juan Bautista",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407017",
      "brgy_name": "San Juan Nepomuceno",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407018",
      "brgy_name": "San Matias",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407019",
      "brgy_name": "San Miguel (Betis)",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407020",
      "brgy_name": "San Nicolas 1st",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407021",
      "brgy_name": "San Nicolas 2nd",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407022",
      "brgy_name": "San Pablo",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407023",
      "brgy_name": "San Pedro",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407024",
      "brgy_name": "San Rafael (Duck Island)",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407025",
      "brgy_name": "San Roque",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407026",
      "brgy_name": "San Vicente (Ibus)",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407027",
      "brgy_name": "San Juan",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407028",
      "brgy_name": "Santa Filomena (Pob.)",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407029",
      "brgy_name": "Santa Ines",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407030",
      "brgy_name": "Santa Ursula",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407031",
      "brgy_name": "Santo Cristo",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407033",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035407034",
      "brgy_name": "Ascomo",
      "city_code": "035407",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408001",
      "brgy_name": "Balantacan",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408002",
      "brgy_name": "Bancal Sinubli",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408003",
      "brgy_name": "Bancal Pugad",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408004",
      "brgy_name": "Baruya (San Rafael)",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408005",
      "brgy_name": "Calangain",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408006",
      "brgy_name": "Concepcion",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408007",
      "brgy_name": "Del Carmen",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408008",
      "brgy_name": "De La Paz",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408009",
      "brgy_name": "Don Ignacio Dimson",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408010",
      "brgy_name": "Lourdes (Lauc Pau)",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408011",
      "brgy_name": "Prado Siongco",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408012",
      "brgy_name": "Remedios",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408013",
      "brgy_name": "San Agustin",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408014",
      "brgy_name": "San Antonio",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408015",
      "brgy_name": "San Francisco",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408016",
      "brgy_name": "San Isidro",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408017",
      "brgy_name": "San Jose Apunan",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408018",
      "brgy_name": "San Jose Gumi",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408019",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408020",
      "brgy_name": "San Matias",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408021",
      "brgy_name": "San Miguel",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408022",
      "brgy_name": "San Nicolas 1st (Pob.)",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408023",
      "brgy_name": "San Nicolas 2nd",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408024",
      "brgy_name": "San Pablo 1st",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408025",
      "brgy_name": "San Pablo 2nd",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408026",
      "brgy_name": "San Pedro Palcarangan",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408027",
      "brgy_name": "San Pedro Saug",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408029",
      "brgy_name": "San Roque Arbol",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408030",
      "brgy_name": "San Roque Dau",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408031",
      "brgy_name": "San Vicente",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408032",
      "brgy_name": "Santa Barbara",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408033",
      "brgy_name": "Santa Catalina",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408034",
      "brgy_name": "Santa Cruz",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408035",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408036",
      "brgy_name": "Santa Maria",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408037",
      "brgy_name": "Santa Monica",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408038",
      "brgy_name": "Santa Rita",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408040",
      "brgy_name": "Santa Teresa 1st",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408041",
      "brgy_name": "Santa Teresa 2nd",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408042",
      "brgy_name": "Santiago",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408043",
      "brgy_name": "Santo Domingo",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408044",
      "brgy_name": "Santo Niño (Prado Aruba)",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408045",
      "brgy_name": "Santo Tomas (Pob.)",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035408046",
      "brgy_name": "Santo Cristo",
      "city_code": "035408",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409001",
      "brgy_name": "Atlu-Bola",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409002",
      "brgy_name": "Bical",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409003",
      "brgy_name": "Bundagul",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409004",
      "brgy_name": "Cacutud",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409005",
      "brgy_name": "Calumpang",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409006",
      "brgy_name": "Camachiles",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409007",
      "brgy_name": "Dapdap",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409008",
      "brgy_name": "Dau",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409009",
      "brgy_name": "Dolores",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409010",
      "brgy_name": "Duquit",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409011",
      "brgy_name": "Lakandula",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409012",
      "brgy_name": "Mabiga",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409013",
      "brgy_name": "Macapagal Village",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409015",
      "brgy_name": "Mamatitang",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409016",
      "brgy_name": "Mangalit",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409017",
      "brgy_name": "Marcos Village",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409018",
      "brgy_name": "Mawaque",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409019",
      "brgy_name": "Paralayunan",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409020",
      "brgy_name": "Poblacion",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409021",
      "brgy_name": "San Francisco",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409022",
      "brgy_name": "San Joaquin",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409023",
      "brgy_name": "Santa Ines",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409024",
      "brgy_name": "Santa Maria",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409025",
      "brgy_name": "Santo Rosario",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409026",
      "brgy_name": "Sapang Balen",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409027",
      "brgy_name": "Sapang Biabas",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035409028",
      "brgy_name": "Tabun",
      "city_code": "035409",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410001",
      "brgy_name": "Batasan",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410002",
      "brgy_name": "Caduang Tete",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410003",
      "brgy_name": "Candelaria",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410004",
      "brgy_name": "Castuli",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410005",
      "brgy_name": "Consuelo",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410006",
      "brgy_name": "Dalayap",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410007",
      "brgy_name": "Mataguiti",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410008",
      "brgy_name": "San Esteban",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410009",
      "brgy_name": "San Francisco",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410010",
      "brgy_name": "San Gabriel (Pob.)",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410011",
      "brgy_name": "San Isidro",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410012",
      "brgy_name": "San Jose",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410013",
      "brgy_name": "San Juan",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410014",
      "brgy_name": "San Rafael",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410015",
      "brgy_name": "San Roque",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410016",
      "brgy_name": "San Vicente",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410017",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410018",
      "brgy_name": "Santa Lutgarda",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410019",
      "brgy_name": "Santa Maria",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410020",
      "brgy_name": "Santa Rita (Pob.)",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410021",
      "brgy_name": "Santo Niño",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410022",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410023",
      "brgy_name": "Saplad David",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410024",
      "brgy_name": "Tacasan",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035410025",
      "brgy_name": "Telacsan",
      "city_code": "035410",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411001",
      "brgy_name": "Camias",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411002",
      "brgy_name": "Dolores",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411003",
      "brgy_name": "Escaler",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411004",
      "brgy_name": "La Paz",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411005",
      "brgy_name": "Navaling",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411006",
      "brgy_name": "San Agustin",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411007",
      "brgy_name": "San Antonio",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411008",
      "brgy_name": "San Franciso",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411009",
      "brgy_name": "San Ildefonso",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411010",
      "brgy_name": "San Isidro",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411011",
      "brgy_name": "San Jose",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411012",
      "brgy_name": "San Miguel",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411013",
      "brgy_name": "San Nicolas 1st (Pob.)",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411014",
      "brgy_name": "San Nicolas 2nd",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411015",
      "brgy_name": "San Pablo (Pob.)",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411016",
      "brgy_name": "San Pedro I",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411017",
      "brgy_name": "San Pedro II",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411018",
      "brgy_name": "San Roque",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411019",
      "brgy_name": "San Vicente",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411020",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411021",
      "brgy_name": "Santa Lucia",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411022",
      "brgy_name": "Santa Maria",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411023",
      "brgy_name": "Santo Niño",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411024",
      "brgy_name": "Santo Rosario",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411025",
      "brgy_name": "Bucanan",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411026",
      "brgy_name": "Turu",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035411027",
      "brgy_name": "Ayala",
      "city_code": "035411",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412001",
      "brgy_name": "Alauli",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412003",
      "brgy_name": "Bagang",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412004",
      "brgy_name": "Balibago",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412006",
      "brgy_name": "Bebe Anac",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412007",
      "brgy_name": "Bebe Matua",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412008",
      "brgy_name": "Bulacus",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412009",
      "brgy_name": "San Agustin (Caingin)",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412010",
      "brgy_name": "Santa Monica (Caingin)",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412011",
      "brgy_name": "Cambasi",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412012",
      "brgy_name": "Malauli",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412013",
      "brgy_name": "Nigui",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412014",
      "brgy_name": "Palimpe",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412015",
      "brgy_name": "Puti",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412016",
      "brgy_name": "Sagrada (Tibagin)",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412017",
      "brgy_name": "San Isidro Anac",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412018",
      "brgy_name": "San Isidro Matua (Pob.)",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412019",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412020",
      "brgy_name": "San Pedro",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412021",
      "brgy_name": "Santa Cruz",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412023",
      "brgy_name": "Santa Lucia Matua",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412024",
      "brgy_name": "Santa Lucia Paguiba",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412025",
      "brgy_name": "Santa Lucia Wakas",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412026",
      "brgy_name": "Santa Lucia Anac (Pob.)",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412027",
      "brgy_name": "Sapang Kawayan",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412028",
      "brgy_name": "Sua",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035412029",
      "brgy_name": "Santo Niño",
      "city_code": "035412",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413001",
      "brgy_name": "Acli",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413002",
      "brgy_name": "Anao",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413003",
      "brgy_name": "Balas",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413004",
      "brgy_name": "Buenavista",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413005",
      "brgy_name": "Camuning",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413006",
      "brgy_name": "Cawayan",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413007",
      "brgy_name": "Concepcion",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413008",
      "brgy_name": "Culubasa",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413009",
      "brgy_name": "Divisoria",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413010",
      "brgy_name": "Dolores (Piring)",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413011",
      "brgy_name": "Eden",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413012",
      "brgy_name": "Gandus",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413013",
      "brgy_name": "Lagundi",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413014",
      "brgy_name": "Laput",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413015",
      "brgy_name": "Laug",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413017",
      "brgy_name": "Masamat",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413018",
      "brgy_name": "Masangsang",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413020",
      "brgy_name": "Nueva Victoria",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413021",
      "brgy_name": "Pandacaqui",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413022",
      "brgy_name": "Pangatlan",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413023",
      "brgy_name": "Panipuan",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413024",
      "brgy_name": "Parian (Pob.)",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413025",
      "brgy_name": "Sabanilla",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413026",
      "brgy_name": "San Antonio",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413027",
      "brgy_name": "San Carlos",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413029",
      "brgy_name": "San Jose Malino",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413030",
      "brgy_name": "San Jose Matulid",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413031",
      "brgy_name": "San Juan",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413032",
      "brgy_name": "San Lorenzo",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413033",
      "brgy_name": "San Miguel",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413034",
      "brgy_name": "San Nicolas",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413035",
      "brgy_name": "San Pablo",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413036",
      "brgy_name": "San Patricio",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413037",
      "brgy_name": "San Rafael",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413038",
      "brgy_name": "San Roque",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413039",
      "brgy_name": "San Vicente",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413040",
      "brgy_name": "Santa Cruz",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413041",
      "brgy_name": "Santa Maria",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413042",
      "brgy_name": "Santo Domingo",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413043",
      "brgy_name": "Santo Rosario",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413044",
      "brgy_name": "Sapang Maisac",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413045",
      "brgy_name": "Suclaban",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035413046",
      "brgy_name": "Tangle (Tanglay)",
      "city_code": "035413",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414001",
      "brgy_name": "Bulac",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414002",
      "brgy_name": "Dawe",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414003",
      "brgy_name": "Lourdes",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414004",
      "brgy_name": "Maniango",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414006",
      "brgy_name": "San Francisco 1st",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414007",
      "brgy_name": "San Francisco 2nd",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414008",
      "brgy_name": "San Isidro",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414009",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414010",
      "brgy_name": "San Pedro",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414011",
      "brgy_name": "Santa Catalina",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414012",
      "brgy_name": "Santa Maria",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414013",
      "brgy_name": "Santa Rita",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414014",
      "brgy_name": "Santo Domingo",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414015",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035414016",
      "brgy_name": "Saplad",
      "city_code": "035414",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415001",
      "brgy_name": "Babo Pangulo",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415002",
      "brgy_name": "Babo Sacan",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415004",
      "brgy_name": "Balubad",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415008",
      "brgy_name": "Calzadang Bayu",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415009",
      "brgy_name": "Camias",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415010",
      "brgy_name": "Cangatba",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415011",
      "brgy_name": "Diaz",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415013",
      "brgy_name": "Dolores (Hacienda Dolores)",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415014",
      "brgy_name": "Jalung",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415016",
      "brgy_name": "Mancatian",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415017",
      "brgy_name": "Manibaug Libutad",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415018",
      "brgy_name": "Manibaug Paralaya",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415019",
      "brgy_name": "Manibaug Pasig",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415020",
      "brgy_name": "Manuali",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415021",
      "brgy_name": "Mitla Proper",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415022",
      "brgy_name": "Palat",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415023",
      "brgy_name": "Pias",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415024",
      "brgy_name": "Pio",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415025",
      "brgy_name": "Planas",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415026",
      "brgy_name": "Poblacion",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415028",
      "brgy_name": "Pulong Santol",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415029",
      "brgy_name": "Salu",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415030",
      "brgy_name": "San Jose Mitla",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415031",
      "brgy_name": "Santa Cruz",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415032",
      "brgy_name": "Sepung Bulaun",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415033",
      "brgy_name": "Sinura",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415035",
      "brgy_name": "Villa Maria (Aetas)",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415036",
      "brgy_name": "Inararo (Aetas)",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035415037",
      "brgy_name": "Sapang Uwak (Aetas)",
      "city_code": "035415",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416001",
      "brgy_name": "Alasas",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416002",
      "brgy_name": "Baliti",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416003",
      "brgy_name": "Bulaon",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416004",
      "brgy_name": "Calulut",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416005",
      "brgy_name": "Dela Paz Norte",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416006",
      "brgy_name": "Dela Paz Sur",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416007",
      "brgy_name": "Del Carmen",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416008",
      "brgy_name": "Del Pilar",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416009",
      "brgy_name": "Del Rosario",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416010",
      "brgy_name": "Dolores",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416011",
      "brgy_name": "Juliana",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416012",
      "brgy_name": "Lara",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416013",
      "brgy_name": "Lourdes",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416014",
      "brgy_name": "Magliman",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416015",
      "brgy_name": "Maimpis",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416016",
      "brgy_name": "Malino",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416017",
      "brgy_name": "Malpitic",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416019",
      "brgy_name": "Pandaras",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416020",
      "brgy_name": "Panipuan",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416021",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416022",
      "brgy_name": "Quebiauan",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416023",
      "brgy_name": "Saguin",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416024",
      "brgy_name": "San Agustin",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416025",
      "brgy_name": "San Felipe",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416026",
      "brgy_name": "San Isidro",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416027",
      "brgy_name": "San Jose",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416029",
      "brgy_name": "San Juan",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416030",
      "brgy_name": "San Nicolas",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416031",
      "brgy_name": "San Pedro",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416032",
      "brgy_name": "Santa Lucia",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416033",
      "brgy_name": "Santa Teresita",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416034",
      "brgy_name": "Santo Niño",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416036",
      "brgy_name": "Sindalan",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416037",
      "brgy_name": "Telabastagan",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035416038",
      "brgy_name": "Pulung Bulu",
      "city_code": "035416",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417001",
      "brgy_name": "San Agustin",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417002",
      "brgy_name": "San Carlos",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417003",
      "brgy_name": "San Isidro",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417004",
      "brgy_name": "San Jose",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417005",
      "brgy_name": "San Juan",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417006",
      "brgy_name": "San Nicolas",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417008",
      "brgy_name": "San Roque",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417009",
      "brgy_name": "San Sebastian",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417010",
      "brgy_name": "Santa Catalina",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417011",
      "brgy_name": "Santa Cruz Pambilog",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417012",
      "brgy_name": "Santa Cruz Poblacion",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417013",
      "brgy_name": "Santa Lucia",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417014",
      "brgy_name": "Santa Monica",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417015",
      "brgy_name": "Santa Rita",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417016",
      "brgy_name": "Santo Niño",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417017",
      "brgy_name": "Santo Rosario",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035417018",
      "brgy_name": "Santo Tomas",
      "city_code": "035417",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418001",
      "brgy_name": "Concepcion",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418002",
      "brgy_name": "De La Paz",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418003",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418004",
      "brgy_name": "San Agustin",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418005",
      "brgy_name": "San Isidro",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418006",
      "brgy_name": "San Jose",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418007",
      "brgy_name": "San Miguel",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418008",
      "brgy_name": "San Nicolas",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418009",
      "brgy_name": "San Pablo Libutad",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418010",
      "brgy_name": "San Pablo Proper",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418011",
      "brgy_name": "San Pedro",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418012",
      "brgy_name": "Santa Cruz",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418013",
      "brgy_name": "Santa Monica",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035418014",
      "brgy_name": "Santo Niño",
      "city_code": "035418",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419001",
      "brgy_name": "San Agustin",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419002",
      "brgy_name": "San Bartolome",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419003",
      "brgy_name": "San Isidro",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419004",
      "brgy_name": "San Joaquin (Pob.)",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419005",
      "brgy_name": "San Jose",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419006",
      "brgy_name": "San Juan",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419007",
      "brgy_name": "San Nicolas",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419008",
      "brgy_name": "San Pablo",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419009",
      "brgy_name": "San Pedro",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419010",
      "brgy_name": "San Roque",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419011",
      "brgy_name": "Santa Lucia",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419012",
      "brgy_name": "Santa Maria",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419013",
      "brgy_name": "Santiago",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035419014",
      "brgy_name": "Santo Rosario",
      "city_code": "035419",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420001",
      "brgy_name": "Becuran",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420002",
      "brgy_name": "Dila-dila",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420003",
      "brgy_name": "San Agustin",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420004",
      "brgy_name": "San Basilio",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420005",
      "brgy_name": "San Isidro",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420006",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420007",
      "brgy_name": "San Juan",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420008",
      "brgy_name": "San Matias",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420009",
      "brgy_name": "San Vicente",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035420010",
      "brgy_name": "Santa Monica",
      "city_code": "035420",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035421002",
      "brgy_name": "Moras De La Paz",
      "city_code": "035421",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035421004",
      "brgy_name": "Poblacion",
      "city_code": "035421",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035421005",
      "brgy_name": "San Bartolome",
      "city_code": "035421",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035421006",
      "brgy_name": "San Matias",
      "city_code": "035421",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035421007",
      "brgy_name": "San Vicente",
      "city_code": "035421",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035421008",
      "brgy_name": "Santo Rosario (Pau)",
      "city_code": "035421",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035421009",
      "brgy_name": "Sapa (Santo Niño)",
      "city_code": "035421",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422001",
      "brgy_name": "Batang 1st",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422002",
      "brgy_name": "Batang 2nd",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422004",
      "brgy_name": "Mabuanbuan",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422005",
      "brgy_name": "Malusac",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422006",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422007",
      "brgy_name": "San Antonio",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422008",
      "brgy_name": "San Nicolas 1st",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422009",
      "brgy_name": "San Nicolas 2nd",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422010",
      "brgy_name": "San Pedro",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422011",
      "brgy_name": "Santa Monica",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422012",
      "brgy_name": "Santo Tomas",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "035422013",
      "brgy_name": "Sabitanan",
      "city_code": "035422",
      "province_code": "0354",
      "region_code": "03"
    },
    {
      "brgy_code": "036901001",
      "brgy_name": "Baguindoc (Baguinloc)",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901003",
      "brgy_name": "Bantog",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901006",
      "brgy_name": "Campos",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901008",
      "brgy_name": "Carmen",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901009",
      "brgy_name": "Casili",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901010",
      "brgy_name": "Don Ramon",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901011",
      "brgy_name": "Hernando",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901013",
      "brgy_name": "Poblacion",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901015",
      "brgy_name": "Rizal",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901016",
      "brgy_name": "San Francisco East",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901017",
      "brgy_name": "San Francisco West",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901018",
      "brgy_name": "San Jose North",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901019",
      "brgy_name": "San Jose South",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901020",
      "brgy_name": "San Juan",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901021",
      "brgy_name": "San Roque",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901022",
      "brgy_name": "Santo Domingo",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901023",
      "brgy_name": "Sinense",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036901024",
      "brgy_name": "Suaverdez",
      "city_code": "036901",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902001",
      "brgy_name": "Anupul",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902002",
      "brgy_name": "Banaba",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902003",
      "brgy_name": "Bangcu",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902004",
      "brgy_name": "Culubasa",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902005",
      "brgy_name": "Dela Cruz",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902006",
      "brgy_name": "La Paz",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902007",
      "brgy_name": "Lourdes",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902008",
      "brgy_name": "Malonzo",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902009",
      "brgy_name": "Virgen de los Remedios (Pacalcal)",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902010",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902011",
      "brgy_name": "San Pedro",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902012",
      "brgy_name": "San Rafael",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902013",
      "brgy_name": "San Roque",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902014",
      "brgy_name": "San Vicente",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036902015",
      "brgy_name": "Santo Niño",
      "city_code": "036902",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903001",
      "brgy_name": "Anoling 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903002",
      "brgy_name": "Anoling 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903003",
      "brgy_name": "Anoling 3rd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903004",
      "brgy_name": "Bacabac",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903005",
      "brgy_name": "Bacsay",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903006",
      "brgy_name": "Bancay 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903007",
      "brgy_name": "Bancay 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903008",
      "brgy_name": "Bilad",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903009",
      "brgy_name": "Birbira",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903011",
      "brgy_name": "Bobon Caarosipan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903012",
      "brgy_name": "Bobon 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903013",
      "brgy_name": "Bobon 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903016",
      "brgy_name": "Cabanabaan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903017",
      "brgy_name": "Cacamilingan Norte",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903018",
      "brgy_name": "Cacamilingan Sur",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903019",
      "brgy_name": "Caniag",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903020",
      "brgy_name": "Carael",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903021",
      "brgy_name": "Cayaoan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903022",
      "brgy_name": "Cayasan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903023",
      "brgy_name": "Florida",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903024",
      "brgy_name": "Lasong",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903025",
      "brgy_name": "Libueg",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903027",
      "brgy_name": "Malacampa",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903028",
      "brgy_name": "Manakem",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903029",
      "brgy_name": "Manupeg",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903030",
      "brgy_name": "Marawi",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903031",
      "brgy_name": "Matubog",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903033",
      "brgy_name": "Nagrambacan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903034",
      "brgy_name": "Nagserialan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903035",
      "brgy_name": "Palimbo Proper",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903036",
      "brgy_name": "Palimbo-Caarosipan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903037",
      "brgy_name": "Pao 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903038",
      "brgy_name": "Pao 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903039",
      "brgy_name": "Pao 3rd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903040",
      "brgy_name": "Papaac",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903041",
      "brgy_name": "Pindangan 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903042",
      "brgy_name": "Pindangan 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903043",
      "brgy_name": "Poblacion A",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903044",
      "brgy_name": "Poblacion B",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903045",
      "brgy_name": "Poblacion C",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903046",
      "brgy_name": "Poblacion D",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903047",
      "brgy_name": "Poblacion E",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903048",
      "brgy_name": "Poblacion F",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903049",
      "brgy_name": "Poblacion G",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903050",
      "brgy_name": "Poblacion H",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903051",
      "brgy_name": "Poblacion I",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903052",
      "brgy_name": "Poblacion J",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903053",
      "brgy_name": "Santa Maria",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903055",
      "brgy_name": "Sawat",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903057",
      "brgy_name": "Sinilian 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903058",
      "brgy_name": "Sinilian 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903059",
      "brgy_name": "Sinilian 3rd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903060",
      "brgy_name": "Sinilian Cacalibosoan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903061",
      "brgy_name": "Sinulatan 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903062",
      "brgy_name": "Sinulatan 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903063",
      "brgy_name": "Surgui 1st",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903064",
      "brgy_name": "Surgui 2nd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903065",
      "brgy_name": "Surgui 3rd",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903066",
      "brgy_name": "Tambugan",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903067",
      "brgy_name": "Telbang",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036903068",
      "brgy_name": "Tuec",
      "city_code": "036903",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904001",
      "brgy_name": "Aranguren",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904002",
      "brgy_name": "Bueno",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904003",
      "brgy_name": "Cubcub (Pob.)",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904004",
      "brgy_name": "Cutcut 1st",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904005",
      "brgy_name": "Cutcut 2nd",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904007",
      "brgy_name": "Dolores",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904008",
      "brgy_name": "Estrada (Calingcuan)",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904009",
      "brgy_name": "Lawy",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904010",
      "brgy_name": "Manga",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904011",
      "brgy_name": "Manlapig",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904013",
      "brgy_name": "O'Donnell",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904016",
      "brgy_name": "Santa Lucia",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904017",
      "brgy_name": "Santa Rita",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904019",
      "brgy_name": "Santo Domingo 1st",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904020",
      "brgy_name": "Santo Domingo 2nd",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904021",
      "brgy_name": "Santo Rosario",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904022",
      "brgy_name": "Talaga",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904023",
      "brgy_name": "Maruglu",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904024",
      "brgy_name": "Santa Juliana",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036904025",
      "brgy_name": "Cristo Rey",
      "city_code": "036904",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905001",
      "brgy_name": "Alfonso",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905003",
      "brgy_name": "Balutu",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905005",
      "brgy_name": "Cafe",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905006",
      "brgy_name": "Calius Gueco",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905007",
      "brgy_name": "Caluluan",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905008",
      "brgy_name": "Castillo",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905009",
      "brgy_name": "Corazon de Jesus",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905010",
      "brgy_name": "Culatingan",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905011",
      "brgy_name": "Dungan",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905012",
      "brgy_name": "Dutung-A-Matas",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905013",
      "brgy_name": "Lilibangan",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905014",
      "brgy_name": "Mabilog",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905015",
      "brgy_name": "Magao",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905017",
      "brgy_name": "Malupa",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905019",
      "brgy_name": "Minane",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905021",
      "brgy_name": "Panalicsian (Panalicsican)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905022",
      "brgy_name": "Pando",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905024",
      "brgy_name": "Parang",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905025",
      "brgy_name": "Parulung",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905026",
      "brgy_name": "Pitabunan",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905027",
      "brgy_name": "San Agustin (Murcia)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905028",
      "brgy_name": "San Antonio",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905029",
      "brgy_name": "San Bartolome",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905030",
      "brgy_name": "San Francisco",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905031",
      "brgy_name": "San Isidro (Almendras)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905032",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905033",
      "brgy_name": "San Juan (Castro)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905034",
      "brgy_name": "San Martin",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905035",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905036",
      "brgy_name": "San Nicolas Balas",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905037",
      "brgy_name": "Santo Niño",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905040",
      "brgy_name": "Santa Cruz",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905041",
      "brgy_name": "Santa Maria",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905042",
      "brgy_name": "Santa Monica",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905043",
      "brgy_name": "Santa Rita",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905044",
      "brgy_name": "Santa Rosa",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905045",
      "brgy_name": "Santiago",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905046",
      "brgy_name": "Santo Cristo",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905047",
      "brgy_name": "Santo Rosario (Magunting)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905049",
      "brgy_name": "Talimunduc Marimla",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905050",
      "brgy_name": "Talimunduc San Miguel",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905051",
      "brgy_name": "Telabanca",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905053",
      "brgy_name": "Tinang",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905054",
      "brgy_name": "San Vicente (Caluis/Cobra)",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036905055",
      "brgy_name": "Green Village",
      "city_code": "036905",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906001",
      "brgy_name": "Abagon",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906002",
      "brgy_name": "Amacalan",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906003",
      "brgy_name": "Apsayan",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906005",
      "brgy_name": "Ayson",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906006",
      "brgy_name": "Bawa",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906007",
      "brgy_name": "Buenlag",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906008",
      "brgy_name": "Bularit",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906009",
      "brgy_name": "Calayaan",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906010",
      "brgy_name": "Carbonel",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906011",
      "brgy_name": "Cardona",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906012",
      "brgy_name": "Caturay",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906013",
      "brgy_name": "Danzo",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906014",
      "brgy_name": "Dicolor",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906015",
      "brgy_name": "Don Basilio",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906016",
      "brgy_name": "Luna",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906017",
      "brgy_name": "Mabini",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906018",
      "brgy_name": "Magaspac",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906019",
      "brgy_name": "Malayep",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906020",
      "brgy_name": "Matapitap",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906021",
      "brgy_name": "Matayumcab",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906022",
      "brgy_name": "New Salem",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906024",
      "brgy_name": "Oloybuaya",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906025",
      "brgy_name": "Padapada",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906026",
      "brgy_name": "Parsolingan",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906027",
      "brgy_name": "Pinasling (Pinasung)",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906028",
      "brgy_name": "Plastado",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906029",
      "brgy_name": "Poblacion 1",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906030",
      "brgy_name": "Poblacion 2",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906031",
      "brgy_name": "Poblacion 3",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906032",
      "brgy_name": "Quezon",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906033",
      "brgy_name": "Rizal",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906034",
      "brgy_name": "Salapungan",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906035",
      "brgy_name": "San Agustin",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906036",
      "brgy_name": "San Antonio",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906037",
      "brgy_name": "San Bartolome",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906038",
      "brgy_name": "San Jose",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906039",
      "brgy_name": "Santa Lucia",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906040",
      "brgy_name": "Santiago",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906041",
      "brgy_name": "Sembrano",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906043",
      "brgy_name": "Singat",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906044",
      "brgy_name": "Sulipa",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906045",
      "brgy_name": "Tagumbao",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906047",
      "brgy_name": "Tangcaran",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036906048",
      "brgy_name": "Villa Paz",
      "city_code": "036906",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907001",
      "brgy_name": "Balanoy",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907002",
      "brgy_name": "Bantog-Caricutan",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907003",
      "brgy_name": "Caramutan",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907004",
      "brgy_name": "Caut",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907005",
      "brgy_name": "Comillas",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907006",
      "brgy_name": "Dumarais",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907007",
      "brgy_name": "Guevarra",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907008",
      "brgy_name": "Kapanikian",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907009",
      "brgy_name": "La Purisima",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907010",
      "brgy_name": "Lara",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907011",
      "brgy_name": "Laungcupang",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907012",
      "brgy_name": "Lomboy",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907013",
      "brgy_name": "Macalong",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907014",
      "brgy_name": "Matayumtayum",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907015",
      "brgy_name": "Mayang",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907016",
      "brgy_name": "Motrico",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907017",
      "brgy_name": "Paludpud",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907018",
      "brgy_name": "Rizal",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907019",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907020",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036907021",
      "brgy_name": "Sierra",
      "city_code": "036907",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908001",
      "brgy_name": "Ambalingit",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908002",
      "brgy_name": "Baybayaoas",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908003",
      "brgy_name": "Bigbiga",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908004",
      "brgy_name": "Binbinaca",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908005",
      "brgy_name": "Calabtangan",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908006",
      "brgy_name": "Caocaoayan",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908007",
      "brgy_name": "Carabaoan",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908008",
      "brgy_name": "Cubcub",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908009",
      "brgy_name": "Gayonggayong",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908010",
      "brgy_name": "Gossood",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908011",
      "brgy_name": "Labney",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908012",
      "brgy_name": "Mamonit",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908013",
      "brgy_name": "Maniniog",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908014",
      "brgy_name": "Mapandan",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908015",
      "brgy_name": "Nambalan",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908016",
      "brgy_name": "Pedro L. Quines",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908017",
      "brgy_name": "Pitombayog",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908018",
      "brgy_name": "Poblacion Norte",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908019",
      "brgy_name": "Poblacion Sur",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908020",
      "brgy_name": "Rotrottooc",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908021",
      "brgy_name": "San Bartolome",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908022",
      "brgy_name": "San Jose",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908023",
      "brgy_name": "Taldiapan",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036908024",
      "brgy_name": "Tangcarang",
      "city_code": "036908",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909001",
      "brgy_name": "Ablang-Sapang",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909002",
      "brgy_name": "Aringin",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909003",
      "brgy_name": "Atencio",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909004",
      "brgy_name": "Banaoang East",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909005",
      "brgy_name": "Banaoang West",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909006",
      "brgy_name": "Baquero Norte",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909007",
      "brgy_name": "Baquero Sur",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909008",
      "brgy_name": "Burgos",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909009",
      "brgy_name": "Calamay",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909010",
      "brgy_name": "Calapan",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909011",
      "brgy_name": "Camangaan East",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909012",
      "brgy_name": "Camangaan West",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909013",
      "brgy_name": "Camposanto 1 - Norte",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909014",
      "brgy_name": "Camposanto 1 - Sur",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909015",
      "brgy_name": "Camposanto 2",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909016",
      "brgy_name": "Capaoayan",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909017",
      "brgy_name": "Lapsing",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909018",
      "brgy_name": "Mabini",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909019",
      "brgy_name": "Maluac",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909020",
      "brgy_name": "Poblacion 1",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909021",
      "brgy_name": "Poblacion 2",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909022",
      "brgy_name": "Poblacion 3",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909023",
      "brgy_name": "Poblacion 4",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909024",
      "brgy_name": "Rizal",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909025",
      "brgy_name": "San Juan",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909026",
      "brgy_name": "San Julian",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909027",
      "brgy_name": "San Leon",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909028",
      "brgy_name": "San Pedro",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909029",
      "brgy_name": "San Roque",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909030",
      "brgy_name": "Santa Lucia East",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909031",
      "brgy_name": "Santa Lucia West",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909032",
      "brgy_name": "Santa Maria",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909033",
      "brgy_name": "Santa Monica",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909034",
      "brgy_name": "Tubectubang",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909035",
      "brgy_name": "Tolega Norte",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909036",
      "brgy_name": "Tolega Sur",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036909038",
      "brgy_name": "Villa",
      "city_code": "036909",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910001",
      "brgy_name": "Abogado",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910002",
      "brgy_name": "Acocolao",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910003",
      "brgy_name": "Aduas",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910004",
      "brgy_name": "Apulid",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910005",
      "brgy_name": "Balaoang",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910006",
      "brgy_name": "Barang (Borang)",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910007",
      "brgy_name": "Brillante",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910008",
      "brgy_name": "Burgos",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910009",
      "brgy_name": "Cabayaoasan",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910010",
      "brgy_name": "Canan",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910011",
      "brgy_name": "Carino",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910012",
      "brgy_name": "Cayanga",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910013",
      "brgy_name": "Colibangbang",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910014",
      "brgy_name": "Coral",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910015",
      "brgy_name": "Dapdap",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910016",
      "brgy_name": "Estacion",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910017",
      "brgy_name": "Mabilang",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910018",
      "brgy_name": "Manaois",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910019",
      "brgy_name": "Matalapitap",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910020",
      "brgy_name": "Nagmisaan",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910021",
      "brgy_name": "Nancamarinan",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910022",
      "brgy_name": "Nipaco",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910023",
      "brgy_name": "Patalan",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910024",
      "brgy_name": "Poblacion Norte",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910025",
      "brgy_name": "Poblacion Sur",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910026",
      "brgy_name": "Rang-ayan",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910027",
      "brgy_name": "Salumague",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910028",
      "brgy_name": "Samput",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910029",
      "brgy_name": "San Carlos",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910030",
      "brgy_name": "San Isidro",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910031",
      "brgy_name": "San Juan de Milla",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910032",
      "brgy_name": "Santa Ines",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910033",
      "brgy_name": "Sinigpit",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910034",
      "brgy_name": "Tablang",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036910035",
      "brgy_name": "Ventenilla",
      "city_code": "036910",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911001",
      "brgy_name": "Balite",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911002",
      "brgy_name": "Buenavista",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911003",
      "brgy_name": "Cadanglaan",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911004",
      "brgy_name": "Estipona",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911005",
      "brgy_name": "Linao",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911006",
      "brgy_name": "Maasin",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911007",
      "brgy_name": "Matindeg",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911008",
      "brgy_name": "Maungib",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911009",
      "brgy_name": "Naya",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911010",
      "brgy_name": "Nilasin 1st",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911011",
      "brgy_name": "Nilasin 2nd",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911012",
      "brgy_name": "Poblacion 1",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911013",
      "brgy_name": "Poblacion 2",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911014",
      "brgy_name": "Poblacion 3",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911015",
      "brgy_name": "Poroc",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036911018",
      "brgy_name": "Singat",
      "city_code": "036911",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912001",
      "brgy_name": "Coral-Iloco",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912002",
      "brgy_name": "Guiteb",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912003",
      "brgy_name": "Pance",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912004",
      "brgy_name": "Poblacion Center",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912005",
      "brgy_name": "Poblacion North",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912006",
      "brgy_name": "Poblacion South",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912007",
      "brgy_name": "San Juan",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912008",
      "brgy_name": "San Raymundo",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036912009",
      "brgy_name": "Toledo",
      "city_code": "036912",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913001",
      "brgy_name": "Balloc",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913002",
      "brgy_name": "Bamban",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913004",
      "brgy_name": "Casipo",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913005",
      "brgy_name": "Catagudingan",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913006",
      "brgy_name": "Daldalayap",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913007",
      "brgy_name": "Doclong 1",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913008",
      "brgy_name": "Doclong 2",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913010",
      "brgy_name": "Maasin",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913011",
      "brgy_name": "Nagsabaran",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913013",
      "brgy_name": "Pit-ao",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913014",
      "brgy_name": "Poblacion Norte",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036913015",
      "brgy_name": "Poblacion Sur",
      "city_code": "036913",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914001",
      "brgy_name": "Colubot",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914002",
      "brgy_name": "Lanat",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914003",
      "brgy_name": "Legaspi",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914004",
      "brgy_name": "Mangandingay",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914005",
      "brgy_name": "Matarannoc",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914006",
      "brgy_name": "Pacpaco",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914007",
      "brgy_name": "Poblacion",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914008",
      "brgy_name": "Salcedo",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914009",
      "brgy_name": "San Agustin",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914010",
      "brgy_name": "San Felipe",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914011",
      "brgy_name": "San Jacinto",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914012",
      "brgy_name": "San Miguel",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914013",
      "brgy_name": "San Narciso",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914014",
      "brgy_name": "San Vicente",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036914015",
      "brgy_name": "Santa Maria",
      "city_code": "036914",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915001",
      "brgy_name": "Baldios",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915002",
      "brgy_name": "Botbotones",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915003",
      "brgy_name": "Caanamongan",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915004",
      "brgy_name": "Cabaruan",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915005",
      "brgy_name": "Cabugbugan",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915006",
      "brgy_name": "Caduldulaoan",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915007",
      "brgy_name": "Calipayan",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915008",
      "brgy_name": "Macaguing",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915009",
      "brgy_name": "Nambalan",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915010",
      "brgy_name": "Padapada",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915011",
      "brgy_name": "Pilpila",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915012",
      "brgy_name": "Pinpinas",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915013",
      "brgy_name": "Poblacion East",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915014",
      "brgy_name": "Poblacion West",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915015",
      "brgy_name": "Pugo-Cecilio",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915016",
      "brgy_name": "San Francisco",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915017",
      "brgy_name": "San Sotero",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915018",
      "brgy_name": "San Vicente",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915019",
      "brgy_name": "Santa Ines Centro",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915020",
      "brgy_name": "Santa Ines East",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915021",
      "brgy_name": "Santa Ines West",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915022",
      "brgy_name": "Taguiporo",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915023",
      "brgy_name": "Timmaguab",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036915024",
      "brgy_name": "Vargas",
      "city_code": "036915",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916001",
      "brgy_name": "Aguso",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916003",
      "brgy_name": "Alvindia Segundo",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916004",
      "brgy_name": "Amucao",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916005",
      "brgy_name": "Armenia",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916006",
      "brgy_name": "Asturias",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916007",
      "brgy_name": "Atioc",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916008",
      "brgy_name": "Balanti",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916009",
      "brgy_name": "Balete",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916010",
      "brgy_name": "Balibago I",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916011",
      "brgy_name": "Balibago II",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916012",
      "brgy_name": "Balingcanaway",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916013",
      "brgy_name": "Banaba",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916014",
      "brgy_name": "Bantog",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916015",
      "brgy_name": "Baras-baras",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916016",
      "brgy_name": "Batang-batang",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916018",
      "brgy_name": "Binauganan",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916019",
      "brgy_name": "Bora",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916020",
      "brgy_name": "Buenavista",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916021",
      "brgy_name": "Buhilit (Bubulit)",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916023",
      "brgy_name": "Burot",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916024",
      "brgy_name": "Calingcuan",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916025",
      "brgy_name": "Capehan",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916026",
      "brgy_name": "Carangian",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916027",
      "brgy_name": "Central",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916028",
      "brgy_name": "Culipat",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916029",
      "brgy_name": "Cut-cut I",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916030",
      "brgy_name": "Cut-cut II",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916031",
      "brgy_name": "Dalayap",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916034",
      "brgy_name": "Dela Paz",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916035",
      "brgy_name": "Dolores",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916039",
      "brgy_name": "Laoang",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916041",
      "brgy_name": "Ligtasan",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916042",
      "brgy_name": "Lourdes",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916046",
      "brgy_name": "Mabini",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916047",
      "brgy_name": "Maligaya",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916048",
      "brgy_name": "Maliwalo",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916050",
      "brgy_name": "Mapalacsiao",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916051",
      "brgy_name": "Mapalad",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916052",
      "brgy_name": "Matatalaib",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916056",
      "brgy_name": "Paraiso",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916057",
      "brgy_name": "Poblacion",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916058",
      "brgy_name": "San Carlos",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916059",
      "brgy_name": "San Francisco",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916060",
      "brgy_name": "San Isidro",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916061",
      "brgy_name": "San Jose",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916062",
      "brgy_name": "San Jose de Urquico",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916064",
      "brgy_name": "San Juan de Mata",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916066",
      "brgy_name": "San Luis",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916067",
      "brgy_name": "San Manuel",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916068",
      "brgy_name": "San Miguel",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916069",
      "brgy_name": "San Nicolas",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916070",
      "brgy_name": "San Pablo",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916071",
      "brgy_name": "San Pascual",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916072",
      "brgy_name": "San Rafael",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916073",
      "brgy_name": "San Roque",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916074",
      "brgy_name": "San Sebastian",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916075",
      "brgy_name": "San Vicente",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916076",
      "brgy_name": "Santa Cruz (Alvindia Primero)",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916077",
      "brgy_name": "Santa Maria",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916078",
      "brgy_name": "Santo Cristo",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916079",
      "brgy_name": "Santo Domingo",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916080",
      "brgy_name": "Santo Niño",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916081",
      "brgy_name": "Sapang Maragul",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916082",
      "brgy_name": "Sapang Tagalog",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916083",
      "brgy_name": "Sepung Calzada",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916084",
      "brgy_name": "Sinait",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916085",
      "brgy_name": "Suizo",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916087",
      "brgy_name": "Tariji",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916088",
      "brgy_name": "Tibag",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916089",
      "brgy_name": "Tibagan",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916091",
      "brgy_name": "Trinidad (Trinidad Primero)",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916093",
      "brgy_name": "Ungot",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916096",
      "brgy_name": "Matadero",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916097",
      "brgy_name": "Salapungan",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916098",
      "brgy_name": "Villa Bacolor",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036916099",
      "brgy_name": "Care",
      "city_code": "036916",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917001",
      "brgy_name": "Baculong",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917002",
      "brgy_name": "Balayang",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917003",
      "brgy_name": "Balbaloto",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917004",
      "brgy_name": "Bangar",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917005",
      "brgy_name": "Bantog",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917006",
      "brgy_name": "Batangbatang",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917007",
      "brgy_name": "Bulo",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917008",
      "brgy_name": "Cabuluan",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917009",
      "brgy_name": "Calibungan",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917010",
      "brgy_name": "Canarem",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917011",
      "brgy_name": "Cruz",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917012",
      "brgy_name": "Lalapac",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917013",
      "brgy_name": "Maluid",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917014",
      "brgy_name": "Mangolago",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917015",
      "brgy_name": "Masalasa",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917016",
      "brgy_name": "Palacpalac",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917017",
      "brgy_name": "San Agustin",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917018",
      "brgy_name": "San Andres",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917019",
      "brgy_name": "San Fernando (Pob.)",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917020",
      "brgy_name": "San Francisco",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917021",
      "brgy_name": "San Gavino (Pob.)",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917023",
      "brgy_name": "San Jacinto",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917024",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917025",
      "brgy_name": "San Vicente",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917026",
      "brgy_name": "Santa Barbara",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036917027",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "036917",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918001",
      "brgy_name": "Burgos",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918002",
      "brgy_name": "David",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918003",
      "brgy_name": "Iba",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918004",
      "brgy_name": "Labney",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918005",
      "brgy_name": "Lawacamulag",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918006",
      "brgy_name": "Lubigan",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918007",
      "brgy_name": "Maamot",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918008",
      "brgy_name": "Mababanaba",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918009",
      "brgy_name": "Moriones",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918010",
      "brgy_name": "Pao",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918011",
      "brgy_name": "San Juan de Valdez",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918012",
      "brgy_name": "Sula",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "036918013",
      "brgy_name": "Villa Aglipay",
      "city_code": "036918",
      "province_code": "0369",
      "region_code": "03"
    },
    {
      "brgy_code": "037101001",
      "brgy_name": "Bangan",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101003",
      "brgy_name": "Batonlapoc",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101004",
      "brgy_name": "Beneg",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101005",
      "brgy_name": "Capayawan",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101006",
      "brgy_name": "Carael",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101007",
      "brgy_name": "Danacbunga",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101008",
      "brgy_name": "Maguisguis",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101009",
      "brgy_name": "Mambog",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101010",
      "brgy_name": "Moraza",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101011",
      "brgy_name": "Paco (Pob.)",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101013",
      "brgy_name": "Panan",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101014",
      "brgy_name": "Parel",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101015",
      "brgy_name": "Paudpod",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101016",
      "brgy_name": "Poonbato",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101017",
      "brgy_name": "Porac",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101018",
      "brgy_name": "San Isidro",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101019",
      "brgy_name": "San Juan",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101020",
      "brgy_name": "San Miguel",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101021",
      "brgy_name": "Santiago",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101022",
      "brgy_name": "Tampo (Pob.)",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101023",
      "brgy_name": "Taugtog",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101024",
      "brgy_name": "Villar",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101025",
      "brgy_name": "Bancal",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101026",
      "brgy_name": "Belbel",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101027",
      "brgy_name": "Binuclutan",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101028",
      "brgy_name": "Burgos",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101029",
      "brgy_name": "Cabatuan",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101030",
      "brgy_name": "Malomboy",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101031",
      "brgy_name": "Nacolcol",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101032",
      "brgy_name": "Owaog-Nibloc",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037101033",
      "brgy_name": "Palis",
      "city_code": "037101",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102001",
      "brgy_name": "Anonang",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102002",
      "brgy_name": "Apo-apo",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102004",
      "brgy_name": "Arew",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102005",
      "brgy_name": "Banuambayo (Pob.)",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102006",
      "brgy_name": "Cadmang-Reserva",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102008",
      "brgy_name": "Camiling (Camiing)",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102009",
      "brgy_name": "Casabaan",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102010",
      "brgy_name": "Dolores (Pob.)",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102011",
      "brgy_name": "Del Carmen (Pob.)",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102012",
      "brgy_name": "Laoag",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102013",
      "brgy_name": "Lomboy",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102014",
      "brgy_name": "Longos",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102016",
      "brgy_name": "Mabanglit",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102017",
      "brgy_name": "New San Juan",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102018",
      "brgy_name": "San Antonio",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102019",
      "brgy_name": "San Isidro",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102020",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102021",
      "brgy_name": "San Rafael",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102023",
      "brgy_name": "Santa Rita",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102024",
      "brgy_name": "Santo Niño",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102025",
      "brgy_name": "Tondo",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037102026",
      "brgy_name": "Felmida-Diaz",
      "city_code": "037102",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103001",
      "brgy_name": "Babancal",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103002",
      "brgy_name": "Binabalian",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103003",
      "brgy_name": "Catol",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103004",
      "brgy_name": "Dampay",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103005",
      "brgy_name": "Lauis",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103006",
      "brgy_name": "Libertador",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103007",
      "brgy_name": "Malabon (San Roque)",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103008",
      "brgy_name": "Malimanga",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103011",
      "brgy_name": "Pamibian",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103012",
      "brgy_name": "Panayonan",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103013",
      "brgy_name": "Pinagrealan",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103015",
      "brgy_name": "Poblacion",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103016",
      "brgy_name": "Sinabacan",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103017",
      "brgy_name": "Taposo",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103018",
      "brgy_name": "Uacon",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037103019",
      "brgy_name": "Yamot",
      "city_code": "037103",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104001",
      "brgy_name": "Balaybay",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104002",
      "brgy_name": "Buenavista",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104003",
      "brgy_name": "Del Pilar",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104004",
      "brgy_name": "Looc",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104005",
      "brgy_name": "Magsaysay",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104006",
      "brgy_name": "Nagbayan",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104007",
      "brgy_name": "Nagbunga",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104008",
      "brgy_name": "San Agustin",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104010",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104011",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104012",
      "brgy_name": "San Nicolas",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104013",
      "brgy_name": "San Pablo (Pob.)",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104014",
      "brgy_name": "San Roque",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037104015",
      "brgy_name": "Santa Maria",
      "city_code": "037104",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105001",
      "brgy_name": "Amungan",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105002",
      "brgy_name": "Zone 2 Pob. (Aypa)",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105003",
      "brgy_name": "Zone 5 Pob. (Bano)",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105004",
      "brgy_name": "Bangantalinga",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105005",
      "brgy_name": "Zone 6 Pob. (Baytan)",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105007",
      "brgy_name": "Zone 3 Pob. (Botlay)",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105008",
      "brgy_name": "Dirita-Baloguen",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105009",
      "brgy_name": "Zone 1 Pob. (Libaba)",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105010",
      "brgy_name": "Lipay-Dingin-Panibuatan",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105012",
      "brgy_name": "Palanginan (Palanguinan-Tambak)",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105015",
      "brgy_name": "Zone 4 Pob. (Sagapan)",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105016",
      "brgy_name": "San Agustin",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105017",
      "brgy_name": "Santa Barbara",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037105018",
      "brgy_name": "Santo Rosario",
      "city_code": "037105",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106001",
      "brgy_name": "Baloganon",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106002",
      "brgy_name": "Bamban",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106003",
      "brgy_name": "Bani",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106004",
      "brgy_name": "Collat",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106005",
      "brgy_name": "Inhobol",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106006",
      "brgy_name": "North Poblacion",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106007",
      "brgy_name": "San Lorenzo",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106008",
      "brgy_name": "San Salvador",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106009",
      "brgy_name": "Santa Rita",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106010",
      "brgy_name": "Santo Rosario",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106011",
      "brgy_name": "South Poblacion",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106012",
      "brgy_name": "Taltal",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037106013",
      "brgy_name": "Tapuac",
      "city_code": "037106",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107001",
      "brgy_name": "Asinan",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107002",
      "brgy_name": "Banicain",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107003",
      "brgy_name": "Barreto",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107005",
      "brgy_name": "East Bajac-bajac",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107006",
      "brgy_name": "East Tapinac",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107008",
      "brgy_name": "Gordon Heights",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107011",
      "brgy_name": "Kalaklan",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107012",
      "brgy_name": "New Kalalake",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107013",
      "brgy_name": "Mabayuan",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107014",
      "brgy_name": "New Cabalan",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107015",
      "brgy_name": "New Ilalim",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107016",
      "brgy_name": "New Kababae",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107017",
      "brgy_name": "Pag-asa",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107018",
      "brgy_name": "Santa Rita",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107019",
      "brgy_name": "West Bajac-bajac",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107020",
      "brgy_name": "West Tapinac",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037107021",
      "brgy_name": "Old Cabalan",
      "city_code": "037107",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108001",
      "brgy_name": "Alwa",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108002",
      "brgy_name": "Bato",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108003",
      "brgy_name": "Bulawen",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108004",
      "brgy_name": "Cauyan",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108005",
      "brgy_name": "Garreta",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108006",
      "brgy_name": "Libaba",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108007",
      "brgy_name": "Liozon",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108008",
      "brgy_name": "Lipay",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108009",
      "brgy_name": "Locloc",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108010",
      "brgy_name": "Macarang",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108011",
      "brgy_name": "Magalawa",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108012",
      "brgy_name": "Pangolingan",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108013",
      "brgy_name": "East Poblacion",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108015",
      "brgy_name": "West Poblacion",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108016",
      "brgy_name": "Salaza",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108017",
      "brgy_name": "San Juan",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108018",
      "brgy_name": "Santo Niño",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108019",
      "brgy_name": "Santo Tomas",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037108020",
      "brgy_name": "Tition (San Vicente)",
      "city_code": "037108",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109001",
      "brgy_name": "Angeles",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109002",
      "brgy_name": "Antipolo (Pob.)",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109003",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109004",
      "brgy_name": "West Dirita",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109005",
      "brgy_name": "East Dirita",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109006",
      "brgy_name": "Luna (Pob.)",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109007",
      "brgy_name": "Pundaquit",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109008",
      "brgy_name": "San Esteban",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109009",
      "brgy_name": "San Gregorio (Pob.)",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109010",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109011",
      "brgy_name": "San Miguel",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109012",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109013",
      "brgy_name": "Santiago",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037109014",
      "brgy_name": "Rizal",
      "city_code": "037109",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110001",
      "brgy_name": "Amagna (Pob.)",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110002",
      "brgy_name": "Apostol (Pob.)",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110003",
      "brgy_name": "Balincaguing",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110004",
      "brgy_name": "Farañal (Pob.)",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110005",
      "brgy_name": "Feria (Pob.)",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110006",
      "brgy_name": "Maloma",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110007",
      "brgy_name": "Manglicmot (Pob.)",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110008",
      "brgy_name": "Rosete (Pob.)",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110009",
      "brgy_name": "San Rafael",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110011",
      "brgy_name": "Santo Niño",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037110012",
      "brgy_name": "Sindol",
      "city_code": "037110",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111001",
      "brgy_name": "Aglao",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111002",
      "brgy_name": "Buhawen",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111003",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111004",
      "brgy_name": "Consuelo Norte",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111005",
      "brgy_name": "Consuelo Sur (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111006",
      "brgy_name": "La Paz (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111007",
      "brgy_name": "Laoag",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111008",
      "brgy_name": "Linasin",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111010",
      "brgy_name": "Linusungan",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111011",
      "brgy_name": "Lucero (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111012",
      "brgy_name": "Nagbunga",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111014",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111015",
      "brgy_name": "San Guillermo (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111016",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111017",
      "brgy_name": "San Rafael",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111018",
      "brgy_name": "Santa Fe",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111019",
      "brgy_name": "Central (Pob.)",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037111020",
      "brgy_name": "Rabanes",
      "city_code": "037111",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112001",
      "brgy_name": "Alusiis",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112002",
      "brgy_name": "Beddeng",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112003",
      "brgy_name": "Candelaria (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112004",
      "brgy_name": "Dallipawen",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112005",
      "brgy_name": "Grullo",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112006",
      "brgy_name": "La Paz",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112007",
      "brgy_name": "Libertad (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112008",
      "brgy_name": "Namatacan",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112009",
      "brgy_name": "Natividad (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112010",
      "brgy_name": "Paite",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112011",
      "brgy_name": "Patrocinio (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112012",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112013",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112014",
      "brgy_name": "San Pascual (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112015",
      "brgy_name": "San Rafael (Pob.)",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112016",
      "brgy_name": "Siminublan",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037112017",
      "brgy_name": "Omaya",
      "city_code": "037112",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113001",
      "brgy_name": "Babuyan",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113002",
      "brgy_name": "Bolitoc",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113003",
      "brgy_name": "Bangcol",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113004",
      "brgy_name": "Bayto",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113005",
      "brgy_name": "Biay",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113007",
      "brgy_name": "Canaynayan",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113008",
      "brgy_name": "Gama",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113009",
      "brgy_name": "Guinabon",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113010",
      "brgy_name": "Guisguis",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113011",
      "brgy_name": "Lipay",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113012",
      "brgy_name": "Lomboy",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113013",
      "brgy_name": "Lucapon North",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113014",
      "brgy_name": "Lucapon South",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113015",
      "brgy_name": "Malabago",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113016",
      "brgy_name": "Naulo",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113017",
      "brgy_name": "Poblacion North",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113018",
      "brgy_name": "Pagatpat",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113019",
      "brgy_name": "Pamonoran",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113022",
      "brgy_name": "Sabang",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113023",
      "brgy_name": "San Fernando",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113024",
      "brgy_name": "Poblacion South",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113025",
      "brgy_name": "Tabalong",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113028",
      "brgy_name": "Tubotubo North",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113029",
      "brgy_name": "Tubotubo South",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037113030",
      "brgy_name": "Bulawon",
      "city_code": "037113",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114001",
      "brgy_name": "Aningway Sacatihan",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114002",
      "brgy_name": "Asinan Poblacion",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114003",
      "brgy_name": "Asinan Proper",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114004",
      "brgy_name": "Baraca-Camachile (Pob.)",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114005",
      "brgy_name": "Batiawan",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114008",
      "brgy_name": "Calapacuan",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114009",
      "brgy_name": "Calapandayan (Pob.)",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114011",
      "brgy_name": "Cawag",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114013",
      "brgy_name": "Ilwas (Pob.)",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114014",
      "brgy_name": "Mangan-Vaca",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114016",
      "brgy_name": "Matain",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114017",
      "brgy_name": "Naugsol",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114019",
      "brgy_name": "Pamatawan",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114021",
      "brgy_name": "San Isidro",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114022",
      "brgy_name": "Santo Tomas",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037114023",
      "brgy_name": "Wawandue (Pob.)",
      "city_code": "037114",
      "province_code": "0371",
      "region_code": "03"
    },
    {
      "brgy_code": "037701001",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701002",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701003",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701004",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701005",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701006",
      "brgy_name": "Buhangin",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701007",
      "brgy_name": "Calabuanan",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701008",
      "brgy_name": "Obligacion",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701009",
      "brgy_name": "Pingit",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701010",
      "brgy_name": "Reserva",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701011",
      "brgy_name": "Sabang",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701012",
      "brgy_name": "Suclayin",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037701013",
      "brgy_name": "Zabali",
      "city_code": "037701",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702001",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702002",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702003",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702004",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702005",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702006",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702007",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702008",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702009",
      "brgy_name": "Calabgan",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702010",
      "brgy_name": "Calangcuasan",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702011",
      "brgy_name": "Calantas",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702012",
      "brgy_name": "Culat",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702013",
      "brgy_name": "Dibet",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702014",
      "brgy_name": "Esperanza",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702015",
      "brgy_name": "Lual",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702016",
      "brgy_name": "Marikit",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702017",
      "brgy_name": "Tabas",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702018",
      "brgy_name": "Tinib",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702019",
      "brgy_name": "Bianuan",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702020",
      "brgy_name": "Cozo",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702021",
      "brgy_name": "Dibacong",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702022",
      "brgy_name": "Ditinagyan",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702023",
      "brgy_name": "Esteves",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037702024",
      "brgy_name": "San Ildefonso",
      "city_code": "037702",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703001",
      "brgy_name": "Diagyan",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703002",
      "brgy_name": "Dicabasan",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703003",
      "brgy_name": "Dilaguidi",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703004",
      "brgy_name": "Dimaseset",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703005",
      "brgy_name": "Diniog",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703006",
      "brgy_name": "Lawang",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703007",
      "brgy_name": "Maligaya (Pob.)",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703008",
      "brgy_name": "Manggitahan",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703009",
      "brgy_name": "Masagana (Pob.)",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703010",
      "brgy_name": "Ura",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037703011",
      "brgy_name": "Esperanza",
      "city_code": "037703",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704001",
      "brgy_name": "Abuleg",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704002",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704003",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704004",
      "brgy_name": "Nipoo (Bulo)",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704005",
      "brgy_name": "Dibaraybay",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704006",
      "brgy_name": "Ditawini",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704007",
      "brgy_name": "Mapalad",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704008",
      "brgy_name": "Paleg",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037704009",
      "brgy_name": "Simbahan",
      "city_code": "037704",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705001",
      "brgy_name": "Aplaya",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705002",
      "brgy_name": "Butas Na Bato",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705003",
      "brgy_name": "Cabog (Matawe)",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705004",
      "brgy_name": "Caragsacan",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705005",
      "brgy_name": "Davildavilan",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705006",
      "brgy_name": "Dikapanikian",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705007",
      "brgy_name": "Ibona",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705009",
      "brgy_name": "Paltic",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705010",
      "brgy_name": "Poblacion",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705011",
      "brgy_name": "Tanawan",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037705013",
      "brgy_name": "Umiray (Malamig)",
      "city_code": "037705",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706001",
      "brgy_name": "Bayabas",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706002",
      "brgy_name": "Buenavista",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706003",
      "brgy_name": "Borlongan",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706004",
      "brgy_name": "Calaocan",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706005",
      "brgy_name": "Dianed",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706006",
      "brgy_name": "Diarabasin",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706007",
      "brgy_name": "Dibutunan",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706008",
      "brgy_name": "Dimabuno",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706009",
      "brgy_name": "Dinadiawan",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706010",
      "brgy_name": "Ditale",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706011",
      "brgy_name": "Gupa",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706012",
      "brgy_name": "Ipil",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706013",
      "brgy_name": "Laboy",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706014",
      "brgy_name": "Lipit",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706015",
      "brgy_name": "Lobbot",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706016",
      "brgy_name": "Maligaya",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706017",
      "brgy_name": "Mijares",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706018",
      "brgy_name": "Mucdol",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706019",
      "brgy_name": "North Poblacion",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706020",
      "brgy_name": "Puangi",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706021",
      "brgy_name": "Salay",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706022",
      "brgy_name": "Sapangkawayan",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706023",
      "brgy_name": "South Poblacion",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706024",
      "brgy_name": "Toytoyan",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037706025",
      "brgy_name": "Diamanen",
      "city_code": "037706",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707001",
      "brgy_name": "Alcala",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707002",
      "brgy_name": "Bagtu",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707003",
      "brgy_name": "Bangco",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707004",
      "brgy_name": "Bannawag",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707005",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707006",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707007",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707008",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707009",
      "brgy_name": "Baubo",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707010",
      "brgy_name": "Bayanihan",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707011",
      "brgy_name": "Bazal",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707012",
      "brgy_name": "Cabituculan East",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707013",
      "brgy_name": "Cabituculan West",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707014",
      "brgy_name": "Debucao",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707015",
      "brgy_name": "Decoliat",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707016",
      "brgy_name": "Detailen",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707017",
      "brgy_name": "Diaat",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707018",
      "brgy_name": "Dialatman",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707019",
      "brgy_name": "Diaman",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707020",
      "brgy_name": "Dianawan",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707021",
      "brgy_name": "Dikildit",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707022",
      "brgy_name": "Dimanpudso",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707023",
      "brgy_name": "Diome",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707024",
      "brgy_name": "Estonilo",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707025",
      "brgy_name": "Florida",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707026",
      "brgy_name": "Galintuja",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707027",
      "brgy_name": "Cadayacan",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707028",
      "brgy_name": "Malasin",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707029",
      "brgy_name": "Ponglo",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707030",
      "brgy_name": "Quirino",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707031",
      "brgy_name": "Ramada",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707032",
      "brgy_name": "San Joaquin",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707033",
      "brgy_name": "San Jose",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707034",
      "brgy_name": "San Leonardo",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707035",
      "brgy_name": "Santa Lucia",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707036",
      "brgy_name": "Santo Tomas",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707037",
      "brgy_name": "Suguit",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707038",
      "brgy_name": "Villa Aurora",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707039",
      "brgy_name": "Wenceslao",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037707040",
      "brgy_name": "San Juan",
      "city_code": "037707",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708001",
      "brgy_name": "Bacong",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708003",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708004",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708005",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708006",
      "brgy_name": "Dibalo",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708007",
      "brgy_name": "Dibayabay",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708008",
      "brgy_name": "Dibut",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708009",
      "brgy_name": "Dikapinisan",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708010",
      "brgy_name": "Dimanayat",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708011",
      "brgy_name": "Diteki",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708012",
      "brgy_name": "Ditumabo",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708013",
      "brgy_name": "L. Pimentel",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708014",
      "brgy_name": "Nonong Senior",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708015",
      "brgy_name": "Real",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708016",
      "brgy_name": "San Isidro",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708017",
      "brgy_name": "San Jose",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "037708018",
      "brgy_name": "Zarah",
      "city_code": "037708",
      "province_code": "0377",
      "region_code": "03"
    },
    {
      "brgy_code": "041001001",
      "brgy_name": "Adia",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001002",
      "brgy_name": "Bagong Sikat",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001004",
      "brgy_name": "Balangon",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001005",
      "brgy_name": "Bilibinwang",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001006",
      "brgy_name": "Bangin",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001007",
      "brgy_name": "Barigon",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001008",
      "brgy_name": "Coral Na Munti",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001009",
      "brgy_name": "Guitna",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001010",
      "brgy_name": "Mabini",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001012",
      "brgy_name": "Pamiga",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001013",
      "brgy_name": "Panhulan",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001014",
      "brgy_name": "Pansipit",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001015",
      "brgy_name": "Poblacion",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001016",
      "brgy_name": "Pook",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001017",
      "brgy_name": "San Jacinto",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001018",
      "brgy_name": "San Teodoro",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001019",
      "brgy_name": "Santa Cruz",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001020",
      "brgy_name": "Santo Tomas",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001021",
      "brgy_name": "Subic Ibaba",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001022",
      "brgy_name": "Subic Ilaya",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041001023",
      "brgy_name": "Banyaga",
      "city_code": "041001",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002001",
      "brgy_name": "Balagbag",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002002",
      "brgy_name": "Concepcion",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002003",
      "brgy_name": "Concordia",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002004",
      "brgy_name": "Dalipit East",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002005",
      "brgy_name": "Dalipit West",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002006",
      "brgy_name": "Dominador East",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002007",
      "brgy_name": "Dominador West",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002008",
      "brgy_name": "Munlawin Sur",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002009",
      "brgy_name": "Munlawin Norte",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002011",
      "brgy_name": "Muzon Primero",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002012",
      "brgy_name": "Muzon Segundo",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002013",
      "brgy_name": "Pinagkurusan",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002014",
      "brgy_name": "Ping-As",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002015",
      "brgy_name": "Poblacion East",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002016",
      "brgy_name": "Poblacion West",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002018",
      "brgy_name": "San Jose",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002019",
      "brgy_name": "Santa Cruz",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002020",
      "brgy_name": "Tadlac",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041002021",
      "brgy_name": "San Juan",
      "city_code": "041002",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003001",
      "brgy_name": "Baclaran",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003003",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003004",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003005",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003006",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003007",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003008",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003009",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003010",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003011",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003012",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003013",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003014",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003016",
      "brgy_name": "Calan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003017",
      "brgy_name": "Caloocan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003018",
      "brgy_name": "Calzada",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003019",
      "brgy_name": "Canda",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003020",
      "brgy_name": "Carenahan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003021",
      "brgy_name": "Caybunga",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003022",
      "brgy_name": "Cayponce",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003023",
      "brgy_name": "Dalig",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003024",
      "brgy_name": "Dao",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003025",
      "brgy_name": "Dilao",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003026",
      "brgy_name": "Duhatan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003027",
      "brgy_name": "Durungao",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003028",
      "brgy_name": "Gimalas",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003029",
      "brgy_name": "Gumamela",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003030",
      "brgy_name": "Lagnas",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003031",
      "brgy_name": "Lanatan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003032",
      "brgy_name": "Langgangan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003033",
      "brgy_name": "Lucban Putol",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003034",
      "brgy_name": "Lucban Pook",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003035",
      "brgy_name": "Magabe",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003036",
      "brgy_name": "Malalay",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003037",
      "brgy_name": "Munting Tubig",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003038",
      "brgy_name": "Navotas",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003039",
      "brgy_name": "Patugo",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003040",
      "brgy_name": "Palikpikan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003042",
      "brgy_name": "Pooc",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003043",
      "brgy_name": "Sambat",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003044",
      "brgy_name": "Sampaga",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003045",
      "brgy_name": "San Juan",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003046",
      "brgy_name": "San Piro",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003048",
      "brgy_name": "Santol",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003049",
      "brgy_name": "Sukol",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003050",
      "brgy_name": "Tactac",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003051",
      "brgy_name": "Taludtud",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041003052",
      "brgy_name": "Tanggoy",
      "city_code": "041003",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004001",
      "brgy_name": "Alangilan",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004002",
      "brgy_name": "Calawit",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004003",
      "brgy_name": "Looc",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004004",
      "brgy_name": "Magapi",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004005",
      "brgy_name": "Makina",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004006",
      "brgy_name": "Malabanan",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004007",
      "brgy_name": "Paligawan",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004008",
      "brgy_name": "Palsara",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004009",
      "brgy_name": "Poblacion",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004010",
      "brgy_name": "Sala",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004011",
      "brgy_name": "Sampalocan",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004012",
      "brgy_name": "Solis",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041004013",
      "brgy_name": "San Sebastian",
      "city_code": "041004",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005001",
      "brgy_name": "Alangilan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005003",
      "brgy_name": "Balagtas",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005004",
      "brgy_name": "Balete",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005006",
      "brgy_name": "Banaba Center",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005007",
      "brgy_name": "Banaba Kanluran",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005008",
      "brgy_name": "Banaba Silangan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005009",
      "brgy_name": "Banaba Ibaba",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005010",
      "brgy_name": "Bilogo",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005011",
      "brgy_name": "Maapas",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005012",
      "brgy_name": "Bolbok",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005013",
      "brgy_name": "Bukal",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005014",
      "brgy_name": "Calicanto",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005016",
      "brgy_name": "Catandala",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005017",
      "brgy_name": "Concepcion",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005018",
      "brgy_name": "Conde Itaas",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005019",
      "brgy_name": "Conde Labak",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005020",
      "brgy_name": "Cuta",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005021",
      "brgy_name": "Dalig",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005022",
      "brgy_name": "Dela Paz",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005023",
      "brgy_name": "Dela Paz Pulot Aplaya",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005024",
      "brgy_name": "Dela Paz Pulot Itaas",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005026",
      "brgy_name": "Domoclay",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005027",
      "brgy_name": "Dumantay",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005028",
      "brgy_name": "Gulod Itaas",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005029",
      "brgy_name": "Gulod Labak",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005030",
      "brgy_name": "Haligue Kanluran",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005031",
      "brgy_name": "Haligue Silangan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005032",
      "brgy_name": "Ilihan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005033",
      "brgy_name": "Kumba",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005034",
      "brgy_name": "Kumintang Ibaba",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005035",
      "brgy_name": "Kumintang Ilaya",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005036",
      "brgy_name": "Libjo",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005037",
      "brgy_name": "Liponpon, Isla Verde",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005038",
      "brgy_name": "Mahabang Dahilig",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005039",
      "brgy_name": "Mahabang Parang",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005040",
      "brgy_name": "Mahacot Silangan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005041",
      "brgy_name": "Mahacot Kanluran",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005042",
      "brgy_name": "Malalim",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005043",
      "brgy_name": "Malibayo",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005044",
      "brgy_name": "Malitam",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005045",
      "brgy_name": "Maruclap",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005046",
      "brgy_name": "Mabacong (Matoco)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005047",
      "brgy_name": "Pagkilatan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005048",
      "brgy_name": "Paharang Kanluran",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005049",
      "brgy_name": "Paharang Silangan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005050",
      "brgy_name": "Pallocan Silangan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005051",
      "brgy_name": "Pallocan Kanluran",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005052",
      "brgy_name": "Pinamucan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005053",
      "brgy_name": "Pinamucan Ibaba",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005054",
      "brgy_name": "Pinamucan Silangan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005055",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005056",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005057",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005058",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005059",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005060",
      "brgy_name": "Barangay 14 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005061",
      "brgy_name": "Barangay 15 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005062",
      "brgy_name": "Barangay 16 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005063",
      "brgy_name": "Barangay 17 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005064",
      "brgy_name": "Barangay 18 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005065",
      "brgy_name": "Barangay 19 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005066",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005067",
      "brgy_name": "Barangay 20 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005068",
      "brgy_name": "Barangay 21 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005069",
      "brgy_name": "Barangay 22 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005070",
      "brgy_name": "Barangay 23 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005071",
      "brgy_name": "Barangay 24 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005072",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005073",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005074",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005075",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005076",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005077",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005078",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005082",
      "brgy_name": "Sampaga",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005083",
      "brgy_name": "San Agapito, Isla Verde",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005084",
      "brgy_name": "San Agustin Kanluran, Isla Verde",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005085",
      "brgy_name": "San Agustin Silangan, Isla Verde",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005087",
      "brgy_name": "San Andres, Isla Verde",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005088",
      "brgy_name": "San Antonio, Isla Verde",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005090",
      "brgy_name": "San Isidro",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005092",
      "brgy_name": "San Jose Sico",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005093",
      "brgy_name": "San Miguel",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005094",
      "brgy_name": "San Pedro",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005095",
      "brgy_name": "Santa Clara",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005096",
      "brgy_name": "Santa Rita Aplaya",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005097",
      "brgy_name": "Santa Rita Karsada",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005099",
      "brgy_name": "Santo Domingo",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005100",
      "brgy_name": "Santo Niño",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005101",
      "brgy_name": "Simlong",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005102",
      "brgy_name": "Sirang Lupa",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005103",
      "brgy_name": "Sorosoro Ibaba",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005104",
      "brgy_name": "Sorosoro Ilaya",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005105",
      "brgy_name": "Sorosoro Karsada",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005106",
      "brgy_name": "Tabangao Aplaya (Tabangao Proper)",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005107",
      "brgy_name": "Tabangao Ambulong",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005109",
      "brgy_name": "Tabangao Dao",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005110",
      "brgy_name": "Talahib Pandayan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005111",
      "brgy_name": "Talahib Payapa",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005112",
      "brgy_name": "Talumpok Kanluran",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005113",
      "brgy_name": "Talumpok Silangan",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005114",
      "brgy_name": "Tinga Itaas",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005115",
      "brgy_name": "Tinga Labak",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005116",
      "brgy_name": "Tulo",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041005117",
      "brgy_name": "Wawa",
      "city_code": "041005",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006001",
      "brgy_name": "Alagao",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006002",
      "brgy_name": "Aplaya",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006003",
      "brgy_name": "As-Is",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006004",
      "brgy_name": "Bagong Silang",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006005",
      "brgy_name": "Baguilawa",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006006",
      "brgy_name": "Balayong",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006007",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006008",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006009",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006010",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006011",
      "brgy_name": "Bolo",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006012",
      "brgy_name": "Colvo",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006013",
      "brgy_name": "Cupang",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006014",
      "brgy_name": "Durungao",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006015",
      "brgy_name": "Gulibay",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006016",
      "brgy_name": "Inicbulan",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006018",
      "brgy_name": "Locloc",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006019",
      "brgy_name": "Magalang-Galang",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006020",
      "brgy_name": "Malindig",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006021",
      "brgy_name": "Manalupong",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006022",
      "brgy_name": "Manghinao Proper",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006023",
      "brgy_name": "Manghinao Uno",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006024",
      "brgy_name": "New Danglayan",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006025",
      "brgy_name": "Orense",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006026",
      "brgy_name": "Pitugo",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006028",
      "brgy_name": "Rizal",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006029",
      "brgy_name": "Sampaguita",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006030",
      "brgy_name": "San Agustin",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006031",
      "brgy_name": "San Andres Proper",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006032",
      "brgy_name": "San Andres Uno",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006033",
      "brgy_name": "San Diego",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006034",
      "brgy_name": "San Miguel",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006035",
      "brgy_name": "San Pablo",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006036",
      "brgy_name": "San Pedro",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006037",
      "brgy_name": "San Roque",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006038",
      "brgy_name": "San Teodoro",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006039",
      "brgy_name": "San Vicente",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006041",
      "brgy_name": "Santa Maria",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006042",
      "brgy_name": "Santo Domingo",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041006044",
      "brgy_name": "Sinala",
      "city_code": "041006",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007001",
      "brgy_name": "Bagong Tubig",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007002",
      "brgy_name": "Baclas",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007003",
      "brgy_name": "Balimbing",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007004",
      "brgy_name": "Bambang",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007006",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007007",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007008",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007009",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007010",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007011",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007012",
      "brgy_name": "Bisaya",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007013",
      "brgy_name": "Cahil",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007014",
      "brgy_name": "Caluangan",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007015",
      "brgy_name": "Calantas",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007016",
      "brgy_name": "Camastilisan",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007017",
      "brgy_name": "Coral Ni Lopez (Sugod)",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007018",
      "brgy_name": "Coral Ni Bacal",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007019",
      "brgy_name": "Dacanlao",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007020",
      "brgy_name": "Dila",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007021",
      "brgy_name": "Loma",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007022",
      "brgy_name": "Lumbang Calzada",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007023",
      "brgy_name": "Lumbang Na Bata",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007024",
      "brgy_name": "Lumbang Na Matanda",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007025",
      "brgy_name": "Madalunot",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007027",
      "brgy_name": "Makina",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007028",
      "brgy_name": "Matipok",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007030",
      "brgy_name": "Munting Coral",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007031",
      "brgy_name": "Niyugan",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007032",
      "brgy_name": "Pantay",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007033",
      "brgy_name": "Puting Bato West",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007034",
      "brgy_name": "Puting Kahoy",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007035",
      "brgy_name": "Puting Bato East",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007036",
      "brgy_name": "Quisumbing",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007037",
      "brgy_name": "Salong",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007038",
      "brgy_name": "San Rafael",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007039",
      "brgy_name": "Sinisian",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007041",
      "brgy_name": "Taklang Anak",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007042",
      "brgy_name": "Talisay",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007043",
      "brgy_name": "Tamayo",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041007044",
      "brgy_name": "Timbain",
      "city_code": "041007",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008001",
      "brgy_name": "Bagong Silang",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008002",
      "brgy_name": "Baha",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008003",
      "brgy_name": "Balibago",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008004",
      "brgy_name": "Balitoc",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008005",
      "brgy_name": "Biga",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008006",
      "brgy_name": "Bucal",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008007",
      "brgy_name": "Carlosa",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008008",
      "brgy_name": "Carretunan",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008009",
      "brgy_name": "Encarnacion",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008010",
      "brgy_name": "Gulod",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008011",
      "brgy_name": "Hukay",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008013",
      "brgy_name": "Lucsuhin",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008014",
      "brgy_name": "Luya",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008015",
      "brgy_name": "Paraiso",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008016",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008017",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008018",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008019",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008020",
      "brgy_name": "Quilitisan",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008021",
      "brgy_name": "Real",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008022",
      "brgy_name": "Sambungan",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008023",
      "brgy_name": "Santa Ana",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008026",
      "brgy_name": "Talibayog",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008027",
      "brgy_name": "Talisay",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041008028",
      "brgy_name": "Tanagan",
      "city_code": "041008",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009001",
      "brgy_name": "Balagbag",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009002",
      "brgy_name": "Bungahan",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009003",
      "brgy_name": "Calumayin",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009005",
      "brgy_name": "Dalipit East",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009006",
      "brgy_name": "Dalipit West",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009007",
      "brgy_name": "Dita",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009008",
      "brgy_name": "Don Juan",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009009",
      "brgy_name": "Emmanuel",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009010",
      "brgy_name": "Ibabao",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009011",
      "brgy_name": "Labac",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009012",
      "brgy_name": "Pinagkaisahan",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009013",
      "brgy_name": "San Felipe",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009014",
      "brgy_name": "San Isidro",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009015",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009016",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009017",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009018",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009019",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009020",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009021",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041009022",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "041009",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010001",
      "brgy_name": "Bago",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010002",
      "brgy_name": "Balanga",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010003",
      "brgy_name": "Bungahan",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010004",
      "brgy_name": "Calamias",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010005",
      "brgy_name": "Catandala",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010006",
      "brgy_name": "Coliat",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010007",
      "brgy_name": "Dayapan",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010008",
      "brgy_name": "Lapu-lapu",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010009",
      "brgy_name": "Lucsuhin",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010010",
      "brgy_name": "Mabalor",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010011",
      "brgy_name": "Malainin",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010012",
      "brgy_name": "Matala",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010013",
      "brgy_name": "Munting-Tubig",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010014",
      "brgy_name": "Palindan",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010015",
      "brgy_name": "Pangao",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010016",
      "brgy_name": "Panghayaan",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010017",
      "brgy_name": "Poblacion",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010018",
      "brgy_name": "Quilo",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010019",
      "brgy_name": "Sabang",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010020",
      "brgy_name": "Salaban I",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010022",
      "brgy_name": "San Agustin",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010023",
      "brgy_name": "Sandalan",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010024",
      "brgy_name": "Santo Niño",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010025",
      "brgy_name": "Talaibon",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010027",
      "brgy_name": "Tulay Na Patpat",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041010028",
      "brgy_name": "Salaban II",
      "city_code": "041010",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011001",
      "brgy_name": "As-Is",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011002",
      "brgy_name": "Balakilong",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011004",
      "brgy_name": "Berinayan",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011006",
      "brgy_name": "Bugaan East",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011007",
      "brgy_name": "Bugaan West",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011008",
      "brgy_name": "Buso-buso",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011010",
      "brgy_name": "Dayap Itaas",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011011",
      "brgy_name": "Gulod",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011012",
      "brgy_name": "J. Leviste",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011013",
      "brgy_name": "Molinete",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011014",
      "brgy_name": "Niyugan",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011015",
      "brgy_name": "Paliparan",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011016",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011017",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011018",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011019",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011020",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011021",
      "brgy_name": "San Gabriel",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011022",
      "brgy_name": "San Gregorio",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011023",
      "brgy_name": "Santa Maria",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041011024",
      "brgy_name": "Ticub",
      "city_code": "041011",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012001",
      "brgy_name": "Anak-Dagat",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012002",
      "brgy_name": "Arumahan",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012003",
      "brgy_name": "Ayao-iyao",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012004",
      "brgy_name": "Bagong Pook",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012005",
      "brgy_name": "Bagong Sikat",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012006",
      "brgy_name": "Balanga",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012007",
      "brgy_name": "Bukal",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012009",
      "brgy_name": "Cahilan I",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012010",
      "brgy_name": "Cahilan II",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012011",
      "brgy_name": "Dayapan",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012012",
      "brgy_name": "Dita",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012013",
      "brgy_name": "Gulod",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012014",
      "brgy_name": "Lucky",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012015",
      "brgy_name": "Maguihan",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012016",
      "brgy_name": "Mahabang Dahilig",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012017",
      "brgy_name": "Mahayahay",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012018",
      "brgy_name": "Maigsing Dahilig",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012020",
      "brgy_name": "Maligaya",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012021",
      "brgy_name": "Malinis",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012022",
      "brgy_name": "Masalisi",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012023",
      "brgy_name": "Mataas Na Bayan",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012024",
      "brgy_name": "Matingain I",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012025",
      "brgy_name": "Matingain II",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012026",
      "brgy_name": "Mayasang",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012027",
      "brgy_name": "Niugan",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012028",
      "brgy_name": "Nonong Casto",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012029",
      "brgy_name": "Palanas",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012030",
      "brgy_name": "Payapa Ibaba",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012031",
      "brgy_name": "Payapa Ilaya",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012032",
      "brgy_name": "District I (Pob.)",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012033",
      "brgy_name": "District II (Pob.)",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012034",
      "brgy_name": "District III (Pob.)",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012035",
      "brgy_name": "District IV (Pob.)",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012036",
      "brgy_name": "Rizal",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012037",
      "brgy_name": "Sambal Ibaba",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012038",
      "brgy_name": "Sambal Ilaya",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012039",
      "brgy_name": "San Isidro Ibaba",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012040",
      "brgy_name": "San Isidro Itaas",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012041",
      "brgy_name": "Sangalang",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012043",
      "brgy_name": "Talaga",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012044",
      "brgy_name": "Tubigan",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012045",
      "brgy_name": "Tubuan",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012046",
      "brgy_name": "Wawa Ibaba",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012047",
      "brgy_name": "Wawa Ilaya",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012048",
      "brgy_name": "Sinisian East",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041012049",
      "brgy_name": "Sinisian West",
      "city_code": "041012",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013002",
      "brgy_name": "Bagong Pook",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013003",
      "brgy_name": "Balibago",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013004",
      "brgy_name": "Binubusan",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013005",
      "brgy_name": "Bungahan",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013007",
      "brgy_name": "Cumba",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013008",
      "brgy_name": "Humayingan",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013009",
      "brgy_name": "Kapito",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013012",
      "brgy_name": "Lumaniag",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013013",
      "brgy_name": "Luyahan",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013014",
      "brgy_name": "Malaruhatan",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013015",
      "brgy_name": "Matabungkay",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013016",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013017",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013018",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013019",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013020",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013021",
      "brgy_name": "Prenza",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013022",
      "brgy_name": "Puting-Kahoy",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041013023",
      "brgy_name": "San Diego",
      "city_code": "041013",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014001",
      "brgy_name": "Adya",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014003",
      "brgy_name": "Anilao",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014004",
      "brgy_name": "Anilao-Labac",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014005",
      "brgy_name": "Antipolo Del Norte",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014006",
      "brgy_name": "Antipolo Del Sur",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014007",
      "brgy_name": "Bagong Pook",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014008",
      "brgy_name": "San Sebastian (Balagbag)",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014009",
      "brgy_name": "Balintawak",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014010",
      "brgy_name": "Banaybanay",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014011",
      "brgy_name": "Bolbok",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014012",
      "brgy_name": "Bugtong na Pulo",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014013",
      "brgy_name": "Bulacnin",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014014",
      "brgy_name": "Bulaklakan",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014015",
      "brgy_name": "Calamias",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014016",
      "brgy_name": "Cumba",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014017",
      "brgy_name": "Dagatan",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014018",
      "brgy_name": "Duhatan",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014020",
      "brgy_name": "Halang",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014021",
      "brgy_name": "Inosloban",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014022",
      "brgy_name": "Kayumanggi",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014024",
      "brgy_name": "Latag",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014025",
      "brgy_name": "Lodlod",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014026",
      "brgy_name": "Lumbang",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014027",
      "brgy_name": "Mabini",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014028",
      "brgy_name": "Malagonlong",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014029",
      "brgy_name": "Malitlit",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014030",
      "brgy_name": "Marauoy",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014031",
      "brgy_name": "Mataas Na Lupa",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014032",
      "brgy_name": "Munting Pulo",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014033",
      "brgy_name": "Pagolingin Bata",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014034",
      "brgy_name": "Pagolingin East",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014035",
      "brgy_name": "Pagolingin West",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014036",
      "brgy_name": "Pangao",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014037",
      "brgy_name": "Pinagkawitan",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014038",
      "brgy_name": "Pinagtongulan",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014039",
      "brgy_name": "Plaridel",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014040",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014041",
      "brgy_name": "Poblacion Barangay 10",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014042",
      "brgy_name": "Poblacion Barangay 11",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014043",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014044",
      "brgy_name": "Poblacion Barangay 3",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014045",
      "brgy_name": "Poblacion Barangay 4",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014046",
      "brgy_name": "Poblacion Barangay 5",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014047",
      "brgy_name": "Poblacion Barangay 6",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014048",
      "brgy_name": "Poblacion Barangay 7",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014049",
      "brgy_name": "Poblacion Barangay 8",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014050",
      "brgy_name": "Poblacion Barangay 9",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014051",
      "brgy_name": "Pusil",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014052",
      "brgy_name": "Quezon",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014053",
      "brgy_name": "Rizal",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014054",
      "brgy_name": "Sabang",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014055",
      "brgy_name": "Sampaguita",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014056",
      "brgy_name": "San Benito",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014057",
      "brgy_name": "San Carlos",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014058",
      "brgy_name": "San Celestino",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014059",
      "brgy_name": "San Francisco",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014060",
      "brgy_name": "San Guillermo",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014061",
      "brgy_name": "San Jose",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014062",
      "brgy_name": "San Lucas",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014063",
      "brgy_name": "San Salvador",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014064",
      "brgy_name": "Sapac",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014066",
      "brgy_name": "Sico",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014068",
      "brgy_name": "Santo Niño",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014069",
      "brgy_name": "Santo Toribio",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014070",
      "brgy_name": "Talisay",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014071",
      "brgy_name": "Tambo",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014072",
      "brgy_name": "Tangob",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014073",
      "brgy_name": "Tanguay",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014074",
      "brgy_name": "Tibig",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014075",
      "brgy_name": "Tipacan",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014076",
      "brgy_name": "Poblacion Barangay 9-A",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041014077",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "041014",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015001",
      "brgy_name": "Apar",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015002",
      "brgy_name": "Balatbat",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015003",
      "brgy_name": "Balibago",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015004",
      "brgy_name": "Banalo",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015005",
      "brgy_name": "Biga",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015006",
      "brgy_name": "Bignay",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015007",
      "brgy_name": "Calo",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015008",
      "brgy_name": "Calumpit",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015009",
      "brgy_name": "Fabrica",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015010",
      "brgy_name": "Jaybanga",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015011",
      "brgy_name": "Lagadlarin",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015012",
      "brgy_name": "Mabilog Na Bundok",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015013",
      "brgy_name": "Malabrigo",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015014",
      "brgy_name": "Malalim Na Sanog",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015015",
      "brgy_name": "Malapad Na Parang",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015016",
      "brgy_name": "Masaguitsit",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015017",
      "brgy_name": "Nagtalongtong",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015018",
      "brgy_name": "Nagtoctoc",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015019",
      "brgy_name": "Olo-olo",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015020",
      "brgy_name": "Pinaghawanan",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015021",
      "brgy_name": "San Miguel",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015022",
      "brgy_name": "San Nicolas",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015023",
      "brgy_name": "Sawang",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015024",
      "brgy_name": "Soloc",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015025",
      "brgy_name": "Tayuman",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041015026",
      "brgy_name": "Poblacion",
      "city_code": "041015",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016001",
      "brgy_name": "Anilao Proper",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016002",
      "brgy_name": "Anilao East",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016003",
      "brgy_name": "Bagalangit",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016004",
      "brgy_name": "Bulacan",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016005",
      "brgy_name": "Calamias",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016006",
      "brgy_name": "Estrella",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016007",
      "brgy_name": "Gasang",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016008",
      "brgy_name": "Laurel",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016009",
      "brgy_name": "Ligaya",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016010",
      "brgy_name": "Mainaga",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016011",
      "brgy_name": "Mainit",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016012",
      "brgy_name": "Majuben",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016014",
      "brgy_name": "Malimatoc I",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016015",
      "brgy_name": "Malimatoc II",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016016",
      "brgy_name": "Nag-Iba",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016017",
      "brgy_name": "Pilahan",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016018",
      "brgy_name": "Poblacion",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016019",
      "brgy_name": "Pulang Lupa",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016020",
      "brgy_name": "Pulong Anahao",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016021",
      "brgy_name": "Pulong Balibaguhan",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016022",
      "brgy_name": "Pulong Niogan",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016023",
      "brgy_name": "Saguing",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016024",
      "brgy_name": "Sampaguita",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016025",
      "brgy_name": "San Francisco",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016026",
      "brgy_name": "San Jose",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016027",
      "brgy_name": "San Juan",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016028",
      "brgy_name": "San Teodoro",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016029",
      "brgy_name": "Santa Ana",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016030",
      "brgy_name": "Santa Mesa",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016031",
      "brgy_name": "Santo Niño",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016032",
      "brgy_name": "Santo Tomas",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016033",
      "brgy_name": "Solo",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016034",
      "brgy_name": "Talaga Proper",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041016035",
      "brgy_name": "Talaga East",
      "city_code": "041016",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017001",
      "brgy_name": "Bagong Pook",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017002",
      "brgy_name": "Bilucao (San Isidro Western)",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017003",
      "brgy_name": "Bulihan",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017004",
      "brgy_name": "San Gregorio",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017005",
      "brgy_name": "Luta Del Norte",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017006",
      "brgy_name": "Luta Del Sur",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017008",
      "brgy_name": "Poblacion",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017012",
      "brgy_name": "San Andres",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017013",
      "brgy_name": "San Fernando",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017015",
      "brgy_name": "San Isidro East",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017016",
      "brgy_name": "San Juan",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017017",
      "brgy_name": "San Pedro II (Western)",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017018",
      "brgy_name": "San Pedro I (Eastern)",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017019",
      "brgy_name": "San Pioquinto",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041017020",
      "brgy_name": "Santiago",
      "city_code": "041017",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018001",
      "brgy_name": "District I (Pob.)",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018002",
      "brgy_name": "District II (Pob.)",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018003",
      "brgy_name": "District III (Pob.)",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018004",
      "brgy_name": "District IV (Pob.)",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018006",
      "brgy_name": "Bayorbor",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018008",
      "brgy_name": "Bubuyan",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018009",
      "brgy_name": "Calingatan",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018010",
      "brgy_name": "Kinalaglagan",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018011",
      "brgy_name": "Loob",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018012",
      "brgy_name": "Lumang Lipa",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018013",
      "brgy_name": "Manggahan",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018014",
      "brgy_name": "Nangkaan",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018015",
      "brgy_name": "San Sebastian",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018016",
      "brgy_name": "Santol",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018017",
      "brgy_name": "Upa",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041018018",
      "brgy_name": "Barangay II-A (Pob.)",
      "city_code": "041018",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019001",
      "brgy_name": "Aga",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019002",
      "brgy_name": "Balaytigui",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019003",
      "brgy_name": "Banilad",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019004",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019005",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019006",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019007",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019008",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019009",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019010",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019011",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019012",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019013",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019014",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019015",
      "brgy_name": "Bilaran",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019016",
      "brgy_name": "Bucana",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019017",
      "brgy_name": "Bulihan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019018",
      "brgy_name": "Bunducan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019019",
      "brgy_name": "Butucan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019020",
      "brgy_name": "Calayo",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019021",
      "brgy_name": "Catandaan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019022",
      "brgy_name": "Kaylaway",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019023",
      "brgy_name": "Kayrilaw",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019024",
      "brgy_name": "Cogunan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019026",
      "brgy_name": "Dayap",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019027",
      "brgy_name": "Latag",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019028",
      "brgy_name": "Looc",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019029",
      "brgy_name": "Lumbangan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019032",
      "brgy_name": "Malapad Na Bato",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019033",
      "brgy_name": "Mataas Na Pulo",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019035",
      "brgy_name": "Maugat",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019036",
      "brgy_name": "Munting Indan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019037",
      "brgy_name": "Natipuan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019039",
      "brgy_name": "Pantalan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019041",
      "brgy_name": "Papaya",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019042",
      "brgy_name": "Putat",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019044",
      "brgy_name": "Reparo",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019045",
      "brgy_name": "Talangan",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019046",
      "brgy_name": "Tumalim",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019047",
      "brgy_name": "Utod",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019048",
      "brgy_name": "Wawa",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041019049",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "041019",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020001",
      "brgy_name": "Banaba",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020002",
      "brgy_name": "Banaybanay",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020014",
      "brgy_name": "Bawi",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020015",
      "brgy_name": "Bukal",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020016",
      "brgy_name": "Castillo",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020017",
      "brgy_name": "Cawongan",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020018",
      "brgy_name": "Manggas",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020019",
      "brgy_name": "Maugat East",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020020",
      "brgy_name": "Maugat West",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020021",
      "brgy_name": "Pansol",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020022",
      "brgy_name": "Payapa",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020023",
      "brgy_name": "Poblacion",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020024",
      "brgy_name": "Quilo-quilo North",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020025",
      "brgy_name": "Quilo-quilo South",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020026",
      "brgy_name": "San Felipe",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020027",
      "brgy_name": "San Miguel",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020028",
      "brgy_name": "Tamak",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041020029",
      "brgy_name": "Tangob",
      "city_code": "041020",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021001",
      "brgy_name": "Alupay",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021002",
      "brgy_name": "Antipolo",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021003",
      "brgy_name": "Bagong Pook",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021004",
      "brgy_name": "Balibago",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021005",
      "brgy_name": "Bayawang",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021006",
      "brgy_name": "Baybayin",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021007",
      "brgy_name": "Bulihan",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021008",
      "brgy_name": "Cahigam",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021009",
      "brgy_name": "Calantas",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021010",
      "brgy_name": "Colongan",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021011",
      "brgy_name": "Itlugan",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021012",
      "brgy_name": "Lumbangan",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021013",
      "brgy_name": "Maalas-As",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021014",
      "brgy_name": "Mabato",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021015",
      "brgy_name": "Mabunga",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021016",
      "brgy_name": "Macalamcam A",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021017",
      "brgy_name": "Macalamcam B",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021018",
      "brgy_name": "Malaya",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021019",
      "brgy_name": "Maligaya",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021020",
      "brgy_name": "Marilag",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021021",
      "brgy_name": "Masaya",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021022",
      "brgy_name": "Matamis",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021023",
      "brgy_name": "Mavalor",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021024",
      "brgy_name": "Mayuro",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021025",
      "brgy_name": "Namuco",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021026",
      "brgy_name": "Namunga",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021027",
      "brgy_name": "Natu",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021028",
      "brgy_name": "Nasi",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021029",
      "brgy_name": "Palakpak",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021030",
      "brgy_name": "Pinagsibaan",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021031",
      "brgy_name": "Barangay A (Pob.)",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021032",
      "brgy_name": "Barangay B (Pob.)",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021033",
      "brgy_name": "Barangay C (Pob.)",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021034",
      "brgy_name": "Barangay D (Pob.)",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021035",
      "brgy_name": "Barangay E (Pob.)",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021036",
      "brgy_name": "Putingkahoy",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021037",
      "brgy_name": "Quilib",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021038",
      "brgy_name": "Salao",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021039",
      "brgy_name": "San Carlos",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021040",
      "brgy_name": "San Ignacio",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021041",
      "brgy_name": "San Isidro",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021042",
      "brgy_name": "San Jose",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021043",
      "brgy_name": "San Roque",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021044",
      "brgy_name": "Santa Cruz",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021045",
      "brgy_name": "Timbugan",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021046",
      "brgy_name": "Tiquiwan",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021047",
      "brgy_name": "Leviste (Tubahan)",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041021048",
      "brgy_name": "Tulos",
      "city_code": "041021",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022001",
      "brgy_name": "Aguila",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022002",
      "brgy_name": "Anus",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022003",
      "brgy_name": "Aya",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022004",
      "brgy_name": "Bagong Pook",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022005",
      "brgy_name": "Balagtasin",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022006",
      "brgy_name": "Balagtasin I",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022007",
      "brgy_name": "Banaybanay I",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022008",
      "brgy_name": "Banaybanay II",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022009",
      "brgy_name": "Bigain I",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022010",
      "brgy_name": "Bigain II",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022011",
      "brgy_name": "Calansayan",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022012",
      "brgy_name": "Dagatan",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022013",
      "brgy_name": "Don Luis",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022014",
      "brgy_name": "Galamay-Amo",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022015",
      "brgy_name": "Lalayat",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022016",
      "brgy_name": "Lapolapo I",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022017",
      "brgy_name": "Lapolapo II",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022018",
      "brgy_name": "Lepute",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022019",
      "brgy_name": "Lumil",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022020",
      "brgy_name": "Natunuan",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022021",
      "brgy_name": "Palanca",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022022",
      "brgy_name": "Pinagtung-Ulan",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022023",
      "brgy_name": "Poblacion Barangay I",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022024",
      "brgy_name": "Poblacion Barangay II",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022025",
      "brgy_name": "Poblacion Barangay III",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022026",
      "brgy_name": "Poblacion Barangay IV",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022027",
      "brgy_name": "Sabang",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022028",
      "brgy_name": "Salaban",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022029",
      "brgy_name": "Santo Cristo",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022030",
      "brgy_name": "Mojon-Tampoy",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022031",
      "brgy_name": "Taysan",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022032",
      "brgy_name": "Tugtug",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041022033",
      "brgy_name": "Bigain South",
      "city_code": "041022",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023001",
      "brgy_name": "Abung",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023002",
      "brgy_name": "Balagbag",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023003",
      "brgy_name": "Barualte",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023004",
      "brgy_name": "Bataan",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023005",
      "brgy_name": "Buhay Na Sapa",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023006",
      "brgy_name": "Bulsa",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023007",
      "brgy_name": "Calicanto",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023008",
      "brgy_name": "Calitcalit",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023009",
      "brgy_name": "Calubcub I",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023010",
      "brgy_name": "Calubcub II",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023011",
      "brgy_name": "Catmon",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023012",
      "brgy_name": "Coloconto",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023013",
      "brgy_name": "Escribano",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023014",
      "brgy_name": "Hugom",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023015",
      "brgy_name": "Imelda (Tubog)",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023016",
      "brgy_name": "Janaojanao",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023017",
      "brgy_name": "Laiya-Ibabao",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023018",
      "brgy_name": "Laiya-Aplaya",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023019",
      "brgy_name": "Libato",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023020",
      "brgy_name": "Lipahan",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023021",
      "brgy_name": "Mabalanoy",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023022",
      "brgy_name": "Nagsaulay",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023023",
      "brgy_name": "Maraykit",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023024",
      "brgy_name": "Muzon",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023025",
      "brgy_name": "Palahanan I",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023026",
      "brgy_name": "Palahanan II",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023027",
      "brgy_name": "Palingowak",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023028",
      "brgy_name": "Pinagbayanan",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023029",
      "brgy_name": "Poblacion",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023030",
      "brgy_name": "Poctol",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023031",
      "brgy_name": "Pulangbato",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023032",
      "brgy_name": "Putingbuhangin",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023033",
      "brgy_name": "Quipot",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023034",
      "brgy_name": "Sampiro",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023035",
      "brgy_name": "Sapangan",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023036",
      "brgy_name": "Sico I",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023037",
      "brgy_name": "Sico II",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023038",
      "brgy_name": "Subukin",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023039",
      "brgy_name": "Talahiban I",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023040",
      "brgy_name": "Talahiban II",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023041",
      "brgy_name": "Ticalan",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041023042",
      "brgy_name": "Tipaz",
      "city_code": "041023",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024001",
      "brgy_name": "Abiacao",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024002",
      "brgy_name": "Bagong Tubig",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024003",
      "brgy_name": "Balagtasin",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024004",
      "brgy_name": "Balite",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024005",
      "brgy_name": "Banoyo",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024006",
      "brgy_name": "Boboy",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024007",
      "brgy_name": "Bonliw",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024008",
      "brgy_name": "Calumpang West",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024009",
      "brgy_name": "Calumpang East",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024010",
      "brgy_name": "Dulangan",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024011",
      "brgy_name": "Durungao",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024012",
      "brgy_name": "Locloc",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024013",
      "brgy_name": "Luya",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024014",
      "brgy_name": "Mahabang Parang",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024015",
      "brgy_name": "Manggahan",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024016",
      "brgy_name": "Muzon",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024019",
      "brgy_name": "San Antonio",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024020",
      "brgy_name": "San Isidro",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024021",
      "brgy_name": "San Jose",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024022",
      "brgy_name": "San Martin",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024023",
      "brgy_name": "Santa Monica",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024024",
      "brgy_name": "Taliba",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024025",
      "brgy_name": "Talon",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024026",
      "brgy_name": "Tejero",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024027",
      "brgy_name": "Tungal",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041024028",
      "brgy_name": "Poblacion",
      "city_code": "041024",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025001",
      "brgy_name": "Abelo",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025002",
      "brgy_name": "Balete",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025003",
      "brgy_name": "Baluk-baluk",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025004",
      "brgy_name": "Bancoro",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025005",
      "brgy_name": "Bangin",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025006",
      "brgy_name": "Calangay",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025007",
      "brgy_name": "Hipit",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025009",
      "brgy_name": "Maabud North",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025010",
      "brgy_name": "Maabud South",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025011",
      "brgy_name": "Munlawin",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025012",
      "brgy_name": "Pansipit",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025013",
      "brgy_name": "Poblacion",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025014",
      "brgy_name": "Santo Niño",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025015",
      "brgy_name": "Sinturisan",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025016",
      "brgy_name": "Tagudtod",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025017",
      "brgy_name": "Talang",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025018",
      "brgy_name": "Alas-as",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041025019",
      "brgy_name": "Pulang-Bato",
      "city_code": "041025",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026001",
      "brgy_name": "Alalum",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026002",
      "brgy_name": "Antipolo",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026003",
      "brgy_name": "Balimbing",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026004",
      "brgy_name": "Banaba",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026005",
      "brgy_name": "Bayanan",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026006",
      "brgy_name": "Danglayan",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026007",
      "brgy_name": "Del Pilar",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026008",
      "brgy_name": "Gelerang Kawayan",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026009",
      "brgy_name": "Ilat North",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026010",
      "brgy_name": "Ilat South",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026011",
      "brgy_name": "Kaingin",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026012",
      "brgy_name": "Laurel",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026013",
      "brgy_name": "Malaking Pook",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026014",
      "brgy_name": "Mataas Na Lupa",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026015",
      "brgy_name": "Natunuan North",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026016",
      "brgy_name": "Natunuan South",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026017",
      "brgy_name": "Padre Castillo",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026018",
      "brgy_name": "Palsahingin",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026019",
      "brgy_name": "Pila",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026020",
      "brgy_name": "Poblacion",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026021",
      "brgy_name": "Pook Ni Banal",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026022",
      "brgy_name": "Pook Ni Kapitan",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026023",
      "brgy_name": "Resplandor",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026024",
      "brgy_name": "Sambat",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026025",
      "brgy_name": "San Antonio",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026026",
      "brgy_name": "San Mariano",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026027",
      "brgy_name": "San Mateo",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026028",
      "brgy_name": "Santa Elena",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041026029",
      "brgy_name": "Santo Niño",
      "city_code": "041026",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027001",
      "brgy_name": "Antipolo",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027002",
      "brgy_name": "Bihis",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027003",
      "brgy_name": "Burol",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027004",
      "brgy_name": "Calayaan",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027005",
      "brgy_name": "Calumala",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027006",
      "brgy_name": "Cuta East",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027007",
      "brgy_name": "Cutang Cawayan",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027008",
      "brgy_name": "Irukan",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027009",
      "brgy_name": "Pacifico",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027010",
      "brgy_name": "Poblacion I",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027012",
      "brgy_name": "Saimsim",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027013",
      "brgy_name": "Sampa",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027014",
      "brgy_name": "Sinipian",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027015",
      "brgy_name": "Tambo Ibaba",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027016",
      "brgy_name": "Tambo Ilaya",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027017",
      "brgy_name": "Poblacion II",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041027018",
      "brgy_name": "Poblacion III",
      "city_code": "041027",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028001",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028002",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028003",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028004",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028005",
      "brgy_name": "San Agustin",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028006",
      "brgy_name": "San Antonio",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028007",
      "brgy_name": "San Bartolome",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028008",
      "brgy_name": "San Felix",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028009",
      "brgy_name": "San Fernando",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028010",
      "brgy_name": "San Francisco",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028011",
      "brgy_name": "San Isidro Norte",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028012",
      "brgy_name": "San Isidro Sur",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028013",
      "brgy_name": "San Joaquin",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028014",
      "brgy_name": "San Jose",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028015",
      "brgy_name": "San Juan",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028016",
      "brgy_name": "San Luis",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028017",
      "brgy_name": "San Miguel",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028018",
      "brgy_name": "San Pablo",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028019",
      "brgy_name": "San Pedro",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028020",
      "brgy_name": "San Rafael",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028021",
      "brgy_name": "San Roque",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028022",
      "brgy_name": "San Vicente",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028023",
      "brgy_name": "Santa Ana",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028024",
      "brgy_name": "Santa Anastacia",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028025",
      "brgy_name": "Santa Clara",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028026",
      "brgy_name": "Santa Cruz",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028027",
      "brgy_name": "Santa Elena",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028028",
      "brgy_name": "Santa Maria",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028029",
      "brgy_name": "Santiago",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041028030",
      "brgy_name": "Santa Teresita",
      "city_code": "041028",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029001",
      "brgy_name": "Apacay",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029002",
      "brgy_name": "Balisong",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029003",
      "brgy_name": "Bihis",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029004",
      "brgy_name": "Bolbok",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029005",
      "brgy_name": "Buli",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029006",
      "brgy_name": "Butong",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029007",
      "brgy_name": "Carasuche",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029008",
      "brgy_name": "Cawit",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029009",
      "brgy_name": "Caysasay",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029010",
      "brgy_name": "Cubamba",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029011",
      "brgy_name": "Cultihan",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029012",
      "brgy_name": "Gahol",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029013",
      "brgy_name": "Halang",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029014",
      "brgy_name": "Iba",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029015",
      "brgy_name": "Ilog",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029016",
      "brgy_name": "Imamawo",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029017",
      "brgy_name": "Ipil",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029018",
      "brgy_name": "Luntal",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029019",
      "brgy_name": "Mahabang Lodlod",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029020",
      "brgy_name": "Niogan",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029021",
      "brgy_name": "Pansol",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029022",
      "brgy_name": "Poblacion 11",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029023",
      "brgy_name": "Poblacion 1",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029024",
      "brgy_name": "Poblacion 10",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029025",
      "brgy_name": "Poblacion 12",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029026",
      "brgy_name": "Poblacion 2",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029027",
      "brgy_name": "Poblacion 3",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029028",
      "brgy_name": "Poblacion 4",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029029",
      "brgy_name": "Poblacion 5",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029030",
      "brgy_name": "Poblacion 6",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029031",
      "brgy_name": "Poblacion 7",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029032",
      "brgy_name": "Poblacion 8",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029033",
      "brgy_name": "Poblacion 9",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029034",
      "brgy_name": "Pook",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029044",
      "brgy_name": "Seiran",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029045",
      "brgy_name": "Laguile",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029046",
      "brgy_name": "Latag",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029047",
      "brgy_name": "Tierra Alta",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029048",
      "brgy_name": "Tulo",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029049",
      "brgy_name": "Tatlong Maria",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029050",
      "brgy_name": "Poblacion 13",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041029051",
      "brgy_name": "Poblacion 14",
      "city_code": "041029",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030001",
      "brgy_name": "Aya",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030003",
      "brgy_name": "Balas",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030004",
      "brgy_name": "Banga",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030006",
      "brgy_name": "Buco",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030008",
      "brgy_name": "Caloocan",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030009",
      "brgy_name": "Leynes",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030010",
      "brgy_name": "Miranda",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030011",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030012",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030013",
      "brgy_name": "Poblacion Barangay 3",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030014",
      "brgy_name": "Poblacion Barangay 4",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030015",
      "brgy_name": "Poblacion Barangay 5",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030016",
      "brgy_name": "Poblacion Barangay 6",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030017",
      "brgy_name": "Poblacion Barangay 7",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030018",
      "brgy_name": "Poblacion Barangay 8",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030019",
      "brgy_name": "Quiling",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030020",
      "brgy_name": "Sampaloc",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030021",
      "brgy_name": "San Guillermo",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030023",
      "brgy_name": "Santa Maria",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030024",
      "brgy_name": "Tranca",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041030025",
      "brgy_name": "Tumaway",
      "city_code": "041030",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031001",
      "brgy_name": "Altura Bata",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031002",
      "brgy_name": "Altura Matanda",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031003",
      "brgy_name": "Altura-South",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031004",
      "brgy_name": "Ambulong",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031005",
      "brgy_name": "Banadero",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031006",
      "brgy_name": "Bagbag",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031007",
      "brgy_name": "Bagumbayan",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031008",
      "brgy_name": "Balele",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031010",
      "brgy_name": "Banjo East",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031011",
      "brgy_name": "Banjo Laurel (Banjo West)",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031013",
      "brgy_name": "Bilog-bilog",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031014",
      "brgy_name": "Boot",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031015",
      "brgy_name": "Cale",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031016",
      "brgy_name": "Darasa",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031017",
      "brgy_name": "Pagaspas",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031018",
      "brgy_name": "Gonzales",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031019",
      "brgy_name": "Hidalgo",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031020",
      "brgy_name": "Janopol",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031021",
      "brgy_name": "Janopol Oriental",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031022",
      "brgy_name": "Laurel",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031023",
      "brgy_name": "Luyos",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031024",
      "brgy_name": "Mabini",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031025",
      "brgy_name": "Malaking Pulo",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031026",
      "brgy_name": "Maria Paz",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031027",
      "brgy_name": "Maugat",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031028",
      "brgy_name": "Montaña (Ik-ik)",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031029",
      "brgy_name": "Natatas",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031030",
      "brgy_name": "Pantay Matanda",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031031",
      "brgy_name": "Pantay Bata",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031032",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031033",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031034",
      "brgy_name": "Poblacion Barangay 3",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031035",
      "brgy_name": "Poblacion Barangay 4",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031036",
      "brgy_name": "Poblacion Barangay 5",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031037",
      "brgy_name": "Poblacion Barangay 6",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031038",
      "brgy_name": "Poblacion Barangay 7",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031039",
      "brgy_name": "Sala",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031040",
      "brgy_name": "Sambat",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031041",
      "brgy_name": "San Jose",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031042",
      "brgy_name": "Santol (Doña Jacoba Garcia)",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031043",
      "brgy_name": "Santor",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031044",
      "brgy_name": "Sulpoc",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031045",
      "brgy_name": "Suplang",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031046",
      "brgy_name": "Talaga",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031047",
      "brgy_name": "Tinurik",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031048",
      "brgy_name": "Trapiche",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031049",
      "brgy_name": "Ulango",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041031050",
      "brgy_name": "Wawa",
      "city_code": "041031",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032001",
      "brgy_name": "Bacao",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032002",
      "brgy_name": "Bilogo",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032003",
      "brgy_name": "Bukal",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032004",
      "brgy_name": "Dagatan",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032005",
      "brgy_name": "Guinhawa",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032006",
      "brgy_name": "Laurel",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032007",
      "brgy_name": "Mabayabas",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032008",
      "brgy_name": "Mahanadiong",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032009",
      "brgy_name": "Mapulo",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032010",
      "brgy_name": "Mataas Na Lupa",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032011",
      "brgy_name": "Pag-Asa",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032012",
      "brgy_name": "Panghayaan",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032013",
      "brgy_name": "Piña",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032014",
      "brgy_name": "Pinagbayanan",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032015",
      "brgy_name": "Poblacion East",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032016",
      "brgy_name": "Poblacion West",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032017",
      "brgy_name": "San Isidro",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032018",
      "brgy_name": "San Marcelino",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032019",
      "brgy_name": "Santo Niño",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041032020",
      "brgy_name": "Tilambo",
      "city_code": "041032",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033001",
      "brgy_name": "Corona",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033003",
      "brgy_name": "Gamao",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033004",
      "brgy_name": "Makawayan",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033005",
      "brgy_name": "Marikaban",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033006",
      "brgy_name": "Papaya",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033007",
      "brgy_name": "Pisa",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033008",
      "brgy_name": "Barangay 13 (Poblacion 1)",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033009",
      "brgy_name": "Barangay 14 (Poblacion 2)",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033010",
      "brgy_name": "Barangay 15 (Poblacion 3)",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033011",
      "brgy_name": "San Isidro",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033012",
      "brgy_name": "San Jose",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033013",
      "brgy_name": "San Juan",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033014",
      "brgy_name": "San Pedro",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033015",
      "brgy_name": "Santo Tomas",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041033016",
      "brgy_name": "Talahib",
      "city_code": "041033",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034001",
      "brgy_name": "Acle",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034002",
      "brgy_name": "Bayudbud",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034003",
      "brgy_name": "Bolboc",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034004",
      "brgy_name": "Dalima",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034005",
      "brgy_name": "Dao",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034006",
      "brgy_name": "Guinhawa",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034007",
      "brgy_name": "Lumbangan",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034008",
      "brgy_name": "Luntal",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034009",
      "brgy_name": "Magahis",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034010",
      "brgy_name": "Malibu",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034011",
      "brgy_name": "Mataywanac",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034012",
      "brgy_name": "Palincaro",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034013",
      "brgy_name": "Luna (Pob.)",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034014",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034015",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034016",
      "brgy_name": "Rillo (Pob.)",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034017",
      "brgy_name": "Putol",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034018",
      "brgy_name": "Sabang",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034019",
      "brgy_name": "San Jose",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034021",
      "brgy_name": "Talon",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034022",
      "brgy_name": "Toong",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "041034023",
      "brgy_name": "Tuyon-tuyon",
      "city_code": "041034",
      "province_code": "0410",
      "region_code": "04"
    },
    {
      "brgy_code": "042101001",
      "brgy_name": "Amuyong",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101003",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101004",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101005",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101006",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101007",
      "brgy_name": "Buck Estate",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101008",
      "brgy_name": "Esperanza Ibaba",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101009",
      "brgy_name": "Kaytitinga I",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101010",
      "brgy_name": "Luksuhin",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101011",
      "brgy_name": "Mangas I",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101012",
      "brgy_name": "Marahan I",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101013",
      "brgy_name": "Matagbak I",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101014",
      "brgy_name": "Pajo",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101016",
      "brgy_name": "Sikat",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101017",
      "brgy_name": "Sinaliw Malaki",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101018",
      "brgy_name": "Sinaliw na Munti",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101019",
      "brgy_name": "Sulsugin",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101020",
      "brgy_name": "Taywanak Ibaba",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101021",
      "brgy_name": "Taywanak Ilaya",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101022",
      "brgy_name": "Upli",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101023",
      "brgy_name": "Kaysuyo",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101024",
      "brgy_name": "Luksuhin Ilaya",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101025",
      "brgy_name": "Palumlum",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101026",
      "brgy_name": "Bilog",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101027",
      "brgy_name": "Esperanza Ilaya",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101028",
      "brgy_name": "Kaytitinga II",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101029",
      "brgy_name": "Kaytitinga III",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101030",
      "brgy_name": "Mangas II",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101031",
      "brgy_name": "Marahan II",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101032",
      "brgy_name": "Matagbak II",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042101033",
      "brgy_name": "Santa Teresa",
      "city_code": "042101",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102001",
      "brgy_name": "Banaybanay",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102002",
      "brgy_name": "Bucal",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102003",
      "brgy_name": "Dagatan",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102004",
      "brgy_name": "Halang",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102005",
      "brgy_name": "Loma",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102007",
      "brgy_name": "Maitim I",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102008",
      "brgy_name": "Maymangga",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102009",
      "brgy_name": "Minantok Kanluran",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102010",
      "brgy_name": "Pangil",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102011",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102012",
      "brgy_name": "Barangay X (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102013",
      "brgy_name": "Barangay XI (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102014",
      "brgy_name": "Barangay XII (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102015",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102016",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102017",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102018",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102019",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102020",
      "brgy_name": "Barangay VII (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102021",
      "brgy_name": "Barangay VIII (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102022",
      "brgy_name": "Barangay IX (Pob.)",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102023",
      "brgy_name": "Salaban",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102024",
      "brgy_name": "Talon",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102025",
      "brgy_name": "Tamacan",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102026",
      "brgy_name": "Buho",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042102027",
      "brgy_name": "Minantok Silangan",
      "city_code": "042102",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103001",
      "brgy_name": "Alima",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103002",
      "brgy_name": "Aniban I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103003",
      "brgy_name": "Banalo",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103004",
      "brgy_name": "Bayanan",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103005",
      "brgy_name": "Daang Bukid",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103006",
      "brgy_name": "Digman",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103007",
      "brgy_name": "Dulong Bayan",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103008",
      "brgy_name": "Habay I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103009",
      "brgy_name": "Kaingin (Pob.)",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103010",
      "brgy_name": "Ligas I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103011",
      "brgy_name": "Mabolo I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103012",
      "brgy_name": "Maliksi I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103013",
      "brgy_name": "Mambog I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103014",
      "brgy_name": "Molino I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103015",
      "brgy_name": "Niog I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103018",
      "brgy_name": "P.F. Espiritu I (Panapaan)",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103019",
      "brgy_name": "Real I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103020",
      "brgy_name": "Salinas I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103021",
      "brgy_name": "San Nicolas I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103022",
      "brgy_name": "Sineguelasan",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103023",
      "brgy_name": "Tabing Dagat",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103024",
      "brgy_name": "Talaba I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103025",
      "brgy_name": "Zapote I",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103026",
      "brgy_name": "Queens Row Central",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103027",
      "brgy_name": "Queens Row East",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103028",
      "brgy_name": "Queens Row West",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103029",
      "brgy_name": "Aniban II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103030",
      "brgy_name": "Aniban III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103031",
      "brgy_name": "Aniban IV",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103032",
      "brgy_name": "Aniban V",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103033",
      "brgy_name": "Campo Santo",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103034",
      "brgy_name": "Habay II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103035",
      "brgy_name": "Ligas II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103036",
      "brgy_name": "Ligas III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103037",
      "brgy_name": "Mabolo II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103038",
      "brgy_name": "Mabolo III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103039",
      "brgy_name": "Maliksi II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103040",
      "brgy_name": "Maliksi III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103041",
      "brgy_name": "Mambog II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103042",
      "brgy_name": "Mambog III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103043",
      "brgy_name": "Mambog IV",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103044",
      "brgy_name": "Mambog V",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103045",
      "brgy_name": "Molino II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103046",
      "brgy_name": "Molino III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103047",
      "brgy_name": "Molino IV",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103048",
      "brgy_name": "Molino V",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103049",
      "brgy_name": "Molino VI",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103050",
      "brgy_name": "Molino VII",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103051",
      "brgy_name": "Niog II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103052",
      "brgy_name": "Niog III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103053",
      "brgy_name": "P.F. Espiritu II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103054",
      "brgy_name": "P.F. Espiritu III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103055",
      "brgy_name": "P.F. Espiritu IV",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103056",
      "brgy_name": "P.F. Espiritu V",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103057",
      "brgy_name": "P.F. Espiritu VI",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103058",
      "brgy_name": "P.F. Espiritu VII",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103059",
      "brgy_name": "P.F. Espiritu VIII",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103060",
      "brgy_name": "Real II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103061",
      "brgy_name": "Salinas II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103062",
      "brgy_name": "Salinas III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103063",
      "brgy_name": "Salinas IV",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103064",
      "brgy_name": "San Nicolas II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103065",
      "brgy_name": "San Nicolas III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103066",
      "brgy_name": "Talaba II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103067",
      "brgy_name": "Talaba III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103068",
      "brgy_name": "Talaba IV",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103069",
      "brgy_name": "Talaba V",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103070",
      "brgy_name": "Talaba VI",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103071",
      "brgy_name": "Talaba VII",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103072",
      "brgy_name": "Zapote II",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103073",
      "brgy_name": "Zapote III",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103074",
      "brgy_name": "Zapote IV",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042103075",
      "brgy_name": "Zapote V",
      "city_code": "042103",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104001",
      "brgy_name": "Bancal",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104006",
      "brgy_name": "Cabilang Baybay",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104008",
      "brgy_name": "Lantic",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104009",
      "brgy_name": "Mabuhay",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104010",
      "brgy_name": "Maduya",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104011",
      "brgy_name": "Milagrosa",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104012",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104013",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104014",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104015",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104016",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104017",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104018",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042104019",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "042104",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105001",
      "brgy_name": "Barangay 1 (Hen. M. Alvarez)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105002",
      "brgy_name": "Barangay 10 (Kingfisher)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105003",
      "brgy_name": "Barangay 2 (C. Tirona)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105004",
      "brgy_name": "Barangay 11 (Lawin)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105005",
      "brgy_name": "Barangay 12 (Love Bird)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105006",
      "brgy_name": "Barangay 13 (Aguila)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105007",
      "brgy_name": "Barangay 14 (Loro)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105008",
      "brgy_name": "Barangay 15 (Kilyawan)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105009",
      "brgy_name": "Barangay 16 (Martines)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105010",
      "brgy_name": "Barangay 17 (Kalapati)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105011",
      "brgy_name": "Barangay 18 (Maya)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105012",
      "brgy_name": "Barangay 19 (Gemini)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105013",
      "brgy_name": "Barangay 20 (Virgo)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105014",
      "brgy_name": "Barangay 21 (Scorpio)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105015",
      "brgy_name": "Barangay 22 (Leo)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105016",
      "brgy_name": "Barangay 23 (Aquarius)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105017",
      "brgy_name": "Barangay 24 (Libra)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105018",
      "brgy_name": "Barangay 25 (Capricorn)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105019",
      "brgy_name": "Barangay 26 (Cancer)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105020",
      "brgy_name": "Barangay 27 (Sagitarius)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105021",
      "brgy_name": "Barangay 28 (Taurus)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105022",
      "brgy_name": "Barangay 29 (Lao-lao)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105023",
      "brgy_name": "Barangay 3 (Hen. E. Aguinaldo)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105024",
      "brgy_name": "Barangay 30 (Bid-bid)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105025",
      "brgy_name": "Barangay 31 (Maya-maya)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105026",
      "brgy_name": "Barangay 32 (Salay-salay)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105027",
      "brgy_name": "Barangay 33 (Buwan-buwan)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105028",
      "brgy_name": "Barangay 34 (Lapu-lapu)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105029",
      "brgy_name": "Barangay 35 (Hasa-hasa)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105030",
      "brgy_name": "Barangay 36 (Sap-sap)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105031",
      "brgy_name": "Barangay 37 (Cadena De Amor)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105032",
      "brgy_name": "Barangay 38 (Sampaguita)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105033",
      "brgy_name": "Barangay 39 (Jasmin)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105034",
      "brgy_name": "Barangay 4 (Hen. M. Trias)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105035",
      "brgy_name": "Barangay 40 (Gumamela)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105036",
      "brgy_name": "Barangay 41 (Rosal)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105037",
      "brgy_name": "Barangay 42 (Pinagbuklod)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105038",
      "brgy_name": "Barangay 43 (Pinagpala)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105039",
      "brgy_name": "Barangay 44 (Maligaya)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105040",
      "brgy_name": "Barangay 45 (Kaunlaran)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105041",
      "brgy_name": "Barangay 46 (Sinagtala)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105042",
      "brgy_name": "Barangay 47 (Pagkakaisa)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105043",
      "brgy_name": "Barangay 48 (Narra)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105044",
      "brgy_name": "Barangay 49 (Akasya)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105045",
      "brgy_name": "Barangay 5 (Hen. E. Evangelista)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105046",
      "brgy_name": "Barangay 50 (Kabalyero)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105047",
      "brgy_name": "Barangay 51 (Kamagong)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105048",
      "brgy_name": "Barangay 52 (Ipil)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105049",
      "brgy_name": "Barangay 53 (Yakal)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105050",
      "brgy_name": "Barangay 54 (Pechay)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105051",
      "brgy_name": "Barangay 55 (Ampalaya)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105052",
      "brgy_name": "Barangay 56 (Labanos)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105053",
      "brgy_name": "Barangay 57 (Repolyo)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105054",
      "brgy_name": "Barangay 58 (Patola)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105055",
      "brgy_name": "Barangay 59 (Sitaw)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105056",
      "brgy_name": "Barangay 6 (Diego Silang)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105057",
      "brgy_name": "Barangay 60 (Letsugas)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105058",
      "brgy_name": "Barangay 61 (Talong)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105059",
      "brgy_name": "Barangay 62 (Kangkong)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105060",
      "brgy_name": "Barangay 7 (Kapitan Kong)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105061",
      "brgy_name": "Barangay 8 (Manuel S. Rojas)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105062",
      "brgy_name": "Barangay 9 (Kanaway)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105063",
      "brgy_name": "Barangay 10-A (Kingfisher-A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105064",
      "brgy_name": "Barangay 10-B (Kingfisher-B)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105065",
      "brgy_name": "Barangay 22-A (Leo A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105066",
      "brgy_name": "Barangay 29-A (Lao-lao A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105067",
      "brgy_name": "Barangay 36-A (Sap-sap A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105068",
      "brgy_name": "Barangay 37-A (Cadena de Amor A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105069",
      "brgy_name": "Barangay 38-A (Sampaguita A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105070",
      "brgy_name": "Barangay 42-A (Pinagbuklod A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105071",
      "brgy_name": "Barangay 42-B (Pinagbuklod B)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105072",
      "brgy_name": "Barangay 42-C (Pinagbuklod C)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105073",
      "brgy_name": "Barangay 45-A (Kaunlaran A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105074",
      "brgy_name": "Barangay 47-A (Pagkakaisa A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105075",
      "brgy_name": "Barangay 47-B (Pagkakaisa B)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105076",
      "brgy_name": "Barangay 48-A (Narra A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105077",
      "brgy_name": "Barangay 49-A (Akasya A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105078",
      "brgy_name": "Barangay 53-A (Yakal A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105079",
      "brgy_name": "Barangay 53-B (Yakal B)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105080",
      "brgy_name": "Barangay 54-A (Pechay A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105081",
      "brgy_name": "Barangay 58-A (Patola A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105082",
      "brgy_name": "Barangay 61-A (Talong A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105083",
      "brgy_name": "Barangay 62-A (Kangkong A)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042105084",
      "brgy_name": "Barangay 62-B (Kangkong B)",
      "city_code": "042105",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106003",
      "brgy_name": "Burol",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106005",
      "brgy_name": "Langkaan I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106008",
      "brgy_name": "Paliparan I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106010",
      "brgy_name": "Sabang",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106011",
      "brgy_name": "Salawag",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106012",
      "brgy_name": "Salitran I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106013",
      "brgy_name": "Sampaloc I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106014",
      "brgy_name": "San Agustin I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106015",
      "brgy_name": "San Jose",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106016",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106017",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106018",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106019",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106020",
      "brgy_name": "Datu Esmael (Bago-a-ingud)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106021",
      "brgy_name": "Emmanuel Bergado I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106022",
      "brgy_name": "Fatima I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106023",
      "brgy_name": "Luzviminda I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106024",
      "brgy_name": "Saint Peter I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106025",
      "brgy_name": "San Andres I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106026",
      "brgy_name": "San Antonio De Padua I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106027",
      "brgy_name": "San Dionisio (Barangay 1)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106028",
      "brgy_name": "San Esteban (Barangay 4)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106029",
      "brgy_name": "San Francisco I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106030",
      "brgy_name": "San Isidro Labrador I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106031",
      "brgy_name": "San Juan (San Juan I)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106032",
      "brgy_name": "San Lorenzo Ruiz I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106033",
      "brgy_name": "San Luis I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106034",
      "brgy_name": "San Manuel I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106035",
      "brgy_name": "San Mateo",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106036",
      "brgy_name": "San Miguel",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106037",
      "brgy_name": "San Nicolas I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106038",
      "brgy_name": "San Roque (Sta. Cristina II)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106039",
      "brgy_name": "San Simon (Barangay 7)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106040",
      "brgy_name": "Santa Cristina I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106041",
      "brgy_name": "Santa Cruz I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106042",
      "brgy_name": "Santa Fe",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106043",
      "brgy_name": "Santa Lucia (San Juan II)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106044",
      "brgy_name": "Santa Maria (Barangay 20)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106045",
      "brgy_name": "Santo Cristo (Barangay 3)",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106046",
      "brgy_name": "Santo Niño I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106047",
      "brgy_name": "Burol I",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106048",
      "brgy_name": "Burol II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106049",
      "brgy_name": "Burol III",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106050",
      "brgy_name": "Emmanuel Bergado II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106051",
      "brgy_name": "Fatima II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106052",
      "brgy_name": "Fatima III",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106053",
      "brgy_name": "Langkaan II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106054",
      "brgy_name": "Luzviminda II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106055",
      "brgy_name": "Paliparan II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106056",
      "brgy_name": "Paliparan III",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106057",
      "brgy_name": "Saint Peter II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106058",
      "brgy_name": "Salitran II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106059",
      "brgy_name": "Salitran III",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106060",
      "brgy_name": "Salitran IV",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106061",
      "brgy_name": "Sampaloc II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106062",
      "brgy_name": "Sampaloc III",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106063",
      "brgy_name": "Sampaloc IV",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106064",
      "brgy_name": "Sampaloc V",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106065",
      "brgy_name": "San Agustin II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106066",
      "brgy_name": "San Agustin III",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106067",
      "brgy_name": "San Andres II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106068",
      "brgy_name": "San Antonio De Padua II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106069",
      "brgy_name": "San Francisco II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106070",
      "brgy_name": "San Isidro Labrador II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106071",
      "brgy_name": "San Lorenzo Ruiz II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106072",
      "brgy_name": "San Luis II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106073",
      "brgy_name": "San Manuel II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106074",
      "brgy_name": "San Miguel II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106075",
      "brgy_name": "San Nicolas II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106076",
      "brgy_name": "Santa Cristina II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106077",
      "brgy_name": "Santa Cruz II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106078",
      "brgy_name": "Santo Niño II",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106079",
      "brgy_name": "Zone I-B",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106080",
      "brgy_name": "H-2",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042106081",
      "brgy_name": "Victoria Reyes",
      "city_code": "042106",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107001",
      "brgy_name": "A. Dalusag",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107002",
      "brgy_name": "Batas Dao",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107003",
      "brgy_name": "Castaños Cerca",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107004",
      "brgy_name": "Castaños Lejos",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107005",
      "brgy_name": "Kabulusan",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107006",
      "brgy_name": "Kaymisas",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107007",
      "brgy_name": "Kaypaaba",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107008",
      "brgy_name": "Lumipa",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107009",
      "brgy_name": "Narvaez",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107010",
      "brgy_name": "Poblacion I",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107011",
      "brgy_name": "Tabora",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107012",
      "brgy_name": "Poblacion II",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107013",
      "brgy_name": "Poblacion III",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042107014",
      "brgy_name": "Poblacion IV",
      "city_code": "042107",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108001",
      "brgy_name": "Alingaro",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108002",
      "brgy_name": "Bacao I",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108003",
      "brgy_name": "Gov. Ferrer Pob. (Bgy. 1)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108004",
      "brgy_name": "Sampalucan Pob. (Bgy. 2)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108005",
      "brgy_name": "Dulong Bayan Pob. (Bgy. 3)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108006",
      "brgy_name": "San Gabriel Pob. (Bgy. 4)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108007",
      "brgy_name": "Bagumbayan Pob. (Bgy. 5)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108008",
      "brgy_name": "Vibora Pob. (Bgy. 6)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108009",
      "brgy_name": "Ninety Sixth Pob. (Bgy. 8)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108010",
      "brgy_name": "Prinza Pob. (Bgy. 9)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108011",
      "brgy_name": "Biclatan",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108012",
      "brgy_name": "Buenavista I",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108013",
      "brgy_name": "Corregidor Pob. (Bgy. 10)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108014",
      "brgy_name": "Javalera",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108015",
      "brgy_name": "Manggahan",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108016",
      "brgy_name": "Navarro",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108017",
      "brgy_name": "Panungyanan",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108018",
      "brgy_name": "Pasong Camachile I",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108020",
      "brgy_name": "Pasong Kawayan I",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108021",
      "brgy_name": "Pasong Kawayan II",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108022",
      "brgy_name": "Pinagtipunan",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108023",
      "brgy_name": "San Francisco",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108025",
      "brgy_name": "San Juan I",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108026",
      "brgy_name": "Santa Clara",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108027",
      "brgy_name": "Santiago",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108028",
      "brgy_name": "Tapia",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108029",
      "brgy_name": "Tejero",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108030",
      "brgy_name": "Arnaldo Pob. (Bgy. 7)",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108031",
      "brgy_name": "Bacao II",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108032",
      "brgy_name": "Buenavista II",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108033",
      "brgy_name": "Buenavista III",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108034",
      "brgy_name": "Pasong Camachile II",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042108035",
      "brgy_name": "San Juan II",
      "city_code": "042108",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109001",
      "brgy_name": "Alapan I-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109002",
      "brgy_name": "Alapan II-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109003",
      "brgy_name": "Anabu I-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109004",
      "brgy_name": "Anabu II-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109006",
      "brgy_name": "Poblacion I-A (Pob.)",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109007",
      "brgy_name": "Poblacion II-A (Pob.)",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109008",
      "brgy_name": "Poblacion III-A (Pob.)",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109009",
      "brgy_name": "Poblacion IV-A (Pob.)",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109010",
      "brgy_name": "Bayan Luma I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109011",
      "brgy_name": "Bucandala I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109012",
      "brgy_name": "Carsadang Bago I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109014",
      "brgy_name": "Malagasang I-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109015",
      "brgy_name": "Malagasang II-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109016",
      "brgy_name": "Medicion I-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109017",
      "brgy_name": "Medicion II-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109018",
      "brgy_name": "Pag-Asa I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109019",
      "brgy_name": "Palico I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109020",
      "brgy_name": "Pasong Buaya I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109022",
      "brgy_name": "Tanzang Luma I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109024",
      "brgy_name": "Toclong I-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109025",
      "brgy_name": "Toclong II-A",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109026",
      "brgy_name": "Alapan I-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109027",
      "brgy_name": "Alapan I-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109028",
      "brgy_name": "Alapan II-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109029",
      "brgy_name": "Anabu I-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109030",
      "brgy_name": "Anabu I-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109031",
      "brgy_name": "Anabu I-D",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109032",
      "brgy_name": "Anabu I-E",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109033",
      "brgy_name": "Anabu I-F",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109034",
      "brgy_name": "Anabu I-G",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109035",
      "brgy_name": "Anabu II-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109036",
      "brgy_name": "Anabu II-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109037",
      "brgy_name": "Anabu II-D",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109038",
      "brgy_name": "Anabu II-E",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109039",
      "brgy_name": "Anabu II-F",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109040",
      "brgy_name": "Bagong Silang (Bahayang Pag-Asa)",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109041",
      "brgy_name": "Bayan Luma II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109042",
      "brgy_name": "Bayan Luma III",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109043",
      "brgy_name": "Bayan Luma IV",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109044",
      "brgy_name": "Bayan Luma V",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109045",
      "brgy_name": "Bayan Luma VI",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109046",
      "brgy_name": "Bayan Luma VII",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109047",
      "brgy_name": "Bayan Luma VIII",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109048",
      "brgy_name": "Bayan Luma IX",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109049",
      "brgy_name": "Bucandala II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109050",
      "brgy_name": "Bucandala III",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109051",
      "brgy_name": "Bucandala IV",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109052",
      "brgy_name": "Bucandala V",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109053",
      "brgy_name": "Buhay na Tubig",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109054",
      "brgy_name": "Carsadang Bago II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109055",
      "brgy_name": "Magdalo",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109056",
      "brgy_name": "Maharlika",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109057",
      "brgy_name": "Malagasang I-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109058",
      "brgy_name": "Malagasang I-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109059",
      "brgy_name": "Malagasang I-D",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109060",
      "brgy_name": "Malagasang I-E",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109061",
      "brgy_name": "Malagasang I-F",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109062",
      "brgy_name": "Malagasang I-G",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109063",
      "brgy_name": "Malagasang II-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109064",
      "brgy_name": "Malagasang II-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109065",
      "brgy_name": "Malagasang II-D",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109066",
      "brgy_name": "Malagasang II-E",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109067",
      "brgy_name": "Malagasang II-F",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109068",
      "brgy_name": "Malagasang II-G",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109069",
      "brgy_name": "Mariano Espeleta I",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109070",
      "brgy_name": "Mariano Espeleta II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109071",
      "brgy_name": "Mariano Espeleta III",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109072",
      "brgy_name": "Medicion I-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109073",
      "brgy_name": "Medicion I-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109074",
      "brgy_name": "Medicion I-D",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109075",
      "brgy_name": "Medicion II-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109076",
      "brgy_name": "Medicion II-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109077",
      "brgy_name": "Medicion II-D",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109078",
      "brgy_name": "Medicion II-E",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109079",
      "brgy_name": "Medicion II-F",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109080",
      "brgy_name": "Pag-Asa II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109081",
      "brgy_name": "Pag-Asa III",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109082",
      "brgy_name": "Palico II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109083",
      "brgy_name": "Palico III",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109084",
      "brgy_name": "Palico IV",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109085",
      "brgy_name": "Pasong Buaya II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109086",
      "brgy_name": "Pinagbuklod",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109087",
      "brgy_name": "Poblacion I-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109088",
      "brgy_name": "Poblacion I-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109089",
      "brgy_name": "Poblacion II-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109090",
      "brgy_name": "Poblacion III-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109091",
      "brgy_name": "Poblacion IV-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109092",
      "brgy_name": "Poblacion IV-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109093",
      "brgy_name": "Poblacion IV-D",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109094",
      "brgy_name": "Tanzang Luma II",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109095",
      "brgy_name": "Tanzang Luma III",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109096",
      "brgy_name": "Tanzang Luma IV (Southern City)",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109097",
      "brgy_name": "Tanzang Luma V",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109098",
      "brgy_name": "Tanzang Luma VI",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109099",
      "brgy_name": "Toclong I-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109100",
      "brgy_name": "Toclong I-C",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042109101",
      "brgy_name": "Toclong II-B",
      "city_code": "042109",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110001",
      "brgy_name": "Agus-us",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110002",
      "brgy_name": "Alulod",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110003",
      "brgy_name": "Banaba Cerca",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110004",
      "brgy_name": "Banaba Lejos",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110005",
      "brgy_name": "Bancod",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110006",
      "brgy_name": "Buna Cerca",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110007",
      "brgy_name": "Buna Lejos I",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110008",
      "brgy_name": "Calumpang Cerca",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110009",
      "brgy_name": "Calumpang Lejos I",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110010",
      "brgy_name": "Carasuchi",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110011",
      "brgy_name": "Kayquit I",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110012",
      "brgy_name": "Daine I",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110013",
      "brgy_name": "Guyam Malaki",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110014",
      "brgy_name": "Guyam Munti",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110015",
      "brgy_name": "Harasan",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110016",
      "brgy_name": "Kaytambog",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110018",
      "brgy_name": "Limbon",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110019",
      "brgy_name": "Lumampong Balagbag",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110020",
      "brgy_name": "Lumampong Halayhay",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110021",
      "brgy_name": "Mahabangkahoy Lejos",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110022",
      "brgy_name": "Mahabangkahoy Cerca",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110023",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110024",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110025",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110026",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110027",
      "brgy_name": "Pulo",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110028",
      "brgy_name": "Tambo Balagbag",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110029",
      "brgy_name": "Tambo Ilaya",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110030",
      "brgy_name": "Tambo Malaki",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110031",
      "brgy_name": "Tambo Kulit",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110032",
      "brgy_name": "Buna Lejos II",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110033",
      "brgy_name": "Daine II",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110034",
      "brgy_name": "Kayquit II",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110035",
      "brgy_name": "Kayquit III",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110036",
      "brgy_name": "Kaytapos",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042110037",
      "brgy_name": "Mataas na Lupa (Checkpoint)",
      "city_code": "042110",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111001",
      "brgy_name": "Binakayan-Kanluran",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111002",
      "brgy_name": "Gahak",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111003",
      "brgy_name": "Kaingen",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111004",
      "brgy_name": "Marulas",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111005",
      "brgy_name": "Panamitan",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111006",
      "brgy_name": "Poblacion",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111007",
      "brgy_name": "Magdalo (Putol)",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111008",
      "brgy_name": "San Sebastian",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111009",
      "brgy_name": "Santa Isabel",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111010",
      "brgy_name": "Tabon I",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111011",
      "brgy_name": "Toclong",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111012",
      "brgy_name": "Wakas I",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111013",
      "brgy_name": "Batong Dalig",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111014",
      "brgy_name": "Balsahan-Bisita",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111015",
      "brgy_name": "Binakayan-Aplaya",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111016",
      "brgy_name": "Congbalay-Legaspi",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111017",
      "brgy_name": "Manggahan-Lawin",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111018",
      "brgy_name": "Pulvorista",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111019",
      "brgy_name": "Samala-Marquez",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111020",
      "brgy_name": "Tabon II",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111021",
      "brgy_name": "Tabon III",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111022",
      "brgy_name": "Tramo-Bantayan",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042111023",
      "brgy_name": "Wakas II",
      "city_code": "042111",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112001",
      "brgy_name": "Baliwag",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112002",
      "brgy_name": "Bendita I",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112003",
      "brgy_name": "Caluangan",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112004",
      "brgy_name": "Medina",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112005",
      "brgy_name": "Pacheco",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112006",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112007",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112008",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112009",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112010",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112011",
      "brgy_name": "Ramirez",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112012",
      "brgy_name": "Tua",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112013",
      "brgy_name": "Urdaneta",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112014",
      "brgy_name": "Kabulusan",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112015",
      "brgy_name": "Bendita II",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042112016",
      "brgy_name": "San Agustin",
      "city_code": "042112",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113001",
      "brgy_name": "Caingin Pob. (Barangay 3)",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113002",
      "brgy_name": "Bucal I",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113003",
      "brgy_name": "Bucal II",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113004",
      "brgy_name": "Bucal III A",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113005",
      "brgy_name": "Bucal IV A",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113007",
      "brgy_name": "San Miguel I A (Caputatan)",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113008",
      "brgy_name": "Garita I A",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113009",
      "brgy_name": "Mabato",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113010",
      "brgy_name": "Talipusngo",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113011",
      "brgy_name": "Pantihan I (Balayungan)",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113012",
      "brgy_name": "Pantihan II (Sagbat)",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113013",
      "brgy_name": "Pantihan III (Pook na Munti)",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113014",
      "brgy_name": "Pantihan IV (Pook ni Sara)",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113015",
      "brgy_name": "Patungan",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113016",
      "brgy_name": "Pinagsanhan I A",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113018",
      "brgy_name": "Poblacion I A",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113019",
      "brgy_name": "Poblacion II A",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113020",
      "brgy_name": "Tulay Silangan",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113021",
      "brgy_name": "Layong Mabilog",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113022",
      "brgy_name": "Bucal III B",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113023",
      "brgy_name": "Bucal IV B",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113024",
      "brgy_name": "Garita I B",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113025",
      "brgy_name": "Pinagsanhan I B",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113026",
      "brgy_name": "Poblacion I B",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113027",
      "brgy_name": "Poblacion II B",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113028",
      "brgy_name": "San Miguel I B",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042113029",
      "brgy_name": "Tulay Kanluran",
      "city_code": "042113",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114001",
      "brgy_name": "Anuling Lejos I (Anuling)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114002",
      "brgy_name": "Asis I",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114003",
      "brgy_name": "Galicia I",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114004",
      "brgy_name": "Palocpoc I",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114005",
      "brgy_name": "Panungyan I",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114007",
      "brgy_name": "Poblacion I (Barangay I)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114008",
      "brgy_name": "Poblacion II (Barangay II)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114009",
      "brgy_name": "Poblacion III (Barangay III)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114010",
      "brgy_name": "Poblacion IV (Barangay IV)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114011",
      "brgy_name": "Poblacion V (Barangay V)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114012",
      "brgy_name": "Poblacion VI (Barangay VI)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114013",
      "brgy_name": "Poblacion VII (Barangay VII)",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114014",
      "brgy_name": "Anuling Cerca I",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114015",
      "brgy_name": "Anuling Cerca II",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114016",
      "brgy_name": "Anuling Lejos II",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114017",
      "brgy_name": "Asis II",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114018",
      "brgy_name": "Asis III",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114019",
      "brgy_name": "Banayad",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114020",
      "brgy_name": "Bukal",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114021",
      "brgy_name": "Galicia II",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114022",
      "brgy_name": "Galicia III",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114023",
      "brgy_name": "Miguel Mojica",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114024",
      "brgy_name": "Palocpoc II",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042114025",
      "brgy_name": "Panungyan II",
      "city_code": "042114",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115001",
      "brgy_name": "Bagong Karsada",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115002",
      "brgy_name": "Balsahan",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115003",
      "brgy_name": "Bancaan",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115004",
      "brgy_name": "Bucana Malaki",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115005",
      "brgy_name": "Bucana Sasahan",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115006",
      "brgy_name": "Capt. C. Nazareno (Pob.)",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115007",
      "brgy_name": "Calubcob",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115008",
      "brgy_name": "Palangue 2 & 3",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115009",
      "brgy_name": "Gomez-Zamora (Pob.)",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115010",
      "brgy_name": "Halang",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115011",
      "brgy_name": "Humbac",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115012",
      "brgy_name": "Ibayo Estacion",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115013",
      "brgy_name": "Ibayo Silangan",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115014",
      "brgy_name": "Kanluran",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115015",
      "brgy_name": "Labac",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115016",
      "brgy_name": "Latoria",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115018",
      "brgy_name": "Mabolo",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115019",
      "brgy_name": "Makina",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115020",
      "brgy_name": "Malainen Bago",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115021",
      "brgy_name": "Malainen Luma",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115022",
      "brgy_name": "Molino",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115023",
      "brgy_name": "Munting Mapino",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115024",
      "brgy_name": "Muzon",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115025",
      "brgy_name": "Palangue 1",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115026",
      "brgy_name": "Sabang",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115028",
      "brgy_name": "San Roque",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115029",
      "brgy_name": "Santulan",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115030",
      "brgy_name": "Sapa",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115031",
      "brgy_name": "Timalan Balsahan",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042115032",
      "brgy_name": "Timalan Concepcion",
      "city_code": "042115",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116001",
      "brgy_name": "Magdiwang",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116002",
      "brgy_name": "Poblacion",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116003",
      "brgy_name": "Salcedo I",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116004",
      "brgy_name": "San Antonio I",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116005",
      "brgy_name": "San Juan I",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116006",
      "brgy_name": "San Rafael I",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116007",
      "brgy_name": "San Rafael II",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116008",
      "brgy_name": "San Jose I",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116009",
      "brgy_name": "Santa Rosa I",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116010",
      "brgy_name": "Salcedo II",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116011",
      "brgy_name": "San Antonio II",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116012",
      "brgy_name": "San Jose II",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116013",
      "brgy_name": "San Juan II",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116014",
      "brgy_name": "San Rafael III",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116015",
      "brgy_name": "San Rafael IV",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042116016",
      "brgy_name": "Santa Rosa II",
      "city_code": "042116",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117001",
      "brgy_name": "Silangan I",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117002",
      "brgy_name": "Bagbag I",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117003",
      "brgy_name": "Kanluran",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117005",
      "brgy_name": "Ligtong I",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117006",
      "brgy_name": "Ligtong II",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117007",
      "brgy_name": "Muzon I",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117008",
      "brgy_name": "Poblacion",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117009",
      "brgy_name": "Sapa I",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117010",
      "brgy_name": "Tejeros Convention",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117011",
      "brgy_name": "Wawa I",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117012",
      "brgy_name": "Ligtong III",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117013",
      "brgy_name": "Bagbag II",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117014",
      "brgy_name": "Ligtong IV",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117015",
      "brgy_name": "Muzon II",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117016",
      "brgy_name": "Sapa II",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117017",
      "brgy_name": "Sapa III",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117018",
      "brgy_name": "Sapa IV",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117019",
      "brgy_name": "Silangan II",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117020",
      "brgy_name": "Wawa II",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042117021",
      "brgy_name": "Wawa III",
      "city_code": "042117",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118001",
      "brgy_name": "Adlas",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118003",
      "brgy_name": "Balite I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118004",
      "brgy_name": "Balite II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118005",
      "brgy_name": "Balubad",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118006",
      "brgy_name": "Batas",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118007",
      "brgy_name": "Biga I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118008",
      "brgy_name": "Biluso",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118009",
      "brgy_name": "Buho",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118010",
      "brgy_name": "Bucal",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118011",
      "brgy_name": "Bulihan",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118012",
      "brgy_name": "Cabangaan",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118013",
      "brgy_name": "Carmen",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118014",
      "brgy_name": "Hukay",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118015",
      "brgy_name": "Iba",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118016",
      "brgy_name": "Inchican",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118017",
      "brgy_name": "Kalubkob",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118018",
      "brgy_name": "Kaong",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118019",
      "brgy_name": "Lalaan I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118020",
      "brgy_name": "Lalaan II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118021",
      "brgy_name": "Litlit",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118022",
      "brgy_name": "Lucsuhin",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118023",
      "brgy_name": "Lumil",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118026",
      "brgy_name": "Maguyam",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118027",
      "brgy_name": "Malabag",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118028",
      "brgy_name": "Mataas Na Burol",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118029",
      "brgy_name": "Munting Ilog",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118030",
      "brgy_name": "Paligawan",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118031",
      "brgy_name": "Pasong Langka",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118032",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118033",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118034",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118035",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118036",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118037",
      "brgy_name": "Pooc I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118038",
      "brgy_name": "Pulong Bunga",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118039",
      "brgy_name": "Pulong Saging",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118040",
      "brgy_name": "Puting Kahoy",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118041",
      "brgy_name": "Sabutan",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118042",
      "brgy_name": "San Miguel I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118043",
      "brgy_name": "San Vicente I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118044",
      "brgy_name": "Santol",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118045",
      "brgy_name": "Tartaria",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118046",
      "brgy_name": "Tibig",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118047",
      "brgy_name": "Tubuan I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118048",
      "brgy_name": "Ulat",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118049",
      "brgy_name": "Acacia",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118050",
      "brgy_name": "Anahaw I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118051",
      "brgy_name": "Ipil I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118052",
      "brgy_name": "Narra I",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118053",
      "brgy_name": "Yakal",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118054",
      "brgy_name": "Anahaw II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118055",
      "brgy_name": "Banaba",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118056",
      "brgy_name": "Biga II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118057",
      "brgy_name": "Hoyo",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118058",
      "brgy_name": "Ipil II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118059",
      "brgy_name": "Malaking Tatyao",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118060",
      "brgy_name": "Narra II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118061",
      "brgy_name": "Narra III",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118062",
      "brgy_name": "Pooc II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118063",
      "brgy_name": "San Miguel II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118064",
      "brgy_name": "San Vicente II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118065",
      "brgy_name": "Toledo",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118066",
      "brgy_name": "Tubuan II",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042118067",
      "brgy_name": "Tubuan III",
      "city_code": "042118",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119001",
      "brgy_name": "Asisan",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119002",
      "brgy_name": "Bagong Tubig",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119003",
      "brgy_name": "Dapdap West",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119004",
      "brgy_name": "Francisco (San Francisco)",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119005",
      "brgy_name": "Guinhawa South",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119006",
      "brgy_name": "Iruhin West",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119007",
      "brgy_name": "Calabuso (Calabuso South & North)",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119008",
      "brgy_name": "Kaybagal South (Pob.)",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119010",
      "brgy_name": "Mag-Asawang Ilat",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119011",
      "brgy_name": "Maharlika West",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119012",
      "brgy_name": "Maitim 2nd East",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119013",
      "brgy_name": "Mendez Crossing West",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119014",
      "brgy_name": "Neogan",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119015",
      "brgy_name": "Patutong Malaki South",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119017",
      "brgy_name": "Sambong",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119018",
      "brgy_name": "San Jose",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119019",
      "brgy_name": "Silang Junction South",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119020",
      "brgy_name": "Sungay South",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119021",
      "brgy_name": "Tolentino West",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119022",
      "brgy_name": "Zambal",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119023",
      "brgy_name": "Iruhin East",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119024",
      "brgy_name": "Kaybagal North",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119025",
      "brgy_name": "Maitim 2nd West",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119027",
      "brgy_name": "Dapdap East",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119028",
      "brgy_name": "Guinhawa North",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119029",
      "brgy_name": "Iruhin South",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119030",
      "brgy_name": "Kaybagal East",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119031",
      "brgy_name": "Maharlika East",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119032",
      "brgy_name": "Maitim 2nd Central",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119033",
      "brgy_name": "Mendez Crossing East",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119034",
      "brgy_name": "Patutong Malaki North",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119035",
      "brgy_name": "Silang Junction North",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119036",
      "brgy_name": "Sungay North",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042119037",
      "brgy_name": "Tolentino East",
      "city_code": "042119",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120001",
      "brgy_name": "Amaya I",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120002",
      "brgy_name": "Bagtas",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120003",
      "brgy_name": "Biga",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120004",
      "brgy_name": "Biwas",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120005",
      "brgy_name": "Bucal",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120006",
      "brgy_name": "Bunga",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120007",
      "brgy_name": "Calibuyo",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120008",
      "brgy_name": "Capipisa",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120009",
      "brgy_name": "Daang Amaya I",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120010",
      "brgy_name": "Halayhay",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120011",
      "brgy_name": "Julugan I",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120012",
      "brgy_name": "Mulawin",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120015",
      "brgy_name": "Paradahan I",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120016",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120017",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120018",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120019",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120020",
      "brgy_name": "Punta I",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120021",
      "brgy_name": "Sahud Ulan",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120022",
      "brgy_name": "Sanja Mayor",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120023",
      "brgy_name": "Santol",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120024",
      "brgy_name": "Tanauan",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120026",
      "brgy_name": "Tres Cruses",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120027",
      "brgy_name": "Lambingan",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120028",
      "brgy_name": "Amaya II",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120029",
      "brgy_name": "Amaya III",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120030",
      "brgy_name": "Amaya IV",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120031",
      "brgy_name": "Amaya V",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120032",
      "brgy_name": "Amaya VI",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120033",
      "brgy_name": "Amaya VII",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120034",
      "brgy_name": "Daang Amaya II",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120035",
      "brgy_name": "Daang Amaya III",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120036",
      "brgy_name": "Julugan II",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120037",
      "brgy_name": "Julugan III",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120038",
      "brgy_name": "Julugan IV",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120039",
      "brgy_name": "Julugan V",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120040",
      "brgy_name": "Julugan VI",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120041",
      "brgy_name": "Julugan VII",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120042",
      "brgy_name": "Julugan VIII",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120043",
      "brgy_name": "Paradahan II",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042120044",
      "brgy_name": "Punta II",
      "city_code": "042120",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121001",
      "brgy_name": "Poblacion I (Barangay I)",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121002",
      "brgy_name": "Poblacion II (Barangay II)",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121003",
      "brgy_name": "Bucana",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121005",
      "brgy_name": "Poblacion III (Barangay III)",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121006",
      "brgy_name": "San Jose",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121007",
      "brgy_name": "San Juan I",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121008",
      "brgy_name": "Sapang I",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121009",
      "brgy_name": "Poblacion I A",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121010",
      "brgy_name": "San Juan II",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042121011",
      "brgy_name": "Sapang II",
      "city_code": "042121",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122002",
      "brgy_name": "Cabezas",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122003",
      "brgy_name": "Cabuco",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122004",
      "brgy_name": "De Ocampo",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122005",
      "brgy_name": "Lallana",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122006",
      "brgy_name": "San Agustin (Pob.)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122008",
      "brgy_name": "Osorio",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122009",
      "brgy_name": "Conchu (Lagundian)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122010",
      "brgy_name": "Perez (Lucbanan)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122011",
      "brgy_name": "Aguado (Piscal Mundo)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122012",
      "brgy_name": "Gregorio (Aliang)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122013",
      "brgy_name": "Inocencio (B. Pook)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122014",
      "brgy_name": "Lapidario (Bayog)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042122015",
      "brgy_name": "Luciano (Bitangan)",
      "city_code": "042122",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123001",
      "brgy_name": "Aldiano Olaes",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123002",
      "brgy_name": "Barangay 1 Poblacion (Area I)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123003",
      "brgy_name": "Barangay 2 Poblacion",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123004",
      "brgy_name": "Barangay 3 Poblacion",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123005",
      "brgy_name": "Barangay 4 Poblacion",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123006",
      "brgy_name": "Barangay 5 Poblacion",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123007",
      "brgy_name": "Benjamin Tirona (Area D)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123008",
      "brgy_name": "Bernardo Pulido (Area H)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123009",
      "brgy_name": "Epifanio Malia",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123010",
      "brgy_name": "Francisco De Castro (Sunshine Vill.)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123011",
      "brgy_name": "Francisco Reyes",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123012",
      "brgy_name": "Fiorello Carimag (Area C)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123013",
      "brgy_name": "Gavino Maderan",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123014",
      "brgy_name": "Gregoria De Jesus",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123015",
      "brgy_name": "Inocencio Salud",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123016",
      "brgy_name": "Jacinto Lumbreras",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123017",
      "brgy_name": "Kapitan Kua (Area F)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123018",
      "brgy_name": "Koronel Jose P. Elises (Area E)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123019",
      "brgy_name": "Macario Dacon",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123020",
      "brgy_name": "Marcelino Memije",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123021",
      "brgy_name": "Nicolasa Virata (San Jose)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123022",
      "brgy_name": "Pantaleon Granados (Area G)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123023",
      "brgy_name": "Ramon Cruz (Area J)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123024",
      "brgy_name": "San Gabriel (Area K)",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123025",
      "brgy_name": "San Jose",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123026",
      "brgy_name": "Severino De Las Alas",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "042123027",
      "brgy_name": "Tiniente Tiago",
      "city_code": "042123",
      "province_code": "0421",
      "region_code": "04"
    },
    {
      "brgy_code": "043401001",
      "brgy_name": "Del Carmen",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401002",
      "brgy_name": "Palma",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401003",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401004",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401005",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401006",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401007",
      "brgy_name": "San Agustin",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401008",
      "brgy_name": "San Andres",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401009",
      "brgy_name": "San Benito",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401010",
      "brgy_name": "San Gregorio",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401011",
      "brgy_name": "San Ildefonso",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401012",
      "brgy_name": "San Juan",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401013",
      "brgy_name": "San Miguel",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401014",
      "brgy_name": "San Roque",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043401015",
      "brgy_name": "Santa Rosa",
      "city_code": "043401",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402001",
      "brgy_name": "Bitin",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402002",
      "brgy_name": "Calo",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402003",
      "brgy_name": "Dila",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402004",
      "brgy_name": "Maitim",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402005",
      "brgy_name": "Masaya",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402006",
      "brgy_name": "Paciano Rizal",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402009",
      "brgy_name": "Puypuy",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402010",
      "brgy_name": "San Antonio",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402011",
      "brgy_name": "San Isidro",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402012",
      "brgy_name": "Santa Cruz",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402013",
      "brgy_name": "Santo Domingo",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402014",
      "brgy_name": "Tagumpay",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402015",
      "brgy_name": "Tranca",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402016",
      "brgy_name": "San Agustin (Pob.)",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043402017",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "043402",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403001",
      "brgy_name": "Biñan (Poblacion)",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403002",
      "brgy_name": "Bungahan",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403003",
      "brgy_name": "Santo Tomas (Calabuso)",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403004",
      "brgy_name": "Canlalay",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403005",
      "brgy_name": "Casile",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403006",
      "brgy_name": "De La Paz",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403007",
      "brgy_name": "Ganado",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403008",
      "brgy_name": "San Francisco (Halang)",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403009",
      "brgy_name": "Langkiwa",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403011",
      "brgy_name": "Loma",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403012",
      "brgy_name": "Malaban",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403013",
      "brgy_name": "Malamig",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403014",
      "brgy_name": "Mampalasan",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403015",
      "brgy_name": "Platero",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403016",
      "brgy_name": "Poblacion",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403018",
      "brgy_name": "Santo Niño",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403019",
      "brgy_name": "San Antonio",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403020",
      "brgy_name": "San Jose",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403021",
      "brgy_name": "San Vicente",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403022",
      "brgy_name": "Soro-soro",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403023",
      "brgy_name": "Santo Domingo",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403024",
      "brgy_name": "Timbao",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403025",
      "brgy_name": "Tubigan",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043403026",
      "brgy_name": "Zapote",
      "city_code": "043403",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404001",
      "brgy_name": "Baclaran",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404002",
      "brgy_name": "Banaybanay",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404003",
      "brgy_name": "Banlic",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404004",
      "brgy_name": "Butong",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404005",
      "brgy_name": "Bigaa",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404006",
      "brgy_name": "Casile",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404007",
      "brgy_name": "Gulod",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404009",
      "brgy_name": "Mamatid",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404010",
      "brgy_name": "Marinig",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404011",
      "brgy_name": "Niugan",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404012",
      "brgy_name": "Pittland",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404013",
      "brgy_name": "Pulo",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404014",
      "brgy_name": "Sala",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404015",
      "brgy_name": "San Isidro",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404016",
      "brgy_name": "Diezmo",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404017",
      "brgy_name": "Barangay Uno (Pob.)",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404018",
      "brgy_name": "Barangay Dos (Pob.)",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043404019",
      "brgy_name": "Barangay Tres (Pob.)",
      "city_code": "043404",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405001",
      "brgy_name": "Bagong Kalsada",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405002",
      "brgy_name": "Banadero",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405003",
      "brgy_name": "Banlic",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405004",
      "brgy_name": "Barandal",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405005",
      "brgy_name": "Bubuyan",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405006",
      "brgy_name": "Bucal",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405007",
      "brgy_name": "Bunggo",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405009",
      "brgy_name": "Burol",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405010",
      "brgy_name": "Camaligan",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405011",
      "brgy_name": "Canlubang",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405013",
      "brgy_name": "Halang",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405014",
      "brgy_name": "Hornalan",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405016",
      "brgy_name": "Kay-Anlog",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405017",
      "brgy_name": "Laguerta",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405018",
      "brgy_name": "La Mesa",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405019",
      "brgy_name": "Lawa",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405020",
      "brgy_name": "Lecheria",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405021",
      "brgy_name": "Lingga",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405023",
      "brgy_name": "Looc",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405024",
      "brgy_name": "Mabato",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405026",
      "brgy_name": "Makiling",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405028",
      "brgy_name": "Mapagong",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405029",
      "brgy_name": "Masili",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405031",
      "brgy_name": "Maunong",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405032",
      "brgy_name": "Mayapa",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405033",
      "brgy_name": "Paciano Rizal",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405034",
      "brgy_name": "Palingon",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405035",
      "brgy_name": "Palo-Alto",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405036",
      "brgy_name": "Pansol",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405037",
      "brgy_name": "Parian",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405038",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405039",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405040",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405041",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405042",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405043",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405044",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405045",
      "brgy_name": "Prinza",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405046",
      "brgy_name": "Punta",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405047",
      "brgy_name": "Puting Lupa",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405049",
      "brgy_name": "Real",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405050",
      "brgy_name": "Sucol",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405051",
      "brgy_name": "Saimsim",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405052",
      "brgy_name": "Sampiruhan",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405053",
      "brgy_name": "San Cristobal",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405054",
      "brgy_name": "San Jose",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405055",
      "brgy_name": "San Juan",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405056",
      "brgy_name": "Sirang Lupa",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405057",
      "brgy_name": "Milagrosa (Tulo)",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405058",
      "brgy_name": "Turbina",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405059",
      "brgy_name": "Ulango",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405060",
      "brgy_name": "Uwisan",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405061",
      "brgy_name": "Batino",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043405062",
      "brgy_name": "Majada Labas",
      "city_code": "043405",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406001",
      "brgy_name": "Balayhangin",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406002",
      "brgy_name": "Bangyas",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406003",
      "brgy_name": "Dayap",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406004",
      "brgy_name": "Hanggan",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406005",
      "brgy_name": "Imok",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406006",
      "brgy_name": "Lamot 1",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406007",
      "brgy_name": "Lamot 2",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406008",
      "brgy_name": "Limao",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406009",
      "brgy_name": "Mabacan",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406010",
      "brgy_name": "Masiit",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406011",
      "brgy_name": "Paliparan",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406012",
      "brgy_name": "Perez",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406013",
      "brgy_name": "Kanluran (Pob.)",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406014",
      "brgy_name": "Silangan (Pob.)",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406015",
      "brgy_name": "Prinza",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406016",
      "brgy_name": "San Isidro",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043406017",
      "brgy_name": "Santo Tomas",
      "city_code": "043406",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407001",
      "brgy_name": "Anglas",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407002",
      "brgy_name": "Bangco",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407003",
      "brgy_name": "Bukal",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407004",
      "brgy_name": "Bulajo",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407005",
      "brgy_name": "Cansuso",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407006",
      "brgy_name": "Duhat",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407007",
      "brgy_name": "Inao-Awan",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407008",
      "brgy_name": "Kanluran Talaongan",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407009",
      "brgy_name": "Labayo",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407010",
      "brgy_name": "Layasin",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407011",
      "brgy_name": "Layug",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407012",
      "brgy_name": "Mahipon",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407014",
      "brgy_name": "Paowin",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407015",
      "brgy_name": "Poblacion",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407016",
      "brgy_name": "Sisilmin",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407017",
      "brgy_name": "Silangan Talaongan",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407018",
      "brgy_name": "Sumucab",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407019",
      "brgy_name": "Tibatib",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043407020",
      "brgy_name": "Udia",
      "city_code": "043407",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408001",
      "brgy_name": "Asana (Pob.)",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408002",
      "brgy_name": "Bacong-Sigsigan",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408003",
      "brgy_name": "Bagong Pag-Asa (Pob.)",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408004",
      "brgy_name": "Balitoc",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408005",
      "brgy_name": "Banaba (Pob.)",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408006",
      "brgy_name": "Batuhan",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408007",
      "brgy_name": "Bulihan",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408008",
      "brgy_name": "Caballero (Pob.)",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408009",
      "brgy_name": "Calumpang (Pob.)",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408010",
      "brgy_name": "Kapatalan",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408011",
      "brgy_name": "Cuebang Bato",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408012",
      "brgy_name": "Damayan (Pob.)",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408013",
      "brgy_name": "Kataypuanan",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408014",
      "brgy_name": "Liyang",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408015",
      "brgy_name": "Maate",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408016",
      "brgy_name": "Magdalo (Pob.)",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408017",
      "brgy_name": "Mayatba",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408018",
      "brgy_name": "Minayutan",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408019",
      "brgy_name": "Salangbato",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043408020",
      "brgy_name": "Tunhac",
      "city_code": "043408",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043409001",
      "brgy_name": "Longos",
      "city_code": "043409",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043409002",
      "brgy_name": "San Antonio",
      "city_code": "043409",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043409003",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "043409",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410002",
      "brgy_name": "Bagong Anyo (Pob.)",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410003",
      "brgy_name": "Bayate",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410004",
      "brgy_name": "Bubukal",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410005",
      "brgy_name": "Bongkol",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410007",
      "brgy_name": "Cabuyao",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410008",
      "brgy_name": "Calumpang",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410009",
      "brgy_name": "Culoy",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410010",
      "brgy_name": "Dagatan",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410011",
      "brgy_name": "Daniw (Danliw)",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410012",
      "brgy_name": "Dita",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410013",
      "brgy_name": "Ibabang Palina",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410014",
      "brgy_name": "Ibabang San Roque",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410015",
      "brgy_name": "Ibabang Sungi",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410016",
      "brgy_name": "Ibabang Taykin",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410018",
      "brgy_name": "Ilayang Palina",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410019",
      "brgy_name": "Ilayang San Roque",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410020",
      "brgy_name": "Ilayang Sungi",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410021",
      "brgy_name": "Ilayang Taykin",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410022",
      "brgy_name": "Kanlurang Bukal",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410023",
      "brgy_name": "Laguan",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410024",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410025",
      "brgy_name": "Luquin",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410026",
      "brgy_name": "Malabo-Kalantukan",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410027",
      "brgy_name": "Masikap (Pob.)",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410028",
      "brgy_name": "Maslun (Pob.)",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410029",
      "brgy_name": "Mojon",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410030",
      "brgy_name": "Novaliches",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410031",
      "brgy_name": "Oples",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410032",
      "brgy_name": "Pag-Asa (Pob.)",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410033",
      "brgy_name": "Palayan",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410034",
      "brgy_name": "San Isidro",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410035",
      "brgy_name": "Silangang Bukal",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043410037",
      "brgy_name": "Tuy-Baanan",
      "city_code": "043410",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411001",
      "brgy_name": "Anos",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411002",
      "brgy_name": "Bagong Silang",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411003",
      "brgy_name": "Bambang",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411004",
      "brgy_name": "Batong Malake",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411005",
      "brgy_name": "Baybayin (Pob.)",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411006",
      "brgy_name": "Bayog",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411007",
      "brgy_name": "Lalakay",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411008",
      "brgy_name": "Maahas",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411010",
      "brgy_name": "Mayondon",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411011",
      "brgy_name": "Putho Tuntungin",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411012",
      "brgy_name": "San Antonio",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411013",
      "brgy_name": "Tadlak",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411014",
      "brgy_name": "Timugan (Pob.)",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043411015",
      "brgy_name": "Malinta",
      "city_code": "043411",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412001",
      "brgy_name": "De La Paz",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412002",
      "brgy_name": "Barangay Zone I (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412003",
      "brgy_name": "Barangay Zone II (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412004",
      "brgy_name": "Barangay Zone III (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412005",
      "brgy_name": "Barangay Zone IV (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412006",
      "brgy_name": "Barangay Zone V (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412007",
      "brgy_name": "Barangay Zone VI (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412008",
      "brgy_name": "Barangay Zone VII (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412009",
      "brgy_name": "Barangay Zone VIII (Pob.)",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412010",
      "brgy_name": "San Antonio",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412011",
      "brgy_name": "San Buenaventura",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412012",
      "brgy_name": "San Diego",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412013",
      "brgy_name": "San Isidro",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412014",
      "brgy_name": "San Jose",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412015",
      "brgy_name": "San Juan",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412016",
      "brgy_name": "San Luis",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412017",
      "brgy_name": "San Pablo",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412018",
      "brgy_name": "San Pedro",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412019",
      "brgy_name": "San Rafael",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412020",
      "brgy_name": "San Roque",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412021",
      "brgy_name": "San Salvador",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412022",
      "brgy_name": "Santo Domingo",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043412023",
      "brgy_name": "Santo Tomas",
      "city_code": "043412",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413001",
      "brgy_name": "Bagong Silang",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413002",
      "brgy_name": "Balimbingan (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413003",
      "brgy_name": "Balubad",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413004",
      "brgy_name": "Caliraya",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413005",
      "brgy_name": "Concepcion",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413006",
      "brgy_name": "Lewin",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413007",
      "brgy_name": "Maracta (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413008",
      "brgy_name": "Maytalang I",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413009",
      "brgy_name": "Maytalang II",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413010",
      "brgy_name": "Primera Parang (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413011",
      "brgy_name": "Primera Pulo (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413012",
      "brgy_name": "Salac (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413013",
      "brgy_name": "Segunda Parang (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413014",
      "brgy_name": "Segunda Pulo (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413015",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043413016",
      "brgy_name": "Wawa",
      "city_code": "043413",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414001",
      "brgy_name": "Amuyong",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414002",
      "brgy_name": "Lambac (Pob.)",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414003",
      "brgy_name": "Lucong",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414004",
      "brgy_name": "Matalatala",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414005",
      "brgy_name": "Nanguma",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414006",
      "brgy_name": "Numero",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414007",
      "brgy_name": "Paagahan",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414008",
      "brgy_name": "Bayanihan (Pob.)",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414009",
      "brgy_name": "Libis ng Nayon (Pob.)",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414010",
      "brgy_name": "Maligaya (Pob.)",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414011",
      "brgy_name": "Masikap (Pob.)",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414012",
      "brgy_name": "Pag-Asa (Pob.)",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414013",
      "brgy_name": "Sinagtala (Pob.)",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414014",
      "brgy_name": "San Antonio",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043414015",
      "brgy_name": "San Miguel",
      "city_code": "043414",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415001",
      "brgy_name": "Alipit",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415002",
      "brgy_name": "Malaking Ambling",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415003",
      "brgy_name": "Munting Ambling",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415004",
      "brgy_name": "Baanan",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415005",
      "brgy_name": "Balanac",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415006",
      "brgy_name": "Bucal",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415007",
      "brgy_name": "Buenavista",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415008",
      "brgy_name": "Bungkol",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415009",
      "brgy_name": "Buo",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415010",
      "brgy_name": "Burlungan",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415011",
      "brgy_name": "Cigaras",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415012",
      "brgy_name": "Ibabang Atingay",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415013",
      "brgy_name": "Ibabang Butnong",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415014",
      "brgy_name": "Ilayang Atingay",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415015",
      "brgy_name": "Ilayang Butnong",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415016",
      "brgy_name": "Ilog",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415017",
      "brgy_name": "Malinao",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415018",
      "brgy_name": "Maravilla",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415019",
      "brgy_name": "Poblacion",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415020",
      "brgy_name": "Sabang",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415021",
      "brgy_name": "Salasad",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415022",
      "brgy_name": "Tanawan",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415023",
      "brgy_name": "Tipunan",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043415024",
      "brgy_name": "Halayhayin",
      "city_code": "043415",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416001",
      "brgy_name": "Amonoy",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416002",
      "brgy_name": "Bakia",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416003",
      "brgy_name": "Bukal",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416004",
      "brgy_name": "Balanac",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416005",
      "brgy_name": "Balayong",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416007",
      "brgy_name": "Banilad",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416008",
      "brgy_name": "Banti",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416010",
      "brgy_name": "Bitaoy",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416011",
      "brgy_name": "Botocan",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416012",
      "brgy_name": "Burgos",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416013",
      "brgy_name": "Burol",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416014",
      "brgy_name": "Coralao",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416015",
      "brgy_name": "Gagalot",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416016",
      "brgy_name": "Ibabang Banga",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416017",
      "brgy_name": "Ibabang Bayucain",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416018",
      "brgy_name": "Ilayang Banga",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416019",
      "brgy_name": "Ilayang Bayucain",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416020",
      "brgy_name": "Isabang",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416021",
      "brgy_name": "Malinao",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416022",
      "brgy_name": "May-It",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416023",
      "brgy_name": "Munting Kawayan",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416024",
      "brgy_name": "Oobi",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416025",
      "brgy_name": "Olla",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416026",
      "brgy_name": "Origuel (Pob.)",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416027",
      "brgy_name": "Panalaban",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416028",
      "brgy_name": "Panglan",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416029",
      "brgy_name": "Pangil",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416030",
      "brgy_name": "Piit",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416031",
      "brgy_name": "Pook",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416032",
      "brgy_name": "Rizal",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416033",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416034",
      "brgy_name": "San Isidro",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416035",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416036",
      "brgy_name": "San Roque",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416037",
      "brgy_name": "Santa Catalina (Pob.)",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416038",
      "brgy_name": "Suba",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416039",
      "brgy_name": "Tanawan",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416040",
      "brgy_name": "Taytay",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416041",
      "brgy_name": "Talortor",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043416042",
      "brgy_name": "Villa Nogales",
      "city_code": "043416",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417001",
      "brgy_name": "Abo",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417002",
      "brgy_name": "Alibungbungan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417003",
      "brgy_name": "Alumbrado",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417004",
      "brgy_name": "Balayong",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417005",
      "brgy_name": "Balimbing",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417006",
      "brgy_name": "Balinacon",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417007",
      "brgy_name": "Bambang",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417008",
      "brgy_name": "Banago",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417009",
      "brgy_name": "Banca-banca",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417010",
      "brgy_name": "Bangcuro",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417011",
      "brgy_name": "Banilad",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417012",
      "brgy_name": "Bayaquitos",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417013",
      "brgy_name": "Buboy",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417014",
      "brgy_name": "Buenavista",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417015",
      "brgy_name": "Buhanginan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417016",
      "brgy_name": "Bukal",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417017",
      "brgy_name": "Bunga",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417018",
      "brgy_name": "Cabuyew",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417019",
      "brgy_name": "Calumpang",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417020",
      "brgy_name": "Kanluran Kabubuhayan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417021",
      "brgy_name": "Silangan Kabubuhayan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417022",
      "brgy_name": "Labangan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417023",
      "brgy_name": "Lawaguin",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417024",
      "brgy_name": "Kanluran Lazaan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417025",
      "brgy_name": "Silangan Lazaan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417026",
      "brgy_name": "Lagulo",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417027",
      "brgy_name": "Maiit",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417028",
      "brgy_name": "Malaya",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417029",
      "brgy_name": "Malinao",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417030",
      "brgy_name": "Manaol",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417031",
      "brgy_name": "Maravilla",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417032",
      "brgy_name": "Nagcalbang",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417033",
      "brgy_name": "Poblacion I (Pob.)",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417034",
      "brgy_name": "Poblacion II (Pob.)",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417035",
      "brgy_name": "Poblacion III (Pob.)",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417036",
      "brgy_name": "Oples",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417037",
      "brgy_name": "Palayan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417038",
      "brgy_name": "Palina",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417039",
      "brgy_name": "Sabang",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417040",
      "brgy_name": "San Francisco",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417041",
      "brgy_name": "Sibulan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417042",
      "brgy_name": "Silangan Napapatid",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417043",
      "brgy_name": "Silangan Ilaya",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417044",
      "brgy_name": "Sinipian",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417045",
      "brgy_name": "Santa Lucia",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417046",
      "brgy_name": "Sulsuguin",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417047",
      "brgy_name": "Talahib",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417048",
      "brgy_name": "Talangan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417049",
      "brgy_name": "Taytay",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417050",
      "brgy_name": "Tipacan",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417052",
      "brgy_name": "Wakat",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043417053",
      "brgy_name": "Yukos",
      "city_code": "043417",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418001",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418002",
      "brgy_name": "Bangkusay (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418003",
      "brgy_name": "Ermita (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418004",
      "brgy_name": "Ibaba del Norte (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418005",
      "brgy_name": "Ibaba del Sur (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418006",
      "brgy_name": "Ilaya del Norte (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418007",
      "brgy_name": "Ilaya del Sur (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418008",
      "brgy_name": "Maytoong (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043418009",
      "brgy_name": "Quinale (Pob.)",
      "city_code": "043418",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419001",
      "brgy_name": "Anibong",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419002",
      "brgy_name": "Biñan",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419003",
      "brgy_name": "Buboy",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419004",
      "brgy_name": "Cabanbanan",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419005",
      "brgy_name": "Calusiche",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419006",
      "brgy_name": "Dingin",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419007",
      "brgy_name": "Lambac",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419008",
      "brgy_name": "Layugan",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419009",
      "brgy_name": "Magdapio",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419010",
      "brgy_name": "Maulawin",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419011",
      "brgy_name": "Pinagsanjan",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419012",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419013",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419014",
      "brgy_name": "Sabang",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419015",
      "brgy_name": "Sampaloc",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043419016",
      "brgy_name": "San Isidro",
      "city_code": "043419",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420001",
      "brgy_name": "Baño (Pob.)",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420002",
      "brgy_name": "Banilan",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420003",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420004",
      "brgy_name": "Casa Real",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420005",
      "brgy_name": "Casinsin",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420006",
      "brgy_name": "Dorado",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420007",
      "brgy_name": "Gonzales (Pob.)",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420008",
      "brgy_name": "Kabulusan",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420009",
      "brgy_name": "Matikiw",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420010",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420011",
      "brgy_name": "Saray",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420012",
      "brgy_name": "Taft (Pob.)",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043420013",
      "brgy_name": "Tavera (Pob.)",
      "city_code": "043420",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421001",
      "brgy_name": "Balian",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421002",
      "brgy_name": "Dambo",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421003",
      "brgy_name": "Galalan",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421004",
      "brgy_name": "Isla (Pob.)",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421005",
      "brgy_name": "Mabato-Azufre",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421006",
      "brgy_name": "Natividad (Pob.)",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421007",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043421008",
      "brgy_name": "Sulib",
      "city_code": "043421",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422001",
      "brgy_name": "Aplaya",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422002",
      "brgy_name": "Bagong Pook",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422003",
      "brgy_name": "Bukal",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422004",
      "brgy_name": "Bulilan Norte (Pob.)",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422005",
      "brgy_name": "Bulilan Sur (Pob.)",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422006",
      "brgy_name": "Concepcion",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422007",
      "brgy_name": "Labuin",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422008",
      "brgy_name": "Linga",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422009",
      "brgy_name": "Masico",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422010",
      "brgy_name": "Mojon",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422011",
      "brgy_name": "Pansol",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422012",
      "brgy_name": "Pinagbayanan",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422014",
      "brgy_name": "San Antonio",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422015",
      "brgy_name": "San Miguel",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422016",
      "brgy_name": "Santa Clara Norte (Pob.)",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422017",
      "brgy_name": "Santa Clara Sur (Pob.)",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043422018",
      "brgy_name": "Tubuan",
      "city_code": "043422",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423001",
      "brgy_name": "Antipolo",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423002",
      "brgy_name": "Entablado",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423003",
      "brgy_name": "Laguan",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423004",
      "brgy_name": "Paule 1",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423005",
      "brgy_name": "Paule 2",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423006",
      "brgy_name": "East Poblacion",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423007",
      "brgy_name": "West Poblacion",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423008",
      "brgy_name": "Pook",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423009",
      "brgy_name": "Tala",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423010",
      "brgy_name": "Talaga",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043423011",
      "brgy_name": "Tuy",
      "city_code": "043423",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424001",
      "brgy_name": "Bagong Bayan II-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424002",
      "brgy_name": "Bagong Pook VI-C (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424003",
      "brgy_name": "Barangay I-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424004",
      "brgy_name": "Barangay I-B (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424005",
      "brgy_name": "Barangay II-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424006",
      "brgy_name": "Barangay II-B (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424007",
      "brgy_name": "Barangay II-C (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424008",
      "brgy_name": "Barangay II-D (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424009",
      "brgy_name": "Barangay II-E (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424010",
      "brgy_name": "Barangay II-F (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424011",
      "brgy_name": "Barangay III-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424012",
      "brgy_name": "Barangay III-B (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424013",
      "brgy_name": "Barangay III-C (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424014",
      "brgy_name": "Barangay III-D (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424015",
      "brgy_name": "Barangay III-E (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424016",
      "brgy_name": "Barangay III-F (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424017",
      "brgy_name": "Barangay IV-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424018",
      "brgy_name": "Barangay IV-B (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424019",
      "brgy_name": "Barangay IV-C (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424020",
      "brgy_name": "Barangay V-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424021",
      "brgy_name": "Barangay V-B (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424022",
      "brgy_name": "Barangay V-C (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424023",
      "brgy_name": "Barangay V-D (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424024",
      "brgy_name": "Barangay VI-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424025",
      "brgy_name": "Barangay VI-B (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424027",
      "brgy_name": "Barangay VI-D (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424028",
      "brgy_name": "Barangay VI-E (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424029",
      "brgy_name": "Barangay VII-A (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424030",
      "brgy_name": "Barangay VII-B (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424031",
      "brgy_name": "Barangay VII-C (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424032",
      "brgy_name": "Barangay VII-D (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424033",
      "brgy_name": "Barangay VII-E (Pob.)",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424034",
      "brgy_name": "Bautista",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424035",
      "brgy_name": "Concepcion",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424036",
      "brgy_name": "Del Remedio",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424037",
      "brgy_name": "Dolores",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424040",
      "brgy_name": "San Antonio 1",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424041",
      "brgy_name": "San Antonio 2",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424042",
      "brgy_name": "San Bartolome",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424043",
      "brgy_name": "San Buenaventura",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424044",
      "brgy_name": "San Crispin",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424045",
      "brgy_name": "San Cristobal",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424046",
      "brgy_name": "San Diego",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424047",
      "brgy_name": "San Francisco",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424048",
      "brgy_name": "San Gabriel",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424049",
      "brgy_name": "San Gregorio",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424050",
      "brgy_name": "San Ignacio",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424051",
      "brgy_name": "San Isidro",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424052",
      "brgy_name": "San Joaquin",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424053",
      "brgy_name": "San Jose",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424054",
      "brgy_name": "San Juan",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424055",
      "brgy_name": "San Lorenzo",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424056",
      "brgy_name": "San Lucas 1",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424057",
      "brgy_name": "San Lucas 2",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424058",
      "brgy_name": "San Marcos",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424059",
      "brgy_name": "San Mateo",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424060",
      "brgy_name": "San Miguel",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424061",
      "brgy_name": "San Nicolas",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424062",
      "brgy_name": "San Pedro",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424063",
      "brgy_name": "San Rafael",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424064",
      "brgy_name": "San Roque",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424065",
      "brgy_name": "San Vicente",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424066",
      "brgy_name": "Santa Ana",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424067",
      "brgy_name": "Santa Catalina",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424068",
      "brgy_name": "Santa Cruz",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424069",
      "brgy_name": "Santa Felomina",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424070",
      "brgy_name": "Santa Isabel",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424071",
      "brgy_name": "Santa Maria Magdalena",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424072",
      "brgy_name": "Santa Veronica",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424073",
      "brgy_name": "Santiago I",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424074",
      "brgy_name": "Santiago II",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424075",
      "brgy_name": "Santisimo Rosario",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424076",
      "brgy_name": "Santo Angel",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424077",
      "brgy_name": "Santo Cristo",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424078",
      "brgy_name": "Santo Niño",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424079",
      "brgy_name": "Soledad",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424080",
      "brgy_name": "Atisan",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424081",
      "brgy_name": "Santa Elena",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424082",
      "brgy_name": "Santa Maria",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043424083",
      "brgy_name": "Santa Monica",
      "city_code": "043424",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425001",
      "brgy_name": "Bagong Silang",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425002",
      "brgy_name": "Cuyab",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425003",
      "brgy_name": "Estrella",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425004",
      "brgy_name": "G.S.I.S.",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425005",
      "brgy_name": "Landayan",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425006",
      "brgy_name": "Langgam",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425007",
      "brgy_name": "Laram",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425008",
      "brgy_name": "Magsaysay",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425010",
      "brgy_name": "Nueva",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425011",
      "brgy_name": "Poblacion",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425013",
      "brgy_name": "Riverside",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425014",
      "brgy_name": "San Antonio",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425015",
      "brgy_name": "San Roque",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425016",
      "brgy_name": "San Vicente",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425017",
      "brgy_name": "Santo Niño",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425018",
      "brgy_name": "United Bayanihan",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425019",
      "brgy_name": "United Better Living",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425020",
      "brgy_name": "Sampaguita Village",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425021",
      "brgy_name": "Calendola",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043425022",
      "brgy_name": "Narra",
      "city_code": "043425",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426001",
      "brgy_name": "Alipit",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426002",
      "brgy_name": "Bagumbayan",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426003",
      "brgy_name": "Bubukal",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426004",
      "brgy_name": "Calios",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426005",
      "brgy_name": "Duhat",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426006",
      "brgy_name": "Gatid",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426007",
      "brgy_name": "Jasaan",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426008",
      "brgy_name": "Labuin",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426009",
      "brgy_name": "Malinao",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426010",
      "brgy_name": "Oogong",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426011",
      "brgy_name": "Pagsawitan",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426012",
      "brgy_name": "Palasan",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426013",
      "brgy_name": "Patimbao",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426014",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426015",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426016",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426017",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426018",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426019",
      "brgy_name": "San Jose",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426020",
      "brgy_name": "San Juan",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426021",
      "brgy_name": "San Pablo Norte",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426022",
      "brgy_name": "San Pablo Sur",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426023",
      "brgy_name": "Santisima Cruz",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426024",
      "brgy_name": "Santo Angel Central",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426025",
      "brgy_name": "Santo Angel Norte",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043426026",
      "brgy_name": "Santo Angel Sur",
      "city_code": "043426",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427001",
      "brgy_name": "Adia",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427002",
      "brgy_name": "Bagong Pook",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427003",
      "brgy_name": "Bagumbayan",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427004",
      "brgy_name": "Bubukal",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427005",
      "brgy_name": "Cabooan",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427006",
      "brgy_name": "Calangay",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427007",
      "brgy_name": "Cambuja",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427008",
      "brgy_name": "Coralan",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427009",
      "brgy_name": "Cueva",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427010",
      "brgy_name": "Inayapan",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427011",
      "brgy_name": "Jose Laurel, Sr.",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427012",
      "brgy_name": "Kayhakat",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427013",
      "brgy_name": "Macasipac",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427014",
      "brgy_name": "Masinao",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427015",
      "brgy_name": "Mataling-Ting",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427016",
      "brgy_name": "Pao-o",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427018",
      "brgy_name": "Parang Ng Buho",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427019",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427020",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427021",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427022",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427023",
      "brgy_name": "Jose Rizal",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427024",
      "brgy_name": "Santiago",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427025",
      "brgy_name": "Talangka",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043427026",
      "brgy_name": "Tungkod",
      "city_code": "043427",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428001",
      "brgy_name": "Aplaya",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428002",
      "brgy_name": "Balibago",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428003",
      "brgy_name": "Caingin",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428004",
      "brgy_name": "Dila",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428005",
      "brgy_name": "Dita",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428006",
      "brgy_name": "Don Jose",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428007",
      "brgy_name": "Ibaba",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428008",
      "brgy_name": "Labas",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428009",
      "brgy_name": "Macabling",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428010",
      "brgy_name": "Malitlit",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428011",
      "brgy_name": "Malusak (Pob.)",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428012",
      "brgy_name": "Market Area (Pob.)",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428015",
      "brgy_name": "Kanluran (Pob.)",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428016",
      "brgy_name": "Pook",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428018",
      "brgy_name": "Pulong Santa Cruz",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428020",
      "brgy_name": "Santo Domingo",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428022",
      "brgy_name": "Sinalhan",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043428023",
      "brgy_name": "Tagapo",
      "city_code": "043428",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429001",
      "brgy_name": "Acevida",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429002",
      "brgy_name": "Bagong Pag-Asa (Pob.)",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429003",
      "brgy_name": "Bagumbarangay (Pob.)",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429005",
      "brgy_name": "Buhay",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429006",
      "brgy_name": "Gen. Luna",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429007",
      "brgy_name": "Halayhayin",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429008",
      "brgy_name": "Mendiola",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429009",
      "brgy_name": "Kapatalan",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429010",
      "brgy_name": "Laguio",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429011",
      "brgy_name": "Liyang",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429012",
      "brgy_name": "Llavac",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429013",
      "brgy_name": "Pandeno",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429015",
      "brgy_name": "Magsaysay",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429016",
      "brgy_name": "Macatad",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429017",
      "brgy_name": "Mayatba",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429019",
      "brgy_name": "P. Burgos",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429020",
      "brgy_name": "G. Redor (Pob.)",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429021",
      "brgy_name": "Salubungan",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429022",
      "brgy_name": "Wawa",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043429023",
      "brgy_name": "J. Rizal (Pob.)",
      "city_code": "043429",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430001",
      "brgy_name": "Banca-banca",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430002",
      "brgy_name": "Daniw",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430003",
      "brgy_name": "Masapang",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430004",
      "brgy_name": "Nanhaya (Pob.)",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430005",
      "brgy_name": "Pagalangan",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430007",
      "brgy_name": "San Benito",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430008",
      "brgy_name": "San Felix",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430009",
      "brgy_name": "San Francisco",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "043430010",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "043430",
      "province_code": "0434",
      "region_code": "04"
    },
    {
      "brgy_code": "045601001",
      "brgy_name": "Binagbag",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601002",
      "brgy_name": "Dayap",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601003",
      "brgy_name": "Ibabang Kinagunan",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601004",
      "brgy_name": "Ilayang Kinagunan",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601005",
      "brgy_name": "Kanlurang Calutan",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601006",
      "brgy_name": "Kanlurang Maligaya",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601008",
      "brgy_name": "Salvacion",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601009",
      "brgy_name": "Silangang Calutan",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601010",
      "brgy_name": "Silangang Maligaya",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601011",
      "brgy_name": "Sildora",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601012",
      "brgy_name": "Poblacion I",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045601013",
      "brgy_name": "Poblacion II",
      "city_code": "045601",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602001",
      "brgy_name": "Angeles",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602002",
      "brgy_name": "Bacong",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602003",
      "brgy_name": "Balungay",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602004",
      "brgy_name": "Buenavista",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602005",
      "brgy_name": "Caglate",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602006",
      "brgy_name": "Camagong",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602007",
      "brgy_name": "Gordon",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602008",
      "brgy_name": "Pambilan Norte",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602009",
      "brgy_name": "Pambilan Sur",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602010",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602011",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602012",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602013",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602014",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602015",
      "brgy_name": "Villa Esperanza",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602016",
      "brgy_name": "Villa Jesus Este",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602017",
      "brgy_name": "Villa Jesus Weste",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602018",
      "brgy_name": "Villa Norte",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045602019",
      "brgy_name": "Villa Victoria",
      "city_code": "045602",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603001",
      "brgy_name": "Angeles",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603002",
      "brgy_name": "Balubad",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603003",
      "brgy_name": "Balugohin",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603004",
      "brgy_name": "Barangay Zone 1 (Pob.)",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603005",
      "brgy_name": "Barangay Zone 2 (Pob.)",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603006",
      "brgy_name": "Barangay Zone 3 (Pob.)",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603007",
      "brgy_name": "Barangay Zone 4 (Pob.)",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603008",
      "brgy_name": "Buhangin",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603009",
      "brgy_name": "Caridad Ibaba",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603010",
      "brgy_name": "Caridad Ilaya",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603011",
      "brgy_name": "Habingan",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603012",
      "brgy_name": "Inaclagan",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603013",
      "brgy_name": "Inalig",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603014",
      "brgy_name": "Kilait",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603015",
      "brgy_name": "Kulawit",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603016",
      "brgy_name": "Lakip",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603017",
      "brgy_name": "Lubi",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603018",
      "brgy_name": "Lumutan",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603019",
      "brgy_name": "Magsaysay",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603020",
      "brgy_name": "Malinao Ibaba",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603021",
      "brgy_name": "Malinao Ilaya",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603022",
      "brgy_name": "Malusak",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603024",
      "brgy_name": "Manggalayan Bundok",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603025",
      "brgy_name": "Manggalayan Labak",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603026",
      "brgy_name": "Matanag",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603027",
      "brgy_name": "Montes Balaon",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603028",
      "brgy_name": "Montes Kallagan",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603029",
      "brgy_name": "Ponon",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603030",
      "brgy_name": "Rizal",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603031",
      "brgy_name": "San Andres Bundok",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603032",
      "brgy_name": "San Andres Labak",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603033",
      "brgy_name": "San Isidro",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603034",
      "brgy_name": "San Jose Balatok",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603035",
      "brgy_name": "San Rafael",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603036",
      "brgy_name": "Santa Catalina",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603037",
      "brgy_name": "Sapaan",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603038",
      "brgy_name": "Sokol",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603039",
      "brgy_name": "Tagbakin",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603040",
      "brgy_name": "Talaba",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603041",
      "brgy_name": "Tinandog",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603042",
      "brgy_name": "Villa Ibaba",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045603043",
      "brgy_name": "Villa Ilaya",
      "city_code": "045603",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605002",
      "brgy_name": "Bagong Silang",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605004",
      "brgy_name": "Batabat Norte",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605005",
      "brgy_name": "Batabat Sur",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605006",
      "brgy_name": "Buenavista",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605007",
      "brgy_name": "Bukal",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605008",
      "brgy_name": "Bulo",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605009",
      "brgy_name": "Cabong",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605010",
      "brgy_name": "Cadlit",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605011",
      "brgy_name": "Catulin",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605012",
      "brgy_name": "Cawa",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605013",
      "brgy_name": "De La Paz",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605014",
      "brgy_name": "Del Rosario",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605016",
      "brgy_name": "Hagonghong",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605017",
      "brgy_name": "Ibabang Wasay",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605018",
      "brgy_name": "Ilayang Wasay",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605019",
      "brgy_name": "Lilukin",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605020",
      "brgy_name": "Mabini",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605021",
      "brgy_name": "Mabutag",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605022",
      "brgy_name": "Magallanes",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605023",
      "brgy_name": "Maligaya (Esperanza)",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605024",
      "brgy_name": "Manlana",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605025",
      "brgy_name": "Masaya",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605026",
      "brgy_name": "Poblacion",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605027",
      "brgy_name": "Rizal",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605028",
      "brgy_name": "Sabang Pinamasagan",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605029",
      "brgy_name": "Sabang Piris",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605030",
      "brgy_name": "San Diego",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605031",
      "brgy_name": "San Isidro Ibaba",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605032",
      "brgy_name": "San Isidro Ilaya",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605033",
      "brgy_name": "San Pablo",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605034",
      "brgy_name": "San Pedro (Villa Rodrigo)",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605035",
      "brgy_name": "San Vicente",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605036",
      "brgy_name": "Siain",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605038",
      "brgy_name": "Villa Aurora",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605039",
      "brgy_name": "Villa Batabat",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605040",
      "brgy_name": "Villa Magsaysay",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045605042",
      "brgy_name": "Villa Veronica",
      "city_code": "045605",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606001",
      "brgy_name": "Aluyon",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606002",
      "brgy_name": "Amot",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606003",
      "brgy_name": "Anibawan",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606004",
      "brgy_name": "Bonifacio",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606005",
      "brgy_name": "Cabugao",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606006",
      "brgy_name": "Cabungalunan",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606007",
      "brgy_name": "Calutcot",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606008",
      "brgy_name": "Caniwan",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606009",
      "brgy_name": "Carlagan",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606010",
      "brgy_name": "Mabini",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606011",
      "brgy_name": "Palasan",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606012",
      "brgy_name": "Poblacion",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606013",
      "brgy_name": "Rizal",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045606014",
      "brgy_name": "San Rafael",
      "city_code": "045606",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607001",
      "brgy_name": "Agoho",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607002",
      "brgy_name": "Anahawan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607003",
      "brgy_name": "Anas",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607004",
      "brgy_name": "Apad Lutao",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607005",
      "brgy_name": "Apad Quezon",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607006",
      "brgy_name": "Apad Taisan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607007",
      "brgy_name": "Atulayan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607008",
      "brgy_name": "Baclaran",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607009",
      "brgy_name": "Bagong Silang",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607010",
      "brgy_name": "Balibago",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607011",
      "brgy_name": "Bangkuruhan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607012",
      "brgy_name": "Bantolinao",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607013",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607014",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607015",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607016",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607017",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607018",
      "brgy_name": "Bigaan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607019",
      "brgy_name": "Binutas",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607020",
      "brgy_name": "Biyan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607021",
      "brgy_name": "Bukal",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607022",
      "brgy_name": "Buli",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607023",
      "brgy_name": "Dapdap",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607024",
      "brgy_name": "Dominlog",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607026",
      "brgy_name": "Doña Aurora",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607027",
      "brgy_name": "Guinosayan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607029",
      "brgy_name": "Ipil",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607032",
      "brgy_name": "Kalibo (Santa Cruz)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607033",
      "brgy_name": "Kapaluhan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607034",
      "brgy_name": "Katangtang",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607035",
      "brgy_name": "Kigtan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607036",
      "brgy_name": "Kinamaligan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607037",
      "brgy_name": "Kinalin Ibaba",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607038",
      "brgy_name": "Kinalin Ilaya",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607039",
      "brgy_name": "Kumaludkud",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607040",
      "brgy_name": "Kunalum",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607041",
      "brgy_name": "Kuyaoyao",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607042",
      "brgy_name": "Lagay",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607043",
      "brgy_name": "Lainglaingan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607044",
      "brgy_name": "Lungib",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607045",
      "brgy_name": "Mabini",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607046",
      "brgy_name": "Madlangdungan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607047",
      "brgy_name": "Maglipad (Rosario)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607048",
      "brgy_name": "Maligaya",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607049",
      "brgy_name": "Mambaling",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607050",
      "brgy_name": "Manhulugin",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607051",
      "brgy_name": "Marilag (Punaya)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607053",
      "brgy_name": "Mulay",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607054",
      "brgy_name": "Pandanan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607055",
      "brgy_name": "Pansol",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607058",
      "brgy_name": "Patihan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607059",
      "brgy_name": "Pinagbayanan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607060",
      "brgy_name": "Pinagkamaligan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607061",
      "brgy_name": "Pinagsakahan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607062",
      "brgy_name": "Pinagtalleran",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607064",
      "brgy_name": "Rizal Ibaba",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607065",
      "brgy_name": "Rizal Ilaya",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607066",
      "brgy_name": "Sabang I",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607067",
      "brgy_name": "Sabang II",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607068",
      "brgy_name": "Salvacion",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607069",
      "brgy_name": "San Quintin",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607070",
      "brgy_name": "San Roque Ibaba",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607071",
      "brgy_name": "San Roque Ilaya",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607072",
      "brgy_name": "Santa Cecilia",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607073",
      "brgy_name": "Santa Maria",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607074",
      "brgy_name": "Santa Milagrosa",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607075",
      "brgy_name": "Santa Rosa",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607076",
      "brgy_name": "Santo Angel (Pangahoy)",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607077",
      "brgy_name": "Santo Domingo",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607078",
      "brgy_name": "Sinag",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607079",
      "brgy_name": "Sumilang",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607080",
      "brgy_name": "Sumulong",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607081",
      "brgy_name": "Tabansak",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607083",
      "brgy_name": "Talingting",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607084",
      "brgy_name": "Tamis",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607085",
      "brgy_name": "Tikiwan",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607086",
      "brgy_name": "Tiniguiban",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607087",
      "brgy_name": "Villa Magsino",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607088",
      "brgy_name": "Villa San Isidro",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607089",
      "brgy_name": "Viñas",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045607090",
      "brgy_name": "Yaganak",
      "city_code": "045607",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608001",
      "brgy_name": "Poblacion",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608002",
      "brgy_name": "Buenavista East",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608003",
      "brgy_name": "Buenavista West",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608004",
      "brgy_name": "Bukal Norte",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608005",
      "brgy_name": "Bukal Sur",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608006",
      "brgy_name": "Kinatihan I",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608007",
      "brgy_name": "Kinatihan II",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608008",
      "brgy_name": "Malabanban Norte",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608009",
      "brgy_name": "Malabanban Sur",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608010",
      "brgy_name": "Mangilag Norte",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608011",
      "brgy_name": "Mangilag Sur",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608012",
      "brgy_name": "Masalukot I",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608013",
      "brgy_name": "Masalukot II",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608014",
      "brgy_name": "Masalukot III",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608015",
      "brgy_name": "Masalukot IV",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608016",
      "brgy_name": "Masin Norte",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608017",
      "brgy_name": "Masin Sur",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608018",
      "brgy_name": "Mayabobo",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608019",
      "brgy_name": "Pahinga Norte",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608020",
      "brgy_name": "Pahinga Sur",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608022",
      "brgy_name": "San Andres",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608023",
      "brgy_name": "San Isidro",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608024",
      "brgy_name": "Santa Catalina Norte",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608025",
      "brgy_name": "Santa Catalina Sur",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045608026",
      "brgy_name": "Masalukot V",
      "city_code": "045608",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610001",
      "brgy_name": "Ajos",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610002",
      "brgy_name": "Anusan",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610003",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610004",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610005",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610006",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610007",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610008",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610009",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610010",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610011",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610012",
      "brgy_name": "Bolo",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610013",
      "brgy_name": "Bulagsong",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610014",
      "brgy_name": "Camandiison",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610015",
      "brgy_name": "Canculajao",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610016",
      "brgy_name": "Catumbo",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610017",
      "brgy_name": "Cawayanin Ibaba",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610018",
      "brgy_name": "Cawayanin Ilaya",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610019",
      "brgy_name": "Cutcutan",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610020",
      "brgy_name": "Dahican",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610021",
      "brgy_name": "Doongan Ibaba",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610022",
      "brgy_name": "Doongan Ilaya",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610023",
      "brgy_name": "Gatasan",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610024",
      "brgy_name": "Macpac",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610025",
      "brgy_name": "Madulao",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610026",
      "brgy_name": "Matandang Sabang Kanluran",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610027",
      "brgy_name": "Matandang Sabang Silangan",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610028",
      "brgy_name": "Milagrosa",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610029",
      "brgy_name": "Navitas",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610030",
      "brgy_name": "Pacabit",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610031",
      "brgy_name": "San Antonio Magkupa",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610032",
      "brgy_name": "San Antonio Pala",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610033",
      "brgy_name": "San Isidro",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610034",
      "brgy_name": "San Jose (Anyao)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610035",
      "brgy_name": "San Pablo (Suha)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610036",
      "brgy_name": "San Roque (Doyon)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610037",
      "brgy_name": "San Vicente Kanluran",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610038",
      "brgy_name": "San Vicente Silangan",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610039",
      "brgy_name": "Santa Maria (Dao)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610040",
      "brgy_name": "Tagabas Ibaba",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610041",
      "brgy_name": "Tagabas Ilaya",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610042",
      "brgy_name": "Tagbacan Ibaba",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610043",
      "brgy_name": "Tagbacan Ilaya",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610044",
      "brgy_name": "Tagbacan Silangan",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610045",
      "brgy_name": "Tuhian",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045610046",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "045610",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615001",
      "brgy_name": "Antonino (Ayusan)",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615002",
      "brgy_name": "Bagong Anyo (Pob.)",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615003",
      "brgy_name": "Bayanihan (Pob.)",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615004",
      "brgy_name": "Bulakin I",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615005",
      "brgy_name": "Bungoy",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615006",
      "brgy_name": "Cabatang",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615007",
      "brgy_name": "Dagatan",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615008",
      "brgy_name": "Kinabuhayan",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615009",
      "brgy_name": "Maligaya (Pob.)",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615010",
      "brgy_name": "Manggahan",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615011",
      "brgy_name": "Pinagdanlayan",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615012",
      "brgy_name": "Putol",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615013",
      "brgy_name": "San Mateo",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615014",
      "brgy_name": "Santa Lucia",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615015",
      "brgy_name": "Silanganan (Pob.)",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045615016",
      "brgy_name": "Bulakin II",
      "city_code": "045615",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616001",
      "brgy_name": "Bacong Ibaba",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616002",
      "brgy_name": "Bacong Ilaya",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616003",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616004",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616005",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616006",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616007",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616008",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616009",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616010",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616011",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616012",
      "brgy_name": "Lavides",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616013",
      "brgy_name": "Magsaysay",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616014",
      "brgy_name": "Malaya",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616015",
      "brgy_name": "Nieva",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616017",
      "brgy_name": "Recto",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616018",
      "brgy_name": "San Ignacio Ibaba",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616019",
      "brgy_name": "San Ignacio Ilaya",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616020",
      "brgy_name": "San Isidro Ibaba",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616021",
      "brgy_name": "San Isidro Ilaya",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616022",
      "brgy_name": "San Jose",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616023",
      "brgy_name": "San Nicolas",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616024",
      "brgy_name": "San Vicente",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616025",
      "brgy_name": "Santa Maria Ibaba",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616026",
      "brgy_name": "Santa Maria Ilaya",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616027",
      "brgy_name": "Sumilang",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045616028",
      "brgy_name": "Villarica",
      "city_code": "045616",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617001",
      "brgy_name": "Anoling",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617002",
      "brgy_name": "Banglos",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617003",
      "brgy_name": "Batangan",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617004",
      "brgy_name": "Catablingan",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617005",
      "brgy_name": "Canaway",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617006",
      "brgy_name": "Lumutan",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617007",
      "brgy_name": "Mahabang Lalim",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617008",
      "brgy_name": "Maigang",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617009",
      "brgy_name": "Maligaya",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617010",
      "brgy_name": "Magsikap",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617011",
      "brgy_name": "Minahan Norte",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617012",
      "brgy_name": "Minahan Sur",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617013",
      "brgy_name": "Pagsangahan",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617014",
      "brgy_name": "Pamplona",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617015",
      "brgy_name": "Pisa",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617016",
      "brgy_name": "Poblacion",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617017",
      "brgy_name": "Sablang",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617018",
      "brgy_name": "San Marcelino",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045617019",
      "brgy_name": "Umiray",
      "city_code": "045617",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618001",
      "brgy_name": "A. Mabini",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618002",
      "brgy_name": "Aloneros",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618003",
      "brgy_name": "Arbismen",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618004",
      "brgy_name": "Bagong Silang",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618005",
      "brgy_name": "Balinarin",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618006",
      "brgy_name": "Bukal Maligaya",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618007",
      "brgy_name": "Cabibihan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618008",
      "brgy_name": "Cabong Norte",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618009",
      "brgy_name": "Cabong Sur",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618010",
      "brgy_name": "Calimpak",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618011",
      "brgy_name": "Capuluan Central",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618012",
      "brgy_name": "Capuluan Tulon",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618013",
      "brgy_name": "Dancalan Caimawan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618014",
      "brgy_name": "Dancalan Central",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618015",
      "brgy_name": "Danlagan Batis",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618016",
      "brgy_name": "Danlagan Cabayao",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618017",
      "brgy_name": "Danlagan Central",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618018",
      "brgy_name": "Danlagan Reserva",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618019",
      "brgy_name": "Del Rosario",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618020",
      "brgy_name": "Dungawan Central",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618021",
      "brgy_name": "Dungawan Paalyunan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618022",
      "brgy_name": "Dungawan Pantay",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618023",
      "brgy_name": "Ermita",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618024",
      "brgy_name": "Gapas",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618025",
      "brgy_name": "Himbubulo Este",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618026",
      "brgy_name": "Himbubulo Weste",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618027",
      "brgy_name": "Hinabaan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618028",
      "brgy_name": "Ligpit Bantayan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618029",
      "brgy_name": "Lubigan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618030",
      "brgy_name": "Magallanes",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618031",
      "brgy_name": "Magsaysay",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618032",
      "brgy_name": "Manggagawa",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618033",
      "brgy_name": "Manggalang",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618034",
      "brgy_name": "Manlayo",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618035",
      "brgy_name": "Poblacion",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618036",
      "brgy_name": "Salakan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618037",
      "brgy_name": "San Antonio",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618038",
      "brgy_name": "San Isidro",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618039",
      "brgy_name": "San Jose",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618040",
      "brgy_name": "San Lorenzo",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618041",
      "brgy_name": "San Luis I",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618042",
      "brgy_name": "San Luis II",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618043",
      "brgy_name": "San Miguel",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618044",
      "brgy_name": "San Pedro I",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618045",
      "brgy_name": "San Pedro II",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618046",
      "brgy_name": "San Roque",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618047",
      "brgy_name": "Santa Cruz",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618048",
      "brgy_name": "Santa Maria",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618049",
      "brgy_name": "Santa Teresita",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618050",
      "brgy_name": "Sintones",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618051",
      "brgy_name": "Sisi",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618052",
      "brgy_name": "Tikay",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618053",
      "brgy_name": "Triumpo",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045618054",
      "brgy_name": "Villa Hiwasayan",
      "city_code": "045618",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619001",
      "brgy_name": "Adia Bitaog",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619002",
      "brgy_name": "Anonangin",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619003",
      "brgy_name": "Bagong Buhay (Pob.)",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619004",
      "brgy_name": "Bamban",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619006",
      "brgy_name": "Bantad",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619007",
      "brgy_name": "Batong Dalig",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619008",
      "brgy_name": "Biga",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619009",
      "brgy_name": "Binambang",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619010",
      "brgy_name": "Buensuceso",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619011",
      "brgy_name": "Bungahan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619012",
      "brgy_name": "Butaguin",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619013",
      "brgy_name": "Calumangin",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619014",
      "brgy_name": "Camohaguin",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619015",
      "brgy_name": "Casasahan Ibaba",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619016",
      "brgy_name": "Casasahan Ilaya",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619017",
      "brgy_name": "Cawayan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619018",
      "brgy_name": "Gayagayaan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619019",
      "brgy_name": "Gitnang Barrio",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619020",
      "brgy_name": "Hardinan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619021",
      "brgy_name": "Inaclagan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619022",
      "brgy_name": "Inagbuhan Ilaya",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619023",
      "brgy_name": "Hagakhakin",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619024",
      "brgy_name": "Labnig",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619025",
      "brgy_name": "Laguna",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619026",
      "brgy_name": "Mabini (Pob.)",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619027",
      "brgy_name": "Mabunga",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619028",
      "brgy_name": "Malabtog",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619029",
      "brgy_name": "Manlayaan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619030",
      "brgy_name": "Marcelo H. Del Pilar",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619031",
      "brgy_name": "Mataas Na Bundok",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619032",
      "brgy_name": "Maunlad (Pob.)",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619033",
      "brgy_name": "Pagsabangan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619034",
      "brgy_name": "Panikihan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619035",
      "brgy_name": "Peñafrancia (Pob.)",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619036",
      "brgy_name": "Pipisik (Pob.)",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619037",
      "brgy_name": "Progreso",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619038",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619039",
      "brgy_name": "Rosario",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619040",
      "brgy_name": "San Agustin",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619041",
      "brgy_name": "San Diego Poblacion",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619042",
      "brgy_name": "San Isidro Kanluran",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619043",
      "brgy_name": "San Isidro Silangan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619044",
      "brgy_name": "San Juan De Jesus",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619045",
      "brgy_name": "San Vicente",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619046",
      "brgy_name": "Sastre",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619047",
      "brgy_name": "Tabing Dagat (Pob.)",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619048",
      "brgy_name": "Tumayan",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619049",
      "brgy_name": "Villa Arcaya",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619050",
      "brgy_name": "Villa Bota",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619051",
      "brgy_name": "Villa Fuerte",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619052",
      "brgy_name": "Villa Mendoza",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619053",
      "brgy_name": "Villa Padua",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619054",
      "brgy_name": "Villa Perez",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619055",
      "brgy_name": "Villa M. Principe",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619056",
      "brgy_name": "Villa Tañada",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619057",
      "brgy_name": "Villa Victoria",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619058",
      "brgy_name": "San Diego",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619059",
      "brgy_name": "Villa Nava",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045619060",
      "brgy_name": "Lagyo",
      "city_code": "045619",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620001",
      "brgy_name": "Abiawin",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620002",
      "brgy_name": "Agos-agos",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620003",
      "brgy_name": "Alitas",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620004",
      "brgy_name": "Amolongin",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620005",
      "brgy_name": "Anibong",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620006",
      "brgy_name": "Antikin",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620007",
      "brgy_name": "Bacong",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620008",
      "brgy_name": "Balobo",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620009",
      "brgy_name": "Bantilan",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620010",
      "brgy_name": "Banugao",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620011",
      "brgy_name": "Poblacion 1 (Barangay 1)",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620012",
      "brgy_name": "Poblacion 38 (Poblacion Barangay 2)",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620013",
      "brgy_name": "Poblacion 39 (Poblacion Barangay 3)",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620014",
      "brgy_name": "Batican",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620015",
      "brgy_name": "Binonoan",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620016",
      "brgy_name": "Binulasan",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620017",
      "brgy_name": "Boboin",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620018",
      "brgy_name": "Comon",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620019",
      "brgy_name": "Dinahican",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620020",
      "brgy_name": "Gumian",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620021",
      "brgy_name": "Ilog",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620022",
      "brgy_name": "Ingas",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620023",
      "brgy_name": "Catambungan",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620024",
      "brgy_name": "Cawaynin",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620025",
      "brgy_name": "Langgas",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620026",
      "brgy_name": "Libjo",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620027",
      "brgy_name": "Lual",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620028",
      "brgy_name": "Magsaysay",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620029",
      "brgy_name": "Maypulot",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620030",
      "brgy_name": "Miswa",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620031",
      "brgy_name": "Pilaway",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620032",
      "brgy_name": "Pinaglapatan",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620033",
      "brgy_name": "Pulo",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620034",
      "brgy_name": "Silangan",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620035",
      "brgy_name": "Tongohin",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045620036",
      "brgy_name": "Tudturan",
      "city_code": "045620",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045621001",
      "brgy_name": "Bukal",
      "city_code": "045621",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045621002",
      "brgy_name": "Casuguran",
      "city_code": "045621",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045621003",
      "brgy_name": "Gango",
      "city_code": "045621",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045621004",
      "brgy_name": "Talisoy (Pob.)",
      "city_code": "045621",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045621005",
      "brgy_name": "Apad",
      "city_code": "045621",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622001",
      "brgy_name": "Bacungan",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622002",
      "brgy_name": "Bagacay",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622003",
      "brgy_name": "Banabahin Ibaba",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622004",
      "brgy_name": "Banabahin Ilaya",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622005",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622006",
      "brgy_name": "Gomez (Pob.)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622007",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622008",
      "brgy_name": "Talolong (Pob.)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622009",
      "brgy_name": "Bayabas",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622010",
      "brgy_name": "Bebito",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622011",
      "brgy_name": "Bigajo",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622012",
      "brgy_name": "Binahian A",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622013",
      "brgy_name": "Binahian B",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622014",
      "brgy_name": "Binahian C",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622015",
      "brgy_name": "Bocboc",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622016",
      "brgy_name": "Buenavista",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622017",
      "brgy_name": "Buyacanin",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622018",
      "brgy_name": "Cagacag",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622019",
      "brgy_name": "Calantipayan",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622020",
      "brgy_name": "Canda Ibaba",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622021",
      "brgy_name": "Canda Ilaya",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622022",
      "brgy_name": "Cawayan",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622023",
      "brgy_name": "Cawayanin",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622024",
      "brgy_name": "Cogorin Ibaba",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622025",
      "brgy_name": "Cogorin Ilaya",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622026",
      "brgy_name": "Concepcion",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622027",
      "brgy_name": "Danlagan",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622028",
      "brgy_name": "De La Paz",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622029",
      "brgy_name": "Del Pilar",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622030",
      "brgy_name": "Del Rosario",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622031",
      "brgy_name": "Esperanza Ibaba",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622032",
      "brgy_name": "Esperanza Ilaya",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622033",
      "brgy_name": "Guihay",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622034",
      "brgy_name": "Guinuangan",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622035",
      "brgy_name": "Guites",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622037",
      "brgy_name": "Hondagua",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622038",
      "brgy_name": "Ilayang Ilog A",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622039",
      "brgy_name": "Ilayang Ilog B",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622040",
      "brgy_name": "Inalusan",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622041",
      "brgy_name": "Jongo",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622042",
      "brgy_name": "Lalaguna",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622043",
      "brgy_name": "Lourdes",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622044",
      "brgy_name": "Mabanban",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622045",
      "brgy_name": "Mabini",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622046",
      "brgy_name": "Magallanes",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622047",
      "brgy_name": "Maguilayan",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622048",
      "brgy_name": "Mahayod-Hayod",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622049",
      "brgy_name": "Mal-ay",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622050",
      "brgy_name": "Mandoog",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622051",
      "brgy_name": "Manguisian",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622052",
      "brgy_name": "Matinik",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622053",
      "brgy_name": "Monteclaro",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622054",
      "brgy_name": "Pamampangin",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622055",
      "brgy_name": "Pansol",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622056",
      "brgy_name": "Peñafrancia",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622057",
      "brgy_name": "Pisipis",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622058",
      "brgy_name": "Rizal (Rural)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622059",
      "brgy_name": "Roma",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622060",
      "brgy_name": "Rosario",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622061",
      "brgy_name": "Samat",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622062",
      "brgy_name": "San Andres",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622063",
      "brgy_name": "San Antonio",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622064",
      "brgy_name": "San Francisco A",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622065",
      "brgy_name": "San Francisco B",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622066",
      "brgy_name": "San Isidro",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622067",
      "brgy_name": "San Jose",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622068",
      "brgy_name": "San Miguel (Dao)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622069",
      "brgy_name": "San Pedro",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622070",
      "brgy_name": "San Rafael",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622071",
      "brgy_name": "San Roque",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622072",
      "brgy_name": "Santa Catalina",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622073",
      "brgy_name": "Santa Elena",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622074",
      "brgy_name": "Santa Jacobe",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622075",
      "brgy_name": "Santa Lucia",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622076",
      "brgy_name": "Santa Maria",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622077",
      "brgy_name": "Santa Rosa",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622078",
      "brgy_name": "Santo Niño Ibaba",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622079",
      "brgy_name": "Santo Niño Ilaya",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622080",
      "brgy_name": "Silang",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622081",
      "brgy_name": "Sugod",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622082",
      "brgy_name": "Sumalang",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622083",
      "brgy_name": "Tan-ag Ibaba",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622084",
      "brgy_name": "Tan-ag Ilaya",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622085",
      "brgy_name": "Tocalin",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622086",
      "brgy_name": "Vegaflor",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622087",
      "brgy_name": "Vergaña",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622088",
      "brgy_name": "Veronica",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622089",
      "brgy_name": "Villa Aurora",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622090",
      "brgy_name": "Villa Espina",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622091",
      "brgy_name": "Villa Hermosa",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622092",
      "brgy_name": "Villa Geda",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622093",
      "brgy_name": "Villamonte",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622094",
      "brgy_name": "Villanacaob",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622095",
      "brgy_name": "Rizal (Poblacion)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045622096",
      "brgy_name": "Santa Teresa (Mayupag)",
      "city_code": "045622",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623001",
      "brgy_name": "Abang",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623002",
      "brgy_name": "Aliliw",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623003",
      "brgy_name": "Atulinao",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623004",
      "brgy_name": "Ayuti",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623005",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623006",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623007",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623008",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623009",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623010",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623011",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623012",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623013",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623014",
      "brgy_name": "Igang",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623015",
      "brgy_name": "Kabatete",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623016",
      "brgy_name": "Kakawit",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623017",
      "brgy_name": "Kalangay",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623018",
      "brgy_name": "Kalyaat",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623019",
      "brgy_name": "Kilib",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623020",
      "brgy_name": "Kulapi",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623021",
      "brgy_name": "Mahabang Parang",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623022",
      "brgy_name": "Malupak",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623023",
      "brgy_name": "Manasa",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623024",
      "brgy_name": "May-It",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623025",
      "brgy_name": "Nagsinamo",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623026",
      "brgy_name": "Nalunao",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623027",
      "brgy_name": "Palola",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623028",
      "brgy_name": "Piis",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623029",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623030",
      "brgy_name": "Samil",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623031",
      "brgy_name": "Tiawe",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045623032",
      "brgy_name": "Tinamnan",
      "city_code": "045623",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624002",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624003",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624004",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624005",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624006",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624007",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624008",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624009",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624010",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624011",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624012",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624013",
      "brgy_name": "Barra",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624014",
      "brgy_name": "Bocohan",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624015",
      "brgy_name": "Mayao Castillo",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624016",
      "brgy_name": "Cotta",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624017",
      "brgy_name": "Gulang-gulang",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624018",
      "brgy_name": "Dalahican",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624019",
      "brgy_name": "Domoit",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624020",
      "brgy_name": "Ibabang Dupay",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624021",
      "brgy_name": "Ibabang Iyam",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624022",
      "brgy_name": "Ibabang Talim",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624023",
      "brgy_name": "Ilayang Dupay",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624024",
      "brgy_name": "Ilayang Iyam",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624025",
      "brgy_name": "Ilayang Talim",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624026",
      "brgy_name": "Isabang",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624027",
      "brgy_name": "Mayao Crossing",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624028",
      "brgy_name": "Mayao Kanluran",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624029",
      "brgy_name": "Mayao Parada",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624030",
      "brgy_name": "Mayao Silangan",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624032",
      "brgy_name": "Ransohan",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624033",
      "brgy_name": "Salinas",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624034",
      "brgy_name": "Talao-talao",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045624035",
      "brgy_name": "Market View",
      "city_code": "045624",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625001",
      "brgy_name": "Amontay",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625002",
      "brgy_name": "Anos",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625003",
      "brgy_name": "Buyao",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625004",
      "brgy_name": "Candangal",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625005",
      "brgy_name": "Calantas",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625006",
      "brgy_name": "Lahing",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625007",
      "brgy_name": "Luctob",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625008",
      "brgy_name": "Mabini Ibaba",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625009",
      "brgy_name": "Mabini Ilaya",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625010",
      "brgy_name": "Malabahay",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625011",
      "brgy_name": "Mambog",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625012",
      "brgy_name": "Olongtao Ibaba",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625013",
      "brgy_name": "Olongtao Ilaya",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625014",
      "brgy_name": "Padre Herrera",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625015",
      "brgy_name": "Pajarillo",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625016",
      "brgy_name": "Pinagbayanan",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625017",
      "brgy_name": "Rodriquez (Pob.)",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625018",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625019",
      "brgy_name": "Castillo (Pob.)",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625020",
      "brgy_name": "Pag-Asa (Pob.)",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625021",
      "brgy_name": "Masipag (Pob.)",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625022",
      "brgy_name": "Damayan (Pob.)",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625023",
      "brgy_name": "San Isidro",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625024",
      "brgy_name": "San Jose",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625025",
      "brgy_name": "San Nicolas",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625026",
      "brgy_name": "San Vicente",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625027",
      "brgy_name": "Taguin",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625028",
      "brgy_name": "Tubigan Ibaba",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625029",
      "brgy_name": "Tubigan Ilaya",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045625030",
      "brgy_name": "Vista Hermosa",
      "city_code": "045625",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627001",
      "brgy_name": "Abo-abo",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627002",
      "brgy_name": "Alitap",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627003",
      "brgy_name": "Baao",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627004",
      "brgy_name": "Balaybalay",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627005",
      "brgy_name": "Bato",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627006",
      "brgy_name": "Cagbalete I",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627007",
      "brgy_name": "Cagbalete II",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627008",
      "brgy_name": "Cagsiay I",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627009",
      "brgy_name": "Cagsiay II",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627010",
      "brgy_name": "Cagsiay III",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627011",
      "brgy_name": "Concepcion",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627012",
      "brgy_name": "Liwayway",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627013",
      "brgy_name": "Lucutan",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627014",
      "brgy_name": "Luya-luya",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627015",
      "brgy_name": "Macasin",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627017",
      "brgy_name": "Lual (Pob.)",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627018",
      "brgy_name": "Mabato (Pob.)",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627019",
      "brgy_name": "Daungan (Pob.)",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627020",
      "brgy_name": "Bagong Bayan (Pob.)",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627021",
      "brgy_name": "Sadsaran (Pob.)",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627022",
      "brgy_name": "Rizaliana (Pob.)",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627024",
      "brgy_name": "Polo",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627025",
      "brgy_name": "Remedios I",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627026",
      "brgy_name": "Remedios II",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627027",
      "brgy_name": "Rosario",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627028",
      "brgy_name": "San Gabriel",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627029",
      "brgy_name": "San Isidro",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627030",
      "brgy_name": "San Jose",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627031",
      "brgy_name": "San Lorenzo",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627032",
      "brgy_name": "San Miguel",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627033",
      "brgy_name": "San Rafael",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627034",
      "brgy_name": "San Roque",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627035",
      "brgy_name": "San Vicente",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627036",
      "brgy_name": "Santa Lucia",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627037",
      "brgy_name": "Santo Angel",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627038",
      "brgy_name": "Santo Niño",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627039",
      "brgy_name": "Santol",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627040",
      "brgy_name": "Soledad",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627041",
      "brgy_name": "Tapucan",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045627042",
      "brgy_name": "Lual Rural",
      "city_code": "045627",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628001",
      "brgy_name": "Ajos",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628002",
      "brgy_name": "Amuguis",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628003",
      "brgy_name": "Anonang",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628004",
      "brgy_name": "Bagong Silang",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628005",
      "brgy_name": "Bagupaye",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628006",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628008",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628009",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628010",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628012",
      "brgy_name": "Bolo",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628013",
      "brgy_name": "Buenavista",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628014",
      "brgy_name": "Burgos",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628015",
      "brgy_name": "Butanyog",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628016",
      "brgy_name": "Canuyep",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628017",
      "brgy_name": "F. Nanadiego",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628018",
      "brgy_name": "Ibabang Cambuga",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628019",
      "brgy_name": "Ibabang Yuni",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628020",
      "brgy_name": "Ilayang Cambuga (Mabini)",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628021",
      "brgy_name": "Ilayang Yuni",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628022",
      "brgy_name": "Latangan",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628024",
      "brgy_name": "Magsaysay",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628025",
      "brgy_name": "Matataja",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628026",
      "brgy_name": "Pakiing",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628027",
      "brgy_name": "Patabog",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628028",
      "brgy_name": "Sagongon",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628029",
      "brgy_name": "San Isidro",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628030",
      "brgy_name": "San Pedro",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045628031",
      "brgy_name": "Santa Rosa",
      "city_code": "045628",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629001",
      "brgy_name": "Cabuyao Norte",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629002",
      "brgy_name": "Cabuyao Sur",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629003",
      "brgy_name": "Danlagan",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629004",
      "brgy_name": "Duhat",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629005",
      "brgy_name": "Hinguiwin",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629006",
      "brgy_name": "Kinagunan Ibaba",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629007",
      "brgy_name": "Kinagunan Ilaya",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629008",
      "brgy_name": "Lipata",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629009",
      "brgy_name": "Marao",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629010",
      "brgy_name": "Marquez",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629011",
      "brgy_name": "Burgos (Pob.)",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629012",
      "brgy_name": "Campo (Pob.)",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629013",
      "brgy_name": "Basiao (Pob.)",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629014",
      "brgy_name": "Punta (Pob.)",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629015",
      "brgy_name": "Rizal",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629016",
      "brgy_name": "San Isidro",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629017",
      "brgy_name": "San Vicente",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629018",
      "brgy_name": "Sipa",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629019",
      "brgy_name": "Tulay Buhangin",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629020",
      "brgy_name": "Villapaz",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629021",
      "brgy_name": "Walay",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045629022",
      "brgy_name": "Yawe",
      "city_code": "045629",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630001",
      "brgy_name": "Alupaye",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630002",
      "brgy_name": "Añato",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630003",
      "brgy_name": "Antipolo",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630004",
      "brgy_name": "Bantigue",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630005",
      "brgy_name": "Bigo",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630006",
      "brgy_name": "Binahaan",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630007",
      "brgy_name": "Bukal",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630008",
      "brgy_name": "Ibabang Bagumbungan",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630009",
      "brgy_name": "Ibabang Palsabangon",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630010",
      "brgy_name": "Ibabang Polo",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630011",
      "brgy_name": "Ikirin",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630012",
      "brgy_name": "Ilayang Bagumbungan",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630013",
      "brgy_name": "Ilayang Palsabangon",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630014",
      "brgy_name": "Ilayang Polo",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630015",
      "brgy_name": "Kanluran Malicboy",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630016",
      "brgy_name": "Mapagong",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630017",
      "brgy_name": "Mayhay",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630018",
      "brgy_name": "Pinagbayanan",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630019",
      "brgy_name": "Barangay 1 Castillo (Pob.)",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630020",
      "brgy_name": "Barangay 2 Daungan (Pob.)",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630021",
      "brgy_name": "Barangay 3 Del Carmen (Pob.)",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630022",
      "brgy_name": "Barangay 4 Parang (Pob.)",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630023",
      "brgy_name": "Barangay 5 Santa Catalina (Pob.)",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630024",
      "brgy_name": "Barangay 6 Tambak (Pob.)",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630025",
      "brgy_name": "Silangan Malicboy",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630026",
      "brgy_name": "Talipan",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045630027",
      "brgy_name": "Tukalan",
      "city_code": "045630",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631001",
      "brgy_name": "Balungay",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631002",
      "brgy_name": "Bato",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631003",
      "brgy_name": "Bonbon",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631004",
      "brgy_name": "Calasumanga",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631005",
      "brgy_name": "Kinalagti",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631006",
      "brgy_name": "Libo",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631007",
      "brgy_name": "Lipata",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631008",
      "brgy_name": "Matangkap",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631009",
      "brgy_name": "Milawid",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631010",
      "brgy_name": "Pagitan",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631011",
      "brgy_name": "Pandan",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045631012",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "045631",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045632001",
      "brgy_name": "Amaga",
      "city_code": "045632",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045632002",
      "brgy_name": "Busdak",
      "city_code": "045632",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045632003",
      "brgy_name": "Kilogan",
      "city_code": "045632",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045632004",
      "brgy_name": "Luod",
      "city_code": "045632",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045632005",
      "brgy_name": "Patnanungan Norte",
      "city_code": "045632",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045632006",
      "brgy_name": "Patnanungan Sur (Pob.)",
      "city_code": "045632",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633001",
      "brgy_name": "Maabot",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633002",
      "brgy_name": "Mainit Norte",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633003",
      "brgy_name": "Mainit Sur",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633004",
      "brgy_name": "Pambuhan",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633005",
      "brgy_name": "Pinagtubigan Este",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633006",
      "brgy_name": "Pinagtubigan Weste",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633007",
      "brgy_name": "Pagkakaisa Pob. (Barangay 1)",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633008",
      "brgy_name": "Mapagmahal Pob. (Barangay 2)",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633009",
      "brgy_name": "Bagong Pag-Asa Pob. (Barangay 3)",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633010",
      "brgy_name": "Bagong Silang Pob. (Barangay 4)",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633011",
      "brgy_name": "Rizal",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633012",
      "brgy_name": "Sangirin",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633013",
      "brgy_name": "Villamanzano Norte",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045633014",
      "brgy_name": "Villamanzano Sur",
      "city_code": "045633",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634001",
      "brgy_name": "Amontay",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634003",
      "brgy_name": "Cometa",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634004",
      "brgy_name": "Biga",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634005",
      "brgy_name": "Bilucao",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634006",
      "brgy_name": "Cabulihan",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634007",
      "brgy_name": "Cawayanin",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634008",
      "brgy_name": "Gangahin",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634009",
      "brgy_name": "Ibabang Burgos",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634010",
      "brgy_name": "Ibabang Pacatin",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634011",
      "brgy_name": "Ibabang Piña",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634012",
      "brgy_name": "Ibabang Soliyao",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634013",
      "brgy_name": "Ilayang Burgos",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634014",
      "brgy_name": "Ilayang Pacatin",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634015",
      "brgy_name": "Ilayang Piña",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634016",
      "brgy_name": "Ilayang Soliyao",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634017",
      "brgy_name": "Nag-Cruz",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634018",
      "brgy_name": "Osmeña",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634019",
      "brgy_name": "Payte",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634020",
      "brgy_name": "Pinagbayanan",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634021",
      "brgy_name": "Masaya (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634022",
      "brgy_name": "Manggahan (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634023",
      "brgy_name": "Dulong Bayan (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634024",
      "brgy_name": "Pag-Asa (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634025",
      "brgy_name": "Castillo (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634026",
      "brgy_name": "Maaliw (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634027",
      "brgy_name": "Mayubok (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634028",
      "brgy_name": "Pamilihan (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634029",
      "brgy_name": "Dalampasigan (Pob.)",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634030",
      "brgy_name": "Poctol",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634031",
      "brgy_name": "Quezon",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634032",
      "brgy_name": "Quinagasan",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634033",
      "brgy_name": "Rizalino",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634034",
      "brgy_name": "Saguinsinan",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634035",
      "brgy_name": "Sampaloc",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634036",
      "brgy_name": "San Roque",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634037",
      "brgy_name": "Sisirin",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634038",
      "brgy_name": "Sumag Este",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634039",
      "brgy_name": "Sumag Norte",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045634040",
      "brgy_name": "Sumag Weste",
      "city_code": "045634",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635002",
      "brgy_name": "Concepcion",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635003",
      "brgy_name": "Duhat",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635004",
      "brgy_name": "Ilaya",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635005",
      "brgy_name": "Ilosong",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635007",
      "brgy_name": "Tanauan",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635008",
      "brgy_name": "Central (Pob.)",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635009",
      "brgy_name": "Paang Bundok (Pob.)",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635010",
      "brgy_name": "Pampaaralan (Pob.)",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045635011",
      "brgy_name": "M. L. Tumagay Pob. (Remas Mabuton)",
      "city_code": "045635",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636001",
      "brgy_name": "Anawan",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636002",
      "brgy_name": "Atulayan",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636003",
      "brgy_name": "Balesin",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636004",
      "brgy_name": "Bañadero",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636005",
      "brgy_name": "Binibitinan",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636006",
      "brgy_name": "Bislian",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636007",
      "brgy_name": "Bucao",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636008",
      "brgy_name": "Canicanian",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636009",
      "brgy_name": "Kalubakis",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636010",
      "brgy_name": "Languyin",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636011",
      "brgy_name": "Libjo",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636012",
      "brgy_name": "Pamatdan",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636013",
      "brgy_name": "Pilion",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636014",
      "brgy_name": "Pinaglubayan",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636015",
      "brgy_name": "Poblacion",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636016",
      "brgy_name": "Sabang",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636017",
      "brgy_name": "Salipsip",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636018",
      "brgy_name": "Sibulan",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636019",
      "brgy_name": "Taluong",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045636020",
      "brgy_name": "Tamulaya-Anitong",
      "city_code": "045636",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637001",
      "brgy_name": "Apad",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637002",
      "brgy_name": "Argosino",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637003",
      "brgy_name": "Cagbalogo",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637004",
      "brgy_name": "Caridad",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637005",
      "brgy_name": "Cometa",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637006",
      "brgy_name": "Del Pilar",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637007",
      "brgy_name": "Guinhawa",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637008",
      "brgy_name": "Gumubat",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637009",
      "brgy_name": "Magsino",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637010",
      "brgy_name": "Mascariña",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637011",
      "brgy_name": "Montaña",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637012",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637013",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637014",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637015",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637016",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637017",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637018",
      "brgy_name": "Sabang",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637019",
      "brgy_name": "Silangan",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637020",
      "brgy_name": "Tagkawa",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637021",
      "brgy_name": "Villa Belen",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637022",
      "brgy_name": "Villa Francia",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637023",
      "brgy_name": "Villa Gomez",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045637024",
      "brgy_name": "Villa Mercedes",
      "city_code": "045637",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638001",
      "brgy_name": "Poblacion I (Barangay 1)",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638002",
      "brgy_name": "Capalong",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638003",
      "brgy_name": "Cawayan",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638004",
      "brgy_name": "Kiloloran",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638005",
      "brgy_name": "Llavac",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638006",
      "brgy_name": "Lubayat",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638007",
      "brgy_name": "Malapad",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638008",
      "brgy_name": "Maragondon",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638009",
      "brgy_name": "Pandan",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638011",
      "brgy_name": "Tanauan",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638012",
      "brgy_name": "Tignoan",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638013",
      "brgy_name": "Ungos",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638014",
      "brgy_name": "Poblacion 61 (Barangay 2)",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638015",
      "brgy_name": "Maunlad",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638016",
      "brgy_name": "Bagong Silang",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638017",
      "brgy_name": "Masikap",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045638018",
      "brgy_name": "Tagumpay",
      "city_code": "045638",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639001",
      "brgy_name": "Alupay",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639002",
      "brgy_name": "Apasan",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639003",
      "brgy_name": "Banot",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639008",
      "brgy_name": "Bataan",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639009",
      "brgy_name": "Bayongon",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639010",
      "brgy_name": "Bilucao",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639011",
      "brgy_name": "Caldong",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639012",
      "brgy_name": "Ibabang Owain",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639013",
      "brgy_name": "Ilayang Owain",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639014",
      "brgy_name": "Mamala",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639016",
      "brgy_name": "San Bueno",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639017",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639018",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045639019",
      "brgy_name": "Taquico",
      "city_code": "045639",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045640001",
      "brgy_name": "Alibihaban",
      "city_code": "045640",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045640002",
      "brgy_name": "Camflora",
      "city_code": "045640",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045640003",
      "brgy_name": "Mangero",
      "city_code": "045640",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045640004",
      "brgy_name": "Pansoy",
      "city_code": "045640",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045640005",
      "brgy_name": "Tala",
      "city_code": "045640",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045640006",
      "brgy_name": "Talisay",
      "city_code": "045640",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045640007",
      "brgy_name": "Poblacion",
      "city_code": "045640",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641001",
      "brgy_name": "Arawan",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641002",
      "brgy_name": "Bagong Niing",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641003",
      "brgy_name": "Balat Atis",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641004",
      "brgy_name": "Briones",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641005",
      "brgy_name": "Bulihan",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641006",
      "brgy_name": "Buliran",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641007",
      "brgy_name": "Callejon",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641008",
      "brgy_name": "Corazon",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641009",
      "brgy_name": "Manuel del Valle, Sr.",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641010",
      "brgy_name": "Loob",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641011",
      "brgy_name": "Magsaysay",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641012",
      "brgy_name": "Matipunso",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641013",
      "brgy_name": "Niing",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641014",
      "brgy_name": "Poblacion",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641015",
      "brgy_name": "Pulo",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641016",
      "brgy_name": "Pury",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641017",
      "brgy_name": "Sampaga",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641018",
      "brgy_name": "Sampaguita",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641019",
      "brgy_name": "San Jose",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045641020",
      "brgy_name": "Sinturisan",
      "city_code": "045641",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642001",
      "brgy_name": "Butanguiad",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642003",
      "brgy_name": "Casay",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642004",
      "brgy_name": "Cawayan I",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642005",
      "brgy_name": "Cawayan II",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642006",
      "brgy_name": "Huyon-Uyon",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642007",
      "brgy_name": "Ibabang Tayuman",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642008",
      "brgy_name": "Ilayang Tayuman",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642009",
      "brgy_name": "Inabuan",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642010",
      "brgy_name": "Nasalaan",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642011",
      "brgy_name": "Pagsangahan",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642012",
      "brgy_name": "Poblacion",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642013",
      "brgy_name": "Pugon",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642014",
      "brgy_name": "Silongin",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642015",
      "brgy_name": "Don Juan Vercelos",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642016",
      "brgy_name": "Mabuñga",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045642017",
      "brgy_name": "Santo Niño",
      "city_code": "045642",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644001",
      "brgy_name": "Abuyon",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644002",
      "brgy_name": "Andres Bonifacio",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644003",
      "brgy_name": "Bani",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644004",
      "brgy_name": "Binay",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644005",
      "brgy_name": "Buenavista",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644006",
      "brgy_name": "Busokbusokan",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644008",
      "brgy_name": "Calwit",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644009",
      "brgy_name": "Guinhalinan",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644010",
      "brgy_name": "Lacdayan",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644011",
      "brgy_name": "Maguiting",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644012",
      "brgy_name": "Manlampong",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644013",
      "brgy_name": "Pagkakaisa (Pob.)",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644014",
      "brgy_name": "Maligaya (Pob.)",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644015",
      "brgy_name": "Bayanihan (Pob.)",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644016",
      "brgy_name": "Pagdadamayan (Pob.)",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644017",
      "brgy_name": "Punta",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644018",
      "brgy_name": "Rizal",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644019",
      "brgy_name": "San Isidro",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644020",
      "brgy_name": "San Juan",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644021",
      "brgy_name": "San Vicente",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644022",
      "brgy_name": "Vigo Central",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644023",
      "brgy_name": "Villa Aurin (Pinagsama)",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644024",
      "brgy_name": "Villa Reyes",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045644025",
      "brgy_name": "White Cliff",
      "city_code": "045644",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645001",
      "brgy_name": "Antipolo",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645002",
      "brgy_name": "Balubal",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645003",
      "brgy_name": "Concepcion Pinagbakuran",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645006",
      "brgy_name": "Bignay 1",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645007",
      "brgy_name": "Bignay 2",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645009",
      "brgy_name": "Bucal",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645010",
      "brgy_name": "Canda",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645011",
      "brgy_name": "Castañas",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645012",
      "brgy_name": "Concepcion Banahaw",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645013",
      "brgy_name": "Concepcion No. 1",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645014",
      "brgy_name": "Concepcion Palasan",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645015",
      "brgy_name": "Gibanga",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645016",
      "brgy_name": "Guisguis-San Roque",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645017",
      "brgy_name": "Guisguis-Talon",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645018",
      "brgy_name": "Janagdong 1",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645019",
      "brgy_name": "Janagdong 2",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645020",
      "brgy_name": "Limbon",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645021",
      "brgy_name": "Lutucan Bata",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645022",
      "brgy_name": "Lutucan Malabag",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645023",
      "brgy_name": "Lutucan 1",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645024",
      "brgy_name": "Mamala I",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645025",
      "brgy_name": "Manggalang 1",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645026",
      "brgy_name": "Manggalang Tulo-tulo",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645027",
      "brgy_name": "Manggalang-Bantilan",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645028",
      "brgy_name": "Manggalang-Kiling",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645029",
      "brgy_name": "Montecillo",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645030",
      "brgy_name": "Morong",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645032",
      "brgy_name": "Pili",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645033",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645034",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645035",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645036",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645037",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645038",
      "brgy_name": "Sampaloc Bogon",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645039",
      "brgy_name": "Sampaloc 1",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645040",
      "brgy_name": "Sampaloc 2",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645041",
      "brgy_name": "Sampaloc Santo Cristo",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645042",
      "brgy_name": "Talaan Aplaya",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645043",
      "brgy_name": "Talaanpantoc",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645044",
      "brgy_name": "Tumbaga 1",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645045",
      "brgy_name": "Tumbaga 2",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645046",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045645047",
      "brgy_name": "Mamala II",
      "city_code": "045645",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646001",
      "brgy_name": "Aldavoc",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646002",
      "brgy_name": "Aliji",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646003",
      "brgy_name": "Bagong Silang",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646004",
      "brgy_name": "Bamban",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646005",
      "brgy_name": "Bosigon",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646006",
      "brgy_name": "Bukal",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646007",
      "brgy_name": "Cabugwang",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646008",
      "brgy_name": "Cagascas",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646009",
      "brgy_name": "Casispalan",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646010",
      "brgy_name": "Colong-colong",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646011",
      "brgy_name": "Del Rosario",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646012",
      "brgy_name": "Cabibihan",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646013",
      "brgy_name": "Candalapdap",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646014",
      "brgy_name": "Katimo",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646015",
      "brgy_name": "Kinatakutan",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646016",
      "brgy_name": "Landing",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646017",
      "brgy_name": "Laurel",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646018",
      "brgy_name": "Magsaysay",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646019",
      "brgy_name": "Maguibuay",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646020",
      "brgy_name": "Mahinta",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646021",
      "brgy_name": "Malbog",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646022",
      "brgy_name": "Manato Central",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646023",
      "brgy_name": "Manato Station",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646024",
      "brgy_name": "Mangayao",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646025",
      "brgy_name": "Mansilay",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646026",
      "brgy_name": "Mapulot",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646027",
      "brgy_name": "Munting Parang",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646028",
      "brgy_name": "Payapa",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646029",
      "brgy_name": "Poblacion",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646030",
      "brgy_name": "Rizal",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646031",
      "brgy_name": "Sabang",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646032",
      "brgy_name": "San Diego",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646033",
      "brgy_name": "San Francisco",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646034",
      "brgy_name": "San Isidro",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646035",
      "brgy_name": "San Roque",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646036",
      "brgy_name": "San Vicente",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646037",
      "brgy_name": "Santa Cecilia",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646038",
      "brgy_name": "Santa Monica",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646039",
      "brgy_name": "Santo Niño I",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646040",
      "brgy_name": "Santo Niño II",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646041",
      "brgy_name": "Santo Tomas",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646042",
      "brgy_name": "Seguiwan",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646043",
      "brgy_name": "Tabason",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646044",
      "brgy_name": "Tunton",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045646045",
      "brgy_name": "Victoria",
      "city_code": "045646",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647001",
      "brgy_name": "Alitao",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647002",
      "brgy_name": "Alupay",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647003",
      "brgy_name": "Angeles Zone I (Pob.)",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647004",
      "brgy_name": "Angeles Zone II",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647005",
      "brgy_name": "Angeles Zone III",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647006",
      "brgy_name": "Angeles Zone IV",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647007",
      "brgy_name": "Angustias Zone I (Pob.)",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647008",
      "brgy_name": "Angustias Zone II",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647009",
      "brgy_name": "Angustias Zone III",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647010",
      "brgy_name": "Angustias Zone IV",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647011",
      "brgy_name": "Anos",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647012",
      "brgy_name": "Ayaas",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647013",
      "brgy_name": "Baguio",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647014",
      "brgy_name": "Banilad",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647015",
      "brgy_name": "Calantas",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647016",
      "brgy_name": "Camaysa",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647017",
      "brgy_name": "Dapdap",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647018",
      "brgy_name": "Gibanga",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647019",
      "brgy_name": "Alsam Ibaba",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647020",
      "brgy_name": "Bukal Ibaba",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647021",
      "brgy_name": "Ilasan Ibaba",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647022",
      "brgy_name": "Nangka Ibaba",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647023",
      "brgy_name": "Palale Ibaba",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647024",
      "brgy_name": "Ibas",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647025",
      "brgy_name": "Alsam Ilaya",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647026",
      "brgy_name": "Bukal Ilaya",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647027",
      "brgy_name": "Ilasan Ilaya",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647028",
      "brgy_name": "Nangka Ilaya",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647029",
      "brgy_name": "Palale Ilaya",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647030",
      "brgy_name": "Ipilan",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647031",
      "brgy_name": "Isabang",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647032",
      "brgy_name": "Calumpang",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647033",
      "brgy_name": "Domoit Kanluran",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647034",
      "brgy_name": "Katigan Kanluran",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647035",
      "brgy_name": "Palale Kanluran",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647036",
      "brgy_name": "Lakawan",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647037",
      "brgy_name": "Lalo",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647038",
      "brgy_name": "Lawigue",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647039",
      "brgy_name": "Lita (Pob.)",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647040",
      "brgy_name": "Malaoa",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647041",
      "brgy_name": "Masin",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647042",
      "brgy_name": "Mate",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647043",
      "brgy_name": "Mateuna",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647044",
      "brgy_name": "Mayowe",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647045",
      "brgy_name": "Opias",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647046",
      "brgy_name": "Pandakaki",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647047",
      "brgy_name": "Pook",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647048",
      "brgy_name": "Potol",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647049",
      "brgy_name": "San Diego Zone I (Pob.)",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647050",
      "brgy_name": "San Diego Zone II",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647051",
      "brgy_name": "San Diego Zone III",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647052",
      "brgy_name": "San Diego Zone IV",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647053",
      "brgy_name": "San Isidro Zone I (Pob.)",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647054",
      "brgy_name": "San Isidro Zone II",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647055",
      "brgy_name": "San Isidro Zone III",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647056",
      "brgy_name": "San Isidro Zone IV",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647057",
      "brgy_name": "San Roque Zone I (Pob.)",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647058",
      "brgy_name": "San Roque Zone II",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647059",
      "brgy_name": "Domoit Silangan",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647060",
      "brgy_name": "Katigan Silangan",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647061",
      "brgy_name": "Palale Silangan",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647062",
      "brgy_name": "Talolong",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647063",
      "brgy_name": "Tamlong",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647064",
      "brgy_name": "Tongko",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647065",
      "brgy_name": "Valencia",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045647066",
      "brgy_name": "Wakas",
      "city_code": "045647",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648001",
      "brgy_name": "Anastacia (Tagbak)",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648002",
      "brgy_name": "Ayusan I",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648003",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648004",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648005",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648006",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648007",
      "brgy_name": "Behia",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648008",
      "brgy_name": "Bukal",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648009",
      "brgy_name": "Bula",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648010",
      "brgy_name": "Bulakin",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648011",
      "brgy_name": "Cabatang",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648012",
      "brgy_name": "Cabay",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648013",
      "brgy_name": "Del Rosario",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648014",
      "brgy_name": "Lagalag",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648015",
      "brgy_name": "Lalig",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648016",
      "brgy_name": "Lumingon",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648017",
      "brgy_name": "Lusacan",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648018",
      "brgy_name": "Paiisa",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648019",
      "brgy_name": "Palagaran",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648021",
      "brgy_name": "Quipot",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648022",
      "brgy_name": "San Agustin",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648023",
      "brgy_name": "San Isidro",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648024",
      "brgy_name": "San Jose",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648025",
      "brgy_name": "San Juan",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648026",
      "brgy_name": "San Pedro",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648027",
      "brgy_name": "Tagbakin",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648028",
      "brgy_name": "Talisay",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648029",
      "brgy_name": "Tamisian",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648030",
      "brgy_name": "San Francisco",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648031",
      "brgy_name": "Aquino",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045648032",
      "brgy_name": "Ayusan II",
      "city_code": "045648",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649001",
      "brgy_name": "Almacen",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649002",
      "brgy_name": "Balagtas",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649003",
      "brgy_name": "Balanacan",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649005",
      "brgy_name": "Bulo Ibaba",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649006",
      "brgy_name": "Bulo Ilaya",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649007",
      "brgy_name": "Bonifacio",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649008",
      "brgy_name": "Burgos",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649009",
      "brgy_name": "Caigdal",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649010",
      "brgy_name": "General Luna",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649011",
      "brgy_name": "Kalilayan Ibaba",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649012",
      "brgy_name": "Cabulihan Ibaba",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649013",
      "brgy_name": "Mairok Ibaba",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649014",
      "brgy_name": "Kalilayan Ilaya",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649015",
      "brgy_name": "Cabulihan Ilaya",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649016",
      "brgy_name": "Mabini",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649017",
      "brgy_name": "Mairok Ilaya",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649018",
      "brgy_name": "Malvar",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649019",
      "brgy_name": "Maputat",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649020",
      "brgy_name": "Muliguin",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649021",
      "brgy_name": "Pagaguasan",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649022",
      "brgy_name": "Panaon Ibaba",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649023",
      "brgy_name": "Panaon Ilaya",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649024",
      "brgy_name": "Plaridel",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649025",
      "brgy_name": "F. De Jesus (Pob.)",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649026",
      "brgy_name": "R. Lapu-lapu (Pob.)",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649027",
      "brgy_name": "Raja Soliman (Pob.)",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649028",
      "brgy_name": "R. Magsaysay (Pob.)",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649029",
      "brgy_name": "Poctol",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649030",
      "brgy_name": "Punta",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649031",
      "brgy_name": "Rizal Ibaba",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649032",
      "brgy_name": "Rizal Ilaya",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649033",
      "brgy_name": "San Roque",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649034",
      "brgy_name": "Socorro",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649035",
      "brgy_name": "Tagumpay",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649036",
      "brgy_name": "Tubas",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045649037",
      "brgy_name": "Tubigan",
      "city_code": "045649",
      "province_code": "0456",
      "region_code": "04"
    },
    {
      "brgy_code": "045801001",
      "brgy_name": "Bagumbayan",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801005",
      "brgy_name": "Kalayaan",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801007",
      "brgy_name": "Poblacion Ibaba",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801008",
      "brgy_name": "Poblacion Itaas",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801009",
      "brgy_name": "San Isidro",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801010",
      "brgy_name": "San Pedro",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801011",
      "brgy_name": "San Roque",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801012",
      "brgy_name": "San Vicente",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801013",
      "brgy_name": "Santo Niño",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045801014",
      "brgy_name": "Mahabang Parang",
      "city_code": "045801",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802001",
      "brgy_name": "Calawis",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802002",
      "brgy_name": "Cupang",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802003",
      "brgy_name": "Dela Paz (Pob.)",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802004",
      "brgy_name": "Mayamot",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802007",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802008",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802009",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802010",
      "brgy_name": "Mambugan",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802011",
      "brgy_name": "Bagong Nayon",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802012",
      "brgy_name": "Beverly Hills",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802013",
      "brgy_name": "Dalig",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802014",
      "brgy_name": "Inarawan",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802015",
      "brgy_name": "San Juan",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802016",
      "brgy_name": "San Luis",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802017",
      "brgy_name": "Santa Cruz",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045802018",
      "brgy_name": "Muntingdilaw",
      "city_code": "045802",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803001",
      "brgy_name": "Evangelista",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803002",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803003",
      "brgy_name": "San Jose",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803004",
      "brgy_name": "San Salvador",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803005",
      "brgy_name": "Santiago",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803006",
      "brgy_name": "Concepcion",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803007",
      "brgy_name": "San Juan",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803008",
      "brgy_name": "San Miguel",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803009",
      "brgy_name": "Mabini",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045803010",
      "brgy_name": "Pinugay",
      "city_code": "045803",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804001",
      "brgy_name": "Bangad",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804002",
      "brgy_name": "Batingan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804003",
      "brgy_name": "Bilibiran",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804004",
      "brgy_name": "Binitagan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804005",
      "brgy_name": "Bombong",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804006",
      "brgy_name": "Buhangin",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804007",
      "brgy_name": "Calumpang",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804009",
      "brgy_name": "Ginoong Sanay",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804010",
      "brgy_name": "Gulod",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804011",
      "brgy_name": "Habagatan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804012",
      "brgy_name": "Ithan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804013",
      "brgy_name": "Janosa",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804014",
      "brgy_name": "Kalawaan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804015",
      "brgy_name": "Kalinawan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804016",
      "brgy_name": "Kasile",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804017",
      "brgy_name": "Kaytome",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804018",
      "brgy_name": "Kinaboogan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804019",
      "brgy_name": "Kinagatan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804020",
      "brgy_name": "Libis (Pob.)",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804021",
      "brgy_name": "Limbon-limbon",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804022",
      "brgy_name": "Lunsad",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804023",
      "brgy_name": "Mahabang Parang",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804024",
      "brgy_name": "Macamot",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804025",
      "brgy_name": "Mambog",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804026",
      "brgy_name": "Palangoy",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804027",
      "brgy_name": "Pantok",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804028",
      "brgy_name": "Pila Pila",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804029",
      "brgy_name": "Pinagdilawan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804030",
      "brgy_name": "Pipindan",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804031",
      "brgy_name": "Rayap",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804032",
      "brgy_name": "Sapang",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804033",
      "brgy_name": "Tabon",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804034",
      "brgy_name": "Tagpos",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804035",
      "brgy_name": "Tatala",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804036",
      "brgy_name": "Tayuman",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804037",
      "brgy_name": "Layunan (Pob.)",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804038",
      "brgy_name": "Libid (Pob.)",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804039",
      "brgy_name": "Malakaban",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804040",
      "brgy_name": "Pag-Asa",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045804041",
      "brgy_name": "San Carlos",
      "city_code": "045804",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045805014",
      "brgy_name": "San Andres (Pob.)",
      "city_code": "045805",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045805015",
      "brgy_name": "San Isidro",
      "city_code": "045805",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045805016",
      "brgy_name": "San Juan",
      "city_code": "045805",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045805017",
      "brgy_name": "San Roque",
      "city_code": "045805",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045805018",
      "brgy_name": "Santa Rosa",
      "city_code": "045805",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045805019",
      "brgy_name": "Santo Niño",
      "city_code": "045805",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045805020",
      "brgy_name": "Santo Domingo",
      "city_code": "045805",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806002",
      "brgy_name": "Balibago",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806005",
      "brgy_name": "Boor",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806007",
      "brgy_name": "Calahan",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806008",
      "brgy_name": "Dalig",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806009",
      "brgy_name": "Del Remedio (Pob.)",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806012",
      "brgy_name": "Iglesia (Pob.)",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806013",
      "brgy_name": "Lambac",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806014",
      "brgy_name": "Looc",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806016",
      "brgy_name": "Malanggam-Calubacan",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806017",
      "brgy_name": "Nagsulo",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806018",
      "brgy_name": "Navotas",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806019",
      "brgy_name": "Patunhay",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806022",
      "brgy_name": "Real (Pob.)",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806023",
      "brgy_name": "Sampad",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806027",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806030",
      "brgy_name": "Subay",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806032",
      "brgy_name": "Ticulio",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045806033",
      "brgy_name": "Tuna",
      "city_code": "045806",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807001",
      "brgy_name": "Bagumbong",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807002",
      "brgy_name": "Bayugo",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807004",
      "brgy_name": "Second District (Pob.)",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807005",
      "brgy_name": "Third District (Pob.)",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807007",
      "brgy_name": "Lubo",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807008",
      "brgy_name": "Pagkalinawan",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807009",
      "brgy_name": "Palaypalay",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807010",
      "brgy_name": "Punta",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807014",
      "brgy_name": "Sipsipin",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807015",
      "brgy_name": "Special District (Pob.)",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045807016",
      "brgy_name": "Paalaman",
      "city_code": "045807",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808001",
      "brgy_name": "Balite (Pob.)",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808002",
      "brgy_name": "Burgos",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808003",
      "brgy_name": "Geronimo",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808004",
      "brgy_name": "Macabud",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808005",
      "brgy_name": "Manggahan",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808006",
      "brgy_name": "Mascap",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808007",
      "brgy_name": "Puray",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808008",
      "brgy_name": "Rosario",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808009",
      "brgy_name": "San Isidro",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808010",
      "brgy_name": "San Jose",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045808011",
      "brgy_name": "San Rafael",
      "city_code": "045808",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809001",
      "brgy_name": "Bombongan",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809002",
      "brgy_name": "Can-Cal-Lan (Caniogan-Calero-Lanang)",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809003",
      "brgy_name": "Lagundi",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809004",
      "brgy_name": "Maybancal",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809005",
      "brgy_name": "San Guillermo",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809006",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809007",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045809008",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "045809",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810001",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810003",
      "brgy_name": "Halayhayin",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810004",
      "brgy_name": "Hulo (Pob.)",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810005",
      "brgy_name": "Imatong (Pob.)",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810006",
      "brgy_name": "Malaya",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810007",
      "brgy_name": "Niogan",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810008",
      "brgy_name": "Quisao",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810009",
      "brgy_name": "Wawa (Pob.)",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045810010",
      "brgy_name": "Takungan (Pob.)",
      "city_code": "045810",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811001",
      "brgy_name": "Ampid I",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811002",
      "brgy_name": "Dulong Bayan 1",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811003",
      "brgy_name": "Dulong Bayan 2",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811004",
      "brgy_name": "Guinayang",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811005",
      "brgy_name": "Guitnang Bayan I (Pob.)",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811006",
      "brgy_name": "Guitnang Bayan II (Pob.)",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811007",
      "brgy_name": "Malanday",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811008",
      "brgy_name": "Maly",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811010",
      "brgy_name": "Santa Ana",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811011",
      "brgy_name": "Ampid II",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811012",
      "brgy_name": "Banaba",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811013",
      "brgy_name": "Gulod Malaya",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811014",
      "brgy_name": "Pintong Bocawe",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811015",
      "brgy_name": "Santo Niño",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045811016",
      "brgy_name": "Silangan",
      "city_code": "045811",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812001",
      "brgy_name": "Cayabu",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812002",
      "brgy_name": "Cuyambay",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812003",
      "brgy_name": "Daraitan",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812004",
      "brgy_name": "Katipunan-Bayan (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812005",
      "brgy_name": "Kaybuto (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812006",
      "brgy_name": "Laiban",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812007",
      "brgy_name": "Mag-Ampon (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812008",
      "brgy_name": "Mamuyao",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812009",
      "brgy_name": "Pinagkamaligan (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812011",
      "brgy_name": "Plaza Aldea (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812012",
      "brgy_name": "Sampaloc",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812013",
      "brgy_name": "San Andres",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812014",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812015",
      "brgy_name": "Santa Inez",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812016",
      "brgy_name": "Santo Niño",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812017",
      "brgy_name": "Tabing Ilog (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812018",
      "brgy_name": "Tandang Kutyo (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812019",
      "brgy_name": "Tinucan",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045812020",
      "brgy_name": "Wawa (Pob.)",
      "city_code": "045812",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045813001",
      "brgy_name": "Dolores (Pob.)",
      "city_code": "045813",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045813002",
      "brgy_name": "Muzon",
      "city_code": "045813",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045813003",
      "brgy_name": "San Isidro",
      "city_code": "045813",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045813004",
      "brgy_name": "San Juan",
      "city_code": "045813",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045813005",
      "brgy_name": "Santa Ana",
      "city_code": "045813",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814001",
      "brgy_name": "Bagumbayan",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814003",
      "brgy_name": "Dalig",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814004",
      "brgy_name": "Dulumbayan",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814005",
      "brgy_name": "May-Iba",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814006",
      "brgy_name": "Poblacion",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814007",
      "brgy_name": "Prinza",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814008",
      "brgy_name": "San Gabriel",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814009",
      "brgy_name": "San Roque",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "045814010",
      "brgy_name": "Calumpang Santo Cristo",
      "city_code": "045814",
      "province_code": "0458",
      "region_code": "04"
    },
    {
      "brgy_code": "174001001",
      "brgy_name": "Agot",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001002",
      "brgy_name": "Agumaymayan",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001003",
      "brgy_name": "Amoingon",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001004",
      "brgy_name": "Apitong",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001005",
      "brgy_name": "Balagasan",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001006",
      "brgy_name": "Balaring",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001007",
      "brgy_name": "Balimbing",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001008",
      "brgy_name": "Balogo",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001009",
      "brgy_name": "Bangbangalon",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001010",
      "brgy_name": "Bamban",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001011",
      "brgy_name": "Bantad",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001012",
      "brgy_name": "Bantay",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001013",
      "brgy_name": "Bayuti",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001014",
      "brgy_name": "Binunga",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001015",
      "brgy_name": "Boi",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001016",
      "brgy_name": "Boton",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001017",
      "brgy_name": "Buliasnin",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001018",
      "brgy_name": "Bunganay",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001019",
      "brgy_name": "Maligaya",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001020",
      "brgy_name": "Caganhao",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001021",
      "brgy_name": "Canat",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001022",
      "brgy_name": "Catubugan",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001023",
      "brgy_name": "Cawit",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001024",
      "brgy_name": "Daig",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001025",
      "brgy_name": "Daypay",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001026",
      "brgy_name": "Duyay",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001027",
      "brgy_name": "Ihatub",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001028",
      "brgy_name": "Isok II Pob. (Kalamias)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001029",
      "brgy_name": "Hinapulan",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001030",
      "brgy_name": "Laylay",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001031",
      "brgy_name": "Lupac",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001032",
      "brgy_name": "Mahinhin",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001033",
      "brgy_name": "Mainit",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001034",
      "brgy_name": "Malbog",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001035",
      "brgy_name": "Malusak (Pob.)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001036",
      "brgy_name": "Mansiwat",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001037",
      "brgy_name": "Mataas Na Bayan (Pob.)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001038",
      "brgy_name": "Maybo",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001039",
      "brgy_name": "Mercado (Pob.)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001040",
      "brgy_name": "Murallon (Pob.)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001041",
      "brgy_name": "Ogbac",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001042",
      "brgy_name": "Pawa",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001043",
      "brgy_name": "Pili",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001044",
      "brgy_name": "Poctoy",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001045",
      "brgy_name": "Poras",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001046",
      "brgy_name": "Puting Buhangin",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001047",
      "brgy_name": "Puyog",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001048",
      "brgy_name": "Sabong",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001049",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001050",
      "brgy_name": "Santol",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001051",
      "brgy_name": "Sawi",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001052",
      "brgy_name": "Tabi",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001053",
      "brgy_name": "Tabigue",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001054",
      "brgy_name": "Tagwak",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001055",
      "brgy_name": "Tambunan",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001056",
      "brgy_name": "Tampus (Pob.)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001057",
      "brgy_name": "Tanza",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001058",
      "brgy_name": "Tugos",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001059",
      "brgy_name": "Tumagabok",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001060",
      "brgy_name": "Tumapon",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174001061",
      "brgy_name": "Isok I (Pob.)",
      "city_code": "174001",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002001",
      "brgy_name": "Bagacay",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002002",
      "brgy_name": "Bagtingon",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002003",
      "brgy_name": "Bicas-bicas",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002004",
      "brgy_name": "Caigangan",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002005",
      "brgy_name": "Daykitin",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002006",
      "brgy_name": "Libas",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002007",
      "brgy_name": "Malbog",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002011",
      "brgy_name": "Sihi",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002012",
      "brgy_name": "Timbo (Sanggulong)",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002013",
      "brgy_name": "Tungib-Lipata",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002014",
      "brgy_name": "Yook",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002015",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002016",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002017",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174002018",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "174002",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003001",
      "brgy_name": "Antipolo",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003003",
      "brgy_name": "Bachao Ibaba",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003004",
      "brgy_name": "Bachao Ilaya",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003005",
      "brgy_name": "Bacongbacong",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003006",
      "brgy_name": "Bahi",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003007",
      "brgy_name": "Bangbang",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003008",
      "brgy_name": "Banot",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003009",
      "brgy_name": "Banuyo",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003010",
      "brgy_name": "Bognuyan",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003011",
      "brgy_name": "Cabugao",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003012",
      "brgy_name": "Dawis",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003013",
      "brgy_name": "Dili",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003014",
      "brgy_name": "Libtangin",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003015",
      "brgy_name": "Mahunig",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003016",
      "brgy_name": "Mangiliol",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003017",
      "brgy_name": "Masiga",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003018",
      "brgy_name": "Matandang Gasan",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003019",
      "brgy_name": "Pangi",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003020",
      "brgy_name": "Pingan",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003021",
      "brgy_name": "Tabionan",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003022",
      "brgy_name": "Tapuyan",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003023",
      "brgy_name": "Tiguion",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003024",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003025",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174003026",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "174003",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004001",
      "brgy_name": "Anapog-Sibucao",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004002",
      "brgy_name": "Argao",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004003",
      "brgy_name": "Balanacan",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004004",
      "brgy_name": "Banto",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004005",
      "brgy_name": "Bintakay",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004006",
      "brgy_name": "Bocboc",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004007",
      "brgy_name": "Butansapa",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004008",
      "brgy_name": "Candahon",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004009",
      "brgy_name": "Capayang",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004010",
      "brgy_name": "Danao",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004011",
      "brgy_name": "Dulong Bayan (Pob.)",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004012",
      "brgy_name": "Gitnang Bayan (Pob.)",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004013",
      "brgy_name": "Guisian",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004014",
      "brgy_name": "Hinadharan",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004015",
      "brgy_name": "Hinanggayon",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004016",
      "brgy_name": "Ino",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004017",
      "brgy_name": "Janagdong",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004018",
      "brgy_name": "Lamesa",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004019",
      "brgy_name": "Laon",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004020",
      "brgy_name": "Magapua",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004021",
      "brgy_name": "Malayak",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004022",
      "brgy_name": "Malusak",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004023",
      "brgy_name": "Mampaitan",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004024",
      "brgy_name": "Mangyan-Mababad",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004025",
      "brgy_name": "Market Site (Pob.)",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004026",
      "brgy_name": "Mataas Na Bayan",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004027",
      "brgy_name": "Mendez",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004028",
      "brgy_name": "Nangka I",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004029",
      "brgy_name": "Nangka II",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004030",
      "brgy_name": "Paye",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004032",
      "brgy_name": "Pili",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004033",
      "brgy_name": "Puting Buhangin",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004034",
      "brgy_name": "Sayao",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004035",
      "brgy_name": "Silangan",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004036",
      "brgy_name": "Sumangga",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004037",
      "brgy_name": "Tarug",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174004038",
      "brgy_name": "Villa Mendez (Pob.)",
      "city_code": "174004",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005001",
      "brgy_name": "Alobo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005002",
      "brgy_name": "Angas",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005003",
      "brgy_name": "Aturan",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005004",
      "brgy_name": "Bagong Silang Pob. (2nd Zone)",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005005",
      "brgy_name": "Baguidbirin",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005006",
      "brgy_name": "Baliis",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005007",
      "brgy_name": "Balogo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005008",
      "brgy_name": "Banahaw Pob. (3rd Zone)",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005009",
      "brgy_name": "Bangcuangan",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005010",
      "brgy_name": "Banogbog",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005011",
      "brgy_name": "Biga",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005012",
      "brgy_name": "Botilao",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005013",
      "brgy_name": "Buyabod",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005014",
      "brgy_name": "Dating Bayan",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005015",
      "brgy_name": "Devilla",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005016",
      "brgy_name": "Dolores",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005017",
      "brgy_name": "Haguimit",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005018",
      "brgy_name": "Hupi",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005019",
      "brgy_name": "Ipil",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005020",
      "brgy_name": "Jolo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005021",
      "brgy_name": "Kaganhao",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005022",
      "brgy_name": "Kalangkang",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005023",
      "brgy_name": "Kamandugan",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005024",
      "brgy_name": "Kasily",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005025",
      "brgy_name": "Kilo-kilo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005026",
      "brgy_name": "Kiñaman",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005027",
      "brgy_name": "Labo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005028",
      "brgy_name": "Lamesa",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005029",
      "brgy_name": "Landy",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005030",
      "brgy_name": "Lapu-lapu Pob. (5th Zone)",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005031",
      "brgy_name": "Libjo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005032",
      "brgy_name": "Lipa",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005033",
      "brgy_name": "Lusok",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005034",
      "brgy_name": "Maharlika Pob. (1st Zone)",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005035",
      "brgy_name": "Makulapnit",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005036",
      "brgy_name": "Maniwaya",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005037",
      "brgy_name": "Manlibunan",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005038",
      "brgy_name": "Masaguisi",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005039",
      "brgy_name": "Masalukot",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005040",
      "brgy_name": "Matalaba",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005041",
      "brgy_name": "Mongpong",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005042",
      "brgy_name": "Morales",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005043",
      "brgy_name": "Napo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005044",
      "brgy_name": "Pag-Asa Pob. (4th Zone)",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005045",
      "brgy_name": "Pantayin",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005047",
      "brgy_name": "Polo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005048",
      "brgy_name": "Pulong-Parang",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005049",
      "brgy_name": "Punong",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005050",
      "brgy_name": "San Antonio",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005051",
      "brgy_name": "San Isidro",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005052",
      "brgy_name": "Tagum",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005053",
      "brgy_name": "Tamayo",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005054",
      "brgy_name": "Tambangan",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005055",
      "brgy_name": "Tawiran",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174005056",
      "brgy_name": "Taytay",
      "city_code": "174005",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006001",
      "brgy_name": "Bangwayin",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006002",
      "brgy_name": "Bayakbakin",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006003",
      "brgy_name": "Bolo",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006004",
      "brgy_name": "Bonliw",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006005",
      "brgy_name": "Buangan",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006006",
      "brgy_name": "Cabuyo",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006007",
      "brgy_name": "Cagpo",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006008",
      "brgy_name": "Dampulan",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006009",
      "brgy_name": "Kay Duke",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006010",
      "brgy_name": "Mabuhay",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006011",
      "brgy_name": "Makawayan",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006012",
      "brgy_name": "Malibago",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006013",
      "brgy_name": "Malinao",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006014",
      "brgy_name": "Maranlig",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006015",
      "brgy_name": "Marlangga",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006016",
      "brgy_name": "Matuyatuya",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006017",
      "brgy_name": "Nangka",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006018",
      "brgy_name": "Pakaskasan",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006019",
      "brgy_name": "Payanas",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006020",
      "brgy_name": "Poblacion",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006021",
      "brgy_name": "Poctoy",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006022",
      "brgy_name": "Sibuyao",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006023",
      "brgy_name": "Suha",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006024",
      "brgy_name": "Talawan",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "174006025",
      "brgy_name": "Tigwi",
      "city_code": "174006",
      "province_code": "1740",
      "region_code": "17"
    },
    {
      "brgy_code": "175101001",
      "brgy_name": "Balao",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101002",
      "brgy_name": "Cabacao",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101003",
      "brgy_name": "Lumangbayan",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101004",
      "brgy_name": "Poblacion",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101005",
      "brgy_name": "San Vicente",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101006",
      "brgy_name": "Tibag",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101007",
      "brgy_name": "Udalo (Camurong)",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101008",
      "brgy_name": "Wawa",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101009",
      "brgy_name": "Armado",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175101010",
      "brgy_name": "Santa Maria",
      "city_code": "175101",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175102001",
      "brgy_name": "Concepcion",
      "city_code": "175102",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175102002",
      "brgy_name": "Iriron",
      "city_code": "175102",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175102003",
      "brgy_name": "Malpalon",
      "city_code": "175102",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175102004",
      "brgy_name": "New Dagupan",
      "city_code": "175102",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175102005",
      "brgy_name": "Poblacion",
      "city_code": "175102",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175102006",
      "brgy_name": "Poypoy",
      "city_code": "175102",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175102007",
      "brgy_name": "Tanyag",
      "city_code": "175102",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103001",
      "brgy_name": "Agkawayan",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103002",
      "brgy_name": "Ambil",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103003",
      "brgy_name": "Balikyas",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103004",
      "brgy_name": "Bonbon (Pob.)",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103005",
      "brgy_name": "Bulacan",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103006",
      "brgy_name": "Burol",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103007",
      "brgy_name": "Guitna (Pob.)",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103008",
      "brgy_name": "Kanluran (Pob.)",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175103009",
      "brgy_name": "Talaotao",
      "city_code": "175103",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104001",
      "brgy_name": "Binakas",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104002",
      "brgy_name": "Cabra",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104003",
      "brgy_name": "Maligaya",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104004",
      "brgy_name": "Maliig",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104005",
      "brgy_name": "Tagbac",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104006",
      "brgy_name": "Tangal",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104007",
      "brgy_name": "Tilik",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104008",
      "brgy_name": "Vigo",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104009",
      "brgy_name": "Surville (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104010",
      "brgy_name": "Araw At Bituin (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104011",
      "brgy_name": "Bagong Sikat (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104012",
      "brgy_name": "Banaag At Pag-Asa (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104013",
      "brgy_name": "Likas Ng Silangan (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104014",
      "brgy_name": "Maginhawa (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104015",
      "brgy_name": "Ninikat Ng Pag-Asa (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175104016",
      "brgy_name": "Paraiso (Pob.)",
      "city_code": "175104",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105001",
      "brgy_name": "Alibog",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105003",
      "brgy_name": "Caguray",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105004",
      "brgy_name": "Calawag",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105005",
      "brgy_name": "Gapasan",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105006",
      "brgy_name": "Laste",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105007",
      "brgy_name": "Lourdes",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105008",
      "brgy_name": "Nicolas (Bulo)",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105009",
      "brgy_name": "Paclolo",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105010",
      "brgy_name": "Poblacion",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105011",
      "brgy_name": "Purnaga",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105012",
      "brgy_name": "Santa Teresa",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175105013",
      "brgy_name": "Sibalat",
      "city_code": "175105",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106001",
      "brgy_name": "Balansay",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106002",
      "brgy_name": "Fatima (Tii)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106003",
      "brgy_name": "Payompon",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106004",
      "brgy_name": "San Luis (Ligang)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106005",
      "brgy_name": "Talabaan",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106006",
      "brgy_name": "Tangkalan",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106007",
      "brgy_name": "Tayamaan",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106008",
      "brgy_name": "Poblacion 1 (Barangay 1)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106009",
      "brgy_name": "Poblacion 2 (Barangay 2)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106010",
      "brgy_name": "Poblacion 3 (Barangay 3)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106011",
      "brgy_name": "Poblacion 4 (Barangay 4)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106012",
      "brgy_name": "Poblacion 5 (Barangay 5)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106013",
      "brgy_name": "Poblacion 6 (Barangay 6)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106014",
      "brgy_name": "Poblacion 7 (Barangay 7)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175106015",
      "brgy_name": "Poblacion 8 (Barangay 8)",
      "city_code": "175106",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107001",
      "brgy_name": "Alipaoy",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107002",
      "brgy_name": "Harrison",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107003",
      "brgy_name": "Lumangbayan",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107004",
      "brgy_name": "Mananao",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107005",
      "brgy_name": "Marikit",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107006",
      "brgy_name": "Mapalad Pob. (Bgy 1)",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107007",
      "brgy_name": "Handang Tumulong Pob. (Bgy 2)",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107008",
      "brgy_name": "Silahis Ng Pag-Asa Pob. (Bgy 3)",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107009",
      "brgy_name": "Pag-Asa Ng Bayan Pob. (Bgy 4)",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107010",
      "brgy_name": "Bagong Silang Pob. (Bgy 5)",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107011",
      "brgy_name": "San Jose Pob. (Bgy 6)",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175107012",
      "brgy_name": "Tubili",
      "city_code": "175107",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108001",
      "brgy_name": "Adela",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108002",
      "brgy_name": "Aguas",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108003",
      "brgy_name": "Magsikap",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108004",
      "brgy_name": "Malawaan",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108005",
      "brgy_name": "Pitogo",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108006",
      "brgy_name": "Rizal",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108007",
      "brgy_name": "Rumbang",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108008",
      "brgy_name": "Salvacion",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108009",
      "brgy_name": "San Pedro",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108010",
      "brgy_name": "Santo Niño",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175108012",
      "brgy_name": "Manoot",
      "city_code": "175108",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109001",
      "brgy_name": "Batong Buhay",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109002",
      "brgy_name": "Buenavista",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109003",
      "brgy_name": "Burgos",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109004",
      "brgy_name": "Claudio Salgado",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109005",
      "brgy_name": "General Emilio Aguinaldo",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109006",
      "brgy_name": "Ibud",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109007",
      "brgy_name": "Ilvita",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109008",
      "brgy_name": "Ligaya",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109009",
      "brgy_name": "Poblacion (Lumangbayan)",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109010",
      "brgy_name": "Paetan",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109011",
      "brgy_name": "Pag-Asa",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109013",
      "brgy_name": "San Agustin",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109014",
      "brgy_name": "San Francisco",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109016",
      "brgy_name": "San Nicolas",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109017",
      "brgy_name": "San Vicente",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109018",
      "brgy_name": "Santa Lucia",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109019",
      "brgy_name": "Santo Niño",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109020",
      "brgy_name": "Tagumpay",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109021",
      "brgy_name": "Victoria",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109022",
      "brgy_name": "Lagnas",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109023",
      "brgy_name": "Malisbong",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175109024",
      "brgy_name": "Tuban",
      "city_code": "175109",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110001",
      "brgy_name": "Ambulong",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110002",
      "brgy_name": "Ansiray",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110003",
      "brgy_name": "Bagong Sikat",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110004",
      "brgy_name": "Bangkal",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110005",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110006",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110007",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110008",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110009",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110010",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110011",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110012",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110013",
      "brgy_name": "Batasan",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110014",
      "brgy_name": "Bayotbot",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110015",
      "brgy_name": "Bubog",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110016",
      "brgy_name": "Buri",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110017",
      "brgy_name": "Camburay",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110018",
      "brgy_name": "Caminawit",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110019",
      "brgy_name": "Catayungan",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110020",
      "brgy_name": "Central",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110021",
      "brgy_name": "Iling Proper",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110022",
      "brgy_name": "Inasakan",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110023",
      "brgy_name": "Ipil",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110024",
      "brgy_name": "La Curva",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110025",
      "brgy_name": "Labangan Iling",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110026",
      "brgy_name": "Labangan Poblacion",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110027",
      "brgy_name": "Mabini",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110028",
      "brgy_name": "Magbay",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110029",
      "brgy_name": "Mangarin",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110030",
      "brgy_name": "Mapaya",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110031",
      "brgy_name": "Murtha",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110032",
      "brgy_name": "Monte Claro",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110033",
      "brgy_name": "Natandol",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110034",
      "brgy_name": "Pag-Asa",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110035",
      "brgy_name": "Pawican",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110037",
      "brgy_name": "San Agustin",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110038",
      "brgy_name": "San Isidro",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175110039",
      "brgy_name": "San Roque",
      "city_code": "175110",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111001",
      "brgy_name": "Alacaak",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111002",
      "brgy_name": "Barahan",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111003",
      "brgy_name": "Casague",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111004",
      "brgy_name": "Dayap",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111006",
      "brgy_name": "Lumangbayan",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111007",
      "brgy_name": "Mulawin",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111008",
      "brgy_name": "Pinagturilan (San Pedro)",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111009",
      "brgy_name": "Poblacion I (Barangay 1)",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111010",
      "brgy_name": "San Vicente",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111011",
      "brgy_name": "Poblacion II (Barangay 2)",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175111012",
      "brgy_name": "Kurtinganan",
      "city_code": "175111",
      "province_code": "1751",
      "region_code": "17"
    },
    {
      "brgy_code": "175201001",
      "brgy_name": "Alag",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201002",
      "brgy_name": "Bangkatan",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201004",
      "brgy_name": "Burbuli",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201005",
      "brgy_name": "Catwiran I",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201006",
      "brgy_name": "Catwiran II",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201007",
      "brgy_name": "Dulangan I",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201008",
      "brgy_name": "Dulangan II",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201010",
      "brgy_name": "Lumang Bayan",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201011",
      "brgy_name": "Malapad",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201012",
      "brgy_name": "Mangangan I",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201013",
      "brgy_name": "Mangangan II",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201014",
      "brgy_name": "Mayabig",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201015",
      "brgy_name": "Pambisan",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201016",
      "brgy_name": "Pulang-Tubig",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201017",
      "brgy_name": "Putican-Cabulo",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201018",
      "brgy_name": "San Andres",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201019",
      "brgy_name": "San Ignacio (Dulangan III)",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201020",
      "brgy_name": "Santa Cruz",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201021",
      "brgy_name": "Santa Rosa I",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201022",
      "brgy_name": "Santa Rosa II",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201023",
      "brgy_name": "Tabon-tabon",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201024",
      "brgy_name": "Tagumpay",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201025",
      "brgy_name": "Water",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201026",
      "brgy_name": "Baras (Mangyan Minority)",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201027",
      "brgy_name": "Bayanan",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201028",
      "brgy_name": "Lantuyang (Mangyan Minority)",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175201029",
      "brgy_name": "Poblacion",
      "city_code": "175201",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202001",
      "brgy_name": "Alcadesma",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202003",
      "brgy_name": "Bato",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202004",
      "brgy_name": "Conrazon",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202005",
      "brgy_name": "Malo",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202006",
      "brgy_name": "Manihala",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202007",
      "brgy_name": "Pag-Asa",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202008",
      "brgy_name": "Poblacion",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202009",
      "brgy_name": "Proper Bansud",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202010",
      "brgy_name": "Rosacara",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202011",
      "brgy_name": "Salcedo",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202012",
      "brgy_name": "Sumagui",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202013",
      "brgy_name": "Proper Tiguisan",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175202014",
      "brgy_name": "Villa Pag-Asa",
      "city_code": "175202",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203001",
      "brgy_name": "Anilao",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203002",
      "brgy_name": "Batangan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203003",
      "brgy_name": "Bukal",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203004",
      "brgy_name": "Camantigue",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203005",
      "brgy_name": "Carmundo",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203006",
      "brgy_name": "Cawayan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203007",
      "brgy_name": "Dayhagan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203008",
      "brgy_name": "Formon",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203009",
      "brgy_name": "Hagan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203010",
      "brgy_name": "Hagupit",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203011",
      "brgy_name": "Kaligtasan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203012",
      "brgy_name": "Labasan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203013",
      "brgy_name": "Labonan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203014",
      "brgy_name": "Libertad",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203015",
      "brgy_name": "Lisap",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203016",
      "brgy_name": "Luna",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203017",
      "brgy_name": "Malitbog",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203018",
      "brgy_name": "Mapang",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203019",
      "brgy_name": "Masaguisi",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203020",
      "brgy_name": "Morente",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203021",
      "brgy_name": "Ogbot",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203022",
      "brgy_name": "Orconuma",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203023",
      "brgy_name": "Polusahi",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203024",
      "brgy_name": "Sagana",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203025",
      "brgy_name": "San Isidro",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203026",
      "brgy_name": "San Jose",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203027",
      "brgy_name": "San Juan",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203028",
      "brgy_name": "Santa Cruz",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203029",
      "brgy_name": "Sigange",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203031",
      "brgy_name": "Tawas",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203032",
      "brgy_name": "Poblacion",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203033",
      "brgy_name": "Aplaya",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203034",
      "brgy_name": "Bagumbayan I",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203035",
      "brgy_name": "Bagumbayan II",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203036",
      "brgy_name": "Ipil",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175203037",
      "brgy_name": "Mina de Oro",
      "city_code": "175203",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204001",
      "brgy_name": "Bagong Sikat",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204002",
      "brgy_name": "Balatasan",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204003",
      "brgy_name": "Benli (Mangyan Settlement)",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204005",
      "brgy_name": "Cabugao",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204006",
      "brgy_name": "Cambunang (Pob.)",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204007",
      "brgy_name": "Campaasan (Pob.)",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204008",
      "brgy_name": "Maasin",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204009",
      "brgy_name": "Maujao",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204010",
      "brgy_name": "Milagrosa (Guiob)",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204011",
      "brgy_name": "Nasukob (Pob.)",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204012",
      "brgy_name": "Poblacion",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204013",
      "brgy_name": "San Francisco (Alimawan)",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204014",
      "brgy_name": "San Isidro",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204015",
      "brgy_name": "San Juan",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175204017",
      "brgy_name": "San Roque (Buyayao)",
      "city_code": "175204",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205001",
      "brgy_name": "Balingayan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205002",
      "brgy_name": "Balite",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205003",
      "brgy_name": "Baruyan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205004",
      "brgy_name": "Batino",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205005",
      "brgy_name": "Bayanan I",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205006",
      "brgy_name": "Bayanan II",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205007",
      "brgy_name": "Biga",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205008",
      "brgy_name": "Bondoc",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205009",
      "brgy_name": "Bucayao",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205010",
      "brgy_name": "Buhuan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205011",
      "brgy_name": "Bulusan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205012",
      "brgy_name": "Santa Rita (Bungahan)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205013",
      "brgy_name": "Calero (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205015",
      "brgy_name": "Camansihan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205016",
      "brgy_name": "Camilmil",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205017",
      "brgy_name": "Canubing I",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205018",
      "brgy_name": "Canubing II",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205019",
      "brgy_name": "Comunal",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205021",
      "brgy_name": "Guinobatan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205022",
      "brgy_name": "Gulod",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205023",
      "brgy_name": "Gutad",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205025",
      "brgy_name": "Ibaba East (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205026",
      "brgy_name": "Ibaba West (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205027",
      "brgy_name": "Ilaya (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205028",
      "brgy_name": "Lalud",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205029",
      "brgy_name": "Lazareto",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205030",
      "brgy_name": "Libis (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205031",
      "brgy_name": "Lumang Bayan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205032",
      "brgy_name": "Mahal Na Pangalan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205033",
      "brgy_name": "Maidlang",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205034",
      "brgy_name": "Malad",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205035",
      "brgy_name": "Malamig",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205036",
      "brgy_name": "Managpi",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205037",
      "brgy_name": "Masipit",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205038",
      "brgy_name": "Nag-Iba I",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205039",
      "brgy_name": "Navotas",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205041",
      "brgy_name": "Pachoca",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205042",
      "brgy_name": "Palhi",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205043",
      "brgy_name": "Panggalaan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205044",
      "brgy_name": "Parang",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205045",
      "brgy_name": "Patas",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205046",
      "brgy_name": "Personas",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205047",
      "brgy_name": "Putingtubig",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205048",
      "brgy_name": "Salong",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205049",
      "brgy_name": "San Antonio",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205050",
      "brgy_name": "San Vicente Central (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205051",
      "brgy_name": "San Vicente East (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205052",
      "brgy_name": "San Vicente North (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205053",
      "brgy_name": "San Vicente South (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205054",
      "brgy_name": "San Vicente West (Pob.)",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205055",
      "brgy_name": "Santa Cruz",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205056",
      "brgy_name": "Santa Isabel",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205057",
      "brgy_name": "Santo Niño",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205058",
      "brgy_name": "Sapul",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205059",
      "brgy_name": "Silonay",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205061",
      "brgy_name": "Santa Maria Village",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205062",
      "brgy_name": "Suqui",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205063",
      "brgy_name": "Tawagan",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205064",
      "brgy_name": "Tawiran",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205065",
      "brgy_name": "Tibag",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205066",
      "brgy_name": "Wawa",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175205068",
      "brgy_name": "Nag-Iba II",
      "city_code": "175205",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206001",
      "brgy_name": "Agsalin",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206002",
      "brgy_name": "Agos",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206003",
      "brgy_name": "Andres Bonifacio",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206004",
      "brgy_name": "Balete",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206005",
      "brgy_name": "Banus",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206006",
      "brgy_name": "Banutan",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206007",
      "brgy_name": "Buong Lupa",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206008",
      "brgy_name": "Bulaklakan",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206009",
      "brgy_name": "Gaudencio Antonino",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206010",
      "brgy_name": "Guimbonan",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206012",
      "brgy_name": "Kawit",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206013",
      "brgy_name": "Lucio Laurel",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206014",
      "brgy_name": "Macario Adriatico",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206015",
      "brgy_name": "Malamig",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206016",
      "brgy_name": "Malayong",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206017",
      "brgy_name": "Maligaya (Pob.)",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206018",
      "brgy_name": "Malubay",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206020",
      "brgy_name": "Manguyang",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206021",
      "brgy_name": "Maragooc",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206022",
      "brgy_name": "Mirayan",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206023",
      "brgy_name": "Narra",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206024",
      "brgy_name": "Papandungin",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206025",
      "brgy_name": "San Antonio",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206026",
      "brgy_name": "Santa Maria",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206027",
      "brgy_name": "Santa Theresa",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206028",
      "brgy_name": "Tambong",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175206029",
      "brgy_name": "Alma Villa",
      "city_code": "175206",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207001",
      "brgy_name": "B. Del Mundo",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207002",
      "brgy_name": "Balugo",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207003",
      "brgy_name": "Bonbon",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207004",
      "brgy_name": "Budburan",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207005",
      "brgy_name": "Cabalwa",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207007",
      "brgy_name": "Don Pedro",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207010",
      "brgy_name": "Maliwanag",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207011",
      "brgy_name": "Manaul",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207012",
      "brgy_name": "Panaytayan",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207013",
      "brgy_name": "Poblacion",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207014",
      "brgy_name": "Roma",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207016",
      "brgy_name": "Santa Brigida",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207017",
      "brgy_name": "Santa Maria",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207018",
      "brgy_name": "Villa Celestial",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207019",
      "brgy_name": "Wasig",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207020",
      "brgy_name": "Santa Teresita",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175207021",
      "brgy_name": "Waygan",
      "city_code": "175207",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208001",
      "brgy_name": "Adrialuna",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208002",
      "brgy_name": "Antipolo",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208003",
      "brgy_name": "Apitong",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208004",
      "brgy_name": "Arangin",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208005",
      "brgy_name": "Aurora",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208006",
      "brgy_name": "Bacungan",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208007",
      "brgy_name": "Bagong Buhay",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208008",
      "brgy_name": "Bancuro",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208010",
      "brgy_name": "Barcenaga",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208011",
      "brgy_name": "Bayani",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208012",
      "brgy_name": "Buhangin",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208013",
      "brgy_name": "Concepcion",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208014",
      "brgy_name": "Dao",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208015",
      "brgy_name": "Del Pilar",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208016",
      "brgy_name": "Estrella",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208017",
      "brgy_name": "Evangelista",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208018",
      "brgy_name": "Gamao",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208019",
      "brgy_name": "General Esco",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208020",
      "brgy_name": "Herrera",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208021",
      "brgy_name": "Inarawan",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208022",
      "brgy_name": "Kalinisan",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208023",
      "brgy_name": "Laguna",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208024",
      "brgy_name": "Mabini",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208025",
      "brgy_name": "Andres Ilagan (Mag-asawang Tubig)",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208026",
      "brgy_name": "Mahabang Parang",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208027",
      "brgy_name": "Malaya",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208028",
      "brgy_name": "Malinao",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208029",
      "brgy_name": "Malvar",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208030",
      "brgy_name": "Masagana",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208031",
      "brgy_name": "Masaguing",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208032",
      "brgy_name": "Melgar A",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208033",
      "brgy_name": "Metolza",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208034",
      "brgy_name": "Montelago",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208035",
      "brgy_name": "Montemayor",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208036",
      "brgy_name": "Motoderazo",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208037",
      "brgy_name": "Mulawin",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208038",
      "brgy_name": "Nag-Iba I",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208039",
      "brgy_name": "Nag-Iba II",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208040",
      "brgy_name": "Pagkakaisa",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208042",
      "brgy_name": "Paniquian",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208043",
      "brgy_name": "Pinagsabangan I",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208044",
      "brgy_name": "Pinagsabangan II",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208045",
      "brgy_name": "Piñahan",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208046",
      "brgy_name": "Poblacion I (Barangay I)",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208047",
      "brgy_name": "Poblacion II (Barangay II)",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208048",
      "brgy_name": "Poblacion III (Barangay III)",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208049",
      "brgy_name": "Sampaguita",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208050",
      "brgy_name": "San Agustin I",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208051",
      "brgy_name": "San Agustin II",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208052",
      "brgy_name": "San Andres",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208053",
      "brgy_name": "San Antonio",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208054",
      "brgy_name": "San Carlos",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208055",
      "brgy_name": "San Isidro",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208056",
      "brgy_name": "San Jose",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208057",
      "brgy_name": "San Luis",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208058",
      "brgy_name": "San Nicolas",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208059",
      "brgy_name": "San Pedro",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208060",
      "brgy_name": "Santa Isabel",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208061",
      "brgy_name": "Santa Maria",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208062",
      "brgy_name": "Santiago",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208063",
      "brgy_name": "Santo Niño",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208064",
      "brgy_name": "Tagumpay",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208065",
      "brgy_name": "Tigkan",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208066",
      "brgy_name": "Melgar B",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208067",
      "brgy_name": "Santa Cruz",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208068",
      "brgy_name": "Balite",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208069",
      "brgy_name": "Banuton",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208070",
      "brgy_name": "Caburo",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208071",
      "brgy_name": "Magtibay",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175208072",
      "brgy_name": "Paitan",
      "city_code": "175208",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209001",
      "brgy_name": "Anoling",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209002",
      "brgy_name": "Bacungan",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209003",
      "brgy_name": "Bangbang",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209004",
      "brgy_name": "Banilad",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209005",
      "brgy_name": "Buli",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209006",
      "brgy_name": "Cacawan",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209007",
      "brgy_name": "Calingag",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209008",
      "brgy_name": "Del Razon",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209009",
      "brgy_name": "Inclanay",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209010",
      "brgy_name": "Lumangbayan",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209011",
      "brgy_name": "Malaya",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209012",
      "brgy_name": "Maliangcog",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209013",
      "brgy_name": "Maningcol",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209014",
      "brgy_name": "Marayos",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209015",
      "brgy_name": "Marfrancisco",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209016",
      "brgy_name": "Nabuslot",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209017",
      "brgy_name": "Pagalagala",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209018",
      "brgy_name": "Palayan",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209019",
      "brgy_name": "Pambisan Malaki",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209020",
      "brgy_name": "Pambisan Munti",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209021",
      "brgy_name": "Panggulayan",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209022",
      "brgy_name": "Papandayan",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209023",
      "brgy_name": "Pili",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209024",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209025",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209026",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209027",
      "brgy_name": "Quinabigan",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209028",
      "brgy_name": "Ranzo",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209029",
      "brgy_name": "Rosario",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209030",
      "brgy_name": "Sabang",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209031",
      "brgy_name": "Santa Isabel",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209032",
      "brgy_name": "Santa Maria",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209033",
      "brgy_name": "Santa Rita",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209034",
      "brgy_name": "Wawa",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209035",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209036",
      "brgy_name": "Santo Niño",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175209037",
      "brgy_name": "Guinhawa",
      "city_code": "175209",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210001",
      "brgy_name": "Bacawan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210002",
      "brgy_name": "Bacungan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210004",
      "brgy_name": "Batuhan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210005",
      "brgy_name": "Bayanan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210006",
      "brgy_name": "Biga",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210007",
      "brgy_name": "Buhay Na Tubig",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210008",
      "brgy_name": "Calubasanhon",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210009",
      "brgy_name": "Calima",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210010",
      "brgy_name": "Casiligan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210011",
      "brgy_name": "Malibago",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210012",
      "brgy_name": "Maluanluan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210013",
      "brgy_name": "Matulatula",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210014",
      "brgy_name": "Pahilahan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210015",
      "brgy_name": "Panikihan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210017",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210018",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210019",
      "brgy_name": "Pula",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210020",
      "brgy_name": "Puting Cacao",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210021",
      "brgy_name": "Tagbakin",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210022",
      "brgy_name": "Tagumpay",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210023",
      "brgy_name": "Tiguihan",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210024",
      "brgy_name": "Campamento",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175210025",
      "brgy_name": "Misong",
      "city_code": "175210",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211001",
      "brgy_name": "Aninuan",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211002",
      "brgy_name": "Balatero",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211003",
      "brgy_name": "Dulangan",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211004",
      "brgy_name": "Palangan",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211005",
      "brgy_name": "Sabang",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211006",
      "brgy_name": "San Antonio",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211007",
      "brgy_name": "San Isidro",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211008",
      "brgy_name": "Santo Niño",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211009",
      "brgy_name": "Sinandigan",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211010",
      "brgy_name": "Tabinay",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211011",
      "brgy_name": "Villaflor",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211012",
      "brgy_name": "Poblacion",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175211013",
      "brgy_name": "Baclayan",
      "city_code": "175211",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212001",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212002",
      "brgy_name": "Cantil",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212003",
      "brgy_name": "Dangay",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212004",
      "brgy_name": "Happy Valley",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212005",
      "brgy_name": "Libertad",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212006",
      "brgy_name": "Libtong",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212007",
      "brgy_name": "Mabuhay",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212008",
      "brgy_name": "Maraska",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212009",
      "brgy_name": "Odiong",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212010",
      "brgy_name": "Paclasan (Pob.)",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212011",
      "brgy_name": "San Aquilino",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212012",
      "brgy_name": "San Isidro",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212013",
      "brgy_name": "San Jose",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212014",
      "brgy_name": "San Mariano",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212015",
      "brgy_name": "San Miguel",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212016",
      "brgy_name": "San Rafael",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212017",
      "brgy_name": "San Vicente",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212018",
      "brgy_name": "Uyao",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212019",
      "brgy_name": "Victoria",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175212020",
      "brgy_name": "Little Tanauan",
      "city_code": "175212",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213001",
      "brgy_name": "Bigaan",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213002",
      "brgy_name": "Calangatan",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213003",
      "brgy_name": "Calsapa",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213004",
      "brgy_name": "Ilag",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213005",
      "brgy_name": "Lumangbayan",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213006",
      "brgy_name": "Tacligan",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213007",
      "brgy_name": "Poblacion",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175213008",
      "brgy_name": "Caagutayan",
      "city_code": "175213",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214001",
      "brgy_name": "Bagsok",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214002",
      "brgy_name": "Batong Dalig",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214003",
      "brgy_name": "Bayuin",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214005",
      "brgy_name": "Calocmoy",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214006",
      "brgy_name": "Catiningan",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214007",
      "brgy_name": "Villareal (Daan)",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214008",
      "brgy_name": "Fortuna",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214009",
      "brgy_name": "Happy Valley",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214010",
      "brgy_name": "Calubayan",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214012",
      "brgy_name": "Leuteboro I",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214013",
      "brgy_name": "Leuteboro II",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214014",
      "brgy_name": "Mabuhay I",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214015",
      "brgy_name": "Malugay",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214016",
      "brgy_name": "Matungao",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214017",
      "brgy_name": "Monteverde",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214018",
      "brgy_name": "Pasi I",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214019",
      "brgy_name": "Pasi II",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214020",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214021",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214022",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214023",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214024",
      "brgy_name": "Santo Domingo (Lapog)",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214025",
      "brgy_name": "Subaan",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214027",
      "brgy_name": "Bugtong Na Tuog",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214028",
      "brgy_name": "Mabuhay II",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175214029",
      "brgy_name": "Ma. Concepcion",
      "city_code": "175214",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215001",
      "brgy_name": "Alcate",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215002",
      "brgy_name": "Babangonan",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215003",
      "brgy_name": "Bagong Silang",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215004",
      "brgy_name": "Bagong Buhay",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215005",
      "brgy_name": "Bambanin",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215006",
      "brgy_name": "Bethel",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215007",
      "brgy_name": "Canaan",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215008",
      "brgy_name": "Concepcion",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215009",
      "brgy_name": "Duongan",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215010",
      "brgy_name": "Loyal",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215011",
      "brgy_name": "Mabini",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215012",
      "brgy_name": "Macatoc",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215013",
      "brgy_name": "Malabo",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215015",
      "brgy_name": "Merit",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215016",
      "brgy_name": "Ordovilla",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215017",
      "brgy_name": "Pakyas",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215018",
      "brgy_name": "Poblacion I",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215019",
      "brgy_name": "Poblacion II",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215020",
      "brgy_name": "Poblacion III",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215021",
      "brgy_name": "Poblacion IV",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215027",
      "brgy_name": "Sampaguita",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215028",
      "brgy_name": "San Antonio",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215029",
      "brgy_name": "San Gabriel",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215030",
      "brgy_name": "San Gelacio",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215031",
      "brgy_name": "San Isidro",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215032",
      "brgy_name": "San Juan",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215033",
      "brgy_name": "San Narciso",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215034",
      "brgy_name": "Urdaneta",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215035",
      "brgy_name": "Villa Cerveza",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215036",
      "brgy_name": "Jose Leido Jr.",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215037",
      "brgy_name": "San Cristobal",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175215038",
      "brgy_name": "Antonino",
      "city_code": "175215",
      "province_code": "1752",
      "region_code": "17"
    },
    {
      "brgy_code": "175301001",
      "brgy_name": "Apo-Aporawan",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301002",
      "brgy_name": "Apoc-apoc",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301003",
      "brgy_name": "Aporawan",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301004",
      "brgy_name": "Barake",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301005",
      "brgy_name": "Cabigaan",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301006",
      "brgy_name": "Gogognan",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301007",
      "brgy_name": "Iraan",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301008",
      "brgy_name": "Isaub",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301009",
      "brgy_name": "Jose Rizal",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301010",
      "brgy_name": "Mabini",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301011",
      "brgy_name": "Magbabadil",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301012",
      "brgy_name": "Plaridel",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301014",
      "brgy_name": "Ramon Magsaysay",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301015",
      "brgy_name": "Sagpangan",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301016",
      "brgy_name": "San Juan",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301017",
      "brgy_name": "Tagpait",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301018",
      "brgy_name": "Tigman",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301019",
      "brgy_name": "Poblacion",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175301020",
      "brgy_name": "Culandanum",
      "city_code": "175301",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302002",
      "brgy_name": "Algeciras",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302003",
      "brgy_name": "Concepcion",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302004",
      "brgy_name": "Diit",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302005",
      "brgy_name": "Maracanao",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302006",
      "brgy_name": "Matarawis",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302007",
      "brgy_name": "Abagat (Pob.)",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302008",
      "brgy_name": "Bangcal (Pob.)",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302009",
      "brgy_name": "Cambian (Pob.)",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302010",
      "brgy_name": "Villafria",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175302011",
      "brgy_name": "Villasol",
      "city_code": "175302",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303001",
      "brgy_name": "Balogo",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303004",
      "brgy_name": "Dagman",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303005",
      "brgy_name": "Dalayawon",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303006",
      "brgy_name": "Lumacad",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303007",
      "brgy_name": "Madoldolon",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303008",
      "brgy_name": "Mauringuen",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303009",
      "brgy_name": "Osmeña",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303010",
      "brgy_name": "San Jose De Oro",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303011",
      "brgy_name": "Santo Niño",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303012",
      "brgy_name": "Taloto",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303013",
      "brgy_name": "Tinintinan",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303014",
      "brgy_name": "Tudela (Calandagan)",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175303015",
      "brgy_name": "Poblacion (Centro)",
      "city_code": "175303",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304001",
      "brgy_name": "Agutayan",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304002",
      "brgy_name": "Bugsuk (New Cagayancillo)",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304003",
      "brgy_name": "Bancalaan",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304005",
      "brgy_name": "Indalawan",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304006",
      "brgy_name": "Catagupan",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304007",
      "brgy_name": "Malaking Ilog",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304008",
      "brgy_name": "Mangsee",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304009",
      "brgy_name": "Melville",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304011",
      "brgy_name": "Pandanan",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304012",
      "brgy_name": "Pasig",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304019",
      "brgy_name": "Rabor",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304020",
      "brgy_name": "Ramos",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304021",
      "brgy_name": "Salang",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304022",
      "brgy_name": "Sebaring",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304024",
      "brgy_name": "Poblacion I",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304025",
      "brgy_name": "Poblacion II",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304026",
      "brgy_name": "Poblacion III",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304027",
      "brgy_name": "Poblacion IV",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304028",
      "brgy_name": "Poblacion V",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175304029",
      "brgy_name": "Poblacion VI",
      "city_code": "175304",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305001",
      "brgy_name": "Bono-bono",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305002",
      "brgy_name": "Bulalacao",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305003",
      "brgy_name": "Buliluyan",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305004",
      "brgy_name": "Culandanum",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305005",
      "brgy_name": "Igang-igang",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305006",
      "brgy_name": "Inogbong",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305007",
      "brgy_name": "Iwahig",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305008",
      "brgy_name": "Malihud",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305009",
      "brgy_name": "Malitub",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305010",
      "brgy_name": "Marangas (Pob.)",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305011",
      "brgy_name": "Ocayan",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305012",
      "brgy_name": "Puring",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305013",
      "brgy_name": "Rio Tuba",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305014",
      "brgy_name": "Sandoval",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305015",
      "brgy_name": "Sapa",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305016",
      "brgy_name": "Sarong",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305017",
      "brgy_name": "Sumbiling",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305018",
      "brgy_name": "Tabud",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305019",
      "brgy_name": "Tagnato",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305020",
      "brgy_name": "Tagolango",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305021",
      "brgy_name": "Taratak",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175305022",
      "brgy_name": "Tarusan",
      "city_code": "175305",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306001",
      "brgy_name": "Amas",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306002",
      "brgy_name": "Aribungos",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306003",
      "brgy_name": "Barong-barong",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306004",
      "brgy_name": "Calasaguen",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306005",
      "brgy_name": "Imulnod",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306006",
      "brgy_name": "Ipilan",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306010",
      "brgy_name": "Maasin",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306011",
      "brgy_name": "Mainit",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306012",
      "brgy_name": "Malis",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306013",
      "brgy_name": "Mambalot",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306014",
      "brgy_name": "Oring-oring",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306015",
      "brgy_name": "Pangobilian",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306017",
      "brgy_name": "Poblacion I",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306018",
      "brgy_name": "Poblacion II",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306023",
      "brgy_name": "Salogon",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306024",
      "brgy_name": "Samareñana",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306025",
      "brgy_name": "Saraza",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175306026",
      "brgy_name": "Tubtub",
      "city_code": "175306",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307001",
      "brgy_name": "Bogtong",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307002",
      "brgy_name": "Buluang",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307004",
      "brgy_name": "Cheey",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307005",
      "brgy_name": "Concepcion",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307006",
      "brgy_name": "Maglalambay",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307007",
      "brgy_name": "New Busuanga (Pob.)",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307008",
      "brgy_name": "Old Busuanga",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307009",
      "brgy_name": "Panlaitan",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307010",
      "brgy_name": "Quezon",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307011",
      "brgy_name": "Sagrada",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307012",
      "brgy_name": "Salvacion",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307013",
      "brgy_name": "San Isidro",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307014",
      "brgy_name": "San Rafael",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175307015",
      "brgy_name": "Santo Niño",
      "city_code": "175307",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308001",
      "brgy_name": "Bantayan (Pob.)",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308002",
      "brgy_name": "Calsada (Pob.)",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308003",
      "brgy_name": "Convento (Pob.)",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308004",
      "brgy_name": "Lipot North (Pob.)",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308005",
      "brgy_name": "Lipot South (Pob.)",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308006",
      "brgy_name": "Magsaysay",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308007",
      "brgy_name": "Mampio",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308008",
      "brgy_name": "Nusa",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308009",
      "brgy_name": "Santa Cruz",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308010",
      "brgy_name": "Tacas (Pob.)",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308011",
      "brgy_name": "Talaga",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175308012",
      "brgy_name": "Wahig (Pob.)",
      "city_code": "175308",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309001",
      "brgy_name": "Banuang Daan",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309002",
      "brgy_name": "Bintuan",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309003",
      "brgy_name": "Borac",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309004",
      "brgy_name": "Buenavista",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309005",
      "brgy_name": "Bulalacao",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309006",
      "brgy_name": "Cabugao",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309008",
      "brgy_name": "Decabobo",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309009",
      "brgy_name": "Decalachao",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309010",
      "brgy_name": "Guadalupe",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309011",
      "brgy_name": "Lajala",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309012",
      "brgy_name": "Malawig",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309013",
      "brgy_name": "Marcilla",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309016",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309017",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309018",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309019",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309020",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309022",
      "brgy_name": "San Jose",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309023",
      "brgy_name": "San Nicolas",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309024",
      "brgy_name": "Tagumpay",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309025",
      "brgy_name": "Tara",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309026",
      "brgy_name": "Turda",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175309032",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "175309",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310001",
      "brgy_name": "Balading",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310002",
      "brgy_name": "Bangcal (Pob.)",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310003",
      "brgy_name": "Cabigsing (Pob.)",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310004",
      "brgy_name": "Caburian",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310005",
      "brgy_name": "Caponayan",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310006",
      "brgy_name": "Catadman (Pob.)",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310007",
      "brgy_name": "Funda",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310008",
      "brgy_name": "Lagaoriao (Pob.)",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310009",
      "brgy_name": "Lubid",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310010",
      "brgy_name": "Manamoc",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310011",
      "brgy_name": "Maringian",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310012",
      "brgy_name": "Lungsod (Pob.)",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310014",
      "brgy_name": "Pawa",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310015",
      "brgy_name": "San Carlos",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310016",
      "brgy_name": "Suba",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310017",
      "brgy_name": "Tenga-tenga (Pob.)",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175310018",
      "brgy_name": "Tocadan (Pob.)",
      "city_code": "175310",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311001",
      "brgy_name": "Bacao",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311002",
      "brgy_name": "Bohol",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311003",
      "brgy_name": "Calasag",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311004",
      "brgy_name": "Capayas",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311005",
      "brgy_name": "Catep",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311006",
      "brgy_name": "Culasian",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311007",
      "brgy_name": "Danleg",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311008",
      "brgy_name": "Dumaran (Pob.)",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311009",
      "brgy_name": "Itangil",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311010",
      "brgy_name": "Ilian",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311011",
      "brgy_name": "Magsaysay",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311012",
      "brgy_name": "San Juan",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311013",
      "brgy_name": "Santa Teresita",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311014",
      "brgy_name": "Santo Tomas",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311015",
      "brgy_name": "Tanatanaon",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175311016",
      "brgy_name": "Santa Maria",
      "city_code": "175311",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312001",
      "brgy_name": "Bagong Bayan",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312002",
      "brgy_name": "Buena Suerte Pob. (Barangay 2)",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312003",
      "brgy_name": "Barotuan",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312004",
      "brgy_name": "Bebeladan",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312005",
      "brgy_name": "Corong-corong Pob. (Barangay 4)",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312006",
      "brgy_name": "Mabini",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312007",
      "brgy_name": "Manlag",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312008",
      "brgy_name": "Masagana Pob. (Barangay 3)",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312009",
      "brgy_name": "New Ibajay",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312010",
      "brgy_name": "Pasadeña",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312011",
      "brgy_name": "Maligaya Pob. (Barangay 1)",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312012",
      "brgy_name": "San Fernando",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312013",
      "brgy_name": "Sibaltan",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312014",
      "brgy_name": "Teneguiban",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312015",
      "brgy_name": "Villa Libertad",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312016",
      "brgy_name": "Villa Paz",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312017",
      "brgy_name": "Bucana",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175312018",
      "brgy_name": "Aberawan",
      "city_code": "175312",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313001",
      "brgy_name": "Barangonan (Iloc)",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313002",
      "brgy_name": "Cabunlawan",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313003",
      "brgy_name": "Calibangbangan",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313004",
      "brgy_name": "Decabaitot",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313006",
      "brgy_name": "Maroyogroyog",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313007",
      "brgy_name": "Nangalao",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313008",
      "brgy_name": "New Culaylayan",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313009",
      "brgy_name": "Pical",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313010",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175313011",
      "brgy_name": "San Nicolas",
      "city_code": "175313",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314001",
      "brgy_name": "Alcoba",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314002",
      "brgy_name": "Balaguen",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314003",
      "brgy_name": "Canipo",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314004",
      "brgy_name": "Cocoro",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314005",
      "brgy_name": "Danawan (Pob.)",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314006",
      "brgy_name": "Emilod",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314007",
      "brgy_name": "Igabas",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314008",
      "brgy_name": "Lacaren",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314009",
      "brgy_name": "Los Angeles",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314010",
      "brgy_name": "Lucbuan",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175314011",
      "brgy_name": "Rizal",
      "city_code": "175314",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315001",
      "brgy_name": "Antipuluan",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315002",
      "brgy_name": "Aramaywan",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315003",
      "brgy_name": "Batang-batang",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315004",
      "brgy_name": "Bato-bato",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315005",
      "brgy_name": "Burirao",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315006",
      "brgy_name": "Caguisan",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315007",
      "brgy_name": "Calategas",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315008",
      "brgy_name": "Dumagueña",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315009",
      "brgy_name": "Elvita",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315010",
      "brgy_name": "Estrella Village",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315011",
      "brgy_name": "Ipilan",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315012",
      "brgy_name": "Malatgao",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315013",
      "brgy_name": "Malinao",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315014",
      "brgy_name": "Narra (Pob.)",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315015",
      "brgy_name": "Panacan",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315016",
      "brgy_name": "Princess Urduja",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315017",
      "brgy_name": "Sandoval",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315018",
      "brgy_name": "Tacras",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315019",
      "brgy_name": "Taritien",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315020",
      "brgy_name": "Teresa",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315021",
      "brgy_name": "Tinagong Dagat",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315022",
      "brgy_name": "Bagong Sikat",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175315023",
      "brgy_name": "Panacan 2",
      "city_code": "175315",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316001",
      "brgy_name": "Babuyan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316002",
      "brgy_name": "Bacungan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316003",
      "brgy_name": "Bagong Bayan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316004",
      "brgy_name": "Bagong Pag-Asa (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316005",
      "brgy_name": "Bagong Sikat (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316006",
      "brgy_name": "Bagong Silang (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316008",
      "brgy_name": "Bahile",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316009",
      "brgy_name": "Bancao-bancao",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316010",
      "brgy_name": "Binduyan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316011",
      "brgy_name": "Buenavista",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316012",
      "brgy_name": "Cabayugan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316013",
      "brgy_name": "Concepcion",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316014",
      "brgy_name": "Inagawan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316015",
      "brgy_name": "Irawan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316016",
      "brgy_name": "Iwahig (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316017",
      "brgy_name": "Kalipay (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316018",
      "brgy_name": "Kamuning",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316019",
      "brgy_name": "Langogan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316020",
      "brgy_name": "Liwanag (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316021",
      "brgy_name": "Lucbuan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316022",
      "brgy_name": "Mabuhay (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316023",
      "brgy_name": "Macarascas",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316024",
      "brgy_name": "Magkakaibigan (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316025",
      "brgy_name": "Maligaya (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316026",
      "brgy_name": "Manalo",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316027",
      "brgy_name": "Manggahan (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316028",
      "brgy_name": "Maningning (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316029",
      "brgy_name": "Maoyon",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316030",
      "brgy_name": "Marufinas",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316031",
      "brgy_name": "Maruyogon",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316032",
      "brgy_name": "Masigla (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316033",
      "brgy_name": "Masikap (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316034",
      "brgy_name": "Masipag (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316035",
      "brgy_name": "Matahimik (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316036",
      "brgy_name": "Matiyaga (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316037",
      "brgy_name": "Maunlad (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316038",
      "brgy_name": "Milagrosa (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316039",
      "brgy_name": "Model (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316040",
      "brgy_name": "Montible (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316041",
      "brgy_name": "Napsan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316042",
      "brgy_name": "New Panggangan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316043",
      "brgy_name": "Pagkakaisa (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316044",
      "brgy_name": "Princesa (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316045",
      "brgy_name": "Salvacion",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316046",
      "brgy_name": "San Jose",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316047",
      "brgy_name": "San Miguel",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316048",
      "brgy_name": "San Pedro",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316049",
      "brgy_name": "San Rafael",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316050",
      "brgy_name": "Santa Cruz",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316051",
      "brgy_name": "Santa Lourdes",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316052",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316053",
      "brgy_name": "Santa Monica",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316054",
      "brgy_name": "Seaside (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316055",
      "brgy_name": "Sicsican",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316056",
      "brgy_name": "Simpocan",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316057",
      "brgy_name": "Tagabinit",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316058",
      "brgy_name": "Tagburos",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316059",
      "brgy_name": "Tagumpay (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316060",
      "brgy_name": "Tanabag",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316061",
      "brgy_name": "Tanglaw (Pob.)",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316062",
      "brgy_name": "Barangay ng mga Mangingisda",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316063",
      "brgy_name": "Inagawan Sub-Colony",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316064",
      "brgy_name": "Luzviminda",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316065",
      "brgy_name": "Mandaragat",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316066",
      "brgy_name": "San Manuel",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175316067",
      "brgy_name": "Tiniguiban",
      "city_code": "175316",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317001",
      "brgy_name": "Alfonso XIII (Pob.)",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317002",
      "brgy_name": "Aramaywan",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317003",
      "brgy_name": "Berong",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317005",
      "brgy_name": "Calumpang",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317011",
      "brgy_name": "Isugod",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317012",
      "brgy_name": "Quinlogan",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317014",
      "brgy_name": "Maasin",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317016",
      "brgy_name": "Panitian",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317017",
      "brgy_name": "Pinaglabanan",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317020",
      "brgy_name": "Sowangan",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317021",
      "brgy_name": "Tabon",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317023",
      "brgy_name": "Calatagbak",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317024",
      "brgy_name": "Malatgao",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175317025",
      "brgy_name": "Tagusao",
      "city_code": "175317",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318001",
      "brgy_name": "Abaroan",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318002",
      "brgy_name": "Antonino",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318003",
      "brgy_name": "Bagong Bayan",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318004",
      "brgy_name": "Caramay",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318005",
      "brgy_name": "Dumarao",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318006",
      "brgy_name": "Iraan",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318008",
      "brgy_name": "Jolo",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318009",
      "brgy_name": "Magara (Arasan)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318010",
      "brgy_name": "Malcampo",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318011",
      "brgy_name": "Mendoza",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318012",
      "brgy_name": "Narra (Minara)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318013",
      "brgy_name": "New Barbacan (Retac)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318014",
      "brgy_name": "New Cuyo",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318015",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318017",
      "brgy_name": "Rizal",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318018",
      "brgy_name": "Salvacion",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318019",
      "brgy_name": "San Isidro",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318020",
      "brgy_name": "San Jose",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318021",
      "brgy_name": "San Miguel",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318022",
      "brgy_name": "San Nicolas",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318023",
      "brgy_name": "Sandoval",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318024",
      "brgy_name": "Tagumpay",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318025",
      "brgy_name": "Taradungan",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318026",
      "brgy_name": "Tinitian",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318028",
      "brgy_name": "Tumarbong",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318029",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318030",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318031",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318032",
      "brgy_name": "Barangay V Pob. (Porao Island)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318033",
      "brgy_name": "Barangay VI Pob. (Johnson Island)",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175318034",
      "brgy_name": "Nicanor Zabala",
      "city_code": "175318",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319001",
      "brgy_name": "Alimanguan",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319002",
      "brgy_name": "Binga",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319003",
      "brgy_name": "Caruray",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319004",
      "brgy_name": "Kemdeng",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319005",
      "brgy_name": "New Agutaya",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319006",
      "brgy_name": "New Canipo",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319007",
      "brgy_name": "Port Barton",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319008",
      "brgy_name": "Poblacion (San Vicente)",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319009",
      "brgy_name": "San Isidro",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175319010",
      "brgy_name": "Santo Niño",
      "city_code": "175319",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320001",
      "brgy_name": "Abongan",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320002",
      "brgy_name": "Banbanan",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320003",
      "brgy_name": "Bantulan",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320004",
      "brgy_name": "Batas",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320005",
      "brgy_name": "Bato",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320006",
      "brgy_name": "Beton",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320007",
      "brgy_name": "Busy Bees",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320008",
      "brgy_name": "Calawag",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320009",
      "brgy_name": "Casian",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320010",
      "brgy_name": "Cataban",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320011",
      "brgy_name": "Debangan",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320012",
      "brgy_name": "Depla",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320013",
      "brgy_name": "Liminangcong",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320014",
      "brgy_name": "Meytegued",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320015",
      "brgy_name": "New Guinlo",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320016",
      "brgy_name": "Old Guinlo",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320017",
      "brgy_name": "Pamantolon",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320018",
      "brgy_name": "Pancol",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320019",
      "brgy_name": "Paly (Paly Island)",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320020",
      "brgy_name": "Poblacion",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320021",
      "brgy_name": "Pularaquen (Canique)",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320022",
      "brgy_name": "San Jose",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320023",
      "brgy_name": "Sandoval",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320024",
      "brgy_name": "Silanga",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320025",
      "brgy_name": "Alacalian",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320026",
      "brgy_name": "Baras (Pangpang)",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320027",
      "brgy_name": "Libertad",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320028",
      "brgy_name": "Minapla",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320029",
      "brgy_name": "Talog",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320030",
      "brgy_name": "Tumbod",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175320031",
      "brgy_name": "Paglaum",
      "city_code": "175320",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175321004",
      "brgy_name": "Pag-Asa (Pob.)",
      "city_code": "175321",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322001",
      "brgy_name": "Balala",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322002",
      "brgy_name": "Baldat",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322003",
      "brgy_name": "Binudac",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322004",
      "brgy_name": "Culango",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322005",
      "brgy_name": "Galoc",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322006",
      "brgy_name": "Jardin",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322007",
      "brgy_name": "Libis",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322008",
      "brgy_name": "Luac",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322009",
      "brgy_name": "Malaking Patag",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322010",
      "brgy_name": "Osmeña",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322011",
      "brgy_name": "Tiza",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322012",
      "brgy_name": "Burabod",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322013",
      "brgy_name": "Halsey",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175322014",
      "brgy_name": "De Carabao",
      "city_code": "175322",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323001",
      "brgy_name": "Bunog",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323002",
      "brgy_name": "Campong Ulay",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323003",
      "brgy_name": "Candawaga",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323004",
      "brgy_name": "Canipaan",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323005",
      "brgy_name": "Culasian",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323006",
      "brgy_name": "Iraan",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323007",
      "brgy_name": "Latud",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323008",
      "brgy_name": "Panalingaan",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323009",
      "brgy_name": "Punta Baja",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323010",
      "brgy_name": "Ransang",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175323011",
      "brgy_name": "Taburi",
      "city_code": "175323",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324001",
      "brgy_name": "Abo-abo",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324002",
      "brgy_name": "Iraray",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324003",
      "brgy_name": "Isumbo",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324004",
      "brgy_name": "Labog",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324005",
      "brgy_name": "Panitian",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324006",
      "brgy_name": "Pulot Center",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324007",
      "brgy_name": "Pulot Interior (Pulot II)",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324008",
      "brgy_name": "Pulot Shore (Pulot I)",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175324009",
      "brgy_name": "Punang",
      "city_code": "175324",
      "province_code": "1753",
      "region_code": "17"
    },
    {
      "brgy_code": "175901001",
      "brgy_name": "Bonlao",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901002",
      "brgy_name": "Calagonsao",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901003",
      "brgy_name": "Camili",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901004",
      "brgy_name": "Camod-Om",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901005",
      "brgy_name": "Madalag",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901006",
      "brgy_name": "Poblacion",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901007",
      "brgy_name": "San Isidro",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901008",
      "brgy_name": "Tugdan",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901009",
      "brgy_name": "Bagsik",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901010",
      "brgy_name": "Gui-ob",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901011",
      "brgy_name": "Lawan",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175901012",
      "brgy_name": "San Roque",
      "city_code": "175901",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902001",
      "brgy_name": "Balogo",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902002",
      "brgy_name": "Banice",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902004",
      "brgy_name": "Hambi-an",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902005",
      "brgy_name": "Lagang",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902006",
      "brgy_name": "Libtong",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902007",
      "brgy_name": "Mainit",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902008",
      "brgy_name": "Nabalay",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902009",
      "brgy_name": "Nasunogan",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902010",
      "brgy_name": "Poblacion",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902011",
      "brgy_name": "Sibay",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902012",
      "brgy_name": "Tan-Ag",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902013",
      "brgy_name": "Toctoc",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902014",
      "brgy_name": "Togbongan",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902015",
      "brgy_name": "Togong",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902016",
      "brgy_name": "Tungonan",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902017",
      "brgy_name": "Tumalum",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175902018",
      "brgy_name": "Yabawon",
      "city_code": "175902",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903001",
      "brgy_name": "Alibagon",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903002",
      "brgy_name": "Cambajao",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903003",
      "brgy_name": "Cambalo",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903004",
      "brgy_name": "Cambijang",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903005",
      "brgy_name": "Cantagda",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903006",
      "brgy_name": "Danao",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903007",
      "brgy_name": "Gutivan",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903008",
      "brgy_name": "Lico",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903009",
      "brgy_name": "Lumbang Este",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903010",
      "brgy_name": "Lumbang Weste",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903011",
      "brgy_name": "Marigondon",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903012",
      "brgy_name": "Poblacion",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903013",
      "brgy_name": "Sugod",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175903014",
      "brgy_name": "Taguilos",
      "city_code": "175903",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175904001",
      "brgy_name": "Balogo",
      "city_code": "175904",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175904002",
      "brgy_name": "Linao",
      "city_code": "175904",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175904003",
      "brgy_name": "Poblacion",
      "city_code": "175904",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175904004",
      "brgy_name": "Pagsangahan",
      "city_code": "175904",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175904005",
      "brgy_name": "Pangulo",
      "city_code": "175904",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175904006",
      "brgy_name": "San Roque",
      "city_code": "175904",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175904007",
      "brgy_name": "Talisay",
      "city_code": "175904",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905002",
      "brgy_name": "Bachawan",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905003",
      "brgy_name": "Calabasahan",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905004",
      "brgy_name": "Dalajican",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905005",
      "brgy_name": "Masudsud",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905006",
      "brgy_name": "Poblacion",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905007",
      "brgy_name": "Sampong",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905008",
      "brgy_name": "San Pedro (Agbatang)",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905009",
      "brgy_name": "San Vicente",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175905010",
      "brgy_name": "Masadya",
      "city_code": "175905",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906001",
      "brgy_name": "Alegria",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906002",
      "brgy_name": "Ambulong",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906003",
      "brgy_name": "Colongcolong",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906004",
      "brgy_name": "Gobon",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906005",
      "brgy_name": "Guintiguiban",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906006",
      "brgy_name": "Ilijan",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906007",
      "brgy_name": "Labnig",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906008",
      "brgy_name": "Mabini",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906009",
      "brgy_name": "Mahaba",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906010",
      "brgy_name": "Mangansag",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906011",
      "brgy_name": "Poblacion",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906013",
      "brgy_name": "San Agustin",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906014",
      "brgy_name": "San Roque",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906015",
      "brgy_name": "San Vicente",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175906016",
      "brgy_name": "Tacasan",
      "city_code": "175906",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907001",
      "brgy_name": "Agojo",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907002",
      "brgy_name": "Balatucan",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907003",
      "brgy_name": "Buenavista",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907004",
      "brgy_name": "Camandao",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907005",
      "brgy_name": "Guinhayaan",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907006",
      "brgy_name": "Limon Norte",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907007",
      "brgy_name": "Limon Sur",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907008",
      "brgy_name": "Manhac",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907009",
      "brgy_name": "Pili",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907011",
      "brgy_name": "Poblacion",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907012",
      "brgy_name": "Punta",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175907013",
      "brgy_name": "Tuguis",
      "city_code": "175907",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908001",
      "brgy_name": "Agsao",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908002",
      "brgy_name": "Agutay",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908003",
      "brgy_name": "Ambulong",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908004",
      "brgy_name": "Dulangan",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908005",
      "brgy_name": "Ipil",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908006",
      "brgy_name": "Jao-asan",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908007",
      "brgy_name": "Poblacion",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908008",
      "brgy_name": "Silum",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175908009",
      "brgy_name": "Tampayan",
      "city_code": "175908",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909002",
      "brgy_name": "Amatong",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909003",
      "brgy_name": "Anahao",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909004",
      "brgy_name": "Bangon",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909005",
      "brgy_name": "Batiano",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909006",
      "brgy_name": "Budiong",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909008",
      "brgy_name": "Canduyong",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909010",
      "brgy_name": "Dapawan",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909012",
      "brgy_name": "Gabawan",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909014",
      "brgy_name": "Libertad",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909015",
      "brgy_name": "Malilico",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909016",
      "brgy_name": "Mayha",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909017",
      "brgy_name": "Panique",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909018",
      "brgy_name": "Pato-o",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909020",
      "brgy_name": "Ligaya (Pob.)",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909021",
      "brgy_name": "Liwanag (Pob.)",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909022",
      "brgy_name": "Poctoy",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909023",
      "brgy_name": "Progreso Este",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909024",
      "brgy_name": "Progreso Weste",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909025",
      "brgy_name": "Rizal",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909026",
      "brgy_name": "Tabing Dagat (Pob.)",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909027",
      "brgy_name": "Tabobo-an",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909029",
      "brgy_name": "Tulay",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909030",
      "brgy_name": "Tumingad",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909031",
      "brgy_name": "Liwayway (Pob.)",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175909032",
      "brgy_name": "Tuburan",
      "city_code": "175909",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910001",
      "brgy_name": "Agbaluto",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910002",
      "brgy_name": "Agpanabat",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910003",
      "brgy_name": "Agbudia",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910004",
      "brgy_name": "Agnaga",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910005",
      "brgy_name": "Agnay",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910006",
      "brgy_name": "Agnipa",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910007",
      "brgy_name": "Agtongo",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910008",
      "brgy_name": "Alad",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910009",
      "brgy_name": "Bagacay",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910010",
      "brgy_name": "Cajimos",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910011",
      "brgy_name": "Calabogo",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910012",
      "brgy_name": "Capaclan",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910013",
      "brgy_name": "Ginablan",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910014",
      "brgy_name": "Guimpingan",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910015",
      "brgy_name": "Ilauran",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910016",
      "brgy_name": "Lamao",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910017",
      "brgy_name": "Li-o",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910018",
      "brgy_name": "Logbon",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910019",
      "brgy_name": "Lunas",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910020",
      "brgy_name": "Lonos",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910021",
      "brgy_name": "Macalas",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910022",
      "brgy_name": "Mapula",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910023",
      "brgy_name": "Cobrador (Naguso)",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910024",
      "brgy_name": "Palje",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910025",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910026",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910027",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910028",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910029",
      "brgy_name": "Sablayan",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910030",
      "brgy_name": "Sawang",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175910031",
      "brgy_name": "Tambac",
      "city_code": "175910",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911001",
      "brgy_name": "Bachawan",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911002",
      "brgy_name": "Binongahan",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911004",
      "brgy_name": "Buli",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911005",
      "brgy_name": "Cabolutan",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911006",
      "brgy_name": "Cagbuaya",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911007",
      "brgy_name": "Carmen",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911008",
      "brgy_name": "Cawayan",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911011",
      "brgy_name": "Doña Juana",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911012",
      "brgy_name": "Dubduban",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911013",
      "brgy_name": "Binugusan",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911015",
      "brgy_name": "Lusong",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911016",
      "brgy_name": "Mahabang Baybay (Long Beach)",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911017",
      "brgy_name": "Poblacion",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911018",
      "brgy_name": "Sugod",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175911019",
      "brgy_name": "Camantaya",
      "city_code": "175911",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912001",
      "brgy_name": "Agpudlos",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912002",
      "brgy_name": "Calunacon",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912003",
      "brgy_name": "Doña Trinidad",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912004",
      "brgy_name": "Linawan",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912005",
      "brgy_name": "Mabini",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912006",
      "brgy_name": "Marigondon Norte",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912007",
      "brgy_name": "Marigondon Sur",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912008",
      "brgy_name": "Matutuna",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912009",
      "brgy_name": "Pag-Alad",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912010",
      "brgy_name": "Poblacion",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912011",
      "brgy_name": "Tan-Agan",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912012",
      "brgy_name": "Victoria",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175912013",
      "brgy_name": "Juncarlo",
      "city_code": "175912",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913001",
      "brgy_name": "Agtiwa",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913002",
      "brgy_name": "Azarga",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913003",
      "brgy_name": "Campalingo",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913004",
      "brgy_name": "Canjalon",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913005",
      "brgy_name": "España",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913006",
      "brgy_name": "Mabini",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913007",
      "brgy_name": "Mabulo",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913008",
      "brgy_name": "Otod",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913009",
      "brgy_name": "Panangcalan",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913010",
      "brgy_name": "Pili",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913011",
      "brgy_name": "Poblacion",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175913012",
      "brgy_name": "Taclobo",
      "city_code": "175913",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175914001",
      "brgy_name": "Busay",
      "city_code": "175914",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175914002",
      "brgy_name": "Combot",
      "city_code": "175914",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175914003",
      "brgy_name": "Lanas",
      "city_code": "175914",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175914004",
      "brgy_name": "Pinamihagan",
      "city_code": "175914",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175914005",
      "brgy_name": "Poblacion (Agcogon)",
      "city_code": "175914",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915001",
      "brgy_name": "Agmanic",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915002",
      "brgy_name": "Canyayo",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915003",
      "brgy_name": "Danao Norte",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915004",
      "brgy_name": "Danao Sur",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915005",
      "brgy_name": "Guinbirayan",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915006",
      "brgy_name": "Guintigbasan",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915007",
      "brgy_name": "Magsaysay",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915008",
      "brgy_name": "Mat-i",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915009",
      "brgy_name": "Pandan",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915010",
      "brgy_name": "Poblacion",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175915011",
      "brgy_name": "Tabugon",
      "city_code": "175915",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175916001",
      "brgy_name": "Agnonoc",
      "city_code": "175916",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175916002",
      "brgy_name": "Bunsoran",
      "city_code": "175916",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175916003",
      "brgy_name": "Claro M. Recto",
      "city_code": "175916",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175916004",
      "brgy_name": "Poblacion (Ferrol)",
      "city_code": "175916",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175916005",
      "brgy_name": "Hinaguman",
      "city_code": "175916",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175916006",
      "brgy_name": "Tubigon",
      "city_code": "175916",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175917001",
      "brgy_name": "Bonga",
      "city_code": "175917",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175917002",
      "brgy_name": "Concepcion Norte (Pob.)",
      "city_code": "175917",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175917003",
      "brgy_name": "Concepcion Sur",
      "city_code": "175917",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175917004",
      "brgy_name": "Paroyhog",
      "city_code": "175917",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175917005",
      "brgy_name": "Santo Niño",
      "city_code": "175917",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "175917006",
      "brgy_name": "San Isidro",
      "city_code": "175917",
      "province_code": "1759",
      "region_code": "17"
    },
    {
      "brgy_code": "050501001",
      "brgy_name": "Baclayon",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501002",
      "brgy_name": "Banao",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501003",
      "brgy_name": "Bariw",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501004",
      "brgy_name": "Basud",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501005",
      "brgy_name": "Bayandong",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501006",
      "brgy_name": "Bonga (Upper)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501008",
      "brgy_name": "Buang",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501009",
      "brgy_name": "Cabasan",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501010",
      "brgy_name": "Cagbulacao",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501011",
      "brgy_name": "Cagraray",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501012",
      "brgy_name": "Cajogutan",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501013",
      "brgy_name": "Cawayan",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501014",
      "brgy_name": "Damacan",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501015",
      "brgy_name": "Gubat Ilawod",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501016",
      "brgy_name": "Gubat Iraya",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501017",
      "brgy_name": "Hindi",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501018",
      "brgy_name": "Igang",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501019",
      "brgy_name": "Langaton",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501020",
      "brgy_name": "Manaet",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501021",
      "brgy_name": "Mapulang Daga",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501022",
      "brgy_name": "Mataas",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501023",
      "brgy_name": "Misibis",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501024",
      "brgy_name": "Nahapunan",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501025",
      "brgy_name": "Namanday",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501026",
      "brgy_name": "Namantao",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501027",
      "brgy_name": "Napao",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501028",
      "brgy_name": "Panarayon",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501029",
      "brgy_name": "Pigcobohan",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501030",
      "brgy_name": "Pili Ilawod",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501031",
      "brgy_name": "Pili Iraya",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501032",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501033",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501034",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501035",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501036",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501037",
      "brgy_name": "Barangay 14 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501038",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501039",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501040",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501041",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501042",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501043",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501044",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501045",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501046",
      "brgy_name": "Pongco (Lower Bonga)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501048",
      "brgy_name": "Busdac (San Jose)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501049",
      "brgy_name": "San Pablo",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501050",
      "brgy_name": "San Pedro",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501051",
      "brgy_name": "Sogod",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501052",
      "brgy_name": "Sula",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501053",
      "brgy_name": "Tambilagao (Tambognon)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501054",
      "brgy_name": "Tambongon (Tambilagao)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501055",
      "brgy_name": "Tanagan",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501056",
      "brgy_name": "Uson",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501057",
      "brgy_name": "Vinisitahan-Basud (Mainland)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050501058",
      "brgy_name": "Vinisitahan-Napao (lsland)",
      "city_code": "050501",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502001",
      "brgy_name": "Anoling",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502002",
      "brgy_name": "Baligang",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502003",
      "brgy_name": "Bantonan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502004",
      "brgy_name": "Bariw",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502006",
      "brgy_name": "Binanderahan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502007",
      "brgy_name": "Binitayan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502009",
      "brgy_name": "Bongabong",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502010",
      "brgy_name": "Cabagñan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502011",
      "brgy_name": "Cabraran Pequeño",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502012",
      "brgy_name": "Calabidongan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502013",
      "brgy_name": "Comun",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502014",
      "brgy_name": "Cotmon",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502015",
      "brgy_name": "Del Rosario",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502016",
      "brgy_name": "Gapo",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502017",
      "brgy_name": "Gotob",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502018",
      "brgy_name": "Ilawod",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502019",
      "brgy_name": "Iluluan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502021",
      "brgy_name": "Libod",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502022",
      "brgy_name": "Ligban",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502023",
      "brgy_name": "Mabunga",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502024",
      "brgy_name": "Magogon",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502025",
      "brgy_name": "Manawan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502026",
      "brgy_name": "Maninila",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502027",
      "brgy_name": "Mina",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502028",
      "brgy_name": "Miti",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502029",
      "brgy_name": "Palanog",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502030",
      "brgy_name": "Panoypoy",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502031",
      "brgy_name": "Pariaan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502032",
      "brgy_name": "Quinartilan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502033",
      "brgy_name": "Quirangay",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502034",
      "brgy_name": "Quitinday",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502035",
      "brgy_name": "Salugan",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502036",
      "brgy_name": "Solong",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502037",
      "brgy_name": "Sua",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502038",
      "brgy_name": "Sumlang",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502039",
      "brgy_name": "Tagaytay",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502040",
      "brgy_name": "Tagoytoy",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502041",
      "brgy_name": "Taladong",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502042",
      "brgy_name": "Taloto",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502043",
      "brgy_name": "Taplacon",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502044",
      "brgy_name": "Tinago",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502045",
      "brgy_name": "Tumpa",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502046",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502047",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502048",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502049",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502050",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502051",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502052",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050502053",
      "brgy_name": "Caguiba",
      "city_code": "050502",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503001",
      "brgy_name": "Alcala",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503002",
      "brgy_name": "Alobo",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503003",
      "brgy_name": "Anislag",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503004",
      "brgy_name": "Bagumbayan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503005",
      "brgy_name": "Balinad",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503006",
      "brgy_name": "Bañadero",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503007",
      "brgy_name": "Bañag",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503008",
      "brgy_name": "Bascaran",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503009",
      "brgy_name": "Bigao",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503010",
      "brgy_name": "Binitayan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503011",
      "brgy_name": "Bongalon",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503012",
      "brgy_name": "Budiao",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503013",
      "brgy_name": "Burgos",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503014",
      "brgy_name": "Busay",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503015",
      "brgy_name": "Cullat",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503016",
      "brgy_name": "Canaron",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503017",
      "brgy_name": "Dela Paz",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503018",
      "brgy_name": "Dinoronan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503019",
      "brgy_name": "Gabawan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503020",
      "brgy_name": "Gapo",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503021",
      "brgy_name": "Ibaugan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503022",
      "brgy_name": "Ilawod Area Pob. (Dist. 2)",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503023",
      "brgy_name": "Inarado",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503024",
      "brgy_name": "Kidaco",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503025",
      "brgy_name": "Kilicao",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503026",
      "brgy_name": "Kimantong",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503027",
      "brgy_name": "Kinawitan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503028",
      "brgy_name": "Kiwalo",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503029",
      "brgy_name": "Lacag",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503030",
      "brgy_name": "Mabini",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503031",
      "brgy_name": "Malabog",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503032",
      "brgy_name": "Malobago",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503033",
      "brgy_name": "Maopi",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503034",
      "brgy_name": "Market Area Pob. (Dist. 1)",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503035",
      "brgy_name": "Maroroy",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503036",
      "brgy_name": "Matnog",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503037",
      "brgy_name": "Mayon",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503038",
      "brgy_name": "Mi-isi",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503039",
      "brgy_name": "Nabasan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503040",
      "brgy_name": "Namantao",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503041",
      "brgy_name": "Pandan",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503042",
      "brgy_name": "Peñafrancia",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503044",
      "brgy_name": "Sagpon",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503045",
      "brgy_name": "Salvacion",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503046",
      "brgy_name": "San Rafael",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503047",
      "brgy_name": "San Ramon",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503048",
      "brgy_name": "San Roque",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503049",
      "brgy_name": "San Vicente Grande",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503050",
      "brgy_name": "San Vicente Pequeño",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503051",
      "brgy_name": "Sipi",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503052",
      "brgy_name": "Tabon-tabon",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503053",
      "brgy_name": "Tagas",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503054",
      "brgy_name": "Talahib",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050503055",
      "brgy_name": "Villahermosa",
      "city_code": "050503",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504001",
      "brgy_name": "Agpay",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504002",
      "brgy_name": "Balite",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504003",
      "brgy_name": "Banao",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504004",
      "brgy_name": "Batbat",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504005",
      "brgy_name": "Binogsacan Lower",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504006",
      "brgy_name": "Bololo",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504007",
      "brgy_name": "Bubulusan",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504008",
      "brgy_name": "Marcial O. Rañola (Cabaloaon)",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504009",
      "brgy_name": "Calzada",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504010",
      "brgy_name": "Catomag",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504011",
      "brgy_name": "Doña Mercedes",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504012",
      "brgy_name": "Doña Tomasa (Magatol)",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504013",
      "brgy_name": "Ilawod",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504014",
      "brgy_name": "Inamnan Pequeño",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504015",
      "brgy_name": "Inamnan Grande",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504016",
      "brgy_name": "Inascan",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504017",
      "brgy_name": "Iraya",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504018",
      "brgy_name": "Lomacao",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504021",
      "brgy_name": "Maguiron",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504022",
      "brgy_name": "Maipon",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504023",
      "brgy_name": "Malabnig",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504024",
      "brgy_name": "Malipo",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504025",
      "brgy_name": "Malobago",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504026",
      "brgy_name": "Maninila",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504027",
      "brgy_name": "Mapaco",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504029",
      "brgy_name": "Masarawag",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504030",
      "brgy_name": "Mauraro",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504031",
      "brgy_name": "Minto",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504032",
      "brgy_name": "Morera",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504033",
      "brgy_name": "Muladbucad Pequeño",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504034",
      "brgy_name": "Muladbucad Grande",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504035",
      "brgy_name": "Ongo",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504036",
      "brgy_name": "Palanas",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504038",
      "brgy_name": "Poblacion",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504040",
      "brgy_name": "Pood",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504041",
      "brgy_name": "Quitago",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504042",
      "brgy_name": "Quibongbongan",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504043",
      "brgy_name": "San Francisco",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504044",
      "brgy_name": "San Jose (Ogsong)",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504045",
      "brgy_name": "San Rafael",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504046",
      "brgy_name": "Sinungtan",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504047",
      "brgy_name": "Tandarora",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504048",
      "brgy_name": "Travesia",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050504049",
      "brgy_name": "Binogsacan Upper",
      "city_code": "050504",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505003",
      "brgy_name": "Bagacay",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505004",
      "brgy_name": "Rizal Pob. (Bgy. 1)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505005",
      "brgy_name": "Mabini Pob. (Bgy. 2)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505006",
      "brgy_name": "Plaza Pob. (Bgy. 3)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505007",
      "brgy_name": "Magsaysay Pob (Bgy. 4)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505008",
      "brgy_name": "Calzada Pob. (Bgy. 7)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505009",
      "brgy_name": "Quitinday Pob. (Bgy. 8)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505010",
      "brgy_name": "White Deer Pob. (Bgy. 9)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505011",
      "brgy_name": "Bautista",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505012",
      "brgy_name": "Cabraran",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505013",
      "brgy_name": "Del Rosario",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505014",
      "brgy_name": "Estrella",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505015",
      "brgy_name": "Florista",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505016",
      "brgy_name": "Mamlad",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505017",
      "brgy_name": "Maogog",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505018",
      "brgy_name": "Mercado Pob. (Bgy. 5)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505019",
      "brgy_name": "Salvacion",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505020",
      "brgy_name": "San Isidro",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505021",
      "brgy_name": "San Roque",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505022",
      "brgy_name": "San Vicente",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505023",
      "brgy_name": "Sinagaran",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505024",
      "brgy_name": "Villa Paz",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050505025",
      "brgy_name": "Aurora Pob. (Bgy. 6)",
      "city_code": "050505",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506001",
      "brgy_name": "Bgy. 47 - Arimbay",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506002",
      "brgy_name": "Bgy. 64 - Bagacay (Bgy. 41 Bagacay)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506003",
      "brgy_name": "Bgy. 48 - Bagong Abre (Bgy. 42)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506004",
      "brgy_name": "Bgy. 66 - Banquerohan (Bgy. 43)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506005",
      "brgy_name": "Bgy. 1 - Em's Barrio (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506007",
      "brgy_name": "Bgy. 11 - Maoyod Pob. (Bgy. 10 & 11)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506008",
      "brgy_name": "Bgy. 12 - Tula-tula (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506009",
      "brgy_name": "Bgy. 13 - Ilawod West Pob. (Ilawod 1)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506010",
      "brgy_name": "Bgy. 14 - Ilawod Pob. (Ilawod 2)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506011",
      "brgy_name": "Bgy. 15 - Ilawod East Pob. (Ilawod 3)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506012",
      "brgy_name": "Bgy. 16 - Kawit-East Washington Drive (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506013",
      "brgy_name": "Bgy. 17 - Rizal Sreet., Ilawod (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506015",
      "brgy_name": "Bgy. 19 - Cabagñan",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506016",
      "brgy_name": "Bgy. 2 - Em's Barrio South (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506017",
      "brgy_name": "Bgy. 18 - Cabagñan West (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506018",
      "brgy_name": "Bgy. 21 - Binanuahan West (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506019",
      "brgy_name": "Bgy. 22 - Binanuahan East (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506020",
      "brgy_name": "Bgy. 23 - Imperial Court Subd. (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506021",
      "brgy_name": "Bgy. 20 - Cabagñan East (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506022",
      "brgy_name": "Bgy. 25 - Lapu-lapu (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506023",
      "brgy_name": "Bgy. 26 - Dinagaan (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506024",
      "brgy_name": "Bgy. 27 - Victory Village South (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506025",
      "brgy_name": "Bgy. 28 - Victory Village North (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506026",
      "brgy_name": "Bgy. 29 - Sabang (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506027",
      "brgy_name": "Bgy. 3 - Em's Barrio East (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506028",
      "brgy_name": "Bgy. 36 - Kapantawan (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506029",
      "brgy_name": "Bgy. 30 - Pigcale (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506030",
      "brgy_name": "Bgy. 31 - Centro-Baybay (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506032",
      "brgy_name": "Bgy. 33 - PNR-Peñaranda St.-Iraya (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506033",
      "brgy_name": "Bgy. 34 - Oro Site-Magallanes St. (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506034",
      "brgy_name": "Bgy. 35 - Tinago (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506035",
      "brgy_name": "Bgy. 37 - Bitano (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506037",
      "brgy_name": "Bgy. 39 - Bonot (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506038",
      "brgy_name": "Bgy. 4 - Sagpon Pob. (Sagpon 1)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506039",
      "brgy_name": "Bgy. 5 - Sagmin Pob. (Sagpon 2)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506040",
      "brgy_name": "Bgy. 6 - Bañadero Pob. (Sagpon 3)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506041",
      "brgy_name": "Bgy. 7 - Baño (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506042",
      "brgy_name": "Bgy. 8 - Bagumbayan (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506043",
      "brgy_name": "Bgy. 9 - Pinaric (Pob.)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506044",
      "brgy_name": "Bgy. 67 - Bariis (Bgy. 46)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506045",
      "brgy_name": "Bgy. 49 - Bigaa (Bgy. 44)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506046",
      "brgy_name": "Bgy. 41 - Bogtong (Bgy. 45)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506047",
      "brgy_name": "Bgy. 53 - Bonga (Bgy. 48)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506048",
      "brgy_name": "Bgy. 69 - Buenavista (Bgy.47)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506049",
      "brgy_name": "Bgy. 51 - Buyuan (Bgy. 49)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506050",
      "brgy_name": "Bgy. 70 - Cagbacong (Bgy. 50)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506051",
      "brgy_name": "Bgy. 40 - Cruzada (Bgy. 52)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506052",
      "brgy_name": "Bgy. 57 - Dap-dap (Bgy. 69)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506053",
      "brgy_name": "Bgy. 45 - Dita (Bgy. 51)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506054",
      "brgy_name": "Bgy. 55 - Estanza (Bgy. 53)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506055",
      "brgy_name": "Bgy. 38 - Gogon (Bgy. 54)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506056",
      "brgy_name": "Bgy. 62 - Homapon (Bgy. 55)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506057",
      "brgy_name": "Bgy. 65 - Imalnod (Bgy. 57)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506058",
      "brgy_name": "Bgy. 54 - Mabinit (Bgy. 59)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506059",
      "brgy_name": "Bgy. 63 - Mariawa (Bgy. 56)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506060",
      "brgy_name": "Bgy. 61 - Maslog (Bgy. 58)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506061",
      "brgy_name": "Bgy. 50 - Padang (Bgy. 60)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506062",
      "brgy_name": "Bgy. 44 - Pawa (Bgy. 61)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506063",
      "brgy_name": "Bgy. 59 - Puro (Bgy. 63)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506064",
      "brgy_name": "Bgy. 42 - Rawis (Bgy. 65)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506065",
      "brgy_name": "Bgy. 68 - San Francisco (Bgy. 62)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506066",
      "brgy_name": "Bgy. 46 - San Joaquin (Bgy. 64)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506067",
      "brgy_name": "Bgy. 32 - San Roque (Bgy. 66)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506069",
      "brgy_name": "Bgy. 43 - Tamaoyan (Bgy. 67)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506070",
      "brgy_name": "Bgy. 56 - Taysan (Bgy. 68)",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506071",
      "brgy_name": "Bgy. 52 - Matanag",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506072",
      "brgy_name": "Bgy. 10 - Cabugao",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506073",
      "brgy_name": "Bgy. 24 - Rizal Street",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506074",
      "brgy_name": "Bgy. 58 - Buragwis",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050506077",
      "brgy_name": "Bgy. 60 - Lamba",
      "city_code": "050506",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507001",
      "brgy_name": "Alongong",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507002",
      "brgy_name": "Apud",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507003",
      "brgy_name": "Bacolod",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507004",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507005",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507006",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507007",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507008",
      "brgy_name": "Zone V (Pob.)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507009",
      "brgy_name": "Zone VI (Pob.)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507010",
      "brgy_name": "Zone VII (Pob.)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507011",
      "brgy_name": "Bariw",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507012",
      "brgy_name": "Bonbon",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507013",
      "brgy_name": "Buga",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507014",
      "brgy_name": "Bulusan",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507015",
      "brgy_name": "Burabod",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507016",
      "brgy_name": "Caguscos",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507017",
      "brgy_name": "East Carisac",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507018",
      "brgy_name": "West Carisac",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507019",
      "brgy_name": "Harigue",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507020",
      "brgy_name": "Libtong",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507021",
      "brgy_name": "Linao",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507022",
      "brgy_name": "Mabayawas",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507023",
      "brgy_name": "Macabugos",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507024",
      "brgy_name": "Magallang",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507025",
      "brgy_name": "Malabiga",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507026",
      "brgy_name": "Marayag",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507027",
      "brgy_name": "Matara",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507028",
      "brgy_name": "Molosbolos",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507029",
      "brgy_name": "Natasan",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507030",
      "brgy_name": "Nogpo",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507031",
      "brgy_name": "Pantao",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507033",
      "brgy_name": "Rawis",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507034",
      "brgy_name": "Sagrada Familia",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507035",
      "brgy_name": "Salvacion",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507036",
      "brgy_name": "Sampongan",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507037",
      "brgy_name": "San Agustin",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507038",
      "brgy_name": "San Antonio",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507039",
      "brgy_name": "San Isidro",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507040",
      "brgy_name": "San Jose",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507041",
      "brgy_name": "San Pascual",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507042",
      "brgy_name": "San Ramon",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507043",
      "brgy_name": "San Vicente",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507044",
      "brgy_name": "Santa Cruz",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507045",
      "brgy_name": "Niño Jesus (Santo Niño Jesus)",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507046",
      "brgy_name": "Talin-talin",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507047",
      "brgy_name": "Tambo",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050507049",
      "brgy_name": "Villa Petrona",
      "city_code": "050507",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508001",
      "brgy_name": "Abella",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508002",
      "brgy_name": "Allang",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508003",
      "brgy_name": "Amtic",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508004",
      "brgy_name": "Bacong",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508005",
      "brgy_name": "Bagumbayan",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508006",
      "brgy_name": "Balanac",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508007",
      "brgy_name": "Baligang",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508008",
      "brgy_name": "Barayong",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508009",
      "brgy_name": "Basag",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508010",
      "brgy_name": "Batang",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508011",
      "brgy_name": "Bay",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508012",
      "brgy_name": "Binanowan",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508013",
      "brgy_name": "Binatagan (Pob.)",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508014",
      "brgy_name": "Bobonsuran",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508015",
      "brgy_name": "Bonga",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508016",
      "brgy_name": "Busac",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508017",
      "brgy_name": "Busay",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508018",
      "brgy_name": "Cabarian",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508019",
      "brgy_name": "Calzada (Pob.)",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508020",
      "brgy_name": "Catburawan",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508021",
      "brgy_name": "Cavasi",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508022",
      "brgy_name": "Culliat",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508023",
      "brgy_name": "Dunao",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508024",
      "brgy_name": "Francia",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508025",
      "brgy_name": "Guilid",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508026",
      "brgy_name": "Herrera",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508027",
      "brgy_name": "Layon",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508028",
      "brgy_name": "Macalidong",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508029",
      "brgy_name": "Mahaba",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508030",
      "brgy_name": "Malama",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508031",
      "brgy_name": "Maonon",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508032",
      "brgy_name": "Nasisi",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508033",
      "brgy_name": "Nabonton",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508034",
      "brgy_name": "Oma-oma",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508035",
      "brgy_name": "Palapas",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508036",
      "brgy_name": "Pandan",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508037",
      "brgy_name": "Paulba",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508038",
      "brgy_name": "Paulog",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508039",
      "brgy_name": "Pinamaniquian",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508040",
      "brgy_name": "Pinit",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508042",
      "brgy_name": "Ranao-ranao",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508043",
      "brgy_name": "San Vicente",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508044",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508045",
      "brgy_name": "Tagpo",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508046",
      "brgy_name": "Tambo",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508047",
      "brgy_name": "Tandarora",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508048",
      "brgy_name": "Tastas",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508049",
      "brgy_name": "Tinago",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508050",
      "brgy_name": "Tinampo",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508051",
      "brgy_name": "Tiongson",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508052",
      "brgy_name": "Tomolin",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508053",
      "brgy_name": "Tuburan",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508054",
      "brgy_name": "Tula-tula Grande",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508055",
      "brgy_name": "Tula-tula Pequeño",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050508056",
      "brgy_name": "Tupas",
      "city_code": "050508",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509001",
      "brgy_name": "Binitayan",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509002",
      "brgy_name": "Calbayog",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509003",
      "brgy_name": "Canaway",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509004",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509005",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509006",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509007",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509008",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509009",
      "brgy_name": "Salvacion",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509010",
      "brgy_name": "San Antonio Santicon (Pob.)",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509011",
      "brgy_name": "San Antonio Sulong",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509012",
      "brgy_name": "San Francisco",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509013",
      "brgy_name": "San Isidro Ilawod",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509014",
      "brgy_name": "San Isidro Iraya",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509015",
      "brgy_name": "San Jose",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509016",
      "brgy_name": "San Roque",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509017",
      "brgy_name": "Santa Cruz",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050509018",
      "brgy_name": "Santa Teresa",
      "city_code": "050509",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510001",
      "brgy_name": "Awang",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510002",
      "brgy_name": "Bagatangki",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510003",
      "brgy_name": "Balading",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510004",
      "brgy_name": "Balza",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510005",
      "brgy_name": "Bariw",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510006",
      "brgy_name": "Baybay",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510007",
      "brgy_name": "Bulang",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510008",
      "brgy_name": "Burabod",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510009",
      "brgy_name": "Cabunturan",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510010",
      "brgy_name": "Comun",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510011",
      "brgy_name": "Diaro",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510012",
      "brgy_name": "Estancia",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510013",
      "brgy_name": "Jonop",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510014",
      "brgy_name": "Labnig",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510015",
      "brgy_name": "Libod",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510016",
      "brgy_name": "Malolos",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510017",
      "brgy_name": "Matalipni",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510018",
      "brgy_name": "Ogob",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510019",
      "brgy_name": "Pawa",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510020",
      "brgy_name": "Payahan",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510021",
      "brgy_name": "Poblacion",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510022",
      "brgy_name": "Bagumbayan",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510023",
      "brgy_name": "Quinarabasahan",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510024",
      "brgy_name": "Santa Elena",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510025",
      "brgy_name": "Soa",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510026",
      "brgy_name": "Sugcad",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510027",
      "brgy_name": "Tagoytoy",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510028",
      "brgy_name": "Tanawan",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050510029",
      "brgy_name": "Tuliw",
      "city_code": "050510",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511001",
      "brgy_name": "Balabagon",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511002",
      "brgy_name": "Balasbas",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511003",
      "brgy_name": "Bamban",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511004",
      "brgy_name": "Buyo",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511005",
      "brgy_name": "Cabacongan",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511006",
      "brgy_name": "Cabit",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511007",
      "brgy_name": "Cawayan",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511008",
      "brgy_name": "Cawit",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511009",
      "brgy_name": "Holugan",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511010",
      "brgy_name": "It-Ba (Pob.)",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511011",
      "brgy_name": "Malobago",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511012",
      "brgy_name": "Manumbalay",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511013",
      "brgy_name": "Nagotgot",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511015",
      "brgy_name": "Pawa",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050511018",
      "brgy_name": "Tinapian",
      "city_code": "050511",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512001",
      "brgy_name": "Badbad",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512002",
      "brgy_name": "Badian",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512003",
      "brgy_name": "Bagsa",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512004",
      "brgy_name": "Bagumbayan",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512005",
      "brgy_name": "Balogo",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512008",
      "brgy_name": "Banao",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512009",
      "brgy_name": "Bangiawon",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512010",
      "brgy_name": "Bongoran",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512011",
      "brgy_name": "Bogtong",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512012",
      "brgy_name": "Busac",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512013",
      "brgy_name": "Cadawag",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512014",
      "brgy_name": "Cagmanaba",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512015",
      "brgy_name": "Calaguimit",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512016",
      "brgy_name": "Calpi",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512017",
      "brgy_name": "Calzada",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512018",
      "brgy_name": "Camagong",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512019",
      "brgy_name": "Casinagan",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512020",
      "brgy_name": "Centro Poblacion",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512021",
      "brgy_name": "Coliat",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512022",
      "brgy_name": "Del Rosario",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512023",
      "brgy_name": "Gumabao",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512024",
      "brgy_name": "Ilaor Norte",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512025",
      "brgy_name": "Ilaor Sur",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512026",
      "brgy_name": "Iraya Norte",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512027",
      "brgy_name": "Iraya Sur",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512028",
      "brgy_name": "Manga",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512029",
      "brgy_name": "Maporong",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512030",
      "brgy_name": "Maramba",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512031",
      "brgy_name": "Moroponros",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512032",
      "brgy_name": "Matambo",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512033",
      "brgy_name": "Mayag",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512034",
      "brgy_name": "Mayao",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512035",
      "brgy_name": "Nagas",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512036",
      "brgy_name": "San Pascual (Nale)",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512037",
      "brgy_name": "Obaliw-Rinas",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512038",
      "brgy_name": "Pistola",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512039",
      "brgy_name": "Ramay",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512040",
      "brgy_name": "Rizal",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512041",
      "brgy_name": "Saban",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512042",
      "brgy_name": "San Agustin",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512043",
      "brgy_name": "San Antonio",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512044",
      "brgy_name": "San Isidro",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512045",
      "brgy_name": "San Jose",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512046",
      "brgy_name": "San Juan",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512047",
      "brgy_name": "San Miguel",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512049",
      "brgy_name": "San Ramon",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512050",
      "brgy_name": "San Vicente (Suca)",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512051",
      "brgy_name": "Talisay",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512052",
      "brgy_name": "Talongog",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512053",
      "brgy_name": "Tapel",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512054",
      "brgy_name": "Tobgon",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512055",
      "brgy_name": "Tobog",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050512056",
      "brgy_name": "Tablon",
      "city_code": "050512",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513001",
      "brgy_name": "Agol",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513002",
      "brgy_name": "Alabangpuro",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513003",
      "brgy_name": "Basicao Coastal",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513004",
      "brgy_name": "Basicao Interior",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513005",
      "brgy_name": "Banawan (Binawan)",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513006",
      "brgy_name": "Binodegahan",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513007",
      "brgy_name": "Buenavista",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513008",
      "brgy_name": "Buyo",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513009",
      "brgy_name": "Caratagan",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513010",
      "brgy_name": "Cuyaoyao",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513011",
      "brgy_name": "Flores",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513012",
      "brgy_name": "Lawinon",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513013",
      "brgy_name": "Macasitas",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513014",
      "brgy_name": "Malapay",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513015",
      "brgy_name": "Malidong",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513016",
      "brgy_name": "Mamlad",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513017",
      "brgy_name": "Marigondon",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513018",
      "brgy_name": "Matanglad",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513019",
      "brgy_name": "Nablangbulod",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513020",
      "brgy_name": "Oringon",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513021",
      "brgy_name": "Palapas",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513022",
      "brgy_name": "Panganiran",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513023",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513024",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513025",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513026",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513027",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513028",
      "brgy_name": "Rawis",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513029",
      "brgy_name": "Salvacion",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513030",
      "brgy_name": "Santo Cristo",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513031",
      "brgy_name": "Sukip",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513032",
      "brgy_name": "Tibabo",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050513033",
      "brgy_name": "La Medalla",
      "city_code": "050513",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514001",
      "brgy_name": "Agos",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514002",
      "brgy_name": "Alnay",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514003",
      "brgy_name": "Alomon",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514004",
      "brgy_name": "Amoguis",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514005",
      "brgy_name": "Anopol",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514006",
      "brgy_name": "Apad",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514007",
      "brgy_name": "Balaba",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514008",
      "brgy_name": "Balangibang",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514009",
      "brgy_name": "Balinad",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514010",
      "brgy_name": "Basud",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514011",
      "brgy_name": "Binagbangan (Pintor)",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514012",
      "brgy_name": "Buyo",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514013",
      "brgy_name": "Centro Occidental (Pob.)",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514014",
      "brgy_name": "Centro Oriental (Pob.)",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514015",
      "brgy_name": "Cepres",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514016",
      "brgy_name": "Cotmon",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514017",
      "brgy_name": "Cotnogan",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514018",
      "brgy_name": "Danao",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514019",
      "brgy_name": "Gabon",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514020",
      "brgy_name": "Gamot",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514022",
      "brgy_name": "Itaran",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514023",
      "brgy_name": "Kinale",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514024",
      "brgy_name": "Kinuartilan",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514025",
      "brgy_name": "La Medalla",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514026",
      "brgy_name": "La Purisima",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514027",
      "brgy_name": "Lanigay",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514028",
      "brgy_name": "Lidong",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514029",
      "brgy_name": "Lourdes",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514030",
      "brgy_name": "Magpanambo",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514031",
      "brgy_name": "Magurang",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514032",
      "brgy_name": "Matacon",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514033",
      "brgy_name": "Maynaga",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514034",
      "brgy_name": "Maysua",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514035",
      "brgy_name": "Mendez",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514036",
      "brgy_name": "Napo",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514037",
      "brgy_name": "Pinagdapugan",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514039",
      "brgy_name": "Ponso",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514040",
      "brgy_name": "Salvacion",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514041",
      "brgy_name": "San Roque",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514042",
      "brgy_name": "Santicon",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514043",
      "brgy_name": "Santa Cruz",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514044",
      "brgy_name": "Santa Teresita",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514045",
      "brgy_name": "Sugcad",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050514046",
      "brgy_name": "Ubaliw",
      "city_code": "050514",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515003",
      "brgy_name": "Bagaobawan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515004",
      "brgy_name": "Batan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515005",
      "brgy_name": "Bilbao",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515006",
      "brgy_name": "Binosawan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515007",
      "brgy_name": "Bogtong",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515008",
      "brgy_name": "Buenavista",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515009",
      "brgy_name": "Buhatan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515010",
      "brgy_name": "Calanaga",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515011",
      "brgy_name": "Caracaran",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515012",
      "brgy_name": "Carogcog",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515013",
      "brgy_name": "Dap-dap",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515014",
      "brgy_name": "Gaba",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515015",
      "brgy_name": "Galicia",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515016",
      "brgy_name": "Guadalupe",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515017",
      "brgy_name": "Hamorawon",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515018",
      "brgy_name": "Lagundi",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515019",
      "brgy_name": "Liguan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515020",
      "brgy_name": "Linao",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515021",
      "brgy_name": "Malobago",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515022",
      "brgy_name": "Mananao",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515023",
      "brgy_name": "Mancao",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515024",
      "brgy_name": "Manila",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515025",
      "brgy_name": "Masaga",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515026",
      "brgy_name": "Morocborocan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515027",
      "brgy_name": "Nagcalsot",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515028",
      "brgy_name": "Pagcolbon",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515029",
      "brgy_name": "Poblacion",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515030",
      "brgy_name": "Sagrada",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515031",
      "brgy_name": "San Ramon",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515032",
      "brgy_name": "Santa Barbara",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515033",
      "brgy_name": "Tinocawan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515034",
      "brgy_name": "Tinopan",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515035",
      "brgy_name": "Viga",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050515036",
      "brgy_name": "Villahermosa",
      "city_code": "050515",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516001",
      "brgy_name": "Alimsog",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516002",
      "brgy_name": "Buhatan",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516003",
      "brgy_name": "Calayucay",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516004",
      "brgy_name": "Fidel Surtida",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516005",
      "brgy_name": "Lidong",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516006",
      "brgy_name": "Bagong San Roque",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516008",
      "brgy_name": "San Juan Pob. (Bgy. 2)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516009",
      "brgy_name": "Santo Domingo Pob. (Bgy. 4)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516010",
      "brgy_name": "San Pedro Pob. (Bgy. 5)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516011",
      "brgy_name": "San Vicente Pob. (Bgy. 6.)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516012",
      "brgy_name": "San Rafael Pob. (Bgy. 7)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516013",
      "brgy_name": "Del Rosario Pob. (Bgy. 3)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516015",
      "brgy_name": "San Francisco Pob. (Bgy. 1)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516017",
      "brgy_name": "Nagsiya Pob. (Bgy. 8)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516018",
      "brgy_name": "Salvacion",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516019",
      "brgy_name": "San Andres",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516020",
      "brgy_name": "San Fernando",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516021",
      "brgy_name": "San Isidro",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516022",
      "brgy_name": "San Roque",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516023",
      "brgy_name": "Santa Misericordia",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516024",
      "brgy_name": "Santo Niño",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516025",
      "brgy_name": "Market Site Pob. (Bgy. 9)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050516026",
      "brgy_name": "Pandayan Pob. (Bgy. 10)",
      "city_code": "050516",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517001",
      "brgy_name": "Agnas (San Miguel Island)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517002",
      "brgy_name": "Bacolod",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517003",
      "brgy_name": "Bangkilingan",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517004",
      "brgy_name": "Bantayan",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517005",
      "brgy_name": "Baranghawon",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517006",
      "brgy_name": "Basagan",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517007",
      "brgy_name": "Basud (Pob.)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517008",
      "brgy_name": "Bogñabong",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517009",
      "brgy_name": "Bombon (Pob.)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517010",
      "brgy_name": "Bonot",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517011",
      "brgy_name": "San Isidro (Boring)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517012",
      "brgy_name": "Buang",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517013",
      "brgy_name": "Buhian",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517014",
      "brgy_name": "Cabagñan",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517015",
      "brgy_name": "Cobo",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517016",
      "brgy_name": "Comon",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517017",
      "brgy_name": "Cormidal",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517018",
      "brgy_name": "Divino Rostro (Pob.)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517019",
      "brgy_name": "Fatima",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517020",
      "brgy_name": "Guinobat",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517021",
      "brgy_name": "Hacienda (San Miguel Island)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517022",
      "brgy_name": "Magapo",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517023",
      "brgy_name": "Mariroc",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517024",
      "brgy_name": "Matagbac",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517025",
      "brgy_name": "Oras",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517026",
      "brgy_name": "Oson",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517027",
      "brgy_name": "Panal",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517029",
      "brgy_name": "Pawa",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517030",
      "brgy_name": "Pinagbobong",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517031",
      "brgy_name": "Quinale Cabasan (Pob.)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517032",
      "brgy_name": "Quinastillojan",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517033",
      "brgy_name": "Rawis (San Miguel Island)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517034",
      "brgy_name": "Sagurong (San Miguel Island)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517035",
      "brgy_name": "Salvacion",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517036",
      "brgy_name": "San Antonio",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517037",
      "brgy_name": "San Carlos",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517038",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517039",
      "brgy_name": "San Lorenzo",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517040",
      "brgy_name": "San Ramon",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517041",
      "brgy_name": "San Roque",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517042",
      "brgy_name": "San Vicente",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517044",
      "brgy_name": "Santo Cristo (Pob.)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517045",
      "brgy_name": "Sua-Igot",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517046",
      "brgy_name": "Tabiguian",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517048",
      "brgy_name": "Tagas",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517049",
      "brgy_name": "Tayhi (Pob.)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050517050",
      "brgy_name": "Visita (San Miguel Island)",
      "city_code": "050517",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518001",
      "brgy_name": "Bagumbayan",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518002",
      "brgy_name": "Bariis",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518003",
      "brgy_name": "Baybay",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518004",
      "brgy_name": "Belen (Malabog)",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518005",
      "brgy_name": "Biyong",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518006",
      "brgy_name": "Bolo",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518007",
      "brgy_name": "Cale",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518008",
      "brgy_name": "Cararayan",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518009",
      "brgy_name": "Coro-coro",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518010",
      "brgy_name": "Dap-dap",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518011",
      "brgy_name": "Gajo",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518012",
      "brgy_name": "Joroan",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518013",
      "brgy_name": "Libjo",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518014",
      "brgy_name": "Libtong",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518017",
      "brgy_name": "Matalibong",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518018",
      "brgy_name": "Maynonong",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518019",
      "brgy_name": "Mayong",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518020",
      "brgy_name": "Misibis",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518021",
      "brgy_name": "Naga",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518023",
      "brgy_name": "Nagas",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518024",
      "brgy_name": "Oyama",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518025",
      "brgy_name": "Putsan",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518026",
      "brgy_name": "San Bernardo",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518027",
      "brgy_name": "Sogod",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "050518028",
      "brgy_name": "Tigbi (Pob.)",
      "city_code": "050518",
      "province_code": "0505",
      "region_code": "05"
    },
    {
      "brgy_code": "051601001",
      "brgy_name": "Angas",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601002",
      "brgy_name": "Bactas",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601003",
      "brgy_name": "Binatagan",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601004",
      "brgy_name": "Caayunan",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601005",
      "brgy_name": "Guinatungan",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601006",
      "brgy_name": "Hinampacan",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601007",
      "brgy_name": "Langa",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601008",
      "brgy_name": "Laniton",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601009",
      "brgy_name": "Lidong",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601010",
      "brgy_name": "Mampili",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601011",
      "brgy_name": "Mandazo",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601012",
      "brgy_name": "Mangcamagong",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601014",
      "brgy_name": "Manmuntay",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601015",
      "brgy_name": "Mantugawe",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601016",
      "brgy_name": "Matnog",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601017",
      "brgy_name": "Mocong",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601018",
      "brgy_name": "Oliva",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601019",
      "brgy_name": "Pagsangahan",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601020",
      "brgy_name": "Pinagwarasan",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601021",
      "brgy_name": "Plaridel",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601022",
      "brgy_name": "Poblacion 1",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601025",
      "brgy_name": "San Felipe",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601027",
      "brgy_name": "San Jose",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601028",
      "brgy_name": "San Pascual",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601030",
      "brgy_name": "Taba-taba",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601031",
      "brgy_name": "Tacad",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601032",
      "brgy_name": "Taisan",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601033",
      "brgy_name": "Tuaca",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051601034",
      "brgy_name": "Poblacion 2",
      "city_code": "051601",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602001",
      "brgy_name": "Alayao",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602002",
      "brgy_name": "Binawangan",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602003",
      "brgy_name": "Calabaca",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602004",
      "brgy_name": "Camagsaan",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602005",
      "brgy_name": "Catabaguangan",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602006",
      "brgy_name": "Catioan",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602007",
      "brgy_name": "Del Pilar",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602008",
      "brgy_name": "Itok",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602009",
      "brgy_name": "Lucbanan",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602010",
      "brgy_name": "Mabini",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602011",
      "brgy_name": "Mactang",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602012",
      "brgy_name": "Mataque",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602013",
      "brgy_name": "Old Camp",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602014",
      "brgy_name": "Poblacion",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602015",
      "brgy_name": "Magsaysay",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602016",
      "brgy_name": "San Antonio",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602017",
      "brgy_name": "San Isidro",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602018",
      "brgy_name": "San Roque",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602019",
      "brgy_name": "Tanawan",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602020",
      "brgy_name": "Ubang",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602021",
      "brgy_name": "Villa Aurora",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051602022",
      "brgy_name": "Villa Belen",
      "city_code": "051602",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603001",
      "brgy_name": "Alawihao",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603002",
      "brgy_name": "Awitan",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603003",
      "brgy_name": "Bagasbas",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603004",
      "brgy_name": "Bibirao",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603005",
      "brgy_name": "Borabod",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603006",
      "brgy_name": "Calasgasan",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603007",
      "brgy_name": "Camambugan",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603008",
      "brgy_name": "Cobangbang (Carumpit)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603012",
      "brgy_name": "Dogongan",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603013",
      "brgy_name": "Gahonon",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603014",
      "brgy_name": "Gubat",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603015",
      "brgy_name": "Lag-On",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603018",
      "brgy_name": "Magang",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603019",
      "brgy_name": "Mambalite",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603021",
      "brgy_name": "Mancruz (Mangcruz)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603023",
      "brgy_name": "Pamorangon",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603024",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603025",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603026",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603027",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603028",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603029",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603030",
      "brgy_name": "Barangay VII (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603031",
      "brgy_name": "Barangay VIII (Pob.)",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051603032",
      "brgy_name": "San Isidro",
      "city_code": "051603",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604001",
      "brgy_name": "Daculang Bolo",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604002",
      "brgy_name": "Dagotdotan",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604003",
      "brgy_name": "Langga",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604004",
      "brgy_name": "Laniton",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604005",
      "brgy_name": "Maisog",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604006",
      "brgy_name": "Mampurog",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604007",
      "brgy_name": "Manlimonsito",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604008",
      "brgy_name": "Matacong (Pob.)",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604009",
      "brgy_name": "Salvacion",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604010",
      "brgy_name": "San Antonio",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604011",
      "brgy_name": "San Isidro",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051604012",
      "brgy_name": "San Ramon",
      "city_code": "051604",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605001",
      "brgy_name": "Bagong Bayan",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605002",
      "brgy_name": "Calero",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605003",
      "brgy_name": "Dahican",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605004",
      "brgy_name": "Dayhagan",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605005",
      "brgy_name": "Larap",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605006",
      "brgy_name": "Luklukan Norte",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605007",
      "brgy_name": "Luklukan Sur",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605008",
      "brgy_name": "Motherlode",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605009",
      "brgy_name": "Nakalaya",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605010",
      "brgy_name": "Osmeña",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605011",
      "brgy_name": "Pag-Asa",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605012",
      "brgy_name": "Parang",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605013",
      "brgy_name": "Plaridel",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605014",
      "brgy_name": "North Poblacion",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605015",
      "brgy_name": "South Poblacion",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605016",
      "brgy_name": "Salvacion",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605017",
      "brgy_name": "San Isidro",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605018",
      "brgy_name": "San Jose",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605019",
      "brgy_name": "San Martin",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605020",
      "brgy_name": "San Pedro",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605021",
      "brgy_name": "San Rafael",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605022",
      "brgy_name": "Santa Cruz",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605023",
      "brgy_name": "Santa Elena",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605024",
      "brgy_name": "Santa Milagrosa",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605025",
      "brgy_name": "Santa Rosa Norte",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605026",
      "brgy_name": "Santa Rosa Sur",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051605027",
      "brgy_name": "Tamisan",
      "city_code": "051605",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606001",
      "brgy_name": "Anameam",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606002",
      "brgy_name": "Awitan",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606003",
      "brgy_name": "Baay",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606004",
      "brgy_name": "Bagacay",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606005",
      "brgy_name": "Bagong Silang I",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606006",
      "brgy_name": "Bagong Silang II",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606007",
      "brgy_name": "Bakiad",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606008",
      "brgy_name": "Bautista",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606009",
      "brgy_name": "Bayabas",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606010",
      "brgy_name": "Bayan-bayan",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606011",
      "brgy_name": "Benit",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606012",
      "brgy_name": "Anahaw (Pob.)",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606013",
      "brgy_name": "Gumamela (Pob.)",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606014",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606015",
      "brgy_name": "Kalamunding (Pob.)",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606016",
      "brgy_name": "Bulhao",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606017",
      "brgy_name": "Cabatuhan",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606018",
      "brgy_name": "Cabusay",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606019",
      "brgy_name": "Calabasa",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606020",
      "brgy_name": "Canapawan",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606021",
      "brgy_name": "Daguit",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606022",
      "brgy_name": "Dalas",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606023",
      "brgy_name": "Dumagmang",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606024",
      "brgy_name": "Exciban",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606025",
      "brgy_name": "Fundado",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606026",
      "brgy_name": "Guinacutan",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606027",
      "brgy_name": "Guisican",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606028",
      "brgy_name": "Iberica",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606030",
      "brgy_name": "Lugui",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606031",
      "brgy_name": "Mabilo I",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606032",
      "brgy_name": "Mabilo II",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606033",
      "brgy_name": "Macogon",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606034",
      "brgy_name": "Mahawan-hawan",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606035",
      "brgy_name": "Malangcao-Basud",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606036",
      "brgy_name": "Malasugui",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606037",
      "brgy_name": "Malatap",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606038",
      "brgy_name": "Malaya",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606039",
      "brgy_name": "Malibago",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606040",
      "brgy_name": "Maot",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606041",
      "brgy_name": "Masalong",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606042",
      "brgy_name": "Matanlang",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606043",
      "brgy_name": "Napaod",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606044",
      "brgy_name": "Pag-Asa",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606045",
      "brgy_name": "Pangpang",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606046",
      "brgy_name": "Pinya (Pob.)",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606047",
      "brgy_name": "San Antonio",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606048",
      "brgy_name": "Santa Cruz",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606049",
      "brgy_name": "Bagong Silang III (Silang)",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606050",
      "brgy_name": "Submakin",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606051",
      "brgy_name": "Talobatib",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606052",
      "brgy_name": "Tigbinan",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051606053",
      "brgy_name": "Tulay Na Lupa",
      "city_code": "051606",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607001",
      "brgy_name": "Apuao",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607003",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607004",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607005",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607006",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607007",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607008",
      "brgy_name": "Barangay VII (Pob.)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607009",
      "brgy_name": "Caringo",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607010",
      "brgy_name": "Catandunganon",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607011",
      "brgy_name": "Cayucyucan",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607012",
      "brgy_name": "Colasi",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607013",
      "brgy_name": "Del Rosario (Tagongtong)",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607014",
      "brgy_name": "Gaboc",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607015",
      "brgy_name": "Hamoraon",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607016",
      "brgy_name": "Hinipaan",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607017",
      "brgy_name": "Lalawigan",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607018",
      "brgy_name": "Lanot",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607019",
      "brgy_name": "Mambungalon",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607020",
      "brgy_name": "Manguisoc",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607021",
      "brgy_name": "Masalongsalong",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607022",
      "brgy_name": "Matoogtoog",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607023",
      "brgy_name": "Pambuhan",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607024",
      "brgy_name": "Quinapaguian",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607025",
      "brgy_name": "San Roque",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051607026",
      "brgy_name": "Tarum",
      "city_code": "051607",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608001",
      "brgy_name": "Awitan",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608002",
      "brgy_name": "Bagumbayan",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608003",
      "brgy_name": "Bakal",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608004",
      "brgy_name": "Batobalani",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608005",
      "brgy_name": "Calaburnay",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608006",
      "brgy_name": "Capacuan",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608007",
      "brgy_name": "Casalugan",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608008",
      "brgy_name": "Dagang",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608009",
      "brgy_name": "Dalnac",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608010",
      "brgy_name": "Dancalan",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608011",
      "brgy_name": "Gumaus",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608012",
      "brgy_name": "Labnig",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608013",
      "brgy_name": "Macolabo Island",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608014",
      "brgy_name": "Malacbang",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608015",
      "brgy_name": "Malaguit",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608016",
      "brgy_name": "Mampungo",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608017",
      "brgy_name": "Mangkasay",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608018",
      "brgy_name": "Maybato",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608019",
      "brgy_name": "Palanas",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608020",
      "brgy_name": "Pinagbirayan Malaki",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608021",
      "brgy_name": "Pinagbirayan Munti",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608022",
      "brgy_name": "Poblacion Norte",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608023",
      "brgy_name": "Poblacion Sur",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608024",
      "brgy_name": "Tabas",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608025",
      "brgy_name": "Talusan",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608026",
      "brgy_name": "Tawig",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051608027",
      "brgy_name": "Tugos",
      "city_code": "051608",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609001",
      "brgy_name": "Asdum",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609002",
      "brgy_name": "Cabanbanan",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609003",
      "brgy_name": "Calabagas",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609004",
      "brgy_name": "Fabrica",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609005",
      "brgy_name": "Iraya Sur",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609006",
      "brgy_name": "Man-Ogob",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609007",
      "brgy_name": "Poblacion District I (Silangan/Bgy. 1)",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609008",
      "brgy_name": "Poblacion District II (Kanluran/Bgy. 2)",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051609009",
      "brgy_name": "San Jose (Iraya Norte)",
      "city_code": "051609",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610001",
      "brgy_name": "Basiad",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610002",
      "brgy_name": "Bulala",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610003",
      "brgy_name": "Polungguitguit",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610004",
      "brgy_name": "Rizal",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610005",
      "brgy_name": "Salvacion",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610006",
      "brgy_name": "San Lorenzo",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610007",
      "brgy_name": "San Pedro",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610008",
      "brgy_name": "San Vicente",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610009",
      "brgy_name": "Santa Elena (Pob.)",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610010",
      "brgy_name": "Villa San Isidro",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610011",
      "brgy_name": "Don Tomas",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610012",
      "brgy_name": "Guitol",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610013",
      "brgy_name": "Kabuluan",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610014",
      "brgy_name": "Kagtalaba",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610015",
      "brgy_name": "Maulawin",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610016",
      "brgy_name": "Patag Ibaba",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610017",
      "brgy_name": "Patag Iraya",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610018",
      "brgy_name": "Plaridel",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051610019",
      "brgy_name": "Tabugon",
      "city_code": "051610",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611001",
      "brgy_name": "Binanuaan",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611002",
      "brgy_name": "Caawigan",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611003",
      "brgy_name": "Cahabaan",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611004",
      "brgy_name": "Calintaan",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611005",
      "brgy_name": "Del Carmen",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611006",
      "brgy_name": "Gabon",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611007",
      "brgy_name": "Itomang",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611008",
      "brgy_name": "Poblacion",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611009",
      "brgy_name": "San Francisco",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611010",
      "brgy_name": "San Isidro",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611011",
      "brgy_name": "San Jose",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611012",
      "brgy_name": "San Nicolas",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611013",
      "brgy_name": "Santa Cruz",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611014",
      "brgy_name": "Santa Elena",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051611015",
      "brgy_name": "Santo Niño",
      "city_code": "051611",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612001",
      "brgy_name": "Aguit-It",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612002",
      "brgy_name": "Banocboc",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612004",
      "brgy_name": "Cagbalogo",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612005",
      "brgy_name": "Calangcawan Norte",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612006",
      "brgy_name": "Calangcawan Sur",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612007",
      "brgy_name": "Guinacutan",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612008",
      "brgy_name": "Mangcayo",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612009",
      "brgy_name": "Mangcawayan",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612010",
      "brgy_name": "Manlucugan",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612011",
      "brgy_name": "Matango",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612012",
      "brgy_name": "Napilihan",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612013",
      "brgy_name": "Pinagtigasan",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612014",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612015",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612016",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612017",
      "brgy_name": "Sabang",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612018",
      "brgy_name": "Santo Domingo",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612019",
      "brgy_name": "Singi",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051612020",
      "brgy_name": "Sula",
      "city_code": "051612",
      "province_code": "0516",
      "region_code": "05"
    },
    {
      "brgy_code": "051701001",
      "brgy_name": "Agdangan Pob. (San Cayetano)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701002",
      "brgy_name": "Antipolo",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701003",
      "brgy_name": "Bagumbayan",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701004",
      "brgy_name": "Caranday (La Purisima)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701005",
      "brgy_name": "Cristo Rey",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701006",
      "brgy_name": "Del Pilar",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701007",
      "brgy_name": "Del Rosario (Pob.)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701008",
      "brgy_name": "Iyagan",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701009",
      "brgy_name": "La Medalla",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701010",
      "brgy_name": "Lourdes",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701011",
      "brgy_name": "Nababarera",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701012",
      "brgy_name": "Sagrada",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701013",
      "brgy_name": "Salvacion",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701014",
      "brgy_name": "Buluang (San Antonio)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701015",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701016",
      "brgy_name": "San Isidro",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701017",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701018",
      "brgy_name": "San Juan",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701019",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701020",
      "brgy_name": "San Rafael (Ikpan)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701021",
      "brgy_name": "Pugay (San Rafael)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701022",
      "brgy_name": "San Ramon (Pob.)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701023",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701024",
      "brgy_name": "San Vicente",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701025",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701026",
      "brgy_name": "Santa Eulalia",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701027",
      "brgy_name": "Santa Isabel",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701028",
      "brgy_name": "Santa Teresa (Viga)",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701029",
      "brgy_name": "Santa Teresita",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051701030",
      "brgy_name": "Tapol",
      "city_code": "051701",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702002",
      "brgy_name": "Cabanbanan",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702003",
      "brgy_name": "Cabungan",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702004",
      "brgy_name": "Camangahan (Caorasan)",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702006",
      "brgy_name": "Cayogcog",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702007",
      "brgy_name": "Coguit",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702008",
      "brgy_name": "Duran",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702009",
      "brgy_name": "Laganac",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702010",
      "brgy_name": "Luluasan",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702011",
      "brgy_name": "Montenegro (Maguiron)",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702012",
      "brgy_name": "Pararao",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702013",
      "brgy_name": "Siramag (Pob.)",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702014",
      "brgy_name": "Pulang Daga",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702015",
      "brgy_name": "Sagrada Nacacale",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702016",
      "brgy_name": "San Francisco",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702017",
      "brgy_name": "Santiago Nacacale",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702018",
      "brgy_name": "Tapayas",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051702019",
      "brgy_name": "Tomatarayo",
      "city_code": "051702",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703001",
      "brgy_name": "Agos",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703002",
      "brgy_name": "Bacolod",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703003",
      "brgy_name": "Buluang",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703004",
      "brgy_name": "Caricot",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703005",
      "brgy_name": "Cawacagan",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703006",
      "brgy_name": "Cotmon",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703007",
      "brgy_name": "Cristo Rey",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703008",
      "brgy_name": "Del Rosario",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703009",
      "brgy_name": "Divina Pastora (Pob.)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703010",
      "brgy_name": "Goyudan",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703011",
      "brgy_name": "Lobong",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703012",
      "brgy_name": "Lubigan",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703013",
      "brgy_name": "Mainit",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703014",
      "brgy_name": "Manga (Mangga)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703015",
      "brgy_name": "Masoli",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703016",
      "brgy_name": "Neighborhood",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703017",
      "brgy_name": "Niño Jesus",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703018",
      "brgy_name": "Pagatpatan",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703019",
      "brgy_name": "Palo",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703020",
      "brgy_name": "Payak",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703023",
      "brgy_name": "Sagrada (Sagrada Familia)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703024",
      "brgy_name": "Salvacion",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703025",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703026",
      "brgy_name": "San Juan",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703027",
      "brgy_name": "San Miguel",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703028",
      "brgy_name": "San Rafael (Pob.)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703029",
      "brgy_name": "San Roque",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703030",
      "brgy_name": "San Vicente",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703031",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703032",
      "brgy_name": "Santiago (Pob.)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703033",
      "brgy_name": "Sooc",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703034",
      "brgy_name": "Tagpolo",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051703035",
      "brgy_name": "Tres Reyes (Pob.)",
      "city_code": "051703",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704001",
      "brgy_name": "San Antonio",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704002",
      "brgy_name": "San Francisco",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704003",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704004",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704005",
      "brgy_name": "Pagao (San Juan)",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704006",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704007",
      "brgy_name": "Siembre",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051704008",
      "brgy_name": "Santo Domingo",
      "city_code": "051704",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705001",
      "brgy_name": "Antipolo",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705002",
      "brgy_name": "Burocbusoc",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705003",
      "brgy_name": "Cabatuan",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705004",
      "brgy_name": "Cagmaslog",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705005",
      "brgy_name": "Amlongan (Del Rosario)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705007",
      "brgy_name": "De La Fe",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705009",
      "brgy_name": "Divino Rostro",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705010",
      "brgy_name": "Ipil (Fatima)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705011",
      "brgy_name": "Gabas",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705012",
      "brgy_name": "Ibayugan",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705013",
      "brgy_name": "Igbac",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705014",
      "brgy_name": "Iraya (Del Rosario)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705015",
      "brgy_name": "Labawon (Santa Teresita)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705016",
      "brgy_name": "Delos Angeles (Los Angeles)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705018",
      "brgy_name": "Monte Calvario",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705019",
      "brgy_name": "Namurabod",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705020",
      "brgy_name": "Sagrada Familia",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705021",
      "brgy_name": "Salvacion",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705022",
      "brgy_name": "San Antonio",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705023",
      "brgy_name": "San Buenaventura (Pob.)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705024",
      "brgy_name": "San Francisco (Parada)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705025",
      "brgy_name": "San Isidro",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705026",
      "brgy_name": "San Jose Baybayon",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705027",
      "brgy_name": "San Jose Salay",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705028",
      "brgy_name": "Macaangay",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705029",
      "brgy_name": "San Pascual (Pob.)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705030",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705031",
      "brgy_name": "San Rafael",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705032",
      "brgy_name": "San Ramon",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705033",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705034",
      "brgy_name": "San Vicente",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705035",
      "brgy_name": "Santa Clara (Pob.)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705036",
      "brgy_name": "Santa Cruz",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705037",
      "brgy_name": "Santa Elena (Pob.)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705038",
      "brgy_name": "Santa Isabel",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705039",
      "brgy_name": "Santa Justina",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705040",
      "brgy_name": "Lourdes (Santa Lourdes)",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051705041",
      "brgy_name": "Tambo",
      "city_code": "051705",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706001",
      "brgy_name": "Bagoladio",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706002",
      "brgy_name": "Bagumbayan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706003",
      "brgy_name": "Balaogan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706004",
      "brgy_name": "Caorasan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706005",
      "brgy_name": "Casugad",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706006",
      "brgy_name": "Causip",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706007",
      "brgy_name": "Fabrica",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706008",
      "brgy_name": "Inoyonan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706009",
      "brgy_name": "Itangon",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706011",
      "brgy_name": "Kinalabasahan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706012",
      "brgy_name": "La Purisima",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706013",
      "brgy_name": "La Victoria",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706014",
      "brgy_name": "Lanipga",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706015",
      "brgy_name": "Lubgan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706016",
      "brgy_name": "Ombao Heights",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706017",
      "brgy_name": "Ombao Polpog",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706018",
      "brgy_name": "Palsong",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706019",
      "brgy_name": "Panoypoyan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706020",
      "brgy_name": "Pawili",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706021",
      "brgy_name": "Sagrada (Sagrada Familia)",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706022",
      "brgy_name": "Salvacion (Pob.)",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706023",
      "brgy_name": "San Agustin",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706024",
      "brgy_name": "San Francisco",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706025",
      "brgy_name": "San Isidro",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706026",
      "brgy_name": "San Jose",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706027",
      "brgy_name": "San Miguel",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706028",
      "brgy_name": "San Ramon",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706029",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706030",
      "brgy_name": "San Roque Heights",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706031",
      "brgy_name": "Santa Elena",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706032",
      "brgy_name": "Santo Domingo",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706033",
      "brgy_name": "Santo Niño",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051706034",
      "brgy_name": "Taisan",
      "city_code": "051706",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707001",
      "brgy_name": "Barcelonita",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707002",
      "brgy_name": "Biong",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707004",
      "brgy_name": "Camagong",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707005",
      "brgy_name": "Castillo",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707006",
      "brgy_name": "New Poblacion",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707007",
      "brgy_name": "Pandan",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707008",
      "brgy_name": "San Pedro",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707009",
      "brgy_name": "Santa Cruz",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051707010",
      "brgy_name": "Santa Lutgarda (Pob.)",
      "city_code": "051707",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708001",
      "brgy_name": "Balatasan",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708002",
      "brgy_name": "Balombon",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708003",
      "brgy_name": "Balongay",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708004",
      "brgy_name": "Belen",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708005",
      "brgy_name": "Bigaas",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708006",
      "brgy_name": "Binanuaanan Grande",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708007",
      "brgy_name": "Binanuaanan Pequeño",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708008",
      "brgy_name": "Binaliw",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708009",
      "brgy_name": "Bonot-Santa Rosa",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708010",
      "brgy_name": "Burabod",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708011",
      "brgy_name": "Cabanbanan",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708012",
      "brgy_name": "Cagsao",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708013",
      "brgy_name": "Camuning",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708014",
      "brgy_name": "Comaguingking",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708015",
      "brgy_name": "Del Carmen (Pob.)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708016",
      "brgy_name": "Dominorog",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708017",
      "brgy_name": "Fabrica",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708018",
      "brgy_name": "Harobay",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708019",
      "brgy_name": "La Purisima",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708021",
      "brgy_name": "Lugsad",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708022",
      "brgy_name": "Manguiring",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708023",
      "brgy_name": "Pagatpat (San Jose)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708024",
      "brgy_name": "Paolbo",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708025",
      "brgy_name": "Pinada",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708026",
      "brgy_name": "Punta Tarawal",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708027",
      "brgy_name": "Quinale",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708028",
      "brgy_name": "Sabang",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708029",
      "brgy_name": "Salvacion-Baybay",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708030",
      "brgy_name": "San Antonio Poblacion",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708031",
      "brgy_name": "San Antonio (Quipayo)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708032",
      "brgy_name": "San Bernardino",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708033",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708034",
      "brgy_name": "San Isidro",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708036",
      "brgy_name": "San Lucas",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708037",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708038",
      "brgy_name": "San Pablo (Pob.)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708039",
      "brgy_name": "San Roque",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708040",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708041",
      "brgy_name": "Santa Cruz Ratay",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708042",
      "brgy_name": "Santa Cruz Poblacion",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708043",
      "brgy_name": "Santa Isabel (Pob.)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708044",
      "brgy_name": "Santa Salud (Pob.)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708045",
      "brgy_name": "Santo Domingo",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708046",
      "brgy_name": "Santo Niño (Quipayo)",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708047",
      "brgy_name": "Siba-o",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708048",
      "brgy_name": "Sibobo",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708049",
      "brgy_name": "Sogod",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051708050",
      "brgy_name": "Tomagodtod",
      "city_code": "051708",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709001",
      "brgy_name": "Dugcal",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709002",
      "brgy_name": "Marupit",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709003",
      "brgy_name": "San Francisco",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709005",
      "brgy_name": "San Juan-San Ramon (Pob.)",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709007",
      "brgy_name": "San Lucas (Pob.)",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709008",
      "brgy_name": "San Marcos (Pob.)",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709009",
      "brgy_name": "San Mateo (Pob.)",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709011",
      "brgy_name": "San Jose-San Pablo (Pob.)",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709013",
      "brgy_name": "San Roque",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709014",
      "brgy_name": "Santo Domingo (Pob.)",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709015",
      "brgy_name": "Santo Tomas (Pob.)",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709016",
      "brgy_name": "Sua",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051709017",
      "brgy_name": "Tarosanan",
      "city_code": "051709",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710001",
      "brgy_name": "Baras (Pob.)",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710002",
      "brgy_name": "Del Rosario",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710003",
      "brgy_name": "Dinaga (Pob.)",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710004",
      "brgy_name": "Fundado",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710005",
      "brgy_name": "Haring",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710006",
      "brgy_name": "Iquin",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710007",
      "brgy_name": "Linaga",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710008",
      "brgy_name": "Mangayawan",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710009",
      "brgy_name": "Palo",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710010",
      "brgy_name": "Pangpang (Pob.)",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710011",
      "brgy_name": "Poro",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710012",
      "brgy_name": "San Agustin",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710014",
      "brgy_name": "San Francisco",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710015",
      "brgy_name": "San Jose East",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710016",
      "brgy_name": "San Jose West",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710017",
      "brgy_name": "San Juan",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710018",
      "brgy_name": "San Nicolas",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710019",
      "brgy_name": "San Roque",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710020",
      "brgy_name": "San Vicente",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710021",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710022",
      "brgy_name": "Santa Teresita",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710023",
      "brgy_name": "Sua",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710024",
      "brgy_name": "Talidtid",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051710025",
      "brgy_name": "Tibgao (Pob.)",
      "city_code": "051710",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711001",
      "brgy_name": "Agaas",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711002",
      "brgy_name": "Antolon",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711003",
      "brgy_name": "Bacgong",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711004",
      "brgy_name": "Bahay",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711005",
      "brgy_name": "Bikal",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711006",
      "brgy_name": "Binanuahan (Pob.)",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711007",
      "brgy_name": "Cabacongan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711008",
      "brgy_name": "Cadong",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711009",
      "brgy_name": "Colongcogong",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711010",
      "brgy_name": "Canatuan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711011",
      "brgy_name": "Caputatan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711012",
      "brgy_name": "Gogon",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711013",
      "brgy_name": "Daraga",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711014",
      "brgy_name": "Gata",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711015",
      "brgy_name": "Gibgos",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711016",
      "brgy_name": "Guijalo",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711017",
      "brgy_name": "Hanopol",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711018",
      "brgy_name": "Hanoy",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711019",
      "brgy_name": "Haponan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711020",
      "brgy_name": "Ilawod",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711022",
      "brgy_name": "Ili-Centro (Pob.)",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711023",
      "brgy_name": "Lidong",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711024",
      "brgy_name": "Lubas",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711025",
      "brgy_name": "Malabog",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711026",
      "brgy_name": "Maligaya",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711027",
      "brgy_name": "Mampirao",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711028",
      "brgy_name": "Mandiclum (Mandiclom)",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711029",
      "brgy_name": "Maqueda",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711030",
      "brgy_name": "Minalaba",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711031",
      "brgy_name": "Oring",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711032",
      "brgy_name": "Oroc-Osoc",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711033",
      "brgy_name": "Pagolinan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711034",
      "brgy_name": "Pandanan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711035",
      "brgy_name": "Paniman",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711036",
      "brgy_name": "Patag-Belen",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711037",
      "brgy_name": "Pili-Centro",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711038",
      "brgy_name": "Pili-Tabiguian",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711039",
      "brgy_name": "Poloan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711040",
      "brgy_name": "Salvacion",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711041",
      "brgy_name": "San Roque",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711042",
      "brgy_name": "San Vicente",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711043",
      "brgy_name": "Santa Cruz",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711044",
      "brgy_name": "Solnopan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711045",
      "brgy_name": "Tabgon",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711046",
      "brgy_name": "Tabiguian",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711047",
      "brgy_name": "Tabog",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711048",
      "brgy_name": "Tawog (Pob.)",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711049",
      "brgy_name": "Toboan",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051711050",
      "brgy_name": "Terogo",
      "city_code": "051711",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712001",
      "brgy_name": "Bagong Silang",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712002",
      "brgy_name": "Bucal",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712003",
      "brgy_name": "Cabasag",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712004",
      "brgy_name": "Comadaycaday",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712005",
      "brgy_name": "Domagondong",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712006",
      "brgy_name": "Kinalangan",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712007",
      "brgy_name": "Comadogcadog",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712008",
      "brgy_name": "Mabini",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712009",
      "brgy_name": "Magais I",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712010",
      "brgy_name": "Magais II",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712011",
      "brgy_name": "Mansalaya",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712012",
      "brgy_name": "Nagkalit",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712013",
      "brgy_name": "Palaspas",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712014",
      "brgy_name": "Pamplona",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712015",
      "brgy_name": "Pasay",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712016",
      "brgy_name": "Pinagdapian",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712017",
      "brgy_name": "Pinugusan",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712018",
      "brgy_name": "Zone I Fatima (Pob.)",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712019",
      "brgy_name": "Zone II San Antonio (Pob.)",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712020",
      "brgy_name": "Poblacion Zone III",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712021",
      "brgy_name": "Sabang",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712022",
      "brgy_name": "Salvacion",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712023",
      "brgy_name": "San Juan",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712024",
      "brgy_name": "San Pablo",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712025",
      "brgy_name": "Santa Rita I",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712026",
      "brgy_name": "Santa Rita II",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712027",
      "brgy_name": "Sinagawsawan",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712028",
      "brgy_name": "Sinuknipan I",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712029",
      "brgy_name": "Sinuknipan II",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712030",
      "brgy_name": "Sugsugin",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712031",
      "brgy_name": "Tabion",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051712032",
      "brgy_name": "Tomagoktok",
      "city_code": "051712",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713001",
      "brgy_name": "Cagbunga",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713002",
      "brgy_name": "Dahilig",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713004",
      "brgy_name": "Loob",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713005",
      "brgy_name": "Malbong",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713006",
      "brgy_name": "Namuat",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713007",
      "brgy_name": "Sampaloc",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713008",
      "brgy_name": "District I (Pob.)",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051713009",
      "brgy_name": "District II (Pob.)",
      "city_code": "051713",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714001",
      "brgy_name": "Ason (Anson)",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714002",
      "brgy_name": "Bahi",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714003",
      "brgy_name": "Binagasbasan",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714004",
      "brgy_name": "Burabod",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714005",
      "brgy_name": "Cagamutan",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714006",
      "brgy_name": "Cagnipa",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714007",
      "brgy_name": "Canlong",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714008",
      "brgy_name": "Dangla",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714009",
      "brgy_name": "Del Pilar",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714010",
      "brgy_name": "Denrica",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714011",
      "brgy_name": "Harrison",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714012",
      "brgy_name": "Mansangat",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714013",
      "brgy_name": "Pambuhan",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714014",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714015",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714016",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714017",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714018",
      "brgy_name": "Sagrada",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714019",
      "brgy_name": "Salvacion",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714020",
      "brgy_name": "San Vicente",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714021",
      "brgy_name": "Sumaoy",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714022",
      "brgy_name": "Tamiawon",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051714023",
      "brgy_name": "Toytoy",
      "city_code": "051714",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715001",
      "brgy_name": "Abucayan",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715002",
      "brgy_name": "Bagumbayan Grande (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715003",
      "brgy_name": "Bagumbayan Pequeño (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715004",
      "brgy_name": "Balaynan",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715005",
      "brgy_name": "Belen (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715006",
      "brgy_name": "Buyo",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715007",
      "brgy_name": "Cagaycay",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715008",
      "brgy_name": "Catagbacan",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715009",
      "brgy_name": "Digdigon",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715010",
      "brgy_name": "Gimaga",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715011",
      "brgy_name": "Halawig-Gogon",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715013",
      "brgy_name": "Hiwacloy",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715014",
      "brgy_name": "La Purisima (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715015",
      "brgy_name": "Lamon",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715016",
      "brgy_name": "Matacla",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715017",
      "brgy_name": "Maymatan",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715018",
      "brgy_name": "Maysalay",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715019",
      "brgy_name": "Napawon (Napunuon)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715020",
      "brgy_name": "Panday (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715021",
      "brgy_name": "Payatan",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715022",
      "brgy_name": "Pinaglabanan",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715023",
      "brgy_name": "Salog",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715024",
      "brgy_name": "San Benito (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715025",
      "brgy_name": "San Isidro West",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715026",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715027",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715028",
      "brgy_name": "San Juan Bautista (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715029",
      "brgy_name": "San Juan Evangelista (Pob.)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715030",
      "brgy_name": "San Pedro (Aroro)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715031",
      "brgy_name": "Scout Fuentebella",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715032",
      "brgy_name": "Tabgon",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715033",
      "brgy_name": "Tagongtong",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715034",
      "brgy_name": "Tamban (Mabini)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051715035",
      "brgy_name": "Taytay (Halgon East)",
      "city_code": "051715",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716001",
      "brgy_name": "Antipolo",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716002",
      "brgy_name": "Cristo Rey",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716003",
      "brgy_name": "Del Rosario (Banao)",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716004",
      "brgy_name": "Francia",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716005",
      "brgy_name": "La Anunciacion",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716006",
      "brgy_name": "La Medalla",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716007",
      "brgy_name": "La Purisima",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716008",
      "brgy_name": "La Trinidad",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716009",
      "brgy_name": "Niño Jesus",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716010",
      "brgy_name": "Perpetual Help",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716011",
      "brgy_name": "Sagrada",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716012",
      "brgy_name": "Salvacion",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716013",
      "brgy_name": "San Agustin",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716014",
      "brgy_name": "San Andres",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716015",
      "brgy_name": "San Antonio",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716016",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716017",
      "brgy_name": "San Isidro",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716018",
      "brgy_name": "San Jose",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716019",
      "brgy_name": "San Juan",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716020",
      "brgy_name": "San Miguel",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716021",
      "brgy_name": "San Nicolas",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716022",
      "brgy_name": "San Pedro",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716023",
      "brgy_name": "San Rafael",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716024",
      "brgy_name": "San Ramon",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716025",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716026",
      "brgy_name": "San Vicente Norte",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716027",
      "brgy_name": "San Vicente Sur",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716028",
      "brgy_name": "Santa Cruz Norte",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716029",
      "brgy_name": "Santa Cruz Sur",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716030",
      "brgy_name": "Santa Elena",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716031",
      "brgy_name": "Santa Isabel",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716032",
      "brgy_name": "Santa Maria",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716033",
      "brgy_name": "Santa Teresita",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716034",
      "brgy_name": "Santiago",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716035",
      "brgy_name": "Santo Domingo",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051716036",
      "brgy_name": "Santo Niño",
      "city_code": "051716",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717001",
      "brgy_name": "Agosais",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717002",
      "brgy_name": "Agpo-Camagong-Tabog",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717003",
      "brgy_name": "Amoguis",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717004",
      "brgy_name": "Bocogan",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717005",
      "brgy_name": "Balaton",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717006",
      "brgy_name": "Binanuahan",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717007",
      "brgy_name": "Burabod",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717008",
      "brgy_name": "Cabotonan",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717009",
      "brgy_name": "Dahat",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717010",
      "brgy_name": "Del Carmen",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717011",
      "brgy_name": "Ginorangan (Genorangan)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717012",
      "brgy_name": "Gimagtocon",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717013",
      "brgy_name": "Gubat",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717014",
      "brgy_name": "Guibahoy",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717015",
      "brgy_name": "Himanag",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717016",
      "brgy_name": "Kinahologan",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717017",
      "brgy_name": "Loho",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717018",
      "brgy_name": "Manamoc",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717019",
      "brgy_name": "Mangogon",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717020",
      "brgy_name": "Mapid",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717021",
      "brgy_name": "Olas",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717022",
      "brgy_name": "Omalo",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717023",
      "brgy_name": "Panagan",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717024",
      "brgy_name": "Panicuan",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717025",
      "brgy_name": "Pinamihagan",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717026",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717027",
      "brgy_name": "San Isidro",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717028",
      "brgy_name": "San Isidro Sur (Pob.)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717029",
      "brgy_name": "San Isidro Norte (Pob.)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717030",
      "brgy_name": "San Rafael",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717031",
      "brgy_name": "San Ramon",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717032",
      "brgy_name": "San Roque",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717033",
      "brgy_name": "San Sebastian",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717034",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717035",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717036",
      "brgy_name": "Santa Maria (Pob.)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717037",
      "brgy_name": "Saripongpong (Pob.)",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051717038",
      "brgy_name": "Sipaco",
      "city_code": "051717",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718001",
      "brgy_name": "Aslong",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718002",
      "brgy_name": "Awayan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718003",
      "brgy_name": "Bagacay",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718004",
      "brgy_name": "Bagadion",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718005",
      "brgy_name": "Bagamelon",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718006",
      "brgy_name": "Bagumbayan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718007",
      "brgy_name": "Bahao",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718008",
      "brgy_name": "Bahay",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718009",
      "brgy_name": "Beguito Nuevo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718010",
      "brgy_name": "Beguito Viejo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718012",
      "brgy_name": "Begajo Norte",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718013",
      "brgy_name": "Begajo Sur",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718014",
      "brgy_name": "Bikal",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718015",
      "brgy_name": "Busak",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718016",
      "brgy_name": "Caima",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718017",
      "brgy_name": "Calabnigan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718018",
      "brgy_name": "Camambugan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718019",
      "brgy_name": "Cambalidio",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718020",
      "brgy_name": "Candami",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718021",
      "brgy_name": "Candato",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718022",
      "brgy_name": "Cawayan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718023",
      "brgy_name": "Concepcion",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718024",
      "brgy_name": "Cuyapi",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718025",
      "brgy_name": "Danawan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718026",
      "brgy_name": "Duang Niog",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718027",
      "brgy_name": "Handong",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718028",
      "brgy_name": "Ibid",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718029",
      "brgy_name": "Inalahan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718030",
      "brgy_name": "Labao",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718031",
      "brgy_name": "Libod I",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718032",
      "brgy_name": "Libod II",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718033",
      "brgy_name": "Loba-loba",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718035",
      "brgy_name": "Mabini",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718036",
      "brgy_name": "Malansad Nuevo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718037",
      "brgy_name": "Malansad Viejo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718038",
      "brgy_name": "Malbogon",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718039",
      "brgy_name": "Malinao",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718040",
      "brgy_name": "Mambalite",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718041",
      "brgy_name": "Mambayawas",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718042",
      "brgy_name": "Mambulo Nuevo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718043",
      "brgy_name": "Mambulo Viejo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718044",
      "brgy_name": "Mancawayan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718045",
      "brgy_name": "Mandacanan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718046",
      "brgy_name": "Mantalisay",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718047",
      "brgy_name": "Padlos",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718048",
      "brgy_name": "Pag-Oring Nuevo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718049",
      "brgy_name": "Pag-Oring Viejo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718050",
      "brgy_name": "Palangon",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718051",
      "brgy_name": "Palong",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718052",
      "brgy_name": "Patag",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718053",
      "brgy_name": "Planza",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718054",
      "brgy_name": "Poblacion",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718055",
      "brgy_name": "Potot",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718056",
      "brgy_name": "Puro-Batia",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718057",
      "brgy_name": "Rongos",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718058",
      "brgy_name": "Salvacion",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718060",
      "brgy_name": "San Isidro",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718061",
      "brgy_name": "San Juan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718062",
      "brgy_name": "San Pablo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718063",
      "brgy_name": "San Vicente",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718064",
      "brgy_name": "Sibujo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718065",
      "brgy_name": "Sigamot",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718066",
      "brgy_name": "Station-Church Site",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718067",
      "brgy_name": "Taban-Fundado",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718068",
      "brgy_name": "Tampuhan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718069",
      "brgy_name": "Tanag",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718070",
      "brgy_name": "Tarum",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718071",
      "brgy_name": "Tinalmud Nuevo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718072",
      "brgy_name": "Tinalmud Viejo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718073",
      "brgy_name": "Tinangkihan",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718074",
      "brgy_name": "Udoc",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718075",
      "brgy_name": "Umalo",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718076",
      "brgy_name": "Uson",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718077",
      "brgy_name": "Villasocorro",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051718078",
      "brgy_name": "Villadima (Santa Cruz)",
      "city_code": "051718",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719001",
      "brgy_name": "Alleomar",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719003",
      "brgy_name": "Bagangan Sr.",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719004",
      "brgy_name": "Bagong Sikat",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719005",
      "brgy_name": "Bel-Cruz",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719006",
      "brgy_name": "Bangon",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719007",
      "brgy_name": "Barrera Jr.",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719008",
      "brgy_name": "Barrera Sr.",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719009",
      "brgy_name": "Belwang",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719010",
      "brgy_name": "Buenasuerte",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719011",
      "brgy_name": "Bulawan Jr.",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719012",
      "brgy_name": "Bulawan Sr.",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719013",
      "brgy_name": "Cabutagan",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719014",
      "brgy_name": "Kaibigan",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719015",
      "brgy_name": "Casay",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719016",
      "brgy_name": "Colacling (Del Rosario)",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719017",
      "brgy_name": "Cristo Rey",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719018",
      "brgy_name": "Del Carmen",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719019",
      "brgy_name": "Haguimit",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719020",
      "brgy_name": "Haluban (Pigbasagan)",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719021",
      "brgy_name": "La Purisima",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719022",
      "brgy_name": "Lourdes",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719024",
      "brgy_name": "Mangcawayan",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719025",
      "brgy_name": "Napolidan",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719027",
      "brgy_name": "Poblacion",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719028",
      "brgy_name": "Polantuna",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719029",
      "brgy_name": "Sagrada",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719030",
      "brgy_name": "Salvacion",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719031",
      "brgy_name": "San Isidro",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719032",
      "brgy_name": "San Jose",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719033",
      "brgy_name": "San Pedro",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719035",
      "brgy_name": "San Rafael Norte",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719036",
      "brgy_name": "San Rafael Sur",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719037",
      "brgy_name": "San Ramon",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719038",
      "brgy_name": "San Vicente",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719039",
      "brgy_name": "Sooc",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719040",
      "brgy_name": "Tanawan",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719041",
      "brgy_name": "Tible",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051719042",
      "brgy_name": "Tapi (Lupi Nuevo)",
      "city_code": "051719",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720001",
      "brgy_name": "Barobaybay",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720002",
      "brgy_name": "Bell (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720003",
      "brgy_name": "Carangcang",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720004",
      "brgy_name": "Carigsa",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720005",
      "brgy_name": "Casuray",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720006",
      "brgy_name": "Monserrat (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720007",
      "brgy_name": "Ponong",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720008",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720009",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720010",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720011",
      "brgy_name": "San Miguel",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720012",
      "brgy_name": "San Pantaleon (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720013",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720014",
      "brgy_name": "Santa Rosa",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051720015",
      "brgy_name": "Santo Tomas (Pob.)",
      "city_code": "051720",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721001",
      "brgy_name": "Alimbuyog",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721002",
      "brgy_name": "Amparado (Pob.)",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721003",
      "brgy_name": "Balagbag",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721004",
      "brgy_name": "Borongborongan",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721005",
      "brgy_name": "Cabugao",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721006",
      "brgy_name": "Capucnasan",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721007",
      "brgy_name": "Dalipay",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721008",
      "brgy_name": "Del Rosario (Pob.)",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721009",
      "brgy_name": "Flordeliz",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721010",
      "brgy_name": "Lipot",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721011",
      "brgy_name": "Mayaopayawan",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721012",
      "brgy_name": "Maycatmon",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721013",
      "brgy_name": "Maydaso",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721014",
      "brgy_name": "San Antonio",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721015",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721016",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721017",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721018",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721019",
      "brgy_name": "Santo Domingo (Pob.)",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051721020",
      "brgy_name": "Tarusanan",
      "city_code": "051721",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722001",
      "brgy_name": "Antipolo",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722002",
      "brgy_name": "Bagolatao",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722003",
      "brgy_name": "Bagongbong",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722004",
      "brgy_name": "Baliuag Nuevo",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722005",
      "brgy_name": "Baliuag Viejo",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722006",
      "brgy_name": "Catanusan",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722007",
      "brgy_name": "Del Carmen-Del Rosario (Pob.)",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722008",
      "brgy_name": "Del Socorro",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722009",
      "brgy_name": "Hamoraon",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722010",
      "brgy_name": "Hobo",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722011",
      "brgy_name": "Irayang Solong",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722012",
      "brgy_name": "Magadap",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722013",
      "brgy_name": "Malitbog",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722014",
      "brgy_name": "Manapao",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722015",
      "brgy_name": "Mataoroc",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722016",
      "brgy_name": "Sagrada (Sagrada Familia)",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722017",
      "brgy_name": "Salingogon",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722018",
      "brgy_name": "San Antonio",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722019",
      "brgy_name": "San Felipe-Santiago (Pob.)",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722020",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722021",
      "brgy_name": "San Jose",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722022",
      "brgy_name": "San Juan-San Lorenzo (Pob.)",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722023",
      "brgy_name": "Taban",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722024",
      "brgy_name": "Tariric",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051722025",
      "brgy_name": "Timbang",
      "city_code": "051722",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723001",
      "brgy_name": "Angustia (Angustia Inapatan)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723002",
      "brgy_name": "Antipolo Old",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723003",
      "brgy_name": "Antipolo Young",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723004",
      "brgy_name": "Aro-aldao",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723005",
      "brgy_name": "Bustrac",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723006",
      "brgy_name": "Inapatan (Del Rosario Inapatan)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723007",
      "brgy_name": "Dolorosa (Dolorosa Inapatan)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723009",
      "brgy_name": "Duran (Jesus Duran)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723010",
      "brgy_name": "La Purisima (La Purisima Agupit)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723011",
      "brgy_name": "Lourdes Old",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723012",
      "brgy_name": "Lourdes Young",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723015",
      "brgy_name": "La Opinion",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723016",
      "brgy_name": "Paloyon Proper (Sagrada Paloyon)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723018",
      "brgy_name": "Salvacion Que Gatos",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723019",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723020",
      "brgy_name": "San Antonio Ogbon",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723021",
      "brgy_name": "San Esteban (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723022",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723023",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723024",
      "brgy_name": "San Isidro Inapatan",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723026",
      "brgy_name": "Malawag (San Jose Malawag)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723027",
      "brgy_name": "San Jose (San Jose Pangaraon)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723028",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723029",
      "brgy_name": "San Luis (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723030",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723031",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723033",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723034",
      "brgy_name": "San Roque Madawon",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723035",
      "brgy_name": "San Roque Sagumay",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723036",
      "brgy_name": "San Vicente Gorong-Gorong",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723037",
      "brgy_name": "San Vicente Ogbon",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723038",
      "brgy_name": "Santa Barbara (Maliban)",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723039",
      "brgy_name": "Santa Cruz",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723040",
      "brgy_name": "Santa Elena Baras",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723041",
      "brgy_name": "Santa Lucia Baras",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723042",
      "brgy_name": "Santiago Old",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723043",
      "brgy_name": "Santiago Young",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723044",
      "brgy_name": "Santo Domingo",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723045",
      "brgy_name": "Tandaay",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723046",
      "brgy_name": "Topas Proper",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723047",
      "brgy_name": "Topas Sogod",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051723048",
      "brgy_name": "Paloyon Oriental",
      "city_code": "051723",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724001",
      "brgy_name": "Abella",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724002",
      "brgy_name": "Bagumbayan Norte",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724003",
      "brgy_name": "Bagumbayan Sur",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724004",
      "brgy_name": "Balatas",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724006",
      "brgy_name": "Calauag",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724007",
      "brgy_name": "Cararayan",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724008",
      "brgy_name": "Carolina",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724009",
      "brgy_name": "Concepcion Grande",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724010",
      "brgy_name": "Concepcion Pequeña",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724011",
      "brgy_name": "Dayangdang",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724012",
      "brgy_name": "Del Rosario",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724013",
      "brgy_name": "Dinaga",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724014",
      "brgy_name": "Igualdad Interior",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724017",
      "brgy_name": "Lerma",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724018",
      "brgy_name": "Liboton",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724019",
      "brgy_name": "Mabolo",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724020",
      "brgy_name": "Pacol",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724023",
      "brgy_name": "Panicuason",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724024",
      "brgy_name": "Peñafrancia",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724025",
      "brgy_name": "Sabang",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724026",
      "brgy_name": "San Felipe",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724027",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724028",
      "brgy_name": "San Isidro",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724029",
      "brgy_name": "Santa Cruz",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724030",
      "brgy_name": "Tabuco",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724031",
      "brgy_name": "Tinago",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051724032",
      "brgy_name": "Triangulo",
      "city_code": "051724",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725001",
      "brgy_name": "Ayugan",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725002",
      "brgy_name": "Cabariwan",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725003",
      "brgy_name": "Cagmanaba",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725004",
      "brgy_name": "Del Rosario",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725005",
      "brgy_name": "Gatbo",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725006",
      "brgy_name": "Guinaban",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725007",
      "brgy_name": "Hanawan",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725008",
      "brgy_name": "Hibago",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725009",
      "brgy_name": "La Purisima Nuevo",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725010",
      "brgy_name": "May-Ogob",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725011",
      "brgy_name": "New Moriones",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725012",
      "brgy_name": "Old Moriones",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725013",
      "brgy_name": "Pinit",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725014",
      "brgy_name": "Poblacion Central",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725015",
      "brgy_name": "Poblacion East",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725016",
      "brgy_name": "Poblacion West",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725017",
      "brgy_name": "Salvacion",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725018",
      "brgy_name": "San Antonio",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725019",
      "brgy_name": "San Francisco",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725020",
      "brgy_name": "San Jose Oras",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725022",
      "brgy_name": "San Roque Commonal",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725023",
      "brgy_name": "San Vicente",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725024",
      "brgy_name": "Santa Cruz",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725025",
      "brgy_name": "Santo Niño",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051725026",
      "brgy_name": "Villaflorida",
      "city_code": "051725",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726001",
      "brgy_name": "Batang",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726002",
      "brgy_name": "Burabod",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726003",
      "brgy_name": "Cagbibi",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726004",
      "brgy_name": "Cagbunga",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726005",
      "brgy_name": "Calawat",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726006",
      "brgy_name": "Del Rosario",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726007",
      "brgy_name": "Patong",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726009",
      "brgy_name": "Poblacion",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726010",
      "brgy_name": "Salvacion",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726011",
      "brgy_name": "San Gabriel",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726012",
      "brgy_name": "San Isidro",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726013",
      "brgy_name": "San Rafael",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726014",
      "brgy_name": "San Ramon",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726015",
      "brgy_name": "San Vicente",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726016",
      "brgy_name": "Veneracion (Taguilid)",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726017",
      "brgy_name": "Tambo",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051726018",
      "brgy_name": "Tampadong",
      "city_code": "051726",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727001",
      "brgy_name": "Antipolo",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727002",
      "brgy_name": "Bagong Silang",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727003",
      "brgy_name": "Bahay",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727004",
      "brgy_name": "Balogo",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727005",
      "brgy_name": "Caranan",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727006",
      "brgy_name": "Cuco",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727007",
      "brgy_name": "Dalupaon",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727008",
      "brgy_name": "Macad (Hebrio Lourdes)",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727009",
      "brgy_name": "Hubo",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727010",
      "brgy_name": "Itulan",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727011",
      "brgy_name": "Odicon",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727012",
      "brgy_name": "Quitang",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727013",
      "brgy_name": "Salvacion",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727014",
      "brgy_name": "San Antonio",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727015",
      "brgy_name": "San Cirilo (Pob.)",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727016",
      "brgy_name": "Santa Rosa Del Norte (Pob.)",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727017",
      "brgy_name": "Santa Rosa Del Sur (Pob.)",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727019",
      "brgy_name": "Tilnac",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051727020",
      "brgy_name": "Tinalmud",
      "city_code": "051727",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728001",
      "brgy_name": "Anayan",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728002",
      "brgy_name": "Bagong Sirang",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728003",
      "brgy_name": "Binanwaanan",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728004",
      "brgy_name": "Binobong",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728005",
      "brgy_name": "Cadlan",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728006",
      "brgy_name": "Caroyroyan",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728007",
      "brgy_name": "Curry",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728008",
      "brgy_name": "Del Rosario",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728009",
      "brgy_name": "Himaao",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728010",
      "brgy_name": "La Purisima",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728011",
      "brgy_name": "New San Roque",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728012",
      "brgy_name": "Old San Roque (Pob.)",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728013",
      "brgy_name": "Palestina",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728014",
      "brgy_name": "Pawili",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728015",
      "brgy_name": "Sagurong",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728016",
      "brgy_name": "Sagrada",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728017",
      "brgy_name": "San Agustin",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728018",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728019",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728020",
      "brgy_name": "San Jose",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728021",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728022",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728023",
      "brgy_name": "Santiago (Pob.)",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728024",
      "brgy_name": "Santo Niño",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728025",
      "brgy_name": "Tagbong",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051728026",
      "brgy_name": "Tinangis",
      "city_code": "051728",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729001",
      "brgy_name": "Ayugao",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729002",
      "brgy_name": "Bagong Sirang",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729003",
      "brgy_name": "Baliguian",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729004",
      "brgy_name": "Bantugan",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729005",
      "brgy_name": "Bicalen",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729006",
      "brgy_name": "Bitaogan",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729007",
      "brgy_name": "Buenavista",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729008",
      "brgy_name": "Bulalacao",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729009",
      "brgy_name": "Cagnipa",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729010",
      "brgy_name": "Lagha",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729011",
      "brgy_name": "Lidong",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729012",
      "brgy_name": "Liwacsa",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729013",
      "brgy_name": "Maangas",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729014",
      "brgy_name": "Pagsangahan",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729016",
      "brgy_name": "Patrocinio",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729017",
      "brgy_name": "Pili",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729018",
      "brgy_name": "Santa Maria (Pob.)",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051729019",
      "brgy_name": "Tanawan",
      "city_code": "051729",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730001",
      "brgy_name": "Agao-ao",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730002",
      "brgy_name": "Agrupacion",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730003",
      "brgy_name": "Amomokpok",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730004",
      "brgy_name": "Apad",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730005",
      "brgy_name": "Apale",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730006",
      "brgy_name": "Banga Caves",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730007",
      "brgy_name": "Baya",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730008",
      "brgy_name": "Binahan Proper",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730009",
      "brgy_name": "Binahan Upper",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730010",
      "brgy_name": "Buenasuerte",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730011",
      "brgy_name": "Cabadisan",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730012",
      "brgy_name": "Cabinitan",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730013",
      "brgy_name": "Cabugao",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730014",
      "brgy_name": "Caditaan",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730015",
      "brgy_name": "Cale",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730016",
      "brgy_name": "Godofredo Reyes Sr. (Catabangan Crossing)",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730017",
      "brgy_name": "Catabangan Proper",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730018",
      "brgy_name": "Inandawa",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730019",
      "brgy_name": "Laguio",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730020",
      "brgy_name": "Lanipga-Cawayan",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730021",
      "brgy_name": "Liboro",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730022",
      "brgy_name": "Lohong",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730023",
      "brgy_name": "Lower Omon",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730024",
      "brgy_name": "Lower Santa Cruz",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730026",
      "brgy_name": "Panaytayan",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730027",
      "brgy_name": "Panaytayan Nuevo",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730028",
      "brgy_name": "Patalunan",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730029",
      "brgy_name": "Poblacion Ilaod",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730030",
      "brgy_name": "Poblacion Iraya",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730031",
      "brgy_name": "Port Junction Norte",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730032",
      "brgy_name": "Port Junction Sur",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730034",
      "brgy_name": "Salvacion",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730035",
      "brgy_name": "Samay",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730036",
      "brgy_name": "San Rafael",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730037",
      "brgy_name": "F. Simeon (Pugod)",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730038",
      "brgy_name": "Tagbac",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730039",
      "brgy_name": "Upper Omon",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051730040",
      "brgy_name": "Upper Santa Cruz",
      "city_code": "051730",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731001",
      "brgy_name": "Aniog",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731002",
      "brgy_name": "Atulayan",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731003",
      "brgy_name": "Bongalon",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731004",
      "brgy_name": "Buracan",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731005",
      "brgy_name": "Catalotoan",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731006",
      "brgy_name": "Del Carmen (Pob.)",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731007",
      "brgy_name": "Kilantaao",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731008",
      "brgy_name": "Kilomaon",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731010",
      "brgy_name": "Mabca",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731011",
      "brgy_name": "Minadongjol",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731012",
      "brgy_name": "Nato",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731014",
      "brgy_name": "Patitinan",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731015",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731017",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731018",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731019",
      "brgy_name": "Santo Niño",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731020",
      "brgy_name": "Sibaguan",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731022",
      "brgy_name": "Tinorongan",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051731023",
      "brgy_name": "Turague",
      "city_code": "051731",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732001",
      "brgy_name": "Alianza",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732002",
      "brgy_name": "Beberon",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732003",
      "brgy_name": "Bical",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732004",
      "brgy_name": "Bocal",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732005",
      "brgy_name": "Bonifacio (Pob.)",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732006",
      "brgy_name": "Buenavista (Pob.)",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732007",
      "brgy_name": "Calascagas",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732008",
      "brgy_name": "Cotmo",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732009",
      "brgy_name": "Daculang Tubig",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732010",
      "brgy_name": "Del Pilar (Pob.)",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732011",
      "brgy_name": "Gñaran",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732012",
      "brgy_name": "Grijalvo",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732013",
      "brgy_name": "Lupi",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732014",
      "brgy_name": "Maragñi",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732015",
      "brgy_name": "Pamukid",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732016",
      "brgy_name": "Pinamasagan",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732017",
      "brgy_name": "Pipian",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732018",
      "brgy_name": "Planza",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732019",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732020",
      "brgy_name": "San Joaquin",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732021",
      "brgy_name": "Santa Cruz",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051732022",
      "brgy_name": "Tagpocol",
      "city_code": "051732",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733001",
      "brgy_name": "Adiangao",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733002",
      "brgy_name": "Bagacay",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733003",
      "brgy_name": "Bahay",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733004",
      "brgy_name": "Boclod",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733005",
      "brgy_name": "Calalahan",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733006",
      "brgy_name": "Calawit",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733007",
      "brgy_name": "Camagong",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733008",
      "brgy_name": "Catalotoan",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733009",
      "brgy_name": "Danlog",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733010",
      "brgy_name": "Del Carmen (Pob.)",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733011",
      "brgy_name": "Dolo",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733012",
      "brgy_name": "Kinalansan",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733013",
      "brgy_name": "Mampirao",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733014",
      "brgy_name": "Manzana",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733015",
      "brgy_name": "Minoro",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733016",
      "brgy_name": "Palale",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733017",
      "brgy_name": "Ponglon",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733018",
      "brgy_name": "Pugay",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733019",
      "brgy_name": "Sabang",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733020",
      "brgy_name": "Salogon",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733021",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733023",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733024",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733025",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733026",
      "brgy_name": "Soledad (Pob.)",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733027",
      "brgy_name": "Tagas",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733028",
      "brgy_name": "Tambangan",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733029",
      "brgy_name": "Telegrafo",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051733030",
      "brgy_name": "Tominawog",
      "city_code": "051733",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734001",
      "brgy_name": "Aldezar",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734002",
      "brgy_name": "Alteza",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734003",
      "brgy_name": "Anib",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734004",
      "brgy_name": "Awayan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734005",
      "brgy_name": "Azucena",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734006",
      "brgy_name": "Bagong Sirang",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734007",
      "brgy_name": "Binahian",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734008",
      "brgy_name": "Bolo Sur",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734009",
      "brgy_name": "Bolo Norte",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734010",
      "brgy_name": "Bulan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734011",
      "brgy_name": "Bulawan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734012",
      "brgy_name": "Cabuyao",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734013",
      "brgy_name": "Caima",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734014",
      "brgy_name": "Calagbangan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734015",
      "brgy_name": "Calampinay",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734016",
      "brgy_name": "Carayrayan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734017",
      "brgy_name": "Cotmo",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734018",
      "brgy_name": "Gabi",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734019",
      "brgy_name": "Gaongan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734020",
      "brgy_name": "Impig",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734021",
      "brgy_name": "Lipilip",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734022",
      "brgy_name": "Lubigan Jr.",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734023",
      "brgy_name": "Lubigan Sr.",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734024",
      "brgy_name": "Malaguico",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734025",
      "brgy_name": "Malubago",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734026",
      "brgy_name": "Manangle",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734027",
      "brgy_name": "Mangga",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734028",
      "brgy_name": "Mangapo",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734029",
      "brgy_name": "Manlubang",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734030",
      "brgy_name": "Mantila",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734031",
      "brgy_name": "North Centro (Pob.)",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734032",
      "brgy_name": "North Villazar",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734034",
      "brgy_name": "Sagrada Familia",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734035",
      "brgy_name": "Salanda",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734036",
      "brgy_name": "Salvacion",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734037",
      "brgy_name": "San Isidro",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734038",
      "brgy_name": "San Vicente",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734039",
      "brgy_name": "Serranzana",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734040",
      "brgy_name": "South Centro (Pob.)",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734041",
      "brgy_name": "South Villazar",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734042",
      "brgy_name": "Taisan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734043",
      "brgy_name": "Tara",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734044",
      "brgy_name": "Tible",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734045",
      "brgy_name": "Tula-tula",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734046",
      "brgy_name": "Vigaan",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051734048",
      "brgy_name": "Yabo",
      "city_code": "051734",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735001",
      "brgy_name": "Bagong Sirang",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735002",
      "brgy_name": "Bahao",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735003",
      "brgy_name": "Boboan",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735004",
      "brgy_name": "Butawanan",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735005",
      "brgy_name": "Cabugao",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735006",
      "brgy_name": "Fundado",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735008",
      "brgy_name": "Homestead",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735009",
      "brgy_name": "La Purisima",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735010",
      "brgy_name": "Mabuhay",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735011",
      "brgy_name": "Malaconini",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735012",
      "brgy_name": "Nalayahan",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735013",
      "brgy_name": "Matandang Siruma",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735014",
      "brgy_name": "Pinitan",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735015",
      "brgy_name": "Poblacion",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735016",
      "brgy_name": "Pamintan-Bantilan",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735017",
      "brgy_name": "Salvacion",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735018",
      "brgy_name": "San Andres",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735019",
      "brgy_name": "San Ramon (Daldagon)",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735020",
      "brgy_name": "Sulpa",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735021",
      "brgy_name": "Tandoc",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735022",
      "brgy_name": "Tongo-Bantigue",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051735023",
      "brgy_name": "Vito",
      "city_code": "051735",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736001",
      "brgy_name": "Abo",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736002",
      "brgy_name": "Cabalinadan",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736003",
      "brgy_name": "Caraycayon",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736004",
      "brgy_name": "Casuna",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736005",
      "brgy_name": "Consocep",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736006",
      "brgy_name": "Coyaoyao",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736007",
      "brgy_name": "Gaao",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736008",
      "brgy_name": "Gingaroy",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736009",
      "brgy_name": "Gubat",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736010",
      "brgy_name": "Huyonhuyon",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736011",
      "brgy_name": "Libod",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736012",
      "brgy_name": "Mabalodbalod",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736013",
      "brgy_name": "May-Anao",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736014",
      "brgy_name": "Panagan",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736015",
      "brgy_name": "Poblacion",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736016",
      "brgy_name": "Salvacion",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736018",
      "brgy_name": "San Antonio",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736019",
      "brgy_name": "San Francisco",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736020",
      "brgy_name": "San Miguel",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736021",
      "brgy_name": "San Rafael",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736022",
      "brgy_name": "Talojongon",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736023",
      "brgy_name": "Tinawagan",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051736024",
      "brgy_name": "Vinagre",
      "city_code": "051736",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737001",
      "brgy_name": "Agay-Ayan",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737002",
      "brgy_name": "Antipolo",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737003",
      "brgy_name": "Bagacay",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737004",
      "brgy_name": "Banga",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737005",
      "brgy_name": "Bolaobalite",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737006",
      "brgy_name": "Bani",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737007",
      "brgy_name": "Bataan",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737008",
      "brgy_name": "Binalay",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737009",
      "brgy_name": "Buenavista",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737010",
      "brgy_name": "Buyo",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737011",
      "brgy_name": "Cagliliog",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737012",
      "brgy_name": "Caloco",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737013",
      "brgy_name": "Camagong",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737014",
      "brgy_name": "Canayonan",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737015",
      "brgy_name": "Cawaynan",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737016",
      "brgy_name": "Daligan",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737017",
      "brgy_name": "Filarca (Pob.)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737018",
      "brgy_name": "La Purisima (Pob.)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737019",
      "brgy_name": "Lupi",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737020",
      "brgy_name": "Magsaysay (Camp 4)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737021",
      "brgy_name": "Magtang",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737022",
      "brgy_name": "Mananao",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737023",
      "brgy_name": "La Medalla (Mile 9)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737024",
      "brgy_name": "New Caaluan",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737025",
      "brgy_name": "Olag Grande",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737026",
      "brgy_name": "Olag Pequeño",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737027",
      "brgy_name": "Old Caaluan",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737028",
      "brgy_name": "Pag-Asa",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737029",
      "brgy_name": "Pantat",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737030",
      "brgy_name": "Sagrada (Camp 6)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737031",
      "brgy_name": "Salvacion",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737032",
      "brgy_name": "San Antonio",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737033",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737034",
      "brgy_name": "San Jose (Tiltilan)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737035",
      "brgy_name": "San Pascual (Pob.)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737036",
      "brgy_name": "San Ramon (Camp 7)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737037",
      "brgy_name": "San Roque",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737038",
      "brgy_name": "San Vicente",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737039",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737040",
      "brgy_name": "Sogod",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737041",
      "brgy_name": "Tambang",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737042",
      "brgy_name": "Tierra Nevada",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737043",
      "brgy_name": "Union",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "051737044",
      "brgy_name": "Salvacion Poblacion",
      "city_code": "051737",
      "province_code": "0517",
      "region_code": "05"
    },
    {
      "brgy_code": "052001001",
      "brgy_name": "Antipolo",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001002",
      "brgy_name": "Bacak",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001003",
      "brgy_name": "Bagatabao",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001004",
      "brgy_name": "Bugao",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001005",
      "brgy_name": "Cahan",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001006",
      "brgy_name": "Hinipaan",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001007",
      "brgy_name": "Magsaysay",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001008",
      "brgy_name": "Poblacion",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001010",
      "brgy_name": "Quigaray",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001011",
      "brgy_name": "Quezon (Pancayanan)",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001012",
      "brgy_name": "Sagrada",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001013",
      "brgy_name": "Salvacion (Panuto)",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001014",
      "brgy_name": "San Isidro",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001015",
      "brgy_name": "San Rafael (Mahantod)",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001016",
      "brgy_name": "San Vicente",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001017",
      "brgy_name": "Santa Mesa",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001018",
      "brgy_name": "Santa Teresa",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052001019",
      "brgy_name": "Suchan",
      "city_code": "052001",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002001",
      "brgy_name": "Abihao",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002002",
      "brgy_name": "Agban",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002003",
      "brgy_name": "Bagong Sirang",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002004",
      "brgy_name": "Benticayan",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002005",
      "brgy_name": "Buenavista",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002006",
      "brgy_name": "Caragumihan",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002007",
      "brgy_name": "Batolinao",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002008",
      "brgy_name": "Danao",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002009",
      "brgy_name": "Sagrada",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002010",
      "brgy_name": "Ginitligan",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002011",
      "brgy_name": "Guinsaanan",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002012",
      "brgy_name": "J. M. Alberto",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002013",
      "brgy_name": "Macutal",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002014",
      "brgy_name": "Moning",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002015",
      "brgy_name": "Nagbarorong",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002016",
      "brgy_name": "Osmeña",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002017",
      "brgy_name": "P. Teston",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002018",
      "brgy_name": "Paniquihan",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002019",
      "brgy_name": "Eastern Poblacion",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002020",
      "brgy_name": "Puraran",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002021",
      "brgy_name": "Putsan",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002022",
      "brgy_name": "Quezon",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002023",
      "brgy_name": "Rizal",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002024",
      "brgy_name": "Salvacion",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002025",
      "brgy_name": "San Lorenzo",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002026",
      "brgy_name": "San Miguel",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002027",
      "brgy_name": "Santa Maria",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002028",
      "brgy_name": "Tilod",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052002029",
      "brgy_name": "Western Poblacion",
      "city_code": "052002",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003001",
      "brgy_name": "Aroyao Pequeño",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003002",
      "brgy_name": "Bagumbayan",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003003",
      "brgy_name": "Banawang",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003004",
      "brgy_name": "Batalay",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003005",
      "brgy_name": "Binanwahan",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003006",
      "brgy_name": "Bote",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003007",
      "brgy_name": "Buenavista",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003008",
      "brgy_name": "Cabugao",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003009",
      "brgy_name": "Cagraray",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003010",
      "brgy_name": "Carorian",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003011",
      "brgy_name": "Guinobatan",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003012",
      "brgy_name": "Libjo",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003013",
      "brgy_name": "Marinawa",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003014",
      "brgy_name": "Mintay",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003015",
      "brgy_name": "Oguis",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003016",
      "brgy_name": "Pananaogan",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003017",
      "brgy_name": "Libod Poblacion",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003018",
      "brgy_name": "San Andres",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003019",
      "brgy_name": "San Pedro",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003020",
      "brgy_name": "San Roque",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003021",
      "brgy_name": "Santa Isabel",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003022",
      "brgy_name": "Sibacungan",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003023",
      "brgy_name": "Sipi",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003024",
      "brgy_name": "Talisay",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003025",
      "brgy_name": "Tamburan",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003026",
      "brgy_name": "Tilis",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052003027",
      "brgy_name": "Ilawod (Pob.)",
      "city_code": "052003",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004001",
      "brgy_name": "Baybay (Pob.)",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004002",
      "brgy_name": "Bocon",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004003",
      "brgy_name": "Bothoan (Pob.)",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004004",
      "brgy_name": "Buenavista",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004005",
      "brgy_name": "Bulalacao",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004006",
      "brgy_name": "Camburo",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004007",
      "brgy_name": "Dariao",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004008",
      "brgy_name": "Datag East",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004009",
      "brgy_name": "Datag West",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004010",
      "brgy_name": "Guiamlong",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004011",
      "brgy_name": "Hitoma",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004012",
      "brgy_name": "Icanbato (Pob.)",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004013",
      "brgy_name": "Inalmasinan",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004014",
      "brgy_name": "Iyao",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004015",
      "brgy_name": "Mabini",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004017",
      "brgy_name": "Maui",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004018",
      "brgy_name": "Maysuran",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004019",
      "brgy_name": "Milaviga",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004020",
      "brgy_name": "Panique",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004021",
      "brgy_name": "Sabangan",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004022",
      "brgy_name": "Sabloyon",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004023",
      "brgy_name": "Salvacion",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004024",
      "brgy_name": "Supang",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004025",
      "brgy_name": "Toytoy (Pob.)",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004026",
      "brgy_name": "Tubli",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004027",
      "brgy_name": "Tucao",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052004028",
      "brgy_name": "Obi",
      "city_code": "052004",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005001",
      "brgy_name": "Biong",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005002",
      "brgy_name": "Dororian",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005003",
      "brgy_name": "Poblacion District I",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005004",
      "brgy_name": "Poblacion District II",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005005",
      "brgy_name": "Poblacion District III",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005006",
      "brgy_name": "San Pedro",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005007",
      "brgy_name": "San Vicente",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005008",
      "brgy_name": "Sicmil",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052005009",
      "brgy_name": "Sioron",
      "city_code": "052005",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006001",
      "brgy_name": "Bagawang",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006002",
      "brgy_name": "Balagñonan",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006003",
      "brgy_name": "Baldoc",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006004",
      "brgy_name": "Canlubi",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006005",
      "brgy_name": "Santa Cruz (Catagbacan)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006006",
      "brgy_name": "Catamban",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006007",
      "brgy_name": "Cobo",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006009",
      "brgy_name": "Hiyop",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006010",
      "brgy_name": "Libod (Pob.)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006011",
      "brgy_name": "Lourdes",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006012",
      "brgy_name": "Lumabao",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006013",
      "brgy_name": "Marambong",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006014",
      "brgy_name": "Napo (Pob.)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006015",
      "brgy_name": "Oga",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006016",
      "brgy_name": "Pandan Del Norte (Pob.)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006017",
      "brgy_name": "Pandan Del Sur (Pob.)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006018",
      "brgy_name": "Panuto",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006019",
      "brgy_name": "Salvacion (Tariwara)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006020",
      "brgy_name": "San Andres (Dinungsuran)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006021",
      "brgy_name": "San Isidro (Langob)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006022",
      "brgy_name": "Porot (San Jose)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006023",
      "brgy_name": "San Rafael (Bogtong)",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006024",
      "brgy_name": "San Roque",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006025",
      "brgy_name": "Tabugoc",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006026",
      "brgy_name": "Tokio",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052006027",
      "brgy_name": "Wagdas",
      "city_code": "052006",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007001",
      "brgy_name": "Alinawan",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007002",
      "brgy_name": "Babaguan",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007003",
      "brgy_name": "Bagong Bayan",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007005",
      "brgy_name": "Burabod",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007006",
      "brgy_name": "Cabuyoan",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007007",
      "brgy_name": "Cagdarao",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007008",
      "brgy_name": "Mabini",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007009",
      "brgy_name": "Maculiw",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007010",
      "brgy_name": "Panay",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007011",
      "brgy_name": "Taopon (Pangcayanan)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007013",
      "brgy_name": "Salvacion (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007014",
      "brgy_name": "San Antonio",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007015",
      "brgy_name": "San Joaquin (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007016",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007017",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007018",
      "brgy_name": "San Miguel",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007019",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007020",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007021",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007022",
      "brgy_name": "Santa Ana (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007023",
      "brgy_name": "Santa Maria (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007024",
      "brgy_name": "Santo Santiago (Pob.)",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052007026",
      "brgy_name": "Tibo",
      "city_code": "052007",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008001",
      "brgy_name": "Agojo",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008002",
      "brgy_name": "Alibuag",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008003",
      "brgy_name": "Asgad (Juan M. Alberto)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008004",
      "brgy_name": "Bagong Sirang",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008005",
      "brgy_name": "Barihay",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008006",
      "brgy_name": "Batong Paloway",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008007",
      "brgy_name": "Belmonte (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008008",
      "brgy_name": "Bislig",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008009",
      "brgy_name": "Bon-ot",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008010",
      "brgy_name": "Cabungahan",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008011",
      "brgy_name": "Cabcab",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008012",
      "brgy_name": "Carangag",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008013",
      "brgy_name": "Catagbacan",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008014",
      "brgy_name": "Codon",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008015",
      "brgy_name": "Comagaycay",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008016",
      "brgy_name": "Datag",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008017",
      "brgy_name": "Divino Rostro (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008018",
      "brgy_name": "Esperanza (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008019",
      "brgy_name": "Hilawan",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008020",
      "brgy_name": "Lictin",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008021",
      "brgy_name": "Lubas",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008022",
      "brgy_name": "Manambrag",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008023",
      "brgy_name": "Mayngaway",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008024",
      "brgy_name": "Palawig",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008025",
      "brgy_name": "Puting Baybay",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008026",
      "brgy_name": "Rizal",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008027",
      "brgy_name": "Salvacion (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008028",
      "brgy_name": "San Isidro",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008029",
      "brgy_name": "San Jose",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008030",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008031",
      "brgy_name": "San Vicente",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008032",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008033",
      "brgy_name": "Sapang Palay (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008034",
      "brgy_name": "Tibang",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008035",
      "brgy_name": "Timbaan",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008036",
      "brgy_name": "Tominawog",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008037",
      "brgy_name": "Wagdas (Pob.)",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052008038",
      "brgy_name": "Yocti",
      "city_code": "052008",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009001",
      "brgy_name": "Balatohan",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009002",
      "brgy_name": "Salvacion (Patagan)",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009003",
      "brgy_name": "Boton",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009004",
      "brgy_name": "Buhi",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009005",
      "brgy_name": "Dayawa",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009006",
      "brgy_name": "Atsan (District I)",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009007",
      "brgy_name": "Poblacion District II",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009008",
      "brgy_name": "Poblacion District III",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009009",
      "brgy_name": "J. M. Alberto",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009010",
      "brgy_name": "Katipunan",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009011",
      "brgy_name": "Kilikilihan",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009012",
      "brgy_name": "Mabato",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009013",
      "brgy_name": "Obo",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009014",
      "brgy_name": "Pacogon",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009015",
      "brgy_name": "Pagsangahan",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009016",
      "brgy_name": "Pangilao",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009017",
      "brgy_name": "Paraiso",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009018",
      "brgy_name": "Santa Elena (Patagan)",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009019",
      "brgy_name": "Progreso",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009020",
      "brgy_name": "San Juan (Aroyao)",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009021",
      "brgy_name": "San Marcos",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009022",
      "brgy_name": "Siay",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009023",
      "brgy_name": "Solong",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052009024",
      "brgy_name": "Tobrehon",
      "city_code": "052009",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010001",
      "brgy_name": "Almojuela",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010002",
      "brgy_name": "Ananong",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010003",
      "brgy_name": "Asuncion (Pob.)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010004",
      "brgy_name": "Batohonan",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010005",
      "brgy_name": "Begonia",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010006",
      "brgy_name": "Botinagan",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010007",
      "brgy_name": "Buenavista",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010008",
      "brgy_name": "Burgos",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010009",
      "brgy_name": "Del Pilar",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010010",
      "brgy_name": "Mabini",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010011",
      "brgy_name": "Magsaysay",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010012",
      "brgy_name": "Ogbong",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010013",
      "brgy_name": "Osmeña",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010014",
      "brgy_name": "Pedro Vera (Summit)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010015",
      "brgy_name": "Peñafrancia (Pob.)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010016",
      "brgy_name": "Quezon",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010017",
      "brgy_name": "Quirino (Abugan)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010018",
      "brgy_name": "Rizal",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010019",
      "brgy_name": "Roxas",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010020",
      "brgy_name": "Sagrada",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010021",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010022",
      "brgy_name": "San Jose Poblacion",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010023",
      "brgy_name": "San Jose Oco",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010024",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010025",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010026",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010027",
      "brgy_name": "Santa Rosa",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010028",
      "brgy_name": "Soboc",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010029",
      "brgy_name": "Tambongon",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010030",
      "brgy_name": "Tinago",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052010031",
      "brgy_name": "Villa Aurora",
      "city_code": "052010",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011001",
      "brgy_name": "Antipolo Del Norte",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011002",
      "brgy_name": "Antipolo Del Sur",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011003",
      "brgy_name": "Balite",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011004",
      "brgy_name": "Batag",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011005",
      "brgy_name": "Bigaa",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011006",
      "brgy_name": "Buenavista",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011007",
      "brgy_name": "Buyo",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011008",
      "brgy_name": "Cabihian",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011009",
      "brgy_name": "Calabnigan",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011010",
      "brgy_name": "Calampong",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011011",
      "brgy_name": "Calatagan Proper",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011012",
      "brgy_name": "Calatagan Tibang",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011013",
      "brgy_name": "Capilihan",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011014",
      "brgy_name": "Casoocan",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011015",
      "brgy_name": "Cavinitan",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011016",
      "brgy_name": "Gogon Sirangan",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011017",
      "brgy_name": "Concepcion (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011018",
      "brgy_name": "Constantino (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011019",
      "brgy_name": "Danicop",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011020",
      "brgy_name": "Dugui San Vicente",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011021",
      "brgy_name": "Dugui San Isidro",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011023",
      "brgy_name": "Dugui Too",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011024",
      "brgy_name": "F. Tacorda Village",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011025",
      "brgy_name": "Francia (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011026",
      "brgy_name": "Gogon Centro",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011027",
      "brgy_name": "Hawan Grande",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011028",
      "brgy_name": "Hawan Ilaya",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011029",
      "brgy_name": "Hicming",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011030",
      "brgy_name": "Igang",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011031",
      "brgy_name": "Juan M. Alberto (Poniton)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011032",
      "brgy_name": "Lanao (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011033",
      "brgy_name": "Magnesia Del Norte",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011034",
      "brgy_name": "Magnesia Del Sur",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011035",
      "brgy_name": "Marcelo Alberto (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011036",
      "brgy_name": "Marilima",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011037",
      "brgy_name": "Pajo Baguio",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011038",
      "brgy_name": "Pajo San Isidro",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011039",
      "brgy_name": "Palnab Del Norte",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011040",
      "brgy_name": "Palnab Del Sur",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011041",
      "brgy_name": "Palta Big",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011042",
      "brgy_name": "Palta Salvacion",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011043",
      "brgy_name": "Palta Small",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011044",
      "brgy_name": "Rawis (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011045",
      "brgy_name": "Salvacion",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011046",
      "brgy_name": "San Isidro Village",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011047",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011048",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011049",
      "brgy_name": "San Pablo (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011050",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011051",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011052",
      "brgy_name": "San Vicente",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011053",
      "brgy_name": "Ibong Sapa (San Vicente Sur)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011054",
      "brgy_name": "Santa Cruz",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011055",
      "brgy_name": "Santa Elena (Pob.)",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011056",
      "brgy_name": "Santo Cristo",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011057",
      "brgy_name": "Santo Domingo",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011058",
      "brgy_name": "Santo Niño",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011059",
      "brgy_name": "Simamla",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011061",
      "brgy_name": "Sogod-Simamla",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011062",
      "brgy_name": "Talisoy",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011063",
      "brgy_name": "Sogod-Tibgao",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011064",
      "brgy_name": "Tubaon",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "052011065",
      "brgy_name": "Valencia",
      "city_code": "052011",
      "province_code": "0520",
      "region_code": "05"
    },
    {
      "brgy_code": "054101001",
      "brgy_name": "Ambolong",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101002",
      "brgy_name": "Amoroy",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101003",
      "brgy_name": "Amutag",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101004",
      "brgy_name": "Bagauma",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101005",
      "brgy_name": "Balawing",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101006",
      "brgy_name": "Balete",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101007",
      "brgy_name": "Bangon",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101008",
      "brgy_name": "Cabangcalan",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101009",
      "brgy_name": "Cabas-An",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101010",
      "brgy_name": "Calanay",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101011",
      "brgy_name": "Capsay",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101012",
      "brgy_name": "Concepcion",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101013",
      "brgy_name": "Dayhagan",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101014",
      "brgy_name": "Don Pablo Dela Rosa",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101015",
      "brgy_name": "Gumahang",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101016",
      "brgy_name": "Jaboyoan",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101017",
      "brgy_name": "Lanang",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101018",
      "brgy_name": "Luy-a",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101019",
      "brgy_name": "Macabug",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101020",
      "brgy_name": "Malubi",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101021",
      "brgy_name": "Managanaga",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101022",
      "brgy_name": "Manamoc",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101023",
      "brgy_name": "Mariposa",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101024",
      "brgy_name": "Mataba",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101025",
      "brgy_name": "Matalangtalang",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101026",
      "brgy_name": "Matangog",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101027",
      "brgy_name": "Nabongsoran",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101028",
      "brgy_name": "Pangle",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101029",
      "brgy_name": "Panique",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101030",
      "brgy_name": "Pinanaan",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101031",
      "brgy_name": "Poblacion",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101032",
      "brgy_name": "Puro",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101033",
      "brgy_name": "San Agustin",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101034",
      "brgy_name": "San Isidro",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101035",
      "brgy_name": "Sawang",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101036",
      "brgy_name": "Syndicate",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101037",
      "brgy_name": "Talabaan",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101038",
      "brgy_name": "Talib",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101039",
      "brgy_name": "Tigbao",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101040",
      "brgy_name": "Tinago",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054101041",
      "brgy_name": "Tinigban",
      "city_code": "054101",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102001",
      "brgy_name": "Baao",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102002",
      "brgy_name": "Banase",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102003",
      "brgy_name": "Batuila",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102004",
      "brgy_name": "Cagara",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102005",
      "brgy_name": "Cagpandan",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102006",
      "brgy_name": "Cancahorao",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102007",
      "brgy_name": "Canjunday",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102008",
      "brgy_name": "Docol",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102009",
      "brgy_name": "Eastern Capsay",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102010",
      "brgy_name": "Gabi",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102011",
      "brgy_name": "Gangao",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102012",
      "brgy_name": "Lagta",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102013",
      "brgy_name": "Lahong Proper",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102014",
      "brgy_name": "Lahong Interior",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102015",
      "brgy_name": "Lipata",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102016",
      "brgy_name": "Madangcalan",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102017",
      "brgy_name": "Magdalena",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102018",
      "brgy_name": "Manoboc",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102019",
      "brgy_name": "Obongon Diot",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102020",
      "brgy_name": "Poblacion",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102021",
      "brgy_name": "Polot",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102022",
      "brgy_name": "Potoson",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102023",
      "brgy_name": "Sog-Ong",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054102024",
      "brgy_name": "Tinapian",
      "city_code": "054102",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103001",
      "brgy_name": "Baybay (Lumocab)",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103002",
      "brgy_name": "Bongcanaway",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103003",
      "brgy_name": "Mabuhay (Bongcanaway III)",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103004",
      "brgy_name": "Calumpang",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103005",
      "brgy_name": "Cantil",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103006",
      "brgy_name": "Casamongan",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103007",
      "brgy_name": "Dao",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103008",
      "brgy_name": "Danao",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103009",
      "brgy_name": "Guinbanwahan",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103010",
      "brgy_name": "Ilaya",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103011",
      "brgy_name": "Jangan",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103012",
      "brgy_name": "Jintotolo",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103013",
      "brgy_name": "Mapili",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103014",
      "brgy_name": "Mapitogo",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103015",
      "brgy_name": "Pajo",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103016",
      "brgy_name": "Palane",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103017",
      "brgy_name": "Panguiranan",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103018",
      "brgy_name": "Panubigan",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103019",
      "brgy_name": "Poblacion (Balud)",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103020",
      "brgy_name": "Pulanduta",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103021",
      "brgy_name": "Quinayangan Diotay",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103022",
      "brgy_name": "Quinayangan Tonga",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103023",
      "brgy_name": "Salvacion",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103024",
      "brgy_name": "Sampad",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103025",
      "brgy_name": "San Andres (Quinayangan Dacu)",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103026",
      "brgy_name": "San Antonio",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103027",
      "brgy_name": "Sapatos",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103029",
      "brgy_name": "Talisay",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103030",
      "brgy_name": "Tonga",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103031",
      "brgy_name": "Ubo",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103032",
      "brgy_name": "Victory (Victoria)",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054103033",
      "brgy_name": "Villa Alvarez",
      "city_code": "054103",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104001",
      "brgy_name": "Burgos",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104002",
      "brgy_name": "Canares",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104003",
      "brgy_name": "Cambañez",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104004",
      "brgy_name": "Costa Rica",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104005",
      "brgy_name": "Danao",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104006",
      "brgy_name": "Gibraltar",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104007",
      "brgy_name": "Mabuhay",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104008",
      "brgy_name": "Matabao",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104009",
      "brgy_name": "Nasandig",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104010",
      "brgy_name": "Panisihan",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104011",
      "brgy_name": "Poblacion",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104013",
      "brgy_name": "Rizal",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104014",
      "brgy_name": "Royroy",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054104015",
      "brgy_name": "Sawang",
      "city_code": "054104",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105001",
      "brgy_name": "Abaca",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105002",
      "brgy_name": "Aguada",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105003",
      "brgy_name": "Badiang",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105004",
      "brgy_name": "Bagumbayan",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105005",
      "brgy_name": "Cadulawan",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105006",
      "brgy_name": "Cagbatang",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105007",
      "brgy_name": "Chimenea",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105008",
      "brgy_name": "Concepcion",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105010",
      "brgy_name": "Curvada",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105011",
      "brgy_name": "Divisoria",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105012",
      "brgy_name": "Domorog",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105013",
      "brgy_name": "Estampar",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105014",
      "brgy_name": "Gahit",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105015",
      "brgy_name": "Libtong",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105016",
      "brgy_name": "Liong",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105017",
      "brgy_name": "Maanahao",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105018",
      "brgy_name": "Madamba",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105019",
      "brgy_name": "Malobago",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105020",
      "brgy_name": "Matayum",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105021",
      "brgy_name": "Matubinao",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105022",
      "brgy_name": "Mintac",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105023",
      "brgy_name": "Nadawisan",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105024",
      "brgy_name": "Osmeña",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105025",
      "brgy_name": "Pawican",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105026",
      "brgy_name": "Pitogo",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105027",
      "brgy_name": "Poblacion",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105028",
      "brgy_name": "Quezon",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105029",
      "brgy_name": "San Isidro",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105030",
      "brgy_name": "San Jose",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105031",
      "brgy_name": "San Pedro",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105032",
      "brgy_name": "San Rafael",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105033",
      "brgy_name": "Santa Teresita",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105034",
      "brgy_name": "Santo Niño",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105036",
      "brgy_name": "Tagboan",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105037",
      "brgy_name": "Tuybo",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054105038",
      "brgy_name": "Villa Pogado",
      "city_code": "054105",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106002",
      "brgy_name": "Begia",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106003",
      "brgy_name": "Cabayugan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106004",
      "brgy_name": "Cabungahan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106005",
      "brgy_name": "Calapayan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106006",
      "brgy_name": "Calumpang",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106007",
      "brgy_name": "Dalipe",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106008",
      "brgy_name": "Divisoria",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106009",
      "brgy_name": "Guiom",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106010",
      "brgy_name": "Gilotongan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106011",
      "brgy_name": "Itombato",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106012",
      "brgy_name": "Libertad",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106013",
      "brgy_name": "Looc",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106014",
      "brgy_name": "Mactan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106015",
      "brgy_name": "Madbad",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106016",
      "brgy_name": "R.M. Magbalon (Bebinan)",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106017",
      "brgy_name": "Mahayahay",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106018",
      "brgy_name": "Maihao",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106019",
      "brgy_name": "Malbug",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106020",
      "brgy_name": "Naro",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106021",
      "brgy_name": "Pananawan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106022",
      "brgy_name": "Poblacion",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106023",
      "brgy_name": "Pulot",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106024",
      "brgy_name": "Recodo",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106025",
      "brgy_name": "San Jose",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106026",
      "brgy_name": "San Vicente",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106027",
      "brgy_name": "Taberna",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106028",
      "brgy_name": "Talisay",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106029",
      "brgy_name": "Tuburan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106030",
      "brgy_name": "Villahermosa",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106031",
      "brgy_name": "Chico Island",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106032",
      "brgy_name": "Lague-lague",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106033",
      "brgy_name": "Palobandera",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106034",
      "brgy_name": "Peña Island",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106035",
      "brgy_name": "Pin-As",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106036",
      "brgy_name": "Iraya",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106037",
      "brgy_name": "Punta Batsan",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054106038",
      "brgy_name": "Tubog",
      "city_code": "054106",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107001",
      "brgy_name": "Albasan",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107002",
      "brgy_name": "Boca Engaño",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107003",
      "brgy_name": "Buyo",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107004",
      "brgy_name": "Calpi",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107005",
      "brgy_name": "Canomay",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107006",
      "brgy_name": "Cawayan",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107007",
      "brgy_name": "Poblacion District I (Bgy. 1)",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107008",
      "brgy_name": "Poblacion District II (Bgy. 2)",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107009",
      "brgy_name": "Mababang Baybay",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107010",
      "brgy_name": "Mabiton",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107011",
      "brgy_name": "Manapao",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107012",
      "brgy_name": "Nabasagan",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107013",
      "brgy_name": "Nonoc",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107014",
      "brgy_name": "Osmeña",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107015",
      "brgy_name": "Pasig",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107016",
      "brgy_name": "Peñafrancia",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107017",
      "brgy_name": "Quezon",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107018",
      "brgy_name": "San Isidro",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107019",
      "brgy_name": "San Ramon",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107020",
      "brgy_name": "San Vicente",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107021",
      "brgy_name": "Taguilid",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054107022",
      "brgy_name": "Imelda",
      "city_code": "054107",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108001",
      "brgy_name": "Balantay",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108002",
      "brgy_name": "Balocawe",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108003",
      "brgy_name": "Banahao",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108004",
      "brgy_name": "Buenaflor",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108005",
      "brgy_name": "Buracan",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108006",
      "brgy_name": "Cabanoyoan",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108007",
      "brgy_name": "Cabrera",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108008",
      "brgy_name": "Cadulan",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108009",
      "brgy_name": "Calabad",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108010",
      "brgy_name": "Canomay",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108011",
      "brgy_name": "Divisoria",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108012",
      "brgy_name": "Gaid",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108013",
      "brgy_name": "Gregorio Alino (Pia-ong)",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108014",
      "brgy_name": "Magcaraguit",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108015",
      "brgy_name": "Mambog",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108016",
      "brgy_name": "Poblacion",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108017",
      "brgy_name": "Rizal",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108018",
      "brgy_name": "San Vicente",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108019",
      "brgy_name": "Suba",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054108020",
      "brgy_name": "T.R. Yangco (Yanco)",
      "city_code": "054108",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109001",
      "brgy_name": "Agoho",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109002",
      "brgy_name": "Almero",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109003",
      "brgy_name": "Baras",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109004",
      "brgy_name": "Domorog",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109005",
      "brgy_name": "Guadalupe",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109006",
      "brgy_name": "Iligan",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109007",
      "brgy_name": "Labangtaytay",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109008",
      "brgy_name": "Labrador",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109009",
      "brgy_name": "Libertad",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109010",
      "brgy_name": "Magsaysay",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109011",
      "brgy_name": "Masbaranon",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109012",
      "brgy_name": "Poblacion",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109013",
      "brgy_name": "Potingbato",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109014",
      "brgy_name": "Rizal",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109015",
      "brgy_name": "San Roque",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109016",
      "brgy_name": "Santiago",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109017",
      "brgy_name": "Sorosimbajan",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109018",
      "brgy_name": "Tawad",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109019",
      "brgy_name": "Tunga",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054109020",
      "brgy_name": "Villa",
      "city_code": "054109",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110001",
      "brgy_name": "Alas",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110002",
      "brgy_name": "Ayat",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110003",
      "brgy_name": "Bat-Ongan",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110004",
      "brgy_name": "Bugtong",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110005",
      "brgy_name": "Buri",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110006",
      "brgy_name": "Cabitan",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110007",
      "brgy_name": "Cagmasoso",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110008",
      "brgy_name": "Canomoy",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110009",
      "brgy_name": "Centro",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110010",
      "brgy_name": "Dayao",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110011",
      "brgy_name": "Guincaiptan",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110012",
      "brgy_name": "Lantangan",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110013",
      "brgy_name": "Looc",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110014",
      "brgy_name": "Mabatobato",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110015",
      "brgy_name": "Maolingon",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110016",
      "brgy_name": "Nailaban",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110017",
      "brgy_name": "Nanipsan",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110018",
      "brgy_name": "Pinamangcaan",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110019",
      "brgy_name": "Poblacion",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110020",
      "brgy_name": "Polo Dacu",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110021",
      "brgy_name": "San Juan",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110022",
      "brgy_name": "San Pablo",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110023",
      "brgy_name": "Santa Fe",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110024",
      "brgy_name": "Tagpu",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110025",
      "brgy_name": "Tumalaytay",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054110026",
      "brgy_name": "Laguinbanwa",
      "city_code": "054110",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111001",
      "brgy_name": "Anas",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111002",
      "brgy_name": "Asid",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111003",
      "brgy_name": "B. Titong",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111004",
      "brgy_name": "Bagumbayan",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111005",
      "brgy_name": "Bantigue",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111006",
      "brgy_name": "Bapor (Pob.)",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111007",
      "brgy_name": "Batuhan",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111008",
      "brgy_name": "Bayombon",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111009",
      "brgy_name": "Biyong",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111010",
      "brgy_name": "Bolo",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111011",
      "brgy_name": "Cagay",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111012",
      "brgy_name": "Cawayan Exterior",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111013",
      "brgy_name": "Cawayan Interior",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111014",
      "brgy_name": "Centro (Pob.)",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111015",
      "brgy_name": "Espinosa",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111016",
      "brgy_name": "F. Magallanes",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111017",
      "brgy_name": "Ibingay",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111018",
      "brgy_name": "Igang",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111019",
      "brgy_name": "Kalipay (Pob.)",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111020",
      "brgy_name": "Kinamaligan",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111021",
      "brgy_name": "Malinta",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111022",
      "brgy_name": "Mapiña",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111023",
      "brgy_name": "Mayngaran",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111024",
      "brgy_name": "Nursery",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111025",
      "brgy_name": "Pating (Pob.)",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111026",
      "brgy_name": "Pawa",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111027",
      "brgy_name": "Sinalongan",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111028",
      "brgy_name": "Tugbo",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111029",
      "brgy_name": "Ubongan Dacu",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054111030",
      "brgy_name": "Usab",
      "city_code": "054111",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112001",
      "brgy_name": "Bacolod",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112002",
      "brgy_name": "Bangad",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112003",
      "brgy_name": "Bara",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112004",
      "brgy_name": "Bonbon",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112005",
      "brgy_name": "Calasuche",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112006",
      "brgy_name": "Calumpang (Taisan)",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112007",
      "brgy_name": "Capaculan",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112008",
      "brgy_name": "Cayabon",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112009",
      "brgy_name": "Guinluthangan",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112010",
      "brgy_name": "Jamorawon",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112011",
      "brgy_name": "Magsalangi",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112012",
      "brgy_name": "Matagbac",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112013",
      "brgy_name": "Matanglad",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112014",
      "brgy_name": "Matiporon",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112015",
      "brgy_name": "Moises R. Espinosa",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112016",
      "brgy_name": "Narangasan",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112018",
      "brgy_name": "Pamangpangon",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112019",
      "brgy_name": "Poblacion East",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112020",
      "brgy_name": "Poblacion West",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112021",
      "brgy_name": "Paraiso (Potot)",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112022",
      "brgy_name": "San Antonio",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112023",
      "brgy_name": "San Carlos",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112024",
      "brgy_name": "Sawmill",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112025",
      "brgy_name": "Tagbon",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112026",
      "brgy_name": "Tawad",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112027",
      "brgy_name": "Tigbao",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054112028",
      "brgy_name": "Tinaclipan (Bato)",
      "city_code": "054112",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113001",
      "brgy_name": "Baang",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113002",
      "brgy_name": "Bagacay",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113003",
      "brgy_name": "Balatucan",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113004",
      "brgy_name": "Barag",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113005",
      "brgy_name": "Dacu",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113006",
      "brgy_name": "Fabrica",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113007",
      "brgy_name": "Guintorelan",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113008",
      "brgy_name": "Holjogon",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113009",
      "brgy_name": "Lalaguna",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113010",
      "brgy_name": "Lomocloc",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113011",
      "brgy_name": "Luyong Catungan",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113012",
      "brgy_name": "Mabuhay",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113013",
      "brgy_name": "Mandali",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113014",
      "brgy_name": "Mapuyo",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113015",
      "brgy_name": "Marintoc",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113016",
      "brgy_name": "Nasunduan",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113017",
      "brgy_name": "Pinamalatican",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113018",
      "brgy_name": "Pinamarbuhan",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113019",
      "brgy_name": "Poblacion Dist. I",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113020",
      "brgy_name": "Poblacion Dist. II",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113021",
      "brgy_name": "Polot",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113022",
      "brgy_name": "Sambulawan",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113023",
      "brgy_name": "Santa Maria",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113024",
      "brgy_name": "Sawmill",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113025",
      "brgy_name": "Tabuc",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113026",
      "brgy_name": "Tugawe",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113027",
      "brgy_name": "Tugbo",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113028",
      "brgy_name": "Umabay Exterior",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054113029",
      "brgy_name": "Umabay Interior",
      "city_code": "054113",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114001",
      "brgy_name": "Cantorna",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114002",
      "brgy_name": "Famosa",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114003",
      "brgy_name": "Macarthur",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114004",
      "brgy_name": "Maglambong",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114005",
      "brgy_name": "Morocborocan",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114006",
      "brgy_name": "Poblacion",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114007",
      "brgy_name": "Guinhadap",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114008",
      "brgy_name": "Real",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114009",
      "brgy_name": "Rizal",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114010",
      "brgy_name": "Santo Niño",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054114011",
      "brgy_name": "Togoron",
      "city_code": "054114",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115001",
      "brgy_name": "Antipolo",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115003",
      "brgy_name": "Banco",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115004",
      "brgy_name": "Biga-a",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115005",
      "brgy_name": "Bontod",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115006",
      "brgy_name": "Buenasuerte",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115007",
      "brgy_name": "Intusan",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115008",
      "brgy_name": "Jose A. Abenir Sr. (Libtong)",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115009",
      "brgy_name": "Maanahao",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115010",
      "brgy_name": "Mabini",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115011",
      "brgy_name": "Malibas",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115012",
      "brgy_name": "Maravilla",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115013",
      "brgy_name": "Matugnao",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115014",
      "brgy_name": "Miabas",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115015",
      "brgy_name": "Nabangig",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115016",
      "brgy_name": "Nipa",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115017",
      "brgy_name": "Parina",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115018",
      "brgy_name": "Piña",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115019",
      "brgy_name": "Poblacion",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115020",
      "brgy_name": "Salvacion",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115021",
      "brgy_name": "San Antonio",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115022",
      "brgy_name": "San Carlos",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115023",
      "brgy_name": "San Isidro",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115024",
      "brgy_name": "Santa Cruz",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054115025",
      "brgy_name": "Malatawan",
      "city_code": "054115",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116001",
      "brgy_name": "Alegria",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116002",
      "brgy_name": "Buenasuerte",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116003",
      "brgy_name": "Bugang",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116004",
      "brgy_name": "Bugtong",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116005",
      "brgy_name": "Bunducan",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116006",
      "brgy_name": "Cabangrayan",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116007",
      "brgy_name": "Calongongan",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116008",
      "brgy_name": "Casabangan",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116009",
      "brgy_name": "Guindawahan",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116010",
      "brgy_name": "Labigan",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116011",
      "brgy_name": "Lampuyang",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116012",
      "brgy_name": "Mabuhay",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116014",
      "brgy_name": "Palho",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116015",
      "brgy_name": "Poblacion",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116016",
      "brgy_name": "Salvacion",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116017",
      "brgy_name": "Tanque",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116018",
      "brgy_name": "Tubigan",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054116019",
      "brgy_name": "Tubog",
      "city_code": "054116",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117001",
      "brgy_name": "Aguada",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117002",
      "brgy_name": "Ban-Ao",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117003",
      "brgy_name": "Burabod",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117004",
      "brgy_name": "Cabangcalan",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117005",
      "brgy_name": "Calumpang",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117006",
      "brgy_name": "Camayabsan",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117008",
      "brgy_name": "Daanlungsod",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117009",
      "brgy_name": "Dangpanan",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117010",
      "brgy_name": "Daraga",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117011",
      "brgy_name": "Guin-Awayan",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117012",
      "brgy_name": "Guinhan-Ayan",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117013",
      "brgy_name": "Katipunan",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117014",
      "brgy_name": "Libas",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117015",
      "brgy_name": "Locso-An",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117016",
      "brgy_name": "Luna",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117017",
      "brgy_name": "Mahayag",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117018",
      "brgy_name": "Mahayahay",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117019",
      "brgy_name": "Manlut-Od",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117020",
      "brgy_name": "Matagantang",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117021",
      "brgy_name": "Naboctot",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117022",
      "brgy_name": "Nagarao",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117023",
      "brgy_name": "Nainday",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117024",
      "brgy_name": "Naocondiot",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117025",
      "brgy_name": "Pasiagon",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117026",
      "brgy_name": "Pili",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117027",
      "brgy_name": "Poblacion",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117028",
      "brgy_name": "Puro",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117029",
      "brgy_name": "Quibrada",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117030",
      "brgy_name": "San Marcos",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117031",
      "brgy_name": "Santa Cruz",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117032",
      "brgy_name": "Taboc",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117033",
      "brgy_name": "Tan-Awan",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117034",
      "brgy_name": "Taverna",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117035",
      "brgy_name": "Tubod",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054117036",
      "brgy_name": "Villa Inocencio",
      "city_code": "054117",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118001",
      "brgy_name": "Altavista",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118002",
      "brgy_name": "Benitinan",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118003",
      "brgy_name": "Buenasuerte",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118004",
      "brgy_name": "Buenavista",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118005",
      "brgy_name": "Buenos Aires",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118006",
      "brgy_name": "Buyo",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118007",
      "brgy_name": "Cañelas",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118008",
      "brgy_name": "Corbada",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118009",
      "brgy_name": "Daplian",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118010",
      "brgy_name": "Del Rosario",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118011",
      "brgy_name": "Ipil",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118012",
      "brgy_name": "Lahong",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118013",
      "brgy_name": "Lumbia",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118014",
      "brgy_name": "Magkaipit",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118015",
      "brgy_name": "Minio",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118016",
      "brgy_name": "Pinamoghaan",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118017",
      "brgy_name": "Baybaydagat Pob. (District 1)",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118018",
      "brgy_name": "Silangan Pob. (District 2)",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118019",
      "brgy_name": "Magsasaka Pob. (District 3)",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118020",
      "brgy_name": "Bayanihan Pob. (District 4)",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118021",
      "brgy_name": "Progreso",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118022",
      "brgy_name": "Resurreccion",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118023",
      "brgy_name": "Salvacion",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118024",
      "brgy_name": "Sowa",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118025",
      "brgy_name": "Talisay",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054118026",
      "brgy_name": "Valparaiso",
      "city_code": "054118",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119001",
      "brgy_name": "Almiñe",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119002",
      "brgy_name": "Bagacay",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119003",
      "brgy_name": "Bagahanglad",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119004",
      "brgy_name": "Bartolabac",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119005",
      "brgy_name": "Burgos",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119006",
      "brgy_name": "Calipat-An",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119007",
      "brgy_name": "Danao",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119008",
      "brgy_name": "Dorong-an Daplian",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119009",
      "brgy_name": "Interior",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119010",
      "brgy_name": "Jagna-an",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119011",
      "brgy_name": "Luna",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119012",
      "brgy_name": "Mabini",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119013",
      "brgy_name": "Piña",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119014",
      "brgy_name": "District I (Pob.)",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119015",
      "brgy_name": "District II (Pob.)",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119016",
      "brgy_name": "District III (Pob.)",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119017",
      "brgy_name": "District IV (Pob.)",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119018",
      "brgy_name": "Roosevelt",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119019",
      "brgy_name": "San Isidro",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119020",
      "brgy_name": "Santa Rosa",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054119021",
      "brgy_name": "Washington",
      "city_code": "054119",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120001",
      "brgy_name": "Boca Chica",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120002",
      "brgy_name": "Bolod (Pob.)",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120003",
      "brgy_name": "Busing",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120004",
      "brgy_name": "Dangcalan",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120005",
      "brgy_name": "Halabangbaybay",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120006",
      "brgy_name": "Iniwaran",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120007",
      "brgy_name": "Ki-Buaya (Rizal)",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120008",
      "brgy_name": "Ki-Romero (Roxas)",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120009",
      "brgy_name": "Laurente",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120010",
      "brgy_name": "Mabini",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120011",
      "brgy_name": "Mabuhay",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120013",
      "brgy_name": "Mapanique",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120014",
      "brgy_name": "Nazareno",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120015",
      "brgy_name": "Pinamasingan",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120016",
      "brgy_name": "Quintina",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120019",
      "brgy_name": "San Jose",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120020",
      "brgy_name": "San Pedro",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120021",
      "brgy_name": "San Rafael",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120022",
      "brgy_name": "Santa Cruz",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120023",
      "brgy_name": "Terraplin (Pob.)",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120024",
      "brgy_name": "Cueva",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054120025",
      "brgy_name": "Malaking Ilog",
      "city_code": "054120",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121001",
      "brgy_name": "Arado",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121002",
      "brgy_name": "Armenia",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121003",
      "brgy_name": "Aurora",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121004",
      "brgy_name": "Badling",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121005",
      "brgy_name": "Bonifacio",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121006",
      "brgy_name": "Buenasuerte",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121007",
      "brgy_name": "Buenavista",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121008",
      "brgy_name": "Campana",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121009",
      "brgy_name": "Candelaria",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121010",
      "brgy_name": "Centro",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121011",
      "brgy_name": "Crossing",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121012",
      "brgy_name": "Dapdap",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121013",
      "brgy_name": "Del Carmen",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121014",
      "brgy_name": "Del Rosario",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121015",
      "brgy_name": "Libertad",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121016",
      "brgy_name": "Madao",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121017",
      "brgy_name": "Mabini",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121018",
      "brgy_name": "Magsaysay",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121019",
      "brgy_name": "Marcella",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121020",
      "brgy_name": "Miaga",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121021",
      "brgy_name": "Mongahay",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121022",
      "brgy_name": "Morocborocan",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121023",
      "brgy_name": "Mabuhay",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121024",
      "brgy_name": "Paguihaman",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121025",
      "brgy_name": "Panicijan",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121026",
      "brgy_name": "Poblacion",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121027",
      "brgy_name": "Quezon",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121028",
      "brgy_name": "San Isidro",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121029",
      "brgy_name": "San Jose",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121030",
      "brgy_name": "San Mateo",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121031",
      "brgy_name": "San Ramon",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121032",
      "brgy_name": "San Vicente",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121033",
      "brgy_name": "Santo Cristo",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121034",
      "brgy_name": "Sawang",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "054121035",
      "brgy_name": "Simawa",
      "city_code": "054121",
      "province_code": "0541",
      "region_code": "05"
    },
    {
      "brgy_code": "056202002",
      "brgy_name": "Alegria",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202003",
      "brgy_name": "Bagacay",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202004",
      "brgy_name": "Bangate",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202005",
      "brgy_name": "Bugtong",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202006",
      "brgy_name": "Cagang",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202008",
      "brgy_name": "Fabrica",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202010",
      "brgy_name": "Jibong",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202011",
      "brgy_name": "Lago",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202012",
      "brgy_name": "Layog",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202013",
      "brgy_name": "Luneta",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202014",
      "brgy_name": "Macabari",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202015",
      "brgy_name": "Mapapac",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202016",
      "brgy_name": "Olandia",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202017",
      "brgy_name": "Paghaluban",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202018",
      "brgy_name": "Poblacion Central",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202019",
      "brgy_name": "Poblacion Norte",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202020",
      "brgy_name": "Poblacion Sur",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202021",
      "brgy_name": "Putiao",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202022",
      "brgy_name": "San Antonio",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202023",
      "brgy_name": "San Isidro",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202024",
      "brgy_name": "San Ramon",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202025",
      "brgy_name": "San Vicente",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202027",
      "brgy_name": "Santa Cruz",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202028",
      "brgy_name": "Santa Lourdes",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056202029",
      "brgy_name": "Tagdon",
      "city_code": "056202",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203001",
      "brgy_name": "A. Bonifacio",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203002",
      "brgy_name": "Abad Santos",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203003",
      "brgy_name": "Aguinaldo",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203004",
      "brgy_name": "Antipolo",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203005",
      "brgy_name": "Zone I Pob. (Bgy. 1- South Ilawod)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203006",
      "brgy_name": "Zone II Pob. (Bgy. 2- West Ilawod)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203007",
      "brgy_name": "Zone III Pob. (Bgy. 3- East Ilawod)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203008",
      "brgy_name": "Zone IV Pob. (Bgy. 4- West Central)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203009",
      "brgy_name": "Zone V Pob. (Bgy. 5-Lanipan)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203010",
      "brgy_name": "Zone VI Pob. (Bgy. 6- Baybay)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203011",
      "brgy_name": "Bical",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203012",
      "brgy_name": "Beguin",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203013",
      "brgy_name": "Bonga",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203014",
      "brgy_name": "Butag",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203015",
      "brgy_name": "Cadandanan",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203016",
      "brgy_name": "Calomagon",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203017",
      "brgy_name": "Calpi",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203018",
      "brgy_name": "Cocok-Cabitan",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203019",
      "brgy_name": "Daganas",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203020",
      "brgy_name": "Danao",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203021",
      "brgy_name": "Dolos",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203022",
      "brgy_name": "E. Quirino",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203023",
      "brgy_name": "Fabrica",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203024",
      "brgy_name": "Gate",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203025",
      "brgy_name": "Benigno S. Aquino (Imelda)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203026",
      "brgy_name": "Inararan",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203027",
      "brgy_name": "J. Gerona",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203028",
      "brgy_name": "Jamorawon",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203029",
      "brgy_name": "Libertad",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203030",
      "brgy_name": "Lajong",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203031",
      "brgy_name": "Magsaysay",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203032",
      "brgy_name": "Managanaga",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203033",
      "brgy_name": "Marinab",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203034",
      "brgy_name": "Nasuje",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203035",
      "brgy_name": "Montecalvario",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203036",
      "brgy_name": "N. Roque (Rizal)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203037",
      "brgy_name": "Namo",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203038",
      "brgy_name": "Obrero",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203039",
      "brgy_name": "Osmeña",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203040",
      "brgy_name": "Otavi",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203041",
      "brgy_name": "Padre Diaz",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203042",
      "brgy_name": "Palale",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203045",
      "brgy_name": "J.P. Laurel (Pon-od)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203046",
      "brgy_name": "Quezon",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203047",
      "brgy_name": "R. Gerona",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203048",
      "brgy_name": "Recto",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203049",
      "brgy_name": "M. Roxas",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203050",
      "brgy_name": "Sagrada",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203051",
      "brgy_name": "San Francisco",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203052",
      "brgy_name": "San Isidro",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203053",
      "brgy_name": "San Juan Bag-o",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203054",
      "brgy_name": "San Juan Daan",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203055",
      "brgy_name": "San Rafael",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203056",
      "brgy_name": "San Ramon",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203057",
      "brgy_name": "San Vicente",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203058",
      "brgy_name": "Santa Remedios",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203059",
      "brgy_name": "Santa Teresita",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203060",
      "brgy_name": "Sigad",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203061",
      "brgy_name": "Somagongsong",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203062",
      "brgy_name": "G. Del Pilar (Tanga)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203063",
      "brgy_name": "Taromata",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203064",
      "brgy_name": "Zone VII Pob. (Bgy. 7- Iraya)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056203065",
      "brgy_name": "Zone VIII Pob. (Bgy. 8- Loyo)",
      "city_code": "056203",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204001",
      "brgy_name": "Bagacay",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204002",
      "brgy_name": "Central (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204003",
      "brgy_name": "Cogon",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204004",
      "brgy_name": "Dancalan",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204005",
      "brgy_name": "Dapdap (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204006",
      "brgy_name": "Lalud",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204007",
      "brgy_name": "Looban (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204008",
      "brgy_name": "Mabuhay (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204009",
      "brgy_name": "Madlawon (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204010",
      "brgy_name": "Poctol (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204011",
      "brgy_name": "Porog",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204012",
      "brgy_name": "Sabang (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204013",
      "brgy_name": "Salvacion",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204014",
      "brgy_name": "San Antonio",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204015",
      "brgy_name": "San Bernardo",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204016",
      "brgy_name": "San Francisco",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204017",
      "brgy_name": "San Isidro",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204018",
      "brgy_name": "San Jose",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204019",
      "brgy_name": "San Rafael",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204020",
      "brgy_name": "San Roque",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204021",
      "brgy_name": "San Vicente (Buhang)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204022",
      "brgy_name": "Santa Barbara",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204023",
      "brgy_name": "Sapngan (Pob.)",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056204024",
      "brgy_name": "Tinampo",
      "city_code": "056204",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205001",
      "brgy_name": "Adovis (Pob.)",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205002",
      "brgy_name": "Boton",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205003",
      "brgy_name": "Burgos",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205004",
      "brgy_name": "Casay",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205005",
      "brgy_name": "Cawit",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205006",
      "brgy_name": "Central (Pob.)",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205007",
      "brgy_name": "Colambis",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205008",
      "brgy_name": "Escuala",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205010",
      "brgy_name": "Cogon",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205011",
      "brgy_name": "Inlagadian",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205012",
      "brgy_name": "Lungib",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205013",
      "brgy_name": "Mabini",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205014",
      "brgy_name": "Ponong",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205015",
      "brgy_name": "Rizal",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205016",
      "brgy_name": "San Antonio (Boho)",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205017",
      "brgy_name": "San Isidro",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205018",
      "brgy_name": "San Juan",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205019",
      "brgy_name": "San Pascual",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205020",
      "brgy_name": "Santa Cruz",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205021",
      "brgy_name": "Somal-ot (Pob.)",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205022",
      "brgy_name": "Tigbao",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205023",
      "brgy_name": "Timbayog (Pob.)",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205024",
      "brgy_name": "Tiris",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205025",
      "brgy_name": "Trece Martirez",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056205026",
      "brgy_name": "Tulay",
      "city_code": "056205",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206001",
      "brgy_name": "Amomonting",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206002",
      "brgy_name": "Bagalayag",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206003",
      "brgy_name": "Bagong Sirang",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206004",
      "brgy_name": "Bonga",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206005",
      "brgy_name": "Buenavista",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206006",
      "brgy_name": "Burabod",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206007",
      "brgy_name": "Caburacan",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206008",
      "brgy_name": "Canjela",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206009",
      "brgy_name": "Cogon",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206010",
      "brgy_name": "Cumadcad",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206011",
      "brgy_name": "Dangcalan",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206012",
      "brgy_name": "Dinapa",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206013",
      "brgy_name": "La Union",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206014",
      "brgy_name": "Libtong",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206015",
      "brgy_name": "Loreto",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206016",
      "brgy_name": "Macalaya",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206017",
      "brgy_name": "Maracabac",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206018",
      "brgy_name": "Mayon",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206019",
      "brgy_name": "Milagrosa",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206020",
      "brgy_name": "Miluya",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206021",
      "brgy_name": "Maypangi",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206022",
      "brgy_name": "Monte Carmelo",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206023",
      "brgy_name": "Oras",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206024",
      "brgy_name": "Pandan",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206025",
      "brgy_name": "Poblacion",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206026",
      "brgy_name": "Quirapi",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206027",
      "brgy_name": "Saclayan",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206028",
      "brgy_name": "Salvacion",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206029",
      "brgy_name": "San Isidro",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206030",
      "brgy_name": "San Rafael",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206031",
      "brgy_name": "San Roque",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206032",
      "brgy_name": "San Vicente",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206033",
      "brgy_name": "Sogoy",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056206034",
      "brgy_name": "Tomalaytay",
      "city_code": "056206",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207001",
      "brgy_name": "Alin",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207002",
      "brgy_name": "Awai",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207003",
      "brgy_name": "Banban",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207004",
      "brgy_name": "Bandi",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207005",
      "brgy_name": "Banuang Gurang",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207006",
      "brgy_name": "Baras",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207007",
      "brgy_name": "Bayawas",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207008",
      "brgy_name": "Bororan Barangay 1 (Pob.)",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207009",
      "brgy_name": "Cabugao",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207010",
      "brgy_name": "Central Barangay 2 (Pob.)",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207011",
      "brgy_name": "Cristo",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207012",
      "brgy_name": "Dancalan",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207013",
      "brgy_name": "De Vera",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207014",
      "brgy_name": "Gimagaan",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207015",
      "brgy_name": "Girawan",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207016",
      "brgy_name": "Gogon",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207017",
      "brgy_name": "Gura",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207018",
      "brgy_name": "Juan Adre",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207019",
      "brgy_name": "Lourdes",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207020",
      "brgy_name": "Mabini",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207021",
      "brgy_name": "Malapoc",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207022",
      "brgy_name": "Malinao",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207023",
      "brgy_name": "Market Site Barangay 3 (Pob.)",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207024",
      "brgy_name": "New Maguisa",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207025",
      "brgy_name": "Ogod (Crossing)",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207026",
      "brgy_name": "Old Maguisa",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207027",
      "brgy_name": "Orange",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207028",
      "brgy_name": "Pangpang",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207029",
      "brgy_name": "Parina",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207030",
      "brgy_name": "Pawala",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207031",
      "brgy_name": "Pinamanaan",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207032",
      "brgy_name": "Poso Pob. (Barangay 5)",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207033",
      "brgy_name": "Punta Waling-Waling Pob. (Barangay 4)",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207034",
      "brgy_name": "Rawis",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207035",
      "brgy_name": "San Antonio",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207036",
      "brgy_name": "San Isidro",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207037",
      "brgy_name": "San Jose",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207038",
      "brgy_name": "San Rafael",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207039",
      "brgy_name": "San Ramon",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207040",
      "brgy_name": "San Vicente",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207041",
      "brgy_name": "Santa Cruz",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207042",
      "brgy_name": "Sevilla",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207043",
      "brgy_name": "Sibago",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207044",
      "brgy_name": "Suguian",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207045",
      "brgy_name": "Tagbac",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207046",
      "brgy_name": "Tinanogan",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207047",
      "brgy_name": "Tongdol",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207048",
      "brgy_name": "Tres Marias",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207049",
      "brgy_name": "Tuba",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207050",
      "brgy_name": "Tupas",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056207051",
      "brgy_name": "Vinisitahan",
      "city_code": "056207",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208001",
      "brgy_name": "Ariman",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208002",
      "brgy_name": "Bagacay",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208003",
      "brgy_name": "Balud Del Norte (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208004",
      "brgy_name": "Balud Del Sur (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208006",
      "brgy_name": "Benguet",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208007",
      "brgy_name": "Bentuco",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208008",
      "brgy_name": "Beriran",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208009",
      "brgy_name": "Buenavista",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208010",
      "brgy_name": "Bulacao",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208011",
      "brgy_name": "Cabigaan",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208012",
      "brgy_name": "Cabiguhan",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208013",
      "brgy_name": "Carriedo",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208014",
      "brgy_name": "Casili",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208015",
      "brgy_name": "Cogon",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208016",
      "brgy_name": "Cota Na Daco (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208017",
      "brgy_name": "Dita",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208018",
      "brgy_name": "Jupi",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208019",
      "brgy_name": "Lapinig",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208020",
      "brgy_name": "Luna-Candol (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208021",
      "brgy_name": "Manapao",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208022",
      "brgy_name": "Manook (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208023",
      "brgy_name": "Naagtan",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208024",
      "brgy_name": "Nato",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208025",
      "brgy_name": "Nazareno",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208026",
      "brgy_name": "Ogao",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208027",
      "brgy_name": "Paco",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208028",
      "brgy_name": "Panganiban (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208029",
      "brgy_name": "Paradijon (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208030",
      "brgy_name": "Patag",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208031",
      "brgy_name": "Payawin",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208033",
      "brgy_name": "Pinontingan (Pob.)",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208034",
      "brgy_name": "Rizal",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208035",
      "brgy_name": "San Ignacio",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208036",
      "brgy_name": "Sangat",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208037",
      "brgy_name": "Santa Ana",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208038",
      "brgy_name": "Tabi",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208039",
      "brgy_name": "Tagaytay",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208040",
      "brgy_name": "Tigkiw",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208041",
      "brgy_name": "Tiris",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208042",
      "brgy_name": "Togawe",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208043",
      "brgy_name": "Union",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056208044",
      "brgy_name": "Villareal",
      "city_code": "056208",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209001",
      "brgy_name": "Bagsangan",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209002",
      "brgy_name": "Bacolod (Pob.)",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209003",
      "brgy_name": "Batang",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209004",
      "brgy_name": "Bolos",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209005",
      "brgy_name": "Buenavista",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209006",
      "brgy_name": "Bulawan",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209007",
      "brgy_name": "Carriedo",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209008",
      "brgy_name": "Casini",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209009",
      "brgy_name": "Cawayan",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209010",
      "brgy_name": "Cogon",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209011",
      "brgy_name": "Gabao",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209012",
      "brgy_name": "Gulang-Gulang",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209013",
      "brgy_name": "Gumapia",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209014",
      "brgy_name": "Santo Domingo (Lamboon)",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209015",
      "brgy_name": "Liang",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209016",
      "brgy_name": "Macawayan",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209017",
      "brgy_name": "Mapaso",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209018",
      "brgy_name": "Monbon",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209019",
      "brgy_name": "Patag",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209020",
      "brgy_name": "Salvacion",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209021",
      "brgy_name": "San Agustin (Pob.)",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209022",
      "brgy_name": "San Isidro",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209023",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209024",
      "brgy_name": "San Julian (Pob.)",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209025",
      "brgy_name": "San Pedro",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209026",
      "brgy_name": "Tabon-Tabon",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209027",
      "brgy_name": "Tinampo",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056209028",
      "brgy_name": "Tongdol",
      "city_code": "056209",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210001",
      "brgy_name": "Anog",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210002",
      "brgy_name": "Aroroy",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210003",
      "brgy_name": "Bacolod",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210004",
      "brgy_name": "Binanuahan",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210005",
      "brgy_name": "Biriran",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210006",
      "brgy_name": "Buraburan",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210007",
      "brgy_name": "Calateo",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210008",
      "brgy_name": "Calmayon",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210009",
      "brgy_name": "Carohayon",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210010",
      "brgy_name": "Catanagan",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210011",
      "brgy_name": "Catanusan",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210012",
      "brgy_name": "Cogon",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210013",
      "brgy_name": "Embarcadero",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210014",
      "brgy_name": "Guruyan",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210016",
      "brgy_name": "Lajong",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210017",
      "brgy_name": "Maalo",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210019",
      "brgy_name": "North Poblacion",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210020",
      "brgy_name": "South Poblacion",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210021",
      "brgy_name": "Puting Sapa",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210022",
      "brgy_name": "Rangas",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210023",
      "brgy_name": "Sablayan",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210024",
      "brgy_name": "Sipaya",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210025",
      "brgy_name": "Taboc",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210026",
      "brgy_name": "Tinago",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056210027",
      "brgy_name": "Tughan",
      "city_code": "056210",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211001",
      "brgy_name": "Aguada Norte",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211002",
      "brgy_name": "Aguada Sur",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211003",
      "brgy_name": "Anibong",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211004",
      "brgy_name": "Bacalon",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211005",
      "brgy_name": "Bacolod",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211006",
      "brgy_name": "Banacud",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211007",
      "brgy_name": "Biga",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211008",
      "brgy_name": "Behia",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211009",
      "brgy_name": "Binisitahan del Norte",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211010",
      "brgy_name": "Binisitahan del Sur",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211011",
      "brgy_name": "Biton",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211012",
      "brgy_name": "Bulala",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211013",
      "brgy_name": "Busay",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211014",
      "brgy_name": "Caditaan",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211015",
      "brgy_name": "Cagbolo",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211016",
      "brgy_name": "Cagtalaba",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211017",
      "brgy_name": "Cawit Extension",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211018",
      "brgy_name": "Cawit Proper",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211019",
      "brgy_name": "Ginangra",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211020",
      "brgy_name": "Hubo",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211021",
      "brgy_name": "Incarizan",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211022",
      "brgy_name": "Lapinig",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211023",
      "brgy_name": "Magsaysay",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211024",
      "brgy_name": "Malbog",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211025",
      "brgy_name": "Pantalan",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211026",
      "brgy_name": "Pawik",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211027",
      "brgy_name": "Pili",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211028",
      "brgy_name": "Poblacion",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211029",
      "brgy_name": "Salvacion",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211030",
      "brgy_name": "Santa Elena",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211031",
      "brgy_name": "Siuton",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211032",
      "brgy_name": "Tagas",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211033",
      "brgy_name": "Tulatula Norte",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056211034",
      "brgy_name": "Tulatula Sur",
      "city_code": "056211",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212001",
      "brgy_name": "Balocawe",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212002",
      "brgy_name": "Banogao",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212003",
      "brgy_name": "Banuangdaan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212004",
      "brgy_name": "Bariis",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212005",
      "brgy_name": "Bolo",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212006",
      "brgy_name": "Bon-Ot Big",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212007",
      "brgy_name": "Bon-Ot Small",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212008",
      "brgy_name": "Cabagahan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212010",
      "brgy_name": "Calayuan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212011",
      "brgy_name": "Calintaan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212012",
      "brgy_name": "Caloocan (Pob.)",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212013",
      "brgy_name": "Calpi",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212014",
      "brgy_name": "Camachiles (Pob.)",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212015",
      "brgy_name": "Camcaman (Pob.)",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212016",
      "brgy_name": "Coron-coron",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212017",
      "brgy_name": "Culasi",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212018",
      "brgy_name": "Gadgaron",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212020",
      "brgy_name": "Genablan Occidental",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212021",
      "brgy_name": "Genablan Oriental",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212022",
      "brgy_name": "Hidhid",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212023",
      "brgy_name": "Laboy",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212025",
      "brgy_name": "Lajong",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212026",
      "brgy_name": "Mambajog",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212028",
      "brgy_name": "Manjunlad",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212029",
      "brgy_name": "Manurabi",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212030",
      "brgy_name": "Naburacan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212031",
      "brgy_name": "Paghuliran",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212032",
      "brgy_name": "Pangi",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212033",
      "brgy_name": "Pawa",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212034",
      "brgy_name": "Poropandan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212035",
      "brgy_name": "Santa Isabel",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212036",
      "brgy_name": "Sinalmacan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212037",
      "brgy_name": "Sinang-Atan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212038",
      "brgy_name": "Sinibaran",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212039",
      "brgy_name": "Sisigon",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212040",
      "brgy_name": "Sua",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212041",
      "brgy_name": "Sulangan",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212042",
      "brgy_name": "Tablac (Pob.)",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212043",
      "brgy_name": "Tabunan (Pob.)",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056212044",
      "brgy_name": "Tugas",
      "city_code": "056212",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213001",
      "brgy_name": "Abas",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213002",
      "brgy_name": "Abucay",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213003",
      "brgy_name": "Bantayan",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213004",
      "brgy_name": "Banuyo (Pob.)",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213005",
      "brgy_name": "Bayasong",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213006",
      "brgy_name": "Bayawas",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213007",
      "brgy_name": "Binanuahan (Pob.)",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213009",
      "brgy_name": "Cabiguan",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213010",
      "brgy_name": "Cagdongon",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213011",
      "brgy_name": "Calongay",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213012",
      "brgy_name": "Calpi",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213013",
      "brgy_name": "Catamlangan",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213014",
      "brgy_name": "Comapo-capo",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213015",
      "brgy_name": "Danlog",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213016",
      "brgy_name": "Dao (Pob.)",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213017",
      "brgy_name": "Dapdap",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213018",
      "brgy_name": "Del Rosario (Bual)",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213019",
      "brgy_name": "Esmerada",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213020",
      "brgy_name": "Esperanza",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213021",
      "brgy_name": "Guiron",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213022",
      "brgy_name": "Ginablan",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213023",
      "brgy_name": "Inang",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213024",
      "brgy_name": "Inapugan",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213025",
      "brgy_name": "Lubiano",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213026",
      "brgy_name": "Leona",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213027",
      "brgy_name": "Lipason",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213028",
      "brgy_name": "Lourdes",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213029",
      "brgy_name": "Lungib",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213030",
      "brgy_name": "Lumbang",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213031",
      "brgy_name": "Mabanate",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213032",
      "brgy_name": "Malbog",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213033",
      "brgy_name": "Marifosque (Pob.)",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213034",
      "brgy_name": "Mercedes",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213035",
      "brgy_name": "Migabod",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213036",
      "brgy_name": "Naspi",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213037",
      "brgy_name": "Palanas",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213038",
      "brgy_name": "Pangpang",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213039",
      "brgy_name": "Pinagsalog",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213040",
      "brgy_name": "Pineda",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213042",
      "brgy_name": "Poctol",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213043",
      "brgy_name": "Pudo",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213044",
      "brgy_name": "Putiao",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213045",
      "brgy_name": "Sacnangan",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213046",
      "brgy_name": "Salvacion",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213047",
      "brgy_name": "San Antonio (Millabas)",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213048",
      "brgy_name": "San Antonio (Sapa)",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213049",
      "brgy_name": "San Jose",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213050",
      "brgy_name": "San Rafael",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056213051",
      "brgy_name": "Santa Fe",
      "city_code": "056213",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214001",
      "brgy_name": "Brillante (Pob.)",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214002",
      "brgy_name": "Bulawan",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214003",
      "brgy_name": "Calao",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214004",
      "brgy_name": "Carayat",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214005",
      "brgy_name": "Diamante",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214006",
      "brgy_name": "Gogon",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214007",
      "brgy_name": "Lupi",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214008",
      "brgy_name": "Manlabong",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214010",
      "brgy_name": "Maningcay De Oro",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214011",
      "brgy_name": "Perlas",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214012",
      "brgy_name": "Quidolog",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214013",
      "brgy_name": "Rizal",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214014",
      "brgy_name": "San Antonio",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214015",
      "brgy_name": "San Fernando",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214016",
      "brgy_name": "San Isidro",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214017",
      "brgy_name": "San Juan",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214018",
      "brgy_name": "San Rafael",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214019",
      "brgy_name": "San Ramon",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214020",
      "brgy_name": "Santa Lourdes",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214021",
      "brgy_name": "Santo Domingo",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214022",
      "brgy_name": "Talisayan",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214023",
      "brgy_name": "Tupaz",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056214024",
      "brgy_name": "Ulag",
      "city_code": "056214",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215001",
      "brgy_name": "La Esperanza",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215002",
      "brgy_name": "Peñafrancia",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215003",
      "brgy_name": "Barangay Poblacion I",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215004",
      "brgy_name": "Barangay Poblacion II",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215005",
      "brgy_name": "Barangay Poblacion III",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215006",
      "brgy_name": "Barangay Poblacion IV",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215007",
      "brgy_name": "Salvacion",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215008",
      "brgy_name": "San Antonio",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215009",
      "brgy_name": "San Bartolome (Talaongan)",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215010",
      "brgy_name": "San Eugenio",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215011",
      "brgy_name": "San Isidro",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215012",
      "brgy_name": "San Rafael",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215013",
      "brgy_name": "San Roque",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056215014",
      "brgy_name": "San Sebastian",
      "city_code": "056215",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216001",
      "brgy_name": "Abuyog",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216002",
      "brgy_name": "Almendras-Cogon (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216003",
      "brgy_name": "Balogo (Sorsogon East District) ",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216004",
      "brgy_name": "Barayong",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216005",
      "brgy_name": "Basud",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216006",
      "brgy_name": "Bibincahan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216008",
      "brgy_name": "Bitan-o/Dalipay (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216009",
      "brgy_name": "Bucalbucalan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216010",
      "brgy_name": "Buenavista (Sorsogon West District)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216011",
      "brgy_name": "Buhatan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216012",
      "brgy_name": "Bulabog",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216013",
      "brgy_name": "Burabod (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216014",
      "brgy_name": "Cabid-An",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216015",
      "brgy_name": "Cambulaga",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216016",
      "brgy_name": "Capuy",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216017",
      "brgy_name": "Gimaloto",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216018",
      "brgy_name": "Guinlajon",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216019",
      "brgy_name": "Macabog",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216020",
      "brgy_name": "Marinas",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216021",
      "brgy_name": "Pamurayan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216022",
      "brgy_name": "Pangpang",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216023",
      "brgy_name": "Panlayaan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216024",
      "brgy_name": "Peñafrancia",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216025",
      "brgy_name": "Piot (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216026",
      "brgy_name": "Polvorista (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216027",
      "brgy_name": "Rizal",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216028",
      "brgy_name": "Salog (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216029",
      "brgy_name": "Salvacion (Sorsogon West District)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216030",
      "brgy_name": "Sampaloc (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216031",
      "brgy_name": "San Isidro (Sorsogon West District)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216032",
      "brgy_name": "San Juan (Roro)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216033",
      "brgy_name": "Sirangan (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216034",
      "brgy_name": "Sulucan (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216035",
      "brgy_name": "Talisay (Pob.)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216036",
      "brgy_name": "Ticol",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216037",
      "brgy_name": "Tugos",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216038",
      "brgy_name": "Balete",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216039",
      "brgy_name": "Balogo (Bacon District) ",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216040",
      "brgy_name": "Bato",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216041",
      "brgy_name": "Bon-Ot",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216042",
      "brgy_name": "Bogña",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216043",
      "brgy_name": "Buenavista (Bacon District)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216044",
      "brgy_name": "Cabarbuhan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216045",
      "brgy_name": "Caricaran",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216046",
      "brgy_name": "Del Rosario",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216047",
      "brgy_name": "Gatbo",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216048",
      "brgy_name": "Jamislagan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216049",
      "brgy_name": "Maricrum",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216050",
      "brgy_name": "Osiao",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216051",
      "brgy_name": "Poblacion",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216052",
      "brgy_name": "Rawis",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216053",
      "brgy_name": "Salvacion (Bacon District)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216054",
      "brgy_name": "San Isidro (Bacon District)",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216055",
      "brgy_name": "San Juan",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216056",
      "brgy_name": "San Pascual",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216057",
      "brgy_name": "San Ramon",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216058",
      "brgy_name": "San Roque",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216059",
      "brgy_name": "San Vicente",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216060",
      "brgy_name": "Santa Cruz",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216061",
      "brgy_name": "Santa Lucia",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216062",
      "brgy_name": "Santo Domingo",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216063",
      "brgy_name": "Santo Niño",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216064",
      "brgy_name": "Sawanga",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "056216065",
      "brgy_name": "Sugod",
      "city_code": "056216",
      "province_code": "0562",
      "region_code": "05"
    },
    {
      "brgy_code": "060401001",
      "brgy_name": "Cabangila",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401002",
      "brgy_name": "Cabugao",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401003",
      "brgy_name": "Catmon",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401004",
      "brgy_name": "Dalipdip",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401005",
      "brgy_name": "Ginictan",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401006",
      "brgy_name": "Linayasan",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401007",
      "brgy_name": "Lumaynay",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401008",
      "brgy_name": "Lupo",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401009",
      "brgy_name": "Man-up",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401010",
      "brgy_name": "Odiong",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401011",
      "brgy_name": "Poblacion",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401012",
      "brgy_name": "Quinasay-an",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401013",
      "brgy_name": "Talon",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060401014",
      "brgy_name": "Tibiao",
      "city_code": "060401",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402001",
      "brgy_name": "Aranas",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402002",
      "brgy_name": "Arcangel",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402004",
      "brgy_name": "Calizo",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402005",
      "brgy_name": "Cortes",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402006",
      "brgy_name": "Feliciano",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402007",
      "brgy_name": "Fulgencio",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402008",
      "brgy_name": "Guanko",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402009",
      "brgy_name": "Morales",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402010",
      "brgy_name": "Oquendo",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060402011",
      "brgy_name": "Poblacion",
      "city_code": "060402",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403001",
      "brgy_name": "Agbanawan",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403002",
      "brgy_name": "Bacan",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403003",
      "brgy_name": "Badiangan",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403004",
      "brgy_name": "Cerrudo",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403005",
      "brgy_name": "Cupang",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403006",
      "brgy_name": "Daguitan",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403007",
      "brgy_name": "Daja Norte",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403008",
      "brgy_name": "Daja Sur",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403009",
      "brgy_name": "Dingle",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403010",
      "brgy_name": "Jumarap",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403011",
      "brgy_name": "Lapnag",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403012",
      "brgy_name": "Libas",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403013",
      "brgy_name": "Linabuan Sur",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403014",
      "brgy_name": "Mambog",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403015",
      "brgy_name": "Mangan",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403016",
      "brgy_name": "Muguing",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403017",
      "brgy_name": "Pagsanghan",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403018",
      "brgy_name": "Palale",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403019",
      "brgy_name": "Poblacion",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403020",
      "brgy_name": "Polo",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403021",
      "brgy_name": "Polocate",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403022",
      "brgy_name": "San Isidro",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403023",
      "brgy_name": "Sibalew",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403024",
      "brgy_name": "Sigcay",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403025",
      "brgy_name": "Taba-ao",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403026",
      "brgy_name": "Tabayon",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403027",
      "brgy_name": "Tinapuay",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403028",
      "brgy_name": "Torralba",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403029",
      "brgy_name": "Ugsod",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060403030",
      "brgy_name": "Venturanza",
      "city_code": "060403",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404001",
      "brgy_name": "Ambolong",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404002",
      "brgy_name": "Angas",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404003",
      "brgy_name": "Bay-ang",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404004",
      "brgy_name": "Caiyang",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404005",
      "brgy_name": "Cabugao",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404006",
      "brgy_name": "Camaligan",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404007",
      "brgy_name": "Camanci",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404008",
      "brgy_name": "Ipil",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404009",
      "brgy_name": "Lalab",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404010",
      "brgy_name": "Lupit",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404011",
      "brgy_name": "Magpag-ong",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404012",
      "brgy_name": "Magubahay",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404013",
      "brgy_name": "Mambuquiao",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404014",
      "brgy_name": "Man-up",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404015",
      "brgy_name": "Mandong",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404016",
      "brgy_name": "Napti",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404017",
      "brgy_name": "Palay",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404018",
      "brgy_name": "Poblacion",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404019",
      "brgy_name": "Songcolan",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060404020",
      "brgy_name": "Tabon",
      "city_code": "060404",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405001",
      "brgy_name": "Alegria",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405002",
      "brgy_name": "Bagongbayan",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405003",
      "brgy_name": "Balusbos",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405004",
      "brgy_name": "Bel-is",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405005",
      "brgy_name": "Cabugan",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405006",
      "brgy_name": "El Progreso",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405007",
      "brgy_name": "Habana",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405008",
      "brgy_name": "Katipunan",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405009",
      "brgy_name": "Mayapay",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405010",
      "brgy_name": "Nazareth",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405011",
      "brgy_name": "Panilongan",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405012",
      "brgy_name": "Poblacion",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405013",
      "brgy_name": "Santander",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405014",
      "brgy_name": "Tag-osip",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060405015",
      "brgy_name": "Tigum",
      "city_code": "060405",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406001",
      "brgy_name": "Agbago",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406002",
      "brgy_name": "Agdugayan",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406003",
      "brgy_name": "Antipolo",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406004",
      "brgy_name": "Aparicio",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406005",
      "brgy_name": "Aquino",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406006",
      "brgy_name": "Aslum",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406007",
      "brgy_name": "Bagacay",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406008",
      "brgy_name": "Batuan",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406009",
      "brgy_name": "Buenavista",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406010",
      "brgy_name": "Bugtongbato",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406011",
      "brgy_name": "Cabugao",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406012",
      "brgy_name": "Capilijan",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406013",
      "brgy_name": "Colongcolong",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406014",
      "brgy_name": "Laguinbanua",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406015",
      "brgy_name": "Mabusao",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406016",
      "brgy_name": "Malindog",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406017",
      "brgy_name": "Maloco",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406018",
      "brgy_name": "Mina-a",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406019",
      "brgy_name": "Monlaque",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406020",
      "brgy_name": "Naile",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406021",
      "brgy_name": "Naisud",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406022",
      "brgy_name": "Naligusan",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406023",
      "brgy_name": "Ondoy",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406024",
      "brgy_name": " ",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406025",
      "brgy_name": "Polo",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406026",
      "brgy_name": "Regador",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406027",
      "brgy_name": "Rivera",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406028",
      "brgy_name": "Rizal",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406029",
      "brgy_name": "San Isidro",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406030",
      "brgy_name": "San Jose",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406031",
      "brgy_name": "Santa Cruz",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406032",
      "brgy_name": "Tagbaya",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406033",
      "brgy_name": "Tul-ang",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406034",
      "brgy_name": "Unat",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060406035",
      "brgy_name": "Yawan",
      "city_code": "060406",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407001",
      "brgy_name": "Andagaw",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407002",
      "brgy_name": "Bachaw Norte",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407003",
      "brgy_name": "Bachaw Sur",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407004",
      "brgy_name": "Briones",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407005",
      "brgy_name": "Buswang New",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407006",
      "brgy_name": "Buswang Old",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407007",
      "brgy_name": "Caano",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407008",
      "brgy_name": "Estancia",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407009",
      "brgy_name": "Linabuan Norte",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407010",
      "brgy_name": "Mabilo",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407011",
      "brgy_name": "Mobo",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407012",
      "brgy_name": "Nalook",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407013",
      "brgy_name": "Poblacion",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407014",
      "brgy_name": "Pook",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407015",
      "brgy_name": "Tigayon",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060407016",
      "brgy_name": "Tinigaw",
      "city_code": "060407",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408001",
      "brgy_name": "Agcawilan",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408002",
      "brgy_name": "Bagto",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408003",
      "brgy_name": "Bugasongan",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408004",
      "brgy_name": "Carugdog",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408005",
      "brgy_name": "Cogon",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408006",
      "brgy_name": "Ibao",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408007",
      "brgy_name": "Mina",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408008",
      "brgy_name": "Poblacion",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408009",
      "brgy_name": "Santa Cruz",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408010",
      "brgy_name": "Santa Cruz Bigaa",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408011",
      "brgy_name": "Silakat-Nonok",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060408012",
      "brgy_name": "Tayhawan",
      "city_code": "060408",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409001",
      "brgy_name": "Agmailig",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409002",
      "brgy_name": "Alfonso XII",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409003",
      "brgy_name": "Batobato",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409004",
      "brgy_name": "Bonza",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409005",
      "brgy_name": "Calacabian",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409006",
      "brgy_name": "Calamcan",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409007",
      "brgy_name": "Can-Awan",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409008",
      "brgy_name": "Casit-an",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409009",
      "brgy_name": "Dalagsa-an",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409010",
      "brgy_name": "Guadalupe",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409011",
      "brgy_name": "Janlud",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409012",
      "brgy_name": "Julita",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409013",
      "brgy_name": "Luctoga",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409014",
      "brgy_name": "Magugba",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409015",
      "brgy_name": "Manika",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409016",
      "brgy_name": "Ogsip",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409017",
      "brgy_name": "Ortega",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409018",
      "brgy_name": "Oyang",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409019",
      "brgy_name": "Pampango",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409020",
      "brgy_name": "Pinonoy",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409021",
      "brgy_name": "Poblacion",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409022",
      "brgy_name": "Rivera",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409023",
      "brgy_name": "Rosal",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060409024",
      "brgy_name": "Sibalew",
      "city_code": "060409",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410001",
      "brgy_name": "Alaminos",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410002",
      "brgy_name": "Alas-as",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410003",
      "brgy_name": "Bacyang",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410004",
      "brgy_name": "Balactasan",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410005",
      "brgy_name": "Cabangahan",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410006",
      "brgy_name": "Cabilawan",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410007",
      "brgy_name": "Catabana",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410008",
      "brgy_name": "Dit-Ana",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410009",
      "brgy_name": "Galicia",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410010",
      "brgy_name": "Guinatu-an",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410011",
      "brgy_name": "Logohon",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410012",
      "brgy_name": "Mamba",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410013",
      "brgy_name": "Maria Cristina",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410014",
      "brgy_name": "Medina",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410015",
      "brgy_name": "Mercedes",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410016",
      "brgy_name": "Napnot",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410017",
      "brgy_name": "Pang-Itan",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410018",
      "brgy_name": "Paningayan",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410019",
      "brgy_name": "Panipiason",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410020",
      "brgy_name": "Poblacion",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410021",
      "brgy_name": "San Jose",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410022",
      "brgy_name": "Singay",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410023",
      "brgy_name": "Talangban",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410024",
      "brgy_name": "Talimagao",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060410025",
      "brgy_name": "Tigbawan",
      "city_code": "060410",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411001",
      "brgy_name": "Agbalogo",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411002",
      "brgy_name": "Aglucay",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411003",
      "brgy_name": "Alibagon",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411004",
      "brgy_name": "Bagong Barrio",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411005",
      "brgy_name": "Baybay",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411006",
      "brgy_name": "Cabatanga",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411007",
      "brgy_name": "Cajilo",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411008",
      "brgy_name": "Calangcang",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411009",
      "brgy_name": "Calimbajan",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411010",
      "brgy_name": "Castillo",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411011",
      "brgy_name": "Cayangwan",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411012",
      "brgy_name": "Dumga",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411013",
      "brgy_name": "Libang",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411014",
      "brgy_name": "Mantiguib",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411015",
      "brgy_name": "Poblacion",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411016",
      "brgy_name": "Tibiawan",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411017",
      "brgy_name": "Tina",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060411018",
      "brgy_name": "Tugas",
      "city_code": "060411",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412001",
      "brgy_name": "Argao",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412002",
      "brgy_name": "Balabag",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412003",
      "brgy_name": "Balusbus",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412004",
      "brgy_name": "Cabulihan",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412005",
      "brgy_name": "Caticlan",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412006",
      "brgy_name": "Cogon",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412007",
      "brgy_name": "Cubay Norte",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412008",
      "brgy_name": "Cubay Sur",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412009",
      "brgy_name": "Dumlog",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412010",
      "brgy_name": "Manoc-Manoc",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412011",
      "brgy_name": "Naasug",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412012",
      "brgy_name": "Nabaoy",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412013",
      "brgy_name": "Napaan",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412014",
      "brgy_name": "Poblacion",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412015",
      "brgy_name": "San Viray",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412016",
      "brgy_name": "Yapak",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060412017",
      "brgy_name": "Motag",
      "city_code": "060412",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413001",
      "brgy_name": "Banaybanay",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413002",
      "brgy_name": "Biga-a",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413003",
      "brgy_name": "Bulabud",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413004",
      "brgy_name": "Cabayugan",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413005",
      "brgy_name": "Capataga",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413006",
      "brgy_name": "Cogon",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413007",
      "brgy_name": "Dangcalan",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413008",
      "brgy_name": "Kinalangay Nuevo",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413009",
      "brgy_name": "Kinalangay Viejo",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413010",
      "brgy_name": "Lilo-an",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413011",
      "brgy_name": "Malandayon",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413012",
      "brgy_name": "Manhanip",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413013",
      "brgy_name": "Navitas",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413014",
      "brgy_name": "Osman",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413015",
      "brgy_name": "Poblacion",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413016",
      "brgy_name": "Rosario",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413017",
      "brgy_name": "San Dimas",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413018",
      "brgy_name": "San Ramon",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413019",
      "brgy_name": "San Roque",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413020",
      "brgy_name": "Sipac",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413021",
      "brgy_name": "Sugnod",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413022",
      "brgy_name": "Tambuan",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060413023",
      "brgy_name": "Tigpalas",
      "city_code": "060413",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414001",
      "brgy_name": "Alimbo-Baybay",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414002",
      "brgy_name": "Buenasuerte",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414003",
      "brgy_name": "Buenafortuna",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414004",
      "brgy_name": "Buenavista",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414005",
      "brgy_name": "Gibon",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414006",
      "brgy_name": "Habana",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414007",
      "brgy_name": "Laserna",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414008",
      "brgy_name": "Libertad",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414009",
      "brgy_name": "Magallanes",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414010",
      "brgy_name": "Matabana",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414012",
      "brgy_name": "Nagustan",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414013",
      "brgy_name": "Pawa",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414014",
      "brgy_name": "Pinatuad",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414015",
      "brgy_name": "Poblacion",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414016",
      "brgy_name": "Rizal",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414017",
      "brgy_name": "Solido",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414018",
      "brgy_name": "Tagororoc",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414019",
      "brgy_name": "Toledo",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414020",
      "brgy_name": "Unidos",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060414021",
      "brgy_name": "Union",
      "city_code": "060414",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415001",
      "brgy_name": "Candelaria",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415002",
      "brgy_name": "Cawayan",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415003",
      "brgy_name": "Dumaguit",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415004",
      "brgy_name": "Fatima",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415005",
      "brgy_name": "Guinbaliwan",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415006",
      "brgy_name": "Jalas",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415007",
      "brgy_name": "Jugas",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415008",
      "brgy_name": "Lawa-an",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415009",
      "brgy_name": "Mabilo",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415010",
      "brgy_name": "Mataphao",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415011",
      "brgy_name": "Ochando",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415012",
      "brgy_name": "Pinamuk-an",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415013",
      "brgy_name": "Poblacion",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415014",
      "brgy_name": "Polo",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415015",
      "brgy_name": "Puis",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060415017",
      "brgy_name": "Tambak",
      "city_code": "060415",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416001",
      "brgy_name": "Albasan",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416002",
      "brgy_name": "Aliputos",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416003",
      "brgy_name": "Badio",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416004",
      "brgy_name": "Bubog",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416006",
      "brgy_name": "Bulwang",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416007",
      "brgy_name": "Camanci Norte",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416008",
      "brgy_name": "Camanci Sur",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416009",
      "brgy_name": "Dongon East",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416010",
      "brgy_name": "Dongon West",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416011",
      "brgy_name": "Joyao-joyao",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416012",
      "brgy_name": "Laguinbanua East",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416013",
      "brgy_name": "Laguinbanua West",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416014",
      "brgy_name": "Marianos",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416015",
      "brgy_name": "Navitas",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416016",
      "brgy_name": "Poblacion",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416017",
      "brgy_name": "Pusiw",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060416018",
      "brgy_name": "Tabangka",
      "city_code": "060416",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417001",
      "brgy_name": "Afga",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417002",
      "brgy_name": "Baybay",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417003",
      "brgy_name": "Dapdap",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417004",
      "brgy_name": "Dumatad",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417005",
      "brgy_name": "Jawili",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417006",
      "brgy_name": "Lanipga",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417007",
      "brgy_name": "Napatag",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417008",
      "brgy_name": "Panayakan",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417009",
      "brgy_name": "Poblacion",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417010",
      "brgy_name": "Pudiot",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417011",
      "brgy_name": "Tagas",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417012",
      "brgy_name": "Tamalagon",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417013",
      "brgy_name": "Tamokoe",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417014",
      "brgy_name": "Tondog",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060417015",
      "brgy_name": "Vivo",
      "city_code": "060417",
      "province_code": "0604",
      "region_code": "06"
    },
    {
      "brgy_code": "060601001",
      "brgy_name": "Bayo Grande",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601002",
      "brgy_name": "Bayo Pequeño",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601004",
      "brgy_name": "Butuan",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601005",
      "brgy_name": "Casay",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601006",
      "brgy_name": "Casay Viejo",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601007",
      "brgy_name": "Iba",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601008",
      "brgy_name": "Igbarabatuan",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601009",
      "brgy_name": "Igpalge",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601010",
      "brgy_name": "Igtumarom",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601011",
      "brgy_name": "Lisub A",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601012",
      "brgy_name": "Lisub B",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601013",
      "brgy_name": "Mabuyong",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601014",
      "brgy_name": "Magdalena",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601015",
      "brgy_name": "Nasuli C",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601016",
      "brgy_name": "Nato",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601017",
      "brgy_name": "Poblacion",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601018",
      "brgy_name": "Sagua",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601019",
      "brgy_name": "Salvacion",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601020",
      "brgy_name": "San Francisco",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601021",
      "brgy_name": "San Ramon",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601022",
      "brgy_name": "San Roque",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601023",
      "brgy_name": "Tagaytay",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060601024",
      "brgy_name": "Talisayan",
      "city_code": "060601",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602002",
      "brgy_name": "Baghari",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602003",
      "brgy_name": "Bahuyan",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602004",
      "brgy_name": "Beri",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602005",
      "brgy_name": "Biga-a",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602006",
      "brgy_name": "Binangbang",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602007",
      "brgy_name": "Binangbang Centro",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602008",
      "brgy_name": "Binanu-an",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602009",
      "brgy_name": "Cadiao",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602010",
      "brgy_name": "Calapadan",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602011",
      "brgy_name": "Capoyuan",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602012",
      "brgy_name": "Cubay",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602013",
      "brgy_name": "Esparar",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602014",
      "brgy_name": "Gua",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602015",
      "brgy_name": "Idao",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602016",
      "brgy_name": "Igpalge",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602017",
      "brgy_name": "Igtunarum",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602018",
      "brgy_name": "Embrangga-an",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602019",
      "brgy_name": "Integasan",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602020",
      "brgy_name": "Ipil",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602021",
      "brgy_name": "Jinalinan",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602022",
      "brgy_name": "Lanas",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602023",
      "brgy_name": "Langcaon (Evelio Javier)",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602024",
      "brgy_name": "Lisub",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602025",
      "brgy_name": "Lombuyan",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602026",
      "brgy_name": "Mablad",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602027",
      "brgy_name": "Magtulis",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602028",
      "brgy_name": "Marigne",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602029",
      "brgy_name": "Mayabay",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602030",
      "brgy_name": "Mayos",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602031",
      "brgy_name": "Nalusdan",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602032",
      "brgy_name": "Narirong",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602033",
      "brgy_name": "Palma",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602034",
      "brgy_name": "Poblacion",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602035",
      "brgy_name": "San Antonio",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602036",
      "brgy_name": "San Ramon",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602037",
      "brgy_name": "Soligao",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602038",
      "brgy_name": "Tabongtabong",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602039",
      "brgy_name": "Tig-Alaran",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060602040",
      "brgy_name": "Yapo",
      "city_code": "060602",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603001",
      "brgy_name": "Borocboroc",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603002",
      "brgy_name": "Buenavista",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603003",
      "brgy_name": "Concepcion",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603004",
      "brgy_name": "Delima",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603005",
      "brgy_name": "Ipil",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603006",
      "brgy_name": "Maradiona",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603007",
      "brgy_name": "Mojon",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603008",
      "brgy_name": "Poblacion",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603009",
      "brgy_name": "Rombang",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603010",
      "brgy_name": "Salvacion",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060603011",
      "brgy_name": "Sinaja",
      "city_code": "060603",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604001",
      "brgy_name": "Anilawan",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604002",
      "brgy_name": "Arangote",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604003",
      "brgy_name": "Bagtason",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604004",
      "brgy_name": "Camangahan",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604005",
      "brgy_name": "Cubay North",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604006",
      "brgy_name": "Cubay South",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604009",
      "brgy_name": "Guija",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604010",
      "brgy_name": "Igbalangao",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604011",
      "brgy_name": "Igsoro",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604012",
      "brgy_name": "Ilaures",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604013",
      "brgy_name": "Jinalinan",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604014",
      "brgy_name": "Lacayon",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604015",
      "brgy_name": "Maray",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604016",
      "brgy_name": "Paliwan",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604017",
      "brgy_name": "Pangalcagan",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604018",
      "brgy_name": "Centro Ilawod (Pob.)",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604019",
      "brgy_name": "Centro Ilaya (Pob.)",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604020",
      "brgy_name": "Centro Pojo (Pob.)",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604021",
      "brgy_name": "Sabang East",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604022",
      "brgy_name": "Sabang West",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604023",
      "brgy_name": "Tagudtud North",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604024",
      "brgy_name": "Tagudtud South",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604025",
      "brgy_name": "Talisay",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604026",
      "brgy_name": "Tica",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604027",
      "brgy_name": "Tono-an",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604028",
      "brgy_name": "Yapu",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060604029",
      "brgy_name": "Zaragoza",
      "city_code": "060604",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605001",
      "brgy_name": "Alegria",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605002",
      "brgy_name": "Bacong",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605003",
      "brgy_name": "Banago",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605004",
      "brgy_name": "Bonbon",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605005",
      "brgy_name": "Dawis",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605006",
      "brgy_name": "Dionela",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605007",
      "brgy_name": "Harigue",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605008",
      "brgy_name": "Hininga-an",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605009",
      "brgy_name": "Imba",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605010",
      "brgy_name": "Masanag",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605011",
      "brgy_name": "Poblacion",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605012",
      "brgy_name": "Sabang",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605013",
      "brgy_name": "Salamento",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605014",
      "brgy_name": "Semirara",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605015",
      "brgy_name": "Sibato",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605016",
      "brgy_name": "Sibay",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605017",
      "brgy_name": "Sibolo",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060605018",
      "brgy_name": "Tinogboc",
      "city_code": "060605",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606001",
      "brgy_name": "Alojipan",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606002",
      "brgy_name": "Bagacay",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606003",
      "brgy_name": "Balac-balac",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606004",
      "brgy_name": "Magsaysay (Balua)",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606005",
      "brgy_name": "Batbatan Island",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606007",
      "brgy_name": "Batonan Norte",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606008",
      "brgy_name": "Batonan Sur",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606009",
      "brgy_name": "Bita",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606010",
      "brgy_name": "Bitadton Norte",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606011",
      "brgy_name": "Bitadton Sur",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606012",
      "brgy_name": "Buenavista",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606013",
      "brgy_name": "Buhi",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606014",
      "brgy_name": "Camancijan",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606015",
      "brgy_name": "Caridad",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606016",
      "brgy_name": "Carit-an",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606017",
      "brgy_name": "Condes",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606018",
      "brgy_name": "Esperanza",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606019",
      "brgy_name": "Fe",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606020",
      "brgy_name": "Flores",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606021",
      "brgy_name": "Jalandoni",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606022",
      "brgy_name": "Janlagasi",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606023",
      "brgy_name": "Lamputong",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606024",
      "brgy_name": "Lipata",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606025",
      "brgy_name": "Malacañang",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606026",
      "brgy_name": "Malalison Island",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606027",
      "brgy_name": "Maniguin",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606028",
      "brgy_name": "Naba",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606029",
      "brgy_name": "Osorio",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606030",
      "brgy_name": "Paningayan",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606031",
      "brgy_name": "Centro Poblacion",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606032",
      "brgy_name": "Centro Norte (Pob.)",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606033",
      "brgy_name": "Centro Sur (Pob.)",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606034",
      "brgy_name": "Salde",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606036",
      "brgy_name": "San Antonio",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606037",
      "brgy_name": "San Gregorio",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606038",
      "brgy_name": "San Juan",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606039",
      "brgy_name": "San Luis",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606040",
      "brgy_name": "San Pascual",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606041",
      "brgy_name": "San Vicente",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606042",
      "brgy_name": "Simbola",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606043",
      "brgy_name": "Tigbobolo",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606044",
      "brgy_name": "Tinabusan",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606045",
      "brgy_name": "Tomao",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060606046",
      "brgy_name": "Valderama",
      "city_code": "060606",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607001",
      "brgy_name": "Abaca",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607002",
      "brgy_name": "Aras-Asan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607003",
      "brgy_name": "Arobo",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607004",
      "brgy_name": "Atabay",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607005",
      "brgy_name": "Atiotes",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607006",
      "brgy_name": "Bagumbayan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607007",
      "brgy_name": "Balloscas",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607008",
      "brgy_name": "Balud",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607009",
      "brgy_name": "Barasanan A",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607010",
      "brgy_name": "Barasanan B",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607011",
      "brgy_name": "Barasanan C",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607012",
      "brgy_name": "Bariri",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607013",
      "brgy_name": "Camandagan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607014",
      "brgy_name": "Cato-ogan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607015",
      "brgy_name": "Danawan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607016",
      "brgy_name": "Diclum",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607017",
      "brgy_name": "Fatima",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607018",
      "brgy_name": "Gamad (Igdamacio)",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607019",
      "brgy_name": "Igbalogo",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607020",
      "brgy_name": "Igbangcal-A",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607021",
      "brgy_name": "Igbangcal-B",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607022",
      "brgy_name": "Igbangcal-C",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607023",
      "brgy_name": "Igcabuad",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607024",
      "brgy_name": "Igcado",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607025",
      "brgy_name": "Igcalawagan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607026",
      "brgy_name": "Igcapuyas",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607027",
      "brgy_name": "Igcasicad (San Pedro)",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607028",
      "brgy_name": "Igdalaguit",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607029",
      "brgy_name": "Igdanlog",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607030",
      "brgy_name": "Igdurarog",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607031",
      "brgy_name": "Igtugas",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607032",
      "brgy_name": "Lawigan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607033",
      "brgy_name": "Manaling (Cata-an)",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607034",
      "brgy_name": "Masayo",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607035",
      "brgy_name": "Nagsubuan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607036",
      "brgy_name": "Paciencia",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607037",
      "brgy_name": "Poblacion Norte",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607038",
      "brgy_name": "Poblacion Sur",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607039",
      "brgy_name": "Portillo",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607040",
      "brgy_name": "Quezon",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607041",
      "brgy_name": "Opsan (San Jose)",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607042",
      "brgy_name": "Nasuli-A (San Ramon)",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607043",
      "brgy_name": "Salamague (Santa Maria)",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607044",
      "brgy_name": "Santo Tomas",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607045",
      "brgy_name": "Tacbuyan",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607046",
      "brgy_name": "Tene",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607047",
      "brgy_name": "Villaflor",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607048",
      "brgy_name": "Ysulat",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607049",
      "brgy_name": "Igcadac",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060607050",
      "brgy_name": "Lindero",
      "city_code": "060607",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608001",
      "brgy_name": "Apdo",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608002",
      "brgy_name": "Asluman",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608003",
      "brgy_name": "Banawon",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608005",
      "brgy_name": "Bia-an",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608006",
      "brgy_name": "Bongbongan I-II",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608008",
      "brgy_name": "Bongbongan III",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608009",
      "brgy_name": "Botbot",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608010",
      "brgy_name": "Budbudan",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608011",
      "brgy_name": "Buhang",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608012",
      "brgy_name": "Calacja I",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608013",
      "brgy_name": "Calacja II",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608014",
      "brgy_name": "Calala",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608015",
      "brgy_name": "Cantulan",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608016",
      "brgy_name": "Caridad",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608017",
      "brgy_name": "Caromangay",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608018",
      "brgy_name": "Casalngan",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608019",
      "brgy_name": "Dangcalan",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608020",
      "brgy_name": "Del Pilar",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608021",
      "brgy_name": "Fabrica",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608022",
      "brgy_name": "Funda",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608023",
      "brgy_name": "General Fullon (Tina)",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608024",
      "brgy_name": "Guintas",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608025",
      "brgy_name": "Igbical",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608026",
      "brgy_name": "Igbucagay",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608027",
      "brgy_name": "Inabasan",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608028",
      "brgy_name": "Ingwan-Batangan",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608029",
      "brgy_name": "La Paz",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608030",
      "brgy_name": "Gov. Evelio B. Javier (Lanag)",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608031",
      "brgy_name": "Linaban",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608033",
      "brgy_name": "Malandog",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608034",
      "brgy_name": "Mapatag",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608035",
      "brgy_name": "Masanag",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608036",
      "brgy_name": "Nalihawan",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608037",
      "brgy_name": "Pamandayan (Botbot)",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608038",
      "brgy_name": "Pasu-Jungao",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608039",
      "brgy_name": "Piape I",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608040",
      "brgy_name": "Piape II",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608041",
      "brgy_name": "Piape III",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608042",
      "brgy_name": "Pili 1, 2, 3",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608045",
      "brgy_name": "Poblacion 1",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608046",
      "brgy_name": "Poblacion 2",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608047",
      "brgy_name": "Poblacion 3",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608048",
      "brgy_name": "Poblacion 4",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608049",
      "brgy_name": "Poblacion 5",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608050",
      "brgy_name": "Pu-ao",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608051",
      "brgy_name": "Suloc",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060608053",
      "brgy_name": "Villavert-Jimenez",
      "city_code": "060608",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609002",
      "brgy_name": "Banban",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609003",
      "brgy_name": "Bongbongan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609004",
      "brgy_name": "Cabariwan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609005",
      "brgy_name": "Cadajug",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609006",
      "brgy_name": "Canituan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609007",
      "brgy_name": "Capnayan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609008",
      "brgy_name": "Casit-an",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609009",
      "brgy_name": "Guinbanga-an",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609010",
      "brgy_name": "Guiamon",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609011",
      "brgy_name": "Guisijan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609012",
      "brgy_name": "Igtadiao",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609013",
      "brgy_name": "Intao",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609014",
      "brgy_name": "Jaguikican",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609015",
      "brgy_name": "Jinalinan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609016",
      "brgy_name": "Lactudan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609017",
      "brgy_name": "Latazon",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609018",
      "brgy_name": "Laua-an",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609019",
      "brgy_name": "Loon",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609020",
      "brgy_name": "Liberato",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609021",
      "brgy_name": "Lindero",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609022",
      "brgy_name": "Liya-liya",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609023",
      "brgy_name": "Lugta",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609024",
      "brgy_name": "Lupa-an",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609025",
      "brgy_name": "Magyapo",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609026",
      "brgy_name": "Maria",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609027",
      "brgy_name": "Mauno",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609028",
      "brgy_name": "Maybunga",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609029",
      "brgy_name": "Necesito (Paniatan)",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609030",
      "brgy_name": "Oloc",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609031",
      "brgy_name": "Omlot",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609032",
      "brgy_name": "Pandanan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609034",
      "brgy_name": "Paningayan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609035",
      "brgy_name": "Pascuala",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609036",
      "brgy_name": "Poblacion (Centro)",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609038",
      "brgy_name": "San Ramon",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609039",
      "brgy_name": "Santiago",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609040",
      "brgy_name": "Tibacan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609041",
      "brgy_name": "Tigunhao",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609042",
      "brgy_name": "Virginia",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060609043",
      "brgy_name": "Bagongbayan",
      "city_code": "060609",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610001",
      "brgy_name": "Barusbus",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610002",
      "brgy_name": "Bulanao",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610003",
      "brgy_name": "Cubay",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610004",
      "brgy_name": "Codiong",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610005",
      "brgy_name": "Igcagay",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610006",
      "brgy_name": "Inyawan",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610007",
      "brgy_name": "Lindero",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610008",
      "brgy_name": "Maramig",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610009",
      "brgy_name": "Pucio",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610010",
      "brgy_name": "Pajo",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610011",
      "brgy_name": "Panangkilon",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610012",
      "brgy_name": "Paz",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610013",
      "brgy_name": "Centro Este (Pob.)",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610014",
      "brgy_name": "Centro Weste (Pob.)",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610015",
      "brgy_name": "San Roque",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610016",
      "brgy_name": "Tinigbas",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610017",
      "brgy_name": "Tinindugan",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610018",
      "brgy_name": "Taboc",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060610019",
      "brgy_name": "Union",
      "city_code": "060610",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611001",
      "brgy_name": "Aracay",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611002",
      "brgy_name": "Badiangan",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611003",
      "brgy_name": "Bagumbayan",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611004",
      "brgy_name": "Baybay",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611005",
      "brgy_name": "Botbot",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611007",
      "brgy_name": "Buang",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611008",
      "brgy_name": "Cabugao",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611009",
      "brgy_name": "Candari",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611010",
      "brgy_name": "Carmen",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611011",
      "brgy_name": "Centro Sur (Pob.)",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611012",
      "brgy_name": "Dionela",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611013",
      "brgy_name": "Dumrog",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611014",
      "brgy_name": "Duyong",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611015",
      "brgy_name": "Fragante",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611016",
      "brgy_name": "Guia",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611017",
      "brgy_name": "Idiacacan",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611018",
      "brgy_name": "Jinalinan",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611019",
      "brgy_name": "Luhod-Bayang",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611020",
      "brgy_name": "Maadios",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611021",
      "brgy_name": "Mag-aba",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611022",
      "brgy_name": "Napuid",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611023",
      "brgy_name": "Nauring",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611024",
      "brgy_name": "Patria",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611025",
      "brgy_name": "Perfecta",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611026",
      "brgy_name": "Centro Norte (Pob.)",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611027",
      "brgy_name": "San Andres",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611028",
      "brgy_name": "San Joaquin",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611029",
      "brgy_name": "Santa Ana",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611030",
      "brgy_name": "Santa Cruz",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611031",
      "brgy_name": "Santa Fe",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611032",
      "brgy_name": "Santo Rosario",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611033",
      "brgy_name": "Talisay",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611034",
      "brgy_name": "Tingib",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060611035",
      "brgy_name": "Zaldivar",
      "city_code": "060611",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612001",
      "brgy_name": "Alvañiz",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612002",
      "brgy_name": "Amparo",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612003",
      "brgy_name": "Apgahan",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612004",
      "brgy_name": "Aureliana",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612005",
      "brgy_name": "Badiangan",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612006",
      "brgy_name": "Bernaldo A. Julagting (Bitas)",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612007",
      "brgy_name": "Carit-an",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612008",
      "brgy_name": "Cuyapiao",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612009",
      "brgy_name": "Villa Elio",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612010",
      "brgy_name": "Gella",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612011",
      "brgy_name": "Igbarawan",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612012",
      "brgy_name": "Igbobon",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612013",
      "brgy_name": "Igburi",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612014",
      "brgy_name": "La Rioja",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612015",
      "brgy_name": "Mabasa",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612016",
      "brgy_name": "Macarina",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612017",
      "brgy_name": "Magarang",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612018",
      "brgy_name": "Magsaysay",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612019",
      "brgy_name": "Padang",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612020",
      "brgy_name": "Pandanan",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612021",
      "brgy_name": "Patlabawon",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612022",
      "brgy_name": "Poblacion",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612023",
      "brgy_name": "Quezon",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612024",
      "brgy_name": "Salaguiawan",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612025",
      "brgy_name": "Samalague",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612026",
      "brgy_name": "San Rafael",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612027",
      "brgy_name": "Tobias Fornier",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612028",
      "brgy_name": "Tamayoc",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612029",
      "brgy_name": "Tigbalogo",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612030",
      "brgy_name": "Villa Crespo",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612031",
      "brgy_name": "Villa Cruz",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612032",
      "brgy_name": "Villa Flores",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612033",
      "brgy_name": "Villa Laua-an",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612034",
      "brgy_name": "Villa Sal",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612035",
      "brgy_name": "Villa Salomon",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060612036",
      "brgy_name": "Vista Alegre",
      "city_code": "060612",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613001",
      "brgy_name": "Atabay",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613002",
      "brgy_name": "Badiang",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613003",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613004",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613005",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613006",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613007",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613008",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613009",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613010",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613011",
      "brgy_name": "Bariri",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613014",
      "brgy_name": "Bugarot (Catungan-Bugarot)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613015",
      "brgy_name": "Cansadan (Cansadan-Tubudan)",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613016",
      "brgy_name": "Durog",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613017",
      "brgy_name": "Funda-Dalipe",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613018",
      "brgy_name": "Igbonglo",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613019",
      "brgy_name": "Inabasan",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613020",
      "brgy_name": "Madrangca",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613021",
      "brgy_name": "Magcalon",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613022",
      "brgy_name": "Malaiba",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613023",
      "brgy_name": "Maybato Norte",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613024",
      "brgy_name": "Maybato Sur",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613025",
      "brgy_name": "Mojon",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613026",
      "brgy_name": "Pantao",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613027",
      "brgy_name": "San Angel",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613028",
      "brgy_name": "San Fernando",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613029",
      "brgy_name": "San Pedro",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060613030",
      "brgy_name": "Supa",
      "city_code": "060613",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614001",
      "brgy_name": "Agricula",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614002",
      "brgy_name": "Alegria",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614003",
      "brgy_name": "Aningalan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614004",
      "brgy_name": "Atabay",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614005",
      "brgy_name": "Bagumbayan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614006",
      "brgy_name": "Baladjay",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614007",
      "brgy_name": "Banbanan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614008",
      "brgy_name": "Barangbang",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614009",
      "brgy_name": "Bawang",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614010",
      "brgy_name": "Bugo",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614011",
      "brgy_name": "Bulan-bulan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614012",
      "brgy_name": "Cabiawan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614013",
      "brgy_name": "Cabunga-an",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614014",
      "brgy_name": "Cadolonan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614015",
      "brgy_name": "Poblacion (Calag-itan)",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614016",
      "brgy_name": "Carawisan I",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614017",
      "brgy_name": "Carawisan II",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614018",
      "brgy_name": "Carmelo I",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614019",
      "brgy_name": "Carmelo II",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614020",
      "brgy_name": "General Fullon",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614021",
      "brgy_name": "General Luna",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614022",
      "brgy_name": "Orquia (Igcatumbal)",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614023",
      "brgy_name": "Iguirindon",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614024",
      "brgy_name": "Insubuan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614025",
      "brgy_name": "La Union",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614026",
      "brgy_name": "Lapak",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614027",
      "brgy_name": "Lumpatan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614028",
      "brgy_name": "Magdalena",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614029",
      "brgy_name": "Maragubdub",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614030",
      "brgy_name": "Nagbangi I (Amatong)",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614031",
      "brgy_name": "Nagbangi II",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614033",
      "brgy_name": "Nasuli",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614034",
      "brgy_name": "Osorio I",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614035",
      "brgy_name": "Osorio II",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614036",
      "brgy_name": "Panpanan I",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614037",
      "brgy_name": "Panpanan II",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614039",
      "brgy_name": "Ramon Magsaysay",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614040",
      "brgy_name": "Rizal",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614041",
      "brgy_name": "San Rafael",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614042",
      "brgy_name": "Sinundolan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614043",
      "brgy_name": "Sumaray",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614044",
      "brgy_name": "Trinidad",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614045",
      "brgy_name": "Tubudan",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614046",
      "brgy_name": "Vilvar",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060614047",
      "brgy_name": "Walker",
      "city_code": "060614",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615001",
      "brgy_name": "Abiera",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615002",
      "brgy_name": "Aguila",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615003",
      "brgy_name": "Alegre",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615004",
      "brgy_name": "Aras-Asan",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615005",
      "brgy_name": "Bacalan",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615006",
      "brgy_name": "Callan",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615007",
      "brgy_name": "Nauhon",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615008",
      "brgy_name": "P. Javier",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615009",
      "brgy_name": "Poblacion",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060615010",
      "brgy_name": "Idio",
      "city_code": "060615",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616002",
      "brgy_name": "Alangan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616003",
      "brgy_name": "Valentin Grasparil (Bad-as)",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616004",
      "brgy_name": "Bari",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616005",
      "brgy_name": "Biga-a",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616006",
      "brgy_name": "Bongbongan I",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616007",
      "brgy_name": "Bongbongan II",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616008",
      "brgy_name": "Bongsod",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616009",
      "brgy_name": "Bontol",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616010",
      "brgy_name": "Bugnay",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616011",
      "brgy_name": "Bulalacao",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616012",
      "brgy_name": "Cabanbanan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616013",
      "brgy_name": "Cabariuan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616014",
      "brgy_name": "Cabladan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616015",
      "brgy_name": "Cadoldolan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616016",
      "brgy_name": "Calo-oy",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616017",
      "brgy_name": "Calog",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616018",
      "brgy_name": "Catmon",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616019",
      "brgy_name": "Catungan I",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616020",
      "brgy_name": "Catungan II",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616021",
      "brgy_name": "Catungan III",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616022",
      "brgy_name": "Catungan IV",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616023",
      "brgy_name": "Cubay-Sermon",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616024",
      "brgy_name": "Egaña",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616025",
      "brgy_name": "Esperanza I",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616026",
      "brgy_name": "Esperanza II",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616027",
      "brgy_name": "Esperanza III",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616028",
      "brgy_name": "Igcococ",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616029",
      "brgy_name": "Igdalaquit",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616030",
      "brgy_name": "Igdagmay",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616031",
      "brgy_name": "Iglanot",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616032",
      "brgy_name": "Igpanolong",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616033",
      "brgy_name": "Igparas",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616034",
      "brgy_name": "Igsuming",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616035",
      "brgy_name": "Ilabas",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616036",
      "brgy_name": "Imparayan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616037",
      "brgy_name": "Inabasan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616038",
      "brgy_name": "Indag-an",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616039",
      "brgy_name": "Initan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616040",
      "brgy_name": "Insarayan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616041",
      "brgy_name": "Lacaron",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616042",
      "brgy_name": "Lagdo",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616043",
      "brgy_name": "Lambayagan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616044",
      "brgy_name": "Luna",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616045",
      "brgy_name": "Luyang",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616046",
      "brgy_name": "Maasin",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616047",
      "brgy_name": "Mabini",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616048",
      "brgy_name": "Millamena",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616049",
      "brgy_name": "Mojon",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616050",
      "brgy_name": "Nagdayao",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616051",
      "brgy_name": "Cubay-Napultan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616053",
      "brgy_name": "Nazareth",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616054",
      "brgy_name": "Odiong",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616055",
      "brgy_name": "Olaga",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616056",
      "brgy_name": "Pangpang",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616057",
      "brgy_name": "Panlagangan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616058",
      "brgy_name": "Pantao",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616059",
      "brgy_name": "Pasong",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616060",
      "brgy_name": "Pis-Anan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616061",
      "brgy_name": "District I (Pob.)",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616062",
      "brgy_name": "District II (Pob.)",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616063",
      "brgy_name": "District III (Pob.)",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616064",
      "brgy_name": "District IV (Pob.)",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616065",
      "brgy_name": "Rombang",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616066",
      "brgy_name": "Salvacion",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616067",
      "brgy_name": "San Juan",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616068",
      "brgy_name": "Sido",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616069",
      "brgy_name": "Solong",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616070",
      "brgy_name": "Tabongtabong",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616071",
      "brgy_name": "Tig-Ohot",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616073",
      "brgy_name": "Tigbalua I",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616074",
      "brgy_name": "Tordesillas",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616075",
      "brgy_name": "Tulatula",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616076",
      "brgy_name": "Villafont",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616077",
      "brgy_name": "Villahermosa",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616078",
      "brgy_name": "Villar",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060616079",
      "brgy_name": "Tigbalua II",
      "city_code": "060616",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617001",
      "brgy_name": "Alegre",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617002",
      "brgy_name": "Amar",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617003",
      "brgy_name": "Bandoja (Lupa-an)",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617004",
      "brgy_name": "Castillo",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617005",
      "brgy_name": "Esparagoza",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617006",
      "brgy_name": "Importante",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617007",
      "brgy_name": "La Paz",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617008",
      "brgy_name": "Malabor",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617009",
      "brgy_name": "Martinez",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617010",
      "brgy_name": "Natividad",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617011",
      "brgy_name": "Pitac",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617012",
      "brgy_name": "Poblacion",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617013",
      "brgy_name": "Salazar",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617014",
      "brgy_name": "San Francisco Norte",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617015",
      "brgy_name": "San Francisco Sur",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617016",
      "brgy_name": "San Isidro",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617017",
      "brgy_name": "Santa Ana",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617018",
      "brgy_name": "Santa Justa",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617019",
      "brgy_name": "Santo Rosario",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617020",
      "brgy_name": "Tigbaboy",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060617021",
      "brgy_name": "Tuno",
      "city_code": "060617",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618001",
      "brgy_name": "Bakiang",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618002",
      "brgy_name": "Binanogan",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618003",
      "brgy_name": "Borocboroc",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618004",
      "brgy_name": "Bugnay",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618005",
      "brgy_name": "Buluangan I",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618006",
      "brgy_name": "Buluangan II",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618007",
      "brgy_name": "Bunsod",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618008",
      "brgy_name": "Busog",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618009",
      "brgy_name": "Cananghan",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618010",
      "brgy_name": "Canipayan",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618011",
      "brgy_name": "Cansilayan",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618012",
      "brgy_name": "Culyat",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618013",
      "brgy_name": "Iglinab",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618014",
      "brgy_name": "Igmasandig",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618015",
      "brgy_name": "Lublub",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618016",
      "brgy_name": "Manlacbo",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618017",
      "brgy_name": "Pandanan",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618018",
      "brgy_name": "San Agustin",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618019",
      "brgy_name": "Takas (Pob.)",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618020",
      "brgy_name": "Tigmamale",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618021",
      "brgy_name": "Ubos (Pob.)",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "060618022",
      "brgy_name": "Alon",
      "city_code": "060618",
      "province_code": "0606",
      "region_code": "06"
    },
    {
      "brgy_code": "061901001",
      "brgy_name": "Agcabugao",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901002",
      "brgy_name": "Agdahon",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901003",
      "brgy_name": "Agnaga",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901004",
      "brgy_name": "Angub",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901005",
      "brgy_name": "Balingasag",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901007",
      "brgy_name": "Bito-on Ilawod",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901008",
      "brgy_name": "Bito-on Ilaya",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901009",
      "brgy_name": "Bun-od",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901010",
      "brgy_name": "Carataya",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901011",
      "brgy_name": "Lunayan",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901012",
      "brgy_name": "Mahunodhunod",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901013",
      "brgy_name": "Maindang",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901014",
      "brgy_name": "Mainit",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901015",
      "brgy_name": "Malagab-i",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901016",
      "brgy_name": "Nagba",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901017",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901018",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901019",
      "brgy_name": "Poblacion Takas",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901020",
      "brgy_name": "Puti-an",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901021",
      "brgy_name": "San Antonio",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901022",
      "brgy_name": "Sinabsaban",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061901023",
      "brgy_name": "Mahabang Sapa",
      "city_code": "061901",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902001",
      "brgy_name": "Aganan",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902002",
      "brgy_name": "Agtambi",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902003",
      "brgy_name": "Agtanguay",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902004",
      "brgy_name": "Balucuan",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902005",
      "brgy_name": "Bita",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902006",
      "brgy_name": "Centro",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902007",
      "brgy_name": "Daplas",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902008",
      "brgy_name": "Duyoc",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902009",
      "brgy_name": "Ilas Sur",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902012",
      "brgy_name": "Lacaron",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902013",
      "brgy_name": "Malonoy",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902014",
      "brgy_name": "Manhoy",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902015",
      "brgy_name": "Mapulang Bato",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902016",
      "brgy_name": "Matagnop",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902017",
      "brgy_name": "Nasunogan",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902018",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902019",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902020",
      "brgy_name": "Quinabcaban",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902021",
      "brgy_name": "Quinayuya",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061902022",
      "brgy_name": "San Agustin (Ilas Norte)",
      "city_code": "061902",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903001",
      "brgy_name": "Concepcion",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903002",
      "brgy_name": "Consolacion",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903003",
      "brgy_name": "Dolores",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903004",
      "brgy_name": "Duran",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903007",
      "brgy_name": "San Agustin",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903008",
      "brgy_name": "San Jose",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903009",
      "brgy_name": "San Martin",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903010",
      "brgy_name": "San Miguel",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903011",
      "brgy_name": "San Rafael",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903012",
      "brgy_name": "San Roque",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903013",
      "brgy_name": "Santa Carmen",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903014",
      "brgy_name": "Santa Cruz",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903015",
      "brgy_name": "Santa Monica",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903016",
      "brgy_name": "Santa Rita",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903017",
      "brgy_name": "Santa Teresa",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903018",
      "brgy_name": "Santo Angel",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903019",
      "brgy_name": "Santo Niño",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903020",
      "brgy_name": "Santo Rosario",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061903021",
      "brgy_name": "Poblacion",
      "city_code": "061903",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904001",
      "brgy_name": "Agbatuan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904002",
      "brgy_name": "Aglalana",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904003",
      "brgy_name": "Aglanot",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904006",
      "brgy_name": "Agsirab",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904007",
      "brgy_name": "Alipasiawan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904008",
      "brgy_name": "Astorga",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904009",
      "brgy_name": "Bayog",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904010",
      "brgy_name": "Bungsuan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904011",
      "brgy_name": "Calapawan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904012",
      "brgy_name": "Cubi",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904013",
      "brgy_name": "Dacuton",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904014",
      "brgy_name": "Dangula",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904015",
      "brgy_name": "Gibato",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904016",
      "brgy_name": "Codingle",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904017",
      "brgy_name": "Guinotos",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904018",
      "brgy_name": "Jambad",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904019",
      "brgy_name": "Janguslob",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904021",
      "brgy_name": "Lawaan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904023",
      "brgy_name": "Malonoy",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904024",
      "brgy_name": "Nagsulang",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904026",
      "brgy_name": "Ongol Ilawod",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904027",
      "brgy_name": "Ongol Ilaya",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904028",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904029",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904030",
      "brgy_name": "Sagrada Familia",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904031",
      "brgy_name": "Salcedo",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904032",
      "brgy_name": "San Juan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904033",
      "brgy_name": "Sibariwan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904034",
      "brgy_name": "Tamulalod",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904035",
      "brgy_name": "Taslan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904036",
      "brgy_name": "Tina",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904037",
      "brgy_name": "Tinaytayan",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061904038",
      "brgy_name": "Traciano",
      "city_code": "061904",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905001",
      "brgy_name": "Agmalobo",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905002",
      "brgy_name": "Agustin Navarra",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905003",
      "brgy_name": "Balaring",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905004",
      "brgy_name": "Basiao",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905005",
      "brgy_name": "Cabugao",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905006",
      "brgy_name": "Cudian",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905007",
      "brgy_name": "Ilaya-Ivisan",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905008",
      "brgy_name": "Malocloc Norte",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905009",
      "brgy_name": "Malocloc Sur",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905010",
      "brgy_name": "Matnog",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905011",
      "brgy_name": "Mianay",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905012",
      "brgy_name": "Ondoy",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905013",
      "brgy_name": "Poblacion Norte",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905014",
      "brgy_name": "Poblacion Sur",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061905015",
      "brgy_name": "Santa Cruz",
      "city_code": "061905",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906001",
      "brgy_name": "Agambulong",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906002",
      "brgy_name": "Agbun-od",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906003",
      "brgy_name": "Agcagay",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906004",
      "brgy_name": "Aglibacao",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906005",
      "brgy_name": "Agloloway",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906006",
      "brgy_name": "Bayebaye",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906007",
      "brgy_name": "Caridad",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906008",
      "brgy_name": "Esperanza",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906009",
      "brgy_name": "Fe",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906010",
      "brgy_name": "Ganzon",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906011",
      "brgy_name": "Guintas",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906012",
      "brgy_name": "Igang",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906013",
      "brgy_name": "Jaena Norte",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906014",
      "brgy_name": "Jaena Sur",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906015",
      "brgy_name": "Jagnaya",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906016",
      "brgy_name": "Lapaz",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906017",
      "brgy_name": "Linambasan",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906018",
      "brgy_name": "Lucero",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906019",
      "brgy_name": "Maantol",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906020",
      "brgy_name": "Masgrau",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906021",
      "brgy_name": "Milan",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906022",
      "brgy_name": "Molet",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906023",
      "brgy_name": "Pangabat",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906024",
      "brgy_name": "Pangabuan",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906025",
      "brgy_name": "Pasol-o",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906026",
      "brgy_name": "Poblacion",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906027",
      "brgy_name": "San Jose",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906028",
      "brgy_name": "San Juan",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906029",
      "brgy_name": "San Vicente",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061906030",
      "brgy_name": "Santo Rosario",
      "city_code": "061906",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907001",
      "brgy_name": "Aglimocon",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907002",
      "brgy_name": "Alasaging",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907003",
      "brgy_name": "Alayunan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907004",
      "brgy_name": "Balighot",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907005",
      "brgy_name": "Batabat",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907006",
      "brgy_name": "Bongbongan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907007",
      "brgy_name": "Cabungahan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907008",
      "brgy_name": "Canapian",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907009",
      "brgy_name": "Carataya",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907010",
      "brgy_name": "Duluan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907011",
      "brgy_name": "East Villaflores",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907012",
      "brgy_name": "Fernandez",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907013",
      "brgy_name": "Guinbi-alan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907014",
      "brgy_name": "Indayagan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907015",
      "brgy_name": "Jebaca",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907016",
      "brgy_name": "Maalan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907017",
      "brgy_name": "Manayupit",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907018",
      "brgy_name": "New Guia",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907019",
      "brgy_name": "Quevedo (Ngalan)",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907020",
      "brgy_name": "Old Guia",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907021",
      "brgy_name": "Palaguian",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907022",
      "brgy_name": "Parallan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907023",
      "brgy_name": "Piña",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907024",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907025",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907026",
      "brgy_name": "Poblacion Tabuc",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907028",
      "brgy_name": "Quinabonglan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907029",
      "brgy_name": "Quinat-uyan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907030",
      "brgy_name": "Salgan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907031",
      "brgy_name": "Tapulang",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907032",
      "brgy_name": "Tuburan",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061907034",
      "brgy_name": "West Villaflores",
      "city_code": "061907",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908001",
      "brgy_name": "Atiplo",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908002",
      "brgy_name": "Balat-an",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908003",
      "brgy_name": "Balit",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908004",
      "brgy_name": "Batiano",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908005",
      "brgy_name": "Bating",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908006",
      "brgy_name": "Bato Bato",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908007",
      "brgy_name": "Baye",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908008",
      "brgy_name": "Bergante",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908009",
      "brgy_name": "Bunga",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908010",
      "brgy_name": "Bula",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908011",
      "brgy_name": "Bungsi",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908012",
      "brgy_name": "Burias",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908013",
      "brgy_name": "Caidquid",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908014",
      "brgy_name": "Cala-agus",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908015",
      "brgy_name": "Libo-o",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908016",
      "brgy_name": "Manibad",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908017",
      "brgy_name": "Maralag",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908018",
      "brgy_name": "Najus-an",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908019",
      "brgy_name": "Pangpang Norte",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908020",
      "brgy_name": "Pangpang Sur",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908021",
      "brgy_name": "Pinay",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908022",
      "brgy_name": "Poblacion Proper",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908023",
      "brgy_name": "Poblacion Tabuc",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908024",
      "brgy_name": "Sinondojan",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908025",
      "brgy_name": "Tugas",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061908026",
      "brgy_name": "Tumalalud",
      "city_code": "061908",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909001",
      "brgy_name": "Agbalo",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909002",
      "brgy_name": "Agbanban",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909003",
      "brgy_name": "Agojo",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909004",
      "brgy_name": "Anhawon",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909005",
      "brgy_name": "Bagacay",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909006",
      "brgy_name": "Bago Chiquito",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909007",
      "brgy_name": "Bago Grande",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909008",
      "brgy_name": "Bahit",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909009",
      "brgy_name": "Bantique",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909010",
      "brgy_name": "Bato",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909011",
      "brgy_name": "Binangig",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909012",
      "brgy_name": "Binantuan",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909013",
      "brgy_name": "Bonga",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909014",
      "brgy_name": "Buntod",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909015",
      "brgy_name": "Butacal",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909016",
      "brgy_name": "Cabugao Este",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909017",
      "brgy_name": "Cabugao Oeste",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909018",
      "brgy_name": "Calapawan",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909019",
      "brgy_name": "Calitan",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909020",
      "brgy_name": "Candual",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909021",
      "brgy_name": "Cogon",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909022",
      "brgy_name": "Daga",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909023",
      "brgy_name": "Ilamnay",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909024",
      "brgy_name": "Jamul-awon",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909025",
      "brgy_name": "Lanipga",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909026",
      "brgy_name": "Lat-Asan",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909027",
      "brgy_name": "Libon",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909028",
      "brgy_name": "Linao",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909029",
      "brgy_name": "Linateran",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909030",
      "brgy_name": "Lomboy",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909031",
      "brgy_name": "Lus-Onan",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909032",
      "brgy_name": "Magubilan",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909033",
      "brgy_name": "Navitas",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909034",
      "brgy_name": "Pawa",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909035",
      "brgy_name": "Pili",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909036",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909037",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909038",
      "brgy_name": "Poblacion Tabuc",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909039",
      "brgy_name": "Talasa",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909040",
      "brgy_name": "Tanza Norte",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909041",
      "brgy_name": "Tanza Sur",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061909042",
      "brgy_name": "Tico",
      "city_code": "061909",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910001",
      "brgy_name": "Agbabadiang",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910002",
      "brgy_name": "Agkilo",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910003",
      "brgy_name": "Agloway",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910004",
      "brgy_name": "Ambilay",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910005",
      "brgy_name": "Bahit",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910006",
      "brgy_name": "Balatucan",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910007",
      "brgy_name": "Banga-an",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910008",
      "brgy_name": "Cabugao",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910009",
      "brgy_name": "Cabangahan",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910010",
      "brgy_name": "Cadio",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910011",
      "brgy_name": "Cala-an",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910012",
      "brgy_name": "Capagao",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910013",
      "brgy_name": "Cogon",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910014",
      "brgy_name": "Conciencia",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910015",
      "brgy_name": "Ensenagan",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910016",
      "brgy_name": "Intampilan",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910017",
      "brgy_name": "Pasugue",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910018",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910019",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910020",
      "brgy_name": "Quios",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910021",
      "brgy_name": "Salocon",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910022",
      "brgy_name": "Tabuc Norte",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910023",
      "brgy_name": "Tabuc Sur",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910024",
      "brgy_name": "Timpas",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910025",
      "brgy_name": "Tincupon",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061910026",
      "brgy_name": "Tinigban",
      "city_code": "061910",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911001",
      "brgy_name": "Balogo",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911002",
      "brgy_name": "Binaobawan",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911003",
      "brgy_name": "Blasco",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911004",
      "brgy_name": "Casanayan",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911005",
      "brgy_name": "Cayus",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911006",
      "brgy_name": "Dayhagan",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911007",
      "brgy_name": "Dulangan",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911010",
      "brgy_name": "Monteflor",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911011",
      "brgy_name": "Natividad",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911012",
      "brgy_name": "Olalo",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911013",
      "brgy_name": "Poblacion",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911014",
      "brgy_name": "Rosario",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911015",
      "brgy_name": "San Antonio",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911016",
      "brgy_name": "San Blas",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911017",
      "brgy_name": "San Esteban",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911018",
      "brgy_name": "San Fernando",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911019",
      "brgy_name": "San Nicolas",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911020",
      "brgy_name": "San Pedro",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911021",
      "brgy_name": "San Ramon",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911022",
      "brgy_name": "San Silvestre",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911023",
      "brgy_name": "Sinamongan",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911024",
      "brgy_name": "Santa Fe",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911025",
      "brgy_name": "Tabun-acan",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061911026",
      "brgy_name": "Yating",
      "city_code": "061911",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912001",
      "brgy_name": "Agbanog",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912002",
      "brgy_name": "Agdalipe",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912003",
      "brgy_name": "Ameligan",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912004",
      "brgy_name": "Bailan",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912005",
      "brgy_name": "Banate",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912006",
      "brgy_name": "Bantigue",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912007",
      "brgy_name": "Binuntucan",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912008",
      "brgy_name": "Cabugao",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912009",
      "brgy_name": "Gabuc (Caugiat)",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912011",
      "brgy_name": "Guba",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912012",
      "brgy_name": "Hipona",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912013",
      "brgy_name": "Intungcan",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912014",
      "brgy_name": "Jolongajog",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912015",
      "brgy_name": "Lantangan",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912016",
      "brgy_name": "Linampongan",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912017",
      "brgy_name": "Malag-it",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912018",
      "brgy_name": "Manapao",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912019",
      "brgy_name": "Ilawod (Pob.)",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912020",
      "brgy_name": "Ilaya (Pob.)",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912021",
      "brgy_name": "Rizal",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912022",
      "brgy_name": "San Pedro",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912023",
      "brgy_name": "Solo",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912024",
      "brgy_name": "Sublangon",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912025",
      "brgy_name": "Tabuc",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912026",
      "brgy_name": "Tacas",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061912027",
      "brgy_name": "Yatingan",
      "city_code": "061912",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913001",
      "brgy_name": "Aranguel",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913002",
      "brgy_name": "Badiangon",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913003",
      "brgy_name": "Bayuyan",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913004",
      "brgy_name": "Cabugcabug",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913005",
      "brgy_name": "Carmencita",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913006",
      "brgy_name": "Cubay",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913007",
      "brgy_name": "Culilang",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913008",
      "brgy_name": "Goce",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913009",
      "brgy_name": "Hanglid",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913010",
      "brgy_name": "Ibaca",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913011",
      "brgy_name": "Madulano",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913012",
      "brgy_name": "Manoling",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913013",
      "brgy_name": "Marita",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913014",
      "brgy_name": "Pandan",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913015",
      "brgy_name": "Pantalan Cabugcabug",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913016",
      "brgy_name": "Pinamihagan",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913017",
      "brgy_name": "Poblacion (Elizalde)",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913018",
      "brgy_name": "Pondol",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913019",
      "brgy_name": "Quiajo",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913020",
      "brgy_name": "Sangkal",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913021",
      "brgy_name": "Santo Niño",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061913022",
      "brgy_name": "Vizcaya",
      "city_code": "061913",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914001",
      "brgy_name": "Adlawan",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914002",
      "brgy_name": "Bago",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914003",
      "brgy_name": "Balijuagan",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914004",
      "brgy_name": "Banica",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914005",
      "brgy_name": "Poblacion I (Barangay I)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914006",
      "brgy_name": "Poblacion X (Barangay X)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914007",
      "brgy_name": "Poblacion XI (Barangay XI)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914009",
      "brgy_name": "Poblacion II (Barangay II)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914010",
      "brgy_name": "Poblacion III (Barangay III)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914011",
      "brgy_name": "Poblacion IV (Barangay IV)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914012",
      "brgy_name": "Poblacion V (Barangay V)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914013",
      "brgy_name": "Poblacion VI (Barangay VI)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914014",
      "brgy_name": "Poblacion VII (Barangay VII)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914015",
      "brgy_name": "Poblacion VIII (Barangay VIII)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914016",
      "brgy_name": "Poblacion IX (Barangay IX)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914017",
      "brgy_name": "Barra",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914018",
      "brgy_name": "Bato",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914019",
      "brgy_name": "Baybay",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914020",
      "brgy_name": "Bolo",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914021",
      "brgy_name": "Cabugao",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914022",
      "brgy_name": "Cagay",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914023",
      "brgy_name": "Cogon",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914024",
      "brgy_name": "Culajao",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914025",
      "brgy_name": "Culasi",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914026",
      "brgy_name": "Dumolog",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914027",
      "brgy_name": "Dayao",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914028",
      "brgy_name": "Dinginan",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914029",
      "brgy_name": "Gabu-an",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914030",
      "brgy_name": "Inzo Arnaldo Village (Cadimahan)",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914031",
      "brgy_name": "Jumaguicjic",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914032",
      "brgy_name": "Lanot",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914033",
      "brgy_name": "Lawa-an",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914034",
      "brgy_name": "Liong",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914035",
      "brgy_name": "Libas",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914036",
      "brgy_name": "Loctugan",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914037",
      "brgy_name": "Lonoy",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914039",
      "brgy_name": "Milibili",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914040",
      "brgy_name": "Mongpong",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914041",
      "brgy_name": "Olotayan",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914042",
      "brgy_name": "Punta Cogon",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914043",
      "brgy_name": "Punta Tabuc",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914044",
      "brgy_name": "San Jose",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914045",
      "brgy_name": "Sibaguan",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914046",
      "brgy_name": "Talon",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914047",
      "brgy_name": "Tanque",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914048",
      "brgy_name": "Tanza",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061914049",
      "brgy_name": "Tiza",
      "city_code": "061914",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915001",
      "brgy_name": "Agsilab",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915002",
      "brgy_name": "Agtatacay Norte",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915003",
      "brgy_name": "Agtatacay Sur",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915004",
      "brgy_name": "Bilao",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915005",
      "brgy_name": "Damayan",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915006",
      "brgy_name": "Dapdapan",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915007",
      "brgy_name": "Lonoy",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915008",
      "brgy_name": "Majanlud",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915009",
      "brgy_name": "Maninang",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061915010",
      "brgy_name": "Poblacion",
      "city_code": "061915",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916001",
      "brgy_name": "Acbo",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916002",
      "brgy_name": "Amaga",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916003",
      "brgy_name": "Balucuan",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916004",
      "brgy_name": "Bangonbangon",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916005",
      "brgy_name": "Capuyhan",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916006",
      "brgy_name": "Cogon",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916007",
      "brgy_name": "Dayhagon",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916008",
      "brgy_name": "Guintas",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916009",
      "brgy_name": "Malapad Cogon",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916010",
      "brgy_name": "Mangoso",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916011",
      "brgy_name": "Mansacul",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916012",
      "brgy_name": "Matangcong",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916013",
      "brgy_name": "Matinabus",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916014",
      "brgy_name": "Mianay",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916015",
      "brgy_name": "Oyong",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916016",
      "brgy_name": "Pagbunitan",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916017",
      "brgy_name": "Parian",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916018",
      "brgy_name": "Pinamalatican",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916019",
      "brgy_name": "Poblacion Norte",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916020",
      "brgy_name": "Poblacion Sur",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061916021",
      "brgy_name": "Tawog",
      "city_code": "061916",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917001",
      "brgy_name": "Abangay",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917002",
      "brgy_name": "Acuña",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917003",
      "brgy_name": "Agcococ",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917004",
      "brgy_name": "Aglinab",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917005",
      "brgy_name": "Aglupacan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917006",
      "brgy_name": "Agpalali",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917007",
      "brgy_name": "Apero",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917008",
      "brgy_name": "Artuz",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917009",
      "brgy_name": "Bag-Ong Barrio",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917010",
      "brgy_name": "Bato-bato",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917011",
      "brgy_name": "Buri",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917012",
      "brgy_name": "Camburanan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917013",
      "brgy_name": "Candelaria",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917014",
      "brgy_name": "Carida",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917015",
      "brgy_name": "Cristina",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917016",
      "brgy_name": "Da-an Banwa",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917017",
      "brgy_name": "Da-an Norte",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917018",
      "brgy_name": "Da-an Sur",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917019",
      "brgy_name": "Garcia",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917020",
      "brgy_name": "Gebio-an",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917021",
      "brgy_name": "Hilwan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917022",
      "brgy_name": "Initan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917023",
      "brgy_name": "Katipunan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917024",
      "brgy_name": "Lagdungan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917025",
      "brgy_name": "Lahug",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917026",
      "brgy_name": "Libertad",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917027",
      "brgy_name": "Mabini",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917028",
      "brgy_name": "Maliao",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917029",
      "brgy_name": "Malitbog",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917030",
      "brgy_name": "Minan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917031",
      "brgy_name": "Nayawan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917032",
      "brgy_name": "Poblacion",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917033",
      "brgy_name": "Rizal Norte",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917034",
      "brgy_name": "Rizal Sur",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917035",
      "brgy_name": "Roosevelt",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917036",
      "brgy_name": "Roxas",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917037",
      "brgy_name": "Salong",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917038",
      "brgy_name": "San Antonio",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917039",
      "brgy_name": "San Francisco",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917041",
      "brgy_name": "San Jose",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917042",
      "brgy_name": "San Julian",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917043",
      "brgy_name": "San Miguel Ilawod",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917044",
      "brgy_name": "San Miguel Ilaya",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917045",
      "brgy_name": "San Nicolas",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917046",
      "brgy_name": "San Pedro",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917047",
      "brgy_name": "San Roque",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917048",
      "brgy_name": "San Vicente",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917049",
      "brgy_name": "Santa Ana",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917050",
      "brgy_name": "Santa Petronila",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917051",
      "brgy_name": "Senonod",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917052",
      "brgy_name": "Siya",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917053",
      "brgy_name": "Switch",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917054",
      "brgy_name": "Tabon",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917055",
      "brgy_name": "Tacayan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917056",
      "brgy_name": "Taft",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917057",
      "brgy_name": "Taganghin",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917058",
      "brgy_name": "Taslan",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "061917059",
      "brgy_name": "Wright",
      "city_code": "061917",
      "province_code": "0619",
      "region_code": "06"
    },
    {
      "brgy_code": "063001001",
      "brgy_name": "Adcadarao",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001002",
      "brgy_name": "Agbobolo",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001003",
      "brgy_name": "Badiangan",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001004",
      "brgy_name": "Barrido",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001005",
      "brgy_name": "Bato Biasong",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001006",
      "brgy_name": "Bay-ang",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001007",
      "brgy_name": "Bucana Bunglas",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001008",
      "brgy_name": "Central",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001009",
      "brgy_name": "Culasi",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001010",
      "brgy_name": "Lanjagan",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001011",
      "brgy_name": "Luca",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001012",
      "brgy_name": "Malayu-an",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001013",
      "brgy_name": "Mangorocoro",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001014",
      "brgy_name": "Nasidman",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001015",
      "brgy_name": "Pantalan Nabaye",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001016",
      "brgy_name": "Pantalan Navarro",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001017",
      "brgy_name": "Pedada",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001018",
      "brgy_name": "Pili",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001019",
      "brgy_name": "Pinantan Diel",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001020",
      "brgy_name": "Pinantan Elizalde",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001021",
      "brgy_name": "Pinay Espinosa",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001022",
      "brgy_name": "Poblacion",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001023",
      "brgy_name": "Progreso",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001024",
      "brgy_name": "Puente Bunglas",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001025",
      "brgy_name": "Punta Buri",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001026",
      "brgy_name": "Rojas",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001027",
      "brgy_name": "San Antonio",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001028",
      "brgy_name": "Silagon",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001029",
      "brgy_name": "Santo Rosario",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001030",
      "brgy_name": "Tagubanhan",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001031",
      "brgy_name": "Taguhangin",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001032",
      "brgy_name": "Tanduyan",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001033",
      "brgy_name": "Tipacla",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063001034",
      "brgy_name": "Tubogan",
      "city_code": "063001",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002001",
      "brgy_name": "Abang-abang",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002002",
      "brgy_name": "Agsing",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002003",
      "brgy_name": "Atabay",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002004",
      "brgy_name": "Ba-ong",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002005",
      "brgy_name": "Baguingin-Lanot",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002006",
      "brgy_name": "Bagsakan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002007",
      "brgy_name": "Bagumbayan-Ilajas",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002008",
      "brgy_name": "Balabago",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002009",
      "brgy_name": "Ban-ag",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002010",
      "brgy_name": "Bancal",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002011",
      "brgy_name": "Binalud",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002012",
      "brgy_name": "Bugang",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002014",
      "brgy_name": "Buhay",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002015",
      "brgy_name": "Bulod",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002017",
      "brgy_name": "Cabacanan Proper",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002018",
      "brgy_name": "Cabacanan Rizal",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002019",
      "brgy_name": "Cagay",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002020",
      "brgy_name": "Coline",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002021",
      "brgy_name": "Coline-Dalag",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002022",
      "brgy_name": "Cunsad",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002023",
      "brgy_name": "Cuyad",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002024",
      "brgy_name": "Dalid",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002025",
      "brgy_name": "Dao",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002026",
      "brgy_name": "Gines",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002027",
      "brgy_name": "Ginomoy",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002028",
      "brgy_name": "Ingwan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002029",
      "brgy_name": "Laylayan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002030",
      "brgy_name": "Lico",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002031",
      "brgy_name": "Luan-luan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002032",
      "brgy_name": "Malamhay",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002033",
      "brgy_name": "Malamboy-Bondolan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002034",
      "brgy_name": "Mambawi",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002035",
      "brgy_name": "Manasa",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002036",
      "brgy_name": "Manduyog",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002037",
      "brgy_name": "Pajo",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002038",
      "brgy_name": "Pianda-an Norte",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002039",
      "brgy_name": "Pianda-an Sur",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002041",
      "brgy_name": "Punong",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002042",
      "brgy_name": "Quinaspan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002043",
      "brgy_name": "Sinamay",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002044",
      "brgy_name": "Sulong",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002045",
      "brgy_name": "Taban-Manguining",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002046",
      "brgy_name": "Tabug",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002047",
      "brgy_name": "Tarug",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002048",
      "brgy_name": "Tugaslon",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002049",
      "brgy_name": "Ubodan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002050",
      "brgy_name": "Ugbo",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002051",
      "brgy_name": "Ulay-Bugang",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002052",
      "brgy_name": "Ulay-Hinablan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002053",
      "brgy_name": "Umingan",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063002054",
      "brgy_name": "Poblacion",
      "city_code": "063002",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003001",
      "brgy_name": "Agbatuan",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003002",
      "brgy_name": "Badiang",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003003",
      "brgy_name": "Balabag",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003004",
      "brgy_name": "Balunos",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003005",
      "brgy_name": "Cag-an",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003006",
      "brgy_name": "Camiros",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003007",
      "brgy_name": "Sambag Culob",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003008",
      "brgy_name": "Dangula-an",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003009",
      "brgy_name": "Guipis",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003010",
      "brgy_name": "Manganese",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003011",
      "brgy_name": "Medina",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003012",
      "brgy_name": "Mostro",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003013",
      "brgy_name": "Palaypay",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003014",
      "brgy_name": "Pantalan",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003015",
      "brgy_name": "Poblacion",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003016",
      "brgy_name": "San Carlos",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003017",
      "brgy_name": "San Juan Crisostomo",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003018",
      "brgy_name": "Santa Rita",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003019",
      "brgy_name": "Santo Rosario",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003020",
      "brgy_name": "Serallo",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063003021",
      "brgy_name": "Vista Alegre",
      "city_code": "063003",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004001",
      "brgy_name": "Agusipan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004002",
      "brgy_name": "Astorga",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004003",
      "brgy_name": "Bita-oyan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004004",
      "brgy_name": "Botong",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004005",
      "brgy_name": "Budiawe",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004006",
      "brgy_name": "Cabanga-an",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004007",
      "brgy_name": "Cabayogan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004008",
      "brgy_name": "Calansanan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004009",
      "brgy_name": "Catubig",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004010",
      "brgy_name": "Guinawahan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004011",
      "brgy_name": "Ilongbukid",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004012",
      "brgy_name": "Indorohan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004013",
      "brgy_name": "Iniligan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004014",
      "brgy_name": "Latawan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004015",
      "brgy_name": "Linayuan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004016",
      "brgy_name": "Mainguit",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004017",
      "brgy_name": "Malublub",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004018",
      "brgy_name": "Manaolan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004019",
      "brgy_name": "Mapili Grande",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004020",
      "brgy_name": "Mapili Sanjo",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004021",
      "brgy_name": "Odiongan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004022",
      "brgy_name": "Poblacion (Badiangan)",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004023",
      "brgy_name": "San Julian",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004024",
      "brgy_name": "Sariri",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004025",
      "brgy_name": "Sianon",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004026",
      "brgy_name": "Sinuagan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004027",
      "brgy_name": "Talaba",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004028",
      "brgy_name": "Tamocol",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004029",
      "brgy_name": "Teneclan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004030",
      "brgy_name": "Tina",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063004031",
      "brgy_name": "Bingauan",
      "city_code": "063004",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005001",
      "brgy_name": "Aranjuez",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005002",
      "brgy_name": "Bacolod",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005003",
      "brgy_name": "Balanti-an",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005004",
      "brgy_name": "Batuan",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005005",
      "brgy_name": "Cabalic",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005006",
      "brgy_name": "Camambugan",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005007",
      "brgy_name": "Dolores",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005008",
      "brgy_name": "Gimamanay",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005009",
      "brgy_name": "Ipil",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005010",
      "brgy_name": "Kinalkalan",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005011",
      "brgy_name": "Lawis",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005012",
      "brgy_name": "Malapoc",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005013",
      "brgy_name": "Mamhut Norte",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005014",
      "brgy_name": "Mamhut Sur",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005015",
      "brgy_name": "Maya",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005016",
      "brgy_name": "Pani-an",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005017",
      "brgy_name": "Poblacion Norte",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005018",
      "brgy_name": "Poblacion Sur",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005019",
      "brgy_name": "Quiasan",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005020",
      "brgy_name": "Salong",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005021",
      "brgy_name": "Salvacion",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005022",
      "brgy_name": "Tingui-an",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063005023",
      "brgy_name": "Zaragosa",
      "city_code": "063005",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006003",
      "brgy_name": "Alacaygan",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006005",
      "brgy_name": "Bariga",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006006",
      "brgy_name": "Belen",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006007",
      "brgy_name": "Bobon",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006008",
      "brgy_name": "Bularan",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006010",
      "brgy_name": "Carmelo",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006012",
      "brgy_name": "De La Paz",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006013",
      "brgy_name": "Dugwakan",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006018",
      "brgy_name": "Juanico",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006020",
      "brgy_name": "Libertad",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006022",
      "brgy_name": "Magdalo",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006023",
      "brgy_name": "Managopaya",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006024",
      "brgy_name": "Merced",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006025",
      "brgy_name": "Poblacion",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006026",
      "brgy_name": "San Salvador",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006027",
      "brgy_name": "Talokgangan",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006028",
      "brgy_name": "Zona Sur",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063006029",
      "brgy_name": "Fuentes*",
      "city_code": "063006",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007001",
      "brgy_name": "Acuit",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007002",
      "brgy_name": "Agcuyawan Calsada",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007003",
      "brgy_name": "Agcuyawan Pulo",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007004",
      "brgy_name": "Bagongbong",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007005",
      "brgy_name": "Baras",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007006",
      "brgy_name": "Bungca",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007007",
      "brgy_name": "Cabilauan",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007008",
      "brgy_name": "Cruz",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007009",
      "brgy_name": "Guintas",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007010",
      "brgy_name": "Igbong",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007011",
      "brgy_name": "Ilaud Poblacion",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007012",
      "brgy_name": "Ilaya Poblacion",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007013",
      "brgy_name": "Jalaud",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007014",
      "brgy_name": "Lagubang",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007015",
      "brgy_name": "Lanas",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007016",
      "brgy_name": "Lico-an",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007017",
      "brgy_name": "Linao",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007018",
      "brgy_name": "Monpon",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007019",
      "brgy_name": "Palaciawan",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007020",
      "brgy_name": "Patag",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007021",
      "brgy_name": "Salihid",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007022",
      "brgy_name": "So-ol",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007023",
      "brgy_name": "Sohoton",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007024",
      "brgy_name": "Tabuc-Suba",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007025",
      "brgy_name": "Tabucan",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007026",
      "brgy_name": "Talisay",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007027",
      "brgy_name": "Tinorian",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007028",
      "brgy_name": "Tiwi",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063007029",
      "brgy_name": "Tubungan",
      "city_code": "063007",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008002",
      "brgy_name": "Bugnay",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008004",
      "brgy_name": "California",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008005",
      "brgy_name": "Del Pilar",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008006",
      "brgy_name": "De la Peña",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008007",
      "brgy_name": "General Luna",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008009",
      "brgy_name": "La Fortuna",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008010",
      "brgy_name": "Lipata",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008011",
      "brgy_name": "Natividad",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008012",
      "brgy_name": "Nueva Invencion",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008013",
      "brgy_name": "Nueva Sevilla",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008014",
      "brgy_name": "Poblacion",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008016",
      "brgy_name": "Puerto Princesa",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008017",
      "brgy_name": "Rizal",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008019",
      "brgy_name": "San Antonio",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008021",
      "brgy_name": "San Fernando",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008023",
      "brgy_name": "San Francisco",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008024",
      "brgy_name": "San Geronimo",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008025",
      "brgy_name": "San Juan",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008026",
      "brgy_name": "San Lucas",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008027",
      "brgy_name": "San Miguel",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008029",
      "brgy_name": "San Roque",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008030",
      "brgy_name": "Santiago",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008031",
      "brgy_name": "Santo Domingo",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008032",
      "brgy_name": "Santo Tomas",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008033",
      "brgy_name": "Ugasan",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063008034",
      "brgy_name": "Vista Alegre",
      "city_code": "063008",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009001",
      "brgy_name": "Alapasco",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009002",
      "brgy_name": "Alinsolong",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009003",
      "brgy_name": "Banban",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009004",
      "brgy_name": "Batad Viejo",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009005",
      "brgy_name": "Binon-an",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009006",
      "brgy_name": "Bolhog",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009007",
      "brgy_name": "Bulak Norte",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009008",
      "brgy_name": "Bulak Sur",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009009",
      "brgy_name": "Cabagohan",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009011",
      "brgy_name": "Calangag",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009012",
      "brgy_name": "Caw-i",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009013",
      "brgy_name": "Drancalan",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009014",
      "brgy_name": "Embarcadero",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009015",
      "brgy_name": "Hamod",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009016",
      "brgy_name": "Malico",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009017",
      "brgy_name": "Nangka",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009018",
      "brgy_name": "Pasayan",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009019",
      "brgy_name": "Poblacion",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009020",
      "brgy_name": "Quiazan Florete",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009021",
      "brgy_name": "Quiazan Lopez",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009022",
      "brgy_name": "Salong",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009023",
      "brgy_name": "Santa Ana",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009024",
      "brgy_name": "Tanao",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063009025",
      "brgy_name": "Tapi-an",
      "city_code": "063009",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010001",
      "brgy_name": "Agba-o",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010002",
      "brgy_name": "Alabidhan",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010003",
      "brgy_name": "Bulabog",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010004",
      "brgy_name": "Cairohan",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010005",
      "brgy_name": "Guinhulacan",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010006",
      "brgy_name": "Inamyungan",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010007",
      "brgy_name": "Malitbog Ilawod",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010008",
      "brgy_name": "Malitbog Ilaya",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010009",
      "brgy_name": "Ngingi-an",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010010",
      "brgy_name": "Poblacion",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010011",
      "brgy_name": "Quinangyana",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010012",
      "brgy_name": "Quinar-Upan",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010013",
      "brgy_name": "Tapacon",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063010014",
      "brgy_name": "Tubod",
      "city_code": "063010",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012001",
      "brgy_name": "Acao",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012002",
      "brgy_name": "Amerang",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012003",
      "brgy_name": "Amurao",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012004",
      "brgy_name": "Anuang",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012005",
      "brgy_name": "Ayaman",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012006",
      "brgy_name": "Ayong",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012007",
      "brgy_name": "Bacan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012008",
      "brgy_name": "Balabag",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012009",
      "brgy_name": "Baluyan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012010",
      "brgy_name": "Banguit",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012011",
      "brgy_name": "Bulay",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012012",
      "brgy_name": "Cadoldolan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012013",
      "brgy_name": "Cagban",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012014",
      "brgy_name": "Calawagan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012015",
      "brgy_name": "Calayo",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012016",
      "brgy_name": "Duyanduyan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012017",
      "brgy_name": "Gaub",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012018",
      "brgy_name": "Gines Interior",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012019",
      "brgy_name": "Gines Patag",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012020",
      "brgy_name": "Guibuangan Tigbauan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012021",
      "brgy_name": "Inabasan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012022",
      "brgy_name": "Inaca",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012023",
      "brgy_name": "Inaladan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012024",
      "brgy_name": "Ingas",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012025",
      "brgy_name": "Ito Norte",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012026",
      "brgy_name": "Ito Sur",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012027",
      "brgy_name": "Janipaan Central",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012028",
      "brgy_name": "Janipaan Este",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012029",
      "brgy_name": "Janipaan Oeste",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012030",
      "brgy_name": "Janipaan Olo",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012031",
      "brgy_name": "Jelicuon Lusaya",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012032",
      "brgy_name": "Jelicuon Montinola",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012033",
      "brgy_name": "Lag-an",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012034",
      "brgy_name": "Leong",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012035",
      "brgy_name": "Lutac",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012036",
      "brgy_name": "Manguna",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012037",
      "brgy_name": "Maraguit",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012038",
      "brgy_name": "Morubuan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012039",
      "brgy_name": "Pacatin",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012040",
      "brgy_name": "Pagotpot",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012041",
      "brgy_name": "Pamul-Ogan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012042",
      "brgy_name": "Pamuringao Proper",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012043",
      "brgy_name": "Pamuringao Garrido",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012044",
      "brgy_name": "Zone I Pob. (Barangay 1)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012045",
      "brgy_name": "Zone X Pob. (Barangay 10)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012046",
      "brgy_name": "Zone XI Pob. (Barangay 11)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012047",
      "brgy_name": "Zone II Pob. (Barangay 2)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012048",
      "brgy_name": "Zone III Pob. (Barangay 3)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012049",
      "brgy_name": "Zone IV Pob. (Barangay 4)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012050",
      "brgy_name": "Zone V Pob. (Barangay 5)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012051",
      "brgy_name": "Zone VI Pob. (Barangay 6 )",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012052",
      "brgy_name": "Zone VII Pob. (Barangay 7)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012053",
      "brgy_name": "Zone VIII Pob. (Barangay 8)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012054",
      "brgy_name": "Zone IX Pob. (Barangay 9)",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012055",
      "brgy_name": "Pungtod",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012056",
      "brgy_name": "Puyas",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012057",
      "brgy_name": "Salacay",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012059",
      "brgy_name": "Sulanga",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012060",
      "brgy_name": "Tabucan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012061",
      "brgy_name": "Tacdangan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012062",
      "brgy_name": "Talanghauan",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012063",
      "brgy_name": "Tigbauan Road",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012064",
      "brgy_name": "Tinio-an",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012065",
      "brgy_name": "Tiring",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012066",
      "brgy_name": "Tupol Central",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012067",
      "brgy_name": "Tupol Este",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012068",
      "brgy_name": "Tupol Oeste",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063012069",
      "brgy_name": "Tuy-an",
      "city_code": "063012",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013002",
      "brgy_name": "Agcalaga",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013003",
      "brgy_name": "Aglibacao",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013004",
      "brgy_name": "Aglonok",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013006",
      "brgy_name": "Alibunan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013007",
      "brgy_name": "Badlan Grande",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013008",
      "brgy_name": "Badlan Pequeño",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013009",
      "brgy_name": "Badu",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013011",
      "brgy_name": "Balaticon",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013012",
      "brgy_name": "Banban Grande",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013013",
      "brgy_name": "Banban Pequeño",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013015",
      "brgy_name": "Binolosan Grande",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013016",
      "brgy_name": "Binolosan Pequeño",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013017",
      "brgy_name": "Cabagiao",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013019",
      "brgy_name": "Cabugao",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013020",
      "brgy_name": "Cahigon",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013022",
      "brgy_name": "Barrio Calinog",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013023",
      "brgy_name": "Camalongo",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013024",
      "brgy_name": "Canabajan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013025",
      "brgy_name": "Caratagan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013026",
      "brgy_name": "Carvasana",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013027",
      "brgy_name": "Dalid",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013028",
      "brgy_name": "Datagan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013030",
      "brgy_name": "Gama Grande",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013031",
      "brgy_name": "Gama Pequeño",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013032",
      "brgy_name": "Garangan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013034",
      "brgy_name": "Guinbonyugan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013036",
      "brgy_name": "Guiso",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013037",
      "brgy_name": "Hilwan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013038",
      "brgy_name": "Impalidan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013040",
      "brgy_name": "Ipil",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013041",
      "brgy_name": "Jamin-ay",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013042",
      "brgy_name": "Lampaya",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013043",
      "brgy_name": "Libot",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013044",
      "brgy_name": "Lonoy",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013045",
      "brgy_name": "Malaguinabot",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013046",
      "brgy_name": "Malapawe",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013047",
      "brgy_name": "Malitbog Centro",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013049",
      "brgy_name": "Mambiranan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013050",
      "brgy_name": "Manaripay",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013051",
      "brgy_name": "Marandig",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013052",
      "brgy_name": "Masaroy",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013053",
      "brgy_name": "Maspasan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013054",
      "brgy_name": "Nalbugan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013056",
      "brgy_name": "Owak",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013057",
      "brgy_name": "Poblacion Centro",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013058",
      "brgy_name": "Poblacion Delgado",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013059",
      "brgy_name": "Poblacion Rizal Ilaud",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013060",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013062",
      "brgy_name": "Baje San Julian",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013063",
      "brgy_name": "San Nicolas",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013064",
      "brgy_name": "Simsiman",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013065",
      "brgy_name": "Tabucan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013066",
      "brgy_name": "Tahing",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013068",
      "brgy_name": "Tibiao",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013069",
      "brgy_name": "Tigbayog",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013071",
      "brgy_name": "Toyungan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013072",
      "brgy_name": "Ulayan",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013073",
      "brgy_name": "Malag-it",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063013074",
      "brgy_name": "Supanga",
      "city_code": "063013",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014001",
      "brgy_name": "Abong",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014002",
      "brgy_name": "Alipata",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014003",
      "brgy_name": "Asluman",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014004",
      "brgy_name": "Bancal",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014005",
      "brgy_name": "Barangcalan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014006",
      "brgy_name": "Barosbos",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014007",
      "brgy_name": "Punta Batuanan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014009",
      "brgy_name": "Binuluangan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014010",
      "brgy_name": "Bito-on",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014011",
      "brgy_name": "Bolo",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014012",
      "brgy_name": "Buaya",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014013",
      "brgy_name": "Buenavista",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014014",
      "brgy_name": "Isla De Cana",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014016",
      "brgy_name": "Cabilao Grande",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014017",
      "brgy_name": "Cabilao Pequeño",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014018",
      "brgy_name": "Cabuguana",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014019",
      "brgy_name": "Cawayan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014020",
      "brgy_name": "Dayhagan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014021",
      "brgy_name": "Gabi",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014022",
      "brgy_name": "Granada",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014023",
      "brgy_name": "Guinticgan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014024",
      "brgy_name": "Lantangan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014025",
      "brgy_name": "Manlot",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014026",
      "brgy_name": "Nalumsan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014027",
      "brgy_name": "Pantalan",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014028",
      "brgy_name": "Poblacion",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014029",
      "brgy_name": "Punta (Bolocawe)",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014030",
      "brgy_name": "San Fernando",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014031",
      "brgy_name": "Tabugon",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014032",
      "brgy_name": "Talingting",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014033",
      "brgy_name": "Tarong",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014034",
      "brgy_name": "Tinigban",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063014035",
      "brgy_name": "Tupaz",
      "city_code": "063014",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015002",
      "brgy_name": "Aglosong",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015003",
      "brgy_name": "Agnaga",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015004",
      "brgy_name": "Bacjawan Norte",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015005",
      "brgy_name": "Bacjawan Sur",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015006",
      "brgy_name": "Bagongon",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015007",
      "brgy_name": "Batiti",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015008",
      "brgy_name": "Botlog",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015009",
      "brgy_name": "Calamigan",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015010",
      "brgy_name": "Dungon",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015011",
      "brgy_name": "Igbon",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015012",
      "brgy_name": "Jamul-Awon",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015013",
      "brgy_name": "Lo-ong",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015014",
      "brgy_name": "Macalbang",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015015",
      "brgy_name": "Macatunao",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015016",
      "brgy_name": "Malangabang",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015017",
      "brgy_name": "Maliogliog",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015018",
      "brgy_name": "Niño",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015019",
      "brgy_name": "Nipa",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015021",
      "brgy_name": "Plandico",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015022",
      "brgy_name": "Poblacion",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015023",
      "brgy_name": "Polopina",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015024",
      "brgy_name": "Salvacion",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015025",
      "brgy_name": "Talotu-an",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015026",
      "brgy_name": "Tambaliza",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063015027",
      "brgy_name": "Tamis-ac",
      "city_code": "063015",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016001",
      "brgy_name": "Abangay",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016002",
      "brgy_name": "Agsalanan",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016003",
      "brgy_name": "Agtatacay",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016004",
      "brgy_name": "Alegria",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016005",
      "brgy_name": "Bongloy",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016006",
      "brgy_name": "Buenavista",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016007",
      "brgy_name": "Caguyuman",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016008",
      "brgy_name": "Calicuang",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016009",
      "brgy_name": "Camambugan",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016011",
      "brgy_name": "Dawis",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016012",
      "brgy_name": "Ginalinan Nuevo",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016013",
      "brgy_name": "Ginalinan Viejo",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016014",
      "brgy_name": "Gutao",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016015",
      "brgy_name": "Ilajas",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016016",
      "brgy_name": "Libo-o",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016017",
      "brgy_name": "Licu-an",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016018",
      "brgy_name": "Lincud",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016019",
      "brgy_name": "Matangharon",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016020",
      "brgy_name": "Moroboro",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016021",
      "brgy_name": "Namatay",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016022",
      "brgy_name": "Nazuni",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016023",
      "brgy_name": "Pandan",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016024",
      "brgy_name": "Poblacion",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016025",
      "brgy_name": "Potolan",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016026",
      "brgy_name": "San Jose",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016027",
      "brgy_name": "San Matias",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016028",
      "brgy_name": "Siniba-an",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016029",
      "brgy_name": "Tabugon",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016030",
      "brgy_name": "Tambunac",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016031",
      "brgy_name": "Tanghawan",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016032",
      "brgy_name": "Tiguib",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016033",
      "brgy_name": "Tinocuan",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063016034",
      "brgy_name": "Tulatula-an",
      "city_code": "063016",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017001",
      "brgy_name": "Agutayan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017002",
      "brgy_name": "Angare",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017003",
      "brgy_name": "Anjawan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017004",
      "brgy_name": "Baac",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017005",
      "brgy_name": "Bagongbong",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017006",
      "brgy_name": "Balangigan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017007",
      "brgy_name": "Balingasag",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017008",
      "brgy_name": "Banugan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017009",
      "brgy_name": "Batuan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017010",
      "brgy_name": "Bita",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017011",
      "brgy_name": "Buenavista",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017012",
      "brgy_name": "Bugtongan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017013",
      "brgy_name": "Cabudian",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017014",
      "brgy_name": "Calaca-an",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017015",
      "brgy_name": "Calang",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017016",
      "brgy_name": "Calawinan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017017",
      "brgy_name": "Capaycapay",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017018",
      "brgy_name": "Capuling",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017019",
      "brgy_name": "Catig",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017020",
      "brgy_name": "Dila-an",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017021",
      "brgy_name": "Fundacion",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017022",
      "brgy_name": "Inadlawan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017023",
      "brgy_name": "Jagdong",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017024",
      "brgy_name": "Jaguimit",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017025",
      "brgy_name": "Lacadon",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017026",
      "brgy_name": "Luag",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017027",
      "brgy_name": "Malusgod",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017028",
      "brgy_name": "Maribuyong",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017029",
      "brgy_name": "Minanga",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017030",
      "brgy_name": "Monpon",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017031",
      "brgy_name": "Navalas",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017032",
      "brgy_name": "Pader",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017033",
      "brgy_name": "Pandan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017038",
      "brgy_name": "Ponong Grande",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017039",
      "brgy_name": "Ponong Pequeño",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017040",
      "brgy_name": "Purog",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017041",
      "brgy_name": "Romblon",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017042",
      "brgy_name": "San Isidro",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017043",
      "brgy_name": "Santo Niño",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017044",
      "brgy_name": "Sawe",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017045",
      "brgy_name": "Taminla",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017046",
      "brgy_name": "Tinocuan",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017047",
      "brgy_name": "Tipolo",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017048",
      "brgy_name": "Poblacion A",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017049",
      "brgy_name": "Poblacion B",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017050",
      "brgy_name": "Poblacion C",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063017051",
      "brgy_name": "Poblacion D",
      "city_code": "063017",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018002",
      "brgy_name": "Bacay",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018003",
      "brgy_name": "Bacong",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018004",
      "brgy_name": "Balabag",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018005",
      "brgy_name": "Balud",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018006",
      "brgy_name": "Bantud",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018007",
      "brgy_name": "Bantud Fabrica",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018008",
      "brgy_name": "Baras",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018009",
      "brgy_name": "Barasan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018010",
      "brgy_name": "Bolilao",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018012",
      "brgy_name": "Calao",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018013",
      "brgy_name": "Cali",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018014",
      "brgy_name": "Cansilayan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018015",
      "brgy_name": "Capaliz",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018016",
      "brgy_name": "Cayos",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018017",
      "brgy_name": "Compayan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018018",
      "brgy_name": "Dacutan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018019",
      "brgy_name": "Ermita",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018020",
      "brgy_name": "Pd Monfort South (Guinsampanan)",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018021",
      "brgy_name": "Ilaya 1st",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018022",
      "brgy_name": "Ilaya 2nd",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018023",
      "brgy_name": "Ilaya 3rd",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018025",
      "brgy_name": "Jardin",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018026",
      "brgy_name": "Lacturan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018028",
      "brgy_name": "Pd Monfort North (Lublub)",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018029",
      "brgy_name": "Managuit",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018030",
      "brgy_name": "Maquina",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018031",
      "brgy_name": "Nanding Lopez",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018032",
      "brgy_name": "Pagdugue",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018033",
      "brgy_name": "Paloc Bigque",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018034",
      "brgy_name": "Paloc Sool",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018035",
      "brgy_name": "Patlad",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018036",
      "brgy_name": "Pulao",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018037",
      "brgy_name": "Rosario",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018038",
      "brgy_name": "Sapao",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018039",
      "brgy_name": "Sulangan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018040",
      "brgy_name": "Tabucan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018041",
      "brgy_name": "Talusan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018042",
      "brgy_name": "Tambobo",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018043",
      "brgy_name": "Tamboilan",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018044",
      "brgy_name": "Victorias",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018045",
      "brgy_name": "Burgos-Regidor (Pob.)",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018046",
      "brgy_name": "Aurora-del Pilar (Pob.)",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018047",
      "brgy_name": "Buenaflor Embarkadero (Pob.)",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018048",
      "brgy_name": "Lopez Jaena - Rizal (Pob.)",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063018049",
      "brgy_name": "Basa-Mabini Bonifacio (Pob.)",
      "city_code": "063018",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019001",
      "brgy_name": "Lumbia (Ana Cuenca)",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019002",
      "brgy_name": "Bayas (Bayas Island)",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019003",
      "brgy_name": "Bayuyan",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019004",
      "brgy_name": "Botongan",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019005",
      "brgy_name": "Bulaqueña",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019006",
      "brgy_name": "Calapdan",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019007",
      "brgy_name": "Cano-an",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019008",
      "brgy_name": "Daan Banua",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019009",
      "brgy_name": "Daculan",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019010",
      "brgy_name": "Gogo",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019011",
      "brgy_name": "Jolog",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019012",
      "brgy_name": "Loguingot (Loguingot Island)",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019013",
      "brgy_name": "Malbog",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019014",
      "brgy_name": "Manipulon",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019015",
      "brgy_name": "Pa-on",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019016",
      "brgy_name": "Pani-an",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019017",
      "brgy_name": "Poblacion Zone 1",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019018",
      "brgy_name": "Lonoy (Roman Mosqueda)",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019019",
      "brgy_name": "San Roque",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019020",
      "brgy_name": "Santa Ana",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019021",
      "brgy_name": "Tabu-an",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019022",
      "brgy_name": "Tacbuyan",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019023",
      "brgy_name": "Tanza",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019024",
      "brgy_name": "Poblacion Zone II",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063019025",
      "brgy_name": "Poblacion Zone III",
      "city_code": "063019",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020001",
      "brgy_name": "Anono-o",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020002",
      "brgy_name": "Bacong",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020003",
      "brgy_name": "Bagumbayan Pob. (Bagumbayan-Granada)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020004",
      "brgy_name": "Balantad-Carlos Fruto (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020005",
      "brgy_name": "Baras",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020006",
      "brgy_name": "Binanua-an",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020007",
      "brgy_name": "Torreblanca-Blumentritt (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020008",
      "brgy_name": "Bongol San Miguel",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020009",
      "brgy_name": "Bongol San Vicente",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020010",
      "brgy_name": "Bulad",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020011",
      "brgy_name": "Buluangan",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020012",
      "brgy_name": "Burgos-Gengos (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020013",
      "brgy_name": "Cabasi",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020014",
      "brgy_name": "Cabubugan",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020015",
      "brgy_name": "Calampitao",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020016",
      "brgy_name": "Camangahan",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020017",
      "brgy_name": "Generosa-Cristobal Colon (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020018",
      "brgy_name": "Gerona-Gimeno (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020019",
      "brgy_name": "Girado-Magsaysay (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020020",
      "brgy_name": "Gotera (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020021",
      "brgy_name": "Igcocolo",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020022",
      "brgy_name": "Libo-on Gonzales (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020023",
      "brgy_name": "Lubacan",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020024",
      "brgy_name": "Nahapay",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020025",
      "brgy_name": "Nalundan",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020026",
      "brgy_name": "Nanga",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020027",
      "brgy_name": "Nito-an Lupsag",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020028",
      "brgy_name": "Particion",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020029",
      "brgy_name": "Pescadores (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020030",
      "brgy_name": "Rizal-Tuguisan (Pob.)",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020031",
      "brgy_name": "Sipitan-Badiang",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020032",
      "brgy_name": "Iyasan",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063020033",
      "brgy_name": "Santa Rosa-Laguna",
      "city_code": "063020",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021001",
      "brgy_name": "Alameda",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021002",
      "brgy_name": "Amorogtong",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021003",
      "brgy_name": "Anilawan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021004",
      "brgy_name": "Bagacay",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021005",
      "brgy_name": "Bagacayan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021006",
      "brgy_name": "Bagay",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021007",
      "brgy_name": "Balibagan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021008",
      "brgy_name": "Barasan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021009",
      "brgy_name": "Binanua-an",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021010",
      "brgy_name": "Boclod",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021011",
      "brgy_name": "Buenavista",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021012",
      "brgy_name": "Buga",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021013",
      "brgy_name": "Bugnay",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021014",
      "brgy_name": "Calampitao",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021015",
      "brgy_name": "Cale",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021016",
      "brgy_name": "Corucuan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021017",
      "brgy_name": "Catiringan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021018",
      "brgy_name": "Igcabugao",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021019",
      "brgy_name": "Igpigus",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021020",
      "brgy_name": "Igtalongon",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021021",
      "brgy_name": "Indaluyon",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021022",
      "brgy_name": "Jovellar",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021023",
      "brgy_name": "Kinagdan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021024",
      "brgy_name": "Lab-on",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021025",
      "brgy_name": "Lacay Dol-Dol",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021026",
      "brgy_name": "Lumangan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021027",
      "brgy_name": "Lutungan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021028",
      "brgy_name": "Mantangon",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021029",
      "brgy_name": "Mulangan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021030",
      "brgy_name": "Pasong",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021031",
      "brgy_name": "Passi",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021032",
      "brgy_name": "Pinaopawan",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021033",
      "brgy_name": "Barangay 1 Poblacion",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021034",
      "brgy_name": "Barangay 2 Poblacion",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021035",
      "brgy_name": "Barangay 3 Poblacion",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021036",
      "brgy_name": "Barangay 4 Poblacion",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021037",
      "brgy_name": "Barangay 5 Poblacion",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021038",
      "brgy_name": "Barangay 6 Poblacion",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021039",
      "brgy_name": "Riro-an",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021040",
      "brgy_name": "San Ambrosio",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021041",
      "brgy_name": "Santa Barbara",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021042",
      "brgy_name": "Signe",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021043",
      "brgy_name": "Tabiac",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021044",
      "brgy_name": "Talayatay",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021045",
      "brgy_name": "Taytay",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063021046",
      "brgy_name": "Tigbanaba",
      "city_code": "063021",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022001",
      "brgy_name": "Santa Cruz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022003",
      "brgy_name": "Aguinaldo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022004",
      "brgy_name": "Airport (Tabucan Airport)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022005",
      "brgy_name": "Alalasan Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022006",
      "brgy_name": "Arguelles",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022007",
      "brgy_name": "Arsenal Aduana",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022008",
      "brgy_name": "North Avanceña",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022009",
      "brgy_name": "Bakhaw",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022010",
      "brgy_name": "Balabago",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022011",
      "brgy_name": "Balantang",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022012",
      "brgy_name": "Baldoza",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022014",
      "brgy_name": "Sinikway (Bangkerohan Lapuz)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022015",
      "brgy_name": "Bantud",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022016",
      "brgy_name": "Banuyao",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022017",
      "brgy_name": "Baybay Tanza",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022018",
      "brgy_name": "Benedicto (Jaro)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022019",
      "brgy_name": "Bito-on",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022020",
      "brgy_name": "Monica Blumentritt",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022021",
      "brgy_name": "Bolilao",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022022",
      "brgy_name": "Bonifacio Tanza",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022023",
      "brgy_name": "Bonifacio (Arevalo)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022024",
      "brgy_name": "Buhang",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022025",
      "brgy_name": "Buhang Taft North",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022026",
      "brgy_name": "Buntatala",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022028",
      "brgy_name": "Seminario (Burgos Jalandoni)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022029",
      "brgy_name": "Caingin",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022030",
      "brgy_name": "Calahunan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022031",
      "brgy_name": "Calaparan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022032",
      "brgy_name": "Calumpang",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022033",
      "brgy_name": "Camalig",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022034",
      "brgy_name": "El 98 Castilla (Claudio Lopez)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022035",
      "brgy_name": "Cochero",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022036",
      "brgy_name": "Compania",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022038",
      "brgy_name": "Concepcion-Montes",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022040",
      "brgy_name": "Cuartero",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022041",
      "brgy_name": "Cubay",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022042",
      "brgy_name": "Danao",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022043",
      "brgy_name": "Mabolo-Delgado",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022044",
      "brgy_name": "Democracia",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022045",
      "brgy_name": "Desamparados",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022046",
      "brgy_name": "Divinagracia",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022047",
      "brgy_name": "Don Esteban-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022048",
      "brgy_name": "Dulonan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022049",
      "brgy_name": "Dungon",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022050",
      "brgy_name": "Dungon A",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022051",
      "brgy_name": "Dungon B",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022052",
      "brgy_name": "East Baluarte",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022053",
      "brgy_name": "East Timawa",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022054",
      "brgy_name": "Edganzon",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022055",
      "brgy_name": "Tanza-Esperanza",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022056",
      "brgy_name": "Fajardo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022057",
      "brgy_name": "Flores",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022060",
      "brgy_name": "South Fundidor",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022061",
      "brgy_name": "General Hughes-Montes",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022062",
      "brgy_name": "Gloria",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022063",
      "brgy_name": "Gustilo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022064",
      "brgy_name": "Guzman-Jesena",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022065",
      "brgy_name": "Habog-habog Salvacion",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022067",
      "brgy_name": "Hibao-an Sur",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022068",
      "brgy_name": "Hinactacan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022069",
      "brgy_name": "Hipodromo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022070",
      "brgy_name": "Inday",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022071",
      "brgy_name": "Infante",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022072",
      "brgy_name": "Ingore",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022073",
      "brgy_name": "Jalandoni Estate-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022074",
      "brgy_name": "Jalandoni-Wilson",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022075",
      "brgy_name": "Delgado-Jalandoni-Bagumbayan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022076",
      "brgy_name": "Javellana",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022077",
      "brgy_name": "Jereos",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022078",
      "brgy_name": "Calubihan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022079",
      "brgy_name": "Kasingkasing",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022080",
      "brgy_name": "Katilingban",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022081",
      "brgy_name": "Kauswagan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022084",
      "brgy_name": "Our Lady Of Fatima",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022085",
      "brgy_name": "Laguda",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022086",
      "brgy_name": "Lanit",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022087",
      "brgy_name": "Lapuz Norte",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022088",
      "brgy_name": "Lapuz Sur",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022089",
      "brgy_name": "Legaspi dela Rama",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022090",
      "brgy_name": "Liberation",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022091",
      "brgy_name": "Libertad, Santa Isabel",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022092",
      "brgy_name": "Libertad-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022093",
      "brgy_name": "Lopez Jaena (Jaro)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022094",
      "brgy_name": "Loboc-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022095",
      "brgy_name": "Lopez Jaena Norte",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022096",
      "brgy_name": "Lopez Jaena Sur",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022097",
      "brgy_name": "Luna (Jaro)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022098",
      "brgy_name": "M. V. Hechanova",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022099",
      "brgy_name": "Burgos-Mabini-Plaza",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022100",
      "brgy_name": "Macarthur",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022101",
      "brgy_name": "Magdalo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022102",
      "brgy_name": "Magsaysay",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022103",
      "brgy_name": "Magsaysay Village",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022104",
      "brgy_name": "Malipayon-Delgado",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022105",
      "brgy_name": "Mansaya-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022106",
      "brgy_name": "Marcelo H. del Pilar",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022107",
      "brgy_name": "Maria Clara",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022108",
      "brgy_name": "Maria Cristina",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022109",
      "brgy_name": "Mohon",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022110",
      "brgy_name": "Molo Boulevard",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022112",
      "brgy_name": "Montinola",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022113",
      "brgy_name": "Muelle Loney-Montes",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022114",
      "brgy_name": "Nabitasan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022115",
      "brgy_name": "Navais",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022116",
      "brgy_name": "Nonoy",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022117",
      "brgy_name": "North Fundidor",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022118",
      "brgy_name": "North Baluarte",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022119",
      "brgy_name": "North San Jose",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022120",
      "brgy_name": "Oñate de Leon",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022121",
      "brgy_name": "Obrero-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022122",
      "brgy_name": "Ortiz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022123",
      "brgy_name": "Osmeña",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022124",
      "brgy_name": "Our Lady Of Lourdes",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022125",
      "brgy_name": "Rizal Palapala I",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022126",
      "brgy_name": "Rizal Palapala II",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022127",
      "brgy_name": "PHHC Block 17",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022128",
      "brgy_name": "PHHC Block 22 NHA",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022129",
      "brgy_name": "Poblacion Molo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022131",
      "brgy_name": "President Roxas",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022132",
      "brgy_name": "Progreso-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022133",
      "brgy_name": "Punong-Lapuz",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022134",
      "brgy_name": "Quezon",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022135",
      "brgy_name": "Quintin Salas",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022136",
      "brgy_name": "Rima-Rizal",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022137",
      "brgy_name": "Rizal Estanzuela",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022138",
      "brgy_name": "Rizal Ibarra",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022139",
      "brgy_name": "Railway",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022140",
      "brgy_name": "Roxas Village",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022144",
      "brgy_name": "Sambag",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022146",
      "brgy_name": "Sampaguita",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022148",
      "brgy_name": "San Agustin",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022149",
      "brgy_name": "San Antonio",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022150",
      "brgy_name": "San Felix",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022151",
      "brgy_name": "San Isidro (Jaro)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022152",
      "brgy_name": "Hibao-an Norte",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022154",
      "brgy_name": "San Jose (Jaro)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022155",
      "brgy_name": "San Jose (Arevalo)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022156",
      "brgy_name": "San Juan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022157",
      "brgy_name": "San Nicolas",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022158",
      "brgy_name": "San Pedro (Molo)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022159",
      "brgy_name": "San Pedro (Jaro)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022160",
      "brgy_name": "San Rafael",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022161",
      "brgy_name": "San Roque",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022163",
      "brgy_name": "San Vicente",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022164",
      "brgy_name": "Santa Filomena",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022165",
      "brgy_name": "Santa Rosa",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022166",
      "brgy_name": "Santo Domingo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022167",
      "brgy_name": "Santo Niño Norte",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022168",
      "brgy_name": "Santo Niño Sur",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022169",
      "brgy_name": "Santo Rosario-Duran",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022170",
      "brgy_name": "Simon Ledesma",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022171",
      "brgy_name": "So-oc",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022172",
      "brgy_name": "South Baluarte",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022173",
      "brgy_name": "South San Jose",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022174",
      "brgy_name": "Taal",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022175",
      "brgy_name": "Tabuc Suba (Jaro)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022176",
      "brgy_name": "Tabucan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022177",
      "brgy_name": "Tacas",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022178",
      "brgy_name": "Abeto Mirasol Taft South (Quirino Abeto)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022179",
      "brgy_name": "Tagbac",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022181",
      "brgy_name": "Tap-oc",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022182",
      "brgy_name": "Taytay Zone II",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022183",
      "brgy_name": "Ticud (La Paz)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022185",
      "brgy_name": "Timawa Tanza I",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022186",
      "brgy_name": "Timawa Tanza II",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022187",
      "brgy_name": "Ungka",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022188",
      "brgy_name": "Veterans Village",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022189",
      "brgy_name": "Villa Anita",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022191",
      "brgy_name": "West Habog-habog",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022192",
      "brgy_name": "West Timawa",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022193",
      "brgy_name": "Yulo-Arroyo",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022194",
      "brgy_name": "Yulo Drive",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022195",
      "brgy_name": "Zamora-Melliza",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022196",
      "brgy_name": "Pale Benedicto Rizal (Mandurriao)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022197",
      "brgy_name": "Kahirupan",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022198",
      "brgy_name": "Luna (La Paz)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022199",
      "brgy_name": "San Isidro (La Paz)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022200",
      "brgy_name": "San Jose (City Proper)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022201",
      "brgy_name": "Tabuc Suba (La Paz)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063022202",
      "brgy_name": "Rizal (La Paz)",
      "city_code": "063022",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023001",
      "brgy_name": "Abangay",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023002",
      "brgy_name": "Agcarope",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023003",
      "brgy_name": "Aglobong",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023004",
      "brgy_name": "Aguingay",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023005",
      "brgy_name": "Anhawan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023006",
      "brgy_name": "Atimonan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023007",
      "brgy_name": "Balanac",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023008",
      "brgy_name": "Barasalon",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023009",
      "brgy_name": "Bongol",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023010",
      "brgy_name": "Cabantog",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023011",
      "brgy_name": "Calmay",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023012",
      "brgy_name": "Canawili",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023013",
      "brgy_name": "Canawillian",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023014",
      "brgy_name": "Caranas",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023015",
      "brgy_name": "Caraudan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023016",
      "brgy_name": "Carigangan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023017",
      "brgy_name": "Cunsad",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023018",
      "brgy_name": "Dabong",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023019",
      "brgy_name": "Damires",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023020",
      "brgy_name": "Damo-ong",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023021",
      "brgy_name": "Danao",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023022",
      "brgy_name": "Gines",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023023",
      "brgy_name": "Guadalupe",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023025",
      "brgy_name": "Jibolo",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023026",
      "brgy_name": "Kuyot",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023027",
      "brgy_name": "Madong",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023028",
      "brgy_name": "Manacabac",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023029",
      "brgy_name": "Mangil",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023030",
      "brgy_name": "Matag-ub",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023031",
      "brgy_name": "Monte-Magapa",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023032",
      "brgy_name": "Pangilihan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023033",
      "brgy_name": "Panuran",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023034",
      "brgy_name": "Pararinga",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023035",
      "brgy_name": "Patong-patong",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023036",
      "brgy_name": "Quipot",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023037",
      "brgy_name": "Santo Tomas",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023038",
      "brgy_name": "Sarawag",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023039",
      "brgy_name": "Tambal",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023040",
      "brgy_name": "Tamu-an",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023041",
      "brgy_name": "Tiringanan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023042",
      "brgy_name": "Tolarucan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023043",
      "brgy_name": "Tuburan",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023044",
      "brgy_name": "Ubian",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023045",
      "brgy_name": "Yabon",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023046",
      "brgy_name": "Aquino Nobleza East (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023047",
      "brgy_name": "Aquino Nobleza West (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023048",
      "brgy_name": "R. Armada (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023049",
      "brgy_name": "Concepcion Pob. (D.G. Abordo)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023050",
      "brgy_name": "Golgota (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023051",
      "brgy_name": "Locsin (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023052",
      "brgy_name": "Don T. Lutero Center (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023053",
      "brgy_name": "Don T. Lutero East (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023054",
      "brgy_name": "Don T. Lutero West Pob. (Don T. Lutero North)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023055",
      "brgy_name": "Crispin Salazar North (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023056",
      "brgy_name": "Crispin Salazar South (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023057",
      "brgy_name": "San Julian (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023058",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023059",
      "brgy_name": "Santa Rita (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023060",
      "brgy_name": "Capt. A. Tirador (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063023061",
      "brgy_name": "S. M. Villa (Pob.)",
      "city_code": "063023",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025001",
      "brgy_name": "Agsirab",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025002",
      "brgy_name": "Agtuman",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025003",
      "brgy_name": "Alugmawa",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025004",
      "brgy_name": "Badiangan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025005",
      "brgy_name": "Bogongbong",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025006",
      "brgy_name": "Balagiao",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025007",
      "brgy_name": "Banban",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025008",
      "brgy_name": "Bansag",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025009",
      "brgy_name": "Bayuco",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025010",
      "brgy_name": "Binaba-an Armada",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025011",
      "brgy_name": "Binaba-an Labayno",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025012",
      "brgy_name": "Binaba-an Limoso",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025013",
      "brgy_name": "Binaba-an Portigo",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025014",
      "brgy_name": "Binaba-an Tirador",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025015",
      "brgy_name": "Bonbon",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025016",
      "brgy_name": "Bontoc",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025017",
      "brgy_name": "Buri",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025018",
      "brgy_name": "Burirao",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025019",
      "brgy_name": "Buwang",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025020",
      "brgy_name": "Cabatangan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025021",
      "brgy_name": "Cabugao",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025022",
      "brgy_name": "Cabunlawan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025023",
      "brgy_name": "Caguisanan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025024",
      "brgy_name": "Caloy-Ahan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025025",
      "brgy_name": "Caninguan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025026",
      "brgy_name": "Capangyan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025027",
      "brgy_name": "Cayan Este",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025028",
      "brgy_name": "Cayan Oeste",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025029",
      "brgy_name": "Corot-on",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025030",
      "brgy_name": "Coto",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025031",
      "brgy_name": "Cubay",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025032",
      "brgy_name": "Cunarum",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025033",
      "brgy_name": "Daanbanwa",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025034",
      "brgy_name": "Gines",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025035",
      "brgy_name": "Hipgos",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025036",
      "brgy_name": "Jayubo",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025037",
      "brgy_name": "Jorog",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025038",
      "brgy_name": "Lanot Grande",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025039",
      "brgy_name": "Lanot Pequeño",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025040",
      "brgy_name": "Legayada",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025041",
      "brgy_name": "Lumanay",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025042",
      "brgy_name": "Madarag",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025043",
      "brgy_name": "Magbato",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025044",
      "brgy_name": "Maite Grande",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025045",
      "brgy_name": "Maite Pequeño",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025046",
      "brgy_name": "Malag-it",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025047",
      "brgy_name": "Manaulan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025048",
      "brgy_name": "Maribong",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025049",
      "brgy_name": "Marong",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025050",
      "brgy_name": "Misi",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025051",
      "brgy_name": "Natividad",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025052",
      "brgy_name": "Pajo",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025053",
      "brgy_name": "Pandan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025054",
      "brgy_name": "Panuran",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025055",
      "brgy_name": "Pasig",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025056",
      "brgy_name": "Patag",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025057",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025058",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025059",
      "brgy_name": "Poong",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025060",
      "brgy_name": "Pughanan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025061",
      "brgy_name": "Pungsod",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025062",
      "brgy_name": "Quiling",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025063",
      "brgy_name": "Sagcup",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025064",
      "brgy_name": "San Gregorio",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025065",
      "brgy_name": "Sibacungan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025066",
      "brgy_name": "Sibaguan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025067",
      "brgy_name": "Simsiman",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025068",
      "brgy_name": "Supoc",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025069",
      "brgy_name": "Tampucao",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025070",
      "brgy_name": "Tranghawan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025071",
      "brgy_name": "Tubungan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025072",
      "brgy_name": "Tuburan",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063025073",
      "brgy_name": "Walang",
      "city_code": "063025",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026001",
      "brgy_name": "M.V. Hechanova (Balagon)",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026002",
      "brgy_name": "Bigke",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026003",
      "brgy_name": "Buntatala",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026004",
      "brgy_name": "Cagamutan Norte",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026005",
      "brgy_name": "Cagamutan Sur",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026007",
      "brgy_name": "Calaboa",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026008",
      "brgy_name": "Camangay",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026009",
      "brgy_name": "Cari Mayor",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026010",
      "brgy_name": "Cari Minor",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026011",
      "brgy_name": "Gua-an",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026012",
      "brgy_name": "Guihaman",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026013",
      "brgy_name": "Guinobatan",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026014",
      "brgy_name": "Guintas",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026015",
      "brgy_name": "Lapayon",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026016",
      "brgy_name": "Nabitasan",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026017",
      "brgy_name": "Napnud",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026019",
      "brgy_name": "Poblacion",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063026020",
      "brgy_name": "San Vicente",
      "city_code": "063026",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027001",
      "brgy_name": "Agpipili",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027002",
      "brgy_name": "Alcantara",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027003",
      "brgy_name": "Almeñana",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027004",
      "brgy_name": "Anabo",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027005",
      "brgy_name": "Bankal",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027006",
      "brgy_name": "Buenavista",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027007",
      "brgy_name": "Cabantohan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027008",
      "brgy_name": "Capiñahan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027009",
      "brgy_name": "Dalipe",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027010",
      "brgy_name": "Dapdapan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027011",
      "brgy_name": "Gerongan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027012",
      "brgy_name": "Imbaulan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027013",
      "brgy_name": "Layogbato",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027014",
      "brgy_name": "Marapal",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027015",
      "brgy_name": "Milan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027016",
      "brgy_name": "Nagsulang",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027017",
      "brgy_name": "Nasapahan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027018",
      "brgy_name": "Omio",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027019",
      "brgy_name": "Pacuan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027020",
      "brgy_name": "Poblacion NW Zone",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027021",
      "brgy_name": "Poblacion SE Zone",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027022",
      "brgy_name": "Pontoc",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027023",
      "brgy_name": "San Antonio",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027024",
      "brgy_name": "San Diego",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027025",
      "brgy_name": "San Jose Moto",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027026",
      "brgy_name": "Sepanton",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027027",
      "brgy_name": "Sincua",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027028",
      "brgy_name": "Tabunan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027029",
      "brgy_name": "Tugas",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027030",
      "brgy_name": "Velasco",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063027031",
      "brgy_name": "Yawyawan",
      "city_code": "063027",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028001",
      "brgy_name": "Agboy Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028002",
      "brgy_name": "Agboy Sur",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028003",
      "brgy_name": "Agta",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028004",
      "brgy_name": "Ambulong",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028005",
      "brgy_name": "Anonang",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028006",
      "brgy_name": "Apian",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028007",
      "brgy_name": "Avanzada",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028008",
      "brgy_name": "Awis",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028009",
      "brgy_name": "Ayabang",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028010",
      "brgy_name": "Ayubo",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028011",
      "brgy_name": "Bacolod",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028012",
      "brgy_name": "Baje",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028013",
      "brgy_name": "Banagan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028014",
      "brgy_name": "Barangbang",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028015",
      "brgy_name": "Barasan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028016",
      "brgy_name": "Bayag Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028017",
      "brgy_name": "Bayag Sur",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028018",
      "brgy_name": "Binolbog",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028019",
      "brgy_name": "Biri Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028020",
      "brgy_name": "Biri Sur",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028021",
      "brgy_name": "Bobon",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028022",
      "brgy_name": "Bucari",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028023",
      "brgy_name": "Buenavista",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028024",
      "brgy_name": "Buga",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028025",
      "brgy_name": "Bulad",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028026",
      "brgy_name": "Bulwang",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028027",
      "brgy_name": "Cabolo-an",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028028",
      "brgy_name": "Cabunga-an",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028029",
      "brgy_name": "Cabutongan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028030",
      "brgy_name": "Cagay",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028031",
      "brgy_name": "Camandag",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028032",
      "brgy_name": "Camando",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028033",
      "brgy_name": "Cananaman",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028034",
      "brgy_name": "Capt. Fernando",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028035",
      "brgy_name": "Carara-an",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028036",
      "brgy_name": "Carolina",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028037",
      "brgy_name": "Cawilihan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028038",
      "brgy_name": "Coyugan Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028039",
      "brgy_name": "Coyugan Sur",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028040",
      "brgy_name": "Danao",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028041",
      "brgy_name": "Dorog",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028042",
      "brgy_name": "Dusacan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028043",
      "brgy_name": "Gines",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028044",
      "brgy_name": "Gumboc",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028045",
      "brgy_name": "Igcadios",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028046",
      "brgy_name": "Ingay",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028047",
      "brgy_name": "Isian Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028048",
      "brgy_name": "Isian Victoria",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028049",
      "brgy_name": "Jamog Gines",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028050",
      "brgy_name": "Lanag",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028051",
      "brgy_name": "Lang-og",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028052",
      "brgy_name": "Ligtos",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028053",
      "brgy_name": "Lonoc",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028054",
      "brgy_name": "Lampaya",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028055",
      "brgy_name": "Magcapay",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028056",
      "brgy_name": "Maliao",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028057",
      "brgy_name": "Malublub",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028058",
      "brgy_name": "Manampunay",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028059",
      "brgy_name": "Marirong",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028060",
      "brgy_name": "Mina",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028061",
      "brgy_name": "Mocol",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028062",
      "brgy_name": "Nagbangi",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028063",
      "brgy_name": "Nalbang",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028064",
      "brgy_name": "Odong-odong",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028065",
      "brgy_name": "Oluangan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028066",
      "brgy_name": "Omambong",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028067",
      "brgy_name": "Paoy",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028068",
      "brgy_name": "Pandan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028069",
      "brgy_name": "Panginman",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028070",
      "brgy_name": "Pepe",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028071",
      "brgy_name": "Poblacion",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028072",
      "brgy_name": "Paga",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028073",
      "brgy_name": "Salngan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028074",
      "brgy_name": "Samlague",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028075",
      "brgy_name": "Siol Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028076",
      "brgy_name": "Siol Sur",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028077",
      "brgy_name": "Tacuyong Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028078",
      "brgy_name": "Tacuyong Sur",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028079",
      "brgy_name": "Tagsing",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028080",
      "brgy_name": "Talacuan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028081",
      "brgy_name": "Ticuan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028082",
      "brgy_name": "Tina-an Norte",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028083",
      "brgy_name": "Tina-an Sur",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028084",
      "brgy_name": "Tunguan",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063028085",
      "brgy_name": "Tu-og",
      "city_code": "063028",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029001",
      "brgy_name": "Abay",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029002",
      "brgy_name": "Abilay",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029004",
      "brgy_name": "AGROCEL Pob. (Aguinaldo-Roxas--Celso Mayor)",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029005",
      "brgy_name": "Amerang",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029006",
      "brgy_name": "Bagacay East",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029007",
      "brgy_name": "Bagacay West",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029008",
      "brgy_name": "Bug-ot",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029009",
      "brgy_name": "Bolo",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029010",
      "brgy_name": "Bulay",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029011",
      "brgy_name": "Buntalan",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029012",
      "brgy_name": "Burak",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029013",
      "brgy_name": "Cabangcalan",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029014",
      "brgy_name": "Cabatac",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029015",
      "brgy_name": "Caigon",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029016",
      "brgy_name": "Cananghan",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029017",
      "brgy_name": "Canawili",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029020",
      "brgy_name": "Dagami",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029021",
      "brgy_name": "Daja",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029022",
      "brgy_name": "Dalusan",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029023",
      "brgy_name": "DELCAR Pob. (Delgado-Cartagena)",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029025",
      "brgy_name": "Inabasan",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029026",
      "brgy_name": "Layog",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029027",
      "brgy_name": "Liñagan Calsada",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029028",
      "brgy_name": "Liñagan Tacas",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029029",
      "brgy_name": "Linab",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029030",
      "brgy_name": "MARI Pob. (Mabini-Rizal)",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029031",
      "brgy_name": "Magsaysay",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029032",
      "brgy_name": "Mandog",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029033",
      "brgy_name": "Miapa",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029034",
      "brgy_name": "Nagba",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029035",
      "brgy_name": "Nasaka",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029036",
      "brgy_name": "Naslo-Bucao",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029037",
      "brgy_name": "Nasuli",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029038",
      "brgy_name": "Panalian",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029039",
      "brgy_name": "Piandaan East",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029040",
      "brgy_name": "Piandaan West",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029041",
      "brgy_name": "Pispis",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029042",
      "brgy_name": "Punong",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029044",
      "brgy_name": "Sinubsuban",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029045",
      "brgy_name": "Siwalo",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029046",
      "brgy_name": "Santa Rita",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029047",
      "brgy_name": "Subog",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029048",
      "brgy_name": "THTP Pob. (Taft-Thompson--Hughes-del Pilar)",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029050",
      "brgy_name": "Tigbauan",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029051",
      "brgy_name": "Trangka",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029052",
      "brgy_name": "Tubang",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029053",
      "brgy_name": "Tulahong",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029054",
      "brgy_name": "Tuy-an East",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029055",
      "brgy_name": "Tuy-an West",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063029056",
      "brgy_name": "Ubian",
      "city_code": "063029",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030001",
      "brgy_name": "Agdum",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030002",
      "brgy_name": "Aguiauan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030003",
      "brgy_name": "Alimodias",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030004",
      "brgy_name": "Awang",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030005",
      "brgy_name": "Oya-oy",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030006",
      "brgy_name": "Bacauan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030007",
      "brgy_name": "Bacolod",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030008",
      "brgy_name": "Bagumbayan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030009",
      "brgy_name": "Banbanan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030010",
      "brgy_name": "Banga",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030011",
      "brgy_name": "Bangladan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030012",
      "brgy_name": "Banuyao",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030013",
      "brgy_name": "Baraclayan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030014",
      "brgy_name": "Bariri",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030015",
      "brgy_name": "Baybay Norte (Pob.)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030016",
      "brgy_name": "Baybay Sur (Pob.)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030017",
      "brgy_name": "Belen",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030018",
      "brgy_name": "Bolho (Pob.)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030019",
      "brgy_name": "Bolocaue",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030020",
      "brgy_name": "Buenavista Norte",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030021",
      "brgy_name": "Buenavista Sur",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030022",
      "brgy_name": "Bugtong Lumangan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030023",
      "brgy_name": "Bugtong Naulid",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030024",
      "brgy_name": "Cabalaunan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030025",
      "brgy_name": "Cabangcalan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030026",
      "brgy_name": "Cabunotan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030027",
      "brgy_name": "Cadoldolan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030028",
      "brgy_name": "Cagbang",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030029",
      "brgy_name": "Caitib",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030030",
      "brgy_name": "Calagtangan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030031",
      "brgy_name": "Calampitao",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030032",
      "brgy_name": "Cavite",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030033",
      "brgy_name": "Cawayanan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030034",
      "brgy_name": "Cubay",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030035",
      "brgy_name": "Cubay Ubos",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030036",
      "brgy_name": "Dalije",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030037",
      "brgy_name": "Damilisan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030038",
      "brgy_name": "Dawog",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030039",
      "brgy_name": "Diday",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030040",
      "brgy_name": "Dingle",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030041",
      "brgy_name": "Durog",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030042",
      "brgy_name": "Frantilla",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030043",
      "brgy_name": "Fundacion",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030044",
      "brgy_name": "Gines",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030045",
      "brgy_name": "Guibongan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030046",
      "brgy_name": "Igbita",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030047",
      "brgy_name": "Igbugo",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030048",
      "brgy_name": "Igcabidio",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030050",
      "brgy_name": "Igcabito-on",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030051",
      "brgy_name": "Igcatambor",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030052",
      "brgy_name": "Igdalaquit",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030053",
      "brgy_name": "Igdulaca",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030054",
      "brgy_name": "Igpajo",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030055",
      "brgy_name": "Igpandan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030056",
      "brgy_name": "Igpuro",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030057",
      "brgy_name": "Igpuro-Bariri",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030058",
      "brgy_name": "Igsoligue",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030059",
      "brgy_name": "Igtuba",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030060",
      "brgy_name": "Ilog-ilog",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030061",
      "brgy_name": "Indag-an",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030062",
      "brgy_name": "Kirayan Norte",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030063",
      "brgy_name": "Kirayan Sur",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030064",
      "brgy_name": "Kirayan Tacas",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030065",
      "brgy_name": "La Consolacion",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030066",
      "brgy_name": "Lacadon",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030067",
      "brgy_name": "Lanutan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030068",
      "brgy_name": "Lumangan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030069",
      "brgy_name": "Mabayan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030070",
      "brgy_name": "Maduyo",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030071",
      "brgy_name": "Malagyan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030072",
      "brgy_name": "Mambatad",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030073",
      "brgy_name": "Maninila",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030074",
      "brgy_name": "Maricolcol",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030075",
      "brgy_name": "Maringyan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030076",
      "brgy_name": "Mat-y (Pob.)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030077",
      "brgy_name": "Matalngon",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030078",
      "brgy_name": "Naclub",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030079",
      "brgy_name": "Nam-o Sur",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030080",
      "brgy_name": "Nam-o Norte",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030082",
      "brgy_name": "Narat-an",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030083",
      "brgy_name": "Narorogan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030084",
      "brgy_name": "Naulid",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030085",
      "brgy_name": "Olango",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030086",
      "brgy_name": "Ongyod",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030087",
      "brgy_name": "Onop",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030088",
      "brgy_name": "Oyungan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030089",
      "brgy_name": "Palaca",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030090",
      "brgy_name": "Paro-on",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030091",
      "brgy_name": "Potrido",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030092",
      "brgy_name": "Pudpud",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030093",
      "brgy_name": "Pungtod Monteclaro",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030094",
      "brgy_name": "Pungtod Naulid",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030095",
      "brgy_name": "Sag-on",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030096",
      "brgy_name": "San Fernando",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030097",
      "brgy_name": "San Jose",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030098",
      "brgy_name": "San Rafael",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030099",
      "brgy_name": "Sapa (Miagao)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030100",
      "brgy_name": "Saring",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030101",
      "brgy_name": "Sibucao",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030102",
      "brgy_name": "Taal",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030103",
      "brgy_name": "Tabunacan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030104",
      "brgy_name": "Tacas (Pob.)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030105",
      "brgy_name": "Tambong",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030106",
      "brgy_name": "Tan-agan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030107",
      "brgy_name": "Tatoy",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030108",
      "brgy_name": "Ticdalan",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030109",
      "brgy_name": "Tig-amaga",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030110",
      "brgy_name": "Tig-Apog-Apog",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030111",
      "brgy_name": "Tigbagacay",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030112",
      "brgy_name": "Tiglawa",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030113",
      "brgy_name": "Tigmalapad",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030114",
      "brgy_name": "Tigmarabo",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030115",
      "brgy_name": "To-og",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030116",
      "brgy_name": "Tugura-ao",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030117",
      "brgy_name": "Tumagboc",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030118",
      "brgy_name": "Ubos Ilawod (Pob.)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030119",
      "brgy_name": "Ubos Ilaya (Pob.)",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030120",
      "brgy_name": "Valencia",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063030121",
      "brgy_name": "Wayang",
      "city_code": "063030",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031001",
      "brgy_name": "Abat",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031002",
      "brgy_name": "Agmanaphao",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031003",
      "brgy_name": "Amiroy",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031004",
      "brgy_name": "Badiangan",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031005",
      "brgy_name": "Bangac",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031006",
      "brgy_name": "Cabalabaguan",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031007",
      "brgy_name": "Capul-an",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031008",
      "brgy_name": "Dala",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031009",
      "brgy_name": "Guibuangan",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031010",
      "brgy_name": "Janipa-an West",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031011",
      "brgy_name": "Janipa-an East",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031012",
      "brgy_name": "Mina East (Pob.)",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031013",
      "brgy_name": "Mina West (Pob.)",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031014",
      "brgy_name": "Nasirum",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031015",
      "brgy_name": "Naumuan",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031016",
      "brgy_name": "Singay",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031018",
      "brgy_name": "Talibong Grande",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031019",
      "brgy_name": "Talibong Pequeño",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031020",
      "brgy_name": "Tipolo",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031021",
      "brgy_name": "Tolarucan",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031022",
      "brgy_name": "Tumay",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063031023",
      "brgy_name": "Yugot",
      "city_code": "063031",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032001",
      "brgy_name": "Baclayan",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032002",
      "brgy_name": "Badiang",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032003",
      "brgy_name": "Balabag",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032004",
      "brgy_name": "Bilidan",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032005",
      "brgy_name": "Bita-og Gaja",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032007",
      "brgy_name": "Bololacao",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032008",
      "brgy_name": "Burot",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032009",
      "brgy_name": "Cabilauan",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032010",
      "brgy_name": "Cabugao",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032012",
      "brgy_name": "Cagban",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032013",
      "brgy_name": "Calumbuyan",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032014",
      "brgy_name": "Damires",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032015",
      "brgy_name": "Dawis",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032016",
      "brgy_name": "General Delgado",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032017",
      "brgy_name": "Guinobatan",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032018",
      "brgy_name": "Janipa-an Oeste",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032019",
      "brgy_name": "Jelicuon Este",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032020",
      "brgy_name": "Jelicuon Oeste",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032021",
      "brgy_name": "Pasil",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032022",
      "brgy_name": "Poblacion",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063032023",
      "brgy_name": "Wari-wari",
      "city_code": "063032",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034001",
      "brgy_name": "Abilay Norte",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034002",
      "brgy_name": "Abilay Sur",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034003",
      "brgy_name": "Alegre",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034004",
      "brgy_name": "Batuan Ilaud",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034005",
      "brgy_name": "Batuan Ilaya",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034006",
      "brgy_name": "Bita Norte",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034007",
      "brgy_name": "Bita Sur",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034008",
      "brgy_name": "Botong",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034009",
      "brgy_name": "Buray",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034010",
      "brgy_name": "Cabanbanan",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034011",
      "brgy_name": "Caboloan Norte",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034012",
      "brgy_name": "Caboloan Sur",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034013",
      "brgy_name": "Cadinglian",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034014",
      "brgy_name": "Cagbang",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034015",
      "brgy_name": "Calam-isan",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034016",
      "brgy_name": "Galang",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034017",
      "brgy_name": "Lambuyao",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034018",
      "brgy_name": "Mambog",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034019",
      "brgy_name": "Pakiad",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034020",
      "brgy_name": "Poblacion East",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034021",
      "brgy_name": "Poblacion North",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034022",
      "brgy_name": "Poblacion South",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034023",
      "brgy_name": "Poblacion West",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034024",
      "brgy_name": "Polo Maestra Bita",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034025",
      "brgy_name": "Rizal",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034026",
      "brgy_name": "Salngan",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034027",
      "brgy_name": "Sambaludan",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034028",
      "brgy_name": "San Antonio",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034029",
      "brgy_name": "San Nicolas",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034030",
      "brgy_name": "Santa Clara",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034031",
      "brgy_name": "Santa Monica",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034032",
      "brgy_name": "Santa Rita",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034035",
      "brgy_name": "Tagbac Norte",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034036",
      "brgy_name": "Tagbac Sur",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034037",
      "brgy_name": "Trapiche",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034038",
      "brgy_name": "Tuburan",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063034039",
      "brgy_name": "Turog-Turog",
      "city_code": "063034",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035001",
      "brgy_name": "Agdahon",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035002",
      "brgy_name": "Agdayao",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035003",
      "brgy_name": "Aglalana",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035004",
      "brgy_name": "Agtabo",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035005",
      "brgy_name": "Agtambo",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035006",
      "brgy_name": "Alimono",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035007",
      "brgy_name": "Arac",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035008",
      "brgy_name": "Ayuyan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035009",
      "brgy_name": "Bacuranan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035010",
      "brgy_name": "Bagacay",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035011",
      "brgy_name": "Batu",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035012",
      "brgy_name": "Bayan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035013",
      "brgy_name": "Bitaogan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035014",
      "brgy_name": "Buenavista",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035015",
      "brgy_name": "Buyo",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035016",
      "brgy_name": "Cabunga",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035017",
      "brgy_name": "Cadilang",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035018",
      "brgy_name": "Cairohan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035019",
      "brgy_name": "Dalicanan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035020",
      "brgy_name": "Gemat-y",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035021",
      "brgy_name": "Gemumua-agahon",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035022",
      "brgy_name": "Gegachac",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035023",
      "brgy_name": "Gines Viejo",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035024",
      "brgy_name": "Imbang Grande",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035025",
      "brgy_name": "Jaguimitan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035026",
      "brgy_name": "Libo-o",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035027",
      "brgy_name": "Maasin",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035028",
      "brgy_name": "Magdungao",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035029",
      "brgy_name": "Malag-it Grande",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035030",
      "brgy_name": "Malag-it Pequeño",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035031",
      "brgy_name": "Mambiranan Grande",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035032",
      "brgy_name": "Mambiranan Pequeño",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035033",
      "brgy_name": "Man-it",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035034",
      "brgy_name": "Mantulang",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035035",
      "brgy_name": "Mulapula",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035036",
      "brgy_name": "Nueva Union",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035037",
      "brgy_name": "Pangi",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035038",
      "brgy_name": "Pagaypay",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035039",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035040",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035041",
      "brgy_name": "Punong",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035042",
      "brgy_name": "Quinagaringan Grande",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035043",
      "brgy_name": "Quinagaringan Pequeño",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035044",
      "brgy_name": "Sablogon",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035045",
      "brgy_name": "Salngan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035046",
      "brgy_name": "Santo Tomas",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035047",
      "brgy_name": "Sarapan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035049",
      "brgy_name": "Tagubong",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035050",
      "brgy_name": "Talongonan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035051",
      "brgy_name": "Tubod",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063035052",
      "brgy_name": "Tuburan",
      "city_code": "063035",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036001",
      "brgy_name": "Aganan",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036002",
      "brgy_name": "Amparo",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036003",
      "brgy_name": "Anilao",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036004",
      "brgy_name": "Balabag",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036005",
      "brgy_name": "Purok I (Pob.)",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036006",
      "brgy_name": "Purok II (Pob.)",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036007",
      "brgy_name": "Purok III (Pob.)",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036008",
      "brgy_name": "Purok IV (Pob.)",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036009",
      "brgy_name": "Cabugao Norte",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036010",
      "brgy_name": "Cabugao Sur",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036011",
      "brgy_name": "Jibao-an",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036012",
      "brgy_name": "Mali-ao",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036013",
      "brgy_name": "Pagsanga-an",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036014",
      "brgy_name": "Pandac",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036017",
      "brgy_name": "Tigum",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036018",
      "brgy_name": "Ungka I",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036019",
      "brgy_name": "Ungka II",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063036020",
      "brgy_name": "Pal-agon",
      "city_code": "063036",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037001",
      "brgy_name": "Abangay",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037002",
      "brgy_name": "Amamaros",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037003",
      "brgy_name": "Bagacay",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037004",
      "brgy_name": "Barasan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037005",
      "brgy_name": "Batuan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037006",
      "brgy_name": "Bongco",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037007",
      "brgy_name": "Cahaguichican",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037008",
      "brgy_name": "Callan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037009",
      "brgy_name": "Cansilayan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037010",
      "brgy_name": "Casalsagan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037011",
      "brgy_name": "Cato-ogan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037012",
      "brgy_name": "Cau-ayan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037013",
      "brgy_name": "Culob",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037014",
      "brgy_name": "Danao",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037015",
      "brgy_name": "Dapitan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037016",
      "brgy_name": "Dawis",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037019",
      "brgy_name": "Dongsol",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037020",
      "brgy_name": "Fundacion",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037021",
      "brgy_name": "Guinacas",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037022",
      "brgy_name": "Guibuangan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037023",
      "brgy_name": "Igang",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037024",
      "brgy_name": "Intaluan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037025",
      "brgy_name": "Iwa Ilaud",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037026",
      "brgy_name": "Iwa Ilaya",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037027",
      "brgy_name": "Jamabalud",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037028",
      "brgy_name": "Jebioc",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037030",
      "brgy_name": "Lay-Ahan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037031",
      "brgy_name": "Primitivo Ledesma Ward (Pob.)",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037032",
      "brgy_name": "Lopez Jaena Ward (Pob.)",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037033",
      "brgy_name": "Lumbo",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037034",
      "brgy_name": "Macatol",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037035",
      "brgy_name": "Malusgod",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037036",
      "brgy_name": "Naslo",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037037",
      "brgy_name": "Nabitasan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037038",
      "brgy_name": "Naga",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037039",
      "brgy_name": "Nanga",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037040",
      "brgy_name": "Pajo",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037041",
      "brgy_name": "Palanguia",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037042",
      "brgy_name": "Fernando Parcon Ward (Pob.)",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037044",
      "brgy_name": "Pitogo",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037045",
      "brgy_name": "Polot-an",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037046",
      "brgy_name": "Purog",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037047",
      "brgy_name": "Rumbang",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037048",
      "brgy_name": "San Jose Ward (Pob.)",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037049",
      "brgy_name": "Sinuagan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037050",
      "brgy_name": "Tuburan",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037051",
      "brgy_name": "Tumcon Ilaya",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037052",
      "brgy_name": "Tumcon Ilaud",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037053",
      "brgy_name": "Ubang",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063037054",
      "brgy_name": "Zarrague",
      "city_code": "063037",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038001",
      "brgy_name": "Agdaliran",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038002",
      "brgy_name": "Amayong",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038003",
      "brgy_name": "Bagacay",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038004",
      "brgy_name": "Batuan",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038005",
      "brgy_name": "Bondulan",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038006",
      "brgy_name": "Boroñgon",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038007",
      "brgy_name": "Canas",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038008",
      "brgy_name": "Capinang",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038009",
      "brgy_name": "Cubay",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038010",
      "brgy_name": "Cudionan",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038011",
      "brgy_name": "Dugman",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038012",
      "brgy_name": "Hacienda Conchita",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038013",
      "brgy_name": "Madanlog",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038014",
      "brgy_name": "Mandu-awak",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038015",
      "brgy_name": "Moto",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038016",
      "brgy_name": "Naborot",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038017",
      "brgy_name": "Nipa",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038018",
      "brgy_name": "Odiongan",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038019",
      "brgy_name": "Pangi",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038020",
      "brgy_name": "Pase",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038021",
      "brgy_name": "Poblacion",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038022",
      "brgy_name": "San Nicolas",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038024",
      "brgy_name": "Santol",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038025",
      "brgy_name": "Siempreviva",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038026",
      "brgy_name": "Sua",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038027",
      "brgy_name": "Talo-ato",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038028",
      "brgy_name": "Tamangi",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038029",
      "brgy_name": "Tiabas",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063038030",
      "brgy_name": "Tuble",
      "city_code": "063038",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039001",
      "brgy_name": "Abaca",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039002",
      "brgy_name": "Asisig",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039003",
      "brgy_name": "Bantayan",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039004",
      "brgy_name": "Braulan",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039005",
      "brgy_name": "Cabugao Nuevo",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039006",
      "brgy_name": "Cabugao Viejo",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039007",
      "brgy_name": "Camiri",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039008",
      "brgy_name": "Compo",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039009",
      "brgy_name": "Catan-Agan",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039010",
      "brgy_name": "Cubay",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039011",
      "brgy_name": "Dacal",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039012",
      "brgy_name": "Dumiles",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039013",
      "brgy_name": "Garita",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039014",
      "brgy_name": "Gines Nuevo",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039015",
      "brgy_name": "Imbang Pequeño",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039016",
      "brgy_name": "Imbesad-an",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039017",
      "brgy_name": "Iprog",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039018",
      "brgy_name": "Lip-ac",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039019",
      "brgy_name": "Madarag",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039020",
      "brgy_name": "Mapili",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039021",
      "brgy_name": "Paga",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039022",
      "brgy_name": "Palje",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039023",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039024",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039025",
      "brgy_name": "Quinolpan",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039026",
      "brgy_name": "Rumagayray",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039027",
      "brgy_name": "San Antonio",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063039028",
      "brgy_name": "Tambunac",
      "city_code": "063039",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040001",
      "brgy_name": "Amboyu-an",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040002",
      "brgy_name": "Andres Bonifacio",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040003",
      "brgy_name": "Antalon",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040004",
      "brgy_name": "Bad-as",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040005",
      "brgy_name": "Bagumbayan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040006",
      "brgy_name": "Balabago",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040007",
      "brgy_name": "Baybay",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040008",
      "brgy_name": "Bayunan (Panday Oro)",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040010",
      "brgy_name": "Bolbogan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040011",
      "brgy_name": "Bulho",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040012",
      "brgy_name": "Bucaya",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040013",
      "brgy_name": "Cadluman",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040014",
      "brgy_name": "Cadoldolan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040015",
      "brgy_name": "Camia",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040016",
      "brgy_name": "Camaba-an",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040017",
      "brgy_name": "Cata-an",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040018",
      "brgy_name": "Crossing Dapuyan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040019",
      "brgy_name": "Cubay",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040020",
      "brgy_name": "Cumarascas",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040021",
      "brgy_name": "Dacdacanan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040022",
      "brgy_name": "Danawan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040024",
      "brgy_name": "Doldol",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040025",
      "brgy_name": "Dongoc",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040026",
      "brgy_name": "Escalantera",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040027",
      "brgy_name": "Ginot-an",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040029",
      "brgy_name": "Huna",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040030",
      "brgy_name": "Igbaje",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040031",
      "brgy_name": "Igbangcal",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040032",
      "brgy_name": "Igbinangon",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040033",
      "brgy_name": "Igburi",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040034",
      "brgy_name": "Igcabutong",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040035",
      "brgy_name": "Igcadlum",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040036",
      "brgy_name": "Igcaphang",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040037",
      "brgy_name": "Igcaratong",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040038",
      "brgy_name": "Igcondao",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040039",
      "brgy_name": "Igcores",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040040",
      "brgy_name": "Igdagmay",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040041",
      "brgy_name": "Igdomingding",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040042",
      "brgy_name": "Iglilico",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040043",
      "brgy_name": "Igpayong",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040044",
      "brgy_name": "Jawod",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040045",
      "brgy_name": "Langca",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040046",
      "brgy_name": "Languanan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040047",
      "brgy_name": "Lawigan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040048",
      "brgy_name": "Lomboy",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040049",
      "brgy_name": "Lopez Vito",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040050",
      "brgy_name": "Mabini Norte",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040051",
      "brgy_name": "Mabini Sur",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040053",
      "brgy_name": "Manhara",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040054",
      "brgy_name": "Maninila",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040055",
      "brgy_name": "Masagud",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040056",
      "brgy_name": "Matambog",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040057",
      "brgy_name": "Mayunoc",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040058",
      "brgy_name": "Montinola",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040059",
      "brgy_name": "Nagquirisan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040060",
      "brgy_name": "Nadsadan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040061",
      "brgy_name": "Nagsipit",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040062",
      "brgy_name": "New Gumawan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040063",
      "brgy_name": "Panatan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040064",
      "brgy_name": "Pitogo",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040065",
      "brgy_name": "Purok 1 (Pob.)",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040066",
      "brgy_name": "Purok 2 (Pob.)",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040067",
      "brgy_name": "Purok 3 (Pob.)",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040068",
      "brgy_name": "Purok 4 (Pob.)",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040069",
      "brgy_name": "Purok 5 (Pob.)",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040070",
      "brgy_name": "Qui-anan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040071",
      "brgy_name": "Roma",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040072",
      "brgy_name": "San Luis",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040073",
      "brgy_name": "San Mateo Norte",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040074",
      "brgy_name": "San Mateo Sur",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040075",
      "brgy_name": "Santiago",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040076",
      "brgy_name": "Sinogbuhan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040077",
      "brgy_name": "Siwaragan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040078",
      "brgy_name": "Lomboyan (Santa Ana)",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040079",
      "brgy_name": "Santa Rita",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040080",
      "brgy_name": "Talagutac",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040081",
      "brgy_name": "Tapikan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040082",
      "brgy_name": "Taslan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040083",
      "brgy_name": "Tiglawa",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040084",
      "brgy_name": "Tiolas",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040085",
      "brgy_name": "To-og",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040086",
      "brgy_name": "Torocadan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040087",
      "brgy_name": "Ulay",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040088",
      "brgy_name": "Bonga",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063040089",
      "brgy_name": "Guibongan Bayunan",
      "city_code": "063040",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041001",
      "brgy_name": "Bgy. 1 Pob. (Roxas St.)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041002",
      "brgy_name": "Bgy. 10 (Pob. R.V.Sanchez St. South)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041003",
      "brgy_name": "Bgy. 11 Pob. (R.V.Sanchez St. North)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041004",
      "brgy_name": "Bgy. 12 Pob. (Sales Malaga Saliedo)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041005",
      "brgy_name": "Bgy. 13 Pob. (Sta.Rita-Saclauso St.)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041006",
      "brgy_name": "Bgy. 14 Pob. (San Miguel North)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041007",
      "brgy_name": "Bgy. 15 Pob. (San Miguel South)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041008",
      "brgy_name": "Bgy. 16 Pob. (San Agustin St.)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041009",
      "brgy_name": "Bgy. 2 Pob. (Savilla Sto. Rosario)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041010",
      "brgy_name": "Bgy. 3 Pob. (A.S.Suarez St. Zone 1)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041011",
      "brgy_name": "Bgy. 4 Pob. (A.S.Suarez East)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041012",
      "brgy_name": "Bgy. 5 Pob. (Santiago St.North)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041013",
      "brgy_name": "Bgy. 6 Pob. (Santiago St.South)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041014",
      "brgy_name": "Bgy. 7 Pob. (San Roque St.South)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041015",
      "brgy_name": "Bgy. 8 Pob. (Montano - San Roque)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041016",
      "brgy_name": "Bgy. 9 Pob. (Salazar San Jose)",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041017",
      "brgy_name": "Consolacion",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041018",
      "brgy_name": "Igtambo",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041019",
      "brgy_name": "San Antonio",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041020",
      "brgy_name": "San Jose",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041021",
      "brgy_name": "Santa Cruz",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041022",
      "brgy_name": "Santa Teresa",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041023",
      "brgy_name": "Santo Angel",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063041024",
      "brgy_name": "Santo Niño",
      "city_code": "063041",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042001",
      "brgy_name": "Aripdip",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042002",
      "brgy_name": "Bagacay",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042003",
      "brgy_name": "Calaigang",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042004",
      "brgy_name": "Ilongbukid",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042005",
      "brgy_name": "Poscolon",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042007",
      "brgy_name": "San Andres",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042008",
      "brgy_name": "San Dionisio",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042009",
      "brgy_name": "San Florentino",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063042010",
      "brgy_name": "Poblacion",
      "city_code": "063042",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043001",
      "brgy_name": "Agusipan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043002",
      "brgy_name": "Agutayan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043003",
      "brgy_name": "Bagumbayan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043004",
      "brgy_name": "Balabag",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043005",
      "brgy_name": "Balibagan Este",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043006",
      "brgy_name": "Balibagan Oeste",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043007",
      "brgy_name": "Ban-ag",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043008",
      "brgy_name": "Bantay",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043009",
      "brgy_name": "Barangay Zone I (Pob.)",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043010",
      "brgy_name": "Barangay Zone II (Pob.)",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043011",
      "brgy_name": "Barangay Zone III (Pob.)",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043012",
      "brgy_name": "Barangay Zone IV (Pob.)",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043013",
      "brgy_name": "Barangay Zone V (Pob.)",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043014",
      "brgy_name": "Barasan Este",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043015",
      "brgy_name": "Barasan Oeste",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043016",
      "brgy_name": "Binangkilan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043017",
      "brgy_name": "Bitaog-Taytay",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043018",
      "brgy_name": "Bolong Este",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043019",
      "brgy_name": "Bolong Oeste",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043020",
      "brgy_name": "Buayahon",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043021",
      "brgy_name": "Buyo",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043022",
      "brgy_name": "Cabugao Norte",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043023",
      "brgy_name": "Cabugao Sur",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043024",
      "brgy_name": "Cadagmayan Norte",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043025",
      "brgy_name": "Cadagmayan Sur",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043026",
      "brgy_name": "Cafe",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043027",
      "brgy_name": "Calaboa Este",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043028",
      "brgy_name": "Calaboa Oeste",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043029",
      "brgy_name": "Camambugan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043030",
      "brgy_name": "Canipayan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043032",
      "brgy_name": "Conaynay",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043033",
      "brgy_name": "Daga",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043034",
      "brgy_name": "Dalid",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043035",
      "brgy_name": "Duyanduyan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043036",
      "brgy_name": "Gen. Martin T. Delgado",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043037",
      "brgy_name": "Guno",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043038",
      "brgy_name": "Inangayan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043039",
      "brgy_name": "Jibao-an",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043040",
      "brgy_name": "Lacadon",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043041",
      "brgy_name": "Lanag",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043042",
      "brgy_name": "Lupa",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043043",
      "brgy_name": "Magancina",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043044",
      "brgy_name": "Malawog",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043045",
      "brgy_name": "Mambuyo",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043046",
      "brgy_name": "Manhayang",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043047",
      "brgy_name": "Miraga-Guibuangan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043048",
      "brgy_name": "Nasugban",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043049",
      "brgy_name": "Omambog",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043050",
      "brgy_name": "Pal-Agon",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043051",
      "brgy_name": "Pungsod",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043052",
      "brgy_name": "San Sebastian",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043053",
      "brgy_name": "Sangcate",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043054",
      "brgy_name": "Tagsing",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043055",
      "brgy_name": "Talanghauan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043056",
      "brgy_name": "Talongadian",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043057",
      "brgy_name": "Tigtig",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043058",
      "brgy_name": "Tungay",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043059",
      "brgy_name": "Tuburan",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043060",
      "brgy_name": "Tugas",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063043061",
      "brgy_name": "Barangay Zone VI (Pob.)",
      "city_code": "063043",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044001",
      "brgy_name": "Aguirre",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044002",
      "brgy_name": "Aldeguer",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044003",
      "brgy_name": "Alibayog",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044004",
      "brgy_name": "Anoring",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044005",
      "brgy_name": "Apelo",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044006",
      "brgy_name": "Apologista",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044007",
      "brgy_name": "Aposaga",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044008",
      "brgy_name": "Arante",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044009",
      "brgy_name": "Ardemil",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044010",
      "brgy_name": "Aspera",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044011",
      "brgy_name": "Aswe-Pabriaga",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044012",
      "brgy_name": "Bagaygay",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044013",
      "brgy_name": "Bakabak",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044014",
      "brgy_name": "Batitao",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044015",
      "brgy_name": "Bato",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044016",
      "brgy_name": "Del Castillo",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044017",
      "brgy_name": "Castor",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044018",
      "brgy_name": "Crespo",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044020",
      "brgy_name": "Devera",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044021",
      "brgy_name": "Domingo",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044022",
      "brgy_name": "Ferraris",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044023",
      "brgy_name": "Gildore",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044024",
      "brgy_name": "Improgo",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044025",
      "brgy_name": "Juaneza",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044026",
      "brgy_name": "Labigan",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044027",
      "brgy_name": "Lanciola",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044028",
      "brgy_name": "Latawan",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044029",
      "brgy_name": "Malapaya",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044030",
      "brgy_name": "Muyco",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044031",
      "brgy_name": "Padios",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044032",
      "brgy_name": "Pasig",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044033",
      "brgy_name": "Poblacion Ilawod",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044034",
      "brgy_name": "Poblacion Ilaya",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044035",
      "brgy_name": "Poblacion Market",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044036",
      "brgy_name": "Posadas",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044037",
      "brgy_name": "Preciosa",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044038",
      "brgy_name": "Salcedo",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044039",
      "brgy_name": "San Luis",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044040",
      "brgy_name": "Tady",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044041",
      "brgy_name": "Tentay",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044042",
      "brgy_name": "Villahermosa",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063044043",
      "brgy_name": "Zerrudo",
      "city_code": "063044",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045001",
      "brgy_name": "Alupidian",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045002",
      "brgy_name": "Atabayan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045003",
      "brgy_name": "Bagacay",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045004",
      "brgy_name": "Baguingin",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045005",
      "brgy_name": "Bagumbayan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045006",
      "brgy_name": "Bangkal",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045007",
      "brgy_name": "Bantud",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045008",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045009",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045010",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045011",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045012",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045013",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045014",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045015",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045016",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045017",
      "brgy_name": "Barosong",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045018",
      "brgy_name": "Barroc",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045019",
      "brgy_name": "Bayuco",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045020",
      "brgy_name": "Binaliuan Mayor",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045021",
      "brgy_name": "Binaliuan Menor",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045022",
      "brgy_name": "Bitas",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045023",
      "brgy_name": "Buenavista",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045024",
      "brgy_name": "Bugasongan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045025",
      "brgy_name": "Buyu-an",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045026",
      "brgy_name": "Canabuan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045027",
      "brgy_name": "Cansilayan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045028",
      "brgy_name": "Cordova Norte",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045029",
      "brgy_name": "Cordova Sur",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045030",
      "brgy_name": "Danao",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045031",
      "brgy_name": "Dapdap",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045032",
      "brgy_name": "Dorong-an",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045033",
      "brgy_name": "Guisian",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045034",
      "brgy_name": "Isawan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045035",
      "brgy_name": "Isian",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045036",
      "brgy_name": "Jamog",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045037",
      "brgy_name": "Lanag",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045038",
      "brgy_name": "Linobayan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045039",
      "brgy_name": "Lubog",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045040",
      "brgy_name": "Nagba",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045041",
      "brgy_name": "Namocon",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045042",
      "brgy_name": "Napnapan Norte",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045043",
      "brgy_name": "Napnapan Sur",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045044",
      "brgy_name": "Olo Barroc",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045045",
      "brgy_name": "Parara Norte",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045046",
      "brgy_name": "Parara Sur",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045047",
      "brgy_name": "San Rafael",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045048",
      "brgy_name": "Sermon",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045049",
      "brgy_name": "Sipitan",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045050",
      "brgy_name": "Supa",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045051",
      "brgy_name": "Tan Pael",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063045052",
      "brgy_name": "Taro",
      "city_code": "063045",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046001",
      "brgy_name": "Adgao",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046002",
      "brgy_name": "Ago",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046003",
      "brgy_name": "Ambarihon",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046004",
      "brgy_name": "Ayubo",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046005",
      "brgy_name": "Bacan",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046006",
      "brgy_name": "Bagunanay",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046007",
      "brgy_name": "Badiang",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046008",
      "brgy_name": "Balicua",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046009",
      "brgy_name": "Bantayanan",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046010",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046011",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046012",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046013",
      "brgy_name": "Batga",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046014",
      "brgy_name": "Bato",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046015",
      "brgy_name": "Bikil",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046016",
      "brgy_name": "Boloc",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046017",
      "brgy_name": "Bondoc",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046018",
      "brgy_name": "Borong",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046019",
      "brgy_name": "Buenavista",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046020",
      "brgy_name": "Cadabdab",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046021",
      "brgy_name": "Daga-ay",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046022",
      "brgy_name": "Desposorio",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046023",
      "brgy_name": "Igdampog Norte",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046024",
      "brgy_name": "Igdampog Sur",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046025",
      "brgy_name": "Igpaho",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046026",
      "brgy_name": "Igtuble",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046027",
      "brgy_name": "Ingay",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046028",
      "brgy_name": "Isauan",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046029",
      "brgy_name": "Jolason",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046030",
      "brgy_name": "Jona",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046031",
      "brgy_name": "La-ag",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046032",
      "brgy_name": "Lanag Norte",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046033",
      "brgy_name": "Lanag Sur",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046034",
      "brgy_name": "Male",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046035",
      "brgy_name": "Mayang",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046036",
      "brgy_name": "Molina",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046037",
      "brgy_name": "Morcillas",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046038",
      "brgy_name": "Nagba",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046039",
      "brgy_name": "Navillan",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046040",
      "brgy_name": "Pinamacalan",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046041",
      "brgy_name": "San Jose",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046042",
      "brgy_name": "Sibucauan",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046043",
      "brgy_name": "Singon",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046044",
      "brgy_name": "Tabat",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046045",
      "brgy_name": "Tagpu-an",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046046",
      "brgy_name": "Talento",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046047",
      "brgy_name": "Teniente Benito",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063046048",
      "brgy_name": "Victoria",
      "city_code": "063046",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047001",
      "brgy_name": "Balud Lilo-an",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047002",
      "brgy_name": "Balud I",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047003",
      "brgy_name": "Balud II",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047004",
      "brgy_name": "Dawis Centro",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047005",
      "brgy_name": "Dawis Norte",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047006",
      "brgy_name": "Dawis Sur",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047007",
      "brgy_name": "Gines",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047008",
      "brgy_name": "Inagdangan Centro",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047009",
      "brgy_name": "Inagdangan Norte",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047010",
      "brgy_name": "Inagdangan Sur",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047011",
      "brgy_name": "Jalaud Norte",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047012",
      "brgy_name": "Jalaud Sur",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047013",
      "brgy_name": "Libongcogon",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047014",
      "brgy_name": "Malunang",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047015",
      "brgy_name": "Pajo",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047016",
      "brgy_name": "Ilawod Poblacion",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047017",
      "brgy_name": "Ilaya Poblacion",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047018",
      "brgy_name": "Sambag",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047019",
      "brgy_name": "Sigangao",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047020",
      "brgy_name": "Talauguis",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047021",
      "brgy_name": "Talibong",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047022",
      "brgy_name": "Tubigan",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047023",
      "brgy_name": "Tuburan",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "063047024",
      "brgy_name": "Tuburan Sulbod",
      "city_code": "063047",
      "province_code": "0630",
      "region_code": "06"
    },
    {
      "brgy_code": "064501001",
      "brgy_name": "Alangilan",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501002",
      "brgy_name": "Alijis",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501003",
      "brgy_name": "Banago",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501004",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501005",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501006",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501007",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501008",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501009",
      "brgy_name": "Barangay 14 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501010",
      "brgy_name": "Barangay 15 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501011",
      "brgy_name": "Barangay 16 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501012",
      "brgy_name": "Barangay 17 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501013",
      "brgy_name": "Barangay 18 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501014",
      "brgy_name": "Barangay 19 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501015",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501016",
      "brgy_name": "Barangay 20 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501017",
      "brgy_name": "Barangay 21 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501018",
      "brgy_name": "Barangay 22 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501019",
      "brgy_name": "Barangay 23 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501020",
      "brgy_name": "Barangay 24 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501021",
      "brgy_name": "Barangay 25 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501022",
      "brgy_name": "Barangay 26 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501023",
      "brgy_name": "Barangay 27 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501024",
      "brgy_name": "Barangay 28 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501025",
      "brgy_name": "Barangay 29 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501026",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501027",
      "brgy_name": "Barangay 30 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501028",
      "brgy_name": "Barangay 31 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501029",
      "brgy_name": "Barangay 32 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501030",
      "brgy_name": "Barangay 33 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501031",
      "brgy_name": "Barangay 34 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501032",
      "brgy_name": "Barangay 35 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501033",
      "brgy_name": "Barangay 36 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501034",
      "brgy_name": "Barangay 37 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501035",
      "brgy_name": "Barangay 38 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501036",
      "brgy_name": "Barangay 39 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501037",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501038",
      "brgy_name": "Barangay 40 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501039",
      "brgy_name": "Barangay 41 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501040",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501041",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501042",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501043",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501044",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501045",
      "brgy_name": "Bata",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501046",
      "brgy_name": "Cabug",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501047",
      "brgy_name": "Estefania",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501048",
      "brgy_name": "Felisa",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501049",
      "brgy_name": "Granada",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501050",
      "brgy_name": "Mandalagan",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501051",
      "brgy_name": "Mansilingan",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501052",
      "brgy_name": "Montevista",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501053",
      "brgy_name": "Pahanocoy",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501054",
      "brgy_name": "Punta Taytay",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501055",
      "brgy_name": "Singcang-Airport",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501056",
      "brgy_name": "Sum-ag",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501057",
      "brgy_name": "Taculing",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501058",
      "brgy_name": "Tangub",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501059",
      "brgy_name": "Villamonte",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501060",
      "brgy_name": "Vista Alegre",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064501061",
      "brgy_name": "Handumanan",
      "city_code": "064501",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502001",
      "brgy_name": "Abuanan",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502002",
      "brgy_name": "Alianza",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502003",
      "brgy_name": "Atipuluan",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502004",
      "brgy_name": "Bacong-Montilla",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502005",
      "brgy_name": "Bagroy",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502006",
      "brgy_name": "Balingasag",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502007",
      "brgy_name": "Binubuhan",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502008",
      "brgy_name": "Busay",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502009",
      "brgy_name": "Calumangan",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502010",
      "brgy_name": "Caridad",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502011",
      "brgy_name": "Dulao",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502012",
      "brgy_name": "Ilijan",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502013",
      "brgy_name": "Lag-Asan",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502014",
      "brgy_name": "Ma-ao Barrio",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502015",
      "brgy_name": "Jorge L. Araneta (Ma-ao Central)",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502016",
      "brgy_name": "Mailum",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502017",
      "brgy_name": "Malingin",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502018",
      "brgy_name": "Napoles",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502019",
      "brgy_name": "Pacol",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502020",
      "brgy_name": "Poblacion",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502021",
      "brgy_name": "Sagasa",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502022",
      "brgy_name": "Tabunan",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502023",
      "brgy_name": "Taloc",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064502024",
      "brgy_name": "Sampinit",
      "city_code": "064502",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503001",
      "brgy_name": "Amontay",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503002",
      "brgy_name": "Bagroy",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503003",
      "brgy_name": "Bi-ao",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503004",
      "brgy_name": "Canmoros (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503005",
      "brgy_name": "Enclaro",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503006",
      "brgy_name": "Marina (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503007",
      "brgy_name": "Paglaum (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503008",
      "brgy_name": "Payao",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503009",
      "brgy_name": "Progreso (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503010",
      "brgy_name": "San Jose",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503011",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503012",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503013",
      "brgy_name": "San Teodoro (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503014",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503015",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064503016",
      "brgy_name": "Santol",
      "city_code": "064503",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504001",
      "brgy_name": "Andres Bonifacio",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504002",
      "brgy_name": "Banquerohan",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504003",
      "brgy_name": "Barangay 1 Pob. (Zone 1)",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504004",
      "brgy_name": "Barangay 2 Pob. (Zone 2)",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504005",
      "brgy_name": "Barangay 3 Pob. (Zone 3)",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504006",
      "brgy_name": "Barangay 4 Pob. (Zone 4)",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504007",
      "brgy_name": "Barangay 5 Pob. (Zone 5)",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504008",
      "brgy_name": "Barangay 6 Pob. (Zone 6)",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504009",
      "brgy_name": "Burgos",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504010",
      "brgy_name": "Cabahug",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504011",
      "brgy_name": "Cadiz Viejo",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504012",
      "brgy_name": "Caduha-an",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504013",
      "brgy_name": "Celestino Villacin",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504014",
      "brgy_name": "Daga",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504015",
      "brgy_name": "V. F. Gustilo",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504016",
      "brgy_name": "Jerusalem",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504017",
      "brgy_name": "Luna",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504018",
      "brgy_name": "Mabini",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504019",
      "brgy_name": "Magsaysay",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504021",
      "brgy_name": "Sicaba",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504022",
      "brgy_name": "Tiglawigan",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064504023",
      "brgy_name": "Tinampa-an",
      "city_code": "064504",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505001",
      "brgy_name": "Agpangi",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505002",
      "brgy_name": "Ani-e",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505003",
      "brgy_name": "Bagacay",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505004",
      "brgy_name": "Bantayanon",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505005",
      "brgy_name": "Buenavista",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505006",
      "brgy_name": "Cabungahan",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505007",
      "brgy_name": "Calampisawan",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505008",
      "brgy_name": "Cambayobo",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505009",
      "brgy_name": "Castellano",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505010",
      "brgy_name": "Cruz",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505011",
      "brgy_name": "Dolis",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505012",
      "brgy_name": "Hilub-Ang",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505013",
      "brgy_name": "Hinab-Ongan",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505014",
      "brgy_name": "Ilaya",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505015",
      "brgy_name": "Laga-an",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505016",
      "brgy_name": "Lalong",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505017",
      "brgy_name": "Lemery",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505018",
      "brgy_name": "Lipat-on",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505019",
      "brgy_name": "Lo-ok (Pob.)",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505020",
      "brgy_name": "Ma-aslob",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505021",
      "brgy_name": "Macasilao",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505022",
      "brgy_name": "Malanog",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505023",
      "brgy_name": "Malatas",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505024",
      "brgy_name": "Marcelo",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505025",
      "brgy_name": "Mina-utok",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505026",
      "brgy_name": "Menchaca",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505027",
      "brgy_name": "Minapasuk",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505028",
      "brgy_name": "Mahilum",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505029",
      "brgy_name": "Paghumayan",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505030",
      "brgy_name": "Pantao",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505031",
      "brgy_name": "Patun-an",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505032",
      "brgy_name": "Pinocutan",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505034",
      "brgy_name": "Refugio",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505035",
      "brgy_name": "San Benito",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505036",
      "brgy_name": "San Isidro",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505037",
      "brgy_name": "Suba (Pob.)",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505038",
      "brgy_name": "Telim",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505039",
      "brgy_name": "Tigbao",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505040",
      "brgy_name": "Tigbon",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064505041",
      "brgy_name": "Winaswasan",
      "city_code": "064505",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506001",
      "brgy_name": "Agboy",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506002",
      "brgy_name": "Banga",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506003",
      "brgy_name": "Cabia-an",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506004",
      "brgy_name": "Caningay",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506005",
      "brgy_name": "Gatuslao",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506006",
      "brgy_name": "Haba",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506007",
      "brgy_name": "Payauan",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506008",
      "brgy_name": "Poblacion East",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064506009",
      "brgy_name": "Poblacion West",
      "city_code": "064506",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507001",
      "brgy_name": "Abaca",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507002",
      "brgy_name": "Baclao",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507004",
      "brgy_name": "Poblacion",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507007",
      "brgy_name": "Basak",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507008",
      "brgy_name": "Bulata",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507009",
      "brgy_name": "Caliling",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507010",
      "brgy_name": "Camalanda-an",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507012",
      "brgy_name": "Camindangan",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507013",
      "brgy_name": "Elihan",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507014",
      "brgy_name": "Guiljungan",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507015",
      "brgy_name": "Inayawan",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507016",
      "brgy_name": "Isio",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507017",
      "brgy_name": "Linaon",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507018",
      "brgy_name": "Lumbia",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507020",
      "brgy_name": "Mambugsay",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507021",
      "brgy_name": "Man-Uling",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507022",
      "brgy_name": "Masaling",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507023",
      "brgy_name": "Molobolo",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507025",
      "brgy_name": "Sura",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507027",
      "brgy_name": "Talacdan",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507028",
      "brgy_name": "Tambad",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507029",
      "brgy_name": "Tiling",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507030",
      "brgy_name": "Tomina",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507031",
      "brgy_name": "Tuyom",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064507032",
      "brgy_name": "Yao-yao",
      "city_code": "064507",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508001",
      "brgy_name": "Alacaygan",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508002",
      "brgy_name": "Alicante",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508003",
      "brgy_name": "Poblacion I (Barangay 1)",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508004",
      "brgy_name": "Poblacion II (Barangay 2)",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508005",
      "brgy_name": "Poblacion III (Barangay 3)",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508006",
      "brgy_name": "Batea",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508007",
      "brgy_name": "Consing",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508008",
      "brgy_name": "Cudangdang",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508009",
      "brgy_name": "Damgo",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508010",
      "brgy_name": "Gahit",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508011",
      "brgy_name": "Canlusong",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508012",
      "brgy_name": "Latasan",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508013",
      "brgy_name": "Madalag",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508014",
      "brgy_name": "Manta-angan",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508015",
      "brgy_name": "Nanca",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508016",
      "brgy_name": "Pasil",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508017",
      "brgy_name": "San Isidro",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508018",
      "brgy_name": "San Jose",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508019",
      "brgy_name": "Santo Niño",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508020",
      "brgy_name": "Tabigue",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508021",
      "brgy_name": "Tanza",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508022",
      "brgy_name": "Tuburan",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064508023",
      "brgy_name": "Tomongtong",
      "city_code": "064508",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509001",
      "brgy_name": "Alimango",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509002",
      "brgy_name": "Balintawak (Pob.)",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509003",
      "brgy_name": "Magsaysay (Binabongol)",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509004",
      "brgy_name": "Binaguiohan",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509005",
      "brgy_name": "Buenavista",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509006",
      "brgy_name": "Cervantes",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509007",
      "brgy_name": "Dian-ay",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509008",
      "brgy_name": "Hacienda Fe",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509009",
      "brgy_name": "Jonobjonob",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509010",
      "brgy_name": "Japitan",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509011",
      "brgy_name": "Langub",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509012",
      "brgy_name": "Libertad",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509013",
      "brgy_name": "Mabini",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509014",
      "brgy_name": "Malasibog",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509015",
      "brgy_name": "Paitan",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509016",
      "brgy_name": "Pinapugasan",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509017",
      "brgy_name": "Old Poblacion",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509018",
      "brgy_name": "Rizal",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509019",
      "brgy_name": "Tamlang",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509020",
      "brgy_name": "Udtongan",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064509021",
      "brgy_name": "Washington",
      "city_code": "064509",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510001",
      "brgy_name": "Aguisan",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510002",
      "brgy_name": "Buenavista",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510003",
      "brgy_name": "Cabadiangan",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510004",
      "brgy_name": "Cabanbanan",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510005",
      "brgy_name": "Carabalan",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510006",
      "brgy_name": "Caradio-an",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510007",
      "brgy_name": "Libacao",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510008",
      "brgy_name": "Mambagaton",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510009",
      "brgy_name": "Nabali-an",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510010",
      "brgy_name": "Mahalang",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510011",
      "brgy_name": "San Antonio",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510012",
      "brgy_name": "Sara-et",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510013",
      "brgy_name": "Su-ay",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510014",
      "brgy_name": "Talaban",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510015",
      "brgy_name": "To-oy",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510016",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510017",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510018",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064510019",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "064510",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511001",
      "brgy_name": "Anahaw",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511002",
      "brgy_name": "Aranda",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511003",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511004",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511005",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511006",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511007",
      "brgy_name": "Bato",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511009",
      "brgy_name": "Calapi",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511010",
      "brgy_name": "Camalobalo",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511011",
      "brgy_name": "Camba-og",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511012",
      "brgy_name": "Cambugsa",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511013",
      "brgy_name": "Candumarao",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511014",
      "brgy_name": "Gargato",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511015",
      "brgy_name": "Himaya",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511016",
      "brgy_name": "Miranda",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511017",
      "brgy_name": "Nanunga",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511018",
      "brgy_name": "Narauis",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511019",
      "brgy_name": "Palayog",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511020",
      "brgy_name": "Paticui",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511021",
      "brgy_name": "Pilar",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511022",
      "brgy_name": "Quiwi",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511023",
      "brgy_name": "Tagda",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511024",
      "brgy_name": "Tuguis",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064511025",
      "brgy_name": "Baga-as",
      "city_code": "064511",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512001",
      "brgy_name": "Alim",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512002",
      "brgy_name": "Asia",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512003",
      "brgy_name": "Bacuyangan",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512004",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512005",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512006",
      "brgy_name": "Bulwangan",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512007",
      "brgy_name": "Culipapa",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512008",
      "brgy_name": "Damutan",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512009",
      "brgy_name": "Daug",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512010",
      "brgy_name": "Po-ok",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512011",
      "brgy_name": "San Rafael",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512012",
      "brgy_name": "Sangke",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064512013",
      "brgy_name": "Talacagay",
      "city_code": "064512",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513001",
      "brgy_name": "Andulauan",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513002",
      "brgy_name": "Balicotoc",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513004",
      "brgy_name": "Bocana",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513006",
      "brgy_name": "Calubang",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513007",
      "brgy_name": "Canlamay",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513008",
      "brgy_name": "Consuelo",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513009",
      "brgy_name": "Dancalan",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513010",
      "brgy_name": "Delicioso",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513011",
      "brgy_name": "Galicia",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513012",
      "brgy_name": "Manalad",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513013",
      "brgy_name": "Pinggot",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513014",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513015",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513016",
      "brgy_name": "Tabu",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064513018",
      "brgy_name": "Vista Alegre",
      "city_code": "064513",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514001",
      "brgy_name": "Amin",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514002",
      "brgy_name": "Banogbanog",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514003",
      "brgy_name": "Bulad",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514004",
      "brgy_name": "Bungahin",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514005",
      "brgy_name": "Cabcab",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514006",
      "brgy_name": "Camangcamang",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514007",
      "brgy_name": "Camp Clark",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514008",
      "brgy_name": "Cansalongon",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514009",
      "brgy_name": "Guintubhan",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514010",
      "brgy_name": "Libas",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514011",
      "brgy_name": "Limalima",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514012",
      "brgy_name": "Makilignit",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514013",
      "brgy_name": "Mansablay",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514014",
      "brgy_name": "Maytubig",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514015",
      "brgy_name": "Panaquiao",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514016",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514017",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514018",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514019",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514020",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514021",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514022",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514023",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514024",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514025",
      "brgy_name": "Riverside",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514026",
      "brgy_name": "Rumirang",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514027",
      "brgy_name": "San Agustin",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514028",
      "brgy_name": "Sebucawan",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514029",
      "brgy_name": "Sikatuna",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064514030",
      "brgy_name": "Tinongan",
      "city_code": "064514",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515001",
      "brgy_name": "Bantayan",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515002",
      "brgy_name": "Binicuil",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515003",
      "brgy_name": "Camansi",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515004",
      "brgy_name": "Camingawan",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515005",
      "brgy_name": "Camugao",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515006",
      "brgy_name": "Carol-an",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515007",
      "brgy_name": "Daan Banua",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515008",
      "brgy_name": "Hilamonan",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515009",
      "brgy_name": "Inapoy",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515010",
      "brgy_name": "Linao",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515011",
      "brgy_name": "Locotan",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515012",
      "brgy_name": "Magballo",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515013",
      "brgy_name": "Oringao",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515014",
      "brgy_name": "Orong",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515015",
      "brgy_name": "Pinaguinpinan",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515016",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515017",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515018",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515019",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515020",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515021",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515023",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515024",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515025",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515026",
      "brgy_name": "Salong",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515027",
      "brgy_name": "Tabugon",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515028",
      "brgy_name": "Tagoc",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515029",
      "brgy_name": "Talubangi",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515030",
      "brgy_name": "Tampalon",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515031",
      "brgy_name": "Tan-Awan",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515032",
      "brgy_name": "Tapi",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064515033",
      "brgy_name": "Tagukon",
      "city_code": "064515",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516001",
      "brgy_name": "Ara-al",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516002",
      "brgy_name": "Ayungon",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516003",
      "brgy_name": "Balabag",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516004",
      "brgy_name": "Batuan",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516005",
      "brgy_name": "Roberto S. Benedicto (Consuelo)",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516006",
      "brgy_name": "Cubay",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516007",
      "brgy_name": "Haguimit",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516008",
      "brgy_name": "La Granja",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516009",
      "brgy_name": "Nagasi",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516010",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516011",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516012",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516013",
      "brgy_name": "San Miguel",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064516014",
      "brgy_name": "Yubo",
      "city_code": "064516",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517001",
      "brgy_name": "Biaknabato",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517002",
      "brgy_name": "Cabacungan",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517004",
      "brgy_name": "Cabagnaan",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517005",
      "brgy_name": "Camandag",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517006",
      "brgy_name": "Lalagsan",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517007",
      "brgy_name": "Manghanoy",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517008",
      "brgy_name": "Mansalanao",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517009",
      "brgy_name": "Masulog",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517010",
      "brgy_name": "Nato",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517011",
      "brgy_name": "Puso",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517012",
      "brgy_name": "Robles (Pob.)",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517013",
      "brgy_name": "Sag-Ang",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064517016",
      "brgy_name": "Talaptap",
      "city_code": "064517",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518001",
      "brgy_name": "Chambery",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518003",
      "brgy_name": "Barangay I-A (Pob.)",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518004",
      "brgy_name": "Barangay I-B (Pob.)",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518005",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518006",
      "brgy_name": "Barangay II-A (Pob.)",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518007",
      "brgy_name": "Punta Mesa",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518008",
      "brgy_name": "Punta Salong",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518009",
      "brgy_name": "Purisima",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518010",
      "brgy_name": "San Pablo",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518011",
      "brgy_name": "Santa Teresa",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064518012",
      "brgy_name": "Tortosa",
      "city_code": "064518",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519002",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519003",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519004",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519005",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519006",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519007",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519008",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519009",
      "brgy_name": "Crossing Magallon",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519010",
      "brgy_name": "Guinpana-an",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519011",
      "brgy_name": "Inolingan",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519012",
      "brgy_name": "Macagahay",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519013",
      "brgy_name": "Magallon Cadre",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519014",
      "brgy_name": "Montilla",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519015",
      "brgy_name": "Odiong",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064519016",
      "brgy_name": "Quintin Remo",
      "city_code": "064519",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520001",
      "brgy_name": "Abo-abo",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520002",
      "brgy_name": "Alegria",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520003",
      "brgy_name": "Amayco",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520004",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520005",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520006",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520007",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520008",
      "brgy_name": "Zone V (Pob.)",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520009",
      "brgy_name": "Blumentritt",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520010",
      "brgy_name": "Buenavista",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520011",
      "brgy_name": "Caliban",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520012",
      "brgy_name": "Canlandog",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520013",
      "brgy_name": "Cansilayan",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520014",
      "brgy_name": "Damsite",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520015",
      "brgy_name": "Iglau-an",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520018",
      "brgy_name": "Lopez Jaena",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520019",
      "brgy_name": "Minoyan",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520020",
      "brgy_name": "Pandanon",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520021",
      "brgy_name": "San Miguel",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520022",
      "brgy_name": "Santa Cruz",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520023",
      "brgy_name": "Santa Rosa",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520024",
      "brgy_name": "Salvacion",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064520025",
      "brgy_name": "Talotog",
      "city_code": "064520",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521001",
      "brgy_name": "Antipolo",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521003",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521004",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521006",
      "brgy_name": "Buenavista Gibong",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521007",
      "brgy_name": "Buenavista Rizal",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521008",
      "brgy_name": "Burgos",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521009",
      "brgy_name": "Cambarus",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521010",
      "brgy_name": "Canroma",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521011",
      "brgy_name": "Don Salvador Benedicto (Carmen)",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521012",
      "brgy_name": "General Malvar",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521013",
      "brgy_name": "Gomez",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521014",
      "brgy_name": "M. H. Del Pilar",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521015",
      "brgy_name": "Mabini",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521016",
      "brgy_name": "Miranda",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521017",
      "brgy_name": "Pandan",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521018",
      "brgy_name": "Recreo",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521019",
      "brgy_name": "San Isidro",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521020",
      "brgy_name": "San Juan",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064521021",
      "brgy_name": "Zamora",
      "city_code": "064521",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522001",
      "brgy_name": "Barangay Zone 1-A (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522002",
      "brgy_name": "Barangay Zone 4-A (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522003",
      "brgy_name": "Barangay Zone 1 (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522004",
      "brgy_name": "Barangay Zone 2 (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522005",
      "brgy_name": "Barangay Zone 3 (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522006",
      "brgy_name": "Barangay Zone 4 (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522007",
      "brgy_name": "Barangay Zone 5 (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522008",
      "brgy_name": "Barangay Zone 6 (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522009",
      "brgy_name": "Barangay Zone 7 (Pob.)",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522010",
      "brgy_name": "Canjusa",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522011",
      "brgy_name": "Crossing Pulupandan",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522012",
      "brgy_name": "Culo",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522013",
      "brgy_name": "Mabini",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522014",
      "brgy_name": "Pag-ayon",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522015",
      "brgy_name": "Palaka Norte",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522016",
      "brgy_name": "Palaka Sur",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522017",
      "brgy_name": "Patic",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522018",
      "brgy_name": "Tapong",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522019",
      "brgy_name": "Ubay",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064522020",
      "brgy_name": "Utod",
      "city_code": "064522",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523001",
      "brgy_name": "Andres Bonifacio",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523002",
      "brgy_name": "Bato",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523003",
      "brgy_name": "Baviera",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523004",
      "brgy_name": "Bulanon",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523005",
      "brgy_name": "Campo Himoga-an",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523006",
      "brgy_name": "Campo Santiago",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523007",
      "brgy_name": "Colonia Divina",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523008",
      "brgy_name": "Fabrica",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523009",
      "brgy_name": "General Luna",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523010",
      "brgy_name": "Himoga-an Baybay",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523011",
      "brgy_name": "Lopez Jaena",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523012",
      "brgy_name": "Malubon",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523013",
      "brgy_name": "Makiling",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523014",
      "brgy_name": "Molocaboc",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523015",
      "brgy_name": "Old Sagay",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523016",
      "brgy_name": "Paraiso",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523017",
      "brgy_name": "Plaridel",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523018",
      "brgy_name": "Poblacion I (Barangay 1)",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523019",
      "brgy_name": "Poblacion II (Barangay 2)",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523020",
      "brgy_name": "Puey",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523021",
      "brgy_name": "Rizal",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523022",
      "brgy_name": "Taba-ao",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523023",
      "brgy_name": "Tadlong",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523025",
      "brgy_name": "Vito",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064523026",
      "brgy_name": "Rafaela Barrera",
      "city_code": "064523",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524001",
      "brgy_name": "Bagonbon",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524002",
      "brgy_name": "Buluangan",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524004",
      "brgy_name": "Codcod",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524005",
      "brgy_name": "Ermita",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524006",
      "brgy_name": "Guadalupe",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524008",
      "brgy_name": "Nataban",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524009",
      "brgy_name": "Palampas",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524010",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524011",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524012",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524013",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524014",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524015",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524016",
      "brgy_name": "Prosperidad",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524017",
      "brgy_name": "Punao",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524018",
      "brgy_name": "Quezon",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524019",
      "brgy_name": "Rizal",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064524020",
      "brgy_name": "San Juan",
      "city_code": "064524",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525001",
      "brgy_name": "Bagonawa",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525002",
      "brgy_name": "Baliwagan",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525003",
      "brgy_name": "Batuan",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525004",
      "brgy_name": "Guintorilan",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525005",
      "brgy_name": "Nayon",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525006",
      "brgy_name": "Poblacion",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525007",
      "brgy_name": "Sibucao",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525008",
      "brgy_name": "Tabao Baybay",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525009",
      "brgy_name": "Tabao Rizal",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064525010",
      "brgy_name": "Tibsoc",
      "city_code": "064525",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526001",
      "brgy_name": "Balaring",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526003",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526004",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526005",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526006",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526007",
      "brgy_name": "Barangay VI Pob. (Hawaiian)",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526008",
      "brgy_name": "Eustaquio Lopez",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526010",
      "brgy_name": "Guimbala-on",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526011",
      "brgy_name": "Guinhalaran",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526012",
      "brgy_name": "Kapitan Ramon",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526013",
      "brgy_name": "Lantad",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526015",
      "brgy_name": "Mambulac",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526016",
      "brgy_name": "Rizal",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526017",
      "brgy_name": "Bagtic",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064526018",
      "brgy_name": "Patag",
      "city_code": "064526",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527001",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527002",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527003",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527004",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527005",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527006",
      "brgy_name": "Cabadiangan",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527007",
      "brgy_name": "Camindangan",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527008",
      "brgy_name": "Canturay",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527009",
      "brgy_name": "Cartagena",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527010",
      "brgy_name": "Cayhagan",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527011",
      "brgy_name": "Gil Montilla",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527012",
      "brgy_name": "Mambaroto",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527013",
      "brgy_name": "Manlucahoc",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527014",
      "brgy_name": "Maricalum",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527015",
      "brgy_name": "Nabulao",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527016",
      "brgy_name": "Nauhang",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064527017",
      "brgy_name": "San Jose",
      "city_code": "064527",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528001",
      "brgy_name": "Bubog",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528002",
      "brgy_name": "Cabatangan",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528003",
      "brgy_name": "Zone 4-A (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528004",
      "brgy_name": "Zone 4 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528007",
      "brgy_name": "Concepcion",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528010",
      "brgy_name": "Dos Hermanas",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528011",
      "brgy_name": "Efigenio Lizares",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528012",
      "brgy_name": "Zone 7 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528013",
      "brgy_name": "Zone 14-B (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528015",
      "brgy_name": "Zone 12-A (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528018",
      "brgy_name": "Zone 10 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528020",
      "brgy_name": "Zone 5 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528021",
      "brgy_name": "Zone 16 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528023",
      "brgy_name": "Matab-ang",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528025",
      "brgy_name": "Zone 9 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528027",
      "brgy_name": "Zone 6 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528029",
      "brgy_name": "Zone 14 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528030",
      "brgy_name": "San Fernando",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528032",
      "brgy_name": "Zone 15 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528033",
      "brgy_name": "Zone 14-A (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528034",
      "brgy_name": "Zone 11 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528035",
      "brgy_name": "Zone 8 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528038",
      "brgy_name": "Zone 12 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528040",
      "brgy_name": "Zone 1 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528041",
      "brgy_name": "Zone 2 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528042",
      "brgy_name": "Zone 3 (Pob.)",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064528043",
      "brgy_name": "Katilingban",
      "city_code": "064528",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529001",
      "brgy_name": "Bandila",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529002",
      "brgy_name": "Bug-ang",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529003",
      "brgy_name": "General Luna",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529004",
      "brgy_name": "Magticol",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529005",
      "brgy_name": "Poblacion",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529006",
      "brgy_name": "Salamanca",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529007",
      "brgy_name": "San Isidro",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529008",
      "brgy_name": "San Jose",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064529009",
      "brgy_name": "Tabun-ac",
      "city_code": "064529",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530001",
      "brgy_name": "Alijis",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530002",
      "brgy_name": "Ayungon",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530003",
      "brgy_name": "Bagumbayan",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530004",
      "brgy_name": "Batuan",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530005",
      "brgy_name": "Bayabas",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530006",
      "brgy_name": "Central Tabao",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530007",
      "brgy_name": "Doldol",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530008",
      "brgy_name": "Guintorilan",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530010",
      "brgy_name": "Lacaron",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530011",
      "brgy_name": "Mabini",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530012",
      "brgy_name": "Pacol",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530013",
      "brgy_name": "Palaka",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530014",
      "brgy_name": "Paloma",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530015",
      "brgy_name": "Poblacion",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530016",
      "brgy_name": "Sagua Banua",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064530017",
      "brgy_name": "Tabao Proper",
      "city_code": "064530",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531004",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531005",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531006",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531007",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531008",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531009",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531010",
      "brgy_name": "Barangay VII (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531011",
      "brgy_name": "Barangay VIII (Pob.)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531012",
      "brgy_name": "Barangay IX (Daan Banwa)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531013",
      "brgy_name": "Barangay X (Estado)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531014",
      "brgy_name": "Barangay XI (Gawahon)",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531015",
      "brgy_name": "Barangay XII",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531016",
      "brgy_name": "Barangay XIII",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531017",
      "brgy_name": "Barangay XIV",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531018",
      "brgy_name": "Barangay XV",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531019",
      "brgy_name": "Barangay XV-A",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531020",
      "brgy_name": "Barangay XVI",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531021",
      "brgy_name": "Barangay XVI-A",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531022",
      "brgy_name": "Barangay XVII",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531023",
      "brgy_name": "Barangay XVIII",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531024",
      "brgy_name": "Barangay XVIII-A",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531025",
      "brgy_name": "Barangay XIX",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531026",
      "brgy_name": "Barangay XIX-A",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531027",
      "brgy_name": "Barangay XX",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531028",
      "brgy_name": "Barangay XXI",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064531029",
      "brgy_name": "Barangay VI-A",
      "city_code": "064531",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064532001",
      "brgy_name": "Bago (Lalung)",
      "city_code": "064532",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064532002",
      "brgy_name": "Bagong Silang (Marcelo)",
      "city_code": "064532",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064532003",
      "brgy_name": "Bunga",
      "city_code": "064532",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064532004",
      "brgy_name": "Igmaya-an",
      "city_code": "064532",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064532005",
      "brgy_name": "Kumaliskis",
      "city_code": "064532",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064532006",
      "brgy_name": "Pandanon",
      "city_code": "064532",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "064532007",
      "brgy_name": "Pinowayan (Prosperidad)",
      "city_code": "064532",
      "province_code": "0645",
      "region_code": "06"
    },
    {
      "brgy_code": "067901001",
      "brgy_name": "Agsanayan",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901002",
      "brgy_name": "Avila",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901003",
      "brgy_name": "Banban",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901005",
      "brgy_name": "Bacjao (Calumingan)",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901006",
      "brgy_name": "Cansilayan",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901007",
      "brgy_name": "Dagsa-an",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901008",
      "brgy_name": "Daragan",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901009",
      "brgy_name": "East Valencia",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901011",
      "brgy_name": "Getulio",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901014",
      "brgy_name": "Mabini",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901015",
      "brgy_name": "Magsaysay",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901016",
      "brgy_name": "Mclain",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901017",
      "brgy_name": "Montpiller",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901018",
      "brgy_name": "Navalas",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901019",
      "brgy_name": "Nazaret",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901020",
      "brgy_name": "New Poblacion (Calingao)",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901021",
      "brgy_name": "Old Poblacion",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901022",
      "brgy_name": "Piña",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901023",
      "brgy_name": "Rizal",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901024",
      "brgy_name": "Salvacion",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901025",
      "brgy_name": "San Fernando",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901026",
      "brgy_name": "San Isidro",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901027",
      "brgy_name": "San Miguel",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901028",
      "brgy_name": "San Nicolas",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901029",
      "brgy_name": "San Pedro",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901030",
      "brgy_name": "San Roque",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901031",
      "brgy_name": "Santo Rosario",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901032",
      "brgy_name": "Sawang",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901034",
      "brgy_name": "Supang",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901035",
      "brgy_name": "Tacay",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901036",
      "brgy_name": "Taminla",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901037",
      "brgy_name": "Tanag",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901038",
      "brgy_name": "Tastasan",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901039",
      "brgy_name": "Tinadtaran",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901040",
      "brgy_name": "Umilig",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067901041",
      "brgy_name": "Zaldivar",
      "city_code": "067901",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902002",
      "brgy_name": "Alaguisoc",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902005",
      "brgy_name": "Balcon Maravilla",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902006",
      "brgy_name": "Balcon Melliza",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902008",
      "brgy_name": "Bugnay",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902009",
      "brgy_name": "Buluangan",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902013",
      "brgy_name": "Espinosa",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902014",
      "brgy_name": "Hoskyn",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902017",
      "brgy_name": "Lawi",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902020",
      "brgy_name": "Morobuan",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902021",
      "brgy_name": "Poblacion",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902023",
      "brgy_name": "Rizal",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902026",
      "brgy_name": "San Miguel",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902030",
      "brgy_name": "Sinapsapan",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067902031",
      "brgy_name": "Santa Teresa",
      "city_code": "067902",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903001",
      "brgy_name": "Cabalagnan",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903002",
      "brgy_name": "Calaya",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903003",
      "brgy_name": "Canhawan",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903004",
      "brgy_name": "Concordia Sur",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903005",
      "brgy_name": "Dolores",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903006",
      "brgy_name": "Guiwanon",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903007",
      "brgy_name": "Igang",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903008",
      "brgy_name": "Igdarapdap",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903009",
      "brgy_name": "La Paz",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903010",
      "brgy_name": "Lanipe",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903011",
      "brgy_name": "Lucmayan",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903012",
      "brgy_name": "Magamay",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903013",
      "brgy_name": "Napandong",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903014",
      "brgy_name": "Oracon Sur",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903015",
      "brgy_name": "Pandaraonan",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903016",
      "brgy_name": "Panobolon",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903017",
      "brgy_name": "Poblacion",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903018",
      "brgy_name": "Salvacion",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903019",
      "brgy_name": "San Antonio",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903020",
      "brgy_name": "San Roque",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903021",
      "brgy_name": "Santo Domingo",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067903022",
      "brgy_name": "Tando",
      "city_code": "067903",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904001",
      "brgy_name": "Aguilar",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904002",
      "brgy_name": "Cabano",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904003",
      "brgy_name": "Cabungahan",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904004",
      "brgy_name": "Constancia",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904005",
      "brgy_name": "Gaban",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904006",
      "brgy_name": "Igcawayan",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904007",
      "brgy_name": "M. Chavez",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904008",
      "brgy_name": "San Enrique (Lebas)",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904009",
      "brgy_name": "Sapal",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904010",
      "brgy_name": "Sebario",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904011",
      "brgy_name": "Suclaran",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067904012",
      "brgy_name": "Tamborong",
      "city_code": "067904",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905001",
      "brgy_name": "Alegria",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905002",
      "brgy_name": "Ayangan",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905003",
      "brgy_name": "Bubog",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905004",
      "brgy_name": "Concordia Norte",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905005",
      "brgy_name": "Dasal",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905006",
      "brgy_name": "Inampologan",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905007",
      "brgy_name": "Maabay",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905008",
      "brgy_name": "Millan",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905009",
      "brgy_name": "Oracon Norte",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905010",
      "brgy_name": "Ravina",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905011",
      "brgy_name": "Sabang",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905012",
      "brgy_name": "San Isidro",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905013",
      "brgy_name": "Sebaste",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "067905014",
      "brgy_name": "Tanglad",
      "city_code": "067905",
      "province_code": "0679",
      "region_code": "06"
    },
    {
      "brgy_code": "071201001",
      "brgy_name": "Bahi",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201002",
      "brgy_name": "Basacdacu",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201003",
      "brgy_name": "Cantiguib",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201004",
      "brgy_name": "Dangay",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201005",
      "brgy_name": "East Poblacion",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201006",
      "brgy_name": "Ponong",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201007",
      "brgy_name": "San Agustin",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201008",
      "brgy_name": "Santa Filomena",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201009",
      "brgy_name": "Tagbuane",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201010",
      "brgy_name": "Toril",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071201011",
      "brgy_name": "West Poblacion",
      "city_code": "071201",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202001",
      "brgy_name": "Cabatang",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202002",
      "brgy_name": "Cagongcagong",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202003",
      "brgy_name": "Cambaol",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202004",
      "brgy_name": "Cayacay",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202005",
      "brgy_name": "Del Monte",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202006",
      "brgy_name": "Katipunan",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202007",
      "brgy_name": "La Hacienda",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202008",
      "brgy_name": "Mahayag",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202009",
      "brgy_name": "Napo",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202010",
      "brgy_name": "Pagahat",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202011",
      "brgy_name": "Poblacion (Calingganay)",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202012",
      "brgy_name": "Progreso",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202013",
      "brgy_name": "Putlongcam",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202014",
      "brgy_name": "Sudlon (Omhor)",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071202015",
      "brgy_name": "Untaga",
      "city_code": "071202",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203001",
      "brgy_name": "Almaria",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203002",
      "brgy_name": "Bacong",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203003",
      "brgy_name": "Badiang",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203004",
      "brgy_name": "Buenasuerte",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203005",
      "brgy_name": "Candabong",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203006",
      "brgy_name": "Casica",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203007",
      "brgy_name": "Katipunan",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203008",
      "brgy_name": "Linawan",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203009",
      "brgy_name": "Lundag",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203010",
      "brgy_name": "Poblacion",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203011",
      "brgy_name": "Santa Cruz",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203012",
      "brgy_name": "Suba",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203013",
      "brgy_name": "Talisay",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203014",
      "brgy_name": "Tanod",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203015",
      "brgy_name": "Tawid",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071203016",
      "brgy_name": "Virgen",
      "city_code": "071203",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204001",
      "brgy_name": "Angilan",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204002",
      "brgy_name": "Bantolinao",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204003",
      "brgy_name": "Bicahan",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204004",
      "brgy_name": "Bitaugan",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204005",
      "brgy_name": "Bungahan",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204007",
      "brgy_name": "Canlaas",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204008",
      "brgy_name": "Cansibuan",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204009",
      "brgy_name": "Can-omay",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204010",
      "brgy_name": "Celing",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204011",
      "brgy_name": "Danao",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204012",
      "brgy_name": "Danicop",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204013",
      "brgy_name": "Mag-aso",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204014",
      "brgy_name": "Poblacion",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204015",
      "brgy_name": "Quinapon-an",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204016",
      "brgy_name": "Santo Rosario",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204017",
      "brgy_name": "Tabuan",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204018",
      "brgy_name": "Tagubaas",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204019",
      "brgy_name": "Tupas",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204020",
      "brgy_name": "Ubojan",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204021",
      "brgy_name": "Viga",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071204022",
      "brgy_name": "Villa Aurora (Canoc-oc)",
      "city_code": "071204",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205001",
      "brgy_name": "Cambanac",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205002",
      "brgy_name": "Dasitam",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205003",
      "brgy_name": "Buenaventura",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205004",
      "brgy_name": "Guiwanon",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205005",
      "brgy_name": "Landican",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205006",
      "brgy_name": "Laya",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205007",
      "brgy_name": "Libertad",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205008",
      "brgy_name": "Montana",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205009",
      "brgy_name": "Pamilacan",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205010",
      "brgy_name": "Payahan",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205011",
      "brgy_name": "Poblacion",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205012",
      "brgy_name": "San Isidro",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205013",
      "brgy_name": "San Roque",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205014",
      "brgy_name": "San Vicente",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205015",
      "brgy_name": "Santa Cruz",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205016",
      "brgy_name": "Taguihon",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071205017",
      "brgy_name": "Tanday",
      "city_code": "071205",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206001",
      "brgy_name": "Baucan Norte",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206002",
      "brgy_name": "Baucan Sur",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206003",
      "brgy_name": "Boctol",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206004",
      "brgy_name": "Boyog Norte",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206005",
      "brgy_name": "Boyog Proper",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206006",
      "brgy_name": "Boyog Sur",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206007",
      "brgy_name": "Cabad",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206008",
      "brgy_name": "Candasig",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206009",
      "brgy_name": "Cantalid",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206010",
      "brgy_name": "Cantomimbo",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206011",
      "brgy_name": "Cogon",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206012",
      "brgy_name": "Datag Norte",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206013",
      "brgy_name": "Datag Sur",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206014",
      "brgy_name": "Del Carmen Este (Pob.)",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206015",
      "brgy_name": "Del Carmen Norte (Pob.)",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206016",
      "brgy_name": "Del Carmen Weste (Pob.)",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206017",
      "brgy_name": "Del Carmen Sur (Pob.)",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206018",
      "brgy_name": "Del Rosario",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206019",
      "brgy_name": "Dorol",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206020",
      "brgy_name": "Haguilanan Grande",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206021",
      "brgy_name": "Hanopol Este",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206022",
      "brgy_name": "Hanopol Norte",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206023",
      "brgy_name": "Hanopol Weste",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206024",
      "brgy_name": "Magsija",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206025",
      "brgy_name": "Maslog",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206026",
      "brgy_name": "Sagasa",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206027",
      "brgy_name": "Sal-ing",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206028",
      "brgy_name": "San Isidro",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206029",
      "brgy_name": "San Roque",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206030",
      "brgy_name": "Santo Niño",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071206031",
      "brgy_name": "Tagustusan",
      "city_code": "071206",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207001",
      "brgy_name": "Aloja",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207002",
      "brgy_name": "Cabacnitan",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207003",
      "brgy_name": "Cambacay",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207004",
      "brgy_name": "Cantigdas",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207005",
      "brgy_name": "Garcia",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207006",
      "brgy_name": "Janlud",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207008",
      "brgy_name": "Poblacion Norte",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207009",
      "brgy_name": "Poblacion Sur",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207010",
      "brgy_name": "Poblacion Vieja (Longsudaan)",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207011",
      "brgy_name": "Quezon",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207012",
      "brgy_name": "Quirino",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207013",
      "brgy_name": "Rizal",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207014",
      "brgy_name": "Rosariohan",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207016",
      "brgy_name": "Behind The Clouds (San Jose)",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071207017",
      "brgy_name": "Santa Cruz",
      "city_code": "071207",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208001",
      "brgy_name": "Bonifacio",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208002",
      "brgy_name": "Bugang Norte",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208003",
      "brgy_name": "Bugang Sur",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208004",
      "brgy_name": "Cabacnitan (Magsaysay)",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208005",
      "brgy_name": "Cambigsi",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208006",
      "brgy_name": "Campagao",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208007",
      "brgy_name": "Cansumbol",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208008",
      "brgy_name": "Dagohoy",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208009",
      "brgy_name": "Owac",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208010",
      "brgy_name": "Poblacion",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208011",
      "brgy_name": "Quezon",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208012",
      "brgy_name": "Riverside",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208013",
      "brgy_name": "Rizal",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208014",
      "brgy_name": "Roxas",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208015",
      "brgy_name": "Subayon",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208016",
      "brgy_name": "Villa Aurora",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208017",
      "brgy_name": "Villa Suerte",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208018",
      "brgy_name": "Yanaya",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071208019",
      "brgy_name": "Zamora",
      "city_code": "071208",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209001",
      "brgy_name": "Anonang",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209002",
      "brgy_name": "Asinan",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209003",
      "brgy_name": "Bago",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209004",
      "brgy_name": "Baluarte",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209005",
      "brgy_name": "Bantuan",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209006",
      "brgy_name": "Bato",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209007",
      "brgy_name": "Bonotbonot",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209008",
      "brgy_name": "Bugaong",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209011",
      "brgy_name": "Cambuhat",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209012",
      "brgy_name": "Cambus-oc",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209013",
      "brgy_name": "Cangawa",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209014",
      "brgy_name": "Cantomugcad",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209015",
      "brgy_name": "Cantores",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209016",
      "brgy_name": "Cantuba",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209017",
      "brgy_name": "Catigbian",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209018",
      "brgy_name": "Cawag",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209019",
      "brgy_name": "Cruz",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209020",
      "brgy_name": "Dait",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209021",
      "brgy_name": "Eastern Cabul-an",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209022",
      "brgy_name": "Hunan",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209025",
      "brgy_name": "Lapacan Norte",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209026",
      "brgy_name": "Lapacan Sur",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209028",
      "brgy_name": "Lubang",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209029",
      "brgy_name": "Lusong (Plateau)",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209030",
      "brgy_name": "Magkaya",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209031",
      "brgy_name": "Merryland",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209032",
      "brgy_name": "Nueva Granada",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209033",
      "brgy_name": "Nueva Montana",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209034",
      "brgy_name": "Overland",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209035",
      "brgy_name": "Panghagban",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209036",
      "brgy_name": "Poblacion",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209037",
      "brgy_name": "Puting Bato",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209038",
      "brgy_name": "Rufo Hill",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209039",
      "brgy_name": "Sweetland",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071209040",
      "brgy_name": "Western Cabul-an",
      "city_code": "071209",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210001",
      "brgy_name": "Abucayan Norte",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210002",
      "brgy_name": "Abucayan Sur",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210003",
      "brgy_name": "Banlasan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210004",
      "brgy_name": "Bentig",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210005",
      "brgy_name": "Binogawan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210006",
      "brgy_name": "Bonbon",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210007",
      "brgy_name": "Cabayugan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210008",
      "brgy_name": "Cabudburan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210009",
      "brgy_name": "Calunasan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210010",
      "brgy_name": "Camias",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210011",
      "brgy_name": "Canguha",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210012",
      "brgy_name": "Catmonan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210013",
      "brgy_name": "Desamparados (Pob.)",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210014",
      "brgy_name": "Kahayag",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210015",
      "brgy_name": "Kinabag-an",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210016",
      "brgy_name": "Labuon",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210017",
      "brgy_name": "Lawis",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210018",
      "brgy_name": "Liboron",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210019",
      "brgy_name": "Lo-oc",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210020",
      "brgy_name": "Lomboy",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210021",
      "brgy_name": "Lucob",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210022",
      "brgy_name": "Madangog",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210023",
      "brgy_name": "Magtongtong",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210024",
      "brgy_name": "Mandaug",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210025",
      "brgy_name": "Mantatao",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210027",
      "brgy_name": "Sampoangon",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210028",
      "brgy_name": "San Isidro",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210029",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210030",
      "brgy_name": "Sojoton",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210031",
      "brgy_name": "Talisay",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210032",
      "brgy_name": "Tinibgan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210033",
      "brgy_name": "Tultugan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071210034",
      "brgy_name": "Ulbujan",
      "city_code": "071210",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211001",
      "brgy_name": "Abihilan",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211002",
      "brgy_name": "Anoling",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211003",
      "brgy_name": "Boyo-an",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211004",
      "brgy_name": "Cadapdapan",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211005",
      "brgy_name": "Cambane",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211006",
      "brgy_name": "Can-olin",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211007",
      "brgy_name": "Canawa",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211008",
      "brgy_name": "Cogtong",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211009",
      "brgy_name": "La Union",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211010",
      "brgy_name": "Luan",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211011",
      "brgy_name": "Lungsoda-an",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211012",
      "brgy_name": "Mahangin",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211013",
      "brgy_name": "Pagahat",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211014",
      "brgy_name": "Panadtaran",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211015",
      "brgy_name": "Panas",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211016",
      "brgy_name": "Poblacion",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211017",
      "brgy_name": "San Isidro",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211018",
      "brgy_name": "Tambongan",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211019",
      "brgy_name": "Tawid",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211021",
      "brgy_name": "Tugas",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071211022",
      "brgy_name": "Tubod (Tres Rosas)",
      "city_code": "071211",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212001",
      "brgy_name": "Alegria",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212002",
      "brgy_name": "Bicao",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212003",
      "brgy_name": "Buenavista",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212004",
      "brgy_name": "Buenos Aires",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212005",
      "brgy_name": "Calatrava",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212006",
      "brgy_name": "El Progreso",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212007",
      "brgy_name": "El Salvador",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212008",
      "brgy_name": "Guadalupe",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212009",
      "brgy_name": "Katipunan",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212010",
      "brgy_name": "La Libertad",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212011",
      "brgy_name": "La Paz",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212012",
      "brgy_name": "La Salvacion",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212013",
      "brgy_name": "La Victoria",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212014",
      "brgy_name": "Matin-ao",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212015",
      "brgy_name": "Montehermoso",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212016",
      "brgy_name": "Montesuerte",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212017",
      "brgy_name": "Montesunting",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212018",
      "brgy_name": "Montevideo",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212019",
      "brgy_name": "Nueva Fuerza",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212020",
      "brgy_name": "Nueva Vida Este",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212021",
      "brgy_name": "Nueva Vida Sur",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212022",
      "brgy_name": "Nueva Vida Norte",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212023",
      "brgy_name": "Poblacion Norte",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212024",
      "brgy_name": "Poblacion Sur",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212025",
      "brgy_name": "Tambo-an",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212026",
      "brgy_name": "Vallehermoso",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212027",
      "brgy_name": "Villaflor",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212028",
      "brgy_name": "Villafuerte",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071212029",
      "brgy_name": "Villarcayo",
      "city_code": "071212",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213001",
      "brgy_name": "Alegria",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213002",
      "brgy_name": "Ambuan",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213003",
      "brgy_name": "Baang",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213004",
      "brgy_name": "Bagtic",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213005",
      "brgy_name": "Bongbong",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213007",
      "brgy_name": "Cambailan",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213008",
      "brgy_name": "Candumayao",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213009",
      "brgy_name": "Kang-iras",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213011",
      "brgy_name": "Causwagan Norte",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213013",
      "brgy_name": "Hagbuaya",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213014",
      "brgy_name": "Haguilanan",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213015",
      "brgy_name": "Libertad Sur",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213016",
      "brgy_name": "Liboron",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213017",
      "brgy_name": "Mahayag Norte",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213018",
      "brgy_name": "Mahayag Sur",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213019",
      "brgy_name": "Maitum",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213020",
      "brgy_name": "Mantasida",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213021",
      "brgy_name": "Poblacion",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213022",
      "brgy_name": "Rizal",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213025",
      "brgy_name": "Sinakayanan",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213026",
      "brgy_name": "Triple Union",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071213027",
      "brgy_name": "Poblacion Weste",
      "city_code": "071213",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214001",
      "brgy_name": "Bacani",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214002",
      "brgy_name": "Bogtongbod",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214003",
      "brgy_name": "Bonbon",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214004",
      "brgy_name": "Bontud",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214005",
      "brgy_name": "Buacao",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214006",
      "brgy_name": "Buangan",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214007",
      "brgy_name": "Cabog",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214008",
      "brgy_name": "Caboy",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214009",
      "brgy_name": "Caluwasan",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214010",
      "brgy_name": "Candajec",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214011",
      "brgy_name": "Cantoyoc",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214012",
      "brgy_name": "Comaang",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214013",
      "brgy_name": "Danahao",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214014",
      "brgy_name": "Katipunan",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214015",
      "brgy_name": "Lajog",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214016",
      "brgy_name": "Mataub",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214017",
      "brgy_name": "Nahawan",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214018",
      "brgy_name": "Poblacion Centro",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214019",
      "brgy_name": "Poblacion Norte",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214020",
      "brgy_name": "Poblacion Sur",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214021",
      "brgy_name": "Tangaran",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214022",
      "brgy_name": "Tontunan",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214023",
      "brgy_name": "Tubod",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071214024",
      "brgy_name": "Villaflor",
      "city_code": "071214",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215001",
      "brgy_name": "Anislag",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215002",
      "brgy_name": "Canangca-an",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215003",
      "brgy_name": "Canapnapan",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215004",
      "brgy_name": "Cancatac",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215005",
      "brgy_name": "Pandol",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215006",
      "brgy_name": "Poblacion",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215007",
      "brgy_name": "Sambog",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071215008",
      "brgy_name": "Tanday",
      "city_code": "071215",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216001",
      "brgy_name": "De la Paz",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216002",
      "brgy_name": "Fatima",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216003",
      "brgy_name": "Loreto",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216004",
      "brgy_name": "Lourdes",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216005",
      "brgy_name": "Malayo Norte",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216006",
      "brgy_name": "Malayo Sur",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216007",
      "brgy_name": "Monserrat",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216008",
      "brgy_name": "New Lourdes",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216009",
      "brgy_name": "Patrocinio",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216010",
      "brgy_name": "Poblacion",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216011",
      "brgy_name": "Rosario",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216012",
      "brgy_name": "Salvador",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216013",
      "brgy_name": "San Roque",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071216014",
      "brgy_name": "Upper de la Paz",
      "city_code": "071216",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217001",
      "brgy_name": "Babag",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217002",
      "brgy_name": "Can-oling",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217003",
      "brgy_name": "Candelaria",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217004",
      "brgy_name": "Estaca",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217005",
      "brgy_name": "Cagawasan",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217006",
      "brgy_name": "Cagawitan",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217007",
      "brgy_name": "Caluasan",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217008",
      "brgy_name": "La Esperanza",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217009",
      "brgy_name": "Mahayag",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217010",
      "brgy_name": "Malitbog",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217011",
      "brgy_name": "Poblacion",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217012",
      "brgy_name": "San Miguel",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217013",
      "brgy_name": "San Vicente",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217014",
      "brgy_name": "Santa Cruz",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071217015",
      "brgy_name": "Villa Aurora",
      "city_code": "071217",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218001",
      "brgy_name": "Cabatuan",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218002",
      "brgy_name": "Cantubod",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218003",
      "brgy_name": "Carbon",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218004",
      "brgy_name": "Concepcion",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218005",
      "brgy_name": "Dagohoy",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218006",
      "brgy_name": "Hibale",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218007",
      "brgy_name": "Magtangtang",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218008",
      "brgy_name": "Nahud",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218009",
      "brgy_name": "Poblacion",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218010",
      "brgy_name": "Remedios",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218011",
      "brgy_name": "San Carlos",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218012",
      "brgy_name": "San Miguel",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218013",
      "brgy_name": "Santa Fe",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218014",
      "brgy_name": "Santo Niño",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218015",
      "brgy_name": "Tabok",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218016",
      "brgy_name": "Taming",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071218017",
      "brgy_name": "Villa Anunciado",
      "city_code": "071218",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219001",
      "brgy_name": "Biking",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219002",
      "brgy_name": "Bingag",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219003",
      "brgy_name": "San Isidro (Canlongon)",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219004",
      "brgy_name": "Catarman",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219005",
      "brgy_name": "Dao",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219006",
      "brgy_name": "Mayacabac",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219007",
      "brgy_name": "Poblacion",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219008",
      "brgy_name": "Songculan",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219009",
      "brgy_name": "Tabalong",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219010",
      "brgy_name": "Tinago",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219011",
      "brgy_name": "Totolan",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071219012",
      "brgy_name": "Mariveles",
      "city_code": "071219",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220001",
      "brgy_name": "Abihid",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220002",
      "brgy_name": "Alemania",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220003",
      "brgy_name": "Baguhan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220004",
      "brgy_name": "Bakilid",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220005",
      "brgy_name": "Balbalan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220006",
      "brgy_name": "Banban",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220007",
      "brgy_name": "Bauhugan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220008",
      "brgy_name": "Bilisan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220009",
      "brgy_name": "Cabagakian",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220010",
      "brgy_name": "Cabanbanan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220011",
      "brgy_name": "Cadap-agan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220012",
      "brgy_name": "Cambacol",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220013",
      "brgy_name": "Cambayaon",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220014",
      "brgy_name": "Canhayupon",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220015",
      "brgy_name": "Canlambong",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220016",
      "brgy_name": "Casingan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220017",
      "brgy_name": "Catugasan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220018",
      "brgy_name": "Datag",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220019",
      "brgy_name": "Guindaguitan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220020",
      "brgy_name": "Guingoyuran",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220021",
      "brgy_name": "Ile",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220022",
      "brgy_name": "Lapsaon",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220023",
      "brgy_name": "Limokon Ilaod",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220024",
      "brgy_name": "Limokon Ilaya",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220025",
      "brgy_name": "Luyo",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220026",
      "brgy_name": "Malijao",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220027",
      "brgy_name": "Oac",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220028",
      "brgy_name": "Pagsa",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220029",
      "brgy_name": "Pangihawan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220030",
      "brgy_name": "Sawang",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220031",
      "brgy_name": "Puangyuta",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220033",
      "brgy_name": "Tangohay",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220036",
      "brgy_name": "Taongon Cabatuan",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220037",
      "brgy_name": "Tawid Bitaog",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071220038",
      "brgy_name": "Taongon Can-andam",
      "city_code": "071220",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221001",
      "brgy_name": "Alejawan",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221002",
      "brgy_name": "Angilan",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221003",
      "brgy_name": "Anibongan",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221004",
      "brgy_name": "Bangwalog",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221005",
      "brgy_name": "Cansuhay",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221006",
      "brgy_name": "Danao",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221007",
      "brgy_name": "Duay",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221008",
      "brgy_name": "Guinsularan",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221009",
      "brgy_name": "Itum",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221010",
      "brgy_name": "Langkis",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221011",
      "brgy_name": "Lobogon",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221012",
      "brgy_name": "Madua Norte",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221013",
      "brgy_name": "Madua Sur",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221014",
      "brgy_name": "Mambool",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221015",
      "brgy_name": "Mawi",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221016",
      "brgy_name": "Payao",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221017",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221018",
      "brgy_name": "San Isidro",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221019",
      "brgy_name": "San Pedro",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221020",
      "brgy_name": "Imelda",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071221021",
      "brgy_name": "Taytay",
      "city_code": "071221",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222001",
      "brgy_name": "Abijilan",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222002",
      "brgy_name": "Antipolo",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222003",
      "brgy_name": "Basiao",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222004",
      "brgy_name": "Cagwang",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222005",
      "brgy_name": "Calma",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222006",
      "brgy_name": "Cambuyo",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222007",
      "brgy_name": "Canayaon East",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222008",
      "brgy_name": "Canayaon West",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222009",
      "brgy_name": "Candanas",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222010",
      "brgy_name": "Candulao",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222011",
      "brgy_name": "Catmon",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222012",
      "brgy_name": "Cayam",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222013",
      "brgy_name": "Cupa",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222014",
      "brgy_name": "Datag",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222015",
      "brgy_name": "Estaca",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222016",
      "brgy_name": "Libertad",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222017",
      "brgy_name": "Lungsodaan East",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222018",
      "brgy_name": "Lungsodaan West",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222019",
      "brgy_name": "Malinao",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222020",
      "brgy_name": "Manaba",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222021",
      "brgy_name": "Pasong",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222022",
      "brgy_name": "Poblacion East",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222023",
      "brgy_name": "Poblacion West",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222024",
      "brgy_name": "Sacaon",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222025",
      "brgy_name": "Sampong",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222026",
      "brgy_name": "Tabuan",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222027",
      "brgy_name": "Togbongon",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222028",
      "brgy_name": "Ulbujan East",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222029",
      "brgy_name": "Ulbujan West",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071222030",
      "brgy_name": "Victoria",
      "city_code": "071222",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223001",
      "brgy_name": "Basdio",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223002",
      "brgy_name": "Bato",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223003",
      "brgy_name": "Bayong",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223004",
      "brgy_name": "Biabas",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223005",
      "brgy_name": "Bulawan",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223006",
      "brgy_name": "Cabantian",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223007",
      "brgy_name": "Canhaway",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223008",
      "brgy_name": "Cansiwang",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223009",
      "brgy_name": "Casbu",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223010",
      "brgy_name": "Catungawan Sur",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223011",
      "brgy_name": "Catungawan Norte",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223013",
      "brgy_name": "Guinacot",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223014",
      "brgy_name": "Guio-ang",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223016",
      "brgy_name": "Lombog",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223017",
      "brgy_name": "Mayuga",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223018",
      "brgy_name": "Sawang (Pob.)",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223019",
      "brgy_name": "Tabajan (Pob.)",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223021",
      "brgy_name": "Tabunok",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071223022",
      "brgy_name": "Trinidad",
      "city_code": "071223",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224001",
      "brgy_name": "Anonang",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224002",
      "brgy_name": "Bahan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224003",
      "brgy_name": "Badiang",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224005",
      "brgy_name": "Baguhan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224007",
      "brgy_name": "Banahao",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224008",
      "brgy_name": "Baogo",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224009",
      "brgy_name": "Bugang",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224010",
      "brgy_name": "Cagawasan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224011",
      "brgy_name": "Cagayan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224012",
      "brgy_name": "Cambitoon",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224013",
      "brgy_name": "Canlinte",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224014",
      "brgy_name": "Cawayan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224015",
      "brgy_name": "Cogon",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224016",
      "brgy_name": "Cuaming",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224017",
      "brgy_name": "Dagnawan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224018",
      "brgy_name": "Dagohoy",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224019",
      "brgy_name": "Dait Sur",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224020",
      "brgy_name": "Datag",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224021",
      "brgy_name": "Fatima",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224022",
      "brgy_name": "Hambongan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224023",
      "brgy_name": "Ilaud (Pob.)",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224024",
      "brgy_name": "Ilaya",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224025",
      "brgy_name": "Ilihan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224027",
      "brgy_name": "Lapacan Norte",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224028",
      "brgy_name": "Lapacan Sur",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224029",
      "brgy_name": "Lawis",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224030",
      "brgy_name": "Liloan Norte",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224031",
      "brgy_name": "Liloan Sur",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224032",
      "brgy_name": "Lomboy",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224033",
      "brgy_name": "Lonoy Cainsican",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224034",
      "brgy_name": "Lonoy Roma",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224035",
      "brgy_name": "Lutao",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224036",
      "brgy_name": "Luyo",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224037",
      "brgy_name": "Mabuhay",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224038",
      "brgy_name": "Maria Rosario",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224039",
      "brgy_name": "Nabuad",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224040",
      "brgy_name": "Napo",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224041",
      "brgy_name": "Ondol",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224042",
      "brgy_name": "Poblacion",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224043",
      "brgy_name": "Riverside",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224044",
      "brgy_name": "Saa",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224045",
      "brgy_name": "San Isidro",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224046",
      "brgy_name": "San Jose",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224047",
      "brgy_name": "Santo Niño",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224048",
      "brgy_name": "Santo Rosario",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224049",
      "brgy_name": "Sua",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224050",
      "brgy_name": "Tambook",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224051",
      "brgy_name": "Tungod",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224052",
      "brgy_name": "U-og",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071224053",
      "brgy_name": "Ubujan",
      "city_code": "071224",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225001",
      "brgy_name": "Alejawan",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225002",
      "brgy_name": "Balili",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225003",
      "brgy_name": "Boctol",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225004",
      "brgy_name": "Buyog",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225005",
      "brgy_name": "Bunga Ilaya",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225006",
      "brgy_name": "Bunga Mar",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225007",
      "brgy_name": "Cabunga-an",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225008",
      "brgy_name": "Calabacita",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225009",
      "brgy_name": "Cambugason",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225010",
      "brgy_name": "Can-ipol",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225011",
      "brgy_name": "Canjulao",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225012",
      "brgy_name": "Cantagay",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225013",
      "brgy_name": "Cantuyoc",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225014",
      "brgy_name": "Can-uba",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225015",
      "brgy_name": "Can-upao",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225016",
      "brgy_name": "Faraon",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225017",
      "brgy_name": "Ipil",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225018",
      "brgy_name": "Kinagbaan",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225019",
      "brgy_name": "Laca",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225020",
      "brgy_name": "Larapan",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225021",
      "brgy_name": "Lonoy",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225022",
      "brgy_name": "Looc",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225023",
      "brgy_name": "Malbog",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225024",
      "brgy_name": "Mayana",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225025",
      "brgy_name": "Naatang",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225026",
      "brgy_name": "Nausok",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225027",
      "brgy_name": "Odiong",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225028",
      "brgy_name": "Pagina",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225029",
      "brgy_name": "Pangdan",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225030",
      "brgy_name": "Poblacion (Pondol)",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225031",
      "brgy_name": "Tejero",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225032",
      "brgy_name": "Tubod Mar",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071225033",
      "brgy_name": "Tubod Monte",
      "city_code": "071225",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226001",
      "brgy_name": "Alumar",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226002",
      "brgy_name": "Banacon",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226003",
      "brgy_name": "Buyog",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226004",
      "brgy_name": "Cabasakan",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226006",
      "brgy_name": "Campao Occidental",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226007",
      "brgy_name": "Campao Oriental",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226008",
      "brgy_name": "Cangmundo",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226009",
      "brgy_name": "Carlos P. Garcia",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226010",
      "brgy_name": "Corte Baud",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226011",
      "brgy_name": "Handumon",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226012",
      "brgy_name": "Jagoliao",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226013",
      "brgy_name": "Jandayan Norte",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226014",
      "brgy_name": "Jandayan Sur",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226015",
      "brgy_name": "Mahanay (Mahanay Island)",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226016",
      "brgy_name": "Nasingin",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226017",
      "brgy_name": "Pandanon",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226018",
      "brgy_name": "Poblacion",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226019",
      "brgy_name": "Saguise",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226020",
      "brgy_name": "Salog",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226021",
      "brgy_name": "San Jose",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226022",
      "brgy_name": "Santo Niño",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226023",
      "brgy_name": "Taytay",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226024",
      "brgy_name": "Tugas",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071226025",
      "brgy_name": "Tulang",
      "city_code": "071226",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227001",
      "brgy_name": "Banban",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227002",
      "brgy_name": "Bonkokan Ilaya",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227003",
      "brgy_name": "Bonkokan Ubos",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227004",
      "brgy_name": "Calvario",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227005",
      "brgy_name": "Candulang",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227006",
      "brgy_name": "Catugasan",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227007",
      "brgy_name": "Cayupo",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227008",
      "brgy_name": "Cogon",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227009",
      "brgy_name": "Jambawan",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227010",
      "brgy_name": "La Fortuna",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227011",
      "brgy_name": "Lomanoy",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227012",
      "brgy_name": "Macalingan",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227013",
      "brgy_name": "Malinao East",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227014",
      "brgy_name": "Malinao West",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227015",
      "brgy_name": "Nagsulay",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227016",
      "brgy_name": "Poblacion",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227017",
      "brgy_name": "Taug",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071227018",
      "brgy_name": "Tiguis",
      "city_code": "071227",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228001",
      "brgy_name": "Agape",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228002",
      "brgy_name": "Alegria Norte",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228003",
      "brgy_name": "Alegria Sur",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228004",
      "brgy_name": "Bonbon",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228005",
      "brgy_name": "Botoc Occidental",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228006",
      "brgy_name": "Botoc Oriental",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228007",
      "brgy_name": "Calvario",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228008",
      "brgy_name": "Concepcion",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228010",
      "brgy_name": "Hinawanan",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228011",
      "brgy_name": "Las Salinas Norte",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228012",
      "brgy_name": "Las Salinas Sur",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228013",
      "brgy_name": "Palo",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228014",
      "brgy_name": "Poblacion Ibabao",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228015",
      "brgy_name": "Poblacion Ubos",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228016",
      "brgy_name": "Sagnap",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228017",
      "brgy_name": "Tambangan",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228018",
      "brgy_name": "Tangcasan Norte",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228019",
      "brgy_name": "Tangcasan Sur",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228020",
      "brgy_name": "Tayong Occidental",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228021",
      "brgy_name": "Tayong Oriental",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228023",
      "brgy_name": "Tocdog Dacu",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228024",
      "brgy_name": "Tocdog Ilaya",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228025",
      "brgy_name": "Villalimpia",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071228026",
      "brgy_name": "Yanangan",
      "city_code": "071228",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229001",
      "brgy_name": "Agape",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229002",
      "brgy_name": "Alegria",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229003",
      "brgy_name": "Bagumbayan",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229004",
      "brgy_name": "Bahian",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229005",
      "brgy_name": "Bonbon Lower",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229006",
      "brgy_name": "Bonbon Upper",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229007",
      "brgy_name": "Buenavista",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229008",
      "brgy_name": "Bugho",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229009",
      "brgy_name": "Cabadiangan",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229010",
      "brgy_name": "Calunasan Norte",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229011",
      "brgy_name": "Calunasan Sur",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229012",
      "brgy_name": "Camayaan",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229013",
      "brgy_name": "Cambance",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229014",
      "brgy_name": "Candabong",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229015",
      "brgy_name": "Candasag",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229016",
      "brgy_name": "Canlasid",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229017",
      "brgy_name": "Gon-ob",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229018",
      "brgy_name": "Gotozon",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229019",
      "brgy_name": "Jimilian",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229020",
      "brgy_name": "Oy",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229021",
      "brgy_name": "Poblacion Sawang",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229022",
      "brgy_name": "Poblacion Ondol",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229023",
      "brgy_name": "Quinoguitan",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229024",
      "brgy_name": "Taytay",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229025",
      "brgy_name": "Tigbao",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229026",
      "brgy_name": "Ugpong",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229027",
      "brgy_name": "Valladolid",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071229028",
      "brgy_name": "Villaflor",
      "city_code": "071229",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230001",
      "brgy_name": "Agsoso",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230002",
      "brgy_name": "Badbad Occidental",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230003",
      "brgy_name": "Badbad Oriental",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230004",
      "brgy_name": "Bagacay Katipunan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230005",
      "brgy_name": "Bagacay Kawayan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230006",
      "brgy_name": "Bagacay Saong",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230007",
      "brgy_name": "Bahi",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230008",
      "brgy_name": "Basac",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230009",
      "brgy_name": "Basdacu",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230010",
      "brgy_name": "Basdio",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230011",
      "brgy_name": "Biasong",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230012",
      "brgy_name": "Bongco",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230013",
      "brgy_name": "Bugho",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230014",
      "brgy_name": "Cabacongan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230015",
      "brgy_name": "Cabadug",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230016",
      "brgy_name": "Cabug",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230017",
      "brgy_name": "Calayugan Norte",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230018",
      "brgy_name": "Calayugan Sur",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230019",
      "brgy_name": "Canmaag",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230020",
      "brgy_name": "Cambaquiz",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230021",
      "brgy_name": "Campatud",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230022",
      "brgy_name": "Candaigan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230023",
      "brgy_name": "Canhangdon Occidental",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230024",
      "brgy_name": "Canhangdon Oriental",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230025",
      "brgy_name": "Canigaan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230026",
      "brgy_name": "Canmanoc",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230027",
      "brgy_name": "Cansuagwit",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230028",
      "brgy_name": "Cansubayon",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230029",
      "brgy_name": "Catagbacan Handig",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230030",
      "brgy_name": "Catagbacan Norte",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230031",
      "brgy_name": "Catagbacan Sur",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230032",
      "brgy_name": "Cantam-is Bago",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230033",
      "brgy_name": "Cantaongon",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230034",
      "brgy_name": "Cantumocad",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230035",
      "brgy_name": "Cantam-is Baslay",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230036",
      "brgy_name": "Cogon Norte (Pob.)",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230037",
      "brgy_name": "Cogon Sur",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230038",
      "brgy_name": "Cuasi",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230039",
      "brgy_name": "Genomoan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230040",
      "brgy_name": "Lintuan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230041",
      "brgy_name": "Looc",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230042",
      "brgy_name": "Mocpoc Norte",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230043",
      "brgy_name": "Mocpoc Sur",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230044",
      "brgy_name": "Nagtuang",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230045",
      "brgy_name": "Napo (Pob.)",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230046",
      "brgy_name": "Nueva Vida",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230047",
      "brgy_name": "Panangquilon",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230048",
      "brgy_name": "Pantudlan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230049",
      "brgy_name": "Pig-ot",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230050",
      "brgy_name": "Moto Norte (Pob.)",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230051",
      "brgy_name": "Moto Sur (Pob.)",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230052",
      "brgy_name": "Pondol",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230053",
      "brgy_name": "Quinobcoban",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230054",
      "brgy_name": "Sondol",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230055",
      "brgy_name": "Song-on",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230056",
      "brgy_name": "Talisay",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230057",
      "brgy_name": "Tan-awan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230058",
      "brgy_name": "Tangnan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230059",
      "brgy_name": "Taytay",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230060",
      "brgy_name": "Ticugan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230061",
      "brgy_name": "Tiwi",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230062",
      "brgy_name": "Tontonan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230063",
      "brgy_name": "Tubodacu",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230064",
      "brgy_name": "Tubodio",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230065",
      "brgy_name": "Tubuan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230066",
      "brgy_name": "Ubayon",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071230067",
      "brgy_name": "Ubojan",
      "city_code": "071230",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231001",
      "brgy_name": "Abaca",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231002",
      "brgy_name": "Abad Santos",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231003",
      "brgy_name": "Aguipo",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231004",
      "brgy_name": "Concepcion (Banlas)",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231005",
      "brgy_name": "Baybayon",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231006",
      "brgy_name": "Bulawan",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231007",
      "brgy_name": "Cabidian",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231009",
      "brgy_name": "Cawayanan",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231010",
      "brgy_name": "Del Mar",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231011",
      "brgy_name": "Lungsoda-an",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231012",
      "brgy_name": "Marcelo",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231013",
      "brgy_name": "Minol",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231014",
      "brgy_name": "Paraiso",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231015",
      "brgy_name": "Poblacion I",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231016",
      "brgy_name": "Poblacion II",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231017",
      "brgy_name": "San Isidro",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231018",
      "brgy_name": "San Jose",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231019",
      "brgy_name": "San Rafael",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231020",
      "brgy_name": "San Roque (Cabulao)",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231021",
      "brgy_name": "Tambo",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231022",
      "brgy_name": "Tangkigan",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071231023",
      "brgy_name": "Valaga",
      "city_code": "071231",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232001",
      "brgy_name": "San Roque (Aghao)",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232002",
      "brgy_name": "Agahay",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232003",
      "brgy_name": "Aliguay",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232004",
      "brgy_name": "Anislag",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232005",
      "brgy_name": "Bayacabac",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232006",
      "brgy_name": "Bood",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232007",
      "brgy_name": "Busao",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232008",
      "brgy_name": "Cabawan",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232009",
      "brgy_name": "Candavid",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232010",
      "brgy_name": "Dipatlong",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232011",
      "brgy_name": "Guiwanon",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232012",
      "brgy_name": "Jandig",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232013",
      "brgy_name": "Lagtangon",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232014",
      "brgy_name": "Lincod",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232015",
      "brgy_name": "Pagnitoan",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232016",
      "brgy_name": "Poblacion",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232017",
      "brgy_name": "Punsod",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232018",
      "brgy_name": "Punta Cruz",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232019",
      "brgy_name": "San Isidro",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232020",
      "brgy_name": "San Vicente",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232021",
      "brgy_name": "Tinibgan",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071232022",
      "brgy_name": "Toril",
      "city_code": "071232",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233001",
      "brgy_name": "Bil-isan",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233002",
      "brgy_name": "Bolod",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233003",
      "brgy_name": "Danao",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233004",
      "brgy_name": "Doljo",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233005",
      "brgy_name": "Libaong",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233006",
      "brgy_name": "Looc",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233007",
      "brgy_name": "Lourdes",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233008",
      "brgy_name": "Poblacion",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233009",
      "brgy_name": "Tangnan",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071233010",
      "brgy_name": "Tawala",
      "city_code": "071233",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234001",
      "brgy_name": "Aurora",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234002",
      "brgy_name": "Bagacay",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234003",
      "brgy_name": "Bagumbayan",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234004",
      "brgy_name": "Bayong",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234005",
      "brgy_name": "Buenasuerte",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234006",
      "brgy_name": "Cagawasan",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234007",
      "brgy_name": "Cansungay",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234008",
      "brgy_name": "Catagda-an",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234009",
      "brgy_name": "Del Pilar",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234010",
      "brgy_name": "Estaca",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234011",
      "brgy_name": "Ilaud",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234012",
      "brgy_name": "Inaghuban",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234013",
      "brgy_name": "La Suerte",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234014",
      "brgy_name": "Lumbay",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234015",
      "brgy_name": "Lundag",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234016",
      "brgy_name": "Pamacsalan",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234017",
      "brgy_name": "Poblacion",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234018",
      "brgy_name": "Rizal",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234019",
      "brgy_name": "San Carlos",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234020",
      "brgy_name": "San Isidro",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071234021",
      "brgy_name": "San Vicente",
      "city_code": "071234",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235001",
      "brgy_name": "Aguining",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235003",
      "brgy_name": "Basiao",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235004",
      "brgy_name": "Baud",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235005",
      "brgy_name": "Bayog",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235006",
      "brgy_name": "Bogo",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235007",
      "brgy_name": "Bonbonon",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235008",
      "brgy_name": "Canmangao",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235009",
      "brgy_name": "Campamanog",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235010",
      "brgy_name": "Gaus",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235011",
      "brgy_name": "Kabangkalan",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235012",
      "brgy_name": "Lapinig",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235013",
      "brgy_name": "Lipata",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235014",
      "brgy_name": "Poblacion",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235015",
      "brgy_name": "Popoo",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235016",
      "brgy_name": "Saguise",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235017",
      "brgy_name": "San Jose (Tawid)",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235018",
      "brgy_name": "Santo Rosario",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235019",
      "brgy_name": "Tilmobo",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235020",
      "brgy_name": "Tugas",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235021",
      "brgy_name": "Tugnao",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235022",
      "brgy_name": "Villa Milagrosa",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235023",
      "brgy_name": "Butan",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071235024",
      "brgy_name": "San Vicente",
      "city_code": "071235",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236001",
      "brgy_name": "Calangahan",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236002",
      "brgy_name": "Canmano",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236003",
      "brgy_name": "Canmaya Centro",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236004",
      "brgy_name": "Canmaya Diot",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236005",
      "brgy_name": "Dagnawan",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236006",
      "brgy_name": "Kabasacan",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236007",
      "brgy_name": "Kagawasan",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236008",
      "brgy_name": "Katipunan",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236009",
      "brgy_name": "Langtad",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236010",
      "brgy_name": "Libertad Norte",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236011",
      "brgy_name": "Libertad Sur",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236012",
      "brgy_name": "Mantalongon",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236013",
      "brgy_name": "Poblacion",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236014",
      "brgy_name": "Sagbayan Sur",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236015",
      "brgy_name": "San Agustin",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236016",
      "brgy_name": "San Antonio",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236017",
      "brgy_name": "San Isidro",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236018",
      "brgy_name": "San Ramon",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236019",
      "brgy_name": "San Roque",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236020",
      "brgy_name": "San Vicente Norte",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236021",
      "brgy_name": "San Vicente Sur",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236022",
      "brgy_name": "Santa Catalina",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236023",
      "brgy_name": "Santa Cruz",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071236024",
      "brgy_name": "Ubojan",
      "city_code": "071236",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237002",
      "brgy_name": "Abehilan",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237003",
      "brgy_name": "Baunos",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237005",
      "brgy_name": "Cabanugan",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237006",
      "brgy_name": "Caimbang",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237007",
      "brgy_name": "Cambansag",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237008",
      "brgy_name": "Candungao",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237009",
      "brgy_name": "Cansague Norte",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237010",
      "brgy_name": "Cansague Sur",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237011",
      "brgy_name": "Causwagan Sur",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237012",
      "brgy_name": "Masonoy",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237013",
      "brgy_name": "Poblacion",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071237014",
      "brgy_name": "Baryong Daan",
      "city_code": "071237",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238001",
      "brgy_name": "Bayongan",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238003",
      "brgy_name": "Bugang",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238004",
      "brgy_name": "Cabangahan",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238005",
      "brgy_name": "Kagawasan",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238006",
      "brgy_name": "Camanaga",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238007",
      "brgy_name": "Cambangay Norte",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238008",
      "brgy_name": "Capayas",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238009",
      "brgy_name": "Corazon",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238010",
      "brgy_name": "Garcia",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238011",
      "brgy_name": "Hagbuyo",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238012",
      "brgy_name": "Caluasan",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238013",
      "brgy_name": "Mahayag",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238014",
      "brgy_name": "Poblacion",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238015",
      "brgy_name": "San Isidro",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238016",
      "brgy_name": "San Jose",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238017",
      "brgy_name": "San Vicente",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238018",
      "brgy_name": "Santo Niño",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071238019",
      "brgy_name": "Tomoc",
      "city_code": "071238",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239001",
      "brgy_name": "Bayawahan",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239002",
      "brgy_name": "Cabancalan",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239003",
      "brgy_name": "Calinga-an",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239004",
      "brgy_name": "Calinginan Norte",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239005",
      "brgy_name": "Calinginan Sur",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239006",
      "brgy_name": "Cambagui",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239008",
      "brgy_name": "Ewon",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239009",
      "brgy_name": "Guinob-an",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239010",
      "brgy_name": "Lagtangan",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239011",
      "brgy_name": "Licolico",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239012",
      "brgy_name": "Lobgob",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239013",
      "brgy_name": "Magsaysay",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071239014",
      "brgy_name": "Poblacion",
      "city_code": "071239",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240001",
      "brgy_name": "Abachanan",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240002",
      "brgy_name": "Anibongan",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240003",
      "brgy_name": "Bugsoc",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240004",
      "brgy_name": "Canlangit",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240005",
      "brgy_name": "Canta-ub",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240006",
      "brgy_name": "Casilay",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240007",
      "brgy_name": "Danicop",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240008",
      "brgy_name": "Dusita",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240009",
      "brgy_name": "Cahayag",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240010",
      "brgy_name": "La Union",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240011",
      "brgy_name": "Lataban",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240012",
      "brgy_name": "Magsaysay",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240013",
      "brgy_name": "Matin-ao",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240014",
      "brgy_name": "Man-od",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240015",
      "brgy_name": "Poblacion",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240016",
      "brgy_name": "Salvador",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240017",
      "brgy_name": "San Agustin",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240018",
      "brgy_name": "San Isidro",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240019",
      "brgy_name": "San Jose",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240020",
      "brgy_name": "San Juan",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240021",
      "brgy_name": "Santa Cruz",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071240022",
      "brgy_name": "Villa Garcia",
      "city_code": "071240",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241001",
      "brgy_name": "Abucay Norte",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241002",
      "brgy_name": "Abucay Sur",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241003",
      "brgy_name": "Badiang",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241004",
      "brgy_name": "Bahaybahay",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241005",
      "brgy_name": "Cambuac Norte",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241006",
      "brgy_name": "Cambuac Sur",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241007",
      "brgy_name": "Canagong",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241008",
      "brgy_name": "Libjo",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241009",
      "brgy_name": "Poblacion I",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071241010",
      "brgy_name": "Poblacion II",
      "city_code": "071241",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242001",
      "brgy_name": "Bool",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242002",
      "brgy_name": "Booy",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242003",
      "brgy_name": "Cabawan",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242004",
      "brgy_name": "Cogon",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242005",
      "brgy_name": "Dao",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242006",
      "brgy_name": "Dampas",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242008",
      "brgy_name": "Manga",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242009",
      "brgy_name": "Mansasa",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242010",
      "brgy_name": "Poblacion I",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242011",
      "brgy_name": "Poblacion II",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242012",
      "brgy_name": "Poblacion III",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242013",
      "brgy_name": "San Isidro",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242014",
      "brgy_name": "Taloto",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242015",
      "brgy_name": "Tiptip",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071242016",
      "brgy_name": "Ubujan",
      "city_code": "071242",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243001",
      "brgy_name": "Bagacay",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243002",
      "brgy_name": "Balintawak",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243006",
      "brgy_name": "Burgos",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243007",
      "brgy_name": "Busalian",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243008",
      "brgy_name": "Calituban",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243009",
      "brgy_name": "Cataban",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243010",
      "brgy_name": "Guindacpan",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243013",
      "brgy_name": "Magsaysay",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243014",
      "brgy_name": "Mahanay",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243017",
      "brgy_name": "Nocnocan",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243021",
      "brgy_name": "Poblacion",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243023",
      "brgy_name": "Rizal",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243024",
      "brgy_name": "Sag",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243026",
      "brgy_name": "San Agustin",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243027",
      "brgy_name": "San Carlos",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243028",
      "brgy_name": "San Francisco",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243029",
      "brgy_name": "San Isidro",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243030",
      "brgy_name": "San Jose",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243031",
      "brgy_name": "San Pedro",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243032",
      "brgy_name": "San Roque",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243033",
      "brgy_name": "Santo Niño",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243034",
      "brgy_name": "Sikatuna",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243035",
      "brgy_name": "Suba",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243036",
      "brgy_name": "Tanghaligue",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071243038",
      "brgy_name": "Zamora",
      "city_code": "071243",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244001",
      "brgy_name": "Banlasan",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244002",
      "brgy_name": "Bongbong",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244003",
      "brgy_name": "Catoogan",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244004",
      "brgy_name": "Guinobatan",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244005",
      "brgy_name": "Hinlayagan Ilaud",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244006",
      "brgy_name": "Hinlayagan Ilaya",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244007",
      "brgy_name": "Kauswagan",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244008",
      "brgy_name": "Kinan-oan",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244009",
      "brgy_name": "La Union",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244010",
      "brgy_name": "La Victoria",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244012",
      "brgy_name": "Mabuhay Cabigohan",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244013",
      "brgy_name": "Mahagbu",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244014",
      "brgy_name": "Manuel M. Roxas",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244015",
      "brgy_name": "Poblacion",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244016",
      "brgy_name": "San Isidro",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244017",
      "brgy_name": "San Vicente",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244018",
      "brgy_name": "Santo Tomas",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244019",
      "brgy_name": "Soom",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244020",
      "brgy_name": "Tagum Norte",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071244021",
      "brgy_name": "Tagum Sur",
      "city_code": "071244",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245001",
      "brgy_name": "Bagongbanwa",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245002",
      "brgy_name": "Bunacan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245003",
      "brgy_name": "Banlasan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245004",
      "brgy_name": "Batasan (Batasan Island)",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245005",
      "brgy_name": "Bilangbilangan (Bilangbilangan Island)",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245006",
      "brgy_name": "Bosongon",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245007",
      "brgy_name": "Buenos Aires",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245008",
      "brgy_name": "Cabulihan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245009",
      "brgy_name": "Cahayag",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245010",
      "brgy_name": "Cawayanan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245011",
      "brgy_name": "Centro (Pob.)",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245012",
      "brgy_name": "Genonocan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245013",
      "brgy_name": "Guiwanon",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245014",
      "brgy_name": "Ilihan Norte",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245015",
      "brgy_name": "Ilihan Sur",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245016",
      "brgy_name": "Libertad",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245017",
      "brgy_name": "Macaas",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245018",
      "brgy_name": "Mocaboc Island",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245019",
      "brgy_name": "Matabao",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245020",
      "brgy_name": "Panadtaran",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245021",
      "brgy_name": "Panaytayon",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245022",
      "brgy_name": "Pandan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245023",
      "brgy_name": "Pangapasan (Pangapasan Island)",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245024",
      "brgy_name": "Pinayagan Norte",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245025",
      "brgy_name": "Pinayagan Sur",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245026",
      "brgy_name": "Pooc Occidental (Pob.)",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245027",
      "brgy_name": "Pooc Oriental (Pob.)",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245028",
      "brgy_name": "Potohan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245029",
      "brgy_name": "Talenceras",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245030",
      "brgy_name": "Tan-awan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245031",
      "brgy_name": "Tinangnan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245032",
      "brgy_name": "Ubay Island",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245033",
      "brgy_name": "Ubojan",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071245034",
      "brgy_name": "Villanueva",
      "city_code": "071245",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246001",
      "brgy_name": "Achila",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246002",
      "brgy_name": "Bay-ang",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246004",
      "brgy_name": "Biabas",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246005",
      "brgy_name": "Benliw",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246007",
      "brgy_name": "Bongbong",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246008",
      "brgy_name": "Bood",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246009",
      "brgy_name": "Buenavista",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246011",
      "brgy_name": "Cagting",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246012",
      "brgy_name": "Camali-an",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246013",
      "brgy_name": "Camambugan",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246015",
      "brgy_name": "Casate",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246016",
      "brgy_name": "Katarungan",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246017",
      "brgy_name": "Cuya",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246018",
      "brgy_name": "Fatima",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246019",
      "brgy_name": "Gabi",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246020",
      "brgy_name": "Governor Boyles",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246021",
      "brgy_name": "Guintabo-an",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246022",
      "brgy_name": "Hambabauran",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246023",
      "brgy_name": "Humayhumay",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246024",
      "brgy_name": "Ilihan",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246025",
      "brgy_name": "Imelda",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246026",
      "brgy_name": "Juagdan",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246027",
      "brgy_name": "Calanggaman",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246030",
      "brgy_name": "Los Angeles",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246031",
      "brgy_name": "Lomangog",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246032",
      "brgy_name": "Pag-asa",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246033",
      "brgy_name": "Pangpang",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246034",
      "brgy_name": "Poblacion",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246037",
      "brgy_name": "San Francisco",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246038",
      "brgy_name": "San Isidro",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246040",
      "brgy_name": "San Pascual",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246041",
      "brgy_name": "San Vicente",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246043",
      "brgy_name": "Sentinila",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246044",
      "brgy_name": "Sinandigan",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246045",
      "brgy_name": "Tapal",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246046",
      "brgy_name": "Tapon",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246047",
      "brgy_name": "Tintinan",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246048",
      "brgy_name": "Tipolo",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246049",
      "brgy_name": "Tubog",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246050",
      "brgy_name": "Tuboran",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246052",
      "brgy_name": "Union",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246054",
      "brgy_name": "Villa Teresita",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246055",
      "brgy_name": "Bulilis",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071246056",
      "brgy_name": "California",
      "city_code": "071246",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247001",
      "brgy_name": "Adlawan",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247002",
      "brgy_name": "Anas",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247003",
      "brgy_name": "Anonang",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247004",
      "brgy_name": "Anoyon",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247005",
      "brgy_name": "Balingasao",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247006",
      "brgy_name": "Botong",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247007",
      "brgy_name": "Buyog",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247008",
      "brgy_name": "Canduao Occidental",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247009",
      "brgy_name": "Canduao Oriental",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247010",
      "brgy_name": "Canlusong",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247011",
      "brgy_name": "Canmanico",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247012",
      "brgy_name": "Cansibao",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247013",
      "brgy_name": "Catug-a",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247014",
      "brgy_name": "Cutcutan",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247015",
      "brgy_name": "Danao",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247016",
      "brgy_name": "Genoveva",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247017",
      "brgy_name": "Ginopolan (Ginopolan Proper)",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247018",
      "brgy_name": "La Victoria",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247019",
      "brgy_name": "Lantang",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247020",
      "brgy_name": "Limocon",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247021",
      "brgy_name": "Loctob",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247022",
      "brgy_name": "Magsaysay",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247023",
      "brgy_name": "Marawis",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247024",
      "brgy_name": "Maubo",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247025",
      "brgy_name": "Nailo",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247026",
      "brgy_name": "Omjon",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247027",
      "brgy_name": "Pangi-an",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247028",
      "brgy_name": "Poblacion Occidental",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247029",
      "brgy_name": "Poblacion Oriental",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247030",
      "brgy_name": "Simang",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247031",
      "brgy_name": "Taug",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247032",
      "brgy_name": "Tausion",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247033",
      "brgy_name": "Taytay",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247034",
      "brgy_name": "Ticum",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071247035",
      "brgy_name": "Banderahan (Upper Ginopolan)",
      "city_code": "071247",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248001",
      "brgy_name": "Bilangbilangan Dako",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248002",
      "brgy_name": "Bilangbilangan Diot",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248003",
      "brgy_name": "Hingotanan East",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248004",
      "brgy_name": "Hingotanan West",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248005",
      "brgy_name": "Liberty",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248006",
      "brgy_name": "Malingin",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248007",
      "brgy_name": "Mandawa",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248008",
      "brgy_name": "Maomawan",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248009",
      "brgy_name": "Nueva Esperanza",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248010",
      "brgy_name": "Nueva Estrella",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248011",
      "brgy_name": "Pinamgo",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248012",
      "brgy_name": "Poblacion (Bien Unido)",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248013",
      "brgy_name": "Puerto San Pedro",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248014",
      "brgy_name": "Sagasa",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "071248015",
      "brgy_name": "Tuboran",
      "city_code": "071248",
      "province_code": "0712",
      "region_code": "07"
    },
    {
      "brgy_code": "072201001",
      "brgy_name": "Cabadiangan",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201002",
      "brgy_name": "Cabil-isan",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201003",
      "brgy_name": "Candabong",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201004",
      "brgy_name": "Lawaan",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201005",
      "brgy_name": "Manga",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201006",
      "brgy_name": "Palanas",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201007",
      "brgy_name": "Poblacion",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201008",
      "brgy_name": "Polo",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072201009",
      "brgy_name": "Salagmaya",
      "city_code": "072201",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202001",
      "brgy_name": "Atabay",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202002",
      "brgy_name": "Daan-Lungsod",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202003",
      "brgy_name": "Guiwang",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202004",
      "brgy_name": "Nug-as",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202005",
      "brgy_name": "Pasol",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202006",
      "brgy_name": "Poblacion",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202007",
      "brgy_name": "Pugalo",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072202008",
      "brgy_name": "San Agustin",
      "city_code": "072202",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203001",
      "brgy_name": "Compostela",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203002",
      "brgy_name": "Guadalupe",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203003",
      "brgy_name": "Legaspi",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203004",
      "brgy_name": "Lepanto",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203005",
      "brgy_name": "Madridejos",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203006",
      "brgy_name": "Montpeller",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203007",
      "brgy_name": "Poblacion",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203008",
      "brgy_name": "Santa Filomena",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072203009",
      "brgy_name": "Valencia",
      "city_code": "072203",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204001",
      "brgy_name": "Angilan",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204002",
      "brgy_name": "Bojo",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204003",
      "brgy_name": "Bonbon",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204004",
      "brgy_name": "Esperanza",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204005",
      "brgy_name": "Kandingan",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204006",
      "brgy_name": "Kantabogon",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204007",
      "brgy_name": "Kawasan",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204008",
      "brgy_name": "Olango",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204009",
      "brgy_name": "Poblacion",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204010",
      "brgy_name": "Punay",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204011",
      "brgy_name": "Rosario",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204012",
      "brgy_name": "Saksak",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204013",
      "brgy_name": "Tampa-an",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204014",
      "brgy_name": "Toyokon",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072204015",
      "brgy_name": "Zaragosa",
      "city_code": "072204",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205001",
      "brgy_name": "Alambijud",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205002",
      "brgy_name": "Anajao",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205003",
      "brgy_name": "Apo",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205004",
      "brgy_name": "Balaas",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205005",
      "brgy_name": "Balisong",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205006",
      "brgy_name": "Binlod",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205007",
      "brgy_name": "Bogo",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205008",
      "brgy_name": "Butong",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205009",
      "brgy_name": "Bug-ot",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205010",
      "brgy_name": "Bulasa",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205012",
      "brgy_name": "Calagasan",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205013",
      "brgy_name": "Canbantug",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205014",
      "brgy_name": "Canbanua",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205015",
      "brgy_name": "Cansuje",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205016",
      "brgy_name": "Capio-an",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205017",
      "brgy_name": "Casay",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205018",
      "brgy_name": "Catang",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205019",
      "brgy_name": "Colawin",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205020",
      "brgy_name": "Conalum",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205021",
      "brgy_name": "Guiwanon",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205022",
      "brgy_name": "Gutlang",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205023",
      "brgy_name": "Jampang",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205024",
      "brgy_name": "Jomgao",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205025",
      "brgy_name": "Lamacan",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205026",
      "brgy_name": "Langtad",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205027",
      "brgy_name": "Langub",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205028",
      "brgy_name": "Lapay",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205029",
      "brgy_name": "Lengigon",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205030",
      "brgy_name": "Linut-od",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205031",
      "brgy_name": "Mabasa",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205032",
      "brgy_name": "Mandilikit",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205033",
      "brgy_name": "Mompeller",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205034",
      "brgy_name": "Panadtaran",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205035",
      "brgy_name": "Poblacion",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205036",
      "brgy_name": "Sua",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205037",
      "brgy_name": "Sumaguan",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205038",
      "brgy_name": "Tabayag",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205039",
      "brgy_name": "Talaga",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205040",
      "brgy_name": "Talaytay",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205041",
      "brgy_name": "Talo-ot",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205042",
      "brgy_name": "Tiguib",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205043",
      "brgy_name": "Tulang",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205044",
      "brgy_name": "Tulic",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205045",
      "brgy_name": "Ubaub",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072205046",
      "brgy_name": "Usmad",
      "city_code": "072205",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206001",
      "brgy_name": "Agbanga",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206002",
      "brgy_name": "Agtugop",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206003",
      "brgy_name": "Bago",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206004",
      "brgy_name": "Bairan",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206005",
      "brgy_name": "Banban",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206006",
      "brgy_name": "Baye",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206007",
      "brgy_name": "Bog-o",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206009",
      "brgy_name": "Kaluangan",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206010",
      "brgy_name": "Lanao",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206011",
      "brgy_name": "Langub",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206012",
      "brgy_name": "Looc Norte",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206013",
      "brgy_name": "Lunas",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206014",
      "brgy_name": "Magcalape",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206015",
      "brgy_name": "Manguiao",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206016",
      "brgy_name": "New Bago",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206017",
      "brgy_name": "Owak",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206018",
      "brgy_name": "Poblacion",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206019",
      "brgy_name": "Saksak",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206020",
      "brgy_name": "San Isidro",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206021",
      "brgy_name": "San Roque",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206022",
      "brgy_name": "Santa Lucia",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206023",
      "brgy_name": "Santa Rita",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206024",
      "brgy_name": "Tag-amakan",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206025",
      "brgy_name": "Tagbubonga",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206026",
      "brgy_name": "Tubigagmanok",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206027",
      "brgy_name": "Tubod",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072206028",
      "brgy_name": "Ubogon",
      "city_code": "072206",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207001",
      "brgy_name": "Alawijao",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207002",
      "brgy_name": "Balhaan",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207003",
      "brgy_name": "Banhigan",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207004",
      "brgy_name": "Basak",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207005",
      "brgy_name": "Basiao",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207006",
      "brgy_name": "Bato",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207007",
      "brgy_name": "Bugas",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207008",
      "brgy_name": "Calangcang",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207009",
      "brgy_name": "Candiis",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207011",
      "brgy_name": "Dagatan",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207012",
      "brgy_name": "Dobdob",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207013",
      "brgy_name": "Ginablan",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207014",
      "brgy_name": "Lambug",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207015",
      "brgy_name": "Malabago",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207016",
      "brgy_name": "Malhiao",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207017",
      "brgy_name": "Manduyong",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207018",
      "brgy_name": "Matutinao",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207019",
      "brgy_name": "Patong",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207020",
      "brgy_name": "Poblacion",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207021",
      "brgy_name": "Sanlagan",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207022",
      "brgy_name": "Santicon",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207023",
      "brgy_name": "Sohoton",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207024",
      "brgy_name": "Sulsugan",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207025",
      "brgy_name": "Talayong",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207026",
      "brgy_name": "Taytay",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207027",
      "brgy_name": "Tigbao",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207028",
      "brgy_name": "Tiguib",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207029",
      "brgy_name": "Tubod",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072207030",
      "brgy_name": "Zaragosa",
      "city_code": "072207",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208001",
      "brgy_name": "Abucayan",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208002",
      "brgy_name": "Aliwanay",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208003",
      "brgy_name": "Arpili",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208004",
      "brgy_name": "Bayong",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208005",
      "brgy_name": "Biasong",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208006",
      "brgy_name": "Buanoy",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208007",
      "brgy_name": "Cabagdalan",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208008",
      "brgy_name": "Cabasiangan",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208009",
      "brgy_name": "Cambuhawe",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208010",
      "brgy_name": "Cansomoroy",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208011",
      "brgy_name": "Cantibas",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208012",
      "brgy_name": "Cantuod",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208013",
      "brgy_name": "Duangan",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208014",
      "brgy_name": "Gaas",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208015",
      "brgy_name": "Ginatilan",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208016",
      "brgy_name": "Hingatmonan",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208017",
      "brgy_name": "Lamesa",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208018",
      "brgy_name": "Liki",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208019",
      "brgy_name": "Luca",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208020",
      "brgy_name": "Matun-og",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208021",
      "brgy_name": "Nangka",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208022",
      "brgy_name": "Pondol",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208023",
      "brgy_name": "Prenza",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208024",
      "brgy_name": "Singsing",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208025",
      "brgy_name": "Sunog",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208026",
      "brgy_name": "Vito",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208027",
      "brgy_name": "Baliwagan (Pob.)",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072208028",
      "brgy_name": "Santa Cruz-Santo Niño (Pob.)",
      "city_code": "072208",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209001",
      "brgy_name": "Atop-atop",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209002",
      "brgy_name": "Baigad",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209003",
      "brgy_name": "Baod",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209004",
      "brgy_name": "Binaobao (Pob.)",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209005",
      "brgy_name": "Botigues",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209006",
      "brgy_name": "Kabac",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209007",
      "brgy_name": "Doong",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209008",
      "brgy_name": "Hilotongan",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209009",
      "brgy_name": "Guiwanon",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209010",
      "brgy_name": "Kabangbang",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209011",
      "brgy_name": "Kampingganon",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209012",
      "brgy_name": "Kangkaibe",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209013",
      "brgy_name": "Lipayran",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209014",
      "brgy_name": "Luyongbaybay",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209016",
      "brgy_name": "Mojon",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209017",
      "brgy_name": "Obo-ob",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209018",
      "brgy_name": "Patao",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209019",
      "brgy_name": "Putian",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209020",
      "brgy_name": "Sillon",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209021",
      "brgy_name": "Sungko",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209022",
      "brgy_name": "Suba (Pob.)",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209023",
      "brgy_name": "Sulangan",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209024",
      "brgy_name": "Tamiao",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209025",
      "brgy_name": "Bantigue (Pob.)",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072209026",
      "brgy_name": "Ticad (Pob.)",
      "city_code": "072209",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210001",
      "brgy_name": "Azucena",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210002",
      "brgy_name": "Bagakay",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210003",
      "brgy_name": "Balao",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210004",
      "brgy_name": "Bolocboloc",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210005",
      "brgy_name": "Budbud",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210006",
      "brgy_name": "Bugtong Kawayan",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210007",
      "brgy_name": "Cabcaban",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210008",
      "brgy_name": "Campangga",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210009",
      "brgy_name": "Dakit",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210010",
      "brgy_name": "Giloctog",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210011",
      "brgy_name": "Guibuangan",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210012",
      "brgy_name": "Giwanon",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210013",
      "brgy_name": "Gunting",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210014",
      "brgy_name": "Hilasgasan",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210015",
      "brgy_name": "Japitan",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210016",
      "brgy_name": "Cagay",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210017",
      "brgy_name": "Kalubihan",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210018",
      "brgy_name": "Kangdampas",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210020",
      "brgy_name": "Candugay",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210021",
      "brgy_name": "Luhod",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210022",
      "brgy_name": "Lupo",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210023",
      "brgy_name": "Luyo",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210024",
      "brgy_name": "Maghanoy",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210025",
      "brgy_name": "Maigang",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210026",
      "brgy_name": "Malolos",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210027",
      "brgy_name": "Mantalongon",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210028",
      "brgy_name": "Mantayupan",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210029",
      "brgy_name": "Mayana",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210030",
      "brgy_name": "Minolos",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210031",
      "brgy_name": "Nabunturan",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210032",
      "brgy_name": "Nasipit",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210034",
      "brgy_name": "Pancil",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210035",
      "brgy_name": "Pangpang",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210036",
      "brgy_name": "Paril",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210037",
      "brgy_name": "Patupat",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210038",
      "brgy_name": "Poblacion",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210039",
      "brgy_name": "San Rafael",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210040",
      "brgy_name": "Santa Ana",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210041",
      "brgy_name": "Sayaw",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210042",
      "brgy_name": "Tal-ot",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210043",
      "brgy_name": "Tubod",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072210044",
      "brgy_name": "Vito",
      "city_code": "072210",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211001",
      "brgy_name": "Cogon (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211002",
      "brgy_name": "Anonang Norte",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211003",
      "brgy_name": "Anonang Sur",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211004",
      "brgy_name": "Banban",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211005",
      "brgy_name": "Binabag",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211006",
      "brgy_name": "Bungtod (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211007",
      "brgy_name": "Carbon (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211008",
      "brgy_name": "Cayang",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211009",
      "brgy_name": "Dakit",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211010",
      "brgy_name": "Don Pedro Rodriguez",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211011",
      "brgy_name": "Gairan",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211012",
      "brgy_name": "Guadalupe",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211013",
      "brgy_name": "La Paz",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211014",
      "brgy_name": "La Purisima Concepcion (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211015",
      "brgy_name": "Libertad",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211016",
      "brgy_name": "Lourdes (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211017",
      "brgy_name": "Malingin",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211018",
      "brgy_name": "Marangog",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211019",
      "brgy_name": "Nailon",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211020",
      "brgy_name": "Odlot",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211021",
      "brgy_name": "Pandan (Pandan Heights)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211022",
      "brgy_name": "Polambato",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211023",
      "brgy_name": "Sambag (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211024",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211025",
      "brgy_name": "Santo Niño",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211026",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211027",
      "brgy_name": "Siocon",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211028",
      "brgy_name": "Taytayan",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072211029",
      "brgy_name": "Sudlonon",
      "city_code": "072211",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212001",
      "brgy_name": "Baclayan",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212002",
      "brgy_name": "El Pardo",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212003",
      "brgy_name": "Granada",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212004",
      "brgy_name": "Lower Becerril",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212005",
      "brgy_name": "Poblacion",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212006",
      "brgy_name": "San Antonio",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212007",
      "brgy_name": "Upper Becerril",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212008",
      "brgy_name": "Arbor",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212009",
      "brgy_name": "Lunop",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212010",
      "brgy_name": "Nangka",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072212011",
      "brgy_name": "South Granada",
      "city_code": "072212",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213001",
      "brgy_name": "Bagacay",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213002",
      "brgy_name": "Bili",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213003",
      "brgy_name": "Bingay",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213004",
      "brgy_name": "Bongdo",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213005",
      "brgy_name": "Bongdo Gua",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213006",
      "brgy_name": "Bongoyan",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213007",
      "brgy_name": "Cadaruhan",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213008",
      "brgy_name": "Cajel",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213009",
      "brgy_name": "Campusong",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213010",
      "brgy_name": "Clavera",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213011",
      "brgy_name": "Don Gregorio Antigua (Taytayan)",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213012",
      "brgy_name": "Laaw",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213013",
      "brgy_name": "Lugo",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213014",
      "brgy_name": "Managase",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213015",
      "brgy_name": "Poblacion",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213016",
      "brgy_name": "Sagay",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213017",
      "brgy_name": "San Jose",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213018",
      "brgy_name": "Tabunan",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072213019",
      "brgy_name": "Tagnucan",
      "city_code": "072213",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214001",
      "brgy_name": "Bolinawan",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214002",
      "brgy_name": "Buenavista",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214003",
      "brgy_name": "Calidngan",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214004",
      "brgy_name": "Can-asujan",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214005",
      "brgy_name": "Guadalupe",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214006",
      "brgy_name": "Liburon",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214007",
      "brgy_name": "Napo",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214008",
      "brgy_name": "Ocana",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214009",
      "brgy_name": "Perrelos",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214010",
      "brgy_name": "Valencia",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214011",
      "brgy_name": "Valladolid",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214012",
      "brgy_name": "Poblacion I",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214013",
      "brgy_name": "Poblacion II",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214014",
      "brgy_name": "Poblacion III",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072214015",
      "brgy_name": "Tuyom",
      "city_code": "072214",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215001",
      "brgy_name": "Baring",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215002",
      "brgy_name": "Cantipay",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215003",
      "brgy_name": "Cantumog",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215004",
      "brgy_name": "Cantukong",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215005",
      "brgy_name": "Caurasan",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215006",
      "brgy_name": "Corte",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215007",
      "brgy_name": "Dawis Norte",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215008",
      "brgy_name": "Dawis Sur",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215009",
      "brgy_name": "Cogon East",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215010",
      "brgy_name": "Hagnaya",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215011",
      "brgy_name": "Ipil",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215012",
      "brgy_name": "Lanipga",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215013",
      "brgy_name": "Liboron",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215014",
      "brgy_name": "Lower Natimao-an",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215015",
      "brgy_name": "Luyang",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215016",
      "brgy_name": "Poblacion",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215017",
      "brgy_name": "Puente",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215018",
      "brgy_name": "Sac-on",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215019",
      "brgy_name": "Triumfo",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215020",
      "brgy_name": "Upper Natimao-an",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072215021",
      "brgy_name": "Cogon West",
      "city_code": "072215",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216001",
      "brgy_name": "Agsuwao",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216002",
      "brgy_name": "Amancion",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216003",
      "brgy_name": "Anapog",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216004",
      "brgy_name": "Bactas",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216005",
      "brgy_name": "Bongyas",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216006",
      "brgy_name": "Basak",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216007",
      "brgy_name": "Binongkalan",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216008",
      "brgy_name": "Cabungaan",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216009",
      "brgy_name": "Cambangkaya",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216010",
      "brgy_name": "Can-ibuang",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216011",
      "brgy_name": "Catmondaan",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216012",
      "brgy_name": "Duyan",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216013",
      "brgy_name": "Ginabucan",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216014",
      "brgy_name": "Macaas",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216015",
      "brgy_name": "Panalipan",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216016",
      "brgy_name": "Tabili",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216017",
      "brgy_name": "Tinabyonan",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216018",
      "brgy_name": "San Jose Pob. (Catadman)",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216019",
      "brgy_name": "Corazon (Pob.)",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072216020",
      "brgy_name": "Flores (Pob.)",
      "city_code": "072216",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217001",
      "brgy_name": "Adlaon",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217002",
      "brgy_name": "Agsungot",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217003",
      "brgy_name": "Apas",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217004",
      "brgy_name": "Babag",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217005",
      "brgy_name": "Basak Pardo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217006",
      "brgy_name": "Bacayan",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217007",
      "brgy_name": "Banilad",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217008",
      "brgy_name": "Basak San Nicolas",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217010",
      "brgy_name": "Binaliw",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217011",
      "brgy_name": "Bonbon",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217013",
      "brgy_name": "Budla-an (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217014",
      "brgy_name": "Buhisan",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217015",
      "brgy_name": "Bulacao",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217016",
      "brgy_name": "Buot-Taup Pardo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217017",
      "brgy_name": "Busay (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217018",
      "brgy_name": "Calamba",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217019",
      "brgy_name": "Cambinocot",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217020",
      "brgy_name": "Capitol Site (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217021",
      "brgy_name": "Carreta",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217022",
      "brgy_name": "Central (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217023",
      "brgy_name": "Cogon Ramos (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217024",
      "brgy_name": "Cogon Pardo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217025",
      "brgy_name": "Day-as",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217027",
      "brgy_name": "Duljo (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217028",
      "brgy_name": "Ermita (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217029",
      "brgy_name": "Guadalupe",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217030",
      "brgy_name": "Guba",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217031",
      "brgy_name": "Hippodromo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217032",
      "brgy_name": "Inayawan",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217033",
      "brgy_name": "Kalubihan (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217034",
      "brgy_name": "Kalunasan",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217035",
      "brgy_name": "Kamagayan (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217036",
      "brgy_name": "Camputhaw (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217037",
      "brgy_name": "Kasambagan",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217038",
      "brgy_name": "Kinasang-an Pardo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217040",
      "brgy_name": "Labangon",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217041",
      "brgy_name": "Lahug (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217042",
      "brgy_name": "Lorega (Lorega San Miguel)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217043",
      "brgy_name": "Lusaran",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217044",
      "brgy_name": "Luz",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217045",
      "brgy_name": "Mabini",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217046",
      "brgy_name": "Mabolo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217048",
      "brgy_name": "Malubog",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217049",
      "brgy_name": "Mambaling",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217050",
      "brgy_name": "Pahina Central (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217051",
      "brgy_name": "Pahina San Nicolas",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217052",
      "brgy_name": "Pamutan",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217053",
      "brgy_name": "Pardo (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217054",
      "brgy_name": "Pari-an",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217055",
      "brgy_name": "Paril",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217056",
      "brgy_name": "Pasil",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217057",
      "brgy_name": "Pit-os",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217059",
      "brgy_name": "Pulangbato",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217060",
      "brgy_name": "Pung-ol-Sibugay",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217062",
      "brgy_name": "Punta Princesa",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217063",
      "brgy_name": "Quiot Pardo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217064",
      "brgy_name": "Sambag I (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217065",
      "brgy_name": "Sambag II (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217066",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217067",
      "brgy_name": "San Jose",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217068",
      "brgy_name": "San Nicolas Central",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217069",
      "brgy_name": "San Roque (Ciudad)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217070",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217071",
      "brgy_name": "Sawang Calero (Pob.)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217073",
      "brgy_name": "Sinsin",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217074",
      "brgy_name": "Sirao",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217075",
      "brgy_name": "Suba Pob. (Suba San Nicolas)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217076",
      "brgy_name": "Sudlon I",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217077",
      "brgy_name": "Sapangdaku",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217078",
      "brgy_name": "T. Padilla",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217079",
      "brgy_name": "Tabunan",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217080",
      "brgy_name": "Tagbao",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217081",
      "brgy_name": "Talamban",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217082",
      "brgy_name": "Taptap",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217083",
      "brgy_name": "Tejero (Villa Gonzalo)",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217084",
      "brgy_name": "Tinago",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217085",
      "brgy_name": "Tisa",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217086",
      "brgy_name": "To-ong Pardo",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217087",
      "brgy_name": "Zapatera",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072217088",
      "brgy_name": "Sudlon II",
      "city_code": "072217",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218001",
      "brgy_name": "Bagalnga",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218002",
      "brgy_name": "Basak",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218003",
      "brgy_name": "Buluang",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218004",
      "brgy_name": "Cabadiangan",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218005",
      "brgy_name": "Cambayog",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218006",
      "brgy_name": "Canamucan",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218007",
      "brgy_name": "Cogon",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218008",
      "brgy_name": "Dapdap",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218009",
      "brgy_name": "Estaca",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218010",
      "brgy_name": "Lupa",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218011",
      "brgy_name": "Magay",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218012",
      "brgy_name": "Mulao",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218013",
      "brgy_name": "Panangban",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218014",
      "brgy_name": "Poblacion",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218015",
      "brgy_name": "Tag-ube",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218016",
      "brgy_name": "Tamiao",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072218017",
      "brgy_name": "Tubigan",
      "city_code": "072218",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219001",
      "brgy_name": "Cabangahan",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219002",
      "brgy_name": "Cansaga",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219003",
      "brgy_name": "Casili",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219004",
      "brgy_name": "Danglag",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219005",
      "brgy_name": "Garing",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219006",
      "brgy_name": "Jugan",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219007",
      "brgy_name": "Lamac",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219008",
      "brgy_name": "Lanipga",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219009",
      "brgy_name": "Nangka",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219010",
      "brgy_name": "Panas",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219011",
      "brgy_name": "Panoypoy",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219012",
      "brgy_name": "Pitogo",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219013",
      "brgy_name": "Poblacion Occidental",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219014",
      "brgy_name": "Poblacion Oriental",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219015",
      "brgy_name": "Polog",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219016",
      "brgy_name": "Pulpogan",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219017",
      "brgy_name": "Sacsac",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219018",
      "brgy_name": "Tayud",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219019",
      "brgy_name": "Tilhaong",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219020",
      "brgy_name": "Tolotolo",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072219021",
      "brgy_name": "Tugbongan",
      "city_code": "072219",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220001",
      "brgy_name": "Alegria",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220002",
      "brgy_name": "Bangbang",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220003",
      "brgy_name": "Buagsong",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220004",
      "brgy_name": "Catarman",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220005",
      "brgy_name": "Cogon",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220006",
      "brgy_name": "Dapitan",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220007",
      "brgy_name": "Day-as",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220008",
      "brgy_name": "Gabi",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220009",
      "brgy_name": "Gilutongan",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220010",
      "brgy_name": "Ibabao",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220011",
      "brgy_name": "Pilipog",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220012",
      "brgy_name": "Poblacion",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072220013",
      "brgy_name": "San Miguel",
      "city_code": "072220",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221001",
      "brgy_name": "Aguho",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221002",
      "brgy_name": "Bagay",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221003",
      "brgy_name": "Bakhawan",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221004",
      "brgy_name": "Bateria",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221005",
      "brgy_name": "Bitoon",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221006",
      "brgy_name": "Calape",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221007",
      "brgy_name": "Carnaza",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221008",
      "brgy_name": "Dalingding",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221009",
      "brgy_name": "Lanao",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221010",
      "brgy_name": "Logon",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221011",
      "brgy_name": "Malbago",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221012",
      "brgy_name": "Malingin",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221013",
      "brgy_name": "Maya",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221014",
      "brgy_name": "Pajo",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221015",
      "brgy_name": "Paypay",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221016",
      "brgy_name": "Poblacion",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221017",
      "brgy_name": "Talisay",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221018",
      "brgy_name": "Tapilon",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221019",
      "brgy_name": "Tinubdan",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072221020",
      "brgy_name": "Tominjao",
      "city_code": "072221",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222001",
      "brgy_name": "Ablayan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222002",
      "brgy_name": "Babayongan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222003",
      "brgy_name": "Balud",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222004",
      "brgy_name": "Banhigan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222005",
      "brgy_name": "Bulak",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222006",
      "brgy_name": "Caliongan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222007",
      "brgy_name": "Caleriohan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222008",
      "brgy_name": "Casay",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222009",
      "brgy_name": "Catolohan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222010",
      "brgy_name": "Cawayan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222011",
      "brgy_name": "Consolacion",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222012",
      "brgy_name": "Coro",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222013",
      "brgy_name": "Dugyan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222014",
      "brgy_name": "Dumalan",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222015",
      "brgy_name": "Jolomaynon",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222016",
      "brgy_name": "Lanao",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222017",
      "brgy_name": "Langkas",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222018",
      "brgy_name": "Lumbang",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222019",
      "brgy_name": "Malones",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222020",
      "brgy_name": "Maloray",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222021",
      "brgy_name": "Mananggal",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222023",
      "brgy_name": "Manlapay",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222024",
      "brgy_name": "Mantalongon",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222025",
      "brgy_name": "Nalhub",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222026",
      "brgy_name": "Obo",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222027",
      "brgy_name": "Obong",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222028",
      "brgy_name": "Panas",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222029",
      "brgy_name": "Poblacion",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222030",
      "brgy_name": "Sacsac",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222031",
      "brgy_name": "Tapun",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222032",
      "brgy_name": "Tuba",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222033",
      "brgy_name": "Salug",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072222034",
      "brgy_name": "Tabon",
      "city_code": "072222",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223001",
      "brgy_name": "Baliang",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223002",
      "brgy_name": "Bayabas",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223004",
      "brgy_name": "Binaliw",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223006",
      "brgy_name": "Cabungahan",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223007",
      "brgy_name": "Cagat-Lamac",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223008",
      "brgy_name": "Cahumayan",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223009",
      "brgy_name": "Cambanay",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223010",
      "brgy_name": "Cambubho",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223011",
      "brgy_name": "Cogon-Cruz",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223012",
      "brgy_name": "Danasan",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223013",
      "brgy_name": "Dungga",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223014",
      "brgy_name": "Dunggoan",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223015",
      "brgy_name": "Guinacot",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223016",
      "brgy_name": "Guinsay",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223017",
      "brgy_name": "Ibo",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223018",
      "brgy_name": "Langosig",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223019",
      "brgy_name": "Lawaan",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223020",
      "brgy_name": "Licos",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223021",
      "brgy_name": "Looc",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223022",
      "brgy_name": "Magtagobtob",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223023",
      "brgy_name": "Malapoc",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223024",
      "brgy_name": "Manlayag",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223025",
      "brgy_name": "Mantija",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223026",
      "brgy_name": "Masaba",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223027",
      "brgy_name": "Maslog",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223028",
      "brgy_name": "Nangka",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223029",
      "brgy_name": "Oguis",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223030",
      "brgy_name": "Pili",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223031",
      "brgy_name": "Poblacion",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223032",
      "brgy_name": "Quisol",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223033",
      "brgy_name": "Sabang",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223034",
      "brgy_name": "Sacsac",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223035",
      "brgy_name": "Sandayong Norte",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223036",
      "brgy_name": "Sandayong Sur",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223037",
      "brgy_name": "Santa Rosa",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223038",
      "brgy_name": "Santican",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223039",
      "brgy_name": "Sibacan",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223040",
      "brgy_name": "Suba",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223041",
      "brgy_name": "Taboc",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223042",
      "brgy_name": "Taytay",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223043",
      "brgy_name": "Togonon",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072223044",
      "brgy_name": "Tuburan Sur",
      "city_code": "072223",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224001",
      "brgy_name": "Balaygtiki",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224002",
      "brgy_name": "Bitoon",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224003",
      "brgy_name": "Bulak",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224004",
      "brgy_name": "Bullogan",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224006",
      "brgy_name": "Doldol",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224007",
      "brgy_name": "Kabalaasnan",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224008",
      "brgy_name": "Kabatbatan",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224009",
      "brgy_name": "Calaboon",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224010",
      "brgy_name": "Kambanog",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224011",
      "brgy_name": "Camboang",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224012",
      "brgy_name": "Candabong",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224013",
      "brgy_name": "Kang-actol",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224014",
      "brgy_name": "Kanghalo",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224015",
      "brgy_name": "Kanghumaod",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224016",
      "brgy_name": "Kanguha",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224017",
      "brgy_name": "Kantangkas",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224018",
      "brgy_name": "Kanyuko",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224019",
      "brgy_name": "Cogon",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224020",
      "brgy_name": "Kolabtingon",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224021",
      "brgy_name": "Cotcoton",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224022",
      "brgy_name": "Lamak",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224023",
      "brgy_name": "Lawaan",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224024",
      "brgy_name": "Liong",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224025",
      "brgy_name": "Manlapay",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224026",
      "brgy_name": "Masa",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224027",
      "brgy_name": "Matalao",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224028",
      "brgy_name": "Paculob",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224029",
      "brgy_name": "Panlaan",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224030",
      "brgy_name": "Pawa",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224031",
      "brgy_name": "Ilaya (Pob.)",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224032",
      "brgy_name": "Poblacion Looc",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224033",
      "brgy_name": "Poblacion Sima",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224034",
      "brgy_name": "Tangil",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224035",
      "brgy_name": "Tapon",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224037",
      "brgy_name": "Tubod-Bitoon",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224038",
      "brgy_name": "Tubod-Dugoan",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072224039",
      "brgy_name": "Poblacion Central",
      "city_code": "072224",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225001",
      "brgy_name": "Anao",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225002",
      "brgy_name": "Cagsing",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225003",
      "brgy_name": "Calabawan",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225004",
      "brgy_name": "Cambagte",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225005",
      "brgy_name": "Campisong",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225006",
      "brgy_name": "Canorong",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225007",
      "brgy_name": "Guiwanon",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225008",
      "brgy_name": "Looc",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225009",
      "brgy_name": "Malatbo",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225010",
      "brgy_name": "Mangaco",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225011",
      "brgy_name": "Palanas",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225012",
      "brgy_name": "Poblacion",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225013",
      "brgy_name": "Salamanca",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072225014",
      "brgy_name": "San Roque",
      "city_code": "072225",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226001",
      "brgy_name": "Agus",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226002",
      "brgy_name": "Babag",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226003",
      "brgy_name": "Bankal",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226004",
      "brgy_name": "Baring",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226005",
      "brgy_name": "Basak",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226006",
      "brgy_name": "Buaya",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226007",
      "brgy_name": "Calawisan",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226008",
      "brgy_name": "Canjulao",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226009",
      "brgy_name": "Caw-oy",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226010",
      "brgy_name": "Cawhagan",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226011",
      "brgy_name": "Caubian",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226012",
      "brgy_name": "Gun-ob",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226013",
      "brgy_name": "Ibo",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226014",
      "brgy_name": "Looc",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226015",
      "brgy_name": "Mactan",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226016",
      "brgy_name": "Maribago",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226017",
      "brgy_name": "Marigondon",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226018",
      "brgy_name": "Pajac",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226019",
      "brgy_name": "Pajo",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226020",
      "brgy_name": "Pangan-an",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226021",
      "brgy_name": "Poblacion",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226022",
      "brgy_name": "Punta Engaño",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226024",
      "brgy_name": "Pusok",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226025",
      "brgy_name": "Sabang",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226026",
      "brgy_name": "Santa Rosa",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226027",
      "brgy_name": "Subabasbas",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226028",
      "brgy_name": "Talima",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226029",
      "brgy_name": "Tingo",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226030",
      "brgy_name": "Tungasan",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072226031",
      "brgy_name": "San Vicente",
      "city_code": "072226",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227001",
      "brgy_name": "Cabadiangan",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227002",
      "brgy_name": "Calero",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227003",
      "brgy_name": "Catarman",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227004",
      "brgy_name": "Cotcot",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227005",
      "brgy_name": "Jubay",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227006",
      "brgy_name": "Lataban",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227007",
      "brgy_name": "Mulao",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227008",
      "brgy_name": "Poblacion",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227009",
      "brgy_name": "San Roque",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227010",
      "brgy_name": "San Vicente",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227011",
      "brgy_name": "Santa Cruz",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227012",
      "brgy_name": "Tabla",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227013",
      "brgy_name": "Tayud",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072227014",
      "brgy_name": "Yati",
      "city_code": "072227",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228001",
      "brgy_name": "Bunakan",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228002",
      "brgy_name": "Kangwayan",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228003",
      "brgy_name": "Kaongkod",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228004",
      "brgy_name": "Kodia",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228005",
      "brgy_name": "Maalat",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228006",
      "brgy_name": "Malbago",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228007",
      "brgy_name": "Mancilang",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228008",
      "brgy_name": "Pili",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228009",
      "brgy_name": "Poblacion",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228010",
      "brgy_name": "San Agustin",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228011",
      "brgy_name": "Tabagak",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228012",
      "brgy_name": "Talangnan",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228013",
      "brgy_name": "Tarong",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072228014",
      "brgy_name": "Tugas",
      "city_code": "072228",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229001",
      "brgy_name": "Armeña (Cansilongan)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229004",
      "brgy_name": "Tolosa (Calatagan)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229005",
      "brgy_name": "Cerdeña (Ansan)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229006",
      "brgy_name": "Labrador (Bulod)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229007",
      "brgy_name": "Looc",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229008",
      "brgy_name": "Lombo",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229009",
      "brgy_name": "Mahanlud",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229010",
      "brgy_name": "Mindanao (Pajo)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229011",
      "brgy_name": "Montañeza (Inamlang)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229012",
      "brgy_name": "Salmeron (Bulak)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229013",
      "brgy_name": "Santo Niño",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229014",
      "brgy_name": "Sorsogon (Balimaya)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229015",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072229016",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "072229",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230001",
      "brgy_name": "Alang-alang",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230002",
      "brgy_name": "Bakilid",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230003",
      "brgy_name": "Banilad",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230004",
      "brgy_name": "Basak",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230005",
      "brgy_name": "Cabancalan",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230006",
      "brgy_name": "Cambaro",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230007",
      "brgy_name": "Canduman",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230008",
      "brgy_name": "Casili",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230009",
      "brgy_name": "Casuntingan",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230010",
      "brgy_name": "Centro (Pob.)",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230011",
      "brgy_name": "Cubacub",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230012",
      "brgy_name": "Guizo",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230013",
      "brgy_name": "Ibabao-Estancia",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230014",
      "brgy_name": "Jagobiao",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230015",
      "brgy_name": "Labogon",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230016",
      "brgy_name": "Looc",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230017",
      "brgy_name": "Maguikay",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230018",
      "brgy_name": "Mantuyong",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230019",
      "brgy_name": "Opao",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230020",
      "brgy_name": "Pakna-an",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230021",
      "brgy_name": "Pagsabungan",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230022",
      "brgy_name": "Subangdaku",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230023",
      "brgy_name": "Tabok",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230024",
      "brgy_name": "Tawason",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230025",
      "brgy_name": "Tingub",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230026",
      "brgy_name": "Tipolo",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072230027",
      "brgy_name": "Umapad",
      "city_code": "072230",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231001",
      "brgy_name": "Antipolo",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231002",
      "brgy_name": "Curva",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231003",
      "brgy_name": "Daanlungsod",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231004",
      "brgy_name": "Dalingding Sur",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231005",
      "brgy_name": "Dayhagon",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231006",
      "brgy_name": "Gibitngil",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231007",
      "brgy_name": "Canhabagat",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231008",
      "brgy_name": "Caputatan Norte",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231009",
      "brgy_name": "Caputatan Sur",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231010",
      "brgy_name": "Kawit",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231011",
      "brgy_name": "Lamintak Norte",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231012",
      "brgy_name": "Luy-a",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231013",
      "brgy_name": "Panugnawan",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231014",
      "brgy_name": "Poblacion",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231015",
      "brgy_name": "Tindog",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231016",
      "brgy_name": "Don Virgilio Gonzales",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231017",
      "brgy_name": "Lamintak Sur",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231018",
      "brgy_name": "Maharuhay",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072231019",
      "brgy_name": "Mahawak",
      "city_code": "072231",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232001",
      "brgy_name": "Cadulawan",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232002",
      "brgy_name": "Calajo-an",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232003",
      "brgy_name": "Camp 7",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232004",
      "brgy_name": "Camp 8",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232005",
      "brgy_name": "Cuanos",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232006",
      "brgy_name": "Guindaruhan",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232007",
      "brgy_name": "Linao",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232008",
      "brgy_name": "Manduang",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232009",
      "brgy_name": "Pakigne",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232010",
      "brgy_name": "Poblacion Ward I",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232011",
      "brgy_name": "Poblacion Ward II",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232012",
      "brgy_name": "Poblacion Ward III",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232013",
      "brgy_name": "Poblacion Ward IV",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232014",
      "brgy_name": "Tubod",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232015",
      "brgy_name": "Tulay",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232016",
      "brgy_name": "Tunghaan",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232017",
      "brgy_name": "Tungkop",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232018",
      "brgy_name": "Vito",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072232019",
      "brgy_name": "Tungkil",
      "city_code": "072232",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233001",
      "brgy_name": "Agbalanga",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233002",
      "brgy_name": "Bala",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233003",
      "brgy_name": "Balabagon",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233004",
      "brgy_name": "Basdiot",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233005",
      "brgy_name": "Batadbatad",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233006",
      "brgy_name": "Bugho",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233007",
      "brgy_name": "Buguil",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233008",
      "brgy_name": "Busay",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233009",
      "brgy_name": "Lanao",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233010",
      "brgy_name": "Poblacion East",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233011",
      "brgy_name": "Poblacion West",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233012",
      "brgy_name": "Saavedra",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233013",
      "brgy_name": "Tomonoy",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233014",
      "brgy_name": "Tuble",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072233015",
      "brgy_name": "Tunga",
      "city_code": "072233",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234001",
      "brgy_name": "Alfaco",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234002",
      "brgy_name": "Bairan",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234003",
      "brgy_name": "Balirong",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234004",
      "brgy_name": "Cabungahan",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234005",
      "brgy_name": "Cantao-an",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234006",
      "brgy_name": "Central Poblacion",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234007",
      "brgy_name": "Cogon",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234008",
      "brgy_name": "Colon",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234009",
      "brgy_name": "East Poblacion",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234010",
      "brgy_name": "Inoburan",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234011",
      "brgy_name": "Inayagan",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234012",
      "brgy_name": "Jaguimit",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234013",
      "brgy_name": "Lanas",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234014",
      "brgy_name": "Langtad",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234015",
      "brgy_name": "Lutac",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234016",
      "brgy_name": "Mainit",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234017",
      "brgy_name": "Mayana",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234018",
      "brgy_name": "Naalad",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234019",
      "brgy_name": "North Poblacion",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234020",
      "brgy_name": "Pangdan",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234021",
      "brgy_name": "Patag",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234022",
      "brgy_name": "South Poblacion",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234023",
      "brgy_name": "Tagjaguimit",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234024",
      "brgy_name": "Tangke",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234025",
      "brgy_name": "Tinaan",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234027",
      "brgy_name": "Tuyan",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234028",
      "brgy_name": "Uling",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072234029",
      "brgy_name": "West Poblacion",
      "city_code": "072234",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235001",
      "brgy_name": "Alo",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235002",
      "brgy_name": "Bangcogon",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235003",
      "brgy_name": "Bonbon",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235004",
      "brgy_name": "Calumpang",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235005",
      "brgy_name": "Canangca-an",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235006",
      "brgy_name": "Cañang",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235007",
      "brgy_name": "Can-ukban",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235009",
      "brgy_name": "Cansalo-ay",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235010",
      "brgy_name": "Daanlungsod",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235011",
      "brgy_name": "Gawi",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235012",
      "brgy_name": "Hagdan",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235014",
      "brgy_name": "Lagunde",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235015",
      "brgy_name": "Looc",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235016",
      "brgy_name": "Luka",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235017",
      "brgy_name": "Mainit",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235018",
      "brgy_name": "Manlum",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235019",
      "brgy_name": "Nueva Caceres",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235020",
      "brgy_name": "Poblacion",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235021",
      "brgy_name": "Pungtod",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235023",
      "brgy_name": "Tan-awan",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072235024",
      "brgy_name": "Tumalog",
      "city_code": "072235",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236001",
      "brgy_name": "Biasong",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236002",
      "brgy_name": "Cawit",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236003",
      "brgy_name": "Dapdap",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236004",
      "brgy_name": "Esperanza",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236005",
      "brgy_name": "Lanao",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236006",
      "brgy_name": "Lower Poblacion",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236007",
      "brgy_name": "Moabog",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236008",
      "brgy_name": "Montserrat",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236011",
      "brgy_name": "San Isidro",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236012",
      "brgy_name": "San Juan",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236013",
      "brgy_name": "Upper Poblacion",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236014",
      "brgy_name": "Villahermosa",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072236015",
      "brgy_name": "Imelda",
      "city_code": "072236",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237001",
      "brgy_name": "Anislag",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237002",
      "brgy_name": "Anopog",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237003",
      "brgy_name": "Binabag",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237004",
      "brgy_name": "Buhingtubig",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237005",
      "brgy_name": "Busay",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237006",
      "brgy_name": "Butong",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237007",
      "brgy_name": "Cabiangon",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237008",
      "brgy_name": "Camugao",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237009",
      "brgy_name": "Duangan",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237010",
      "brgy_name": "Guimbawian",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237011",
      "brgy_name": "Lamac",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237012",
      "brgy_name": "Lut-od",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237013",
      "brgy_name": "Mangoto",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237014",
      "brgy_name": "Opao",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237015",
      "brgy_name": "Pandacan",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237016",
      "brgy_name": "Poblacion",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237017",
      "brgy_name": "Punod",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237018",
      "brgy_name": "Rizal",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237019",
      "brgy_name": "Sacsac",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237020",
      "brgy_name": "Sambagon",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237021",
      "brgy_name": "Sibago",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237022",
      "brgy_name": "Tajao",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237023",
      "brgy_name": "Tangub",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237024",
      "brgy_name": "Tanibag",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237025",
      "brgy_name": "Tupas",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072237026",
      "brgy_name": "Tutay",
      "city_code": "072237",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238001",
      "brgy_name": "Adela",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238002",
      "brgy_name": "Altavista",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238003",
      "brgy_name": "Cagcagan",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238004",
      "brgy_name": "Cansabusab",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238005",
      "brgy_name": "Daan Paz",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238006",
      "brgy_name": "Eastern Poblacion",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238007",
      "brgy_name": "Esperanza",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238008",
      "brgy_name": "Libertad",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238010",
      "brgy_name": "Mabini",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238011",
      "brgy_name": "Mercedes",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238012",
      "brgy_name": "Pagsa",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238013",
      "brgy_name": "Paz",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238014",
      "brgy_name": "Rizal",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238015",
      "brgy_name": "San Jose",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238016",
      "brgy_name": "Santa Rita",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238018",
      "brgy_name": "Teguis",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072238019",
      "brgy_name": "Western Poblacion",
      "city_code": "072238",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239001",
      "brgy_name": "Butong",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239002",
      "brgy_name": "Can-abuhon",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239003",
      "brgy_name": "Canduling",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239004",
      "brgy_name": "Cansalonoy",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239005",
      "brgy_name": "Cansayahon",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239006",
      "brgy_name": "Ilaya",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239007",
      "brgy_name": "Langin",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239008",
      "brgy_name": "Libo-o",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239009",
      "brgy_name": "Malalay",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239010",
      "brgy_name": "Palanas",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239011",
      "brgy_name": "Poblacion",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239012",
      "brgy_name": "Santa Cruz",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239013",
      "brgy_name": "Tupas",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072239014",
      "brgy_name": "Vive",
      "city_code": "072239",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240001",
      "brgy_name": "Basak",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240002",
      "brgy_name": "Bonbon",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240003",
      "brgy_name": "Bulangsuran",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240004",
      "brgy_name": "Calatagan",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240005",
      "brgy_name": "Cambigong",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240006",
      "brgy_name": "Canorong",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240007",
      "brgy_name": "Colase",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240008",
      "brgy_name": "Dalahikan",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240009",
      "brgy_name": "Jumangpas",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240010",
      "brgy_name": "Camburoy",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240011",
      "brgy_name": "Poblacion",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240012",
      "brgy_name": "San Sebastian",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240013",
      "brgy_name": "Suba",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240014",
      "brgy_name": "Tangbo",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072240015",
      "brgy_name": "Monteverde",
      "city_code": "072240",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241002",
      "brgy_name": "Balud",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241003",
      "brgy_name": "Balungag",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241005",
      "brgy_name": "Basak",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241007",
      "brgy_name": "Bugho",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241008",
      "brgy_name": "Cabatbatan",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241009",
      "brgy_name": "Greenhills",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241010",
      "brgy_name": "Lantawan",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241011",
      "brgy_name": "Liburon",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241012",
      "brgy_name": "Magsico",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241013",
      "brgy_name": "Poblacion North",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241014",
      "brgy_name": "Panadtaran",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241015",
      "brgy_name": "Pitalo",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241016",
      "brgy_name": "San Isidro",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241017",
      "brgy_name": "Sangat",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241018",
      "brgy_name": "Poblacion South",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241019",
      "brgy_name": "Tabionan",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241020",
      "brgy_name": "Tananas",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241021",
      "brgy_name": "Tinubdan",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241022",
      "brgy_name": "Tonggo",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241023",
      "brgy_name": "Tubod",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072241024",
      "brgy_name": "Ilaya",
      "city_code": "072241",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242001",
      "brgy_name": "Montealegre",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242002",
      "brgy_name": "Cabunga-an",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242003",
      "brgy_name": "Campo",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242004",
      "brgy_name": "Consuelo",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242006",
      "brgy_name": "Esperanza",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242007",
      "brgy_name": "Himensulan",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242008",
      "brgy_name": "Northern Poblacion",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242009",
      "brgy_name": "San Isidro",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242010",
      "brgy_name": "Santa Cruz",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242011",
      "brgy_name": "Santiago",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242012",
      "brgy_name": "Sonog",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242013",
      "brgy_name": "Southern Poblacion",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242014",
      "brgy_name": "Unidos",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242015",
      "brgy_name": "Union",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072242016",
      "brgy_name": "Western Poblacion",
      "city_code": "072242",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243001",
      "brgy_name": "Anapog",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243002",
      "brgy_name": "Argawanon",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243003",
      "brgy_name": "Bagtic",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243004",
      "brgy_name": "Bancasan",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243005",
      "brgy_name": "Batad",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243006",
      "brgy_name": "Busogon",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243007",
      "brgy_name": "Calambua",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243008",
      "brgy_name": "Canagahan",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243009",
      "brgy_name": "Dapdap",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243010",
      "brgy_name": "Gawaygaway",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243011",
      "brgy_name": "Hagnaya",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243012",
      "brgy_name": "Kayam",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243013",
      "brgy_name": "Kinawahan",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243014",
      "brgy_name": "Lambusan",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243015",
      "brgy_name": "Lawis",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243016",
      "brgy_name": "Libaong",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243017",
      "brgy_name": "Looc",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243018",
      "brgy_name": "Luyang",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243019",
      "brgy_name": "Mano",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243020",
      "brgy_name": "Poblacion",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243021",
      "brgy_name": "Punta",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243022",
      "brgy_name": "Sab-a",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243023",
      "brgy_name": "San Miguel",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243024",
      "brgy_name": "Tacup",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243025",
      "brgy_name": "Tambongon",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243026",
      "brgy_name": "To-ong",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072243027",
      "brgy_name": "Victoria",
      "city_code": "072243",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244002",
      "brgy_name": "Hagdan",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244003",
      "brgy_name": "Hilantagaan",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244004",
      "brgy_name": "Kinatarkan",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244005",
      "brgy_name": "Langub",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244006",
      "brgy_name": "Maricaban",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244007",
      "brgy_name": "Okoy",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244008",
      "brgy_name": "Poblacion",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244009",
      "brgy_name": "Balidbid",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244010",
      "brgy_name": "Pooc",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072244011",
      "brgy_name": "Talisay",
      "city_code": "072244",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245001",
      "brgy_name": "Bunlan",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245002",
      "brgy_name": "Cabutongan",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245003",
      "brgy_name": "Candamiang",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245004",
      "brgy_name": "Liloan",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245005",
      "brgy_name": "Lip-tong",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245006",
      "brgy_name": "Looc",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245007",
      "brgy_name": "Pasil",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245008",
      "brgy_name": "Poblacion",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245009",
      "brgy_name": "Talisay",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072245010",
      "brgy_name": "Canlumacad",
      "city_code": "072245",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246001",
      "brgy_name": "Abugon",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246002",
      "brgy_name": "Bae",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246003",
      "brgy_name": "Bagacay",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246004",
      "brgy_name": "Bahay",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246005",
      "brgy_name": "Banlot",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246006",
      "brgy_name": "Basak",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246007",
      "brgy_name": "Bato",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246008",
      "brgy_name": "Cagay",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246009",
      "brgy_name": "Can-aga",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246010",
      "brgy_name": "Candaguit",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246011",
      "brgy_name": "Cantolaroy",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246012",
      "brgy_name": "Dugoan",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246013",
      "brgy_name": "Guimbangco-an",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246014",
      "brgy_name": "Lamacan",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246015",
      "brgy_name": "Libo",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246016",
      "brgy_name": "Lindogon",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246017",
      "brgy_name": "Magcagong",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246018",
      "brgy_name": "Manatad",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246019",
      "brgy_name": "Mangyan",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246020",
      "brgy_name": "Papan",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246021",
      "brgy_name": "Poblacion",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246022",
      "brgy_name": "Sabang",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246023",
      "brgy_name": "Sayao",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246024",
      "brgy_name": "Simala",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072246025",
      "brgy_name": "Tubod",
      "city_code": "072246",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247001",
      "brgy_name": "Ampongol",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247002",
      "brgy_name": "Bagakay",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247003",
      "brgy_name": "Bagatayam",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247004",
      "brgy_name": "Bawo",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247005",
      "brgy_name": "Cabalawan",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247006",
      "brgy_name": "Cabangahan",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247007",
      "brgy_name": "Calumboyan",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247009",
      "brgy_name": "Dakit",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247010",
      "brgy_name": "Damolog",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247011",
      "brgy_name": "Ibabao",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247012",
      "brgy_name": "Liki",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247013",
      "brgy_name": "Lubo",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247014",
      "brgy_name": "Mohon",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247015",
      "brgy_name": "Nahus-an",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247016",
      "brgy_name": "Poblacion",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247017",
      "brgy_name": "Tabunok",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247018",
      "brgy_name": "Takay",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072247019",
      "brgy_name": "Pansoy",
      "city_code": "072247",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248001",
      "brgy_name": "Alang-alang",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248002",
      "brgy_name": "Caduawan",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248003",
      "brgy_name": "Kal-anan",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248004",
      "brgy_name": "Camoboan",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248005",
      "brgy_name": "Canaocanao",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248006",
      "brgy_name": "Combado",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248007",
      "brgy_name": "Daantabogon",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248008",
      "brgy_name": "Ilihan",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248009",
      "brgy_name": "Labangon",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248010",
      "brgy_name": "Libjo",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248011",
      "brgy_name": "Loong",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248012",
      "brgy_name": "Mabuli",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248013",
      "brgy_name": "Managase",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248014",
      "brgy_name": "Manlagtang",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248015",
      "brgy_name": "Maslog",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248016",
      "brgy_name": "Muabog",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248017",
      "brgy_name": "Pio",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248018",
      "brgy_name": "Poblacion",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248019",
      "brgy_name": "Salag",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248020",
      "brgy_name": "Sambag",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248021",
      "brgy_name": "San Isidro",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248022",
      "brgy_name": "San Vicente",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248023",
      "brgy_name": "Somosa",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248024",
      "brgy_name": "Taba-ao",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072248025",
      "brgy_name": "Tapul",
      "city_code": "072248",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249001",
      "brgy_name": "Bongon",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249005",
      "brgy_name": "Kanlim-ao",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249006",
      "brgy_name": "Kanluhangon",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249008",
      "brgy_name": "Kantubaon",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249010",
      "brgy_name": "Dalid",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249013",
      "brgy_name": "Mabunao",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249014",
      "brgy_name": "Maravilla",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249015",
      "brgy_name": "Olivo",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249016",
      "brgy_name": "Poblacion",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249017",
      "brgy_name": "Tabunok",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249018",
      "brgy_name": "Tigbawan",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072249019",
      "brgy_name": "Villahermosa",
      "city_code": "072249",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250001",
      "brgy_name": "Bulacao",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250002",
      "brgy_name": "Cadulawan",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250003",
      "brgy_name": "Cansojong",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250004",
      "brgy_name": "Dumlog",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250005",
      "brgy_name": "Jaclupan",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250006",
      "brgy_name": "Lagtang",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250007",
      "brgy_name": "Lawaan I",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250008",
      "brgy_name": "Linao",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250009",
      "brgy_name": "Maghaway",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250010",
      "brgy_name": "Manipis",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250011",
      "brgy_name": "Mohon",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250012",
      "brgy_name": "Poblacion",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250013",
      "brgy_name": "Pooc",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250014",
      "brgy_name": "San Isidro",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250015",
      "brgy_name": "San Roque",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250016",
      "brgy_name": "Tabunoc",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250017",
      "brgy_name": "Tangke",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250018",
      "brgy_name": "Tapul",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250019",
      "brgy_name": "Biasong",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250020",
      "brgy_name": "Camp IV",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250021",
      "brgy_name": "Lawaan II",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072250022",
      "brgy_name": "Lawaan III",
      "city_code": "072250",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251001",
      "brgy_name": "Awihao",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251002",
      "brgy_name": "Bagakay",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251003",
      "brgy_name": "Bato",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251004",
      "brgy_name": "Biga",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251005",
      "brgy_name": "Bulongan",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251006",
      "brgy_name": "Bunga",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251007",
      "brgy_name": "Cabitoonan",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251008",
      "brgy_name": "Calongcalong",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251009",
      "brgy_name": "Cambang-ug",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251010",
      "brgy_name": "Camp 8",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251011",
      "brgy_name": "Canlumampao",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251012",
      "brgy_name": "Cantabaco",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251013",
      "brgy_name": "Capitan Claudio",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251014",
      "brgy_name": "Carmen",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251015",
      "brgy_name": "Daanglungsod",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251016",
      "brgy_name": "Don Andres Soriano (Lutopan)",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251017",
      "brgy_name": "Dumlog",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251018",
      "brgy_name": "Ibo",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251019",
      "brgy_name": "Ilihan",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251020",
      "brgy_name": "Landahan",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251021",
      "brgy_name": "Loay",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251022",
      "brgy_name": "Luray II",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251023",
      "brgy_name": "Juan Climaco, Sr. (Magdugo)",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251024",
      "brgy_name": "Gen. Climaco (Malubog)",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251025",
      "brgy_name": "Matab-ang",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251026",
      "brgy_name": "Media Once",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251027",
      "brgy_name": "Pangamihan",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251028",
      "brgy_name": "Poblacion",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251029",
      "brgy_name": "Poog",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251030",
      "brgy_name": "Putingbato",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251031",
      "brgy_name": "Sagay",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251032",
      "brgy_name": "Sam-ang",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251033",
      "brgy_name": "Sangi",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251034",
      "brgy_name": "Santo Niño (Mainggit)",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251035",
      "brgy_name": "Subayon",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251037",
      "brgy_name": "Talavera",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251038",
      "brgy_name": "Tungkay",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072251039",
      "brgy_name": "Tubod",
      "city_code": "072251",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252001",
      "brgy_name": "Alegria",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252002",
      "brgy_name": "Amatugan",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252003",
      "brgy_name": "Antipolo",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252004",
      "brgy_name": "Apalan",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252005",
      "brgy_name": "Bagasawe",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252006",
      "brgy_name": "Bakyawan",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252007",
      "brgy_name": "Bangkito",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252008",
      "brgy_name": "Bulwang",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252009",
      "brgy_name": "Kabangkalan",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252010",
      "brgy_name": "Kalangahan",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252011",
      "brgy_name": "Kamansi",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252012",
      "brgy_name": "Kan-an",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252013",
      "brgy_name": "Kanlunsing",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252014",
      "brgy_name": "Kansi",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252015",
      "brgy_name": "Caridad",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252016",
      "brgy_name": "Carmelo",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252017",
      "brgy_name": "Cogon",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252018",
      "brgy_name": "Colonia",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252019",
      "brgy_name": "Daan Lungsod",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252020",
      "brgy_name": "Fortaliza",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252021",
      "brgy_name": "Ga-ang",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252022",
      "brgy_name": "Gimama-a",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252023",
      "brgy_name": "Jagbuaya",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252024",
      "brgy_name": "Kabkaban",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252025",
      "brgy_name": "Kagba-o",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252026",
      "brgy_name": "Kampoot",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252027",
      "brgy_name": "Kaorasan",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252028",
      "brgy_name": "Libo",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252029",
      "brgy_name": "Lusong",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252030",
      "brgy_name": "Macupa",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252031",
      "brgy_name": "Mag-alwa",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252032",
      "brgy_name": "Mag-antoy",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252033",
      "brgy_name": "Mag-atubang",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252034",
      "brgy_name": "Maghan-ay",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252035",
      "brgy_name": "Mangga",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252036",
      "brgy_name": "Marmol",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252037",
      "brgy_name": "Molobolo",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252038",
      "brgy_name": "Montealegre",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252040",
      "brgy_name": "Putat",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252041",
      "brgy_name": "San Juan",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252042",
      "brgy_name": "Sandayong",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252043",
      "brgy_name": "Santo Niño",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252044",
      "brgy_name": "Siotes",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252045",
      "brgy_name": "Sumon",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252046",
      "brgy_name": "Tominjao",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252047",
      "brgy_name": "Tomugpa",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252048",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252049",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252050",
      "brgy_name": "Barangay III (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252051",
      "brgy_name": "Barangay IV (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252052",
      "brgy_name": "Barangay V (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252053",
      "brgy_name": "Barangay VI (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252054",
      "brgy_name": "Barangay VII (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072252055",
      "brgy_name": "Barangay VIII (Pob.)",
      "city_code": "072252",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253001",
      "brgy_name": "Buenavista",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253002",
      "brgy_name": "Calmante",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253003",
      "brgy_name": "Daan Secante",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253004",
      "brgy_name": "General",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253005",
      "brgy_name": "McArthur",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253006",
      "brgy_name": "Northern Poblacion",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253007",
      "brgy_name": "Puertobello",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253008",
      "brgy_name": "Santander",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253009",
      "brgy_name": "Secante Bag-o",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253010",
      "brgy_name": "Southern Poblacion",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "072253011",
      "brgy_name": "Villahermosa",
      "city_code": "072253",
      "province_code": "0722",
      "region_code": "07"
    },
    {
      "brgy_code": "074601001",
      "brgy_name": "Bio-os",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074601002",
      "brgy_name": "Jantianon",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074601003",
      "brgy_name": "Jugno",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074601004",
      "brgy_name": "Mag-abo",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074601005",
      "brgy_name": "Poblacion",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074601006",
      "brgy_name": "Silab",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074601007",
      "brgy_name": "Tambojangin",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074601008",
      "brgy_name": "Tandayag",
      "city_code": "074601",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602001",
      "brgy_name": "Amdus",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602002",
      "brgy_name": "Jandalamanon",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602003",
      "brgy_name": "Anibong",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602004",
      "brgy_name": "Atabay",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602005",
      "brgy_name": "Awa-an",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602006",
      "brgy_name": "Ban-ban",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602007",
      "brgy_name": "Calagcalag",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602008",
      "brgy_name": "Candana-ay",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602009",
      "brgy_name": "Carol-an",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602010",
      "brgy_name": "Gomentoc",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602011",
      "brgy_name": "Inacban",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602012",
      "brgy_name": "Iniban",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602013",
      "brgy_name": "Kilaban",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602014",
      "brgy_name": "Lamigan",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602015",
      "brgy_name": "Maaslum",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602016",
      "brgy_name": "Mabato",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602017",
      "brgy_name": "Manogtong",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602018",
      "brgy_name": "Nabhang",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602019",
      "brgy_name": "Tambo",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602020",
      "brgy_name": "Tampocon I",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602021",
      "brgy_name": "Tampocon II",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602022",
      "brgy_name": "Tibyawan",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602023",
      "brgy_name": "Tiguib",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074602024",
      "brgy_name": "Poblacion",
      "city_code": "074602",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603001",
      "brgy_name": "Balayagmanok",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603002",
      "brgy_name": "Banilad",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603003",
      "brgy_name": "Buntis",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603004",
      "brgy_name": "Buntod",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603005",
      "brgy_name": "Calangag",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603006",
      "brgy_name": "Combado",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603007",
      "brgy_name": "Doldol",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603008",
      "brgy_name": "Isugan",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603009",
      "brgy_name": "Liptong",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603010",
      "brgy_name": "Lutao",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603011",
      "brgy_name": "Magsuhot",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603012",
      "brgy_name": "Malabago",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603013",
      "brgy_name": "Mampas",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603014",
      "brgy_name": "North Poblacion",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603015",
      "brgy_name": "Sacsac",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603016",
      "brgy_name": "San Miguel",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603017",
      "brgy_name": "South Poblacion",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603018",
      "brgy_name": "Sulodpan",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603019",
      "brgy_name": "Timbanga",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603020",
      "brgy_name": "Timbao",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603021",
      "brgy_name": "Tubod",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074603022",
      "brgy_name": "West Poblacion",
      "city_code": "074603",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604001",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604002",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604003",
      "brgy_name": "Basak",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604004",
      "brgy_name": "Biñohon",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604005",
      "brgy_name": "Cabanlutan",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604006",
      "brgy_name": "Calasga-an",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604007",
      "brgy_name": "Cambagahan",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604008",
      "brgy_name": "Cambaguio",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604009",
      "brgy_name": "Cambanjao",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604010",
      "brgy_name": "Cambuilao",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604011",
      "brgy_name": "Canlargo",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604012",
      "brgy_name": "Capiñahan",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604013",
      "brgy_name": "Consolacion",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604014",
      "brgy_name": "Dansulan",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604015",
      "brgy_name": "Hangyad",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604017",
      "brgy_name": "La Paz",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604018",
      "brgy_name": "Lo-oc",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604019",
      "brgy_name": "Lonoy",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604020",
      "brgy_name": "Mabunao",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604021",
      "brgy_name": "Manlipac",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604022",
      "brgy_name": "Mansangaban",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604023",
      "brgy_name": "Okiot",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604024",
      "brgy_name": "Olympia",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604025",
      "brgy_name": "Panala-an",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604026",
      "brgy_name": "Panam-angan",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604027",
      "brgy_name": "Rosario",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604028",
      "brgy_name": "Sab-ahan",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604029",
      "brgy_name": "San Isidro",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604030",
      "brgy_name": "Katacgahan (Tacgahan)",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604031",
      "brgy_name": "Tagpo",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604032",
      "brgy_name": "Talungon",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604033",
      "brgy_name": "Tamisu",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604034",
      "brgy_name": "Tamogong",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604035",
      "brgy_name": "Tangculogan",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074604036",
      "brgy_name": "Valencia",
      "city_code": "074604",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605001",
      "brgy_name": "Actin",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605002",
      "brgy_name": "Bal-os",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605003",
      "brgy_name": "Bongalonan",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605004",
      "brgy_name": "Cabalayongan",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605005",
      "brgy_name": "Cabatuanan",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605006",
      "brgy_name": "Linantayan",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605007",
      "brgy_name": "Maglinao",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605008",
      "brgy_name": "Nagbo-alao",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605009",
      "brgy_name": "Olandao",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074605010",
      "brgy_name": "Poblacion",
      "city_code": "074605",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606001",
      "brgy_name": "Ali-is",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606002",
      "brgy_name": "Banaybanay",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606003",
      "brgy_name": "Banga",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606004",
      "brgy_name": "Villasol (Bato)",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606005",
      "brgy_name": "Boyco",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606006",
      "brgy_name": "Bugay",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606007",
      "brgy_name": "Cansumalig",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606008",
      "brgy_name": "Dawis",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606009",
      "brgy_name": "Kalamtukan",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606010",
      "brgy_name": "Kalumboyan",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606011",
      "brgy_name": "Malabugas",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606012",
      "brgy_name": "Mandu-ao",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606013",
      "brgy_name": "Maninihon",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606014",
      "brgy_name": "Minaba",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606015",
      "brgy_name": "Nangka",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606016",
      "brgy_name": "Narra",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606017",
      "brgy_name": "Pagatban",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606018",
      "brgy_name": "Poblacion",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606019",
      "brgy_name": "San Jose",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606020",
      "brgy_name": "San Miguel",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606021",
      "brgy_name": "San Roque",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606022",
      "brgy_name": "Suba (Pob.)",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606023",
      "brgy_name": "Tabuan",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606024",
      "brgy_name": "Tayawan",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606025",
      "brgy_name": "Tinago (Pob.)",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606026",
      "brgy_name": "Ubos (Pob.)",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606027",
      "brgy_name": "Villareal",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074606028",
      "brgy_name": "San Isidro",
      "city_code": "074606",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607001",
      "brgy_name": "Atotes",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607002",
      "brgy_name": "Batangan",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607003",
      "brgy_name": "Bulod",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607004",
      "brgy_name": "Cabcaban",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607005",
      "brgy_name": "Cabugan",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607006",
      "brgy_name": "Camudlas",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607007",
      "brgy_name": "Canluto",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607008",
      "brgy_name": "Danao",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607009",
      "brgy_name": "Danawan",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607010",
      "brgy_name": "Domolog",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607011",
      "brgy_name": "Malaga",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607012",
      "brgy_name": "Manseje",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607013",
      "brgy_name": "Matobato",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607014",
      "brgy_name": "Nagcasunog",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607015",
      "brgy_name": "Nalundan",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607016",
      "brgy_name": "Pangalaycayan",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607017",
      "brgy_name": "Peñahan",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607018",
      "brgy_name": "Poblacion (Payabon)",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607019",
      "brgy_name": "Salong",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607020",
      "brgy_name": "Tagaytay",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607021",
      "brgy_name": "Tinaogan",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074607022",
      "brgy_name": "Tubod",
      "city_code": "074607",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608001",
      "brgy_name": "Bayog",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608002",
      "brgy_name": "Binalbagan",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608003",
      "brgy_name": "Bucalan (East Budsalan)",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608004",
      "brgy_name": "Linothangan",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608005",
      "brgy_name": "Lumapao",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608006",
      "brgy_name": "Malaiba",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608007",
      "brgy_name": "Masulog",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608008",
      "brgy_name": "Panubigan",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608009",
      "brgy_name": "Mabigo (Pob.)",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608010",
      "brgy_name": "Pula",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608011",
      "brgy_name": "Budlasan (West Budlasan)",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074608012",
      "brgy_name": "Ninoy Aquino",
      "city_code": "074608",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609001",
      "brgy_name": "Anahawan",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609002",
      "brgy_name": "Apo Island",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609003",
      "brgy_name": "Bagacay",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609004",
      "brgy_name": "Baslay",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609005",
      "brgy_name": "Batuhon Dacu",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609006",
      "brgy_name": "Boloc-boloc",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609007",
      "brgy_name": "Bulak",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609008",
      "brgy_name": "Bunga",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609009",
      "brgy_name": "Casile",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609010",
      "brgy_name": "Libjo",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609011",
      "brgy_name": "Lipayo",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609012",
      "brgy_name": "Maayongtubig",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609013",
      "brgy_name": "Mag-aso",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609014",
      "brgy_name": "Magsaysay",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609015",
      "brgy_name": "Malongcay Dacu",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609016",
      "brgy_name": "Masaplod Norte",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609017",
      "brgy_name": "Masaplod Sur",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609018",
      "brgy_name": "Panubtuban",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609019",
      "brgy_name": "Poblacion I",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609020",
      "brgy_name": "Poblacion II",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609021",
      "brgy_name": "Poblacion III",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609022",
      "brgy_name": "Tugawe",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074609023",
      "brgy_name": "Tunga-tunga",
      "city_code": "074609",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610001",
      "brgy_name": "Bagacay",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610002",
      "brgy_name": "Bajumpandan",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610003",
      "brgy_name": "Balugo",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610004",
      "brgy_name": "Banilad",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610005",
      "brgy_name": "Bantayan",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610006",
      "brgy_name": "Batinguel",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610007",
      "brgy_name": "Bunao",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610008",
      "brgy_name": "Cadawinonan",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610009",
      "brgy_name": "Calindagan",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610010",
      "brgy_name": "Camanjac",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610011",
      "brgy_name": "Candau-ay",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610012",
      "brgy_name": "Cantil-e",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610013",
      "brgy_name": "Daro",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610014",
      "brgy_name": "Junob",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610015",
      "brgy_name": "Looc",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610016",
      "brgy_name": "Mangnao-Canal",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610017",
      "brgy_name": "Motong",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610018",
      "brgy_name": "Piapi",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610019",
      "brgy_name": "Poblacion No. 1 (Barangay 1)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610020",
      "brgy_name": "Poblacion No. 2 (Barangay 2)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610021",
      "brgy_name": "Poblacion No. 3 (Barangay 3)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610022",
      "brgy_name": "Poblacion No. 4 (Barangay 4)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610023",
      "brgy_name": "Poblacion No. 5 (Barangay 5)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610024",
      "brgy_name": "Poblacion No. 6 (Barangay 6)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610025",
      "brgy_name": "Poblacion No. 7 (Barangay 7)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610026",
      "brgy_name": "Poblacion No. 8 (Barangay 8)",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610027",
      "brgy_name": "Pulantubig",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610028",
      "brgy_name": "Tabuctubig",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610029",
      "brgy_name": "Taclobo",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074610030",
      "brgy_name": "Talay",
      "city_code": "074610",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611001",
      "brgy_name": "Bakid",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611002",
      "brgy_name": "Balogo",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611003",
      "brgy_name": "Banwaque",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611004",
      "brgy_name": "Basak",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611005",
      "brgy_name": "Binobohan",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611006",
      "brgy_name": "Buenavista",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611007",
      "brgy_name": "Bulado",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611008",
      "brgy_name": "Calamba",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611009",
      "brgy_name": "Calupa-an",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611010",
      "brgy_name": "Hibaiyo",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611011",
      "brgy_name": "Hilaitan",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611012",
      "brgy_name": "Hinakpan",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611013",
      "brgy_name": "Humayhumay",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611014",
      "brgy_name": "Imelda",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611015",
      "brgy_name": "Kagawasan",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611016",
      "brgy_name": "Linantuyan",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611017",
      "brgy_name": "Luz",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611018",
      "brgy_name": "Mabunga",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611019",
      "brgy_name": "Mckinley",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611020",
      "brgy_name": "Nagsaha",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611021",
      "brgy_name": "Magsaysay",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611022",
      "brgy_name": "Malusay",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611023",
      "brgy_name": "Maniak",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611025",
      "brgy_name": "Padre Zamora",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611026",
      "brgy_name": "Plagatasanon",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611027",
      "brgy_name": "Planas",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611028",
      "brgy_name": "Poblacion",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611029",
      "brgy_name": "Sandayao",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611030",
      "brgy_name": "Tacpao",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611031",
      "brgy_name": "Tinayunan Beach",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611032",
      "brgy_name": "Tinayunan Hill",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611033",
      "brgy_name": "Trinidad",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074611034",
      "brgy_name": "Villegas",
      "city_code": "074611",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612001",
      "brgy_name": "Aglahug",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612002",
      "brgy_name": "Agutayon",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612003",
      "brgy_name": "Apanangon",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612004",
      "brgy_name": "Bae",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612005",
      "brgy_name": "Bala-as",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612006",
      "brgy_name": "Bangcal",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612007",
      "brgy_name": "Banog",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612008",
      "brgy_name": "Buto",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612009",
      "brgy_name": "Cabang",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612010",
      "brgy_name": "Camandayon",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612011",
      "brgy_name": "Cangharay",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612012",
      "brgy_name": "Canlahao",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612013",
      "brgy_name": "Dayoyo",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612014",
      "brgy_name": "Eli",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612015",
      "brgy_name": "Lacaon",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612016",
      "brgy_name": "Mahanlud",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612017",
      "brgy_name": "Malabago",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612018",
      "brgy_name": "Mambaid",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612019",
      "brgy_name": "Mongpong",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612020",
      "brgy_name": "Owacan",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612021",
      "brgy_name": "Pacuan",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612022",
      "brgy_name": "Panglaya-an",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612023",
      "brgy_name": "North Poblacion",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612024",
      "brgy_name": "South Poblacion",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612025",
      "brgy_name": "Polopantao",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612026",
      "brgy_name": "Sampiniton",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612027",
      "brgy_name": "Talamban",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074612028",
      "brgy_name": "Tamao",
      "city_code": "074612",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613001",
      "brgy_name": "Aniniaw",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613002",
      "brgy_name": "Aya",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613003",
      "brgy_name": "Bagtic",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613004",
      "brgy_name": "Biga-a",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613005",
      "brgy_name": "Busilak",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613006",
      "brgy_name": "Cangabo",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613007",
      "brgy_name": "Cantupa",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613008",
      "brgy_name": "Eli",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613009",
      "brgy_name": "Guihob",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613010",
      "brgy_name": "Kansumandig",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613011",
      "brgy_name": "Mambulod",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613012",
      "brgy_name": "Mandapaton",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613013",
      "brgy_name": "Manghulyawon",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613014",
      "brgy_name": "Manluminsag",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613015",
      "brgy_name": "Mapalasan",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613016",
      "brgy_name": "Maragondong",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613017",
      "brgy_name": "Martilo",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613018",
      "brgy_name": "Nasungan",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613019",
      "brgy_name": "Pacuan",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613020",
      "brgy_name": "Pangca",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613021",
      "brgy_name": "Pisong",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613022",
      "brgy_name": "Pitogo",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613023",
      "brgy_name": "Poblacion North",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613024",
      "brgy_name": "Poblacion South",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613025",
      "brgy_name": "San Jose",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613026",
      "brgy_name": "Solongon",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613027",
      "brgy_name": "Tala-on",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613028",
      "brgy_name": "Talayong",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074613029",
      "brgy_name": "Elecia (Talostos)",
      "city_code": "074613",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614001",
      "brgy_name": "Abis",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614002",
      "brgy_name": "Arebasore",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614003",
      "brgy_name": "Bagtic",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614005",
      "brgy_name": "Banban",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614006",
      "brgy_name": "Barras",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614007",
      "brgy_name": "Bato",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614008",
      "brgy_name": "Bugnay",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614009",
      "brgy_name": "Bulibulihan",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614010",
      "brgy_name": "Bulwang",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614011",
      "brgy_name": "Campanun-an",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614012",
      "brgy_name": "Canggohob",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614013",
      "brgy_name": "Cansal-ing",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614014",
      "brgy_name": "Dagbasan",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614015",
      "brgy_name": "Dahile",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614016",
      "brgy_name": "Himocdongon",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614017",
      "brgy_name": "Hagtu",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614018",
      "brgy_name": "Inapoy",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614019",
      "brgy_name": "Lamdas",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614020",
      "brgy_name": "Lumbangan",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614021",
      "brgy_name": "Luyang",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614022",
      "brgy_name": "Manlingay",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614023",
      "brgy_name": "Mayaposi",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614024",
      "brgy_name": "Napasu-an",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614025",
      "brgy_name": "New Namangka",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614026",
      "brgy_name": "Old Namangka",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614027",
      "brgy_name": "Pandanon",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614028",
      "brgy_name": "Paniabonan",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614029",
      "brgy_name": "Pantao",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614030",
      "brgy_name": "Poblacion",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614031",
      "brgy_name": "Samac",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614032",
      "brgy_name": "Tadlong",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074614033",
      "brgy_name": "Tara",
      "city_code": "074614",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615001",
      "brgy_name": "Alangilanan",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615002",
      "brgy_name": "Bagtic",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615003",
      "brgy_name": "Balaas",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615004",
      "brgy_name": "Bantolinao",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615005",
      "brgy_name": "Bolisong",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615006",
      "brgy_name": "Butong",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615007",
      "brgy_name": "Campuyo",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615008",
      "brgy_name": "Candabong",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615009",
      "brgy_name": "Concepcion",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615010",
      "brgy_name": "Dungo-an",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615011",
      "brgy_name": "Kauswagan",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615012",
      "brgy_name": "Libjo",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615013",
      "brgy_name": "Lamogong",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615015",
      "brgy_name": "Maaslum",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615016",
      "brgy_name": "Mandalupang",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615017",
      "brgy_name": "Panciao",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615018",
      "brgy_name": "Poblacion",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615019",
      "brgy_name": "Sac-sac",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615020",
      "brgy_name": "Salvacion",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615021",
      "brgy_name": "San Isidro",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615022",
      "brgy_name": "San Jose",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615023",
      "brgy_name": "Santa Monica",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615024",
      "brgy_name": "Suba",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615025",
      "brgy_name": "Sundo-an",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615026",
      "brgy_name": "Tanglad",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615027",
      "brgy_name": "Tubod",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074615028",
      "brgy_name": "Tupas",
      "city_code": "074615",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616001",
      "brgy_name": "Abante",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616002",
      "brgy_name": "Balayong",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616003",
      "brgy_name": "Banawe",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616004",
      "brgy_name": "Datagon",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616005",
      "brgy_name": "Fatima",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616007",
      "brgy_name": "Inawasan",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616008",
      "brgy_name": "Magsusunog",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616009",
      "brgy_name": "Malalangsi",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616010",
      "brgy_name": "Mamburao",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616011",
      "brgy_name": "Mangoto",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616012",
      "brgy_name": "Poblacion",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616013",
      "brgy_name": "San Isidro",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616014",
      "brgy_name": "Santa Agueda",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616015",
      "brgy_name": "Simborio",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616016",
      "brgy_name": "Yupisan",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074616017",
      "brgy_name": "Calicanan",
      "city_code": "074616",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617001",
      "brgy_name": "Basak",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617002",
      "brgy_name": "Basiao",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617004",
      "brgy_name": "Cambaloctot",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617005",
      "brgy_name": "Cancawas",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617006",
      "brgy_name": "Janayjanay",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617007",
      "brgy_name": "Jilocon",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617008",
      "brgy_name": "Naiba",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617009",
      "brgy_name": "Poblacion",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617010",
      "brgy_name": "San Roque",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617011",
      "brgy_name": "Santo Niño",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617012",
      "brgy_name": "Señora Ascion (Calo)",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617013",
      "brgy_name": "Siapo",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617014",
      "brgy_name": "Tampi",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074617015",
      "brgy_name": "Tapon Norte",
      "city_code": "074617",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618001",
      "brgy_name": "Alangilan",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618002",
      "brgy_name": "Amio",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618003",
      "brgy_name": "Buenavista",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618004",
      "brgy_name": "Kabulacan",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618006",
      "brgy_name": "Caigangan",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618008",
      "brgy_name": "Caranoche",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618009",
      "brgy_name": "Cawitan",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618010",
      "brgy_name": "Fatima",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618011",
      "brgy_name": "Mabuhay",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618012",
      "brgy_name": "Manalongon",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618013",
      "brgy_name": "Mansagomayon",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618014",
      "brgy_name": "Milagrosa",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618015",
      "brgy_name": "Nagbinlod",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618016",
      "brgy_name": "Nagbalaye",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618017",
      "brgy_name": "Obat",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618019",
      "brgy_name": "Poblacion",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618020",
      "brgy_name": "San Francisco",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618021",
      "brgy_name": "San Jose",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618022",
      "brgy_name": "San Miguel",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618023",
      "brgy_name": "San Pedro",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618024",
      "brgy_name": "Santo Rosario",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074618025",
      "brgy_name": "Talalak",
      "city_code": "074618",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619001",
      "brgy_name": "Albiga",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619002",
      "brgy_name": "Apoloy",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619003",
      "brgy_name": "Bonawon",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619004",
      "brgy_name": "Bonbonon",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619005",
      "brgy_name": "Cabangahan",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619007",
      "brgy_name": "Canaway",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619008",
      "brgy_name": "Casala-an",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619009",
      "brgy_name": "Caticugan",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619010",
      "brgy_name": "Datag",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619011",
      "brgy_name": "Giliga-on",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619012",
      "brgy_name": "Inalad",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619013",
      "brgy_name": "Malabuhan",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619014",
      "brgy_name": "Maloh",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619015",
      "brgy_name": "Mantiquil",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619016",
      "brgy_name": "Mantuyop",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619017",
      "brgy_name": "Napacao",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619018",
      "brgy_name": "Poblacion I",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619019",
      "brgy_name": "Poblacion II",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619020",
      "brgy_name": "Poblacion III",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619021",
      "brgy_name": "Poblacion IV",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619022",
      "brgy_name": "Salag",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619023",
      "brgy_name": "San Jose",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619024",
      "brgy_name": "Sandulot",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619025",
      "brgy_name": "Si-it",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619026",
      "brgy_name": "Sumaliring",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074619027",
      "brgy_name": "Tayak",
      "city_code": "074619",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620001",
      "brgy_name": "Agan-an",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620002",
      "brgy_name": "Ajong",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620003",
      "brgy_name": "Balugo",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620004",
      "brgy_name": "Bolocboloc",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620005",
      "brgy_name": "Calabnugan",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620006",
      "brgy_name": "Cangmating",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620007",
      "brgy_name": "Enrique Villanueva",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620008",
      "brgy_name": "Looc",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620009",
      "brgy_name": "Magatas",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620010",
      "brgy_name": "Maningcao",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620011",
      "brgy_name": "Maslog",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620012",
      "brgy_name": "Poblacion",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620013",
      "brgy_name": "San Antonio",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620014",
      "brgy_name": "Tubigon",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074620015",
      "brgy_name": "Tubtubon",
      "city_code": "074620",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621001",
      "brgy_name": "Azagra",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621002",
      "brgy_name": "Bahi-an",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621003",
      "brgy_name": "Luca",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621004",
      "brgy_name": "Manipis",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621005",
      "brgy_name": "Novallas",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621006",
      "brgy_name": "Obogon",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621007",
      "brgy_name": "Pal-ew",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621008",
      "brgy_name": "Poblacion I (Barangay 1)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621009",
      "brgy_name": "Poblacion II (Barangay 2)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621010",
      "brgy_name": "Poblacion III (Barangay 3)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621011",
      "brgy_name": "Poblacion IV (Barangay 4)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621012",
      "brgy_name": "Poblacion V (Barangay 5)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621013",
      "brgy_name": "Poblacion VI (Barangay 6)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621014",
      "brgy_name": "Poblacion VII (Barangay 7)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621015",
      "brgy_name": "Poblacion VIII (Barangay 8)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621016",
      "brgy_name": "Poblacion IX (Barangay 9)",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621017",
      "brgy_name": "Polo",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621018",
      "brgy_name": "San Isidro",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621019",
      "brgy_name": "San Jose",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621020",
      "brgy_name": "San Miguel",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621021",
      "brgy_name": "Santa Cruz Nuevo",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621022",
      "brgy_name": "Santa Cruz Viejo",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621023",
      "brgy_name": "Santo Niño",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074621025",
      "brgy_name": "Tugas",
      "city_code": "074621",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622001",
      "brgy_name": "Bacong",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622002",
      "brgy_name": "Bago",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622003",
      "brgy_name": "Banga",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622004",
      "brgy_name": "Cabulotan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622005",
      "brgy_name": "Cambaye",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622006",
      "brgy_name": "Dalaupon",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622007",
      "brgy_name": "Guincalaban",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622008",
      "brgy_name": "Ilaya-Tayasan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622009",
      "brgy_name": "Jilabangan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622010",
      "brgy_name": "Lag-it",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622011",
      "brgy_name": "Linao",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622012",
      "brgy_name": "Lutay",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622013",
      "brgy_name": "Maglihe",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622014",
      "brgy_name": "Matauta",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622015",
      "brgy_name": "Magtuhao",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622016",
      "brgy_name": "Matuog",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622017",
      "brgy_name": "Numnum",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622018",
      "brgy_name": "Palaslan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622019",
      "brgy_name": "Pindahan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622020",
      "brgy_name": "Pinalubngan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622021",
      "brgy_name": "Pinocawan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622022",
      "brgy_name": "Poblacion",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622023",
      "brgy_name": "Santa Cruz",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622024",
      "brgy_name": "Saying",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622025",
      "brgy_name": "Suquib",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622026",
      "brgy_name": "Tamao",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622027",
      "brgy_name": "Tambulan",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074622028",
      "brgy_name": "Tanlad",
      "city_code": "074622",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623001",
      "brgy_name": "Apolong",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623002",
      "brgy_name": "Balabag East",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623003",
      "brgy_name": "Balabag West",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623004",
      "brgy_name": "Balayagmanok",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623005",
      "brgy_name": "Balili",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623006",
      "brgy_name": "Balugo",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623007",
      "brgy_name": "Bongbong",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623008",
      "brgy_name": "Bong-ao",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623009",
      "brgy_name": "Calayugan",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623010",
      "brgy_name": "Cambucad",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623011",
      "brgy_name": "Dobdob",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623012",
      "brgy_name": "Jawa",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623013",
      "brgy_name": "Caidiocan",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623014",
      "brgy_name": "Liptong",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623015",
      "brgy_name": "Lunga",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623016",
      "brgy_name": "Malabo",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623017",
      "brgy_name": "Malaunay",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623018",
      "brgy_name": "Mampas",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623019",
      "brgy_name": "Palinpinon",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623020",
      "brgy_name": "North Poblacion",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623021",
      "brgy_name": "South Poblacion",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623022",
      "brgy_name": "Puhagan",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623023",
      "brgy_name": "Pulangbato",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074623024",
      "brgy_name": "Sagbang",
      "city_code": "074623",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624001",
      "brgy_name": "Bagawines",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624002",
      "brgy_name": "Bairan",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624003",
      "brgy_name": "Don Espiridion Villegas",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624004",
      "brgy_name": "Guba",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624005",
      "brgy_name": "Cabulihan",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624006",
      "brgy_name": "Macapso",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624007",
      "brgy_name": "Malangsa",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624008",
      "brgy_name": "Molobolo",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624009",
      "brgy_name": "Maglahos",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624010",
      "brgy_name": "Pinocawan",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624011",
      "brgy_name": "Poblacion",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624012",
      "brgy_name": "Puan",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624013",
      "brgy_name": "Tabon",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624014",
      "brgy_name": "Tagbino",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074624015",
      "brgy_name": "Ulay",
      "city_code": "074624",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625001",
      "brgy_name": "Basac",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625002",
      "brgy_name": "Calango",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625003",
      "brgy_name": "Lotuban",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625004",
      "brgy_name": "Malongcay Diot",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625005",
      "brgy_name": "Maluay",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625006",
      "brgy_name": "Mayabon",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625007",
      "brgy_name": "Nabago",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625008",
      "brgy_name": "Nasig-id",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625009",
      "brgy_name": "Najandig",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "074625010",
      "brgy_name": "Poblacion",
      "city_code": "074625",
      "province_code": "0746",
      "region_code": "07"
    },
    {
      "brgy_code": "076101001",
      "brgy_name": "Balolong",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101002",
      "brgy_name": "Bino-ongan",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101003",
      "brgy_name": "Bitaug",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101004",
      "brgy_name": "Bolot",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101005",
      "brgy_name": "Camogao",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101006",
      "brgy_name": "Cangmangki",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101007",
      "brgy_name": "Libo",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101008",
      "brgy_name": "Lomangcapan",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101009",
      "brgy_name": "Lotloton",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101010",
      "brgy_name": "Manan-ao",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101011",
      "brgy_name": "Olave",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101012",
      "brgy_name": "Parian",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101013",
      "brgy_name": "Poblacion",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076101014",
      "brgy_name": "Tulapos",
      "city_code": "076101",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102001",
      "brgy_name": "Bagacay",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102002",
      "brgy_name": "Balolang",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102003",
      "brgy_name": "Basac",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102004",
      "brgy_name": "Bintangan",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102005",
      "brgy_name": "Bontod",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102006",
      "brgy_name": "Cabulihan",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102007",
      "brgy_name": "Calunasan",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102008",
      "brgy_name": "Candigum",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102009",
      "brgy_name": "Cang-allas",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102010",
      "brgy_name": "Cang-apa",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102011",
      "brgy_name": "Cangbagsa",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102012",
      "brgy_name": "Cangmalalag",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102013",
      "brgy_name": "Canlambo",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102014",
      "brgy_name": "Canlasog",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102015",
      "brgy_name": "Catamboan",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102016",
      "brgy_name": "Helen (Datag)",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102017",
      "brgy_name": "Nonoc",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102018",
      "brgy_name": "North Poblacion",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102019",
      "brgy_name": "South Poblacion",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102020",
      "brgy_name": "Ponong",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102021",
      "brgy_name": "Sabang",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102022",
      "brgy_name": "Sandugan",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076102023",
      "brgy_name": "Taculing",
      "city_code": "076102",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103001",
      "brgy_name": "Campalanas",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103002",
      "brgy_name": "Cangclaran",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103003",
      "brgy_name": "Cangomantong",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103004",
      "brgy_name": "Capalasanan",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103005",
      "brgy_name": "Catamboan (Pob.)",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103006",
      "brgy_name": "Gabayan",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103007",
      "brgy_name": "Kimba",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103008",
      "brgy_name": "Kinamandagan",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103009",
      "brgy_name": "Lower Cabangcalan",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103010",
      "brgy_name": "Nagerong",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103011",
      "brgy_name": "Po-o",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103012",
      "brgy_name": "Simacolong",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103013",
      "brgy_name": "Tagmanocan",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103014",
      "brgy_name": "Talayong",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103015",
      "brgy_name": "Tigbawan (Pob.)",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103016",
      "brgy_name": "Tignao",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103017",
      "brgy_name": "Upper Cabangcalan",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076103018",
      "brgy_name": "Ytaya",
      "city_code": "076103",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104001",
      "brgy_name": "Bogo",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104002",
      "brgy_name": "Bonga",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104003",
      "brgy_name": "Cabal-asan",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104004",
      "brgy_name": "Calunasan",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104005",
      "brgy_name": "Candaping A",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104006",
      "brgy_name": "Candaping B",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104007",
      "brgy_name": "Cantaroc A",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104008",
      "brgy_name": "Cantaroc B",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104009",
      "brgy_name": "Cantugbas",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104010",
      "brgy_name": "Lico-an",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104011",
      "brgy_name": "Lilo-an",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104012",
      "brgy_name": "Looc",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104013",
      "brgy_name": "Logucan",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104014",
      "brgy_name": "Minalulan",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104015",
      "brgy_name": "Nabutay",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104016",
      "brgy_name": "Olang",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104017",
      "brgy_name": "Pisong A",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104018",
      "brgy_name": "Pisong B",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104019",
      "brgy_name": "Poblacion Norte",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104020",
      "brgy_name": "Poblacion Sur",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104021",
      "brgy_name": "Saguing",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076104022",
      "brgy_name": "Sawang",
      "city_code": "076104",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105001",
      "brgy_name": "Canasagan",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105002",
      "brgy_name": "Candura",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105003",
      "brgy_name": "Cangmunag",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105004",
      "brgy_name": "Cansayang",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105005",
      "brgy_name": "Catulayan",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105006",
      "brgy_name": "Lala-o",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105007",
      "brgy_name": "Maite",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105008",
      "brgy_name": "Napo",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105009",
      "brgy_name": "Paliton",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105010",
      "brgy_name": "Poblacion",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105011",
      "brgy_name": "Solangon",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105012",
      "brgy_name": "Tag-ibo",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105013",
      "brgy_name": "Tambisan",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105014",
      "brgy_name": "Timbaon",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076105015",
      "brgy_name": "Tubod",
      "city_code": "076105",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106001",
      "brgy_name": "Banban",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106002",
      "brgy_name": "Bolos",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106003",
      "brgy_name": "Caipilan",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106004",
      "brgy_name": "Caitican",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106005",
      "brgy_name": "Calalinan",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106006",
      "brgy_name": "Cang-atuyom",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106007",
      "brgy_name": "Canal",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106008",
      "brgy_name": "Candanay Norte",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106009",
      "brgy_name": "Candanay Sur",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106010",
      "brgy_name": "Cang-adieng",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106011",
      "brgy_name": "Cang-agong",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106012",
      "brgy_name": "Cang-alwang",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106013",
      "brgy_name": "Cang-asa",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106014",
      "brgy_name": "Cang-inte",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106015",
      "brgy_name": "Cang-isad",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106016",
      "brgy_name": "Canghunoghunog",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106017",
      "brgy_name": "Cangmatnog",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106018",
      "brgy_name": "Cangmohao",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106019",
      "brgy_name": "Cantabon",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106020",
      "brgy_name": "Caticugan",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106021",
      "brgy_name": "Dumanhog",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106022",
      "brgy_name": "Ibabao",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106023",
      "brgy_name": "Lambojon",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106024",
      "brgy_name": "Luyang",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106025",
      "brgy_name": "Luzong",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106026",
      "brgy_name": "Olo",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106027",
      "brgy_name": "Pangi",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106028",
      "brgy_name": "Panlautan",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106029",
      "brgy_name": "Pasihagon",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106030",
      "brgy_name": "Pili",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106031",
      "brgy_name": "Poblacion",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106032",
      "brgy_name": "Polangyuta",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106033",
      "brgy_name": "Ponong",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106034",
      "brgy_name": "Sabang",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106035",
      "brgy_name": "San Antonio",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106036",
      "brgy_name": "Songculan",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106037",
      "brgy_name": "Tacdog",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106038",
      "brgy_name": "Tacloban",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106039",
      "brgy_name": "Tambisan",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106040",
      "brgy_name": "Tebjong",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106041",
      "brgy_name": "Tinago",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "076106042",
      "brgy_name": "Tongo",
      "city_code": "076106",
      "province_code": "0761",
      "region_code": "07"
    },
    {
      "brgy_code": "082601001",
      "brgy_name": "Aguinaldo",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601002",
      "brgy_name": "Balud (Pob.)",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601003",
      "brgy_name": "Bato (San Luis)",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601004",
      "brgy_name": "Beri",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601005",
      "brgy_name": "Bigo",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601006",
      "brgy_name": "Buenavista",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601008",
      "brgy_name": "Cagsalay",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601009",
      "brgy_name": "Campacion",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601010",
      "brgy_name": "Carapdapan",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601011",
      "brgy_name": "Casidman",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601013",
      "brgy_name": "Catumsan",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601014",
      "brgy_name": "Central (Pob.)",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601015",
      "brgy_name": "Concepcion",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601016",
      "brgy_name": "Garden (Pob.)",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601017",
      "brgy_name": "Inayawan",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601020",
      "brgy_name": "Macarthur",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601022",
      "brgy_name": "Rawis (Pob.)",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601024",
      "brgy_name": "Tangbo",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601025",
      "brgy_name": "Tawagan",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082601026",
      "brgy_name": "Tebalawon",
      "city_code": "082601",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602001",
      "brgy_name": "Bacjao",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602002",
      "brgy_name": "Cag-olango",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602003",
      "brgy_name": "Cansumangcay",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602004",
      "brgy_name": "Guinmaayohan",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602005",
      "brgy_name": "Barangay Poblacion I",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602006",
      "brgy_name": "Barangay Poblacion II",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602007",
      "brgy_name": "Barangay Poblacion III",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602008",
      "brgy_name": "Barangay Poblacion IV",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602009",
      "brgy_name": "Barangay Poblacion V",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602010",
      "brgy_name": "Barangay Poblacion VI",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602011",
      "brgy_name": "San Miguel",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602012",
      "brgy_name": "Santa Rosa",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082602013",
      "brgy_name": "Maybunga",
      "city_code": "082602",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603001",
      "brgy_name": "Balogo",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603002",
      "brgy_name": "Bangon",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603004",
      "brgy_name": "Cabay",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603008",
      "brgy_name": "Caisawan",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603009",
      "brgy_name": "Cantubi",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603010",
      "brgy_name": "General Malvar",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603011",
      "brgy_name": "Guinpoliran",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603012",
      "brgy_name": "Julag",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603013",
      "brgy_name": "Magsaysay",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603014",
      "brgy_name": "Maramag",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603015",
      "brgy_name": "Poblacion I",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603016",
      "brgy_name": "Poblacion II",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603017",
      "brgy_name": "Poblacion III",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603018",
      "brgy_name": "Poblacion IV",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082603019",
      "brgy_name": "Poblacion V",
      "city_code": "082603",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604001",
      "brgy_name": "Alang-alang",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604002",
      "brgy_name": "Amantacop",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604003",
      "brgy_name": "Ando",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604004",
      "brgy_name": "Balacdas",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604005",
      "brgy_name": "Balud",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604006",
      "brgy_name": "Banuyo",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604007",
      "brgy_name": "Baras",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604008",
      "brgy_name": "Bato",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604009",
      "brgy_name": "Bayobay",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604010",
      "brgy_name": "Benowangan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604011",
      "brgy_name": "Bugas",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604012",
      "brgy_name": "Cabalagnan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604013",
      "brgy_name": "Cabong",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604014",
      "brgy_name": "Cagbonga",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604015",
      "brgy_name": "Calico-an",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604016",
      "brgy_name": "Calingatngan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604017",
      "brgy_name": "Campesao",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604018",
      "brgy_name": "Can-abong",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604019",
      "brgy_name": "Can-aga",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604020",
      "brgy_name": "Camada",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604021",
      "brgy_name": "Canjaway",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604022",
      "brgy_name": "Canlaray",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604023",
      "brgy_name": "Canyopay",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604024",
      "brgy_name": "Divinubo",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604025",
      "brgy_name": "Hebacong",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604026",
      "brgy_name": "Hindang",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604027",
      "brgy_name": "Lalawigan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604028",
      "brgy_name": "Libuton",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604029",
      "brgy_name": "Locso-on",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604030",
      "brgy_name": "Maybacong",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604031",
      "brgy_name": "Maypangdan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604032",
      "brgy_name": "Pepelitan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604033",
      "brgy_name": "Pinanag-an",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604034",
      "brgy_name": "Purok D1 (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604035",
      "brgy_name": "Purok A (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604036",
      "brgy_name": "Purok B (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604037",
      "brgy_name": "Purok C (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604038",
      "brgy_name": "Purok D2 (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604039",
      "brgy_name": "Purok E (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604040",
      "brgy_name": "Purok F (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604041",
      "brgy_name": "Purok G (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604042",
      "brgy_name": "Purok H (Pob.)",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604043",
      "brgy_name": "Punta Maria",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604044",
      "brgy_name": "Sabang North",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604045",
      "brgy_name": "Sabang South",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604046",
      "brgy_name": "San Andres",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604047",
      "brgy_name": "San Gabriel",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604048",
      "brgy_name": "San Gregorio",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604049",
      "brgy_name": "San Jose",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604050",
      "brgy_name": "San Mateo",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604051",
      "brgy_name": "San Pablo",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604052",
      "brgy_name": "San Saturnino",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604053",
      "brgy_name": "Santa Fe",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604054",
      "brgy_name": "Siha",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604055",
      "brgy_name": "Songco",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604056",
      "brgy_name": "Sohutan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604057",
      "brgy_name": "Suribao",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604058",
      "brgy_name": "Surok",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604059",
      "brgy_name": "Taboc",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604060",
      "brgy_name": "Tabunan",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082604061",
      "brgy_name": "Tamoso",
      "city_code": "082604",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605001",
      "brgy_name": "Balagon",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605002",
      "brgy_name": "Baruk",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605003",
      "brgy_name": "Boco",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605004",
      "brgy_name": "Caghalong",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605005",
      "brgy_name": "Camantang",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605007",
      "brgy_name": "Can-ilay",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605008",
      "brgy_name": "Cansangaya",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605009",
      "brgy_name": "Canteros",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605010",
      "brgy_name": "Carolina",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605011",
      "brgy_name": "Guibuangan",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605012",
      "brgy_name": "Jepaco",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605013",
      "brgy_name": "Mabuhay",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605014",
      "brgy_name": "Malogo",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605015",
      "brgy_name": "Obong",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605016",
      "brgy_name": "Pandol",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605018",
      "brgy_name": "Barangay 1 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605019",
      "brgy_name": "Barangay 10 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605020",
      "brgy_name": "Barangay 2 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605021",
      "brgy_name": "Barangay 3 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605022",
      "brgy_name": "Barangay 4 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605023",
      "brgy_name": "Barangay 5 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605024",
      "brgy_name": "Barangay 6 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605025",
      "brgy_name": "Barangay 7 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605026",
      "brgy_name": "Barangay 8 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605027",
      "brgy_name": "Barangay 9 Poblacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605028",
      "brgy_name": "Salvacion",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605032",
      "brgy_name": "Solong",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082605033",
      "brgy_name": "Rawis",
      "city_code": "082605",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606001",
      "brgy_name": "Aroganga",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606002",
      "brgy_name": "Magongbong",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606003",
      "brgy_name": "Buenavista",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606004",
      "brgy_name": "Cabago-an",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606005",
      "brgy_name": "Caglao-an",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606006",
      "brgy_name": "Cagtabon",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606007",
      "brgy_name": "Dampigan",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606008",
      "brgy_name": "Dapdap",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606009",
      "brgy_name": "Del Pilar",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606010",
      "brgy_name": "Denigpian",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606011",
      "brgy_name": "Gap-ang",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606012",
      "brgy_name": "Japitan",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606013",
      "brgy_name": "Jicontol",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606014",
      "brgy_name": "Hilabaan",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606015",
      "brgy_name": "Hinolaso",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606016",
      "brgy_name": "Libertad",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606018",
      "brgy_name": "Magsaysay",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606019",
      "brgy_name": "Malobago",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606020",
      "brgy_name": "Osmeña",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606022",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606023",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606024",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606025",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606026",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606027",
      "brgy_name": "Barangay 14 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606028",
      "brgy_name": "Barangay 15 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606029",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606030",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606031",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606032",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606033",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606034",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606035",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606036",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606037",
      "brgy_name": "Rizal",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606038",
      "brgy_name": "San Isidro (Malabag)",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606039",
      "brgy_name": "San Pascual",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606040",
      "brgy_name": "San Roque",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606041",
      "brgy_name": "San Vicente",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606042",
      "brgy_name": "Santa Cruz",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606043",
      "brgy_name": "Santo Niño",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606044",
      "brgy_name": "Tanauan",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606046",
      "brgy_name": "Villahermosa",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606047",
      "brgy_name": "Bonghon",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606048",
      "brgy_name": "Malaintos",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082606049",
      "brgy_name": "Tikling",
      "city_code": "082606",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607001",
      "brgy_name": "Alang-alang",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607002",
      "brgy_name": "Binalay",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607003",
      "brgy_name": "Calutan",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607004",
      "brgy_name": "Camcuevas",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607005",
      "brgy_name": "Domrog",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607006",
      "brgy_name": "Limbujan",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607007",
      "brgy_name": "Magsaysay",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607008",
      "brgy_name": "Osmeña",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607009",
      "brgy_name": "Pingan",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607010",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607011",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607012",
      "brgy_name": "Poblacion Barangay 3",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607013",
      "brgy_name": "Poblacion Barangay 4",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607014",
      "brgy_name": "Poblacion Barangay 5",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607015",
      "brgy_name": "Poblacion Barangay 6",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607016",
      "brgy_name": "Poblacion Barangay 7",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607017",
      "brgy_name": "Poblacion Barangay 8",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607018",
      "brgy_name": "Laurel",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607019",
      "brgy_name": "Roxas",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607020",
      "brgy_name": "Quirino",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607021",
      "brgy_name": "San Isidro",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607022",
      "brgy_name": "San Roque",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607023",
      "brgy_name": "Santa Cruz (Opong)",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607024",
      "brgy_name": "Santa Fe",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607025",
      "brgy_name": "Tugop",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607026",
      "brgy_name": "Vigan",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607027",
      "brgy_name": "Macapagal",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607028",
      "brgy_name": "Aguinaldo",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607029",
      "brgy_name": "Quezon",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082607030",
      "brgy_name": "Tandang Sora",
      "city_code": "082607",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608001",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608002",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608003",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608004",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608005",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608006",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608007",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608008",
      "brgy_name": "Biga",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608009",
      "brgy_name": "Coticot",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608010",
      "brgy_name": "Gigoso",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608011",
      "brgy_name": "Huknan",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608012",
      "brgy_name": "Parina",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608013",
      "brgy_name": "Paya",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608015",
      "brgy_name": "President Roxas",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608017",
      "brgy_name": "San Miguel",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608018",
      "brgy_name": "Santa Cruz (Cansingkol)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608019",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082608020",
      "brgy_name": "San Isidro (Malabag)",
      "city_code": "082608",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609001",
      "brgy_name": "Alingarog",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609002",
      "brgy_name": "Bagua",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609003",
      "brgy_name": "Banaag",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609004",
      "brgy_name": "Banahao",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609005",
      "brgy_name": "Baras",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609006",
      "brgy_name": "Barbo",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609007",
      "brgy_name": "Bitaugan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609008",
      "brgy_name": "Bungtod",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609009",
      "brgy_name": "Bucao",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609010",
      "brgy_name": "Buenavista",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609011",
      "brgy_name": "Cagdara-o",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609012",
      "brgy_name": "Cagusu-an",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609013",
      "brgy_name": "Camparang",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609014",
      "brgy_name": "Campoyong",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609015",
      "brgy_name": "Cantahay",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609017",
      "brgy_name": "Casuguran",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609018",
      "brgy_name": "Cogon",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609019",
      "brgy_name": "Culasi",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609020",
      "brgy_name": "Poblacion Ward 10",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609021",
      "brgy_name": "Poblacion Ward 9-A",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609022",
      "brgy_name": "Gahoy",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609024",
      "brgy_name": "Habag",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609025",
      "brgy_name": "Hamorawon",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609026",
      "brgy_name": "Inapulangan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609027",
      "brgy_name": "Poblacion Ward 4-A",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609028",
      "brgy_name": "Lupok (Pob.)",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609029",
      "brgy_name": "Mayana",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609030",
      "brgy_name": "Ngolos",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609031",
      "brgy_name": "Pagbabangnan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609032",
      "brgy_name": "Pagnamitan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609033",
      "brgy_name": "Poblacion Ward 1",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609034",
      "brgy_name": "Poblacion Ward 2",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609035",
      "brgy_name": "Poblacion Ward 11",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609036",
      "brgy_name": "Poblacion Ward 12",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609037",
      "brgy_name": "Poblacion Ward 3",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609038",
      "brgy_name": "Poblacion Ward 4",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609039",
      "brgy_name": "Poblacion Ward 5",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609040",
      "brgy_name": "Poblacion Ward 6",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609041",
      "brgy_name": "Poblacion Ward 7",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609042",
      "brgy_name": "Poblacion Ward 8",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609043",
      "brgy_name": "Poblacion Ward 9",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609044",
      "brgy_name": "Salug",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609045",
      "brgy_name": "San Antonio",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609046",
      "brgy_name": "San Jose",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609047",
      "brgy_name": "San Pedro",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609048",
      "brgy_name": "Sapao",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609050",
      "brgy_name": "Sulangan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609051",
      "brgy_name": "Suluan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609052",
      "brgy_name": "Surok",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609054",
      "brgy_name": "Taytay",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609055",
      "brgy_name": "Timala",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609056",
      "brgy_name": "Trinidad",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609057",
      "brgy_name": "Victory Island",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609058",
      "brgy_name": "Canawayon",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609059",
      "brgy_name": "Dalaragan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609060",
      "brgy_name": "Hagna",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609061",
      "brgy_name": "Hollywood",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609062",
      "brgy_name": "San Juan",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609063",
      "brgy_name": "Santo Niño",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082609064",
      "brgy_name": "Tagporo",
      "city_code": "082609",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610001",
      "brgy_name": "Batang",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610002",
      "brgy_name": "Cacatmonan",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610003",
      "brgy_name": "Garawon",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610004",
      "brgy_name": "Canciledes",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610005",
      "brgy_name": "Carmen",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610006",
      "brgy_name": "Nagaja",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610007",
      "brgy_name": "Padang",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610008",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610009",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610010",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610011",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610012",
      "brgy_name": "San Isidro",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082610013",
      "brgy_name": "San Miguel",
      "city_code": "082610",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611001",
      "brgy_name": "Agsaman",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611007",
      "brgy_name": "Cagmanaba",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611009",
      "brgy_name": "Dorillo",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611012",
      "brgy_name": "Jewaran",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611013",
      "brgy_name": "Mabuhay",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611014",
      "brgy_name": "Magsaysay",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611015",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611016",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611017",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611018",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611019",
      "brgy_name": "Recare",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611020",
      "brgy_name": "Roxas",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082611021",
      "brgy_name": "San Roque",
      "city_code": "082611",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612001",
      "brgy_name": "Betaog",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612002",
      "brgy_name": "Bolusao",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612004",
      "brgy_name": "Guinob-an",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612005",
      "brgy_name": "Maslog",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612010",
      "brgy_name": "Barangay Poblacion 1",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612011",
      "brgy_name": "Barangay Poblacion 2",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612012",
      "brgy_name": "Barangay Poblacion 3",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612013",
      "brgy_name": "Barangay Poblacion 4",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612014",
      "brgy_name": "Barangay Poblacion 5",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612015",
      "brgy_name": "Barangay Poblacion 6",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612016",
      "brgy_name": "Barangay Poblacion 7",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612017",
      "brgy_name": "Barangay Poblacion 8",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612018",
      "brgy_name": "Barangay Poblacion 9",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612019",
      "brgy_name": "Barangay Poblacion 10",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612020",
      "brgy_name": "San Isidro",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082612021",
      "brgy_name": "Taguite",
      "city_code": "082612",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613001",
      "brgy_name": "Antipolo",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613002",
      "brgy_name": "Babanikhon",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613003",
      "brgy_name": "Bacayawan",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613004",
      "brgy_name": "Barobo",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613005",
      "brgy_name": "Burak",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613006",
      "brgy_name": "Can-ato",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613007",
      "brgy_name": "Candoros",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613008",
      "brgy_name": "Canliwag",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613010",
      "brgy_name": "Cantomco",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613011",
      "brgy_name": "Hugpa",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613012",
      "brgy_name": "Maca-anga",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613013",
      "brgy_name": "Magtino",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613014",
      "brgy_name": "Mina-anod",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613015",
      "brgy_name": "Naubay",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613016",
      "brgy_name": "Piliw",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613017",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613018",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613019",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613020",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613021",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613022",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613023",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613024",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613025",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613026",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613027",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613028",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613029",
      "brgy_name": "San Jose",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613030",
      "brgy_name": "San Miguel",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613031",
      "brgy_name": "San Roque",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613032",
      "brgy_name": "So-ong",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613033",
      "brgy_name": "Tabok",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082613034",
      "brgy_name": "Waso",
      "city_code": "082613",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614001",
      "brgy_name": "Bulawan",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614003",
      "brgy_name": "Carayacay",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614005",
      "brgy_name": "Libertad",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614006",
      "brgy_name": "Malobago",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614007",
      "brgy_name": "Maputi",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614008",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614009",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614010",
      "brgy_name": "San Miguel",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614011",
      "brgy_name": "San Roque",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614012",
      "brgy_name": "Tangbo",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614013",
      "brgy_name": "Taytay",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082614014",
      "brgy_name": "Tugas",
      "city_code": "082614",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615002",
      "brgy_name": "Camada",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615003",
      "brgy_name": "Campakerit (Botay)",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615004",
      "brgy_name": "Canloterio",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615005",
      "brgy_name": "Del Pilar",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615006",
      "brgy_name": "Guindalitan",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615007",
      "brgy_name": "Lapgap",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615008",
      "brgy_name": "Malobago",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615009",
      "brgy_name": "Maybocog",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615010",
      "brgy_name": "Maytigbao",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615011",
      "brgy_name": "Omawas",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615012",
      "brgy_name": "Patag",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615013",
      "brgy_name": "Barangay Poblacion 1",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615014",
      "brgy_name": "Barangay Poblacion 2",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615015",
      "brgy_name": "Barangay Poblacion 3",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615016",
      "brgy_name": "Barangay Poblacion 4",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615017",
      "brgy_name": "Barangay Poblacion 5",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615018",
      "brgy_name": "Barangay Poblacion 6",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615019",
      "brgy_name": "Barangay Poblacion 7",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615020",
      "brgy_name": "San Gabriel",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082615021",
      "brgy_name": "Tagaslian",
      "city_code": "082615",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616001",
      "brgy_name": "Anuron",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616002",
      "brgy_name": "Banuyo",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616003",
      "brgy_name": "Bobon",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616004",
      "brgy_name": "Busay",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616005",
      "brgy_name": "Buyayawon",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616006",
      "brgy_name": "Cabunga-an",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616007",
      "brgy_name": "Cambante",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616008",
      "brgy_name": "Barangay 1 Poblacion",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616009",
      "brgy_name": "Barangay 2 Poblacion",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616010",
      "brgy_name": "Barangay 3 Poblacion",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616011",
      "brgy_name": "San Jose",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616012",
      "brgy_name": "Sung-an",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616013",
      "brgy_name": "Palamrag (Cabiliri-an)",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616014",
      "brgy_name": "Barangay 4 Poblacion",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616015",
      "brgy_name": "Port Kennedy",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082616016",
      "brgy_name": "San Roque",
      "city_code": "082616",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617001",
      "brgy_name": "Agsam",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617002",
      "brgy_name": "Bagacay",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617003",
      "brgy_name": "Balingasag",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617004",
      "brgy_name": "Balocawe (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617005",
      "brgy_name": "Bantayan",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617006",
      "brgy_name": "Batang",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617007",
      "brgy_name": "Bato",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617008",
      "brgy_name": "Binalayan",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617009",
      "brgy_name": "Buntay",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617010",
      "brgy_name": "Burak",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617011",
      "brgy_name": "Butnga (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617012",
      "brgy_name": "Cadian",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617013",
      "brgy_name": "Cagdine",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617014",
      "brgy_name": "Cagpile",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617015",
      "brgy_name": "Cagtoog",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617016",
      "brgy_name": "Camanga (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617018",
      "brgy_name": "Dalid",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617019",
      "brgy_name": "Dao",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617020",
      "brgy_name": "Factoria",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617021",
      "brgy_name": "Gamot",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617022",
      "brgy_name": "Iwayan",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617023",
      "brgy_name": "Japay",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617024",
      "brgy_name": "Kalaw",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617025",
      "brgy_name": "Mabuhay",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617026",
      "brgy_name": "Malingon",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617027",
      "brgy_name": "Minap-os",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617028",
      "brgy_name": "Nadacpan",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617029",
      "brgy_name": "Naga",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617030",
      "brgy_name": "Pangudtan",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617031",
      "brgy_name": "Paypayon (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617033",
      "brgy_name": "Riverside (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617034",
      "brgy_name": "Rizal",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617035",
      "brgy_name": "Sabang",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617036",
      "brgy_name": "San Eduardo",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617037",
      "brgy_name": "Santa Monica",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617038",
      "brgy_name": "Saugan",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617039",
      "brgy_name": "Saurong",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617041",
      "brgy_name": "Tawagan (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617043",
      "brgy_name": "Tiguib (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617044",
      "brgy_name": "Trinidad (Maycorot)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617045",
      "brgy_name": "Alang-alang",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082617046",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "082617",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618001",
      "brgy_name": "Anislag",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618002",
      "brgy_name": "Bagte",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618003",
      "brgy_name": "Barangay No. 1 (Pob.)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618004",
      "brgy_name": "Barangay No. 2 (Pob.)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618005",
      "brgy_name": "Barangay No. 3 (Pob.)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618006",
      "brgy_name": "Barangay No. 4 (Pob.)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618007",
      "brgy_name": "Barangay No. 5 (Pob.)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618008",
      "brgy_name": "Buenavista",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618009",
      "brgy_name": "Caculangan",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618010",
      "brgy_name": "Cagdaja",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618011",
      "brgy_name": "Cambilla",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618012",
      "brgy_name": "Cantenio",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618014",
      "brgy_name": "Naga",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618015",
      "brgy_name": "Paco",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618016",
      "brgy_name": "Rizal (Pana-ugan)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618017",
      "brgy_name": "San Pedro",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618018",
      "brgy_name": "San Vicente",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618019",
      "brgy_name": "Santa Cruz (Loro Diyo)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618020",
      "brgy_name": "Santa Margarita",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618021",
      "brgy_name": "Santo Niño",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618022",
      "brgy_name": "Palactad (Valley)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618023",
      "brgy_name": "Alang-alang",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618024",
      "brgy_name": "Barangay No. 6 (Pob.)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618025",
      "brgy_name": "Barangay No. 7 (Pob.)",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082618026",
      "brgy_name": "San Isidro",
      "city_code": "082618",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619001",
      "brgy_name": "Abejao",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619002",
      "brgy_name": "Alog",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619003",
      "brgy_name": "Asgad",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619004",
      "brgy_name": "Bagtong",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619005",
      "brgy_name": "Balud",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619006",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619007",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619008",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619009",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619010",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619011",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619012",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619013",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619014",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619015",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619016",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619017",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619018",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619020",
      "brgy_name": "Buabua",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619021",
      "brgy_name": "Burak",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619022",
      "brgy_name": "Butig",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619023",
      "brgy_name": "Cagaut",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619024",
      "brgy_name": "Camanga",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619025",
      "brgy_name": "Cantomoja",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619026",
      "brgy_name": "Carapdapan",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619027",
      "brgy_name": "Caridad",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619028",
      "brgy_name": "Casili-on",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619029",
      "brgy_name": "Iberan",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619030",
      "brgy_name": "Jagnaya",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619031",
      "brgy_name": "Lusod",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619032",
      "brgy_name": "Malbog",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619033",
      "brgy_name": "Maliwaliw",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619035",
      "brgy_name": "Matarinao",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619037",
      "brgy_name": "Naparaan",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619041",
      "brgy_name": "Seguinon",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619046",
      "brgy_name": "San Roque (Bugay)",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619047",
      "brgy_name": "Santa Cruz",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619049",
      "brgy_name": "Tacla-on",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619050",
      "brgy_name": "Tagbacan",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619051",
      "brgy_name": "Palanas",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082619052",
      "brgy_name": "Talangdawan",
      "city_code": "082619",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620001",
      "brgy_name": "Bunacan",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620002",
      "brgy_name": "Campidhan",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620003",
      "brgy_name": "Casoroy",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620004",
      "brgy_name": "Libas",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620005",
      "brgy_name": "Nena (Luna)",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620006",
      "brgy_name": "Pagbabangnan",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620007",
      "brgy_name": "Barangay No. 1 Poblacion",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620008",
      "brgy_name": "Barangay No. 2 Poblacion",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620009",
      "brgy_name": "Barangay No. 3 Poblacion",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620010",
      "brgy_name": "Barangay No. 4 Poblacion",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620011",
      "brgy_name": "Barangay No. 5 Poblacion",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620012",
      "brgy_name": "Barangay No. 6 Poblacion",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620013",
      "brgy_name": "Putong",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620015",
      "brgy_name": "San Isidro",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620016",
      "brgy_name": "San Miguel",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082620020",
      "brgy_name": "Lunang",
      "city_code": "082620",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621001",
      "brgy_name": "Alugan",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621002",
      "brgy_name": "Bahay",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621003",
      "brgy_name": "Bangon",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621004",
      "brgy_name": "Baras (Lipata)",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621005",
      "brgy_name": "Binogawan",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621006",
      "brgy_name": "Cajagwayan",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621007",
      "brgy_name": "Japunan",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621008",
      "brgy_name": "Natividad",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621009",
      "brgy_name": "Pangpang",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621010",
      "brgy_name": "Barangay No. 1 (Pob.)",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621011",
      "brgy_name": "Barangay No. 2 (Pob.)",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621012",
      "brgy_name": "Barangay No. 3 (Pob.)",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621013",
      "brgy_name": "Barangay No. 4 (Pob.)",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621014",
      "brgy_name": "Barangay No. 5 (Pob.)",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621015",
      "brgy_name": "Santa Cruz",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621016",
      "brgy_name": "Tabo",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082621017",
      "brgy_name": "Tan-awan",
      "city_code": "082621",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622001",
      "brgy_name": "A-et",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622002",
      "brgy_name": "Baybay (Pob.)",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622003",
      "brgy_name": "Kandalakit",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622004",
      "brgy_name": "Del Remedio",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622005",
      "brgy_name": "Loyola Heights (Pob.)",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622006",
      "brgy_name": "Mabini",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622007",
      "brgy_name": "Maglipay (Pob.)",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622008",
      "brgy_name": "Maramara (Pob.)",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622009",
      "brgy_name": "Riverside (Pob.)",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622010",
      "brgy_name": "San Francisco",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622011",
      "brgy_name": "San Isidro",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622012",
      "brgy_name": "San Juan",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622013",
      "brgy_name": "San Mateo",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622014",
      "brgy_name": "San Vicente",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622015",
      "brgy_name": "Santo Niño",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622016",
      "brgy_name": "Santo Tomas",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622017",
      "brgy_name": "Tabi (Pob.)",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082622018",
      "brgy_name": "Abucay (Pob.)",
      "city_code": "082622",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623001",
      "brgy_name": "Batiawan",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623002",
      "brgy_name": "Beto",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623003",
      "brgy_name": "Binaloan",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623004",
      "brgy_name": "Bongdo",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623006",
      "brgy_name": "Dacul",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623007",
      "brgy_name": "Danao",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623008",
      "brgy_name": "Del Remedios",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623009",
      "brgy_name": "Gayam",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623010",
      "brgy_name": "Lomatud (Burak)",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623011",
      "brgy_name": "Mabuhay",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623012",
      "brgy_name": "Malinao",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623013",
      "brgy_name": "Mantang",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623014",
      "brgy_name": "Nato",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623015",
      "brgy_name": "Pangabutan",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623016",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623017",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623018",
      "brgy_name": "Poblacion Barangay 3",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623019",
      "brgy_name": "Poblacion Barangay 4",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623020",
      "brgy_name": "Poblacion Barangay 5",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623021",
      "brgy_name": "Poblacion Barangay 6",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623022",
      "brgy_name": "Polangi",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623023",
      "brgy_name": "San Luis",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623024",
      "brgy_name": "San Pablo",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "082623025",
      "brgy_name": "San Rafael",
      "city_code": "082623",
      "province_code": "0826",
      "region_code": "08"
    },
    {
      "brgy_code": "083701001",
      "brgy_name": "Alangilan",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701002",
      "brgy_name": "Anibongan",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701003",
      "brgy_name": "Buaya",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701004",
      "brgy_name": "Bagacay",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701005",
      "brgy_name": "Bahay",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701006",
      "brgy_name": "Balinsasayao",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701007",
      "brgy_name": "Balocawe",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701008",
      "brgy_name": "Balocawehay",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701009",
      "brgy_name": "Barayong",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701010",
      "brgy_name": "Bayabas",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701011",
      "brgy_name": "Bito (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701012",
      "brgy_name": "Buenavista",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701013",
      "brgy_name": "Bulak",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701014",
      "brgy_name": "Bunga",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701015",
      "brgy_name": "Buntay (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701016",
      "brgy_name": "Burubud-an",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701017",
      "brgy_name": "Cagbolo",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701018",
      "brgy_name": "Can-uguib (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701019",
      "brgy_name": "Can-aporong",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701020",
      "brgy_name": "Canmarating",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701021",
      "brgy_name": "Capilian",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701022",
      "brgy_name": "Cadac-an",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701023",
      "brgy_name": "Combis",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701024",
      "brgy_name": "Dingle",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701025",
      "brgy_name": "Guintagbucan (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701026",
      "brgy_name": "Hampipila",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701027",
      "brgy_name": "Katipunan",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701028",
      "brgy_name": "Kikilo",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701029",
      "brgy_name": "Laray",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701030",
      "brgy_name": "Lawa-an",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701031",
      "brgy_name": "Libertad",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701032",
      "brgy_name": "Loyonsawang (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701033",
      "brgy_name": "Mahagna (New Cagbolo)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701034",
      "brgy_name": "Mag-atubang",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701035",
      "brgy_name": "Mahayahay",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701036",
      "brgy_name": "Maitum",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701037",
      "brgy_name": "Malaguicay",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701038",
      "brgy_name": "Matagnao",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701039",
      "brgy_name": "Nalibunan (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701040",
      "brgy_name": "Nebga",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701041",
      "brgy_name": "Odiongan",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701042",
      "brgy_name": "Pagsang-an",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701043",
      "brgy_name": "Paguite",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701044",
      "brgy_name": "Parasanon",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701045",
      "brgy_name": "Picas Sur",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701046",
      "brgy_name": "Pilar",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701047",
      "brgy_name": "Pinamanagan",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701048",
      "brgy_name": "Salvacion",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701049",
      "brgy_name": "San Francisco",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701050",
      "brgy_name": "San Isidro",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701051",
      "brgy_name": "San Roque",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701052",
      "brgy_name": "Santa Fe (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701053",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701054",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701055",
      "brgy_name": "Tabigue",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701056",
      "brgy_name": "Tadoc",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701057",
      "brgy_name": "New Taligue",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701058",
      "brgy_name": "Old Taligue",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701059",
      "brgy_name": "Tib-o",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701060",
      "brgy_name": "Tinalian",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701061",
      "brgy_name": "Tinocolan",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701062",
      "brgy_name": "Tuy-a",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083701063",
      "brgy_name": "Victory (Pob.)",
      "city_code": "083701",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702001",
      "brgy_name": "Aslum",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702002",
      "brgy_name": "Astorga (Burabod)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702004",
      "brgy_name": "Bato",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702005",
      "brgy_name": "Binongto-an",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702006",
      "brgy_name": "Binotong",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702007",
      "brgy_name": "Bobonon",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702008",
      "brgy_name": "Borseth",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702009",
      "brgy_name": "Buenavista",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702010",
      "brgy_name": "Bugho",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702011",
      "brgy_name": "Buri",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702012",
      "brgy_name": "Cabadsan",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702013",
      "brgy_name": "Calaasan",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702014",
      "brgy_name": "Cambahanon",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702015",
      "brgy_name": "Cambolao",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702016",
      "brgy_name": "Canvertudes",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702017",
      "brgy_name": "Capiz",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702018",
      "brgy_name": "Cavite",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702019",
      "brgy_name": "Cogon",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702020",
      "brgy_name": "Dapdap",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702021",
      "brgy_name": "Divisoria",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702022",
      "brgy_name": "Ekiran",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702023",
      "brgy_name": "Hinapolan",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702024",
      "brgy_name": "Hubang",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702025",
      "brgy_name": "Hupit",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702026",
      "brgy_name": "Langit",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702027",
      "brgy_name": "Lingayon",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702028",
      "brgy_name": "Lourdes",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702029",
      "brgy_name": "Lukay",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702030",
      "brgy_name": "Magsaysay",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702031",
      "brgy_name": "Mudboron",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702032",
      "brgy_name": "P. Barrantes",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702033",
      "brgy_name": "Peñalosa",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702034",
      "brgy_name": "Pepita",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702035",
      "brgy_name": "Salvacion Poblacion",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702036",
      "brgy_name": "San Antonio",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702037",
      "brgy_name": "San Diego",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702038",
      "brgy_name": "San Francisco East",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702039",
      "brgy_name": "San Francisco West",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702040",
      "brgy_name": "San Isidro",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702041",
      "brgy_name": "San Pedro",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702042",
      "brgy_name": "San Vicente",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702043",
      "brgy_name": "Santiago",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702044",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702045",
      "brgy_name": "Santol",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702046",
      "brgy_name": "Tabangohay",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702047",
      "brgy_name": "Tombo",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702048",
      "brgy_name": "Veteranos",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702049",
      "brgy_name": "Blumentritt (Pob.)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702050",
      "brgy_name": "Holy Child I (Pob.)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702051",
      "brgy_name": "Holy Child II (Pob.)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702052",
      "brgy_name": "Milagrosa (Pob.)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702053",
      "brgy_name": "San Antonio Pob. (Patong Norte)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702054",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083702055",
      "brgy_name": "Salvacion",
      "city_code": "083702",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703001",
      "brgy_name": "Antipolo",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703002",
      "brgy_name": "Balugo",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703003",
      "brgy_name": "Benolho",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703004",
      "brgy_name": "Cambalading",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703005",
      "brgy_name": "Damula-an",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703006",
      "brgy_name": "Doña Maria (Kangkuirina)",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703008",
      "brgy_name": "Mahayag",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703009",
      "brgy_name": "Mahayahay",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703010",
      "brgy_name": "Poblacion",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703011",
      "brgy_name": "Salvacion",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703012",
      "brgy_name": "San Pedro",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703013",
      "brgy_name": "Seguinon",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703014",
      "brgy_name": "Sherwood",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703015",
      "brgy_name": "Tabgas",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703016",
      "brgy_name": "Talisayan",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083703017",
      "brgy_name": "Tinag-an",
      "city_code": "083703",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705002",
      "brgy_name": "Biasong",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705003",
      "brgy_name": "Gov. E. Jaro (Bagahupi)",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705004",
      "brgy_name": "Malibago",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705005",
      "brgy_name": "Magcasuang",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705006",
      "brgy_name": "Planza",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705007",
      "brgy_name": "Poblacion District I",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705008",
      "brgy_name": "Poblacion District II",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705009",
      "brgy_name": "Poblacion District III",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705010",
      "brgy_name": "Poblacion District IV",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705011",
      "brgy_name": "Rizal I",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705012",
      "brgy_name": "San Ricardo",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705013",
      "brgy_name": "Sangputan",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705014",
      "brgy_name": "Pagsulhugon",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705015",
      "brgy_name": "Taguite",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705016",
      "brgy_name": "Uban",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705017",
      "brgy_name": "Victory",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705018",
      "brgy_name": "Villa Magsaysay",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705019",
      "brgy_name": "Bacong",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705020",
      "brgy_name": "Bagong Silang",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705021",
      "brgy_name": "Lukay",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705022",
      "brgy_name": "Rizal II",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705023",
      "brgy_name": "San Agustin",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705024",
      "brgy_name": "Guintigui-an",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705025",
      "brgy_name": "Naga-asan",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083705026",
      "brgy_name": "San Isidro",
      "city_code": "083705",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706001",
      "brgy_name": "Abango",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706002",
      "brgy_name": "Amahit",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706004",
      "brgy_name": "Balire",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706005",
      "brgy_name": "Balud",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706006",
      "brgy_name": "Bukid",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706007",
      "brgy_name": "Bulod",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706008",
      "brgy_name": "Busay",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706009",
      "brgy_name": "Cabolo-an",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706010",
      "brgy_name": "Calingcaguing",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706011",
      "brgy_name": "Can-isak",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706012",
      "brgy_name": "Canomantag",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706013",
      "brgy_name": "Duka",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706014",
      "brgy_name": "Guindaohan",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706015",
      "brgy_name": "Hiagsam",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706016",
      "brgy_name": "Hilaba",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706017",
      "brgy_name": "Hinugayan",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706018",
      "brgy_name": "Ibag",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706019",
      "brgy_name": "Minuhang",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706020",
      "brgy_name": "Minuswang",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706021",
      "brgy_name": "Pikas",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706022",
      "brgy_name": "Pitogo",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706023",
      "brgy_name": "Poblacion Dist. I",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706024",
      "brgy_name": "Poblacion Dist. II",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706025",
      "brgy_name": "Poblacion Dist. III",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706026",
      "brgy_name": "Poblacion Dist. IV",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706027",
      "brgy_name": "Poblacion Dist. V",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706028",
      "brgy_name": "Poblacion Dist. VI (New Road)",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706029",
      "brgy_name": "Pongso",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706031",
      "brgy_name": "Roosevelt",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706032",
      "brgy_name": "San Isidro",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706033",
      "brgy_name": "Santa Rosa",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706034",
      "brgy_name": "Santarin",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706035",
      "brgy_name": "Tutug-an",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706036",
      "brgy_name": "Cabarasan",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706037",
      "brgy_name": "Cuta",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706038",
      "brgy_name": "Domogdog",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083706039",
      "brgy_name": "San Roque",
      "city_code": "083706",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707001",
      "brgy_name": "Alegria",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707002",
      "brgy_name": "Alejos",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707003",
      "brgy_name": "Amagos",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707004",
      "brgy_name": "Anahawan",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707005",
      "brgy_name": "Bago",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707006",
      "brgy_name": "Bagong Bayan District (Pob.)",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707008",
      "brgy_name": "Buli",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707009",
      "brgy_name": "Cebuana",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707010",
      "brgy_name": "Daan Lungsod",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707011",
      "brgy_name": "Dawahon",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707012",
      "brgy_name": "Himamaa",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707013",
      "brgy_name": "Dolho",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707014",
      "brgy_name": "Domagocdoc",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707015",
      "brgy_name": "Guerrero District (Pob.)",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707016",
      "brgy_name": "Iniguihan District (Pob.)",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707017",
      "brgy_name": "Katipunan",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707018",
      "brgy_name": "Liberty (Binaliw)",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707019",
      "brgy_name": "Mabini",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707020",
      "brgy_name": "Naga",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707021",
      "brgy_name": "Osmeña",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707022",
      "brgy_name": "Plaridel",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707023",
      "brgy_name": "Kalanggaman District (Pob.)",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707026",
      "brgy_name": "Ponong",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707027",
      "brgy_name": "San Agustin",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707028",
      "brgy_name": "Santo Niño",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707029",
      "brgy_name": "Tabunok",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707030",
      "brgy_name": "Tagaytay",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707031",
      "brgy_name": "Tinago District (Pob.)",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707032",
      "brgy_name": "Tugas",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707033",
      "brgy_name": "Imelda",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707034",
      "brgy_name": "Marcelo",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083707035",
      "brgy_name": "Rivilla",
      "city_code": "083707",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708001",
      "brgy_name": "Altavista",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708002",
      "brgy_name": "Ambacan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708003",
      "brgy_name": "Amguhan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708004",
      "brgy_name": "Ampihanon",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708005",
      "brgy_name": "Balao",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708006",
      "brgy_name": "Banahao",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708008",
      "brgy_name": "Biasong",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708009",
      "brgy_name": "Bidlinan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708010",
      "brgy_name": "Bitanhuan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708011",
      "brgy_name": "Bubon",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708012",
      "brgy_name": "Buenavista",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708013",
      "brgy_name": "Bunga",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708014",
      "brgy_name": "Butigan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708015",
      "brgy_name": "Kabatuan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708016",
      "brgy_name": "Caridad",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708017",
      "brgy_name": "Ga-as",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708018",
      "brgy_name": "Gabas",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708019",
      "brgy_name": "Gakat",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708020",
      "brgy_name": "Guadalupe (Utod)",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708021",
      "brgy_name": "Gubang",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708022",
      "brgy_name": "Hibunawan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708023",
      "brgy_name": "Higuloan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708024",
      "brgy_name": "Hilapnitan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708025",
      "brgy_name": "Hipusngo",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708026",
      "brgy_name": "Igang",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708027",
      "brgy_name": "Imelda",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708028",
      "brgy_name": "Jaena",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708029",
      "brgy_name": "Kabalasan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708030",
      "brgy_name": "Kabungaan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708031",
      "brgy_name": "Kagumay",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708032",
      "brgy_name": "Kambonggan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708034",
      "brgy_name": "Candadam",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708035",
      "brgy_name": "Kan-ipa",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708036",
      "brgy_name": "Kansungka",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708037",
      "brgy_name": "Kantagnos",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708038",
      "brgy_name": "Kilim",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708039",
      "brgy_name": "Lintaon",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708041",
      "brgy_name": "Maganhan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708042",
      "brgy_name": "Mahayahay",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708043",
      "brgy_name": "Mailhi",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708044",
      "brgy_name": "Maitum",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708045",
      "brgy_name": "Makinhas",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708046",
      "brgy_name": "Mapgap",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708047",
      "brgy_name": "Marcos",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708048",
      "brgy_name": "Maslug",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708049",
      "brgy_name": "Matam-is",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708050",
      "brgy_name": "Maybog",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708051",
      "brgy_name": "Maypatag",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708052",
      "brgy_name": "Monterico",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708053",
      "brgy_name": "Palhi",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708054",
      "brgy_name": "Pangasungan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708055",
      "brgy_name": "Pansagan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708056",
      "brgy_name": "Patag",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708057",
      "brgy_name": "Plaridel",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708058",
      "brgy_name": "Poblacion Zone 2",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708059",
      "brgy_name": "Poblacion Zone 3",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708060",
      "brgy_name": "Poblacion Zone 4",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708061",
      "brgy_name": "Poblacion Zone 5",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708062",
      "brgy_name": "Poblacion Zone 6",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708063",
      "brgy_name": "Poblacion Zone 7",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708064",
      "brgy_name": "Poblacion Zone 8",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708065",
      "brgy_name": "Poblacion Zone 9",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708066",
      "brgy_name": "Poblacion Zone 10",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708067",
      "brgy_name": "Poblacion Zone 11",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708068",
      "brgy_name": "Poblacion Zone 12",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708069",
      "brgy_name": "Poblacion Zone 13",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708070",
      "brgy_name": "Poblacion Zone 14",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708071",
      "brgy_name": "Poblacion Zone 15",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708072",
      "brgy_name": "Poblacion Zone 16",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708073",
      "brgy_name": "Poblacion Zone 17",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708074",
      "brgy_name": "Poblacion Zone 18",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708075",
      "brgy_name": "Poblacion Zone 19",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708076",
      "brgy_name": "Poblacion Zone 20",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708077",
      "brgy_name": "Poblacion Zone 21",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708078",
      "brgy_name": "Poblacion Zone 22",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708079",
      "brgy_name": "Poblacion Zone 23",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708080",
      "brgy_name": "Pomponan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708081",
      "brgy_name": "Punta",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708082",
      "brgy_name": "Sabang",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708083",
      "brgy_name": "San Agustin",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708084",
      "brgy_name": "San Isidro",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708085",
      "brgy_name": "San Juan",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708086",
      "brgy_name": "Santa Cruz",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708087",
      "brgy_name": "Santo Rosario",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708088",
      "brgy_name": "Sapa",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708089",
      "brgy_name": "Ciabo",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708090",
      "brgy_name": "Villa Solidaridad",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708091",
      "brgy_name": "Zacarito",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708092",
      "brgy_name": "Poblacion Zone 1",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708093",
      "brgy_name": "Cogon",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708094",
      "brgy_name": "Monte Verde",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083708095",
      "brgy_name": "Villa Mag-aso",
      "city_code": "083708",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710001",
      "brgy_name": "Abuyogon",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710002",
      "brgy_name": "Anonang",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710003",
      "brgy_name": "Arado",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710004",
      "brgy_name": "Balao",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710005",
      "brgy_name": "Balatson",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710006",
      "brgy_name": "Balorinay",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710007",
      "brgy_name": "Bobon",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710008",
      "brgy_name": "Buenavista",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710009",
      "brgy_name": "Buri",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710010",
      "brgy_name": "Caanislagan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710011",
      "brgy_name": "Cadahunan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710012",
      "brgy_name": "Cagangon",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710013",
      "brgy_name": "Cali",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710014",
      "brgy_name": "Calsadahay",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710015",
      "brgy_name": "Candag-on",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710016",
      "brgy_name": "Cansiboy",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710017",
      "brgy_name": "Catagbacan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710018",
      "brgy_name": "Poblacion District I",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710019",
      "brgy_name": "Poblacion District II",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710020",
      "brgy_name": "Poblacion District III",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710021",
      "brgy_name": "Poblacion District IV",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710022",
      "brgy_name": "Poblacion District V",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710023",
      "brgy_name": "Poblacion District VI",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710024",
      "brgy_name": "Poblacion District VII",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710025",
      "brgy_name": "Poblacion District VIII",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710026",
      "brgy_name": "Poblacion District IX",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710027",
      "brgy_name": "Dumalag (Pusod)",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710028",
      "brgy_name": "Ilihan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710029",
      "brgy_name": "Esperanza",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710030",
      "brgy_name": "Gitablan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710031",
      "brgy_name": "Hapunan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710032",
      "brgy_name": "Hibonawan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710033",
      "brgy_name": "Hugpa East",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710034",
      "brgy_name": "Hugpa West",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710035",
      "brgy_name": "Kalao",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710036",
      "brgy_name": "Kaparasanan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710037",
      "brgy_name": "Laguiwan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710038",
      "brgy_name": "Libas",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710039",
      "brgy_name": "Limburan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710040",
      "brgy_name": "Logsongan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710041",
      "brgy_name": "Maabab",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710042",
      "brgy_name": "Maghubas",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710043",
      "brgy_name": "Mahagnao",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710044",
      "brgy_name": "Malabca",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710045",
      "brgy_name": "Malaguinabot",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710046",
      "brgy_name": "Malaihao (San Ramon)",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710047",
      "brgy_name": "Matin-ao",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710048",
      "brgy_name": "Moguing",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710049",
      "brgy_name": "Paghudlan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710050",
      "brgy_name": "Paitan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710051",
      "brgy_name": "Pangdan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710052",
      "brgy_name": "Patag",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710053",
      "brgy_name": "Patong",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710054",
      "brgy_name": "Pawa",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710056",
      "brgy_name": "Roxas",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710057",
      "brgy_name": "Sambel",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710058",
      "brgy_name": "San Esteban",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710059",
      "brgy_name": "San Fernando",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710061",
      "brgy_name": "San Jose East",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710062",
      "brgy_name": "San Jose West",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710063",
      "brgy_name": "San Pablo",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710064",
      "brgy_name": "Tabuanon",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710065",
      "brgy_name": "Tagadtaran",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710066",
      "brgy_name": "Taghuyan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710067",
      "brgy_name": "Takin",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710068",
      "brgy_name": "Tambis (Naboya)",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710070",
      "brgy_name": "Toloyao",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710071",
      "brgy_name": "Villa Aurora",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710072",
      "brgy_name": "Villa Corazon",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710073",
      "brgy_name": "Villa Patria",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710074",
      "brgy_name": "Villa Rosas (Cabang)",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710075",
      "brgy_name": "Kagbana",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710076",
      "brgy_name": "Damulo-an",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710077",
      "brgy_name": "Dina-ayan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710078",
      "brgy_name": "Gamay",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710079",
      "brgy_name": "Kalipayan",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083710080",
      "brgy_name": "Tambuko",
      "city_code": "083710",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713001",
      "brgy_name": "Abanilla",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713002",
      "brgy_name": "Agas",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713003",
      "brgy_name": "Anislagan",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713005",
      "brgy_name": "Bunacan",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713006",
      "brgy_name": "Cabalquinto",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713007",
      "brgy_name": "Cabalhin",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713008",
      "brgy_name": "Cabradilla",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713009",
      "brgy_name": "Caneja",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713010",
      "brgy_name": "Cantonghao",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713011",
      "brgy_name": "Caroyocan",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713012",
      "brgy_name": "Casiongan",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713014",
      "brgy_name": "Cristina",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713015",
      "brgy_name": "Dalumpines",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713016",
      "brgy_name": "Don Luis",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713017",
      "brgy_name": "Dulao",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713018",
      "brgy_name": "Efe",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713019",
      "brgy_name": "Enage",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713020",
      "brgy_name": "Espinosa",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713021",
      "brgy_name": "Garganera",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713022",
      "brgy_name": "Ferdinand E. Marcos",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713023",
      "brgy_name": "Garrido",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713024",
      "brgy_name": "Guadalupe (Guadalupe Mendoza)",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713025",
      "brgy_name": "Gutosan",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713027",
      "brgy_name": "Igang",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713028",
      "brgy_name": "Inalad",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713029",
      "brgy_name": "Jubay",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713030",
      "brgy_name": "Juson",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713031",
      "brgy_name": "Kawayan Bogtong",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713032",
      "brgy_name": "Kawayanan",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713033",
      "brgy_name": "Kokoy Romualdez",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713034",
      "brgy_name": "Labtic",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713035",
      "brgy_name": "Laray",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713036",
      "brgy_name": "M. Veloso",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713037",
      "brgy_name": "Mahait",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713038",
      "brgy_name": "Malobago",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713039",
      "brgy_name": "Matagok",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713040",
      "brgy_name": "Nipa",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713041",
      "brgy_name": "Obispo",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713042",
      "brgy_name": "Pagatpat",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713043",
      "brgy_name": "Pangpang",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713045",
      "brgy_name": "Patag",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713046",
      "brgy_name": "Pates",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713047",
      "brgy_name": "Paula",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713048",
      "brgy_name": "Padoga",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713049",
      "brgy_name": "Petrolio",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713050",
      "brgy_name": "Poblacion",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713051",
      "brgy_name": "Railes",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713052",
      "brgy_name": "Tabla",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713053",
      "brgy_name": "Tagharigue",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713054",
      "brgy_name": "Tuburan",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713056",
      "brgy_name": "Villahermosa",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713057",
      "brgy_name": "Villalon",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083713058",
      "brgy_name": "Villanueva",
      "city_code": "083713",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714001",
      "brgy_name": "Balucanad",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714002",
      "brgy_name": "Balud",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714003",
      "brgy_name": "Balugo",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714004",
      "brgy_name": "Cabul-an",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714006",
      "brgy_name": "Gayad",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714007",
      "brgy_name": "Culasian",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714008",
      "brgy_name": "Guinadiongan",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714009",
      "brgy_name": "Lemon",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714010",
      "brgy_name": "Libertad",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714011",
      "brgy_name": "Manloy",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714012",
      "brgy_name": "Nauguisan",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714014",
      "brgy_name": "Pinamopoan",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714015",
      "brgy_name": "Poblacion Zone I",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714016",
      "brgy_name": "Poblacion Zone II",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714017",
      "brgy_name": "Potot",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714018",
      "brgy_name": "San Joaquin",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714019",
      "brgy_name": "Santo Niño",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714020",
      "brgy_name": "Talairan",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714021",
      "brgy_name": "Talisay",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714022",
      "brgy_name": "Tolibao",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083714023",
      "brgy_name": "Visares",
      "city_code": "083714",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715001",
      "brgy_name": "Balilit",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715002",
      "brgy_name": "Barayong",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715003",
      "brgy_name": "Barugohay Central",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715004",
      "brgy_name": "Barugohay Norte",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715005",
      "brgy_name": "Barugohay Sur",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715006",
      "brgy_name": "Baybay (Pob.)",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715007",
      "brgy_name": "Binibihan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715008",
      "brgy_name": "Bislig",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715009",
      "brgy_name": "Caghalo",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715010",
      "brgy_name": "Camansi",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715011",
      "brgy_name": "Canal",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715012",
      "brgy_name": "Candigahub",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715013",
      "brgy_name": "Canlampay",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715014",
      "brgy_name": "Cogon",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715015",
      "brgy_name": "Cutay",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715016",
      "brgy_name": "East Visoria",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715017",
      "brgy_name": "Guindapunan East",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715018",
      "brgy_name": "Guindapunan West",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715019",
      "brgy_name": "Hiluctogan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715020",
      "brgy_name": "Jugaban (Pob.)",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715021",
      "brgy_name": "Libo",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715022",
      "brgy_name": "Lower Hiraan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715023",
      "brgy_name": "Lower Sogod",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715024",
      "brgy_name": "Macalpi",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715025",
      "brgy_name": "Manloy",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715026",
      "brgy_name": "Nauguisan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715027",
      "brgy_name": "Pangna",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715028",
      "brgy_name": "Parag-um",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715029",
      "brgy_name": "Parina",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715030",
      "brgy_name": "Piloro",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715031",
      "brgy_name": "Ponong (Pob.)",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715032",
      "brgy_name": "Sagkahan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715033",
      "brgy_name": "San Mateo (Pob.)",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715034",
      "brgy_name": "Santa Fe",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715035",
      "brgy_name": "Sawang (Pob.)",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715036",
      "brgy_name": "Tagak",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715037",
      "brgy_name": "Tangnan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715038",
      "brgy_name": "Tigbao",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715039",
      "brgy_name": "Tinaguban",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715040",
      "brgy_name": "Upper Hiraan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715041",
      "brgy_name": "Upper Sogod",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715042",
      "brgy_name": "Uyawan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715043",
      "brgy_name": "West Visoria",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715044",
      "brgy_name": "Paglaum",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715045",
      "brgy_name": "San Juan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715046",
      "brgy_name": "Bagong Lipunan",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715047",
      "brgy_name": "Canfabi",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715048",
      "brgy_name": "Rizal (Tagak East)",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083715049",
      "brgy_name": "San Isidro",
      "city_code": "083715",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717001",
      "brgy_name": "Abaca",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717002",
      "brgy_name": "Abre",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717003",
      "brgy_name": "Balilit",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717004",
      "brgy_name": "Banayon",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717005",
      "brgy_name": "Bayabas",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717006",
      "brgy_name": "Bolirao",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717007",
      "brgy_name": "Buenavista",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717008",
      "brgy_name": "Buntay",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717009",
      "brgy_name": "Caanislagan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717010",
      "brgy_name": "Cabariwan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717011",
      "brgy_name": "Cabuloran",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717012",
      "brgy_name": "Cabunga-an",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717013",
      "brgy_name": "Calipayan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717014",
      "brgy_name": "Calsadahay",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717015",
      "brgy_name": "Caluctogan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717016",
      "brgy_name": "Calutan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717017",
      "brgy_name": "Camono-an",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717018",
      "brgy_name": "Candagara",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717019",
      "brgy_name": "Canlingga",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717020",
      "brgy_name": "Cansamada East",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717021",
      "brgy_name": "Digahongan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717022",
      "brgy_name": "Guinarona",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717023",
      "brgy_name": "Hiabangan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717024",
      "brgy_name": "Hilabago",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717025",
      "brgy_name": "Hinabuyan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717026",
      "brgy_name": "Hinologan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717027",
      "brgy_name": "Hitumnog",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717028",
      "brgy_name": "Katipunan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717029",
      "brgy_name": "Los Martires",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717030",
      "brgy_name": "Lobe-lobe",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717031",
      "brgy_name": "Macaalang",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717032",
      "brgy_name": "Maliwaliw",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717033",
      "brgy_name": "Maragondong",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717034",
      "brgy_name": "Ormocay",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717035",
      "brgy_name": "Palacio",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717036",
      "brgy_name": "Panda",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717037",
      "brgy_name": "Patoc",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717038",
      "brgy_name": "Plaridel",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717039",
      "brgy_name": "Sampao West Pob. (Dist. 8)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717040",
      "brgy_name": "Lapu-lapu Pob. (Dist. 2)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717041",
      "brgy_name": "Lusad Pob. (Dist. 6)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717042",
      "brgy_name": "Sampao East Pob. (Dist. 9)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717043",
      "brgy_name": "San Antonio Pob. (Dist. 5)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717044",
      "brgy_name": "San Jose Pob. (Dist. 1)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717045",
      "brgy_name": "Sta. Mesa Pob. (Dist. 7)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717046",
      "brgy_name": "Tunga Pob. (Dist. 4)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717047",
      "brgy_name": "San Roque Pob. (Dist. 3)",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717048",
      "brgy_name": "Poponton",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717049",
      "brgy_name": "Rizal",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717050",
      "brgy_name": "Salvacion",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717051",
      "brgy_name": "San Benito",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717052",
      "brgy_name": "Santo Domingo",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717053",
      "brgy_name": "Sirab",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717054",
      "brgy_name": "Tagkip",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717055",
      "brgy_name": "Tin-ao",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717056",
      "brgy_name": "Victoria",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717057",
      "brgy_name": "Balugo",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717058",
      "brgy_name": "Cansamada West",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717059",
      "brgy_name": "Capulhan",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717060",
      "brgy_name": "Lobe-lobe East",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717061",
      "brgy_name": "Paraiso",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717062",
      "brgy_name": "Sampaguita",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717063",
      "brgy_name": "Sawahon",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717064",
      "brgy_name": "Talinhugon",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083717065",
      "brgy_name": "Tuya",
      "city_code": "083717",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718001",
      "brgy_name": "Alegre",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718002",
      "brgy_name": "Arado",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718003",
      "brgy_name": "Bulod",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718004",
      "brgy_name": "Batug",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718005",
      "brgy_name": "Bolongtohan",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718006",
      "brgy_name": "Cabacungan",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718007",
      "brgy_name": "Cabarasan",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718008",
      "brgy_name": "Cabato-an",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718009",
      "brgy_name": "Calipayan",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718010",
      "brgy_name": "Calubian",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718011",
      "brgy_name": "Camitoc",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718012",
      "brgy_name": "Camote",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718013",
      "brgy_name": "Dacay",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718014",
      "brgy_name": "Del Carmen",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718015",
      "brgy_name": "Del Pilar",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718016",
      "brgy_name": "Fatima",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718017",
      "brgy_name": "General Roxas",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718019",
      "brgy_name": "Luan",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718020",
      "brgy_name": "Magsaysay",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718021",
      "brgy_name": "Maricum",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718022",
      "brgy_name": "Barbo (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718023",
      "brgy_name": "Buntay (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718024",
      "brgy_name": "Cambula District (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718025",
      "brgy_name": "Candao (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718026",
      "brgy_name": "Catmonan (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718027",
      "brgy_name": "Combis (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718028",
      "brgy_name": "Highway (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718029",
      "brgy_name": "Market Site (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718030",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718031",
      "brgy_name": "Serrano (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718032",
      "brgy_name": "Sungi (Pob.)",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718033",
      "brgy_name": "Rawis",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718034",
      "brgy_name": "Rizal",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718035",
      "brgy_name": "Romualdez",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718036",
      "brgy_name": "Sabang Daguitan",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718037",
      "brgy_name": "Salvacion",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718038",
      "brgy_name": "San Agustin",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718039",
      "brgy_name": "San Antonio",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718040",
      "brgy_name": "San Isidro",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718041",
      "brgy_name": "San Jose",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718042",
      "brgy_name": "San Rafael",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718043",
      "brgy_name": "San Vicente",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718044",
      "brgy_name": "Tabu",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718045",
      "brgy_name": "Tigbao",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083718046",
      "brgy_name": "Victory",
      "city_code": "083718",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719001",
      "brgy_name": "Agutayan",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719002",
      "brgy_name": "Atabay",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719003",
      "brgy_name": "Baas",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719004",
      "brgy_name": "Bagumbayan",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719005",
      "brgy_name": "Baliw",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719006",
      "brgy_name": "Bantigue",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719007",
      "brgy_name": "Bung-aw",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719008",
      "brgy_name": "Cacao",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719009",
      "brgy_name": "Campina",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719010",
      "brgy_name": "Catandog 1",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719011",
      "brgy_name": "Catandog 2",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719012",
      "brgy_name": "Concepcion",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719014",
      "brgy_name": "Himo-aw",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719015",
      "brgy_name": "Hitudpan",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719016",
      "brgy_name": "Imelda Marcos (Pong-on)",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719017",
      "brgy_name": "Kang-iras",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719018",
      "brgy_name": "Lamak",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719019",
      "brgy_name": "Libertad",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719020",
      "brgy_name": "Liberty",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719021",
      "brgy_name": "Lunang",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719022",
      "brgy_name": "Magnangoy",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719023",
      "brgy_name": "Marangog",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719024",
      "brgy_name": "Matapay",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719025",
      "brgy_name": "Naval",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719026",
      "brgy_name": "Owak",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719027",
      "brgy_name": "Pa-a",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719028",
      "brgy_name": "Central Barangay (Pob.)",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719029",
      "brgy_name": "Eastern Barangay (Pob.)",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719030",
      "brgy_name": "Western Barangay (Pob.)",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719031",
      "brgy_name": "Pontod",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719032",
      "brgy_name": "Proteccion",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719033",
      "brgy_name": "San Agustin",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719034",
      "brgy_name": "San Antonio",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719035",
      "brgy_name": "San Isidro",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719036",
      "brgy_name": "San Juan",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719037",
      "brgy_name": "San Roque",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719038",
      "brgy_name": "Santa Cruz",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719039",
      "brgy_name": "Santa Margarita",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719041",
      "brgy_name": "Santo Niño",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719042",
      "brgy_name": "Tabunok",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719043",
      "brgy_name": "Tagnate",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719044",
      "brgy_name": "Talisay",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719045",
      "brgy_name": "Tambis",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719046",
      "brgy_name": "Tejero",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719047",
      "brgy_name": "Tuguipa",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719048",
      "brgy_name": "Utanan",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719049",
      "brgy_name": "Bagong Lipunan",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719050",
      "brgy_name": "Bon-ot",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719051",
      "brgy_name": "Hampangan",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719052",
      "brgy_name": "Kangha-as",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083719053",
      "brgy_name": "Manaul",
      "city_code": "083719",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720001",
      "brgy_name": "Anahaw",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720002",
      "brgy_name": "Anolon",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720003",
      "brgy_name": "Baldoza",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720004",
      "brgy_name": "Bontoc",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720006",
      "brgy_name": "Bulacan",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720007",
      "brgy_name": "Canha-ayon",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720008",
      "brgy_name": "Capudlosan",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720009",
      "brgy_name": "Himacugo",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720010",
      "brgy_name": "Doos Del Norte",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720011",
      "brgy_name": "Doos Del Sur",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720012",
      "brgy_name": "Himokilan Island",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720013",
      "brgy_name": "Katipunan",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720014",
      "brgy_name": "Maasin",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720015",
      "brgy_name": "Mabagon",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720016",
      "brgy_name": "Mahilum",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720017",
      "brgy_name": "Poblacion 1",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720018",
      "brgy_name": "Poblacion 2",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720019",
      "brgy_name": "San Vicente",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720020",
      "brgy_name": "Tabok",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083720021",
      "brgy_name": "Tagbibi",
      "city_code": "083720",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721001",
      "brgy_name": "Apid",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721002",
      "brgy_name": "Cabulisan",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721003",
      "brgy_name": "Caminto",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721004",
      "brgy_name": "Can-angay",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721005",
      "brgy_name": "Caulisihan",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721006",
      "brgy_name": "Conalum",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721007",
      "brgy_name": "De los Santos (Mahilum)",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721008",
      "brgy_name": "Esperanza",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721009",
      "brgy_name": "Guadalupe",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721010",
      "brgy_name": "Guinsanga-an",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721011",
      "brgy_name": "Hinabay",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721012",
      "brgy_name": "Jubasan",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721013",
      "brgy_name": "Linao",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721014",
      "brgy_name": "Macagoco",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721016",
      "brgy_name": "Maljo",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721017",
      "brgy_name": "Marao",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721018",
      "brgy_name": "Poblacion",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721019",
      "brgy_name": "Tahud",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721020",
      "brgy_name": "Taotaon",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083721021",
      "brgy_name": "Tinago",
      "city_code": "083721",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722001",
      "brgy_name": "Anislag",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722002",
      "brgy_name": "Antipolo",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722003",
      "brgy_name": "Apale",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722004",
      "brgy_name": "Bantigue",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722005",
      "brgy_name": "Benog",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722006",
      "brgy_name": "Bilwang",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722007",
      "brgy_name": "Can-andan",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722008",
      "brgy_name": "Cangag",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722009",
      "brgy_name": "Consolacion",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722010",
      "brgy_name": "Honan",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722011",
      "brgy_name": "Libertad",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722012",
      "brgy_name": "Mahayag",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722013",
      "brgy_name": "Marvel (Pob.)",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722014",
      "brgy_name": "Matlang",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722015",
      "brgy_name": "Monte Alegre",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722017",
      "brgy_name": "Puting Bato",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722018",
      "brgy_name": "San Francisco",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722019",
      "brgy_name": "San Roque",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722020",
      "brgy_name": "Santa Cruz",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722021",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722022",
      "brgy_name": "Santo Rosario",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722023",
      "brgy_name": "Tabunok",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722024",
      "brgy_name": "Tolingon",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083722025",
      "brgy_name": "Tubod",
      "city_code": "083722",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723001",
      "brgy_name": "Alahag",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723002",
      "brgy_name": "Anibongan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723003",
      "brgy_name": "Badiang",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723004",
      "brgy_name": "Batug",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723005",
      "brgy_name": "Buenavista",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723006",
      "brgy_name": "Bukid",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723007",
      "brgy_name": "Burabod",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723008",
      "brgy_name": "Buri",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723009",
      "brgy_name": "Kaglawaan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723010",
      "brgy_name": "Canhandugan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723011",
      "brgy_name": "Crossing Rubas",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723012",
      "brgy_name": "Daro",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723013",
      "brgy_name": "Hiagsam",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723014",
      "brgy_name": "Hibunawon",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723015",
      "brgy_name": "Hibucawan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723016",
      "brgy_name": "Kalinawan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723017",
      "brgy_name": "Likod",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723018",
      "brgy_name": "Macanip",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723019",
      "brgy_name": "Macopa",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723020",
      "brgy_name": "Mag-aso",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723021",
      "brgy_name": "Malobago",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723023",
      "brgy_name": "Olotan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723024",
      "brgy_name": "Pange",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723025",
      "brgy_name": "Parasan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723026",
      "brgy_name": "Pitogo",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723027",
      "brgy_name": "District I (Pob.)",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723028",
      "brgy_name": "District II (Pob.)",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723029",
      "brgy_name": "District III (Pob.)",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723030",
      "brgy_name": "District IV (Pob.)",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723031",
      "brgy_name": "Sagkahan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723032",
      "brgy_name": "San Agustin",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723033",
      "brgy_name": "San Pedro",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723034",
      "brgy_name": "San Roque",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723035",
      "brgy_name": "Santa Cruz",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723036",
      "brgy_name": "Santo Niño",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723037",
      "brgy_name": "Sari-sari",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723038",
      "brgy_name": "Tinambacan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723039",
      "brgy_name": "Tuba",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723040",
      "brgy_name": "Uguiao",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723041",
      "brgy_name": "Villagonzoilo (Villa Consuelo)",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723042",
      "brgy_name": "Villa Paz",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723043",
      "brgy_name": "Bias Zabala",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723044",
      "brgy_name": "Atipolo",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723045",
      "brgy_name": "Canapuan",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723046",
      "brgy_name": "La Paz",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083723047",
      "brgy_name": "Palanog",
      "city_code": "083723",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724001",
      "brgy_name": "Abuyogay",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724002",
      "brgy_name": "Andres Bonifacio",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724003",
      "brgy_name": "Batug",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724004",
      "brgy_name": "Binulho",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724005",
      "brgy_name": "Calzada",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724006",
      "brgy_name": "Cancayang",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724007",
      "brgy_name": "Caranhug",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724008",
      "brgy_name": "Caraye",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724009",
      "brgy_name": "Casalungan",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724010",
      "brgy_name": "Comatin",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724011",
      "brgy_name": "Guindapunan",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724012",
      "brgy_name": "Inayupan",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724013",
      "brgy_name": "Laray",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724014",
      "brgy_name": "Magsaysay (Responde)",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724015",
      "brgy_name": "Malitbogay",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724016",
      "brgy_name": "Manarug",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724017",
      "brgy_name": "Manlilisid",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724018",
      "brgy_name": "Naliwatan",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724019",
      "brgy_name": "Odiong",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724020",
      "brgy_name": "Picas Norte",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724021",
      "brgy_name": "Pinocawan",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724022",
      "brgy_name": "Poblacion Zone 1",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724023",
      "brgy_name": "Poblacion Zone 2",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724024",
      "brgy_name": "Rizal",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724026",
      "brgy_name": "Santa Cruz",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724027",
      "brgy_name": "Talisayan",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724028",
      "brgy_name": "San Sotero (Tambis)",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083724029",
      "brgy_name": "Ulhay",
      "city_code": "083724",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725001",
      "brgy_name": "Alegria",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725002",
      "brgy_name": "Anibong",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725003",
      "brgy_name": "Aslum",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725004",
      "brgy_name": "Balante",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725005",
      "brgy_name": "Bongdo",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725006",
      "brgy_name": "Bonifacio",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725007",
      "brgy_name": "Bugho",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725008",
      "brgy_name": "Calbasag",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725009",
      "brgy_name": "Caridad",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725010",
      "brgy_name": "Cuya-e",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725013",
      "brgy_name": "Dita",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725014",
      "brgy_name": "Gitabla",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725015",
      "brgy_name": "Hindang",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725016",
      "brgy_name": "Inawangan",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725017",
      "brgy_name": "Jurao",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725018",
      "brgy_name": "Poblacion District I",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725019",
      "brgy_name": "Poblacion District II",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725020",
      "brgy_name": "Poblacion District III",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725021",
      "brgy_name": "Poblacion District IV",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725022",
      "brgy_name": "San Andres",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725023",
      "brgy_name": "San Pablo",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725024",
      "brgy_name": "Santa Cruz",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725025",
      "brgy_name": "Santo Niño",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725026",
      "brgy_name": "Tagkip",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725027",
      "brgy_name": "Tolosahay",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083725028",
      "brgy_name": "Villa Hermosa",
      "city_code": "083725",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726001",
      "brgy_name": "Aguiting",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726002",
      "brgy_name": "Cacao",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726003",
      "brgy_name": "Kawayan",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726004",
      "brgy_name": "Hiluctogan",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726005",
      "brgy_name": "Libertad",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726006",
      "brgy_name": "Libongao",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726007",
      "brgy_name": "Lim-ao",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726008",
      "brgy_name": "Lonoy",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726009",
      "brgy_name": "Mahawan",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726010",
      "brgy_name": "Masarayao",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726011",
      "brgy_name": "Monte Alegre",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726012",
      "brgy_name": "Monte Bello",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726013",
      "brgy_name": "Naghalin",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726014",
      "brgy_name": "Natubgan",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726015",
      "brgy_name": "Poblacion",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726016",
      "brgy_name": "Rizal",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726017",
      "brgy_name": "San Ignacio",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726018",
      "brgy_name": "San Isidro",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726019",
      "brgy_name": "Santo Domingo",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726020",
      "brgy_name": "Santo Niño",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726021",
      "brgy_name": "Tagaytay",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726022",
      "brgy_name": "Tongonan",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083726023",
      "brgy_name": "Tugbong",
      "city_code": "083726",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728001",
      "brgy_name": "Bagacay East",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728002",
      "brgy_name": "Bagacay West",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728003",
      "brgy_name": "Bongtod",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728004",
      "brgy_name": "Bocawon",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728005",
      "brgy_name": "Buracan",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728006",
      "brgy_name": "Caabangan",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728007",
      "brgy_name": "Cacao",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728008",
      "brgy_name": "Cagngaran",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728009",
      "brgy_name": "Calabnian",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728010",
      "brgy_name": "Calaghusan",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728011",
      "brgy_name": "Caltayan",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728012",
      "brgy_name": "Canbañez",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728013",
      "brgy_name": "Cogon",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728014",
      "brgy_name": "Duyog",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728015",
      "brgy_name": "Gimenarat East",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728016",
      "brgy_name": "Gimenarat West",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728017",
      "brgy_name": "Limba",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728018",
      "brgy_name": "Lubi-lubi",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728019",
      "brgy_name": "Luneta",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728020",
      "brgy_name": "Mag-aso",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728021",
      "brgy_name": "Moroboro",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728022",
      "brgy_name": "Pansud",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728023",
      "brgy_name": "Pawa",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728024",
      "brgy_name": "Piliway",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728025",
      "brgy_name": "Poblacion District 1",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728026",
      "brgy_name": "Poblacion District 2",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728027",
      "brgy_name": "Poblacion District 3",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728028",
      "brgy_name": "Poblacion District 4",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728029",
      "brgy_name": "Quiong",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728030",
      "brgy_name": "Rizal",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728031",
      "brgy_name": "San Victoray",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728032",
      "brgy_name": "Santa Ana",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728033",
      "brgy_name": "Santa Elena",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728034",
      "brgy_name": "Tabang",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083728035",
      "brgy_name": "Tarugan",
      "city_code": "083728",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729001",
      "brgy_name": "Bachao",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729002",
      "brgy_name": "Baco",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729003",
      "brgy_name": "Bagaba-o",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729004",
      "brgy_name": "Basud",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729005",
      "brgy_name": "Belen",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729006",
      "brgy_name": "Burabod",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729007",
      "brgy_name": "Calaguise",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729008",
      "brgy_name": "Consuegra",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729009",
      "brgy_name": "Culasi",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729010",
      "brgy_name": "Danus",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729011",
      "brgy_name": "Elizabeth",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729012",
      "brgy_name": "Kawayan",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729013",
      "brgy_name": "Libas",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729014",
      "brgy_name": "Maanda",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729015",
      "brgy_name": "Macupa",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729016",
      "brgy_name": "Mataloto",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729017",
      "brgy_name": "Palarao",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729018",
      "brgy_name": "Palid I (Ilawod)",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729019",
      "brgy_name": "Palid II (Iraya)",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729020",
      "brgy_name": "Parasan",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729021",
      "brgy_name": "Poblacion",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729022",
      "brgy_name": "Salog",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729023",
      "brgy_name": "Sambulawan",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729024",
      "brgy_name": "Tag-abaca",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729025",
      "brgy_name": "Tapol",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729026",
      "brgy_name": "Tigbawan",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729027",
      "brgy_name": "Tinocdugan",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729028",
      "brgy_name": "Toctoc",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729029",
      "brgy_name": "Ugbon",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083729030",
      "brgy_name": "Wague",
      "city_code": "083729",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730001",
      "brgy_name": "Batug",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730002",
      "brgy_name": "Burabod",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730004",
      "brgy_name": "Capudlosan",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730005",
      "brgy_name": "Casuntingan",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730006",
      "brgy_name": "Causwagan",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730007",
      "brgy_name": "Danao",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730008",
      "brgy_name": "General Luna",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730009",
      "brgy_name": "Kiling",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730010",
      "brgy_name": "Lanawan",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730011",
      "brgy_name": "Liwayway",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730012",
      "brgy_name": "Maya",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730013",
      "brgy_name": "Oguisan",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730014",
      "brgy_name": "Osmeña",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730015",
      "brgy_name": "Palale 1",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730016",
      "brgy_name": "Palale 2",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730017",
      "brgy_name": "Poblacion District 1",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730018",
      "brgy_name": "Poblacion District 2",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730019",
      "brgy_name": "Poblacion District 3",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730020",
      "brgy_name": "Pongon",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730021",
      "brgy_name": "Quezon",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730022",
      "brgy_name": "Romualdez",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730023",
      "brgy_name": "Salvacion",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730024",
      "brgy_name": "San Antonio",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730025",
      "brgy_name": "San Isidro",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730026",
      "brgy_name": "San Pedro",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730027",
      "brgy_name": "San Vicente",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730028",
      "brgy_name": "Santa Isabel",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730029",
      "brgy_name": "Tinawan",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730030",
      "brgy_name": "Tuyo",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730031",
      "brgy_name": "Doña Josefa",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083730032",
      "brgy_name": "Villa Imelda",
      "city_code": "083730",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731001",
      "brgy_name": "Campin",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731002",
      "brgy_name": "Cuatro De Agosto",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731003",
      "brgy_name": "Hilusig",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731004",
      "brgy_name": "Himamara",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731005",
      "brgy_name": "Hinaguimitan",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731006",
      "brgy_name": "Liberacion",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731007",
      "brgy_name": "Mabuhay",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731008",
      "brgy_name": "Mabunga",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731009",
      "brgy_name": "Magsuganao",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731010",
      "brgy_name": "Mahayag",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731011",
      "brgy_name": "Mahayahay",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731012",
      "brgy_name": "Malinao",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731013",
      "brgy_name": "Malipoon",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731014",
      "brgy_name": "Palañogan",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731015",
      "brgy_name": "Paril",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731016",
      "brgy_name": "Pinamonoan",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731017",
      "brgy_name": "Poblacion",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731018",
      "brgy_name": "Polahongon",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731019",
      "brgy_name": "San Isidro",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731020",
      "brgy_name": "San Juan",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731021",
      "brgy_name": "Santa Cruz",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731022",
      "brgy_name": "Tagaytay",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731023",
      "brgy_name": "Uguis",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731024",
      "brgy_name": "Union",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731025",
      "brgy_name": "Upper Mahaplag",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731026",
      "brgy_name": "Hiluctogan",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731027",
      "brgy_name": "Maligaya",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083731028",
      "brgy_name": "Santo Niño",
      "city_code": "083731",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733001",
      "brgy_name": "Balagtas",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733002",
      "brgy_name": "Bulak",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733003",
      "brgy_name": "Cambadbad",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733004",
      "brgy_name": "Candelaria",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733005",
      "brgy_name": "Cansoso",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733006",
      "brgy_name": "Mansahaon",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733007",
      "brgy_name": "Masaba",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733008",
      "brgy_name": "Naulayan",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733009",
      "brgy_name": "Bonoy (Pob.)",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733010",
      "brgy_name": "Mansalip (Pob.)",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733011",
      "brgy_name": "Riverside (Pob.)",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733012",
      "brgy_name": "Talisay (Pob.)",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733013",
      "brgy_name": "San Dionisio",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733014",
      "brgy_name": "San Marcelino",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733015",
      "brgy_name": "San Sebastian",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733016",
      "brgy_name": "San Vicente",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733017",
      "brgy_name": "Santa Rosa",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733018",
      "brgy_name": "Santo Rosario",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733019",
      "brgy_name": "Imelda",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733020",
      "brgy_name": "Malazarte",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083733021",
      "brgy_name": "San Guillermo",
      "city_code": "083733",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734001",
      "brgy_name": "Agbanga",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734002",
      "brgy_name": "Altavista",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734004",
      "brgy_name": "Cahagnaan",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734005",
      "brgy_name": "Calumpang",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734006",
      "brgy_name": "Caningag",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734007",
      "brgy_name": "Caridad Norte",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734008",
      "brgy_name": "Caridad Sur",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734009",
      "brgy_name": "Elevado",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734010",
      "brgy_name": "Esperanza",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734011",
      "brgy_name": "Hitoog",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734012",
      "brgy_name": "Itum",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734013",
      "brgy_name": "Lowan",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734014",
      "brgy_name": "Monte Alegre",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734016",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734017",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734018",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734019",
      "brgy_name": "President Garcia",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734020",
      "brgy_name": "Punong",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734021",
      "brgy_name": "San Juan",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734022",
      "brgy_name": "San Salvador",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734023",
      "brgy_name": "San Vicente",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734024",
      "brgy_name": "Santa Fe",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734025",
      "brgy_name": "Santa Paz",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734026",
      "brgy_name": "Tag-os",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734027",
      "brgy_name": "Templanza",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734028",
      "brgy_name": "Tigbao",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734029",
      "brgy_name": "Waterloo",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734030",
      "brgy_name": "Zaragoza",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734031",
      "brgy_name": "Bagong Lipunan",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083734032",
      "brgy_name": "Taglibas Imelda",
      "city_code": "083734",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735001",
      "brgy_name": "A. Bonifacio",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735002",
      "brgy_name": "Mabini",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735003",
      "brgy_name": "Burgos",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735004",
      "brgy_name": "Calipayan",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735005",
      "brgy_name": "Camansi",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735006",
      "brgy_name": "General Antonio Luna",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735007",
      "brgy_name": "Liberty",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735008",
      "brgy_name": "Ormocay",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735009",
      "brgy_name": "Poblacion Zone 1",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735010",
      "brgy_name": "Poblacion Zone 2",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735011",
      "brgy_name": "Poblacion Zone 3",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735012",
      "brgy_name": "San Roque",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735013",
      "brgy_name": "Santa Cruz",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735014",
      "brgy_name": "Talisay",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735015",
      "brgy_name": "Union",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083735016",
      "brgy_name": "Wilson",
      "city_code": "083735",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736001",
      "brgy_name": "Binabaye",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736002",
      "brgy_name": "Cabaliwan",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736003",
      "brgy_name": "Calunangan",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736004",
      "brgy_name": "Calunasan",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736005",
      "brgy_name": "Cambalong",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736007",
      "brgy_name": "Can-unzo",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736008",
      "brgy_name": "Canbantug",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736009",
      "brgy_name": "Casilda",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736010",
      "brgy_name": "Lamanoc",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736011",
      "brgy_name": "Libas",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736012",
      "brgy_name": "Libjo",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736013",
      "brgy_name": "Lundag",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736014",
      "brgy_name": "Mahalit",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736015",
      "brgy_name": "Mahayag",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736016",
      "brgy_name": "Masumbang",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736017",
      "brgy_name": "Poblacion",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736018",
      "brgy_name": "Puerto Bello",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736019",
      "brgy_name": "San Isidro",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736020",
      "brgy_name": "San Jose",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736021",
      "brgy_name": "Macario",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736022",
      "brgy_name": "Tubod",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083736023",
      "brgy_name": "Mat-e",
      "city_code": "083736",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738001",
      "brgy_name": "Alegria",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738002",
      "brgy_name": "Bagong",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738003",
      "brgy_name": "Labrador (Balion)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738004",
      "brgy_name": "Bantigue",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738005",
      "brgy_name": "Batuan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738006",
      "brgy_name": "Biliboy",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738007",
      "brgy_name": "Borok",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738009",
      "brgy_name": "Cabaon-an",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738010",
      "brgy_name": "Cabulihan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738011",
      "brgy_name": "Cagbuhangin",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738012",
      "brgy_name": "Can-adieng",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738013",
      "brgy_name": "Can-untog",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738015",
      "brgy_name": "Catmon",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738016",
      "brgy_name": "Cogon Combado",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738017",
      "brgy_name": "Concepcion",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738018",
      "brgy_name": "Curva",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738019",
      "brgy_name": "Danao",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738020",
      "brgy_name": "Dayhagan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738021",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738022",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738023",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738024",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738025",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738026",
      "brgy_name": "Barangay 14 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738027",
      "brgy_name": "Barangay 15 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738028",
      "brgy_name": "Barangay 16 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738029",
      "brgy_name": "Barangay 17 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738030",
      "brgy_name": "Barangay 18 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738031",
      "brgy_name": "Barangay 19 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738032",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738033",
      "brgy_name": "Barangay 20 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738034",
      "brgy_name": "Barangay 21 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738035",
      "brgy_name": "Barangay 22 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738036",
      "brgy_name": "Barangay 23 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738037",
      "brgy_name": "Barangay 24 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738038",
      "brgy_name": "Barangay 25 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738039",
      "brgy_name": "Barangay 26 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738040",
      "brgy_name": "Barangay 27 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738041",
      "brgy_name": "Barangay 28 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738042",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738043",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738044",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738045",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738046",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738047",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738048",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738049",
      "brgy_name": "Dolores",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738050",
      "brgy_name": "Domonar",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738051",
      "brgy_name": "Don Felipe Larrazabal",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738052",
      "brgy_name": "Donghol",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738053",
      "brgy_name": "Esperanza",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738054",
      "brgy_name": "Hibunawon",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738055",
      "brgy_name": "Hugpa",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738056",
      "brgy_name": "Ipil",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738057",
      "brgy_name": "Lao",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738058",
      "brgy_name": "Libertad",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738059",
      "brgy_name": "Liloan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738060",
      "brgy_name": "Linao",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738061",
      "brgy_name": "Mabini",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738062",
      "brgy_name": "Macabug",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738063",
      "brgy_name": "Magaswi",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738064",
      "brgy_name": "Mahayag",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738065",
      "brgy_name": "Mahayahay",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738066",
      "brgy_name": "Manlilinao",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738067",
      "brgy_name": "Margen",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738068",
      "brgy_name": "Mas-in",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738069",
      "brgy_name": "Matica-a",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738070",
      "brgy_name": "Milagro",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738071",
      "brgy_name": "Monterico",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738072",
      "brgy_name": "Nasunogan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738073",
      "brgy_name": "Naungan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738074",
      "brgy_name": "Nueva Vista",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738075",
      "brgy_name": "Patag",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738076",
      "brgy_name": "Punta",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738077",
      "brgy_name": "Quezon, Jr.",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738078",
      "brgy_name": "Rufina M. Tan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738079",
      "brgy_name": "Sabang Bao",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738080",
      "brgy_name": "Salvacion",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738081",
      "brgy_name": "San Antonio",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738082",
      "brgy_name": "San Isidro",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738083",
      "brgy_name": "San Jose",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738084",
      "brgy_name": "San Juan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738086",
      "brgy_name": "San Vicente",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738087",
      "brgy_name": "Santo Niño",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738088",
      "brgy_name": "San Pablo (Simangan)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738089",
      "brgy_name": "Sumangga",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738090",
      "brgy_name": "Valencia",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738091",
      "brgy_name": "Barangay 29 (Pob.)",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738092",
      "brgy_name": "Airport",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738093",
      "brgy_name": "Cabintan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738094",
      "brgy_name": "Camp Downes",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738095",
      "brgy_name": "Gaas",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738096",
      "brgy_name": "Green Valley",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738097",
      "brgy_name": "Licuma",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738098",
      "brgy_name": "Liberty",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738099",
      "brgy_name": "Leondoni",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738100",
      "brgy_name": "Nueva Sociedad",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738101",
      "brgy_name": "Tambulilid",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738102",
      "brgy_name": "Tongonan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738104",
      "brgy_name": "Don Potenciano Larrazabal",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738105",
      "brgy_name": "Kadaohan",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738106",
      "brgy_name": "Guintigui-an",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738107",
      "brgy_name": "Danhug",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738108",
      "brgy_name": "Alta Vista",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738109",
      "brgy_name": "Bagong Buhay",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738110",
      "brgy_name": "Bayog",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738111",
      "brgy_name": "Doña Feliza Z. Mejia",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738112",
      "brgy_name": "Juaton",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738113",
      "brgy_name": "Luna",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083738114",
      "brgy_name": "Mabato",
      "city_code": "083738",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739001",
      "brgy_name": "Anahaway",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739002",
      "brgy_name": "Arado",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739004",
      "brgy_name": "Baras",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739005",
      "brgy_name": "Barayong",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739006",
      "brgy_name": "Cabarasan Daku",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739007",
      "brgy_name": "Cabarasan Guti",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739008",
      "brgy_name": "Campetik",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739009",
      "brgy_name": "Candahug",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739010",
      "brgy_name": "Cangumbang",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739011",
      "brgy_name": "Canhidoc",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739012",
      "brgy_name": "Capirawan",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739013",
      "brgy_name": "Castilla",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739014",
      "brgy_name": "Cogon (Cogon San Joaquin)",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739015",
      "brgy_name": "Gacao",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739016",
      "brgy_name": "Guindapunan",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739017",
      "brgy_name": "Libertad",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739018",
      "brgy_name": "Naga-naga",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739019",
      "brgy_name": "Pawing",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739020",
      "brgy_name": "Buri (Pob.)",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739021",
      "brgy_name": "Cavite East (Pob.)",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739022",
      "brgy_name": "Cavite West (Pob.)",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739023",
      "brgy_name": "Luntad (Pob.)",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739024",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739025",
      "brgy_name": "Salvacion",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739026",
      "brgy_name": "San Agustin",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739027",
      "brgy_name": "San Antonio",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739028",
      "brgy_name": "San Isidro",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739029",
      "brgy_name": "San Joaquin",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739030",
      "brgy_name": "San Jose",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739031",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739032",
      "brgy_name": "Tacuranga",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739033",
      "brgy_name": "Teraza",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083739034",
      "brgy_name": "San Fernando",
      "city_code": "083739",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740001",
      "brgy_name": "Baguinbin",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740002",
      "brgy_name": "Belen",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740003",
      "brgy_name": "Buenavista",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740004",
      "brgy_name": "Caduhaan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740005",
      "brgy_name": "Cambakbak",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740006",
      "brgy_name": "Cambinoy",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740007",
      "brgy_name": "Cangcosme",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740008",
      "brgy_name": "Cangmuya",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740009",
      "brgy_name": "Canipaan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740010",
      "brgy_name": "Cantandoy",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740011",
      "brgy_name": "Cantuhaon",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740012",
      "brgy_name": "Catigahan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740013",
      "brgy_name": "Cruz",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740014",
      "brgy_name": "Duljugan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740016",
      "brgy_name": "Guiwan 1 (Pob.)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740017",
      "brgy_name": "Guiwan 2 (Pob.)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740018",
      "brgy_name": "Himarco",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740019",
      "brgy_name": "Hinagbuan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740020",
      "brgy_name": "Lat-osan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740021",
      "brgy_name": "Liberty",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740022",
      "brgy_name": "Mazawalo Pob. (Lili-on)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740023",
      "brgy_name": "Lomonon",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740024",
      "brgy_name": "Mabini",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740025",
      "brgy_name": "Magsaysay",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740026",
      "brgy_name": "Masaba",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740027",
      "brgy_name": "Parilla",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740029",
      "brgy_name": "Plaridel",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740030",
      "brgy_name": "Central 1 (Pob.)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740031",
      "brgy_name": "Central 2 (Pob.)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740032",
      "brgy_name": "Hinablayan Pob. (Central 3)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740033",
      "brgy_name": "Rizal",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740034",
      "brgy_name": "Sabang",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740035",
      "brgy_name": "San Guillermo",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740036",
      "brgy_name": "San Isidro",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740037",
      "brgy_name": "San Joaquin",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740038",
      "brgy_name": "San Juan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740039",
      "brgy_name": "San Miguel",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740040",
      "brgy_name": "San Pablo",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740041",
      "brgy_name": "San Pedro",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740042",
      "brgy_name": "San Roque",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740043",
      "brgy_name": "Santiago",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740044",
      "brgy_name": "Taberna",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740045",
      "brgy_name": "Tabunok",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740046",
      "brgy_name": "Tambis",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740047",
      "brgy_name": "Tinabilan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740048",
      "brgy_name": "Tinago",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740049",
      "brgy_name": "Tinubdan",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740050",
      "brgy_name": "Pinagdait Pob. (Ypil I)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740051",
      "brgy_name": "Pinaghi-usa Pob. (Ypil II)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083740052",
      "brgy_name": "Bitaog Pob. (Ypil III)",
      "city_code": "083740",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741001",
      "brgy_name": "Arabunog",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741002",
      "brgy_name": "Aringit",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741003",
      "brgy_name": "Bahay",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741004",
      "brgy_name": "Cabaohan",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741005",
      "brgy_name": "Calsadahay",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741006",
      "brgy_name": "Cancaraja",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741007",
      "brgy_name": "Caninoan",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741008",
      "brgy_name": "Capilla",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741009",
      "brgy_name": "Colawen",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741010",
      "brgy_name": "Dumarag",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741011",
      "brgy_name": "Guindapunan",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741012",
      "brgy_name": "Halaba",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741013",
      "brgy_name": "Jones",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741014",
      "brgy_name": "Lanawan",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741016",
      "brgy_name": "Lima",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741017",
      "brgy_name": "Macalpiay",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741018",
      "brgy_name": "Malitbogay",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741019",
      "brgy_name": "Manaybanay",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741020",
      "brgy_name": "Maricum",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741021",
      "brgy_name": "Patong",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741022",
      "brgy_name": "District 1 (Pob.)",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741023",
      "brgy_name": "District 2 (Pob.)",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741024",
      "brgy_name": "District 3 (Pob.)",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741025",
      "brgy_name": "District 4 (Pob.)",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741026",
      "brgy_name": "Sapsap",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741027",
      "brgy_name": "Socsocon",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741028",
      "brgy_name": "Tingib",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741029",
      "brgy_name": "Yapad",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083741030",
      "brgy_name": "Lourdes",
      "city_code": "083741",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742001",
      "brgy_name": "Banat-e",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742003",
      "brgy_name": "Basud",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742004",
      "brgy_name": "Bawod (Pob.)",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742005",
      "brgy_name": "Biasong",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742006",
      "brgy_name": "Bunacan",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742008",
      "brgy_name": "Cabungaan",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742009",
      "brgy_name": "Capiñahan (Pob.)",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742010",
      "brgy_name": "Crossing (Pob.)",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742011",
      "brgy_name": "Daja-daku",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742012",
      "brgy_name": "Daja-diot",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742013",
      "brgy_name": "Hacienda Maria",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742014",
      "brgy_name": "Linao",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742015",
      "brgy_name": "Matungao",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742017",
      "brgy_name": "Paril",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742018",
      "brgy_name": "San Jose",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742019",
      "brgy_name": "Taglawigan",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742020",
      "brgy_name": "Tinago",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742021",
      "brgy_name": "Busay",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083742022",
      "brgy_name": "San Miguel",
      "city_code": "083742",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743001",
      "brgy_name": "Bagacay",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743002",
      "brgy_name": "Bahay",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743003",
      "brgy_name": "Bairan",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743004",
      "brgy_name": "Cabatianuhan",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743005",
      "brgy_name": "Canap",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743006",
      "brgy_name": "Capilihan",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743007",
      "brgy_name": "Caraycaray",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743009",
      "brgy_name": "Libtong (East Poblacion)",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743010",
      "brgy_name": "Guinciaman",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743011",
      "brgy_name": "Impo",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743012",
      "brgy_name": "Kinalumsan",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743014",
      "brgy_name": "Lukay",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743015",
      "brgy_name": "Malaguinabot",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743016",
      "brgy_name": "Malpag",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743017",
      "brgy_name": "Mawodpawod",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743018",
      "brgy_name": "Patong",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743019",
      "brgy_name": "Pinarigusan",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743020",
      "brgy_name": "San Andres",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743021",
      "brgy_name": "Santa Cruz",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743022",
      "brgy_name": "Santol",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083743023",
      "brgy_name": "Cayare (West Poblacion)",
      "city_code": "083743",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744001",
      "brgy_name": "Baculanad",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744002",
      "brgy_name": "Badiangay",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744003",
      "brgy_name": "Bulod",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744004",
      "brgy_name": "Catoogan",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744005",
      "brgy_name": "Katipunan",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744006",
      "brgy_name": "Milagrosa",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744007",
      "brgy_name": "Pilit",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744008",
      "brgy_name": "Pitogo",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744009",
      "brgy_name": "Zone 1 (Pob.)",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744010",
      "brgy_name": "Zone 2 (Pob.)",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744011",
      "brgy_name": "Zone 3 (Pob.)",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744012",
      "brgy_name": "San Isidro",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744013",
      "brgy_name": "San Juan",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744014",
      "brgy_name": "San Miguelay",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744015",
      "brgy_name": "San Roque",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744016",
      "brgy_name": "Tibak",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744017",
      "brgy_name": "Victoria",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744018",
      "brgy_name": "Cutay",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744019",
      "brgy_name": "Gapas",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083744020",
      "brgy_name": "Zone 4 Pob. (Cabangcalan)",
      "city_code": "083744",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745002",
      "brgy_name": "Butason I",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745003",
      "brgy_name": "Butason II",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745004",
      "brgy_name": "Campokpok",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745005",
      "brgy_name": "Catmon",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745006",
      "brgy_name": "Gimarco",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745007",
      "brgy_name": "Gibacungan",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745008",
      "brgy_name": "Inangatan",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745009",
      "brgy_name": "Manlawaan",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745010",
      "brgy_name": "Omaganhan",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745011",
      "brgy_name": "Poblacion",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745012",
      "brgy_name": "Santa Rosa",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745013",
      "brgy_name": "Tabing",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083745014",
      "brgy_name": "Tugas",
      "city_code": "083745",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746001",
      "brgy_name": "Amandangay",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746002",
      "brgy_name": "Aslum",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746003",
      "brgy_name": "Balingasag",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746004",
      "brgy_name": "Belisong",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746005",
      "brgy_name": "Cambucao",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746006",
      "brgy_name": "Capahuan",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746007",
      "brgy_name": "Guingawan",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746008",
      "brgy_name": "Jabong",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746009",
      "brgy_name": "Mercadohay",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746010",
      "brgy_name": "Mering",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746011",
      "brgy_name": "Mohon",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746013",
      "brgy_name": "District I Pob. (Quezon)",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746014",
      "brgy_name": "District II Pob. (Rizal)",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746015",
      "brgy_name": "District III Pob. (Bonifacio)",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746016",
      "brgy_name": "District IV Pob. (Macarthur)",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083746017",
      "brgy_name": "San Pablo (Mooc)",
      "city_code": "083746",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747006",
      "brgy_name": "Barangay 2",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747008",
      "brgy_name": "Nula-tula (Bgys. 3 & 3A)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747010",
      "brgy_name": "Libertad (Barangays 1 & 4)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747011",
      "brgy_name": "Barangay 5",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747012",
      "brgy_name": "Barangay 6",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747013",
      "brgy_name": "Barangay 6-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747014",
      "brgy_name": "Barangay 7",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747015",
      "brgy_name": "Barangay 8",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747018",
      "brgy_name": "Barangay 100 (San Roque)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747019",
      "brgy_name": "Barangay 101 (New Kawayan)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747020",
      "brgy_name": "Barangay 102 (Old Kawayan)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747021",
      "brgy_name": "Barangay 103 (Palanog)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747022",
      "brgy_name": "Barangay 103-A (San Paglaum)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747023",
      "brgy_name": "Barangay 104 (Salvacion)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747024",
      "brgy_name": "Barangay 105 (Suhi)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747025",
      "brgy_name": "Barangay 106 (Santo Niño)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747026",
      "brgy_name": "Barangay 107 (Santa Elena)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747027",
      "brgy_name": "Barangay 108 (Tagapuro)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747032",
      "brgy_name": "Barangay 12 (Palanog Resettlement)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747034",
      "brgy_name": "Barangay 13",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747035",
      "brgy_name": "Barangay 14",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747036",
      "brgy_name": "Barangay 15",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747037",
      "brgy_name": "Barangay 16",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747038",
      "brgy_name": "Barangay 17",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747039",
      "brgy_name": "Barangay 18",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747040",
      "brgy_name": "Barangay 19",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747041",
      "brgy_name": "Barangay 20",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747042",
      "brgy_name": "Barangay 21",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747043",
      "brgy_name": "Barangay 21-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747044",
      "brgy_name": "Barangay 22",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747045",
      "brgy_name": "Barangay 23",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747046",
      "brgy_name": "Barangay 24",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747047",
      "brgy_name": "Barangay 25",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747048",
      "brgy_name": "Barangay 26",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747049",
      "brgy_name": "Barangay 27",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747050",
      "brgy_name": "Barangay 28",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747051",
      "brgy_name": "Barangay 29",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747052",
      "brgy_name": "Barangay 30",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747053",
      "brgy_name": "Barangay 31",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747054",
      "brgy_name": "Barangay 32",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747055",
      "brgy_name": "Barangay 33",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747056",
      "brgy_name": "Barangay 34",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747057",
      "brgy_name": "Barangay 35",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747058",
      "brgy_name": "Barangay 35-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747059",
      "brgy_name": "Barangay 36",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747060",
      "brgy_name": "Barangay 37",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747061",
      "brgy_name": "Barangay 37-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747062",
      "brgy_name": "Barangay 38",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747063",
      "brgy_name": "Barangay 39",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747064",
      "brgy_name": "Barangay 40",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747065",
      "brgy_name": "Barangay 41",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747066",
      "brgy_name": "Barangay 42",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747067",
      "brgy_name": "Barangay 43",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747068",
      "brgy_name": "Barangay 43-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747069",
      "brgy_name": "Barangay 43-B",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747070",
      "brgy_name": "Barangay 44",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747071",
      "brgy_name": "Barangay 44-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747072",
      "brgy_name": "Barangay 45",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747073",
      "brgy_name": "Barangay 46",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747074",
      "brgy_name": "Barangay 47",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747075",
      "brgy_name": "Barangay 48",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747076",
      "brgy_name": "Barangay 49",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747077",
      "brgy_name": "Barangay 50",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747078",
      "brgy_name": "Barangay 50-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747079",
      "brgy_name": "Barangay 50-B",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747080",
      "brgy_name": "Barangay 51",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747081",
      "brgy_name": "Barangay 52",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747082",
      "brgy_name": "Barangay 53",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747083",
      "brgy_name": "Barangay 54",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747084",
      "brgy_name": "El Reposo (Barangays 55 & 55A)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747085",
      "brgy_name": "Barangay 56",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747086",
      "brgy_name": "Barangay 57",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747087",
      "brgy_name": "Barangay 58",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747088",
      "brgy_name": "Barangay 59",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747089",
      "brgy_name": "Barangay 60",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747090",
      "brgy_name": "Barangay 60-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747091",
      "brgy_name": "Barangay 61",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747092",
      "brgy_name": "Barangay 62",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747093",
      "brgy_name": "Barangay 63",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747094",
      "brgy_name": "Barangay 64",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747095",
      "brgy_name": "Barangay 65",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747096",
      "brgy_name": "Barangay 66",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747097",
      "brgy_name": "Barangay 66-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747098",
      "brgy_name": "Barangay 67",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747099",
      "brgy_name": "Barangay 68",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747100",
      "brgy_name": "Barangay 69",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747101",
      "brgy_name": "Barangay 70",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747102",
      "brgy_name": "Barangay 71",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747103",
      "brgy_name": "Barangay 72",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747104",
      "brgy_name": "Barangay 73",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747105",
      "brgy_name": "Barangay 74",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747106",
      "brgy_name": "Barangay 75",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747107",
      "brgy_name": "Barangay 76",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747108",
      "brgy_name": "Barangay 77",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747109",
      "brgy_name": "Barangay 78 (Marasbaras)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747110",
      "brgy_name": "Barangay 79 (Marasbaras)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747112",
      "brgy_name": "Barangay 80 (Marasbaras)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747113",
      "brgy_name": "Barangay 81 (Marasbaras)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747114",
      "brgy_name": "Barangay 82 (Marasbaras)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747115",
      "brgy_name": "Barangay 83 (San Jose)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747116",
      "brgy_name": "Barangay 83-A (San Jose)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747117",
      "brgy_name": "Barangay 84 (San Jose)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747118",
      "brgy_name": "Barangay 85 (San Jose)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747119",
      "brgy_name": "Barangay 86",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747120",
      "brgy_name": "Barangay 87",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747121",
      "brgy_name": "Barangay 88",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747122",
      "brgy_name": "Barangay 89",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747123",
      "brgy_name": "Barangay 90 (San Jose)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747124",
      "brgy_name": "Barangay 91 (Abucay)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747125",
      "brgy_name": "Barangay 92 (Apitong)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747127",
      "brgy_name": "Barangay 93 (Bagacay)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747128",
      "brgy_name": "Barangay 94 (Tigbao)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747129",
      "brgy_name": "Barangay 95 (Caibaan)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747130",
      "brgy_name": "Barangay 96 (Calanipawan)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747131",
      "brgy_name": "Barangay 97 (Cabalawan)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747132",
      "brgy_name": "Barangay 98 (Camansinay)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747133",
      "brgy_name": "Barangay 99 (Diit)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747134",
      "brgy_name": "Barangay 109 (V & G Subd.)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747135",
      "brgy_name": "Barangay 109-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747136",
      "brgy_name": "Barangay 110 (Utap)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747137",
      "brgy_name": "Barangay 5-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747138",
      "brgy_name": "Barangay 36-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747139",
      "brgy_name": "Barangay 42-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747140",
      "brgy_name": "Barangay 48-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747141",
      "brgy_name": "Barangay 48-B",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747142",
      "brgy_name": "Barangay 51-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747143",
      "brgy_name": "Barangay 54-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747145",
      "brgy_name": "Barangay 56-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747146",
      "brgy_name": "Barangay 59-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747147",
      "brgy_name": "Barangay 59-B",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747148",
      "brgy_name": "Barangay 62-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747149",
      "brgy_name": "Barangay 62-B",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747150",
      "brgy_name": "Barangay 83-B",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747151",
      "brgy_name": "Barangay 83-C (San Jose)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747152",
      "brgy_name": "Barangay 95-A (Caibaan)",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747153",
      "brgy_name": "Barangay 8-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747154",
      "brgy_name": "Barangay 23-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083747155",
      "brgy_name": "Barangay 94-A",
      "city_code": "083747",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748001",
      "brgy_name": "Ada",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748002",
      "brgy_name": "Amanluran",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748003",
      "brgy_name": "Arado",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748004",
      "brgy_name": "Atipolo",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748005",
      "brgy_name": "Balud",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748006",
      "brgy_name": "Bangon",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748007",
      "brgy_name": "Bantagan",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748008",
      "brgy_name": "Baras",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748009",
      "brgy_name": "Binolo",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748010",
      "brgy_name": "Binongto-an",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748011",
      "brgy_name": "Bislig",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748012",
      "brgy_name": "Cabalagnan",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748013",
      "brgy_name": "Cabarasan Guti",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748014",
      "brgy_name": "Cabonga-an",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748015",
      "brgy_name": "Cabuynan",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748016",
      "brgy_name": "Cahumayhumayan",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748017",
      "brgy_name": "Calogcog",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748018",
      "brgy_name": "Calsadahay",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748019",
      "brgy_name": "Camire",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748020",
      "brgy_name": "Canbalisara",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748021",
      "brgy_name": "Catigbian",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748022",
      "brgy_name": "Catmon",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748023",
      "brgy_name": "Cogon",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748024",
      "brgy_name": "Guindag-an",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748025",
      "brgy_name": "Guingawan",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748026",
      "brgy_name": "Hilagpad",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748028",
      "brgy_name": "Lapay",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748029",
      "brgy_name": "Limbuhan Daku",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748030",
      "brgy_name": "Limbuhan Guti",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748031",
      "brgy_name": "Linao",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748032",
      "brgy_name": "Magay",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748033",
      "brgy_name": "Maghulod",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748034",
      "brgy_name": "Malaguicay",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748035",
      "brgy_name": "Maribi",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748036",
      "brgy_name": "Mohon",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748037",
      "brgy_name": "Pago",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748038",
      "brgy_name": "Pasil",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748039",
      "brgy_name": "Pikas",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748040",
      "brgy_name": "Buntay (Pob.)",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748041",
      "brgy_name": "Canramos (Pob.)",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748042",
      "brgy_name": "Licod (Pob.)",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748043",
      "brgy_name": "San Miguel (Pob.)",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748044",
      "brgy_name": "Salvador",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748045",
      "brgy_name": "San Isidro",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748046",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748047",
      "brgy_name": "San Victor",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748048",
      "brgy_name": "Santa Cruz",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748049",
      "brgy_name": "Santa Elena",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748050",
      "brgy_name": "Santo Niño Pob. (Haclagan)",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748051",
      "brgy_name": "Solano",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748052",
      "brgy_name": "Talolora",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748053",
      "brgy_name": "Tugop",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748054",
      "brgy_name": "Kiling",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083748055",
      "brgy_name": "Sacme",
      "city_code": "083748",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749001",
      "brgy_name": "Burak",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749002",
      "brgy_name": "Canmogsay",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749003",
      "brgy_name": "Cantariwis",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749004",
      "brgy_name": "Capangihan",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749005",
      "brgy_name": "Malbog",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749006",
      "brgy_name": "Olot",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749007",
      "brgy_name": "Opong",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749008",
      "brgy_name": "Poblacion",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749009",
      "brgy_name": "Quilao",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749010",
      "brgy_name": "San Roque",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749011",
      "brgy_name": "San Vicente",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749012",
      "brgy_name": "Tanghas",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749013",
      "brgy_name": "Telegrafo",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749014",
      "brgy_name": "Doña Brigida",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083749015",
      "brgy_name": "Imelda",
      "city_code": "083749",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750001",
      "brgy_name": "Astorga (Upart)",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750002",
      "brgy_name": "Balire",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750003",
      "brgy_name": "Banawang",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750004",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750005",
      "brgy_name": "San Pedro",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750006",
      "brgy_name": "San Roque",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750007",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083750008",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "083750",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751001",
      "brgy_name": "Abijao",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751002",
      "brgy_name": "Balite",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751003",
      "brgy_name": "Bugabuga",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751004",
      "brgy_name": "Cabungahan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751005",
      "brgy_name": "Cabunga-an",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751006",
      "brgy_name": "Cagnocot",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751007",
      "brgy_name": "Cahigan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751008",
      "brgy_name": "Calbugos",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751009",
      "brgy_name": "Camporog",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751010",
      "brgy_name": "Capinyahan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751011",
      "brgy_name": "Casili-on",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751012",
      "brgy_name": "Catagbacan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751013",
      "brgy_name": "Fatima (Pob.)",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751014",
      "brgy_name": "Hibulangan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751015",
      "brgy_name": "Hinabuyan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751016",
      "brgy_name": "Iligay",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751017",
      "brgy_name": "Jalas",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751018",
      "brgy_name": "Jordan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751019",
      "brgy_name": "Libagong",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751020",
      "brgy_name": "New Balanac",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751021",
      "brgy_name": "Payao",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751022",
      "brgy_name": "Poblacion Norte",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751023",
      "brgy_name": "Poblacion Sur",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751024",
      "brgy_name": "Sambulawan",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751025",
      "brgy_name": "San Francisco",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751026",
      "brgy_name": "Silad",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751027",
      "brgy_name": "Sulpa",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751028",
      "brgy_name": "Tabunok",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751029",
      "brgy_name": "Tagbubunga",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751030",
      "brgy_name": "Tinghub",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751031",
      "brgy_name": "Bangcal",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751032",
      "brgy_name": "Canquiason",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751033",
      "brgy_name": "San Vicente",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751034",
      "brgy_name": "Santa Cruz",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "083751035",
      "brgy_name": "Suba",
      "city_code": "083751",
      "province_code": "0837",
      "region_code": "08"
    },
    {
      "brgy_code": "084801001",
      "brgy_name": "Alejandro Village (Santiago)",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801002",
      "brgy_name": "Bonifacio",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801003",
      "brgy_name": "Cabacungan",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801004",
      "brgy_name": "Calarayan",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801006",
      "brgy_name": "Guin-arawayan",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801007",
      "brgy_name": "Jubasan",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801008",
      "brgy_name": "Kinabranan Zone I (Pob.)",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801009",
      "brgy_name": "Kinaguitman",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801010",
      "brgy_name": "Lagundi",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801011",
      "brgy_name": "Lipata",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801012",
      "brgy_name": "Londres",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801015",
      "brgy_name": "Sabang Zone I (Pob.)",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801016",
      "brgy_name": "Santa Rita",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801017",
      "brgy_name": "Tasvilla",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801018",
      "brgy_name": "Frederic",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801019",
      "brgy_name": "Imelda",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801020",
      "brgy_name": "Lo-oc",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801021",
      "brgy_name": "Kinabranan Zone II (Pob.)",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801022",
      "brgy_name": "Sabang Zone II (Pob.)",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084801023",
      "brgy_name": "Victoria",
      "city_code": "084801",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802002",
      "brgy_name": "Poblacion (Biri)",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802003",
      "brgy_name": "MacArthur",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802004",
      "brgy_name": "Kauswagan (Basud)",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802005",
      "brgy_name": "Pio Del Pilar",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802007",
      "brgy_name": "Progresso",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802008",
      "brgy_name": "San Antonio",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802009",
      "brgy_name": "San Pedro",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084802010",
      "brgy_name": "Santo Niño",
      "city_code": "084802",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803001",
      "brgy_name": "Acerida",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803002",
      "brgy_name": "Arellano",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803003",
      "brgy_name": "Balat-balud",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803004",
      "brgy_name": "Dancalan",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803005",
      "brgy_name": "E. Duran",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803006",
      "brgy_name": "Gen. Lucban (Pob.)",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803007",
      "brgy_name": "Jose Abad Santos",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803008",
      "brgy_name": "Jose P. Laurel (Casulgan)",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803010",
      "brgy_name": "Magsaysay (Doce)",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803012",
      "brgy_name": "Calantiao (Pangobi-an)",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803013",
      "brgy_name": "Quezon (Panicayan)",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803014",
      "brgy_name": "Salvacion",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803015",
      "brgy_name": "San Isidro",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803016",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803018",
      "brgy_name": "Santa Clara (Pob.)",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803020",
      "brgy_name": "Santander",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803022",
      "brgy_name": "Somoroy",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084803024",
      "brgy_name": "Trojello",
      "city_code": "084803",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804001",
      "brgy_name": "Aguin",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804003",
      "brgy_name": "Jubang",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804004",
      "brgy_name": "Landusan",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804005",
      "brgy_name": "Oson",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804006",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804007",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804008",
      "brgy_name": "Poblacion Barangay 3",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804009",
      "brgy_name": "Poblacion Barangay 4",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804010",
      "brgy_name": "Poblacion Barangay 5",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804011",
      "brgy_name": "Sagaosawan",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804012",
      "brgy_name": "San Luis",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084804013",
      "brgy_name": "Sawang",
      "city_code": "084804",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805001",
      "brgy_name": "Aguinaldo",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805002",
      "brgy_name": "Airport Village",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805003",
      "brgy_name": "Baybay",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805005",
      "brgy_name": "Bocsol",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805007",
      "brgy_name": "Cabayhan",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805008",
      "brgy_name": "Cag-abaca",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805010",
      "brgy_name": "Cal-igang",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805011",
      "brgy_name": "Cawayan",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805012",
      "brgy_name": "Cervantes",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805013",
      "brgy_name": "Cularima",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805015",
      "brgy_name": "Daganas",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805016",
      "brgy_name": "Galutan",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805018",
      "brgy_name": "General Malvar",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805020",
      "brgy_name": "Guba",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805021",
      "brgy_name": "Gebalagnan (Hibalagnan)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805022",
      "brgy_name": "Gebulwangan",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805025",
      "brgy_name": "Doña Pulqueria (Himbang)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805026",
      "brgy_name": "Hinatad",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805027",
      "brgy_name": "Imelda (Elimbo)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805028",
      "brgy_name": "Liberty",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805029",
      "brgy_name": "Libjo (Binog)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805032",
      "brgy_name": "Mabini",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805033",
      "brgy_name": "Macagtas",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805034",
      "brgy_name": "Mckinley",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805037",
      "brgy_name": "New Rizal",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805038",
      "brgy_name": "Old Rizal",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805041",
      "brgy_name": "Paticua",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805042",
      "brgy_name": "Polangi",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805043",
      "brgy_name": "Quezon",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805045",
      "brgy_name": "Salvacion",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805049",
      "brgy_name": "San Julian",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805051",
      "brgy_name": "Somoge",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805053",
      "brgy_name": "Tinowaran",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805054",
      "brgy_name": "Trangue",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805056",
      "brgy_name": "Washington",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805059",
      "brgy_name": "UEP I",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805060",
      "brgy_name": "UEP II",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805061",
      "brgy_name": "UEP III",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805062",
      "brgy_name": "Acacia (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805063",
      "brgy_name": "Talisay (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805064",
      "brgy_name": "Molave (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805065",
      "brgy_name": "Yakal (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805066",
      "brgy_name": "Ipil-ipil (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805067",
      "brgy_name": "Jose Abad Santos (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805068",
      "brgy_name": "Kasoy (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805069",
      "brgy_name": "Lapu-lapu (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805070",
      "brgy_name": "Santol (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805071",
      "brgy_name": "Narra (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805072",
      "brgy_name": "Calachuchi (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805073",
      "brgy_name": "Sampaguita (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805074",
      "brgy_name": "Mabolo (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805075",
      "brgy_name": "Jose P. Rizal (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805076",
      "brgy_name": "Bangkerohan",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805077",
      "brgy_name": "Dalakit (Pob.)",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084805078",
      "brgy_name": "San Pascual",
      "city_code": "084805",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806001",
      "brgy_name": "Anongo",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806002",
      "brgy_name": "D. Mercader (Bongog)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806003",
      "brgy_name": "Bonifacio",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806004",
      "brgy_name": "Boring",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806005",
      "brgy_name": "Cagbugna",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806006",
      "brgy_name": "Cagmanaba",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806008",
      "brgy_name": "Cagogobngan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806009",
      "brgy_name": "Calingnan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806010",
      "brgy_name": "Canuctan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806011",
      "brgy_name": "Guibwangan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806013",
      "brgy_name": "Hinagonoyan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806014",
      "brgy_name": "Hiparayan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806015",
      "brgy_name": "Hitapi-an",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806016",
      "brgy_name": "Inoburan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806017",
      "brgy_name": "Irawahan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806018",
      "brgy_name": "Libon",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806020",
      "brgy_name": "Claro M. Recto (Lobedico)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806021",
      "brgy_name": "Lenoyahan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806023",
      "brgy_name": "Magongon",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806024",
      "brgy_name": "Magtuad",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806025",
      "brgy_name": "Manering",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806026",
      "brgy_name": "Nabulo",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806027",
      "brgy_name": "Nagoocan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806028",
      "brgy_name": "Nahulid",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806029",
      "brgy_name": "Opong",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806030",
      "brgy_name": "Osang",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806031",
      "brgy_name": "Osmeña",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806032",
      "brgy_name": "P. Rebadulla",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806033",
      "brgy_name": "Roxas",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806034",
      "brgy_name": "Sagudsuron",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806035",
      "brgy_name": "San Antonio",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806036",
      "brgy_name": "San Francisco",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806037",
      "brgy_name": "San Jose (Hebobollao)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806038",
      "brgy_name": "San Vicente",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806039",
      "brgy_name": "Santa Fe",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806040",
      "brgy_name": "Sulitan",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806042",
      "brgy_name": "Tangbo",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806043",
      "brgy_name": "Tungodnon",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806044",
      "brgy_name": "Vienna Maria",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806045",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806046",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806047",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806048",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806049",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806050",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806051",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084806052",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "084806",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807001",
      "brgy_name": "Anito",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807002",
      "brgy_name": "Bangon",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807003",
      "brgy_name": "Bato",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807004",
      "brgy_name": "Bonifacio",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807005",
      "brgy_name": "Cabarasan",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807006",
      "brgy_name": "Cadac-an (Calingnan)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807007",
      "brgy_name": "Cade-an",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807008",
      "brgy_name": "Cagamutan del Norte",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807010",
      "brgy_name": "Dao",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807011",
      "brgy_name": "G. M. Osias",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807012",
      "brgy_name": "Guibuangan",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807013",
      "brgy_name": "Henogawe",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807014",
      "brgy_name": "Lonoy",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807015",
      "brgy_name": "Luneta",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807016",
      "brgy_name": "Malidong",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807018",
      "brgy_name": "Gamay Central (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807019",
      "brgy_name": "Gamay Occidental I (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807020",
      "brgy_name": "Gamay Oriental I (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807021",
      "brgy_name": "Rizal",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807022",
      "brgy_name": "San Antonio",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807023",
      "brgy_name": "Baybay District (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807024",
      "brgy_name": "Burabod (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807025",
      "brgy_name": "Cagamutan del Sur",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807026",
      "brgy_name": "Libertad (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807027",
      "brgy_name": "Occidental II (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084807028",
      "brgy_name": "Oriental II (Pob.)",
      "city_code": "084807",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808001",
      "brgy_name": "Abaton",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808002",
      "brgy_name": "Aguadahan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808003",
      "brgy_name": "Aroganga",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808004",
      "brgy_name": "Atipolo",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808005",
      "brgy_name": "Bawang",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808006",
      "brgy_name": "Baybay (Pob.)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808007",
      "brgy_name": "Binatiklan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808008",
      "brgy_name": "Bobolosan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808009",
      "brgy_name": "Bongliw",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808010",
      "brgy_name": "Burabud",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808011",
      "brgy_name": "Cabadiangan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808012",
      "brgy_name": "Cabagngan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808013",
      "brgy_name": "Cabago-an",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808014",
      "brgy_name": "Cabulaloan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808015",
      "brgy_name": "Cagaasan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808016",
      "brgy_name": "Cagdara-o",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808017",
      "brgy_name": "Cahayagan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808018",
      "brgy_name": "Calintaan Pob. (Sto. Niño)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808019",
      "brgy_name": "Calomotan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808020",
      "brgy_name": "Candawid",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808021",
      "brgy_name": "Cangcahipos",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808022",
      "brgy_name": "Canyomanao",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808023",
      "brgy_name": "Catigbian",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808026",
      "brgy_name": "E. J. Dulay",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808027",
      "brgy_name": "G. B. Tan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808028",
      "brgy_name": "Gibatangan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808029",
      "brgy_name": "Guilaoangi (Pob.)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808030",
      "brgy_name": "Inamlan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808031",
      "brgy_name": "La Perla",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808032",
      "brgy_name": "Langob",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808033",
      "brgy_name": "Lawaan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808034",
      "brgy_name": "Little Venice (Pob.)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808035",
      "brgy_name": "Magsaysay",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808036",
      "brgy_name": "Marubay",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808037",
      "brgy_name": "Mualbual",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808038",
      "brgy_name": "Napotiocan (Salvacion)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808039",
      "brgy_name": "Oleras",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808040",
      "brgy_name": "Onay (Doña Luisa)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808041",
      "brgy_name": "Palmera",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808042",
      "brgy_name": "Pangdan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808043",
      "brgy_name": "Rawis",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808044",
      "brgy_name": "Rombang",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808045",
      "brgy_name": "San Antonio (Son-og)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808046",
      "brgy_name": "San Miguel Heights (Pob.)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808048",
      "brgy_name": "Sangcol",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808049",
      "brgy_name": "Sibunot",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808050",
      "brgy_name": "Simora",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808052",
      "brgy_name": "Suba",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808054",
      "brgy_name": "Tan-awan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808055",
      "brgy_name": "Tarusan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808056",
      "brgy_name": "Tinoblan",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808057",
      "brgy_name": "Tumaguingting (Pob.)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808058",
      "brgy_name": "Vigo",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808059",
      "brgy_name": "Yabyaban (San Vicente)",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808060",
      "brgy_name": "Yapas",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084808061",
      "brgy_name": "Talisay",
      "city_code": "084808",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809001",
      "brgy_name": "Alang-alang",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809002",
      "brgy_name": "Bagacay",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809003",
      "brgy_name": "Cahagwayan",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809004",
      "brgy_name": "Can Maria",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809005",
      "brgy_name": "Can Omanio",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809006",
      "brgy_name": "Imelda",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809007",
      "brgy_name": "Lapinig Del Sur (Pob.)",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809008",
      "brgy_name": "Lapinig Del Norte (Pob.)",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809009",
      "brgy_name": "Lo-ok",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809010",
      "brgy_name": "Mabini",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809011",
      "brgy_name": "May-igot",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809012",
      "brgy_name": "Palanas",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809013",
      "brgy_name": "Pio Del Pilar",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809014",
      "brgy_name": "Potong",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084809015",
      "brgy_name": "Potong Del Sur",
      "city_code": "084809",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810001",
      "brgy_name": "Balugo",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810002",
      "brgy_name": "Bugay",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810003",
      "brgy_name": "Bugtosan",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810004",
      "brgy_name": "Bukid",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810005",
      "brgy_name": "Bulao",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810006",
      "brgy_name": "Caputoan",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810007",
      "brgy_name": "Catoto-ogan",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810008",
      "brgy_name": "Cuenco",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810009",
      "brgy_name": "Dapdap",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810010",
      "brgy_name": "Del Pilar",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810011",
      "brgy_name": "Dolores",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810012",
      "brgy_name": "Epaw",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810013",
      "brgy_name": "Geguinta",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810014",
      "brgy_name": "Geracdo",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810015",
      "brgy_name": "Guyo",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810016",
      "brgy_name": "H. Jolejole District (Pob.)",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810017",
      "brgy_name": "Hangi",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810018",
      "brgy_name": "Imelda",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810019",
      "brgy_name": "L. Empon",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810020",
      "brgy_name": "Lakandula",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810021",
      "brgy_name": "Lumala-og",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810022",
      "brgy_name": "Lourdes",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810023",
      "brgy_name": "Mabini",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810024",
      "brgy_name": "Macarthur",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810025",
      "brgy_name": "Magsaysay",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810026",
      "brgy_name": "Matelarag",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810027",
      "brgy_name": "Osmeña",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810028",
      "brgy_name": "Paco",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810029",
      "brgy_name": "Palanas",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810030",
      "brgy_name": "Perez",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810031",
      "brgy_name": "Poponton",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810032",
      "brgy_name": "Quezon",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810033",
      "brgy_name": "Quirino",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810034",
      "brgy_name": "Quirino District (Pob.)",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810035",
      "brgy_name": "Rebong",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810036",
      "brgy_name": "Rizal",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810037",
      "brgy_name": "Roxas",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810038",
      "brgy_name": "Rufino",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810039",
      "brgy_name": "Sag-od",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810040",
      "brgy_name": "San Andres",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810041",
      "brgy_name": "San Antonio",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810042",
      "brgy_name": "San Fernando",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810043",
      "brgy_name": "San Francisco",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810045",
      "brgy_name": "San Isidro",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810046",
      "brgy_name": "San Jorge",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810047",
      "brgy_name": "San Jose",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810048",
      "brgy_name": "San Miguel",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810049",
      "brgy_name": "Santo Tomas",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810050",
      "brgy_name": "Tagab-iran",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810051",
      "brgy_name": "Tagan-ayan",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810052",
      "brgy_name": "Taylor",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810053",
      "brgy_name": "Victory",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084810054",
      "brgy_name": "H. Jolejole",
      "city_code": "084810",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811001",
      "brgy_name": "Balicuatro",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811002",
      "brgy_name": "Bani",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811003",
      "brgy_name": "Barobaybay",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811004",
      "brgy_name": "Caburihan (Pob.)",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811005",
      "brgy_name": "Caragas (Pob.)",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811006",
      "brgy_name": "Cataogan (Pob.)",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811007",
      "brgy_name": "Chansvilla",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811008",
      "brgy_name": "Datag",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811009",
      "brgy_name": "Enriqueta",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811010",
      "brgy_name": "Libas",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811011",
      "brgy_name": "Libertad",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811012",
      "brgy_name": "Macarthur",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811013",
      "brgy_name": "Magsaysay",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811014",
      "brgy_name": "Maravilla",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811015",
      "brgy_name": "Ocad (Pob.)",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811018",
      "brgy_name": "Salvacion",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811019",
      "brgy_name": "San Agustin",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811020",
      "brgy_name": "San Isidro",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811021",
      "brgy_name": "San Jose",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811022",
      "brgy_name": "San Juan",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811023",
      "brgy_name": "San Miguel",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811026",
      "brgy_name": "To-og",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811027",
      "brgy_name": "Urdaneta",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811028",
      "brgy_name": "Villa",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811029",
      "brgy_name": "Villahermosa",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084811030",
      "brgy_name": "Sabong-Tabok",
      "city_code": "084811",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812001",
      "brgy_name": "Burgos",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812002",
      "brgy_name": "Jubasan",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812003",
      "brgy_name": "Magsaysay",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812004",
      "brgy_name": "Magtaon",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812005",
      "brgy_name": "Del Norte (Pob.)",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812006",
      "brgy_name": "Del Sur (Pob.)",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812007",
      "brgy_name": "Quezon",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812008",
      "brgy_name": "San Jose",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812009",
      "brgy_name": "Siljagon",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812010",
      "brgy_name": "Naparasan",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812011",
      "brgy_name": "E. Laodenio",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812012",
      "brgy_name": "Manaybanay",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084812013",
      "brgy_name": "Santa Potenciana (Pob.)",
      "city_code": "084812",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813001",
      "brgy_name": "Bagasbas",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813002",
      "brgy_name": "Bugko",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813003",
      "brgy_name": "Cablangan",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813004",
      "brgy_name": "Cagmanaba",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813005",
      "brgy_name": "Cahicsan",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813006",
      "brgy_name": "Chitongco (Pob.)",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813007",
      "brgy_name": "De Maria",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813008",
      "brgy_name": "Doña Lucia",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813009",
      "brgy_name": "Eco (Pob.)",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813010",
      "brgy_name": "Flormina",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813011",
      "brgy_name": "Hinabangan",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813012",
      "brgy_name": "Imelda",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813013",
      "brgy_name": "La Trinidad",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813014",
      "brgy_name": "Makiwalo",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813015",
      "brgy_name": "Mirador",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813016",
      "brgy_name": "Nenita",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813017",
      "brgy_name": "Roxas",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813018",
      "brgy_name": "San Agustin",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813019",
      "brgy_name": "San Antonio",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813020",
      "brgy_name": "San Isidro",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813021",
      "brgy_name": "San Jose",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813022",
      "brgy_name": "San Juan",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813023",
      "brgy_name": "Santa Catalina",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084813024",
      "brgy_name": "Talolora",
      "city_code": "084813",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814001",
      "brgy_name": "Asum (Pob.)",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814002",
      "brgy_name": "Bagacay",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814003",
      "brgy_name": "Bangon",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814004",
      "brgy_name": "Binay",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814005",
      "brgy_name": "Cabariwan",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814006",
      "brgy_name": "Cabatuan",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814007",
      "brgy_name": "Campedico",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814008",
      "brgy_name": "Capacujan",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814009",
      "brgy_name": "Jangtud",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814010",
      "brgy_name": "Laniwan (Pob.)",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814011",
      "brgy_name": "Mabaras",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814012",
      "brgy_name": "Magsaysay",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814013",
      "brgy_name": "Manajao",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814014",
      "brgy_name": "Mapno",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814015",
      "brgy_name": "Maragano",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814016",
      "brgy_name": "Matambag",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814017",
      "brgy_name": "Monbon",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814018",
      "brgy_name": "Nagbobtac",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814019",
      "brgy_name": "Napo",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814020",
      "brgy_name": "Natawo",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814021",
      "brgy_name": "Nipa",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814022",
      "brgy_name": "Osmeña",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814023",
      "brgy_name": "Pangpang",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814024",
      "brgy_name": "Paysud",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814026",
      "brgy_name": "Sangay",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814028",
      "brgy_name": "Simora",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814029",
      "brgy_name": "Sinalaran",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814030",
      "brgy_name": "Sumoroy",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814031",
      "brgy_name": "Talolora",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814032",
      "brgy_name": "Tambangan (Pob.)",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814033",
      "brgy_name": "Tinampo (Pob.)",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084814034",
      "brgy_name": "Benigno S. Aquino, Jr.",
      "city_code": "084814",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815002",
      "brgy_name": "Cababto-an",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815004",
      "brgy_name": "Cabari-an",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815005",
      "brgy_name": "Cagbigajo",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815007",
      "brgy_name": "Canjumadal",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815008",
      "brgy_name": "Doña Anecita",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815009",
      "brgy_name": "Camparanga",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815010",
      "brgy_name": "Ge-adgawan",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815011",
      "brgy_name": "Ginulgan",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815012",
      "brgy_name": "Geparayan",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815013",
      "brgy_name": "Igot",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815014",
      "brgy_name": "Ynaguingayan",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815015",
      "brgy_name": "Inanahawan",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815016",
      "brgy_name": "Manahao",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815018",
      "brgy_name": "Paninirongan",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815019",
      "brgy_name": "Poblacion District 1",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815020",
      "brgy_name": "Poblacion District 2",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815021",
      "brgy_name": "Poblacion District 3",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815022",
      "brgy_name": "Poblacion District 4",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815023",
      "brgy_name": "Poblacion District 5",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815024",
      "brgy_name": "Poblacion District 6",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815025",
      "brgy_name": "Poblacion District 7",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815026",
      "brgy_name": "Poblacion District 8",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815027",
      "brgy_name": "San Ramon",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815028",
      "brgy_name": "Senonogan",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815029",
      "brgy_name": "Sixto T. Balanguit, Sr.",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084815030",
      "brgy_name": "Tula",
      "city_code": "084815",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816001",
      "brgy_name": "Aguada",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816002",
      "brgy_name": "Buenavista",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816003",
      "brgy_name": "Jamoog",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816005",
      "brgy_name": "Ligaya",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816006",
      "brgy_name": "Poblacion",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816007",
      "brgy_name": "Salhag",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816008",
      "brgy_name": "San Lorenzo",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816009",
      "brgy_name": "Bantolinao",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816010",
      "brgy_name": "Commonwealth",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816011",
      "brgy_name": "Guindaulan",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084816012",
      "brgy_name": "Kailingan",
      "city_code": "084816",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817001",
      "brgy_name": "Burabod",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817002",
      "brgy_name": "Dalupirit",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817003",
      "brgy_name": "Manraya",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817005",
      "brgy_name": "Pilar",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817006",
      "brgy_name": "Ward I (Pob.)",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817007",
      "brgy_name": "Rizal",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817008",
      "brgy_name": "San Nicolas",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817009",
      "brgy_name": "Vinisitahan",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817010",
      "brgy_name": "Ward II (Pob.)",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084817011",
      "brgy_name": "Ward III (Pob.)",
      "city_code": "084817",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818001",
      "brgy_name": "Alegria",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818003",
      "brgy_name": "Balite",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818004",
      "brgy_name": "Buenavista",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818005",
      "brgy_name": "Caglanipao",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818007",
      "brgy_name": "Happy Valley",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818008",
      "brgy_name": "Mabuhay",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818009",
      "brgy_name": "Palanit",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818010",
      "brgy_name": "Poblacion Norte",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818011",
      "brgy_name": "Poblacion Sur",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818012",
      "brgy_name": "Salvacion",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818013",
      "brgy_name": "San Juan",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818014",
      "brgy_name": "San Roque",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818015",
      "brgy_name": "Seven Hills",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084818016",
      "brgy_name": "Veriato",
      "city_code": "084818",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819002",
      "brgy_name": "Aguadahan",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819003",
      "brgy_name": "Bagong Sabang",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819004",
      "brgy_name": "Balite",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819005",
      "brgy_name": "Bonglas",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819007",
      "brgy_name": "Da-o",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819008",
      "brgy_name": "Gengarog",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819009",
      "brgy_name": "Geratag",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819012",
      "brgy_name": "Layuhan",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819013",
      "brgy_name": "Mandugang",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819014",
      "brgy_name": "P. Tingzon",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819025",
      "brgy_name": "San Lorenzo",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819026",
      "brgy_name": "Tubigdanao",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819027",
      "brgy_name": "Barangay North (Pob.)",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819028",
      "brgy_name": "Barangay South (Pob.)",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819029",
      "brgy_name": "Barangay East (Pob.)",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084819030",
      "brgy_name": "Barangay West (Pob.)",
      "city_code": "084819",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820001",
      "brgy_name": "Balnasan",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820002",
      "brgy_name": "Balud",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820003",
      "brgy_name": "Bantayan",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820004",
      "brgy_name": "Coroconog",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820005",
      "brgy_name": "Dale",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820006",
      "brgy_name": "Ginagdanan",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820007",
      "brgy_name": "Lao-angan",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820008",
      "brgy_name": "Lawaan",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820009",
      "brgy_name": "Malobago",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820010",
      "brgy_name": "Pagsang-an",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820011",
      "brgy_name": "Zone 1 (Pob.)",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820012",
      "brgy_name": "Zone 2 (Pob.)",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820013",
      "brgy_name": "Zone 3 (Pob.)",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820014",
      "brgy_name": "Zone 4 (Pob.)",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820015",
      "brgy_name": "Zone 5 (Pob.)",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084820016",
      "brgy_name": "Zone 6 (Pob.)",
      "city_code": "084820",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084821002",
      "brgy_name": "Maragat",
      "city_code": "084821",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084821003",
      "brgy_name": "Mongol Bongol Pob. (Bgy.1)",
      "city_code": "084821",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084821005",
      "brgy_name": "Sangputan",
      "city_code": "084821",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084821006",
      "brgy_name": "Sila",
      "city_code": "084821",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084821007",
      "brgy_name": "Tarnate",
      "city_code": "084821",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084821008",
      "brgy_name": "Destacado Pob. (Bgy.2)",
      "city_code": "084821",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084821009",
      "brgy_name": "Punta Pob. (Bgy.3)",
      "city_code": "084821",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822001",
      "brgy_name": "Balud",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822002",
      "brgy_name": "Cababayogan",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822003",
      "brgy_name": "Cabunga-an",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822004",
      "brgy_name": "Cagda-o",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822005",
      "brgy_name": "Caghilot",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822006",
      "brgy_name": "Camanggaran",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822007",
      "brgy_name": "Camaya-an",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822008",
      "brgy_name": "Deit De Suba",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822009",
      "brgy_name": "Deit De Turag",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822010",
      "brgy_name": "Gebonawan",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822011",
      "brgy_name": "Gebolwangan",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822012",
      "brgy_name": "Gecbo-an",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822013",
      "brgy_name": "Giguimitan",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822014",
      "brgy_name": "Genagasan",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822015",
      "brgy_name": "Geparayan De Turag",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822016",
      "brgy_name": "Gusaran",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822017",
      "brgy_name": "Imelda",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822018",
      "brgy_name": "Montalban",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822019",
      "brgy_name": "Suba (Pob.)",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822020",
      "brgy_name": "San Isidro",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822021",
      "brgy_name": "Senonogan de Tubang",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822022",
      "brgy_name": "Tobgon",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822023",
      "brgy_name": "Victory",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822024",
      "brgy_name": "Poblacion I",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822025",
      "brgy_name": "Poblacion II",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084822026",
      "brgy_name": "San Antonio",
      "city_code": "084822",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823001",
      "brgy_name": "Acedillo",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823002",
      "brgy_name": "Buenasuerte",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823003",
      "brgy_name": "Buenos Aires",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823004",
      "brgy_name": "Colab-og",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823006",
      "brgy_name": "Erenas",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823007",
      "brgy_name": "Libertad",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823008",
      "brgy_name": "Luisita",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823009",
      "brgy_name": "Lungib",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823010",
      "brgy_name": "Maxvilla",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823011",
      "brgy_name": "Pasabuena",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823012",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823013",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823014",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823016",
      "brgy_name": "San Lazaro",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823017",
      "brgy_name": "San Miguel",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084823018",
      "brgy_name": "San Roman",
      "city_code": "084823",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824001",
      "brgy_name": "Bayho",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824002",
      "brgy_name": "Bonifacio",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824003",
      "brgy_name": "Cagamesarag",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824004",
      "brgy_name": "Cag-aguingay",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824005",
      "brgy_name": "Curry",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824006",
      "brgy_name": "Gebonawan",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824007",
      "brgy_name": "Gen. Luna",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824008",
      "brgy_name": "Getigo",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824009",
      "brgy_name": "Henaronagan",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824010",
      "brgy_name": "Lope De Vega (Pob.)",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824011",
      "brgy_name": "Lower Caynaga",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824012",
      "brgy_name": "Maghipid",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824013",
      "brgy_name": "Magsaysay",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824014",
      "brgy_name": "Osmeña",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824015",
      "brgy_name": "Paguite",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824016",
      "brgy_name": "Roxas",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824017",
      "brgy_name": "Sampaguita",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824018",
      "brgy_name": "San Francisco",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824019",
      "brgy_name": "San Jose",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824020",
      "brgy_name": "San Miguel",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824021",
      "brgy_name": "Somoroy",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "084824022",
      "brgy_name": "Upper Caynaga",
      "city_code": "084824",
      "province_code": "0848",
      "region_code": "08"
    },
    {
      "brgy_code": "086001001",
      "brgy_name": "Bacjao",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001004",
      "brgy_name": "Biasong I",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001005",
      "brgy_name": "Costa Rica",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001007",
      "brgy_name": "Guin-ansan",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001008",
      "brgy_name": "Kerikite",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001011",
      "brgy_name": "Lunang I (Look)",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001012",
      "brgy_name": "Lunang II",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001013",
      "brgy_name": "Malobago",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001014",
      "brgy_name": "Marasbaras",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001017",
      "brgy_name": "Panjobjoban I",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001019",
      "brgy_name": "Poblacion",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001023",
      "brgy_name": "Talahid",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001024",
      "brgy_name": "Tonga-tonga",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001025",
      "brgy_name": "Imelda (Badjang)",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001026",
      "brgy_name": "Biasong II",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001027",
      "brgy_name": "Costa Rica II",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001028",
      "brgy_name": "Mabuhay",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001029",
      "brgy_name": "Magsaysay",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001030",
      "brgy_name": "Panjobjoban II",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001031",
      "brgy_name": "Roño",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001032",
      "brgy_name": "San Isidro",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001033",
      "brgy_name": "San Jose",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086001034",
      "brgy_name": "Veloso",
      "city_code": "086001",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002001",
      "brgy_name": "Amandayehan",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002002",
      "brgy_name": "Anglit",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002003",
      "brgy_name": "Bacubac",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002004",
      "brgy_name": "Baloog",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002005",
      "brgy_name": "Basiao",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002006",
      "brgy_name": "Buenavista",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002007",
      "brgy_name": "Burgos",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002008",
      "brgy_name": "Cambayan",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002009",
      "brgy_name": "Can-abay",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002010",
      "brgy_name": "Cancaiyas",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002011",
      "brgy_name": "Canmanila",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002012",
      "brgy_name": "Catadman",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002013",
      "brgy_name": "Cogon",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002014",
      "brgy_name": "Dolongan",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002015",
      "brgy_name": "Guintigui-an",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002016",
      "brgy_name": "Guirang",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002017",
      "brgy_name": "Balante",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002018",
      "brgy_name": "Iba",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002019",
      "brgy_name": "Inuntan",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002021",
      "brgy_name": "Loog",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002022",
      "brgy_name": "Mabini",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002023",
      "brgy_name": "Magallanes",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002024",
      "brgy_name": "Manlilinab",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002025",
      "brgy_name": "Del Pilar",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002026",
      "brgy_name": "May-it",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002027",
      "brgy_name": "Mongabong",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002028",
      "brgy_name": "New San Agustin",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002029",
      "brgy_name": "Nouvelas Occidental",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002030",
      "brgy_name": "San Fernando (Nouvelas Oriental)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002031",
      "brgy_name": "Old San Agustin",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002032",
      "brgy_name": "Panugmonon",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002033",
      "brgy_name": "Pelit",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002034",
      "brgy_name": "Baybay (Pob.)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002035",
      "brgy_name": "Buscada (Pob.)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002036",
      "brgy_name": "Lawa-an (Pob.)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002037",
      "brgy_name": "Loyo (Pob.)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002038",
      "brgy_name": "Mercado (Pob.)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002039",
      "brgy_name": "Palaypay (Pob.)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002040",
      "brgy_name": "Sulod (Pob.)",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002041",
      "brgy_name": "Roxas",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002042",
      "brgy_name": "Salvacion",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002043",
      "brgy_name": "San Antonio",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002044",
      "brgy_name": "Sawa",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002045",
      "brgy_name": "Serum",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002046",
      "brgy_name": "Sugca",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002047",
      "brgy_name": "Sugponon",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002048",
      "brgy_name": "Tinaogan",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002049",
      "brgy_name": "Tingib",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002050",
      "brgy_name": "Villa Aurora",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002051",
      "brgy_name": "Binongtu-an",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086002052",
      "brgy_name": "Bulao",
      "city_code": "086002",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003001",
      "brgy_name": "Jose A. Roño",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003002",
      "brgy_name": "Acedillo",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003003",
      "brgy_name": "Alibaba",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003004",
      "brgy_name": "Amampacang",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003005",
      "brgy_name": "Anislag",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003007",
      "brgy_name": "Ba-ay",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003008",
      "brgy_name": "Bagacay",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003009",
      "brgy_name": "Baja",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003010",
      "brgy_name": "Balud (Pob.)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003011",
      "brgy_name": "Bante",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003012",
      "brgy_name": "Bantian",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003013",
      "brgy_name": "Basud",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003015",
      "brgy_name": "Bayo",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003016",
      "brgy_name": "Begaho",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003017",
      "brgy_name": "Binaliw",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003018",
      "brgy_name": "Bugtong",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003019",
      "brgy_name": "Buenavista",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003020",
      "brgy_name": "Cabacungan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003021",
      "brgy_name": "Cabatuan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003022",
      "brgy_name": "Cabicahan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003023",
      "brgy_name": "Cabugawan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003024",
      "brgy_name": "Cacaransan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003025",
      "brgy_name": "Cag-olango",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003027",
      "brgy_name": "Cag-anahaw",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003028",
      "brgy_name": "Cagbanayacao",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003029",
      "brgy_name": "Cagbayang",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003030",
      "brgy_name": "Cagbilwang",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003031",
      "brgy_name": "Cagboborac",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003032",
      "brgy_name": "Caglanipao Sur",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003033",
      "brgy_name": "Cagmanipes Norte",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003034",
      "brgy_name": "Cag-anibong",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003035",
      "brgy_name": "Cagnipa",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003036",
      "brgy_name": "Cagsalaosao",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003037",
      "brgy_name": "Cahumpan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003039",
      "brgy_name": "Calocnayan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003040",
      "brgy_name": "Cangomaod",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003041",
      "brgy_name": "Canhumadac",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003042",
      "brgy_name": "Capacuhan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003043",
      "brgy_name": "Capoocan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003044",
      "brgy_name": "Carayman",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003045",
      "brgy_name": "Catabunan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003047",
      "brgy_name": "Caybago",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003048",
      "brgy_name": "Central (Pob.)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003049",
      "brgy_name": "Cogon",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003050",
      "brgy_name": "Dagum",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003052",
      "brgy_name": "Dawo",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003053",
      "brgy_name": "De Victoria",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003054",
      "brgy_name": "Dinabongan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003055",
      "brgy_name": "Dinagan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003056",
      "brgy_name": "Dinawacan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003057",
      "brgy_name": "Esperanza",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003058",
      "brgy_name": "Gadgaran",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003059",
      "brgy_name": "Gasdo",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003060",
      "brgy_name": "Helino",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003061",
      "brgy_name": "Geraga-an",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003062",
      "brgy_name": "Guin-on",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003063",
      "brgy_name": "Guimbaoyan Norte",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003064",
      "brgy_name": "Guimbaoyan Sur",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003065",
      "brgy_name": "Hamorawon",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003066",
      "brgy_name": "Hibabngan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003067",
      "brgy_name": "Hibatang",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003068",
      "brgy_name": "Higasaan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003069",
      "brgy_name": "Himalandrog",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003070",
      "brgy_name": "Jimautan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003071",
      "brgy_name": "Hugon Rosales",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003072",
      "brgy_name": "Jacinto",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003074",
      "brgy_name": "Aguit-itan (Pob.)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003076",
      "brgy_name": "Kilikili",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003077",
      "brgy_name": "La Paz",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003078",
      "brgy_name": "Langoyon",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003079",
      "brgy_name": "Lapaan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003081",
      "brgy_name": "Libertad",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003082",
      "brgy_name": "Limarayon",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003083",
      "brgy_name": "Looc",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003084",
      "brgy_name": "Longsob",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003085",
      "brgy_name": "Lonoy",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003086",
      "brgy_name": "Mabini I (Calbayog District)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003087",
      "brgy_name": "Mabini II (Oquendo District)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003088",
      "brgy_name": "Macatingog",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003089",
      "brgy_name": "Mag-ubay",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003090",
      "brgy_name": "Maguino-o",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003091",
      "brgy_name": "Malaga",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003092",
      "brgy_name": "Malajog",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003093",
      "brgy_name": "Malayog",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003094",
      "brgy_name": "Malopalo",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003095",
      "brgy_name": "Marcatubig",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003096",
      "brgy_name": "Mancol",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003097",
      "brgy_name": "Mantaong (Oquendo District)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003098",
      "brgy_name": "Matobato",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003099",
      "brgy_name": "Mawacat",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003101",
      "brgy_name": "Maybog",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003102",
      "brgy_name": "Maysalong",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003103",
      "brgy_name": "Migara",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003105",
      "brgy_name": "Nabang",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003106",
      "brgy_name": "Naga",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003107",
      "brgy_name": "Naguma",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003109",
      "brgy_name": "Navarro",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003110",
      "brgy_name": "Nijaga",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003111",
      "brgy_name": "Oboob",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003112",
      "brgy_name": "Obrero",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003113",
      "brgy_name": "Olera",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003114",
      "brgy_name": "Oquendo (Pob.)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003115",
      "brgy_name": "Osmeña",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003116",
      "brgy_name": "Palanas",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003117",
      "brgy_name": "Palanogan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003118",
      "brgy_name": "Panlayahan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003119",
      "brgy_name": "Panonongan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003120",
      "brgy_name": "Panoypoy",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003121",
      "brgy_name": "Patong",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003122",
      "brgy_name": "Peña",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003123",
      "brgy_name": "Pilar",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003124",
      "brgy_name": "Pinamorotan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003126",
      "brgy_name": "Quezon",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003127",
      "brgy_name": "Rawis",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003128",
      "brgy_name": "Rizal I (Calbayog District)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003129",
      "brgy_name": "Rizal II (Oquendo District)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003130",
      "brgy_name": "Roxas I (Calbayog District)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003131",
      "brgy_name": "Roxas II (Oquendo District)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003132",
      "brgy_name": "Saljag (Baut)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003133",
      "brgy_name": "Salvacion",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003134",
      "brgy_name": "San Antonio",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003135",
      "brgy_name": "San Isidro",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003136",
      "brgy_name": "San Joaquin",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003137",
      "brgy_name": "San Jose",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003138",
      "brgy_name": "San Policarpio",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003139",
      "brgy_name": "San Rufino",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003140",
      "brgy_name": "Saputan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003141",
      "brgy_name": "Sigo",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003142",
      "brgy_name": "Sinantan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003143",
      "brgy_name": "Sinidman Occidental",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003144",
      "brgy_name": "Sinidman Oriental",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003145",
      "brgy_name": "Tabawan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003146",
      "brgy_name": "Talahiban",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003147",
      "brgy_name": "Tapa-e",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003148",
      "brgy_name": "Tarabucan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003149",
      "brgy_name": "Tigbe",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003152",
      "brgy_name": "Tinaplacan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003153",
      "brgy_name": "Tomaliguez",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003154",
      "brgy_name": "Trinidad (Sabang)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003155",
      "brgy_name": "Victory",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003156",
      "brgy_name": "Villahermosa",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003158",
      "brgy_name": "Awang East (Pob.)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003159",
      "brgy_name": "Awang West (Pob.)",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003160",
      "brgy_name": "Bagong Lipunan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003162",
      "brgy_name": "Bontay",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003164",
      "brgy_name": "Kalilihan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003165",
      "brgy_name": "Carmen",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003166",
      "brgy_name": "Danao I",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003167",
      "brgy_name": "Danao II",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003168",
      "brgy_name": "Gabay",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003171",
      "brgy_name": "Pagbalican",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003173",
      "brgy_name": "Payahan",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003174",
      "brgy_name": "Tanval",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003175",
      "brgy_name": "Tinambacan Norte",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003176",
      "brgy_name": "Tinambacan Sur",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003177",
      "brgy_name": "Cagmanipes Sur",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086003178",
      "brgy_name": "Manuel Barral, Sr.",
      "city_code": "086003",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004001",
      "brgy_name": "Antol",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004002",
      "brgy_name": "Bacyaran",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004003",
      "brgy_name": "Beri",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004004",
      "brgy_name": "Barobaybay",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004005",
      "brgy_name": "Binanggaran",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004006",
      "brgy_name": "Borong",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004007",
      "brgy_name": "Bulao",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004008",
      "brgy_name": "Buluan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004009",
      "brgy_name": "Caamlongan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004010",
      "brgy_name": "Calayaan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004011",
      "brgy_name": "Calingonan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004012",
      "brgy_name": "Canbagtic",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004013",
      "brgy_name": "Canticum",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004014",
      "brgy_name": "Daligan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004015",
      "brgy_name": "Guinbanga",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004016",
      "brgy_name": "Hubasan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004017",
      "brgy_name": "Literon",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004018",
      "brgy_name": "Lubang",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004019",
      "brgy_name": "Mahangcao",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004020",
      "brgy_name": "Macaalan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004021",
      "brgy_name": "Malabal",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004022",
      "brgy_name": "Minata",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004024",
      "brgy_name": "Otoc",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004025",
      "brgy_name": "Panayuran",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004026",
      "brgy_name": "Pasigay",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004027",
      "brgy_name": "Patong",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004028",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004029",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004030",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004031",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004032",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004033",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004034",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004035",
      "brgy_name": "Polangi",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004036",
      "brgy_name": "Rawis",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004037",
      "brgy_name": "San Ignacio",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004038",
      "brgy_name": "San Mauricio",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004039",
      "brgy_name": "Sinalangtan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004040",
      "brgy_name": "Timbangan",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004041",
      "brgy_name": "Tinago",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086004042",
      "brgy_name": "Hindang",
      "city_code": "086004",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005001",
      "brgy_name": "Albalate",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005002",
      "brgy_name": "Bagongon",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005003",
      "brgy_name": "Bangon",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005004",
      "brgy_name": "Basiao",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005005",
      "brgy_name": "Buluan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005006",
      "brgy_name": "Bunuanan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005007",
      "brgy_name": "Cabugawan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005008",
      "brgy_name": "Cagudalo",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005009",
      "brgy_name": "Cagusipan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005011",
      "brgy_name": "Cagutian",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005012",
      "brgy_name": "Cagutsan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005013",
      "brgy_name": "Canhawan Gote",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005014",
      "brgy_name": "Canlapwas (Pob.)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005015",
      "brgy_name": "Cawayan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005016",
      "brgy_name": "Cinco",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005017",
      "brgy_name": "Darahuway Daco",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005018",
      "brgy_name": "Darahuway Gote",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005019",
      "brgy_name": "Estaka",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005020",
      "brgy_name": "Guinsorongan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005021",
      "brgy_name": "Iguid",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005022",
      "brgy_name": "Lagundi",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005023",
      "brgy_name": "Libas",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005024",
      "brgy_name": "Lobo",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005025",
      "brgy_name": "Manguehay",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005026",
      "brgy_name": "Maulong",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005027",
      "brgy_name": "Mercedes",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005028",
      "brgy_name": "Mombon",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005029",
      "brgy_name": "New Mahayag",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005030",
      "brgy_name": "Old Mahayag",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005031",
      "brgy_name": "Palanyogon",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005032",
      "brgy_name": "Pangdan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005033",
      "brgy_name": "Payao",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005034",
      "brgy_name": "Poblacion 1 (Barangay 1)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005035",
      "brgy_name": "Poblacion 2 (Barangay 2)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005036",
      "brgy_name": "Poblacion 3 (Barangay 3)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005037",
      "brgy_name": "Poblacion 4 (Barangay 4)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005038",
      "brgy_name": "Poblacion 5 (Barangay 5)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005039",
      "brgy_name": "Poblacion 6 (Barangay 6)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005040",
      "brgy_name": "Poblacion 7 (Barangay 7)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005041",
      "brgy_name": "Poblacion 8 (Barangay 8)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005042",
      "brgy_name": "Poblacion 9 (Barangay 9)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005043",
      "brgy_name": "Poblacion 10 (Barangay 10)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005044",
      "brgy_name": "Poblacion 11 (Barangay 11)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005045",
      "brgy_name": "Poblacion 12 (Barangay 12)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005046",
      "brgy_name": "Poblacion 13 (Barangay 13)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005047",
      "brgy_name": "Muñoz (Poblacion 14)",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005048",
      "brgy_name": "Pupua",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005049",
      "brgy_name": "Guindaponan",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005050",
      "brgy_name": "Rama",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005051",
      "brgy_name": "San Andres",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005052",
      "brgy_name": "San Pablo",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005053",
      "brgy_name": "San Roque",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005054",
      "brgy_name": "San Vicente",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005055",
      "brgy_name": "Silanga",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005056",
      "brgy_name": "Totoringon",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005057",
      "brgy_name": "Ibol",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086005059",
      "brgy_name": "Socorro",
      "city_code": "086005",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006001",
      "brgy_name": "Arawane",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006002",
      "brgy_name": "Astorga",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006003",
      "brgy_name": "Bachao",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006004",
      "brgy_name": "Baclayan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006005",
      "brgy_name": "Bagacay",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006006",
      "brgy_name": "Bayog",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006007",
      "brgy_name": "Birawan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006008",
      "brgy_name": "Betaug",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006009",
      "brgy_name": "Bono-anon",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006010",
      "brgy_name": "Buenavista",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006011",
      "brgy_name": "Burgos",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006012",
      "brgy_name": "Cabac",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006013",
      "brgy_name": "Cabil-isan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006014",
      "brgy_name": "Cabiton-an",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006015",
      "brgy_name": "Cabugao",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006016",
      "brgy_name": "Calawan-an",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006017",
      "brgy_name": "Cambuhay",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006018",
      "brgy_name": "Candugue",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006019",
      "brgy_name": "Canloloy",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006020",
      "brgy_name": "Campelipa",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006021",
      "brgy_name": "Cansaganay",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006022",
      "brgy_name": "Poblacion 3 (Canti-il)",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006023",
      "brgy_name": "Casab-ahan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006024",
      "brgy_name": "Guindapunan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006025",
      "brgy_name": "Guintampilan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006026",
      "brgy_name": "Iquiran",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006027",
      "brgy_name": "Jacopon",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006028",
      "brgy_name": "Losa",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006029",
      "brgy_name": "Mabini",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006030",
      "brgy_name": "Macalpe",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006031",
      "brgy_name": "Mandoyucan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006032",
      "brgy_name": "Mongolbongol",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006033",
      "brgy_name": "Marupangdan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006034",
      "brgy_name": "Mayabay",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006035",
      "brgy_name": "Nipa",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006037",
      "brgy_name": "Parasan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006038",
      "brgy_name": "Poblacion 1 (Hilaba)",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006039",
      "brgy_name": "Poblacion 2 (Malingon)",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006040",
      "brgy_name": "Pondang",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006041",
      "brgy_name": "Poso",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006042",
      "brgy_name": "Real",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006043",
      "brgy_name": "Rizal",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006044",
      "brgy_name": "San Antonio",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006045",
      "brgy_name": "San Jose",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006046",
      "brgy_name": "San Miguel",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006047",
      "brgy_name": "San Roque",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006048",
      "brgy_name": "Saugan",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006049",
      "brgy_name": "So-ong",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006050",
      "brgy_name": "Sua",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006051",
      "brgy_name": "Talisay",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006052",
      "brgy_name": "Tugas",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006053",
      "brgy_name": "Ubo",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006054",
      "brgy_name": "Valles-Bello",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006055",
      "brgy_name": "Cagboboto",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006056",
      "brgy_name": "Lucob-lucob",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006057",
      "brgy_name": "San Vicente",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006058",
      "brgy_name": "Sugod",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086006059",
      "brgy_name": "Yangta",
      "city_code": "086006",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007002",
      "brgy_name": "Balocawe",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007006",
      "brgy_name": "Beslig",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007008",
      "brgy_name": "Burabod I (Pob.)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007009",
      "brgy_name": "Burabod II (Pob.)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007010",
      "brgy_name": "Buao",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007014",
      "brgy_name": "Bunyagan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007018",
      "brgy_name": "Calirocan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007020",
      "brgy_name": "Canhumawid",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007023",
      "brgy_name": "Caparangasan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007024",
      "brgy_name": "Caranas",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007025",
      "brgy_name": "Carmona",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007026",
      "brgy_name": "Casab-ahan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007027",
      "brgy_name": "Casandig",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007028",
      "brgy_name": "Caugbusan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007029",
      "brgy_name": "Concepcion",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007031",
      "brgy_name": "Dumalo-ong (Pob.)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007032",
      "brgy_name": "Elcano",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007034",
      "brgy_name": "Gerali",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007035",
      "brgy_name": "Giaboc",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007039",
      "brgy_name": "Hampton",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007041",
      "brgy_name": "Hinayagan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007042",
      "brgy_name": "Hinugacan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007044",
      "brgy_name": "Jasminez",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007048",
      "brgy_name": "Lungib",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007049",
      "brgy_name": "Mabuhay",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007050",
      "brgy_name": "Macugo",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007052",
      "brgy_name": "Minda (Pob.)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007054",
      "brgy_name": "Nacube",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007055",
      "brgy_name": "Nalihugan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007056",
      "brgy_name": "Napalisan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007057",
      "brgy_name": "Natimonan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007058",
      "brgy_name": "Ngoso",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007059",
      "brgy_name": "Palambrag",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007060",
      "brgy_name": "Palanas",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007061",
      "brgy_name": "Piñaplata",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007062",
      "brgy_name": "Pizarro",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007063",
      "brgy_name": "Pologon",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007064",
      "brgy_name": "Purog",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007067",
      "brgy_name": "Rawis",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007068",
      "brgy_name": "Rizal",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007070",
      "brgy_name": "Samoyao",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007071",
      "brgy_name": "San Agustin",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007072",
      "brgy_name": "San Antonio",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007073",
      "brgy_name": "San Enrique",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007074",
      "brgy_name": "San Francisco",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007076",
      "brgy_name": "San Isidro (Dao)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007078",
      "brgy_name": "San Jose",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007079",
      "brgy_name": "San Miguel",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007080",
      "brgy_name": "San Pelayo",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007081",
      "brgy_name": "San Ramon",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007082",
      "brgy_name": "Santa Elena",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007083",
      "brgy_name": "Santo Niño",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007085",
      "brgy_name": "Senibaran (Villa Leona)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007087",
      "brgy_name": "Tagnao",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007088",
      "brgy_name": "Tambongan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007090",
      "brgy_name": "Tawiran",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007091",
      "brgy_name": "Tigbawon",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007092",
      "brgy_name": "Marcos",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007093",
      "brgy_name": "Bangahon",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007094",
      "brgy_name": "Adela Heights (Pob.)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007095",
      "brgy_name": "Arong",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007096",
      "brgy_name": "Catorse De Agosto",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007097",
      "brgy_name": "Diaz",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007098",
      "brgy_name": "Gereganan",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007099",
      "brgy_name": "Hetebac",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007100",
      "brgy_name": "Himamaloto",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007101",
      "brgy_name": "Hiparayan (Kinonongan)",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007102",
      "brgy_name": "Malayog",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086007103",
      "brgy_name": "Sidmon",
      "city_code": "086007",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008001",
      "brgy_name": "Bagacay",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008002",
      "brgy_name": "Binobucalan",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008003",
      "brgy_name": "Bucalan",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008004",
      "brgy_name": "Cabalagnan",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008005",
      "brgy_name": "Canano",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008006",
      "brgy_name": "Consolabao",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008007",
      "brgy_name": "Concord",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008008",
      "brgy_name": "Dalosdoson",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008009",
      "brgy_name": "Lim-ao",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008010",
      "brgy_name": "Osmeña",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008012",
      "brgy_name": "Poblacion 1 (Barangay 1)",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008013",
      "brgy_name": "Poblacion 2 (Barangay 2)",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008014",
      "brgy_name": "Rawis",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008015",
      "brgy_name": "San Rafael",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008016",
      "brgy_name": "Tabay",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008017",
      "brgy_name": "Yabon",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008018",
      "brgy_name": "Cabang",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008019",
      "brgy_name": "Malihao",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008020",
      "brgy_name": "San Jose",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008021",
      "brgy_name": "Fatima",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086008022",
      "brgy_name": "Mugdo",
      "city_code": "086008",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009001",
      "brgy_name": "Bawang",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009002",
      "brgy_name": "Bugho",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009004",
      "brgy_name": "Camarobo-an",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009005",
      "brgy_name": "Candayao",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009006",
      "brgy_name": "Cantongtong",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009007",
      "brgy_name": "Casapa",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009008",
      "brgy_name": "Catalina",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009009",
      "brgy_name": "Cristina",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009011",
      "brgy_name": "Dogongan",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009012",
      "brgy_name": "Garcia",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009013",
      "brgy_name": "Hinaga",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009014",
      "brgy_name": "Jia-an",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009015",
      "brgy_name": "Jidanao",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009016",
      "brgy_name": "Lulugayan",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009017",
      "brgy_name": "Macabetas",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009018",
      "brgy_name": "Malino",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009019",
      "brgy_name": "Malobago (Villalinda)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009020",
      "brgy_name": "Mercedes",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009021",
      "brgy_name": "Nagbac",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009022",
      "brgy_name": "Parina",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009024",
      "brgy_name": "Barangay No. 1 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009025",
      "brgy_name": "Barangay No. 2 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009026",
      "brgy_name": "Barangay No. 3 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009027",
      "brgy_name": "Barangay No. 4 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009028",
      "brgy_name": "Barangay No. 5 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009029",
      "brgy_name": "Barangay No. 6 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009030",
      "brgy_name": "Barangay No. 7 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009031",
      "brgy_name": "Barangay No. 8 (Pob.)",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009032",
      "brgy_name": "Salvacion",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009033",
      "brgy_name": "San Andres",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009034",
      "brgy_name": "San Fernando",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009035",
      "brgy_name": "San Miguel",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009036",
      "brgy_name": "Tagbayaon",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086009037",
      "brgy_name": "Victory",
      "city_code": "086009",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010001",
      "brgy_name": "Amambucale",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010002",
      "brgy_name": "Caluwayan",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010003",
      "brgy_name": "Canyoyo",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010004",
      "brgy_name": "Ferreras",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010005",
      "brgy_name": "Legaspi",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010006",
      "brgy_name": "Logero",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010007",
      "brgy_name": "Osmeña",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010008",
      "brgy_name": "Pinalanga",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010009",
      "brgy_name": "Pinamitinan (Pob.)",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010010",
      "brgy_name": "Catato Pob. (Dist. I)",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010011",
      "brgy_name": "San Roque",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010012",
      "brgy_name": "Santo Niño Pob. (Dist. II)",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010013",
      "brgy_name": "Tagalag",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010014",
      "brgy_name": "Tinabanan",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010015",
      "brgy_name": "Amantillo",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010016",
      "brgy_name": "Binukyahan",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010017",
      "brgy_name": "Lipata",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010018",
      "brgy_name": "Mabuhay",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010019",
      "brgy_name": "Malobago",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010020",
      "brgy_name": "Odoc",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010021",
      "brgy_name": "Panan-awan",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010022",
      "brgy_name": "Roño",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010023",
      "brgy_name": "Santa Rita",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086010024",
      "brgy_name": "Veloso",
      "city_code": "086010",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011001",
      "brgy_name": "Angyap",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011004",
      "brgy_name": "Barruz (Barangay No. 1)",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011005",
      "brgy_name": "Camonoan",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011006",
      "brgy_name": "Carolina",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011007",
      "brgy_name": "Deit",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011008",
      "brgy_name": "Del Rosario",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011009",
      "brgy_name": "Libertad",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011010",
      "brgy_name": "Ligaya",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011011",
      "brgy_name": "Mabuligon Pob. (Barangay No. 2)",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011012",
      "brgy_name": "Maduroto Pob. (Barangay No. 3)",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011013",
      "brgy_name": "Mahanud",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011014",
      "brgy_name": "Mahayag",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011016",
      "brgy_name": "Nagpapacao",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011017",
      "brgy_name": "Rizal",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011018",
      "brgy_name": "Salvacion",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011019",
      "brgy_name": "San Isidro",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011020",
      "brgy_name": "Santa Cruz",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011021",
      "brgy_name": "Bag-otan",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011022",
      "brgy_name": "Inubod",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086011023",
      "brgy_name": "San Roque (Mabuhay)",
      "city_code": "086011",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012001",
      "brgy_name": "Poblacion I",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012002",
      "brgy_name": "Poblacion I-A",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012003",
      "brgy_name": "Angyap",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012004",
      "brgy_name": "Barayong",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012005",
      "brgy_name": "Bayog",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012006",
      "brgy_name": "Beri",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012007",
      "brgy_name": "Bonga",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012008",
      "brgy_name": "Calantawan",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012009",
      "brgy_name": "Calapi",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012010",
      "brgy_name": "Caluyahan",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012011",
      "brgy_name": "Canvais",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012012",
      "brgy_name": "Canatuan",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012013",
      "brgy_name": "Candomacol",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012015",
      "brgy_name": "Capaysagan",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012016",
      "brgy_name": "Caranas",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012017",
      "brgy_name": "Caulayanan",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012018",
      "brgy_name": "Hinica-an",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012019",
      "brgy_name": "Inalad",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012020",
      "brgy_name": "Linonoban",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012021",
      "brgy_name": "Malobago",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012022",
      "brgy_name": "Malonoy",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012023",
      "brgy_name": "Mararangsi",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012024",
      "brgy_name": "Maypange",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012025",
      "brgy_name": "New Minarog",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012026",
      "brgy_name": "Oyandic",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012027",
      "brgy_name": "Pamamasan",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012028",
      "brgy_name": "San Andres",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012029",
      "brgy_name": "Santo Niño",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012030",
      "brgy_name": "Sarao",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086012031",
      "brgy_name": "Pusongan",
      "city_code": "086012",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013001",
      "brgy_name": "Bangon",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013002",
      "brgy_name": "Barangay I (Pob.)",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013003",
      "brgy_name": "Barangay II (Pob.)",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013004",
      "brgy_name": "Botoc",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013005",
      "brgy_name": "Bugho",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013006",
      "brgy_name": "Calampong",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013007",
      "brgy_name": "Canlobo",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013008",
      "brgy_name": "Catigawan",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013010",
      "brgy_name": "Dolores",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013011",
      "brgy_name": "Lale",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013012",
      "brgy_name": "Lawaan",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013013",
      "brgy_name": "Laygayon",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013014",
      "brgy_name": "Layo",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013015",
      "brgy_name": "Loctob",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013016",
      "brgy_name": "Madalunot",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013017",
      "brgy_name": "Magdawat",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013018",
      "brgy_name": "Mambog",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013019",
      "brgy_name": "Manaing",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013020",
      "brgy_name": "Obayan",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013021",
      "brgy_name": "Pahug",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013022",
      "brgy_name": "Parasanon",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013023",
      "brgy_name": "Pelaon",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013025",
      "brgy_name": "San Isidro",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086013026",
      "brgy_name": "Nabong",
      "city_code": "086013",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014001",
      "brgy_name": "Aguingayan",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014002",
      "brgy_name": "Babaclayon",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014004",
      "brgy_name": "Can-aponte",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014005",
      "brgy_name": "Cataydongan",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014006",
      "brgy_name": "Gusa",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014007",
      "brgy_name": "Hagbay",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014008",
      "brgy_name": "Hiduroma",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014009",
      "brgy_name": "Hilumot",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014010",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014011",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014012",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014013",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014014",
      "brgy_name": "San Nicolas",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086014015",
      "brgy_name": "Hibaca-an",
      "city_code": "086014",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015001",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015002",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015003",
      "brgy_name": "Poblacion Barangay 3",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015004",
      "brgy_name": "Poblacion Barangay 4",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015005",
      "brgy_name": "Balogo",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015006",
      "brgy_name": "Bontod",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015007",
      "brgy_name": "Camanhagay",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015008",
      "brgy_name": "Campiyak",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015010",
      "brgy_name": "Dolores",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015011",
      "brgy_name": "Hita-asan I",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015012",
      "brgy_name": "Inobongan",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015013",
      "brgy_name": "Cabaywa",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015014",
      "brgy_name": "Canduyucan",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086015015",
      "brgy_name": "Hita-asan II",
      "city_code": "086015",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016001",
      "brgy_name": "Agrupacion",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016002",
      "brgy_name": "Arapison",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016003",
      "brgy_name": "Avelino",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016004",
      "brgy_name": "Bahay",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016005",
      "brgy_name": "Balud",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016006",
      "brgy_name": "Bana-ao",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016008",
      "brgy_name": "Burabod",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016009",
      "brgy_name": "Cautod (Pob.)",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016010",
      "brgy_name": "Camperito",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016011",
      "brgy_name": "Campeig",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016012",
      "brgy_name": "Can-ipulan",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016013",
      "brgy_name": "Canmoros",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016014",
      "brgy_name": "Cinco",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016015",
      "brgy_name": "Curry",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016016",
      "brgy_name": "Gajo",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016017",
      "brgy_name": "Hindang",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016018",
      "brgy_name": "Ilo",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016019",
      "brgy_name": "Imelda",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016020",
      "brgy_name": "Inoraguiao",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016021",
      "brgy_name": "Jolacao",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016022",
      "brgy_name": "Lambao",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016023",
      "brgy_name": "Mabuhay",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016024",
      "brgy_name": "Mahayag",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016025",
      "brgy_name": "Monbon (Pob.)",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016026",
      "brgy_name": "Nabulo",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016027",
      "brgy_name": "Napuro",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016028",
      "brgy_name": "Palale",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016029",
      "brgy_name": "Panabatan",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016030",
      "brgy_name": "Panaruan",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016031",
      "brgy_name": "Roxas",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016032",
      "brgy_name": "Salvacion",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016033",
      "brgy_name": "Solsogon",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016034",
      "brgy_name": "Sundara",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016035",
      "brgy_name": "Cagsumji",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016036",
      "brgy_name": "Matayonas",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086016037",
      "brgy_name": "Napuro II",
      "city_code": "086016",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017001",
      "brgy_name": "Alegria",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017002",
      "brgy_name": "Anibongan",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017003",
      "brgy_name": "Aslum",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017004",
      "brgy_name": "Bagolibas",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017005",
      "brgy_name": "Binanalan",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017006",
      "brgy_name": "Cabacungan",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017007",
      "brgy_name": "Cabunga-an",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017008",
      "brgy_name": "Camayse",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017009",
      "brgy_name": "Cansadong",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017010",
      "brgy_name": "Caticugan",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017011",
      "brgy_name": "Dampigan",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017012",
      "brgy_name": "Guinbalot-an",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017013",
      "brgy_name": "Hinangudtan",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017014",
      "brgy_name": "Igang-igang",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017015",
      "brgy_name": "La Paz",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017016",
      "brgy_name": "Lupig",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017017",
      "brgy_name": "Magsaysay",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017018",
      "brgy_name": "Maligaya",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017019",
      "brgy_name": "New Manunca",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017020",
      "brgy_name": "Old Manunca",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017021",
      "brgy_name": "Pagsulhogon",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017023",
      "brgy_name": "Salvacion",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017024",
      "brgy_name": "San Eduardo",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017025",
      "brgy_name": "San Isidro",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017026",
      "brgy_name": "San Juan",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017027",
      "brgy_name": "San Pascual (Crossing)",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017028",
      "brgy_name": "San Pedro",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017029",
      "brgy_name": "San Roque",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017030",
      "brgy_name": "Santa Elena",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017031",
      "brgy_name": "Tagacay",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017032",
      "brgy_name": "Tominamos",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017033",
      "brgy_name": "Tulay",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017034",
      "brgy_name": "Union",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017035",
      "brgy_name": "Bokinggan Pob. (Zone I)",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017036",
      "brgy_name": "Bougainvilla Pob. (Zone II)",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017037",
      "brgy_name": "Gumamela Pob. (Zone III)",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017038",
      "brgy_name": "Rosal Pob. (Zone IV)",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086017039",
      "brgy_name": "Santan Pob. (Zone V)",
      "city_code": "086017",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018001",
      "brgy_name": "Balatguti",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018002",
      "brgy_name": "Baras",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018003",
      "brgy_name": "Basud (Pob.)",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018004",
      "brgy_name": "Buenavista",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018005",
      "brgy_name": "Cabunga-an",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018006",
      "brgy_name": "Corocawayan",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018007",
      "brgy_name": "Ilijan",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018008",
      "brgy_name": "Ilo (Pob.)",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018009",
      "brgy_name": "Lobelobe",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018010",
      "brgy_name": "Pinanangnan",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018011",
      "brgy_name": "Sevilla",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018012",
      "brgy_name": "Takut",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086018013",
      "brgy_name": "Villahermosa",
      "city_code": "086018",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019001",
      "brgy_name": "Bo. Independencia",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019002",
      "brgy_name": "Malaguining",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019003",
      "brgy_name": "Mallorga",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019004",
      "brgy_name": "Navatas Daku",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019005",
      "brgy_name": "Navatas Guti",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019006",
      "brgy_name": "Placer",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019007",
      "brgy_name": "Poblacion Barangay 1",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019008",
      "brgy_name": "Poblacion Barangay 2",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019009",
      "brgy_name": "San Juan",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019010",
      "brgy_name": "Tatabunan",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086019011",
      "brgy_name": "Victory",
      "city_code": "086019",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020001",
      "brgy_name": "Alcazar",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020002",
      "brgy_name": "Awang",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020003",
      "brgy_name": "Bahay",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020004",
      "brgy_name": "Balonga-as",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020005",
      "brgy_name": "Balugo",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020007",
      "brgy_name": "Bangon Gote",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020008",
      "brgy_name": "Baras",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020009",
      "brgy_name": "Binalayan",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020010",
      "brgy_name": "Bisitahan",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020011",
      "brgy_name": "Bonga",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020013",
      "brgy_name": "Cabunga-an",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020014",
      "brgy_name": "Cagtutulo",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020018",
      "brgy_name": "Cambatutay Nuevo",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020019",
      "brgy_name": "Cambatutay Viejo",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020020",
      "brgy_name": "Canunghan",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020021",
      "brgy_name": "Catan-agan",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020022",
      "brgy_name": "Dapdap",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020023",
      "brgy_name": "Gallego",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020024",
      "brgy_name": "Imelda Pob. (Posgo)",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020025",
      "brgy_name": "Lucerdoni (Irong-irong)",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020026",
      "brgy_name": "Lahong",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020027",
      "brgy_name": "Libucan Dacu",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020028",
      "brgy_name": "Libucan Gote",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020029",
      "brgy_name": "Majacob",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020030",
      "brgy_name": "Mancares",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020031",
      "brgy_name": "Marabut",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020032",
      "brgy_name": "Oeste - A",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020033",
      "brgy_name": "Oeste - B",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020038",
      "brgy_name": "Pajo",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020039",
      "brgy_name": "Palencia",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020041",
      "brgy_name": "Poblacion A",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020042",
      "brgy_name": "Poblacion B",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020043",
      "brgy_name": "Poblacion C",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020044",
      "brgy_name": "Poblacion D",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020045",
      "brgy_name": "Poblacion E",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020047",
      "brgy_name": "San Vicente",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020048",
      "brgy_name": "Santa Cruz",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020049",
      "brgy_name": "Sugod",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020050",
      "brgy_name": "Talinga",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020051",
      "brgy_name": "Tigdaranao",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086020052",
      "brgy_name": "Tizon",
      "city_code": "086020",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021001",
      "brgy_name": "Banquil",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021002",
      "brgy_name": "Bino-ongan",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021003",
      "brgy_name": "Burabod",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021004",
      "brgy_name": "Cambaguio",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021005",
      "brgy_name": "Canmucat",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021006",
      "brgy_name": "Villarosa Pob. (Campiatot)",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021007",
      "brgy_name": "Conant",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021008",
      "brgy_name": "Guintarcan",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021009",
      "brgy_name": "Himyangan",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021010",
      "brgy_name": "Igot",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021011",
      "brgy_name": "Inarumbacan",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021012",
      "brgy_name": "Inasudlan",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021013",
      "brgy_name": "Lam-awan",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021014",
      "brgy_name": "Lamingao",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021015",
      "brgy_name": "Lawa-an",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021016",
      "brgy_name": "Macopa",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021017",
      "brgy_name": "Mahayag",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021018",
      "brgy_name": "Malonoy",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021019",
      "brgy_name": "Mercado (Pob.)",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021020",
      "brgy_name": "Miramar (Pob.)",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021021",
      "brgy_name": "Nagcaduha",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021022",
      "brgy_name": "Pacao",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021023",
      "brgy_name": "Pacoyoy",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021024",
      "brgy_name": "Pangpang",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021025",
      "brgy_name": "Plaridel",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021026",
      "brgy_name": "Central (Pob.)",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021033",
      "brgy_name": "Polangi",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021034",
      "brgy_name": "San Andres",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021035",
      "brgy_name": "San Fernando",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021036",
      "brgy_name": "San Rafael (Buaya)",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021037",
      "brgy_name": "San Roque",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021038",
      "brgy_name": "Santa Rosa",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021039",
      "brgy_name": "Santo Niño",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021040",
      "brgy_name": "Soledad (Pob.)",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021041",
      "brgy_name": "Tayud (Pob.)",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021042",
      "brgy_name": "Tomabe",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021043",
      "brgy_name": "Ulayan",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086021044",
      "brgy_name": "Patag",
      "city_code": "086021",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022001",
      "brgy_name": "Anagasi",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022002",
      "brgy_name": "Apolonia",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022003",
      "brgy_name": "Bagsa",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022004",
      "brgy_name": "Balbagan",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022005",
      "brgy_name": "Bato",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022006",
      "brgy_name": "Buray (Binogho)",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022008",
      "brgy_name": "Cantaguic",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022009",
      "brgy_name": "Cantao-an",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022010",
      "brgy_name": "Cantato (Canturab)",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022011",
      "brgy_name": "Casandig I",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022012",
      "brgy_name": "Concepcion",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022013",
      "brgy_name": "Jose Roño",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022014",
      "brgy_name": "Cawayan",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022015",
      "brgy_name": "Lawaan I",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022016",
      "brgy_name": "Lipata",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022017",
      "brgy_name": "Lokilokon",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022018",
      "brgy_name": "Mangcal",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022019",
      "brgy_name": "Maylobe",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022020",
      "brgy_name": "Minarog",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022021",
      "brgy_name": "Nawi",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022022",
      "brgy_name": "Pabanog",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022023",
      "brgy_name": "Paco",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022024",
      "brgy_name": "Pagsa-ogan",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022025",
      "brgy_name": "Pagsanjan (Pagsan-an)",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022026",
      "brgy_name": "Patag",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022027",
      "brgy_name": "Pequit",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022028",
      "brgy_name": "Poblacion 1",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022029",
      "brgy_name": "Poblacion 2",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022030",
      "brgy_name": "Poblacion 3",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022031",
      "brgy_name": "Poblacion 4",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022032",
      "brgy_name": "Poblacion 5",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022033",
      "brgy_name": "Poblacion 6",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022038",
      "brgy_name": "Salay",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022039",
      "brgy_name": "San Isidro",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022040",
      "brgy_name": "Santo Niño",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022041",
      "brgy_name": "Sulopan",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022042",
      "brgy_name": "Tabucan",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022043",
      "brgy_name": "Tapul",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022044",
      "brgy_name": "Tenani",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022045",
      "brgy_name": "Tigbawon",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022046",
      "brgy_name": "Tula",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022047",
      "brgy_name": "Tutubigan",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022048",
      "brgy_name": "Casandig II",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086022049",
      "brgy_name": "Lawaan II",
      "city_code": "086022",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023001",
      "brgy_name": "Alegria",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023002",
      "brgy_name": "Arteche",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023003",
      "brgy_name": "Bioso",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023004",
      "brgy_name": "Boblaran",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023005",
      "brgy_name": "Botaera",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023006",
      "brgy_name": "Buntay",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023007",
      "brgy_name": "Camayse",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023008",
      "brgy_name": "Canwarak",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023009",
      "brgy_name": "Ibarra",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023010",
      "brgy_name": "Lumalantang",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023011",
      "brgy_name": "Macalunod",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023012",
      "brgy_name": "Maga-an",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023013",
      "brgy_name": "Maputi",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023014",
      "brgy_name": "Monbon",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023015",
      "brgy_name": "Mualbual",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023016",
      "brgy_name": "Pangdan",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023019",
      "brgy_name": "Poro",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023020",
      "brgy_name": "San Isidro",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023021",
      "brgy_name": "Sugod",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023022",
      "brgy_name": "Tinaugan",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023023",
      "brgy_name": "Tubigan",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023024",
      "brgy_name": "Poblacion 1 (Barangay 1)",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023025",
      "brgy_name": "Poblacion 2 (Barangay 2)",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023026",
      "brgy_name": "Marapilit",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086023027",
      "brgy_name": "Talib",
      "city_code": "086023",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024001",
      "brgy_name": "Baguiw",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024002",
      "brgy_name": "Balocawe",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024003",
      "brgy_name": "Guinbarucan",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024004",
      "brgy_name": "Labangbaybay",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024005",
      "brgy_name": "Luna",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024006",
      "brgy_name": "Mataluto",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024007",
      "brgy_name": "Nipa",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024008",
      "brgy_name": "Pantalan",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024009",
      "brgy_name": "Pulangbato",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024010",
      "brgy_name": "San Vicente",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024011",
      "brgy_name": "Sugod (Pob.)",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024013",
      "brgy_name": "Suarez (Manlangit)",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024014",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086024015",
      "brgy_name": "Trinidad",
      "city_code": "086024",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025001",
      "brgy_name": "Aurora",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025002",
      "brgy_name": "Blanca Aurora",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025003",
      "brgy_name": "Buenavista I",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025004",
      "brgy_name": "Bulao",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025005",
      "brgy_name": "Bungliw",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025006",
      "brgy_name": "Cogtoto-og",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025007",
      "brgy_name": "Calundan",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025008",
      "brgy_name": "Cantaguic",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025009",
      "brgy_name": "Canyaki",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025010",
      "brgy_name": "Erenas",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025011",
      "brgy_name": "Guadalupe",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025012",
      "brgy_name": "Hernandez",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025013",
      "brgy_name": "Himay",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025014",
      "brgy_name": "Janipon",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025015",
      "brgy_name": "La Paz",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025016",
      "brgy_name": "Libertad",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025017",
      "brgy_name": "Lincoro",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025018",
      "brgy_name": "Matalud",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025019",
      "brgy_name": "Mobo-ob",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025020",
      "brgy_name": "Quezon",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025021",
      "brgy_name": "Ranera",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025022",
      "brgy_name": "Rosalim",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025023",
      "brgy_name": "San Isidro",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025024",
      "brgy_name": "San Jorge I (Pob.)",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025025",
      "brgy_name": "Sapinit",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025026",
      "brgy_name": "Sinit-an",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025027",
      "brgy_name": "Tomogbong",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025028",
      "brgy_name": "Gayondato",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025029",
      "brgy_name": "Puhagan",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025030",
      "brgy_name": "Anquiana (Angkiana)",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025031",
      "brgy_name": "Bay-ang",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025032",
      "brgy_name": "Buenavista II",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025033",
      "brgy_name": "Cabugao",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025034",
      "brgy_name": "Cag-olo-olo",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025035",
      "brgy_name": "Guindapunan",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025036",
      "brgy_name": "Mabuhay",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025037",
      "brgy_name": "Mancol (Pob.)",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025038",
      "brgy_name": "Mombon",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025039",
      "brgy_name": "Rawis",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025040",
      "brgy_name": "San Jorge II (Pob.)",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086025041",
      "brgy_name": "San Juan",
      "city_code": "086025",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026001",
      "brgy_name": "Bangon",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026006",
      "brgy_name": "Buenos Aires",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026007",
      "brgy_name": "Calanyugan",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026008",
      "brgy_name": "Caloloma",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026009",
      "brgy_name": "Cambaye",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026010",
      "brgy_name": "Pañge",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026011",
      "brgy_name": "San Luis",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026012",
      "brgy_name": "Villahermosa Occidental",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026013",
      "brgy_name": "Canlapwas (Pob.)",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026014",
      "brgy_name": "Libertad",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026015",
      "brgy_name": "Santo Niño",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026016",
      "brgy_name": "Viejo",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086026017",
      "brgy_name": "Villahermosa Oriental",
      "city_code": "086026",
      "province_code": "0860",
      "region_code": "08"
    },
    {
      "brgy_code": "086401001",
      "brgy_name": "Amagusan",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401002",
      "brgy_name": "Calintaan",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401003",
      "brgy_name": "Canlabian",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401004",
      "brgy_name": "Capacuhan",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401006",
      "brgy_name": "Kagingkingan",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401007",
      "brgy_name": "Lewing",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401008",
      "brgy_name": "Lo-ok",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401009",
      "brgy_name": "Mahalo",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401010",
      "brgy_name": "Mainit",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401011",
      "brgy_name": "Manigawong",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401012",
      "brgy_name": "Poblacion",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401013",
      "brgy_name": "San Vicente",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401014",
      "brgy_name": "Tagup-on",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086401015",
      "brgy_name": "Cogon",
      "city_code": "086401",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402001",
      "brgy_name": "Banahao",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402002",
      "brgy_name": "Baugo",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402003",
      "brgy_name": "Beniton",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402004",
      "brgy_name": "Buenavista",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402005",
      "brgy_name": "Bunga",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402006",
      "brgy_name": "Casao",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402007",
      "brgy_name": "Catmon",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402008",
      "brgy_name": "Catoogan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402009",
      "brgy_name": "Cawayanan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402010",
      "brgy_name": "Dao",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402011",
      "brgy_name": "Divisoria",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402012",
      "brgy_name": "Esperanza",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402013",
      "brgy_name": "Guinsangaan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402014",
      "brgy_name": "Hibagwan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402015",
      "brgy_name": "Hilaan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402016",
      "brgy_name": "Himakilo",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402017",
      "brgy_name": "Hitawos",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402018",
      "brgy_name": "Lanao",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402019",
      "brgy_name": "Lawgawan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402020",
      "brgy_name": "Mahayahay",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402021",
      "brgy_name": "Malbago",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402022",
      "brgy_name": "Mauylab",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402023",
      "brgy_name": "Paku",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402024",
      "brgy_name": "Pamahawan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402025",
      "brgy_name": "Pamigsian",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402026",
      "brgy_name": "Pangi",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402027",
      "brgy_name": "Poblacion",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402028",
      "brgy_name": "Sampongon",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402029",
      "brgy_name": "San Vicente",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402030",
      "brgy_name": "Santa Cruz",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402031",
      "brgy_name": "Taa",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402032",
      "brgy_name": "Talisay",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402033",
      "brgy_name": "Taytagan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402034",
      "brgy_name": "Tuburan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402035",
      "brgy_name": "Union",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402036",
      "brgy_name": "Olisihan",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402037",
      "brgy_name": "Anahao",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402038",
      "brgy_name": "Pong-on",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402039",
      "brgy_name": "San Ramon",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086402040",
      "brgy_name": "Santo Niño",
      "city_code": "086402",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403001",
      "brgy_name": "Ambacon",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403002",
      "brgy_name": "Badiangon",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403003",
      "brgy_name": "Bangcas A",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403004",
      "brgy_name": "Bangcas B",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403005",
      "brgy_name": "Biasong",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403006",
      "brgy_name": "Bugho",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403007",
      "brgy_name": "Calag-itan",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403008",
      "brgy_name": "Calayugan",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403009",
      "brgy_name": "Calinao",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403010",
      "brgy_name": "Canipaan",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403011",
      "brgy_name": "Catublian",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403012",
      "brgy_name": "Ilaya",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403013",
      "brgy_name": "Ingan",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403014",
      "brgy_name": "Labrador",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403015",
      "brgy_name": "Lumbog",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403016",
      "brgy_name": "Manalog",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403017",
      "brgy_name": "Manlico",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403018",
      "brgy_name": "Matin-ao",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403019",
      "brgy_name": "Nava",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403020",
      "brgy_name": "Nueva Esperanza",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403021",
      "brgy_name": "Otama",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403022",
      "brgy_name": "Palongpong",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403023",
      "brgy_name": "Panalaron",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403024",
      "brgy_name": "Patong",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403025",
      "brgy_name": "Poblacion",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403026",
      "brgy_name": "Pondol",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403027",
      "brgy_name": "Salog",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403028",
      "brgy_name": "Salvacion",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403029",
      "brgy_name": "San Pablo Island",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403030",
      "brgy_name": "San Pedro Island",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403031",
      "brgy_name": "Santo Niño I",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403032",
      "brgy_name": "Santo Niño II",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403033",
      "brgy_name": "Tahusan",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403034",
      "brgy_name": "Talisay",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403035",
      "brgy_name": "Tawog",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403036",
      "brgy_name": "Toptop",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403037",
      "brgy_name": "Tuburan",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403038",
      "brgy_name": "Union",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403039",
      "brgy_name": "Upper Bantawon",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086403040",
      "brgy_name": "Libas",
      "city_code": "086403",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404001",
      "brgy_name": "Amaga",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404002",
      "brgy_name": "Ambao",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404003",
      "brgy_name": "An-an",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404004",
      "brgy_name": "Baculod",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404005",
      "brgy_name": "Biasong",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404006",
      "brgy_name": "Bugho",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404007",
      "brgy_name": "Cabulisan",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404008",
      "brgy_name": "District I (Pob.)",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404009",
      "brgy_name": "District II (Pob.)",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404010",
      "brgy_name": "District III (Pob.)",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404011",
      "brgy_name": "Hubasan",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404012",
      "brgy_name": "Cat-iwing",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404013",
      "brgy_name": "Lungsodaan",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404014",
      "brgy_name": "Navalita",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404015",
      "brgy_name": "Plaridel",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404016",
      "brgy_name": "Sabang",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086404017",
      "brgy_name": "Sagbok",
      "city_code": "086404",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405001",
      "brgy_name": "Biasong",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405002",
      "brgy_name": "Bogasong",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405003",
      "brgy_name": "Cawayan",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405004",
      "brgy_name": "Gakat",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405005",
      "brgy_name": "Jubas (Pob.)",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405006",
      "brgy_name": "Magkasag",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405007",
      "brgy_name": "Mayuga",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405008",
      "brgy_name": "Nahaong",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405009",
      "brgy_name": "Nahulid",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405010",
      "brgy_name": "Otikon",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405011",
      "brgy_name": "Pangi",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405012",
      "brgy_name": "Punta",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405013",
      "brgy_name": "Talisay (Pob.)",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086405014",
      "brgy_name": "Tigbao",
      "city_code": "086405",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406001",
      "brgy_name": "Amaga",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406002",
      "brgy_name": "Anilao",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406003",
      "brgy_name": "Bahay",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406004",
      "brgy_name": "Cagbungalon",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406005",
      "brgy_name": "Calian",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406006",
      "brgy_name": "Caligangan",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406007",
      "brgy_name": "Candayuman",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406008",
      "brgy_name": "Estela",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406009",
      "brgy_name": "Gud-an",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406010",
      "brgy_name": "Guintoylan",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406011",
      "brgy_name": "Himayangan",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406012",
      "brgy_name": "Ilag",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406013",
      "brgy_name": "Magaupas",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406014",
      "brgy_name": "Malangsa",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406015",
      "brgy_name": "Pres. Quezon (Maugoc)",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406016",
      "brgy_name": "Molopolo",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406017",
      "brgy_name": "Pandan",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406018",
      "brgy_name": "Poblacion",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406019",
      "brgy_name": "President Roxas",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406020",
      "brgy_name": "San Isidro",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406021",
      "brgy_name": "San Roque",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406022",
      "brgy_name": "Tabugon",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406023",
      "brgy_name": "Catig",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086406024",
      "brgy_name": "Fatima",
      "city_code": "086406",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407001",
      "brgy_name": "Abgao (Pob.)",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407002",
      "brgy_name": "Asuncion",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407003",
      "brgy_name": "Bactul II",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407004",
      "brgy_name": "Bactul I",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407005",
      "brgy_name": "Badiang",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407006",
      "brgy_name": "Bagtican",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407007",
      "brgy_name": "Basak",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407008",
      "brgy_name": "Bato II",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407009",
      "brgy_name": "Bato I",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407010",
      "brgy_name": "Batuan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407011",
      "brgy_name": "Baugo",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407012",
      "brgy_name": "Bilibol",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407013",
      "brgy_name": "Bogo",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407014",
      "brgy_name": "Cabadiangan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407015",
      "brgy_name": "Cabulihan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407016",
      "brgy_name": "Cagnituan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407017",
      "brgy_name": "Cambooc",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407018",
      "brgy_name": "Cansirong",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407019",
      "brgy_name": "Canturing",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407020",
      "brgy_name": "Canyuom",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407021",
      "brgy_name": "Dongon",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407022",
      "brgy_name": "Gawisan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407023",
      "brgy_name": "Guadalupe",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407024",
      "brgy_name": "Hanginan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407025",
      "brgy_name": "Hantag",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407026",
      "brgy_name": "Hinapu Daku",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407027",
      "brgy_name": "Hinapu Gamay",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407028",
      "brgy_name": "Ibarra",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407029",
      "brgy_name": "Isagani",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407030",
      "brgy_name": "Laboon",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407031",
      "brgy_name": "Lanao",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407032",
      "brgy_name": "Libhu",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407033",
      "brgy_name": "Lonoy",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407034",
      "brgy_name": "Lunas",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407035",
      "brgy_name": "Mahayahay",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407036",
      "brgy_name": "Malapoc Norte",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407037",
      "brgy_name": "Malapoc Sur",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407038",
      "brgy_name": "Mambajao (Pob.)",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407039",
      "brgy_name": "Manhilo",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407040",
      "brgy_name": "Mantahan (Pob.)",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407041",
      "brgy_name": "Maria Clara",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407042",
      "brgy_name": "Matin-ao",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407043",
      "brgy_name": "Nasaug",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407044",
      "brgy_name": "Nati",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407045",
      "brgy_name": "Nonok Norte",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407046",
      "brgy_name": "Nonok Sur",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407047",
      "brgy_name": "Panan-awan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407048",
      "brgy_name": "Pansaan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407049",
      "brgy_name": "Pinascohan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407050",
      "brgy_name": "Rizal",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407051",
      "brgy_name": "San Isidro",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407052",
      "brgy_name": "San Jose",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407053",
      "brgy_name": "San Rafael",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407054",
      "brgy_name": "Santa Cruz",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407055",
      "brgy_name": "Santa Rosa",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407056",
      "brgy_name": "Santo Niño",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407057",
      "brgy_name": "Santo Rosario",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407058",
      "brgy_name": "Soro-soro",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407059",
      "brgy_name": "Tagnipa (Pob.)",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407060",
      "brgy_name": "Tam-is",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407061",
      "brgy_name": "Tawid",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407062",
      "brgy_name": "Tigbawan",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407063",
      "brgy_name": "Tomoy-tomoy",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407064",
      "brgy_name": "Tunga-tunga (Pob.)",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407065",
      "brgy_name": "Acasia",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407066",
      "brgy_name": "Combado",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407067",
      "brgy_name": "Libertad",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407068",
      "brgy_name": "Lib-og",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407069",
      "brgy_name": "Pasay",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086407070",
      "brgy_name": "San Agustin",
      "city_code": "086407",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408001",
      "brgy_name": "Aguinaldo",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408002",
      "brgy_name": "Amparo",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408003",
      "brgy_name": "Buscayan",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408004",
      "brgy_name": "Cambaro",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408005",
      "brgy_name": "Canlusay",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408006",
      "brgy_name": "Flordeliz",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408007",
      "brgy_name": "Ichon",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408008",
      "brgy_name": "Ilihan",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408009",
      "brgy_name": "Laray",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408010",
      "brgy_name": "Lower Villa Jacinta",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408011",
      "brgy_name": "Mabini",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408012",
      "brgy_name": "Mohon",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408013",
      "brgy_name": "Molopolo",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408015",
      "brgy_name": "Rizal",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408016",
      "brgy_name": "Salvador (Mangyang)",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408017",
      "brgy_name": "San Isidro",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408018",
      "brgy_name": "San Joaquin",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408019",
      "brgy_name": "San Roque",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408021",
      "brgy_name": "Sindangan",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408022",
      "brgy_name": "Upper Villa Jacinta",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408023",
      "brgy_name": "Asuncion",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408024",
      "brgy_name": "Bagong Silang",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408025",
      "brgy_name": "Danao",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408026",
      "brgy_name": "Guadalupe",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408027",
      "brgy_name": "San Vicente Poblacion",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408028",
      "brgy_name": "Santo Niño",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408029",
      "brgy_name": "San Vicente (Upper San Roque)",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408030",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408031",
      "brgy_name": "Santo Rosario (Pob.)",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086408032",
      "brgy_name": "Upper Ichon",
      "city_code": "086408",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409001",
      "brgy_name": "Abgao",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409002",
      "brgy_name": "Aurora",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409003",
      "brgy_name": "Benit",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409004",
      "brgy_name": "Caaga",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409005",
      "brgy_name": "Cabul-anonan (Pob.)",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409006",
      "brgy_name": "Cadaruhan",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409007",
      "brgy_name": "Candatag",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409008",
      "brgy_name": "Cantamuac",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409009",
      "brgy_name": "Caraatan",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409010",
      "brgy_name": "Concepcion",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409011",
      "brgy_name": "Guinabonan",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409012",
      "brgy_name": "Iba",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409013",
      "brgy_name": "Lambonao",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409014",
      "brgy_name": "Maningning",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409015",
      "brgy_name": "Maujo",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409016",
      "brgy_name": "Pasil (Pob.)",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409017",
      "brgy_name": "Sabang",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409018",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409019",
      "brgy_name": "San Jose",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409020",
      "brgy_name": "San Roque",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409021",
      "brgy_name": "San Vicente",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409022",
      "brgy_name": "Sangahon",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409023",
      "brgy_name": "Santa Cruz",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409024",
      "brgy_name": "Taliwa (Pob.)",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409025",
      "brgy_name": "Tigbawan I",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409026",
      "brgy_name": "Tigbawan II",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409028",
      "brgy_name": "Timba",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409029",
      "brgy_name": "Asuncion",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409030",
      "brgy_name": "Cadaruhan Sur",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409031",
      "brgy_name": "Fatima",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409032",
      "brgy_name": "Juangon",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409033",
      "brgy_name": "Kauswagan",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409034",
      "brgy_name": "Mahayahay",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409035",
      "brgy_name": "New Katipunan",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409036",
      "brgy_name": "Pancil",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409037",
      "brgy_name": "San Isidro",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086409038",
      "brgy_name": "Santo Niño",
      "city_code": "086409",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410001",
      "brgy_name": "Buenavista",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410002",
      "brgy_name": "Bunga",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410003",
      "brgy_name": "Laca",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410005",
      "brgy_name": "Lungsodaan",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410007",
      "brgy_name": "Poblacion",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410010",
      "brgy_name": "San Juan",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410011",
      "brgy_name": "Santa Sofia",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410012",
      "brgy_name": "Santo Rosario",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410014",
      "brgy_name": "Cantutang",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410015",
      "brgy_name": "Dinahugan",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086410016",
      "brgy_name": "Tangkaan",
      "city_code": "086410",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411001",
      "brgy_name": "Badiang",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411002",
      "brgy_name": "Balongbalong",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411003",
      "brgy_name": "Buenavista",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411004",
      "brgy_name": "Bulawan",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411005",
      "brgy_name": "Canlawis",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411006",
      "brgy_name": "Catbawan",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411007",
      "brgy_name": "Caubang",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411008",
      "brgy_name": "Cogon",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411009",
      "brgy_name": "Dan-an",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411011",
      "brgy_name": "Lobo",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411012",
      "brgy_name": "Mainit",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411013",
      "brgy_name": "Manglit",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411014",
      "brgy_name": "Nueva Estrella Sur",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411015",
      "brgy_name": "Poblacion Ibabao",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411016",
      "brgy_name": "Poblacion Ubos",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411017",
      "brgy_name": "Ponod",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411019",
      "brgy_name": "Son-ok I",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411020",
      "brgy_name": "Tautag",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411021",
      "brgy_name": "Nueva Estrella Norte",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411023",
      "brgy_name": "Pociano D. Equipilag",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411024",
      "brgy_name": "San Roque",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411025",
      "brgy_name": "Santa Cruz",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086411026",
      "brgy_name": "Son-ok II",
      "city_code": "086411",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412001",
      "brgy_name": "Atuyan",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412002",
      "brgy_name": "Ayahag",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412003",
      "brgy_name": "Bantawon",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412004",
      "brgy_name": "Bolodbolod",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412005",
      "brgy_name": "Nueva Esperanza (Cabac-an)",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412006",
      "brgy_name": "Cabagawan",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412007",
      "brgy_name": "Carnaga",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412008",
      "brgy_name": "Catmon",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412009",
      "brgy_name": "Guinsaugon",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412010",
      "brgy_name": "Himatagon (Pob.)",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412011",
      "brgy_name": "Himbangan",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412012",
      "brgy_name": "Himos-onan",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412013",
      "brgy_name": "Hindag-an",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412014",
      "brgy_name": "Kauswagan",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412015",
      "brgy_name": "Libas",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412016",
      "brgy_name": "Lipanto",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412017",
      "brgy_name": "Magatas",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412018",
      "brgy_name": "Magbagacay",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412019",
      "brgy_name": "Mahayag",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412020",
      "brgy_name": "Mahayahay",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412021",
      "brgy_name": "Malibago",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412022",
      "brgy_name": "Malinao",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412023",
      "brgy_name": "Panian",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412024",
      "brgy_name": "San Isidro",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412025",
      "brgy_name": "Santa Cruz",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412026",
      "brgy_name": "Sug-angon",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412027",
      "brgy_name": "Tabontabon",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412028",
      "brgy_name": "Tambis I",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412029",
      "brgy_name": "Tambis II",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086412030",
      "brgy_name": "Hinabian",
      "city_code": "086412",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413001",
      "brgy_name": "Anislagon",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413002",
      "brgy_name": "Bongbong",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413003",
      "brgy_name": "Central (Pob.)",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413004",
      "brgy_name": "Dakit (Pob.)",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413005",
      "brgy_name": "Habay",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413006",
      "brgy_name": "Marayag",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413007",
      "brgy_name": "Napantao",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413008",
      "brgy_name": "Pinamudlan",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413009",
      "brgy_name": "Santa Paz Norte",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413010",
      "brgy_name": "Santa Paz Sur",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413011",
      "brgy_name": "Sudmon",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413012",
      "brgy_name": "Tinaan",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413013",
      "brgy_name": "Tuno",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413014",
      "brgy_name": "Ubos (Pob.)",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413015",
      "brgy_name": "Bongawisan",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413016",
      "brgy_name": "Causi",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413017",
      "brgy_name": "Gabi",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413018",
      "brgy_name": "Cahayag",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413019",
      "brgy_name": "Malico",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413020",
      "brgy_name": "Pasanon",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413021",
      "brgy_name": "Punta",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086413022",
      "brgy_name": "Santa Cruz",
      "city_code": "086413",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414001",
      "brgy_name": "Agay-ay",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414002",
      "brgy_name": "Basak",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414003",
      "brgy_name": "Bobon A",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414004",
      "brgy_name": "Dayanog",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414005",
      "brgy_name": "Santa Filomena",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414006",
      "brgy_name": "Garrido",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414007",
      "brgy_name": "Minoyho",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414008",
      "brgy_name": "Pong-oy",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414009",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414010",
      "brgy_name": "San Vicente",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414011",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414012",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414013",
      "brgy_name": "Somoje",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414014",
      "brgy_name": "Sua",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414015",
      "brgy_name": "Timba",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414016",
      "brgy_name": "Osao",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414017",
      "brgy_name": "San Roque",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086414018",
      "brgy_name": "Bobon B",
      "city_code": "086414",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415001",
      "brgy_name": "Benit",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415002",
      "brgy_name": "Bitoon",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415003",
      "brgy_name": "Cabutan",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415004",
      "brgy_name": "Camang",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415005",
      "brgy_name": "Esperanza",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415007",
      "brgy_name": "Pinut-an",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415008",
      "brgy_name": "Poblacion",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415009",
      "brgy_name": "San Antonio (Alangalang)",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415010",
      "brgy_name": "San Ramon",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415011",
      "brgy_name": "Saub",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415012",
      "brgy_name": "Timba",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415013",
      "brgy_name": "Esperanza Dos",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415014",
      "brgy_name": "Kinachawa",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415015",
      "brgy_name": "Inolinan",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086415016",
      "brgy_name": "Looc",
      "city_code": "086415",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416002",
      "brgy_name": "Balagawan",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416003",
      "brgy_name": "Catmon",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416004",
      "brgy_name": "Pob. District I",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416005",
      "brgy_name": "Pob. District II",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416006",
      "brgy_name": "Hingatungan",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416007",
      "brgy_name": "Katipunan",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416008",
      "brgy_name": "Laguma",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416009",
      "brgy_name": "Mercedes",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416010",
      "brgy_name": "Puntana",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416011",
      "brgy_name": "Salvacion",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416012",
      "brgy_name": "Sap-ang",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416013",
      "brgy_name": "Sudmon",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416014",
      "brgy_name": "Tuba-on",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416015",
      "brgy_name": "Tubod",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086416016",
      "brgy_name": "Imelda",
      "city_code": "086416",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417001",
      "brgy_name": "Benit",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417002",
      "brgy_name": "Buac Daku",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417003",
      "brgy_name": "Buac Gamay",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417004",
      "brgy_name": "Cabadbaran",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417005",
      "brgy_name": "Concepcion",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417006",
      "brgy_name": "Consolacion",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417007",
      "brgy_name": "Dagsa",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417008",
      "brgy_name": "Hibod-hibod",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417009",
      "brgy_name": "Hindangan",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417010",
      "brgy_name": "Hipantag",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417011",
      "brgy_name": "Javier",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417012",
      "brgy_name": "Kahupian",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417013",
      "brgy_name": "Kanangkaan",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417014",
      "brgy_name": "Kauswagan",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417015",
      "brgy_name": "La Purisima Concepcion",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417016",
      "brgy_name": "Libas",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417017",
      "brgy_name": "Lum-an",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417018",
      "brgy_name": "Mabicay",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417019",
      "brgy_name": "Mac",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417020",
      "brgy_name": "Magatas",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417021",
      "brgy_name": "Mahayahay",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417022",
      "brgy_name": "Malinao",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417023",
      "brgy_name": "Maria Plana",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417024",
      "brgy_name": "Milagroso",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417025",
      "brgy_name": "Pancho Villa",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417026",
      "brgy_name": "Pandan",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417027",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417028",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417029",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417030",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417031",
      "brgy_name": "Zone V (Pob.)",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417032",
      "brgy_name": "Rizal",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417033",
      "brgy_name": "Salvacion",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417034",
      "brgy_name": "San Francisco Mabuhay",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417035",
      "brgy_name": "San Isidro",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417036",
      "brgy_name": "San Jose",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417037",
      "brgy_name": "San Juan (Agata)",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417038",
      "brgy_name": "San Miguel",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417039",
      "brgy_name": "San Pedro",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417040",
      "brgy_name": "San Roque",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417041",
      "brgy_name": "San Vicente",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417042",
      "brgy_name": "Santa Maria",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417043",
      "brgy_name": "Suba",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417044",
      "brgy_name": "Tampoong",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086417045",
      "brgy_name": "Olisihan",
      "city_code": "086417",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418001",
      "brgy_name": "Anahawan",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418002",
      "brgy_name": "Banday (Pob.)",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418003",
      "brgy_name": "Bogo (Pob.)",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418004",
      "brgy_name": "Cabascan",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418005",
      "brgy_name": "Camansi",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418006",
      "brgy_name": "Cambite (Pob.)",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418007",
      "brgy_name": "Canlupao",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418008",
      "brgy_name": "Carnaga",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418009",
      "brgy_name": "Cawayan",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418010",
      "brgy_name": "Hinagtikan",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418011",
      "brgy_name": "Hinapo",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418012",
      "brgy_name": "Hugpa",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418013",
      "brgy_name": "Iniguihan Pob. (Salvacion)",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418014",
      "brgy_name": "Looc",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418015",
      "brgy_name": "Maanyag",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418016",
      "brgy_name": "Maslog",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418017",
      "brgy_name": "Ponong",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418018",
      "brgy_name": "Rizal",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418019",
      "brgy_name": "San Isidro",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418020",
      "brgy_name": "San Miguel",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418021",
      "brgy_name": "Tinago",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418022",
      "brgy_name": "Biasong",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418023",
      "brgy_name": "Higosoan",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418024",
      "brgy_name": "Mag-ata",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418025",
      "brgy_name": "San Antonio",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418026",
      "brgy_name": "San Roque",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418027",
      "brgy_name": "Luan",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418028",
      "brgy_name": "Mapgap",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086418029",
      "brgy_name": "San Agustin",
      "city_code": "086418",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086419001",
      "brgy_name": "Cabulihan",
      "city_code": "086419",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086419002",
      "brgy_name": "Lugsongan",
      "city_code": "086419",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086419003",
      "brgy_name": "Magallanes",
      "city_code": "086419",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086419004",
      "brgy_name": "San Agustin",
      "city_code": "086419",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086419005",
      "brgy_name": "San Bernardo",
      "city_code": "086419",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "086419006",
      "brgy_name": "Triana",
      "city_code": "086419",
      "province_code": "0864",
      "region_code": "08"
    },
    {
      "brgy_code": "087801001",
      "brgy_name": "Caucab",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801002",
      "brgy_name": "Iyosan",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801003",
      "brgy_name": "Jamorawon",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801004",
      "brgy_name": "Lo-ok",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801005",
      "brgy_name": "Matanga",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801006",
      "brgy_name": "Pili",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801007",
      "brgy_name": "Poblacion",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801008",
      "brgy_name": "Pulang Bato",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801009",
      "brgy_name": "Salangi",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801010",
      "brgy_name": "Sampao",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801011",
      "brgy_name": "Tabunan",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801012",
      "brgy_name": "Talahid",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087801013",
      "brgy_name": "Tamarindo",
      "city_code": "087801",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802001",
      "brgy_name": "Bato",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802002",
      "brgy_name": "Burabod",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802003",
      "brgy_name": "Busali",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802004",
      "brgy_name": "Hugpa",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802005",
      "brgy_name": "Julita",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802006",
      "brgy_name": "Canila",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802007",
      "brgy_name": "Pinangumhan",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802008",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802009",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802010",
      "brgy_name": "Sanggalang",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087802011",
      "brgy_name": "Villa Enage (Baras)",
      "city_code": "087802",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803001",
      "brgy_name": "Balaquid",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803002",
      "brgy_name": "Baso",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803003",
      "brgy_name": "Bunga",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803004",
      "brgy_name": "Caanibongan",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803005",
      "brgy_name": "Casiawan",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803007",
      "brgy_name": "Esperanza (Pob.)",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803008",
      "brgy_name": "Langgao",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803009",
      "brgy_name": "Libertad",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803010",
      "brgy_name": "Looc",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803011",
      "brgy_name": "Magbangon (Pob.)",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803012",
      "brgy_name": "Pawikan",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803013",
      "brgy_name": "Salawad",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087803014",
      "brgy_name": "Talibong",
      "city_code": "087803",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804001",
      "brgy_name": "Alegria",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804002",
      "brgy_name": "Asug",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804003",
      "brgy_name": "Bari-is",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804004",
      "brgy_name": "Binohangan",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804005",
      "brgy_name": "Cabibihan",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804006",
      "brgy_name": "Kawayanon",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804007",
      "brgy_name": "Looc",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804009",
      "brgy_name": "Manlabang",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804010",
      "brgy_name": "Caulangohan (Marevil)",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804011",
      "brgy_name": "Maurang",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804012",
      "brgy_name": "Palanay (Pob.)",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804013",
      "brgy_name": "Palengke (Pob.)",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804014",
      "brgy_name": "Tomalistis",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804015",
      "brgy_name": "Union",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804016",
      "brgy_name": "Uson",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804017",
      "brgy_name": "Victory (Pob.)",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087804018",
      "brgy_name": "Villa Vicenta (Mainit)",
      "city_code": "087804",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805001",
      "brgy_name": "Acaban",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805002",
      "brgy_name": "Bacolod",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805003",
      "brgy_name": "Binongtoan",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805004",
      "brgy_name": "Bool Central (Pob.)",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805005",
      "brgy_name": "Bool East (Pob.)",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805006",
      "brgy_name": "Bool West (Pob.)",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805007",
      "brgy_name": "Calipayan",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805008",
      "brgy_name": "Guindapunan",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805009",
      "brgy_name": "Habuhab",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805010",
      "brgy_name": "Looc",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805011",
      "brgy_name": "Marvel (Pob.)",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805012",
      "brgy_name": "Patag",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805013",
      "brgy_name": "Pinamihagan",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805014",
      "brgy_name": "Culaba Central (Pob.)",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805015",
      "brgy_name": "Salvacion",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805016",
      "brgy_name": "San Roque",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087805017",
      "brgy_name": "Virginia (Pob.)",
      "city_code": "087805",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806001",
      "brgy_name": "Baganito",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806002",
      "brgy_name": "Balacson",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806003",
      "brgy_name": "Bilwang",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806004",
      "brgy_name": "Bulalacao",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806005",
      "brgy_name": "Burabod",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806006",
      "brgy_name": "Inasuyan",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806007",
      "brgy_name": "Kansanok",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806008",
      "brgy_name": "Mada-o",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806009",
      "brgy_name": "Mapuyo",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806010",
      "brgy_name": "Masagaosao",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806011",
      "brgy_name": "Masagongsong",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806012",
      "brgy_name": "Poblacion",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806013",
      "brgy_name": "Tabunan North",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806014",
      "brgy_name": "Tubig Guinoo",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806015",
      "brgy_name": "Tucdao",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806016",
      "brgy_name": "Ungale",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806017",
      "brgy_name": "Balite",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806018",
      "brgy_name": "Buyo",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806019",
      "brgy_name": "Villa Cornejo (Looc)",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087806020",
      "brgy_name": "San Lorenzo",
      "city_code": "087806",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807001",
      "brgy_name": "Agutay",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807002",
      "brgy_name": "Banlas",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807003",
      "brgy_name": "Bato",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807004",
      "brgy_name": "Binalayan West",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807005",
      "brgy_name": "Binalayan East",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807008",
      "brgy_name": "Burabod",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807009",
      "brgy_name": "Calbani",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807010",
      "brgy_name": "Canduhao",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807011",
      "brgy_name": "Casibang",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807012",
      "brgy_name": "Danao",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807014",
      "brgy_name": "Ol-og",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807015",
      "brgy_name": "Binongto-an (Poblacion Norte)",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807016",
      "brgy_name": "Ermita (Poblacion Sur)",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807017",
      "brgy_name": "Trabugan",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087807018",
      "brgy_name": "Viga",
      "city_code": "087807",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808001",
      "brgy_name": "Agpangi",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808002",
      "brgy_name": "Anislagan",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808003",
      "brgy_name": "Atipolo",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808004",
      "brgy_name": "Calumpang",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808005",
      "brgy_name": "Capiñahan",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808006",
      "brgy_name": "Caraycaray",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808007",
      "brgy_name": "Catmon",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808008",
      "brgy_name": "Haguikhikan",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808009",
      "brgy_name": "Padre Inocentes Garcia (Pob.)",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808010",
      "brgy_name": "Libertad",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808012",
      "brgy_name": "Lico",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808013",
      "brgy_name": "Lucsoon",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808014",
      "brgy_name": "Mabini",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808015",
      "brgy_name": "San Pablo",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808016",
      "brgy_name": "Santo Niño",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808017",
      "brgy_name": "Santissimo Rosario Pob. (Santo Rosa)",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808018",
      "brgy_name": "Talustusan",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808019",
      "brgy_name": "Villa Caneja",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808020",
      "brgy_name": "Villa Consuelo",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808021",
      "brgy_name": "Borac",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808022",
      "brgy_name": "Cabungaan",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808023",
      "brgy_name": "Imelda",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808024",
      "brgy_name": "Larrazabal",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808025",
      "brgy_name": "Libtong",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808026",
      "brgy_name": "Padre Sergio Eamiguel",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "087808027",
      "brgy_name": "Sabang",
      "city_code": "087808",
      "province_code": "0878",
      "region_code": "08"
    },
    {
      "brgy_code": "097201001",
      "brgy_name": "Aliguay",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201002",
      "brgy_name": "Antipolo",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201003",
      "brgy_name": "Aseniero",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201004",
      "brgy_name": "Ba-ao",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201005",
      "brgy_name": "Banbanan",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201006",
      "brgy_name": "Barcelona",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201007",
      "brgy_name": "Baylimango",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201009",
      "brgy_name": "Burgos",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201010",
      "brgy_name": "Canlucani",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201011",
      "brgy_name": "Carang",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201012",
      "brgy_name": "Dampalan",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201013",
      "brgy_name": "Daro",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201014",
      "brgy_name": "Diwa-an",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201016",
      "brgy_name": "Guimputlan",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201017",
      "brgy_name": "Hilltop",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201018",
      "brgy_name": "Ilaya",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201019",
      "brgy_name": "Larayan",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201020",
      "brgy_name": "Liyang",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201021",
      "brgy_name": "Maria Cristina",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201022",
      "brgy_name": "Maria Uray",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201023",
      "brgy_name": "Masidlakon",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201024",
      "brgy_name": "Napo",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201025",
      "brgy_name": "Opao",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201026",
      "brgy_name": "Oro",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201027",
      "brgy_name": "Owaon",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201028",
      "brgy_name": "Oyan",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201031",
      "brgy_name": "Polo",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201032",
      "brgy_name": "Potungan",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201033",
      "brgy_name": "San Francisco",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201034",
      "brgy_name": "San Nicolas",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201035",
      "brgy_name": "San Pedro",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201036",
      "brgy_name": "San Vicente",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201038",
      "brgy_name": "Sicayab Bocana",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201039",
      "brgy_name": "Sigayan",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201040",
      "brgy_name": "Silinog",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201041",
      "brgy_name": "Sinonoc",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201042",
      "brgy_name": "Santo Niño",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201043",
      "brgy_name": "Sulangon",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201044",
      "brgy_name": "Tag-olo",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201045",
      "brgy_name": "Taguilon",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201046",
      "brgy_name": "Kauswagan (Talisay)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201047",
      "brgy_name": "Tamion",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201048",
      "brgy_name": "Bagting (Pob.)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201049",
      "brgy_name": "Banonong (Pob.)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201050",
      "brgy_name": "Cawa-cawa (Pob.)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201051",
      "brgy_name": "Dawo (Pob.)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201052",
      "brgy_name": "Matagobtob Pob. (Talisay)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201053",
      "brgy_name": "Linabo (Pob.)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201054",
      "brgy_name": "Potol (Pob.)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097201055",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "097201",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202001",
      "brgy_name": "Cogon",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202002",
      "brgy_name": "Dicayas",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202003",
      "brgy_name": "Diwan",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202004",
      "brgy_name": "Galas",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202005",
      "brgy_name": "Gulayon",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202006",
      "brgy_name": "Lugdungan",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202007",
      "brgy_name": "Minaog",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202008",
      "brgy_name": "Olingan",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202009",
      "brgy_name": "Estaca (Pob.)",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202010",
      "brgy_name": "Biasong (Pob.)",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202011",
      "brgy_name": "Barra (Pob.)",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202012",
      "brgy_name": "Central (Pob.)",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202013",
      "brgy_name": "Miputak (Pob.)",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202014",
      "brgy_name": "Punta",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202015",
      "brgy_name": "San Jose",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202016",
      "brgy_name": "Sangkol",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202017",
      "brgy_name": "Santa Filomena",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202018",
      "brgy_name": "Sicayab",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202019",
      "brgy_name": "Sinaman",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202020",
      "brgy_name": "Turno",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097202021",
      "brgy_name": "Santa Isabel",
      "city_code": "097202",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203001",
      "brgy_name": "Balok",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203002",
      "brgy_name": "Basagan",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203003",
      "brgy_name": "Biniray",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203004",
      "brgy_name": "Bulawan",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203005",
      "brgy_name": "Daanglungsod",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203006",
      "brgy_name": "Dabiak",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203007",
      "brgy_name": "Dr. Jose Rizal (Lower Mias)",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203008",
      "brgy_name": "Fimagas",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203009",
      "brgy_name": "Malugas",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203010",
      "brgy_name": "Malasay",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203011",
      "brgy_name": "Matam",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203012",
      "brgy_name": "Mias",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203013",
      "brgy_name": "Miatan",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203014",
      "brgy_name": "Nanginan",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203016",
      "brgy_name": "Barangay Uno (Pob.)",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203017",
      "brgy_name": "Barangay Dos (Pob.)",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203018",
      "brgy_name": "San Antonio (Looy)",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203019",
      "brgy_name": "Seres",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203020",
      "brgy_name": "Seroan",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203021",
      "brgy_name": "Singatong",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203022",
      "brgy_name": "Sinuyak",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203023",
      "brgy_name": "Sitog",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203024",
      "brgy_name": "Tuburan",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203025",
      "brgy_name": "Carupay",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203026",
      "brgy_name": "Loyuran",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203027",
      "brgy_name": "New Tambo",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203028",
      "brgy_name": "Patik",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203029",
      "brgy_name": "Sanao",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203030",
      "brgy_name": "San Vicente",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097203031",
      "brgy_name": "Santo Niño",
      "city_code": "097203",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204001",
      "brgy_name": "El Paraiso",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204002",
      "brgy_name": "La Union",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204003",
      "brgy_name": "La Victoria",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204004",
      "brgy_name": "Mauswagon",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204005",
      "brgy_name": "Mercedes",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204006",
      "brgy_name": "New Argao",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204007",
      "brgy_name": "New Bataan",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204008",
      "brgy_name": "New Carcar",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204009",
      "brgy_name": "Poblacion",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204010",
      "brgy_name": "San Jose",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204011",
      "brgy_name": "Santa Catalina",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204012",
      "brgy_name": "Santa Cruz",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097204013",
      "brgy_name": "Singaran",
      "city_code": "097204",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205001",
      "brgy_name": "Antonino (Pob.)",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205002",
      "brgy_name": "Balas",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205005",
      "brgy_name": "Bobongan",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205009",
      "brgy_name": "Dansalan",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205011",
      "brgy_name": "Gabu",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205013",
      "brgy_name": "Immaculada",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205015",
      "brgy_name": "Kipit",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205017",
      "brgy_name": "La Union",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205019",
      "brgy_name": "Lapatan",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205020",
      "brgy_name": "Lawagan",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205021",
      "brgy_name": "Lawigan",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205022",
      "brgy_name": "Lopoc (Pob.)",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205024",
      "brgy_name": "Malintuboan",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205026",
      "brgy_name": "New Salvacion",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205027",
      "brgy_name": "Osukan",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205030",
      "brgy_name": "Patawag",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205033",
      "brgy_name": "San Isidro",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205035",
      "brgy_name": "Ubay",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205036",
      "brgy_name": "Gil Sanchez",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097205037",
      "brgy_name": "Imelda",
      "city_code": "097205",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206001",
      "brgy_name": "Banigan",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206002",
      "brgy_name": "Baybay (Pob.)",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206003",
      "brgy_name": "Cabangcalan",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206006",
      "brgy_name": "Candelaria",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206007",
      "brgy_name": "Causwagan",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206008",
      "brgy_name": "Communal",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206009",
      "brgy_name": "Compra",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206010",
      "brgy_name": "Fatima",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206012",
      "brgy_name": "Goaw",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206013",
      "brgy_name": "Goin",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206014",
      "brgy_name": "Kayok",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206015",
      "brgy_name": "Lamao",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206017",
      "brgy_name": "La Libertad (Mawal)",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206020",
      "brgy_name": "Panabang",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206021",
      "brgy_name": "Patawag",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206022",
      "brgy_name": "Punta",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206023",
      "brgy_name": "San Isidro",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206024",
      "brgy_name": "San Francisco",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206025",
      "brgy_name": "San Miguel",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206026",
      "brgy_name": "Santa Cruz",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206027",
      "brgy_name": "Santo Niño",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206028",
      "brgy_name": "Silucap",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206031",
      "brgy_name": "Tapican",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206032",
      "brgy_name": "Timan",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206033",
      "brgy_name": "Villa M. Tejero",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206034",
      "brgy_name": "Dela Paz",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206035",
      "brgy_name": "El Paraiso",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206036",
      "brgy_name": "Ganase",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206037",
      "brgy_name": "Mabuhay",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206038",
      "brgy_name": "Maigang",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206039",
      "brgy_name": "Malila",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206040",
      "brgy_name": "Mauswagon",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206041",
      "brgy_name": "New Bethlehem",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206042",
      "brgy_name": "Overview",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206043",
      "brgy_name": "San Roque",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206044",
      "brgy_name": "Villa Calixto Sudiacal",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097206045",
      "brgy_name": "Canaan",
      "city_code": "097206",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207002",
      "brgy_name": "Dipane",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207003",
      "brgy_name": "Disakan",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207004",
      "brgy_name": "Gupot",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207005",
      "brgy_name": "Libuton",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207006",
      "brgy_name": "Linay",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207007",
      "brgy_name": "Lupasang",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207010",
      "brgy_name": "Mate",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207011",
      "brgy_name": "Poblacion",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207013",
      "brgy_name": "Saluyong",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207014",
      "brgy_name": "Serongan",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207018",
      "brgy_name": "Villaramos",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207019",
      "brgy_name": "Don Jose Aguirre",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207020",
      "brgy_name": "Lingatongan",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207021",
      "brgy_name": "Meses",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207022",
      "brgy_name": "Palaranan",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207023",
      "brgy_name": "Pangandao",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207024",
      "brgy_name": "Patagan",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207025",
      "brgy_name": "San Antonio",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207026",
      "brgy_name": "Upper Disakan",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207027",
      "brgy_name": "East Poblacion",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207028",
      "brgy_name": "Punta Blanca",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097207029",
      "brgy_name": "Suisayan",
      "city_code": "097207",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208001",
      "brgy_name": "Alvenda",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208002",
      "brgy_name": "Buenasuerte",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208003",
      "brgy_name": "Diland",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208004",
      "brgy_name": "Diolen",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208005",
      "brgy_name": "Head Tipan",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208006",
      "brgy_name": "New Casul",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208007",
      "brgy_name": "Newland",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208008",
      "brgy_name": "New Siquijor",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208009",
      "brgy_name": "Paso Rio",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208010",
      "brgy_name": "Poblacion",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208011",
      "brgy_name": "San Miguel",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208012",
      "brgy_name": "Tinglan",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208013",
      "brgy_name": "Totongon",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208014",
      "brgy_name": "Tubac",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208015",
      "brgy_name": "Unidos",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097208016",
      "brgy_name": "Santo Tomas",
      "city_code": "097208",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209001",
      "brgy_name": "Adante",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209002",
      "brgy_name": "Bacuyong",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209003",
      "brgy_name": "Bagong Silang",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209004",
      "brgy_name": "Calican",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209005",
      "brgy_name": "Ubay (Daan Tipan)",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209006",
      "brgy_name": "Del Pilar",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209007",
      "brgy_name": "Dilawa",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209008",
      "brgy_name": "Desin",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209009",
      "brgy_name": "Dionum",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209010",
      "brgy_name": "Lapu-lapu",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209011",
      "brgy_name": "Lower Gumay",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209012",
      "brgy_name": "Luzvilla",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209013",
      "brgy_name": "Poblacion North",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209015",
      "brgy_name": "Santa Fe",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209016",
      "brgy_name": "Segabe",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209017",
      "brgy_name": "Sikitan",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209018",
      "brgy_name": "Silano",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209019",
      "brgy_name": "Teresita",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209020",
      "brgy_name": "Tinaytayan",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209022",
      "brgy_name": "Upper Gumay",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209023",
      "brgy_name": "Villarico",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097209024",
      "brgy_name": "Poblacion South",
      "city_code": "097209",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210001",
      "brgy_name": "Anastacio",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210002",
      "brgy_name": "Bandera",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210003",
      "brgy_name": "Bethlehem",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210004",
      "brgy_name": "Dangi",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210005",
      "brgy_name": "Dansullan",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210006",
      "brgy_name": "De Venta Perla",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210007",
      "brgy_name": "Guinles",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210008",
      "brgy_name": "Isis",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210009",
      "brgy_name": "Labrador (Prinda)",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210010",
      "brgy_name": "Lapayanbaja",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210011",
      "brgy_name": "Letapan",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210012",
      "brgy_name": "Linabo",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210013",
      "brgy_name": "Lingasad",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210014",
      "brgy_name": "Macleodes",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210015",
      "brgy_name": "Magangon",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210016",
      "brgy_name": "Maligaya",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210017",
      "brgy_name": "Milad",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210018",
      "brgy_name": "New Lebangon",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210019",
      "brgy_name": "New Sicayab",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210020",
      "brgy_name": "Obay",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210021",
      "brgy_name": "Pian",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210022",
      "brgy_name": "Poblacion South",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210023",
      "brgy_name": "San Antonio (Paetan)",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210024",
      "brgy_name": "San Miguel (Loboc)",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210025",
      "brgy_name": "San Pedro",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210026",
      "brgy_name": "Santo Niño (Lantoy)",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210027",
      "brgy_name": "Silawe",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210028",
      "brgy_name": "Sianib",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210029",
      "brgy_name": "Villahermosa",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097210030",
      "brgy_name": "Poblacion North",
      "city_code": "097210",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211001",
      "brgy_name": "Balubo",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211002",
      "brgy_name": "Canibongan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211003",
      "brgy_name": "Capase",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211004",
      "brgy_name": "Denoman",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211005",
      "brgy_name": "Dohinob",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211006",
      "brgy_name": "Langatian",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211007",
      "brgy_name": "Lipakan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211008",
      "brgy_name": "Marupay",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211009",
      "brgy_name": "Moliton",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211010",
      "brgy_name": "Nabilid",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211011",
      "brgy_name": "Pangologon",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211012",
      "brgy_name": "Piñalan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211013",
      "brgy_name": "Piñamar",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211014",
      "brgy_name": "Sebod",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211015",
      "brgy_name": "Tanayan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211017",
      "brgy_name": "Villahermoso",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211018",
      "brgy_name": "Banbanan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211019",
      "brgy_name": "Cape",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211020",
      "brgy_name": "Galokso",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211021",
      "brgy_name": "Gubat",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211022",
      "brgy_name": "Irasan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211023",
      "brgy_name": "Labakid",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211024",
      "brgy_name": "Panampalay",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211025",
      "brgy_name": "Piao",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211026",
      "brgy_name": "Pongolan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211027",
      "brgy_name": "Salisig",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211028",
      "brgy_name": "Sibatog",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211029",
      "brgy_name": "Situbo",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211030",
      "brgy_name": "Tantingon",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211031",
      "brgy_name": "Upper Irasan",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097211032",
      "brgy_name": "Upper Minang",
      "city_code": "097211",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212001",
      "brgy_name": "Birayan",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212002",
      "brgy_name": "Damasing",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212003",
      "brgy_name": "La Esperanza",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212004",
      "brgy_name": "Mabuhay",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212005",
      "brgy_name": "Mabunao",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212006",
      "brgy_name": "North Mapang",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212007",
      "brgy_name": "Mitimos",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212008",
      "brgy_name": "Nangca",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212009",
      "brgy_name": "Nangcaan",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212010",
      "brgy_name": "Napilan",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212011",
      "brgy_name": "Nasipang",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212012",
      "brgy_name": "New Dapitan",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212013",
      "brgy_name": "Nilabo",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212014",
      "brgy_name": "East Poblacion",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212015",
      "brgy_name": "Rizalina",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212016",
      "brgy_name": "San Roque",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212017",
      "brgy_name": "Sebaca",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212018",
      "brgy_name": "Sipaon",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212019",
      "brgy_name": "Tolon",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212020",
      "brgy_name": "Balubohan",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212021",
      "brgy_name": "South Mapang",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097212022",
      "brgy_name": "West Poblacion",
      "city_code": "097212",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213001",
      "brgy_name": "Bacong",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213002",
      "brgy_name": "Balakan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213004",
      "brgy_name": "Binoni",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213006",
      "brgy_name": "Calucap",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213007",
      "brgy_name": "Canawan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213008",
      "brgy_name": "Caracol",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213009",
      "brgy_name": "Danao",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213010",
      "brgy_name": "Dinoan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213011",
      "brgy_name": "Dipolod",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213013",
      "brgy_name": "Fatima (Pogan)",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213015",
      "brgy_name": "Liguac",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213017",
      "brgy_name": "Lipakan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213019",
      "brgy_name": "Mucas",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213020",
      "brgy_name": "Poblacion (Salug)",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213021",
      "brgy_name": "Ramon Magsaysay",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213027",
      "brgy_name": "Tambalang",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213028",
      "brgy_name": "Tapalan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213031",
      "brgy_name": "Ipilan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213032",
      "brgy_name": "Lanawan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213035",
      "brgy_name": "Pukay",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213036",
      "brgy_name": "Santo Niño",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213037",
      "brgy_name": "Pacuhan",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097213038",
      "brgy_name": "Poblacion East",
      "city_code": "097213",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214001",
      "brgy_name": "Antonino",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214002",
      "brgy_name": "Bagong Baguio",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214003",
      "brgy_name": "Bagumbayan",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214004",
      "brgy_name": "Biayon",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214005",
      "brgy_name": "Buenavista",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214006",
      "brgy_name": "Dampalan",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214007",
      "brgy_name": "Danao",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214008",
      "brgy_name": "Don Eleno",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214009",
      "brgy_name": "Kauswagan",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214010",
      "brgy_name": "Labiray",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214011",
      "brgy_name": "Liwanag",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214012",
      "brgy_name": "Mabuhay",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214013",
      "brgy_name": "Macalibre",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214014",
      "brgy_name": "Mahayahay",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214015",
      "brgy_name": "Marapong",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214016",
      "brgy_name": "Nazareth",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214017",
      "brgy_name": "Nebo",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214018",
      "brgy_name": "New Rizal",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214019",
      "brgy_name": "New Tangub",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214020",
      "brgy_name": "Nuevavista",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214021",
      "brgy_name": "Pedagan",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214022",
      "brgy_name": "Penacio",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214023",
      "brgy_name": "Poblacion Alto",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214024",
      "brgy_name": "Poblacion Bajo",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214026",
      "brgy_name": "Princesa Lamaya",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214027",
      "brgy_name": "Princesa Freshia",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214028",
      "brgy_name": "San Antonio",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214029",
      "brgy_name": "San Francisco",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214030",
      "brgy_name": "San Isidro",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214031",
      "brgy_name": "San Jose",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214032",
      "brgy_name": "San Juan",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214033",
      "brgy_name": "Sinaad",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214034",
      "brgy_name": "Sinai",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214035",
      "brgy_name": "Situbo",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214036",
      "brgy_name": "Tinago",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214037",
      "brgy_name": "Tinindugan",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214038",
      "brgy_name": "Tuburan",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214039",
      "brgy_name": "Venus",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097214040",
      "brgy_name": "Wilben",
      "city_code": "097214",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215001",
      "brgy_name": "Balok",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215003",
      "brgy_name": "Datagan",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215004",
      "brgy_name": "Denoyan",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215005",
      "brgy_name": "Diongan",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215006",
      "brgy_name": "Domogok",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215007",
      "brgy_name": "Dumpilas",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215008",
      "brgy_name": "Gonayen",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215010",
      "brgy_name": "Guibo",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215011",
      "brgy_name": "Gunyan",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215012",
      "brgy_name": "Litolet",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215013",
      "brgy_name": "Macasing",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215014",
      "brgy_name": "Mangilay",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215015",
      "brgy_name": "Moyo",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215016",
      "brgy_name": "Pange",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215017",
      "brgy_name": "Paranglumba (Pob.)",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215019",
      "brgy_name": "Polayo",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215020",
      "brgy_name": "Sayaw",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215021",
      "brgy_name": "Seriac",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215022",
      "brgy_name": "Siayan Proper (Pob.)",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215023",
      "brgy_name": "Balunokan",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215024",
      "brgy_name": "Muñoz",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097215025",
      "brgy_name": "Suguilon",
      "city_code": "097215",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216001",
      "brgy_name": "Anongan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216002",
      "brgy_name": "Basak",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216003",
      "brgy_name": "Cawit-cawit",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216004",
      "brgy_name": "Dinulan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216005",
      "brgy_name": "Jatian",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216006",
      "brgy_name": "Lakiki",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216007",
      "brgy_name": "Lambagoan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216008",
      "brgy_name": "Limpapa",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216009",
      "brgy_name": "Lingayon",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216010",
      "brgy_name": "Lintangan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216011",
      "brgy_name": "Litawan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216012",
      "brgy_name": "Lunday",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216013",
      "brgy_name": "Malayal",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216014",
      "brgy_name": "Mantivo",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216015",
      "brgy_name": "Panganuran",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216016",
      "brgy_name": "Pangian",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216017",
      "brgy_name": "Paniran",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216018",
      "brgy_name": "Poblacion",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216019",
      "brgy_name": "Puliran",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216020",
      "brgy_name": "Santo Niño (Culabog)",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216021",
      "brgy_name": "Bongalao",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216022",
      "brgy_name": "Cabbunan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216023",
      "brgy_name": "Culaguan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216024",
      "brgy_name": "Cusipan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216025",
      "brgy_name": "Kamarangan",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216026",
      "brgy_name": "Nala (Pob.)",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216027",
      "brgy_name": "Pasilnahut",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097216028",
      "brgy_name": "Tangarak",
      "city_code": "097216",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217001",
      "brgy_name": "Bagacay",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217002",
      "brgy_name": "Calilic",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217003",
      "brgy_name": "Calube",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217004",
      "brgy_name": "Kanim",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217005",
      "brgy_name": "Delapa",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217006",
      "brgy_name": "Libay",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217007",
      "brgy_name": "Magsaysay",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217008",
      "brgy_name": "Marapong",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217009",
      "brgy_name": "Minlasag",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217010",
      "brgy_name": "Oyan",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217011",
      "brgy_name": "Panganuran",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217012",
      "brgy_name": "Poblacion (Sibutad)",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217013",
      "brgy_name": "Sawang",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217014",
      "brgy_name": "Sibuloc",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217015",
      "brgy_name": "Sinipay",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097217016",
      "brgy_name": "Sipaloc",
      "city_code": "097217",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218003",
      "brgy_name": "Bago",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218004",
      "brgy_name": "Binuangan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218005",
      "brgy_name": "Bitoon",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218007",
      "brgy_name": "Dicoyong",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218009",
      "brgy_name": "Don Ricardo Macias (Dinobot)",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218010",
      "brgy_name": "Dumalogdog",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218011",
      "brgy_name": "Inuman",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218012",
      "brgy_name": "La Concepcion",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218013",
      "brgy_name": "Lagag",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218014",
      "brgy_name": "Lapero",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218015",
      "brgy_name": "Mandih",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218018",
      "brgy_name": "Maras",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218019",
      "brgy_name": "Mawal",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218020",
      "brgy_name": "Misok",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218021",
      "brgy_name": "Motibot",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218022",
      "brgy_name": "Nato",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218023",
      "brgy_name": "Pangalalan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218024",
      "brgy_name": "Piao",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218025",
      "brgy_name": "Poblacion",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218026",
      "brgy_name": "Siare",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218028",
      "brgy_name": "Talinga",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218029",
      "brgy_name": "Tinaplan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218030",
      "brgy_name": "Tigbao",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218031",
      "brgy_name": "Titik",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218032",
      "brgy_name": "Bato",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218033",
      "brgy_name": "Bucana",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218034",
      "brgy_name": "Caluan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218035",
      "brgy_name": "Calubian",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218036",
      "brgy_name": "Calatunan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218037",
      "brgy_name": "Dagohoy",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218038",
      "brgy_name": "Datagan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218039",
      "brgy_name": "Disud",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218040",
      "brgy_name": "Doña Josefa",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218041",
      "brgy_name": "Gampis",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218042",
      "brgy_name": "Goleo",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218043",
      "brgy_name": "Imelda",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218044",
      "brgy_name": "Joaquin Macias",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218045",
      "brgy_name": "Labakid",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218050",
      "brgy_name": "Santo Niño",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218051",
      "brgy_name": "Santo Rosario",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218054",
      "brgy_name": "Upper Nipaan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218055",
      "brgy_name": "Benigno Aquino Jr.",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218056",
      "brgy_name": "Fatima",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218057",
      "brgy_name": "Balok",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218058",
      "brgy_name": "Bantayan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218059",
      "brgy_name": "Dapaon",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218060",
      "brgy_name": "Datu Tangkilan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218061",
      "brgy_name": "La Roche San Miguel",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218062",
      "brgy_name": "Lawis",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218063",
      "brgy_name": "Magsaysay",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218064",
      "brgy_name": "Nipaan",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097218065",
      "brgy_name": "Upper Inuman",
      "city_code": "097218",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219002",
      "brgy_name": "Balagunan",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219004",
      "brgy_name": "Andres Micubo Jr. (Balili)",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219005",
      "brgy_name": "Bucana",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219006",
      "brgy_name": "Bulacan",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219008",
      "brgy_name": "Dionisio Riconalla",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219009",
      "brgy_name": "Candiz",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219011",
      "brgy_name": "Jose P. Brillantes, Sr. (Old Lituban)",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219012",
      "brgy_name": "Latabon",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219013",
      "brgy_name": "Mateo Francisco",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219014",
      "brgy_name": "Malipot",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219016",
      "brgy_name": "New Lituban",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219018",
      "brgy_name": "Pisawak",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219019",
      "brgy_name": "Poblacion",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219021",
      "brgy_name": "Santa Maria",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219022",
      "brgy_name": "Suhaile Arabi",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219023",
      "brgy_name": "Tibangao",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219024",
      "brgy_name": "S. Cabral",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219025",
      "brgy_name": "Datu Sailila",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219026",
      "brgy_name": "Makiang",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219027",
      "brgy_name": "Malambuhangin",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219028",
      "brgy_name": "Manaol",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219029",
      "brgy_name": "Matiag",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219030",
      "brgy_name": "Pangian",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219031",
      "brgy_name": "Siay",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219032",
      "brgy_name": "Tabayo",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097219033",
      "brgy_name": "Tagaytay",
      "city_code": "097219",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220001",
      "brgy_name": "Balatakan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220002",
      "brgy_name": "Balonkan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220003",
      "brgy_name": "Balubuan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220004",
      "brgy_name": "Bitugan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220005",
      "brgy_name": "Bongon",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220007",
      "brgy_name": "Catuyan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220009",
      "brgy_name": "Culasian",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220010",
      "brgy_name": "Danganon",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220011",
      "brgy_name": "Doña Cecilia",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220013",
      "brgy_name": "Guban",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220014",
      "brgy_name": "Lagundi",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220015",
      "brgy_name": "Libucon",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220016",
      "brgy_name": "Lubok",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220017",
      "brgy_name": "Macuyon",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220018",
      "brgy_name": "Minanga",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220019",
      "brgy_name": "Motong",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220020",
      "brgy_name": "Napulan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220021",
      "brgy_name": "Panabutan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220022",
      "brgy_name": "Piacan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220024",
      "brgy_name": "Piña",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220025",
      "brgy_name": "Pisa Puti",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220026",
      "brgy_name": "Pisa Itom",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220028",
      "brgy_name": "Saint Mary (Pob.)",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220029",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220030",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220031",
      "brgy_name": "Pugos",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220032",
      "brgy_name": "Pula Bato",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220033",
      "brgy_name": "Pulang Lupa",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220035",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220036",
      "brgy_name": "Sipakit",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220037",
      "brgy_name": "Sipawa",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220038",
      "brgy_name": "Sirawai Proper (Pob.)",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220039",
      "brgy_name": "Talabiga",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097220040",
      "brgy_name": "Tapanayan",
      "city_code": "097220",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221001",
      "brgy_name": "Cabong",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221002",
      "brgy_name": "Galingon",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221003",
      "brgy_name": "Lawaan",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221004",
      "brgy_name": "Molos",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221005",
      "brgy_name": "New Dapitan",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221006",
      "brgy_name": "Situbo",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221007",
      "brgy_name": "Poblacion (Tampilisan)",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221008",
      "brgy_name": "Balacbaan",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221009",
      "brgy_name": "Banbanan",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221010",
      "brgy_name": "Barili",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221011",
      "brgy_name": "Camul",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221012",
      "brgy_name": "Farmington",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221013",
      "brgy_name": "Lumbayao",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221014",
      "brgy_name": "Malila-t",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221015",
      "brgy_name": "Sandayong",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221016",
      "brgy_name": "Santo Niño",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221017",
      "brgy_name": "Tilubog",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221018",
      "brgy_name": "Tininggaan",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221019",
      "brgy_name": "Tubod",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097221020",
      "brgy_name": "Znac",
      "city_code": "097221",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222001",
      "brgy_name": "Dinasan",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222002",
      "brgy_name": "Madalag",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222003",
      "brgy_name": "Manawan",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222004",
      "brgy_name": "Poblacion (Ponot)",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222005",
      "brgy_name": "Siparok",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222006",
      "brgy_name": "Tabon",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222007",
      "brgy_name": "Tamil",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222008",
      "brgy_name": "Balatakan",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222009",
      "brgy_name": "Bitoon",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222010",
      "brgy_name": "Ilihan",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222011",
      "brgy_name": "Labakid",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222012",
      "brgy_name": "Lipay",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222013",
      "brgy_name": "Litalip",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222014",
      "brgy_name": "Lopero",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222015",
      "brgy_name": "Lumanping",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222016",
      "brgy_name": "Marupay",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222017",
      "brgy_name": "Sigamok",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097222018",
      "brgy_name": "Tamarok",
      "city_code": "097222",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223001",
      "brgy_name": "Banganon",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223002",
      "brgy_name": "Cocob",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223003",
      "brgy_name": "Poblacion (Gutalac)",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223004",
      "brgy_name": "La Libertad",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223005",
      "brgy_name": "Lux",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223006",
      "brgy_name": "Panganuran",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223007",
      "brgy_name": "Pitawe",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223008",
      "brgy_name": "Canupong",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223009",
      "brgy_name": "Mamawan",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223010",
      "brgy_name": "Sibalic",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223011",
      "brgy_name": "Tipan",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223012",
      "brgy_name": "Bacong",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223013",
      "brgy_name": "Bagong Silang",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223014",
      "brgy_name": "Bayanihan",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223015",
      "brgy_name": "Buenavista",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223016",
      "brgy_name": "Datagan",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223017",
      "brgy_name": "Imelda",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223018",
      "brgy_name": "Loay",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223019",
      "brgy_name": "Malian",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223020",
      "brgy_name": "Map",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223021",
      "brgy_name": "Matunoy",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223022",
      "brgy_name": "New Dapitan",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223023",
      "brgy_name": "Pitogo",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223024",
      "brgy_name": "Salvador",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223025",
      "brgy_name": "San Isidro",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223026",
      "brgy_name": "San Juan",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223027",
      "brgy_name": "San Roque",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223028",
      "brgy_name": "San Vicente",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223029",
      "brgy_name": "Santo Niño",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223030",
      "brgy_name": "Sas",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223031",
      "brgy_name": "Upper Gutalac",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223032",
      "brgy_name": "Immaculada Concepcion",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097223033",
      "brgy_name": "Lower Lux",
      "city_code": "097223",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224001",
      "brgy_name": "Alegria",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224002",
      "brgy_name": "Diangas",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224003",
      "brgy_name": "Diculom",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224004",
      "brgy_name": "Guimotan",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224005",
      "brgy_name": "Kauswagan",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224006",
      "brgy_name": "Kilalaban",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224007",
      "brgy_name": "Linay",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224008",
      "brgy_name": "Lumay",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224009",
      "brgy_name": "Malinao",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224010",
      "brgy_name": "Mamad",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224011",
      "brgy_name": "Mamawan",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224012",
      "brgy_name": "Milidan",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224013",
      "brgy_name": "Nonoyan",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224014",
      "brgy_name": "Poblacion",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224015",
      "brgy_name": "San Jose",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224016",
      "brgy_name": "Tamao",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097224017",
      "brgy_name": "Tan-awan",
      "city_code": "097224",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225001",
      "brgy_name": "Baluno",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225002",
      "brgy_name": "Banuangan",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225003",
      "brgy_name": "Bunawan",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225004",
      "brgy_name": "Dilucot",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225005",
      "brgy_name": "Dipopor",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225006",
      "brgy_name": "Guisapong",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225007",
      "brgy_name": "Limbonga (Limboangan)",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225008",
      "brgy_name": "Lomogom",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225009",
      "brgy_name": "Mauswagon",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225010",
      "brgy_name": "Miampic",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225011",
      "brgy_name": "Poblacion",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225012",
      "brgy_name": "Raba",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225013",
      "brgy_name": "Rambon",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225014",
      "brgy_name": "San Pedro",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225015",
      "brgy_name": "Sarawagan",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225016",
      "brgy_name": "Sianan",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097225017",
      "brgy_name": "Sioran",
      "city_code": "097225",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226001",
      "brgy_name": "Bacungan (Pob.)",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226002",
      "brgy_name": "Bogabongan",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226003",
      "brgy_name": "Delusom",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226004",
      "brgy_name": "Mangop",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226005",
      "brgy_name": "Manil",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226006",
      "brgy_name": "Mawal",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226007",
      "brgy_name": "Midatag",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226008",
      "brgy_name": "Nasibac",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226009",
      "brgy_name": "Rizon",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226010",
      "brgy_name": "Sipacong",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226011",
      "brgy_name": "Santa Maria",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226012",
      "brgy_name": "Talinga",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226013",
      "brgy_name": "Tinaplan",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226014",
      "brgy_name": "Tiniguiban",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226015",
      "brgy_name": "Tinuyop",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226016",
      "brgy_name": "Tiogan",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226017",
      "brgy_name": "Titik",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097226018",
      "brgy_name": "Morob",
      "city_code": "097226",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227001",
      "brgy_name": "Batayan",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227002",
      "brgy_name": "Botong",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227003",
      "brgy_name": "Concepcion",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227004",
      "brgy_name": "Daniel Maing (Dominolog)",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227005",
      "brgy_name": "Fatima (Lacsutan)",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227006",
      "brgy_name": "Gatas",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227007",
      "brgy_name": "Kalawit (Pob.)",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227008",
      "brgy_name": "Marcelo",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227009",
      "brgy_name": "New Calamba",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227010",
      "brgy_name": "Palalian",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227011",
      "brgy_name": "Paraiso",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227012",
      "brgy_name": "Pianon",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227013",
      "brgy_name": "San Jose",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097227014",
      "brgy_name": "Tugop*",
      "city_code": "097227",
      "province_code": "0972",
      "region_code": "09"
    },
    {
      "brgy_code": "097302001",
      "brgy_name": "Acad",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302002",
      "brgy_name": "Alang-alang",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302003",
      "brgy_name": "Alegria",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302004",
      "brgy_name": "Anonang",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302005",
      "brgy_name": "Bagong Mandaue",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302007",
      "brgy_name": "Bagong Maslog",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302008",
      "brgy_name": "Bagong Oslob",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302009",
      "brgy_name": "Bagong Pitogo",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302010",
      "brgy_name": "Baki",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302011",
      "brgy_name": "Balas",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302012",
      "brgy_name": "Balide",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302013",
      "brgy_name": "Balintawak",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302014",
      "brgy_name": "Bayabas",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302015",
      "brgy_name": "Bemposa",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302016",
      "brgy_name": "Cabilinan",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302017",
      "brgy_name": "Campo Uno",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302018",
      "brgy_name": "Ceboneg",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302019",
      "brgy_name": "Commonwealth",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302021",
      "brgy_name": "Gubaan",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302022",
      "brgy_name": "Inasagan",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302023",
      "brgy_name": "Inroad",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302024",
      "brgy_name": "Kahayagan East (Katipunan)",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302025",
      "brgy_name": "Kahayagan West",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302027",
      "brgy_name": "Kauswagan",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302028",
      "brgy_name": "La Victoria",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302029",
      "brgy_name": "Lantungan",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302031",
      "brgy_name": "Libertad",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302032",
      "brgy_name": "Lintugop",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302033",
      "brgy_name": "Lubid",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302034",
      "brgy_name": "Maguikay",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302035",
      "brgy_name": "Mahayahay",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302037",
      "brgy_name": "Monte Alegre",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302038",
      "brgy_name": "Montela",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302040",
      "brgy_name": "Napo",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302041",
      "brgy_name": "Panaghiusa",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302042",
      "brgy_name": "Poblacion",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302043",
      "brgy_name": "Resthouse",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302044",
      "brgy_name": "Romarate",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302045",
      "brgy_name": "San Jose",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302046",
      "brgy_name": "San Juan",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302047",
      "brgy_name": "Sapa Loboc",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302048",
      "brgy_name": "Tagulalo",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302050",
      "brgy_name": "La Paz (Tinibtiban)",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097302052",
      "brgy_name": "Waterfall",
      "city_code": "097302",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303001",
      "brgy_name": "Baking",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303002",
      "brgy_name": "Balukbahan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303003",
      "brgy_name": "Balumbunan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303004",
      "brgy_name": "Bantal",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303005",
      "brgy_name": "Bobuan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303006",
      "brgy_name": "Camp Blessing",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303007",
      "brgy_name": "Canoayan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303008",
      "brgy_name": "Conacon",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303009",
      "brgy_name": "Dagum",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303010",
      "brgy_name": "Damit",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303011",
      "brgy_name": "Datagan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303012",
      "brgy_name": "Depase",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303013",
      "brgy_name": "Deporehan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303014",
      "brgy_name": "Depore",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303015",
      "brgy_name": "Dimalinao",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303016",
      "brgy_name": "Depili",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303017",
      "brgy_name": "Kahayagan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303018",
      "brgy_name": "Kanipaan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303019",
      "brgy_name": "Lamare",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303020",
      "brgy_name": "Liba",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303021",
      "brgy_name": "Matin-ao",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303022",
      "brgy_name": "Matun-og",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303023",
      "brgy_name": "Poblacion",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303024",
      "brgy_name": "Pulang Bato",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303025",
      "brgy_name": "Salawagan",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303026",
      "brgy_name": "Sigacad",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303027",
      "brgy_name": "Supon",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097303028",
      "brgy_name": "Pangi (San Isidro)",
      "city_code": "097303",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305001",
      "brgy_name": "Bacayawan",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305002",
      "brgy_name": "Baha",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305005",
      "brgy_name": "Baluno",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305006",
      "brgy_name": "Binuay",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305007",
      "brgy_name": "Buburay",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305009",
      "brgy_name": "Grap",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305010",
      "brgy_name": "Kagawasan",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305012",
      "brgy_name": "Lalab",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305014",
      "brgy_name": "Libertad",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305017",
      "brgy_name": "Mahayag",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305021",
      "brgy_name": "Poblacion",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305023",
      "brgy_name": "Saloagan",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305024",
      "brgy_name": "Sugbay Uno",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305026",
      "brgy_name": "Sumpot",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305027",
      "brgy_name": "Tiniguangan",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305030",
      "brgy_name": "Tinggabulong",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305031",
      "brgy_name": "Tipangi",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305032",
      "brgy_name": "Balanagan",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305033",
      "brgy_name": "Josefina",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305034",
      "brgy_name": "Magahis",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305035",
      "brgy_name": "Mercedes",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305036",
      "brgy_name": "San Roque",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305037",
      "brgy_name": "Sumbato",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097305038",
      "brgy_name": "Upper Ludiong",
      "city_code": "097305",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306001",
      "brgy_name": "Bacawan",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306002",
      "brgy_name": "Benuatan",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306003",
      "brgy_name": "Beray",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306004",
      "brgy_name": "Dongos",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306005",
      "brgy_name": "Guinicolalay",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306006",
      "brgy_name": "Kinacap",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306007",
      "brgy_name": "Legarda 2",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306008",
      "brgy_name": "Legarda 3",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306009",
      "brgy_name": "Legarda 1",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306012",
      "brgy_name": "Lucoban",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306013",
      "brgy_name": "Lower Dimaya",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306014",
      "brgy_name": "Ludiong",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306015",
      "brgy_name": "East Migpulao",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306016",
      "brgy_name": "West Migpulao",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306017",
      "brgy_name": "Nangka",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306018",
      "brgy_name": "Ignacio Garrata (New Mirapao)",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306019",
      "brgy_name": "Old Mirapao",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306020",
      "brgy_name": "Poblacion",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306021",
      "brgy_name": "Proper Dimaya",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306022",
      "brgy_name": "Sagacad",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306023",
      "brgy_name": "Sambulawan",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306024",
      "brgy_name": "San Isidro",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306026",
      "brgy_name": "Sumpotan",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306027",
      "brgy_name": "Songayan",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306028",
      "brgy_name": "Tarakan",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306029",
      "brgy_name": "Upper Dimaya",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306030",
      "brgy_name": "Upper Sibul",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306031",
      "brgy_name": "Pisa-an",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306032",
      "brgy_name": "Don Jose",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097306034",
      "brgy_name": "Nian",
      "city_code": "097306",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307001",
      "brgy_name": "Bag-ong Misamis",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307002",
      "brgy_name": "Baga",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307003",
      "brgy_name": "Bag-ong Silao",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307005",
      "brgy_name": "Baloboan",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307007",
      "brgy_name": "Banta-ao",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307009",
      "brgy_name": "Bibilik",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307011",
      "brgy_name": "Calingayan",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307013",
      "brgy_name": "Camalig",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307014",
      "brgy_name": "Camanga",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307016",
      "brgy_name": "Cuatro-cuatro",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307026",
      "brgy_name": "Locuban",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307029",
      "brgy_name": "Malasik",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307030",
      "brgy_name": "Mama (San Juan)",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307032",
      "brgy_name": "Matab-ang",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307034",
      "brgy_name": "Mecolong",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307035",
      "brgy_name": "Metokong",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307036",
      "brgy_name": "Motosawa",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307040",
      "brgy_name": "Pag-asa (Pob.)",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307041",
      "brgy_name": "Paglaum (Pob.)",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307043",
      "brgy_name": "Pantad",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307044",
      "brgy_name": "Piniglibano",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307045",
      "brgy_name": "Rebokon",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307046",
      "brgy_name": "San Agustin",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307047",
      "brgy_name": "Sibucao",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307049",
      "brgy_name": "Sumadat",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307050",
      "brgy_name": "Tikwas",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307053",
      "brgy_name": "Tina",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307054",
      "brgy_name": "Tubo-Pait",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307055",
      "brgy_name": "Upper Dumalinao",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097307057",
      "brgy_name": "Anonang",
      "city_code": "097307",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308002",
      "brgy_name": "Bag-ong Valencia",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308003",
      "brgy_name": "Bucayan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308004",
      "brgy_name": "Calumanggi",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308005",
      "brgy_name": "Caridad",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308006",
      "brgy_name": "Danlugan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308007",
      "brgy_name": "Datu Totocan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308008",
      "brgy_name": "Dilud",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308009",
      "brgy_name": "Ditulan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308010",
      "brgy_name": "Dulian",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308011",
      "brgy_name": "Dulop",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308012",
      "brgy_name": "Guintananan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308013",
      "brgy_name": "Guitran",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308014",
      "brgy_name": "Gumpingan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308015",
      "brgy_name": "La Fortuna",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308016",
      "brgy_name": "Libertad",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308017",
      "brgy_name": "Licabang",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308018",
      "brgy_name": "Lipawan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308019",
      "brgy_name": "Lower Landing",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308020",
      "brgy_name": "Lower Timonan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308021",
      "brgy_name": "Macasing",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308022",
      "brgy_name": "Mahayahay",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308023",
      "brgy_name": "Malagalad",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308024",
      "brgy_name": "Manlabay",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308025",
      "brgy_name": "Maralag",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308026",
      "brgy_name": "Marangan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308027",
      "brgy_name": "New Basak",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308030",
      "brgy_name": "Bagong Kauswagan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308032",
      "brgy_name": "Saad",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308033",
      "brgy_name": "Salvador",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308034",
      "brgy_name": "San Pablo (Pob.)",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308035",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308037",
      "brgy_name": "San Vicente",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308038",
      "brgy_name": "Senote",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308039",
      "brgy_name": "Sinonok",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308041",
      "brgy_name": "Sunop",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308042",
      "brgy_name": "Tagun",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308043",
      "brgy_name": "Tamurayan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308045",
      "brgy_name": "Upper Landing",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308046",
      "brgy_name": "Upper Timonan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308047",
      "brgy_name": "Bagong Silang",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308048",
      "brgy_name": "Dapiwak",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308049",
      "brgy_name": "Labangon",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308050",
      "brgy_name": "San Juan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097308051",
      "brgy_name": "Canibongan",
      "city_code": "097308",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311004",
      "brgy_name": "Bogayo",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311005",
      "brgy_name": "Bolisong",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311006",
      "brgy_name": "Bualan",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311008",
      "brgy_name": "Boyugan East",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311009",
      "brgy_name": "Boyugan West",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311010",
      "brgy_name": "Diplo",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311013",
      "brgy_name": "Gawil",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311014",
      "brgy_name": "Gusom",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311015",
      "brgy_name": "Kitaan Dagat",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311017",
      "brgy_name": "Limamawan",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311019",
      "brgy_name": "Mahayahay",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311020",
      "brgy_name": "Pangi",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311022",
      "brgy_name": "Poblacion",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311023",
      "brgy_name": "Picanan",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311024",
      "brgy_name": "Salagmanok",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311026",
      "brgy_name": "Secade",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311027",
      "brgy_name": "Suminalum",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097311029",
      "brgy_name": "Lantawan",
      "city_code": "097311",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312001",
      "brgy_name": "Bagalupa",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312003",
      "brgy_name": "Balimbingan (West Luya)",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312005",
      "brgy_name": "Binayan",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312006",
      "brgy_name": "Bokong",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312007",
      "brgy_name": "Bulanit",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312008",
      "brgy_name": "Cogonan",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312009",
      "brgy_name": "Dalapang",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312010",
      "brgy_name": "Dipaya",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312011",
      "brgy_name": "Langapod",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312012",
      "brgy_name": "Lantian",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312013",
      "brgy_name": "Lower Campo Islam (Pob.)",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312014",
      "brgy_name": "Lower Pulacan",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312015",
      "brgy_name": "New Labangan",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312016",
      "brgy_name": "Noboran",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312017",
      "brgy_name": "Old Labangan",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312019",
      "brgy_name": "San Isidro",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312020",
      "brgy_name": "Santa Cruz",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312021",
      "brgy_name": "Tapodoc",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312022",
      "brgy_name": "Tawagan Norte",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312023",
      "brgy_name": "Upper Campo Islam (Pob.)",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312024",
      "brgy_name": "Upper Pulacan",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312026",
      "brgy_name": "Combo",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312027",
      "brgy_name": "Dimasangca",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312028",
      "brgy_name": "Lower Sang-an",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097312029",
      "brgy_name": "Upper Sang-an",
      "city_code": "097312",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313002",
      "brgy_name": "Bulawan",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313003",
      "brgy_name": "Carpoc",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313004",
      "brgy_name": "Danganan",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313005",
      "brgy_name": "Dansal",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313007",
      "brgy_name": "Dumara",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313010",
      "brgy_name": "Linokmadalum",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313011",
      "brgy_name": "Luanan",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313012",
      "brgy_name": "Lubusan",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313013",
      "brgy_name": "Mahalingeb",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313014",
      "brgy_name": "Mandeg",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313015",
      "brgy_name": "Maralag",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313016",
      "brgy_name": "Maruing",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313017",
      "brgy_name": "Molum",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313018",
      "brgy_name": "Pampang",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313019",
      "brgy_name": "Pantad",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313020",
      "brgy_name": "Pingalay",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313023",
      "brgy_name": "Salambuyan",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313024",
      "brgy_name": "San Jose",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313025",
      "brgy_name": "Sayog",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313027",
      "brgy_name": "Tabon",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313028",
      "brgy_name": "Talabab",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313029",
      "brgy_name": "Tiguha",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313030",
      "brgy_name": "Tininghalang",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313031",
      "brgy_name": "Tipasan",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313033",
      "brgy_name": "Tugaya",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097313034",
      "brgy_name": "Poblacion",
      "city_code": "097313",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315001",
      "brgy_name": "Bag-ong Balamban",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315002",
      "brgy_name": "Bag-ong Dalaguete",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315003",
      "brgy_name": "Marabanan (Balanan)",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315004",
      "brgy_name": "Boniao",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315005",
      "brgy_name": "Delusom",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315006",
      "brgy_name": "Diwan",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315007",
      "brgy_name": "Guripan",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315008",
      "brgy_name": "Kaangayan",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315009",
      "brgy_name": "Kabuhi",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315011",
      "brgy_name": "Lourmah (Lower Mahayag)",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315012",
      "brgy_name": "Lower Salug Daku",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315013",
      "brgy_name": "Lower Santo Niño",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315014",
      "brgy_name": "Sicpao",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315015",
      "brgy_name": "Malubo",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315016",
      "brgy_name": "Manguiles",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315017",
      "brgy_name": "Panagaan",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315018",
      "brgy_name": "Paraiso",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315019",
      "brgy_name": "Pedagan",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315020",
      "brgy_name": "Poblacion (Upper Mahayag)",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315021",
      "brgy_name": "Pugwan",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315022",
      "brgy_name": "San Isidro",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315023",
      "brgy_name": "San Jose",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315024",
      "brgy_name": "San Vicente",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315025",
      "brgy_name": "Santa Cruz",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315027",
      "brgy_name": "Tuboran",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315028",
      "brgy_name": "Tumapic",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315029",
      "brgy_name": "Upper Salug Daku",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315030",
      "brgy_name": "Upper Santo Niño",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097315032",
      "brgy_name": "Tulan",
      "city_code": "097315",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317001",
      "brgy_name": "Balintawak",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317003",
      "brgy_name": "Bularong",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317005",
      "brgy_name": "Kalian",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317008",
      "brgy_name": "Kolot",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317010",
      "brgy_name": "Digon",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317011",
      "brgy_name": "Guinimanan",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317012",
      "brgy_name": "Igat Island",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317014",
      "brgy_name": "Limamawan",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317016",
      "brgy_name": "Limabatong",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317018",
      "brgy_name": "Lumbog",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317024",
      "brgy_name": "Poblacion",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317025",
      "brgy_name": "Sagua",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317027",
      "brgy_name": "Talanusa",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317028",
      "brgy_name": "Tiguian",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317029",
      "brgy_name": "Tulapoc",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317031",
      "brgy_name": "Magahis",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097317032",
      "brgy_name": "Josefina",
      "city_code": "097317",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318001",
      "brgy_name": "Bacahan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318003",
      "brgy_name": "Bibilop",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318005",
      "brgy_name": "Buloron",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318007",
      "brgy_name": "Canipay Norte",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318008",
      "brgy_name": "Canipay Sur",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318009",
      "brgy_name": "Cumaron",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318011",
      "brgy_name": "Golictop",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318012",
      "brgy_name": "Kahayagan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318016",
      "brgy_name": "Lumpunid",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318017",
      "brgy_name": "Matalang",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318018",
      "brgy_name": "New Katipunan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318019",
      "brgy_name": "New Unidos",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318020",
      "brgy_name": "Palili",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318021",
      "brgy_name": "Piwan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318022",
      "brgy_name": "Poblacion A",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318023",
      "brgy_name": "Poblacion B",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318024",
      "brgy_name": "Sigapod",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318026",
      "brgy_name": "Timbaboy",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318027",
      "brgy_name": "Tulbong",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318028",
      "brgy_name": "Tuluan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318029",
      "brgy_name": "Pisompongan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318030",
      "brgy_name": "Cabaloran",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318031",
      "brgy_name": "Dakayakan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318032",
      "brgy_name": "Duelic",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318033",
      "brgy_name": "Dumalinao",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318034",
      "brgy_name": "Ecuan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318035",
      "brgy_name": "Guinabot",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318036",
      "brgy_name": "Guitalos",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318038",
      "brgy_name": "Licuro-an",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318039",
      "brgy_name": "Pawan",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318040",
      "brgy_name": "Balonai",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318041",
      "brgy_name": "Guma",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097318042",
      "brgy_name": "Pili",
      "city_code": "097318",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319001",
      "brgy_name": "Alicia",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319002",
      "brgy_name": "Ariosa",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319003",
      "brgy_name": "Bagong Argao",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319005",
      "brgy_name": "Bagong Gutlang",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319006",
      "brgy_name": "Blancia",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319007",
      "brgy_name": "Bogo Capalaran",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319008",
      "brgy_name": "Dalaon",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319009",
      "brgy_name": "Dipolo",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319010",
      "brgy_name": "Dontulan",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319012",
      "brgy_name": "Gonosan",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319014",
      "brgy_name": "Culo",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319015",
      "brgy_name": "Lower Dimorok",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319016",
      "brgy_name": "Lower Dimalinao",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319017",
      "brgy_name": "Mabuhay",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319018",
      "brgy_name": "Madasigon (Pob.)",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319019",
      "brgy_name": "Makuguihon (Pob.)",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319020",
      "brgy_name": "Maloloy-on (Pob.)",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319021",
      "brgy_name": "Miligan",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319024",
      "brgy_name": "Parasan",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319025",
      "brgy_name": "Rizal",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319026",
      "brgy_name": "Santo Rosario",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319027",
      "brgy_name": "Silangit",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319028",
      "brgy_name": "Simata",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319029",
      "brgy_name": "Sudlon",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097319030",
      "brgy_name": "Upper Dimorok",
      "city_code": "097319",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322001",
      "brgy_name": "Alegria",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322002",
      "brgy_name": "Balangasan (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322003",
      "brgy_name": "Balintawak",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322004",
      "brgy_name": "Baloyboan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322005",
      "brgy_name": "Banale",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322006",
      "brgy_name": "Bogo",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322007",
      "brgy_name": "Bomba",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322010",
      "brgy_name": "Buenavista",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322011",
      "brgy_name": "Bulatok",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322012",
      "brgy_name": "Bulawan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322013",
      "brgy_name": "Danlugan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322014",
      "brgy_name": "Dao",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322015",
      "brgy_name": "Datagan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322016",
      "brgy_name": "Deborok",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322017",
      "brgy_name": "Ditoray",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322018",
      "brgy_name": "Gatas (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322019",
      "brgy_name": "Gubac",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322020",
      "brgy_name": "Gubang",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322021",
      "brgy_name": "Kagawasan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322022",
      "brgy_name": "Kahayagan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322023",
      "brgy_name": "Kalasan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322024",
      "brgy_name": "La Suerte",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322025",
      "brgy_name": "Lala",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322026",
      "brgy_name": "Lapidian",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322027",
      "brgy_name": "Lenienza",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322028",
      "brgy_name": "Lizon Valley",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322029",
      "brgy_name": "Lourdes",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322030",
      "brgy_name": "Lower Sibatang",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322031",
      "brgy_name": "Lumad",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322032",
      "brgy_name": "Macasing",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322033",
      "brgy_name": "Manga",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322034",
      "brgy_name": "Muricay",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322035",
      "brgy_name": "Napolan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322036",
      "brgy_name": "Palpalan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322037",
      "brgy_name": "Pedulonan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322038",
      "brgy_name": "Poloyagan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322039",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322040",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322041",
      "brgy_name": "San Pedro (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322042",
      "brgy_name": "Santa Lucia (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322043",
      "brgy_name": "Santiago (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322044",
      "brgy_name": "Tawagan Sur",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322045",
      "brgy_name": "Tiguma",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322046",
      "brgy_name": "Tuburan (Pob.)",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322047",
      "brgy_name": "Tulawas",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322048",
      "brgy_name": "Tulangan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322050",
      "brgy_name": "Upper Sibatang",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322051",
      "brgy_name": "White Beach",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322052",
      "brgy_name": "Kawit",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322053",
      "brgy_name": "Lumbia",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322054",
      "brgy_name": "Santa Maria",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322055",
      "brgy_name": "Santo Niño",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322056",
      "brgy_name": "Dampalan",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097322057",
      "brgy_name": "Dumagoc",
      "city_code": "097322",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323001",
      "brgy_name": "Bagong Opon",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323002",
      "brgy_name": "Bambong Daku",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323003",
      "brgy_name": "Bambong Diut",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323004",
      "brgy_name": "Bobongan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323005",
      "brgy_name": "Campo IV",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323006",
      "brgy_name": "Campo V",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323007",
      "brgy_name": "Caniangan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323008",
      "brgy_name": "Dipalusan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323009",
      "brgy_name": "Eastern Bobongan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323010",
      "brgy_name": "Esperanza",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323011",
      "brgy_name": "Gapasan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323012",
      "brgy_name": "Katipunan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323013",
      "brgy_name": "Kauswagan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323014",
      "brgy_name": "Lower Sambulawan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323015",
      "brgy_name": "Mabini",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323016",
      "brgy_name": "Magsaysay",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323017",
      "brgy_name": "Malating",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323018",
      "brgy_name": "Paradise",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323019",
      "brgy_name": "Pasingkalan",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323020",
      "brgy_name": "Poblacion",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323023",
      "brgy_name": "San Fernando",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323026",
      "brgy_name": "Santo Rosario",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323027",
      "brgy_name": "Sapa Anding",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323028",
      "brgy_name": "Sinaguing",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323029",
      "brgy_name": "Switch",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323030",
      "brgy_name": "Upper Laperian",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097323031",
      "brgy_name": "Wakat",
      "city_code": "097323",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324002",
      "brgy_name": "Betinan",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324003",
      "brgy_name": "Bulawan",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324004",
      "brgy_name": "Calube",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324005",
      "brgy_name": "Concepcion",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324007",
      "brgy_name": "Dao-an",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324008",
      "brgy_name": "Dumalian",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324009",
      "brgy_name": "Fatima",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324012",
      "brgy_name": "Langilan",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324013",
      "brgy_name": "Lantawan",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324014",
      "brgy_name": "Laperian",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324015",
      "brgy_name": "Libuganan",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324016",
      "brgy_name": "Limonan",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324017",
      "brgy_name": "Mati",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324018",
      "brgy_name": "Ocapan",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324019",
      "brgy_name": "Poblacion",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324020",
      "brgy_name": "San Isidro",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324021",
      "brgy_name": "Sayog",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097324022",
      "brgy_name": "Tapian",
      "city_code": "097324",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325001",
      "brgy_name": "Bag-ong Misamis",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325002",
      "brgy_name": "Bubual",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325003",
      "brgy_name": "Buton",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325004",
      "brgy_name": "Culasian",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325005",
      "brgy_name": "Daplayan",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325006",
      "brgy_name": "Kalilangan",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325007",
      "brgy_name": "Kapamanok",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325008",
      "brgy_name": "Kondum",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325009",
      "brgy_name": "Lumbayao",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325010",
      "brgy_name": "Mabuhay",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325011",
      "brgy_name": "Marcos Village",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325012",
      "brgy_name": "Miasin",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325013",
      "brgy_name": "Molansong",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325014",
      "brgy_name": "Pantad",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325015",
      "brgy_name": "Pao",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325016",
      "brgy_name": "Payag",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325017",
      "brgy_name": "Poblacion (San Pablo)",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325018",
      "brgy_name": "Pongapong",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325020",
      "brgy_name": "Sagasan",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325021",
      "brgy_name": "Sacbulan",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325022",
      "brgy_name": "Senior",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325023",
      "brgy_name": "Songgoy",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325024",
      "brgy_name": "Tandubuay",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325025",
      "brgy_name": "Ticala Island",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325026",
      "brgy_name": "Taniapan",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325027",
      "brgy_name": "Tubo-pait",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325028",
      "brgy_name": "Villakapa",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097325029",
      "brgy_name": "San Juan",
      "city_code": "097325",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327001",
      "brgy_name": "Abong-abong",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327002",
      "brgy_name": "Baganian",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327003",
      "brgy_name": "Baya-baya",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327004",
      "brgy_name": "Capisan",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327005",
      "brgy_name": "Concepcion",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327006",
      "brgy_name": "Culabay",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327008",
      "brgy_name": "Lumbia",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327009",
      "brgy_name": "Mabuhay",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327010",
      "brgy_name": "Malim",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327011",
      "brgy_name": "Manikaan",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327012",
      "brgy_name": "New Oroquieta",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327013",
      "brgy_name": "Poblacion",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327014",
      "brgy_name": "San Francisco",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327015",
      "brgy_name": "Tultolan",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097327016",
      "brgy_name": "Doña Josefina",
      "city_code": "097327",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328001",
      "brgy_name": "Alang-alang",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328002",
      "brgy_name": "Bag-ong Kauswagan",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328004",
      "brgy_name": "Bag-ong Tabogon",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328005",
      "brgy_name": "Balugo",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328006",
      "brgy_name": "Fabian (Balucot)",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328007",
      "brgy_name": "Cabgan",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328008",
      "brgy_name": "Calolot",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328010",
      "brgy_name": "Dimalinao",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328012",
      "brgy_name": "Gabunon",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328013",
      "brgy_name": "Happy Valley (Pob.)",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328015",
      "brgy_name": "Libato",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328016",
      "brgy_name": "Limamawan",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328019",
      "brgy_name": "Lower Liasan",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328020",
      "brgy_name": "Lower Lodiong (Pob.)",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328021",
      "brgy_name": "Lower Tiparak",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328022",
      "brgy_name": "Lower Usogan",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328024",
      "brgy_name": "Maya-maya",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328027",
      "brgy_name": "New Village (Pob.)",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328029",
      "brgy_name": "Pelocoban",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328030",
      "brgy_name": "Riverside (Pob.)",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328031",
      "brgy_name": "Sagrada Familia",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328032",
      "brgy_name": "San Jose",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328034",
      "brgy_name": "Sumalig",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328036",
      "brgy_name": "Tuluan",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328037",
      "brgy_name": "Tungawan",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328039",
      "brgy_name": "Upper Liason",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328040",
      "brgy_name": "Upper Lodiong",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328041",
      "brgy_name": "Upper Tiparak",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328042",
      "brgy_name": "Angeles",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328043",
      "brgy_name": "Kapalaran",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097328044",
      "brgy_name": "San Vicente",
      "city_code": "097328",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330001",
      "brgy_name": "Alindahaw",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330002",
      "brgy_name": "Baclay",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330005",
      "brgy_name": "Balimbingan",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330006",
      "brgy_name": "Buenasuerte",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330007",
      "brgy_name": "Camanga",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330008",
      "brgy_name": "Curvada",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330009",
      "brgy_name": "Laperian",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330010",
      "brgy_name": "Libertad",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330011",
      "brgy_name": "Lower Bayao",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330012",
      "brgy_name": "Luy-a",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330013",
      "brgy_name": "Manilan",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330014",
      "brgy_name": "Manlayag",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330015",
      "brgy_name": "Militar",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330016",
      "brgy_name": "Navalan",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330019",
      "brgy_name": "Panduma Senior",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330020",
      "brgy_name": "Sambulawan",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330021",
      "brgy_name": "San Antonio",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330022",
      "brgy_name": "San Carlos (Pob.)",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330023",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330024",
      "brgy_name": "Santo Rosario",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330025",
      "brgy_name": "Sugod",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330026",
      "brgy_name": "Tabuan",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330027",
      "brgy_name": "Tagulo",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330028",
      "brgy_name": "Tinotungan",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097330029",
      "brgy_name": "Upper Bayao",
      "city_code": "097330",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332001",
      "brgy_name": "Arena Blanco",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332002",
      "brgy_name": "Ayala",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332004",
      "brgy_name": "Baliwasan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332005",
      "brgy_name": "Baluno",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332010",
      "brgy_name": "Boalan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332011",
      "brgy_name": "Bolong",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332012",
      "brgy_name": "Buenavista",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332013",
      "brgy_name": "Bunguiao",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332014",
      "brgy_name": "Busay (Sacol Island)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332015",
      "brgy_name": "Cabaluay",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332016",
      "brgy_name": "Cabatangan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332017",
      "brgy_name": "Cacao",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332018",
      "brgy_name": "Calabasa",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332019",
      "brgy_name": "Calarian",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332020",
      "brgy_name": "Campo Islam",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332021",
      "brgy_name": "Canelar",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332022",
      "brgy_name": "Cawit",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332023",
      "brgy_name": "Culianan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332024",
      "brgy_name": "Curuan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332025",
      "brgy_name": "Dita",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332026",
      "brgy_name": "Divisoria",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332027",
      "brgy_name": "Dulian (Upper Bunguiao)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332028",
      "brgy_name": "Dulian (Upper Pasonanca)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332030",
      "brgy_name": "Guisao",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332031",
      "brgy_name": "Guiwan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332032",
      "brgy_name": "La Paz",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332033",
      "brgy_name": "Labuan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332034",
      "brgy_name": "Lamisahan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332035",
      "brgy_name": "Landang Gua",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332036",
      "brgy_name": "Landang Laum",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332037",
      "brgy_name": "Lanzones",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332038",
      "brgy_name": "Lapakan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332039",
      "brgy_name": "Latuan (Curuan)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332040",
      "brgy_name": "Limaong",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332041",
      "brgy_name": "Limpapa",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332042",
      "brgy_name": "Lubigan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332043",
      "brgy_name": "Lumayang",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332044",
      "brgy_name": "Lumbangan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332045",
      "brgy_name": "Lunzuran",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332046",
      "brgy_name": "Maasin",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332047",
      "brgy_name": "Malagutay",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332048",
      "brgy_name": "Mampang",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332049",
      "brgy_name": "Manalipa",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332050",
      "brgy_name": "Mangusu",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332051",
      "brgy_name": "Manicahan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332052",
      "brgy_name": "Mariki",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332053",
      "brgy_name": "Mercedes",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332054",
      "brgy_name": "Muti",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332055",
      "brgy_name": "Pamucutan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332056",
      "brgy_name": "Pangapuyan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332057",
      "brgy_name": "Panubigan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332058",
      "brgy_name": "Pasilmanta (Sacol Island)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332059",
      "brgy_name": "Pasonanca",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332060",
      "brgy_name": "Patalon",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332061",
      "brgy_name": "Barangay Zone I (Pob.)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332062",
      "brgy_name": "Barangay Zone II (Pob.)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332063",
      "brgy_name": "Barangay Zone III (Pob.)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332064",
      "brgy_name": "Barangay Zone IV (Pob.)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332065",
      "brgy_name": "Putik",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332066",
      "brgy_name": "Quiniput",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332067",
      "brgy_name": "Recodo",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332068",
      "brgy_name": "Rio Hondo",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332069",
      "brgy_name": "Salaan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332070",
      "brgy_name": "San Jose Cawa-cawa",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332071",
      "brgy_name": "San Jose Gusu",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332072",
      "brgy_name": "San Roque",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332073",
      "brgy_name": "Sangali",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332074",
      "brgy_name": "Santa Barbara",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332075",
      "brgy_name": "Santa Catalina",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332076",
      "brgy_name": "Santa Maria",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332077",
      "brgy_name": "Santo Niño",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332078",
      "brgy_name": "Sibulao (Caruan)",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332079",
      "brgy_name": "Sinubung",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332080",
      "brgy_name": "Sinunoc",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332081",
      "brgy_name": "Tagasilay",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332082",
      "brgy_name": "Taguiti",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332083",
      "brgy_name": "Talabaan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332084",
      "brgy_name": "Talisayan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332085",
      "brgy_name": "Talon-talon",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332086",
      "brgy_name": "Taluksangay",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332087",
      "brgy_name": "Tetuan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332088",
      "brgy_name": "Tictapul",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332089",
      "brgy_name": "Tigbalabag",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332090",
      "brgy_name": "Tigtabon",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332091",
      "brgy_name": "Tolosa",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332092",
      "brgy_name": "Tugbungan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332093",
      "brgy_name": "Tulungatung",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332094",
      "brgy_name": "Tumaga",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332095",
      "brgy_name": "Tumalutab",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332096",
      "brgy_name": "Tumitus",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332097",
      "brgy_name": "Vitali",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332098",
      "brgy_name": "Capisan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332099",
      "brgy_name": "Camino Nuevo",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332100",
      "brgy_name": "Licomo",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332101",
      "brgy_name": "Kasanyangan",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332102",
      "brgy_name": "Pasobolong",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332103",
      "brgy_name": "Victoria",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097332104",
      "brgy_name": "Zambowood",
      "city_code": "097332",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333001",
      "brgy_name": "Baking",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333002",
      "brgy_name": "Bagong Kahayag",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333003",
      "brgy_name": "Biswangan",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333004",
      "brgy_name": "Bululawan",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333005",
      "brgy_name": "Dagum",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333006",
      "brgy_name": "Gasa",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333007",
      "brgy_name": "Gatub",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333008",
      "brgy_name": "Poblacion (Lakewood)",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333009",
      "brgy_name": "Lukuan",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333010",
      "brgy_name": "Matalang",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333011",
      "brgy_name": "Sapang Pinoles",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333012",
      "brgy_name": "Sebuguey",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333013",
      "brgy_name": "Tiwales",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097333014",
      "brgy_name": "Tubod",
      "city_code": "097333",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337001",
      "brgy_name": "Bogo Calabat",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337002",
      "brgy_name": "Dawa (Diwa)",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337003",
      "brgy_name": "Ebarle",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337004",
      "brgy_name": "Upper Bagong Tudela (Pob.)",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337005",
      "brgy_name": "Leonardo",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337006",
      "brgy_name": "Litapan",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337007",
      "brgy_name": "Lower Bagong Tudela",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337008",
      "brgy_name": "Mansanas",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337009",
      "brgy_name": "Moradji",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337010",
      "brgy_name": "Nemeño",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337011",
      "brgy_name": "Nopulan",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337012",
      "brgy_name": "Sebukang",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337013",
      "brgy_name": "Tagaytay Hill",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097337014",
      "brgy_name": "Gumahan (Pob.)",
      "city_code": "097337",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338001",
      "brgy_name": "Balabawan",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338002",
      "brgy_name": "Balong-balong",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338003",
      "brgy_name": "Colojo",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338004",
      "brgy_name": "Liasan",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338005",
      "brgy_name": "Liguac",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338006",
      "brgy_name": "Limbayan",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338007",
      "brgy_name": "Lower Paniki-an",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338008",
      "brgy_name": "Matin-ao",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338009",
      "brgy_name": "Panubigan",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338010",
      "brgy_name": "Poblacion (Pitogo)",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338011",
      "brgy_name": "Punta Flecha",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338012",
      "brgy_name": "Sugbay Dos",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338013",
      "brgy_name": "Tongao",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338014",
      "brgy_name": "Upper Paniki-an",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097338015",
      "brgy_name": "San Isidro",
      "city_code": "097338",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340001",
      "brgy_name": "Bag-ong Baroy",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340002",
      "brgy_name": "Bag-ong Oroquieta",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340003",
      "brgy_name": "Barubuhan",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340004",
      "brgy_name": "Bulanay",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340005",
      "brgy_name": "Datagan",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340006",
      "brgy_name": "Eastern Poblacion",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340007",
      "brgy_name": "Lantawan",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340008",
      "brgy_name": "Libertad",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340009",
      "brgy_name": "Lumangoy",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340010",
      "brgy_name": "New Carmen",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340011",
      "brgy_name": "Picturan",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340012",
      "brgy_name": "Poblacion",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340013",
      "brgy_name": "Rizal",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340014",
      "brgy_name": "San Miguel",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340015",
      "brgy_name": "Santo Niño",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340016",
      "brgy_name": "Sawa",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340017",
      "brgy_name": "Tungawan",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097340018",
      "brgy_name": "Upper Sicpao",
      "city_code": "097340",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341001",
      "brgy_name": "Bui-os",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341002",
      "brgy_name": "Cogon",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341003",
      "brgy_name": "Danan",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341004",
      "brgy_name": "Kabatan",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341005",
      "brgy_name": "Kapatagan",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341006",
      "brgy_name": "Limason",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341007",
      "brgy_name": "Linoguayan",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341008",
      "brgy_name": "Lumbal",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341009",
      "brgy_name": "Lunib",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341010",
      "brgy_name": "Maculay",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341011",
      "brgy_name": "Maraya",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341012",
      "brgy_name": "Sagucan",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341013",
      "brgy_name": "Waling-waling",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097341014",
      "brgy_name": "Ambulon",
      "city_code": "097341",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343001",
      "brgy_name": "Bagong Oroquieta",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343002",
      "brgy_name": "Baguitan",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343003",
      "brgy_name": "Balongating",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343004",
      "brgy_name": "Canunan",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343005",
      "brgy_name": "Dacsol",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343006",
      "brgy_name": "Dagohoy",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343007",
      "brgy_name": "Dalapang",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343008",
      "brgy_name": "Datagan",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343009",
      "brgy_name": "Poblacion (Guipos)",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343010",
      "brgy_name": "Guling",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343011",
      "brgy_name": "Katipunan",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343012",
      "brgy_name": "Lintum",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343013",
      "brgy_name": "Litan",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343014",
      "brgy_name": "Magting",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343015",
      "brgy_name": "Regla",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343016",
      "brgy_name": "Sikatuna",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097343017",
      "brgy_name": "Singclot",
      "city_code": "097343",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344001",
      "brgy_name": "Begong",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344002",
      "brgy_name": "Busol",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344003",
      "brgy_name": "Caluma",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344004",
      "brgy_name": "Diana Countryside",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344005",
      "brgy_name": "Guinlin",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344006",
      "brgy_name": "Lacarayan",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344007",
      "brgy_name": "Lacupayan",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344008",
      "brgy_name": "Libayoy",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344009",
      "brgy_name": "Limas",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344010",
      "brgy_name": "Longmot",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344011",
      "brgy_name": "Maragang",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344012",
      "brgy_name": "Mate",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344013",
      "brgy_name": "Nangan-nangan",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344014",
      "brgy_name": "New Tuburan",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344015",
      "brgy_name": "Nilo",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344016",
      "brgy_name": "Tigbao",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344017",
      "brgy_name": "Timolan",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "097344018",
      "brgy_name": "Upper Nilo",
      "city_code": "097344",
      "province_code": "0973",
      "region_code": "09"
    },
    {
      "brgy_code": "098301001",
      "brgy_name": "Alegria",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301002",
      "brgy_name": "Milagrosa (Baluno)",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301004",
      "brgy_name": "Bella",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301005",
      "brgy_name": "Calades",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301006",
      "brgy_name": "Dawa-dawa",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301007",
      "brgy_name": "Gulayon",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301008",
      "brgy_name": "Ilisan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301009",
      "brgy_name": "Kawayan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301010",
      "brgy_name": "Kauswagan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301011",
      "brgy_name": "La Paz",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301012",
      "brgy_name": "Lambuyogan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301013",
      "brgy_name": "Lapirawan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301014",
      "brgy_name": "Litayon",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301015",
      "brgy_name": "Lutiman",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301016",
      "brgy_name": "Naga-naga",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301017",
      "brgy_name": "Pandan-pandan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301018",
      "brgy_name": "Payongan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301019",
      "brgy_name": "Poblacion",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301020",
      "brgy_name": "Santa Maria",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301021",
      "brgy_name": "Santo Niño",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301022",
      "brgy_name": "Talaptap",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301023",
      "brgy_name": "Tampalan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301024",
      "brgy_name": "Tandiong Muslim",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301025",
      "brgy_name": "Timbang-timbang",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301026",
      "brgy_name": "Bagong Buhay",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301027",
      "brgy_name": "Concepcion",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098301028",
      "brgy_name": "Kapatagan",
      "city_code": "098301",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302002",
      "brgy_name": "Basalem",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302003",
      "brgy_name": "Bawang",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302004",
      "brgy_name": "Bulaan",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302005",
      "brgy_name": "Compostela",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302006",
      "brgy_name": "Del Monte",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302008",
      "brgy_name": "Guitom",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302009",
      "brgy_name": "Guminta",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302010",
      "brgy_name": "Labrador",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302011",
      "brgy_name": "Lantawan",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302012",
      "brgy_name": "Mabuhay",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302013",
      "brgy_name": "Maganay",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302014",
      "brgy_name": "Manlin",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302015",
      "brgy_name": "Muyo",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302016",
      "brgy_name": "Pamintayan",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302017",
      "brgy_name": "Poblacion",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302019",
      "brgy_name": "Talamimi",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302020",
      "brgy_name": "Villacastor (Galit)",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302021",
      "brgy_name": "Agutayan",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302022",
      "brgy_name": "Bagong Borbon",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302023",
      "brgy_name": "Bliss",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302024",
      "brgy_name": "Danlugan",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302025",
      "brgy_name": "Pling",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302026",
      "brgy_name": "Pulog",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302027",
      "brgy_name": "San Jose",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302028",
      "brgy_name": "Talairan",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302029",
      "brgy_name": "Datu Panas",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098302030",
      "brgy_name": "Guintuloan",
      "city_code": "098302",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303001",
      "brgy_name": "Balangao",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303003",
      "brgy_name": "Pilar",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303004",
      "brgy_name": "Poblacion (Diplahan)",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303005",
      "brgy_name": "Ditay",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303006",
      "brgy_name": "Gaulan",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303007",
      "brgy_name": "Goling",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303008",
      "brgy_name": "Guinoman",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303010",
      "brgy_name": "Kauswagan",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303011",
      "brgy_name": "Lindang",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303012",
      "brgy_name": "Lobing",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303015",
      "brgy_name": "Paradise",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303016",
      "brgy_name": "Sampoli A",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303017",
      "brgy_name": "Songcuya",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303018",
      "brgy_name": "Santa Cruz",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303019",
      "brgy_name": "Butong",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303025",
      "brgy_name": "Sampoli B",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303026",
      "brgy_name": "Luop",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303027",
      "brgy_name": "Manangon",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303028",
      "brgy_name": "Mejo",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303029",
      "brgy_name": "Natan",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303030",
      "brgy_name": "Tinongtongan",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098303031",
      "brgy_name": "Tuno",
      "city_code": "098303",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304001",
      "brgy_name": "Lower Baluran",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304002",
      "brgy_name": "Baluyan",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304003",
      "brgy_name": "Cana-an",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304004",
      "brgy_name": "Dumpoc",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304005",
      "brgy_name": "Gandiangan",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304006",
      "brgy_name": "Israel (Balian Israel)",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304007",
      "brgy_name": "La Victoria",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304008",
      "brgy_name": "Lumpanac",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304009",
      "brgy_name": "Little Baguio",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304010",
      "brgy_name": "Lumbog",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304011",
      "brgy_name": "Mali Little Baguio",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304012",
      "brgy_name": "Poblacion (Santa Fe)",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304013",
      "brgy_name": "San Jose",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304014",
      "brgy_name": "Santa Barbara",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304015",
      "brgy_name": "Balugo",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304016",
      "brgy_name": "Balungisan",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304017",
      "brgy_name": "Pulawan (Mt. View)",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098304018",
      "brgy_name": "Upper Baluran",
      "city_code": "098304",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305001",
      "brgy_name": "Bacalan",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305002",
      "brgy_name": "Bangkerohan",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305003",
      "brgy_name": "Bulu-an",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305006",
      "brgy_name": "Don Andres",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305008",
      "brgy_name": "Guituan",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305009",
      "brgy_name": "Ipil Heights",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305012",
      "brgy_name": "Labi",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305013",
      "brgy_name": "Lower Ipil Heights",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305015",
      "brgy_name": "Lower Taway",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305016",
      "brgy_name": "Lumbia",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305018",
      "brgy_name": "Magdaup",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305022",
      "brgy_name": "Pangi",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305023",
      "brgy_name": "Poblacion",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305024",
      "brgy_name": "Sanito",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305027",
      "brgy_name": "Suclema",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305029",
      "brgy_name": "Taway",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305030",
      "brgy_name": "Tenan",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305032",
      "brgy_name": "Tiayon",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305034",
      "brgy_name": "Timalang",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305035",
      "brgy_name": "Tomitom",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305037",
      "brgy_name": "Upper Pangi",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305038",
      "brgy_name": "Veteran's Village (Ruiz)",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305039",
      "brgy_name": "Makilas",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305040",
      "brgy_name": "Caparan",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305041",
      "brgy_name": "Domandan",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305042",
      "brgy_name": "Doña Josefa",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305043",
      "brgy_name": "Logan",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098305044",
      "brgy_name": "Maasin",
      "city_code": "098305",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306002",
      "brgy_name": "Banker",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306003",
      "brgy_name": "Bolo Batallion",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306004",
      "brgy_name": "Buayan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306006",
      "brgy_name": "Cainglet",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306007",
      "brgy_name": "Calapan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306008",
      "brgy_name": "Calubihan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306009",
      "brgy_name": "Concepcion (Balungis)",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306010",
      "brgy_name": "Dipala",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306011",
      "brgy_name": "Gacbusan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306012",
      "brgy_name": "Goodyear",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306013",
      "brgy_name": "Lacnapan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306014",
      "brgy_name": "Little Baguio",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306015",
      "brgy_name": "Lumbayao",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306016",
      "brgy_name": "Timuay Danda (Mangahas)",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306017",
      "brgy_name": "Nazareth",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306018",
      "brgy_name": "Palinta",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306019",
      "brgy_name": "Peñaranda",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306020",
      "brgy_name": "Poblacion",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306021",
      "brgy_name": "Riverside",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306022",
      "brgy_name": "Sanghanan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306023",
      "brgy_name": "Santa Cruz",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306024",
      "brgy_name": "Sayao",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306025",
      "brgy_name": "Simbol",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306026",
      "brgy_name": "Sininan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306027",
      "brgy_name": "Shiolan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306028",
      "brgy_name": "Tampilisan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306029",
      "brgy_name": "Tamin",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306030",
      "brgy_name": "Tigbangagan",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098306031",
      "brgy_name": "Diampak",
      "city_code": "098306",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307001",
      "brgy_name": "Abunda",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307002",
      "brgy_name": "Bagong Silang",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307003",
      "brgy_name": "Bangkaw-bangkaw",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307007",
      "brgy_name": "Caliran",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307008",
      "brgy_name": "Catipan",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307010",
      "brgy_name": "Tandu-Comot (Katipunan)",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307011",
      "brgy_name": "Kauswagan",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307013",
      "brgy_name": "Ligaya",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307014",
      "brgy_name": "Looc-Barlak",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307015",
      "brgy_name": "Malinao",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307017",
      "brgy_name": "Pamansaan",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307018",
      "brgy_name": "Pinalim (San Roque)",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307019",
      "brgy_name": "Poblacion",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307020",
      "brgy_name": "Punawan",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307023",
      "brgy_name": "Santo Niño (Caliran)",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307024",
      "brgy_name": "Sawa",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307025",
      "brgy_name": "Sioton",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098307026",
      "brgy_name": "Taguisian",
      "city_code": "098307",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308001",
      "brgy_name": "Bacao",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308002",
      "brgy_name": "Basak-bawang",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308003",
      "brgy_name": "Camanga",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308005",
      "brgy_name": "Candiis",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308006",
      "brgy_name": "Catituan",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308007",
      "brgy_name": "Dansulao",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308008",
      "brgy_name": "Del Pilar",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308011",
      "brgy_name": "Guilawa",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308013",
      "brgy_name": "Kigay",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308014",
      "brgy_name": "La Dicha",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308016",
      "brgy_name": "Lipacan",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308018",
      "brgy_name": "Logpond",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308020",
      "brgy_name": "Mabini",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308021",
      "brgy_name": "Malungon",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308023",
      "brgy_name": "Mulom",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308025",
      "brgy_name": "Overland",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308026",
      "brgy_name": "Palalian",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308027",
      "brgy_name": "Payag",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308028",
      "brgy_name": "Poblacion",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308031",
      "brgy_name": "Rebocon",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308032",
      "brgy_name": "San Vicente",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308036",
      "brgy_name": "Sinusayan",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308038",
      "brgy_name": "Tackling",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308039",
      "brgy_name": "Tigabon",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098308040",
      "brgy_name": "Bontong",
      "city_code": "098308",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309001",
      "brgy_name": "Aguinaldo",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309002",
      "brgy_name": "Baga",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309003",
      "brgy_name": "Baluno",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309004",
      "brgy_name": "Cabong",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309005",
      "brgy_name": "Gubawang",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309006",
      "brgy_name": "Kaliantana",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309007",
      "brgy_name": "La Paz",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309008",
      "brgy_name": "Lower Sulitan",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309010",
      "brgy_name": "Mamagon",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309011",
      "brgy_name": "Marsolo",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309012",
      "brgy_name": "Poblacion",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309013",
      "brgy_name": "San Isidro",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309014",
      "brgy_name": "Santa Clara",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309015",
      "brgy_name": "Crossing Sta. Clara",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309016",
      "brgy_name": "Sulo",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309017",
      "brgy_name": "Tambanan",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309018",
      "brgy_name": "Taytay Manubo",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309019",
      "brgy_name": "Upper Sulitan",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309020",
      "brgy_name": "Bangkaw-bangkaw",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309021",
      "brgy_name": "Guintoloan",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309022",
      "brgy_name": "Sandayong",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309023",
      "brgy_name": "Tilubog",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098309024",
      "brgy_name": "Tipan",
      "city_code": "098309",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310002",
      "brgy_name": "Bateria",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310003",
      "brgy_name": "Kahayagan",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310005",
      "brgy_name": "Calais (Kalines)",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310006",
      "brgy_name": "Esperanza",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310007",
      "brgy_name": "Fama",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310009",
      "brgy_name": "Galas",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310010",
      "brgy_name": "Gandaan",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310012",
      "brgy_name": "Looc Sapi",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310014",
      "brgy_name": "Matim",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310015",
      "brgy_name": "Noque",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310016",
      "brgy_name": "Solar (Pob.)",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310017",
      "brgy_name": "Pulo Laum",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310018",
      "brgy_name": "Pulo Mabao",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310019",
      "brgy_name": "San Isidro",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310020",
      "brgy_name": "Santa Maria",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310024",
      "brgy_name": "Tambanan",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310025",
      "brgy_name": "Villacorte",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310026",
      "brgy_name": "Villagonzalo",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098310027",
      "brgy_name": "San Jose",
      "city_code": "098310",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311001",
      "brgy_name": "Balian",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311002",
      "brgy_name": "Balungisan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311003",
      "brgy_name": "Bulacan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311004",
      "brgy_name": "Bulawan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311005",
      "brgy_name": "Calape",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311006",
      "brgy_name": "Dalama",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311007",
      "brgy_name": "Fatima (Silal)",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311008",
      "brgy_name": "Guintolan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311009",
      "brgy_name": "Katipunan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311010",
      "brgy_name": "Kima",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311011",
      "brgy_name": "Kulasian",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311012",
      "brgy_name": "Labatan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311013",
      "brgy_name": "Mountain View (Puluan)",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311014",
      "brgy_name": "Nanan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311015",
      "brgy_name": "Poblacion (Payao)",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311016",
      "brgy_name": "San Roque",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311017",
      "brgy_name": "San Vicente (Binangonan)",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311018",
      "brgy_name": "Mayabo (Santa Maria)",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311019",
      "brgy_name": "Minundas (Santo. Niño)",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311020",
      "brgy_name": "Talaptap",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311021",
      "brgy_name": "Balogo",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311022",
      "brgy_name": "Binangonan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311023",
      "brgy_name": "Guiwan",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311024",
      "brgy_name": "Kulisap",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311025",
      "brgy_name": "La Fortuna",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311026",
      "brgy_name": "San Isidro",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311027",
      "brgy_name": "Silal",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311028",
      "brgy_name": "Sumilong",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098311029",
      "brgy_name": "Upper Sumilong",
      "city_code": "098311",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312001",
      "brgy_name": "Balansag",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312002",
      "brgy_name": "Calula",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312003",
      "brgy_name": "Casacon",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312004",
      "brgy_name": "Gango",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312005",
      "brgy_name": "Katipunan",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312006",
      "brgy_name": "Kulambugan",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312007",
      "brgy_name": "Mabini",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312008",
      "brgy_name": "Magsaysay",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312009",
      "brgy_name": "New Antique",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312010",
      "brgy_name": "New Sagay",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312011",
      "brgy_name": "Pres. Roxas",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312012",
      "brgy_name": "San Antonio",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312013",
      "brgy_name": "San Fernandino",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312014",
      "brgy_name": "San Jose",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312015",
      "brgy_name": "Siawang",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312016",
      "brgy_name": "Silingan",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312017",
      "brgy_name": "Surabay",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312018",
      "brgy_name": "Tilasan",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312019",
      "brgy_name": "Tupilac",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312020",
      "brgy_name": "Ali Alsree",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312021",
      "brgy_name": "Don Perfecto",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312022",
      "brgy_name": "Malubal",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312023",
      "brgy_name": "Palmera",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312024",
      "brgy_name": "Remedios",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312025",
      "brgy_name": "Santo Rosario",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098312026",
      "brgy_name": "Taruc",
      "city_code": "098312",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313001",
      "brgy_name": "Balucanan",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313002",
      "brgy_name": "Balagon",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313005",
      "brgy_name": "Balingasan",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313006",
      "brgy_name": "Batu",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313008",
      "brgy_name": "Buyogan",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313010",
      "brgy_name": "Camanga",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313011",
      "brgy_name": "Coloran",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313018",
      "brgy_name": "Kimos (Kima)",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313021",
      "brgy_name": "Labasan",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313022",
      "brgy_name": "Laih",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313023",
      "brgy_name": "Magsaysay",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313024",
      "brgy_name": "Mahayahay",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313025",
      "brgy_name": "Maligaya",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313026",
      "brgy_name": "Maniha",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313027",
      "brgy_name": "Minsulao",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313028",
      "brgy_name": "Mirangan",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313029",
      "brgy_name": "Monching",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313033",
      "brgy_name": "Paruk",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313036",
      "brgy_name": "Poblacion",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313037",
      "brgy_name": "Princesa Sumama",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313040",
      "brgy_name": "Sibuguey",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313041",
      "brgy_name": "Bagong Silang",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313042",
      "brgy_name": "Bataan",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313043",
      "brgy_name": "Lagting",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313044",
      "brgy_name": "Logpond",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313045",
      "brgy_name": "Salinding",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313046",
      "brgy_name": "San Isidro",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313047",
      "brgy_name": "Siloh",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098313048",
      "brgy_name": "Villagracia",
      "city_code": "098313",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314001",
      "brgy_name": "Aurora",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314002",
      "brgy_name": "Baganipay",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314003",
      "brgy_name": "Bolingan",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314004",
      "brgy_name": "Bualan",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314005",
      "brgy_name": "Cawilan",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314006",
      "brgy_name": "Florida",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314007",
      "brgy_name": "Kasigpitan",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314008",
      "brgy_name": "Laparay",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314009",
      "brgy_name": "Mahayahay",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314010",
      "brgy_name": "Moalboal",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314011",
      "brgy_name": "Sagay",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314012",
      "brgy_name": "Samonte",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314013",
      "brgy_name": "Poblacion (Talusan)",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098314014",
      "brgy_name": "Tuburan",
      "city_code": "098314",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315001",
      "brgy_name": "Achasol",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315002",
      "brgy_name": "Bangco",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315003",
      "brgy_name": "Camanga",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315004",
      "brgy_name": "Culasian",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315005",
      "brgy_name": "Dalangin",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315007",
      "brgy_name": "Dalisay",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315008",
      "brgy_name": "Gomotoc",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315009",
      "brgy_name": "Imelda (Upper Camanga)",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315010",
      "brgy_name": "Kipit",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315011",
      "brgy_name": "Kitabog",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315012",
      "brgy_name": "La Libertad",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315013",
      "brgy_name": "Longilog",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315014",
      "brgy_name": "Mabini",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315015",
      "brgy_name": "Malagandis",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315016",
      "brgy_name": "Mate",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315017",
      "brgy_name": "Moalboal",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315018",
      "brgy_name": "Namnama",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315019",
      "brgy_name": "New Canaan",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315020",
      "brgy_name": "Palomoc",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315021",
      "brgy_name": "Poblacion (Titay)",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315023",
      "brgy_name": "Pulidan",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315024",
      "brgy_name": "San Antonio",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315025",
      "brgy_name": "Santa Fe",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315026",
      "brgy_name": "Supit",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315027",
      "brgy_name": "Tugop",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315028",
      "brgy_name": "Tugop Muslim",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315029",
      "brgy_name": "Dalangin Muslim",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315030",
      "brgy_name": "Poblacion Muslim",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315031",
      "brgy_name": "Azusano",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098315032",
      "brgy_name": "San Isidro",
      "city_code": "098315",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316001",
      "brgy_name": "Baluran",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316002",
      "brgy_name": "Cayamcam",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316004",
      "brgy_name": "Langon",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316005",
      "brgy_name": "Libertad (Pob.)",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316006",
      "brgy_name": "Linguisan",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316007",
      "brgy_name": "Looc-labuan",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316008",
      "brgy_name": "Lower Tungawan",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316009",
      "brgy_name": "Malungon",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316010",
      "brgy_name": "San Pedro",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316011",
      "brgy_name": "Tigbanuang",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316012",
      "brgy_name": "Tigbucay",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316013",
      "brgy_name": "Upper Tungawan",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316014",
      "brgy_name": "San Vicente",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316015",
      "brgy_name": "Batungan",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316016",
      "brgy_name": "Loboc",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316017",
      "brgy_name": "San Isidro",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316018",
      "brgy_name": "Datu Tumanggong",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316019",
      "brgy_name": "Sisay",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316020",
      "brgy_name": "Gaycon",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316021",
      "brgy_name": "Little Margos",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316022",
      "brgy_name": "Santo Niño",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316023",
      "brgy_name": "Taglibas",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316024",
      "brgy_name": "Tigpalay",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316025",
      "brgy_name": "Masao",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "098316026",
      "brgy_name": "Timbabauan",
      "city_code": "098316",
      "province_code": "0983",
      "region_code": "09"
    },
    {
      "brgy_code": "099701002",
      "brgy_name": "Aguada",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701004",
      "brgy_name": "Balatanay",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701005",
      "brgy_name": "Baluno",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701006",
      "brgy_name": "Begang",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701007",
      "brgy_name": "Binuangan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701008",
      "brgy_name": "Busay",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701010",
      "brgy_name": "Cabunbata",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701011",
      "brgy_name": "Calvario",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701012",
      "brgy_name": "Carbon",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701013",
      "brgy_name": "Diki",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701014",
      "brgy_name": "Isabela Eastside (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701015",
      "brgy_name": "Isabela Proper (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701016",
      "brgy_name": "Dona Ramona T. Alano (Isabela Westsite)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701017",
      "brgy_name": "Kapatagan Grande",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701018",
      "brgy_name": "Kaumpurnah Zone I",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701019",
      "brgy_name": "Kaumpurnah Zone II",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701020",
      "brgy_name": "Kaumpurnah Zone III",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701021",
      "brgy_name": "Kumalarang",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701022",
      "brgy_name": "La Piedad (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701023",
      "brgy_name": "Lampinigan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701024",
      "brgy_name": "Lanote",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701027",
      "brgy_name": "Lukbuton",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701029",
      "brgy_name": "Lumbang",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701030",
      "brgy_name": "Makiri",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701031",
      "brgy_name": "Maligue (Lunot)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701033",
      "brgy_name": "Marang-marang",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701034",
      "brgy_name": "Marketsite (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701036",
      "brgy_name": "Menzi",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701038",
      "brgy_name": "Panigayan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701039",
      "brgy_name": "Panunsulan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701040",
      "brgy_name": "Port Area (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701041",
      "brgy_name": "Riverside",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701042",
      "brgy_name": "San Rafael",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701043",
      "brgy_name": "Santa Barbara",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701044",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701045",
      "brgy_name": "Seaside (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701047",
      "brgy_name": "Sumagdang",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701049",
      "brgy_name": "Sunrise Village (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701050",
      "brgy_name": "Tabiawan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701051",
      "brgy_name": "Tabuk (Pob.)",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701055",
      "brgy_name": "Timpul",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701057",
      "brgy_name": "Kapayawan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701058",
      "brgy_name": "Masula",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701059",
      "brgy_name": "Small Kapatagan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "099701060",
      "brgy_name": "Tampalan",
      "city_code": "099701",
      "province_code": "0997",
      "region_code": "09"
    },
    {
      "brgy_code": "101301001",
      "brgy_name": "Balintad",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301002",
      "brgy_name": "Buenavista",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301003",
      "brgy_name": "Danatag",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301004",
      "brgy_name": "Kalilangan",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301005",
      "brgy_name": "Lacolac",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301006",
      "brgy_name": "Langaon",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301007",
      "brgy_name": "Liboran",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301008",
      "brgy_name": "Lingating",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301009",
      "brgy_name": "Mabuhay",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301010",
      "brgy_name": "Mabunga",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301011",
      "brgy_name": "Nicdao",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301012",
      "brgy_name": "Imbatug (Pob.)",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301013",
      "brgy_name": "Pualas",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301014",
      "brgy_name": "Salimbalan",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301015",
      "brgy_name": "San Vicente",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101301016",
      "brgy_name": "San Miguel",
      "city_code": "101301",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302001",
      "brgy_name": "Aludas",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302002",
      "brgy_name": "Angga-an",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302003",
      "brgy_name": "Tangkulan (Jose Rizal)",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302004",
      "brgy_name": "Kinapat",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302005",
      "brgy_name": "Kiraon",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302006",
      "brgy_name": "Kitingting",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302007",
      "brgy_name": "Lagandang",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302008",
      "brgy_name": "Macapari",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302009",
      "brgy_name": "Maican",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302010",
      "brgy_name": "Migcawayan",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302011",
      "brgy_name": "New Compostela",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302013",
      "brgy_name": "Old Damulog",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302014",
      "brgy_name": "Omonay",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302015",
      "brgy_name": "Poblacion (New Damulog)",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302016",
      "brgy_name": "Pocopoco",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302017",
      "brgy_name": "Sampagar",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101302019",
      "brgy_name": "San Isidro",
      "city_code": "101302",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303001",
      "brgy_name": "Barongcot",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303002",
      "brgy_name": "Bugwak",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303003",
      "brgy_name": "Dolorosa",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303004",
      "brgy_name": "Kapalaran",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303005",
      "brgy_name": "Kianggat",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303006",
      "brgy_name": "Lourdes",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303007",
      "brgy_name": "Macarthur",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303008",
      "brgy_name": "Miaray",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303009",
      "brgy_name": "Migcuya",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303010",
      "brgy_name": "New Visayas",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303011",
      "brgy_name": "Osmeña",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303012",
      "brgy_name": "Poblacion",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303013",
      "brgy_name": "Sagbayan",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101303014",
      "brgy_name": "San Vicente",
      "city_code": "101303",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304001",
      "brgy_name": "Cabadiangan",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304002",
      "brgy_name": "Bocboc",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304003",
      "brgy_name": "Buyot",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304004",
      "brgy_name": "Calaocalao",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304005",
      "brgy_name": "Don Carlos Norte",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304006",
      "brgy_name": "Embayao",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304008",
      "brgy_name": "Kalubihon",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304009",
      "brgy_name": "Kasigkot",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304010",
      "brgy_name": "Kawilihan",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304011",
      "brgy_name": "Kiara",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304012",
      "brgy_name": "Kibatang",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304013",
      "brgy_name": "Mahayahay",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304014",
      "brgy_name": "Manlamonay",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304015",
      "brgy_name": "Maraymaray",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304016",
      "brgy_name": "Mauswagon",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304017",
      "brgy_name": "Minsalagan",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304018",
      "brgy_name": "New Nongnongan (Masimag)",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304019",
      "brgy_name": "New Visayas",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304020",
      "brgy_name": "Old Nongnongan",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304021",
      "brgy_name": "Pinamaloy",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304022",
      "brgy_name": "Don Carlos Sur (Pob.)",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304023",
      "brgy_name": "Pualas",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304024",
      "brgy_name": "San Antonio East",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304025",
      "brgy_name": "San Antonio West",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304026",
      "brgy_name": "San Francisco",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304027",
      "brgy_name": "San Nicolas (Banban)",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304028",
      "brgy_name": "San Roque",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304029",
      "brgy_name": "Sinangguyan",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101304030",
      "brgy_name": "Bismartz",
      "city_code": "101304",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305001",
      "brgy_name": "Bontongon",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305002",
      "brgy_name": "Bulonay",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305003",
      "brgy_name": "Capitan Bayong",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305004",
      "brgy_name": "Cawayan",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305005",
      "brgy_name": "Dumalaguing",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305006",
      "brgy_name": "Guihean",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305007",
      "brgy_name": "Hagpa",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305008",
      "brgy_name": "Impalutao",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305010",
      "brgy_name": "Kalabugao",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305011",
      "brgy_name": "Kibenton",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305012",
      "brgy_name": "La Fortuna",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305013",
      "brgy_name": "Poblacion",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101305015",
      "brgy_name": "Sayawan",
      "city_code": "101305",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306001",
      "brgy_name": "Bagongbayan",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306002",
      "brgy_name": "Bagor",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306003",
      "brgy_name": "Balaoro",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306004",
      "brgy_name": "Baroy",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306005",
      "brgy_name": "Cabadiangan",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306006",
      "brgy_name": "Husayan",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306007",
      "brgy_name": "Kibalagon",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306008",
      "brgy_name": "Mabuhay",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306009",
      "brgy_name": "Malinao",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306010",
      "brgy_name": "Matampay",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306011",
      "brgy_name": "Sibonga",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306012",
      "brgy_name": "Pay-as",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306013",
      "brgy_name": "Pinamanguhan",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306014",
      "brgy_name": "Poblacion",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306015",
      "brgy_name": "Salvacion",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306016",
      "brgy_name": "San Andres",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101306017",
      "brgy_name": "Kibogtok",
      "city_code": "101306",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307001",
      "brgy_name": "Bangbang",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307002",
      "brgy_name": "Baborawon",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307003",
      "brgy_name": "Canituan",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307004",
      "brgy_name": "Kibaning",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307005",
      "brgy_name": "Kinura",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307006",
      "brgy_name": "Lampanusan",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307007",
      "brgy_name": "Maca-opao",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307008",
      "brgy_name": "Malinao",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307009",
      "brgy_name": "Pamotolon (Pamotdon)",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307010",
      "brgy_name": "Poblacion",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307012",
      "brgy_name": "Public",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307013",
      "brgy_name": "Ninoy Aquino",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307014",
      "brgy_name": "San Vicente Ferrer",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101307015",
      "brgy_name": "West Poblacion",
      "city_code": "101307",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308003",
      "brgy_name": "Balintawak",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308006",
      "brgy_name": "Cagawasan",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308007",
      "brgy_name": "East Kibawe (Pob.)",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308008",
      "brgy_name": "Gutapol",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308011",
      "brgy_name": "Pinamula",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308013",
      "brgy_name": "Kiorao",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308014",
      "brgy_name": "Kisawa",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308015",
      "brgy_name": "Labuagon",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308016",
      "brgy_name": "Magsaysay",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308018",
      "brgy_name": "Marapangi",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308019",
      "brgy_name": "Mascariñas",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308020",
      "brgy_name": "Natulongan",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308021",
      "brgy_name": "New Kidapawan",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308023",
      "brgy_name": "Old Kibawe",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308028",
      "brgy_name": "Romagooc",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308030",
      "brgy_name": "Sampaguita",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308031",
      "brgy_name": "Sanipon",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308032",
      "brgy_name": "Spring",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308033",
      "brgy_name": "Talahiron",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308034",
      "brgy_name": "Tumaras",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308035",
      "brgy_name": "West Kibawe (Pob.)",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308036",
      "brgy_name": "Bukang Liwayway",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101308037",
      "brgy_name": "Palma",
      "city_code": "101308",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309001",
      "brgy_name": "Balangigay",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309002",
      "brgy_name": "Balukbukan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309003",
      "brgy_name": "Bershiba",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309004",
      "brgy_name": "Bobong",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309005",
      "brgy_name": "Bolocaon",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309006",
      "brgy_name": "Cabalantian",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309007",
      "brgy_name": "Calapaton",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309009",
      "brgy_name": "Sinaysayan (Dalurong)",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309010",
      "brgy_name": "Kahusayan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309011",
      "brgy_name": "Kalumihan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309012",
      "brgy_name": "Kauyonan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309013",
      "brgy_name": "Kimolong",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309014",
      "brgy_name": "Kitaihon",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309015",
      "brgy_name": "Kitobo",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309019",
      "brgy_name": "Magsaysay",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309020",
      "brgy_name": "Malobalo",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309021",
      "brgy_name": "Metebagao",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309022",
      "brgy_name": "Sagundanon",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309023",
      "brgy_name": "Pagan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309025",
      "brgy_name": "Panganan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309026",
      "brgy_name": "Poblacion",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309028",
      "brgy_name": "San Isidro",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309029",
      "brgy_name": "San Lorenzo",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309030",
      "brgy_name": "Santo Rosario",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309031",
      "brgy_name": "Sinuda (Simod)",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309032",
      "brgy_name": "Tandong",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309033",
      "brgy_name": "Tawas",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309034",
      "brgy_name": "White Kulaman",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309035",
      "brgy_name": "Napalico",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309037",
      "brgy_name": "Digongan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309038",
      "brgy_name": "Kiulom",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309039",
      "brgy_name": "Binoongan",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309040",
      "brgy_name": "Kipilas",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309041",
      "brgy_name": "East Dalurong",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101309042",
      "brgy_name": "West Dalurong",
      "city_code": "101309",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310001",
      "brgy_name": "Alanib",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310002",
      "brgy_name": "Baclayon",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310003",
      "brgy_name": "Balila",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310004",
      "brgy_name": "Bantuanon",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310005",
      "brgy_name": "Basak",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310006",
      "brgy_name": "Bugcaon",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310007",
      "brgy_name": "Ka-atoan (Kaatuan)",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310008",
      "brgy_name": "Capitan Juan",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310009",
      "brgy_name": "Cawayan",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310010",
      "brgy_name": "Kulasihan",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310011",
      "brgy_name": "Kibangay",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310012",
      "brgy_name": "Poblacion",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310013",
      "brgy_name": "Songco",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101310014",
      "brgy_name": "Victory",
      "city_code": "101310",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311001",
      "brgy_name": "Capihan",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311002",
      "brgy_name": "Crossing",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311003",
      "brgy_name": "Gango",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311004",
      "brgy_name": "Kiliog",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311005",
      "brgy_name": "Kinawe",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311006",
      "brgy_name": "Laturan",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311007",
      "brgy_name": "Maambong",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311008",
      "brgy_name": "Nangka",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311009",
      "brgy_name": "Palabucan",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311010",
      "brgy_name": "Poblacion",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311011",
      "brgy_name": "Pongol",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311012",
      "brgy_name": "San Jose",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311013",
      "brgy_name": "Santa Fe",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101311014",
      "brgy_name": "Sil-ipon",
      "city_code": "101311",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312001",
      "brgy_name": "Aglayan",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312002",
      "brgy_name": "Bangcud",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312003",
      "brgy_name": "Busdi",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312004",
      "brgy_name": "Cabangahan",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312007",
      "brgy_name": "Caburacanan",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312009",
      "brgy_name": "Canayan",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312010",
      "brgy_name": "Capitan Angel",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312012",
      "brgy_name": "Casisang",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312014",
      "brgy_name": "Dalwangan",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312017",
      "brgy_name": "Imbayao",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312018",
      "brgy_name": "Indalaza",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312019",
      "brgy_name": "Kalasungay",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312021",
      "brgy_name": "Kabalabag",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312022",
      "brgy_name": "Kulaman",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312023",
      "brgy_name": "Laguitas",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312025",
      "brgy_name": "Patpat (Lapu-lapu)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312026",
      "brgy_name": "Linabo",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312027",
      "brgy_name": "Apo Macote",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312028",
      "brgy_name": "Miglamin",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312029",
      "brgy_name": "Magsaysay",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312030",
      "brgy_name": "Maligaya",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312031",
      "brgy_name": "Managok",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312032",
      "brgy_name": "Manalog",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312034",
      "brgy_name": "Mapayag",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312035",
      "brgy_name": "Mapulo",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312038",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312045",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312046",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312047",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312048",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312049",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312050",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312051",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312052",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312053",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312054",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312058",
      "brgy_name": "Saint Peter",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312059",
      "brgy_name": "San Jose",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312060",
      "brgy_name": "San Martin",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312061",
      "brgy_name": "Santo Niño",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312062",
      "brgy_name": "Silae",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312063",
      "brgy_name": "Simaya",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312064",
      "brgy_name": "Sinanglanan",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312065",
      "brgy_name": "Sumpong",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312066",
      "brgy_name": "Violeta",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101312067",
      "brgy_name": "Zamboanguita",
      "city_code": "101312",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313001",
      "brgy_name": "Kalingking",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313002",
      "brgy_name": "Kiabo",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313003",
      "brgy_name": "Mindagat",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313004",
      "brgy_name": "Omagling",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313005",
      "brgy_name": "Patpat",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313006",
      "brgy_name": "Poblacion",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313007",
      "brgy_name": "Sampiano",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313008",
      "brgy_name": "San Luis",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313009",
      "brgy_name": "Santa Ines",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313010",
      "brgy_name": "Silo-o",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101313011",
      "brgy_name": "Sumalsag",
      "city_code": "101313",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314001",
      "brgy_name": "Agusan Canyon",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314002",
      "brgy_name": "Alae",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314003",
      "brgy_name": "Dahilayan",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314004",
      "brgy_name": "Dalirig",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314005",
      "brgy_name": "Damilag",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314006",
      "brgy_name": "Diclum",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314007",
      "brgy_name": "Guilang-guilang",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314008",
      "brgy_name": "Kalugmanan",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314009",
      "brgy_name": "Lindaban",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314010",
      "brgy_name": "Lingion",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314011",
      "brgy_name": "Lunocan",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314012",
      "brgy_name": "Maluko",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314013",
      "brgy_name": "Mambatangan",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314014",
      "brgy_name": "Mampayag",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314015",
      "brgy_name": "Minsuro",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314016",
      "brgy_name": "Mantibugao",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314017",
      "brgy_name": "Tankulan (Pob.)",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314018",
      "brgy_name": "San Miguel",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314019",
      "brgy_name": "Sankanan",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314020",
      "brgy_name": "Santiago",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314021",
      "brgy_name": "Santo Niño",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101314023",
      "brgy_name": "Ticala",
      "city_code": "101314",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315001",
      "brgy_name": "Anahawon",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315002",
      "brgy_name": "Base Camp",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315003",
      "brgy_name": "Bayabason (Spring)",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315004",
      "brgy_name": "Camp I",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315005",
      "brgy_name": "Colambugan",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315006",
      "brgy_name": "Dagumba-an",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315007",
      "brgy_name": "Danggawan",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315008",
      "brgy_name": "Dologon",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315009",
      "brgy_name": "Kisanday",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315010",
      "brgy_name": "Kuya",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315011",
      "brgy_name": "La Roxas",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315012",
      "brgy_name": "Panadtalan",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315013",
      "brgy_name": "Panalsalan",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315014",
      "brgy_name": "North Poblacion",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315015",
      "brgy_name": "South Poblacion",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315016",
      "brgy_name": "San Miguel",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315017",
      "brgy_name": "San Roque",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315018",
      "brgy_name": "Tubigon",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315019",
      "brgy_name": "Bagongsilang",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101315020",
      "brgy_name": "Kiharong",
      "city_code": "101315",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316001",
      "brgy_name": "Adtuyon",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316002",
      "brgy_name": "Bacusanon",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316003",
      "brgy_name": "Bangahan",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316004",
      "brgy_name": "Barandias",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316005",
      "brgy_name": "Concepcion",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316006",
      "brgy_name": "Gandingan",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316007",
      "brgy_name": "Kimanait",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316008",
      "brgy_name": "Kipadukan",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316009",
      "brgy_name": "Langcataon",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316010",
      "brgy_name": "Lantay",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316011",
      "brgy_name": "Madaya",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316012",
      "brgy_name": "Malipayon",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316013",
      "brgy_name": "Mendis",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316014",
      "brgy_name": "Nabaliwa",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316015",
      "brgy_name": "New Eden",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316016",
      "brgy_name": "Payad",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316017",
      "brgy_name": "Pigtauranan",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316018",
      "brgy_name": "Poblacion",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101316019",
      "brgy_name": "Portulin",
      "city_code": "101316",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317001",
      "brgy_name": "Butong",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317002",
      "brgy_name": "Cebole",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317003",
      "brgy_name": "Delapa",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317004",
      "brgy_name": "Dumalama",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317006",
      "brgy_name": "C-Handumanan",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317007",
      "brgy_name": "Cawayan",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317008",
      "brgy_name": "Kiburiao",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317009",
      "brgy_name": "Kipaypayon",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317010",
      "brgy_name": "Libertad",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317012",
      "brgy_name": "Linabo",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317013",
      "brgy_name": "Lipa",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317014",
      "brgy_name": "Lumintao",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317016",
      "brgy_name": "Magsaysay",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317017",
      "brgy_name": "Mahayag",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317018",
      "brgy_name": "Manuto",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317019",
      "brgy_name": "Merangerang",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317020",
      "brgy_name": "Mibantang",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317021",
      "brgy_name": "Minongan",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317022",
      "brgy_name": "Minsamongan",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317025",
      "brgy_name": "Paitan",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317026",
      "brgy_name": "Palacapao",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317027",
      "brgy_name": "Pinilayan",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317028",
      "brgy_name": "Poblacion (Kiokong)",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317029",
      "brgy_name": "Puntian",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317030",
      "brgy_name": "Salawagan",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317031",
      "brgy_name": "San Isidro",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317032",
      "brgy_name": "San Jose",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317033",
      "brgy_name": "San Roque",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317034",
      "brgy_name": "Santa Cruz",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317035",
      "brgy_name": "Santa Filomena",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101317036",
      "brgy_name": "Minsalirak",
      "city_code": "101317",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318001",
      "brgy_name": "Bonacao",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318002",
      "brgy_name": "Cabuling",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318003",
      "brgy_name": "Kawayan",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318004",
      "brgy_name": "Cayaga",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318005",
      "brgy_name": "Dao",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318007",
      "brgy_name": "Durian",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318009",
      "brgy_name": "Iglugsad",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318010",
      "brgy_name": "Kalagangan",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318014",
      "brgy_name": "Kibongcog",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318016",
      "brgy_name": "Little Baguio",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318019",
      "brgy_name": "Nacabuklad",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318020",
      "brgy_name": "Namnam",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318021",
      "brgy_name": "Palacpacan",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318022",
      "brgy_name": "Halapitan (Pob.)",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318023",
      "brgy_name": "San Jose",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318024",
      "brgy_name": "Santo Domingo",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318025",
      "brgy_name": "Tugop",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318026",
      "brgy_name": "Matupe",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318027",
      "brgy_name": "Bulalang",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318028",
      "brgy_name": "Candelaria",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318029",
      "brgy_name": "Mabuhay",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318030",
      "brgy_name": "Magkalungay",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318031",
      "brgy_name": "Malayanan",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101318032",
      "brgy_name": "Sacramento Valley",
      "city_code": "101318",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319001",
      "brgy_name": "Kisolon",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319002",
      "brgy_name": "Culasi",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319003",
      "brgy_name": "Licoan",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319004",
      "brgy_name": "Lupiagan",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319005",
      "brgy_name": "Ocasion",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319006",
      "brgy_name": "Puntian",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319007",
      "brgy_name": "San Roque",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319008",
      "brgy_name": "San Vicente",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319009",
      "brgy_name": "Poblacion",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101319010",
      "brgy_name": "Vista Villa",
      "city_code": "101319",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320001",
      "brgy_name": "Basak",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320002",
      "brgy_name": "Baylanan",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320003",
      "brgy_name": "Cacaon",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320004",
      "brgy_name": "Colawingon",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320005",
      "brgy_name": "Cosina",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320006",
      "brgy_name": "Dagumbaan",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320007",
      "brgy_name": "Dagundalahon",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320008",
      "brgy_name": "Dominorog",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320009",
      "brgy_name": "Lapok",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320010",
      "brgy_name": "Indulang",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320011",
      "brgy_name": "Lantud",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320013",
      "brgy_name": "Liguron",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320014",
      "brgy_name": "Lingi-on",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320015",
      "brgy_name": "Lirongan",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320016",
      "brgy_name": "Santo Niño (Lumbayawa)",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320018",
      "brgy_name": "Miarayon",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320019",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320020",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320021",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320022",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320023",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320024",
      "brgy_name": "Sagaran",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320025",
      "brgy_name": "Salucot",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320026",
      "brgy_name": "San Antonio",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320027",
      "brgy_name": "San Isidro",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320028",
      "brgy_name": "San Miguel",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320029",
      "brgy_name": "San Rafael",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320030",
      "brgy_name": "Tagbak",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101320031",
      "brgy_name": "Tikalaan",
      "city_code": "101320",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321001",
      "brgy_name": "Bagontaas",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321002",
      "brgy_name": "Banlag",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321003",
      "brgy_name": "Barobo",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321004",
      "brgy_name": "Batangan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321005",
      "brgy_name": "Catumbalon",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321006",
      "brgy_name": "Colonia",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321007",
      "brgy_name": "Concepcion",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321008",
      "brgy_name": "Dagat-Kidavao",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321010",
      "brgy_name": "Guinoyuran",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321011",
      "brgy_name": "Kahapunan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321012",
      "brgy_name": "Laligan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321013",
      "brgy_name": "Lilingayon",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321014",
      "brgy_name": "Lourdes",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321015",
      "brgy_name": "Lumbayao",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321016",
      "brgy_name": "Lumbo",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321017",
      "brgy_name": "Lurogan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321018",
      "brgy_name": "Maapag",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321019",
      "brgy_name": "Mabuhay",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321020",
      "brgy_name": "Mailag",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321021",
      "brgy_name": "Mt. Nebo",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321022",
      "brgy_name": "Nabago",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321023",
      "brgy_name": "Pinatilan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321024",
      "brgy_name": "Poblacion",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321025",
      "brgy_name": "San Carlos",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321026",
      "brgy_name": "San Isidro",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321028",
      "brgy_name": "Sinabuagan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321029",
      "brgy_name": "Sinayawan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321030",
      "brgy_name": "Sugod",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321033",
      "brgy_name": "Tongantongan",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321034",
      "brgy_name": "Tugaya",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101321035",
      "brgy_name": "Vintar",
      "city_code": "101321",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322001",
      "brgy_name": "Cabulohan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322002",
      "brgy_name": "Canangaan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322003",
      "brgy_name": "Iba",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322004",
      "brgy_name": "Imbatug",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322005",
      "brgy_name": "Lambangan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322006",
      "brgy_name": "Mandaing",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322007",
      "brgy_name": "Paradise",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322008",
      "brgy_name": "Poblacion",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322009",
      "brgy_name": "Anlogan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322010",
      "brgy_name": "Capinonan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322011",
      "brgy_name": "Dalacutan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322012",
      "brgy_name": "Freedom",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322013",
      "brgy_name": "Mandahikan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322014",
      "brgy_name": "Mauswagon",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101322015",
      "brgy_name": "Jasaan",
      "city_code": "101322",
      "province_code": "1013",
      "region_code": "10"
    },
    {
      "brgy_code": "101801001",
      "brgy_name": "Alga",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801002",
      "brgy_name": "Bonbon",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801003",
      "brgy_name": "Bura",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801004",
      "brgy_name": "Catibac",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801005",
      "brgy_name": "Compol",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801006",
      "brgy_name": "Lawigan",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801007",
      "brgy_name": "Liloan",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801008",
      "brgy_name": "Looc",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801009",
      "brgy_name": "Mainit",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801010",
      "brgy_name": "Manduao",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801011",
      "brgy_name": "Panghiawan",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801012",
      "brgy_name": "Poblacion",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801013",
      "brgy_name": "Santo Niño",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101801014",
      "brgy_name": "Tangaro",
      "city_code": "101801",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101802001",
      "brgy_name": "Butay",
      "city_code": "101802",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101802002",
      "brgy_name": "Cabuan",
      "city_code": "101802",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101802003",
      "brgy_name": "Cantaan",
      "city_code": "101802",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101802004",
      "brgy_name": "Liong",
      "city_code": "101802",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101802005",
      "brgy_name": "Maac",
      "city_code": "101802",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101802006",
      "brgy_name": "North Poblacion",
      "city_code": "101802",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101802007",
      "brgy_name": "South Poblacion",
      "city_code": "101802",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803001",
      "brgy_name": "Benoni",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803002",
      "brgy_name": "Binatubo (Binaliwan)",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803004",
      "brgy_name": "Catohugan",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803005",
      "brgy_name": "Hubangon",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803006",
      "brgy_name": "Owakan",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803007",
      "brgy_name": "Poblacion",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803008",
      "brgy_name": "Puntod",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803009",
      "brgy_name": "San Isidro",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803010",
      "brgy_name": "San Jose",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803011",
      "brgy_name": "San Miguel",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803012",
      "brgy_name": "San Roque",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803013",
      "brgy_name": "Tubod",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101803014",
      "brgy_name": "Tupsan Pequeño",
      "city_code": "101803",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804001",
      "brgy_name": "Agoho",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804002",
      "brgy_name": "Anito",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804003",
      "brgy_name": "Balbagon",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804004",
      "brgy_name": "Baylao",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804005",
      "brgy_name": "Benhaan",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804006",
      "brgy_name": "Bug-ong",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804007",
      "brgy_name": "Kuguita",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804008",
      "brgy_name": "Magting",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804009",
      "brgy_name": "Naasag",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804010",
      "brgy_name": "Pandan",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804011",
      "brgy_name": "Poblacion",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804015",
      "brgy_name": "Soro-soro",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804016",
      "brgy_name": "Tagdo",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804017",
      "brgy_name": "Tupsan",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101804018",
      "brgy_name": "Yumbing",
      "city_code": "101804",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805001",
      "brgy_name": "Alangilan",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805002",
      "brgy_name": "Bacnit",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805003",
      "brgy_name": "Balite",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805004",
      "brgy_name": "Bonbon",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805005",
      "brgy_name": "Bugang",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805006",
      "brgy_name": "Cuna",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805007",
      "brgy_name": "Manuyog",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805008",
      "brgy_name": "Mayana",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "101805009",
      "brgy_name": "Poblacion",
      "city_code": "101805",
      "province_code": "1018",
      "region_code": "10"
    },
    {
      "brgy_code": "103501001",
      "brgy_name": "Alegria",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501002",
      "brgy_name": "Babalaya",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501003",
      "brgy_name": "Babalayan Townsite",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501005",
      "brgy_name": "Binuni",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501006",
      "brgy_name": "Demologan",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501007",
      "brgy_name": "Dimarao",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501008",
      "brgy_name": "Esperanza",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501009",
      "brgy_name": "Kahayag",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501010",
      "brgy_name": "Liangan East",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501012",
      "brgy_name": "Punod (Maliwanag)",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501013",
      "brgy_name": "Mati",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501016",
      "brgy_name": "Minaulon",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501017",
      "brgy_name": "Pagayawan",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501018",
      "brgy_name": "Poblacion Bacolod",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501019",
      "brgy_name": "Rupagan",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103501020",
      "brgy_name": "Delabayan West",
      "city_code": "103501",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502001",
      "brgy_name": "Abaga",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502002",
      "brgy_name": "Adapun-Ali (Dariat)",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502003",
      "brgy_name": "Angandog (Bulao)",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502004",
      "brgy_name": "Angayen (Balut)",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502005",
      "brgy_name": "Bangko",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502006",
      "brgy_name": "Batolacongan (Basagad)",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502007",
      "brgy_name": "Buenavista",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502009",
      "brgy_name": "Cadayonan",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502012",
      "brgy_name": "Landa (Gadongan)",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502013",
      "brgy_name": "Lumbac",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502014",
      "brgy_name": "Mamaanun",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502015",
      "brgy_name": "Maria-Cristina",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502016",
      "brgy_name": "Matampay",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502017",
      "brgy_name": "Nangka",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502019",
      "brgy_name": "Pacalundo",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502022",
      "brgy_name": "Poblacion East",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502023",
      "brgy_name": "Poblacion West",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502025",
      "brgy_name": "Sandor (Daduan)",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502026",
      "brgy_name": "Sangcad (Cormatan )",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502027",
      "brgy_name": "Sarip-Alawi (Payawan)",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103502028",
      "brgy_name": "Sigayan",
      "city_code": "103502",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503001",
      "brgy_name": "Andil",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503002",
      "brgy_name": "Bagong Dawis",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503003",
      "brgy_name": "Baroy Daku",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503004",
      "brgy_name": "Bato",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503005",
      "brgy_name": "Cabasagan",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503006",
      "brgy_name": "Dalama",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503007",
      "brgy_name": "Libertad",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503008",
      "brgy_name": "Limwag",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503009",
      "brgy_name": "Lindongan",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503011",
      "brgy_name": "Maliwanag",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503012",
      "brgy_name": "Manan-ao",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503013",
      "brgy_name": "Pange",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503014",
      "brgy_name": "Pindolonan",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503015",
      "brgy_name": "Poblacion",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503016",
      "brgy_name": "Princesa",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503017",
      "brgy_name": "Rawan Point",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503018",
      "brgy_name": "Riverside",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503019",
      "brgy_name": "Sagadan (Sagadan Lower)",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503020",
      "brgy_name": "Salong",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503021",
      "brgy_name": "Tinubdan",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503022",
      "brgy_name": "Sagadan Upper",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503023",
      "brgy_name": "San Juan",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103503024",
      "brgy_name": "Village",
      "city_code": "103503",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504001",
      "brgy_name": "Abuno",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504002",
      "brgy_name": "Bonbonon",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504003",
      "brgy_name": "Bunawan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504005",
      "brgy_name": "Buru-un",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504006",
      "brgy_name": "Dalipuga",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504007",
      "brgy_name": "Digkilaan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504008",
      "brgy_name": "Hinaplanon",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504010",
      "brgy_name": "Kabacsanan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504011",
      "brgy_name": "Kiwalan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504012",
      "brgy_name": "Mahayhay",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504013",
      "brgy_name": "Mainit",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504014",
      "brgy_name": "Mandulog",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504015",
      "brgy_name": "Maria Cristina",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504016",
      "brgy_name": "Palao",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504017",
      "brgy_name": "Poblacion",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504018",
      "brgy_name": "Puga-an",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504019",
      "brgy_name": "Rogongon",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504021",
      "brgy_name": "Santa Elena",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504022",
      "brgy_name": "Santa Filomena",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504024",
      "brgy_name": "Suarez",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504025",
      "brgy_name": "Tambacan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504026",
      "brgy_name": "Saray-Tibanga",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504027",
      "brgy_name": "Tipanoy",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504028",
      "brgy_name": "Tominobo Proper",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504029",
      "brgy_name": "Tominobo Upper",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504030",
      "brgy_name": "Tubod",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504031",
      "brgy_name": "Bagong Silang",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504032",
      "brgy_name": "Del Carmen",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504033",
      "brgy_name": "Dulag",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504034",
      "brgy_name": "San Miguel",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504035",
      "brgy_name": "Santiago",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504036",
      "brgy_name": "Santo Rosario",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504037",
      "brgy_name": "Tibanga",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504038",
      "brgy_name": "Acmac",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504039",
      "brgy_name": "Ditucalan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504040",
      "brgy_name": "Hindang",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504041",
      "brgy_name": "Kalilangan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504042",
      "brgy_name": "Lanipao",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504043",
      "brgy_name": "Luinab",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504044",
      "brgy_name": "Panoroganan",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504045",
      "brgy_name": "San Roque",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504046",
      "brgy_name": "Ubaldo Laya",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504047",
      "brgy_name": "Upper Hinaplanon",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103504048",
      "brgy_name": "Villa Verde",
      "city_code": "103504",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505001",
      "brgy_name": "Bagong Badian",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505003",
      "brgy_name": "Bagong Silang",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505004",
      "brgy_name": "Balili",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505005",
      "brgy_name": "Bansarvil",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505007",
      "brgy_name": "Belis",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505008",
      "brgy_name": "Buenavista",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505010",
      "brgy_name": "Butadon",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505012",
      "brgy_name": "Cathedral Falls",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505014",
      "brgy_name": "Concepcion",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505015",
      "brgy_name": "Curvada",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505016",
      "brgy_name": "De Asis",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505018",
      "brgy_name": "Donggoan",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505019",
      "brgy_name": "Durano",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505021",
      "brgy_name": "Kahayagan",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505026",
      "brgy_name": "Kidalos",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505027",
      "brgy_name": "La Libertad",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505028",
      "brgy_name": "Lapinig",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505030",
      "brgy_name": "Mahayahay",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505031",
      "brgy_name": "Malinas",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505033",
      "brgy_name": "Maranding",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505034",
      "brgy_name": "Margos",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505036",
      "brgy_name": "Poblacion",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505038",
      "brgy_name": "Pulang Yuta",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505039",
      "brgy_name": "San Isidro",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505040",
      "brgy_name": "San Vicente",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505041",
      "brgy_name": "Santa Cruz",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505042",
      "brgy_name": "Santo Tomas",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505043",
      "brgy_name": "Suso",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505046",
      "brgy_name": "Taguitic",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505047",
      "brgy_name": "Tiacongan",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505048",
      "brgy_name": "Tipolo",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505049",
      "brgy_name": "Tulatulahan",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103505050",
      "brgy_name": "Waterfalls",
      "city_code": "103505",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506001",
      "brgy_name": "Bangaan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506002",
      "brgy_name": "Bangco",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506003",
      "brgy_name": "Bansarvil II",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506004",
      "brgy_name": "Bauyan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506005",
      "brgy_name": "Cabongbongan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506006",
      "brgy_name": "Calibao",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506007",
      "brgy_name": "Calipapa",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506008",
      "brgy_name": "Calube",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506009",
      "brgy_name": "Campo Islam",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506010",
      "brgy_name": "Capocao",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506011",
      "brgy_name": "Dabliston",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506012",
      "brgy_name": "Dangulaan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506013",
      "brgy_name": "Ditago",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506014",
      "brgy_name": "Ilian",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506015",
      "brgy_name": "Kauswagan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506016",
      "brgy_name": "Kirapan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506017",
      "brgy_name": "Koreo",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506018",
      "brgy_name": "Lantawan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506019",
      "brgy_name": "Mabuhay",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506020",
      "brgy_name": "Maguindanao",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506021",
      "brgy_name": "Mahayahay",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506022",
      "brgy_name": "Mamagum",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506023",
      "brgy_name": "Pandanan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506024",
      "brgy_name": "Payong",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506025",
      "brgy_name": "Piraka",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506026",
      "brgy_name": "Pikalawag",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506027",
      "brgy_name": "Pikinit",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506028",
      "brgy_name": "Poblacion",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506029",
      "brgy_name": "Ramain",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506030",
      "brgy_name": "Rebucon",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506031",
      "brgy_name": "Sigayan",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506032",
      "brgy_name": "Sugod",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506033",
      "brgy_name": "Tagulo",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506034",
      "brgy_name": "Tantaon",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506035",
      "brgy_name": "Topocon (Capocgo)",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506036",
      "brgy_name": "Dalama",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103506037",
      "brgy_name": "Mina",
      "city_code": "103506",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507001",
      "brgy_name": "Bagumbayan (Pob.)",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507002",
      "brgy_name": "Bara-ason",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507003",
      "brgy_name": "Cayontor",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507004",
      "brgy_name": "Delabayan",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507005",
      "brgy_name": "Inudaran",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507006",
      "brgy_name": "Kawit Occidental",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507007",
      "brgy_name": "Kawit Oriental",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507008",
      "brgy_name": "Libertad",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507009",
      "brgy_name": "Paiton",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507010",
      "brgy_name": "Poblacion",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507011",
      "brgy_name": "Tacub",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507012",
      "brgy_name": "Tingintingin",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103507013",
      "brgy_name": "Tugar",
      "city_code": "103507",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508001",
      "brgy_name": "Austin Heights",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508002",
      "brgy_name": "Baybay",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508004",
      "brgy_name": "Bubong",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508005",
      "brgy_name": "Caromatan",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508006",
      "brgy_name": "Inudaran",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508007",
      "brgy_name": "Kulasihan",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508009",
      "brgy_name": "Libertad",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508010",
      "brgy_name": "Lumbac",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508011",
      "brgy_name": "Manga",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508012",
      "brgy_name": "Matampay",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508013",
      "brgy_name": "Mukas",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508014",
      "brgy_name": "Muntay",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508016",
      "brgy_name": "Pagalungan",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508017",
      "brgy_name": "Palao",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508018",
      "brgy_name": "Pantaon",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508019",
      "brgy_name": "Pantar",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508020",
      "brgy_name": "Poblacion",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508021",
      "brgy_name": "Rebucon",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508022",
      "brgy_name": "Riverside",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508023",
      "brgy_name": "San Roque",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508024",
      "brgy_name": "Santo Niño",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508025",
      "brgy_name": "Simbuco",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508026",
      "brgy_name": "Small Banisilan",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508027",
      "brgy_name": "Sucodan",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508028",
      "brgy_name": "Tabigue",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103508029",
      "brgy_name": "Titunod",
      "city_code": "103508",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509001",
      "brgy_name": "Abaga",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509002",
      "brgy_name": "Andil",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509003",
      "brgy_name": "Matampay Bucana",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509004",
      "brgy_name": "Darumawang Bucana",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509005",
      "brgy_name": "Cabasagan",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509006",
      "brgy_name": "Camalan",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509008",
      "brgy_name": "Darumawang Ilaya",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509009",
      "brgy_name": "El Salvador",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509010",
      "brgy_name": "Gumagamot",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509012",
      "brgy_name": "Lala Proper (Pob.)",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509013",
      "brgy_name": "Lanipao",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509014",
      "brgy_name": "Magpatao",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509015",
      "brgy_name": "Maranding",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509016",
      "brgy_name": "Matampay Ilaya",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509017",
      "brgy_name": "Pacita",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509018",
      "brgy_name": "Pendolonan",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509019",
      "brgy_name": "Pinoyak",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509020",
      "brgy_name": "Raw-an",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509021",
      "brgy_name": "Rebe",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509022",
      "brgy_name": "San Isidro Lower",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509023",
      "brgy_name": "San Isidro Upper",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509024",
      "brgy_name": "San Manuel",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509025",
      "brgy_name": "Santa Cruz Lower",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509026",
      "brgy_name": "Santa Cruz Upper",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509027",
      "brgy_name": "Simpak",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509028",
      "brgy_name": "Tenazas",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103509029",
      "brgy_name": "Tuna-an",
      "city_code": "103509",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510001",
      "brgy_name": "Busque",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510002",
      "brgy_name": "Larapan",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510003",
      "brgy_name": "Magoong",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510004",
      "brgy_name": "Napo",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510005",
      "brgy_name": "Poblacion",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510006",
      "brgy_name": "Purakan",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510007",
      "brgy_name": "Robocon",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103510008",
      "brgy_name": "Samburon",
      "city_code": "103510",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511001",
      "brgy_name": "Babasalon",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511002",
      "brgy_name": "Baguiguicon",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511003",
      "brgy_name": "Daan Campo",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511004",
      "brgy_name": "Durianon",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511005",
      "brgy_name": "Ilihan",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511006",
      "brgy_name": "Lamigadato",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511007",
      "brgy_name": "Lemoncret",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511008",
      "brgy_name": "Lubo",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511009",
      "brgy_name": "Lumbac",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511010",
      "brgy_name": "Malabaogan",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511011",
      "brgy_name": "Mapantao",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511012",
      "brgy_name": "Olango",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511013",
      "brgy_name": "Pangao",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511014",
      "brgy_name": "Pelingkingan",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511015",
      "brgy_name": "Lower Caningag (Perimbangan)",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511016",
      "brgy_name": "Poblacion (Bago-A-Ingud)",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511017",
      "brgy_name": "Rarab",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511018",
      "brgy_name": "Somiorang",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511019",
      "brgy_name": "Upper Caningag (Taguitingan)",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511020",
      "brgy_name": "Talambo",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511021",
      "brgy_name": "Tambacon",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511022",
      "brgy_name": "Tawinian",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511023",
      "brgy_name": "Tipaan",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103511024",
      "brgy_name": "Tombador",
      "city_code": "103511",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512001",
      "brgy_name": "Balagatasa",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512002",
      "brgy_name": "Camp 1",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512003",
      "brgy_name": "Claro M. Recto",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512004",
      "brgy_name": "Inoma",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512005",
      "brgy_name": "Labuay",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512006",
      "brgy_name": "Liangan West",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512007",
      "brgy_name": "Mahayahay",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512008",
      "brgy_name": "Maliwanag",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512009",
      "brgy_name": "Mentring",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512010",
      "brgy_name": "Poblacion",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512011",
      "brgy_name": "Santa Cruz",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512012",
      "brgy_name": "Segapod",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103512013",
      "brgy_name": "Kulasihan (Villanueva)",
      "city_code": "103512",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513001",
      "brgy_name": "Bubong Radapan",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513002",
      "brgy_name": "Bangco",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513003",
      "brgy_name": "Batal",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513004",
      "brgy_name": "Batangan",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513005",
      "brgy_name": "Cadayonan",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513013",
      "brgy_name": "Matampay",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513015",
      "brgy_name": "Pangi",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513016",
      "brgy_name": "Pasayanon",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513018",
      "brgy_name": "Poblacion (Matungao)",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513019",
      "brgy_name": "Puntod",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513020",
      "brgy_name": "Santa Cruz",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103513021",
      "brgy_name": "Somiorang",
      "city_code": "103513",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514001",
      "brgy_name": "Bacayawan",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514002",
      "brgy_name": "Balabacun",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514003",
      "brgy_name": "Balintad",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514005",
      "brgy_name": "Kadayonan",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514008",
      "brgy_name": "Dalama",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514011",
      "brgy_name": "Lindongan",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514012",
      "brgy_name": "Lingco-an",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514013",
      "brgy_name": "Lininding",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514014",
      "brgy_name": "Lumba-Bayabao",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514015",
      "brgy_name": "Madaya",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514016",
      "brgy_name": "Maganding",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514019",
      "brgy_name": "Matampay",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514020",
      "brgy_name": "Old Poblacion",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514021",
      "brgy_name": "North Cadulawan",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514022",
      "brgy_name": "Panggao",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514023",
      "brgy_name": "Pantao",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514024",
      "brgy_name": "Pantao-A-Munai",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514025",
      "brgy_name": "Pantaon",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514026",
      "brgy_name": "Pindolonan",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514027",
      "brgy_name": "Punong",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514028",
      "brgy_name": "Ramain",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514029",
      "brgy_name": "Sandigamunai",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514031",
      "brgy_name": "Tagoranao",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514032",
      "brgy_name": "Tambo",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514033",
      "brgy_name": "Tamparan (Mandaya)",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103514034",
      "brgy_name": "Taporog",
      "city_code": "103514",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515001",
      "brgy_name": "Abaga",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515002",
      "brgy_name": "Bangco",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515003",
      "brgy_name": "Canibongan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515004",
      "brgy_name": "Karcum",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515005",
      "brgy_name": "Dimayon",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515006",
      "brgy_name": "Inayawan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515008",
      "brgy_name": "Kaludan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515010",
      "brgy_name": "Katubuan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515011",
      "brgy_name": "Cabasaran (Laya)",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515012",
      "brgy_name": "Liangan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515013",
      "brgy_name": "Lupitan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515014",
      "brgy_name": "Mangan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515015",
      "brgy_name": "Malaig",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515016",
      "brgy_name": "Masibay",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515017",
      "brgy_name": "Poblacion (Nunungan Proper)",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515018",
      "brgy_name": "Notongan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515019",
      "brgy_name": "Petadun",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515020",
      "brgy_name": "Panganapan",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515021",
      "brgy_name": "Pantar",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515022",
      "brgy_name": "Paridi",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515023",
      "brgy_name": "Rarab",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515024",
      "brgy_name": "Raraban",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515025",
      "brgy_name": "Rebucon",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515026",
      "brgy_name": "Songgod",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103515027",
      "brgy_name": "Taraka",
      "city_code": "103515",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516001",
      "brgy_name": "Aloon",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516003",
      "brgy_name": "Banday",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516004",
      "brgy_name": "Bobonga Pantao Ragat",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516005",
      "brgy_name": "Bobonga Radapan",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516006",
      "brgy_name": "Cabasagan",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516008",
      "brgy_name": "Calawe",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516009",
      "brgy_name": "Culubun",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516010",
      "brgy_name": "Dilimbayan",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516011",
      "brgy_name": "Dimayon",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516012",
      "brgy_name": "Poblacion East",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516013",
      "brgy_name": "Lomidong",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516015",
      "brgy_name": "Madaya",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516016",
      "brgy_name": "Maliwanag",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516017",
      "brgy_name": "Matampay",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516018",
      "brgy_name": "Natangcopan",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516019",
      "brgy_name": "Pansor",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516021",
      "brgy_name": "Pantao Marug",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516022",
      "brgy_name": "Tangcal",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516023",
      "brgy_name": "Tongcopan",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103516024",
      "brgy_name": "Poblacion West",
      "city_code": "103516",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517001",
      "brgy_name": "Alowin",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517002",
      "brgy_name": "Bubong-Dinaig",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517003",
      "brgy_name": "Caromatan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517004",
      "brgy_name": "Daramba",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517005",
      "brgy_name": "Dinaig",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517007",
      "brgy_name": "Cabasaran",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517008",
      "brgy_name": "Kablangan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517009",
      "brgy_name": "Cadayonan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517010",
      "brgy_name": "Linindingan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517012",
      "brgy_name": "Lumbatan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517013",
      "brgy_name": "Lupitan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517014",
      "brgy_name": "Madamba",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517015",
      "brgy_name": "Madaya",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517016",
      "brgy_name": "Maliwanag",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517017",
      "brgy_name": "Nunang",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517018",
      "brgy_name": "Nunungan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517019",
      "brgy_name": "Pantao Raya",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517020",
      "brgy_name": "Pantaon",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517021",
      "brgy_name": "Pened",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517022",
      "brgy_name": "Piangamangaan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517023",
      "brgy_name": "Pendolonan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517024",
      "brgy_name": "Poblacion (Lumbacaingud)",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517025",
      "brgy_name": "Sulo",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517026",
      "brgy_name": "Tagoranao",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517027",
      "brgy_name": "Tangclao",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103517028",
      "brgy_name": "Timbangalan",
      "city_code": "103517",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518001",
      "brgy_name": "Barandia",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518002",
      "brgy_name": "Bulacon",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518003",
      "brgy_name": "Buntong",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518004",
      "brgy_name": "Calimodan",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518005",
      "brgy_name": "Camp III",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518006",
      "brgy_name": "Curva-Miagao",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518007",
      "brgy_name": "Daligdigan",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518008",
      "brgy_name": "Kilala",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518009",
      "brgy_name": "Mabatao",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518010",
      "brgy_name": "Madaya",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518011",
      "brgy_name": "Mamaanon",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518012",
      "brgy_name": "Mapantao",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518013",
      "brgy_name": "Mindalano",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518014",
      "brgy_name": "Padianan",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518015",
      "brgy_name": "Pagalongan",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518016",
      "brgy_name": "Pagayawan",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518017",
      "brgy_name": "Panaliwad-on",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518018",
      "brgy_name": "Pangantapan",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518019",
      "brgy_name": "Pansor",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518020",
      "brgy_name": "Patidon",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518021",
      "brgy_name": "Pawak",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518022",
      "brgy_name": "Poblacion",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518023",
      "brgy_name": "Saumay",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518024",
      "brgy_name": "Sudlon",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103518025",
      "brgy_name": "Inasagan",
      "city_code": "103518",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519001",
      "brgy_name": "Baning",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519002",
      "brgy_name": "Buriasan (Pob.)",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519004",
      "brgy_name": "Dansalan",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519005",
      "brgy_name": "Gamal",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519006",
      "brgy_name": "Inudaran I",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519007",
      "brgy_name": "Inudaran II",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519008",
      "brgy_name": "Karkum",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519009",
      "brgy_name": "Katipunan",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519010",
      "brgy_name": "Mabugnao",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519011",
      "brgy_name": "Maito Salug",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519012",
      "brgy_name": "Mala Salug",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519013",
      "brgy_name": "Mama-anon",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519014",
      "brgy_name": "Mapurog",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519016",
      "brgy_name": "Pancilan",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519017",
      "brgy_name": "Panoloon",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519018",
      "brgy_name": "Pili",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103519019",
      "brgy_name": "Sapad",
      "city_code": "103519",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103520001",
      "brgy_name": "Dalamas",
      "city_code": "103520",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103520002",
      "brgy_name": "Darimbang",
      "city_code": "103520",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103520003",
      "brgy_name": "Dimayon",
      "city_code": "103520",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103520004",
      "brgy_name": "Inagongan",
      "city_code": "103520",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103520005",
      "brgy_name": "Kiazar (Pob.)",
      "city_code": "103520",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103520006",
      "brgy_name": "Malimbato",
      "city_code": "103520",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103520007",
      "brgy_name": "Panalawan",
      "city_code": "103520",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521001",
      "brgy_name": "Small Banisilon",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521003",
      "brgy_name": "Bayabao",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521004",
      "brgy_name": "Berwar",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521005",
      "brgy_name": "Big Banisilon",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521006",
      "brgy_name": "Big Meladoc",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521007",
      "brgy_name": "Bubong",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521008",
      "brgy_name": "Lamaosa",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521009",
      "brgy_name": "Linao",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521010",
      "brgy_name": "Lindongan",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521011",
      "brgy_name": "Lingco-an",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521013",
      "brgy_name": "Small Meladoc",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521014",
      "brgy_name": "Papan",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521016",
      "brgy_name": "Pelingkingan",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521017",
      "brgy_name": "Poblacion",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521018",
      "brgy_name": "Poona Kapatagan",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521019",
      "brgy_name": "Punod",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521021",
      "brgy_name": "Somiorang",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103521024",
      "brgy_name": "Tangcal Proper",
      "city_code": "103521",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522001",
      "brgy_name": "Barakanas",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522002",
      "brgy_name": "Baris (Lumangculob)",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522003",
      "brgy_name": "Bualan",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522004",
      "brgy_name": "Bulod",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522005",
      "brgy_name": "Camp V",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522006",
      "brgy_name": "Candis",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522007",
      "brgy_name": "Caniogan",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522008",
      "brgy_name": "Dalama",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522010",
      "brgy_name": "Kakai Renabor",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522011",
      "brgy_name": "Kalilangan",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522012",
      "brgy_name": "Licapao",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522014",
      "brgy_name": "Malingao",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522015",
      "brgy_name": "Palao",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522016",
      "brgy_name": "Patudan",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522017",
      "brgy_name": "Pigcarangan",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522018",
      "brgy_name": "Pinpin",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522019",
      "brgy_name": "Poblacion",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522020",
      "brgy_name": "Pualas",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522021",
      "brgy_name": "San Antonio",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522022",
      "brgy_name": "Santo Niño",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522023",
      "brgy_name": "Taden",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522024",
      "brgy_name": "Tangueguiron",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522025",
      "brgy_name": "Taguranao",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103522026",
      "brgy_name": "Tubaran",
      "city_code": "103522",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523001",
      "brgy_name": "Bangcal",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523003",
      "brgy_name": "Bubong Madaya",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523005",
      "brgy_name": "Bowi",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523006",
      "brgy_name": "Cabasaran",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523007",
      "brgy_name": "Cadayonan",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523008",
      "brgy_name": "Campong",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523010",
      "brgy_name": "Dibarosan",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523012",
      "brgy_name": "Kalanganan East",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523013",
      "brgy_name": "Kalanganan Lower",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523014",
      "brgy_name": "Kalilangan",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523016",
      "brgy_name": "Pantao-Marug",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523017",
      "brgy_name": "Pantao-Ranao",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523018",
      "brgy_name": "Pantar East",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523019",
      "brgy_name": "Poblacion",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523021",
      "brgy_name": "Pitubo",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523022",
      "brgy_name": "Poona-Punod",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523023",
      "brgy_name": "Punod",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523026",
      "brgy_name": "Sundiga-Punod",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523029",
      "brgy_name": "Tawanan",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523030",
      "brgy_name": "West Pantar",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "103523031",
      "brgy_name": "Lumba-Punod",
      "city_code": "103523",
      "province_code": "1035",
      "region_code": "10"
    },
    {
      "brgy_code": "104201001",
      "brgy_name": "Balintonga",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201002",
      "brgy_name": "Banisilon",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201003",
      "brgy_name": "Burgos",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201004",
      "brgy_name": "Calube",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201005",
      "brgy_name": "Caputol",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201006",
      "brgy_name": "Casusan",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201007",
      "brgy_name": "Conat",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201008",
      "brgy_name": "Culpan",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201009",
      "brgy_name": "Dalisay",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201010",
      "brgy_name": "Dullan",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201011",
      "brgy_name": "Ibabao",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201012",
      "brgy_name": "Tubod (Juan Bacayo)",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201013",
      "brgy_name": "Labo",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201014",
      "brgy_name": "Lawa-an",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201015",
      "brgy_name": "Lobogon",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201016",
      "brgy_name": "Lumbayao",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201017",
      "brgy_name": "Makawa",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201018",
      "brgy_name": "Manamong",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201019",
      "brgy_name": "Matipaz",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201020",
      "brgy_name": "Maular",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201021",
      "brgy_name": "Mitazan",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201022",
      "brgy_name": "Mohon",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201023",
      "brgy_name": "Monterico",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201024",
      "brgy_name": "Nabuna",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201025",
      "brgy_name": "Palayan",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201026",
      "brgy_name": "Pelong",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201027",
      "brgy_name": "Ospital (Pob.)",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201028",
      "brgy_name": "Roxas",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201029",
      "brgy_name": "San Pedro",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201030",
      "brgy_name": "Santa Ana",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201031",
      "brgy_name": "Sinampongan",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201032",
      "brgy_name": "Taguanao",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201033",
      "brgy_name": "Tawi-tawi",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201034",
      "brgy_name": "Toril",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201035",
      "brgy_name": "Tuburan",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201036",
      "brgy_name": "Zamora",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201037",
      "brgy_name": "Macubon (Sina-ad)",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104201038",
      "brgy_name": "Tugaya",
      "city_code": "104201",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202001",
      "brgy_name": "Del Pilar",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202002",
      "brgy_name": "Landing",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202003",
      "brgy_name": "Lumipac",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202004",
      "brgy_name": "Lusot",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202005",
      "brgy_name": "Mabini",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202006",
      "brgy_name": "Magsaysay",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202007",
      "brgy_name": "Misom",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202008",
      "brgy_name": "Mitacas",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202009",
      "brgy_name": "Naburos",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202010",
      "brgy_name": "Northern Poblacion",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202011",
      "brgy_name": "Punta Miray",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202012",
      "brgy_name": "Punta Sulong",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202013",
      "brgy_name": "Sinian",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202014",
      "brgy_name": "Southern Poblacion",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104202015",
      "brgy_name": "Tugas",
      "city_code": "104202",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203002",
      "brgy_name": "Bag-ong Anonang",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203003",
      "brgy_name": "Bagumbang",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203004",
      "brgy_name": "Baybay",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203005",
      "brgy_name": "Bolinsong",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203006",
      "brgy_name": "Buenavista",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203007",
      "brgy_name": "Buracan",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203009",
      "brgy_name": "Calolot",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203010",
      "brgy_name": "Dimalco",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203011",
      "brgy_name": "Dullan",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203012",
      "brgy_name": "Kanaokanao",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203013",
      "brgy_name": "Liloan",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203014",
      "brgy_name": "Linconan",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203015",
      "brgy_name": "Lodiong",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203016",
      "brgy_name": "Lower Usugan",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203017",
      "brgy_name": "Mapurog (Migsale)",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203019",
      "brgy_name": "Migpange",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203020",
      "brgy_name": "Montol",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203023",
      "brgy_name": "Pisa-an",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203024",
      "brgy_name": "Poblacion (Centro)",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203025",
      "brgy_name": "Remedios",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203026",
      "brgy_name": "Rufino Lumapas",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203027",
      "brgy_name": "Sibuyon",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203028",
      "brgy_name": "Tangab",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203029",
      "brgy_name": "Tiaman",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203030",
      "brgy_name": "Tusik",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203031",
      "brgy_name": "Upper Usogan",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203032",
      "brgy_name": "Demetrio Fernan",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104203033",
      "brgy_name": "Digson",
      "city_code": "104203",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204001",
      "brgy_name": "Bonifacio",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204002",
      "brgy_name": "Bunawan",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204003",
      "brgy_name": "Calaran",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204004",
      "brgy_name": "Dapacan Alto",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204005",
      "brgy_name": "Dapacan Bajo",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204006",
      "brgy_name": "Langub",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204007",
      "brgy_name": "Libertad",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204008",
      "brgy_name": "Magcamiguing",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204009",
      "brgy_name": "Mamalad",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204010",
      "brgy_name": "Mauswagon",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204011",
      "brgy_name": "Northern Poblacion",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204012",
      "brgy_name": "Salvador",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204013",
      "brgy_name": "San Isidro (San Isidro-San Pedro)",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204014",
      "brgy_name": "Siloy",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204015",
      "brgy_name": "Singalat",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204016",
      "brgy_name": "Solinog",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204017",
      "brgy_name": "Southwestern Poblacion",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204018",
      "brgy_name": "Sulipat",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104204019",
      "brgy_name": "Don Bernardo Nery Pob. (Tres de Mayo)",
      "city_code": "104204",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205002",
      "brgy_name": "Bernad",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205003",
      "brgy_name": "Bito-on",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205004",
      "brgy_name": "Cabunga-an",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205005",
      "brgy_name": "Canibungan Daku",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205006",
      "brgy_name": "Canibungan Putol",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205007",
      "brgy_name": "Canipacan",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205008",
      "brgy_name": "Dalingap",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205009",
      "brgy_name": "Dela Paz",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205010",
      "brgy_name": "Dolores",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205011",
      "brgy_name": "Gata Daku",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205012",
      "brgy_name": "Gata Diot",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205013",
      "brgy_name": "Guba (Ozamis)",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205014",
      "brgy_name": "Kinangay Norte",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205015",
      "brgy_name": "Kinangay Sur",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205016",
      "brgy_name": "Lapasan",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205017",
      "brgy_name": "Lupagan",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205018",
      "brgy_name": "Malibangcao",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205019",
      "brgy_name": "Masabud",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205020",
      "brgy_name": "Mialen",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205021",
      "brgy_name": "Pan-ay",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205022",
      "brgy_name": "Penacio",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205023",
      "brgy_name": "Poblacion I",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205024",
      "brgy_name": "Segatic Daku",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205025",
      "brgy_name": "Segatic Diot",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205026",
      "brgy_name": "Sebasi",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205027",
      "brgy_name": "Tinacla-an",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205028",
      "brgy_name": "Poblacion II",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205029",
      "brgy_name": "Poblacion III",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104205030",
      "brgy_name": "Poblacion IV",
      "city_code": "104205",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206001",
      "brgy_name": "Bagong Nayon",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206002",
      "brgy_name": "Capule",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206003",
      "brgy_name": "New Casul",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206004",
      "brgy_name": "Guiban",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206005",
      "brgy_name": "Laya-an",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206006",
      "brgy_name": "Lingatongan",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206007",
      "brgy_name": "Maligubaan",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206008",
      "brgy_name": "Mantukoy",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206009",
      "brgy_name": "Marugang",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206011",
      "brgy_name": "Poblacion",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206012",
      "brgy_name": "Pogan",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206013",
      "brgy_name": "Small Potongan",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206014",
      "brgy_name": "Soso-on",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206015",
      "brgy_name": "Upper Dapitan",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206016",
      "brgy_name": "Upper Dioyo",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206017",
      "brgy_name": "Upper Potongan",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206018",
      "brgy_name": "Upper Salimpono",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104206019",
      "brgy_name": "Virayan",
      "city_code": "104206",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207001",
      "brgy_name": "Adorable",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207002",
      "brgy_name": "Butuay",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207003",
      "brgy_name": "Carmen",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207004",
      "brgy_name": "Corrales",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207005",
      "brgy_name": "Dicoloc",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207006",
      "brgy_name": "Gata",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207007",
      "brgy_name": "Guintomoyan",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207008",
      "brgy_name": "Malibacsan",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207009",
      "brgy_name": "Macabayao",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207010",
      "brgy_name": "Matugas Alto",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207011",
      "brgy_name": "Matugas Bajo",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207012",
      "brgy_name": "Mialem",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207014",
      "brgy_name": "Naga (Pob.)",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207015",
      "brgy_name": "Palilan",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207016",
      "brgy_name": "Nacional (Pob.)",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207017",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207018",
      "brgy_name": "San Isidro",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207019",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207020",
      "brgy_name": "Sibaroc",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207021",
      "brgy_name": "Sinara Alto",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207022",
      "brgy_name": "Sinara Bajo",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207023",
      "brgy_name": "Seti",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207024",
      "brgy_name": "Tabo-o",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104207025",
      "brgy_name": "Taraka (Pob.)",
      "city_code": "104207",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208001",
      "brgy_name": "Alegria",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208002",
      "brgy_name": "Bagong Silang",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208003",
      "brgy_name": "Biasong",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208004",
      "brgy_name": "Bonifacio",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208005",
      "brgy_name": "Burgos",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208006",
      "brgy_name": "Dalacon",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208007",
      "brgy_name": "Dampalan",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208008",
      "brgy_name": "Estante",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208010",
      "brgy_name": "Jasa-an",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208011",
      "brgy_name": "Katipa",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208012",
      "brgy_name": "Luzaran",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208013",
      "brgy_name": "Macalibre Alto",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208014",
      "brgy_name": "Macalibre Bajo",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208015",
      "brgy_name": "Mahayahay",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208016",
      "brgy_name": "Manguehan",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208017",
      "brgy_name": "Mansabay Bajo",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208018",
      "brgy_name": "Molatuhan Alto",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208019",
      "brgy_name": "Molatuhan Bajo",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208021",
      "brgy_name": "Peniel",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208022",
      "brgy_name": "Eastern Poblacion",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208023",
      "brgy_name": "Puntod",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208024",
      "brgy_name": "Rizal",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208025",
      "brgy_name": "Sibugon",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208026",
      "brgy_name": "Sibula",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208027",
      "brgy_name": "Don Andres Soriano",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208028",
      "brgy_name": "Mabas",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208029",
      "brgy_name": "Mansabay Alto",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104208030",
      "brgy_name": "Western Poblacion",
      "city_code": "104208",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209001",
      "brgy_name": "Apil",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209002",
      "brgy_name": "Binuangan",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209003",
      "brgy_name": "Bolibol",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209004",
      "brgy_name": "Buenavista",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209005",
      "brgy_name": "Bunga",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209006",
      "brgy_name": "Buntawan",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209007",
      "brgy_name": "Burgos",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209008",
      "brgy_name": "Canubay",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209009",
      "brgy_name": "Clarin Settlement",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209010",
      "brgy_name": "Dolipos Bajo",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209011",
      "brgy_name": "Dolipos Alto",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209012",
      "brgy_name": "Dulapo",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209013",
      "brgy_name": "Dullan Norte",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209014",
      "brgy_name": "Dullan Sur",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209015",
      "brgy_name": "Lamac Lower",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209016",
      "brgy_name": "Lamac Upper",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209017",
      "brgy_name": "Langcangan Lower",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209018",
      "brgy_name": "Langcangan Proper",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209019",
      "brgy_name": "Langcangan Upper",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209020",
      "brgy_name": "Layawan",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209021",
      "brgy_name": "Loboc Lower",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209022",
      "brgy_name": "Loboc Upper",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209023",
      "brgy_name": "Rizal Lower",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209024",
      "brgy_name": "Malindang",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209025",
      "brgy_name": "Mialen",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209026",
      "brgy_name": "Mobod",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209027",
      "brgy_name": "Ciriaco C. Pastrano (Nilabo)",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209028",
      "brgy_name": "Paypayan",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209029",
      "brgy_name": "Pines",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209030",
      "brgy_name": "Poblacion I",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209031",
      "brgy_name": "Poblacion II",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209033",
      "brgy_name": "San Vicente Alto",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209034",
      "brgy_name": "San Vicente Bajo",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209035",
      "brgy_name": "Sebucal",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209036",
      "brgy_name": "Senote",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209037",
      "brgy_name": "Taboc Norte",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209038",
      "brgy_name": "Taboc Sur",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209039",
      "brgy_name": "Talairon",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209040",
      "brgy_name": "Talic",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209041",
      "brgy_name": "Toliyok",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209042",
      "brgy_name": "Tipan",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209043",
      "brgy_name": "Tuyabang Alto",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209044",
      "brgy_name": "Tuyabang Bajo",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209045",
      "brgy_name": "Tuyabang Proper",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209046",
      "brgy_name": "Rizal Upper",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209047",
      "brgy_name": "Victoria",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104209048",
      "brgy_name": "Villaflor",
      "city_code": "104209",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210001",
      "brgy_name": "Aguada (Pob.)",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210002",
      "brgy_name": "Banadero (Pob.)",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210003",
      "brgy_name": "Bacolod",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210004",
      "brgy_name": "Bagakay",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210005",
      "brgy_name": "Balintawak",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210006",
      "brgy_name": "Baybay Santa Cruz",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210007",
      "brgy_name": "Baybay Triunfo",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210008",
      "brgy_name": "Bongbong",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210009",
      "brgy_name": "Calabayan",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210010",
      "brgy_name": "Capucao C.",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210011",
      "brgy_name": "Capucao P.",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210012",
      "brgy_name": "Carangan",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210013",
      "brgy_name": "Catadman-Manabay",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210014",
      "brgy_name": "Cavinte",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210015",
      "brgy_name": "Cogon",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210016",
      "brgy_name": "Dalapang",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210017",
      "brgy_name": "Diguan",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210018",
      "brgy_name": "Dimaluna",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210019",
      "brgy_name": "Embargo",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210020",
      "brgy_name": "Gala",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210021",
      "brgy_name": "Gango",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210022",
      "brgy_name": "Gotokan Daku",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210023",
      "brgy_name": "Gotokan Diot",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210024",
      "brgy_name": "Guimad",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210025",
      "brgy_name": "Guingona",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210026",
      "brgy_name": "Kinuman Norte",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210027",
      "brgy_name": "Kinuman Sur",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210028",
      "brgy_name": "Labinay",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210029",
      "brgy_name": "Labo",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210030",
      "brgy_name": "Lam-an",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210031",
      "brgy_name": "Liposong",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210032",
      "brgy_name": "Litapan",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210033",
      "brgy_name": "Malaubang",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210034",
      "brgy_name": "Manaka",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210035",
      "brgy_name": "Maningcol",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210036",
      "brgy_name": "Mentering",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210037",
      "brgy_name": "Carmen (Misamis Annex)",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210038",
      "brgy_name": "Molicay",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210039",
      "brgy_name": "Stimson Abordo (Montol)",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210040",
      "brgy_name": "Pantaon",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210041",
      "brgy_name": "Pulot",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210042",
      "brgy_name": "San Antonio",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210043",
      "brgy_name": "Baybay San Roque",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210044",
      "brgy_name": "Sangay Daku",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210045",
      "brgy_name": "Sangay Diot",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210046",
      "brgy_name": "Sinuza",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210047",
      "brgy_name": "Tabid",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210048",
      "brgy_name": "Tinago",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210049",
      "brgy_name": "Trigos",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210051",
      "brgy_name": "50th District (Pob.)",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104210052",
      "brgy_name": "Doña Consuelo",
      "city_code": "104210",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211001",
      "brgy_name": "Baga",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211002",
      "brgy_name": "Bangko",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211003",
      "brgy_name": "Camanucan",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211004",
      "brgy_name": "Dela Paz",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211005",
      "brgy_name": "Lutao",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211006",
      "brgy_name": "Magsaysay",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211007",
      "brgy_name": "Map-an",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211008",
      "brgy_name": "Mohon",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211009",
      "brgy_name": "Poblacion",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211010",
      "brgy_name": "Punta",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211011",
      "brgy_name": "Salimpuno",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211012",
      "brgy_name": "San Andres",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211013",
      "brgy_name": "San Juan",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211014",
      "brgy_name": "San Roque",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211015",
      "brgy_name": "Sumasap",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104211016",
      "brgy_name": "Villalin",
      "city_code": "104211",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212001",
      "brgy_name": "Agunod",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212002",
      "brgy_name": "Bato",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212003",
      "brgy_name": "Buena Voluntad",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212004",
      "brgy_name": "Calaca-an",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212005",
      "brgy_name": "Cartagena Proper",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212006",
      "brgy_name": "Catarman",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212007",
      "brgy_name": "Cebulin",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212008",
      "brgy_name": "Clarin",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212009",
      "brgy_name": "Danao",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212010",
      "brgy_name": "Deboloc",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212011",
      "brgy_name": "Divisoria",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212012",
      "brgy_name": "Eastern Looc",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212013",
      "brgy_name": "Ilisan",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212014",
      "brgy_name": "Katipunan",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212015",
      "brgy_name": "Kauswagan",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212016",
      "brgy_name": "Lao Proper",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212017",
      "brgy_name": "Lao Santa Cruz",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212018",
      "brgy_name": "Looc Proper",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212019",
      "brgy_name": "Mamanga Daku",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212020",
      "brgy_name": "Mamanga Gamay",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212021",
      "brgy_name": "Mangidkid",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212022",
      "brgy_name": "New Cartagena",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212023",
      "brgy_name": "New Look",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212024",
      "brgy_name": "Northern Poblacion",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212025",
      "brgy_name": "Panalsalan",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212026",
      "brgy_name": "Puntod",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212027",
      "brgy_name": "Quirino",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212028",
      "brgy_name": "Santa Cruz",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212029",
      "brgy_name": "Southern Looc",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212030",
      "brgy_name": "Southern Poblacion",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212031",
      "brgy_name": "Tipolo",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212032",
      "brgy_name": "Unidos",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104212033",
      "brgy_name": "Usocan",
      "city_code": "104212",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213001",
      "brgy_name": "Bautista",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213002",
      "brgy_name": "Bitibut",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213003",
      "brgy_name": "Boundary",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213004",
      "brgy_name": "Caluya",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213005",
      "brgy_name": "Capundag",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213006",
      "brgy_name": "Casul",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213007",
      "brgy_name": "Dasa",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213008",
      "brgy_name": "Dioyo",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213009",
      "brgy_name": "Guinabot",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213010",
      "brgy_name": "Libertad",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213011",
      "brgy_name": "Locus",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213012",
      "brgy_name": "Manla",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213013",
      "brgy_name": "Masubong",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213016",
      "brgy_name": "Agapito Yap, Sr. (Napilan)",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213017",
      "brgy_name": "Poblacion",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213018",
      "brgy_name": "Salimpuno",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213019",
      "brgy_name": "San Agustin",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213020",
      "brgy_name": "Sinaad",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213021",
      "brgy_name": "Sipac",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213022",
      "brgy_name": "Sixto Velez, Sr.",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213023",
      "brgy_name": "Upper Bautista",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213024",
      "brgy_name": "Ventura",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213025",
      "brgy_name": "Medallo",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213026",
      "brgy_name": "Dalumpinas",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213027",
      "brgy_name": "Disoy",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213028",
      "brgy_name": "El Paraiso",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213029",
      "brgy_name": "Macabibo",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104213030",
      "brgy_name": "Sapang Ama",
      "city_code": "104213",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214001",
      "brgy_name": "Cagay-anon",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214002",
      "brgy_name": "Camanse",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214004",
      "brgy_name": "Colupan Alto",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214005",
      "brgy_name": "Colupan Bajo",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214006",
      "brgy_name": "Dinas",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214007",
      "brgy_name": "Estrella",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214008",
      "brgy_name": "Katipunan",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214010",
      "brgy_name": "Libertad Alto",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214011",
      "brgy_name": "Libertad Bajo",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214013",
      "brgy_name": "Poblacion",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214014",
      "brgy_name": "San Isidro Alto",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214015",
      "brgy_name": "San Isidro Bajo",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214016",
      "brgy_name": "San Vicente",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214017",
      "brgy_name": "Señor",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214018",
      "brgy_name": "Sinonoc",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214019",
      "brgy_name": "San Lorenzo Ruiz (Sungan)",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104214020",
      "brgy_name": "Tipan",
      "city_code": "104214",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215001",
      "brgy_name": "Santa Maria (Baga)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215002",
      "brgy_name": "Balatacan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215003",
      "brgy_name": "Banglay",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215004",
      "brgy_name": "Mantic",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215005",
      "brgy_name": "Migcanaway",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215006",
      "brgy_name": "Bintana",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215007",
      "brgy_name": "Bocator",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215008",
      "brgy_name": "Bongabong",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215009",
      "brgy_name": "Caniangan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215010",
      "brgy_name": "Capalaran",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215011",
      "brgy_name": "Catagan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215012",
      "brgy_name": "Barangay I - City Hall (Pob.)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215013",
      "brgy_name": "Barangay II - Marilou Annex (Pob.)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215014",
      "brgy_name": "Barangay IV - St. Michael (Pob.)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215015",
      "brgy_name": "Isidro D. Tan (Dimaloc-oc)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215016",
      "brgy_name": "Garang",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215017",
      "brgy_name": "Guinabot",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215018",
      "brgy_name": "Guinalaban",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215019",
      "brgy_name": "Kauswagan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215020",
      "brgy_name": "Kimat",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215021",
      "brgy_name": "Labuyo",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215022",
      "brgy_name": "Lorenzo Tan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215023",
      "brgy_name": "Barangay VI - Lower Polao (Pob.)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215024",
      "brgy_name": "Lumban",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215025",
      "brgy_name": "Maloro",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215026",
      "brgy_name": "Barangay V - Malubog (Pob.)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215027",
      "brgy_name": "Manga",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215028",
      "brgy_name": "Maquilao",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215029",
      "brgy_name": "Barangay III- Market Kalubian (Pob.)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215030",
      "brgy_name": "Minsubong",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215031",
      "brgy_name": "Owayan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215032",
      "brgy_name": "Paiton",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215033",
      "brgy_name": "Panalsalan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215034",
      "brgy_name": "Pangabuan",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215035",
      "brgy_name": "Prenza",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215036",
      "brgy_name": "Salimpuno",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215037",
      "brgy_name": "San Antonio",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215038",
      "brgy_name": "San Apolinario",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215039",
      "brgy_name": "San Vicente",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215040",
      "brgy_name": "Santa Cruz",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215041",
      "brgy_name": "Santo Niño",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215042",
      "brgy_name": "Silangit",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215043",
      "brgy_name": "Simasay",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215044",
      "brgy_name": "Sumirap",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215045",
      "brgy_name": "Taguite",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215046",
      "brgy_name": "Tituron",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215047",
      "brgy_name": "Barangay VII - Upper Polao (Pob.)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215048",
      "brgy_name": "Villaba",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215049",
      "brgy_name": "Silanga",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215050",
      "brgy_name": "Aquino (Marcos)",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215051",
      "brgy_name": "Baluk",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215052",
      "brgy_name": "Huyohoy",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215053",
      "brgy_name": "Matugnaw",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215054",
      "brgy_name": "Sicot",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104215055",
      "brgy_name": "Tugas",
      "city_code": "104215",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216001",
      "brgy_name": "Balon",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216002",
      "brgy_name": "Barra",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216003",
      "brgy_name": "Basirang",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216004",
      "brgy_name": "Bongabong",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216005",
      "brgy_name": "Buenavista",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216006",
      "brgy_name": "Cabol-anonan",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216007",
      "brgy_name": "Cahayag",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216008",
      "brgy_name": "Camating",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216009",
      "brgy_name": "Canibungan Proper",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216010",
      "brgy_name": "Casilak San Agustin",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216011",
      "brgy_name": "Centro Hulpa (Pob.)",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216012",
      "brgy_name": "Centro Napu (Pob.)",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216013",
      "brgy_name": "Centro Upper (Pob.)",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216014",
      "brgy_name": "Calambutan Bajo",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216015",
      "brgy_name": "Calambutan Settlement",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216016",
      "brgy_name": "Duanguican",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216017",
      "brgy_name": "Gala",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216018",
      "brgy_name": "Gumbil",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216021",
      "brgy_name": "Locso-on",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216022",
      "brgy_name": "Maikay",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216023",
      "brgy_name": "Maribojoc",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216024",
      "brgy_name": "Mitugas",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216025",
      "brgy_name": "Nailon",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216026",
      "brgy_name": "Namut",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216027",
      "brgy_name": "Napurog",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216028",
      "brgy_name": "Pan-ay Diot",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216029",
      "brgy_name": "San Nicolas",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216032",
      "brgy_name": "Sebac",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216034",
      "brgy_name": "Silongon",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216035",
      "brgy_name": "Sinuza",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216036",
      "brgy_name": "Taguima",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216038",
      "brgy_name": "Tigdok",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104216039",
      "brgy_name": "Yahong",
      "city_code": "104216",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217001",
      "brgy_name": "Bagong Clarin",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217002",
      "brgy_name": "Gandawan",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217003",
      "brgy_name": "Lake Duminagat",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217004",
      "brgy_name": "Lalud",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217005",
      "brgy_name": "Lampasan",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217006",
      "brgy_name": "Liboron",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217007",
      "brgy_name": "Maramara",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217008",
      "brgy_name": "Napangan",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217009",
      "brgy_name": "Nueva Vista (Masawan)",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217010",
      "brgy_name": "Petianan",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104217011",
      "brgy_name": "Tuno",
      "city_code": "104217",
      "province_code": "1042",
      "region_code": "10"
    },
    {
      "brgy_code": "104301001",
      "brgy_name": "Baybay",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301002",
      "brgy_name": "Benigwayan",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301003",
      "brgy_name": "Calatcat",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301004",
      "brgy_name": "Lagtang",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301005",
      "brgy_name": "Lanao",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301006",
      "brgy_name": "Loguilo",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301007",
      "brgy_name": "Lourdes",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301008",
      "brgy_name": "Lumbo",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301009",
      "brgy_name": "Molocboloc",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301010",
      "brgy_name": "Poblacion",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301011",
      "brgy_name": "Sampatulog",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301012",
      "brgy_name": "Sungay",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301013",
      "brgy_name": "Talaba",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301014",
      "brgy_name": "Taparak",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301015",
      "brgy_name": "Tugasnon",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104301016",
      "brgy_name": "Tula",
      "city_code": "104301",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302001",
      "brgy_name": "Balagnan",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302003",
      "brgy_name": "Baliwagan",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302004",
      "brgy_name": "San Francisco",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302005",
      "brgy_name": "Binitinan",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302006",
      "brgy_name": "Blanco",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302008",
      "brgy_name": "Calawag",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302009",
      "brgy_name": "Camuayan",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302010",
      "brgy_name": "Cogon",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302011",
      "brgy_name": "Dansuli",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302012",
      "brgy_name": "Dumarait",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302013",
      "brgy_name": "Hermano",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302014",
      "brgy_name": "Kibanban",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302015",
      "brgy_name": "Linabu",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302016",
      "brgy_name": "Linggangao",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302017",
      "brgy_name": "Mambayaan",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302018",
      "brgy_name": "Mandangoa",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302019",
      "brgy_name": "Napaliran",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302020",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302021",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302022",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302023",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302024",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302025",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302026",
      "brgy_name": "Quezon",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302027",
      "brgy_name": "Rosario",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302028",
      "brgy_name": "Samay",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302029",
      "brgy_name": "San Isidro",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302030",
      "brgy_name": "San Juan",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302031",
      "brgy_name": "Talusan",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104302032",
      "brgy_name": "Waterfall",
      "city_code": "104302",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303002",
      "brgy_name": "Dahilig",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303003",
      "brgy_name": "Baukbauk Pob. (G.E. Antonino)",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303004",
      "brgy_name": "Kabangasan",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303005",
      "brgy_name": "Kabulakan",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303006",
      "brgy_name": "Kauswagan",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303007",
      "brgy_name": "Lapinig (Pob.)",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303008",
      "brgy_name": "Mantangale",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303009",
      "brgy_name": "Mapua",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104303010",
      "brgy_name": "San Alonzo",
      "city_code": "104303",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304001",
      "brgy_name": "Dampias",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304002",
      "brgy_name": "Kitamban",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304003",
      "brgy_name": "Kitambis",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304004",
      "brgy_name": "Mabini",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304005",
      "brgy_name": "Mosangot",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304006",
      "brgy_name": "Nabataan",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304007",
      "brgy_name": "Poblacion",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104304008",
      "brgy_name": "Valdeconcha",
      "city_code": "104304",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305001",
      "brgy_name": "Agusan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305002",
      "brgy_name": "Baikingon",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305003",
      "brgy_name": "Bulua",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305004",
      "brgy_name": "Balubal",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305005",
      "brgy_name": "Balulang",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305006",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305007",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305008",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305009",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305010",
      "brgy_name": "Barangay 14 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305011",
      "brgy_name": "Barangay 15 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305012",
      "brgy_name": "Barangay 16 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305013",
      "brgy_name": "Barangay 17 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305014",
      "brgy_name": "Barangay 18 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305015",
      "brgy_name": "Barangay 19 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305016",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305017",
      "brgy_name": "Barangay 21 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305018",
      "brgy_name": "Barangay 22 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305019",
      "brgy_name": "Barangay 23 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305020",
      "brgy_name": "Barangay 24 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305021",
      "brgy_name": "Barangay 26 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305022",
      "brgy_name": "Barangay 27 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305023",
      "brgy_name": "Barangay 28 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305024",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305025",
      "brgy_name": "Barangay 30 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305026",
      "brgy_name": "Barangay 32 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305027",
      "brgy_name": "Barangay 33 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305028",
      "brgy_name": "Barangay 34 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305029",
      "brgy_name": "Barangay 38 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305030",
      "brgy_name": "Barangay 39 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305031",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305032",
      "brgy_name": "Barangay 40 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305033",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305034",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305035",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305036",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305037",
      "brgy_name": "Bayabas",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305038",
      "brgy_name": "Bayanga",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305039",
      "brgy_name": "Besigan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305040",
      "brgy_name": "Bonbon",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305041",
      "brgy_name": "Bugo",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305042",
      "brgy_name": "Camaman-an",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305043",
      "brgy_name": "Canito-an",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305044",
      "brgy_name": "Carmen",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305045",
      "brgy_name": "Consolacion",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305046",
      "brgy_name": "Cugman",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305047",
      "brgy_name": "Dansolihon",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305048",
      "brgy_name": "F. S. Catanico",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305049",
      "brgy_name": "Gusa",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305050",
      "brgy_name": "Indahag",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305051",
      "brgy_name": "Iponan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305052",
      "brgy_name": "Kauswagan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305053",
      "brgy_name": "Lapasan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305054",
      "brgy_name": "Lumbia",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305055",
      "brgy_name": "Macabalan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305056",
      "brgy_name": "Macasandig",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305057",
      "brgy_name": "Mambuaya",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305058",
      "brgy_name": "Nazareth",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305059",
      "brgy_name": "Pagalungan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305060",
      "brgy_name": "Pagatpat",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305061",
      "brgy_name": "Patag",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305062",
      "brgy_name": "Pigsag-an",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305063",
      "brgy_name": "Puerto",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305064",
      "brgy_name": "Puntod",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305065",
      "brgy_name": "San Simon",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305067",
      "brgy_name": "Tablon",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305068",
      "brgy_name": "Taglimao",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305069",
      "brgy_name": "Tagpangi",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305070",
      "brgy_name": "Tignapoloan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305071",
      "brgy_name": "Tuburan",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305072",
      "brgy_name": "Tumpagon",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305073",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305074",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305075",
      "brgy_name": "Barangay 20 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305076",
      "brgy_name": "Barangay 25 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305077",
      "brgy_name": "Barangay 29 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305078",
      "brgy_name": "Barangay 31 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305079",
      "brgy_name": "Barangay 35 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305080",
      "brgy_name": "Barangay 36 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104305081",
      "brgy_name": "Barangay 37 (Pob.)",
      "city_code": "104305",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306001",
      "brgy_name": "Ani-e",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306002",
      "brgy_name": "Aposkahoy",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306003",
      "brgy_name": "Bulahan",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306004",
      "brgy_name": "Cabacungan",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306006",
      "brgy_name": "Pelaez (Don Gregorio Pelaez)",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306007",
      "brgy_name": "Gumaod",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306008",
      "brgy_name": "Hinaplanan",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306009",
      "brgy_name": "Kalawitan",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306010",
      "brgy_name": "Lanise",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306011",
      "brgy_name": "Luna",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306012",
      "brgy_name": "Madaguing",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306013",
      "brgy_name": "Malagana",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306014",
      "brgy_name": "Minalwang",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306015",
      "brgy_name": "Mat-i",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306016",
      "brgy_name": "Panampawan",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306017",
      "brgy_name": "Pambugas",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306018",
      "brgy_name": "Patrocenio",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306019",
      "brgy_name": "Plaridel",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306020",
      "brgy_name": "Poblacion",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306021",
      "brgy_name": "Punong",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306022",
      "brgy_name": "Rizal",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306023",
      "brgy_name": "Santa Cruz",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306024",
      "brgy_name": "Tamboboan",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104306025",
      "brgy_name": "Tipolohon",
      "city_code": "104306",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307001",
      "brgy_name": "Amoros",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307002",
      "brgy_name": "Bolisong",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307003",
      "brgy_name": "Bolobolo",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307004",
      "brgy_name": "Calongonan",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307006",
      "brgy_name": "Cogon",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307007",
      "brgy_name": "Himaya",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307008",
      "brgy_name": "Hinigdaan",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307010",
      "brgy_name": "Kalabaylabay",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307011",
      "brgy_name": "Molugan",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307012",
      "brgy_name": "Poblacion",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307013",
      "brgy_name": "Kibonbon",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307014",
      "brgy_name": "Sambulawan",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307015",
      "brgy_name": "Sinaloc",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307016",
      "brgy_name": "Taytay",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104307018",
      "brgy_name": "Ulaliman",
      "city_code": "104307",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308001",
      "brgy_name": "Agay-ayan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308002",
      "brgy_name": "Alagatan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308003",
      "brgy_name": "Anakan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308004",
      "brgy_name": "Bagubad",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308005",
      "brgy_name": "Bakidbakid",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308006",
      "brgy_name": "Bal-ason",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308007",
      "brgy_name": "Bantaawan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308008",
      "brgy_name": "Binakalan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308010",
      "brgy_name": "Capitulangan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308011",
      "brgy_name": "Daan-Lungsod",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308012",
      "brgy_name": "Hindangon",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308013",
      "brgy_name": "Kalagonoy",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308014",
      "brgy_name": "Kibuging",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308015",
      "brgy_name": "Kipuntos",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308016",
      "brgy_name": "Lawaan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308017",
      "brgy_name": "Lawit",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308018",
      "brgy_name": "Libertad",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308019",
      "brgy_name": "Libon",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308020",
      "brgy_name": "Lunao",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308021",
      "brgy_name": "Lunotan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308022",
      "brgy_name": "Malibud",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308023",
      "brgy_name": "Malinao",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308024",
      "brgy_name": "Maribucao",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308025",
      "brgy_name": "Mimbuntong",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308026",
      "brgy_name": "Mimbalagon",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308027",
      "brgy_name": "Mimbunga",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308028",
      "brgy_name": "Minsapinit",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308029",
      "brgy_name": "Murallon",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308030",
      "brgy_name": "Odiongan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308031",
      "brgy_name": "Pangasihan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308032",
      "brgy_name": "Pigsaluhan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308033",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308034",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308035",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308036",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308037",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308038",
      "brgy_name": "Barangay 14 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308039",
      "brgy_name": "Barangay 15 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308040",
      "brgy_name": "Barangay 16 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308041",
      "brgy_name": "Barangay 17 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308042",
      "brgy_name": "Barangay 18-A (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308043",
      "brgy_name": "Barangay 19 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308044",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308045",
      "brgy_name": "Barangay 20 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308046",
      "brgy_name": "Barangay 21 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308047",
      "brgy_name": "Barangay 22-A (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308048",
      "brgy_name": "Barangay 23 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308049",
      "brgy_name": "Barangay 24 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308050",
      "brgy_name": "Barangay 25 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308051",
      "brgy_name": "Barangay 26 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308055",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308056",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308057",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308058",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308059",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308060",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308061",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308062",
      "brgy_name": "Punong",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308063",
      "brgy_name": "Ricoro",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308064",
      "brgy_name": "Samay",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308065",
      "brgy_name": "San Juan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308066",
      "brgy_name": "San Luis",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308067",
      "brgy_name": "San Miguel",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308068",
      "brgy_name": "Santiago",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308069",
      "brgy_name": "Talisay",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308070",
      "brgy_name": "Talon",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308071",
      "brgy_name": "Tinabalan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308072",
      "brgy_name": "Tinulongan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308073",
      "brgy_name": "Barangay 18 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308074",
      "brgy_name": "Barangay 22 (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308075",
      "brgy_name": "Barangay 24-A (Pob.)",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308076",
      "brgy_name": "Dinawehan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308077",
      "brgy_name": "Eureka",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308078",
      "brgy_name": "Kalipay",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308079",
      "brgy_name": "Kamanikan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308080",
      "brgy_name": "Kianlagan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308081",
      "brgy_name": "San Jose",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308082",
      "brgy_name": "Sangalan",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104308083",
      "brgy_name": "Tagpako",
      "city_code": "104308",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309001",
      "brgy_name": "Burnay",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309002",
      "brgy_name": "Carlos P. Garcia",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309004",
      "brgy_name": "Cogon",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309005",
      "brgy_name": "Gregorio Pelaez (Lagutay)",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309006",
      "brgy_name": "Kilangit",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309007",
      "brgy_name": "Matangad",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309008",
      "brgy_name": "Pangayawan",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309009",
      "brgy_name": "Poblacion",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309010",
      "brgy_name": "Quezon",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309011",
      "brgy_name": "Tala-o",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104309012",
      "brgy_name": "Ulab",
      "city_code": "104309",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310001",
      "brgy_name": "Aluna",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310002",
      "brgy_name": "Andales",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310003",
      "brgy_name": "Apas",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310004",
      "brgy_name": "Calacapan",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310005",
      "brgy_name": "Gimangpang",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310006",
      "brgy_name": "Jampason",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310007",
      "brgy_name": "Kamelon",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310008",
      "brgy_name": "Kanitoan",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310009",
      "brgy_name": "Oguis",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310010",
      "brgy_name": "Pagahan",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310011",
      "brgy_name": "Poblacion",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310012",
      "brgy_name": "Pontacon",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310013",
      "brgy_name": "San Pedro",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310014",
      "brgy_name": "Sinalac",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310015",
      "brgy_name": "Tawantawan",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104310016",
      "brgy_name": "Tubigan",
      "city_code": "104310",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311001",
      "brgy_name": "Aplaya",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311002",
      "brgy_name": "Bobontugan",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311003",
      "brgy_name": "Corrales",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311004",
      "brgy_name": "Danao",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311005",
      "brgy_name": "Jampason",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311006",
      "brgy_name": "Kimaya",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311007",
      "brgy_name": "Lower Jasaan (Pob.)",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311008",
      "brgy_name": "Luz Banzon",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311009",
      "brgy_name": "Natubo",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311010",
      "brgy_name": "San Antonio",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311011",
      "brgy_name": "San Isidro",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311012",
      "brgy_name": "San Nicolas",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311013",
      "brgy_name": "Solana",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311014",
      "brgy_name": "Upper Jasaan (Pob.)",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104311015",
      "brgy_name": "I. S. Cruz",
      "city_code": "104311",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312001",
      "brgy_name": "Beray",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312002",
      "brgy_name": "Bolisong",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312003",
      "brgy_name": "Buko",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312004",
      "brgy_name": "Kalitian",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312005",
      "brgy_name": "Calubo",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312006",
      "brgy_name": "Campo",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312007",
      "brgy_name": "Esperanza",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312008",
      "brgy_name": "Kagumahan",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312009",
      "brgy_name": "Kitotok",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312010",
      "brgy_name": "Panabol",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312011",
      "brgy_name": "Poblacion",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312012",
      "brgy_name": "Salicapawan",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312013",
      "brgy_name": "Salubsob",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312014",
      "brgy_name": "Suarez",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104312015",
      "brgy_name": "Sumalag",
      "city_code": "104312",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313001",
      "brgy_name": "Banglay",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313002",
      "brgy_name": "Dampil",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313003",
      "brgy_name": "Gaston",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313004",
      "brgy_name": "Kabulawan",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313005",
      "brgy_name": "Kauswagan",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313006",
      "brgy_name": "Lumbo",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313007",
      "brgy_name": "Manaol",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313008",
      "brgy_name": "Poblacion",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313009",
      "brgy_name": "Tabok",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104313010",
      "brgy_name": "Umagos",
      "city_code": "104313",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314001",
      "brgy_name": "Aromahon",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314002",
      "brgy_name": "Gasi",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314003",
      "brgy_name": "Kibaghot",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314004",
      "brgy_name": "Lapad",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314005",
      "brgy_name": "Liberty",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314006",
      "brgy_name": "Mauswagon",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314007",
      "brgy_name": "Moog",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314008",
      "brgy_name": "Poblacion",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314009",
      "brgy_name": "Sambulawan",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314010",
      "brgy_name": "Sinai",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104314011",
      "brgy_name": "Tubajon",
      "city_code": "104314",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315001",
      "brgy_name": "Dulong",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315002",
      "brgy_name": "Gimaylan",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315003",
      "brgy_name": "Kimalok",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315004",
      "brgy_name": "Lubluban",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315005",
      "brgy_name": "Poblacion",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315006",
      "brgy_name": "Retablo",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315007",
      "brgy_name": "Santo Niño",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315008",
      "brgy_name": "Tangcub",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104315009",
      "brgy_name": "Taytayan",
      "city_code": "104315",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316001",
      "brgy_name": "Aya-aya",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316002",
      "brgy_name": "Betahon",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316003",
      "brgy_name": "Biga",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316004",
      "brgy_name": "Calangahan",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316005",
      "brgy_name": "Kaluknayan",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316006",
      "brgy_name": "Lower Talacogon",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316007",
      "brgy_name": "Poblacion",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104316008",
      "brgy_name": "Upper Talacogon",
      "city_code": "104316",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317001",
      "brgy_name": "Abunda",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317002",
      "brgy_name": "Artadi",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317003",
      "brgy_name": "Bonifacio Aquino",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317004",
      "brgy_name": "Cabalawan",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317005",
      "brgy_name": "Cabantian",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317006",
      "brgy_name": "Cabubuhan",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317007",
      "brgy_name": "Candiis",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317008",
      "brgy_name": "Consuelo",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317009",
      "brgy_name": "Damayuhan",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317010",
      "brgy_name": "Gumabon",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317011",
      "brgy_name": "Kauswagan",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317012",
      "brgy_name": "Kibungsod",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317013",
      "brgy_name": "Mahayahay",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317014",
      "brgy_name": "Mindulao",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317015",
      "brgy_name": "Pag-asa",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317016",
      "brgy_name": "Poblacion",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317017",
      "brgy_name": "San Isidro",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317018",
      "brgy_name": "San Vicente",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317019",
      "brgy_name": "Santa Cruz",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317020",
      "brgy_name": "Tibon-tibon",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317021",
      "brgy_name": "Tulang (Cadena de Amor)",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317022",
      "brgy_name": "Villa Felipa",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317023",
      "brgy_name": "Katipunan",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317024",
      "brgy_name": "Tama",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104317025",
      "brgy_name": "Tinaan",
      "city_code": "104317",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318001",
      "brgy_name": "Argayoso",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318002",
      "brgy_name": "Balintad",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318003",
      "brgy_name": "Cabalantian",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318004",
      "brgy_name": "Camanga",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318005",
      "brgy_name": "Digkilaan",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318006",
      "brgy_name": "Mahayahay",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318007",
      "brgy_name": "Pagawan",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318008",
      "brgy_name": "Paniangan",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318009",
      "brgy_name": "Patag",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318010",
      "brgy_name": "Poblacion",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318011",
      "brgy_name": "Punta Silum",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318012",
      "brgy_name": "Tuod",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104318013",
      "brgy_name": "Upper Malubog",
      "city_code": "104318",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319001",
      "brgy_name": "Bangbang",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319002",
      "brgy_name": "Bulwa",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319003",
      "brgy_name": "Cabug",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319004",
      "brgy_name": "Dig-aguyan",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319005",
      "brgy_name": "Duka",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319006",
      "brgy_name": "Gasa",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319007",
      "brgy_name": "Maanas",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319008",
      "brgy_name": "Mananum Bag-o",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319009",
      "brgy_name": "Mananum Daan",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319011",
      "brgy_name": "North Poblacion",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319012",
      "brgy_name": "Pahindong",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319013",
      "brgy_name": "Portulin",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319014",
      "brgy_name": "San Isidro",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319015",
      "brgy_name": "San Jose",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319016",
      "brgy_name": "San Roque",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319017",
      "brgy_name": "San Vicente",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319018",
      "brgy_name": "South Poblacion",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319019",
      "brgy_name": "Tambagan",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104319020",
      "brgy_name": "Tup-on",
      "city_code": "104319",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320001",
      "brgy_name": "Don Pedro",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320002",
      "brgy_name": "Linangkayan",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320003",
      "brgy_name": "Lubilan",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320004",
      "brgy_name": "Mapulog",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320005",
      "brgy_name": "Maputi",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320006",
      "brgy_name": "Mat-i",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320007",
      "brgy_name": "Patag",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320008",
      "brgy_name": "Poblacion",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320009",
      "brgy_name": "Tagbalogo",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104320010",
      "brgy_name": "Tuboran",
      "city_code": "104320",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321001",
      "brgy_name": "Awang",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321002",
      "brgy_name": "Bagocboc",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321003",
      "brgy_name": "Barra",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321004",
      "brgy_name": "Bonbon",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321005",
      "brgy_name": "Cauyonan",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321006",
      "brgy_name": "Igpit",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321007",
      "brgy_name": "Limonda",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321008",
      "brgy_name": "Luyongbonbon",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321009",
      "brgy_name": "Malanang",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321011",
      "brgy_name": "Nangcaon",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321013",
      "brgy_name": "Patag",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321014",
      "brgy_name": "Poblacion",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321015",
      "brgy_name": "Taboc",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104321016",
      "brgy_name": "Tingalan",
      "city_code": "104321",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322001",
      "brgy_name": "Alipuaton",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322002",
      "brgy_name": "Ampenican",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322003",
      "brgy_name": "Bunal",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322004",
      "brgy_name": "Dinagsaan",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322005",
      "brgy_name": "Guinalaban",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322006",
      "brgy_name": "Ili-ilihon",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322007",
      "brgy_name": "Inobulan",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322008",
      "brgy_name": "Looc",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322009",
      "brgy_name": "Matampa",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322010",
      "brgy_name": "Membuli",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322011",
      "brgy_name": "Poblacion",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322012",
      "brgy_name": "Salagsag",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322014",
      "brgy_name": "Salay River I",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322015",
      "brgy_name": "Salay River II",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322016",
      "brgy_name": "Saray",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322017",
      "brgy_name": "Tinagaan",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322018",
      "brgy_name": "Yungod",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104322019",
      "brgy_name": "Casulog",
      "city_code": "104322",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323001",
      "brgy_name": "Alicomohan",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323002",
      "brgy_name": "Ampianga",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323003",
      "brgy_name": "Kaulayanan",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323004",
      "brgy_name": "Kidampas",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323005",
      "brgy_name": "Kiraging",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323006",
      "brgy_name": "Mangga",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323007",
      "brgy_name": "Mimbuahan",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323008",
      "brgy_name": "Poblacion",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323009",
      "brgy_name": "Santa Cruz (Mabini)",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104323010",
      "brgy_name": "Silad",
      "city_code": "104323",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324001",
      "brgy_name": "Baluarte",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324002",
      "brgy_name": "Casinglot",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324003",
      "brgy_name": "Gracia",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324004",
      "brgy_name": "Mohon",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324005",
      "brgy_name": "Natumolan",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324006",
      "brgy_name": "Poblacion",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324007",
      "brgy_name": "Rosario",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324008",
      "brgy_name": "Santa Ana",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324009",
      "brgy_name": "Santa Cruz",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104324010",
      "brgy_name": "Sugbongcogon",
      "city_code": "104324",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325001",
      "brgy_name": "Bugdang",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325002",
      "brgy_name": "Calamcam",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325003",
      "brgy_name": "Casibole",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325004",
      "brgy_name": "Macopa",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325005",
      "brgy_name": "Magkarila",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325006",
      "brgy_name": "Mahayag",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325007",
      "brgy_name": "Mandahilag",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325008",
      "brgy_name": "Mintabon",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325009",
      "brgy_name": "Pangpangon",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325010",
      "brgy_name": "Poblacion",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325011",
      "brgy_name": "Pook",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325012",
      "brgy_name": "Punta Santiago",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325013",
      "brgy_name": "Puting Balas",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325014",
      "brgy_name": "San Jose",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325015",
      "brgy_name": "Santa Ines",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325016",
      "brgy_name": "Sibantang",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325017",
      "brgy_name": "Sindangan",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104325018",
      "brgy_name": "Tagbocboc",
      "city_code": "104325",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326001",
      "brgy_name": "Balacanas",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326003",
      "brgy_name": "Dayawan",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326004",
      "brgy_name": "Katipunan",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326005",
      "brgy_name": "Kimaya",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326007",
      "brgy_name": "Poblacion 1",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326008",
      "brgy_name": "San Martin",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326009",
      "brgy_name": "Tambobong",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326010",
      "brgy_name": "Imelda",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326011",
      "brgy_name": "Looc",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326012",
      "brgy_name": "Poblacion 2",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "104326013",
      "brgy_name": "Poblacion 3",
      "city_code": "104326",
      "province_code": "1043",
      "region_code": "10"
    },
    {
      "brgy_code": "112301004",
      "brgy_name": "Binancian",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301006",
      "brgy_name": "Buan",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301007",
      "brgy_name": "Buclad",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301008",
      "brgy_name": "Cabaywa",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301009",
      "brgy_name": "Camansa",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301010",
      "brgy_name": "Camoning",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301011",
      "brgy_name": "Canatan",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301012",
      "brgy_name": "Concepcion",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301013",
      "brgy_name": "Doña Andrea",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301026",
      "brgy_name": "Magatos",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301028",
      "brgy_name": "Napungas",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301029",
      "brgy_name": "New Bantayan",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301030",
      "brgy_name": "New Santiago",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301031",
      "brgy_name": "Pamacaun",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301032",
      "brgy_name": "Cambanogoy (Pob.)",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301034",
      "brgy_name": "Sagayen",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301036",
      "brgy_name": "San Vicente",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301037",
      "brgy_name": "Santa Filomena",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301039",
      "brgy_name": "Sonlon",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112301040",
      "brgy_name": "New Loon",
      "city_code": "112301",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303001",
      "brgy_name": "Alejal",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303002",
      "brgy_name": "Anibongan",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303003",
      "brgy_name": "Asuncion (Cuatro-Cuatro)",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303005",
      "brgy_name": "Cebulano",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303006",
      "brgy_name": "Guadalupe",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303007",
      "brgy_name": "Ising (Pob.)",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303008",
      "brgy_name": "La Paz",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303010",
      "brgy_name": "Mabaus",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303011",
      "brgy_name": "Mabuhay",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303012",
      "brgy_name": "Magsaysay",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303014",
      "brgy_name": "Mangalcal",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303015",
      "brgy_name": "Minda",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303016",
      "brgy_name": "New Camiling",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303018",
      "brgy_name": "San Isidro",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303019",
      "brgy_name": "Santo Niño",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303020",
      "brgy_name": "Tibulao",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303021",
      "brgy_name": "Tubod",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303022",
      "brgy_name": "Tuganay",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303023",
      "brgy_name": "Salvacion",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112303024",
      "brgy_name": "Taba",
      "city_code": "112303",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305003",
      "brgy_name": "Semong",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305005",
      "brgy_name": "Florida",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305006",
      "brgy_name": "Gabuyan",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305007",
      "brgy_name": "Gupitan",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305008",
      "brgy_name": "Capungagan",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305009",
      "brgy_name": "Katipunan",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305012",
      "brgy_name": "Luna",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305013",
      "brgy_name": "Mabantao",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305015",
      "brgy_name": "Mamacao",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305018",
      "brgy_name": "Pag-asa",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305021",
      "brgy_name": "Maniki (Poblacion)",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305022",
      "brgy_name": "Sampao",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305026",
      "brgy_name": "Sua-on",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112305028",
      "brgy_name": "Tiburcia",
      "city_code": "112305",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314001",
      "brgy_name": "Cabidianan",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314002",
      "brgy_name": "Carcor",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314003",
      "brgy_name": "Del Monte",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314004",
      "brgy_name": "Del Pilar",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314005",
      "brgy_name": "El Salvador",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314006",
      "brgy_name": "Limba-an",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314007",
      "brgy_name": "Macgum",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314008",
      "brgy_name": "Mambing",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314009",
      "brgy_name": "Mesaoy",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314010",
      "brgy_name": "New Bohol",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314011",
      "brgy_name": "New Cortez",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314012",
      "brgy_name": "New Sambog",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314013",
      "brgy_name": "Patrocenio",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314014",
      "brgy_name": "Poblacion",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314015",
      "brgy_name": "San Roque",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314016",
      "brgy_name": "Santa Cruz",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314017",
      "brgy_name": "Santa Fe",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314018",
      "brgy_name": "Santo Niño",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314019",
      "brgy_name": "Suawon",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112314020",
      "brgy_name": "San Jose",
      "city_code": "112314",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315001",
      "brgy_name": "A. O. Floirendo",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315002",
      "brgy_name": "Datu Abdul Dadia",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315003",
      "brgy_name": "Buenavista",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315004",
      "brgy_name": "Cacao",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315005",
      "brgy_name": "Cagangohan",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315006",
      "brgy_name": "Consolacion",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315007",
      "brgy_name": "Dapco",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315009",
      "brgy_name": "Gredu (Pob.)",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315010",
      "brgy_name": "J.P. Laurel",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315011",
      "brgy_name": "Kasilak",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315012",
      "brgy_name": "Katipunan",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315013",
      "brgy_name": "Katualan",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315014",
      "brgy_name": "Kauswagan",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315015",
      "brgy_name": "Kiotoy",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315016",
      "brgy_name": "Little Panay",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315017",
      "brgy_name": "Lower Panaga (Roxas)",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315018",
      "brgy_name": "Mabunao",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315019",
      "brgy_name": "Maduao",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315020",
      "brgy_name": "Malativas",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315021",
      "brgy_name": "Manay",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315022",
      "brgy_name": "Nanyo",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315023",
      "brgy_name": "New Malaga (Dalisay)",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315024",
      "brgy_name": "New Malitbog",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315025",
      "brgy_name": "New Pandan (Pob.)",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315026",
      "brgy_name": "New Visayas",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315027",
      "brgy_name": "Quezon",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315028",
      "brgy_name": "Salvacion",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315029",
      "brgy_name": "San Francisco (Pob.)",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315030",
      "brgy_name": "San Nicolas",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315031",
      "brgy_name": "San Roque",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315032",
      "brgy_name": "San Vicente",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315033",
      "brgy_name": "Santa Cruz",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315034",
      "brgy_name": "Santo Niño (Pob.)",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315035",
      "brgy_name": "Sindaton",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315036",
      "brgy_name": "Southern Davao",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315037",
      "brgy_name": "Tagpore",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315038",
      "brgy_name": "Tibungol",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315039",
      "brgy_name": "Upper Licanan",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315040",
      "brgy_name": "Waterfall",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112315041",
      "brgy_name": "San Pedro",
      "city_code": "112315",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317001",
      "brgy_name": "Adecor",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317002",
      "brgy_name": "Anonang",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317003",
      "brgy_name": "Aumbay",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317004",
      "brgy_name": "Aundanao",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317005",
      "brgy_name": "Balet",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317006",
      "brgy_name": "Bandera",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317007",
      "brgy_name": "Caliclic (Dangca-an)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317008",
      "brgy_name": "Camudmud",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317009",
      "brgy_name": "Catagman",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317010",
      "brgy_name": "Cawag",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317011",
      "brgy_name": "Cogon",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317012",
      "brgy_name": "Cogon (Talicod)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317013",
      "brgy_name": "Dadatan",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317014",
      "brgy_name": "Del Monte",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317015",
      "brgy_name": "Guilon",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317016",
      "brgy_name": "Kanaan",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317017",
      "brgy_name": "Kinawitnon",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317018",
      "brgy_name": "Libertad",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317019",
      "brgy_name": "Libuak",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317020",
      "brgy_name": "Licup",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317021",
      "brgy_name": "Limao",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317022",
      "brgy_name": "Linosutan",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317023",
      "brgy_name": "Mambago-A",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317024",
      "brgy_name": "Mambago-B",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317025",
      "brgy_name": "Miranda (Pob.)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317026",
      "brgy_name": "Moncado (Pob.)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317027",
      "brgy_name": "Pangubatan (Talicod I)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317028",
      "brgy_name": "Peñaplata (Pob.)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317029",
      "brgy_name": "Poblacion (Kaputian)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317030",
      "brgy_name": "San Agustin",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317031",
      "brgy_name": "San Antonio",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317032",
      "brgy_name": "San Isidro (Babak)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317033",
      "brgy_name": "San Isidro (Kaputian)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317034",
      "brgy_name": "San Jose (San Lapuz)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317035",
      "brgy_name": "San Miguel (Magamono)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317036",
      "brgy_name": "San Remigio",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317037",
      "brgy_name": "Santa Cruz (Talicod II)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317038",
      "brgy_name": "Santo Niño",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317039",
      "brgy_name": "Sion (Zion)",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317040",
      "brgy_name": "Tagbaobo",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317041",
      "brgy_name": "Tagbay",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317042",
      "brgy_name": "Tagbitan-ag",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317043",
      "brgy_name": "Tagdaliao",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317044",
      "brgy_name": "Tagpopongan",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317045",
      "brgy_name": "Tambo",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112317046",
      "brgy_name": "Toril",
      "city_code": "112317",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318001",
      "brgy_name": "Balagunan",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318002",
      "brgy_name": "Bobongon",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318003",
      "brgy_name": "Esperanza",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318004",
      "brgy_name": "Kimamon",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318005",
      "brgy_name": "Kinamayan",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318006",
      "brgy_name": "La Libertad",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318007",
      "brgy_name": "Lungaog",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318008",
      "brgy_name": "Magwawa",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318009",
      "brgy_name": "New Katipunan",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318010",
      "brgy_name": "Pantaron",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318011",
      "brgy_name": "Tibal-og (Pob.)",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318013",
      "brgy_name": "San Jose",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318014",
      "brgy_name": "San Miguel",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318015",
      "brgy_name": "Talomo",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318016",
      "brgy_name": "Casig-Ang",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318017",
      "brgy_name": "New Visayas",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318018",
      "brgy_name": "Salvacion",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318019",
      "brgy_name": "San Vicente",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112318020",
      "brgy_name": "Tulalian",
      "city_code": "112318",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319001",
      "brgy_name": "Apokon",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319003",
      "brgy_name": "Bincungan",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319004",
      "brgy_name": "Busaon",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319005",
      "brgy_name": "Canocotan",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319006",
      "brgy_name": "Cuambogan",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319007",
      "brgy_name": "La Filipina",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319008",
      "brgy_name": "Liboganon",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319009",
      "brgy_name": "Madaum",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319010",
      "brgy_name": "Magdum",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319011",
      "brgy_name": "Mankilam",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319012",
      "brgy_name": "New Balamban",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319013",
      "brgy_name": "Nueva Fuerza",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319014",
      "brgy_name": "Pagsabangan",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319015",
      "brgy_name": "Pandapan",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319016",
      "brgy_name": "Magugpo Poblacion",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319018",
      "brgy_name": "San Agustin",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319019",
      "brgy_name": "San Isidro",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319020",
      "brgy_name": "San Miguel (Camp 4)",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319022",
      "brgy_name": "Visayan Village",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319023",
      "brgy_name": "Magugpo East",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319024",
      "brgy_name": "Magugpo North",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319025",
      "brgy_name": "Magugpo South",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112319026",
      "brgy_name": "Magugpo West",
      "city_code": "112319",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112322001",
      "brgy_name": "Dagohoy",
      "city_code": "112322",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112322002",
      "brgy_name": "Palma Gil",
      "city_code": "112322",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112322003",
      "brgy_name": "Santo Niño",
      "city_code": "112322",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112323001",
      "brgy_name": "Cabay-Angan",
      "city_code": "112323",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112323002",
      "brgy_name": "Dujali",
      "city_code": "112323",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112323003",
      "brgy_name": "Magupising",
      "city_code": "112323",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112323004",
      "brgy_name": "New Casay",
      "city_code": "112323",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112323005",
      "brgy_name": "Tanglaw",
      "city_code": "112323",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324001",
      "brgy_name": "Dacudao",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324002",
      "brgy_name": "Datu Balong",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324003",
      "brgy_name": "Igangon",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324004",
      "brgy_name": "Kipalili",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324005",
      "brgy_name": "Libuton",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324006",
      "brgy_name": "Linao",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324007",
      "brgy_name": "Mamangan",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324008",
      "brgy_name": "Monte Dujali",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324009",
      "brgy_name": "Pinamuno",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324010",
      "brgy_name": "Sabangan",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324011",
      "brgy_name": "San Miguel",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324012",
      "brgy_name": "Santo Niño",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112324013",
      "brgy_name": "Sawata",
      "city_code": "112324",
      "province_code": "1123",
      "region_code": "11"
    },
    {
      "brgy_code": "112401001",
      "brgy_name": "Alegre",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401002",
      "brgy_name": "Alta Vista",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401003",
      "brgy_name": "Anonang",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401004",
      "brgy_name": "Bitaug",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401005",
      "brgy_name": "Bonifacio",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401006",
      "brgy_name": "Buenavista",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401007",
      "brgy_name": "Darapuay",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401008",
      "brgy_name": "Dolo",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401009",
      "brgy_name": "Eman",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401010",
      "brgy_name": "Kinuskusan",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401011",
      "brgy_name": "Libertad",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401012",
      "brgy_name": "Linawan",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401013",
      "brgy_name": "Mabuhay",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401014",
      "brgy_name": "Mabunga",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401015",
      "brgy_name": "Managa",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401016",
      "brgy_name": "Marber",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401017",
      "brgy_name": "New Clarin (Miral)",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401018",
      "brgy_name": "Poblacion",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401019",
      "brgy_name": "Rizal",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401020",
      "brgy_name": "Santo Niño",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401021",
      "brgy_name": "Sibayan",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401022",
      "brgy_name": "Tinongtongan",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401023",
      "brgy_name": "Tubod",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401024",
      "brgy_name": "Union",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112401025",
      "brgy_name": "Poblacion Dos",
      "city_code": "112401",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402001",
      "brgy_name": "Acacia",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402002",
      "brgy_name": "Agdao",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402003",
      "brgy_name": "Alambre",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402004",
      "brgy_name": "Atan-Awe",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402005",
      "brgy_name": "Bago Gallera",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402006",
      "brgy_name": "Bago Oshiro",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402007",
      "brgy_name": "Baguio (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402009",
      "brgy_name": "Balengaeng",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402010",
      "brgy_name": "Baliok",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402012",
      "brgy_name": "Bangkas Heights",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402013",
      "brgy_name": "Baracatan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402014",
      "brgy_name": "Bato",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402015",
      "brgy_name": "Bayabas",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402016",
      "brgy_name": "Biao Escuela",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402017",
      "brgy_name": "Biao Guianga",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402018",
      "brgy_name": "Biao Joaquin",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402019",
      "brgy_name": "Binugao",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402020",
      "brgy_name": "Bucana",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402021",
      "brgy_name": "Buhangin (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402022",
      "brgy_name": "Bunawan (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402023",
      "brgy_name": "Cabantian",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402024",
      "brgy_name": "Cadalian",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402026",
      "brgy_name": "Calinan (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402027",
      "brgy_name": "Callawa",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402028",
      "brgy_name": "Camansi",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402029",
      "brgy_name": "Carmen",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402030",
      "brgy_name": "Catalunan Grande",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402031",
      "brgy_name": "Catalunan Pequeño",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402032",
      "brgy_name": "Catigan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402033",
      "brgy_name": "Cawayan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402034",
      "brgy_name": "Colosas",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402035",
      "brgy_name": "Communal",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402036",
      "brgy_name": "Crossing Bayabas",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402037",
      "brgy_name": "Dacudao",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402038",
      "brgy_name": "Dalag",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402039",
      "brgy_name": "Dalagdag",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402040",
      "brgy_name": "Daliao",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402041",
      "brgy_name": "Daliaon Plantation",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402042",
      "brgy_name": "Dominga",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402043",
      "brgy_name": "Dumoy",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402044",
      "brgy_name": "Eden",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402045",
      "brgy_name": "Fatima (Benowang)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402047",
      "brgy_name": "Gatungan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402048",
      "brgy_name": "Gumalang",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402049",
      "brgy_name": "Ilang",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402050",
      "brgy_name": "Indangan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402051",
      "brgy_name": "Kilate",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402052",
      "brgy_name": "Lacson",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402053",
      "brgy_name": "Lamanan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402054",
      "brgy_name": "Lampianao",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402055",
      "brgy_name": "Langub",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402056",
      "brgy_name": "Alejandra Navarro (Lasang)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402057",
      "brgy_name": "Lizada",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402058",
      "brgy_name": "Los Amigos",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402059",
      "brgy_name": "Lubogan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402060",
      "brgy_name": "Lumiad",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402061",
      "brgy_name": "Ma-a",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402062",
      "brgy_name": "Mabuhay",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402063",
      "brgy_name": "Magtuod",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402064",
      "brgy_name": "Mahayag",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402065",
      "brgy_name": "Malabog",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402066",
      "brgy_name": "Malagos",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402067",
      "brgy_name": "Malamba",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402068",
      "brgy_name": "Manambulan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402069",
      "brgy_name": "Mandug",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402070",
      "brgy_name": "Manuel Guianga",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402071",
      "brgy_name": "Mapula",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402072",
      "brgy_name": "Marapangi",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402073",
      "brgy_name": "Marilog",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402074",
      "brgy_name": "Matina Aplaya",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402075",
      "brgy_name": "Matina Crossing",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402077",
      "brgy_name": "Matina Pangi",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402078",
      "brgy_name": "Matina Biao",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402079",
      "brgy_name": "Mintal",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402080",
      "brgy_name": "Mudiang",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402081",
      "brgy_name": "Mulig",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402082",
      "brgy_name": "New Carmen",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402083",
      "brgy_name": "New Valencia",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402086",
      "brgy_name": "Pampanga",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402087",
      "brgy_name": "Panacan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402088",
      "brgy_name": "Panalum",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402089",
      "brgy_name": "Pandaitan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402090",
      "brgy_name": "Pangyan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402091",
      "brgy_name": "Paquibato (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402092",
      "brgy_name": "Paradise Embak",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402097",
      "brgy_name": "Riverside",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402098",
      "brgy_name": "Salapawan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402099",
      "brgy_name": "Salaysay",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402100",
      "brgy_name": "San Isidro (Licanan)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402101",
      "brgy_name": "Sasa",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402102",
      "brgy_name": "Sibulan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402104",
      "brgy_name": "Sirawan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402105",
      "brgy_name": "Sirib",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402106",
      "brgy_name": "Suawan (Tuli)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402107",
      "brgy_name": "Subasta",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402108",
      "brgy_name": "Sumimao",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402110",
      "brgy_name": "Tacunan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402112",
      "brgy_name": "Tagakpan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402113",
      "brgy_name": "Tagluno",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402114",
      "brgy_name": "Tagurano",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402115",
      "brgy_name": "Talandang",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402116",
      "brgy_name": "Talomo (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402117",
      "brgy_name": "Talomo River",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402118",
      "brgy_name": "Tamayong",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402119",
      "brgy_name": "Tambobong",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402120",
      "brgy_name": "Tamugan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402121",
      "brgy_name": "Tapak",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402122",
      "brgy_name": "Tawan-tawan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402123",
      "brgy_name": "Tibuloy",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402124",
      "brgy_name": "Tibungco",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402125",
      "brgy_name": "Tigatto",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402126",
      "brgy_name": "Toril (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402127",
      "brgy_name": "Tugbok (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402128",
      "brgy_name": "Tungakalan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402129",
      "brgy_name": "Ula",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402131",
      "brgy_name": "Wangan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402133",
      "brgy_name": "Wines",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402134",
      "brgy_name": "Barangay 1-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402135",
      "brgy_name": "Barangay 2-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402136",
      "brgy_name": "Barangay 3-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402137",
      "brgy_name": "Barangay 4-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402138",
      "brgy_name": "Barangay 5-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402139",
      "brgy_name": "Barangay 6-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402140",
      "brgy_name": "Barangay 7-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402141",
      "brgy_name": "Barangay 8-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402142",
      "brgy_name": "Barangay 9-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402143",
      "brgy_name": "Barangay 10-A (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402144",
      "brgy_name": "Barangay 11-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402145",
      "brgy_name": "Barangay 12-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402146",
      "brgy_name": "Barangay 13-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402147",
      "brgy_name": "Barangay 14-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402148",
      "brgy_name": "Barangay 15-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402149",
      "brgy_name": "Barangay 16-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402150",
      "brgy_name": "Barangay 17-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402151",
      "brgy_name": "Barangay 18-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402152",
      "brgy_name": "Barangay 19-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402153",
      "brgy_name": "Barangay 20-B (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402154",
      "brgy_name": "Barangay 21-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402155",
      "brgy_name": "Barangay 22-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402156",
      "brgy_name": "Barangay 23-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402157",
      "brgy_name": "Barangay 24-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402158",
      "brgy_name": "Barangay 25-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402159",
      "brgy_name": "Barangay 26-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402160",
      "brgy_name": "Barangay 27-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402161",
      "brgy_name": "Barangay 28-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402162",
      "brgy_name": "Barangay 29-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402163",
      "brgy_name": "Barangay 30-C (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402164",
      "brgy_name": "Barangay 31-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402165",
      "brgy_name": "Barangay 32-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402166",
      "brgy_name": "Barangay 33-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402167",
      "brgy_name": "Barangay 34-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402168",
      "brgy_name": "Barangay 35-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402169",
      "brgy_name": "Barangay 36-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402170",
      "brgy_name": "Barangay 37-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402171",
      "brgy_name": "Barangay 38-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402172",
      "brgy_name": "Barangay 39-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402173",
      "brgy_name": "Barangay 40-D (Pob.)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402174",
      "brgy_name": "Angalan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402175",
      "brgy_name": "Baganihan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402176",
      "brgy_name": "Bago Aplaya",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402177",
      "brgy_name": "Bantol",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402178",
      "brgy_name": "Buda",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402179",
      "brgy_name": "Centro (San Juan)",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402180",
      "brgy_name": "Datu Salumay",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402181",
      "brgy_name": "Gov. Paciano Bangoy",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402182",
      "brgy_name": "Gov. Vicente Duterte",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402183",
      "brgy_name": "Gumitan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402184",
      "brgy_name": "Inayangan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402185",
      "brgy_name": "Kap. Tomas Monteverde, Sr.",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402186",
      "brgy_name": "Lapu-lapu",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402187",
      "brgy_name": "Leon Garcia, Sr.",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402188",
      "brgy_name": "Magsaysay",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402189",
      "brgy_name": "Megkawayan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402190",
      "brgy_name": "Rafael Castillo",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402191",
      "brgy_name": "Saloy",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402192",
      "brgy_name": "San Antonio",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402193",
      "brgy_name": "Santo Niño",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402194",
      "brgy_name": "Ubalde",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402195",
      "brgy_name": "Waan",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402196",
      "brgy_name": "Wilfredo Aquino",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402197",
      "brgy_name": "Alfonso Angliongto Sr.",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112402198",
      "brgy_name": "Vicente Hizon Sr.",
      "city_code": "112402",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403001",
      "brgy_name": "Aplaya",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403002",
      "brgy_name": "Balabag",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403003",
      "brgy_name": "San Jose (Balutakay)",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403004",
      "brgy_name": "Binaton",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403005",
      "brgy_name": "Cogon",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403006",
      "brgy_name": "Colorado",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403007",
      "brgy_name": "Dawis",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403008",
      "brgy_name": "Dulangan",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403009",
      "brgy_name": "Goma",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403010",
      "brgy_name": "Igpit",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403011",
      "brgy_name": "Kiagot",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403012",
      "brgy_name": "Lungag",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403013",
      "brgy_name": "Mahayahay",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403014",
      "brgy_name": "Matti",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403019",
      "brgy_name": "Kapatagan (Rizal)",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403020",
      "brgy_name": "Ruparan",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403021",
      "brgy_name": "San Agustin",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403022",
      "brgy_name": "San Miguel (Odaca)",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403023",
      "brgy_name": "San Roque",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403024",
      "brgy_name": "Sinawilan",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403025",
      "brgy_name": "Soong",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403026",
      "brgy_name": "Tiguman",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403027",
      "brgy_name": "Tres De Mayo",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403028",
      "brgy_name": "Zone 1 (Pob.)",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403029",
      "brgy_name": "Zone 2 (Pob.)",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112403030",
      "brgy_name": "Zone 3 (Pob.)",
      "city_code": "112403",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404002",
      "brgy_name": "Balutakay",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404005",
      "brgy_name": "Clib",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404006",
      "brgy_name": "Guihing Aplaya",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404007",
      "brgy_name": "Guihing",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404008",
      "brgy_name": "Hagonoy Crossing",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404009",
      "brgy_name": "Kibuaya",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404010",
      "brgy_name": "La Union",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404011",
      "brgy_name": "Lanuro",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404012",
      "brgy_name": "Lapulabao",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404013",
      "brgy_name": "Leling",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404014",
      "brgy_name": "Mahayahay",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404017",
      "brgy_name": "Malabang Damsite",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404018",
      "brgy_name": "Maliit Digos",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404019",
      "brgy_name": "New Quezon",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404020",
      "brgy_name": "Paligue",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404021",
      "brgy_name": "Poblacion",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404022",
      "brgy_name": "Sacub",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404023",
      "brgy_name": "San Guillermo",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404024",
      "brgy_name": "San Isidro",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404025",
      "brgy_name": "Sinayawan",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112404026",
      "brgy_name": "Tologan",
      "city_code": "112404",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406001",
      "brgy_name": "Abnate",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406002",
      "brgy_name": "Bagong Negros",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406003",
      "brgy_name": "Bagong Silang",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406004",
      "brgy_name": "Bagumbayan",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406005",
      "brgy_name": "Balasiao",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406007",
      "brgy_name": "Bonifacio",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406008",
      "brgy_name": "Bunot",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406009",
      "brgy_name": "Cogon-Bacaca",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406010",
      "brgy_name": "Dapok",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406011",
      "brgy_name": "Ihan",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406012",
      "brgy_name": "Kibongbong",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406013",
      "brgy_name": "Kimlawis",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406015",
      "brgy_name": "Kisulan",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406016",
      "brgy_name": "Lati-an",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406017",
      "brgy_name": "Manual",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406018",
      "brgy_name": "Maraga-a",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406019",
      "brgy_name": "Molopolo",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406020",
      "brgy_name": "New Sibonga",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406021",
      "brgy_name": "Panaglib",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406022",
      "brgy_name": "Pasig",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406023",
      "brgy_name": "Poblacion",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406024",
      "brgy_name": "Pocaleel",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406025",
      "brgy_name": "San Isidro",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406026",
      "brgy_name": "San Jose",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406027",
      "brgy_name": "San Pedro",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406028",
      "brgy_name": "Santo Niño",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406029",
      "brgy_name": "Tacub",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406030",
      "brgy_name": "Tacul",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406031",
      "brgy_name": "Waterfall",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112406032",
      "brgy_name": "Bulol-Salo",
      "city_code": "112406",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407001",
      "brgy_name": "Bacungan",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407002",
      "brgy_name": "Balnate",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407003",
      "brgy_name": "Barayong",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407004",
      "brgy_name": "Blocon",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407005",
      "brgy_name": "Dalawinon",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407006",
      "brgy_name": "Dalumay",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407007",
      "brgy_name": "Glamang",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407008",
      "brgy_name": "Kanapulo",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407009",
      "brgy_name": "Kasuga",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407010",
      "brgy_name": "Lower Bala",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407011",
      "brgy_name": "Mabini",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407012",
      "brgy_name": "Malawanit",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407013",
      "brgy_name": "Malongon",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407014",
      "brgy_name": "New Ilocos",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407015",
      "brgy_name": "Poblacion",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407016",
      "brgy_name": "San Isidro",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407017",
      "brgy_name": "San Miguel",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407018",
      "brgy_name": "Tacul",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407019",
      "brgy_name": "Tagaytay",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407020",
      "brgy_name": "Upper Bala",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407021",
      "brgy_name": "Maibo",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112407022",
      "brgy_name": "New Opon",
      "city_code": "112407",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408002",
      "brgy_name": "Baybay",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408004",
      "brgy_name": "Bolton",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408005",
      "brgy_name": "Bulacan",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408006",
      "brgy_name": "Caputian",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408007",
      "brgy_name": "Ibo",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408009",
      "brgy_name": "Kiblagon",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408010",
      "brgy_name": "Lapu-Lapu (Lapla)",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408011",
      "brgy_name": "Mabini",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408014",
      "brgy_name": "New Baclayon",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408015",
      "brgy_name": "Pitu",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408016",
      "brgy_name": "Poblacion",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408018",
      "brgy_name": "Tagansule",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408019",
      "brgy_name": "Bagumbayan",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408020",
      "brgy_name": "Rizal (Parame)",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112408021",
      "brgy_name": "San Isidro",
      "city_code": "112408",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410001",
      "brgy_name": "Asbang",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410002",
      "brgy_name": "Asinan",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410003",
      "brgy_name": "Bagumbayan",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410004",
      "brgy_name": "Bangkal",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410005",
      "brgy_name": "Buas",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410006",
      "brgy_name": "Buri",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410007",
      "brgy_name": "Camanchiles",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410008",
      "brgy_name": "Ceboza",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410009",
      "brgy_name": "Colonsabak",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410010",
      "brgy_name": "Dongan-Pekong",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410012",
      "brgy_name": "Kabasagan",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410013",
      "brgy_name": "Kapok",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410014",
      "brgy_name": "Kauswagan",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410015",
      "brgy_name": "Kibao",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410016",
      "brgy_name": "La Suerte",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410017",
      "brgy_name": "Langa-an",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410019",
      "brgy_name": "Lower Marber",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410021",
      "brgy_name": "Cabligan (Managa)",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410022",
      "brgy_name": "Manga",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410023",
      "brgy_name": "New Katipunan",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410024",
      "brgy_name": "New Murcia",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410025",
      "brgy_name": "New Visayas",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410026",
      "brgy_name": "Poblacion",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410027",
      "brgy_name": "Saboy",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410028",
      "brgy_name": "San Jose",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410029",
      "brgy_name": "San Miguel",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410030",
      "brgy_name": "San Vicente",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410031",
      "brgy_name": "Saub",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410032",
      "brgy_name": "Sinaragan",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410033",
      "brgy_name": "Sinawilan",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410034",
      "brgy_name": "Tamlangon",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410035",
      "brgy_name": "Towak",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112410036",
      "brgy_name": "Tibongbong",
      "city_code": "112410",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411001",
      "brgy_name": "Almendras (Pob.)",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411002",
      "brgy_name": "Don Sergio Osmeña, Sr.",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411003",
      "brgy_name": "Harada Butai",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411004",
      "brgy_name": "Lower Katipunan",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411005",
      "brgy_name": "Lower Limonzo",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411006",
      "brgy_name": "Lower Malinao",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411007",
      "brgy_name": "N C Ordaneza District (Pob.)",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411008",
      "brgy_name": "Northern Paligue",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411009",
      "brgy_name": "Palili",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411010",
      "brgy_name": "Piape",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411011",
      "brgy_name": "Punta Piape",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411012",
      "brgy_name": "Quirino District (Pob.)",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411013",
      "brgy_name": "San Isidro",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411014",
      "brgy_name": "Southern Paligue",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411015",
      "brgy_name": "Tulogan",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411016",
      "brgy_name": "Upper Limonzo",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112411017",
      "brgy_name": "Upper Malinao",
      "city_code": "112411",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412001",
      "brgy_name": "Astorga",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412002",
      "brgy_name": "Bato",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412003",
      "brgy_name": "Coronon",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412004",
      "brgy_name": "Darong",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412005",
      "brgy_name": "Inawayan",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412006",
      "brgy_name": "Jose Rizal",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412009",
      "brgy_name": "Matutungan",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412010",
      "brgy_name": "Melilia",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412011",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412013",
      "brgy_name": "Saliducon",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412014",
      "brgy_name": "Sibulan",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412015",
      "brgy_name": "Sinoron",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412016",
      "brgy_name": "Tagabuli",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412017",
      "brgy_name": "Tibolo",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412018",
      "brgy_name": "Tuban",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412019",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412020",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112412021",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "112412",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414001",
      "brgy_name": "Balasinon",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414002",
      "brgy_name": "Buguis",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414003",
      "brgy_name": "Carre",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414004",
      "brgy_name": "Clib",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414005",
      "brgy_name": "Harada Butai",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414006",
      "brgy_name": "Katipunan",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414007",
      "brgy_name": "Kiblagon",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414008",
      "brgy_name": "Labon",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414009",
      "brgy_name": "Laperas",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414010",
      "brgy_name": "Lapla",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414011",
      "brgy_name": "Litos",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414012",
      "brgy_name": "Luparan",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414013",
      "brgy_name": "Mckinley",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414014",
      "brgy_name": "New Cebu",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414015",
      "brgy_name": "Osmeña",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414016",
      "brgy_name": "Palili",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414017",
      "brgy_name": "Parame",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414018",
      "brgy_name": "Poblacion",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414020",
      "brgy_name": "Roxas",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414021",
      "brgy_name": "Solongvale",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414022",
      "brgy_name": "Tagolilong",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414023",
      "brgy_name": "Tala-o",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414024",
      "brgy_name": "Talas",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414025",
      "brgy_name": "Tanwalang",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112414026",
      "brgy_name": "Waterfall",
      "city_code": "112414",
      "province_code": "1124",
      "region_code": "11"
    },
    {
      "brgy_code": "112501001",
      "brgy_name": "Baculin",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501002",
      "brgy_name": "Banao",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501003",
      "brgy_name": "Batawan",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501004",
      "brgy_name": "Batiano",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501005",
      "brgy_name": "Binondo",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501006",
      "brgy_name": "Bobonao",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501007",
      "brgy_name": "Campawan",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501008",
      "brgy_name": "Central (Pob.)",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501009",
      "brgy_name": "Dapnan",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501010",
      "brgy_name": "Kinablangan",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501011",
      "brgy_name": "Lambajon",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501012",
      "brgy_name": "Mahanub",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501013",
      "brgy_name": "Mikit",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501014",
      "brgy_name": "Salingcomot",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501015",
      "brgy_name": "San Isidro",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501016",
      "brgy_name": "San Victor",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501017",
      "brgy_name": "Lucod",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112501018",
      "brgy_name": "Saoquegue",
      "city_code": "112501",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502001",
      "brgy_name": "Cabangcalan",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502002",
      "brgy_name": "Caganganan",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502003",
      "brgy_name": "Calubihan",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502004",
      "brgy_name": "Causwagan",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502005",
      "brgy_name": "Punta Linao",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502006",
      "brgy_name": "Mahayag",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502007",
      "brgy_name": "Maputi",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502008",
      "brgy_name": "Mogbongcogon",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502009",
      "brgy_name": "Panikian",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502010",
      "brgy_name": "Pintatagan",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502011",
      "brgy_name": "Piso Proper",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502012",
      "brgy_name": "Poblacion",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502013",
      "brgy_name": "San Vicente",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112502014",
      "brgy_name": "Rang-ay",
      "city_code": "112502",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503001",
      "brgy_name": "Cabasagan",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503002",
      "brgy_name": "Caatihan",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503003",
      "brgy_name": "Cawayanan",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503004",
      "brgy_name": "Poblacion",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503005",
      "brgy_name": "San Jose",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503006",
      "brgy_name": "Sibajay",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503007",
      "brgy_name": "Carmen",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112503008",
      "brgy_name": "Simulao",
      "city_code": "112503",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504001",
      "brgy_name": "Alvar",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504002",
      "brgy_name": "Caningag",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504004",
      "brgy_name": "Don Leon Balante",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504005",
      "brgy_name": "Lamiawan",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504006",
      "brgy_name": "Manorigao",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504007",
      "brgy_name": "Mercedes",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504008",
      "brgy_name": "Palma Gil",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504009",
      "brgy_name": "Pichon",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504010",
      "brgy_name": "Poblacion",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504011",
      "brgy_name": "San Antonio",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504012",
      "brgy_name": "San Jose",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504013",
      "brgy_name": "San Luis",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504014",
      "brgy_name": "San Miguel",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504015",
      "brgy_name": "San Pedro",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504016",
      "brgy_name": "Santa Fe",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504017",
      "brgy_name": "Santiago",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112504018",
      "brgy_name": "Sobrecarey",
      "city_code": "112504",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505001",
      "brgy_name": "Abijod",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505002",
      "brgy_name": "Alegria",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505003",
      "brgy_name": "Aliwagwag",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505004",
      "brgy_name": "Aragon",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505005",
      "brgy_name": "Baybay",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505009",
      "brgy_name": "Maglahus",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505010",
      "brgy_name": "Mainit",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505011",
      "brgy_name": "Malibago",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505012",
      "brgy_name": "San Alfonso",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505013",
      "brgy_name": "San Antonio",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505014",
      "brgy_name": "San Miguel",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505015",
      "brgy_name": "San Rafael",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505016",
      "brgy_name": "San Vicente",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505017",
      "brgy_name": "Santa Filomena",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505018",
      "brgy_name": "Taytayan",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112505019",
      "brgy_name": "Poblacion",
      "city_code": "112505",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506001",
      "brgy_name": "Anitap",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506002",
      "brgy_name": "Manuel Roxas",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506003",
      "brgy_name": "Don Aurelio Chicote",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506004",
      "brgy_name": "Lavigan",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506005",
      "brgy_name": "Luzon",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506006",
      "brgy_name": "Magdug",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506007",
      "brgy_name": "Monserrat",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506008",
      "brgy_name": "Nangan",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506009",
      "brgy_name": "Oregon",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506010",
      "brgy_name": "Poblacion",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506011",
      "brgy_name": "Pundaguitan",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506012",
      "brgy_name": "Sergio Osmeña",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506013",
      "brgy_name": "Surop",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506014",
      "brgy_name": "Tagabebe",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506015",
      "brgy_name": "Tamban",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506016",
      "brgy_name": "Tandang Sora",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506017",
      "brgy_name": "Tibanban",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506018",
      "brgy_name": "Tiblawan",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506019",
      "brgy_name": "Upper Tibanban",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112506020",
      "brgy_name": "Crispin Dela Cruz",
      "city_code": "112506",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507001",
      "brgy_name": "Bagumbayan",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507003",
      "brgy_name": "Cabadiangan",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507006",
      "brgy_name": "Calapagan",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507008",
      "brgy_name": "Cocornon",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507009",
      "brgy_name": "Corporacion",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507010",
      "brgy_name": "Don Mariano Marcos",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507011",
      "brgy_name": "Ilangay",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507013",
      "brgy_name": "Langka",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507014",
      "brgy_name": "Lantawan",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507015",
      "brgy_name": "Limbahan",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507016",
      "brgy_name": "Macangao",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507017",
      "brgy_name": "Magsaysay",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507019",
      "brgy_name": "Mahayahay",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507021",
      "brgy_name": "Maragatas",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507022",
      "brgy_name": "Marayag",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507024",
      "brgy_name": "New Visayas",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507028",
      "brgy_name": "Poblacion",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507029",
      "brgy_name": "San Isidro",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507030",
      "brgy_name": "San Jose",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507032",
      "brgy_name": "Tagboa",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112507033",
      "brgy_name": "Tagugpo",
      "city_code": "112507",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508001",
      "brgy_name": "Capasnan",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508002",
      "brgy_name": "Cayawan",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508003",
      "brgy_name": "Central (Pob.)",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508004",
      "brgy_name": "Concepcion",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508006",
      "brgy_name": "Del Pilar",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508007",
      "brgy_name": "Guza",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508008",
      "brgy_name": "Holy Cross",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508009",
      "brgy_name": "Mabini",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508010",
      "brgy_name": "Manreza",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508011",
      "brgy_name": "Old Macopa",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508012",
      "brgy_name": "Rizal",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508013",
      "brgy_name": "San Fermin",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508014",
      "brgy_name": "San Ignacio",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508015",
      "brgy_name": "San Isidro",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508016",
      "brgy_name": "New Taokanga",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508017",
      "brgy_name": "Zaragosa",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112508018",
      "brgy_name": "Lambog",
      "city_code": "112508",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509001",
      "brgy_name": "Badas",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509002",
      "brgy_name": "Bobon",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509003",
      "brgy_name": "Buso",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509004",
      "brgy_name": "Cabuaya",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509005",
      "brgy_name": "Central (Pob.)",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509006",
      "brgy_name": "Culian",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509007",
      "brgy_name": "Dahican",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509008",
      "brgy_name": "Danao",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509009",
      "brgy_name": "Dawan",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509010",
      "brgy_name": "Don Enrique Lopez",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509011",
      "brgy_name": "Don Martin Marundan",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509012",
      "brgy_name": "Don Salvador Lopez, Sr.",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509013",
      "brgy_name": "Langka",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509015",
      "brgy_name": "Lawigan",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509016",
      "brgy_name": "Libudon",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509017",
      "brgy_name": "Luban",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509018",
      "brgy_name": "Macambol",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509019",
      "brgy_name": "Mamali",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509020",
      "brgy_name": "Matiao",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509021",
      "brgy_name": "Mayo",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509022",
      "brgy_name": "Sainz",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509023",
      "brgy_name": "Sanghay",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509024",
      "brgy_name": "Tagabakid",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509025",
      "brgy_name": "Tagbinonga",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509026",
      "brgy_name": "Taguibo",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112509027",
      "brgy_name": "Tamisan",
      "city_code": "112509",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510001",
      "brgy_name": "Baon",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510003",
      "brgy_name": "Bitaogan",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510004",
      "brgy_name": "Cambaleon",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510005",
      "brgy_name": "Dugmanon",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510006",
      "brgy_name": "Iba",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510007",
      "brgy_name": "La Union",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510008",
      "brgy_name": "Lapu-lapu",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510009",
      "brgy_name": "Maag",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510010",
      "brgy_name": "Manikling",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510011",
      "brgy_name": "Maputi",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510012",
      "brgy_name": "Batobato (Pob.)",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510013",
      "brgy_name": "San Miguel",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510014",
      "brgy_name": "San Roque",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510015",
      "brgy_name": "Santo Rosario",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510016",
      "brgy_name": "Sudlon",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112510017",
      "brgy_name": "Talisay",
      "city_code": "112510",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511001",
      "brgy_name": "Cabagayan",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511002",
      "brgy_name": "Central (Pob.)",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511003",
      "brgy_name": "Dadong",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511004",
      "brgy_name": "Jovellar",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511005",
      "brgy_name": "Limot",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511006",
      "brgy_name": "Lucatan",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511007",
      "brgy_name": "Maganda",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511008",
      "brgy_name": "Ompao",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511010",
      "brgy_name": "Tomoaong",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "112511011",
      "brgy_name": "Tubaon",
      "city_code": "112511",
      "province_code": "1125",
      "region_code": "11"
    },
    {
      "brgy_code": "118201001",
      "brgy_name": "Bagongon",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201002",
      "brgy_name": "Gabi",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201003",
      "brgy_name": "Lagab",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201004",
      "brgy_name": "Mangayon",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201005",
      "brgy_name": "Mapaca",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201006",
      "brgy_name": "Maparat",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201007",
      "brgy_name": "New Alegria",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201008",
      "brgy_name": "Ngan",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201009",
      "brgy_name": "Osmeña",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201010",
      "brgy_name": "Panansalan",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201011",
      "brgy_name": "Poblacion",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201012",
      "brgy_name": "San Jose",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201013",
      "brgy_name": "San Miguel",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201014",
      "brgy_name": "Siocon",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201015",
      "brgy_name": "Tamia",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118201016",
      "brgy_name": "Aurora",
      "city_code": "118201",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202001",
      "brgy_name": "Aguinaldo",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202002",
      "brgy_name": "Banbanon",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202003",
      "brgy_name": "Binasbas",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202004",
      "brgy_name": "Cebulida",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202005",
      "brgy_name": "Il Papa",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202006",
      "brgy_name": "Kaligutan",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202007",
      "brgy_name": "Kapatagan",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202008",
      "brgy_name": "Kidawa",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202009",
      "brgy_name": "Kilagding",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202010",
      "brgy_name": "Kiokmay",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202011",
      "brgy_name": "Langtud",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202012",
      "brgy_name": "Longanapan",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202013",
      "brgy_name": "Naga",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202014",
      "brgy_name": "Laac (Pob.)",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202015",
      "brgy_name": "San Antonio",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202016",
      "brgy_name": "Amor Cruz",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202017",
      "brgy_name": "Ampawid",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202018",
      "brgy_name": "Andap",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202019",
      "brgy_name": "Anitap",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202020",
      "brgy_name": "Bagong Silang",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202021",
      "brgy_name": "Belmonte",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202022",
      "brgy_name": "Bullucan",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202023",
      "brgy_name": "Concepcion",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202024",
      "brgy_name": "Datu Ampunan",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202025",
      "brgy_name": "Datu Davao",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202026",
      "brgy_name": "Doña Josefa",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202027",
      "brgy_name": "El Katipunan",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202028",
      "brgy_name": "Imelda",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202029",
      "brgy_name": "Inacayan",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202030",
      "brgy_name": "Mabuhay",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202031",
      "brgy_name": "Macopa",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202032",
      "brgy_name": "Malinao",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202033",
      "brgy_name": "Mangloy",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202034",
      "brgy_name": "Melale",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202035",
      "brgy_name": "New Bethlehem",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202036",
      "brgy_name": "Panamoren",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202037",
      "brgy_name": "Sabud",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202038",
      "brgy_name": "Santa Emilia",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202039",
      "brgy_name": "Santo Niño",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118202040",
      "brgy_name": "Sisimon",
      "city_code": "118202",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203002",
      "brgy_name": "Cadunan",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203006",
      "brgy_name": "Pindasan",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203007",
      "brgy_name": "Cuambog (Pob.)",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203011",
      "brgy_name": "Tagnanan (Mampising)",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203012",
      "brgy_name": "Anitapan",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203013",
      "brgy_name": "Cabuyuan",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203014",
      "brgy_name": "Del Pilar",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203015",
      "brgy_name": "Libodon",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203016",
      "brgy_name": "Golden Valley (Maraut)",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203017",
      "brgy_name": "Pangibiran",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118203018",
      "brgy_name": "San Antonio",
      "city_code": "118203",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204001",
      "brgy_name": "Anibongan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204002",
      "brgy_name": "Anislagan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204003",
      "brgy_name": "Binuangan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204004",
      "brgy_name": "Bucana",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204005",
      "brgy_name": "Calabcab",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204006",
      "brgy_name": "Concepcion",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204007",
      "brgy_name": "Dumlan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204008",
      "brgy_name": "Elizalde (Somil)",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204009",
      "brgy_name": "Pangi (Gaudencio Antonio)",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204010",
      "brgy_name": "Gubatan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204011",
      "brgy_name": "Hijo",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204012",
      "brgy_name": "Kinuban",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204013",
      "brgy_name": "Langgam",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204014",
      "brgy_name": "Lapu-lapu",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204015",
      "brgy_name": "Libay-libay",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204016",
      "brgy_name": "Limbo",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204017",
      "brgy_name": "Lumatab",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204018",
      "brgy_name": "Magangit",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204019",
      "brgy_name": "Malamodao",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204020",
      "brgy_name": "Manipongol",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204021",
      "brgy_name": "Mapaang",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204022",
      "brgy_name": "Masara",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204023",
      "brgy_name": "New Asturias",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204024",
      "brgy_name": "Panibasan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204025",
      "brgy_name": "Panoraon",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204026",
      "brgy_name": "Poblacion",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204027",
      "brgy_name": "San Juan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204028",
      "brgy_name": "San Roque",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204029",
      "brgy_name": "Sangab",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204030",
      "brgy_name": "Taglawig",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204031",
      "brgy_name": "Mainit",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204032",
      "brgy_name": "New Barili",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204033",
      "brgy_name": "New Leyte",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204034",
      "brgy_name": "New Visayas",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204035",
      "brgy_name": "Panangan",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204036",
      "brgy_name": "Tagbaros",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118204037",
      "brgy_name": "Teresa",
      "city_code": "118204",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205001",
      "brgy_name": "Bagong Silang",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205002",
      "brgy_name": "Mapawa",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205003",
      "brgy_name": "Maragusan (Pob.)",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205004",
      "brgy_name": "New Albay",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205005",
      "brgy_name": "Tupaz",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205006",
      "brgy_name": "Bahi",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205007",
      "brgy_name": "Cambagang",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205008",
      "brgy_name": "Coronobe",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205009",
      "brgy_name": "Katipunan",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205010",
      "brgy_name": "Lahi",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205011",
      "brgy_name": "Langgawisan",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205012",
      "brgy_name": "Mabugnao",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205013",
      "brgy_name": "Magcagong",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205014",
      "brgy_name": "Mahayahay",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205015",
      "brgy_name": "Mauswagon",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205016",
      "brgy_name": "New Katipunan",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205017",
      "brgy_name": "New Man-ay",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205018",
      "brgy_name": "New Panay",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205019",
      "brgy_name": "Paloc",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205020",
      "brgy_name": "Pamintaran",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205021",
      "brgy_name": "Parasanon",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205022",
      "brgy_name": "Talian",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205023",
      "brgy_name": "Tandik",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118205024",
      "brgy_name": "Tigbao",
      "city_code": "118205",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206001",
      "brgy_name": "Andili",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206002",
      "brgy_name": "Bawani",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206003",
      "brgy_name": "Concepcion",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206004",
      "brgy_name": "Malinawon",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206005",
      "brgy_name": "Nueva Visayas",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206006",
      "brgy_name": "Nuevo Iloco",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206007",
      "brgy_name": "Poblacion",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206008",
      "brgy_name": "Salvacion",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206009",
      "brgy_name": "Saosao",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206010",
      "brgy_name": "Sawangan",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118206011",
      "brgy_name": "Tuboran",
      "city_code": "118206",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207001",
      "brgy_name": "Awao",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207002",
      "brgy_name": "Babag",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207003",
      "brgy_name": "Banlag",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207004",
      "brgy_name": "Baylo",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207005",
      "brgy_name": "Casoon",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207006",
      "brgy_name": "Inambatan",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207007",
      "brgy_name": "Haguimitan",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207008",
      "brgy_name": "Macopa",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207009",
      "brgy_name": "Mamunga",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207010",
      "brgy_name": "Naboc",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207011",
      "brgy_name": "Olaycon",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207012",
      "brgy_name": "Pasian (Santa Filomena)",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207013",
      "brgy_name": "Poblacion",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207014",
      "brgy_name": "Rizal",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207015",
      "brgy_name": "Salvacion",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207016",
      "brgy_name": "San Isidro",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207017",
      "brgy_name": "San Jose",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207019",
      "brgy_name": "Tubo-tubo (New Del Monte)",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207020",
      "brgy_name": "Upper Ulip",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207021",
      "brgy_name": "Union",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118207022",
      "brgy_name": "Mount Diwata",
      "city_code": "118207",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208001",
      "brgy_name": "Banagbanag",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208002",
      "brgy_name": "Banglasan",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208003",
      "brgy_name": "Bankerohan Norte",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208004",
      "brgy_name": "Bankerohan Sur",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208005",
      "brgy_name": "Camansi",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208006",
      "brgy_name": "Camantangan",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208007",
      "brgy_name": "Concepcion",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208008",
      "brgy_name": "Dauman",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208009",
      "brgy_name": "Canidkid",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208010",
      "brgy_name": "Lebanon",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208011",
      "brgy_name": "Linoan",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208012",
      "brgy_name": "Mayaon",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208014",
      "brgy_name": "New Calape",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208015",
      "brgy_name": "New Dalaguete",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208016",
      "brgy_name": "New Cebulan (Sambayon)",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208017",
      "brgy_name": "New Visayas",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208019",
      "brgy_name": "Prosperidad",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208020",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208021",
      "brgy_name": "San Vicente",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118208022",
      "brgy_name": "Tapia",
      "city_code": "118208",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209001",
      "brgy_name": "Anislagan",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209002",
      "brgy_name": "Antiquera",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209003",
      "brgy_name": "Basak",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209004",
      "brgy_name": "Cabacungan",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209005",
      "brgy_name": "Cabidianan",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209006",
      "brgy_name": "Katipunan",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209007",
      "brgy_name": "Libasan",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209008",
      "brgy_name": "Linda",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209009",
      "brgy_name": "Magading",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209010",
      "brgy_name": "Magsaysay",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209011",
      "brgy_name": "Mainit",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209012",
      "brgy_name": "Manat",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209013",
      "brgy_name": "Matilo",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209014",
      "brgy_name": "Mipangi",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209015",
      "brgy_name": "New Dauis",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209016",
      "brgy_name": "New Sibonga",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209017",
      "brgy_name": "Ogao",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209018",
      "brgy_name": "Pangutosan",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209019",
      "brgy_name": "Poblacion",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209020",
      "brgy_name": "San Isidro",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209021",
      "brgy_name": "San Roque",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209022",
      "brgy_name": "San Vicente",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209023",
      "brgy_name": "Santa Maria",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209024",
      "brgy_name": "Santo Niño (Kao)",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209025",
      "brgy_name": "Sasa",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209026",
      "brgy_name": "Tagnocon",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209027",
      "brgy_name": "Bayabas",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118209028",
      "brgy_name": "Bukal",
      "city_code": "118209",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210001",
      "brgy_name": "Bantacan",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210002",
      "brgy_name": "Batinao",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210003",
      "brgy_name": "Camanlangan",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210004",
      "brgy_name": "Cogonon",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210005",
      "brgy_name": "Fatima",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210006",
      "brgy_name": "Katipunan",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210007",
      "brgy_name": "Magsaysay",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210008",
      "brgy_name": "Magangit",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210009",
      "brgy_name": "Pagsabangan",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210010",
      "brgy_name": "Panag",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210011",
      "brgy_name": "Cabinuangan (Pob.)",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210012",
      "brgy_name": "San Roque",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210013",
      "brgy_name": "Andap",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210014",
      "brgy_name": "Kahayag",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210015",
      "brgy_name": "Manurigao",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118210016",
      "brgy_name": "Tandawan",
      "city_code": "118210",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211001",
      "brgy_name": "Bongabong",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211002",
      "brgy_name": "Bongbong",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211003",
      "brgy_name": "P. Fuentes",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211004",
      "brgy_name": "Kingking (Pob.)",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211005",
      "brgy_name": "Magnaga",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211006",
      "brgy_name": "Matiao",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211007",
      "brgy_name": "Napnapan",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211009",
      "brgy_name": "Tagdangua",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211010",
      "brgy_name": "Tambongon",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211011",
      "brgy_name": "Tibagon",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211012",
      "brgy_name": "Las Arenas",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211013",
      "brgy_name": "Araibo",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118211014",
      "brgy_name": "Tag-Ugpo",
      "city_code": "118211",
      "province_code": "1182",
      "region_code": "11"
    },
    {
      "brgy_code": "118601001",
      "brgy_name": "Calian",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601002",
      "brgy_name": "Kiobog",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601003",
      "brgy_name": "North Lamidan",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601004",
      "brgy_name": "Lawa (Pob.)",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601005",
      "brgy_name": "Nueva Villa",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601006",
      "brgy_name": "Talagutong (Pob.)",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601007",
      "brgy_name": "Baluntaya",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601008",
      "brgy_name": "Dalupan",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601009",
      "brgy_name": "Kinanga",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601010",
      "brgy_name": "Lanao",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601011",
      "brgy_name": "Lapuan",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601012",
      "brgy_name": "Linadasan",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601013",
      "brgy_name": "Mabuhay",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601014",
      "brgy_name": "South Lamidan",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118601015",
      "brgy_name": "West Lamidan",
      "city_code": "118601",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602001",
      "brgy_name": "Buguis",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602002",
      "brgy_name": "Balangonan",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602006",
      "brgy_name": "Bukid",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602007",
      "brgy_name": "Butuan",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602008",
      "brgy_name": "Butulan",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602009",
      "brgy_name": "Caburan Big",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602010",
      "brgy_name": "Caburan Small (Pob.)",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602011",
      "brgy_name": "Camalian",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602012",
      "brgy_name": "Carahayan",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602013",
      "brgy_name": "Cayaponga",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602014",
      "brgy_name": "Culaman",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602015",
      "brgy_name": "Kalbay",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602016",
      "brgy_name": "Kitayo",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602019",
      "brgy_name": "Magulibas",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602020",
      "brgy_name": "Malalan",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602021",
      "brgy_name": "Mangile",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602022",
      "brgy_name": "Marabutuan",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602023",
      "brgy_name": "Meybio",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602024",
      "brgy_name": "Molmol",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602025",
      "brgy_name": "Nuing",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602026",
      "brgy_name": "Patulang",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602027",
      "brgy_name": "Quiapo",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602028",
      "brgy_name": "San Isidro",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602033",
      "brgy_name": "Sugal",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602034",
      "brgy_name": "Tabayon",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118602035",
      "brgy_name": "Tanuman",
      "city_code": "118602",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603001",
      "brgy_name": "Bito",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603002",
      "brgy_name": "Bolila",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603003",
      "brgy_name": "Buhangin",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603004",
      "brgy_name": "Culaman",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603005",
      "brgy_name": "Datu Danwata",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603006",
      "brgy_name": "Demoloc",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603007",
      "brgy_name": "Felis",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603008",
      "brgy_name": "Fishing Village (Fisherman's Village)",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603010",
      "brgy_name": "Kibalatong",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603011",
      "brgy_name": "Kidalapong",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603012",
      "brgy_name": "Kilalag",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603013",
      "brgy_name": "Kinangan",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603016",
      "brgy_name": "Lacaron",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603017",
      "brgy_name": "Lagumit",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603018",
      "brgy_name": "Lais",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603022",
      "brgy_name": "Little Baguio",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603023",
      "brgy_name": "Macol",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603024",
      "brgy_name": "Mana",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603025",
      "brgy_name": "Manuel Peralta",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603027",
      "brgy_name": "New Argao",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603030",
      "brgy_name": "Pangian",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603031",
      "brgy_name": "Pinalpalan",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603032",
      "brgy_name": "Poblacion",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603033",
      "brgy_name": "Sangay",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603036",
      "brgy_name": "Talogoy",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603037",
      "brgy_name": "Tical",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603038",
      "brgy_name": "Ticulon",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603039",
      "brgy_name": "Tingolo",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603040",
      "brgy_name": "Tubalan",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118603041",
      "brgy_name": "Pangaleon",
      "city_code": "118603",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604001",
      "brgy_name": "Basiawan",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604002",
      "brgy_name": "Buca",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604003",
      "brgy_name": "Cadaatan",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604004",
      "brgy_name": "Kidadan",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604005",
      "brgy_name": "Kisulad",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604006",
      "brgy_name": "Malalag Tubig",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604007",
      "brgy_name": "Mamacao",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604008",
      "brgy_name": "Ogpao",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604009",
      "brgy_name": "Poblacion",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604010",
      "brgy_name": "Pongpong",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604011",
      "brgy_name": "San Agustin",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604012",
      "brgy_name": "San Antonio",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604013",
      "brgy_name": "San Isidro",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604014",
      "brgy_name": "San Juan",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604015",
      "brgy_name": "San Pedro",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604016",
      "brgy_name": "San Roque",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604018",
      "brgy_name": "Tanglad",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604019",
      "brgy_name": "Santo Niño",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604020",
      "brgy_name": "Santo Rosario",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604021",
      "brgy_name": "Datu Daligasao",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604022",
      "brgy_name": "Datu Intan",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118604023",
      "brgy_name": "Kinilidan",
      "city_code": "118604",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605001",
      "brgy_name": "Batuganding",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605002",
      "brgy_name": "Konel",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605003",
      "brgy_name": "Lipol",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605004",
      "brgy_name": "Mabila (Pob.)",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605005",
      "brgy_name": "Patuco (Sarangani Norte)",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605006",
      "brgy_name": "Laker (Sarangani Sur)",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605007",
      "brgy_name": "Tinina",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605008",
      "brgy_name": "Camahual",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605009",
      "brgy_name": "Camalig",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605010",
      "brgy_name": "Gomtago",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605011",
      "brgy_name": "Tagen",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "118605012",
      "brgy_name": "Tucal",
      "city_code": "118605",
      "province_code": "1186",
      "region_code": "11"
    },
    {
      "brgy_code": "124701001",
      "brgy_name": "Bao",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701002",
      "brgy_name": "Barangiran",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701003",
      "brgy_name": "Camansi",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701004",
      "brgy_name": "Dado",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701005",
      "brgy_name": "Guiling",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701006",
      "brgy_name": "Kitacubong (Pob.)",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701007",
      "brgy_name": "Macabasa",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701008",
      "brgy_name": "Malitubog",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701009",
      "brgy_name": "Mapurok",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701010",
      "brgy_name": "Pacao",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701011",
      "brgy_name": "Paruayan",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701012",
      "brgy_name": "Pigcawaran",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701013",
      "brgy_name": "Polayagan",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701014",
      "brgy_name": "Rangayen",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701015",
      "brgy_name": "Lower Dado",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701016",
      "brgy_name": "Mirasol",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124701017",
      "brgy_name": "Raradangan",
      "city_code": "124701",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702001",
      "brgy_name": "Aroman",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702003",
      "brgy_name": "Bentangan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702005",
      "brgy_name": "Cadiis",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702007",
      "brgy_name": "General Luna",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702008",
      "brgy_name": "Katanayanan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702010",
      "brgy_name": "Kib-Ayao",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702011",
      "brgy_name": "Kibenes",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702012",
      "brgy_name": "Kibugtongan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702013",
      "brgy_name": "Kilala",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702014",
      "brgy_name": "Kimadzil",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702015",
      "brgy_name": "Kitulaan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702016",
      "brgy_name": "Langogan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702017",
      "brgy_name": "Lanoon",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702019",
      "brgy_name": "Liliongan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702020",
      "brgy_name": "Ugalingan (Lumayong)",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702021",
      "brgy_name": "Macabenban",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702022",
      "brgy_name": "Malapag",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702024",
      "brgy_name": "Manarapan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702025",
      "brgy_name": "Manili",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702027",
      "brgy_name": "Nasapian",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702028",
      "brgy_name": "Palanggalan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702030",
      "brgy_name": "Pebpoloan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702032",
      "brgy_name": "Poblacion",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702034",
      "brgy_name": "Ranzo",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702035",
      "brgy_name": "Tacupan",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702036",
      "brgy_name": "Tambad",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702037",
      "brgy_name": "Tonganon",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124702039",
      "brgy_name": "Tupig",
      "city_code": "124702",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703001",
      "brgy_name": "Aringay",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703002",
      "brgy_name": "Bangilan",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703003",
      "brgy_name": "Bannawag",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703004",
      "brgy_name": "Buluan",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703005",
      "brgy_name": "Cuyapon",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703006",
      "brgy_name": "Dagupan",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703007",
      "brgy_name": "Katidtuan",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703008",
      "brgy_name": "Kayaga",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703009",
      "brgy_name": "Kilagasan",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703010",
      "brgy_name": "Magatos",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703011",
      "brgy_name": "Malamote",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703012",
      "brgy_name": "Malanduague",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703013",
      "brgy_name": "Nanga-an",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703014",
      "brgy_name": "Osias",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703015",
      "brgy_name": "Paatan Lower",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703016",
      "brgy_name": "Paatan Upper",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703017",
      "brgy_name": "Pedtad",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703018",
      "brgy_name": "Pisan",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703019",
      "brgy_name": "Poblacion",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703020",
      "brgy_name": "Salapungan",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703021",
      "brgy_name": "Sanggadong",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703022",
      "brgy_name": "Simbuhay",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703023",
      "brgy_name": "Simone",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124703024",
      "brgy_name": "Tamped",
      "city_code": "124703",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704001",
      "brgy_name": "Amas",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704002",
      "brgy_name": "Amazion",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704003",
      "brgy_name": "Balabag",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704004",
      "brgy_name": "Balindog",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704005",
      "brgy_name": "Benoligan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704006",
      "brgy_name": "Berada",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704007",
      "brgy_name": "Gayola",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704008",
      "brgy_name": "Ginatilan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704009",
      "brgy_name": "Ilomavis",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704010",
      "brgy_name": "Indangan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704011",
      "brgy_name": "Junction",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704012",
      "brgy_name": "Kalaisan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704013",
      "brgy_name": "Kalasuyan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704014",
      "brgy_name": "Katipunan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704015",
      "brgy_name": "Lanao",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704016",
      "brgy_name": "Linangcob",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704017",
      "brgy_name": "Luvimin",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704018",
      "brgy_name": "Macabolig",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704020",
      "brgy_name": "Malinan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704021",
      "brgy_name": "Manongol",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704022",
      "brgy_name": "Marbel (Embac)",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704023",
      "brgy_name": "Mateo",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704024",
      "brgy_name": "Meochao",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704025",
      "brgy_name": "Mua-an",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704026",
      "brgy_name": "New Bohol",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704027",
      "brgy_name": "Nuangan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704028",
      "brgy_name": "Onica",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704029",
      "brgy_name": "Paco",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704030",
      "brgy_name": "Patadon (Patadon East)",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704031",
      "brgy_name": "Perez",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704032",
      "brgy_name": "Poblacion",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704033",
      "brgy_name": "San Isidro",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704034",
      "brgy_name": "San Roque",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704035",
      "brgy_name": "Santo Niño",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704036",
      "brgy_name": "Sibawan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704037",
      "brgy_name": "Sikitan",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704038",
      "brgy_name": "Singao",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704039",
      "brgy_name": "Sudapin",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704040",
      "brgy_name": "Sumbao",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124704041",
      "brgy_name": "Magsaysay",
      "city_code": "124704",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705001",
      "brgy_name": "Abaga",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705003",
      "brgy_name": "Baguer",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705004",
      "brgy_name": "Barongis",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705005",
      "brgy_name": "Batiocan",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705006",
      "brgy_name": "Cabaruyan",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705007",
      "brgy_name": "Cabpangi",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705010",
      "brgy_name": "Demapaco",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705011",
      "brgy_name": "Grebona",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705013",
      "brgy_name": "Gumaga",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705014",
      "brgy_name": "Kapayawi",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705015",
      "brgy_name": "Kiloyao",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705017",
      "brgy_name": "Kitubod",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705019",
      "brgy_name": "Malengen",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705023",
      "brgy_name": "Montay",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705024",
      "brgy_name": "Nica-an",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705025",
      "brgy_name": "Palao",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705029",
      "brgy_name": "Poblacion",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705032",
      "brgy_name": "Sinapangan",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705033",
      "brgy_name": "Sinawingan",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124705034",
      "brgy_name": "Ulamian",
      "city_code": "124705",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706001",
      "brgy_name": "Alibayon",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706005",
      "brgy_name": "Bagumbayan",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706006",
      "brgy_name": "Bangkal",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706007",
      "brgy_name": "Bantac",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706008",
      "brgy_name": "Basak",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706009",
      "brgy_name": "Binay",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706010",
      "brgy_name": "Bongolanon",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706014",
      "brgy_name": "Datu Celo",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706015",
      "brgy_name": "Del Pilar",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706016",
      "brgy_name": "Doles",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706021",
      "brgy_name": "Gubatan",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706022",
      "brgy_name": "Ilian",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706023",
      "brgy_name": "Inac",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706024",
      "brgy_name": "Kamada",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706026",
      "brgy_name": "Kauswagan",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706027",
      "brgy_name": "Kisandal",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706029",
      "brgy_name": "Magcaalam",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706030",
      "brgy_name": "Mahongcog",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706033",
      "brgy_name": "Manobo",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706037",
      "brgy_name": "Noa",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706038",
      "brgy_name": "Owas",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706039",
      "brgy_name": "Pangao-an",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706040",
      "brgy_name": "Poblacion",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706042",
      "brgy_name": "Sallab",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706043",
      "brgy_name": "Tagbac",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706044",
      "brgy_name": "Temporan",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706048",
      "brgy_name": "Amabel",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706049",
      "brgy_name": "Balete",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706050",
      "brgy_name": "Don Panaca",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706051",
      "brgy_name": "Imamaling",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706052",
      "brgy_name": "Kinarum",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124706053",
      "brgy_name": "Manobisa",
      "city_code": "124706",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707001",
      "brgy_name": "Batasan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707002",
      "brgy_name": "Bato",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707003",
      "brgy_name": "Biangan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707004",
      "brgy_name": "Buena Vida",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707005",
      "brgy_name": "Buhay",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707006",
      "brgy_name": "Bulakanon",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707007",
      "brgy_name": "Cabilao",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707008",
      "brgy_name": "Concepcion",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707009",
      "brgy_name": "Dagupan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707010",
      "brgy_name": "Garsika",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707011",
      "brgy_name": "Guangan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707012",
      "brgy_name": "Indangan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707013",
      "brgy_name": "Jose Rizal",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707014",
      "brgy_name": "Katipunan II",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707015",
      "brgy_name": "Kawayanon",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707016",
      "brgy_name": "Kisante",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707017",
      "brgy_name": "Leboce",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707018",
      "brgy_name": "Libertad",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707019",
      "brgy_name": "Luayon",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707020",
      "brgy_name": "Luna Norte",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707021",
      "brgy_name": "Luna Sur",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707022",
      "brgy_name": "Malabuan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707023",
      "brgy_name": "Malasila",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707024",
      "brgy_name": "Malungon",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707025",
      "brgy_name": "New Baguio",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707026",
      "brgy_name": "New Bulatukan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707027",
      "brgy_name": "New Cebu",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707028",
      "brgy_name": "Old Bulatukan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707029",
      "brgy_name": "Poblacion",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707030",
      "brgy_name": "Rodero",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707031",
      "brgy_name": "Saguing",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707032",
      "brgy_name": "San Vicente",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707033",
      "brgy_name": "Santa Felomina",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707034",
      "brgy_name": "Santo Niño",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707035",
      "brgy_name": "Sinkatulan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707036",
      "brgy_name": "Taluntalunan",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707037",
      "brgy_name": "Villaflores",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124707038",
      "brgy_name": "New Israel",
      "city_code": "124707",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708001",
      "brgy_name": "New Alimodian",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708003",
      "brgy_name": "Arakan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708004",
      "brgy_name": "Bangbang",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708005",
      "brgy_name": "Bato",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708006",
      "brgy_name": "Central Malamote",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708007",
      "brgy_name": "Dalapitan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708008",
      "brgy_name": "Estado",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708009",
      "brgy_name": "Ilian",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708010",
      "brgy_name": "Kabulacan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708012",
      "brgy_name": "Kibia",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708013",
      "brgy_name": "Kibudoc",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708014",
      "brgy_name": "Kidama",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708015",
      "brgy_name": "Kilada",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708017",
      "brgy_name": "Lampayan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708018",
      "brgy_name": "Latagan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708019",
      "brgy_name": "Linao",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708020",
      "brgy_name": "Lower Malamote",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708025",
      "brgy_name": "Manubuan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708026",
      "brgy_name": "Manupal",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708027",
      "brgy_name": "Marbel",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708028",
      "brgy_name": "Minamaing",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708029",
      "brgy_name": "Natutungan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708030",
      "brgy_name": "New Bugasong",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708031",
      "brgy_name": "New Pandan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708033",
      "brgy_name": "Patadon West",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708034",
      "brgy_name": "Poblacion",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708035",
      "brgy_name": "Salvacion",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708036",
      "brgy_name": "Santa Maria",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708037",
      "brgy_name": "Sarayan",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708038",
      "brgy_name": "Taculen",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708039",
      "brgy_name": "Taguranao",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708040",
      "brgy_name": "Tamped (Tampad)",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708041",
      "brgy_name": "New Abra",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124708042",
      "brgy_name": "Pinamaton",
      "city_code": "124708",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709002",
      "brgy_name": "Agriculture",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709003",
      "brgy_name": "Anonang",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709004",
      "brgy_name": "Arizona",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709005",
      "brgy_name": "Bagumba",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709006",
      "brgy_name": "Baliki",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709007",
      "brgy_name": "Bitoka",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709008",
      "brgy_name": "Bual Norte",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709009",
      "brgy_name": "Bual Sur",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709010",
      "brgy_name": "Central Bulanan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709011",
      "brgy_name": "Damatulan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709012",
      "brgy_name": "Central Glad",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709013",
      "brgy_name": "Ilbocean",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709014",
      "brgy_name": "Kadigasan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709015",
      "brgy_name": "Kadingilan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709016",
      "brgy_name": "Kapinpilan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709017",
      "brgy_name": "Central Katingawan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709018",
      "brgy_name": "Kimagango",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709019",
      "brgy_name": "Kudarangan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709020",
      "brgy_name": "Central Labas",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709021",
      "brgy_name": "Lagumbingan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709022",
      "brgy_name": "Lomopog",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709023",
      "brgy_name": "Lower Glad",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709024",
      "brgy_name": "Lower Katingawan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709025",
      "brgy_name": "Macasendeg",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709026",
      "brgy_name": "Malamote",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709027",
      "brgy_name": "Malingao",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709028",
      "brgy_name": "Milaya",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709029",
      "brgy_name": "Mudseng",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709030",
      "brgy_name": "Nabalawag",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709031",
      "brgy_name": "Nalin",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709032",
      "brgy_name": "Nes",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709033",
      "brgy_name": "Olandang",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709034",
      "brgy_name": "Patindeguen",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709035",
      "brgy_name": "Barangay Poblacion 1",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709036",
      "brgy_name": "Barangay Poblacion 2",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709037",
      "brgy_name": "Barangay Poblacion 3",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709038",
      "brgy_name": "Barangay Poblacion 4",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709039",
      "brgy_name": "Barangay Poblacion 5",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709040",
      "brgy_name": "Barangay Poblacion 6",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709041",
      "brgy_name": "Barangay Poblacion 7",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709042",
      "brgy_name": "Barangay Poblacion 8",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709043",
      "brgy_name": "Palongoguen",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709044",
      "brgy_name": "Rangaban",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709045",
      "brgy_name": "Sadaan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709046",
      "brgy_name": "Salunayan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709047",
      "brgy_name": "Sambulawan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709048",
      "brgy_name": "San Isidro",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709049",
      "brgy_name": "Santa Cruz",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709050",
      "brgy_name": "Tugal",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709051",
      "brgy_name": "Tumbras",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709052",
      "brgy_name": "Bulanan Upper",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709053",
      "brgy_name": "Upper Glad I",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709054",
      "brgy_name": "Upper Glad II",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709055",
      "brgy_name": "Upper Labas",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709056",
      "brgy_name": "Villarica",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709057",
      "brgy_name": "Kiwanan",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124709058",
      "brgy_name": "San Pedro",
      "city_code": "124709",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710001",
      "brgy_name": "Bagontapay",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710002",
      "brgy_name": "Bialong",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710003",
      "brgy_name": "Buayan",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710004",
      "brgy_name": "Calunasan",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710005",
      "brgy_name": "Dalipe",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710006",
      "brgy_name": "Dagong",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710007",
      "brgy_name": "Dungo-an",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710008",
      "brgy_name": "Gaunan",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710009",
      "brgy_name": "Inas",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710010",
      "brgy_name": "Katipunan",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710011",
      "brgy_name": "La Fortuna",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710012",
      "brgy_name": "La Suerte",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710013",
      "brgy_name": "Langkong",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710014",
      "brgy_name": "Lepaga",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710015",
      "brgy_name": "Liboo",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710016",
      "brgy_name": "Lika",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710017",
      "brgy_name": "Luz Village",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710018",
      "brgy_name": "Magallon",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710019",
      "brgy_name": "Malayan",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710020",
      "brgy_name": "New Antique",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710021",
      "brgy_name": "New Barbaza",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710022",
      "brgy_name": "New Kalibo",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710024",
      "brgy_name": "New Consolacion",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710025",
      "brgy_name": "New Esperanza",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710026",
      "brgy_name": "New Janiuay",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710027",
      "brgy_name": "New Lawa-an",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710028",
      "brgy_name": "New Rizal",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710029",
      "brgy_name": "Nueva Vida",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710030",
      "brgy_name": "Pag-asa",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710031",
      "brgy_name": "Poblacion",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710032",
      "brgy_name": "Pulang-lupa",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710033",
      "brgy_name": "Sangat",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710034",
      "brgy_name": "Tawantawan",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710035",
      "brgy_name": "Tibao",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710036",
      "brgy_name": "Ugpay",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710037",
      "brgy_name": "Palma-Perez",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124710038",
      "brgy_name": "Poblacion B",
      "city_code": "124710",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711001",
      "brgy_name": "Anick (Upper Balogo)",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711002",
      "brgy_name": "Upper Baguer (Baguer)",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711003",
      "brgy_name": "Balacayon",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711004",
      "brgy_name": "Balogo",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711006",
      "brgy_name": "Banucagon",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711007",
      "brgy_name": "Bulucaon",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711008",
      "brgy_name": "Buluan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711009",
      "brgy_name": "Buricain",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711010",
      "brgy_name": "Capayuran",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711011",
      "brgy_name": "Central Panatan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711012",
      "brgy_name": "Datu Binasing",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711013",
      "brgy_name": "Datu Mantil",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711014",
      "brgy_name": "Kadingilan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711015",
      "brgy_name": "Kimarayang",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711016",
      "brgy_name": "Libungan Torreta",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711017",
      "brgy_name": "Lower Baguer",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711018",
      "brgy_name": "Lower Pangangkalan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711019",
      "brgy_name": "Malagakit",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711020",
      "brgy_name": "Maluao",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711021",
      "brgy_name": "North Manuangan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711022",
      "brgy_name": "Matilac",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711023",
      "brgy_name": "Midpapan I",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711024",
      "brgy_name": "Mulok",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711025",
      "brgy_name": "New Culasi",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711026",
      "brgy_name": "New Igbaras",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711027",
      "brgy_name": "New Panay",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711028",
      "brgy_name": "Upper Pangangkalan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711029",
      "brgy_name": "Patot",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711030",
      "brgy_name": "Payong-payong",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711031",
      "brgy_name": "Poblacion I",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711032",
      "brgy_name": "Poblacion II",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711033",
      "brgy_name": "Poblacion III",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711034",
      "brgy_name": "Presbitero",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711035",
      "brgy_name": "Renibon",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711036",
      "brgy_name": "Simsiman",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711037",
      "brgy_name": "South Manuangan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711038",
      "brgy_name": "Tigbawan",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711039",
      "brgy_name": "Tubon",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711040",
      "brgy_name": "Midpapan II",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124711041",
      "brgy_name": "Cabpangi",
      "city_code": "124711",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712001",
      "brgy_name": "Bagoaingud (Bagoinged)",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712003",
      "brgy_name": "Balabak",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712004",
      "brgy_name": "Balatican",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712005",
      "brgy_name": "Balong",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712006",
      "brgy_name": "Balungis",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712007",
      "brgy_name": "Barungis",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712008",
      "brgy_name": "Batulawan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712009",
      "brgy_name": "Bualan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712010",
      "brgy_name": "Buliok",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712011",
      "brgy_name": "Bulod",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712012",
      "brgy_name": "Bulol",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712013",
      "brgy_name": "Calawag",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712015",
      "brgy_name": "Dalingaoen (Lalingaon)",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712016",
      "brgy_name": "Damalasak",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712019",
      "brgy_name": "Fort Pikit",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712020",
      "brgy_name": "Ginatilan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712021",
      "brgy_name": "Gligli",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712022",
      "brgy_name": "Gokoton (Gokotan)",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712023",
      "brgy_name": "Inug-ug",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712024",
      "brgy_name": "Kabasalan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712025",
      "brgy_name": "Kalacacan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712026",
      "brgy_name": "Katilacan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712028",
      "brgy_name": "Kolambog",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712029",
      "brgy_name": "Ladtingan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712030",
      "brgy_name": "Lagunde",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712031",
      "brgy_name": "Langayen",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712034",
      "brgy_name": "Macabual",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712035",
      "brgy_name": "Macasendeg",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712037",
      "brgy_name": "Manaulanan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712040",
      "brgy_name": "Nabundas",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712041",
      "brgy_name": "Nalapaan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712044",
      "brgy_name": "Nunguan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712046",
      "brgy_name": "Paidu Pulangi",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712048",
      "brgy_name": "Panicupan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712050",
      "brgy_name": "Poblacion",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712051",
      "brgy_name": "Punol",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712052",
      "brgy_name": "Rajah Muda",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712055",
      "brgy_name": "Silik",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712056",
      "brgy_name": "Takipan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712057",
      "brgy_name": "Talitay",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712060",
      "brgy_name": "Tinutulan",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124712062",
      "brgy_name": "Pamalian",
      "city_code": "124712",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713001",
      "brgy_name": "Alegria",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713002",
      "brgy_name": "Bato-bato",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713003",
      "brgy_name": "Del Carmen",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713005",
      "brgy_name": "F. Cajelo (New Maasin)",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713006",
      "brgy_name": "Idaoman",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713007",
      "brgy_name": "Ilustre",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713008",
      "brgy_name": "Kamarahan",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713009",
      "brgy_name": "Camasi",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713011",
      "brgy_name": "Kisupaan",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713012",
      "brgy_name": "La Esperanza",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713013",
      "brgy_name": "Labu-o",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713014",
      "brgy_name": "Lamalama",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713016",
      "brgy_name": "Lomonay",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713022",
      "brgy_name": "New Cebu",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713024",
      "brgy_name": "Poblacion",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713026",
      "brgy_name": "Sagcungan",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713027",
      "brgy_name": "Salat",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713028",
      "brgy_name": "Sarayan",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713029",
      "brgy_name": "Tuael",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713030",
      "brgy_name": "Greenhill",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713031",
      "brgy_name": "Cabangbangan",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713032",
      "brgy_name": "Datu Indang",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713033",
      "brgy_name": "Datu Sandongan",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713034",
      "brgy_name": "Kimaruhing",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124713035",
      "brgy_name": "Mabuhay",
      "city_code": "124713",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714001",
      "brgy_name": "Bagumbayan",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714002",
      "brgy_name": "Banayal",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714003",
      "brgy_name": "Batang",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714004",
      "brgy_name": "Bituan",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714005",
      "brgy_name": "Bual",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714006",
      "brgy_name": "Daig",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714007",
      "brgy_name": "Damawato",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714008",
      "brgy_name": "Dungos",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714009",
      "brgy_name": "Kanibong",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714010",
      "brgy_name": "La Esperanza",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714011",
      "brgy_name": "Lampagang",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714012",
      "brgy_name": "Bunawan",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714013",
      "brgy_name": "Magbok",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714014",
      "brgy_name": "Maybula",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714015",
      "brgy_name": "Minapan",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714016",
      "brgy_name": "New Caridad",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714017",
      "brgy_name": "New Culasi",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714018",
      "brgy_name": "New Panay",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714019",
      "brgy_name": "Paraiso",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714020",
      "brgy_name": "Poblacion",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714021",
      "brgy_name": "Popoyon",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714022",
      "brgy_name": "Sibsib",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714023",
      "brgy_name": "Tambac",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714024",
      "brgy_name": "Tuburan",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714026",
      "brgy_name": "F. Cajelo",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714027",
      "brgy_name": "Bacong",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714028",
      "brgy_name": "Galidan",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714029",
      "brgy_name": "Genoveva Baynosa",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124714030",
      "brgy_name": "Nabundasan",
      "city_code": "124714",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715001",
      "brgy_name": "Camutan",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715002",
      "brgy_name": "Canaan",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715003",
      "brgy_name": "Dolores",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715004",
      "brgy_name": "Kiyaab",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715005",
      "brgy_name": "Luhong",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715006",
      "brgy_name": "Magsaysay",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715007",
      "brgy_name": "Malangag",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715008",
      "brgy_name": "Malatad",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715009",
      "brgy_name": "Malire",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715010",
      "brgy_name": "New Pontevedra",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715011",
      "brgy_name": "Poblacion",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715012",
      "brgy_name": "B. Cadungon",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124715013",
      "brgy_name": "Datu Agod",
      "city_code": "124715",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716001",
      "brgy_name": "Banisilan Poblacion",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716002",
      "brgy_name": "Busaon",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716003",
      "brgy_name": "Capayangan",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716004",
      "brgy_name": "Carugmanan",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716005",
      "brgy_name": "Gastay",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716006",
      "brgy_name": "Kalawaig",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716007",
      "brgy_name": "Kiaring",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716008",
      "brgy_name": "Malagap",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716009",
      "brgy_name": "Malinao",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716010",
      "brgy_name": "Miguel Macasarte",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716011",
      "brgy_name": "Pantar",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716012",
      "brgy_name": "Paradise",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716013",
      "brgy_name": "Pinamulaan",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716014",
      "brgy_name": "Poblacion II",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716015",
      "brgy_name": "Puting-bato",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716016",
      "brgy_name": "Solama",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716017",
      "brgy_name": "Thailand",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716018",
      "brgy_name": "Tinimbacan",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716019",
      "brgy_name": "Tumbao-Camalig",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124716020",
      "brgy_name": "Wadya",
      "city_code": "124716",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717001",
      "brgy_name": "Bagolibas",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717002",
      "brgy_name": "Cawilihan",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717003",
      "brgy_name": "Dualing",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717004",
      "brgy_name": "Dunguan",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717005",
      "brgy_name": "Katalicanan",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717006",
      "brgy_name": "Lawili",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717007",
      "brgy_name": "Lower Mingading",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717008",
      "brgy_name": "Luanan",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717009",
      "brgy_name": "Malapang",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717010",
      "brgy_name": "New Leon",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717011",
      "brgy_name": "New Panay",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717012",
      "brgy_name": "Pagangan",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717013",
      "brgy_name": "Palacat",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717014",
      "brgy_name": "Pentil",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717015",
      "brgy_name": "San Mateo",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717016",
      "brgy_name": "Santa Cruz",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717017",
      "brgy_name": "Tapodoc",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717018",
      "brgy_name": "Tomado",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124717019",
      "brgy_name": "Upper Mingading",
      "city_code": "124717",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718001",
      "brgy_name": "Allab",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718002",
      "brgy_name": "Anapolon",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718003",
      "brgy_name": "Badiangon",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718004",
      "brgy_name": "Binoongan",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718005",
      "brgy_name": "Dallag",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718006",
      "brgy_name": "Datu Ladayon",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718007",
      "brgy_name": "Datu Matangkil",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718008",
      "brgy_name": "Doroluman",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718009",
      "brgy_name": "Gambodes",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718010",
      "brgy_name": "Ganatan",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718011",
      "brgy_name": "Greenfield",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718012",
      "brgy_name": "Kabalantian",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718013",
      "brgy_name": "Katipunan",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718014",
      "brgy_name": "Kinawayan",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718015",
      "brgy_name": "Kulaman Valley",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718016",
      "brgy_name": "Lanao Kuran",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718017",
      "brgy_name": "Libertad",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718018",
      "brgy_name": "Makalangot",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718019",
      "brgy_name": "Malibatuan",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718020",
      "brgy_name": "Maria Caridad",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718021",
      "brgy_name": "Meocan",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718022",
      "brgy_name": "Naje",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718023",
      "brgy_name": "Napalico",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718024",
      "brgy_name": "Salasang",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718025",
      "brgy_name": "San Miguel",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718026",
      "brgy_name": "Santo Niño",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718027",
      "brgy_name": "Sumalili",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "124718028",
      "brgy_name": "Tumanding",
      "city_code": "124718",
      "province_code": "1247",
      "region_code": "12"
    },
    {
      "brgy_code": "126302002",
      "brgy_name": "Benitez (Pob.)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302003",
      "brgy_name": "Cabudian",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302004",
      "brgy_name": "Cabuling",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302005",
      "brgy_name": "Cinco (Barrio 5)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302006",
      "brgy_name": "Derilon",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302007",
      "brgy_name": "El Nonok",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302008",
      "brgy_name": "Improgo Village (Pob.)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302009",
      "brgy_name": "Kusan (Barrio 8)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302010",
      "brgy_name": "Lam-Apos",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302011",
      "brgy_name": "Lamba",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302012",
      "brgy_name": "Lambingi",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302013",
      "brgy_name": "Lampari",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302014",
      "brgy_name": "Liwanay (Barrio 1)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302015",
      "brgy_name": "Malaya (Barrio 9)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302016",
      "brgy_name": "Punong Grande (Barrio 2)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302017",
      "brgy_name": "Rang-ay (Barrio 4)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302018",
      "brgy_name": "Reyes (Pob.)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302019",
      "brgy_name": "Rizal (Barrio 3)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302020",
      "brgy_name": "Rizal Poblacion",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302021",
      "brgy_name": "San Jose (Barrio 7)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302022",
      "brgy_name": "San Vicente (Barrio 6)",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126302024",
      "brgy_name": "Yangco Poblacion",
      "city_code": "126302",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303003",
      "brgy_name": "Baluan",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303004",
      "brgy_name": "Buayan",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303005",
      "brgy_name": "Bula",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303006",
      "brgy_name": "Conel",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303007",
      "brgy_name": "Dadiangas East (Pob.)",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303009",
      "brgy_name": "Katangawan",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303011",
      "brgy_name": "Lagao (1st & 3rd)",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303012",
      "brgy_name": "Labangal",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303015",
      "brgy_name": "Ligaya",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303016",
      "brgy_name": "Mabuhay",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303023",
      "brgy_name": "San Isidro (Lagao 2nd)",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303024",
      "brgy_name": "San Jose",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303026",
      "brgy_name": "Sinawal",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303027",
      "brgy_name": "Tambler",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303028",
      "brgy_name": "Tinagacan",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303029",
      "brgy_name": "Apopong",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303030",
      "brgy_name": "Siguel",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303031",
      "brgy_name": "Upper Labay",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303032",
      "brgy_name": "Batomelong",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303033",
      "brgy_name": "Calumpang",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303034",
      "brgy_name": "City Heights",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303035",
      "brgy_name": "Dadiangas North",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303036",
      "brgy_name": "Dadiangas South",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303037",
      "brgy_name": "Dadiangas West",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303038",
      "brgy_name": "Fatima",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126303039",
      "brgy_name": "Olympog",
      "city_code": "126303",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306001",
      "brgy_name": "Assumption (Bulol)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306002",
      "brgy_name": "Avanceña (Bo. 3)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306003",
      "brgy_name": "Cacub",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306004",
      "brgy_name": "Caloocan",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306005",
      "brgy_name": "Carpenter Hill",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306006",
      "brgy_name": "Concepcion (Bo. 6)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306007",
      "brgy_name": "Esperanza",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306008",
      "brgy_name": "General Paulino Santos (Bo. 1)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306009",
      "brgy_name": "Mabini",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306010",
      "brgy_name": "Magsaysay",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306011",
      "brgy_name": "Mambucal",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306012",
      "brgy_name": "Morales",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306013",
      "brgy_name": "Namnama",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306014",
      "brgy_name": "New Pangasinan (Bo. 4)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306015",
      "brgy_name": "Paraiso",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306016",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306017",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306018",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306019",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306020",
      "brgy_name": "Rotonda",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306021",
      "brgy_name": "San Isidro",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306022",
      "brgy_name": "San Jose (Bo. 5)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306023",
      "brgy_name": "San Roque",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306024",
      "brgy_name": "Santa Cruz",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306025",
      "brgy_name": "Santo Niño (Bo. 2)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306026",
      "brgy_name": "Sarabia (Bo. 8)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126306027",
      "brgy_name": "Zulueta (Bo. 7)",
      "city_code": "126306",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311002",
      "brgy_name": "Dumaguil",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311003",
      "brgy_name": "Esperanza",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311006",
      "brgy_name": "Kibid",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311007",
      "brgy_name": "Lapuz",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311008",
      "brgy_name": "Liberty",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311009",
      "brgy_name": "Lopez Jaena",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311011",
      "brgy_name": "Matapol",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311013",
      "brgy_name": "Poblacion",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311014",
      "brgy_name": "Puti",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311016",
      "brgy_name": "San Jose",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311017",
      "brgy_name": "San Miguel",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311020",
      "brgy_name": "Simsiman",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311021",
      "brgy_name": "Tinago",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126311022",
      "brgy_name": "Benigno Aquino, Jr.",
      "city_code": "126311",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312001",
      "brgy_name": "Bentung",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312002",
      "brgy_name": "Crossing Palkan",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312003",
      "brgy_name": "Glamang",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312004",
      "brgy_name": "Kinilis",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312005",
      "brgy_name": "Klinan 6",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312006",
      "brgy_name": "Koronadal Proper",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312007",
      "brgy_name": "Lam-Caliaf",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312008",
      "brgy_name": "Landan",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312009",
      "brgy_name": "Lumakil",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312011",
      "brgy_name": "Maligo",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312013",
      "brgy_name": "Palkan",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312014",
      "brgy_name": "Poblacion",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312015",
      "brgy_name": "Polo",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312016",
      "brgy_name": "Magsaysay",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312017",
      "brgy_name": "Rubber",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312018",
      "brgy_name": "Silway 7",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312019",
      "brgy_name": "Silway 8",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312020",
      "brgy_name": "Sulit",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312021",
      "brgy_name": "Sumbakil",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312022",
      "brgy_name": "Upper Klinan",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312023",
      "brgy_name": "Lapu",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312024",
      "brgy_name": "Cannery Site",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126312025",
      "brgy_name": "Pagalungan",
      "city_code": "126312",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313002",
      "brgy_name": "Buenavista",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313003",
      "brgy_name": "Centrala",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313004",
      "brgy_name": "Colongulo",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313005",
      "brgy_name": "Dajay",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313006",
      "brgy_name": "Duengas",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313008",
      "brgy_name": "Canahay (Godwino)",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313014",
      "brgy_name": "Lambontong",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313017",
      "brgy_name": "Lamian",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313018",
      "brgy_name": "Lamsugod",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313019",
      "brgy_name": "Libertad (Pob.)",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313020",
      "brgy_name": "Little Baguio",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313023",
      "brgy_name": "Moloy",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313024",
      "brgy_name": "Naci (Doce)",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313028",
      "brgy_name": "Talahik",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313030",
      "brgy_name": "Tubiala",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313031",
      "brgy_name": "Upper Sepaka",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126313032",
      "brgy_name": "Veterans",
      "city_code": "126313",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314001",
      "brgy_name": "Albagan",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314002",
      "brgy_name": "Kipalbig",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314003",
      "brgy_name": "Lambayong",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314004",
      "brgy_name": "Liberty",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314005",
      "brgy_name": "Maltana",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314006",
      "brgy_name": "Poblacion",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314007",
      "brgy_name": "Tablu",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314008",
      "brgy_name": "Buto",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314009",
      "brgy_name": "Lampitak",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314010",
      "brgy_name": "Palo",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314011",
      "brgy_name": "Pula-bato",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314012",
      "brgy_name": "Danlag",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314013",
      "brgy_name": "San Isidro",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126314014",
      "brgy_name": "Santa Cruz",
      "city_code": "126314",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315001",
      "brgy_name": "Bukay Pait",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315002",
      "brgy_name": "Cabuling",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315003",
      "brgy_name": "Dumadalig",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315004",
      "brgy_name": "Libas",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315005",
      "brgy_name": "Magon",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315006",
      "brgy_name": "Maibo",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315007",
      "brgy_name": "Mangilala",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315008",
      "brgy_name": "New Iloilo",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315009",
      "brgy_name": "New Lambunao",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315010",
      "brgy_name": "Poblacion",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315011",
      "brgy_name": "San Felipe",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315012",
      "brgy_name": "New Cuyapo",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126315013",
      "brgy_name": "Tinongcop",
      "city_code": "126315",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316001",
      "brgy_name": "Basag",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316002",
      "brgy_name": "Edwards (Pob.)",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316004",
      "brgy_name": "Kematu",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316005",
      "brgy_name": "Laconon",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316008",
      "brgy_name": "Lamsalome",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316011",
      "brgy_name": "New Dumangas",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316012",
      "brgy_name": "Sinolon",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316014",
      "brgy_name": "Lambangan",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316016",
      "brgy_name": "Maan",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316017",
      "brgy_name": "Afus",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316018",
      "brgy_name": "Lambuling",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316019",
      "brgy_name": "Lamhako",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316020",
      "brgy_name": "Poblacion",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316021",
      "brgy_name": "Talcon",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316022",
      "brgy_name": "Talufo",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316023",
      "brgy_name": "Tudok",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316024",
      "brgy_name": "Aflek",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316025",
      "brgy_name": "Datal Bob",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316026",
      "brgy_name": "Desawo",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316027",
      "brgy_name": "Dlanag",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316028",
      "brgy_name": "Lemsnolon",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316029",
      "brgy_name": "Malugong",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316030",
      "brgy_name": "Mongocayo",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316031",
      "brgy_name": "Salacafe",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126316032",
      "brgy_name": "T'bolok",
      "city_code": "126316",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317001",
      "brgy_name": "Acmonan",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317002",
      "brgy_name": "Bololmala",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317003",
      "brgy_name": "Bunao",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317004",
      "brgy_name": "Cebuano",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317005",
      "brgy_name": "Crossing Rubber",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317006",
      "brgy_name": "Kablon",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317007",
      "brgy_name": "Kalkam",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317009",
      "brgy_name": "Linan",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317010",
      "brgy_name": "Lunen",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317011",
      "brgy_name": "Miasong",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317012",
      "brgy_name": "Palian",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317013",
      "brgy_name": "Poblacion",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317014",
      "brgy_name": "Polonuling",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317015",
      "brgy_name": "Simbo",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126317016",
      "brgy_name": "Tubeng",
      "city_code": "126317",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318001",
      "brgy_name": "Ambalgan",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318002",
      "brgy_name": "Guinsang-an",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318003",
      "brgy_name": "Katipunan",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318004",
      "brgy_name": "Manuel Roxas",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318005",
      "brgy_name": "Panay",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318006",
      "brgy_name": "Poblacion",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318007",
      "brgy_name": "San Isidro",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318008",
      "brgy_name": "San Vicente",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318009",
      "brgy_name": "Teresita",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126318010",
      "brgy_name": "Sajaneba",
      "city_code": "126318",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319001",
      "brgy_name": "Bacdulong",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319002",
      "brgy_name": "Denlag",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319003",
      "brgy_name": "Halilan",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319004",
      "brgy_name": "Hanoon",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319005",
      "brgy_name": "Klubi",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319006",
      "brgy_name": "Lake Lahit",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319007",
      "brgy_name": "Lamcade",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319008",
      "brgy_name": "Lamdalag",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319009",
      "brgy_name": "Lamfugon",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319010",
      "brgy_name": "Lamlahak",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319011",
      "brgy_name": "Lower Maculan",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319012",
      "brgy_name": "Luhib",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319013",
      "brgy_name": "Ned",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319014",
      "brgy_name": "Poblacion",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319015",
      "brgy_name": "Siluton",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319016",
      "brgy_name": "Talisay",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319017",
      "brgy_name": "Takunel",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319018",
      "brgy_name": "Upper Maculan",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126319019",
      "brgy_name": "Tasiman",
      "city_code": "126319",
      "province_code": "1263",
      "region_code": "12"
    },
    {
      "brgy_code": "126501001",
      "brgy_name": "Bai Sarifinang",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501002",
      "brgy_name": "Biwang",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501003",
      "brgy_name": "Busok",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501004",
      "brgy_name": "Daguma",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501005",
      "brgy_name": "Kapaya",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501006",
      "brgy_name": "Kinayao",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501008",
      "brgy_name": "Masiag",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501009",
      "brgy_name": "Poblacion",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501010",
      "brgy_name": "South Sepaka",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501011",
      "brgy_name": "Tuka",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501012",
      "brgy_name": "Chua",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501013",
      "brgy_name": "Daluga",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501014",
      "brgy_name": "Kabulanan",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501015",
      "brgy_name": "Kanulay",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501016",
      "brgy_name": "Monteverde",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501017",
      "brgy_name": "Santo Niño",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501018",
      "brgy_name": "Sumilil",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501019",
      "brgy_name": "Titulok",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126501020",
      "brgy_name": "Sison",
      "city_code": "126501",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502002",
      "brgy_name": "Bantangan (Lasak)",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502009",
      "brgy_name": "Datablao",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502010",
      "brgy_name": "Eday",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502011",
      "brgy_name": "Elbebe",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502015",
      "brgy_name": "Libertad",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502016",
      "brgy_name": "Lomoyon",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502017",
      "brgy_name": "Makat (Sumali Pas)",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502019",
      "brgy_name": "Maligaya",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502021",
      "brgy_name": "Mayo",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502022",
      "brgy_name": "Natividad",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502023",
      "brgy_name": "Poblacion",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502024",
      "brgy_name": "Polomolok",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502027",
      "brgy_name": "Sinapulan",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502028",
      "brgy_name": "Sucob",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502030",
      "brgy_name": "Telafas",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126502032",
      "brgy_name": "Lasak",
      "city_code": "126502",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503001",
      "brgy_name": "Ala",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503003",
      "brgy_name": "Daladap",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503005",
      "brgy_name": "Dukay",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503007",
      "brgy_name": "Guiamalia",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503008",
      "brgy_name": "Ilian",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503011",
      "brgy_name": "Kangkong",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503016",
      "brgy_name": "Margues",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503018",
      "brgy_name": "New Panay",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503020",
      "brgy_name": "Numo",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503021",
      "brgy_name": "Pamantingan",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503022",
      "brgy_name": "Poblacion",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503023",
      "brgy_name": "Sagasa",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503024",
      "brgy_name": "Salabaca",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503032",
      "brgy_name": "Villamor",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503033",
      "brgy_name": "Laguinding",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503034",
      "brgy_name": "Magsaysay",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503035",
      "brgy_name": "Paitan",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503036",
      "brgy_name": "Saliao",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126503037",
      "brgy_name": "Salumping",
      "city_code": "126503",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504003",
      "brgy_name": "Bambad",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504005",
      "brgy_name": "Bual",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504007",
      "brgy_name": "D'Lotilla",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504009",
      "brgy_name": "Dansuli",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504010",
      "brgy_name": "Impao",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504011",
      "brgy_name": "Kalawag I (Pob.)",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504012",
      "brgy_name": "Kalawag II (Pob.)",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504013",
      "brgy_name": "Kalawag III (Pob.)",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504016",
      "brgy_name": "Kenram",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504018",
      "brgy_name": "Kudanding",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504019",
      "brgy_name": "Kolambog",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504020",
      "brgy_name": "Lagandang",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504021",
      "brgy_name": "Laguilayan",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504022",
      "brgy_name": "Mapantig",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504024",
      "brgy_name": "New Pangasinan",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504026",
      "brgy_name": "Sampao",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126504028",
      "brgy_name": "Tayugo",
      "city_code": "126504",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505001",
      "brgy_name": "Bantogon (Santa Clara)",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505004",
      "brgy_name": "Cadiz",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505006",
      "brgy_name": "Dumangas Nuevo",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505007",
      "brgy_name": "Hinalaan",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505011",
      "brgy_name": "Limulan",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505013",
      "brgy_name": "Obial",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505014",
      "brgy_name": "Paril",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505015",
      "brgy_name": "Poblacion",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505016",
      "brgy_name": "Sangay",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505017",
      "brgy_name": "Santa Maria",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505018",
      "brgy_name": "Datu Ito Andong",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505019",
      "brgy_name": "Datu Wasay",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505020",
      "brgy_name": "Nalilidan",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505021",
      "brgy_name": "Sabanal",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126505022",
      "brgy_name": "Pag-asa",
      "city_code": "126505",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506001",
      "brgy_name": "Barurao",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506003",
      "brgy_name": "Basak",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506004",
      "brgy_name": "Bululawan",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506005",
      "brgy_name": "Capilan",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506006",
      "brgy_name": "Christiannuevo",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506007",
      "brgy_name": "Datu Karon",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506008",
      "brgy_name": "Kalamongog",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506009",
      "brgy_name": "Keytodac",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506010",
      "brgy_name": "Kinodalan",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506012",
      "brgy_name": "New Calinog",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506013",
      "brgy_name": "Nuling",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506014",
      "brgy_name": "Pansud",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506015",
      "brgy_name": "Pasandalan",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506016",
      "brgy_name": "Poblacion",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506017",
      "brgy_name": "Poloy-poloy",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506018",
      "brgy_name": "Purikay",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506019",
      "brgy_name": "Ragandang",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506020",
      "brgy_name": "Salaman",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506021",
      "brgy_name": "Salangsang",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506022",
      "brgy_name": "Taguisa",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506023",
      "brgy_name": "Tibpuan",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506024",
      "brgy_name": "Tran",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506025",
      "brgy_name": "Villamonte",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506026",
      "brgy_name": "Barurao II",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506027",
      "brgy_name": "Aurelio F. Freires (Poblacion II)",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506028",
      "brgy_name": "Poblacion III",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126506029",
      "brgy_name": "Bolebok",
      "city_code": "126506",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507001",
      "brgy_name": "Antong",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507002",
      "brgy_name": "Bayasong",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507003",
      "brgy_name": "Blingkong",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507004",
      "brgy_name": "Lutayan Proper",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507005",
      "brgy_name": "Maindang",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507006",
      "brgy_name": "Mamali",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507007",
      "brgy_name": "Manili",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507008",
      "brgy_name": "Sampao",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507009",
      "brgy_name": "Sisiman",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507010",
      "brgy_name": "Tamnag (Pob.)",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126507011",
      "brgy_name": "Palavilla",
      "city_code": "126507",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508001",
      "brgy_name": "Caridad (Cuyapon)",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508002",
      "brgy_name": "Didtaras",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508004",
      "brgy_name": "Gansing (Bilumen)",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508005",
      "brgy_name": "Kabulakan",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508006",
      "brgy_name": "Kapingkong",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508007",
      "brgy_name": "Katitisan",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508008",
      "brgy_name": "Lagao",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508009",
      "brgy_name": "Lilit",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508010",
      "brgy_name": "Madanding",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508011",
      "brgy_name": "Maligaya",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508012",
      "brgy_name": "Mamali",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508013",
      "brgy_name": "Matiompong",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508014",
      "brgy_name": "Midtapok",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508015",
      "brgy_name": "New Cebu",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508016",
      "brgy_name": "Palumbi",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508017",
      "brgy_name": "Pidtiguian",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508018",
      "brgy_name": "Pimbalayan",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508019",
      "brgy_name": "Pinguiaman",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508020",
      "brgy_name": "Poblacion (Lambayong)",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508021",
      "brgy_name": "Sadsalan",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508022",
      "brgy_name": "Seneben",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508023",
      "brgy_name": "Sigayan",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508024",
      "brgy_name": "Tambak",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508025",
      "brgy_name": "Tinumigues",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508027",
      "brgy_name": "Tumiao (Tinaga)",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126508028",
      "brgy_name": "Udtong",
      "city_code": "126508",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509001",
      "brgy_name": "Akol",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509002",
      "brgy_name": "Badiangon",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509003",
      "brgy_name": "Baliango",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509004",
      "brgy_name": "Baranayan",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509005",
      "brgy_name": "Barongis",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509006",
      "brgy_name": "Batang-baglas",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509007",
      "brgy_name": "Butril",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509009",
      "brgy_name": "Domolol",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509010",
      "brgy_name": "Kabuling",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509011",
      "brgy_name": "Kalibuhan",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509013",
      "brgy_name": "Kanipaan",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509014",
      "brgy_name": "Kisek",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509015",
      "brgy_name": "Kidayan",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509016",
      "brgy_name": "Kiponget",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509017",
      "brgy_name": "Kulong-kulong",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509018",
      "brgy_name": "Kraan",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509020",
      "brgy_name": "Langali",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509021",
      "brgy_name": "Libua",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509022",
      "brgy_name": "Lumitan",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509023",
      "brgy_name": "Maganao",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509024",
      "brgy_name": "Maguid",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509025",
      "brgy_name": "Malatuneng (Malatunol)",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509026",
      "brgy_name": "Malisbong",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509027",
      "brgy_name": "Milbuk",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509028",
      "brgy_name": "Molon",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509029",
      "brgy_name": "Namat Masla",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509030",
      "brgy_name": "Napnapon",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509031",
      "brgy_name": "Poblacion",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509032",
      "brgy_name": "San Roque",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509033",
      "brgy_name": "Colobe (Tagadtal)",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509034",
      "brgy_name": "Tibuhol (East Badiangon)",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509035",
      "brgy_name": "Wal",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509036",
      "brgy_name": "Bambanen",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509038",
      "brgy_name": "Lopoken (Lepolon)",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509039",
      "brgy_name": "Mina",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509040",
      "brgy_name": "Medol",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509041",
      "brgy_name": "Wasag",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509042",
      "brgy_name": "Balwan (Bulan)",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509043",
      "brgy_name": "Ligao",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126509044",
      "brgy_name": "Datu Maguiales",
      "city_code": "126509",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510001",
      "brgy_name": "Bagumbayan",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510003",
      "brgy_name": "Bannawag",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510004",
      "brgy_name": "Bayawa",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510005",
      "brgy_name": "Estrella",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510007",
      "brgy_name": "Kalanawe I",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510008",
      "brgy_name": "Kalanawe II",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510009",
      "brgy_name": "Katico",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510012",
      "brgy_name": "Malingon",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510013",
      "brgy_name": "Mangalen",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510014",
      "brgy_name": "C. Mangilala",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510015",
      "brgy_name": "Pedtubo",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510016",
      "brgy_name": "Poblacion (Sambulawan)",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510018",
      "brgy_name": "Romualdez",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510020",
      "brgy_name": "San Jose",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510021",
      "brgy_name": "Sinakulay",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510022",
      "brgy_name": "Suben",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510023",
      "brgy_name": "Tinaungan",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510025",
      "brgy_name": "Tual (Liguasan)",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126510026",
      "brgy_name": "San Pedro (Tuato)",
      "city_code": "126510",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511001",
      "brgy_name": "Baras",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511002",
      "brgy_name": "Buenaflor",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511003",
      "brgy_name": "Calean",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511004",
      "brgy_name": "Carmen",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511005",
      "brgy_name": "D'Ledesma",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511006",
      "brgy_name": "Gansing",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511007",
      "brgy_name": "Kalandagan",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511008",
      "brgy_name": "Lower Katungal",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511010",
      "brgy_name": "New Isabela",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511011",
      "brgy_name": "New Lagao",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511012",
      "brgy_name": "New Passi",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511013",
      "brgy_name": "Poblacion",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511014",
      "brgy_name": "Rajah Nuda",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511015",
      "brgy_name": "San Antonio",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511016",
      "brgy_name": "San Emmanuel",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511019",
      "brgy_name": "San Pablo",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511020",
      "brgy_name": "Upper Katungal",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511021",
      "brgy_name": "Tina",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511022",
      "brgy_name": "San Rafael",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126511023",
      "brgy_name": "Lancheta",
      "city_code": "126511",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512001",
      "brgy_name": "Banali",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512002",
      "brgy_name": "Basag",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512003",
      "brgy_name": "Buenaflores",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512004",
      "brgy_name": "Bugso",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512005",
      "brgy_name": "Buklod",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512006",
      "brgy_name": "Gapok",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512007",
      "brgy_name": "Kadi",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512008",
      "brgy_name": "Kapatagan",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512009",
      "brgy_name": "Kiadsam",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512010",
      "brgy_name": "Kuden",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512011",
      "brgy_name": "Kulaman",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512012",
      "brgy_name": "Lagubang",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512013",
      "brgy_name": "Langgal",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512014",
      "brgy_name": "Limuhay",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512015",
      "brgy_name": "Malegdeg",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512016",
      "brgy_name": "Midtungok",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512017",
      "brgy_name": "Nati",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512018",
      "brgy_name": "Sewod",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512019",
      "brgy_name": "Tacupis",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "126512020",
      "brgy_name": "Tinalon",
      "city_code": "126512",
      "province_code": "1265",
      "region_code": "12"
    },
    {
      "brgy_code": "128001001",
      "brgy_name": "Alegria",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001002",
      "brgy_name": "Bagacay",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001003",
      "brgy_name": "Baluntay",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001004",
      "brgy_name": "Datal Anggas",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001005",
      "brgy_name": "Domolok",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001006",
      "brgy_name": "Kawas",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001007",
      "brgy_name": "Maribulan",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001008",
      "brgy_name": "Pag-Asa",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001009",
      "brgy_name": "Paraiso",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001010",
      "brgy_name": "Poblacion (Alabel)",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001011",
      "brgy_name": "Spring",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001012",
      "brgy_name": "Tokawal",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128001013",
      "brgy_name": "Ladol",
      "city_code": "128001",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002001",
      "brgy_name": "Baliton",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002002",
      "brgy_name": "Batotuling",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002003",
      "brgy_name": "Batulaki",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002004",
      "brgy_name": "Big Margus",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002005",
      "brgy_name": "Burias",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002006",
      "brgy_name": "Cablalan",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002007",
      "brgy_name": "Calabanit",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002008",
      "brgy_name": "Calpidong",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002009",
      "brgy_name": "Congan",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002010",
      "brgy_name": "Cross",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002011",
      "brgy_name": "Datalbukay",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002012",
      "brgy_name": "E. Alegado",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002013",
      "brgy_name": "Glan Padidu",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002014",
      "brgy_name": "Gumasa",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002015",
      "brgy_name": "Ilaya",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002016",
      "brgy_name": "Kaltuad",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002017",
      "brgy_name": "Kapatan",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002018",
      "brgy_name": "Lago",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002019",
      "brgy_name": "Laguimit",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002020",
      "brgy_name": "Mudan",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002021",
      "brgy_name": "New Aklan",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002022",
      "brgy_name": "Pangyan",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002023",
      "brgy_name": "Poblacion",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002024",
      "brgy_name": "Rio Del Pilar",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002025",
      "brgy_name": "San Jose",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002026",
      "brgy_name": "San Vicente",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002027",
      "brgy_name": "Small Margus",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002028",
      "brgy_name": "Sufatubo",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002029",
      "brgy_name": "Taluya",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002030",
      "brgy_name": "Tango",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128002031",
      "brgy_name": "Tapon",
      "city_code": "128002",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003001",
      "brgy_name": "Badtasan",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003002",
      "brgy_name": "Datu Dani",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003003",
      "brgy_name": "Gasi",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003004",
      "brgy_name": "Kapate",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003005",
      "brgy_name": "Katubao",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003006",
      "brgy_name": "Kayupo",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003007",
      "brgy_name": "Kling (Lumit)",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003008",
      "brgy_name": "Lagundi",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003009",
      "brgy_name": "Lebe",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003010",
      "brgy_name": "Lomuyon",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003011",
      "brgy_name": "Luma",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003012",
      "brgy_name": "Maligang",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003013",
      "brgy_name": "Nalus",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003014",
      "brgy_name": "Poblacion",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003015",
      "brgy_name": "Salakit",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003016",
      "brgy_name": "Suli",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003017",
      "brgy_name": "Tablao",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003018",
      "brgy_name": "Tamadang",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128003019",
      "brgy_name": "Tambilil",
      "city_code": "128003",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004001",
      "brgy_name": "Amsipit",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004002",
      "brgy_name": "Bales",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004003",
      "brgy_name": "Colon",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004004",
      "brgy_name": "Daliao",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004005",
      "brgy_name": "Kabatiol",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004006",
      "brgy_name": "Kablacan",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004007",
      "brgy_name": "Kamanga",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004008",
      "brgy_name": "Kanalo",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004009",
      "brgy_name": "Lumasal",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004010",
      "brgy_name": "Lumatil",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004011",
      "brgy_name": "Malbang",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004012",
      "brgy_name": "Nomoh",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004013",
      "brgy_name": "Pananag",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004014",
      "brgy_name": "Poblacion (Maasim)",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004015",
      "brgy_name": "Seven Hills",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128004016",
      "brgy_name": "Tinoto",
      "city_code": "128004",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005001",
      "brgy_name": "Bati-an",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005002",
      "brgy_name": "Kalaneg",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005003",
      "brgy_name": "Kalaong",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005004",
      "brgy_name": "Kiambing",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005005",
      "brgy_name": "Kiayap",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005006",
      "brgy_name": "Mabay",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005007",
      "brgy_name": "Maguling",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005008",
      "brgy_name": "Malalag (Pob.)",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005009",
      "brgy_name": "Mindupok",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005010",
      "brgy_name": "New La Union",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005011",
      "brgy_name": "Old Poblacion (Maitum)",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005012",
      "brgy_name": "Pangi (Linao)",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005013",
      "brgy_name": "Pinol",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005014",
      "brgy_name": "Sison (Edenton)",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005015",
      "brgy_name": "Ticulab",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005016",
      "brgy_name": "Tuanadatu",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005017",
      "brgy_name": "Upo (Lanao)",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005018",
      "brgy_name": "Wali (Kambuhan)",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128005019",
      "brgy_name": "Zion",
      "city_code": "128005",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006001",
      "brgy_name": "Daan Suyan",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006002",
      "brgy_name": "Kihan",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006003",
      "brgy_name": "Kinam",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006004",
      "brgy_name": "Libi",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006005",
      "brgy_name": "Lun Masla",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006006",
      "brgy_name": "Lun Padidu",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006007",
      "brgy_name": "Patag",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006008",
      "brgy_name": "Poblacion (Malapatan)",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006009",
      "brgy_name": "Sapu Masla",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006010",
      "brgy_name": "Sapu Padidu",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006011",
      "brgy_name": "Tuyan",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128006012",
      "brgy_name": "Upper Suyan",
      "city_code": "128006",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007001",
      "brgy_name": "Alkikan",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007002",
      "brgy_name": "Ampon",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007003",
      "brgy_name": "Atlae",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007004",
      "brgy_name": "Banahaw",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007005",
      "brgy_name": "Banate",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007006",
      "brgy_name": "B'Laan",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007007",
      "brgy_name": "Datal Batong",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007008",
      "brgy_name": "Datal Bila",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007009",
      "brgy_name": "Datal Tampal",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007010",
      "brgy_name": "J.P. Laurel",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007011",
      "brgy_name": "Kawayan",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007012",
      "brgy_name": "Kibala",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007013",
      "brgy_name": "Kiblat",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007014",
      "brgy_name": "Kinabalan",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007015",
      "brgy_name": "Lower Mainit",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007016",
      "brgy_name": "Lutay",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007017",
      "brgy_name": "Malabod",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007018",
      "brgy_name": "Malalag Cogon",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007019",
      "brgy_name": "Malandag",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007020",
      "brgy_name": "Malungon Gamay",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007021",
      "brgy_name": "Nagpan",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007022",
      "brgy_name": "Panamin",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007023",
      "brgy_name": "Poblacion",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007024",
      "brgy_name": "San Juan",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007025",
      "brgy_name": "San Miguel",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007026",
      "brgy_name": "San Roque",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007027",
      "brgy_name": "Talus",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007028",
      "brgy_name": "Tamban",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007029",
      "brgy_name": "Upper Biangan",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007030",
      "brgy_name": "Upper Lumabat",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "128007031",
      "brgy_name": "Upper Mainit",
      "city_code": "128007",
      "province_code": "1280",
      "region_code": "12"
    },
    {
      "brgy_code": "129804001",
      "brgy_name": "Bagua",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804002",
      "brgy_name": "Kalanganan",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804003",
      "brgy_name": "Poblacion",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804004",
      "brgy_name": "Rosary Heights",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804005",
      "brgy_name": "Tamontaka",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804006",
      "brgy_name": "Bagua I",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804007",
      "brgy_name": "Bagua II",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804008",
      "brgy_name": "Bagua III",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804009",
      "brgy_name": "Kalanganan I",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804010",
      "brgy_name": "Kalanganan II",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804011",
      "brgy_name": "Poblacion I",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804012",
      "brgy_name": "Poblacion II",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804013",
      "brgy_name": "Poblacion III",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804014",
      "brgy_name": "Poblacion IV",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804015",
      "brgy_name": "Poblacion V",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804016",
      "brgy_name": "Poblacion VI",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804017",
      "brgy_name": "Poblacion VII",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804018",
      "brgy_name": "Poblacion VIII",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804019",
      "brgy_name": "Poblacion IX",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804020",
      "brgy_name": "Rosary Heights I",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804021",
      "brgy_name": "Rosary Heights II",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804022",
      "brgy_name": "Rosary Heights III",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804023",
      "brgy_name": "Rosary Heights IV",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804024",
      "brgy_name": "Rosary Heights V",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804025",
      "brgy_name": "Rosary Heights VI",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804026",
      "brgy_name": "Rosary Heights VII",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804027",
      "brgy_name": "Rosary Heights VIII",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804028",
      "brgy_name": "Rosary Heights IX",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804029",
      "brgy_name": "Rosary Heights X",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804030",
      "brgy_name": "Rosary Heights XI",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804031",
      "brgy_name": "Rosary Heights XII",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804032",
      "brgy_name": "Rosary Heights XIII",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804033",
      "brgy_name": "Tamontaka I",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804034",
      "brgy_name": "Tamontaka II",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804035",
      "brgy_name": "Tamontaka III",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804036",
      "brgy_name": "Tamontaka IV",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "129804037",
      "brgy_name": "Tamontaka V",
      "city_code": "129804",
      "province_code": "1298",
      "region_code": "12"
    },
    {
      "brgy_code": "133901001",
      "brgy_name": "Barangay 1",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901002",
      "brgy_name": "Barangay 2",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901003",
      "brgy_name": "Barangay 3",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901004",
      "brgy_name": "Barangay 4",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901005",
      "brgy_name": "Barangay 5",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901006",
      "brgy_name": "Barangay 6",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901007",
      "brgy_name": "Barangay 7",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901008",
      "brgy_name": "Barangay 8",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901009",
      "brgy_name": "Barangay 9",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901010",
      "brgy_name": "Barangay 10",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901011",
      "brgy_name": "Barangay 11",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901012",
      "brgy_name": "Barangay 12",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901013",
      "brgy_name": "Barangay 13",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901014",
      "brgy_name": "Barangay 14",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901015",
      "brgy_name": "Barangay 15",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901016",
      "brgy_name": "Barangay 16",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901017",
      "brgy_name": "Barangay 17",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901018",
      "brgy_name": "Barangay 18",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901019",
      "brgy_name": "Barangay 19",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901020",
      "brgy_name": "Barangay 20",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901025",
      "brgy_name": "Barangay 25",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901026",
      "brgy_name": "Barangay 26",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901028",
      "brgy_name": "Barangay 28",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901029",
      "brgy_name": "Barangay 29",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901030",
      "brgy_name": "Barangay 30",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901031",
      "brgy_name": "Barangay 31",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901032",
      "brgy_name": "Barangay 32",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901033",
      "brgy_name": "Barangay 33",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901034",
      "brgy_name": "Barangay 34",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901035",
      "brgy_name": "Barangay 35",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901036",
      "brgy_name": "Barangay 36",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901037",
      "brgy_name": "Barangay 37",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901038",
      "brgy_name": "Barangay 38",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901039",
      "brgy_name": "Barangay 39",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901041",
      "brgy_name": "Barangay 41",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901042",
      "brgy_name": "Barangay 42",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901043",
      "brgy_name": "Barangay 43",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901044",
      "brgy_name": "Barangay 44",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901045",
      "brgy_name": "Barangay 45",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901046",
      "brgy_name": "Barangay 46",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901047",
      "brgy_name": "Barangay 47",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901048",
      "brgy_name": "Barangay 48",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901049",
      "brgy_name": "Barangay 49",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901050",
      "brgy_name": "Barangay 50",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901051",
      "brgy_name": "Barangay 51",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901052",
      "brgy_name": "Barangay 52",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901053",
      "brgy_name": "Barangay 53",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901054",
      "brgy_name": "Barangay 54",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901055",
      "brgy_name": "Barangay 55",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901056",
      "brgy_name": "Barangay 56",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901057",
      "brgy_name": "Barangay 57",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901058",
      "brgy_name": "Barangay 58",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901059",
      "brgy_name": "Barangay 59",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901060",
      "brgy_name": "Barangay 60",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901061",
      "brgy_name": "Barangay 61",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901062",
      "brgy_name": "Barangay 62",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901063",
      "brgy_name": "Barangay 63",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901064",
      "brgy_name": "Barangay 64",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901065",
      "brgy_name": "Barangay 65",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901066",
      "brgy_name": "Barangay 66",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901067",
      "brgy_name": "Barangay 67",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901068",
      "brgy_name": "Barangay 68",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901069",
      "brgy_name": "Barangay 69",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901070",
      "brgy_name": "Barangay 70",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901071",
      "brgy_name": "Barangay 71",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901072",
      "brgy_name": "Barangay 72",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901073",
      "brgy_name": "Barangay 73",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901074",
      "brgy_name": "Barangay 74",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901075",
      "brgy_name": "Barangay 75",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901076",
      "brgy_name": "Barangay 76",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901077",
      "brgy_name": "Barangay 77",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901078",
      "brgy_name": "Barangay 78",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901079",
      "brgy_name": "Barangay 79",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901080",
      "brgy_name": "Barangay 80",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901081",
      "brgy_name": "Barangay 81",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901082",
      "brgy_name": "Barangay 82",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901083",
      "brgy_name": "Barangay 83",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901084",
      "brgy_name": "Barangay 84",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901085",
      "brgy_name": "Barangay 85",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901086",
      "brgy_name": "Barangay 86",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901087",
      "brgy_name": "Barangay 87",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901088",
      "brgy_name": "Barangay 88",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901089",
      "brgy_name": "Barangay 89",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901090",
      "brgy_name": "Barangay 90",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901091",
      "brgy_name": "Barangay 91",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901092",
      "brgy_name": "Barangay 92",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901093",
      "brgy_name": "Barangay 93",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901094",
      "brgy_name": "Barangay 94",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901095",
      "brgy_name": "Barangay 95",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901096",
      "brgy_name": "Barangay 96",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901097",
      "brgy_name": "Barangay 97",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901098",
      "brgy_name": "Barangay 98",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901099",
      "brgy_name": "Barangay 99",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901100",
      "brgy_name": "Barangay 100",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901101",
      "brgy_name": "Barangay 101",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901102",
      "brgy_name": "Barangay 102",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901103",
      "brgy_name": "Barangay 103",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901104",
      "brgy_name": "Barangay 104",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901105",
      "brgy_name": "Barangay 105",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901106",
      "brgy_name": "Barangay 106",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901107",
      "brgy_name": "Barangay 107",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901108",
      "brgy_name": "Barangay 108",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901109",
      "brgy_name": "Barangay 109",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901110",
      "brgy_name": "Barangay 110",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901111",
      "brgy_name": "Barangay 111",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901112",
      "brgy_name": "Barangay 112",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901116",
      "brgy_name": "Barangay 116",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901117",
      "brgy_name": "Barangay 117",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901118",
      "brgy_name": "Barangay 118",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901119",
      "brgy_name": "Barangay 119",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901120",
      "brgy_name": "Barangay 120",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901121",
      "brgy_name": "Barangay 121",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901122",
      "brgy_name": "Barangay 122",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901123",
      "brgy_name": "Barangay 123",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901124",
      "brgy_name": "Barangay 124",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901125",
      "brgy_name": "Barangay 125",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901126",
      "brgy_name": "Barangay 126",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901127",
      "brgy_name": "Barangay 127",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901128",
      "brgy_name": "Barangay 128",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901129",
      "brgy_name": "Barangay 129",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901130",
      "brgy_name": "Barangay 130",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901131",
      "brgy_name": "Barangay 131",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901132",
      "brgy_name": "Barangay 132",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901133",
      "brgy_name": "Barangay 133",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901134",
      "brgy_name": "Barangay 134",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901135",
      "brgy_name": "Barangay 135",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901136",
      "brgy_name": "Barangay 136",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901137",
      "brgy_name": "Barangay 137",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901138",
      "brgy_name": "Barangay 138",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901139",
      "brgy_name": "Barangay 139",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901140",
      "brgy_name": "Barangay 140",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901141",
      "brgy_name": "Barangay 141",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901142",
      "brgy_name": "Barangay 142",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901143",
      "brgy_name": "Barangay 143",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901144",
      "brgy_name": "Barangay 144",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901145",
      "brgy_name": "Barangay 145",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901146",
      "brgy_name": "Barangay 146",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901147",
      "brgy_name": "Barangay 147",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901148",
      "brgy_name": "Barangay 148",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901149",
      "brgy_name": "Barangay 149",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901150",
      "brgy_name": "Barangay 150",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901151",
      "brgy_name": "Barangay 151",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901152",
      "brgy_name": "Barangay 152",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901153",
      "brgy_name": "Barangay 153",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901154",
      "brgy_name": "Barangay 154",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901155",
      "brgy_name": "Barangay 155",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901156",
      "brgy_name": "Barangay 156",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901157",
      "brgy_name": "Barangay 157",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901158",
      "brgy_name": "Barangay 158",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901159",
      "brgy_name": "Barangay 159",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901160",
      "brgy_name": "Barangay 160",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901161",
      "brgy_name": "Barangay 161",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901162",
      "brgy_name": "Barangay 162",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901163",
      "brgy_name": "Barangay 163",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901164",
      "brgy_name": "Barangay 164",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901165",
      "brgy_name": "Barangay 165",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901166",
      "brgy_name": "Barangay 166",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901167",
      "brgy_name": "Barangay 167",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901168",
      "brgy_name": "Barangay 168",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901169",
      "brgy_name": "Barangay 169",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901170",
      "brgy_name": "Barangay 170",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901171",
      "brgy_name": "Barangay 171",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901172",
      "brgy_name": "Barangay 172",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901173",
      "brgy_name": "Barangay 173",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901174",
      "brgy_name": "Barangay 174",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901175",
      "brgy_name": "Barangay 175",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901176",
      "brgy_name": "Barangay 176",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901177",
      "brgy_name": "Barangay 177",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901178",
      "brgy_name": "Barangay 178",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901179",
      "brgy_name": "Barangay 179",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901180",
      "brgy_name": "Barangay 180",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901181",
      "brgy_name": "Barangay 181",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901182",
      "brgy_name": "Barangay 182",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901183",
      "brgy_name": "Barangay 183",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901184",
      "brgy_name": "Barangay 184",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901185",
      "brgy_name": "Barangay 185",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901186",
      "brgy_name": "Barangay 186",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901187",
      "brgy_name": "Barangay 187",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901188",
      "brgy_name": "Barangay 188",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901189",
      "brgy_name": "Barangay 189",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901190",
      "brgy_name": "Barangay 190",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901191",
      "brgy_name": "Barangay 191",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901192",
      "brgy_name": "Barangay 192",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901193",
      "brgy_name": "Barangay 193",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901194",
      "brgy_name": "Barangay 194",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901195",
      "brgy_name": "Barangay 195",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901196",
      "brgy_name": "Barangay 196",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901197",
      "brgy_name": "Barangay 197",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901198",
      "brgy_name": "Barangay 198",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901199",
      "brgy_name": "Barangay 199",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901200",
      "brgy_name": "Barangay 200",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901201",
      "brgy_name": "Barangay 201",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901202",
      "brgy_name": "Barangay 202",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901203",
      "brgy_name": "Barangay 202-A",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901204",
      "brgy_name": "Barangay 203",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901205",
      "brgy_name": "Barangay 204",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901206",
      "brgy_name": "Barangay 205",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901207",
      "brgy_name": "Barangay 206",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901208",
      "brgy_name": "Barangay 207",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901209",
      "brgy_name": "Barangay 208",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901210",
      "brgy_name": "Barangay 209",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901211",
      "brgy_name": "Barangay 210",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901212",
      "brgy_name": "Barangay 211",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901213",
      "brgy_name": "Barangay 212",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901214",
      "brgy_name": "Barangay 213",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901215",
      "brgy_name": "Barangay 214",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901216",
      "brgy_name": "Barangay 215",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901217",
      "brgy_name": "Barangay 216",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901218",
      "brgy_name": "Barangay 217",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901219",
      "brgy_name": "Barangay 218",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901220",
      "brgy_name": "Barangay 219",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901221",
      "brgy_name": "Barangay 220",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901222",
      "brgy_name": "Barangay 221",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901223",
      "brgy_name": "Barangay 222",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901224",
      "brgy_name": "Barangay 223",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901225",
      "brgy_name": "Barangay 224",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901226",
      "brgy_name": "Barangay 225",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901227",
      "brgy_name": "Barangay 226",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901228",
      "brgy_name": "Barangay 227",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901229",
      "brgy_name": "Barangay 228",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901230",
      "brgy_name": "Barangay 229",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901231",
      "brgy_name": "Barangay 230",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901232",
      "brgy_name": "Barangay 231",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901233",
      "brgy_name": "Barangay 232",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901234",
      "brgy_name": "Barangay 233",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901235",
      "brgy_name": "Barangay 234",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901236",
      "brgy_name": "Barangay 235",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901237",
      "brgy_name": "Barangay 236",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901238",
      "brgy_name": "Barangay 237",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901239",
      "brgy_name": "Barangay 238",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901240",
      "brgy_name": "Barangay 239",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901241",
      "brgy_name": "Barangay 240",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901242",
      "brgy_name": "Barangay 241",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901243",
      "brgy_name": "Barangay 242",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901244",
      "brgy_name": "Barangay 243",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901245",
      "brgy_name": "Barangay 244",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901246",
      "brgy_name": "Barangay 245",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901247",
      "brgy_name": "Barangay 246",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901248",
      "brgy_name": "Barangay 247",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901249",
      "brgy_name": "Barangay 248",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901250",
      "brgy_name": "Barangay 249",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901251",
      "brgy_name": "Barangay 250",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901252",
      "brgy_name": "Barangay 251",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901253",
      "brgy_name": "Barangay 252",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901254",
      "brgy_name": "Barangay 253",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901255",
      "brgy_name": "Barangay 254",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901256",
      "brgy_name": "Barangay 255",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901257",
      "brgy_name": "Barangay 256",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901258",
      "brgy_name": "Barangay 257",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901259",
      "brgy_name": "Barangay 258",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901260",
      "brgy_name": "Barangay 259",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901261",
      "brgy_name": "Barangay 260",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901262",
      "brgy_name": "Barangay 261",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901263",
      "brgy_name": "Barangay 262",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901264",
      "brgy_name": "Barangay 263",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901265",
      "brgy_name": "Barangay 264",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901266",
      "brgy_name": "Barangay 265",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901267",
      "brgy_name": "Barangay 266",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133901268",
      "brgy_name": "Barangay 267",
      "city_code": "133901",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902001",
      "brgy_name": "Barangay 287",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902002",
      "brgy_name": "Barangay 288",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902003",
      "brgy_name": "Barangay 289",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902004",
      "brgy_name": "Barangay 290",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902005",
      "brgy_name": "Barangay 291",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902006",
      "brgy_name": "Barangay 292",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902007",
      "brgy_name": "Barangay 293",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902008",
      "brgy_name": "Barangay 294",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902009",
      "brgy_name": "Barangay 295",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133902010",
      "brgy_name": "Barangay 296",
      "city_code": "133902",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903003",
      "brgy_name": "Barangay 383",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903004",
      "brgy_name": "Barangay 384",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903005",
      "brgy_name": "Barangay 385",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903006",
      "brgy_name": "Barangay 386",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903007",
      "brgy_name": "Barangay 387",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903008",
      "brgy_name": "Barangay 388",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903009",
      "brgy_name": "Barangay 389",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903010",
      "brgy_name": "Barangay 390",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903011",
      "brgy_name": "Barangay 391",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903012",
      "brgy_name": "Barangay 392",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903013",
      "brgy_name": "Barangay 393",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903014",
      "brgy_name": "Barangay 394",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903015",
      "brgy_name": "Barangay 306",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903016",
      "brgy_name": "Barangay 307",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903017",
      "brgy_name": "Barangay 308",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133903018",
      "brgy_name": "Barangay 309",
      "city_code": "133903",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904001",
      "brgy_name": "Barangay 268",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904002",
      "brgy_name": "Barangay 269",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904003",
      "brgy_name": "Barangay 270",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904004",
      "brgy_name": "Barangay 271",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904005",
      "brgy_name": "Barangay 272",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904006",
      "brgy_name": "Barangay 273",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904007",
      "brgy_name": "Barangay 274",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904008",
      "brgy_name": "Barangay 275",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904009",
      "brgy_name": "Barangay 276",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904014",
      "brgy_name": "Barangay 281",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904015",
      "brgy_name": "Barangay 282",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904016",
      "brgy_name": "Barangay 283",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904017",
      "brgy_name": "Barangay 284",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904018",
      "brgy_name": "Barangay 285",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133904019",
      "brgy_name": "Barangay 286",
      "city_code": "133904",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905001",
      "brgy_name": "Barangay 297",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905002",
      "brgy_name": "Barangay 298",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905003",
      "brgy_name": "Barangay 299",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905004",
      "brgy_name": "Barangay 300",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905005",
      "brgy_name": "Barangay 301",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905006",
      "brgy_name": "Barangay 302",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905007",
      "brgy_name": "Barangay 303",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905008",
      "brgy_name": "Barangay 304",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905009",
      "brgy_name": "Barangay 305",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905012",
      "brgy_name": "Barangay 310",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905013",
      "brgy_name": "Barangay 311",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905014",
      "brgy_name": "Barangay 312",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905015",
      "brgy_name": "Barangay 313",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905016",
      "brgy_name": "Barangay 314",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905017",
      "brgy_name": "Barangay 315",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905018",
      "brgy_name": "Barangay 316",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905019",
      "brgy_name": "Barangay 317",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905020",
      "brgy_name": "Barangay 318",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905021",
      "brgy_name": "Barangay 319",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905022",
      "brgy_name": "Barangay 320",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905023",
      "brgy_name": "Barangay 321",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905024",
      "brgy_name": "Barangay 322",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905025",
      "brgy_name": "Barangay 323",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905026",
      "brgy_name": "Barangay 324",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905027",
      "brgy_name": "Barangay 325",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905028",
      "brgy_name": "Barangay 326",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905029",
      "brgy_name": "Barangay 327",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905030",
      "brgy_name": "Barangay 328",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905031",
      "brgy_name": "Barangay 329",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905032",
      "brgy_name": "Barangay 330",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905033",
      "brgy_name": "Barangay 331",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905034",
      "brgy_name": "Barangay 332",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905035",
      "brgy_name": "Barangay 333",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905036",
      "brgy_name": "Barangay 334",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905037",
      "brgy_name": "Barangay 335",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905038",
      "brgy_name": "Barangay 336",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905039",
      "brgy_name": "Barangay 337",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905040",
      "brgy_name": "Barangay 338",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905041",
      "brgy_name": "Barangay 339",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905042",
      "brgy_name": "Barangay 340",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905043",
      "brgy_name": "Barangay 341",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905044",
      "brgy_name": "Barangay 342",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905045",
      "brgy_name": "Barangay 343",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905046",
      "brgy_name": "Barangay 344",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905047",
      "brgy_name": "Barangay 345",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905048",
      "brgy_name": "Barangay 346",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905049",
      "brgy_name": "Barangay 347",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905050",
      "brgy_name": "Barangay 348",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905051",
      "brgy_name": "Barangay 349",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905052",
      "brgy_name": "Barangay 350",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905053",
      "brgy_name": "Barangay 351",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905054",
      "brgy_name": "Barangay 352",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905055",
      "brgy_name": "Barangay 353",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905056",
      "brgy_name": "Barangay 354",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905057",
      "brgy_name": "Barangay 355",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905058",
      "brgy_name": "Barangay 356",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905059",
      "brgy_name": "Barangay 357",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905060",
      "brgy_name": "Barangay 358",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905061",
      "brgy_name": "Barangay 359",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905062",
      "brgy_name": "Barangay 360",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905063",
      "brgy_name": "Barangay 361",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905064",
      "brgy_name": "Barangay 362",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905065",
      "brgy_name": "Barangay 363",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905066",
      "brgy_name": "Barangay 364",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905067",
      "brgy_name": "Barangay 365",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905069",
      "brgy_name": "Barangay 366",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905070",
      "brgy_name": "Barangay 367",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905071",
      "brgy_name": "Barangay 368",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905072",
      "brgy_name": "Barangay 369",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905073",
      "brgy_name": "Barangay 370",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905074",
      "brgy_name": "Barangay 371",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905075",
      "brgy_name": "Barangay 372",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905076",
      "brgy_name": "Barangay 373",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905077",
      "brgy_name": "Barangay 374",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905078",
      "brgy_name": "Barangay 375",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905079",
      "brgy_name": "Barangay 376",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905080",
      "brgy_name": "Barangay 377",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905081",
      "brgy_name": "Barangay 378",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905082",
      "brgy_name": "Barangay 379",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905083",
      "brgy_name": "Barangay 380",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905084",
      "brgy_name": "Barangay 381",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133905085",
      "brgy_name": "Barangay 382",
      "city_code": "133905",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906001",
      "brgy_name": "Barangay 395",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906002",
      "brgy_name": "Barangay 396",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906003",
      "brgy_name": "Barangay 397",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906004",
      "brgy_name": "Barangay 398",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906005",
      "brgy_name": "Barangay 399",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906006",
      "brgy_name": "Barangay 400",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906007",
      "brgy_name": "Barangay 401",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906008",
      "brgy_name": "Barangay 402",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906009",
      "brgy_name": "Barangay 403",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906010",
      "brgy_name": "Barangay 404",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906011",
      "brgy_name": "Barangay 405",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906012",
      "brgy_name": "Barangay 406",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906013",
      "brgy_name": "Barangay 407",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906014",
      "brgy_name": "Barangay 408",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906015",
      "brgy_name": "Barangay 409",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906016",
      "brgy_name": "Barangay 410",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906017",
      "brgy_name": "Barangay 411",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906018",
      "brgy_name": "Barangay 412",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906019",
      "brgy_name": "Barangay 413",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906020",
      "brgy_name": "Barangay 414",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906021",
      "brgy_name": "Barangay 415",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906022",
      "brgy_name": "Barangay 416",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906023",
      "brgy_name": "Barangay 417",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906024",
      "brgy_name": "Barangay 418",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906025",
      "brgy_name": "Barangay 419",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906026",
      "brgy_name": "Barangay 420",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906027",
      "brgy_name": "Barangay 421",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906028",
      "brgy_name": "Barangay 422",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906029",
      "brgy_name": "Barangay 423",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906030",
      "brgy_name": "Barangay 424",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906031",
      "brgy_name": "Barangay 425",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906032",
      "brgy_name": "Barangay 426",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906033",
      "brgy_name": "Barangay 427",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906034",
      "brgy_name": "Barangay 428",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906035",
      "brgy_name": "Barangay 429",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906036",
      "brgy_name": "Barangay 430",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906037",
      "brgy_name": "Barangay 431",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906038",
      "brgy_name": "Barangay 432",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906039",
      "brgy_name": "Barangay 433",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906040",
      "brgy_name": "Barangay 434",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906041",
      "brgy_name": "Barangay 435",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906042",
      "brgy_name": "Barangay 436",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906043",
      "brgy_name": "Barangay 437",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906044",
      "brgy_name": "Barangay 438",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906045",
      "brgy_name": "Barangay 439",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906046",
      "brgy_name": "Barangay 440",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906047",
      "brgy_name": "Barangay 441",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906048",
      "brgy_name": "Barangay 442",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906049",
      "brgy_name": "Barangay 443",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906050",
      "brgy_name": "Barangay 444",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906051",
      "brgy_name": "Barangay 445",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906052",
      "brgy_name": "Barangay 446",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906053",
      "brgy_name": "Barangay 447",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906054",
      "brgy_name": "Barangay 448",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906055",
      "brgy_name": "Barangay 449",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906056",
      "brgy_name": "Barangay 450",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906057",
      "brgy_name": "Barangay 451",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906058",
      "brgy_name": "Barangay 452",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906059",
      "brgy_name": "Barangay 453",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906060",
      "brgy_name": "Barangay 454",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906061",
      "brgy_name": "Barangay 455",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906062",
      "brgy_name": "Barangay 456",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906063",
      "brgy_name": "Barangay 457",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906064",
      "brgy_name": "Barangay 458",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906065",
      "brgy_name": "Barangay 459",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906066",
      "brgy_name": "Barangay 460",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906067",
      "brgy_name": "Barangay 461",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906068",
      "brgy_name": "Barangay 462",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906069",
      "brgy_name": "Barangay 463",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906070",
      "brgy_name": "Barangay 464",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906071",
      "brgy_name": "Barangay 465",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906072",
      "brgy_name": "Barangay 466",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906073",
      "brgy_name": "Barangay 467",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906074",
      "brgy_name": "Barangay 468",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906075",
      "brgy_name": "Barangay 469",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906076",
      "brgy_name": "Barangay 470",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906077",
      "brgy_name": "Barangay 471",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906078",
      "brgy_name": "Barangay 472",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906079",
      "brgy_name": "Barangay 473",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906080",
      "brgy_name": "Barangay 474",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906081",
      "brgy_name": "Barangay 475",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906082",
      "brgy_name": "Barangay 476",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906083",
      "brgy_name": "Barangay 477",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906084",
      "brgy_name": "Barangay 478",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906085",
      "brgy_name": "Barangay 479",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906086",
      "brgy_name": "Barangay 480",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906087",
      "brgy_name": "Barangay 481",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906088",
      "brgy_name": "Barangay 482",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906089",
      "brgy_name": "Barangay 483",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906090",
      "brgy_name": "Barangay 484",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906091",
      "brgy_name": "Barangay 485",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906092",
      "brgy_name": "Barangay 486",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906093",
      "brgy_name": "Barangay 487",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906094",
      "brgy_name": "Barangay 488",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906095",
      "brgy_name": "Barangay 489",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906096",
      "brgy_name": "Barangay 490",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906097",
      "brgy_name": "Barangay 491",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906098",
      "brgy_name": "Barangay 492",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906099",
      "brgy_name": "Barangay 493",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906100",
      "brgy_name": "Barangay 494",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906101",
      "brgy_name": "Barangay 495",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906102",
      "brgy_name": "Barangay 496",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906103",
      "brgy_name": "Barangay 497",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906104",
      "brgy_name": "Barangay 498",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906105",
      "brgy_name": "Barangay 499",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906106",
      "brgy_name": "Barangay 500",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906107",
      "brgy_name": "Barangay 501",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906108",
      "brgy_name": "Barangay 502",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906109",
      "brgy_name": "Barangay 503",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906110",
      "brgy_name": "Barangay 504",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906111",
      "brgy_name": "Barangay 505",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906112",
      "brgy_name": "Barangay 506",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906113",
      "brgy_name": "Barangay 507",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906114",
      "brgy_name": "Barangay 508",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906115",
      "brgy_name": "Barangay 509",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906116",
      "brgy_name": "Barangay 510",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906117",
      "brgy_name": "Barangay 511",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906118",
      "brgy_name": "Barangay 512",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906119",
      "brgy_name": "Barangay 513",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906120",
      "brgy_name": "Barangay 514",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906121",
      "brgy_name": "Barangay 515",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906122",
      "brgy_name": "Barangay 516",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906123",
      "brgy_name": "Barangay 517",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906124",
      "brgy_name": "Barangay 518",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906125",
      "brgy_name": "Barangay 519",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906126",
      "brgy_name": "Barangay 520",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906127",
      "brgy_name": "Barangay 521",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906128",
      "brgy_name": "Barangay 522",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906129",
      "brgy_name": "Barangay 523",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906130",
      "brgy_name": "Barangay 524",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906131",
      "brgy_name": "Barangay 525",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906132",
      "brgy_name": "Barangay 526",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906133",
      "brgy_name": "Barangay 527",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906134",
      "brgy_name": "Barangay 528",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906135",
      "brgy_name": "Barangay 529",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906136",
      "brgy_name": "Barangay 530",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906137",
      "brgy_name": "Barangay 531",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906138",
      "brgy_name": "Barangay 532",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906139",
      "brgy_name": "Barangay 533",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906140",
      "brgy_name": "Barangay 534",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906141",
      "brgy_name": "Barangay 535",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906142",
      "brgy_name": "Barangay 536",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906143",
      "brgy_name": "Barangay 537",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906144",
      "brgy_name": "Barangay 538",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906145",
      "brgy_name": "Barangay 539",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906146",
      "brgy_name": "Barangay 540",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906147",
      "brgy_name": "Barangay 541",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906148",
      "brgy_name": "Barangay 542",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906149",
      "brgy_name": "Barangay 543",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906150",
      "brgy_name": "Barangay 544",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906151",
      "brgy_name": "Barangay 545",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906152",
      "brgy_name": "Barangay 546",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906153",
      "brgy_name": "Barangay 547",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906154",
      "brgy_name": "Barangay 548",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906155",
      "brgy_name": "Barangay 549",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906156",
      "brgy_name": "Barangay 550",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906157",
      "brgy_name": "Barangay 551",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906158",
      "brgy_name": "Barangay 552",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906159",
      "brgy_name": "Barangay 553",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906160",
      "brgy_name": "Barangay 554",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906161",
      "brgy_name": "Barangay 555",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906162",
      "brgy_name": "Barangay 556",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906163",
      "brgy_name": "Barangay 557",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906164",
      "brgy_name": "Barangay 558",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906165",
      "brgy_name": "Barangay 559",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906166",
      "brgy_name": "Barangay 560",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906167",
      "brgy_name": "Barangay 561",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906168",
      "brgy_name": "Barangay 562",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906169",
      "brgy_name": "Barangay 563",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906170",
      "brgy_name": "Barangay 564",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906171",
      "brgy_name": "Barangay 565",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906172",
      "brgy_name": "Barangay 566",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906173",
      "brgy_name": "Barangay 567",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906174",
      "brgy_name": "Barangay 568",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906175",
      "brgy_name": "Barangay 569",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906176",
      "brgy_name": "Barangay 570",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906177",
      "brgy_name": "Barangay 571",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906178",
      "brgy_name": "Barangay 572",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906179",
      "brgy_name": "Barangay 573",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906180",
      "brgy_name": "Barangay 574",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906181",
      "brgy_name": "Barangay 575",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906182",
      "brgy_name": "Barangay 576",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906183",
      "brgy_name": "Barangay 577",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906184",
      "brgy_name": "Barangay 578",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906185",
      "brgy_name": "Barangay 579",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906186",
      "brgy_name": "Barangay 580",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906187",
      "brgy_name": "Barangay 581",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906188",
      "brgy_name": "Barangay 582",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906189",
      "brgy_name": "Barangay 583",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906190",
      "brgy_name": "Barangay 584",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906191",
      "brgy_name": "Barangay 585",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906192",
      "brgy_name": "Barangay 586",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906193",
      "brgy_name": "Barangay 587",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906194",
      "brgy_name": "Barangay 587-A",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906195",
      "brgy_name": "Barangay 588",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906196",
      "brgy_name": "Barangay 589",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906197",
      "brgy_name": "Barangay 590",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906198",
      "brgy_name": "Barangay 591",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906199",
      "brgy_name": "Barangay 592",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906200",
      "brgy_name": "Barangay 593",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906201",
      "brgy_name": "Barangay 594",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906202",
      "brgy_name": "Barangay 595",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906203",
      "brgy_name": "Barangay 596",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906204",
      "brgy_name": "Barangay 597",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906205",
      "brgy_name": "Barangay 598",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906206",
      "brgy_name": "Barangay 599",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906207",
      "brgy_name": "Barangay 600",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906208",
      "brgy_name": "Barangay 601",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906209",
      "brgy_name": "Barangay 602",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906210",
      "brgy_name": "Barangay 603",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906211",
      "brgy_name": "Barangay 604",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906212",
      "brgy_name": "Barangay 605",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906213",
      "brgy_name": "Barangay 606",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906214",
      "brgy_name": "Barangay 607",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906215",
      "brgy_name": "Barangay 608",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906216",
      "brgy_name": "Barangay 609",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906217",
      "brgy_name": "Barangay 610",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906218",
      "brgy_name": "Barangay 611",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906219",
      "brgy_name": "Barangay 612",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906220",
      "brgy_name": "Barangay 613",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906221",
      "brgy_name": "Barangay 614",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906222",
      "brgy_name": "Barangay 615",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906223",
      "brgy_name": "Barangay 616",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906224",
      "brgy_name": "Barangay 617",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906225",
      "brgy_name": "Barangay 618",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906226",
      "brgy_name": "Barangay 619",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906227",
      "brgy_name": "Barangay 620",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906228",
      "brgy_name": "Barangay 621",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906229",
      "brgy_name": "Barangay 622",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906230",
      "brgy_name": "Barangay 623",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906231",
      "brgy_name": "Barangay 624",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906232",
      "brgy_name": "Barangay 625",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906233",
      "brgy_name": "Barangay 626",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906234",
      "brgy_name": "Barangay 627",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906235",
      "brgy_name": "Barangay 628",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906236",
      "brgy_name": "Barangay 629",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906237",
      "brgy_name": "Barangay 630",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906238",
      "brgy_name": "Barangay 631",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906239",
      "brgy_name": "Barangay 632",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906240",
      "brgy_name": "Barangay 633",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906241",
      "brgy_name": "Barangay 634",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906242",
      "brgy_name": "Barangay 635",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133906243",
      "brgy_name": "Barangay 636",
      "city_code": "133906",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907001",
      "brgy_name": "Barangay 637",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907002",
      "brgy_name": "Barangay 638",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907003",
      "brgy_name": "Barangay 639",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907004",
      "brgy_name": "Barangay 640",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907005",
      "brgy_name": "Barangay 641",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907006",
      "brgy_name": "Barangay 642",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907007",
      "brgy_name": "Barangay 643",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907008",
      "brgy_name": "Barangay 644",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907009",
      "brgy_name": "Barangay 645",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907010",
      "brgy_name": "Barangay 646",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907011",
      "brgy_name": "Barangay 647",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133907012",
      "brgy_name": "Barangay 648",
      "city_code": "133907",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908001",
      "brgy_name": "Barangay 659",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908002",
      "brgy_name": "Barangay 659-A",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908003",
      "brgy_name": "Barangay 660",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908004",
      "brgy_name": "Barangay 660-A",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908005",
      "brgy_name": "Barangay 661",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908008",
      "brgy_name": "Barangay 666",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908009",
      "brgy_name": "Barangay 667",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908010",
      "brgy_name": "Barangay 668",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908011",
      "brgy_name": "Barangay 669",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908012",
      "brgy_name": "Barangay 670",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908013",
      "brgy_name": "Barangay 663",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908014",
      "brgy_name": "Barangay 663-A",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133908015",
      "brgy_name": "Barangay 664",
      "city_code": "133908",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133909001",
      "brgy_name": "Barangay 654",
      "city_code": "133909",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133909002",
      "brgy_name": "Barangay 655",
      "city_code": "133909",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133909003",
      "brgy_name": "Barangay 656",
      "city_code": "133909",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133909004",
      "brgy_name": "Barangay 657",
      "city_code": "133909",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133909005",
      "brgy_name": "Barangay 658",
      "city_code": "133909",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910004",
      "brgy_name": "Barangay 689",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910005",
      "brgy_name": "Barangay 690",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910006",
      "brgy_name": "Barangay 691",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910007",
      "brgy_name": "Barangay 692",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910008",
      "brgy_name": "Barangay 693",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910009",
      "brgy_name": "Barangay 694",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910010",
      "brgy_name": "Barangay 695",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910011",
      "brgy_name": "Barangay 696",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910012",
      "brgy_name": "Barangay 697",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910013",
      "brgy_name": "Barangay 698",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910014",
      "brgy_name": "Barangay 699",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910015",
      "brgy_name": "Barangay 700",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910016",
      "brgy_name": "Barangay 701",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910017",
      "brgy_name": "Barangay 702",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910018",
      "brgy_name": "Barangay 703",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910019",
      "brgy_name": "Barangay 704",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910020",
      "brgy_name": "Barangay 705",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910021",
      "brgy_name": "Barangay 706",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910022",
      "brgy_name": "Barangay 707",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910023",
      "brgy_name": "Barangay 708",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910024",
      "brgy_name": "Barangay 709",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910025",
      "brgy_name": "Barangay 710",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910026",
      "brgy_name": "Barangay 711",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910027",
      "brgy_name": "Barangay 712",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910028",
      "brgy_name": "Barangay 713",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910029",
      "brgy_name": "Barangay 714",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910030",
      "brgy_name": "Barangay 715",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910031",
      "brgy_name": "Barangay 716",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910032",
      "brgy_name": "Barangay 717",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910033",
      "brgy_name": "Barangay 718",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910034",
      "brgy_name": "Barangay 719",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910035",
      "brgy_name": "Barangay 720",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910036",
      "brgy_name": "Barangay 721",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910037",
      "brgy_name": "Barangay 722",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910038",
      "brgy_name": "Barangay 723",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910039",
      "brgy_name": "Barangay 724",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910040",
      "brgy_name": "Barangay 725",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910041",
      "brgy_name": "Barangay 726",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910042",
      "brgy_name": "Barangay 727",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910043",
      "brgy_name": "Barangay 728",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910044",
      "brgy_name": "Barangay 729",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910045",
      "brgy_name": "Barangay 730",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910046",
      "brgy_name": "Barangay 731",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910047",
      "brgy_name": "Barangay 732",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910048",
      "brgy_name": "Barangay 733",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910052",
      "brgy_name": "Barangay 738",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910053",
      "brgy_name": "Barangay 739",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910054",
      "brgy_name": "Barangay 740",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910055",
      "brgy_name": "Barangay 741",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910056",
      "brgy_name": "Barangay 742",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910057",
      "brgy_name": "Barangay 743",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910058",
      "brgy_name": "Barangay 744",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910059",
      "brgy_name": "Barangay 688",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910060",
      "brgy_name": "Barangay 735",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910061",
      "brgy_name": "Barangay 736",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910062",
      "brgy_name": "Barangay 737",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133910063",
      "brgy_name": "Barangay 734",
      "city_code": "133910",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911001",
      "brgy_name": "Barangay 662",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911004",
      "brgy_name": "Barangay 664-A",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911005",
      "brgy_name": "Barangay 671",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911006",
      "brgy_name": "Barangay 672",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911007",
      "brgy_name": "Barangay 673",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911008",
      "brgy_name": "Barangay 674",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911009",
      "brgy_name": "Barangay 675",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911010",
      "brgy_name": "Barangay 676",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911011",
      "brgy_name": "Barangay 677",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911012",
      "brgy_name": "Barangay 678",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911013",
      "brgy_name": "Barangay 679",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911014",
      "brgy_name": "Barangay 680",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911015",
      "brgy_name": "Barangay 681",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911016",
      "brgy_name": "Barangay 682",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911017",
      "brgy_name": "Barangay 683",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911018",
      "brgy_name": "Barangay 684",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911019",
      "brgy_name": "Barangay 685",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911023",
      "brgy_name": "Barangay 809",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911024",
      "brgy_name": "Barangay 810",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911025",
      "brgy_name": "Barangay 811",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911026",
      "brgy_name": "Barangay 812",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911027",
      "brgy_name": "Barangay 813",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911028",
      "brgy_name": "Barangay 814",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911029",
      "brgy_name": "Barangay 815",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911030",
      "brgy_name": "Barangay 816",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911031",
      "brgy_name": "Barangay 817",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911032",
      "brgy_name": "Barangay 818",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911034",
      "brgy_name": "Barangay 819",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911035",
      "brgy_name": "Barangay 820",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911036",
      "brgy_name": "Barangay 821",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911037",
      "brgy_name": "Barangay 822",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911038",
      "brgy_name": "Barangay 823",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911039",
      "brgy_name": "Barangay 824",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911040",
      "brgy_name": "Barangay 825",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911041",
      "brgy_name": "Barangay 826",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911042",
      "brgy_name": "Barangay 827",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911043",
      "brgy_name": "Barangay 828",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911044",
      "brgy_name": "Barangay 829",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911045",
      "brgy_name": "Barangay 830",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911046",
      "brgy_name": "Barangay 831",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911047",
      "brgy_name": "Barangay 832",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911048",
      "brgy_name": "Barangay 686",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133911049",
      "brgy_name": "Barangay 687",
      "city_code": "133911",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912001",
      "brgy_name": "Barangay 833",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912002",
      "brgy_name": "Barangay 834",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912003",
      "brgy_name": "Barangay 835",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912004",
      "brgy_name": "Barangay 836",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912005",
      "brgy_name": "Barangay 837",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912006",
      "brgy_name": "Barangay 838",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912007",
      "brgy_name": "Barangay 839",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912008",
      "brgy_name": "Barangay 840",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912009",
      "brgy_name": "Barangay 841",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912010",
      "brgy_name": "Barangay 842",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912011",
      "brgy_name": "Barangay 843",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912012",
      "brgy_name": "Barangay 844",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912013",
      "brgy_name": "Barangay 845",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912014",
      "brgy_name": "Barangay 846",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912015",
      "brgy_name": "Barangay 847",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912016",
      "brgy_name": "Barangay 848",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912017",
      "brgy_name": "Barangay 849",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912018",
      "brgy_name": "Barangay 850",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912019",
      "brgy_name": "Barangay 851",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912020",
      "brgy_name": "Barangay 852",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912021",
      "brgy_name": "Barangay 853",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912023",
      "brgy_name": "Barangay 855",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912024",
      "brgy_name": "Barangay 856",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912025",
      "brgy_name": "Barangay 857",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912026",
      "brgy_name": "Barangay 858",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912027",
      "brgy_name": "Barangay 859",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912028",
      "brgy_name": "Barangay 860",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912029",
      "brgy_name": "Barangay 861",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912030",
      "brgy_name": "Barangay 862",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912031",
      "brgy_name": "Barangay 863",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912032",
      "brgy_name": "Barangay 864",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912033",
      "brgy_name": "Barangay 865",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912034",
      "brgy_name": "Barangay 867",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912035",
      "brgy_name": "Barangay 868",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912036",
      "brgy_name": "Barangay 870",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912037",
      "brgy_name": "Barangay 871",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912038",
      "brgy_name": "Barangay 872",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133912039",
      "brgy_name": "Barangay 869",
      "city_code": "133912",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133913001",
      "brgy_name": "Barangay 649",
      "city_code": "133913",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133913002",
      "brgy_name": "Barangay 650",
      "city_code": "133913",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133913003",
      "brgy_name": "Barangay 651",
      "city_code": "133913",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133913004",
      "brgy_name": "Barangay 652",
      "city_code": "133913",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133913005",
      "brgy_name": "Barangay 653",
      "city_code": "133913",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914002",
      "brgy_name": "Barangay 745",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914003",
      "brgy_name": "Barangay 746",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914004",
      "brgy_name": "Barangay 747",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914005",
      "brgy_name": "Barangay 748",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914006",
      "brgy_name": "Barangay 749",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914007",
      "brgy_name": "Barangay 750",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914008",
      "brgy_name": "Barangay 751",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914009",
      "brgy_name": "Barangay 752",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914010",
      "brgy_name": "Barangay 753",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914011",
      "brgy_name": "Barangay 755",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914012",
      "brgy_name": "Barangay 756",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914013",
      "brgy_name": "Barangay 757",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914014",
      "brgy_name": "Barangay 758",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914015",
      "brgy_name": "Barangay 759",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914016",
      "brgy_name": "Barangay 760",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914017",
      "brgy_name": "Barangay 761",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914018",
      "brgy_name": "Barangay 762",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914019",
      "brgy_name": "Barangay 763",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914020",
      "brgy_name": "Barangay 764",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914021",
      "brgy_name": "Barangay 765",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914022",
      "brgy_name": "Barangay 766",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914023",
      "brgy_name": "Barangay 767",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914024",
      "brgy_name": "Barangay 768",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914025",
      "brgy_name": "Barangay 769",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914026",
      "brgy_name": "Barangay 770",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914027",
      "brgy_name": "Barangay 771",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914028",
      "brgy_name": "Barangay 772",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914029",
      "brgy_name": "Barangay 773",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914030",
      "brgy_name": "Barangay 774",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914031",
      "brgy_name": "Barangay 775",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914032",
      "brgy_name": "Barangay 776",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914033",
      "brgy_name": "Barangay 777",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914034",
      "brgy_name": "Barangay 778",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914035",
      "brgy_name": "Barangay 779",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914036",
      "brgy_name": "Barangay 780",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914037",
      "brgy_name": "Barangay 781",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914038",
      "brgy_name": "Barangay 782",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914039",
      "brgy_name": "Barangay 783",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914040",
      "brgy_name": "Barangay 784",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914041",
      "brgy_name": "Barangay 785",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914042",
      "brgy_name": "Barangay 786",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914043",
      "brgy_name": "Barangay 787",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914044",
      "brgy_name": "Barangay 788",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914045",
      "brgy_name": "Barangay 789",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914046",
      "brgy_name": "Barangay 790",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914047",
      "brgy_name": "Barangay 791",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914048",
      "brgy_name": "Barangay 792",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914049",
      "brgy_name": "Barangay 793",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914050",
      "brgy_name": "Barangay 794",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914051",
      "brgy_name": "Barangay 795",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914052",
      "brgy_name": "Barangay 796",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914053",
      "brgy_name": "Barangay 797",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914054",
      "brgy_name": "Barangay 798",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914055",
      "brgy_name": "Barangay 799",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914056",
      "brgy_name": "Barangay 800",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914057",
      "brgy_name": "Barangay 801",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914058",
      "brgy_name": "Barangay 802",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914059",
      "brgy_name": "Barangay 803",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914060",
      "brgy_name": "Barangay 804",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914061",
      "brgy_name": "Barangay 805",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914062",
      "brgy_name": "Barangay 806",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914063",
      "brgy_name": "Barangay 807",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914064",
      "brgy_name": "Barangay 866",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914066",
      "brgy_name": "Barangay 873",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914067",
      "brgy_name": "Barangay 874",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914068",
      "brgy_name": "Barangay 875",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914069",
      "brgy_name": "Barangay 876",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914070",
      "brgy_name": "Barangay 877",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914071",
      "brgy_name": "Barangay 878",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914072",
      "brgy_name": "Barangay 879",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914073",
      "brgy_name": "Barangay 880",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914074",
      "brgy_name": "Barangay 881",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914075",
      "brgy_name": "Barangay 882",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914076",
      "brgy_name": "Barangay 883",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914077",
      "brgy_name": "Barangay 884",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914078",
      "brgy_name": "Barangay 885",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914079",
      "brgy_name": "Barangay 886",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914080",
      "brgy_name": "Barangay 887",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914081",
      "brgy_name": "Barangay 888",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914082",
      "brgy_name": "Barangay 889",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914083",
      "brgy_name": "Barangay 890",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914084",
      "brgy_name": "Barangay 891",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914085",
      "brgy_name": "Barangay 892",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914086",
      "brgy_name": "Barangay 893",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914087",
      "brgy_name": "Barangay 894",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914088",
      "brgy_name": "Barangay 895",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914089",
      "brgy_name": "Barangay 896",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914090",
      "brgy_name": "Barangay 897",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914091",
      "brgy_name": "Barangay 898",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914092",
      "brgy_name": "Barangay 899",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914093",
      "brgy_name": "Barangay 900",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914094",
      "brgy_name": "Barangay 901",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914095",
      "brgy_name": "Barangay 902",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914096",
      "brgy_name": "Barangay 903",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914097",
      "brgy_name": "Barangay 904",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914098",
      "brgy_name": "Barangay 905",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914099",
      "brgy_name": "Barangay 754",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914100",
      "brgy_name": "Barangay 808",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "133914101",
      "brgy_name": "Barangay 818-A",
      "city_code": "133914",
      "province_code": "1339",
      "region_code": "13"
    },
    {
      "brgy_code": "137401001",
      "brgy_name": "Addition Hills",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401002",
      "brgy_name": "Bagong Silang",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401003",
      "brgy_name": "Barangka Drive",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401004",
      "brgy_name": "Barangka Ibaba",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401005",
      "brgy_name": "Barangka Ilaya",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401006",
      "brgy_name": "Barangka Itaas",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401007",
      "brgy_name": "Burol",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401008",
      "brgy_name": "Buayang Bato",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401009",
      "brgy_name": "Daang Bakal",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401010",
      "brgy_name": "Hagdang Bato Itaas",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401011",
      "brgy_name": "Hagdang Bato Libis",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401012",
      "brgy_name": "Harapin Ang Bukas",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401013",
      "brgy_name": "Highway Hills",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401014",
      "brgy_name": "Hulo",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401015",
      "brgy_name": "Mabini-J. Rizal",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401016",
      "brgy_name": "Malamig",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401017",
      "brgy_name": "Mauway",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401018",
      "brgy_name": "Namayan",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401019",
      "brgy_name": "New Zañiga",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401020",
      "brgy_name": "Old Zañiga",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401021",
      "brgy_name": "Pag-asa",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401022",
      "brgy_name": "Plainview",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401023",
      "brgy_name": "Pleasant Hills",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401024",
      "brgy_name": "Poblacion",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401025",
      "brgy_name": "San Jose",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401026",
      "brgy_name": "Vergara",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137401027",
      "brgy_name": "Wack-wack Greenhills",
      "city_code": "137401",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402001",
      "brgy_name": "Barangka",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402002",
      "brgy_name": "Calumpang",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402003",
      "brgy_name": "Concepcion Uno",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402004",
      "brgy_name": "Jesus De La Peña",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402005",
      "brgy_name": "Malanday",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402006",
      "brgy_name": "Nangka",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402007",
      "brgy_name": "Parang",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402008",
      "brgy_name": "San Roque",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402009",
      "brgy_name": "Santa Elena (Pob.)",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402010",
      "brgy_name": "Santo Niño",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402011",
      "brgy_name": "Tañong",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402012",
      "brgy_name": "Concepcion Dos",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402013",
      "brgy_name": "Marikina Heights (Concepcion)",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402014",
      "brgy_name": "Industrial Valley",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402015",
      "brgy_name": "Fortune",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137402016",
      "brgy_name": "Tumana",
      "city_code": "137402",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403001",
      "brgy_name": "Bagong Ilog",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403002",
      "brgy_name": "Bagong Katipunan",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403003",
      "brgy_name": "Bambang",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403004",
      "brgy_name": "Buting",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403005",
      "brgy_name": "Caniogan",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403006",
      "brgy_name": "Dela Paz",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403007",
      "brgy_name": "Kalawaan",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403008",
      "brgy_name": "Kapasigan",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403009",
      "brgy_name": "Kapitolyo",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403010",
      "brgy_name": "Malinao",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403011",
      "brgy_name": "Manggahan",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403012",
      "brgy_name": "Maybunga",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403013",
      "brgy_name": "Oranbo",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403014",
      "brgy_name": "Palatiw",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403015",
      "brgy_name": "Pinagbuhatan",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403016",
      "brgy_name": "Pineda",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403017",
      "brgy_name": "Rosario",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403018",
      "brgy_name": "Sagad",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403019",
      "brgy_name": "San Antonio",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403020",
      "brgy_name": "San Joaquin",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403021",
      "brgy_name": "San Jose",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403022",
      "brgy_name": "San Miguel",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403023",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403024",
      "brgy_name": "Santa Cruz",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403025",
      "brgy_name": "Santa Rosa",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403026",
      "brgy_name": "Santo Tomas",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403027",
      "brgy_name": "Santolan",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403028",
      "brgy_name": "Sumilang",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403029",
      "brgy_name": "Ugong",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137403030",
      "brgy_name": "Santa Lucia",
      "city_code": "137403",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404001",
      "brgy_name": "Alicia",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404002",
      "brgy_name": "Amihan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404003",
      "brgy_name": "Apolonio Samson",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404004",
      "brgy_name": "Aurora",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404005",
      "brgy_name": "Baesa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404006",
      "brgy_name": "Bagbag",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404007",
      "brgy_name": "Bagumbuhay",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404008",
      "brgy_name": "Bagong Lipunan Ng Crame",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404009",
      "brgy_name": "Bagong Pag-asa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404010",
      "brgy_name": "Bagong Silangan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404011",
      "brgy_name": "Bagumbayan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404012",
      "brgy_name": "Bahay Toro",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404013",
      "brgy_name": "Balingasa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404014",
      "brgy_name": "Bayanihan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404015",
      "brgy_name": "Blue Ridge A",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404016",
      "brgy_name": "Blue Ridge B",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404017",
      "brgy_name": "Botocan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404018",
      "brgy_name": "Bungad",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404019",
      "brgy_name": "Camp Aguinaldo",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404020",
      "brgy_name": "Central",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404021",
      "brgy_name": "Claro",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404022",
      "brgy_name": "Commonwealth",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404023",
      "brgy_name": "New Era (Constitution Hills)",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404024",
      "brgy_name": "Kristong Hari",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404025",
      "brgy_name": "Culiat",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404026",
      "brgy_name": "Damar",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404027",
      "brgy_name": "Damayan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404028",
      "brgy_name": "Damayang Lagi",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404029",
      "brgy_name": "Del Monte",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404030",
      "brgy_name": "Dioquino Zobel",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404031",
      "brgy_name": "Doña Imelda",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404032",
      "brgy_name": "Doña Josefa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404033",
      "brgy_name": "Don Manuel",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404034",
      "brgy_name": "Duyan-duyan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404035",
      "brgy_name": "E. Rodriguez",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404036",
      "brgy_name": "East Kamias",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404037",
      "brgy_name": "Escopa I",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404038",
      "brgy_name": "Escopa II",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404039",
      "brgy_name": "Escopa III",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404040",
      "brgy_name": "Escopa IV",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404041",
      "brgy_name": "Fairview",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404042",
      "brgy_name": "N.S. Amoranto (Gintong Silahis)",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404043",
      "brgy_name": "Gulod",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404044",
      "brgy_name": "Horseshoe",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404045",
      "brgy_name": "Immaculate Concepcion",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404046",
      "brgy_name": "Kaligayahan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404047",
      "brgy_name": "Kalusugan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404048",
      "brgy_name": "Kamuning",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404049",
      "brgy_name": "Katipunan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404050",
      "brgy_name": "Kaunlaran",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404051",
      "brgy_name": "Krus Na Ligas",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404052",
      "brgy_name": "Laging Handa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404053",
      "brgy_name": "Libis",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404054",
      "brgy_name": "Lourdes",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404055",
      "brgy_name": "Loyola Heights",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404056",
      "brgy_name": "Maharlika",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404057",
      "brgy_name": "Malaya",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404058",
      "brgy_name": "Manresa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404059",
      "brgy_name": "Mangga",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404060",
      "brgy_name": "Mariana",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404061",
      "brgy_name": "Mariblo",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404062",
      "brgy_name": "Marilag",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404063",
      "brgy_name": "Masagana",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404064",
      "brgy_name": "Masambong",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404065",
      "brgy_name": "Santo Domingo (Matalahib)",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404066",
      "brgy_name": "Matandang Balara",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404067",
      "brgy_name": "Milagrosa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404068",
      "brgy_name": "Nagkaisang Nayon",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404069",
      "brgy_name": "Nayong Kanluran",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404070",
      "brgy_name": "Novaliches Proper",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404071",
      "brgy_name": "Obrero",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404072",
      "brgy_name": "Old Capitol Site",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404073",
      "brgy_name": "Paang Bundok",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404074",
      "brgy_name": "Pag-ibig Sa Nayon",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404075",
      "brgy_name": "Paligsahan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404076",
      "brgy_name": "Paltok",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404077",
      "brgy_name": "Pansol",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404078",
      "brgy_name": "Paraiso",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404079",
      "brgy_name": "Pasong Putik Proper (Pasong Putik)",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404080",
      "brgy_name": "Pasong Tamo",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404081",
      "brgy_name": "Phil-Am",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404082",
      "brgy_name": "Pinyahan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404083",
      "brgy_name": "Pinagkaisahan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404084",
      "brgy_name": "Project 6",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404085",
      "brgy_name": "Quirino 2-A",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404086",
      "brgy_name": "Quirino 2-B",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404087",
      "brgy_name": "Quirino 2-C",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404088",
      "brgy_name": "Quirino 3-A",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404089",
      "brgy_name": "Ramon Magsaysay",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404090",
      "brgy_name": "Roxas",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404091",
      "brgy_name": "Sacred Heart",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404092",
      "brgy_name": "Saint Ignatius",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404093",
      "brgy_name": "Saint Peter",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404094",
      "brgy_name": "Salvacion",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404095",
      "brgy_name": "San Agustin",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404096",
      "brgy_name": "San Antonio",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404097",
      "brgy_name": "San Bartolome",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404098",
      "brgy_name": "San Isidro",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404099",
      "brgy_name": "San Isidro Labrador",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404100",
      "brgy_name": "San Jose",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404101",
      "brgy_name": "San Martin De Porres",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404102",
      "brgy_name": "San Roque",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404103",
      "brgy_name": "San Vicente",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404104",
      "brgy_name": "Santa Cruz",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404105",
      "brgy_name": "Santa Lucia",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404106",
      "brgy_name": "Santa Monica",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404107",
      "brgy_name": "Santa Teresita",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404108",
      "brgy_name": "Santo Cristo",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404109",
      "brgy_name": "Santo Niño",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404110",
      "brgy_name": "Santol",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404111",
      "brgy_name": "Sauyo",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404112",
      "brgy_name": "Sienna",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404113",
      "brgy_name": "Sikatuna Village",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404114",
      "brgy_name": "Silangan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404115",
      "brgy_name": "Socorro",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404116",
      "brgy_name": "South Triangle",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404117",
      "brgy_name": "Tagumpay",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404118",
      "brgy_name": "Talayan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404119",
      "brgy_name": "Talipapa",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404120",
      "brgy_name": "Tandang Sora",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404121",
      "brgy_name": "Tatalon",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404122",
      "brgy_name": "Teachers Village East",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404123",
      "brgy_name": "Teachers Village West",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404124",
      "brgy_name": "U.P. Campus",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404125",
      "brgy_name": "U.P. Village",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404126",
      "brgy_name": "Ugong Norte",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404127",
      "brgy_name": "Unang Sigaw",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404128",
      "brgy_name": "Valencia",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404129",
      "brgy_name": "Vasra",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404130",
      "brgy_name": "Veterans Village",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404131",
      "brgy_name": "Villa Maria Clara",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404132",
      "brgy_name": "West Kamias",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404133",
      "brgy_name": "West Triangle",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404134",
      "brgy_name": "White Plains",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404135",
      "brgy_name": "Balong Bato",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404136",
      "brgy_name": "Capri",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404137",
      "brgy_name": "Sangandaan",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404138",
      "brgy_name": "Payatas",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404139",
      "brgy_name": "Batasan Hills",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404140",
      "brgy_name": "Holy Spirit",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404141",
      "brgy_name": "Greater Lagro",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137404142",
      "brgy_name": "North Fairview",
      "city_code": "137404",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405001",
      "brgy_name": "Addition Hills",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405002",
      "brgy_name": "Balong-Bato",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405003",
      "brgy_name": "Batis",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405004",
      "brgy_name": "Corazon De Jesus",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405005",
      "brgy_name": "Ermitaño",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405006",
      "brgy_name": "Halo-halo (St. Joseph)",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405007",
      "brgy_name": "Isabelita",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405008",
      "brgy_name": "Kabayanan",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405009",
      "brgy_name": "Little Baguio",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405010",
      "brgy_name": "Maytunas",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405011",
      "brgy_name": "Onse",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405012",
      "brgy_name": "Pasadeña",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405013",
      "brgy_name": "Pedro Cruz",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405014",
      "brgy_name": "Progreso",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405015",
      "brgy_name": "Rivera",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405016",
      "brgy_name": "Salapan",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405017",
      "brgy_name": "San Perfecto",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405018",
      "brgy_name": "Santa Lucia",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405019",
      "brgy_name": "Tibagan",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405020",
      "brgy_name": "West Crame",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137405021",
      "brgy_name": "Greenhills",
      "city_code": "137405",
      "province_code": "1374",
      "region_code": "13"
    },
    {
      "brgy_code": "137501001",
      "brgy_name": "Barangay 1",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501002",
      "brgy_name": "Barangay 2",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501003",
      "brgy_name": "Barangay 3",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501004",
      "brgy_name": "Barangay 4",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501005",
      "brgy_name": "Barangay 5",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501006",
      "brgy_name": "Barangay 6",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501007",
      "brgy_name": "Barangay 7",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501008",
      "brgy_name": "Barangay 8",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501009",
      "brgy_name": "Barangay 9",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501010",
      "brgy_name": "Barangay 10",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501011",
      "brgy_name": "Barangay 11",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501012",
      "brgy_name": "Barangay 12",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501013",
      "brgy_name": "Barangay 13",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501014",
      "brgy_name": "Barangay 14",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501015",
      "brgy_name": "Barangay 15",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501016",
      "brgy_name": "Barangay 16",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501017",
      "brgy_name": "Barangay 17",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501018",
      "brgy_name": "Barangay 18",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501019",
      "brgy_name": "Barangay 19",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501020",
      "brgy_name": "Barangay 20",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501021",
      "brgy_name": "Barangay 21",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501022",
      "brgy_name": "Barangay 22",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501023",
      "brgy_name": "Barangay 23",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501024",
      "brgy_name": "Barangay 24",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501025",
      "brgy_name": "Barangay 25",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501026",
      "brgy_name": "Barangay 26",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501027",
      "brgy_name": "Barangay 27",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501028",
      "brgy_name": "Barangay 28",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501029",
      "brgy_name": "Barangay 29",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501030",
      "brgy_name": "Barangay 30",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501031",
      "brgy_name": "Barangay 31",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501032",
      "brgy_name": "Barangay 32",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501033",
      "brgy_name": "Barangay 33",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501034",
      "brgy_name": "Barangay 34",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501035",
      "brgy_name": "Barangay 35",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501036",
      "brgy_name": "Barangay 36",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501037",
      "brgy_name": "Barangay 37",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501038",
      "brgy_name": "Barangay 38",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501039",
      "brgy_name": "Barangay 39",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501040",
      "brgy_name": "Barangay 40",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501041",
      "brgy_name": "Barangay 41",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501042",
      "brgy_name": "Barangay 42",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501043",
      "brgy_name": "Barangay 43",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501044",
      "brgy_name": "Barangay 44",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501045",
      "brgy_name": "Barangay 45",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501046",
      "brgy_name": "Barangay 46",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501047",
      "brgy_name": "Barangay 47",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501048",
      "brgy_name": "Barangay 48",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501049",
      "brgy_name": "Barangay 49",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501050",
      "brgy_name": "Barangay 50",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501051",
      "brgy_name": "Barangay 51",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501052",
      "brgy_name": "Barangay 52",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501053",
      "brgy_name": "Barangay 53",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501054",
      "brgy_name": "Barangay 54",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501055",
      "brgy_name": "Barangay 55",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501056",
      "brgy_name": "Barangay 56",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501057",
      "brgy_name": "Barangay 57",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501058",
      "brgy_name": "Barangay 58",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501059",
      "brgy_name": "Barangay 59",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501060",
      "brgy_name": "Barangay 60",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501061",
      "brgy_name": "Barangay 61",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501062",
      "brgy_name": "Barangay 62",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501063",
      "brgy_name": "Barangay 63",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501064",
      "brgy_name": "Barangay 64",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501065",
      "brgy_name": "Barangay 65",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501066",
      "brgy_name": "Barangay 66",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501067",
      "brgy_name": "Barangay 67",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501068",
      "brgy_name": "Barangay 68",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501069",
      "brgy_name": "Barangay 69",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501070",
      "brgy_name": "Barangay 70",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501071",
      "brgy_name": "Barangay 71",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501072",
      "brgy_name": "Barangay 72",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501073",
      "brgy_name": "Barangay 73",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501074",
      "brgy_name": "Barangay 74",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501075",
      "brgy_name": "Barangay 75",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501076",
      "brgy_name": "Barangay 76",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501077",
      "brgy_name": "Barangay 77",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501078",
      "brgy_name": "Barangay 78",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501079",
      "brgy_name": "Barangay 79",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501080",
      "brgy_name": "Barangay 80",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501081",
      "brgy_name": "Barangay 81",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501082",
      "brgy_name": "Barangay 82",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501083",
      "brgy_name": "Barangay 83",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501084",
      "brgy_name": "Barangay 84",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501085",
      "brgy_name": "Barangay 85",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501086",
      "brgy_name": "Barangay 86",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501087",
      "brgy_name": "Barangay 87",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501088",
      "brgy_name": "Barangay 88",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501089",
      "brgy_name": "Barangay 89",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501090",
      "brgy_name": "Barangay 90",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501091",
      "brgy_name": "Barangay 91",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501092",
      "brgy_name": "Barangay 92",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501093",
      "brgy_name": "Barangay 93",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501094",
      "brgy_name": "Barangay 94",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501095",
      "brgy_name": "Barangay 95",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501096",
      "brgy_name": "Barangay 96",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501097",
      "brgy_name": "Barangay 97",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501098",
      "brgy_name": "Barangay 98",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501099",
      "brgy_name": "Barangay 99",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501100",
      "brgy_name": "Barangay 100",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501101",
      "brgy_name": "Barangay 101",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501102",
      "brgy_name": "Barangay 102",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501103",
      "brgy_name": "Barangay 103",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501104",
      "brgy_name": "Barangay 104",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501105",
      "brgy_name": "Barangay 105",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501106",
      "brgy_name": "Barangay 106",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501107",
      "brgy_name": "Barangay 107",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501108",
      "brgy_name": "Barangay 108",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501109",
      "brgy_name": "Barangay 109",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501110",
      "brgy_name": "Barangay 110",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501111",
      "brgy_name": "Barangay 111",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501112",
      "brgy_name": "Barangay 112",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501113",
      "brgy_name": "Barangay 113",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501114",
      "brgy_name": "Barangay 114",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501115",
      "brgy_name": "Barangay 115",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501116",
      "brgy_name": "Barangay 116",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501117",
      "brgy_name": "Barangay 117",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501118",
      "brgy_name": "Barangay 118",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501119",
      "brgy_name": "Barangay 119",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501120",
      "brgy_name": "Barangay 120",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501121",
      "brgy_name": "Barangay 121",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501122",
      "brgy_name": "Barangay 122",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501123",
      "brgy_name": "Barangay 123",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501124",
      "brgy_name": "Barangay 124",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501125",
      "brgy_name": "Barangay 125",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501126",
      "brgy_name": "Barangay 126",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501127",
      "brgy_name": "Barangay 127",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501128",
      "brgy_name": "Barangay 128",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501129",
      "brgy_name": "Barangay 129",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501130",
      "brgy_name": "Barangay 130",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501131",
      "brgy_name": "Barangay 131",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501132",
      "brgy_name": "Barangay 132",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501133",
      "brgy_name": "Barangay 133",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501134",
      "brgy_name": "Barangay 134",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501135",
      "brgy_name": "Barangay 135",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501136",
      "brgy_name": "Barangay 136",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501137",
      "brgy_name": "Barangay 137",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501138",
      "brgy_name": "Barangay 138",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501139",
      "brgy_name": "Barangay 139",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501140",
      "brgy_name": "Barangay 140",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501141",
      "brgy_name": "Barangay 141",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501142",
      "brgy_name": "Barangay 142",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501143",
      "brgy_name": "Barangay 143",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501144",
      "brgy_name": "Barangay 144",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501145",
      "brgy_name": "Barangay 145",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501146",
      "brgy_name": "Barangay 146",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501147",
      "brgy_name": "Barangay 147",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501148",
      "brgy_name": "Barangay 148",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501149",
      "brgy_name": "Barangay 149",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501150",
      "brgy_name": "Barangay 150",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501151",
      "brgy_name": "Barangay 151",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501152",
      "brgy_name": "Barangay 152",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501153",
      "brgy_name": "Barangay 153",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501154",
      "brgy_name": "Barangay 154",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501155",
      "brgy_name": "Barangay 155",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501156",
      "brgy_name": "Barangay 156",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501157",
      "brgy_name": "Barangay 157",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501158",
      "brgy_name": "Barangay 158",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501159",
      "brgy_name": "Barangay 159",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501160",
      "brgy_name": "Barangay 160",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501161",
      "brgy_name": "Barangay 161",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501162",
      "brgy_name": "Barangay 162",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501163",
      "brgy_name": "Barangay 163",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501164",
      "brgy_name": "Barangay 164",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501165",
      "brgy_name": "Barangay 165",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501166",
      "brgy_name": "Barangay 166",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501167",
      "brgy_name": "Barangay 167",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501168",
      "brgy_name": "Barangay 168",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501169",
      "brgy_name": "Barangay 169",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501170",
      "brgy_name": "Barangay 170",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501171",
      "brgy_name": "Barangay 171",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501172",
      "brgy_name": "Barangay 172",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501173",
      "brgy_name": "Barangay 173",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501174",
      "brgy_name": "Barangay 174",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501175",
      "brgy_name": "Barangay 175",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501176",
      "brgy_name": "Barangay 176",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501177",
      "brgy_name": "Barangay 177",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501178",
      "brgy_name": "Barangay 178",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501179",
      "brgy_name": "Barangay 179",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501180",
      "brgy_name": "Barangay 180",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501181",
      "brgy_name": "Barangay 181",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501182",
      "brgy_name": "Barangay 182",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501183",
      "brgy_name": "Barangay 183",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501184",
      "brgy_name": "Barangay 184",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501185",
      "brgy_name": "Barangay 185",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501186",
      "brgy_name": "Barangay 186",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501187",
      "brgy_name": "Barangay 187",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137501188",
      "brgy_name": "Barangay 188",
      "city_code": "137501",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502001",
      "brgy_name": "Acacia",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502002",
      "brgy_name": "Baritan",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502003",
      "brgy_name": "Bayan-bayanan",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502004",
      "brgy_name": "Catmon",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502005",
      "brgy_name": "Concepcion",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502006",
      "brgy_name": "Dampalit",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502007",
      "brgy_name": "Flores",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502008",
      "brgy_name": "Hulong Duhat",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502009",
      "brgy_name": "Ibaba",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502010",
      "brgy_name": "Longos",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502011",
      "brgy_name": "Maysilo",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502012",
      "brgy_name": "Muzon",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502013",
      "brgy_name": "Niugan",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502014",
      "brgy_name": "Panghulo",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502015",
      "brgy_name": "Potrero",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502016",
      "brgy_name": "San Agustin",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502017",
      "brgy_name": "Santolan",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502018",
      "brgy_name": "Tañong (Pob.)",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502019",
      "brgy_name": "Tinajeros",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502020",
      "brgy_name": "Tonsuya",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137502021",
      "brgy_name": "Tugatog",
      "city_code": "137502",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503001",
      "brgy_name": "Sipac-Almacen",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503002",
      "brgy_name": "Bagumbayan North",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503003",
      "brgy_name": "Bagumbayan South",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503004",
      "brgy_name": "Bangculasi",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503005",
      "brgy_name": "Daanghari",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503006",
      "brgy_name": "Navotas East",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503007",
      "brgy_name": "Navotas West",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503008",
      "brgy_name": "North Bay Blvd., North",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503009",
      "brgy_name": "North Bay Blvd., South",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503010",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503011",
      "brgy_name": "San Rafael Village",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503012",
      "brgy_name": "San Roque",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503013",
      "brgy_name": "Tangos",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137503014",
      "brgy_name": "Tanza",
      "city_code": "137503",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504001",
      "brgy_name": "Arkong Bato",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504002",
      "brgy_name": "Bagbaguin",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504003",
      "brgy_name": "Balangkas",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504004",
      "brgy_name": "Parada",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504005",
      "brgy_name": "Bignay",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504006",
      "brgy_name": "Bisig",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504007",
      "brgy_name": "Canumay West (Canumay)",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504008",
      "brgy_name": "Karuhatan",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504009",
      "brgy_name": "Coloong",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504010",
      "brgy_name": "Dalandanan",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504011",
      "brgy_name": "Hen. T. De Leon",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504012",
      "brgy_name": "Isla",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504013",
      "brgy_name": "Lawang Bato",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504014",
      "brgy_name": "Lingunan",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504015",
      "brgy_name": "Mabolo",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504016",
      "brgy_name": "Malanday",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504017",
      "brgy_name": "Malinta",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504018",
      "brgy_name": "Mapulang Lupa",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504019",
      "brgy_name": "Marulas",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504020",
      "brgy_name": "Maysan",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504021",
      "brgy_name": "Palasan",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504022",
      "brgy_name": "Pariancillo Villa",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504023",
      "brgy_name": "Paso De Blas",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504024",
      "brgy_name": "Pasolo",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504025",
      "brgy_name": "Poblacion",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504026",
      "brgy_name": "Pulo",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504027",
      "brgy_name": "Punturin",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504028",
      "brgy_name": "Rincon",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504029",
      "brgy_name": "Tagalag",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504030",
      "brgy_name": "Ugong",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504031",
      "brgy_name": "Viente Reales",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504032",
      "brgy_name": "Wawang Pulo",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137504033",
      "brgy_name": "Canumay East (Canumay)",
      "city_code": "137504",
      "province_code": "1375",
      "region_code": "13"
    },
    {
      "brgy_code": "137601001",
      "brgy_name": "Almanza Uno",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601002",
      "brgy_name": "Daniel Fajardo",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601003",
      "brgy_name": "Elias Aldana",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601004",
      "brgy_name": "Ilaya",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601005",
      "brgy_name": "Manuyo Uno",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601006",
      "brgy_name": "Pamplona Uno",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601007",
      "brgy_name": "Pulang Lupa Uno",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601008",
      "brgy_name": "Talon Uno",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601009",
      "brgy_name": "Zapote",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601010",
      "brgy_name": "Almanza Dos",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601011",
      "brgy_name": "B. F. International Village",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601012",
      "brgy_name": "Manuyo Dos",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601013",
      "brgy_name": "Pamplona Dos",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601014",
      "brgy_name": "Pamplona Tres",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601015",
      "brgy_name": "Pilar",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601016",
      "brgy_name": "Pulang Lupa Dos",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601017",
      "brgy_name": "Talon Dos",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601018",
      "brgy_name": "Talon Tres",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601019",
      "brgy_name": "Talon Kuatro",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137601020",
      "brgy_name": "Talon Singko",
      "city_code": "137601",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602001",
      "brgy_name": "Bangkal",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602002",
      "brgy_name": "Bel-Air",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602003",
      "brgy_name": "Cembo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602004",
      "brgy_name": "Comembo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602005",
      "brgy_name": "Carmona",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602006",
      "brgy_name": "Dasmariñas",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602007",
      "brgy_name": "East Rembo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602008",
      "brgy_name": "Forbes Park",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602009",
      "brgy_name": "Guadalupe Nuevo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602010",
      "brgy_name": "Guadalupe Viejo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602011",
      "brgy_name": "Kasilawan",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602012",
      "brgy_name": "La Paz",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602013",
      "brgy_name": "Magallanes",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602014",
      "brgy_name": "Olympia",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602015",
      "brgy_name": "Palanan",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602016",
      "brgy_name": "Pembo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602017",
      "brgy_name": "Pinagkaisahan",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602018",
      "brgy_name": "Pio Del Pilar",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602019",
      "brgy_name": "Pitogo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602020",
      "brgy_name": "Poblacion",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602021",
      "brgy_name": "Post Proper Northside",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602022",
      "brgy_name": "Post Proper Southside",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602023",
      "brgy_name": "San Antonio",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602024",
      "brgy_name": "San Isidro",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602025",
      "brgy_name": "San Lorenzo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602026",
      "brgy_name": "Santa Cruz",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602027",
      "brgy_name": "Singkamas",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602028",
      "brgy_name": "South Cembo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602029",
      "brgy_name": "Tejeros",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602030",
      "brgy_name": "Urdaneta",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602031",
      "brgy_name": "Valenzuela",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602032",
      "brgy_name": "West Rembo",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137602033",
      "brgy_name": "Rizal",
      "city_code": "137602",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603001",
      "brgy_name": "Alabang",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603002",
      "brgy_name": "Bayanan",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603003",
      "brgy_name": "Buli",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603004",
      "brgy_name": "Cupang",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603005",
      "brgy_name": "Poblacion",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603006",
      "brgy_name": "Putatan",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603007",
      "brgy_name": "Sucat",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603008",
      "brgy_name": "Tunasan",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137603009",
      "brgy_name": "New Alabang Village",
      "city_code": "137603",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604001",
      "brgy_name": "Baclaran",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604002",
      "brgy_name": "Don Galo",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604003",
      "brgy_name": "La Huerta",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604004",
      "brgy_name": "San Dionisio",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604005",
      "brgy_name": "Santo Niño",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604006",
      "brgy_name": "Tambo",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604007",
      "brgy_name": "B. F. Homes",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604008",
      "brgy_name": "Don Bosco",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604009",
      "brgy_name": "Marcelo Green Village",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604010",
      "brgy_name": "Merville",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604011",
      "brgy_name": "Moonwalk",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604012",
      "brgy_name": "San Antonio",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604013",
      "brgy_name": "San Isidro",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604014",
      "brgy_name": "San Martin De Porres",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604015",
      "brgy_name": "Sun Valley",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137604016",
      "brgy_name": "Vitalez",
      "city_code": "137604",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605001",
      "brgy_name": "Barangay 1",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605002",
      "brgy_name": "Barangay 2",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605003",
      "brgy_name": "Barangay 3",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605004",
      "brgy_name": "Barangay 4",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605005",
      "brgy_name": "Barangay 5",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605006",
      "brgy_name": "Barangay 6",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605007",
      "brgy_name": "Barangay 7",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605008",
      "brgy_name": "Barangay 8",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605009",
      "brgy_name": "Barangay 9",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605010",
      "brgy_name": "Barangay 10",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605011",
      "brgy_name": "Barangay 11",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605012",
      "brgy_name": "Barangay 12",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605013",
      "brgy_name": "Barangay 13",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605014",
      "brgy_name": "Barangay 14",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605015",
      "brgy_name": "Barangay 15",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605016",
      "brgy_name": "Barangay 16",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605017",
      "brgy_name": "Barangay 17",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605018",
      "brgy_name": "Barangay 18",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605019",
      "brgy_name": "Barangay 19",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605020",
      "brgy_name": "Barangay 20",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605021",
      "brgy_name": "Barangay 21",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605022",
      "brgy_name": "Barangay 22",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605023",
      "brgy_name": "Barangay 23",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605024",
      "brgy_name": "Barangay 24",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605025",
      "brgy_name": "Barangay 25",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605026",
      "brgy_name": "Barangay 26",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605027",
      "brgy_name": "Barangay 27",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605028",
      "brgy_name": "Barangay 28",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605029",
      "brgy_name": "Barangay 29",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605030",
      "brgy_name": "Barangay 30",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605031",
      "brgy_name": "Barangay 31",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605032",
      "brgy_name": "Barangay 32",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605033",
      "brgy_name": "Barangay 33",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605034",
      "brgy_name": "Barangay 34",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605035",
      "brgy_name": "Barangay 35",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605036",
      "brgy_name": "Barangay 36",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605037",
      "brgy_name": "Barangay 37",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605038",
      "brgy_name": "Barangay 38",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605039",
      "brgy_name": "Barangay 39",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605040",
      "brgy_name": "Barangay 40",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605041",
      "brgy_name": "Barangay 41",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605042",
      "brgy_name": "Barangay 42",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605043",
      "brgy_name": "Barangay 43",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605044",
      "brgy_name": "Barangay 44",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605045",
      "brgy_name": "Barangay 45",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605046",
      "brgy_name": "Barangay 46",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605047",
      "brgy_name": "Barangay 47",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605048",
      "brgy_name": "Barangay 48",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605049",
      "brgy_name": "Barangay 49",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605050",
      "brgy_name": "Barangay 50",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605051",
      "brgy_name": "Barangay 51",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605052",
      "brgy_name": "Barangay 52",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605053",
      "brgy_name": "Barangay 53",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605054",
      "brgy_name": "Barangay 54",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605055",
      "brgy_name": "Barangay 55",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605056",
      "brgy_name": "Barangay 56",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605057",
      "brgy_name": "Barangay 57",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605058",
      "brgy_name": "Barangay 58",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605059",
      "brgy_name": "Barangay 59",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605060",
      "brgy_name": "Barangay 60",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605061",
      "brgy_name": "Barangay 61",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605062",
      "brgy_name": "Barangay 62",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605063",
      "brgy_name": "Barangay 63",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605064",
      "brgy_name": "Barangay 64",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605065",
      "brgy_name": "Barangay 65",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605066",
      "brgy_name": "Barangay 66",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605067",
      "brgy_name": "Barangay 67",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605068",
      "brgy_name": "Barangay 68",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605069",
      "brgy_name": "Barangay 69",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605070",
      "brgy_name": "Barangay 70",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605071",
      "brgy_name": "Barangay 71",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605072",
      "brgy_name": "Barangay 72",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605073",
      "brgy_name": "Barangay 73",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605074",
      "brgy_name": "Barangay 74",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605075",
      "brgy_name": "Barangay 75",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605076",
      "brgy_name": "Barangay 76",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605077",
      "brgy_name": "Barangay 77",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605078",
      "brgy_name": "Barangay 78",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605079",
      "brgy_name": "Barangay 79",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605080",
      "brgy_name": "Barangay 80",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605081",
      "brgy_name": "Barangay 81",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605082",
      "brgy_name": "Barangay 82",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605083",
      "brgy_name": "Barangay 83",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605084",
      "brgy_name": "Barangay 84",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605085",
      "brgy_name": "Barangay 85",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605086",
      "brgy_name": "Barangay 86",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605087",
      "brgy_name": "Barangay 87",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605088",
      "brgy_name": "Barangay 88",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605089",
      "brgy_name": "Barangay 89",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605090",
      "brgy_name": "Barangay 90",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605091",
      "brgy_name": "Barangay 91",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605092",
      "brgy_name": "Barangay 92",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605093",
      "brgy_name": "Barangay 93",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605094",
      "brgy_name": "Barangay 94",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605095",
      "brgy_name": "Barangay 95",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605096",
      "brgy_name": "Barangay 96",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605097",
      "brgy_name": "Barangay 97",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605098",
      "brgy_name": "Barangay 98",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605099",
      "brgy_name": "Barangay 99",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605100",
      "brgy_name": "Barangay 100",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605101",
      "brgy_name": "Barangay 101",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605102",
      "brgy_name": "Barangay 102",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605103",
      "brgy_name": "Barangay 103",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605104",
      "brgy_name": "Barangay 104",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605105",
      "brgy_name": "Barangay 105",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605106",
      "brgy_name": "Barangay 106",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605107",
      "brgy_name": "Barangay 107",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605108",
      "brgy_name": "Barangay 108",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605109",
      "brgy_name": "Barangay 109",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605110",
      "brgy_name": "Barangay 110",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605111",
      "brgy_name": "Barangay 111",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605112",
      "brgy_name": "Barangay 112",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605113",
      "brgy_name": "Barangay 113",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605114",
      "brgy_name": "Barangay 114",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605115",
      "brgy_name": "Barangay 115",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605116",
      "brgy_name": "Barangay 116",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605117",
      "brgy_name": "Barangay 117",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605118",
      "brgy_name": "Barangay 118",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605119",
      "brgy_name": "Barangay 119",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605120",
      "brgy_name": "Barangay 120",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605121",
      "brgy_name": "Barangay 121",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605122",
      "brgy_name": "Barangay 122",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605123",
      "brgy_name": "Barangay 123",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605124",
      "brgy_name": "Barangay 124",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605125",
      "brgy_name": "Barangay 125",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605126",
      "brgy_name": "Barangay 126",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605127",
      "brgy_name": "Barangay 127",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605128",
      "brgy_name": "Barangay 128",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605129",
      "brgy_name": "Barangay 129",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605130",
      "brgy_name": "Barangay 130",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605131",
      "brgy_name": "Barangay 131",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605132",
      "brgy_name": "Barangay 132",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605133",
      "brgy_name": "Barangay 133",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605134",
      "brgy_name": "Barangay 134",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605135",
      "brgy_name": "Barangay 135",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605136",
      "brgy_name": "Barangay 136",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605137",
      "brgy_name": "Barangay 137",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605138",
      "brgy_name": "Barangay 138",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605139",
      "brgy_name": "Barangay 139",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605140",
      "brgy_name": "Barangay 140",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605141",
      "brgy_name": "Barangay 141",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605142",
      "brgy_name": "Barangay 142",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605143",
      "brgy_name": "Barangay 143",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605144",
      "brgy_name": "Barangay 144",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605145",
      "brgy_name": "Barangay 145",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605146",
      "brgy_name": "Barangay 146",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605147",
      "brgy_name": "Barangay 147",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605148",
      "brgy_name": "Barangay 148",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605149",
      "brgy_name": "Barangay 149",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605150",
      "brgy_name": "Barangay 150",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605151",
      "brgy_name": "Barangay 151",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605152",
      "brgy_name": "Barangay 152",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605153",
      "brgy_name": "Barangay 153",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605154",
      "brgy_name": "Barangay 154",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605155",
      "brgy_name": "Barangay 155",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605156",
      "brgy_name": "Barangay 156",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605157",
      "brgy_name": "Barangay 157",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605158",
      "brgy_name": "Barangay 158",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605159",
      "brgy_name": "Barangay 159",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605160",
      "brgy_name": "Barangay 160",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605161",
      "brgy_name": "Barangay 161",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605162",
      "brgy_name": "Barangay 162",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605163",
      "brgy_name": "Barangay 163",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605164",
      "brgy_name": "Barangay 164",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605165",
      "brgy_name": "Barangay 165",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605166",
      "brgy_name": "Barangay 166",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605167",
      "brgy_name": "Barangay 167",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605168",
      "brgy_name": "Barangay 168",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605169",
      "brgy_name": "Barangay 169",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605170",
      "brgy_name": "Barangay 170",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605171",
      "brgy_name": "Barangay 171",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605172",
      "brgy_name": "Barangay 172",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605173",
      "brgy_name": "Barangay 173",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605174",
      "brgy_name": "Barangay 174",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605175",
      "brgy_name": "Barangay 175",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605176",
      "brgy_name": "Barangay 176",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605177",
      "brgy_name": "Barangay 177",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605178",
      "brgy_name": "Barangay 178",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605179",
      "brgy_name": "Barangay 179",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605180",
      "brgy_name": "Barangay 180",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605181",
      "brgy_name": "Barangay 181",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605182",
      "brgy_name": "Barangay 182",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605183",
      "brgy_name": "Barangay 183",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605184",
      "brgy_name": "Barangay 184",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605185",
      "brgy_name": "Barangay 185",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605186",
      "brgy_name": "Barangay 186",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605187",
      "brgy_name": "Barangay 187",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605188",
      "brgy_name": "Barangay 188",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605189",
      "brgy_name": "Barangay 189",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605190",
      "brgy_name": "Barangay 190",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605191",
      "brgy_name": "Barangay 191",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605192",
      "brgy_name": "Barangay 192",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605193",
      "brgy_name": "Barangay 193",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605194",
      "brgy_name": "Barangay 194",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605195",
      "brgy_name": "Barangay 195",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605196",
      "brgy_name": "Barangay 196",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605197",
      "brgy_name": "Barangay 197",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605198",
      "brgy_name": "Barangay 198",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605199",
      "brgy_name": "Barangay 199",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605200",
      "brgy_name": "Barangay 200",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137605201",
      "brgy_name": "Barangay 201",
      "city_code": "137605",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606001",
      "brgy_name": "Aguho",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606002",
      "brgy_name": "Magtanggol",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606003",
      "brgy_name": "Martires Del 96",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606004",
      "brgy_name": "Poblacion",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606005",
      "brgy_name": "San Pedro",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606006",
      "brgy_name": "San Roque",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606007",
      "brgy_name": "Santa Ana",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606008",
      "brgy_name": "Santo Rosario-Kanluran",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606009",
      "brgy_name": "Santo Rosario-Silangan",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137606010",
      "brgy_name": "Tabacalera",
      "city_code": "137606",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607001",
      "brgy_name": "Tanyag (Bagong Tanyag)",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607002",
      "brgy_name": "Bagumbayan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607003",
      "brgy_name": "Bambang",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607004",
      "brgy_name": "Calzada",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607005",
      "brgy_name": "Hagonoy",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607006",
      "brgy_name": "Ibayo-Tipas",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607007",
      "brgy_name": "Ligid-Tipas",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607008",
      "brgy_name": "Lower Bicutan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607009",
      "brgy_name": "Maharlika Village",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607010",
      "brgy_name": "Napindan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607011",
      "brgy_name": "Palingon",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607012",
      "brgy_name": "Santa Ana",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607013",
      "brgy_name": "Central Signal Village (Signal Village)",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607014",
      "brgy_name": "Tuktukan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607015",
      "brgy_name": "Upper Bicutan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607016",
      "brgy_name": "Ususan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607017",
      "brgy_name": "Wawa",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607018",
      "brgy_name": "Western Bicutan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607019",
      "brgy_name": "Central Bicutan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607020",
      "brgy_name": "Fort Bonifacio",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607021",
      "brgy_name": "Katuparan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607022",
      "brgy_name": "New Lower Bicutan",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607023",
      "brgy_name": "North Daang Hari",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607024",
      "brgy_name": "North Signal Village",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607025",
      "brgy_name": "Pinagsama",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607026",
      "brgy_name": "San Miguel",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607027",
      "brgy_name": "South Daang Hari",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "137607028",
      "brgy_name": "South Signal Village",
      "city_code": "137607",
      "province_code": "1376",
      "region_code": "13"
    },
    {
      "brgy_code": "140101001",
      "brgy_name": "Agtangao",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101002",
      "brgy_name": "Angad",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101003",
      "brgy_name": "Bañacao",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101004",
      "brgy_name": "Bangbangar",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101005",
      "brgy_name": "Cabuloan",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101006",
      "brgy_name": "Calaba",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101007",
      "brgy_name": "Tablac (Calot)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101008",
      "brgy_name": "Cosili West (Buaya)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101009",
      "brgy_name": "Cosili East (Proper)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101010",
      "brgy_name": "Dangdangla",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101011",
      "brgy_name": "Lingtan",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101012",
      "brgy_name": "Lipcan",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101013",
      "brgy_name": "Lubong",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101014",
      "brgy_name": "Macarcarmay",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101015",
      "brgy_name": "Maoay",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101016",
      "brgy_name": "Macray",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101017",
      "brgy_name": "Malita",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101018",
      "brgy_name": "Palao",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101019",
      "brgy_name": "Patucannay",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101020",
      "brgy_name": "Sagap",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101021",
      "brgy_name": "San Antonio",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101022",
      "brgy_name": "Santa Rosa",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101023",
      "brgy_name": "Sao-atan",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101024",
      "brgy_name": "Sappaac",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101025",
      "brgy_name": "Zone 2 Pob. (Consiliman)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101026",
      "brgy_name": "Zone 3 Pob. (Lalaud)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101027",
      "brgy_name": "Zone 4 Pob. (Town Proper)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101028",
      "brgy_name": "Zone 5 Pob. (Bo. Barikir)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101029",
      "brgy_name": "Zone 6 Pob. (Sinapangan)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101030",
      "brgy_name": "Zone 7 Pob. (Baliling)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140101031",
      "brgy_name": "Zone 1 Pob. (Nalasin)",
      "city_code": "140101",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102001",
      "brgy_name": "Amti",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102002",
      "brgy_name": "Bao-yan",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102003",
      "brgy_name": "Danac East",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102004",
      "brgy_name": "Dao-angan",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102005",
      "brgy_name": "Dumagas",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102006",
      "brgy_name": "Kilong-Olao",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102007",
      "brgy_name": "Poblacion (Boliney)",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140102008",
      "brgy_name": "Danac West",
      "city_code": "140102",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103001",
      "brgy_name": "Abang",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103002",
      "brgy_name": "Bangbangcag",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103003",
      "brgy_name": "Bangcagan",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103004",
      "brgy_name": "Banglolao",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103005",
      "brgy_name": "Bugbog",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103006",
      "brgy_name": "Calao",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103007",
      "brgy_name": "Dugong",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103008",
      "brgy_name": "Labon",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103009",
      "brgy_name": "Layugan",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103010",
      "brgy_name": "Madalipay",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103011",
      "brgy_name": "Pagala",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103012",
      "brgy_name": "Palaquio",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103013",
      "brgy_name": "Pakiling",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103014",
      "brgy_name": "Patoc",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103016",
      "brgy_name": "North Poblacion",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103017",
      "brgy_name": "South Poblacion",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103018",
      "brgy_name": "Quimloong",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103019",
      "brgy_name": "Salnec",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103020",
      "brgy_name": "San Miguel",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103021",
      "brgy_name": "Siblong",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140103022",
      "brgy_name": "Tabiog",
      "city_code": "140103",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140104001",
      "brgy_name": "Ducligan",
      "city_code": "140104",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140104002",
      "brgy_name": "Labaan",
      "city_code": "140104",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140104003",
      "brgy_name": "Lingay",
      "city_code": "140104",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140104004",
      "brgy_name": "Lamao (Pob.)",
      "city_code": "140104",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140105001",
      "brgy_name": "Ableg",
      "city_code": "140105",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140105002",
      "brgy_name": "Cabaruyan",
      "city_code": "140105",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140105003",
      "brgy_name": "Pikek",
      "city_code": "140105",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140105004",
      "brgy_name": "Tui (Pob.)",
      "city_code": "140105",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140106001",
      "brgy_name": "Abaquid",
      "city_code": "140106",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140106003",
      "brgy_name": "Cabaruan",
      "city_code": "140106",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140106004",
      "brgy_name": "Caupasan (Pob.)",
      "city_code": "140106",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140106005",
      "brgy_name": "Danglas",
      "city_code": "140106",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140106006",
      "brgy_name": "Nagaparan",
      "city_code": "140106",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140106007",
      "brgy_name": "Padangitan",
      "city_code": "140106",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140106008",
      "brgy_name": "Pangal",
      "city_code": "140106",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107001",
      "brgy_name": "Bayaan",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107002",
      "brgy_name": "Cabaroan",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107003",
      "brgy_name": "Calumbaya",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107004",
      "brgy_name": "Cardona",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107005",
      "brgy_name": "Isit",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107006",
      "brgy_name": "Kimmalaba",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107007",
      "brgy_name": "Libtec",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107008",
      "brgy_name": "Lub-lubba",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107009",
      "brgy_name": "Mudiit",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107010",
      "brgy_name": "Namit-ingan",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107011",
      "brgy_name": "Pacac",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107012",
      "brgy_name": "Poblacion",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107013",
      "brgy_name": "Salucag",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107014",
      "brgy_name": "Talogtog",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140107015",
      "brgy_name": "Taping",
      "city_code": "140107",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108002",
      "brgy_name": "Benben (Bonbon)",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108003",
      "brgy_name": "Bulbulala",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108004",
      "brgy_name": "Buli",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108005",
      "brgy_name": "Canan (Gapan)",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108006",
      "brgy_name": "Liguis",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108007",
      "brgy_name": "Malabbaga",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108008",
      "brgy_name": "Mudeng",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108009",
      "brgy_name": "Pidipid",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108010",
      "brgy_name": "Poblacion",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108011",
      "brgy_name": "San Gregorio",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108012",
      "brgy_name": "Toon",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140108013",
      "brgy_name": "Udangan",
      "city_code": "140108",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140109001",
      "brgy_name": "Bacag",
      "city_code": "140109",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140109002",
      "brgy_name": "Buneg",
      "city_code": "140109",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140109003",
      "brgy_name": "Guinguinabang",
      "city_code": "140109",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140109004",
      "brgy_name": "Lan-ag",
      "city_code": "140109",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140109005",
      "brgy_name": "Pacoc",
      "city_code": "140109",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140109006",
      "brgy_name": "Poblacion (Talampac)",
      "city_code": "140109",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110001",
      "brgy_name": "Aguet",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110002",
      "brgy_name": "Bacooc",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110003",
      "brgy_name": "Balais",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110004",
      "brgy_name": "Cayapa",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110005",
      "brgy_name": "Dalaguisen",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110006",
      "brgy_name": "Laang",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110007",
      "brgy_name": "Lagben",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110008",
      "brgy_name": "Laguiben",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110009",
      "brgy_name": "Nagtipulan",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110010",
      "brgy_name": "Nagtupacan",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110011",
      "brgy_name": "Paganao",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110012",
      "brgy_name": "Pawa",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110013",
      "brgy_name": "Poblacion",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110014",
      "brgy_name": "Presentar",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110015",
      "brgy_name": "San Isidro",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110016",
      "brgy_name": "Tagodtod",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140110017",
      "brgy_name": "Taping",
      "city_code": "140110",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140111002",
      "brgy_name": "Ba-i",
      "city_code": "140111",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140111004",
      "brgy_name": "Collago",
      "city_code": "140111",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140111005",
      "brgy_name": "Pang-ot",
      "city_code": "140111",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140111006",
      "brgy_name": "Poblacion",
      "city_code": "140111",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140111007",
      "brgy_name": "Pulot",
      "city_code": "140111",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140112001",
      "brgy_name": "Baac",
      "city_code": "140112",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140112004",
      "brgy_name": "Dalayap (Nalaas)",
      "city_code": "140112",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140112005",
      "brgy_name": "Mabungtot",
      "city_code": "140112",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140112006",
      "brgy_name": "Malapaao",
      "city_code": "140112",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140112007",
      "brgy_name": "Poblacion",
      "city_code": "140112",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140112008",
      "brgy_name": "Quillat",
      "city_code": "140112",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113001",
      "brgy_name": "Bonglo (Patagui)",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113002",
      "brgy_name": "Bulbulala",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113003",
      "brgy_name": "Cawayan",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113004",
      "brgy_name": "Domenglay",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113005",
      "brgy_name": "Lenneng",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113006",
      "brgy_name": "Mapisla",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113007",
      "brgy_name": "Mogao",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113008",
      "brgy_name": "Nalbuan",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113009",
      "brgy_name": "Poblacion",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113010",
      "brgy_name": "Subagan",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140113011",
      "brgy_name": "Tumalip",
      "city_code": "140113",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114001",
      "brgy_name": "Ampalioc",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114002",
      "brgy_name": "Barit",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114003",
      "brgy_name": "Gayaman",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114005",
      "brgy_name": "Lul-luno",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114006",
      "brgy_name": "Luzong",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114007",
      "brgy_name": "Nagbukel-Tuquipa",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114008",
      "brgy_name": "Poblacion",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140114009",
      "brgy_name": "Sabnangan",
      "city_code": "140114",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115001",
      "brgy_name": "Bayabas",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115002",
      "brgy_name": "Binasaran",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115003",
      "brgy_name": "Buanao",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115004",
      "brgy_name": "Dulao",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115005",
      "brgy_name": "Duldulao",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115006",
      "brgy_name": "Gacab",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115007",
      "brgy_name": "Lat-ey",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115008",
      "brgy_name": "Malibcong (Pob.)",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115009",
      "brgy_name": "Mataragan",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115010",
      "brgy_name": "Pacgued",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115011",
      "brgy_name": "Taripan",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140115012",
      "brgy_name": "Umnap",
      "city_code": "140115",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116002",
      "brgy_name": "Catacdegan Viejo",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116003",
      "brgy_name": "Luzong",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116004",
      "brgy_name": "Ayyeng (Pob.)",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116005",
      "brgy_name": "San Jose Norte",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116006",
      "brgy_name": "San Jose Sur",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116007",
      "brgy_name": "San Juan Norte",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116008",
      "brgy_name": "San Juan Sur",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116011",
      "brgy_name": "San Ramon East",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116012",
      "brgy_name": "San Ramon West",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116013",
      "brgy_name": "Santo Tomas",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140116014",
      "brgy_name": "Catacdegan Nuevo",
      "city_code": "140116",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117001",
      "brgy_name": "Dumayco",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117002",
      "brgy_name": "Lusuac",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117003",
      "brgy_name": "Namarabar",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117004",
      "brgy_name": "Patiao",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117005",
      "brgy_name": "Malamsit (Pau-Malamsit)",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117006",
      "brgy_name": "Poblacion",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117007",
      "brgy_name": "Riang (Tiang)",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117008",
      "brgy_name": "Santa Rosa",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140117009",
      "brgy_name": "Tattawa",
      "city_code": "140117",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118001",
      "brgy_name": "Alinaya",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118002",
      "brgy_name": "Arab",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118004",
      "brgy_name": "Garreta",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118005",
      "brgy_name": "Immuli",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118007",
      "brgy_name": "Laskig",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118008",
      "brgy_name": "Naguirayan",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118009",
      "brgy_name": "Monggoc",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118010",
      "brgy_name": "Pamutic",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118011",
      "brgy_name": "Pangtud",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118012",
      "brgy_name": "Poblacion East",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118013",
      "brgy_name": "Poblacion West",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118014",
      "brgy_name": "San Diego",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118016",
      "brgy_name": "Sulbec",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118018",
      "brgy_name": "Suyo (Malidong)",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140118019",
      "brgy_name": "Yuyeng",
      "city_code": "140118",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119002",
      "brgy_name": "Bolbolo",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119003",
      "brgy_name": "Brookside",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119004",
      "brgy_name": "Ocup",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119005",
      "brgy_name": "Dalit",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119006",
      "brgy_name": "Dintan",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119007",
      "brgy_name": "Gapang",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119008",
      "brgy_name": "Kinabiti",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119009",
      "brgy_name": "Maliplipit",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119010",
      "brgy_name": "Nagcanasan",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119011",
      "brgy_name": "Nanangduan",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119012",
      "brgy_name": "Narnara",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119014",
      "brgy_name": "Pang-ot",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119015",
      "brgy_name": "Patad",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119016",
      "brgy_name": "Poblacion",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119017",
      "brgy_name": "San Juan East",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119018",
      "brgy_name": "San Juan West",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119019",
      "brgy_name": "South Balioag",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119020",
      "brgy_name": "Tikitik",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140119021",
      "brgy_name": "Villavieja",
      "city_code": "140119",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120001",
      "brgy_name": "Bazar",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120002",
      "brgy_name": "Bilabila",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120003",
      "brgy_name": "Gangal (Pob.)",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120004",
      "brgy_name": "Maguyepyep",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120005",
      "brgy_name": "Naguilian",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120006",
      "brgy_name": "Saccaang",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120007",
      "brgy_name": "Sallapadan",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120008",
      "brgy_name": "Subusob",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140120009",
      "brgy_name": "Ud-udiao",
      "city_code": "140120",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121001",
      "brgy_name": "Cabayogan",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121002",
      "brgy_name": "Dalimag",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121003",
      "brgy_name": "Langbaban",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121004",
      "brgy_name": "Manayday",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121007",
      "brgy_name": "Pantoc",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121008",
      "brgy_name": "Poblacion",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121010",
      "brgy_name": "Sabtan-olo",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121012",
      "brgy_name": "San Marcial",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140121013",
      "brgy_name": "Tangbao",
      "city_code": "140121",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122001",
      "brgy_name": "Abualan",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122002",
      "brgy_name": "Ba-ug",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122003",
      "brgy_name": "Badas",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122004",
      "brgy_name": "Cabcaborao",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122005",
      "brgy_name": "Colabaoan",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122006",
      "brgy_name": "Culiong",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122007",
      "brgy_name": "Daoidao",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122008",
      "brgy_name": "Guimba",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122009",
      "brgy_name": "Lam-ag",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122010",
      "brgy_name": "Lumobang",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122011",
      "brgy_name": "Nangobongan",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122012",
      "brgy_name": "Pattaoig",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122013",
      "brgy_name": "Poblacion North",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122014",
      "brgy_name": "Poblacion South",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122015",
      "brgy_name": "Quidaoen",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122016",
      "brgy_name": "Sabangan",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122017",
      "brgy_name": "Silet",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122018",
      "brgy_name": "Supi-il",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140122019",
      "brgy_name": "Tagaytay",
      "city_code": "140122",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140123001",
      "brgy_name": "Labaan",
      "city_code": "140123",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140123002",
      "brgy_name": "Palang",
      "city_code": "140123",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140123003",
      "brgy_name": "Pantoc",
      "city_code": "140123",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140123004",
      "brgy_name": "Poblacion",
      "city_code": "140123",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140123005",
      "brgy_name": "Tangadan",
      "city_code": "140123",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140123006",
      "brgy_name": "Villa Mercedes",
      "city_code": "140123",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124001",
      "brgy_name": "Bagalay",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124002",
      "brgy_name": "Basbasa",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124003",
      "brgy_name": "Budac",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124004",
      "brgy_name": "Bumagcat",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124005",
      "brgy_name": "Cabaroan",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124006",
      "brgy_name": "Deet",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124007",
      "brgy_name": "Gaddani",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124008",
      "brgy_name": "Patucannay",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124009",
      "brgy_name": "Pias",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124010",
      "brgy_name": "Poblacion",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140124011",
      "brgy_name": "Velasco",
      "city_code": "140124",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125001",
      "brgy_name": "Poblacion (Agsimao)",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125002",
      "brgy_name": "Alaoa",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125003",
      "brgy_name": "Anayan",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125004",
      "brgy_name": "Apao",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125005",
      "brgy_name": "Belaat",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125006",
      "brgy_name": "Caganayan",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125007",
      "brgy_name": "Cogon",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125008",
      "brgy_name": "Lanec",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125009",
      "brgy_name": "Lapat-Balantay",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140125010",
      "brgy_name": "Naglibacan",
      "city_code": "140125",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126001",
      "brgy_name": "Alangtin",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126002",
      "brgy_name": "Amtuagan",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126003",
      "brgy_name": "Dilong",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126004",
      "brgy_name": "Kili",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126005",
      "brgy_name": "Poblacion (Mayabo)",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126006",
      "brgy_name": "Supo",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126007",
      "brgy_name": "Tiempo",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126008",
      "brgy_name": "Tubtuba",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126009",
      "brgy_name": "Wayangan",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140126010",
      "brgy_name": "Tabacda",
      "city_code": "140126",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127001",
      "brgy_name": "Ap-apaya",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127002",
      "brgy_name": "Bol-lilising",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127003",
      "brgy_name": "Cal-lao",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127004",
      "brgy_name": "Lap-lapog",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127005",
      "brgy_name": "Lumaba",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127006",
      "brgy_name": "Poblacion",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127007",
      "brgy_name": "Tamac",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "140127008",
      "brgy_name": "Tuquib",
      "city_code": "140127",
      "province_code": "1401",
      "region_code": "14"
    },
    {
      "brgy_code": "141101001",
      "brgy_name": "Abiang",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141101002",
      "brgy_name": "Caliking",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141101003",
      "brgy_name": "Cattubo",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141101004",
      "brgy_name": "Naguey",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141101005",
      "brgy_name": "Paoay",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141101006",
      "brgy_name": "Pasdong",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141101007",
      "brgy_name": "Poblacion",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141101008",
      "brgy_name": "Topdac",
      "city_code": "141101",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102001",
      "brgy_name": "Apugan-Loakan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102002",
      "brgy_name": "Asin Road",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102003",
      "brgy_name": "Atok Trail",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102004",
      "brgy_name": "Bakakeng Central",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102005",
      "brgy_name": "Bakakeng North",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102006",
      "brgy_name": "Happy Hollow",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102007",
      "brgy_name": "Balsigan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102008",
      "brgy_name": "Bayan Park West (Bayan Park)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102010",
      "brgy_name": "Bayan Park East",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102011",
      "brgy_name": "Brookspoint",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102012",
      "brgy_name": "Brookside",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102013",
      "brgy_name": "Cabinet Hill-Teacher's Camp",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102016",
      "brgy_name": "Camp Allen",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102017",
      "brgy_name": "Camp 7",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102018",
      "brgy_name": "Camp 8",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102019",
      "brgy_name": "Campo Filipino",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102022",
      "brgy_name": "City Camp Central",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102023",
      "brgy_name": "City Camp Proper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102025",
      "brgy_name": "Country Club Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102026",
      "brgy_name": "Cresencia Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102028",
      "brgy_name": "Dagsian, Upper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102029",
      "brgy_name": "DPS Area",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102030",
      "brgy_name": "Dizon Subdivision",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102034",
      "brgy_name": "Quirino Hill, East",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102035",
      "brgy_name": "Engineers' Hill",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102036",
      "brgy_name": "Fairview Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102037",
      "brgy_name": "Fort del Pilar",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102038",
      "brgy_name": "General Luna, Upper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102039",
      "brgy_name": "General Luna, Lower",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102040",
      "brgy_name": "Gibraltar",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102041",
      "brgy_name": "Greenwater Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102042",
      "brgy_name": "Guisad Central",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102043",
      "brgy_name": "Guisad Sorong",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102045",
      "brgy_name": "Hillside",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102046",
      "brgy_name": "Holy Ghost Extension",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102047",
      "brgy_name": "Holy Ghost Proper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102049",
      "brgy_name": "Imelda Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102050",
      "brgy_name": "Irisan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102051",
      "brgy_name": "Kayang Extension",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102052",
      "brgy_name": "Kias",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102053",
      "brgy_name": "Kagitingan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102054",
      "brgy_name": "Loakan Proper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102055",
      "brgy_name": "Lopez Jaena",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102056",
      "brgy_name": "Lourdes Subdivision Extension",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102058",
      "brgy_name": "Dagsian, Lower",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102059",
      "brgy_name": "Lourdes Subdivision, Lower",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102061",
      "brgy_name": "Quirino Hill, Lower",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102062",
      "brgy_name": "General Emilio F. Aguinaldo (Quirino-Magsaysay, Lower)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102063",
      "brgy_name": "Lualhati",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102064",
      "brgy_name": "Lucnab",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102065",
      "brgy_name": "Magsaysay, Lower",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102066",
      "brgy_name": "Magsaysay Private Road",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102067",
      "brgy_name": "Aurora Hill Proper (Malvar-Sgt. Floresca)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102068",
      "brgy_name": "Bal-Marcoville (Marcoville)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102069",
      "brgy_name": "Quirino Hill, Middle",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102071",
      "brgy_name": "Military Cut-off",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102072",
      "brgy_name": "Mines View Park",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102074",
      "brgy_name": "Modern Site, East",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102075",
      "brgy_name": "Modern Site, West",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102076",
      "brgy_name": "New Lucban",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102078",
      "brgy_name": "Aurora Hill, North Central",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102079",
      "brgy_name": "Sanitary Camp, North",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102081",
      "brgy_name": "Outlook Drive",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102082",
      "brgy_name": "Pacdal",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102086",
      "brgy_name": "Pinget",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102087",
      "brgy_name": "Pinsao Pilot Project",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102088",
      "brgy_name": "Pinsao Proper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102089",
      "brgy_name": "Poliwes",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102090",
      "brgy_name": "Pucsusan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102091",
      "brgy_name": "MRR-Queen Of Peace",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102092",
      "brgy_name": "Rock Quarry, Lower",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102094",
      "brgy_name": "Salud Mitra",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102096",
      "brgy_name": "San Antonio Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102097",
      "brgy_name": "San Luis Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102098",
      "brgy_name": "San Roque Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102099",
      "brgy_name": "San Vicente",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102100",
      "brgy_name": "Santa Escolastica",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102101",
      "brgy_name": "Santo Rosario",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102102",
      "brgy_name": "Santo Tomas School Area",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102103",
      "brgy_name": "Santo Tomas Proper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102105",
      "brgy_name": "Scout Barrio",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102106",
      "brgy_name": "Session Road Area",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102107",
      "brgy_name": "Slaughter House Area (Santo Niño Slaughter)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102109",
      "brgy_name": "Sanitary Camp, South",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102110",
      "brgy_name": "Saint Joseph Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102111",
      "brgy_name": "Teodora Alonzo",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102112",
      "brgy_name": "Trancoville",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102114",
      "brgy_name": "Rock Quarry, Upper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102115",
      "brgy_name": "Victoria Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102116",
      "brgy_name": "Quirino Hill, West",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102117",
      "brgy_name": "Andres Bonifacio (Lower Bokawkan)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102119",
      "brgy_name": "Legarda-Burnham-Kisad",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102120",
      "brgy_name": "Imelda R. Marcos (La Salle)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102122",
      "brgy_name": "Lourdes Subdivision, Proper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102124",
      "brgy_name": "Quirino-Magsaysay, Upper (Upper QM)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102125",
      "brgy_name": "A. Bonifacio-Caguioa-Rimando (ABCR)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102126",
      "brgy_name": "Ambiong",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102128",
      "brgy_name": "Aurora Hill, South Central",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102129",
      "brgy_name": "Abanao-Zandueta-Kayong-Chugum-Otek (AZKCO)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102130",
      "brgy_name": "Bagong Lipunan (Market Area)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102131",
      "brgy_name": "BGH Compound",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102132",
      "brgy_name": "Bayan Park Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102133",
      "brgy_name": "Camdas Subdivision",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102134",
      "brgy_name": "Palma-Urbano (Cariño-Palma)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102135",
      "brgy_name": "Dominican Hill-Mirador",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102136",
      "brgy_name": "Alfonso Tabora",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102137",
      "brgy_name": "Dontogan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102138",
      "brgy_name": "Ferdinand (Happy Homes-Campo Sioco)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102139",
      "brgy_name": "Happy Homes (Happy Homes-Lucban)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102140",
      "brgy_name": "Harrison-Claudio Carantes",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102141",
      "brgy_name": "Honeymoon (Honeymoon-Holy Ghost)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102142",
      "brgy_name": "Kabayanihan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102143",
      "brgy_name": "Kayang-Hilltop",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102144",
      "brgy_name": "Gabriela Silang",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102145",
      "brgy_name": "Liwanag-Loakan",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102147",
      "brgy_name": "Malcolm Square-Perfecto (Jose Abad Santos)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102148",
      "brgy_name": "Manuel A. Roxas",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102149",
      "brgy_name": "Padre Burgos",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102150",
      "brgy_name": "Quezon Hill, Upper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102151",
      "brgy_name": "Rock Quarry, Middle",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102152",
      "brgy_name": "Phil-Am",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102153",
      "brgy_name": "Quezon Hill Proper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102154",
      "brgy_name": "Middle Quezon Hill Subdivision(Quezon Hill Middle)",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102155",
      "brgy_name": "Rizal Monument Area",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102156",
      "brgy_name": "SLU-SVP Housing Village",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102157",
      "brgy_name": "South Drive",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102159",
      "brgy_name": "Magsaysay, Upper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102160",
      "brgy_name": "Market Subdivision, Upper",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141102161",
      "brgy_name": "Padre Zamora",
      "city_code": "141102",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141103001",
      "brgy_name": "Ampusongan",
      "city_code": "141103",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141103002",
      "brgy_name": "Bagu",
      "city_code": "141103",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141103004",
      "brgy_name": "Dalipey",
      "city_code": "141103",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141103005",
      "brgy_name": "Gambang",
      "city_code": "141103",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141103007",
      "brgy_name": "Kayapa",
      "city_code": "141103",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141103009",
      "brgy_name": "Poblacion (Central)",
      "city_code": "141103",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141103010",
      "brgy_name": "Sinacbat",
      "city_code": "141103",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104001",
      "brgy_name": "Ambuclao",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104002",
      "brgy_name": "Bila",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104003",
      "brgy_name": "Bobok-Bisal",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104004",
      "brgy_name": "Daclan",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104005",
      "brgy_name": "Ekip",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104006",
      "brgy_name": "Karao",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104007",
      "brgy_name": "Nawal",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104008",
      "brgy_name": "Pito",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104009",
      "brgy_name": "Poblacion",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141104010",
      "brgy_name": "Tikey",
      "city_code": "141104",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105001",
      "brgy_name": "Abatan",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105002",
      "brgy_name": "Amgaleyguey",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105003",
      "brgy_name": "Amlimay",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105004",
      "brgy_name": "Baculongan Norte",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105006",
      "brgy_name": "Bangao",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105007",
      "brgy_name": "Buyacaoan",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105008",
      "brgy_name": "Calamagan",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105009",
      "brgy_name": "Catlubong",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105010",
      "brgy_name": "Loo",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105012",
      "brgy_name": "Natubleng",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105013",
      "brgy_name": "Poblacion (Central)",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105014",
      "brgy_name": "Baculongan Sur",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105015",
      "brgy_name": "Lengaoan",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141105016",
      "brgy_name": "Sebang",
      "city_code": "141105",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106001",
      "brgy_name": "Ampucao",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106002",
      "brgy_name": "Dalupirip",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106003",
      "brgy_name": "Gumatdang",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106004",
      "brgy_name": "Loacan",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106005",
      "brgy_name": "Poblacion (Central)",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106006",
      "brgy_name": "Tinongdan",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106007",
      "brgy_name": "Tuding",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106008",
      "brgy_name": "Ucab",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141106009",
      "brgy_name": "Virac",
      "city_code": "141106",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107001",
      "brgy_name": "Adaoay",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107002",
      "brgy_name": "Anchukey",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107003",
      "brgy_name": "Ballay",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107004",
      "brgy_name": "Bashoy",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107005",
      "brgy_name": "Batan",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107009",
      "brgy_name": "Duacan",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107010",
      "brgy_name": "Eddet",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107012",
      "brgy_name": "Gusaran",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107013",
      "brgy_name": "Kabayan Barrio",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107014",
      "brgy_name": "Lusod",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107016",
      "brgy_name": "Pacso",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107017",
      "brgy_name": "Poblacion (Central)",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141107018",
      "brgy_name": "Tawangan",
      "city_code": "141107",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108001",
      "brgy_name": "Balakbak",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108002",
      "brgy_name": "Beleng-Belis",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108003",
      "brgy_name": "Boklaoan",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108004",
      "brgy_name": "Cayapes",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108006",
      "brgy_name": "Cuba",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108008",
      "brgy_name": "Datakan",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108009",
      "brgy_name": "Gadang",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108010",
      "brgy_name": "Gasweling",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108011",
      "brgy_name": "Labueg",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108013",
      "brgy_name": "Paykek",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108014",
      "brgy_name": "Poblacion Central",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108015",
      "brgy_name": "Pudong",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108016",
      "brgy_name": "Pongayan",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108017",
      "brgy_name": "Sagubo",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141108018",
      "brgy_name": "Taba-ao",
      "city_code": "141108",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141109001",
      "brgy_name": "Badeo",
      "city_code": "141109",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141109002",
      "brgy_name": "Lubo",
      "city_code": "141109",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141109003",
      "brgy_name": "Madaymen",
      "city_code": "141109",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141109004",
      "brgy_name": "Palina",
      "city_code": "141109",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141109005",
      "brgy_name": "Poblacion",
      "city_code": "141109",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141109006",
      "brgy_name": "Sagpat",
      "city_code": "141109",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141109007",
      "brgy_name": "Tacadang",
      "city_code": "141109",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110001",
      "brgy_name": "Alapang",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110002",
      "brgy_name": "Alno",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110003",
      "brgy_name": "Ambiong",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110004",
      "brgy_name": "Bahong",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110005",
      "brgy_name": "Balili",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110006",
      "brgy_name": "Beckel",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110007",
      "brgy_name": "Bineng",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110008",
      "brgy_name": "Betag",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110009",
      "brgy_name": "Cruz",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110010",
      "brgy_name": "Lubas",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110011",
      "brgy_name": "Pico",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110012",
      "brgy_name": "Poblacion",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110013",
      "brgy_name": "Puguis",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110014",
      "brgy_name": "Shilan",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110015",
      "brgy_name": "Tawang",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141110016",
      "brgy_name": "Wangal",
      "city_code": "141110",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111001",
      "brgy_name": "Balili",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111002",
      "brgy_name": "Bedbed",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111003",
      "brgy_name": "Bulalacao",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111004",
      "brgy_name": "Cabiten",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111005",
      "brgy_name": "Colalo",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111006",
      "brgy_name": "Guinaoang",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111008",
      "brgy_name": "Paco",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111009",
      "brgy_name": "Palasaan",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111010",
      "brgy_name": "Poblacion",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111011",
      "brgy_name": "Sapid",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111012",
      "brgy_name": "Tabio",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141111013",
      "brgy_name": "Taneg",
      "city_code": "141111",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112002",
      "brgy_name": "Bagong",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112003",
      "brgy_name": "Balluay",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112004",
      "brgy_name": "Banangan",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112005",
      "brgy_name": "Banengbeng",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112006",
      "brgy_name": "Bayabas",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112007",
      "brgy_name": "Kamog",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112010",
      "brgy_name": "Pappa",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141112011",
      "brgy_name": "Poblacion",
      "city_code": "141112",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113001",
      "brgy_name": "Ansagan",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113002",
      "brgy_name": "Camp One",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113003",
      "brgy_name": "Camp 3",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113004",
      "brgy_name": "Camp 4",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113006",
      "brgy_name": "Nangalisan",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113007",
      "brgy_name": "Poblacion",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113008",
      "brgy_name": "San Pascual",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113009",
      "brgy_name": "Tabaan Norte",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113010",
      "brgy_name": "Tabaan Sur",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113011",
      "brgy_name": "Tadiangan",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113012",
      "brgy_name": "Taloy Norte",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113013",
      "brgy_name": "Taloy Sur",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141113014",
      "brgy_name": "Twin Peaks",
      "city_code": "141113",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114001",
      "brgy_name": "Ambassador",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114002",
      "brgy_name": "Ambongdolan",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114003",
      "brgy_name": "Ba-ayan",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114004",
      "brgy_name": "Basil",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114005",
      "brgy_name": "Daclan",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114006",
      "brgy_name": "Caponga (Pob.)",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114007",
      "brgy_name": "Tublay Central",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "141114008",
      "brgy_name": "Tuel",
      "city_code": "141114",
      "province_code": "1411",
      "region_code": "14"
    },
    {
      "brgy_code": "142701001",
      "brgy_name": "Amganad",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701002",
      "brgy_name": "Anaba",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701003",
      "brgy_name": "Bangaan",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701004",
      "brgy_name": "Batad",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701005",
      "brgy_name": "Bocos",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701007",
      "brgy_name": "Banao",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701009",
      "brgy_name": "Cambulo",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701010",
      "brgy_name": "Ducligan",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701011",
      "brgy_name": "Gohang",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701013",
      "brgy_name": "Kinakin",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701016",
      "brgy_name": "Poblacion",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701017",
      "brgy_name": "Poitan",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701018",
      "brgy_name": "San Fernando",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701021",
      "brgy_name": "Balawis",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701022",
      "brgy_name": "Ohaj",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701023",
      "brgy_name": "Tam-an",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701024",
      "brgy_name": "View Point",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142701025",
      "brgy_name": "Pula",
      "city_code": "142701",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702001",
      "brgy_name": "Abatan",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702004",
      "brgy_name": "Bangbang",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702010",
      "brgy_name": "Maggok",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702011",
      "brgy_name": "Poblacion",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702018",
      "brgy_name": "Bokiawan",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702019",
      "brgy_name": "Hapao",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702020",
      "brgy_name": "Lubo-ong",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702021",
      "brgy_name": "Nungulunan",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142702022",
      "brgy_name": "Ba-ang",
      "city_code": "142702",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703001",
      "brgy_name": "Ambabag",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703004",
      "brgy_name": "Baguinge",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703005",
      "brgy_name": "Bokiawan",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703008",
      "brgy_name": "Dalligan",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703009",
      "brgy_name": "Duit",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703011",
      "brgy_name": "Hucab",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703012",
      "brgy_name": "Julongan",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703013",
      "brgy_name": "Lingay",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703014",
      "brgy_name": "Mungayang",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703015",
      "brgy_name": "Nagacadan",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703017",
      "brgy_name": "Pindongan",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703018",
      "brgy_name": "Poblacion",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703020",
      "brgy_name": "Tuplac",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142703021",
      "brgy_name": "Bolog",
      "city_code": "142703",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704001",
      "brgy_name": "Abinuan",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704003",
      "brgy_name": "Banga",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704005",
      "brgy_name": "Boliwong",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704006",
      "brgy_name": "Burnay",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704008",
      "brgy_name": "Buyabuyan",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704009",
      "brgy_name": "Caba",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704010",
      "brgy_name": "Cudog",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704011",
      "brgy_name": "Dulao",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704013",
      "brgy_name": "Jucbong",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704014",
      "brgy_name": "Luta",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704016",
      "brgy_name": "Montabiong",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704018",
      "brgy_name": "Olilicon",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704020",
      "brgy_name": "Poblacion South",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704021",
      "brgy_name": "Ponghal",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704022",
      "brgy_name": "Pullaan",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704023",
      "brgy_name": "Tungngod",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704024",
      "brgy_name": "Tupaya",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704026",
      "brgy_name": "Poblacion East",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704027",
      "brgy_name": "Poblacion North",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142704028",
      "brgy_name": "Poblacion West",
      "city_code": "142704",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705002",
      "brgy_name": "Ambasa",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705004",
      "brgy_name": "Hapid",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705005",
      "brgy_name": "Lawig",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705006",
      "brgy_name": "Lucban",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705007",
      "brgy_name": "Mabatobato (Lamut)",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705008",
      "brgy_name": "Magulon",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705009",
      "brgy_name": "Nayon",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705010",
      "brgy_name": "Panopdopan",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705011",
      "brgy_name": "Payawan",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705012",
      "brgy_name": "Pieza",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705013",
      "brgy_name": "Poblacion East",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705014",
      "brgy_name": "Pugol (Ifugao Reservation)",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705015",
      "brgy_name": "Salamague",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705016",
      "brgy_name": "Bimpal",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705017",
      "brgy_name": "Holowon",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705018",
      "brgy_name": "Poblacion West",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705019",
      "brgy_name": "Sanafe",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142705020",
      "brgy_name": "Umilag",
      "city_code": "142705",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706001",
      "brgy_name": "Aduyongan",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706002",
      "brgy_name": "Alimit",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706003",
      "brgy_name": "Ayangan",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706004",
      "brgy_name": "Balangbang",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706005",
      "brgy_name": "Banao",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706006",
      "brgy_name": "Banhal",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706007",
      "brgy_name": "Bongan",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706009",
      "brgy_name": "Buninan",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706010",
      "brgy_name": "Chaya",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706011",
      "brgy_name": "Chumang",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706014",
      "brgy_name": "Guinihon",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706015",
      "brgy_name": "Inwaloy",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706018",
      "brgy_name": "Langayan",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706019",
      "brgy_name": "Liwo",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706020",
      "brgy_name": "Maga",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706021",
      "brgy_name": "Magulon",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706022",
      "brgy_name": "Mapawoy",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706023",
      "brgy_name": "Mayoyao Proper",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706024",
      "brgy_name": "Mongol",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706025",
      "brgy_name": "Nalbu",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706026",
      "brgy_name": "Nattum",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706027",
      "brgy_name": "Palaad",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706028",
      "brgy_name": "Poblacion",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706030",
      "brgy_name": "Talboc",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706033",
      "brgy_name": "Tulaed",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706035",
      "brgy_name": "Bato-Alatbang",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142706036",
      "brgy_name": "Epeng",
      "city_code": "142706",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707001",
      "brgy_name": "Bangar",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707002",
      "brgy_name": "Busilac",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707003",
      "brgy_name": "Calimag",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707004",
      "brgy_name": "Calupaan",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707005",
      "brgy_name": "Caragasan",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707006",
      "brgy_name": "Dolowog",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707007",
      "brgy_name": "Kiling",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707008",
      "brgy_name": "Namnama",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707009",
      "brgy_name": "Namillangan",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707010",
      "brgy_name": "Pinto",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707011",
      "brgy_name": "Poblacion",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707013",
      "brgy_name": "San Jose",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707014",
      "brgy_name": "San Juan",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707015",
      "brgy_name": "San Marcos",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707016",
      "brgy_name": "San Quintin",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707017",
      "brgy_name": "Santa Maria",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707018",
      "brgy_name": "Santo Domingo (Cabicalan)",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707019",
      "brgy_name": "Little Tadian",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707020",
      "brgy_name": "Ngileb",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142707021",
      "brgy_name": "Laya",
      "city_code": "142707",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708001",
      "brgy_name": "Awayan",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708002",
      "brgy_name": "Bunhian",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708003",
      "brgy_name": "Butac",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708004",
      "brgy_name": "Chalalo",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708005",
      "brgy_name": "Damag",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708006",
      "brgy_name": "Galonogon",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708007",
      "brgy_name": "Halag",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708008",
      "brgy_name": "Itab",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708009",
      "brgy_name": "Jacmal",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708010",
      "brgy_name": "Majlong",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708011",
      "brgy_name": "Mongayang",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708012",
      "brgy_name": "Posnaan",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708013",
      "brgy_name": "Ta-ang",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708014",
      "brgy_name": "Talite",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708015",
      "brgy_name": "Ubao",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142708016",
      "brgy_name": "Buwag",
      "city_code": "142708",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709001",
      "brgy_name": "Anao",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709002",
      "brgy_name": "Bangtinon",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709003",
      "brgy_name": "Bitu",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709004",
      "brgy_name": "Cababuyan",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709005",
      "brgy_name": "Mompolia",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709006",
      "brgy_name": "Namulditan",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709007",
      "brgy_name": "O-ong",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709008",
      "brgy_name": "Piwong",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709009",
      "brgy_name": "Poblacion (Hingyon)",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709010",
      "brgy_name": "Ubuag",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709011",
      "brgy_name": "Umalbong",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142709012",
      "brgy_name": "Northern Cababuyan",
      "city_code": "142709",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710001",
      "brgy_name": "Ahin",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710002",
      "brgy_name": "Ap-apid",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710003",
      "brgy_name": "Binablayan",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710004",
      "brgy_name": "Danggo",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710005",
      "brgy_name": "Eheb",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710006",
      "brgy_name": "Gumhang",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710007",
      "brgy_name": "Impugong",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710008",
      "brgy_name": "Luhong",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710009",
      "brgy_name": "Tinoc",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710010",
      "brgy_name": "Tukucan",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710011",
      "brgy_name": "Tulludan",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142710012",
      "brgy_name": "Wangwang",
      "city_code": "142710",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711001",
      "brgy_name": "Amduntog",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711002",
      "brgy_name": "Antipolo",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711003",
      "brgy_name": "Camandag",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711004",
      "brgy_name": "Cawayan",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711005",
      "brgy_name": "Hallap",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711006",
      "brgy_name": "Namal",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711007",
      "brgy_name": "Nungawa",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711008",
      "brgy_name": "Panubtuban",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "142711009",
      "brgy_name": "Pula",
      "city_code": "142711",
      "province_code": "1427",
      "region_code": "14"
    },
    {
      "brgy_code": "143201001",
      "brgy_name": "Ababa-an",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201002",
      "brgy_name": "Balantoy",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201003",
      "brgy_name": "Balbalan Proper",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201004",
      "brgy_name": "Balbalasang",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201005",
      "brgy_name": "Buaya",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201006",
      "brgy_name": "Dao-angan",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201007",
      "brgy_name": "Gawa-an",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201009",
      "brgy_name": "Mabaca",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201010",
      "brgy_name": "Maling (Kabugao)",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201011",
      "brgy_name": "Pantikian",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201012",
      "brgy_name": "Poswoy",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201013",
      "brgy_name": "Poblacion (Salegseg)",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201015",
      "brgy_name": "Talalang",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143201016",
      "brgy_name": "Tawang",
      "city_code": "143201",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206001",
      "brgy_name": "Dangoy",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206002",
      "brgy_name": "Mabilong",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206003",
      "brgy_name": "Mabongtot",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206004",
      "brgy_name": "Poblacion",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206005",
      "brgy_name": "Tanglag",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206006",
      "brgy_name": "Lower Uma",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206007",
      "brgy_name": "Upper Uma",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206008",
      "brgy_name": "Antonio Canao",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143206009",
      "brgy_name": "Uma del Norte (Western Luna Uma)",
      "city_code": "143206",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208001",
      "brgy_name": "Ableg",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208002",
      "brgy_name": "Balatoc",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208003",
      "brgy_name": "Balinciagao Norte",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208005",
      "brgy_name": "Cagaluan",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208007",
      "brgy_name": "Colayo",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208008",
      "brgy_name": "Dalupa",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208009",
      "brgy_name": "Dangtalan",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208010",
      "brgy_name": "Galdang (Casaloan)",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208011",
      "brgy_name": "Guina-ang (Pob.)",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208012",
      "brgy_name": "Magsilay",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208013",
      "brgy_name": "Malucsad",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208014",
      "brgy_name": "Pugong",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208015",
      "brgy_name": "Balenciagao Sur",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143208016",
      "brgy_name": "Bagtayan",
      "city_code": "143208",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209001",
      "brgy_name": "Aciga",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209002",
      "brgy_name": "Allaguia",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209003",
      "brgy_name": "Ammacian",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209004",
      "brgy_name": "Apatan",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209005",
      "brgy_name": "Ba-ay",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209006",
      "brgy_name": "Ballayangon",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209007",
      "brgy_name": "Bayao",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209008",
      "brgy_name": "Wagud",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209009",
      "brgy_name": "Camalog",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209010",
      "brgy_name": "Katabbogan",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209011",
      "brgy_name": "Dugpa",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209012",
      "brgy_name": "Cawagayan",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209013",
      "brgy_name": "Asibanglan",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209014",
      "brgy_name": "Limos",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209015",
      "brgy_name": "Magaogao",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209016",
      "brgy_name": "Malagnat",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209018",
      "brgy_name": "Mapaco",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209019",
      "brgy_name": "Pakawit",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209020",
      "brgy_name": "Pinukpuk Junction",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209021",
      "brgy_name": "Socbot",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209022",
      "brgy_name": "Taga (Pob.)",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209023",
      "brgy_name": "Pinococ",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143209024",
      "brgy_name": "Taggay",
      "city_code": "143209",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211001",
      "brgy_name": "Babalag East (Pob.)",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211002",
      "brgy_name": "Calaocan",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211005",
      "brgy_name": "Kinama",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211006",
      "brgy_name": "Liwan East",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211007",
      "brgy_name": "Liwan West",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211008",
      "brgy_name": "Macutay",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211011",
      "brgy_name": "San Pascual",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211012",
      "brgy_name": "San Quintin",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211014",
      "brgy_name": "Santor",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211015",
      "brgy_name": "Babalag West (Pob.)",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211016",
      "brgy_name": "Bulbol",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211017",
      "brgy_name": "Romualdez",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211018",
      "brgy_name": "San Francisco",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143211019",
      "brgy_name": "San Pedro",
      "city_code": "143211",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213001",
      "brgy_name": "Agbannawag",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213002",
      "brgy_name": "Amlao",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213003",
      "brgy_name": "Appas",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213004",
      "brgy_name": "Bagumbayan",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213005",
      "brgy_name": "Balawag",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213006",
      "brgy_name": "Balong",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213007",
      "brgy_name": "Bantay",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213008",
      "brgy_name": "Bulanao",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213009",
      "brgy_name": "Cabaritan",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213010",
      "brgy_name": "Cabaruan",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213011",
      "brgy_name": "Calaccad",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213012",
      "brgy_name": "Calanan",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213013",
      "brgy_name": "Dilag",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213014",
      "brgy_name": "Dupag",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213015",
      "brgy_name": "Gobgob",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213016",
      "brgy_name": "Guilayon",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213017",
      "brgy_name": "Lanna",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213018",
      "brgy_name": "Laya East",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213019",
      "brgy_name": "Laya West",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213020",
      "brgy_name": "Lucog",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213021",
      "brgy_name": "Magnao",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213022",
      "brgy_name": "Magsaysay",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213023",
      "brgy_name": "Malalao",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213024",
      "brgy_name": "Masablang",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213025",
      "brgy_name": "Nambaran",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213026",
      "brgy_name": "Nambucayan",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213027",
      "brgy_name": "Naneng",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213029",
      "brgy_name": "Dagupan Centro (Pob.)",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213030",
      "brgy_name": "San Juan",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213031",
      "brgy_name": "Suyang",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213032",
      "brgy_name": "Tuga",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213033",
      "brgy_name": "Bado Dangwa",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213034",
      "brgy_name": "Bulo",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213035",
      "brgy_name": "Casigayan",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213036",
      "brgy_name": "Cudal",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213037",
      "brgy_name": "Dagupan Weste",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213038",
      "brgy_name": "Lacnog",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213039",
      "brgy_name": "Malin-awa",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213040",
      "brgy_name": "New Tanglag",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213041",
      "brgy_name": "San Julian",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213042",
      "brgy_name": "Bulanao Norte",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143213043",
      "brgy_name": "Ipil",
      "city_code": "143213",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214001",
      "brgy_name": "Anggacan",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214002",
      "brgy_name": "Babbanoy",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214003",
      "brgy_name": "Dacalan",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214004",
      "brgy_name": "Gaang",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214005",
      "brgy_name": "Lower Mangali",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214006",
      "brgy_name": "Lower Taloctoc",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214007",
      "brgy_name": "Lower Lubo",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214008",
      "brgy_name": "Upper Lubo",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214009",
      "brgy_name": "Mabaca",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214010",
      "brgy_name": "Pangol",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214011",
      "brgy_name": "Poblacion",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214013",
      "brgy_name": "Upper Taloctoc",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214014",
      "brgy_name": "Anggacan Sur",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214015",
      "brgy_name": "Dupligan",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214016",
      "brgy_name": "Lay-asan",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143214017",
      "brgy_name": "Mangali Centro",
      "city_code": "143214",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215001",
      "brgy_name": "Ambato Legleg",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215003",
      "brgy_name": "Bangad Centro",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215004",
      "brgy_name": "Basao",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215006",
      "brgy_name": "Belong Manubal",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215007",
      "brgy_name": "Butbut (Butbut-Ngibat)",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215008",
      "brgy_name": "Bugnay",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215009",
      "brgy_name": "Buscalan (Buscalan-Locong)",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215010",
      "brgy_name": "Dananao",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215011",
      "brgy_name": "Loccong",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215012",
      "brgy_name": "Luplupa",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215013",
      "brgy_name": "Mallango",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215014",
      "brgy_name": "Poblacion",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215016",
      "brgy_name": "Sumadel 1",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215017",
      "brgy_name": "Sumadel 2",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215018",
      "brgy_name": "Tulgao East",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215019",
      "brgy_name": "Tulgao West",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215020",
      "brgy_name": "Upper Bangad",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215021",
      "brgy_name": "Ngibat",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215022",
      "brgy_name": "Old Tinglayan",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "143215023",
      "brgy_name": "Lower Bangad",
      "city_code": "143215",
      "province_code": "1432",
      "region_code": "14"
    },
    {
      "brgy_code": "144401001",
      "brgy_name": "Chupac",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401002",
      "brgy_name": "Fiangtin",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401003",
      "brgy_name": "Kaleo",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401005",
      "brgy_name": "Latang",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401006",
      "brgy_name": "Lias Kanluran",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401007",
      "brgy_name": "Lingoy",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401008",
      "brgy_name": "Lunas",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401009",
      "brgy_name": "Macalana",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401010",
      "brgy_name": "Ogoog",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401011",
      "brgy_name": "Gawana (Pob.)",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144401012",
      "brgy_name": "Lias Silangan",
      "city_code": "144401",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402001",
      "brgy_name": "Abatan",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402002",
      "brgy_name": "Bagnen Oriente",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402003",
      "brgy_name": "Bagnen Proper",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402004",
      "brgy_name": "Balintaugan",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402005",
      "brgy_name": "Banao",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402006",
      "brgy_name": "Bila (Bua)",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402007",
      "brgy_name": "Guinzadan Central",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402008",
      "brgy_name": "Guinzadan Norte",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402009",
      "brgy_name": "Guinzadan Sur",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402010",
      "brgy_name": "Lagawa",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402011",
      "brgy_name": "Leseb",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402012",
      "brgy_name": "Mabaay",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402013",
      "brgy_name": "Mayag",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402014",
      "brgy_name": "Monamon Norte",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402015",
      "brgy_name": "Monamon Sur",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402016",
      "brgy_name": "Mount Data",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402018",
      "brgy_name": "Otucan Norte",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402019",
      "brgy_name": "Otucan Sur",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402020",
      "brgy_name": "Poblacion (Bauko)",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402021",
      "brgy_name": "Sadsadan",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402022",
      "brgy_name": "Sinto",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144402023",
      "brgy_name": "Tapapan",
      "city_code": "144402",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403001",
      "brgy_name": "Agawa",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403002",
      "brgy_name": "Ambaguio",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403003",
      "brgy_name": "Banguitan",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403004",
      "brgy_name": "Besao East (Besao Proper)",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403005",
      "brgy_name": "Besao West",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403006",
      "brgy_name": "Catengan",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403007",
      "brgy_name": "Gueday",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403009",
      "brgy_name": "Lacmaan",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403010",
      "brgy_name": "Laylaya",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403011",
      "brgy_name": "Padangan",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403012",
      "brgy_name": "Payeo",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403013",
      "brgy_name": "Suquib",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403014",
      "brgy_name": "Tamboan",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144403015",
      "brgy_name": "Kin-iway (Pob.)",
      "city_code": "144403",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404001",
      "brgy_name": "Alab Proper",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404002",
      "brgy_name": "Alab Oriente",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404003",
      "brgy_name": "Balili",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404004",
      "brgy_name": "Bayyo",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404005",
      "brgy_name": "Bontoc Ili",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404006",
      "brgy_name": "Caneo",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404007",
      "brgy_name": "Dalican",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404009",
      "brgy_name": "Gonogon",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404010",
      "brgy_name": "Guinaang",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404011",
      "brgy_name": "Mainit",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404012",
      "brgy_name": "Maligcong",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404013",
      "brgy_name": "Samoki",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404014",
      "brgy_name": "Talubin",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404015",
      "brgy_name": "Tocucan",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404016",
      "brgy_name": "Poblacion (Bontoc)",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144404017",
      "brgy_name": "Calutit",
      "city_code": "144404",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405001",
      "brgy_name": "Alunogan",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405002",
      "brgy_name": "Balangao",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405003",
      "brgy_name": "Banao",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405004",
      "brgy_name": "Banawal",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405005",
      "brgy_name": "Butac",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405006",
      "brgy_name": "Maducayan",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405007",
      "brgy_name": "Poblacion",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405008",
      "brgy_name": "Saliok",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405009",
      "brgy_name": "Santa Isabel",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405010",
      "brgy_name": "Tonglayan",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144405011",
      "brgy_name": "Pudo",
      "city_code": "144405",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406001",
      "brgy_name": "Anonat",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406002",
      "brgy_name": "Bacarni",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406003",
      "brgy_name": "Bananao",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406004",
      "brgy_name": "Bantay",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406005",
      "brgy_name": "Butigue",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406006",
      "brgy_name": "Bunot",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406007",
      "brgy_name": "Buringal",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406008",
      "brgy_name": "Palitod",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144406009",
      "brgy_name": "Poblacion",
      "city_code": "144406",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407001",
      "brgy_name": "Bao-angan",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407002",
      "brgy_name": "Bun-ayan",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407003",
      "brgy_name": "Busa",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407004",
      "brgy_name": "Camatagan",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407005",
      "brgy_name": "Capinitan",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407006",
      "brgy_name": "Data",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407007",
      "brgy_name": "Gayang",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407008",
      "brgy_name": "Lagan",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407009",
      "brgy_name": "Losad",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407010",
      "brgy_name": "Namatec",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407011",
      "brgy_name": "Napua",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407012",
      "brgy_name": "Pingad",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407013",
      "brgy_name": "Poblacion",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407014",
      "brgy_name": "Supang",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144407015",
      "brgy_name": "Tambingan",
      "city_code": "144407",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408001",
      "brgy_name": "Anabel",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408002",
      "brgy_name": "Belwang",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408003",
      "brgy_name": "Betwagan",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408004",
      "brgy_name": "Bekigan",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408005",
      "brgy_name": "Poblacion",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408006",
      "brgy_name": "Sacasacan",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408007",
      "brgy_name": "Saclit",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144408008",
      "brgy_name": "Demang",
      "city_code": "144408",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409001",
      "brgy_name": "Aguid",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409002",
      "brgy_name": "Tetepan Sur",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409003",
      "brgy_name": "Ambasing",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409004",
      "brgy_name": "Angkeling",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409005",
      "brgy_name": "Antadao",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409006",
      "brgy_name": "Balugan",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409007",
      "brgy_name": "Bangaan",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409008",
      "brgy_name": "Dagdag (Pob.)",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409009",
      "brgy_name": "Demang (Pob.)",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409010",
      "brgy_name": "Fidelisan",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409011",
      "brgy_name": "Kilong",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409012",
      "brgy_name": "Madongo",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409014",
      "brgy_name": "Poblacion (Patay)",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409015",
      "brgy_name": "Pide",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409017",
      "brgy_name": "Nacagang",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409018",
      "brgy_name": "Suyo",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409019",
      "brgy_name": "Taccong",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409020",
      "brgy_name": "Tanulong",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144409022",
      "brgy_name": "Tetepan Norte",
      "city_code": "144409",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410002",
      "brgy_name": "Balaoa",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410003",
      "brgy_name": "Banaao",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410004",
      "brgy_name": "Bantey",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410005",
      "brgy_name": "Batayan",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410006",
      "brgy_name": "Bunga",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410007",
      "brgy_name": "Cadad-anan",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410008",
      "brgy_name": "Cagubatan",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410009",
      "brgy_name": "Duagan",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410010",
      "brgy_name": "Dacudac",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410011",
      "brgy_name": "Kayan East",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410012",
      "brgy_name": "Lenga",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410013",
      "brgy_name": "Lubon",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410014",
      "brgy_name": "Mabalite",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410015",
      "brgy_name": "Masla",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410016",
      "brgy_name": "Pandayan",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410017",
      "brgy_name": "Poblacion",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410018",
      "brgy_name": "Sumadel",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410019",
      "brgy_name": "Tue",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "144410020",
      "brgy_name": "Kayan West",
      "city_code": "144410",
      "province_code": "1444",
      "region_code": "14"
    },
    {
      "brgy_code": "148101001",
      "brgy_name": "Butao",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101002",
      "brgy_name": "Cadaclan",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101003",
      "brgy_name": "Langnao",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101004",
      "brgy_name": "Lubong",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101005",
      "brgy_name": "Naguilian",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101006",
      "brgy_name": "Namaltugan",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101007",
      "brgy_name": "Poblacion",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101008",
      "brgy_name": "Sabangan",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101009",
      "brgy_name": "Santa Filomena",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101010",
      "brgy_name": "Tubongan",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101011",
      "brgy_name": "Tanglagan",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101012",
      "brgy_name": "Tubang",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101013",
      "brgy_name": "Don Roque Ablan Sr.",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101014",
      "brgy_name": "Eleazar",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101015",
      "brgy_name": "Eva Puzon",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101016",
      "brgy_name": "Kabugawan",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101017",
      "brgy_name": "Macalino",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148101018",
      "brgy_name": "Santa Elena",
      "city_code": "148101",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102001",
      "brgy_name": "Allangigan",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102002",
      "brgy_name": "Buluan",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102003",
      "brgy_name": "Caglayan (New Pob.)",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102004",
      "brgy_name": "Calafug",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102005",
      "brgy_name": "Cupis",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102006",
      "brgy_name": "Daga",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102007",
      "brgy_name": "Guinamgaman",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102008",
      "brgy_name": "Karikitan",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102009",
      "brgy_name": "Katablangan",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102010",
      "brgy_name": "Malama",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102011",
      "brgy_name": "Manag",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102012",
      "brgy_name": "Nabuangan",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102013",
      "brgy_name": "Paddaoan",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102014",
      "brgy_name": "Puguin",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102015",
      "brgy_name": "Ripang (Old Pob.)",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102016",
      "brgy_name": "Sacpil",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102017",
      "brgy_name": "Talifugo",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102018",
      "brgy_name": "Banban",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102019",
      "brgy_name": "Guinaang",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102020",
      "brgy_name": "Ili",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148102021",
      "brgy_name": "Mawegui",
      "city_code": "148102",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103001",
      "brgy_name": "Allig",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103002",
      "brgy_name": "Anninipan",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103003",
      "brgy_name": "Atok",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103004",
      "brgy_name": "Bagutong",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103005",
      "brgy_name": "Balasi",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103006",
      "brgy_name": "Balluyan",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103008",
      "brgy_name": "Malayugan",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103009",
      "brgy_name": "Malubibit Norte",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103010",
      "brgy_name": "Poblacion East",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103011",
      "brgy_name": "Tamalunog",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103012",
      "brgy_name": "Mallig",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103013",
      "brgy_name": "Malubibit Sur",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103014",
      "brgy_name": "Poblacion West",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103015",
      "brgy_name": "San Jose",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103016",
      "brgy_name": "Santa Maria",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148103017",
      "brgy_name": "Upper Atok (Coliman)",
      "city_code": "148103",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104001",
      "brgy_name": "Badduat",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104002",
      "brgy_name": "Baliwanan",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104004",
      "brgy_name": "Bulu",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104005",
      "brgy_name": "Dagara",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104006",
      "brgy_name": "Dibagat",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104007",
      "brgy_name": "Cabetayan",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104008",
      "brgy_name": "Karagawan",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104009",
      "brgy_name": "Kumao",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104010",
      "brgy_name": "Laco",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104011",
      "brgy_name": "Lenneng (Liyyeng)",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104012",
      "brgy_name": "Lucab",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104013",
      "brgy_name": "Luttuacan",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104014",
      "brgy_name": "Madatag",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104015",
      "brgy_name": "Madduang",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104016",
      "brgy_name": "Magabta",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104017",
      "brgy_name": "Maragat",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104018",
      "brgy_name": "Musimut",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104019",
      "brgy_name": "Nagbabalayan",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104020",
      "brgy_name": "Poblacion",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104021",
      "brgy_name": "Tuyangan",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148104022",
      "brgy_name": "Waga",
      "city_code": "148104",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105001",
      "brgy_name": "Bacsay",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105002",
      "brgy_name": "Capagaypayan",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105003",
      "brgy_name": "Dagupan",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105004",
      "brgy_name": "Lappa",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105006",
      "brgy_name": "Marag",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105007",
      "brgy_name": "Poblacion",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105008",
      "brgy_name": "Quirino",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105009",
      "brgy_name": "Salvacion",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105010",
      "brgy_name": "San Francisco",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105011",
      "brgy_name": "San Isidro Norte",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105012",
      "brgy_name": "San Sebastian",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105013",
      "brgy_name": "Santa Lina",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105014",
      "brgy_name": "Tumog",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105015",
      "brgy_name": "Zumigui",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105016",
      "brgy_name": "Cagandungan",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105017",
      "brgy_name": "Calabigan",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105018",
      "brgy_name": "Cangisitan",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105019",
      "brgy_name": "Luyon",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105020",
      "brgy_name": "San Gregorio",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105021",
      "brgy_name": "San Isidro Sur",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105022",
      "brgy_name": "Shalom",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148105023",
      "brgy_name": "Turod",
      "city_code": "148105",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106001",
      "brgy_name": "Aga",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106002",
      "brgy_name": "Alem",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106006",
      "brgy_name": "Cabatacan",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106007",
      "brgy_name": "Cacalaggan",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106009",
      "brgy_name": "Capannikian",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106010",
      "brgy_name": "Lower Maton",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106012",
      "brgy_name": "Malibang",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106014",
      "brgy_name": "Mataguisi",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106016",
      "brgy_name": "Poblacion",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106017",
      "brgy_name": "San Antonio (Pugo)",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106020",
      "brgy_name": "Swan",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106021",
      "brgy_name": "Upper Maton",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106022",
      "brgy_name": "Amado",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106023",
      "brgy_name": "Aurora",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106024",
      "brgy_name": "Doña Loreta",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106025",
      "brgy_name": "Emilia",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106026",
      "brgy_name": "Imelda",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106027",
      "brgy_name": "Lt. Bilag",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106028",
      "brgy_name": "Lydia",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106029",
      "brgy_name": "San Jose",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106030",
      "brgy_name": "San Luis",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148106031",
      "brgy_name": "San Mariano",
      "city_code": "148106",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107001",
      "brgy_name": "Barocboc",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107002",
      "brgy_name": "Consuelo",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107003",
      "brgy_name": "Imelda (Sipa Annex)",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107004",
      "brgy_name": "Malekkeg",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107005",
      "brgy_name": "Marcela (Pob.)",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107006",
      "brgy_name": "Nueva",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107007",
      "brgy_name": "Panay",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107008",
      "brgy_name": "San Antonio",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107011",
      "brgy_name": "Sipa Proper",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107012",
      "brgy_name": "Emiliana",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107013",
      "brgy_name": "San Carlos",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107014",
      "brgy_name": "San Juan",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "148107015",
      "brgy_name": "San Mariano",
      "city_code": "148107",
      "province_code": "1481",
      "region_code": "14"
    },
    {
      "brgy_code": "150702001",
      "brgy_name": "Arco",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702002",
      "brgy_name": "Ba-as",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702004",
      "brgy_name": "Baimbing",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702005",
      "brgy_name": "Balagtasan",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702006",
      "brgy_name": "Balas",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702007",
      "brgy_name": "Balobo",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702008",
      "brgy_name": "Bato",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702010",
      "brgy_name": "Boheyakan",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702011",
      "brgy_name": "Buahan",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702012",
      "brgy_name": "Boheibu",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702013",
      "brgy_name": "Bohesapa",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702014",
      "brgy_name": "Bulingan",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702016",
      "brgy_name": "Cabobo",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702018",
      "brgy_name": "Campo Uno",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702020",
      "brgy_name": "Colonia",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702024",
      "brgy_name": "Calugusan",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702025",
      "brgy_name": "Kulay Bato",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702028",
      "brgy_name": "Limo-ok",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702032",
      "brgy_name": "Lo-ok",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702034",
      "brgy_name": "Lumuton",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702035",
      "brgy_name": "Luksumbang",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702036",
      "brgy_name": "Malo-ong Canal",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702037",
      "brgy_name": "Malo-ong San Jose",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702039",
      "brgy_name": "Parangbasak",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702045",
      "brgy_name": "Santa Clara",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702047",
      "brgy_name": "Tandong Ahas",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702049",
      "brgy_name": "Tumakid",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702050",
      "brgy_name": "Ubit",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702054",
      "brgy_name": "Bohebessey",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702055",
      "brgy_name": "Baungos",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702056",
      "brgy_name": "Danit-Puntocan",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702057",
      "brgy_name": "Sabong",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702058",
      "brgy_name": "Sengal",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702059",
      "brgy_name": "Ulame",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702060",
      "brgy_name": "Bohenange",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702061",
      "brgy_name": "Boheyawas",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702062",
      "brgy_name": "Bulanting",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702063",
      "brgy_name": "Lebbuh",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702064",
      "brgy_name": "Maganda",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702065",
      "brgy_name": "Malakas",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702066",
      "brgy_name": "Maligaya",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702067",
      "brgy_name": "Malinis (Pob.)",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702068",
      "brgy_name": "Matatag",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702069",
      "brgy_name": "Matibay",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150702070",
      "brgy_name": "Simbangon",
      "city_code": "150702",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703001",
      "brgy_name": "Atong-atong",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703003",
      "brgy_name": "Baungis",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703004",
      "brgy_name": "Bulanza",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703007",
      "brgy_name": "Lantawan Proper (Pob.)",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703008",
      "brgy_name": "Lower Manggas",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703011",
      "brgy_name": "Matarling",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703012",
      "brgy_name": "Matikang",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703016",
      "brgy_name": "Tairan",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703017",
      "brgy_name": "Upper Manggas",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703018",
      "brgy_name": "Bagbagon",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703019",
      "brgy_name": "Bulan-bulan",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703021",
      "brgy_name": "Suba-an (Pangasahan)",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703024",
      "brgy_name": "Lower Bañas",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703025",
      "brgy_name": "Upper Bañas",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703026",
      "brgy_name": "Calugusan",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703027",
      "brgy_name": "Canibungan",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703029",
      "brgy_name": "Landugan",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703030",
      "brgy_name": "Lawila",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703031",
      "brgy_name": "Lawi-lawi",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703033",
      "brgy_name": "Pamucalin",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703034",
      "brgy_name": "Switch Yakal",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703035",
      "brgy_name": "Paniongan",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703036",
      "brgy_name": "Luuk-Maluha",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703037",
      "brgy_name": "Calayan",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150703038",
      "brgy_name": "Parian-Baunoh",
      "city_code": "150703",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704001",
      "brgy_name": "Abong-Abong",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704002",
      "brgy_name": "Batungal",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704003",
      "brgy_name": "Calang Canas",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704004",
      "brgy_name": "Guanan North (Zone I)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704005",
      "brgy_name": "Guanan South (Zone II)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704006",
      "brgy_name": "Limbubong",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704007",
      "brgy_name": "Mahayahay Lower (Zone I)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704008",
      "brgy_name": "Muslim Area",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704009",
      "brgy_name": "Port Holland Zone I Pob. (Upper)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704010",
      "brgy_name": "Port Holland Zone II Pob. (Shipyard Main)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704011",
      "brgy_name": "Port Holland Zone III Pob. (Samal Village)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704012",
      "brgy_name": "Port Holland Zone IV (Lower)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704013",
      "brgy_name": "Townsite (Pob.)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704015",
      "brgy_name": "Taberlongan",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704016",
      "brgy_name": "Fuente Maluso",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704017",
      "brgy_name": "Tamuk",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704018",
      "brgy_name": "Tubigan",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704019",
      "brgy_name": "Mahayahay Upper (Zone II)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704020",
      "brgy_name": "Port Holland Zone V (Shipyard Tabuk)",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150704021",
      "brgy_name": "Upper Garlayan",
      "city_code": "150704",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705001",
      "brgy_name": "Bacung",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705002",
      "brgy_name": "Benembengan Lower",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705006",
      "brgy_name": "Buli-buli",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705007",
      "brgy_name": "Cabcaban",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705010",
      "brgy_name": "Guiong",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705013",
      "brgy_name": "Manaul",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705014",
      "brgy_name": "Mangal (Pob.)",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705019",
      "brgy_name": "Sumisip Central",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705022",
      "brgy_name": "Tumahubong",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705024",
      "brgy_name": "Libug",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705025",
      "brgy_name": "Tongsengal",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705027",
      "brgy_name": "Baiwas",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705028",
      "brgy_name": "Basak",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705029",
      "brgy_name": "Cabengbeng Lower",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705030",
      "brgy_name": "Cabengbeng Upper",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705031",
      "brgy_name": "Luuk-Bait",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705032",
      "brgy_name": "Mahatalang",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705035",
      "brgy_name": "Benembengan Upper",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705036",
      "brgy_name": "Bohe-languyan",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705039",
      "brgy_name": "Ettub-ettub",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705041",
      "brgy_name": "Kaum-Air",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705042",
      "brgy_name": "Limbocandis",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705043",
      "brgy_name": "Lukketon",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705044",
      "brgy_name": "Marang",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705045",
      "brgy_name": "Mebak",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705047",
      "brgy_name": "Sahaya Bohe Bato",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705048",
      "brgy_name": "Sapah Bulak",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705050",
      "brgy_name": "Tikus",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150705051",
      "brgy_name": "Kaumpamatsakem",
      "city_code": "150705",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706002",
      "brgy_name": "Badja",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706003",
      "brgy_name": "Bohebaca",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706004",
      "brgy_name": "Bohelebung",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706011",
      "brgy_name": "Lagayas",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706012",
      "brgy_name": "Limbo-Upas",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706016",
      "brgy_name": "Tipo-tipo Proper (Pob.)",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706017",
      "brgy_name": "Baguindan",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706018",
      "brgy_name": "Banah",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706019",
      "brgy_name": "Bohe-Tambak",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706030",
      "brgy_name": "Silangkum",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150706038",
      "brgy_name": "Bangcuang",
      "city_code": "150706",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707005",
      "brgy_name": "Lahi-lahi",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707010",
      "brgy_name": "Sinulatan",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707016",
      "brgy_name": "Bohetambis",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707021",
      "brgy_name": "Duga-a",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707025",
      "brgy_name": "Mahawid",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707028",
      "brgy_name": "Lower Sinangkapan",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707033",
      "brgy_name": "Tablas Usew",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707034",
      "brgy_name": "Calut",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707040",
      "brgy_name": "Katipunan",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150707041",
      "brgy_name": "Lower Tablas",
      "city_code": "150707",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708001",
      "brgy_name": "Caddayan",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708002",
      "brgy_name": "Linongan",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708003",
      "brgy_name": "Lower Bato-bato",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708004",
      "brgy_name": "Mangalut",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708005",
      "brgy_name": "Manguso",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708006",
      "brgy_name": "Paguengan",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708007",
      "brgy_name": "Semut",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708008",
      "brgy_name": "Upper Bato-bato",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150708009",
      "brgy_name": "Upper Sinangkapan",
      "city_code": "150708",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709001",
      "brgy_name": "Apil-apil",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709002",
      "brgy_name": "Bato-bato",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709003",
      "brgy_name": "Bohe-Piang",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709004",
      "brgy_name": "Bucalao",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709005",
      "brgy_name": "Cambug",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709006",
      "brgy_name": "Danapah",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709007",
      "brgy_name": "Guinanta",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709008",
      "brgy_name": "Kailih",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709009",
      "brgy_name": "Kinukutan",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709010",
      "brgy_name": "Kuhon",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709011",
      "brgy_name": "Kuhon Lennuh",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709012",
      "brgy_name": "Linuan",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709013",
      "brgy_name": "Lookbisaya (Kaulungan Island)",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709014",
      "brgy_name": "Macalang",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709015",
      "brgy_name": "Magcawa",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150709016",
      "brgy_name": "Sangkahan (Kaulungan Island)",
      "city_code": "150709",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710001",
      "brgy_name": "Basakan",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710002",
      "brgy_name": "Buton",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710003",
      "brgy_name": "Candiis",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710004",
      "brgy_name": "Langil",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710005",
      "brgy_name": "Langong",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710006",
      "brgy_name": "Languyan",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710007",
      "brgy_name": "Pintasan",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710008",
      "brgy_name": "Seronggon",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710009",
      "brgy_name": "Sibago",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710010",
      "brgy_name": "Sulutan Matangal",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150710011",
      "brgy_name": "Tuburan Proper (Pob.)",
      "city_code": "150710",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711001",
      "brgy_name": "Amaloy",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711002",
      "brgy_name": "Bohe-Pahuh",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711003",
      "brgy_name": "Bohe-Suyak",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711004",
      "brgy_name": "Cabangalan",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711005",
      "brgy_name": "Danit",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711006",
      "brgy_name": "Kamamburingan",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711007",
      "brgy_name": "Matata",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711008",
      "brgy_name": "Materling",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711009",
      "brgy_name": "Pipil",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711010",
      "brgy_name": "Sungkayut",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711011",
      "brgy_name": "Tongbato",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150711012",
      "brgy_name": "Ulitan",
      "city_code": "150711",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712001",
      "brgy_name": "Baluk-baluk",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712002",
      "brgy_name": "Dasalan",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712003",
      "brgy_name": "Lubukan",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712004",
      "brgy_name": "Luukbongsod",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712005",
      "brgy_name": "Mananggal",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712006",
      "brgy_name": "Palahangan",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712007",
      "brgy_name": "Panducan",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712008",
      "brgy_name": "Sangbay Big",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712009",
      "brgy_name": "Sangbay Small",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150712010",
      "brgy_name": "Tausan",
      "city_code": "150712",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713001",
      "brgy_name": "Babag (Babuan Island)",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713002",
      "brgy_name": "Balanting",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713003",
      "brgy_name": "Boloh-boloh",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713004",
      "brgy_name": "Bukut-Umus",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713005",
      "brgy_name": "Kaumpurnah",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713006",
      "brgy_name": "Lanawan",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713007",
      "brgy_name": "Pisak-pisak",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713008",
      "brgy_name": "Saluping",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713009",
      "brgy_name": "Suligan (Babuan Island)",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713010",
      "brgy_name": "Sulloh (Tapiantana)",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713011",
      "brgy_name": "Tambulig Buton",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "150713012",
      "brgy_name": "Tong-Umus",
      "city_code": "150713",
      "province_code": "1507",
      "region_code": "15"
    },
    {
      "brgy_code": "153601001",
      "brgy_name": "Ampao",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601002",
      "brgy_name": "Bagoaingud",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601004",
      "brgy_name": "Balut",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601005",
      "brgy_name": "Barua",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601006",
      "brgy_name": "Buadiawani",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601007",
      "brgy_name": "Bubong",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601008",
      "brgy_name": "Dilabayan",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601009",
      "brgy_name": "Dipatuan",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601010",
      "brgy_name": "Daramoyod",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601011",
      "brgy_name": "Gandamato",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601012",
      "brgy_name": "Gurain",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601013",
      "brgy_name": "Ilian",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601014",
      "brgy_name": "Lama",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601015",
      "brgy_name": "Liawao",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601016",
      "brgy_name": "Lumbaca-Ingud",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601018",
      "brgy_name": "Madanding",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601019",
      "brgy_name": "Orong",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601020",
      "brgy_name": "Pindolonan",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601021",
      "brgy_name": "Poblacion I",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601022",
      "brgy_name": "Poblacion II",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601023",
      "brgy_name": "Raya",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601024",
      "brgy_name": "Rorowan",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601025",
      "brgy_name": "Sugod",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601026",
      "brgy_name": "Tambo",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601027",
      "brgy_name": "Tuka I",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153601028",
      "brgy_name": "Tuka II",
      "city_code": "153601",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602002",
      "brgy_name": "Banago",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602003",
      "brgy_name": "Barorao",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602004",
      "brgy_name": "Batuan",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602006",
      "brgy_name": "Budas",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602007",
      "brgy_name": "Calilangan",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602008",
      "brgy_name": "Igabay",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602010",
      "brgy_name": "Lalabuan",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602011",
      "brgy_name": "Magulalung Occidental",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602012",
      "brgy_name": "Magulalung Oriental",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602013",
      "brgy_name": "Molimoc",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602014",
      "brgy_name": "Narra",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602015",
      "brgy_name": "Plasan",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602016",
      "brgy_name": "Purakan",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602019",
      "brgy_name": "Buisan (Bengabeng)",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602022",
      "brgy_name": "Buenavista",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602027",
      "brgy_name": "Lorenzo",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602029",
      "brgy_name": "Lower Itil",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602032",
      "brgy_name": "Macao",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602039",
      "brgy_name": "Poblacion (Balabagan)",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602043",
      "brgy_name": "Upper Itil",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602050",
      "brgy_name": "Bagoaingud",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602051",
      "brgy_name": "Ilian",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602052",
      "brgy_name": "Lumbac",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602053",
      "brgy_name": "Matampay",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602054",
      "brgy_name": "Matanog",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602055",
      "brgy_name": "Pindolonan",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153602056",
      "brgy_name": "Tataya",
      "city_code": "153602",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603001",
      "brgy_name": "Abaga",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603005",
      "brgy_name": "Poblacion (Balindong)",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603006",
      "brgy_name": "Pantaragoo",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603007",
      "brgy_name": "Bantoga Wato",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603008",
      "brgy_name": "Barit",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603009",
      "brgy_name": "Bubong",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603011",
      "brgy_name": "Bubong Cadapaan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603013",
      "brgy_name": "Borakis",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603014",
      "brgy_name": "Bualan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603015",
      "brgy_name": "Cadapaan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603016",
      "brgy_name": "Cadayonan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603018",
      "brgy_name": "Kaluntay",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603021",
      "brgy_name": "Dadayag",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603022",
      "brgy_name": "Dado",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603024",
      "brgy_name": "Dibarusan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603025",
      "brgy_name": "Dilausan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603026",
      "brgy_name": "Dimarao",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603027",
      "brgy_name": "Ingud",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603029",
      "brgy_name": "Lalabuan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603031",
      "brgy_name": "Lilod",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603032",
      "brgy_name": "Lumbayao",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603033",
      "brgy_name": "Limbo",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603035",
      "brgy_name": "Lumbac Lalan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603036",
      "brgy_name": "Lumbac Wato",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603037",
      "brgy_name": "Magarang",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603041",
      "brgy_name": "Nusa Lumba Ranao",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603042",
      "brgy_name": "Padila",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603043",
      "brgy_name": "Pagayawan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603044",
      "brgy_name": "Paigoay",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603046",
      "brgy_name": "Raya",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603047",
      "brgy_name": "Salipongan",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603050",
      "brgy_name": "Talub",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603051",
      "brgy_name": "Tomarompong",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603052",
      "brgy_name": "Tantua Raya",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603053",
      "brgy_name": "Tuka Bubong",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603054",
      "brgy_name": "Bolinsong",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603055",
      "brgy_name": "Lati",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153603056",
      "brgy_name": "Malaig",
      "city_code": "153603",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604003",
      "brgy_name": "Bagoaingud",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604004",
      "brgy_name": "Bairan (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604005",
      "brgy_name": "Bandingun",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604006",
      "brgy_name": "Biabi",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604007",
      "brgy_name": "Bialaan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604009",
      "brgy_name": "Cadayonan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604010",
      "brgy_name": "Lumbac Cadayonan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604011",
      "brgy_name": "Lalapung Central",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604012",
      "brgy_name": "Condaraan Pob. (Condaraan Dimadap)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604013",
      "brgy_name": "Cormatan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604014",
      "brgy_name": "Ilian",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604015",
      "brgy_name": "Lalapung Proper (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604017",
      "brgy_name": "Bubong Lilod",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604018",
      "brgy_name": "Linao",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604019",
      "brgy_name": "Linuk (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604020",
      "brgy_name": "Liong",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604021",
      "brgy_name": "Lumbac",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604022",
      "brgy_name": "Mimbalawag",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604024",
      "brgy_name": "Maliwanag",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604025",
      "brgy_name": "Mapantao",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604027",
      "brgy_name": "Cadingilan Occidental",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604028",
      "brgy_name": "Cadingilan Oriental",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604030",
      "brgy_name": "Palao",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604031",
      "brgy_name": "Pama-an",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604032",
      "brgy_name": "Pamacotan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604033",
      "brgy_name": "Pantar",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604034",
      "brgy_name": "Parao",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604035",
      "brgy_name": "Patong",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604036",
      "brgy_name": "Porotan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604037",
      "brgy_name": "Rantian",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604038",
      "brgy_name": "Raya Cadayonan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604039",
      "brgy_name": "Rinabor (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604040",
      "brgy_name": "Sapa",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604041",
      "brgy_name": "Samporna (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604042",
      "brgy_name": "Silid",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604043",
      "brgy_name": "Sugod",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604044",
      "brgy_name": "Sultan Pandapatan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604045",
      "brgy_name": "Sumbag (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604046",
      "brgy_name": "Tagoranao",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604047",
      "brgy_name": "Tangcal",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604048",
      "brgy_name": "Tangcal Proper (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604049",
      "brgy_name": "Tuca (Pob.)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604050",
      "brgy_name": "Tomarompong",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604051",
      "brgy_name": "Tomongcal Ligi",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604052",
      "brgy_name": "Torogan",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604053",
      "brgy_name": "Lalapung Upper",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604054",
      "brgy_name": "Gandamato",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604055",
      "brgy_name": "Bubong Raya",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153604056",
      "brgy_name": "Poblacion (Bayang)",
      "city_code": "153604",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605001",
      "brgy_name": "Badak",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605002",
      "brgy_name": "Baguiangun",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605003",
      "brgy_name": "Balut Maito",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605004",
      "brgy_name": "Basak",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605005",
      "brgy_name": "Bubong",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605006",
      "brgy_name": "Bubonga-Ranao",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605009",
      "brgy_name": "Dansalan Dacsula",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605010",
      "brgy_name": "Ingud",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605011",
      "brgy_name": "Kialilidan",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605012",
      "brgy_name": "Lumbac",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605014",
      "brgy_name": "Macaguiling",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605015",
      "brgy_name": "Madaya",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605016",
      "brgy_name": "Magonaya",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605017",
      "brgy_name": "Maindig",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605018",
      "brgy_name": "Masolun",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605019",
      "brgy_name": "Olama",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605020",
      "brgy_name": "Pagalamatan (Pob.)",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605021",
      "brgy_name": "Pantar",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605022",
      "brgy_name": "Picalilangan",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605023",
      "brgy_name": "Picotaan",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605024",
      "brgy_name": "Pindolonan",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605025",
      "brgy_name": "Poblacion",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605028",
      "brgy_name": "Soldaroro",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605030",
      "brgy_name": "Tambac",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605033",
      "brgy_name": "Timbangan",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153605034",
      "brgy_name": "Tuca",
      "city_code": "153605",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606002",
      "brgy_name": "Bagoaingud",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606003",
      "brgy_name": "Bansayan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606004",
      "brgy_name": "Basingan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606005",
      "brgy_name": "Batangan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606006",
      "brgy_name": "Bualan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606007",
      "brgy_name": "Poblacion (Bubong)",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606008",
      "brgy_name": "Bubonga Didagun",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606011",
      "brgy_name": "Carigongan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606012",
      "brgy_name": "Bacolod",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606014",
      "brgy_name": "Dilabayan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606015",
      "brgy_name": "Dimapatoy",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606017",
      "brgy_name": "Dimayon Proper",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606019",
      "brgy_name": "Diolangan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606022",
      "brgy_name": "Guiguikun",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606023",
      "brgy_name": "Dibarosan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606027",
      "brgy_name": "Madanding",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606028",
      "brgy_name": "Malungun",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606030",
      "brgy_name": "Masorot",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606031",
      "brgy_name": "Matampay Dimarao",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606032",
      "brgy_name": "Miabalawag",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606033",
      "brgy_name": "Montiaan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606035",
      "brgy_name": "Pagayawan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606037",
      "brgy_name": "Palao",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606038",
      "brgy_name": "Panalawan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606039",
      "brgy_name": "Pantar",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606041",
      "brgy_name": "Pendogoan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606045",
      "brgy_name": "Polayagan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606048",
      "brgy_name": "Ramain Bubong",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606049",
      "brgy_name": "Rogero",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606051",
      "brgy_name": "Salipongan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606052",
      "brgy_name": "Sunggod",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606053",
      "brgy_name": "Taboro",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606054",
      "brgy_name": "Dalaon",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606058",
      "brgy_name": "Dimayon",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606059",
      "brgy_name": "Pindolonan",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153606060",
      "brgy_name": "Punud",
      "city_code": "153606",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607012",
      "brgy_name": "Butig Proper",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607013",
      "brgy_name": "Cabasaran",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607016",
      "brgy_name": "Coloyan Tambo",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607019",
      "brgy_name": "Dilimbayan",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607022",
      "brgy_name": "Dolangan",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607041",
      "brgy_name": "Pindolonan",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607044",
      "brgy_name": "Bayabao Poblacion",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607045",
      "brgy_name": "Poktan",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607046",
      "brgy_name": "Ragayan",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607047",
      "brgy_name": "Raya",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607051",
      "brgy_name": "Samer",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607052",
      "brgy_name": "Sandab Madaya",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607056",
      "brgy_name": "Sundig",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607060",
      "brgy_name": "Tiowi",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607062",
      "brgy_name": "Timbab",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153607063",
      "brgy_name": "Malungun",
      "city_code": "153607",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609001",
      "brgy_name": "Bagoaingud",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609002",
      "brgy_name": "Balintad",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609003",
      "brgy_name": "Barit",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609004",
      "brgy_name": "Bato Batoray",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609005",
      "brgy_name": "Campong a Raya",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609006",
      "brgy_name": "Gadongan",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609007",
      "brgy_name": "Gui",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609009",
      "brgy_name": "Linuk",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609010",
      "brgy_name": "Lumbac",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609011",
      "brgy_name": "Macabao",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609012",
      "brgy_name": "Macaguiling",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609013",
      "brgy_name": "Pagalongan",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609014",
      "brgy_name": "Panggawalupa",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609015",
      "brgy_name": "Pantaon A",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609016",
      "brgy_name": "Para-aba",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609017",
      "brgy_name": "Pindolonan",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609018",
      "brgy_name": "Poblacion",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609019",
      "brgy_name": "Baya",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609020",
      "brgy_name": "Sogod Madaya",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609021",
      "brgy_name": "Tabuan",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609022",
      "brgy_name": "Taganonok",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609023",
      "brgy_name": "Taliogon",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609027",
      "brgy_name": "Masolun",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609029",
      "brgy_name": "Lumbacaingud",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609030",
      "brgy_name": "Sekun Matampay",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609031",
      "brgy_name": "Dapaan",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609032",
      "brgy_name": "Balintad A",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609033",
      "brgy_name": "Barorao",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609034",
      "brgy_name": "Campong Sabela",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609051",
      "brgy_name": "Pangadapun",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609052",
      "brgy_name": "Pantaon",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153609053",
      "brgy_name": "Pamalian",
      "city_code": "153609",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610003",
      "brgy_name": "Cormatan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610007",
      "brgy_name": "Dimagaling (Dimagalin Proper)",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610008",
      "brgy_name": "Dimunda",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610009",
      "brgy_name": "Doronan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610012",
      "brgy_name": "Poblacion (Kapai Proper)",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610017",
      "brgy_name": "Malna Proper",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610018",
      "brgy_name": "Pagalongan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610019",
      "brgy_name": "Parao",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610025",
      "brgy_name": "Kasayanan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610026",
      "brgy_name": "Kasayanan West",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610029",
      "brgy_name": "Dilabayan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610030",
      "brgy_name": "Dilimbayan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610031",
      "brgy_name": "Kibolos",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610032",
      "brgy_name": "Kining",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610033",
      "brgy_name": "Pindolonan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610034",
      "brgy_name": "Babayog",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610035",
      "brgy_name": "Gadongan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610036",
      "brgy_name": "Lidasan",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610037",
      "brgy_name": "Macadar",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153610038",
      "brgy_name": "Pantaon",
      "city_code": "153610",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611003",
      "brgy_name": "Bacolod I",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611004",
      "brgy_name": "Bacolod II",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611008",
      "brgy_name": "Bantayao",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611009",
      "brgy_name": "Barit",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611011",
      "brgy_name": "Baugan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611019",
      "brgy_name": "Buad Lumbac",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611020",
      "brgy_name": "Cabasaran",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611021",
      "brgy_name": "Calilangan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611026",
      "brgy_name": "Carandangan-Mipaga",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611027",
      "brgy_name": "Cormatan Langban",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611031",
      "brgy_name": "Dialongana",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611035",
      "brgy_name": "Dilindongan-Cadayonan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611039",
      "brgy_name": "Gadongan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611040",
      "brgy_name": "Galawan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611041",
      "brgy_name": "Gambai",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611044",
      "brgy_name": "Kasola",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611046",
      "brgy_name": "Lalangitun",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611047",
      "brgy_name": "Lama",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611052",
      "brgy_name": "Lindongan Dialongana",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611054",
      "brgy_name": "Lobo Basara",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611055",
      "brgy_name": "Lumbac Bacayawan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611058",
      "brgy_name": "Macaguiling",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611065",
      "brgy_name": "Mapantao",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611066",
      "brgy_name": "Mapoling",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611070",
      "brgy_name": "Pagayawan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611077",
      "brgy_name": "Maribo (Pob.)",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611078",
      "brgy_name": "Posudaragat",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611079",
      "brgy_name": "Rumayas",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611081",
      "brgy_name": "Sabala Bantayao",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611082",
      "brgy_name": "Salaman",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611083",
      "brgy_name": "Salolodun Berwar",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611085",
      "brgy_name": "Sarigidan Madiar",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611089",
      "brgy_name": "Sunggod",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611091",
      "brgy_name": "Taluan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611094",
      "brgy_name": "Tamlang",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611096",
      "brgy_name": "Tongcopan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611097",
      "brgy_name": "Turogan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153611098",
      "brgy_name": "Minaring Diladigan",
      "city_code": "153611",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612001",
      "brgy_name": "Alog",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612008",
      "brgy_name": "Basayungun",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612011",
      "brgy_name": "Buad",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612012",
      "brgy_name": "Budi",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612017",
      "brgy_name": "Dago-ok",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612018",
      "brgy_name": "Dalama",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612021",
      "brgy_name": "Dalipuga",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612024",
      "brgy_name": "Lalapung",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612028",
      "brgy_name": "Lumbac",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612029",
      "brgy_name": "Lumbac Bacayawan",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612032",
      "brgy_name": "Lunay",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612033",
      "brgy_name": "Macadar",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612034",
      "brgy_name": "Madaya",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612035",
      "brgy_name": "Minanga",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612038",
      "brgy_name": "Pantar",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612039",
      "brgy_name": "Picotaan",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612043",
      "brgy_name": "Poblacion (Lumbatan)",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612048",
      "brgy_name": "Tambac",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612050",
      "brgy_name": "Bubong Macadar",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612051",
      "brgy_name": "Penaring",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153612052",
      "brgy_name": "Ligue",
      "city_code": "153612",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613001",
      "brgy_name": "Abaga",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613003",
      "brgy_name": "Bagoaingud",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613006",
      "brgy_name": "Basak",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613007",
      "brgy_name": "Bato",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613008",
      "brgy_name": "Bubong",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613010",
      "brgy_name": "Kormatan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613012",
      "brgy_name": "Dandamun",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613013",
      "brgy_name": "Diampaca",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613014",
      "brgy_name": "Dibarosan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613015",
      "brgy_name": "Delausan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613016",
      "brgy_name": "Gadongan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613017",
      "brgy_name": "Gurain",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613018",
      "brgy_name": "Cabasaran",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613019",
      "brgy_name": "Cadayonan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613021",
      "brgy_name": "Liangan I",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613022",
      "brgy_name": "Lilitun",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613023",
      "brgy_name": "Linao",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613024",
      "brgy_name": "Linuk",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613025",
      "brgy_name": "Madaya",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613026",
      "brgy_name": "Pagayawan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613027",
      "brgy_name": "Poblacion",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613028",
      "brgy_name": "Punud",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613029",
      "brgy_name": "Raya",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613030",
      "brgy_name": "Riray",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613031",
      "brgy_name": "Sabanding",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613032",
      "brgy_name": "Salongabanding",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613033",
      "brgy_name": "Sugod",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613034",
      "brgy_name": "Tamporong",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613035",
      "brgy_name": "Tongantongan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613036",
      "brgy_name": "Udangun",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613040",
      "brgy_name": "Liangan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613041",
      "brgy_name": "Lumbac",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613043",
      "brgy_name": "Paridi-Kalimodan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613044",
      "brgy_name": "Racotan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613049",
      "brgy_name": "Bacayawan",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613050",
      "brgy_name": "Padian Torogan I",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153613051",
      "brgy_name": "Sogod Kaloy",
      "city_code": "153613",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614003",
      "brgy_name": "Balintad",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614004",
      "brgy_name": "Balagunun",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614005",
      "brgy_name": "Bawang",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614006",
      "brgy_name": "Biabe",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614007",
      "brgy_name": "Bubong Uyaan",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614008",
      "brgy_name": "Cabasaran",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614010",
      "brgy_name": "Dibarusan",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614011",
      "brgy_name": "Lakitan",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614012",
      "brgy_name": "Liangan",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614013",
      "brgy_name": "Linuk",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614014",
      "brgy_name": "Lumbaca Ingud",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614016",
      "brgy_name": "Palao",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614017",
      "brgy_name": "Pantaon",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614018",
      "brgy_name": "Pantar",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614019",
      "brgy_name": "Madamba",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614020",
      "brgy_name": "Punud",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614022",
      "brgy_name": "Tubaran",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614023",
      "brgy_name": "Tambo",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614025",
      "brgy_name": "Tuca",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614027",
      "brgy_name": "Uyaan Proper (Pob.)",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614031",
      "brgy_name": "Tulay",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614032",
      "brgy_name": "Ilian",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614033",
      "brgy_name": "Pagayonan",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153614034",
      "brgy_name": "Pangadapan",
      "city_code": "153614",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615003",
      "brgy_name": "Bacayawan",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615004",
      "brgy_name": "Badak Lumao",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615006",
      "brgy_name": "Bagoaingud",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615017",
      "brgy_name": "Boniga",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615018",
      "brgy_name": "BPS Village",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615019",
      "brgy_name": "Betayan",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615020",
      "brgy_name": "Campo Muslim",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615021",
      "brgy_name": "China Town (Pob.)",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615022",
      "brgy_name": "Corahab",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615023",
      "brgy_name": "Diamaro",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615027",
      "brgy_name": "Inandayan",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615028",
      "brgy_name": "Cabasaran (South)",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615029",
      "brgy_name": "Calumbog",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615034",
      "brgy_name": "Lamin",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615038",
      "brgy_name": "Mable",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615043",
      "brgy_name": "Mananayo",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615044",
      "brgy_name": "Manggahan",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615047",
      "brgy_name": "Masao",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615049",
      "brgy_name": "Montay",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615053",
      "brgy_name": "Pasir",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615059",
      "brgy_name": "Rebocun",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615061",
      "brgy_name": "Sarang",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615063",
      "brgy_name": "Tacub",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615064",
      "brgy_name": "Tambara",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615066",
      "brgy_name": "Tiongcop",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615068",
      "brgy_name": "Tuboc",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615070",
      "brgy_name": "Matampay",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615071",
      "brgy_name": "Calibagat",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615072",
      "brgy_name": "Jose Abad Santos",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615073",
      "brgy_name": "Macuranding",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615074",
      "brgy_name": "Matalin",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615075",
      "brgy_name": "Matling",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615078",
      "brgy_name": "Pialot",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615079",
      "brgy_name": "Sumbagarogong",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615080",
      "brgy_name": "Banday",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615081",
      "brgy_name": "Bunk House",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153615082",
      "brgy_name": "Madaya",
      "city_code": "153615",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616001",
      "brgy_name": "Bacayawan",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616005",
      "brgy_name": "Cawayan Bacolod",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616006",
      "brgy_name": "Bacong",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616009",
      "brgy_name": "Camalig Bandara Ingud",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616018",
      "brgy_name": "Camalig Bubong",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616022",
      "brgy_name": "Camalig (Pob.)",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616023",
      "brgy_name": "Inudaran Campong",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616025",
      "brgy_name": "Cawayan",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616026",
      "brgy_name": "Daanaingud",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616035",
      "brgy_name": "Cawayan Kalaw",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616036",
      "brgy_name": "Kialdan",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616039",
      "brgy_name": "Lumbac Kialdan",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616040",
      "brgy_name": "Cawayan Linuk",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616044",
      "brgy_name": "Lubo",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616047",
      "brgy_name": "Inudaran Lumbac",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616051",
      "brgy_name": "Mantapoli",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616054",
      "brgy_name": "Matampay",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616055",
      "brgy_name": "Maul",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616059",
      "brgy_name": "Nataron",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616061",
      "brgy_name": "Pagalongan Bacayawan",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616063",
      "brgy_name": "Pataimas",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616071",
      "brgy_name": "Poona Marantao",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616072",
      "brgy_name": "Punud Proper",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616083",
      "brgy_name": "Tacub",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616097",
      "brgy_name": "Maul Ilian",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616102",
      "brgy_name": "Palao",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616105",
      "brgy_name": "Banga-Pantar",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616106",
      "brgy_name": "Batal-Punud",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616107",
      "brgy_name": "Bubong Madanding (Bubong)",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616108",
      "brgy_name": "Ilian",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616109",
      "brgy_name": "Inudaran Loway",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616110",
      "brgy_name": "Maul Lumbaca Ingud",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616111",
      "brgy_name": "Poblacion",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153616112",
      "brgy_name": "Tuca Kialdan",
      "city_code": "153616",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617001",
      "brgy_name": "Ambolong",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617003",
      "brgy_name": "Bacolod Chico Proper",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617006",
      "brgy_name": "Banga",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617007",
      "brgy_name": "Bangco",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617008",
      "brgy_name": "Banggolo Poblacion",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617009",
      "brgy_name": "Bangon",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617010",
      "brgy_name": "Beyaba-Damag",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617012",
      "brgy_name": "Bito Buadi Itowa",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617013",
      "brgy_name": "Bito Buadi Parba",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617014",
      "brgy_name": "Bubonga Pagalamatan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617015",
      "brgy_name": "Bubonga Lilod Madaya",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617016",
      "brgy_name": "Boganga",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617017",
      "brgy_name": "Boto Ambolong",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617019",
      "brgy_name": "Bubonga Cadayonan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617021",
      "brgy_name": "Bubong Lumbac",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617022",
      "brgy_name": "Bubonga Marawi",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617023",
      "brgy_name": "Bubonga Punod",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617024",
      "brgy_name": "Cabasaran",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617025",
      "brgy_name": "Cabingan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617026",
      "brgy_name": "Cadayonan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617027",
      "brgy_name": "Cadayonan I",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617029",
      "brgy_name": "Calocan East",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617030",
      "brgy_name": "Calocan West",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617032",
      "brgy_name": "Kormatan Matampay",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617033",
      "brgy_name": "Daguduban",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617035",
      "brgy_name": "Dansalan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617036",
      "brgy_name": "Datu Sa Dansalan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617037",
      "brgy_name": "Dayawan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617039",
      "brgy_name": "Dimaluna",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617040",
      "brgy_name": "Dulay",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617042",
      "brgy_name": "Dulay West",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617043",
      "brgy_name": "East Basak",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617044",
      "brgy_name": "Emie Punud",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617045",
      "brgy_name": "Fort",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617046",
      "brgy_name": "Gadongan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617048",
      "brgy_name": "Buadi Sacayo (Green)",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617049",
      "brgy_name": "Guimba (Lilod Proper)",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617051",
      "brgy_name": "Kapantaran",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617052",
      "brgy_name": "Kilala",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617057",
      "brgy_name": "Lilod Madaya (Pob.)",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617058",
      "brgy_name": "Lilod Saduc",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617060",
      "brgy_name": "Lomidong",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617062",
      "brgy_name": "Lumbaca Madaya (Pob.)",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617063",
      "brgy_name": "Lumbac Marinaut",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617064",
      "brgy_name": "Lumbaca Toros",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617065",
      "brgy_name": "Malimono",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617066",
      "brgy_name": "Basak Malutlut",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617068",
      "brgy_name": "Gadongan Mapantao",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617069",
      "brgy_name": "Amito Marantao",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617070",
      "brgy_name": "Marinaut East",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617071",
      "brgy_name": "Marinaut West",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617072",
      "brgy_name": "Matampay",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617074",
      "brgy_name": "Pantaon (Langcaf)",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617075",
      "brgy_name": "Mipaga Proper",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617076",
      "brgy_name": "Moncado Colony",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617077",
      "brgy_name": "Moncado Kadingilan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617079",
      "brgy_name": "Moriatao Loksadato",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617080",
      "brgy_name": "Datu Naga",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617083",
      "brgy_name": "Navarro (Datu Saber)",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617085",
      "brgy_name": "Olawa Ambolong",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617086",
      "brgy_name": "Pagalamatan Gambai",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617089",
      "brgy_name": "Pagayawan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617090",
      "brgy_name": "Panggao Saduc",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617097",
      "brgy_name": "Papandayan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617098",
      "brgy_name": "Paridi",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617099",
      "brgy_name": "Patani",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617101",
      "brgy_name": "Pindolonan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617103",
      "brgy_name": "Poona Marantao",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617105",
      "brgy_name": "Pugaan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617107",
      "brgy_name": "Rapasun MSU",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617109",
      "brgy_name": "Raya Madaya I",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617110",
      "brgy_name": "Raya Madaya II",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617111",
      "brgy_name": "Raya Saduc",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617112",
      "brgy_name": "Rorogagus Proper",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617113",
      "brgy_name": "Rorogagus East",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617116",
      "brgy_name": "Sabala Manao",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617117",
      "brgy_name": "Sabala Manao Proper",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617118",
      "brgy_name": "Saduc Proper",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617119",
      "brgy_name": "Sagonsongan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617120",
      "brgy_name": "Sangcay Dansalan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617123",
      "brgy_name": "Somiorang",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617124",
      "brgy_name": "South Madaya Proper",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617125",
      "brgy_name": "Sugod Proper",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617126",
      "brgy_name": "Tampilong",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617127",
      "brgy_name": "Timbangalan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617129",
      "brgy_name": "Tuca Ambolong",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617131",
      "brgy_name": "Tolali",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617133",
      "brgy_name": "Toros",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617134",
      "brgy_name": "Tuca",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617136",
      "brgy_name": "Tuca Marinaut",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617137",
      "brgy_name": "Tongantongan-Tuca Timbangalan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617138",
      "brgy_name": "Wawalayan Calocan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617139",
      "brgy_name": "Wawalayan Marinaut",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617141",
      "brgy_name": "Marawi Poblacion",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617142",
      "brgy_name": "Norhaya Village",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153617143",
      "brgy_name": "Papandayan Caniogan",
      "city_code": "153617",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618001",
      "brgy_name": "Abdullah Buisan",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618002",
      "brgy_name": "Caramian Alim Raya",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618003",
      "brgy_name": "Alip Lalabuan",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618004",
      "brgy_name": "Alumpang Paino Mimbalay",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618005",
      "brgy_name": "Mai Ditimbang Balindong",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618007",
      "brgy_name": "Mai Sindaoloan Dansalan",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618008",
      "brgy_name": "Lakadun",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618018",
      "brgy_name": "Maranat Bontalis",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618023",
      "brgy_name": "Dalog Balut",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618032",
      "brgy_name": "Gindolongan Alabat",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618034",
      "brgy_name": "Gondarangin Asa Adigao",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618035",
      "brgy_name": "Sawir",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618039",
      "brgy_name": "Moriatao-Bai Labay",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618040",
      "brgy_name": "Laila Lumbac Bacon",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618041",
      "brgy_name": "Lanco Dimapatoy",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618042",
      "brgy_name": "Talub Langi",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618043",
      "brgy_name": "Lomigis Sucod",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618044",
      "brgy_name": "Macabangan Imbala",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618045",
      "brgy_name": "Macadaag Talaguian",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618047",
      "brgy_name": "Macalupang Lumbac Caramian",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618052",
      "brgy_name": "Magayo Bagoaingud",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618053",
      "brgy_name": "Macompara Apa Mimbalay",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618055",
      "brgy_name": "Manalocon Talub",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618060",
      "brgy_name": "Putad Marandang Buisan",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618061",
      "brgy_name": "Matao Araza",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618062",
      "brgy_name": "Mocamad Tangul",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618069",
      "brgy_name": "Pantao",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618077",
      "brgy_name": "Sambowang Atawa",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618082",
      "brgy_name": "Tamboro Cormatan",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618083",
      "brgy_name": "Towanao Arangga",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618085",
      "brgy_name": "Tomambiling Lumbaca Ingud",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618088",
      "brgy_name": "Unda Dayawan",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618089",
      "brgy_name": "Buadi Amloy",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618090",
      "brgy_name": "Kalilangan",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153618091",
      "brgy_name": "Lumbaca Ingud",
      "city_code": "153618",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619002",
      "brgy_name": "Bangon",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619005",
      "brgy_name": "Bubonga Guilopa",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619006",
      "brgy_name": "Buadi-Abala",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619007",
      "brgy_name": "Buadi-Bayawa",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619008",
      "brgy_name": "Buadi-Insuba",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619010",
      "brgy_name": "Bubong",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619011",
      "brgy_name": "Cabasaran",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619013",
      "brgy_name": "Cairatan",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619014",
      "brgy_name": "Cormatan",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619015",
      "brgy_name": "Poblacion (Dado)",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619016",
      "brgy_name": "Dalama",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619018",
      "brgy_name": "Dansalan",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619019",
      "brgy_name": "Dimarao",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619022",
      "brgy_name": "Guilopa",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619024",
      "brgy_name": "Ilian",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619026",
      "brgy_name": "Kitambugun",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619027",
      "brgy_name": "Lama (Bagoaingud)",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619028",
      "brgy_name": "Lilod",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619030",
      "brgy_name": "Lilod Raybalai",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619033",
      "brgy_name": "Lumbaca Ingud",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619034",
      "brgy_name": "Lumbac (Lumbac Bubong)",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619035",
      "brgy_name": "Madaya",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619037",
      "brgy_name": "Pindolonan",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619038",
      "brgy_name": "Salipongan",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619039",
      "brgy_name": "Sugan",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153619043",
      "brgy_name": "Bagoaingud",
      "city_code": "153619",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620001",
      "brgy_name": "Ayong",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620005",
      "brgy_name": "Bandara Ingud",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620006",
      "brgy_name": "Bangon (Pob.)",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620011",
      "brgy_name": "Biala-an",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620015",
      "brgy_name": "Diampaca",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620019",
      "brgy_name": "Guiarong",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620020",
      "brgy_name": "Ilian",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620029",
      "brgy_name": "Madang",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620030",
      "brgy_name": "Mapantao",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620034",
      "brgy_name": "Ngingir (Kabasaran)",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620035",
      "brgy_name": "Padas",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620037",
      "brgy_name": "Paigoay",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620041",
      "brgy_name": "Pinalangca",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620042",
      "brgy_name": "Poblacion (Lumbac)",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620045",
      "brgy_name": "Rangiran",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620046",
      "brgy_name": "Rubokun",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620054",
      "brgy_name": "Linindingan",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153620057",
      "brgy_name": "Kalaludan",
      "city_code": "153620",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621001",
      "brgy_name": "Aposong",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621002",
      "brgy_name": "Bagoaingud",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621003",
      "brgy_name": "Bangco (Pob.)",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621004",
      "brgy_name": "Bansayan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621005",
      "brgy_name": "Basak",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621006",
      "brgy_name": "Bobo",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621007",
      "brgy_name": "Bubong Tawa-an",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621008",
      "brgy_name": "Bubonga Mamaanun",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621009",
      "brgy_name": "Bualan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621010",
      "brgy_name": "Bubong Ilian",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621011",
      "brgy_name": "Gacap",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621012",
      "brgy_name": "Katumbacan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621013",
      "brgy_name": "Ilian Poblacion",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621014",
      "brgy_name": "Kalanganan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621015",
      "brgy_name": "Lininding",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621017",
      "brgy_name": "Lumbaca Mamaan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621018",
      "brgy_name": "Mamaanun",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621020",
      "brgy_name": "Mentring",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621021",
      "brgy_name": "Olango",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621022",
      "brgy_name": "Palacat",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621023",
      "brgy_name": "Palao",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621024",
      "brgy_name": "Paling",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621025",
      "brgy_name": "Pantaon",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621026",
      "brgy_name": "Pantar",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621028",
      "brgy_name": "Paridi",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621029",
      "brgy_name": "Pindolonan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621030",
      "brgy_name": "Radapan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621031",
      "brgy_name": "Radapan Poblacion",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621032",
      "brgy_name": "Sapingit",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621033",
      "brgy_name": "Talao",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621034",
      "brgy_name": "Tambo",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621035",
      "brgy_name": "Tapocan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621036",
      "brgy_name": "Taporug",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621037",
      "brgy_name": "Tawaan",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621038",
      "brgy_name": "Udalo",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621039",
      "brgy_name": "Rantian",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153621040",
      "brgy_name": "Ilian",
      "city_code": "153621",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622001",
      "brgy_name": "Ataragadong",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622002",
      "brgy_name": "Bangon",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622004",
      "brgy_name": "Bansayan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622006",
      "brgy_name": "Bubong-Dimunda",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622007",
      "brgy_name": "Bugaran",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622008",
      "brgy_name": "Bualan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622011",
      "brgy_name": "Cadayonan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622012",
      "brgy_name": "Calilangan Dicala",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622013",
      "brgy_name": "Calupaan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622014",
      "brgy_name": "Dimayon",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622015",
      "brgy_name": "Dilausan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622018",
      "brgy_name": "Dongcoan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622019",
      "brgy_name": "Gadongan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622020",
      "brgy_name": "Poblacion (Gata Proper)",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622024",
      "brgy_name": "Liangan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622026",
      "brgy_name": "Lumbac",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622027",
      "brgy_name": "Lumbaca Ingud",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622030",
      "brgy_name": "Madanding",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622034",
      "brgy_name": "Pantao",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622035",
      "brgy_name": "Punud",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622036",
      "brgy_name": "Ragayan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622037",
      "brgy_name": "Rogan Cairan",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622041",
      "brgy_name": "Talaguian",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622042",
      "brgy_name": "Rogan Tandiong Dimayon",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153622043",
      "brgy_name": "Taporog",
      "city_code": "153622",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623001",
      "brgy_name": "Badak",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623002",
      "brgy_name": "Bantayan",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623003",
      "brgy_name": "Basagad",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623005",
      "brgy_name": "Bolinsong",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623006",
      "brgy_name": "Boring",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623008",
      "brgy_name": "Bualan",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623012",
      "brgy_name": "Danugan",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623013",
      "brgy_name": "Dapao",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623014",
      "brgy_name": "Diamla",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623015",
      "brgy_name": "Gadongan",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623018",
      "brgy_name": "Ingud",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623022",
      "brgy_name": "Linuk",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623023",
      "brgy_name": "Lumbac",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623024",
      "brgy_name": "Maligo",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623025",
      "brgy_name": "Masao",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623026",
      "brgy_name": "Notong",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623030",
      "brgy_name": "Porug",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623031",
      "brgy_name": "Romagondong",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623033",
      "brgy_name": "Tambo (Pob.)",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623034",
      "brgy_name": "Tamlang",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623035",
      "brgy_name": "Tuka",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623036",
      "brgy_name": "Tomarompong",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153623037",
      "brgy_name": "Yaran",
      "city_code": "153623",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624001",
      "brgy_name": "Bagoaingud",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624006",
      "brgy_name": "Barimbingan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624009",
      "brgy_name": "Bayabao",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624012",
      "brgy_name": "Buadi Babai",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624013",
      "brgy_name": "Buadi Alao",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624014",
      "brgy_name": "Buadi Oloc",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624016",
      "brgy_name": "Pagalongan Buadiadingan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624024",
      "brgy_name": "Dado",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624027",
      "brgy_name": "Dangimprampiai",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624028",
      "brgy_name": "Darimbang",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624030",
      "brgy_name": "Dilausan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624032",
      "brgy_name": "Ditsaan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624033",
      "brgy_name": "Gadongan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624038",
      "brgy_name": "Pagalongan Ginaopan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624042",
      "brgy_name": "Baclayan Lilod",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624045",
      "brgy_name": "Linamon",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624048",
      "brgy_name": "Lumbatan Ramain",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624054",
      "brgy_name": "Buayaan Madanding",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624055",
      "brgy_name": "Maindig Ditsaan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624057",
      "brgy_name": "Mandara",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624058",
      "brgy_name": "Maranao Timber (Dalama)",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624064",
      "brgy_name": "Pagalongan Proper",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624070",
      "brgy_name": "Polo",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624077",
      "brgy_name": "Ramain Poblacion",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624078",
      "brgy_name": "Ramain Proper",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624079",
      "brgy_name": "Baclayan Raya",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624081",
      "brgy_name": "Buayaan Raya",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624082",
      "brgy_name": "Rantian",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624086",
      "brgy_name": "Sundiga Bayabao",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624087",
      "brgy_name": "Talub",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624091",
      "brgy_name": "Buayaan Lilod",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624092",
      "brgy_name": "Bubong Dangiprampiai",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624093",
      "brgy_name": "Pagalongan Masioon",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624094",
      "brgy_name": "Sultan Pangadapun",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153624095",
      "brgy_name": "Upper Pugaan",
      "city_code": "153624",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625001",
      "brgy_name": "Alinun",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625002",
      "brgy_name": "Bagoaingud",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625004",
      "brgy_name": "Batangan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625005",
      "brgy_name": "Cadayon",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625006",
      "brgy_name": "Cadingilan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625007",
      "brgy_name": "Lumbac Toros",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625008",
      "brgy_name": "Comonal",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625009",
      "brgy_name": "Dilausan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625010",
      "brgy_name": "Gadongan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625012",
      "brgy_name": "Linao",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625013",
      "brgy_name": "Limogao",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625015",
      "brgy_name": "Lumbayanague",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625018",
      "brgy_name": "Basak Maito",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625019",
      "brgy_name": "Maliwanag",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625020",
      "brgy_name": "Mapantao",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625021",
      "brgy_name": "Mipaga",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625022",
      "brgy_name": "Natangcopan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625023",
      "brgy_name": "Pagalamatan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625025",
      "brgy_name": "Pamacotan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625026",
      "brgy_name": "Panggao",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625027",
      "brgy_name": "Pantao Raya",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625028",
      "brgy_name": "Pantaon",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625029",
      "brgy_name": "Patpangkat",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625030",
      "brgy_name": "Pawak",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625031",
      "brgy_name": "Dilimbayan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625032",
      "brgy_name": "Pindolonan",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625033",
      "brgy_name": "Poblacion",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625035",
      "brgy_name": "Salocad",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625036",
      "brgy_name": "Sungcod",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153625037",
      "brgy_name": "Bubong",
      "city_code": "153625",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626001",
      "brgy_name": "Bocalan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626002",
      "brgy_name": "Bangon",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626004",
      "brgy_name": "Cabasaran",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626007",
      "brgy_name": "Dilausan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626008",
      "brgy_name": "Lalabuan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626009",
      "brgy_name": "Lilod Tamparan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626010",
      "brgy_name": "Lindongan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626011",
      "brgy_name": "Linuk",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626012",
      "brgy_name": "Occidental Linuk",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626013",
      "brgy_name": "Linuk Oriental",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626014",
      "brgy_name": "Lumbaca Ingud",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626015",
      "brgy_name": "Lumbacaingud South",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626016",
      "brgy_name": "Lumbaca Lilod",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626019",
      "brgy_name": "Balutmadiar",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626020",
      "brgy_name": "Mala-abangon",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626021",
      "brgy_name": "Maliwanag",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626022",
      "brgy_name": "Maidan Linuk",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626024",
      "brgy_name": "Miondas",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626027",
      "brgy_name": "New Lumbacaingud",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626030",
      "brgy_name": "Pimbago-Pagalongan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626032",
      "brgy_name": "Pagayawan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626034",
      "brgy_name": "Picarabawan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626037",
      "brgy_name": "Poblacion I",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626038",
      "brgy_name": "Poblacion II",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626039",
      "brgy_name": "Poblacion III",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626040",
      "brgy_name": "Poblacion IV",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626041",
      "brgy_name": "Raya Niondas",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626042",
      "brgy_name": "Raya Buadi Barao",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626043",
      "brgy_name": "Raya Tamparan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626046",
      "brgy_name": "Salongabanding",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626047",
      "brgy_name": "Saminunggay",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626049",
      "brgy_name": "Talub",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626050",
      "brgy_name": "Tatayawan North",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626052",
      "brgy_name": "Tatayawan South",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626054",
      "brgy_name": "Tubok",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626058",
      "brgy_name": "Beruar",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626062",
      "brgy_name": "Dasomalong",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626064",
      "brgy_name": "Ginaopan",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626065",
      "brgy_name": "Lumbac",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626067",
      "brgy_name": "Minanga",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626077",
      "brgy_name": "Lilod Tubok",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626078",
      "brgy_name": "Mariatao Datu",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626079",
      "brgy_name": "Pagalamatan Linuk",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153626080",
      "brgy_name": "Pindolonan Mariatao Sarip",
      "city_code": "153626",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627003",
      "brgy_name": "Bandera Buisan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627006",
      "brgy_name": "Boriongan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627007",
      "brgy_name": "Borowa",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627008",
      "brgy_name": "Buadi Dingun",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627009",
      "brgy_name": "Buadi Amao",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627010",
      "brgy_name": "Buadi Amunta",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627011",
      "brgy_name": "Buadi Arorao",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627012",
      "brgy_name": "Buadi Atopa",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627013",
      "brgy_name": "Buadi Dayomangga",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627014",
      "brgy_name": "Buadi Ongcalo",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627016",
      "brgy_name": "Bucalan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627017",
      "brgy_name": "Cadayonan Bagumbayan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627018",
      "brgy_name": "Caramat",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627019",
      "brgy_name": "Carandangan Calopaan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627021",
      "brgy_name": "Datu Ma-as",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627022",
      "brgy_name": "Dilabayan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627024",
      "brgy_name": "Dimayon",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627028",
      "brgy_name": "Gapao Balindong",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627029",
      "brgy_name": "Ilian",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627035",
      "brgy_name": "Lumasa",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627036",
      "brgy_name": "Lumbac Bagoaingud",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627037",
      "brgy_name": "Lumbac Bubong Maindang",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627038",
      "brgy_name": "Lumbac Pitakus",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627042",
      "brgy_name": "Malungun",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627043",
      "brgy_name": "Maruhom",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627046",
      "brgy_name": "Masolun",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627047",
      "brgy_name": "Moriatao Loksa Datu",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627049",
      "brgy_name": "Pagalamatan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627050",
      "brgy_name": "Pindolonan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627051",
      "brgy_name": "Pitakus",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627053",
      "brgy_name": "Ririk",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627055",
      "brgy_name": "Salipongan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627056",
      "brgy_name": "Lumasa Proper (Salvador Concha)",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627057",
      "brgy_name": "Sambolawan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627058",
      "brgy_name": "Samporna Salamatollah",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627060",
      "brgy_name": "Somiorang Bandingun",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627061",
      "brgy_name": "Sigayan Proper",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627062",
      "brgy_name": "Sunggod",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627063",
      "brgy_name": "Sunding",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627064",
      "brgy_name": "Supangan",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627069",
      "brgy_name": "Tupa-an Buadiatupa",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627070",
      "brgy_name": "Buadi Amunud",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153627071",
      "brgy_name": "Mangayao",
      "city_code": "153627",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628001",
      "brgy_name": "Alog",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628006",
      "brgy_name": "Beta",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628009",
      "brgy_name": "Poblacion (Buribid)",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628012",
      "brgy_name": "Campo",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628015",
      "brgy_name": "Datumanong",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628016",
      "brgy_name": "Dinaigan",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628017",
      "brgy_name": "Guiarong",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628023",
      "brgy_name": "Mindamudag",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628030",
      "brgy_name": "Paigoay-Pimbataan",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628031",
      "brgy_name": "Polo",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628033",
      "brgy_name": "Riantaran",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628036",
      "brgy_name": "Tangcal",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628037",
      "brgy_name": "Tubaran Proper",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628038",
      "brgy_name": "Wago",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628039",
      "brgy_name": "Bagiangun",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628040",
      "brgy_name": "Gadongan",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628041",
      "brgy_name": "Gaput",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628042",
      "brgy_name": "Madaya",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628043",
      "brgy_name": "Malaganding",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628046",
      "brgy_name": "Metadicop",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153628047",
      "brgy_name": "Pagalamatan",
      "city_code": "153628",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629001",
      "brgy_name": "Bagoaingud",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629004",
      "brgy_name": "Bubong",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629005",
      "brgy_name": "Buadi Alawang",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629006",
      "brgy_name": "Buadi Dico",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629010",
      "brgy_name": "Campong Talao",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629011",
      "brgy_name": "Cayagan",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629012",
      "brgy_name": "Dandanun",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629014",
      "brgy_name": "Dilimbayan",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629016",
      "brgy_name": "Gurain",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629017",
      "brgy_name": "Poblacion (Ingud)",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629019",
      "brgy_name": "Lumbac",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629021",
      "brgy_name": "Maidan",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629022",
      "brgy_name": "Mapantao",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629025",
      "brgy_name": "Pagalamatan",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629026",
      "brgy_name": "Pandiaranao",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629027",
      "brgy_name": "Pindolonan I",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629028",
      "brgy_name": "Pindolonan II",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629030",
      "brgy_name": "Putad",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629031",
      "brgy_name": "Raya",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629034",
      "brgy_name": "Sugod I",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629036",
      "brgy_name": "Sugod Mawatan",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629037",
      "brgy_name": "Sumbaga Rogong",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153629038",
      "brgy_name": "Tangcal",
      "city_code": "153629",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630001",
      "brgy_name": "Amoyong",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630002",
      "brgy_name": "Balatin",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630003",
      "brgy_name": "Banga",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630006",
      "brgy_name": "Buntongun",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630007",
      "brgy_name": "Bo-ot",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630009",
      "brgy_name": "Cebuano Group",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630010",
      "brgy_name": "Christian Village",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630011",
      "brgy_name": "Eastern Wao",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630012",
      "brgy_name": "Extension Poblacion",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630014",
      "brgy_name": "Gata",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630016",
      "brgy_name": "Kabatangan",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630017",
      "brgy_name": "Kadingilan",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630019",
      "brgy_name": "Katutungan (Pob.)",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630021",
      "brgy_name": "Kilikili East",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630022",
      "brgy_name": "Kilikili West",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630025",
      "brgy_name": "Malaigang",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630026",
      "brgy_name": "Manila Group",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630029",
      "brgy_name": "Milaya",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630030",
      "brgy_name": "Mimbuaya",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630031",
      "brgy_name": "Muslim Village",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630033",
      "brgy_name": "Pagalongan",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630034",
      "brgy_name": "Panang",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630035",
      "brgy_name": "Park Area (Pob.)",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630036",
      "brgy_name": "Pilintangan",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630038",
      "brgy_name": "Serran Village",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153630042",
      "brgy_name": "Western Wao (Pob.)",
      "city_code": "153630",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631001",
      "brgy_name": "Balut",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631002",
      "brgy_name": "Bagumbayan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631004",
      "brgy_name": "Bitayan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631005",
      "brgy_name": "Bolawan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631006",
      "brgy_name": "Bonga",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631008",
      "brgy_name": "Cabasaran",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631009",
      "brgy_name": "Cahera",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631010",
      "brgy_name": "Cairantang",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631011",
      "brgy_name": "Canibongan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631012",
      "brgy_name": "Diragun",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631015",
      "brgy_name": "Mantailoco",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631016",
      "brgy_name": "Mapantao",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631017",
      "brgy_name": "Marogong East",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631018",
      "brgy_name": "Marogong Proper (Pob.)",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631020",
      "brgy_name": "Mayaman",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631021",
      "brgy_name": "Pabrica",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631023",
      "brgy_name": "Paigoay Coda",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631024",
      "brgy_name": "Pasayanan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631026",
      "brgy_name": "Piangologan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631027",
      "brgy_name": "Puracan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631028",
      "brgy_name": "Romagondong",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631029",
      "brgy_name": "Sarang",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631031",
      "brgy_name": "Cadayonan",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153631032",
      "brgy_name": "Calumbog",
      "city_code": "153631",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632004",
      "brgy_name": "Bubonga Ranao",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632008",
      "brgy_name": "Calalaoan (Pob.)",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632015",
      "brgy_name": "Gas",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632018",
      "brgy_name": "Inudaran",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632019",
      "brgy_name": "Inoma",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632031",
      "brgy_name": "Luguna",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632038",
      "brgy_name": "Mimbalawag",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632041",
      "brgy_name": "Ngingir",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632042",
      "brgy_name": "Pagalongan",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632043",
      "brgy_name": "Panggawalupa",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632044",
      "brgy_name": "Pantaon",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632048",
      "brgy_name": "Piksan",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632050",
      "brgy_name": "Pindolonan",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632052",
      "brgy_name": "Punud",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632054",
      "brgy_name": "Tagoranao",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632055",
      "brgy_name": "Taliboboka",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153632056",
      "brgy_name": "Tambac",
      "city_code": "153632",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633001",
      "brgy_name": "Bacolod",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633003",
      "brgy_name": "Bangon",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633005",
      "brgy_name": "Buadiposo Lilod",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633006",
      "brgy_name": "Buadiposo Proper",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633007",
      "brgy_name": "Bubong",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633008",
      "brgy_name": "Buntong Proper",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633009",
      "brgy_name": "Cadayonan",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633010",
      "brgy_name": "Dansalan",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633011",
      "brgy_name": "Gata",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633013",
      "brgy_name": "Kalakala",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633014",
      "brgy_name": "Katogonan",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633015",
      "brgy_name": "Lumbac",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633017",
      "brgy_name": "Lumbatan Manacab",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633018",
      "brgy_name": "Lumbatan Pataingud",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633019",
      "brgy_name": "Manacab (Pob.)",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633020",
      "brgy_name": "Minanga (Buntong)",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633021",
      "brgy_name": "Paling",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633022",
      "brgy_name": "Pindolonan",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633023",
      "brgy_name": "Pualas",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633025",
      "brgy_name": "Buadiposo Raya",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633026",
      "brgy_name": "Sapot",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633029",
      "brgy_name": "Tangcal",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633030",
      "brgy_name": "Tarik",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633031",
      "brgy_name": "Tuka",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633033",
      "brgy_name": "Bangon Proper",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633035",
      "brgy_name": "Raya Buntong (Buntong East)",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633037",
      "brgy_name": "Lunduban (Ragondingan)",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633038",
      "brgy_name": "Ragondingan East",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633039",
      "brgy_name": "Ragondingan Proper",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633040",
      "brgy_name": "Ragondingan West",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633042",
      "brgy_name": "Boto Ragondingan",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633043",
      "brgy_name": "Datu Tambara",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153633044",
      "brgy_name": "Dirisan",
      "city_code": "153633",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634001",
      "brgy_name": "Agagan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634003",
      "brgy_name": "Balagunun",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634004",
      "brgy_name": "Bolao",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634005",
      "brgy_name": "Balawag",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634006",
      "brgy_name": "Balintao",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634007",
      "brgy_name": "Borocot",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634008",
      "brgy_name": "Bato-bato",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634009",
      "brgy_name": "Borrowa",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634011",
      "brgy_name": "Buadiangkay",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634013",
      "brgy_name": "Bubong Bayabao",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634014",
      "brgy_name": "Botud",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634015",
      "brgy_name": "Camalig",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634016",
      "brgy_name": "Cambong",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634019",
      "brgy_name": "Dilausan (Pob.)",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634020",
      "brgy_name": "Dilimbayan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634024",
      "brgy_name": "Ilalag",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634025",
      "brgy_name": "Kianodan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634028",
      "brgy_name": "Lumbac",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634030",
      "brgy_name": "Madanding",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634031",
      "brgy_name": "Madaya",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634032",
      "brgy_name": "Maguing Proper",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634033",
      "brgy_name": "Malungun",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634037",
      "brgy_name": "Pagalongan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634038",
      "brgy_name": "Panayangan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634041",
      "brgy_name": "Pilimoknan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634045",
      "brgy_name": "Ragayan (Rungayan)",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634048",
      "brgy_name": "Lilod Maguing",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634051",
      "brgy_name": "Bubong",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634052",
      "brgy_name": "Lilod Borocot",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634053",
      "brgy_name": "Malungun Borocot",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634054",
      "brgy_name": "Malungun Pagalongan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634055",
      "brgy_name": "Sabala Dilausan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634056",
      "brgy_name": "Lumbac-Dimarao",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153634057",
      "brgy_name": "Pindolonan",
      "city_code": "153634",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635004",
      "brgy_name": "Bara-as",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635007",
      "brgy_name": "Biasong",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635009",
      "brgy_name": "Bulangos",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635015",
      "brgy_name": "Durian",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635016",
      "brgy_name": "Ilian",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635024",
      "brgy_name": "Liangan (Pob.)",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635028",
      "brgy_name": "Maganding",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635029",
      "brgy_name": "Maladi",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635035",
      "brgy_name": "Mapantao",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635037",
      "brgy_name": "Micalubo",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635041",
      "brgy_name": "Pindolonan",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635042",
      "brgy_name": "Punong",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635043",
      "brgy_name": "Ramitan",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635049",
      "brgy_name": "Torogan",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635050",
      "brgy_name": "Tual",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635052",
      "brgy_name": "Tuca",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635053",
      "brgy_name": "Ubanoban",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635054",
      "brgy_name": "Anas",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153635061",
      "brgy_name": "Mimbalawag",
      "city_code": "153635",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636004",
      "brgy_name": "Bagoaingud",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636005",
      "brgy_name": "Balaigay",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636007",
      "brgy_name": "Bualan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636008",
      "brgy_name": "Cadingilan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636009",
      "brgy_name": "Casalayan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636010",
      "brgy_name": "Dala (Dalama)",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636012",
      "brgy_name": "Dilimbayan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636013",
      "brgy_name": "Cabuntungan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636014",
      "brgy_name": "Lamin",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636015",
      "brgy_name": "Diromoyod",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636017",
      "brgy_name": "Kabasaran (Pob.)",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636018",
      "brgy_name": "Nanagun",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636019",
      "brgy_name": "Mapantao-Balangas",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636020",
      "brgy_name": "Miniros",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636022",
      "brgy_name": "Pantaon",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636023",
      "brgy_name": "Pindolonan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636025",
      "brgy_name": "Pitatanglan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636026",
      "brgy_name": "Poctan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636030",
      "brgy_name": "Singcara",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636033",
      "brgy_name": "Wago",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636035",
      "brgy_name": "Cadayonan",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153636036",
      "brgy_name": "Cadingilan A",
      "city_code": "153636",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637001",
      "brgy_name": "Poblacion (Apartfort)",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637002",
      "brgy_name": "Bagumbayan",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637003",
      "brgy_name": "Bandara-Ingud",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637005",
      "brgy_name": "Comara",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637006",
      "brgy_name": "Frankfort",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637008",
      "brgy_name": "Lambanogan",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637009",
      "brgy_name": "Lico",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637010",
      "brgy_name": "Mansilano",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637012",
      "brgy_name": "Natangcopan",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637013",
      "brgy_name": "Pagonayan",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637014",
      "brgy_name": "Pagalamatan",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637015",
      "brgy_name": "Piagma",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637016",
      "brgy_name": "Punud",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637017",
      "brgy_name": "Ranao-Baning",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637018",
      "brgy_name": "Salam",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637019",
      "brgy_name": "Sagua-an",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153637021",
      "brgy_name": "Sumugot",
      "city_code": "153637",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638002",
      "brgy_name": "Bantalan",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638004",
      "brgy_name": "Bayog",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638005",
      "brgy_name": "Cadayonan",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638006",
      "brgy_name": "Dagonalan",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638007",
      "brgy_name": "Dimalama",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638009",
      "brgy_name": "Gayakay",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638012",
      "brgy_name": "Inodaran",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638013",
      "brgy_name": "Kalilangan",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638015",
      "brgy_name": "Kianibong",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638017",
      "brgy_name": "Kingan",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638018",
      "brgy_name": "Kitaon",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638024",
      "brgy_name": "Bagoaingud",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638025",
      "brgy_name": "Malinao",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638026",
      "brgy_name": "Malingon",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638027",
      "brgy_name": "Mama-an Pagalongan",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638029",
      "brgy_name": "Marawi",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638030",
      "brgy_name": "Maimbaguiang",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638041",
      "brgy_name": "Sigayan",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153638042",
      "brgy_name": "Tagoloan Poblacion",
      "city_code": "153638",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639001",
      "brgy_name": "Bakikis",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639002",
      "brgy_name": "Barao",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639003",
      "brgy_name": "Bongabong",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639004",
      "brgy_name": "Daguan",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639005",
      "brgy_name": "Inudaran",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639006",
      "brgy_name": "Kabaniakawan",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639007",
      "brgy_name": "Kapatagan",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639008",
      "brgy_name": "Lusain",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639009",
      "brgy_name": "Matimos",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639010",
      "brgy_name": "Minimao",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639011",
      "brgy_name": "Pinantao",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639012",
      "brgy_name": "Salaman",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639013",
      "brgy_name": "Sigayan",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639014",
      "brgy_name": "Tabuan",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153639015",
      "brgy_name": "Upper Igabay",
      "city_code": "153639",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153640001",
      "brgy_name": "Bacayawan",
      "city_code": "153640",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153640002",
      "brgy_name": "Buta (Sumalindao)",
      "city_code": "153640",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153640003",
      "brgy_name": "Dinganun Guilopa (Dingunun)",
      "city_code": "153640",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153640004",
      "brgy_name": "Lumbac",
      "city_code": "153640",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153640005",
      "brgy_name": "Malalis",
      "city_code": "153640",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153640006",
      "brgy_name": "Pagalongan",
      "city_code": "153640",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153640007",
      "brgy_name": "Tagoranao",
      "city_code": "153640",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641001",
      "brgy_name": "Bangon (Dilausan)",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641002",
      "brgy_name": "Beta",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641003",
      "brgy_name": "Calalon",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641004",
      "brgy_name": "Calipapa",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641005",
      "brgy_name": "Dilausan",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641006",
      "brgy_name": "Dimapaok",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641007",
      "brgy_name": "Lumbac Dilausan",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641008",
      "brgy_name": "Oriental Beta",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153641009",
      "brgy_name": "Tringun",
      "city_code": "153641",
      "province_code": "1536",
      "region_code": "15"
    },
    {
      "brgy_code": "153801004",
      "brgy_name": "Dicalongan (Pob.)",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801011",
      "brgy_name": "Kakal",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801012",
      "brgy_name": "Kamasi",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801014",
      "brgy_name": "Kapinpilan",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801015",
      "brgy_name": "Kauran",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801018",
      "brgy_name": "Malatimon",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801022",
      "brgy_name": "Matagabong",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801028",
      "brgy_name": "Saniag",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801031",
      "brgy_name": "Tomicor",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801032",
      "brgy_name": "Tubak",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153801035",
      "brgy_name": "Salman",
      "city_code": "153801",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802001",
      "brgy_name": "Ampuan",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802002",
      "brgy_name": "Aratuc",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802005",
      "brgy_name": "Cabayuan",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802007",
      "brgy_name": "Calaan (Pob.)",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802008",
      "brgy_name": "Karim",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802009",
      "brgy_name": "Dinganen",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802011",
      "brgy_name": "Edcor (Gallego Edcor)",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802012",
      "brgy_name": "Kulimpang",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802017",
      "brgy_name": "Mataya",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802018",
      "brgy_name": "Minabay",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802020",
      "brgy_name": "Nuyo",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802021",
      "brgy_name": "Oring",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802022",
      "brgy_name": "Pantawan",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802023",
      "brgy_name": "Piers",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153802025",
      "brgy_name": "Rumidas",
      "city_code": "153802",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153803008",
      "brgy_name": "Digal",
      "city_code": "153803",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153803023",
      "brgy_name": "Lower Siling",
      "city_code": "153803",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153803030",
      "brgy_name": "Maslabeng",
      "city_code": "153803",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153803039",
      "brgy_name": "Poblacion",
      "city_code": "153803",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153803040",
      "brgy_name": "Popol",
      "city_code": "153803",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153803045",
      "brgy_name": "Talitay",
      "city_code": "153803",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153803054",
      "brgy_name": "Upper Siling",
      "city_code": "153803",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805001",
      "brgy_name": "Alip (Pob.)",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805003",
      "brgy_name": "Damawato",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805004",
      "brgy_name": "Katil",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805006",
      "brgy_name": "Malala",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805007",
      "brgy_name": "Mangadeg",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805008",
      "brgy_name": "Manindolo",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805010",
      "brgy_name": "Puya",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805011",
      "brgy_name": "Sepaka",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805013",
      "brgy_name": "Lomoyon",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805014",
      "brgy_name": "Kalumenga (Kalumanga)",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805015",
      "brgy_name": "Palao sa Buto",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805016",
      "brgy_name": "Damalusay",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805017",
      "brgy_name": "Bonawan",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805018",
      "brgy_name": "Bulod",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805019",
      "brgy_name": "Datang",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805020",
      "brgy_name": "Elbebe",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805021",
      "brgy_name": "Lipao",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805022",
      "brgy_name": "Madidis",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805023",
      "brgy_name": "Makat",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805024",
      "brgy_name": "Mao",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805025",
      "brgy_name": "Napok",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805026",
      "brgy_name": "Poblacion",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153805027",
      "brgy_name": "Salendab",
      "city_code": "153805",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806001",
      "brgy_name": "Alonganan",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806002",
      "brgy_name": "Ambadao",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806005",
      "brgy_name": "Balanakan",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806006",
      "brgy_name": "Balong",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806008",
      "brgy_name": "Buayan",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806010",
      "brgy_name": "Dado",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806011",
      "brgy_name": "Damabalas",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806015",
      "brgy_name": "Duaminanga",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806021",
      "brgy_name": "Kalipapa",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806025",
      "brgy_name": "Liong",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806028",
      "brgy_name": "Magaslong",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806029",
      "brgy_name": "Masigay",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806030",
      "brgy_name": "Montay",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806034",
      "brgy_name": "Poblacion (Dulawan)",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806035",
      "brgy_name": "Reina Regente",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153806039",
      "brgy_name": "Kanguan",
      "city_code": "153806",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807001",
      "brgy_name": "Ambolodto",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807002",
      "brgy_name": "Awang",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807003",
      "brgy_name": "Badak",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807004",
      "brgy_name": "Bagoenged",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807005",
      "brgy_name": "Baka",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807006",
      "brgy_name": "Benolen",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807007",
      "brgy_name": "Bitu",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807008",
      "brgy_name": "Bongued",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807009",
      "brgy_name": "Bugawas",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807010",
      "brgy_name": "Capiton",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807011",
      "brgy_name": "Dados",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807013",
      "brgy_name": "Dalican Poblacion",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807014",
      "brgy_name": "Dinaig Proper",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807015",
      "brgy_name": "Dulangan",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807017",
      "brgy_name": "Kakar",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807018",
      "brgy_name": "Kenebeka",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807019",
      "brgy_name": "Kurintem",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807020",
      "brgy_name": "Kusiong",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807021",
      "brgy_name": "Labungan",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807022",
      "brgy_name": "Linek",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807023",
      "brgy_name": "Makir",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807024",
      "brgy_name": "Margues",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807025",
      "brgy_name": "Nekitan",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807026",
      "brgy_name": "Mompong",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807027",
      "brgy_name": "Sapalan",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807028",
      "brgy_name": "Semba",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807029",
      "brgy_name": "Sibuto",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807030",
      "brgy_name": "Sifaren (Sifaran)",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807031",
      "brgy_name": "Tambak",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807032",
      "brgy_name": "Tamontaka",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807033",
      "brgy_name": "Tanuel",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807034",
      "brgy_name": "Tapian",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807035",
      "brgy_name": "Taviran",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153807036",
      "brgy_name": "Tenonggos",
      "city_code": "153807",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808002",
      "brgy_name": "Bagong",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808004",
      "brgy_name": "Bialong",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808011",
      "brgy_name": "Kuloy",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808012",
      "brgy_name": "Labu-labu",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808013",
      "brgy_name": "Lapok (Lepok)",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808023",
      "brgy_name": "Malingao",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808034",
      "brgy_name": "Poblacion",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808037",
      "brgy_name": "Satan",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808038",
      "brgy_name": "Tapikan",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808039",
      "brgy_name": "Timbangan",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808040",
      "brgy_name": "Tina",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808046",
      "brgy_name": "Poblacion I",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153808047",
      "brgy_name": "Poblacion II",
      "city_code": "153808",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809001",
      "brgy_name": "Bayanga Norte",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809002",
      "brgy_name": "Bayanga Sur",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809003",
      "brgy_name": "Bugasan Norte",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809004",
      "brgy_name": "Bugasan Sur (Pob.)",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809005",
      "brgy_name": "Kidama",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809007",
      "brgy_name": "Sapad",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809008",
      "brgy_name": "Langco",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153809009",
      "brgy_name": "Langkong",
      "city_code": "153809",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810001",
      "brgy_name": "Bagoenged",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810004",
      "brgy_name": "Buliok",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810006",
      "brgy_name": "Damalasak",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810008",
      "brgy_name": "Galakit",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810009",
      "brgy_name": "Inug-ug",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810010",
      "brgy_name": "Kalbugan",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810013",
      "brgy_name": "Kilangan",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810014",
      "brgy_name": "Kudal",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810015",
      "brgy_name": "Layog",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810017",
      "brgy_name": "Linandangan",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810021",
      "brgy_name": "Poblacion",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153810025",
      "brgy_name": "Dalgan",
      "city_code": "153810",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811008",
      "brgy_name": "Gadungan",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811009",
      "brgy_name": "Gumagadong Calawag",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811010",
      "brgy_name": "Guiday T. Biruar",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811012",
      "brgy_name": "Landasan (Sarmiento)",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811013",
      "brgy_name": "Limbayan",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811014",
      "brgy_name": "Bongo Island (Litayen)",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811015",
      "brgy_name": "Magsaysay",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811016",
      "brgy_name": "Making",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811017",
      "brgy_name": "Nituan",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811018",
      "brgy_name": "Orandang",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811019",
      "brgy_name": "Pinantao",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811020",
      "brgy_name": "Poblacion",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811021",
      "brgy_name": "Polloc",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811023",
      "brgy_name": "Tagudtongan",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811024",
      "brgy_name": "Tuca-Maror",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811025",
      "brgy_name": "Manion",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811026",
      "brgy_name": "Macasandag",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811027",
      "brgy_name": "Cotongan",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811028",
      "brgy_name": "Poblacion II",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811029",
      "brgy_name": "Samberen",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811030",
      "brgy_name": "Kabuan",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811031",
      "brgy_name": "Campo Islam",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811032",
      "brgy_name": "Datu Macarimbang Biruar",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811033",
      "brgy_name": "Gadunganpedpandaran",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153811034",
      "brgy_name": "Moro Point",
      "city_code": "153811",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812001",
      "brgy_name": "Alamada",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812003",
      "brgy_name": "Banatin",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812004",
      "brgy_name": "Banubo",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812006",
      "brgy_name": "Bulalo",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812007",
      "brgy_name": "Bulibod",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812009",
      "brgy_name": "Calsada",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812010",
      "brgy_name": "Crossing Simuay",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812012",
      "brgy_name": "Dalumangcob (Pob.)",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812013",
      "brgy_name": "Darapanan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812014",
      "brgy_name": "Gang",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812015",
      "brgy_name": "Inawan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812016",
      "brgy_name": "Kabuntalan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812017",
      "brgy_name": "Kakar",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812018",
      "brgy_name": "Kapimpilan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812019",
      "brgy_name": "Katidtuan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812020",
      "brgy_name": "Katuli",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812023",
      "brgy_name": "Ladia",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812024",
      "brgy_name": "Limbo",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812026",
      "brgy_name": "Maidapa",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812027",
      "brgy_name": "Makaguiling",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812028",
      "brgy_name": "Katamlangan (Matampay)",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812029",
      "brgy_name": "Matengen",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812030",
      "brgy_name": "Mulaug",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812031",
      "brgy_name": "Nalinan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812033",
      "brgy_name": "Nekitan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812034",
      "brgy_name": "Olas",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812035",
      "brgy_name": "Panatan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812036",
      "brgy_name": "Pigcalagan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812037",
      "brgy_name": "Pigkelegan (Ibotegen)",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812038",
      "brgy_name": "Pinaring",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812039",
      "brgy_name": "Pingping",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812040",
      "brgy_name": "Raguisi",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812041",
      "brgy_name": "Rebuken",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812042",
      "brgy_name": "Salimbao",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812043",
      "brgy_name": "Sambolawan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812044",
      "brgy_name": "Senditan",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812051",
      "brgy_name": "Ungap",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812052",
      "brgy_name": "Damaniog",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153812053",
      "brgy_name": "Nara",
      "city_code": "153812",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813001",
      "brgy_name": "Angkayamat",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813003",
      "brgy_name": "Barurao",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813004",
      "brgy_name": "Bulod",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813006",
      "brgy_name": "Darampua",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813008",
      "brgy_name": "Gadungan",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813013",
      "brgy_name": "Kulambog",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813015",
      "brgy_name": "Langgapanan",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813021",
      "brgy_name": "Masulot",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813028",
      "brgy_name": "Papakan",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813045",
      "brgy_name": "Tugal",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813046",
      "brgy_name": "Tukanakuden",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153813050",
      "brgy_name": "Paldong",
      "city_code": "153813",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814001",
      "brgy_name": "Bagumbayan",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814003",
      "brgy_name": "Dadtumog (Dadtumeg)",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814005",
      "brgy_name": "Gambar",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814006",
      "brgy_name": "Ganta",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814008",
      "brgy_name": "Katidtuan",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814009",
      "brgy_name": "Langeban",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814011",
      "brgy_name": "Liong",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814012",
      "brgy_name": "Maitong",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814013",
      "brgy_name": "Matilak",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814014",
      "brgy_name": "Pagalungan",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814015",
      "brgy_name": "Payan",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814016",
      "brgy_name": "Pened",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814017",
      "brgy_name": "Pedtad",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814018",
      "brgy_name": "Poblacion",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814019",
      "brgy_name": "Upper Taviran",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814026",
      "brgy_name": "Buterin",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153814028",
      "brgy_name": "Lower Taviran",
      "city_code": "153814",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815002",
      "brgy_name": "Borongotan",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815003",
      "brgy_name": "Bayabas",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815004",
      "brgy_name": "Blensong",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815005",
      "brgy_name": "Bugabungan",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815006",
      "brgy_name": "Bungcog",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815008",
      "brgy_name": "Darugao",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815009",
      "brgy_name": "Ganasi",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815010",
      "brgy_name": "Kabakaba",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815012",
      "brgy_name": "Kibleg",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815013",
      "brgy_name": "Kibucay",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815014",
      "brgy_name": "Kiga",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815017",
      "brgy_name": "Kinitan (Kinitaan)",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815022",
      "brgy_name": "Mirab",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815023",
      "brgy_name": "Nangi",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815025",
      "brgy_name": "Nuro Poblacion",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815028",
      "brgy_name": "Bantek",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815029",
      "brgy_name": "Ranao Pilayan",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815030",
      "brgy_name": "Rempes",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815031",
      "brgy_name": "Renede",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815032",
      "brgy_name": "Renti",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815034",
      "brgy_name": "Rifao",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815036",
      "brgy_name": "Sefegefen",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153815042",
      "brgy_name": "Tinungkaan",
      "city_code": "153815",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816005",
      "brgy_name": "Boboguiron",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816007",
      "brgy_name": "Damablac",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816008",
      "brgy_name": "Fugotan",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816009",
      "brgy_name": "Fukol",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816013",
      "brgy_name": "Katibpuan",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816014",
      "brgy_name": "Kedati",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816018",
      "brgy_name": "Lanting",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816019",
      "brgy_name": "Linamunan",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816025",
      "brgy_name": "Marader",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816028",
      "brgy_name": "Binangga North",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816032",
      "brgy_name": "Binangga South",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816033",
      "brgy_name": "Talayan",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816035",
      "brgy_name": "Tamar",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816036",
      "brgy_name": "Tambunan I",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153816038",
      "brgy_name": "Timbaluan",
      "city_code": "153816",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817001",
      "brgy_name": "Kuya",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817002",
      "brgy_name": "Biarong",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817003",
      "brgy_name": "Bongo",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817004",
      "brgy_name": "Itaw",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817005",
      "brgy_name": "Kigan",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817006",
      "brgy_name": "Lamud",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817007",
      "brgy_name": "Looy",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817008",
      "brgy_name": "Pandan",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817009",
      "brgy_name": "Pilar",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817010",
      "brgy_name": "Romangaob (Pob.)",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153817011",
      "brgy_name": "San Jose",
      "city_code": "153817",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818001",
      "brgy_name": "Barira (Pob.)",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818002",
      "brgy_name": "Bualan",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818003",
      "brgy_name": "Gadung",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818004",
      "brgy_name": "Liong",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818005",
      "brgy_name": "Lipa",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818006",
      "brgy_name": "Lipawan",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818007",
      "brgy_name": "Marang",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818008",
      "brgy_name": "Nabalawag",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818009",
      "brgy_name": "Rominimbang",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818010",
      "brgy_name": "Togaig",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818011",
      "brgy_name": "Minabay",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818012",
      "brgy_name": "Korosoyan",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818013",
      "brgy_name": "Lamin",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153818014",
      "brgy_name": "Panggao",
      "city_code": "153818",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819001",
      "brgy_name": "Badak",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819002",
      "brgy_name": "Bulod",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819005",
      "brgy_name": "Kaladturan",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819006",
      "brgy_name": "Kulasi",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819007",
      "brgy_name": "Lao-lao",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819008",
      "brgy_name": "Lasangan",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819009",
      "brgy_name": "Lower Idtig",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819010",
      "brgy_name": "Lumabao",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819011",
      "brgy_name": "Makainis",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819012",
      "brgy_name": "Midconding",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819013",
      "brgy_name": "Midpandacan",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819015",
      "brgy_name": "Panosolen",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819016",
      "brgy_name": "Ramcor",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819017",
      "brgy_name": "Tonggol",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819018",
      "brgy_name": "Pidtiguian",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819019",
      "brgy_name": "Quipolot",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819020",
      "brgy_name": "Sadangin",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819021",
      "brgy_name": "Sumakubay",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153819022",
      "brgy_name": "Upper Lasangan",
      "city_code": "153819",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820001",
      "brgy_name": "Bagumbong",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820002",
      "brgy_name": "Dabenayan",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820003",
      "brgy_name": "Daladap",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820004",
      "brgy_name": "Dasikil",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820006",
      "brgy_name": "Liab",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820007",
      "brgy_name": "Libutan",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820009",
      "brgy_name": "Lusay",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820010",
      "brgy_name": "Mamasapano",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820011",
      "brgy_name": "Manongkaling",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820013",
      "brgy_name": "Pidsandawan",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820014",
      "brgy_name": "Pimbalakan",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820016",
      "brgy_name": "Sapakan",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820017",
      "brgy_name": "Tuka",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153820018",
      "brgy_name": "Tukanalipao",
      "city_code": "153820",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821002",
      "brgy_name": "Bintan (Bentan)",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821003",
      "brgy_name": "Gadungan",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821004",
      "brgy_name": "Kiladap",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821005",
      "brgy_name": "Kilalan",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821006",
      "brgy_name": "Kuden",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821007",
      "brgy_name": "Makadayon",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821008",
      "brgy_name": "Manggay",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821011",
      "brgy_name": "Pageda",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153821012",
      "brgy_name": "Talitay",
      "city_code": "153821",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822001",
      "brgy_name": "Balatungkayo (Batungkayo)",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822002",
      "brgy_name": "Bulit",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822003",
      "brgy_name": "Bulod",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822004",
      "brgy_name": "Dungguan",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822005",
      "brgy_name": "Limbalud",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822006",
      "brgy_name": "Maridagao",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822007",
      "brgy_name": "Nabundas",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822008",
      "brgy_name": "Pagagawan",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822009",
      "brgy_name": "Talapas",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822010",
      "brgy_name": "Talitay",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153822011",
      "brgy_name": "Tunggol",
      "city_code": "153822",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823001",
      "brgy_name": "Damakling",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823002",
      "brgy_name": "Damalusay",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823003",
      "brgy_name": "Paglat",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823004",
      "brgy_name": "Upper Idtig",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823005",
      "brgy_name": "Campo",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823006",
      "brgy_name": "Kakal",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823007",
      "brgy_name": "Salam",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153823008",
      "brgy_name": "Tual",
      "city_code": "153823",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824001",
      "brgy_name": "Balut",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824002",
      "brgy_name": "Boliok",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824003",
      "brgy_name": "Bungabong",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824004",
      "brgy_name": "Dagurongan",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824005",
      "brgy_name": "Kirkir",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824006",
      "brgy_name": "Macabico (Macabiso)",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824007",
      "brgy_name": "Namuken",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824008",
      "brgy_name": "Simuay/Seashore",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824009",
      "brgy_name": "Solon",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824010",
      "brgy_name": "Tambo",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824011",
      "brgy_name": "Tapayan",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824012",
      "brgy_name": "Tariken",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153824013",
      "brgy_name": "Tuka",
      "city_code": "153824",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825001",
      "brgy_name": "Ahan",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825002",
      "brgy_name": "Bagan",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825003",
      "brgy_name": "Datalpandan",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825004",
      "brgy_name": "Kalumamis",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825005",
      "brgy_name": "Kateman",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825006",
      "brgy_name": "Lambayao",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825007",
      "brgy_name": "Macasampen",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825008",
      "brgy_name": "Muslim",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825009",
      "brgy_name": "Muti",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825010",
      "brgy_name": "Sampao",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153825011",
      "brgy_name": "Tambunan II",
      "city_code": "153825",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826002",
      "brgy_name": "Dapiawan",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826003",
      "brgy_name": "Elian",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826005",
      "brgy_name": "Gawang",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826007",
      "brgy_name": "Kabengi",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826008",
      "brgy_name": "Kitango",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826009",
      "brgy_name": "Kitapok",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826010",
      "brgy_name": "Madia",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153826013",
      "brgy_name": "Salbu",
      "city_code": "153826",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827001",
      "brgy_name": "Bulayan",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827002",
      "brgy_name": "Iganagampong",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827003",
      "brgy_name": "Macalag",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827004",
      "brgy_name": "Maitumaig",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827005",
      "brgy_name": "Malangog",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827006",
      "brgy_name": "Meta",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827008",
      "brgy_name": "Panangeti",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153827010",
      "brgy_name": "Tuntungan",
      "city_code": "153827",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828001",
      "brgy_name": "Banaba",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828002",
      "brgy_name": "Dimampao",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828003",
      "brgy_name": "Guinibon",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828004",
      "brgy_name": "Kaya-kaya",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828005",
      "brgy_name": "Maganoy",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828006",
      "brgy_name": "Mao",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828007",
      "brgy_name": "Maranding",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828008",
      "brgy_name": "Sugadol",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828009",
      "brgy_name": "Talisawa",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153828010",
      "brgy_name": "Tukanolocong (Tukanologong)",
      "city_code": "153828",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829001",
      "brgy_name": "Baital",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829002",
      "brgy_name": "Bakat",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829003",
      "brgy_name": "Dapantis",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829004",
      "brgy_name": "Gaunan",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829005",
      "brgy_name": "Malibpolok",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829006",
      "brgy_name": "Mileb",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829007",
      "brgy_name": "Panadtaban",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829008",
      "brgy_name": "Pidsandawan",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829009",
      "brgy_name": "Sampao",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829010",
      "brgy_name": "Sapakan (Pob.)",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153829011",
      "brgy_name": "Tabungao",
      "city_code": "153829",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830001",
      "brgy_name": "Kinimi",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830002",
      "brgy_name": "Laguitan",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830003",
      "brgy_name": "Lapaken",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830004",
      "brgy_name": "Matuber",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830005",
      "brgy_name": "Meti",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830006",
      "brgy_name": "Nalkan",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830007",
      "brgy_name": "Penansaran",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830008",
      "brgy_name": "Resa",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830009",
      "brgy_name": "Sedem",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830010",
      "brgy_name": "Sinipak",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830011",
      "brgy_name": "Tambak",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830012",
      "brgy_name": "Tubuan",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153830013",
      "brgy_name": "Pura",
      "city_code": "153830",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153831001",
      "brgy_name": "Adaon",
      "city_code": "153831",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153831002",
      "brgy_name": "Brar",
      "city_code": "153831",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153831003",
      "brgy_name": "Mapayag",
      "city_code": "153831",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153831004",
      "brgy_name": "Midtimbang (Pob.)",
      "city_code": "153831",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153831005",
      "brgy_name": "Nunangan (Nunangen)",
      "city_code": "153831",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153831006",
      "brgy_name": "Tugal",
      "city_code": "153831",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153831007",
      "brgy_name": "Tulunan",
      "city_code": "153831",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832001",
      "brgy_name": "Daladagan",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832002",
      "brgy_name": "Kalian",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832003",
      "brgy_name": "Luayan",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832004",
      "brgy_name": "Paitan",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832005",
      "brgy_name": "Panapan",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832006",
      "brgy_name": "Tenok",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832007",
      "brgy_name": "Tinambulan",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153832008",
      "brgy_name": "Tumbao",
      "city_code": "153832",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833001",
      "brgy_name": "Kabuling",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833002",
      "brgy_name": "Kayaga",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833003",
      "brgy_name": "Kayupo (Cuyapo)",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833004",
      "brgy_name": "Lepak",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833005",
      "brgy_name": "Lower Dilag",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833006",
      "brgy_name": "Malangit",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833007",
      "brgy_name": "Pandag",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153833008",
      "brgy_name": "Upper Dilag",
      "city_code": "153833",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834001",
      "brgy_name": "Balong",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834002",
      "brgy_name": "Damatog",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834003",
      "brgy_name": "Gayonga",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834004",
      "brgy_name": "Guiawa",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834005",
      "brgy_name": "Indatuan",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834006",
      "brgy_name": "Kapimpilan",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834007",
      "brgy_name": "Libungan",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834008",
      "brgy_name": "Montay",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834009",
      "brgy_name": "Paulino Labio",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834010",
      "brgy_name": "Sabaken",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153834011",
      "brgy_name": "Tumaguinting",
      "city_code": "153834",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835001",
      "brgy_name": "Kubentong",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835002",
      "brgy_name": "Labu-labu I",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835003",
      "brgy_name": "Labu-labu II",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835004",
      "brgy_name": "Limpongo",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835005",
      "brgy_name": "Sayap",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835006",
      "brgy_name": "Taib",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835007",
      "brgy_name": "Talibadok",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835008",
      "brgy_name": "Tuayan",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835009",
      "brgy_name": "Tuayan I",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835010",
      "brgy_name": "Macalag",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153835011",
      "brgy_name": "Tuntungan",
      "city_code": "153835",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836001",
      "brgy_name": "Alonganan",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836002",
      "brgy_name": "Andavit",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836003",
      "brgy_name": "Balanakan",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836004",
      "brgy_name": "Buayan",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836005",
      "brgy_name": "Butilen",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836006",
      "brgy_name": "Dado",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836007",
      "brgy_name": "Damabalas",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836008",
      "brgy_name": "Duaminanga",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836009",
      "brgy_name": "Kalipapa",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836010",
      "brgy_name": "Liong",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836011",
      "brgy_name": "Magaslong",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836012",
      "brgy_name": "Masigay",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836013",
      "brgy_name": "Pagatin",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836014",
      "brgy_name": "Pandi",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836015",
      "brgy_name": "Penditen",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836016",
      "brgy_name": "Sambulawan",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153836017",
      "brgy_name": "Tee",
      "city_code": "153836",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837001",
      "brgy_name": "Bakat",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837002",
      "brgy_name": "Dale-Bong",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837003",
      "brgy_name": "Dasawao",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837004",
      "brgy_name": "Datu Bakal",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837005",
      "brgy_name": "Datu Kilay",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837006",
      "brgy_name": "Duguengen",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837007",
      "brgy_name": "Ganta",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837008",
      "brgy_name": "Inaladan",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837009",
      "brgy_name": "Linantangan",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837010",
      "brgy_name": "Nabundas",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837011",
      "brgy_name": "Pagatin",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837012",
      "brgy_name": "Pamalian",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837013",
      "brgy_name": "Pikeg",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837014",
      "brgy_name": "Pusao",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837015",
      "brgy_name": "Libutan",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "153837016",
      "brgy_name": "Pagatin (Pagatin I)",
      "city_code": "153837",
      "province_code": "1538",
      "region_code": "15"
    },
    {
      "brgy_code": "156601001",
      "brgy_name": "Adjid",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601002",
      "brgy_name": "Bangalan",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601003",
      "brgy_name": "Bato-bato",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601004",
      "brgy_name": "Buanza",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601005",
      "brgy_name": "Bud-Taran",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601006",
      "brgy_name": "Bunut",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601007",
      "brgy_name": "Jati-Tunggal",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601008",
      "brgy_name": "Kabbon Maas",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601010",
      "brgy_name": "Kagay",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601011",
      "brgy_name": "Kajatian",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601012",
      "brgy_name": "Kan Islam",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601013",
      "brgy_name": "Kandang Tukay",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601014",
      "brgy_name": "Karawan",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601015",
      "brgy_name": "Katian",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601016",
      "brgy_name": "Kuppong",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601017",
      "brgy_name": "Lambayong",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601018",
      "brgy_name": "Langpas",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601019",
      "brgy_name": "Licup",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601020",
      "brgy_name": "Malimbaya",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601021",
      "brgy_name": "Manggis",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601022",
      "brgy_name": "Manilop",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601023",
      "brgy_name": "Paligue",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601024",
      "brgy_name": "Panabuan",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601025",
      "brgy_name": "Pasil",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601026",
      "brgy_name": "Poblacion (Indanan)",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601027",
      "brgy_name": "Sapah Malaum",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601028",
      "brgy_name": "Panglima Misuari (Sasak)",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601029",
      "brgy_name": "Sionogan",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601030",
      "brgy_name": "Tagbak",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601031",
      "brgy_name": "Timbangan",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601032",
      "brgy_name": "Tubig Dakulah",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601033",
      "brgy_name": "Tubig Parang",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601035",
      "brgy_name": "Tumantangis",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156601036",
      "brgy_name": "Sawaki",
      "city_code": "156601",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602001",
      "brgy_name": "Alat",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602002",
      "brgy_name": "Asturias",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602003",
      "brgy_name": "Bus-bus",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602004",
      "brgy_name": "Chinese Pier",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602007",
      "brgy_name": "San Raymundo",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602008",
      "brgy_name": "Takut-takut",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602009",
      "brgy_name": "Tulay",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156602010",
      "brgy_name": "Walled City (Pob.)",
      "city_code": "156602",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603002",
      "brgy_name": "Kambing",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603004",
      "brgy_name": "Kanlagay",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603010",
      "brgy_name": "Pang",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603011",
      "brgy_name": "Pangdan Pangdan",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603012",
      "brgy_name": "Pitogo",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603013",
      "brgy_name": "Karungdong (Pob.)",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603016",
      "brgy_name": "Tunggol",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603017",
      "brgy_name": "Masjid Bayle",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156603018",
      "brgy_name": "Masjid Punjungan",
      "city_code": "156603",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604004",
      "brgy_name": "Bual",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604007",
      "brgy_name": "Kan-Bulak",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604008",
      "brgy_name": "Kan-Mindus",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604012",
      "brgy_name": "Lambago",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604013",
      "brgy_name": "Lianutan",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604014",
      "brgy_name": "Lingah",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604015",
      "brgy_name": "Mananti",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604021",
      "brgy_name": "Tubig-Puti (Pob.)",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604024",
      "brgy_name": "Tandu-Bato",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604028",
      "brgy_name": "Tulayan Island",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604029",
      "brgy_name": "Guimbaun",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156604030",
      "brgy_name": "Niog-niog",
      "city_code": "156604",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605001",
      "brgy_name": "Anak Jati",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605002",
      "brgy_name": "Bato Ugis",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605003",
      "brgy_name": "Bualo Lahi",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605004",
      "brgy_name": "Bualo Lipid",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605005",
      "brgy_name": "Bulabog",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605006",
      "brgy_name": "Ratag Limbon",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605007",
      "brgy_name": "Duhol Kabbon",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605008",
      "brgy_name": "Gulangan",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605009",
      "brgy_name": "Ipil",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605010",
      "brgy_name": "Kandang",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605011",
      "brgy_name": "Kapok-Punggol",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605012",
      "brgy_name": "Kulasi",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605013",
      "brgy_name": "Labah",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605014",
      "brgy_name": "Lagasan Asibih",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605015",
      "brgy_name": "Lantong",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605016",
      "brgy_name": "Laud Kulasi",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605017",
      "brgy_name": "Laum Maimbung",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605018",
      "brgy_name": "Lunggang",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605019",
      "brgy_name": "Lower Tambaking",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605020",
      "brgy_name": "Lapa",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605021",
      "brgy_name": "Matatal",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605022",
      "brgy_name": "Patao",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605023",
      "brgy_name": "Poblacion (Maimbung)",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605024",
      "brgy_name": "Tabu-Bato",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605025",
      "brgy_name": "Tandu Patong",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605026",
      "brgy_name": "Tubig-Samin",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156605027",
      "brgy_name": "Upper Tambaking",
      "city_code": "156605",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156606002",
      "brgy_name": "Bubuan",
      "city_code": "156606",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156606003",
      "brgy_name": "Kabuukan",
      "city_code": "156606",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156606008",
      "brgy_name": "Pag-asinan",
      "city_code": "156606",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156606012",
      "brgy_name": "Bangas (Pob.)",
      "city_code": "156606",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156606015",
      "brgy_name": "Teomabal",
      "city_code": "156606",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607001",
      "brgy_name": "Asin",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607002",
      "brgy_name": "Bakud",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607003",
      "brgy_name": "Bangday",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607004",
      "brgy_name": "Baunoh",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607005",
      "brgy_name": "Bitanag",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607006",
      "brgy_name": "Bud Seit",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607007",
      "brgy_name": "Bulangsi",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607008",
      "brgy_name": "Datag",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607011",
      "brgy_name": "Kamalig",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607013",
      "brgy_name": "Kan Ukol",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607014",
      "brgy_name": "Kan Asaali",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607015",
      "brgy_name": "Kan-Dayok",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607016",
      "brgy_name": "Kan-Sipat",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607017",
      "brgy_name": "Kawasan",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607018",
      "brgy_name": "Kulay-kulay",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607019",
      "brgy_name": "Lakit",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607023",
      "brgy_name": "Lunggang",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607027",
      "brgy_name": "Parang",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607028",
      "brgy_name": "Lower Patibulan",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607029",
      "brgy_name": "Upper Patibulan",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607030",
      "brgy_name": "Pugad Manaul",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607031",
      "brgy_name": "Puhagan",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607033",
      "brgy_name": "Seit Higad",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607034",
      "brgy_name": "Seit Lake (Pob.)",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607036",
      "brgy_name": "Su-uh",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607037",
      "brgy_name": "Tabu Manuk",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607038",
      "brgy_name": "Tandu-tandu",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607039",
      "brgy_name": "Tayungan",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607040",
      "brgy_name": "Tinah",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607042",
      "brgy_name": "Tubig Gantang",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156607043",
      "brgy_name": "Tubig Jati",
      "city_code": "156607",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608001",
      "brgy_name": "Alu Bunah",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608003",
      "brgy_name": "Bangkilay",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608004",
      "brgy_name": "Kawitan",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608005",
      "brgy_name": "Kehi Niog",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608006",
      "brgy_name": "Lantong Babag",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608007",
      "brgy_name": "Lumah Dapdap",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608009",
      "brgy_name": "Pandan Niog",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608010",
      "brgy_name": "Panducan",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608011",
      "brgy_name": "Panitikan",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608012",
      "brgy_name": "Patutol",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608013",
      "brgy_name": "Simbahan (Pob.)",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608014",
      "brgy_name": "Se-ipang",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608016",
      "brgy_name": "Suang Bunah",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608017",
      "brgy_name": "Tonggasang",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608018",
      "brgy_name": "Tubig Nonok",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156608019",
      "brgy_name": "Tubig Sallang",
      "city_code": "156608",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609001",
      "brgy_name": "Kaha",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609002",
      "brgy_name": "Alu Pangkoh",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609003",
      "brgy_name": "Bagsak",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609004",
      "brgy_name": "Bawisan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609005",
      "brgy_name": "Biid",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609006",
      "brgy_name": "Bukid",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609008",
      "brgy_name": "Buli Bawang",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609009",
      "brgy_name": "Buton",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609010",
      "brgy_name": "Buton Mahablo",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609011",
      "brgy_name": "Danapa",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609012",
      "brgy_name": "Duyan Kabao",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609013",
      "brgy_name": "Gimba Lagasan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609015",
      "brgy_name": "Kahoy Sinah",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609016",
      "brgy_name": "Kanaway",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609017",
      "brgy_name": "Kutah Sairap",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609019",
      "brgy_name": "Lagasan Higad",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609021",
      "brgy_name": "Laum Buwahan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609023",
      "brgy_name": "Laum Suwah",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609024",
      "brgy_name": "Alu Layag-Layag",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609025",
      "brgy_name": "Liang",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609026",
      "brgy_name": "Linuho",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609027",
      "brgy_name": "Lipunos",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609028",
      "brgy_name": "Lungan Gitong",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609029",
      "brgy_name": "Lumbaan Mahaba",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609030",
      "brgy_name": "Lupa Abu",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609031",
      "brgy_name": "Nonokan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609032",
      "brgy_name": "Paugan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609033",
      "brgy_name": "Payuhan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609034",
      "brgy_name": "Piyahan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609035",
      "brgy_name": "Poblacion (Parang)",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609036",
      "brgy_name": "Saldang",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609037",
      "brgy_name": "Sampunay",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609038",
      "brgy_name": "Silangkan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609039",
      "brgy_name": "Taingting",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609040",
      "brgy_name": "Tikong",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609041",
      "brgy_name": "Tukay",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609042",
      "brgy_name": "Tumangas",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609043",
      "brgy_name": "Wanni Piyanjihan",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609044",
      "brgy_name": "Lanao Dakula",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156609045",
      "brgy_name": "Lower Sampunay",
      "city_code": "156609",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610001",
      "brgy_name": "Andalan",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610003",
      "brgy_name": "Daungdong",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610004",
      "brgy_name": "Kamawi",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610005",
      "brgy_name": "Kanjarang",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610006",
      "brgy_name": "Kayawan (Pob.)",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610007",
      "brgy_name": "Kiput",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610008",
      "brgy_name": "Likud",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610009",
      "brgy_name": "Luuk-tulay",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610010",
      "brgy_name": "Niog-niog",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610011",
      "brgy_name": "Patian",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610012",
      "brgy_name": "Pisak-pisak",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610013",
      "brgy_name": "Saimbangon",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610014",
      "brgy_name": "Sangkap",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156610015",
      "brgy_name": "Timuddas",
      "city_code": "156610",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611001",
      "brgy_name": "Anuling",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611002",
      "brgy_name": "Bakong",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611003",
      "brgy_name": "Bangkal",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611004",
      "brgy_name": "Bonbon",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611005",
      "brgy_name": "Buhanginan (Darayan)",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611006",
      "brgy_name": "Bungkaung",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611007",
      "brgy_name": "Danag",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611008",
      "brgy_name": "Igasan",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611009",
      "brgy_name": "Kabbon Takas",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611010",
      "brgy_name": "Kadday Mampallam",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611011",
      "brgy_name": "Kan Ague",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611012",
      "brgy_name": "Kaunayan",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611013",
      "brgy_name": "Langhub",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611014",
      "brgy_name": "Latih",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611015",
      "brgy_name": "Liang",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611016",
      "brgy_name": "Maligay",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611017",
      "brgy_name": "Mauboh",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611018",
      "brgy_name": "Pangdanon",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611019",
      "brgy_name": "Panglayahan",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611020",
      "brgy_name": "Pansul",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611021",
      "brgy_name": "Patikul Higad",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611022",
      "brgy_name": "Sandah",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611023",
      "brgy_name": "Taglibi (Pob.)",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611024",
      "brgy_name": "Tandu-Bagua",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611025",
      "brgy_name": "Tanum",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611026",
      "brgy_name": "Taung",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611027",
      "brgy_name": "Timpok",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611028",
      "brgy_name": "Tugas",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611029",
      "brgy_name": "Umangay",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156611030",
      "brgy_name": "Gandasuli",
      "city_code": "156611",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612001",
      "brgy_name": "Bakud",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612004",
      "brgy_name": "Buan",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612006",
      "brgy_name": "Bulansing Tara",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612007",
      "brgy_name": "Bulihkullul",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612008",
      "brgy_name": "Campo Islam",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612009",
      "brgy_name": "Poblacion (Campo Baro)",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612010",
      "brgy_name": "Duggo",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612011",
      "brgy_name": "Duhol Tara",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612012",
      "brgy_name": "East Kungtad",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612013",
      "brgy_name": "East Sisangat",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612015",
      "brgy_name": "Ipil",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612016",
      "brgy_name": "Jambangan",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612018",
      "brgy_name": "Kabubu",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612019",
      "brgy_name": "Kong-Kong Laminusa",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612020",
      "brgy_name": "Kud-kud",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612021",
      "brgy_name": "Kungtad West",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612023",
      "brgy_name": "Luuk Laminusa",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612025",
      "brgy_name": "Latung",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612026",
      "brgy_name": "Luuk Tara",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612030",
      "brgy_name": "Manta",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612032",
      "brgy_name": "Minapan",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612035",
      "brgy_name": "Nipa-nipa",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612037",
      "brgy_name": "North Laud",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612038",
      "brgy_name": "North Manta",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612040",
      "brgy_name": "North Musu Laud",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612041",
      "brgy_name": "North Silumpak",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612042",
      "brgy_name": "Punungan",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612046",
      "brgy_name": "Pislong",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612048",
      "brgy_name": "Ratag",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612049",
      "brgy_name": "Sablay",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612050",
      "brgy_name": "Sarukot",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612053",
      "brgy_name": "Siburi",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612056",
      "brgy_name": "Singko",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612057",
      "brgy_name": "Siolakan",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612058",
      "brgy_name": "Siundoh",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612059",
      "brgy_name": "Siowing",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612060",
      "brgy_name": "Sipanding",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612061",
      "brgy_name": "Sisangat",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612065",
      "brgy_name": "South Musu Laud",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612066",
      "brgy_name": "South Silumpak",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612067",
      "brgy_name": "Southwestern Bulikullul",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612069",
      "brgy_name": "Subah Buaya",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612070",
      "brgy_name": "Tampakan Laminusa",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612071",
      "brgy_name": "Tengah Laminusa",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612072",
      "brgy_name": "Tong Laminusa",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612073",
      "brgy_name": "Tong-tong",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612074",
      "brgy_name": "Tonglabah",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612075",
      "brgy_name": "Tubig Kutah",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612076",
      "brgy_name": "Tulling",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156612080",
      "brgy_name": "Puukan Laminusa",
      "city_code": "156612",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613001",
      "brgy_name": "Andalan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613002",
      "brgy_name": "Bagsak",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613003",
      "brgy_name": "Bandang",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613004",
      "brgy_name": "Bilaan (Pob.)",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613007",
      "brgy_name": "Bud Bunga",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613008",
      "brgy_name": "Buntod",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613009",
      "brgy_name": "Buroh",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613010",
      "brgy_name": "Dalih",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613011",
      "brgy_name": "Gata",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613014",
      "brgy_name": "Kabatuhan Tiis",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613015",
      "brgy_name": "Kabungkol",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613016",
      "brgy_name": "Kagay",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613017",
      "brgy_name": "Kahawa",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613018",
      "brgy_name": "Kandaga",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613019",
      "brgy_name": "Kanlibot",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613020",
      "brgy_name": "Kiutaan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613021",
      "brgy_name": "Kuhaw",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613023",
      "brgy_name": "Kulamboh",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613024",
      "brgy_name": "Kuttong",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613025",
      "brgy_name": "Lagtoh",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613026",
      "brgy_name": "Lambanah",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613027",
      "brgy_name": "Liban",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613028",
      "brgy_name": "Liu-Bud Pantao",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613029",
      "brgy_name": "Lower Binuang",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613030",
      "brgy_name": "Lower Kamuntayan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613031",
      "brgy_name": "Lower Laus",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613032",
      "brgy_name": "Lower Sinumaan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613033",
      "brgy_name": "Lower Talipao",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613035",
      "brgy_name": "Lumbayao",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613036",
      "brgy_name": "Lumping Pigih Daho",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613037",
      "brgy_name": "Lungkiaban",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613038",
      "brgy_name": "Mabahay",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613039",
      "brgy_name": "Mahala",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613040",
      "brgy_name": "Mampallam",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613041",
      "brgy_name": "Marsada",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613042",
      "brgy_name": "Mauboh",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613043",
      "brgy_name": "Mungit-mungit",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613044",
      "brgy_name": "Niog-Sangahan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613045",
      "brgy_name": "Pantao",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613047",
      "brgy_name": "Samak",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613048",
      "brgy_name": "Talipao Proper",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613049",
      "brgy_name": "Tampakan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613051",
      "brgy_name": "Tiis",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613052",
      "brgy_name": "Tinggah",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613053",
      "brgy_name": "Tubod",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613054",
      "brgy_name": "Tuyang",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613055",
      "brgy_name": "Upper Kamuntayan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613056",
      "brgy_name": "Upper Laus",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613057",
      "brgy_name": "Upper Sinumaan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613058",
      "brgy_name": "Upper Talipao",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613059",
      "brgy_name": "Kabatuhan Bilaan",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156613060",
      "brgy_name": "Upper Binuang",
      "city_code": "156613",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614003",
      "brgy_name": "Banting",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614010",
      "brgy_name": "Hawan",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614013",
      "brgy_name": "Alu-Kabingaan",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614014",
      "brgy_name": "Kalang (Pob.)",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614016",
      "brgy_name": "Kamaunggi",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614017",
      "brgy_name": "Kanmangon",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614018",
      "brgy_name": "Kanaway",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614019",
      "brgy_name": "Kaumpang",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614025",
      "brgy_name": "Pagatpat",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614027",
      "brgy_name": "Pangdan",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614028",
      "brgy_name": "Puok",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614030",
      "brgy_name": "Sayli",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614032",
      "brgy_name": "Sumambat",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614033",
      "brgy_name": "Tangkapaan",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156614035",
      "brgy_name": "Tulakan",
      "city_code": "156614",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615001",
      "brgy_name": "Bakkaan",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615002",
      "brgy_name": "Bangalaw",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615003",
      "brgy_name": "Danao",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615004",
      "brgy_name": "Dungon",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615005",
      "brgy_name": "Kahikukuk",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615006",
      "brgy_name": "Luuk (Pob.)",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615007",
      "brgy_name": "North Paarol",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615009",
      "brgy_name": "Sigumbal",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615010",
      "brgy_name": "South Paarol",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615011",
      "brgy_name": "Tabialan",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615012",
      "brgy_name": "Tainga-Bakkao",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615013",
      "brgy_name": "Tambun-bun",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615014",
      "brgy_name": "Tinutungan",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156615015",
      "brgy_name": "Tattalan",
      "city_code": "156615",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616001",
      "brgy_name": "Gagguil",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616002",
      "brgy_name": "Gata-gata",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616003",
      "brgy_name": "Kamih-Pungud",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616004",
      "brgy_name": "Lihbug Kabaw",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616005",
      "brgy_name": "Lubuk-lubuk",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616006",
      "brgy_name": "Pandakan",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616007",
      "brgy_name": "Punay (Pob.)",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616008",
      "brgy_name": "Tiptipon",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616009",
      "brgy_name": "Jinggan",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616010",
      "brgy_name": "Likbah",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616011",
      "brgy_name": "Marsada",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156616012",
      "brgy_name": "Paiksa",
      "city_code": "156616",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617001",
      "brgy_name": "Alu Bus-Bus",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617002",
      "brgy_name": "Alu-Duyong",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617003",
      "brgy_name": "Bas Lugus",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617004",
      "brgy_name": "Gapas Rugasan",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617005",
      "brgy_name": "Gapas Tubig Tuwak",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617006",
      "brgy_name": "Huwit-huwit Proper",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617007",
      "brgy_name": "Huwit-huwit Bas Nonok",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617008",
      "brgy_name": "Kutah Parang",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617009",
      "brgy_name": "Laha",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617010",
      "brgy_name": "Larap",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617011",
      "brgy_name": "Lugus Proper",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617012",
      "brgy_name": "Mangkallay",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617013",
      "brgy_name": "Mantan",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617014",
      "brgy_name": "Pait",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617015",
      "brgy_name": "Parian Kayawan",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617016",
      "brgy_name": "Sibul",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156617017",
      "brgy_name": "Tingkangan",
      "city_code": "156617",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618001",
      "brgy_name": "Baligtang",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618002",
      "brgy_name": "Bud Sibaud",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618003",
      "brgy_name": "Hambilan",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618004",
      "brgy_name": "Kabbon",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618005",
      "brgy_name": "Lahi",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618006",
      "brgy_name": "Lapak",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618007",
      "brgy_name": "Malanta",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618008",
      "brgy_name": "Mamanok",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618009",
      "brgy_name": "North Manubul",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618010",
      "brgy_name": "Parian Dakula",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618011",
      "brgy_name": "Sibaud Proper",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618012",
      "brgy_name": "Siganggang",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618013",
      "brgy_name": "South Manubul",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618014",
      "brgy_name": "Suba-suba",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618015",
      "brgy_name": "Tenga Manubul",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156618016",
      "brgy_name": "Laud Sibaud",
      "city_code": "156618",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619001",
      "brgy_name": "Andalan",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619002",
      "brgy_name": "Angilan",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619003",
      "brgy_name": "Capual Island",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619004",
      "brgy_name": "Huwit-huwit",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619005",
      "brgy_name": "Lahing-Lahing",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619006",
      "brgy_name": "Niangkaan",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619007",
      "brgy_name": "Sucuban",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "156619008",
      "brgy_name": "Tangkuan",
      "city_code": "156619",
      "province_code": "1566",
      "region_code": "15"
    },
    {
      "brgy_code": "157001001",
      "brgy_name": "Balimbing Proper",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001002",
      "brgy_name": "Batu-batu (Pob.)",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001003",
      "brgy_name": "Buan",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001004",
      "brgy_name": "Dungon",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001005",
      "brgy_name": "Luuk Buntal",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001007",
      "brgy_name": "Parangan",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001008",
      "brgy_name": "Tabunan",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001009",
      "brgy_name": "Tungbangkaw",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001010",
      "brgy_name": "Bauno Garing",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001011",
      "brgy_name": "Belatan Halu",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001012",
      "brgy_name": "Karaha",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001013",
      "brgy_name": "Kulape",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001014",
      "brgy_name": "Liyaburan",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001015",
      "brgy_name": "Magsaggaw",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001016",
      "brgy_name": "Malacca",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001017",
      "brgy_name": "Sumangday",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157001018",
      "brgy_name": "Tundon",
      "city_code": "157001",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002005",
      "brgy_name": "Ipil",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002006",
      "brgy_name": "Kamagong",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002007",
      "brgy_name": "Karungdong",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002009",
      "brgy_name": "Lakit Lakit",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002010",
      "brgy_name": "Lamion",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002012",
      "brgy_name": "Lapid Lapid",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002013",
      "brgy_name": "Lato Lato",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002014",
      "brgy_name": "Luuk Pandan",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002015",
      "brgy_name": "Luuk Tulay",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002016",
      "brgy_name": "Malassa",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002017",
      "brgy_name": "Mandulan",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002018",
      "brgy_name": "Masantong",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002019",
      "brgy_name": "Montay Montay",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002021",
      "brgy_name": "Pababag",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002022",
      "brgy_name": "Pagasinan",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002023",
      "brgy_name": "Pahut",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002024",
      "brgy_name": "Pakias",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002025",
      "brgy_name": "Paniongan",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002026",
      "brgy_name": "Pasiagan",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002027",
      "brgy_name": "Bongao Poblacion",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002030",
      "brgy_name": "Sanga-sanga",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002031",
      "brgy_name": "Silubog",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002033",
      "brgy_name": "Simandagit",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002034",
      "brgy_name": "Sumangat",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002037",
      "brgy_name": "Tarawakan",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002040",
      "brgy_name": "Tongsinah",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002042",
      "brgy_name": "Tubig Basag",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002043",
      "brgy_name": "Ungus-ungus",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002044",
      "brgy_name": "Lagasan",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002045",
      "brgy_name": "Nalil",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002046",
      "brgy_name": "Pagatpat",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002047",
      "brgy_name": "Pag-asa",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002048",
      "brgy_name": "Tubig Tanah",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002049",
      "brgy_name": "Tubig-Boh",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157002050",
      "brgy_name": "Tubig-Mampallam",
      "city_code": "157002",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003001",
      "brgy_name": "Boki",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003002",
      "brgy_name": "Duhul Batu",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003003",
      "brgy_name": "Kompang",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003004",
      "brgy_name": "Lupa Pula (Pob.)",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003005",
      "brgy_name": "Guppah",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003006",
      "brgy_name": "Mahalo",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003007",
      "brgy_name": "Pawan",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003008",
      "brgy_name": "Sikub",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003009",
      "brgy_name": "Tabulian",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003010",
      "brgy_name": "Tanduan",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003012",
      "brgy_name": "Umus Mataha",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003013",
      "brgy_name": "Erok-erok",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003014",
      "brgy_name": "Liyubud",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003015",
      "brgy_name": "Lubbak Parang",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157003016",
      "brgy_name": "Sapa",
      "city_code": "157003",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004001",
      "brgy_name": "Bakong",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004002",
      "brgy_name": "Manuk Mangkaw",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004003",
      "brgy_name": "Mongkay",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004004",
      "brgy_name": "Tampakan (Pob.)",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004005",
      "brgy_name": "Tonggosong",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004006",
      "brgy_name": "Tubig Indangan",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004007",
      "brgy_name": "Ubol",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004008",
      "brgy_name": "Doh-Tong",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004009",
      "brgy_name": "Luuk Datan",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004010",
      "brgy_name": "Maruwa",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004011",
      "brgy_name": "Pagasinan",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004012",
      "brgy_name": "Panglima Mastul",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004013",
      "brgy_name": "Sukah-Bulan",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004014",
      "brgy_name": "Timundon",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157004015",
      "brgy_name": "Bagid*",
      "city_code": "157004",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005001",
      "brgy_name": "South Larap (Larap)",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005004",
      "brgy_name": "Sitangkai Poblacion",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005010",
      "brgy_name": "Tongmageng",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005012",
      "brgy_name": "Tongusong",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005016",
      "brgy_name": "Datu Baguinda Putih",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005021",
      "brgy_name": "Imam Sapie",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005022",
      "brgy_name": "North Larap",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005023",
      "brgy_name": "Panglima Alari",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157005025",
      "brgy_name": "Sipangkot",
      "city_code": "157005",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006001",
      "brgy_name": "Babagan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006005",
      "brgy_name": "Bengkol",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006006",
      "brgy_name": "Bintawlan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006007",
      "brgy_name": "Bohe",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006008",
      "brgy_name": "Bubuan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006009",
      "brgy_name": "Bunay Bunay Tong",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006010",
      "brgy_name": "Bunay Bunay Lookan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006011",
      "brgy_name": "Bunay Bunay Center",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006012",
      "brgy_name": "Lahad Dampong",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006013",
      "brgy_name": "East Talisay",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006014",
      "brgy_name": "Nunuk",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006015",
      "brgy_name": "Laitan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006016",
      "brgy_name": "Lambi-lambian",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006017",
      "brgy_name": "Laud",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006019",
      "brgy_name": "Likud Tabawan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006023",
      "brgy_name": "Nusa-nusa",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006024",
      "brgy_name": "Nusa",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006025",
      "brgy_name": "Pampang",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006026",
      "brgy_name": "Putat",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006027",
      "brgy_name": "Sollogan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006028",
      "brgy_name": "Talisay",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006029",
      "brgy_name": "Tampakan Dampong",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006030",
      "brgy_name": "Tinda-tindahan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006032",
      "brgy_name": "Tong Tampakan",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006033",
      "brgy_name": "Tubig Dayang Center",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006034",
      "brgy_name": "Tubig Dayang Riverside",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006035",
      "brgy_name": "Tubig Dayang",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006036",
      "brgy_name": "Tukkai",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006037",
      "brgy_name": "Unas-unas",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006038",
      "brgy_name": "Likud Dampong",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157006039",
      "brgy_name": "Tangngah",
      "city_code": "157006",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007002",
      "brgy_name": "Baliungan",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007009",
      "brgy_name": "Kakoong",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007010",
      "brgy_name": "Kepeng",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007012",
      "brgy_name": "Lahay-lahay",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007026",
      "brgy_name": "Naungan",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007030",
      "brgy_name": "Sallangan",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007031",
      "brgy_name": "Sapa",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007033",
      "brgy_name": "Silantup",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007041",
      "brgy_name": "Tapian",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007042",
      "brgy_name": "Tongbangkaw",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007043",
      "brgy_name": "Tangngah (Tangngah Ungus Matata)",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007044",
      "brgy_name": "Ballak",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007045",
      "brgy_name": "Butun",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007046",
      "brgy_name": "Himbah",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007047",
      "brgy_name": "Kalang-kalang",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007048",
      "brgy_name": "Salamat",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007049",
      "brgy_name": "Sibakloon",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007050",
      "brgy_name": "Tandubato",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007051",
      "brgy_name": "Tapian Sukah",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157007052",
      "brgy_name": "Taruk",
      "city_code": "157007",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157008001",
      "brgy_name": "Taganak Poblacion",
      "city_code": "157008",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157008002",
      "brgy_name": "Likud Bakkao",
      "city_code": "157008",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009001",
      "brgy_name": "Bakong",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009002",
      "brgy_name": "Bas-bas Proper",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009003",
      "brgy_name": "Basnunuk",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009004",
      "brgy_name": "Darussalam",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009005",
      "brgy_name": "Languyan Proper (Pob.)",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009006",
      "brgy_name": "Maraning",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009009",
      "brgy_name": "Simalak",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009010",
      "brgy_name": "Tuhog-tuhog",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009011",
      "brgy_name": "Tumahubong",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009012",
      "brgy_name": "Tumbagaan",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009013",
      "brgy_name": "Parang Pantay",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009014",
      "brgy_name": "Adnin",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009015",
      "brgy_name": "Bakaw-bakaw",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009016",
      "brgy_name": "BasLikud",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009017",
      "brgy_name": "Jakarta (Lookan Latuan)",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009018",
      "brgy_name": "Kalupag",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009019",
      "brgy_name": "Kiniktal",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009020",
      "brgy_name": "Marang-marang",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009021",
      "brgy_name": "Sikullis",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157009022",
      "brgy_name": "Tubig Dakula",
      "city_code": "157009",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010001",
      "brgy_name": "Baldatal Islam",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010002",
      "brgy_name": "Lookan Banaran",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010003",
      "brgy_name": "Tonggusong Banaran",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010004",
      "brgy_name": "Butun",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010005",
      "brgy_name": "Dalo-dalo",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010006",
      "brgy_name": "Palate Gadjaminah",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010007",
      "brgy_name": "Kohec",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010008",
      "brgy_name": "Latuan (Suasang)",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010009",
      "brgy_name": "Lakit-lakit",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010010",
      "brgy_name": "Tangngah (Lalung Sikubong)",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010011",
      "brgy_name": "Tabunan Likud Sikubong",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010012",
      "brgy_name": "Malanta",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010013",
      "brgy_name": "Mantabuan Tabunan",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010014",
      "brgy_name": "Sapa-sapa (Pob.)",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010015",
      "brgy_name": "Tapian Bohe North",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010016",
      "brgy_name": "Look Natuh",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010017",
      "brgy_name": "Lookan Latuan",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010018",
      "brgy_name": "Nunuk Likud Sikubong",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010019",
      "brgy_name": "Pamasan",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010020",
      "brgy_name": "Sapaat",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010021",
      "brgy_name": "Sukah-sukah",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010022",
      "brgy_name": "Tapian Bohe South",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157010023",
      "brgy_name": "Tup-tup Banaran",
      "city_code": "157010",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011001",
      "brgy_name": "Ambulong Sapal",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011002",
      "brgy_name": "Datu Amilhamja Jaafar",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011003",
      "brgy_name": "Hadji Imam Bidin",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011004",
      "brgy_name": "Hadji Mohtar Sulayman",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011005",
      "brgy_name": "Hadji Taha",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011006",
      "brgy_name": "Imam Hadji Mohammad",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011007",
      "brgy_name": "Ligayan",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011008",
      "brgy_name": "Nunukan",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011009",
      "brgy_name": "Sheik Makdum",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011010",
      "brgy_name": "Sibutu (Pob.)",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011011",
      "brgy_name": "Talisay",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011012",
      "brgy_name": "Tandu Banak",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011013",
      "brgy_name": "Taungoh",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011014",
      "brgy_name": "Tongehat",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011015",
      "brgy_name": "Tongsibalo",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "157011016",
      "brgy_name": "Ungus-ungus",
      "city_code": "157011",
      "province_code": "1570",
      "region_code": "15"
    },
    {
      "brgy_code": "160201001",
      "brgy_name": "Abilan",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201002",
      "brgy_name": "Agong-ong",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201003",
      "brgy_name": "Alubijid",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201004",
      "brgy_name": "Guinabsan",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201007",
      "brgy_name": "Macalang",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201008",
      "brgy_name": "Malapong",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201009",
      "brgy_name": "Malpoc",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201010",
      "brgy_name": "Manapa",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201011",
      "brgy_name": "Matabao",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201013",
      "brgy_name": "Poblacion 1",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201014",
      "brgy_name": "Poblacion 2",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201015",
      "brgy_name": "Poblacion 3",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201016",
      "brgy_name": "Poblacion 4",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201017",
      "brgy_name": "Poblacion 5",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201018",
      "brgy_name": "Poblacion 6",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201019",
      "brgy_name": "Poblacion 7",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201020",
      "brgy_name": "Poblacion 8",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201021",
      "brgy_name": "Poblacion 9",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201022",
      "brgy_name": "Poblacion 10",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201023",
      "brgy_name": "Rizal",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201024",
      "brgy_name": "Sacol",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201025",
      "brgy_name": "Sangay",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201026",
      "brgy_name": "Talo-ao",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201027",
      "brgy_name": "Lower Olave",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160201028",
      "brgy_name": "Simbalan",
      "city_code": "160201",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202002",
      "brgy_name": "Agao Pob. (Bgy. 3)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202003",
      "brgy_name": "Agusan Pequeño",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202004",
      "brgy_name": "Ambago",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202006",
      "brgy_name": "Amparo",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202007",
      "brgy_name": "Ampayon",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202008",
      "brgy_name": "Anticala",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202009",
      "brgy_name": "Antongalon",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202010",
      "brgy_name": "Aupagan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202012",
      "brgy_name": "Baan KM 3",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202013",
      "brgy_name": "Babag",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202014",
      "brgy_name": "Bading Pob. (Bgy. 22)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202016",
      "brgy_name": "Bancasi",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202017",
      "brgy_name": "Banza",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202018",
      "brgy_name": "Baobaoan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202019",
      "brgy_name": "Basag",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202020",
      "brgy_name": "Bayanihan Pob. (Bgy. 27)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202021",
      "brgy_name": "Bilay",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202022",
      "brgy_name": "Bit-os",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202023",
      "brgy_name": "Bitan-agan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202024",
      "brgy_name": "Bobon",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202025",
      "brgy_name": "Bonbon",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202026",
      "brgy_name": "Bugabus",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202027",
      "brgy_name": "Buhangin Pob. (Bgy. 19)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202029",
      "brgy_name": "Cabcabon",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202031",
      "brgy_name": "Camayahan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202033",
      "brgy_name": "Baan Riverside Pob. (Bgy. 20)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202036",
      "brgy_name": "Dankias",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202037",
      "brgy_name": "Imadejas Pob. (Bgy. 24)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202038",
      "brgy_name": "Diego Silang Pob. (Bgy. 6)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202039",
      "brgy_name": "Doongan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202040",
      "brgy_name": "Dumalagan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202043",
      "brgy_name": "Golden Ribbon Pob. (Bgy. 2)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202044",
      "brgy_name": "Dagohoy Pob. (Bgy. 7)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202045",
      "brgy_name": "Jose Rizal Pob. (Bgy. 25)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202047",
      "brgy_name": "Holy Redeemer Pob. (Bgy. 23)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202048",
      "brgy_name": "Humabon Pob. (Bgy. 11)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202049",
      "brgy_name": "Kinamlutan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202051",
      "brgy_name": "Lapu-lapu Pob. (Bgy. 8)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202052",
      "brgy_name": "Lemon",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202053",
      "brgy_name": "Leon Kilat Pob. (Bgy. 13)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202054",
      "brgy_name": "Libertad",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202055",
      "brgy_name": "Limaha Pob. (Bgy. 14)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202056",
      "brgy_name": "Los Angeles",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202057",
      "brgy_name": "Lumbocan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202060",
      "brgy_name": "Maguinda",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202061",
      "brgy_name": "Mahay",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202062",
      "brgy_name": "Mahogany Pob. (Bgy. 21)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202063",
      "brgy_name": "Maibu",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202064",
      "brgy_name": "Mandamo",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202065",
      "brgy_name": "Manila de Bugabus",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202066",
      "brgy_name": "Maon Pob. (Bgy. 1)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202067",
      "brgy_name": "Masao",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202068",
      "brgy_name": "Maug",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202069",
      "brgy_name": "Port Poyohon Pob. (Bgy. 17 - New Asia)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202070",
      "brgy_name": "New Society Village Pob. (Bgy. 26)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202071",
      "brgy_name": "Ong Yiu Pob. (Bgy. 16)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202072",
      "brgy_name": "Pianing",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202073",
      "brgy_name": "Pinamanculan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202074",
      "brgy_name": "Rajah Soliman Pob. (Bgy. 4)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202075",
      "brgy_name": "San Ignacio Pob. (Bgy. 15)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202076",
      "brgy_name": "San Mateo",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202077",
      "brgy_name": "San Vicente",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202078",
      "brgy_name": "Sikatuna Pob. (Bgy. 10)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202079",
      "brgy_name": "Silongan Pob. (Bgy. 5)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202080",
      "brgy_name": "Sumilihon",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202082",
      "brgy_name": "Tagabaca",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202083",
      "brgy_name": "Taguibo",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202084",
      "brgy_name": "Taligaman",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202085",
      "brgy_name": "Tandang Sora Pob. (Bgy. 12)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202086",
      "brgy_name": "Tiniwisan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202087",
      "brgy_name": "Tungao",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202089",
      "brgy_name": "Urduja Pob. (Bgy. 9)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202090",
      "brgy_name": "Villa Kananga",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202091",
      "brgy_name": "Obrero Pob. (Bgy. 18)",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202092",
      "brgy_name": "Bugsukan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202093",
      "brgy_name": "De Oro",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202094",
      "brgy_name": "Dulag",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202095",
      "brgy_name": "Florida",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202096",
      "brgy_name": "Nong-nong",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202097",
      "brgy_name": "Pagatpatan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202098",
      "brgy_name": "Pangabugan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202099",
      "brgy_name": "Salvacion",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202100",
      "brgy_name": "Santo Niño",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202101",
      "brgy_name": "Sumile",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202102",
      "brgy_name": "Don Francisco",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160202103",
      "brgy_name": "Pigdaulan",
      "city_code": "160202",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203002",
      "brgy_name": "Antonio Luna",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203005",
      "brgy_name": "Bay-ang",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203006",
      "brgy_name": "Bayabas",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203007",
      "brgy_name": "Caasinan",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203009",
      "brgy_name": "Cabinet",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203010",
      "brgy_name": "Calamba",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203011",
      "brgy_name": "Calibunan",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203012",
      "brgy_name": "Comagascas",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203013",
      "brgy_name": "Concepcion",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203014",
      "brgy_name": "Del Pilar",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203016",
      "brgy_name": "Katugasan",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203017",
      "brgy_name": "Kauswagan",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203018",
      "brgy_name": "La Union",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203019",
      "brgy_name": "Mabini",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203021",
      "brgy_name": "Poblacion 1",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203022",
      "brgy_name": "Poblacion 10",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203023",
      "brgy_name": "Poblacion 11",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203024",
      "brgy_name": "Poblacion 12",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203025",
      "brgy_name": "Poblacion 2",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203026",
      "brgy_name": "Poblacion 3",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203027",
      "brgy_name": "Poblacion 4",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203028",
      "brgy_name": "Poblacion 5",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203029",
      "brgy_name": "Poblacion 6",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203030",
      "brgy_name": "Poblacion 7",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203031",
      "brgy_name": "Poblacion 8",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203032",
      "brgy_name": "Poblacion 9",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203033",
      "brgy_name": "Puting Bato",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203037",
      "brgy_name": "Sanghan",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203038",
      "brgy_name": "Soriano",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203040",
      "brgy_name": "Tolosa",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160203041",
      "brgy_name": "Mahaba",
      "city_code": "160203",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204001",
      "brgy_name": "Cahayagan",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204002",
      "brgy_name": "Gosoon",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204004",
      "brgy_name": "Manoligao",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204009",
      "brgy_name": "Poblacion (Carmen)",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204010",
      "brgy_name": "Rojales",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204011",
      "brgy_name": "San Agustin",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204012",
      "brgy_name": "Tagcatong",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160204014",
      "brgy_name": "Vinapor",
      "city_code": "160204",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205001",
      "brgy_name": "Baleguian",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205002",
      "brgy_name": "Bangonay",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205003",
      "brgy_name": "A. Beltran (Camalig)",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205004",
      "brgy_name": "Bunga",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205005",
      "brgy_name": "Colorado",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205006",
      "brgy_name": "Cuyago",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205007",
      "brgy_name": "Libas",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205008",
      "brgy_name": "Magdagooc",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205009",
      "brgy_name": "Magsaysay",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205010",
      "brgy_name": "Maraiging",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205011",
      "brgy_name": "Poblacion (Jabonga)",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205012",
      "brgy_name": "San Jose",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205013",
      "brgy_name": "San Pablo",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205014",
      "brgy_name": "San Vicente",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160205015",
      "brgy_name": "Santo Niño",
      "city_code": "160205",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206001",
      "brgy_name": "Bangayan",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206002",
      "brgy_name": "Canaway",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206003",
      "brgy_name": "Hinimbangan",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206004",
      "brgy_name": "Jaliobong",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206005",
      "brgy_name": "Mahayahay",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206006",
      "brgy_name": "Poblacion",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206007",
      "brgy_name": "San Isidro",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206008",
      "brgy_name": "San Roque",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206009",
      "brgy_name": "Sangay",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206010",
      "brgy_name": "Crossing",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160206011",
      "brgy_name": "Songkoy",
      "city_code": "160206",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207001",
      "brgy_name": "Ambacon",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207002",
      "brgy_name": "Bonifacio",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207003",
      "brgy_name": "Consorcia",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207004",
      "brgy_name": "Katipunan",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207005",
      "brgy_name": "Lingayao",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207006",
      "brgy_name": "Malicato",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207007",
      "brgy_name": "Maningalao",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207008",
      "brgy_name": "Marcos Calo",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207009",
      "brgy_name": "Mat-i",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207010",
      "brgy_name": "Pinana-an",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207011",
      "brgy_name": "Poblacion",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207013",
      "brgy_name": "San Isidro",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207014",
      "brgy_name": "Tinucoran",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207015",
      "brgy_name": "Balungagan",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207016",
      "brgy_name": "Eduardo G. Montilla (Camboayon)",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207017",
      "brgy_name": "Durian",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207018",
      "brgy_name": "Ibuan",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207019",
      "brgy_name": "Rosario",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207020",
      "brgy_name": "San Roque",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160207021",
      "brgy_name": "Casiklan",
      "city_code": "160207",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208001",
      "brgy_name": "Buhang",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208002",
      "brgy_name": "Caloc-an",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208003",
      "brgy_name": "Guiasan",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208005",
      "brgy_name": "Poblacion",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208008",
      "brgy_name": "Taod-oy",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208009",
      "brgy_name": "Marcos",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208010",
      "brgy_name": "Santo Niño",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160208011",
      "brgy_name": "Santo Rosario",
      "city_code": "160208",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209001",
      "brgy_name": "Aclan",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209002",
      "brgy_name": "Amontay",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209004",
      "brgy_name": "Ata-atahon",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209005",
      "brgy_name": "Camagong",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209006",
      "brgy_name": "Cubi-cubi",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209007",
      "brgy_name": "Culit",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209008",
      "brgy_name": "Jaguimitan",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209009",
      "brgy_name": "Kinabjangan",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209010",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209011",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209012",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209013",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209014",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209015",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209016",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209017",
      "brgy_name": "Punta",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209018",
      "brgy_name": "Santa Ana",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209019",
      "brgy_name": "Talisay",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160209020",
      "brgy_name": "Triangulo",
      "city_code": "160209",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210002",
      "brgy_name": "Curva",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210003",
      "brgy_name": "Jagupit",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210005",
      "brgy_name": "La Paz",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210007",
      "brgy_name": "Poblacion I",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210008",
      "brgy_name": "San Isidro",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210009",
      "brgy_name": "Tagbuyacan",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210010",
      "brgy_name": "Estanislao Morgado",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210011",
      "brgy_name": "Poblacion II",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160210012",
      "brgy_name": "Pangaylan-IP",
      "city_code": "160210",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211001",
      "brgy_name": "Binuangan",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211002",
      "brgy_name": "Cabayawa",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211003",
      "brgy_name": "Doña Rosario",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211004",
      "brgy_name": "La Fraternidad",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211005",
      "brgy_name": "Lawigan",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211006",
      "brgy_name": "Poblacion 1",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211007",
      "brgy_name": "Poblacion 2",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211008",
      "brgy_name": "Santa Ana",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211009",
      "brgy_name": "Tagmamarkay",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211010",
      "brgy_name": "Tagpangahoy",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211011",
      "brgy_name": "Tinigbasan",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211012",
      "brgy_name": "Victory",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160211013",
      "brgy_name": "Doña Telesfora",
      "city_code": "160211",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212001",
      "brgy_name": "Poblacion I (Agay)",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212002",
      "brgy_name": "Balangbalang",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212003",
      "brgy_name": "Basilisa",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212004",
      "brgy_name": "Humilog",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212005",
      "brgy_name": "Panaytayon",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212006",
      "brgy_name": "San Antonio",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212007",
      "brgy_name": "Tagbongabong",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160212008",
      "brgy_name": "Poblacion II",
      "city_code": "160212",
      "province_code": "1602",
      "region_code": "16"
    },
    {
      "brgy_code": "160301001",
      "brgy_name": "Calaitan",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301002",
      "brgy_name": "Charito",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301003",
      "brgy_name": "Fili",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301004",
      "brgy_name": "Hamogaway",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301006",
      "brgy_name": "Katipunan",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301007",
      "brgy_name": "Mabuhay",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301009",
      "brgy_name": "Marcelina",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301010",
      "brgy_name": "Maygatasan",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301011",
      "brgy_name": "Noli",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301013",
      "brgy_name": "Osmeña",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301014",
      "brgy_name": "Panaytay",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301016",
      "brgy_name": "Poblacion",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301017",
      "brgy_name": "Sagmone",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301018",
      "brgy_name": "Saguma",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301019",
      "brgy_name": "Salvacion",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301020",
      "brgy_name": "San Isidro",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301023",
      "brgy_name": "Santa Irene",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301024",
      "brgy_name": "Taglatawan",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301025",
      "brgy_name": "Verdu",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301027",
      "brgy_name": "Wawa",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301028",
      "brgy_name": "Berseba",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301029",
      "brgy_name": "Bucac",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301030",
      "brgy_name": "Cagbas",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301031",
      "brgy_name": "Canayugan",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301032",
      "brgy_name": "Claro Cortez",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301033",
      "brgy_name": "Gamao",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301034",
      "brgy_name": "Getsemane",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301035",
      "brgy_name": "Grace Estate",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301036",
      "brgy_name": "Magkiangkang",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301037",
      "brgy_name": "Mahayag",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301038",
      "brgy_name": "Montivesta",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301039",
      "brgy_name": "Mt. Ararat",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301040",
      "brgy_name": "Mt. Carmel",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301041",
      "brgy_name": "Mt. Olive",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301042",
      "brgy_name": "New Salem",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301043",
      "brgy_name": "Pinagalaan",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301044",
      "brgy_name": "San Agustin",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301045",
      "brgy_name": "San Juan",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301046",
      "brgy_name": "Santa Teresita",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301047",
      "brgy_name": "Santo Niño",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301048",
      "brgy_name": "Taglibas",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301049",
      "brgy_name": "Tagubay",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160301050",
      "brgy_name": "Villa Undayon",
      "city_code": "160301",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302001",
      "brgy_name": "Bunawan Brook",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302002",
      "brgy_name": "Consuelo",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302003",
      "brgy_name": "Libertad",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302004",
      "brgy_name": "Mambalili",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302005",
      "brgy_name": "Poblacion",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302006",
      "brgy_name": "San Andres",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302007",
      "brgy_name": "San Marcos",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302008",
      "brgy_name": "Imelda",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302009",
      "brgy_name": "Nueva Era",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160302010",
      "brgy_name": "San Teodoro",
      "city_code": "160302",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303001",
      "brgy_name": "Anolingan",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303002",
      "brgy_name": "Bakingking",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303003",
      "brgy_name": "Bentahon",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303004",
      "brgy_name": "Bunaguit",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303006",
      "brgy_name": "Catmonon",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303007",
      "brgy_name": "Concordia",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303008",
      "brgy_name": "Dakutan",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303009",
      "brgy_name": "Duangan",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303010",
      "brgy_name": "Mac-Arthur",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303011",
      "brgy_name": "Guadalupe",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303012",
      "brgy_name": "Hawilian",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303013",
      "brgy_name": "Labao",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303014",
      "brgy_name": "Maasin",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303015",
      "brgy_name": "Mahagcot",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303016",
      "brgy_name": "Milagros",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303017",
      "brgy_name": "Nato",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303018",
      "brgy_name": "Oro",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303019",
      "brgy_name": "Poblacion",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303020",
      "brgy_name": "Remedios",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303021",
      "brgy_name": "Salug",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303022",
      "brgy_name": "San Toribio",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303023",
      "brgy_name": "Santa Fe",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303024",
      "brgy_name": "Segunda",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303026",
      "brgy_name": "Tagabase",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303027",
      "brgy_name": "Taganahaw",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303028",
      "brgy_name": "Tagbalili",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303029",
      "brgy_name": "Tahina",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303030",
      "brgy_name": "Tandang Sora",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303031",
      "brgy_name": "Agsabu",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303032",
      "brgy_name": "Aguinaldo",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303033",
      "brgy_name": "Balubo",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303034",
      "brgy_name": "Cebulan",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303035",
      "brgy_name": "Crossing Luna",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303036",
      "brgy_name": "Cubo",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303037",
      "brgy_name": "Guibonon",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303038",
      "brgy_name": "Kalabuan",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303039",
      "brgy_name": "Kinamaybay",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303040",
      "brgy_name": "Langag",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303041",
      "brgy_name": "Maliwanag",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303042",
      "brgy_name": "New Gingoog",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303043",
      "brgy_name": "Odiong",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303044",
      "brgy_name": "Piglawigan",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303045",
      "brgy_name": "San Isidro",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303046",
      "brgy_name": "San Jose",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303047",
      "brgy_name": "San Vicente",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303048",
      "brgy_name": "Sinakungan",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160303049",
      "brgy_name": "Valentina",
      "city_code": "160303",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304001",
      "brgy_name": "Bataan",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304002",
      "brgy_name": "Comota",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304003",
      "brgy_name": "Halapitan",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304004",
      "brgy_name": "Langasian",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304005",
      "brgy_name": "Osmeña, Sr.",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304006",
      "brgy_name": "Poblacion",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304007",
      "brgy_name": "Sagunto",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304008",
      "brgy_name": "Villa Paz",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304009",
      "brgy_name": "Angeles",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304010",
      "brgy_name": "Kasapa II",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304011",
      "brgy_name": "Lydia",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304012",
      "brgy_name": "Panagangan",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304013",
      "brgy_name": "Sabang Adgawan",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304014",
      "brgy_name": "San Patricio",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160304015",
      "brgy_name": "Valentina",
      "city_code": "160304",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305001",
      "brgy_name": "Binucayan",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305002",
      "brgy_name": "Johnson",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305003",
      "brgy_name": "Magaud",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305004",
      "brgy_name": "Nueva Gracia",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305005",
      "brgy_name": "Poblacion",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305006",
      "brgy_name": "San Isidro",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305007",
      "brgy_name": "San Mariano",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305008",
      "brgy_name": "San Vicente",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305009",
      "brgy_name": "Santa Teresa",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305010",
      "brgy_name": "Santo Tomas",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305011",
      "brgy_name": "Violanta",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305012",
      "brgy_name": "Waloe",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305013",
      "brgy_name": "Kasapa",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305014",
      "brgy_name": "Katipunan",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305015",
      "brgy_name": "Kauswagan",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305016",
      "brgy_name": "Santo Niño",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160305017",
      "brgy_name": "Sabud",
      "city_code": "160305",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306001",
      "brgy_name": "Aurora",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306002",
      "brgy_name": "Awa",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306003",
      "brgy_name": "Azpetia",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306004",
      "brgy_name": "Poblacion (Bahbah)",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306006",
      "brgy_name": "La Caridad",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306007",
      "brgy_name": "La Suerte",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306008",
      "brgy_name": "La Union",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306009",
      "brgy_name": "Las Navas",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306010",
      "brgy_name": "Libertad",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306011",
      "brgy_name": "Los Arcos",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306012",
      "brgy_name": "Lucena",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306013",
      "brgy_name": "Mabuhay",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306014",
      "brgy_name": "Magsaysay",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306015",
      "brgy_name": "Mapaga",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306016",
      "brgy_name": "New Maug",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306017",
      "brgy_name": "Napo",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306018",
      "brgy_name": "Patin-ay",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306020",
      "brgy_name": "Salimbogaon",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306021",
      "brgy_name": "Salvacion",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306022",
      "brgy_name": "San Joaquin",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306023",
      "brgy_name": "San Jose",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306024",
      "brgy_name": "San Lorenzo",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306025",
      "brgy_name": "San Martin",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306026",
      "brgy_name": "San Pedro",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306027",
      "brgy_name": "San Rafael",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306029",
      "brgy_name": "San Salvador",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306030",
      "brgy_name": "San Vicente",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306031",
      "brgy_name": "Santa Irene",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306032",
      "brgy_name": "Santa Maria",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306033",
      "brgy_name": "La Perian",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306034",
      "brgy_name": "La Purisima",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160306035",
      "brgy_name": "San Roque",
      "city_code": "160306",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307001",
      "brgy_name": "Bayugan 3",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307002",
      "brgy_name": "Cabantao",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307003",
      "brgy_name": "Cabawan",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307004",
      "brgy_name": "Marfil",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307005",
      "brgy_name": "Novele",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307006",
      "brgy_name": "Poblacion",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307007",
      "brgy_name": "Santa Cruz",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307008",
      "brgy_name": "Tagbayagan",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307009",
      "brgy_name": "Wasi-an",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307010",
      "brgy_name": "Libuac",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160307011",
      "brgy_name": "Maligaya",
      "city_code": "160307",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308001",
      "brgy_name": "Alegria",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308002",
      "brgy_name": "Bayugan 2",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308003",
      "brgy_name": "Borbon",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308005",
      "brgy_name": "Caimpugan",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308006",
      "brgy_name": "Ebro",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308007",
      "brgy_name": "Hubang",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308008",
      "brgy_name": "Lapinigan",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308009",
      "brgy_name": "Lucac",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308010",
      "brgy_name": "Mate",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308011",
      "brgy_name": "New Visayas",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308012",
      "brgy_name": "Pasta",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308013",
      "brgy_name": "Pisa-an",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308015",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308016",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308017",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308018",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308019",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308020",
      "brgy_name": "Rizal",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308021",
      "brgy_name": "San Isidro",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308022",
      "brgy_name": "Santa Ana",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308024",
      "brgy_name": "Tagapua",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308025",
      "brgy_name": "Bitan-agan",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308026",
      "brgy_name": "Buenasuerte",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308027",
      "brgy_name": "Das-agan",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308028",
      "brgy_name": "Karaus",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308029",
      "brgy_name": "Ladgadan",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160308030",
      "brgy_name": "Ormaca",
      "city_code": "160308",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309001",
      "brgy_name": "Anislagan",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309002",
      "brgy_name": "Baylo",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309003",
      "brgy_name": "Coalicion",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309004",
      "brgy_name": "Culi",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309005",
      "brgy_name": "Nuevo Trabajo",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309006",
      "brgy_name": "Poblacion",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309007",
      "brgy_name": "Santa Ines",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309008",
      "brgy_name": "Balit",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309009",
      "brgy_name": "Binicalan",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309010",
      "brgy_name": "Cecilia",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309011",
      "brgy_name": "Dimasalang",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309012",
      "brgy_name": "Don Alejandro",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309013",
      "brgy_name": "Don Pedro",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309014",
      "brgy_name": "Doña Flavia",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309015",
      "brgy_name": "Mahagsay",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309016",
      "brgy_name": "Mahapag",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309017",
      "brgy_name": "Mahayahay",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309018",
      "brgy_name": "Muritula",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309019",
      "brgy_name": "Policarpo",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309020",
      "brgy_name": "San Isidro",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309021",
      "brgy_name": "San Pedro",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309022",
      "brgy_name": "Santa Rita",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309023",
      "brgy_name": "Santiago",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309024",
      "brgy_name": "Wegguam",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160309025",
      "brgy_name": "Doña Maxima",
      "city_code": "160309",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310001",
      "brgy_name": "Angas",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310002",
      "brgy_name": "Aurora",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310003",
      "brgy_name": "Awao",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310004",
      "brgy_name": "Tapaz",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310005",
      "brgy_name": "Patrocinio",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310006",
      "brgy_name": "Poblacion",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310007",
      "brgy_name": "San Jose",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310008",
      "brgy_name": "Santa Isabel",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310009",
      "brgy_name": "Sayon",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310010",
      "brgy_name": "Concepcion",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160310011",
      "brgy_name": "Pag-asa",
      "city_code": "160310",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311001",
      "brgy_name": "BuenaGracia",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311002",
      "brgy_name": "Causwagan",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311004",
      "brgy_name": "Culi",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311005",
      "brgy_name": "Del Monte",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311006",
      "brgy_name": "Desamparados",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311007",
      "brgy_name": "Labnig",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311008",
      "brgy_name": "Sabang Gibung",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311009",
      "brgy_name": "San Agustin (Pob.)",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311010",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311011",
      "brgy_name": "San Nicolas (Pob.)",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311012",
      "brgy_name": "Zamora",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311013",
      "brgy_name": "Zillovia",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311014",
      "brgy_name": "La Flora",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311015",
      "brgy_name": "Maharlika",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311016",
      "brgy_name": "Marbon",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160311017",
      "brgy_name": "Batucan",
      "city_code": "160311",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312001",
      "brgy_name": "Basa",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312002",
      "brgy_name": "Cuevas",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312003",
      "brgy_name": "Kapatungan",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312004",
      "brgy_name": "Langkila-an",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312005",
      "brgy_name": "New Visayas",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312006",
      "brgy_name": "Poblacion",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312007",
      "brgy_name": "Pulang-lupa",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312008",
      "brgy_name": "Salvacion",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312009",
      "brgy_name": "San Ignacio",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312010",
      "brgy_name": "San Isidro",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312011",
      "brgy_name": "San Roque",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312012",
      "brgy_name": "Santa Maria",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312013",
      "brgy_name": "Tudela",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312014",
      "brgy_name": "Cebolin",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312015",
      "brgy_name": "Manat",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160312016",
      "brgy_name": "Pangyan",
      "city_code": "160312",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313001",
      "brgy_name": "Binongan",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313002",
      "brgy_name": "Del Monte",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313003",
      "brgy_name": "Don Mateo",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313004",
      "brgy_name": "La Fortuna",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313005",
      "brgy_name": "Limot",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313006",
      "brgy_name": "Magsaysay",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313007",
      "brgy_name": "Masayan",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313008",
      "brgy_name": "Poblacion",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313009",
      "brgy_name": "Sampaguita",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313010",
      "brgy_name": "San Gabriel",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313011",
      "brgy_name": "Santa Emelia",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313012",
      "brgy_name": "Sinobong",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313013",
      "brgy_name": "Anitap",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313014",
      "brgy_name": "Bacay II",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313015",
      "brgy_name": "Caigangan",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313016",
      "brgy_name": "Candiis",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313017",
      "brgy_name": "Katipunan",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313018",
      "brgy_name": "Santa Cruz",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313019",
      "brgy_name": "Sawagan",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160313020",
      "brgy_name": "Sisimon",
      "city_code": "160313",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314001",
      "brgy_name": "Afga",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314002",
      "brgy_name": "Anahawan",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314003",
      "brgy_name": "Banagbanag",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314004",
      "brgy_name": "Del Rosario",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314005",
      "brgy_name": "El Rio",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314006",
      "brgy_name": "Ilihan",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314007",
      "brgy_name": "Kauswagan",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314008",
      "brgy_name": "Kioya",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314009",
      "brgy_name": "Magkalape",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314010",
      "brgy_name": "Magsaysay",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314011",
      "brgy_name": "Mahayahay",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314012",
      "brgy_name": "New Tubigon",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314013",
      "brgy_name": "Padiay",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314014",
      "brgy_name": "Perez",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314015",
      "brgy_name": "Poblacion",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314016",
      "brgy_name": "San Isidro",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314017",
      "brgy_name": "San Vicente",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314018",
      "brgy_name": "Santa Cruz",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314019",
      "brgy_name": "Santa Maria",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314020",
      "brgy_name": "Sinai",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314021",
      "brgy_name": "Tabon-tabon",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314022",
      "brgy_name": "Tag-uyango",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314023",
      "brgy_name": "Villangit",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "160314024",
      "brgy_name": "Kolambugan",
      "city_code": "160314",
      "province_code": "1603",
      "region_code": "16"
    },
    {
      "brgy_code": "166701001",
      "brgy_name": "Poblacion (Alegria)",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701002",
      "brgy_name": "Alipao",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701003",
      "brgy_name": "Budlingin",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701004",
      "brgy_name": "Camp Eduard (Geotina)",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701005",
      "brgy_name": "Ombong",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701006",
      "brgy_name": "Pongtud",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701007",
      "brgy_name": "San Pedro",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701008",
      "brgy_name": "Ferlda",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701009",
      "brgy_name": "Julio Ouano (Pob.)",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701010",
      "brgy_name": "San Juan",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701011",
      "brgy_name": "Anahaw",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166701012",
      "brgy_name": "Gamuton",
      "city_code": "166701",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702001",
      "brgy_name": "Cabugao",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702002",
      "brgy_name": "Cambuayon",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702003",
      "brgy_name": "Campo",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702004",
      "brgy_name": "Dugsangon",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702005",
      "brgy_name": "Pautao",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702006",
      "brgy_name": "Payapag",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702007",
      "brgy_name": "Poblacion",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702008",
      "brgy_name": "Pungtod",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166702009",
      "brgy_name": "Santo Rosario",
      "city_code": "166702",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166704001",
      "brgy_name": "Baybay",
      "city_code": "166704",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166704002",
      "brgy_name": "Bitaug",
      "city_code": "166704",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166704003",
      "brgy_name": "Poblacion 1",
      "city_code": "166704",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166704004",
      "brgy_name": "Poblacion 2",
      "city_code": "166704",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166704005",
      "brgy_name": "San Mateo",
      "city_code": "166704",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166704006",
      "brgy_name": "Matin-ao",
      "city_code": "166704",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706001",
      "brgy_name": "Cabugo",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706002",
      "brgy_name": "Cagdianao",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706003",
      "brgy_name": "Daywan",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706004",
      "brgy_name": "Hayanggabon",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706005",
      "brgy_name": "Ladgaron (Pob.)",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706006",
      "brgy_name": "Lapinigan",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706007",
      "brgy_name": "Magallanes",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706008",
      "brgy_name": "Panatao",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706009",
      "brgy_name": "Tayaga (Pob. East)",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706010",
      "brgy_name": "Bagakay (Pob. West)",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706011",
      "brgy_name": "Sapa",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706012",
      "brgy_name": "Taganito",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706013",
      "brgy_name": "Urbiztondo",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166706014",
      "brgy_name": "Wangke",
      "city_code": "166706",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707001",
      "brgy_name": "Bagakay",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707002",
      "brgy_name": "Barangay 1 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707003",
      "brgy_name": "Barangay 13 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707004",
      "brgy_name": "Buenavista",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707005",
      "brgy_name": "Cabawa",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707006",
      "brgy_name": "Cambas-ac",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707007",
      "brgy_name": "Consolacion",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707008",
      "brgy_name": "Corregidor",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707009",
      "brgy_name": "Dagohoy",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707010",
      "brgy_name": "Don Paulino",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707011",
      "brgy_name": "Jubang",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707012",
      "brgy_name": "Montserrat",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707013",
      "brgy_name": "Osmeña",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707014",
      "brgy_name": "Barangay 10 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707015",
      "brgy_name": "Barangay 11 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707016",
      "brgy_name": "Barangay 12 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707017",
      "brgy_name": "Barangay 2 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707018",
      "brgy_name": "Barangay 3 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707019",
      "brgy_name": "Barangay 4 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707020",
      "brgy_name": "Barangay 5 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707021",
      "brgy_name": "Barangay 6 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707022",
      "brgy_name": "Barangay 7 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707023",
      "brgy_name": "Barangay 8 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707024",
      "brgy_name": "Barangay 9 (Pob.)",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707025",
      "brgy_name": "San Carlos",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707026",
      "brgy_name": "San Miguel",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707027",
      "brgy_name": "Santa Fe",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707028",
      "brgy_name": "Union",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166707029",
      "brgy_name": "Santa Felomina",
      "city_code": "166707",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708001",
      "brgy_name": "Bagakay (Alburo)",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708002",
      "brgy_name": "Antipolo",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708004",
      "brgy_name": "Bitoon",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708006",
      "brgy_name": "Cabugao",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708007",
      "brgy_name": "Cancohoy",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708008",
      "brgy_name": "Caub",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708009",
      "brgy_name": "Del Carmen (Pob.)",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708010",
      "brgy_name": "Domoyog",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708011",
      "brgy_name": "Esperanza",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708012",
      "brgy_name": "Jamoyaon",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708013",
      "brgy_name": "Katipunan",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708014",
      "brgy_name": "Lobogon",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708016",
      "brgy_name": "Mabuhay",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708017",
      "brgy_name": "Mahayahay",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708019",
      "brgy_name": "Quezon",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708021",
      "brgy_name": "San Fernando",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708022",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708025",
      "brgy_name": "Sayak",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708027",
      "brgy_name": "Tuboran",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166708028",
      "brgy_name": "Halian",
      "city_code": "166708",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710001",
      "brgy_name": "Anajawan",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710002",
      "brgy_name": "Cabitoonan",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710003",
      "brgy_name": "Catangnan",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710004",
      "brgy_name": "Consuelo",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710005",
      "brgy_name": "Corazon",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710006",
      "brgy_name": "Daku",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710007",
      "brgy_name": "Poblacion I (Purok I)",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710008",
      "brgy_name": "Poblacion II (Purok II)",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710009",
      "brgy_name": "Poblacion III (Purok III)",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710010",
      "brgy_name": "Poblacion IV (Purok IV)",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710011",
      "brgy_name": "Poblacion V (Purok V)",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710012",
      "brgy_name": "La Januza",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710013",
      "brgy_name": "Libertad",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710014",
      "brgy_name": "Magsaysay",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710015",
      "brgy_name": "Malinao",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710017",
      "brgy_name": "Santa Cruz",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710018",
      "brgy_name": "Santa Fe",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710019",
      "brgy_name": "Suyangan",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166710020",
      "brgy_name": "Tawin-tawin",
      "city_code": "166710",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711001",
      "brgy_name": "Alambique (Pob.)",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711002",
      "brgy_name": "Anibongan",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711003",
      "brgy_name": "Camam-onan",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711004",
      "brgy_name": "Cam-boayon",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711005",
      "brgy_name": "Ipil (Pob.)",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711006",
      "brgy_name": "Lahi",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711007",
      "brgy_name": "Mahanub",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711008",
      "brgy_name": "Poniente",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711009",
      "brgy_name": "San Antonio (Bonot)",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711010",
      "brgy_name": "San Isidro",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711011",
      "brgy_name": "Sico-sico",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711012",
      "brgy_name": "Villaflor",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166711013",
      "brgy_name": "Villafranca",
      "city_code": "166711",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714001",
      "brgy_name": "Binga",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714002",
      "brgy_name": "Bobona-on",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714003",
      "brgy_name": "Cantugas",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714004",
      "brgy_name": "Dayano",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714005",
      "brgy_name": "Mabini",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714006",
      "brgy_name": "Magpayang",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714007",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714009",
      "brgy_name": "Mansayao",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714010",
      "brgy_name": "Marayag",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714011",
      "brgy_name": "Matin-ao",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714012",
      "brgy_name": "Paco",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714013",
      "brgy_name": "Quezon (Pob.)",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714014",
      "brgy_name": "Roxas",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714015",
      "brgy_name": "San Francisco",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714016",
      "brgy_name": "San Isidro",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714017",
      "brgy_name": "San Jose",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714018",
      "brgy_name": "Siana",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714019",
      "brgy_name": "Silop",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714020",
      "brgy_name": "Tagbuyawan",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714021",
      "brgy_name": "Tapi-an",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166714022",
      "brgy_name": "Tolingon",
      "city_code": "166714",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715001",
      "brgy_name": "Doro (Binocaran)",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715002",
      "brgy_name": "Bunyasan",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715003",
      "brgy_name": "Cantapoy",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715004",
      "brgy_name": "Cagtinae",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715005",
      "brgy_name": "Cayawan",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715007",
      "brgy_name": "Hanagdong",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715008",
      "brgy_name": "Karihatag",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715009",
      "brgy_name": "Masgad",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715010",
      "brgy_name": "Pili",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715012",
      "brgy_name": "San Isidro (Pob.)",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715013",
      "brgy_name": "Tinago",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715014",
      "brgy_name": "Cansayong",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715015",
      "brgy_name": "Can-aga",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166715016",
      "brgy_name": "Villariza",
      "city_code": "166715",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716001",
      "brgy_name": "Caridad",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716002",
      "brgy_name": "Katipunan",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716003",
      "brgy_name": "Maasin",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716004",
      "brgy_name": "Mabini",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716005",
      "brgy_name": "Mabuhay",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716006",
      "brgy_name": "Salvacion",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716007",
      "brgy_name": "San Roque",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716008",
      "brgy_name": "Asinan (Pob.)",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716009",
      "brgy_name": "Centro (Pob.)",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716010",
      "brgy_name": "Pilaring (Pob.)",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716011",
      "brgy_name": "Punta (Pob.)",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716012",
      "brgy_name": "Consolacion",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716013",
      "brgy_name": "Datu",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716014",
      "brgy_name": "Dayaohay",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166716015",
      "brgy_name": "Jaboy",
      "city_code": "166716",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717002",
      "brgy_name": "Amoslog",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717003",
      "brgy_name": "Anislagan",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717004",
      "brgy_name": "Bad-as",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717005",
      "brgy_name": "Boyongan",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717006",
      "brgy_name": "Bugas-bugas",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717007",
      "brgy_name": "Central (Pob.)",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717008",
      "brgy_name": "Ellaperal (Nonok)",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717009",
      "brgy_name": "Ipil (Pob.)",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717010",
      "brgy_name": "Lakandula",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717011",
      "brgy_name": "Mabini",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717012",
      "brgy_name": "Macalaya",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717013",
      "brgy_name": "Magsaysay (Pob.)",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717014",
      "brgy_name": "Magupange",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717015",
      "brgy_name": "Pananay-an",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717016",
      "brgy_name": "Panhutongan",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717017",
      "brgy_name": "San Isidro",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717018",
      "brgy_name": "Santa Cruz",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717019",
      "brgy_name": "Suyoc",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717020",
      "brgy_name": "Tagbongabong",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166717021",
      "brgy_name": "Sani-sani",
      "city_code": "166717",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166718001",
      "brgy_name": "Bongdo",
      "city_code": "166718",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166718002",
      "brgy_name": "Maribojoc",
      "city_code": "166718",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166718003",
      "brgy_name": "Nuevo Campo",
      "city_code": "166718",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166718005",
      "brgy_name": "San Juan",
      "city_code": "166718",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166718006",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "166718",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166718007",
      "brgy_name": "Talisay (Pob.)",
      "city_code": "166718",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719001",
      "brgy_name": "Amontay",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719002",
      "brgy_name": "Balite",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719003",
      "brgy_name": "Banbanon",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719004",
      "brgy_name": "Diaz",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719005",
      "brgy_name": "Honrado",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719006",
      "brgy_name": "Jubgan",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719007",
      "brgy_name": "Linongganan",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719008",
      "brgy_name": "Macopa",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719009",
      "brgy_name": "Magtangale",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719010",
      "brgy_name": "Oslao",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166719011",
      "brgy_name": "Poblacion",
      "city_code": "166719",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720001",
      "brgy_name": "Buhing Calipay",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720002",
      "brgy_name": "Del Carmen (Pob.)",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720003",
      "brgy_name": "Del Pilar",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720004",
      "brgy_name": "Macapagal",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720006",
      "brgy_name": "Pacifico",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720007",
      "brgy_name": "Pelaez",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720008",
      "brgy_name": "Roxas",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720009",
      "brgy_name": "San Miguel",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720010",
      "brgy_name": "Santa Paz",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720011",
      "brgy_name": "Santo Niño",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720012",
      "brgy_name": "Tambacan",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166720013",
      "brgy_name": "Tigasao",
      "city_code": "166720",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721001",
      "brgy_name": "Abad Santos",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721002",
      "brgy_name": "Alegria",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721003",
      "brgy_name": "T. Arlan (Pob.)",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721004",
      "brgy_name": "Bailan",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721005",
      "brgy_name": "Garcia",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721006",
      "brgy_name": "Libertad",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721007",
      "brgy_name": "Mabini",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721008",
      "brgy_name": "Mabuhay (Pob.)",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721009",
      "brgy_name": "Magsaysay",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721010",
      "brgy_name": "Rizal",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166721012",
      "brgy_name": "Tangbo",
      "city_code": "166721",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722001",
      "brgy_name": "Biyabid",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722003",
      "brgy_name": "Gacepan",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722004",
      "brgy_name": "Ima",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722005",
      "brgy_name": "Lower Patag",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722006",
      "brgy_name": "Mabuhay",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722007",
      "brgy_name": "Mayag",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722008",
      "brgy_name": "Poblacion (San Pedro)",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722009",
      "brgy_name": "San Isidro",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722010",
      "brgy_name": "San Pablo",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722011",
      "brgy_name": "Tagbayani",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722012",
      "brgy_name": "Tinogpahan",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166722013",
      "brgy_name": "Upper Patag",
      "city_code": "166722",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723001",
      "brgy_name": "Del Pilar",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723002",
      "brgy_name": "Helene",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723003",
      "brgy_name": "Honrado",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723004",
      "brgy_name": "Navarro (Pob.)",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723005",
      "brgy_name": "Nueva Estrella",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723006",
      "brgy_name": "Pamosaingan",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723007",
      "brgy_name": "Rizal (Pob.)",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723008",
      "brgy_name": "Salog",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723009",
      "brgy_name": "San Roque",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723010",
      "brgy_name": "Santa Cruz",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723011",
      "brgy_name": "Sering",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723012",
      "brgy_name": "Songkoy",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723013",
      "brgy_name": "Sudlon",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166723014",
      "brgy_name": "Albino Taruc",
      "city_code": "166723",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724001",
      "brgy_name": "Alang-alang",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724002",
      "brgy_name": "Alegria",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724003",
      "brgy_name": "Anomar",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724004",
      "brgy_name": "Aurora",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724005",
      "brgy_name": "Serna (Bad-asay)",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724006",
      "brgy_name": "Balibayon",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724007",
      "brgy_name": "Baybay",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724008",
      "brgy_name": "Bilabid",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724010",
      "brgy_name": "Bitaugan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724011",
      "brgy_name": "Bonifacio",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724012",
      "brgy_name": "Buenavista",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724013",
      "brgy_name": "Cabongbongan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724014",
      "brgy_name": "Cagniog",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724015",
      "brgy_name": "Cagutsan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724016",
      "brgy_name": "Cantiasay",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724017",
      "brgy_name": "Capalayan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724018",
      "brgy_name": "Catadman",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724019",
      "brgy_name": "Danao",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724020",
      "brgy_name": "Danawan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724021",
      "brgy_name": "Day-asan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724022",
      "brgy_name": "Ipil",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724023",
      "brgy_name": "Libuac",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724024",
      "brgy_name": "Lipata",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724025",
      "brgy_name": "Lisondra",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724026",
      "brgy_name": "Luna",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724027",
      "brgy_name": "Mabini",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724028",
      "brgy_name": "Mabua",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724029",
      "brgy_name": "Manyagao",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724030",
      "brgy_name": "Mapawa",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724031",
      "brgy_name": "Mat-i",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724032",
      "brgy_name": "Nabago",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724033",
      "brgy_name": "Nonoc",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724034",
      "brgy_name": "Poctoy",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724035",
      "brgy_name": "Punta Bilar",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724036",
      "brgy_name": "Quezon",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724037",
      "brgy_name": "Rizal",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724038",
      "brgy_name": "Sabang",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724039",
      "brgy_name": "San Isidro",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724040",
      "brgy_name": "San Jose",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724041",
      "brgy_name": "San Juan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724042",
      "brgy_name": "San Pedro (Hanigad)",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724043",
      "brgy_name": "San Roque",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724044",
      "brgy_name": "Sidlakan",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724045",
      "brgy_name": "Silop",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724046",
      "brgy_name": "Sugbay",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724047",
      "brgy_name": "Sukailang",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724048",
      "brgy_name": "Taft (Pob.)",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724064",
      "brgy_name": "Talisay",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724065",
      "brgy_name": "Togbongon",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724066",
      "brgy_name": "Trinidad",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724067",
      "brgy_name": "Orok",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724068",
      "brgy_name": "Washington (Pob.)",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724095",
      "brgy_name": "Zaragoza",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166724096",
      "brgy_name": "Canlanipa",
      "city_code": "166724",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725001",
      "brgy_name": "Aurora (Pob.)",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725002",
      "brgy_name": "Azucena (Pob.)",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725003",
      "brgy_name": "Banban",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725004",
      "brgy_name": "Cawilan",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725005",
      "brgy_name": "Fabio",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725007",
      "brgy_name": "Himamaug",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725008",
      "brgy_name": "Laurel",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725009",
      "brgy_name": "Lower Libas",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725010",
      "brgy_name": "Opong",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725011",
      "brgy_name": "Sampaguita (Pob.)",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725012",
      "brgy_name": "Talavera",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725013",
      "brgy_name": "Union",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725014",
      "brgy_name": "Upper Libas",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166725015",
      "brgy_name": "Patino",
      "city_code": "166725",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727001",
      "brgy_name": "Capayahan",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727002",
      "brgy_name": "Cawilan",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727003",
      "brgy_name": "Del Rosario",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727004",
      "brgy_name": "Marga",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727005",
      "brgy_name": "Motorpool",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727006",
      "brgy_name": "Poblacion (Tubod)",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727007",
      "brgy_name": "San Isidro",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727008",
      "brgy_name": "Timamana",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166727009",
      "brgy_name": "San Pablo",
      "city_code": "166727",
      "province_code": "1667",
      "region_code": "16"
    },
    {
      "brgy_code": "166801001",
      "brgy_name": "Amaga",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801002",
      "brgy_name": "Bahi",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801003",
      "brgy_name": "Cabacungan",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801005",
      "brgy_name": "Cambagang",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801006",
      "brgy_name": "Causwagan",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801007",
      "brgy_name": "Dapdap",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801008",
      "brgy_name": "Dughan",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801009",
      "brgy_name": "Gamut",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801010",
      "brgy_name": "Javier",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801011",
      "brgy_name": "Kinayan",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801013",
      "brgy_name": "Mamis",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801015",
      "brgy_name": "Poblacion",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801016",
      "brgy_name": "Rizal",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801017",
      "brgy_name": "San Jose",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801019",
      "brgy_name": "San Vicente",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801020",
      "brgy_name": "Sua",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801021",
      "brgy_name": "Sudlon",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801023",
      "brgy_name": "Unidad",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801024",
      "brgy_name": "Wakat",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801025",
      "brgy_name": "San Roque",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166801026",
      "brgy_name": "Tambis",
      "city_code": "166801",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166802001",
      "brgy_name": "Amag",
      "city_code": "166802",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166802002",
      "brgy_name": "Balete (Pob.)",
      "city_code": "166802",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166802003",
      "brgy_name": "Cabugo",
      "city_code": "166802",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166802004",
      "brgy_name": "Cagbaoto",
      "city_code": "166802",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166802005",
      "brgy_name": "La Paz",
      "city_code": "166802",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166802006",
      "brgy_name": "Magobawok",
      "city_code": "166802",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166802007",
      "brgy_name": "Panaosawon",
      "city_code": "166802",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803002",
      "brgy_name": "Bucto",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803003",
      "brgy_name": "Burboanan",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803004",
      "brgy_name": "San Roque (Cadanglasan)",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803005",
      "brgy_name": "Caguyao",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803006",
      "brgy_name": "Coleto",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803007",
      "brgy_name": "Labisma",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803008",
      "brgy_name": "Lawigan",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803009",
      "brgy_name": "Mangagoy",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803010",
      "brgy_name": "Mone",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803011",
      "brgy_name": "Pamaypayan",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803012",
      "brgy_name": "Poblacion",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803013",
      "brgy_name": "San Antonio",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803014",
      "brgy_name": "San Fernando",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803015",
      "brgy_name": "San Isidro (Bagnan)",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803016",
      "brgy_name": "San Jose",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803017",
      "brgy_name": "San Vicente",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803018",
      "brgy_name": "Santa Cruz",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803019",
      "brgy_name": "Sibaroy",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803020",
      "brgy_name": "Tabon",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803021",
      "brgy_name": "Tumanan",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803022",
      "brgy_name": "Pamanlinan",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803023",
      "brgy_name": "Kahayag",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803024",
      "brgy_name": "Maharlika",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166803025",
      "brgy_name": "Comawas",
      "city_code": "166803",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804001",
      "brgy_name": "Aras-Asan",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804002",
      "brgy_name": "Bacolod",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804003",
      "brgy_name": "Bitaugan East",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804004",
      "brgy_name": "Bitaugan West",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804005",
      "brgy_name": "Tawagan",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804006",
      "brgy_name": "Lactudan",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804007",
      "brgy_name": "Mat-e",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804008",
      "brgy_name": "La Purisima (Palhe)",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804009",
      "brgy_name": "Poblacion",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804011",
      "brgy_name": "Unidad",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166804012",
      "brgy_name": "Tubo-tubo",
      "city_code": "166804",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805002",
      "brgy_name": "Bugsukan",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805003",
      "brgy_name": "Buntalid",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805004",
      "brgy_name": "Cabangahan",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805005",
      "brgy_name": "Cabas-an",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805006",
      "brgy_name": "Calagdaan",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805007",
      "brgy_name": "Consuelo",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805008",
      "brgy_name": "General Island",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805009",
      "brgy_name": "Lininti-an (Pob.)",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805010",
      "brgy_name": "Magasang",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805011",
      "brgy_name": "Magosilom (Pob.)",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805012",
      "brgy_name": "Pag-Antayan",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805013",
      "brgy_name": "Palasao",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805014",
      "brgy_name": "Parang",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805015",
      "brgy_name": "Tapi",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805016",
      "brgy_name": "Tigabong",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805017",
      "brgy_name": "Lobo",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166805018",
      "brgy_name": "San Pedro",
      "city_code": "166805",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806001",
      "brgy_name": "Antao",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806002",
      "brgy_name": "Cancavan",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806003",
      "brgy_name": "Carmen (Pob.)",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806004",
      "brgy_name": "Esperanza",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806005",
      "brgy_name": "Puyat",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806006",
      "brgy_name": "San Vicente",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806007",
      "brgy_name": "Santa Cruz",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166806008",
      "brgy_name": "Hinapoyan",
      "city_code": "166806",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807001",
      "brgy_name": "Adlay",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807002",
      "brgy_name": "Babuyan",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807003",
      "brgy_name": "Bacolod",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807004",
      "brgy_name": "Baybay (Pob.)",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807005",
      "brgy_name": "Bon-ot",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807006",
      "brgy_name": "Caglayag",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807007",
      "brgy_name": "Dahican",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807008",
      "brgy_name": "Doyos (Pob.)",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807009",
      "brgy_name": "Embarcadero (Pob.)",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807010",
      "brgy_name": "Gamuton",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807011",
      "brgy_name": "Panikian",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807012",
      "brgy_name": "Pantukan",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807013",
      "brgy_name": "Saca (Pob.)",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166807014",
      "brgy_name": "Tag-Anito",
      "city_code": "166807",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808001",
      "brgy_name": "Balibadon",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808002",
      "brgy_name": "Burgos",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808003",
      "brgy_name": "Capandan",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808004",
      "brgy_name": "Mabahin",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808005",
      "brgy_name": "Madrelino",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808006",
      "brgy_name": "Manlico",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808007",
      "brgy_name": "Matho",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808008",
      "brgy_name": "Poblacion",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808009",
      "brgy_name": "Tag-Anongan",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808010",
      "brgy_name": "Tigao",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808011",
      "brgy_name": "Tuboran",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166808012",
      "brgy_name": "Uba",
      "city_code": "166808",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809002",
      "brgy_name": "Baculin",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809004",
      "brgy_name": "Bigaan",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809005",
      "brgy_name": "Cambatong",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809006",
      "brgy_name": "Campa",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809009",
      "brgy_name": "Dugmanon",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809010",
      "brgy_name": "Harip",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809012",
      "brgy_name": "La Casa (Pob.)",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809014",
      "brgy_name": "Loyola",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809017",
      "brgy_name": "Maligaya",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809021",
      "brgy_name": "Pagtigni-an (Bitoon)",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809022",
      "brgy_name": "Pocto",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809023",
      "brgy_name": "Port Lamon",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809025",
      "brgy_name": "Roxas",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809026",
      "brgy_name": "San Juan",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809031",
      "brgy_name": "Sasa",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809034",
      "brgy_name": "Tagasaka",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809037",
      "brgy_name": "Talisay",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809038",
      "brgy_name": "Tarusan",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809039",
      "brgy_name": "Tidman",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809040",
      "brgy_name": "Tiwi",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809041",
      "brgy_name": "Benigno Aquino (Zone I Pob.)",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809042",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809043",
      "brgy_name": "Zone III Maharlika (Pob.)",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166809044",
      "brgy_name": "Tagbobonga",
      "city_code": "166809",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810001",
      "brgy_name": "Agsam",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810003",
      "brgy_name": "Bocawe",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810004",
      "brgy_name": "Bunga",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810008",
      "brgy_name": "Gamuton",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810009",
      "brgy_name": "Habag",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810010",
      "brgy_name": "Mampi",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810011",
      "brgy_name": "Nurcia",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810015",
      "brgy_name": "Sibahay",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810016",
      "brgy_name": "Zone I (Pob.)",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810017",
      "brgy_name": "Pakwan",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810018",
      "brgy_name": "Zone II (Pob.)",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810019",
      "brgy_name": "Zone III (Pob.)",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166810020",
      "brgy_name": "Zone IV (Pob.)",
      "city_code": "166810",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811001",
      "brgy_name": "Anibongan",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811002",
      "brgy_name": "Banahao",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811003",
      "brgy_name": "Ban-as",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811004",
      "brgy_name": "Baucawe",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811005",
      "brgy_name": "Diatagon",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811006",
      "brgy_name": "Ganayon",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811007",
      "brgy_name": "Liatimco",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811008",
      "brgy_name": "Manyayay",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811009",
      "brgy_name": "Payasan",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811010",
      "brgy_name": "Poblacion",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811011",
      "brgy_name": "Saint Christine",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811012",
      "brgy_name": "San Isidro",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166811013",
      "brgy_name": "San Pedro",
      "city_code": "166811",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812001",
      "brgy_name": "Anibongan",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812002",
      "brgy_name": "Barcelona",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812003",
      "brgy_name": "Bongan",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812004",
      "brgy_name": "Bogak",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812005",
      "brgy_name": "Handamayan",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812006",
      "brgy_name": "Mahayahay",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812007",
      "brgy_name": "Mandus",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812008",
      "brgy_name": "Mansa-ilao",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812009",
      "brgy_name": "Pagtila-an",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812010",
      "brgy_name": "Palo Alto",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812011",
      "brgy_name": "Poblacion",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812012",
      "brgy_name": "Rajah Cabungso-an",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812013",
      "brgy_name": "Sabang",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812014",
      "brgy_name": "Salvacion",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812015",
      "brgy_name": "San Roque",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812016",
      "brgy_name": "Tagpoporan",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812017",
      "brgy_name": "Union",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166812018",
      "brgy_name": "Valencia",
      "city_code": "166812",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813001",
      "brgy_name": "Bagsac",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813002",
      "brgy_name": "Bayogo",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813003",
      "brgy_name": "Magsaysay",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813004",
      "brgy_name": "Manga",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813005",
      "brgy_name": "Panayogon",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813006",
      "brgy_name": "Patong Patong",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813007",
      "brgy_name": "Quirino (Pob.)",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813008",
      "brgy_name": "San Antonio",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813009",
      "brgy_name": "San Juan",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813010",
      "brgy_name": "San Roque",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813011",
      "brgy_name": "San Vicente",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813012",
      "brgy_name": "Songkit",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813013",
      "brgy_name": "Union",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166813014",
      "brgy_name": "Linibonan",
      "city_code": "166813",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814001",
      "brgy_name": "Alegria",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814002",
      "brgy_name": "Amontay",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814003",
      "brgy_name": "Antipolo",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814004",
      "brgy_name": "Arorogan",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814005",
      "brgy_name": "Bayan",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814006",
      "brgy_name": "Mahaba",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814007",
      "brgy_name": "Mararag",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814008",
      "brgy_name": "Poblacion",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814009",
      "brgy_name": "San Antonio",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814010",
      "brgy_name": "San Isidro",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814011",
      "brgy_name": "San Pedro",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166814012",
      "brgy_name": "Santa Cruz",
      "city_code": "166814",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815001",
      "brgy_name": "Bretania",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815002",
      "brgy_name": "Buatong",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815003",
      "brgy_name": "Buhisan",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815004",
      "brgy_name": "Gata",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815005",
      "brgy_name": "Hornasan",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815006",
      "brgy_name": "Janipaan",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815007",
      "brgy_name": "Kauswagan",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815008",
      "brgy_name": "Oteiza",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815009",
      "brgy_name": "Poblacion",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815010",
      "brgy_name": "Pong-on",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815011",
      "brgy_name": "Pongtod",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815012",
      "brgy_name": "Salvacion",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166815013",
      "brgy_name": "Santo Niño",
      "city_code": "166815",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816001",
      "brgy_name": "Bagyang",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816002",
      "brgy_name": "Baras",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816003",
      "brgy_name": "Bitaugan",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816004",
      "brgy_name": "Bolhoon",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816005",
      "brgy_name": "Calatngan",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816006",
      "brgy_name": "Carromata",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816007",
      "brgy_name": "Castillo",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816008",
      "brgy_name": "Libas Gua",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816009",
      "brgy_name": "Libas Sud",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816010",
      "brgy_name": "Magroyong",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816011",
      "brgy_name": "Mahayag (Maitum)",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816012",
      "brgy_name": "Patong",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816013",
      "brgy_name": "Poblacion",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816014",
      "brgy_name": "Sagbayan",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816016",
      "brgy_name": "San Roque",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816017",
      "brgy_name": "Siagao",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816018",
      "brgy_name": "Umalag",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166816019",
      "brgy_name": "Tina",
      "city_code": "166816",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817001",
      "brgy_name": "Batunan",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817002",
      "brgy_name": "Carpenito",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817003",
      "brgy_name": "Kahayagan",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817004",
      "brgy_name": "Lago",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817006",
      "brgy_name": "Maglambing",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817007",
      "brgy_name": "Maglatab",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817008",
      "brgy_name": "Magsaysay",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817009",
      "brgy_name": "Malixi",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817010",
      "brgy_name": "Manambia",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817011",
      "brgy_name": "Osmeña",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817012",
      "brgy_name": "Poblacion",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817013",
      "brgy_name": "Quezon",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817014",
      "brgy_name": "San Vicente",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817015",
      "brgy_name": "Santa Cruz",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817016",
      "brgy_name": "Santa Fe",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817017",
      "brgy_name": "Santa Juana",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817018",
      "brgy_name": "Santa Maria",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817019",
      "brgy_name": "Sayon",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817020",
      "brgy_name": "Soriano",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817021",
      "brgy_name": "Tagongon",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817022",
      "brgy_name": "Trinidad",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817023",
      "brgy_name": "Ugoban",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817024",
      "brgy_name": "Villaverde",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817025",
      "brgy_name": "Doña Carmen",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166817026",
      "brgy_name": "Hinagdanan",
      "city_code": "166817",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818001",
      "brgy_name": "Alba",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818002",
      "brgy_name": "Anahao Bag-o",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818003",
      "brgy_name": "Anahao Daan",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818004",
      "brgy_name": "Badong",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818005",
      "brgy_name": "Bajao",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818006",
      "brgy_name": "Bangsud",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818007",
      "brgy_name": "Cagdapao",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818008",
      "brgy_name": "Camagong",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818009",
      "brgy_name": "Caras-an",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818010",
      "brgy_name": "Cayale",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818011",
      "brgy_name": "Dayo-an",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818012",
      "brgy_name": "Gamut",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818013",
      "brgy_name": "Jubang",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818014",
      "brgy_name": "Kinabigtasan",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818015",
      "brgy_name": "Layog",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818016",
      "brgy_name": "Lindoy",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818017",
      "brgy_name": "Mercedes",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818018",
      "brgy_name": "Purisima (Pob.)",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818019",
      "brgy_name": "Sumo-sumo",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818020",
      "brgy_name": "Umbay",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818021",
      "brgy_name": "Unaban",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818022",
      "brgy_name": "Unidos",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818023",
      "brgy_name": "Victoria",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166818024",
      "brgy_name": "Cabangahan",
      "city_code": "166818",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819001",
      "brgy_name": "Awasian",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819002",
      "brgy_name": "Bagong Lungsod (Pob.)",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819003",
      "brgy_name": "Bioto",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819004",
      "brgy_name": "Buenavista",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819005",
      "brgy_name": "Bongtod Pob. (East West)",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819006",
      "brgy_name": "Dagocdoc (Pob.)",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819008",
      "brgy_name": "Mabua",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819009",
      "brgy_name": "Mabuhay",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819010",
      "brgy_name": "Maitum",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819011",
      "brgy_name": "Maticdum",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819012",
      "brgy_name": "Pandanon",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819013",
      "brgy_name": "Pangi",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819014",
      "brgy_name": "Quezon",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819015",
      "brgy_name": "Rosario",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819016",
      "brgy_name": "Salvacion",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819017",
      "brgy_name": "San Agustin Norte",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819018",
      "brgy_name": "San Agustin Sur",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819019",
      "brgy_name": "San Antonio",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819020",
      "brgy_name": "San Isidro",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819021",
      "brgy_name": "San Jose",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "166819022",
      "brgy_name": "Telaje",
      "city_code": "166819",
      "province_code": "1668",
      "region_code": "16"
    },
    {
      "brgy_code": "168501002",
      "brgy_name": "Catadman",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501003",
      "brgy_name": "Columbus",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501004",
      "brgy_name": "Coring",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501005",
      "brgy_name": "Cortes",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501006",
      "brgy_name": "Doña Helene",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501007",
      "brgy_name": "Ferdinand",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501008",
      "brgy_name": "Geotina",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501009",
      "brgy_name": "Imee (Bactasan)",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501010",
      "brgy_name": "Melgar",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501011",
      "brgy_name": "Montag",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501012",
      "brgy_name": "Navarro",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501013",
      "brgy_name": "Poblacion",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501014",
      "brgy_name": "Puerto Princesa",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501015",
      "brgy_name": "Rita Glenda",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501016",
      "brgy_name": "Roxas",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501017",
      "brgy_name": "Sering",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501018",
      "brgy_name": "Tag-abaca",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501019",
      "brgy_name": "Benglen",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501020",
      "brgy_name": "Diegas",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501021",
      "brgy_name": "Edera",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501022",
      "brgy_name": "New Nazareth",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501023",
      "brgy_name": "Roma",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501024",
      "brgy_name": "Santa Monica",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501025",
      "brgy_name": "Santo Niño",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501026",
      "brgy_name": "Sombrado",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501027",
      "brgy_name": "Villa Ecleo",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168501028",
      "brgy_name": "Villa Pantinople",
      "city_code": "168501",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502001",
      "brgy_name": "Boa",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502002",
      "brgy_name": "Cabunga-an",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502003",
      "brgy_name": "Del Pilar",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502004",
      "brgy_name": "Laguna",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502005",
      "brgy_name": "Legaspi",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502006",
      "brgy_name": "Ma-atas",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502007",
      "brgy_name": "Nueva Estrella",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502008",
      "brgy_name": "Poblacion",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502009",
      "brgy_name": "San Jose",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502010",
      "brgy_name": "Santa Rita",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502011",
      "brgy_name": "Tigbao",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502012",
      "brgy_name": "Valencia",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502013",
      "brgy_name": "Mabini (Borja)",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168502014",
      "brgy_name": "R. Ecleo, Sr.",
      "city_code": "168502",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503002",
      "brgy_name": "Cab-ilan",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503003",
      "brgy_name": "Cabayawan",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503004",
      "brgy_name": "Escolta (Pob.)",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503005",
      "brgy_name": "Gomez",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503007",
      "brgy_name": "Magsaysay",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503009",
      "brgy_name": "Mauswagon (Pob.)",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503012",
      "brgy_name": "White Beach (Pob.)",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503014",
      "brgy_name": "Bagumbayan",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503016",
      "brgy_name": "New Mabuhay",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503021",
      "brgy_name": "Wadas",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503022",
      "brgy_name": "Cayetano",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168503023",
      "brgy_name": "Justiniana Edera",
      "city_code": "168503",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504001",
      "brgy_name": "Albor (Pob.)",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504002",
      "brgy_name": "Arellano",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504003",
      "brgy_name": "Bayanihan",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504004",
      "brgy_name": "Doña Helen",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504005",
      "brgy_name": "Garcia",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504006",
      "brgy_name": "General Aguinaldo (Bolod-bolod)",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504007",
      "brgy_name": "Kanihaan",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504008",
      "brgy_name": "Magsaysay",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504009",
      "brgy_name": "Osmeña",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504010",
      "brgy_name": "Plaridel",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504011",
      "brgy_name": "Quezon",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504012",
      "brgy_name": "San Antonio (Pob.)",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504013",
      "brgy_name": "San Jose",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504014",
      "brgy_name": "Santo Niño",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504015",
      "brgy_name": "Llamera",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168504016",
      "brgy_name": "Rosita",
      "city_code": "168504",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505001",
      "brgy_name": "Carmen (Pob.)",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505002",
      "brgy_name": "Esperanza",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505003",
      "brgy_name": "Ferdinand",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505004",
      "brgy_name": "Helene",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505006",
      "brgy_name": "Liberty",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505008",
      "brgy_name": "Magsaysay",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505011",
      "brgy_name": "Panamaon",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505013",
      "brgy_name": "San Juan (Pob.)",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505015",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168505017",
      "brgy_name": "Santiago (Pob.)",
      "city_code": "168505",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506001",
      "brgy_name": "Aurelio",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506002",
      "brgy_name": "Cuarinta",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506003",
      "brgy_name": "Don Ruben Ecleo (Baltazar)",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506004",
      "brgy_name": "Jacquez",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506005",
      "brgy_name": "Justiniana Edera",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506006",
      "brgy_name": "Luna",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506007",
      "brgy_name": "Mahayahay",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506008",
      "brgy_name": "Matingbe",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506009",
      "brgy_name": "San Jose (Pob.)",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506010",
      "brgy_name": "San Juan",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506011",
      "brgy_name": "Santa Cruz",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168506012",
      "brgy_name": "Wilson",
      "city_code": "168506",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507001",
      "brgy_name": "Imelda",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507002",
      "brgy_name": "Mabini",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507003",
      "brgy_name": "Malinao",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507004",
      "brgy_name": "Navarro",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507005",
      "brgy_name": "Diaz (Romualdez)",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507006",
      "brgy_name": "Roxas",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507007",
      "brgy_name": "San Roque (Pob.)",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507008",
      "brgy_name": "San Vicente (Pob.)",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    },
    {
      "brgy_code": "168507009",
      "brgy_name": "Santa Cruz (Pob.)",
      "city_code": "168507",
      "province_code": "1685",
      "region_code": "16"
    }
  ]