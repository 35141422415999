import store from '../../store'

export const loadToken = () => {
  let token = localStorage.getItem('token')

  if (token !== null) {
    return { token: token }
  }

  return { token: null }
}

export const storeToken = () => {
  let state = store.getState()
  if (state.token !== null) {
    localStorage.setItem('token', state.token)
  }
}


// TODO: add me endpoint integrationre here
